import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Empty,
  Row,
  Select,
  Tooltip,
  locales,
} from "antd";
import Chart from "../../../../components/custom-chart";
import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import ModalReport from "./ModalReport/ModalReport";
import { toPng, toCanvas } from "html-to-image";
import { getSymbolStats, getSymbolStatsChart } from "../../../../api/report";
import { useSelector } from "react-redux";
import LoadingIux from "../../../../components/loading";
import moment from "moment";
import { fCurrency, fCurrencySymbol } from "../../../../helper/formatNumber";
import CardReport from "./ModalReport/CardReport";
import { ConfigProvider } from "antd";
import { CheckLanguageAntd } from "../../../../helper/countryLangAntd";
import { CheckFormatDate } from "../../../../helper/countryFormatDate";
import { CheckLanguageMoment } from "../../../../helper/countryLangMoment";

const locale = require("react-redux-i18n").I18n;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

function disableScroll() {
  document.body.classList.add("stop-scrolling");
}

function enableScroll() {
  document.body.classList.remove("stop-scrolling");
}

function compareByDate(a, b) {
  const dateA = new Date(a.date);
  const dateB = new Date(b.date);

  if (dateA < dateB) {
    return -1;
  } else if (dateA > dateB) {
    return 1;
  } else {
    return 0;
  }
}

const { Option } = Select;

function Report() {
  const [modalExport, setModalExport] = useState(false);
  const [imagePreviewDark, setimagePreviewDark] = useState();
  const [imagePreviewLight, setimagePreviewLight] = useState();
  const [dataSymbolStats, setDataSymbolStats] = useState();
  const [loading, setLoading] = useState(true);
  const [dataChart, setDataChart] = useState([]);
  const [isAccount, setIsAccount] = useState();
  const [datepickerOpen, setDatepickerOpen] = useState(false);
  const [isDisplayDate, setIsDisplayDate] = useState("All");
  const [isTypeFilter, setIsTypeFilter] = useState("Last Month");
  const [isDate, setIsDate] = useState(
    `${moment().add(-29, "days").format("YYYY-MM-DD")},${moment().format(
      "YYYY-MM-DD"
    )}`
  );
  const [isType, setIsType] = useState("MMM");
  const [filter, setFilter] = useState();
  const [MontValue, setMontValue] = useState(null);
  const [isAccountDetail, setIsAccountDetail] = useState({});
  const user = useSelector((state) => state.user);
  const refDark = useRef();
  const refLight = useRef();
  const onCloseModalExport = () => {
    setimagePreviewDark(null);
    setimagePreviewLight(null);
    setModalExport(false);
  };

  const onPreviewDark = useCallback(() => {
    if (refDark.current === null) {
      return;
    }
    refDark.current.style.borderRadius = "20px";
    refDark.current.style.backgroundColor = "transparent";
    refDark.current.style.overflow = "hidden";
    toPng(refDark.current, {
      cacheBust: false,
      // backgroundColor: "red",
      // style: { backgroundColor: "red", borderRadius: "99px" },
    })
      .then((dataUrl) => {
        setimagePreviewDark(dataUrl);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refDark]);
  const onPreviwLight = useCallback(() => {
    if (refLight.current === null) {
      return;
    }
    refLight.current.style.borderRadius = "20px";
    refLight.current.style.backgroundColor = "transparent";
    refLight.current.style.overflow = "hidden";
    toPng(refLight.current, {
      cacheBust: false,
      // backgroundColor: "transparent",
    })
      .then((dataUrl) => {
        setimagePreviewLight(dataUrl);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refLight]);
  const onDownLoad = (value) => {
    let checkDate = isDate?.split(",");
    if (value === 1) {
      const link = document.createElement("a");
      link.download = `IUX-Report-${moment(checkDate[0])
        .locale(CheckLanguageMoment(localStorage.getItem("locale")))
        .format(CheckFormatDate(localStorage.getItem("locale")))}-${moment(
        checkDate[1]
      )
        .locale(CheckLanguageMoment(localStorage.getItem("locale")))
        .format(CheckFormatDate(localStorage.getItem("locale")))}.png`;
      link.href = imagePreviewDark;
      link.click();
      // onButtonClick();
    } else {
      const link = document.createElement("a");
      link.download = `IUX-Report-${moment(checkDate[0])
        .locale(CheckLanguageMoment(localStorage.getItem("locale")))
        .format(CheckFormatDate(localStorage.getItem("locale")))}-${moment(
        checkDate[1]
      )
        .locale(CheckLanguageMoment(localStorage.getItem("locale")))
        .format(CheckFormatDate(localStorage.getItem("locale")))}.png`;
      link.href = imagePreviewLight;
      link.click();
    }
  };
  const onOpenModalExport = () => {
    onPreviewDark();
    onPreviwLight();
  };
  useEffect(() => {
    if (imagePreviewLight) {
      setModalExport(true);
    }
  }, [imagePreviewLight]);
  const fetchSymbolStats = async (isAccount, isDate) => {
    try {
      const { data } = await getSymbolStats(isAccount, isDate);
      setDataSymbolStats(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSymbolStatsAll = async (isAccount, isDate) => {
    setLoading(true);
    let checkDate = isDate?.split(",");

    let calcDate =
      checkDate?.length > 0 &&
      moment(checkDate[0]).diff(moment(checkDate[1]), "months");

    try {
      let isAccountDetail = user?.accounts?.find((e) => e.id === isAccount);
      setIsAccountDetail(isAccountDetail);
      const { data } = await getSymbolStatsChart(isAccount, isDate);
      if (data.data.length > 0) {
        if (Math.abs(calcDate) > 2) {
          setIsType("MMM");

          setDataChart(gruopMonth(data?.data, isAccountDetail, isDate));
        } else {
          setIsType("DD/MM");
          setDataChart(unGroup(data.data, isAccountDetail));
        }
      } else {
        setDataChart({
          groupedArray: [],
          minTotal: 0,
          maxTotal: 0,
          totalProfit: 0,
          totalLoss: 0,
          totalTotal: 0,
        });
      }

      fetchSymbolStats(isAccount, isDate);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const onChangeAccount = (value, title) => {
    let currentaccounts = user?.accounts?.find((e) => e.id === value);
    if (isTypeFilter === "All") {
      setIsDate(
        `${
          moment(currentaccounts?.createdAt).diff(moment(), "months") > 3
            ? `${moment(currentaccounts?.createdAt).format(
                "YYYY-MM-DD"
              )},${moment().format("YYYY-MM-DD")}`
            : `${moment()
                .add(-11, "month")
                .format("YYYY-MM-DD")},${moment().format(
                "YYYY-MM-DD"
              )},${moment().format("YYYY-MM-DD")}`
        }`
      );
    }

    setIsAccount(value);
  };
  const onChangeFilterDate = (value, title) => {
    setFilter(value);
    setIsTypeFilter(title.key);
    if (value === "Custom") {
      setDatepickerOpen(true);
    } else {
      setMontValue(null);
      if (title.key === "Last Month") {
        setIsDisplayDate(moment().format("MMM YYYY"));
      } else {
        setIsDisplayDate(title.key);
      }
      setIsDate(value);
    }
  };

  useEffect(() => {
    if (user?.accounts.length > 0) {
      setIsAccount(user?.accounts[0]?.id);
      setIsAccountDetail(user?.accounts[0]);
    } else {
      setDataChart({
        groupedArray: [],
        minTotal: 0,
        maxTotal: 0,
        totalProfit: 0,
        totalLoss: 0,
        totalTotal: 0,
      });
      setLoading(false);
    }
  }, [user?.accounts]);
  useEffect(() => {
    if (isAccount) {
      fetchSymbolStatsAll(isAccount, isDate);
    }
  }, [isAccount, isDate]);
  const onChangeCustomDate = (value) => {
    setMontValue(value);
    let currentDate = moment(value).startOf("month").format("YYYY-MM-DD");
    setIsDisplayDate(moment(new Date(value)).format("MMM YYYY"));
    setIsDate(
      `${currentDate},${moment(currentDate)
        .endOf("month")
        .format("YYYY-MM-DD")}`
    );
    setDatepickerOpen(false);
  };

  const gruopMonth = (data, account, isDate) => {
    const groupedData = {};
    let totalProfit = 0;
    let totalLoss = 0;
    let totalTotal = 0;
    let minTotal = Infinity;
    let maxTotal = -Infinity;
    // Iterate through the data
    data.forEach((entry) => {
      const dateParts = entry.date.split("-");
      const year = dateParts[0];
      const month = dateParts[1];

      // Create a unique key for each month (e.g., "2021-07" for July 2021)
      const monthKey = `${year}-${month}`;

      // If the key doesn't exist in groupedData, create it
      if (!groupedData[monthKey]) {
        groupedData[monthKey] = {
          date: monthKey,
          profit: 0,
          loss: 0,
          total: 0,
        };
      }

      // Add the profit, loss, and total values to the corresponding month

      groupedData[monthKey].profit += entry.profit;
      groupedData[monthKey].loss += entry.loss;
      groupedData[monthKey].total += entry.total;
      totalProfit += entry.profit;
      totalLoss += entry.loss;
      totalTotal += entry.total;
    });
    const allMonths = [];
    let isStartDate = isDate?.split(",")[0];
    let isEndDate = isDate?.split(",")[1];
    const currentDate = new Date(isEndDate);
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const startDate = new Date(isStartDate);
    const startYear = startDate.getFullYear();
    const startisMonth = startDate.getMonth() + 1;
    for (let year = startYear; year <= currentYear; year++) {
      const startMonth = year === startYear ? startisMonth : 1; // Start from March 2021
      for (let month = startMonth; month <= 12; month++) {
        if (year === currentYear && month > currentMonth) {
          // Skip months after the current month
          continue;
        }
        const monthStr = month.toString().padStart(2, "0");
        allMonths.push({ month: monthStr, year: year.toString() });
      }
    }
    allMonths.forEach((monthObj) => {
      const key = `${monthObj.year}-${monthObj.month}`;
      if (!groupedData[key]) {
        groupedData[key] = {
          date: `${monthObj.year.toString()}-${monthObj.month}`,
          profit: 0,
          loss: 0,
          total: 0,
        };
      }
    });
    // Convert the grouped data object into an array of objects
    const groupedArray = Object.values(groupedData);

    // Sort the groupedArray by month if needed
    groupedArray.sort((a, b) => {
      return a.date.localeCompare(b.month);
    });

    groupedArray.forEach((monthData) => {
      minTotal = Math.min(minTotal, monthData.total);
      maxTotal = Math.max(maxTotal, monthData.total);
    });

    // if (account.account_type === "Cent") {
    //   let centdata = groupedArray;
    //   totalProfit = totalProfit / 100;
    //   totalLoss = totalLoss / 100;
    //   totalTotal = totalTotal / 100;

    // }
    const isSort = groupedArray.sort(compareByDate);
    // let checkzero =
    return {
      groupedArray: isSort,
      minTotal,
      maxTotal,
      totalProfit,
      totalLoss,
      totalTotal,
    };
    // Now, groupedArray contains the data grouped by month
  };
  const unGroup = (data, account) => {
    let checkDate = isDate?.split(",");
    const startDate = new Date(checkDate[0]);
    const endDate = new Date(checkDate[1]);
    const groupedData = {};
    let totalProfit = 0;
    let totalLoss = 0;
    let totalTotal = 0;
    let minTotal = Infinity;
    let maxTotal = -Infinity;
    for (
      let currentDate = new Date(startDate);
      currentDate <= endDate;
      currentDate.setDate(currentDate.getDate() + 1)
    ) {
      const currentDateStr = currentDate.toISOString().slice(0, 10);

      // Exclude September 30th and 31st
      if (currentDate.getDate() !== 30 && currentDate.getDate() !== 31) {
        if (!data.find((item) => item.date === currentDateStr)) {
          data.push({
            date: currentDateStr,
            profit: 0,
            loss: 0,
            total: 0,
          });
        }
      }
    }
    const missingDates = [];
    for (
      let currentDate = startDate;
      currentDate <= endDate;
      currentDate.setDate(currentDate.getDate() + 1)
    ) {
      const dateString = currentDate.toISOString().split("T")[0];
      if (!data.find((item) => item.date === dateString)) {
        missingDates.push(dateString);
      }
    }

    // Add objects for missing dates to existing data
    missingDates.forEach((date) => {
      data.push({
        date: date,
        profit: 0,
        loss: 0,
        total: 0,
      });
    });

    data.forEach((monthData) => {
      minTotal = Math.min(minTotal, monthData.total);
      maxTotal = Math.max(maxTotal, monthData.total);
      totalProfit += monthData.profit;
      totalLoss += monthData.loss;
      totalTotal += monthData.total;
    });
    data.sort((a, b) => new Date(a.date) - new Date(b.date));

    const groupedArray = data;
    return {
      groupedArray: groupedArray,
      minTotal,
      maxTotal,
      totalProfit,
      totalLoss,
      totalTotal,
    };
  };

  useEffect(() => {
    if (datepickerOpen) {
      disableScroll();
    } else {
      enableScroll();
    }
  }, [datepickerOpen]);

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  }

  return (
    <>
      {datepickerOpen && (
        <div
          className="report-datepicker-backdrop"
          onClick={() => setDatepickerOpen(false)}
        />
      )}
      <div className="analyze-report user position-relative">
        {" "}
        <div className="d-flex position-absolute" style={{ top: 0 }}>
          <div
            ref={refDark}
            className="d-flex"
            style={{
              fontFamily: "Inter",
              width: "fit-content",
              background: "transparent",
            }}
          >
            <div
              style={{ fontFamily: "Inter", width: "fit-content" }}
              className="d-flex"
            >
              <CardReport
                isTheme={"dark"}
                dataReport={dataChart}
                dataSymbolStats={dataSymbolStats}
                isDisplayDate={isDate}
                isAccount={isAccountDetail}
                isTypeFilter={isTypeFilter}
              />
            </div>
          </div>
          <div
            ref={refLight}
            className="d-flex"
            style={{
              fontFamily: "Inter",
              width: "fit-content",
              background: "transparent",
            }}
          >
            <div
              style={{ fontFamily: "Inter", width: "fit-content" }}
              className="d-flex"
            >
              <CardReport
                isTheme={"light"}
                dataReport={dataChart}
                dataSymbolStats={dataSymbolStats}
                isDisplayDate={isDate}
                isAccount={isAccountDetail}
                isTypeFilter={isTypeFilter}
              />
            </div>
          </div>
        </div>
        <ConfigProvider
          locale={CheckLanguageAntd(localStorage.getItem("locale"))}
        >
          <div className="custom-calendar">
            <MonthPicker
              className="report-datepicker"
              value={MontValue}
              disabledDate={disabledDate}
              // className="w-100"
              open={datepickerOpen}
              onChange={onChangeCustomDate}
              placeholder="Select month"
            />
          </div>
        </ConfigProvider>
        <Row justify="space-between" className="mb-4" gutter={[4, 12]}>
          <Col xs={{ span: 24 }} lg={{ span: 9 }} xl={{ span: 11 }} order={1}>
            <div className="fs-14" style={{ position: "relative", zIndex: 10 }}>
              {locale.t("account_summary")}
              <span className="ml-1 cursor-pointer h-100 ">
                <Tooltip
                  className="analyze"
                  overlayClassName="analyze"
                  overlayStyle={{ background: "#FFF" }}
                  placement="bottom"
                  title={
                    <div
                      style={{ background: "#FFF" }}
                      className="text-main-black"
                    >
                      {locale.t("account_summary_detail")}
                    </div>
                  }
                >
                  <InfoCircleOutlined className="text-lightgray fs-16" />
                </Tooltip>
              </span>
            </div>
            <div>
              <span className="fs-24 fw-700 text-main-black">
                {dataChart?.totalTotal >= 0 ? "" : "-"}
                <span className="fw-400 fs-14">
                  {isAccountDetail?.account_type === "Cent" ? "¢" : "$"}
                </span>

                {fCurrencySymbol(Math.abs(dataChart?.totalTotal).toFixed(2))}
              </span>
            </div>
            <div className="fs-12 fw-300 text-main-black">
              ≈ {dataChart?.totalTotal >= 0 ? "" : "-"}
              {user?.profileCountry?.currency_symbol}&nbsp;
              {isAccountDetail?.account_type === "Cent"
                ? fCurrencySymbol(
                    Math.abs(
                      (dataChart?.totalTotal / 100) *
                        user?.profileCountry?.usd_rate
                    )
                  )
                : fCurrencySymbol(
                    Math.abs(dataChart?.totalTotal) *
                      user?.profileCountry?.usd_rate
                  )}
            </div>
          </Col>
          <Col
            xs={{ span: 24, order: 4 }}
            lg={{ span: 15, order: 2 }}
            xl={{ span: 13, order: 2 }}
            // className="d-none d-lg-block"
          >
            <Row align="middle" gutter={6}>
              <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                className="overflow-hidden"
              >
                <div className="fs-12 fw-300">
                  {" "}
                  {locale.t("dashboardWallet.account")}
                </div>
                <ConfigProvider
                  renderEmpty={() => (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={<span>{locale.t("nodata")}</span>}
                    />
                  )}
                >
                  <Select
                    size="large"
                    className="input-selected w-100 "
                    placeholder={locale.t(
                      "deposit_step.first_step.description"
                    )}
                    value={isAccount}
                    onChange={(value) => onChangeAccount(value)}
                  >
                    {user?.accounts?.length > 0 &&
                      user?.accounts?.map((e, i) => (
                        <Option value={e.id} key={e.id}>
                          <div className="d-flex justify-content-between">
                            <div>
                              {e.platform}&nbsp;
                              {e.account_number}
                              &nbsp; <b>({e?.account_group?.display})</b>
                            </div>
                          </div>
                        </Option>
                      ))}
                  </Select>
                </ConfigProvider>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 6 }}>
                <div className="fs-12 fw-300 ">{locale.t("filter")} </div>
                <Select
                  size="large"
                  className="input-selected w-100"
                  placeholder="Last Year"
                  onChange={onChangeFilterDate}
                  defaultValue={`${moment()
                    .add(-29, "days")
                    .format("YYYY-MM-DD")},${moment().format("YYYY-MM-DD")}`}
                  // value={isDate}
                >
                  <Select.Option
                    key="All"
                    value={`${
                      Math.abs(
                        moment(isAccountDetail?.createdAt).diff(
                          moment(),
                          "months"
                        )
                      ) > 3
                        ? `${moment(isAccountDetail?.createdAt).format(
                            "YYYY-MM-DD"
                          )},${moment().format("YYYY-MM-DD")}`
                        : `${moment()
                            .add(-11, "month")
                            .format("YYYY-MM-DD")},${moment().format(
                            "YYYY-MM-DD"
                          )},${moment().format("YYYY-MM-DD")}`
                    }`}
                  >
                    {locale.t("all")}
                  </Select.Option>
                  <Select.Option
                    key="Last Year"
                    value={`${moment()
                      .add(-11, "month")
                      .startOf("month")
                      .format("YYYY-MM-DD")},${moment().format("YYYY-MM-DD")}`}
                  >
                    {locale.t("last_year")}
                  </Select.Option>
                  <Select.Option
                    key="Last Month"
                    value={`${moment()
                      .add(-29, "days")
                      .format("YYYY-MM-DD")},${moment().format("YYYY-MM-DD")}`}
                  >
                    {locale.t("last_month")}
                  </Select.Option>
                  <Select.Option
                    key="Custom"
                    value="Custom"
                    onClick={() => setDatepickerOpen(true)}
                  >
                    <div onClick={() => setDatepickerOpen(true)}>
                      {locale.t("custom")}
                    </div>
                  </Select.Option>
                </Select>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 6 }}>
                <div className="fs-12 fw-300" style={{ visibility: "hidden" }}>
                  Hidden
                </div>
                <div>
                  <div className="d-flex justify-content-end justify-content-md-start  ">
                    <Button
                      disabled={!dataChart?.groupedArray?.length > 0}
                      className="btn-outline-export w-100 w-xs-auto"
                      onClick={() => onOpenModalExport()}
                    >
                      <UploadOutlined />
                      {locale.t("export")}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>{" "}
          <Col xs={{ span: 12, order: 2 }} md={{ span: 12, order: 3 }}>
            <Card className="analyze-profit px-2 report-title-card border-report-card mt-2 mt-md-0">
              <div> {locale.t("profit")}</div>
              <div className="profit-price report-price-card">
                <span className="fw-400 fs-12">
                  {isAccountDetail?.account_type === "Cent" ? "¢" : "$"}
                </span>
                {fCurrencySymbol(dataChart?.totalProfit)}
              </div>
              <div className="fs-12 fw-300 report-sum-card">
                ≈ {user?.profileCountry?.currency_symbol}{" "}
                {/* {fCurrencySymbol(
                  dataChart?.totalProfit *
                    parseFloat(user?.profileCountry?.usd_rate)
                )} */}
                {isAccountDetail?.account_type === "Cent"
                  ? fCurrencySymbol(
                      Math.abs(
                        (dataChart?.totalProfit / 100) *
                          user?.profileCountry?.usd_rate
                      )
                    )
                  : fCurrencySymbol(
                      Math.abs(dataChart?.totalProfit) *
                        user?.profileCountry?.usd_rate
                    )}
              </div>
            </Card>
          </Col>
          <Col xs={{ span: 12, order: 3 }} md={{ span: 12, order: 4 }}>
            <Card className="analyze-loss px-2 report-title-card border-report-card-right mt-2 mt-md-0">
              <div>{locale.t("loss")}</div>
              <div className="loss-price report-price-card">
                <span className="fw-400 fs-12">
                  {isAccountDetail?.account_type === "Cent" ? "¢" : "$"}
                </span>
                {fCurrencySymbol(Math.abs(dataChart?.totalLoss))}
              </div>
              <div className="fs-12 fw-300 report-sum-card">
                ≈ {user?.profileCountry?.currency_symbol}{" "}
                {isAccountDetail?.account_type === "Cent"
                  ? fCurrencySymbol(
                      Math.abs(
                        (dataChart?.totalLoss / 100) *
                          user?.profileCountry?.usd_rate
                      )
                    )
                  : fCurrencySymbol(
                      Math.abs(dataChart?.totalLoss) *
                        user?.profileCountry?.usd_rate
                    )}
              </div>
            </Card>
          </Col>
        </Row>
        {loading ? (
          <LoadingIux />
        ) : (
          <>
            {dataChart?.groupedArray?.length > 0 ? (
              <Chart
                isTypeFilter={isTypeFilter}
                data={dataChart?.groupedArray?.map((e, i) => {
                  return e.total;
                })}
                min={dataChart.minTotal}
                max={dataChart.maxTotal}
                categories={dataChart?.groupedArray?.map((e, i) => {
                  return e.date;
                })}
                typeFormat={isType}
                dataAll={dataChart?.groupedArray}
                isAccount={isAccountDetail}
              />
            ) : (
              <div
                className="d-flex align-items-center justify-content-center text-lightgray"
                style={{ height: 350 }}
              >
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>{locale.t("nodata")}</span>}
                />
                {/* {locale.t("nodata")} */}
              </div>
            )}
          </>
        )}
        <ModalReport
          isOpen={modalExport}
          onClose={onCloseModalExport}
          imagePreviewDark={imagePreviewDark}
          imagePreviewLight={imagePreviewLight}
          onDownload={onDownLoad}
        />
      </div>
    </>
  );
}

export default Report;
