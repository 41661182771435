export default {
  th: {
    calendar_title: "ปฏิทินเศรษฐกิจ ",
    calendar_subtitle: "มีเฉพาะภาษาอังกฤษเท่านั้น",
    holiday_calendar: "ปฏิทินวันหยุด",
    today: "วันนี้",
  },
  lo: {
    calendar_title: "ປະຕິທິນເສດຖະກິດ",
    calendar_subtitle: "ມີເປັນພາສາອັງກິດເທົ່ານັ້ນ",
    holiday_calendar: "ປະຕິທິນວັນພັກ",
    today: "ມື້​ນີ້",
  },
  en: {
    calendar_title: "Economic Calendar",
    calendar_subtitle: "Available in English only ",
    holiday_calendar: "Holiday Calendar",
    today: "Today",
  },
  hi: {
    calendar_title: "आर्थिक कैलेंडर",
    calendar_subtitle: "केवल अंग्रेजी में उपलब्ध है ",
    holiday_calendar: "अवकाश कैलेंडर",
    today: "आज",
  },
  ms: {
    calendar_title: "Kalendar Ekonom",
    calendar_subtitle: "Tersedia dalam bahasa Inggeris sahaja",
    holiday_calendar: "Kalendar Cuti ",
    today: "Hari ini",
  },
  id: {
    calendar_title: "Kalender Ekonomi ",
    calendar_subtitle: "Hanya tersedia dalam bahasa Inggris",
    holiday_calendar: "Kalender Liburan ",
    today: "Hari ini",
  },
  zh: {
    calendar_title: "Calendar",
    calendar_subtitle: "Available in english only ",
    holiday_calendar: "Holiday Calendar",
    today: "Today",
  },
  vi: {
    calendar_title: "lịch kinh tế",
    calendar_subtitle: "Chỉ có sẵn bằng tiếng Anh",
    holiday_calendar: "Lịch nghỉ lễ",
    today: "Hôm nay",
  },
  ko: {
    calendar_title: "Calender",
    calendar_subtitle: "Available in english only ",
    holiday_calendar: "Holiday Calendar",
    today: "Today",
  },
  fil: {
    calendar_title: "Kalendaryo Ekonomiya",
    calendar_subtitle: "Available lang sa English",
    holiday_calendar: "kalendaryo ng holiday",
    today: "ngayon",
  },
  ja: {
    calendar_title: "経済カレンダー",
    calendar_subtitle: "英語のみで利用できます",
    holiday_calendar: "ホリデーカレンダー",
    today: "今日",
  },
  es: {
    calendar_title: "Calendario Económico",
    calendar_subtitle: "Disponible solo en inglés",
    holiday_calendar: "Calendario de Festivos",
    today: "Hoy",
  },
};
