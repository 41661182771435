export default {
  th: {
    platform_mac_header: {
      title: "MetaTrader สำหรับ Mac\n",
      sub_title:
        "ห้ามพลาดโอกาส! ติดตามตลาดตลอดเวลาด้วยแอพ MetaTrader สำหรับ Android\n",
      button_text: "ดาวน์โหลด Metatrader 4 สำหรับ Android",
    },
  },
  lo: {
    platform_mac_header: {
      title: "MetaTrader ສຳ ລັບ Mac\n",
      sub_title:
        "ຢ່າພາດໂອກາດ! ຕິດຕາມຕະຫຼາດຕະຫຼອດເວລາດ້ວຍແອັບ MetaTrader ສຳ ລັບ Android\n",
      button_text: "ດາວໂຫລດ Metatrader 4 ສຳ ລັບ Mac",
    },
  },
  en: {
    platform_mac_header: {
      title: "MetaTrader for Mac\n",
      sub_title:
        "Don't miss the opportunity! Follow the market all the time with the MetaTrader app for Android.\n" +
        " \n",
      button_text: "Download Metatrader 4 for Mac",
    },
  },
  hi: {
    platform_mac_header: {
      title: "MetaTrader for Mac\n",
      sub_title:
        "Don't miss the opportunity! Follow the market all the time with the MetaTrader app for Android.\n" +
        " \n",
      button_text: "Download Metatrader 4 for Mac",
    },
  },
  ms: {
    platform_mac_header: {
      title: `MetaTrader untuk Mac`,
      sub_title: `Jangan lepaskan peluang! Ikut pasaran sepanjang masa dengan aplikasi MetaTrader untuk Android`,
      button_text: `Muat turun Metatrader 4 untuk Mac`,
    },
  },
  id: {
    platform_mac_header: {
      title: `MetaTrader untuk Mac`,
      sub_title: `Jangan lewatkan kesempatan! Ikuti pasar sepanjang waktu dengan aplikasi MetaTrader untuk Android`,
      button_text: `Download Metatrader 4 untuk Mac`,
    },
  },
  zh: {
    platform_mac_header: {
      title: `MetaTrader的为Mac`,
      sub_title: `千万不要错过机会！按照市场上所有的时间Android版MetaTrader的应用`,
      button_text: `下载MetaTrader 4的Mac`,
    },
  },
  vi: {
    platform_mac_header: {
      title: "MetaTrader đối với Mac\n",
      sub_title:
        "Đừng bỏ lỡ cơ hội! Thực hiện theo thị trường cả thời gian với ứng dụng MetaTrader đối với Android.\n",
      button_text: "Tải xuống Metatrader 4 đối với Android",
    },
  },
  ko: {
    platform_mac_header: {
      title: `Mac 용 메타 트레이더`,
      sub_title: `기회를 놓치지 마세요! 안드로이드에 대한 메타 트레이더의 응용 프로그램과 함께 시장을 항상 따라`,
      button_text: `Mac 용 메타 트레이더 4 다운로드`,
    },
  },
  fil: {
    platform_mac_header: {
      title: `MetaTrader para sa Mac`,
      sub_title: `Huwag palampasin ang pagkakataon! Sundin ang market sa lahat ng oras na may MetaTrader app para sa Android`,
      button_text: `I-download ang Metatrader 4 para sa Mac`,
    },
  },
  ja: {
    platform_mac_header: {
      title: "Mac Metatrader",
      sub_title:
        "機会をお見逃しなく！ Android用のMetatraderアプリで常に市場をフォローしてください。\n" +
        " \n",
      button_text: "Mac用のMetatrader 4をダウンロードします",
    },
  },
};
