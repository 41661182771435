import { Collapse } from "antd";
import React from "react";
import { useSelector } from "react-redux";
const locale = require("react-redux-i18n").I18n;

const { Panel } = Collapse;
function BoxFAQ({ type }) {
  const user = useSelector((state) => state.user);
  const customPanelStyle = {
    // background: "#FFF",
    borderRadius: 4,
    fontSize: 12,
    fontWeight: 300,
    // marginBottom: 24,
    border: 0,
    overflow: "hidden",
  };
  return (
    <div className="user box-faq">
      <div style={{ maxWidth: 347, minHeight: 400 }}>
        <div
          className="d-flex flex-column justify-content-between"
          style={{ maxWidth: 347, minHeight: 400 }}
        >
          <div>
            <div className="d-flex justify-content-between ">
              <div className="fw-700 fs-20">{locale.t("faq.title_faq")}</div>
              <div
                className="text-primary-sub-iux cursor-pointer fs-12"
                onClick={() =>
                  window.open(
                    `https://help.iuxmarkets.com/${
                      localStorage.getItem("locale") === "ja"
                        ? "ja"
                        : localStorage.getItem("locale")
                    }/`
                  )
                }
              >
                {locale.t("faq.viewmore")}
              </div>
            </div>
            {type === "deposit" && (
              <Collapse
                bordered={false}
                className="user"
                style={{ backgroundColor: "#FFF" }}
                expandIconPosition="right"
              >
                <Panel
                  header={locale.t("faq.faq_title_1")}
                  key="1"
                  style={customPanelStyle}
                >
                  <p>{locale.t("faq.faq_des_1")}</p>
                </Panel>
                <Panel
                  header={locale.t("faq.faq_title_2")}
                  key="2"
                  style={customPanelStyle}
                >
                  <p>{locale.t("faq.faq_des_2")}</p>
                </Panel>
                <Panel
                  header={locale.t("faq.faq_title_3")}
                  key="3"
                  style={customPanelStyle}
                >
                  <p>{locale.t("faq.faq_des_3")}</p>
                </Panel>
                <Panel
                  header={locale.t("faq.faq_title_4")}
                  key="4"
                  style={customPanelStyle}
                >
                  <p>{locale.t("faq.faq_des_4")}</p>
                </Panel>
                {user?.bonus_config?.bonus_countries?.find(
                  (e) => e === user?.profile?.country_id
                ) && (
                  <Panel
                    header={locale.t("faq.faq_title_5")}
                    key="5 "
                    style={customPanelStyle}
                  >
                    <p>{locale.t("faq.faq_des_5")}</p>
                  </Panel>
                )}
              </Collapse>
            )}
            {type === "withdraw" && (
              <Collapse
                bordered={false}
                className="user"
                style={{ backgroundColor: "#FFF" }}
                expandIconPosition="right"
              >
                <Panel
                  header={locale.t("faq_withdraw.faq_withdraw_title_1")}
                  key="1"
                  style={customPanelStyle}
                >
                  <p>{locale.t("faq_withdraw.faq_withdraw_des_1")}</p>
                </Panel>
                <Panel
                  header={locale.t("faq_withdraw.faq_withdraw_title_2")}
                  key="2"
                  style={customPanelStyle}
                >
                  <p>{locale.t("faq_withdraw.faq_withdraw_des_2")}</p>
                </Panel>
                <Panel
                  header={locale.t("faq_withdraw.faq_withdraw_title_3")}
                  key="3"
                  style={customPanelStyle}
                >
                  <p>{locale.t("faq_withdraw.faq_withdraw_des_3")}</p>
                </Panel>
                <Panel
                  header={locale.t("faq_withdraw.faq_withdraw_title_4")}
                  key="4"
                  style={customPanelStyle}
                >
                  <p>{locale.t("faq_withdraw.faq_withdraw_des_4")}</p>
                </Panel>
              </Collapse>
            )}
          </div>
          <div className="fs-10 text-primary-sub-iux mt-3">
            {locale.t("faq.faq_footer")}
            {/* {step === 0 ? (
              locale.t("faq.faq_footer")
            ) : (
              <div className="d-flex">
                <div>
                  <b>{locale.t("faq.notice_title")}:&nbsp;</b>
                  {locale.t("faq.notice")}
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BoxFAQ;
