import React, { useState, useEffect } from "react";
import {
  Form,
  Icon,
  Result,
  Input,
  Select,
  message,
  Upload,
  Button,
  Alert,
  Divider,
} from "antd";
import { connect, useSelector } from "react-redux";
import { updateVerifierBookBank } from "../../../../actions";
import { post } from "../../../../helper/request";
import jsonBank from "../../../../mock/bank.json";
import jsonBankLaos from "../../../../mock/laosBank.json";
import jsonBankVn from "../../../../mock/bank_vn.json";
import jsonBankMy from "../../../../mock/bank_my.json";
import jsonBankId from "../../../../mock/bank_id.json";
import { CloudUploadOutlined, FileSearchOutlined } from "@ant-design/icons";
import { StyledContent } from "../../../../components/content/Content";
import { getBankList } from "../../../../api/verifyuser";
import InprogressVerify from "./InprogressVerify";
import FormBookbank from "./FormBookbank";
import { ClipLoader } from "react-spinners";

const locale = require("react-redux-i18n").I18n;
const { Option } = Select;

function VerifyBookBank(props) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [dataBankList, setDataBankList] = useState([]);
  const user = useSelector((state) => state.user);
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        // .replace("data:", "")
        // .replace(/^.+,/, "");
        resolve(base64String);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const fetchBankList = async () => {
    try {
      const { data } = await getBankList();
      setDataBankList(data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchBankList();
  }, []);
  useEffect(() => {
    setLoading(false);
    if (user?.bookBankVerify.status) {
      setLoading(false);
    }
  }, [user?.bookBankVerify]);
  return (
    <div>
      <div
        // style={{ width: 320, borderRadius: 12 }}
        className={` pt-4  pb-4 `}
      >
        <>
          {loading ? (
            <div
              className="h-100 d-flex justify-content-center align-items-center flex-column my-5"
              style={{ height: 500 }}
            >
              <ClipLoader
                color="#36d7b7"
                size={72}
                cssOverride={{ borderWidth: 8 }}
              />
            </div>
          ) : (
            <>
              <h2 className="font-weight-bold px-4 fs-24 fs-xs-16">
                {locale.t("bookbankverifyTitle")}
              </h2>
              <Divider />
              {user?.bookBankVerify?.status === "not_verify" ? (
                <div className="bookbank-container mx-auto">
                  <FormBookbank onSuccess={() => console.log("success")} />
                </div>
              ) : user.bookBankVerify.status === "rejected" ? (
                <div className="bookbank-container mx-auto">
                  <FormBookbank onSuccess={() => console.log("success")} />
                </div>
              ) : user.bookBankVerify.status === "pending" ? (
                <div className="w-auto">
                  <InprogressVerify des={locale.t("sendverifysuccess")} />
                </div>
              ) : user.bookBankVerify.status === "accepted" ? (
                <Result
                  status="success"
                  title="You passed identity verification."
                  subTitle="Have enjoy with IUXmarkets."
                />
              ) : (
                <Result
                  status="error"
                  title="Please Wait"
                  subTitle="Please contact to admin"
                />
              )}
            </>
          )}
        </>
        {/* <p className="mt-2">
        <span style={{ color: "red" }}>Note :</span>
        {locale.t("dashboardFund.modalVerify.footerText3")}
      </p> */}
      </div>
      <p className="p-4 fs-10 text-gray">
        Note :{locale.t("dashboardFund.modalVerify.footerText3")}
      </p>
    </div>
  );
}

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps, { updateVerifierBookBank })(
  VerifyBookBank
);
