import React from "react";
import styled from "styled-components";

const Card = styled.div`
  border-radius: 16px;
  height: 132px;
`;
const IconPanel = (props) => {
  return (
    <div className="col-md-3 col-sm-12 mt-4 d-flex justify-content-center">
      <Card className="d-flex ">
        <div className="">
          <div
            style={{ display: "block", height: 96 }}
            className="d-flex align-items-center"
          >
            <img
              style={{ width: "100%", height: 96 }}
              src={props.item.icon}
              alt=""
            />
          </div>
          <div className="mt-2 text-white fs-16">
            <div className="text-center">
              <p
                className="p-0 text-break text-center mt-3 fs-20 text-yellow-new-iux"
                style={props.style}
              >
                {props.item.title}
              </p>
              <p className="text-center  " style={props.style}>
                {props.item.description}
              </p>
              {props.button}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default IconPanel;
