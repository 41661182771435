export function fncToFixed(num, dec) {
  // if (typeof pre != "undefined" && pre != null) {
  //   var decimals = dec;
  // } else {
  //   var decimals = 2;
  // }
  let decimals = dec || 2;
  num *= Math.pow(10, decimals);
  num =
    (Math.round(num, decimals) +
      (num - Math.round(num, decimals) >= 0.4 ? 1 : 0)) /
    Math.pow(10, decimals);
  return num.toFixed(decimals);
}
