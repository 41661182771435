import React, { useState, useEffect } from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
import { updateGetBonus } from "../../../src/actions";
import { connect } from "react-redux";
import {
  Modal,
  Row,
  Col,
  Checkbox,
  Button,
  Form,
  Input,
  Select,
  Alert,
  message,
  Divider,
} from "antd";
import { StyledContent } from "../../components/content/Content";
import { post, get } from "../../helper/request";
import AccountButton from "../../components/account-button/AccountButton";
import VerifyBookBank from "./fund/verifies/VerifyBookBank";
import { renderApiDomain } from "../../helper/renderApiDomain";
const locale = require("react-redux-i18n").I18n;

function Bonus(props) {
  const history = useHistory();
  const { Option } = Select;
  const [buttonbonus, setButtonbonus] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [bonusVisibleModal, setBonusVisibleModal] = useState(false);
  const [accountClick, setAccountClick] = useState(null);
  const [checkDeposit, setCheckDeposit] = useState(null);
  const [type, setType] = useState(null);
  const [sum, setSum] = useState(null);
  const [form] = Form.useForm();
  const refAdd = React.createRef();
  const [dataBonus, setDataBonus] = useState(null);
  const onChange = (e) => {
    setButtonbonus(e.target.checked);
  };

  const onAccountingClick = (item) => {
    setAccountClick(item);
  };

  const setvisibleBonus = () => {
    setBonusVisibleModal(true);
  };

  const setdisibleBonus = () => {
    setBonusVisibleModal(false);
  };
  useEffect(() => {
    if (checkDeposit >= 0) {
      get(renderApiDomain() + "user/account/deposit")
        .then((res) => {
          setCheckDeposit(res.data);
          setSum(res.data.sum);
        })
        .catch((e) => {
          console.log(e);
        });
    }
    return () => {};
  }, []);
  const handleRequestCredit = () => {
    setButtonLoading(true);
    post(renderApiDomain() + "user/get-credit", {
      account_trade_id: accountClick.id,
      type: type,
    })
      .then(() => {
        const index = props.user.accounts.findIndex(
          (accountClick) => accountClick.id === accountClick.id
        );
        const newAccount = [
          ...props.user.accounts.slice(0, index),
          {
            ...props.user.accounts[index],
            current_credit: props.user.accounts[index].current_credit + 30,
          },
          ...props.user.accounts.slice(index + 1),
        ];
        props.updateGetBonus(newAccount, true);
        Modal.success({
          title: "Complete bonus",
          content: "Please wait to receive bonus credit within 24 hours.",
        });
        setBonusVisibleModal(false);
        setButtonLoading(false);
        window.location.href = "../wallet";
      })
      .catch(() => {
        Modal.error({
          title: "Something went wrong",
          content: "Please contact to support@iuxmarkets.com",
        });
        setBonusVisibleModal(false);
        setButtonLoading(false);
      });
  };
  const onCreate = async () => {
    try {
      const values = await form.validateFields();

      const data = {
        password: values.password,
        leverage: 3000,
      };

      setButtonLoading(true);
      post(renderApiDomain() + "bonus/profile", data)
        .then((res) => {
          let { data } = res;

          // refAdd?.current?.resetFields();

          setButtonLoading(false);

          Modal.info({
            title: locale.t("dashboardWallet.your_mt5"),
            content: (
              <Alert message={data?.data?.account_number} type="success" />
            ),
            onOk() {
              window.location.href =
                window.origin +
                `/${localStorage.getItem("locale")}/wallet/account/welcome`;
            },
          });
          // history.push(`/${localStorage.getItem("locale")}/wallet/account/`);
        })
        .catch((e) => {
          console.log(e);
          setButtonLoading(false);
          try {
            if (e.response.data.code === 10) {
              Modal.error({
                title: locale.t("dashboardWallet.your_account"),
                content: (
                  <Alert
                    message={locale.t("dashboardWallet.error_10")}
                    type="success"
                  />
                ),
              });
            } else {
              message.error(e.response.data.error);
            }
          } catch (error) {
            console.log(error);
          }
        });
    } catch (errorInfo) {
      Modal.info({
        title: locale.t("dashboardWallet.your_mt5"),
        content: <Alert message={"Something went wrong"} type="success" />,
      });
      setButtonLoading(false);
    }
  };
  const validatorPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = new RegExp(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
      );

      if (value?.match(regex)) {
        return Promise.resolve();
      } else {
        if (value) {
          return Promise.reject(
            locale.t("dashboardWallet.modal.accountPassword_validate")
          );
        } else {
          return Promise.reject(locale.t("dashboardWallet.input_error"));
        }
      }
    },
  });

  return (
    <>
      <h1>{locale.t("dashboardWallet.bonus")}</h1>
      {props?.user?.bonus_config?.bonus_countries === undefined ||
      props?.user?.profile?.country_id === undefined ? (
        <div>loading</div>
      ) : props?.user?.bonus_config?.bonus_countries?.find(
          (e) => e === props?.user?.profile?.country_id
        ) ? (
        <div
          className="bg-white p-4 d-flex justify-content-center"
          style={{ borderRadius: 20 }}
        >
          {props.user.bookBankVerify.status === "accepted" ? (
            <div className="text-center">
              <h1 className="mb-4 font-weight-bold">Welcome Bonus</h1>
              <Form
                form={form}
                ref={refAdd}
                initialValues={{
                  currency: "USD",
                  select: 3000,
                }}
                className="w-modal width-modal-openAccount user user-change"
              >
                {/* <Modal
          className="w-modal width-modal-openAccount user user-change"
          closable={false}
          visible={modalCreateVisible}
          title={locale.t("dashboardWallet.modal.title")}
          onCancel={() => {
            form.resetFields();
            setModalCreateVisible(false);
          }}
          footer={[
            <div className="d-flex justify-content-between text-center">
              <Button
                className="btn-primary-new-outline "
                key="back"
                onClick={() => {
                  form.resetFields();
                  setModalCreateVisible(false);
                }}
              >
                {locale.t("dashboardWallet.modal.cancel")}
              </Button>

              <Button
                className="btn-primary-new-iux w-100"
                key="submit"
                type="primary"
                loading={buttonLoading}
                onClick={onCreate}
              >
                {locale.t("dashboardWallet.modal.confirm")}
              </Button>
            </div>,
          ]}
        > */}
                <div>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        name="mt_type"
                        rules={[
                          {
                            message: locale.t("dashboardWallet.input_error"),
                          },
                        ]}
                      >
                        <Input
                          disabled
                          defaultValue={"Metatrader 5"}
                          // style={styleInput}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        name="currency"
                        rules={[
                          {
                            required: true,
                            message: locale.t("dashboardWallet.input_error"),
                          },
                        ]}
                      >
                        <Input disabled style={{ height: 42 }} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item name="type">
                        {/* <Input
                            disabled
                            defaultValue={dataAccount?.display}
                            
                          /> */}
                        <Select
                          size="large"
                          placeholder={"Welcome"}
                          className="user"
                          // defaultValue={isDetail?.display}
                          disabled
                        >
                          <Option value={"Welcome"}>{"Welcome"}</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="select"
                        rules={[
                          {
                            required: true,
                            message: locale.t("dashboardWallet.input_error"),
                          },
                        ]}
                        disabled
                      >
                        <Select
                          size="large"
                          placeholder="1:3000"
                          listHeight={290}
                          className="user"
                        >
                          <Option value={3000}>
                            <div>
                              <div className="row">
                                <div className="col-8 pr-0">
                                  <div className="">1:3000 &nbsp;</div>
                                  <Divider className="m-0" />
                                </div>
                              </div>
                            </div>
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        name="password"
                        rules={[
                          // {
                          //   required: true,
                          //   message: locale.t("dashboardWallet.input_error"),
                          // },
                          validatorPassword,
                        ]}
                      >
                        <Input.Password
                          type="password"
                          placeholder={locale.t(
                            "dashboardWallet.modal.accountPassword"
                          )}
                          className="user-password"
                        />
                      </Form.Item>
                    </Col>
                    {/* <Col span={24}>
                      <Form.Item
                        name="password_confirmation"
                        dependencies={["password"]}
                        rules={[
                          {
                            required: true,
                            message: locale.t("dashboardWallet.input_error"),
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                locale.t("menuWallet.notmathPassword")
                              );
                            },
                          }),
                        ]}
                      >
                        <Input
                          type="password"
                          placeholder={locale.t(
                            "dashboardWallet.modal.accountConfirmPassword"
                          )}
                          style={styleInput}
                        />
                      </Form.Item>
                    </Col> */}
                  </Row>
                  <div className="mt-3">
                    <Checkbox onChange={onChange}>
                      <span style={{ fontSize: 12 }}>
                        {locale.t("dashboardBonus.ckeck_box_bonus")}
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={
                            process.env.PUBLIC_URL + locale.t("pdf.bonus30")
                          }
                          style={{
                            textDecoration: "underline",
                          }}
                          className="text-primary-new-iux fs-12"
                        >
                          <p className="text-center mt-2">
                            {locale.t("termCondition.condition_2")}
                          </p>
                        </a>
                      </span>
                    </Checkbox>
                  </div>
                  <Button
                    className="btn-primary-new-iux w-100"
                    key="submit"
                    type="primary"
                    loading={buttonLoading}
                    onClick={onCreate}
                    disabled={buttonbonus === false}
                  >
                    {locale.t("dashboardWallet.modal.confirm")}
                  </Button>
                </div>
                {/* </Modal> */}
              </Form>
            </div>
          ) : (
            <VerifyBookBank />
          )}
        </div>
      ) : (
        <StyledContent>
          <Row style={{ justifyContent: "center" }}>
            <Col span={12}>
              <div className="text-center p-24 d-flex justify-center">
                <div className="border-receive-bonus">
                  <div className="p-12">
                    <p className="bonus-receive text-primary-new-iux">$30</p>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: 18,
                        position: "relative",
                        top: "-9px",
                      }}
                    >
                      Bonus
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: 14,

                        whiteSpace: "break-spaces",
                      }}
                    >
                      {locale.t("dashboardBonus.bonus_term1")}
                    </p>
                    <p className="text-center mt-3  text-primary-new-iux fs-12">
                      {locale.t("dashboardBonus.bonus_term2")}
                    </p>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={process.env.PUBLIC_URL + locale.t("pdf.bonus30")}
                      style={{
                        textDecoration: "underline",
                      }}
                      className="text-primary-new-iux fs-12"
                    >
                      <p className="text-center mt-2">
                        {locale.t("termCondition.condition_2")}
                      </p>
                    </a>

                    <br></br>
                    <div className="mt-3">
                      <Checkbox onChange={onChange}>
                        <span style={{ fontSize: 12 }}>
                          {locale.t("dashboardBonus.ckeck_box_bonus")}
                        </span>
                      </Checkbox>
                    </div>
                    <div className="mt-2">
                      <Button
                        className={
                          buttonbonus === false ? "" : "btn-primary-new-iux"
                        }
                        block
                        onClick={() => {
                          setvisibleBonus(true);
                          setType(30);
                        }}
                        disabled={buttonbonus === false}
                      >
                        {/* {props?.user?.profile?.country_id === 1 ||
                      props?.user?.profile?.country_id === 8 ? (
                        <> */}
                        {sum >= 20 ? (
                          <b>{locale.t("dashbordCommission.Get Bonus")}</b>
                        ) : (
                          <Link
                            to={`/${localStorage.getItem(
                              "locale"
                            )}/wallet/fund`}
                            style={{ width: "100%" }}
                          >
                            {locale.t("dashboardWallet.deposit")}
                          </Link>
                        )}
                        {/* </>
                       ) : (
                        <b>{locale.t("dashbordCommission.Get Bonus")}</b>
                      )} */}
                      </Button>
                      <br></br>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="text-center mt-4">
            <p
              style={{
                textAlign: "center",
                fontSize: "12px",
                whiteSpace: "break-spaces",
              }}
            >
              {locale.t("dashboardBonus.bonus_warning")}
            </p>
            <p style={{ textAlign: "center", fontSize: "12px" }}>
              {locale.t("dashboardBonus.bonus_warning_2")}
            </p>
          </div>
        </StyledContent>
      )}

      <Modal
        width="75%"
        title={locale.t("menuWallet.getBonus")}
        visible={bonusVisibleModal}
        footer={[
          <Button
            key="back"
            onClick={setdisibleBonus}
            className="btn-cancel-new-iux "
          >
            {locale.t("dashboardWallet.modal.cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            className="btn-primary-new-iux"
            loading={buttonLoading}
            onClick={handleRequestCredit}
            disabled={accountClick === null}
          >
            {locale.t("dashboardWallet.modal.confirm")}
          </Button>,
        ]}
      >
        <Row style={{ padding: "24px" }}>
          {props.user.accounts.filter((account) => account.type === "real")
            .length > 0 ? (
            props.user.accounts
              .filter(
                (account) =>
                  (account.account_type === "Standard" &&
                    account.type === "real") ||
                  (account.account_type === "StandardBonus" &&
                    account.type === "real")
              )
              .map((item, index) => {
                return (
                  <Col
                    span={6}
                    xs={24}
                    sm={24}
                    lg={6}
                    key={"account_" + index}
                    onClick={() => {
                      onAccountingClick(item);
                    }}
                  >
                    <AccountButton
                      style={{
                        color: "#000000b5",
                        // background:
                        //   accountClick === item ? "rgb(55 144 255)" : "#fff",
                        boxShadow: "unset",
                        border: `2px solid ${
                          accountClick === item ? "#1cb894" : "#F2F2F2"
                        }`,

                        borderRadius: "20px",
                      }}
                      locale={locale}
                      item={item}
                    />
                  </Col>
                );
              })
          ) : (
            <p style={{ color: "red" }}>
              You do not have an open trading account.
            </p>
          )}
        </Row>
      </Modal>
    </>
  );
}

const mapStateToProps = ({ user, i18n }) => ({
  locale: i18n.locale,
  user: user,
});

export default connect(mapStateToProps, { updateGetBonus })(withRouter(Bonus));
