import { renderApiDomain } from "../helper/renderApiDomain";
import { get } from "../helper/request";

export const getSymbolStats = async (id, date) => {
  return get(
    `${renderApiDomain()}user/account/symbol/stats?account_trade_id=${id}&date=${date}`
  );
};
export const getSymbolStatsChart = async (id, date) => {
  return get(
    `${renderApiDomain()}user/account/trade/stats?account_trade_id=${id}&date=${date}`
  );
};
