export default {
  th: {
    verify: {
      success: {
        title: "การยืนยันอีเมลสำเร็จ",
        description:
          "ยินดีด้วย! การยืนยันอีเมลของท่านได้รับการอนุมัติ เรียบร้อยแล้ว ท่านสามารถเข้าถึงบริการทั้งหมดและระบบ ของเราได้ ขอขอบคุณสำหรับการยืนยันอีเมลของท่าน",
      },
      reject: {
        title: "การยืนยันอีเมลไม่สำเร็จ",
        description:
          "เรามีความเสียใจที่ต้องแจ้งให้ท่านทราบว่า คำร้องขอยืนยันอีเมลของท่านไม่สำเร็จ กรุณาตรวจสอบลิงก์อีกครั้งหรือติดต่อเจ้าหน้าที่ ของเราเพื่อรับความช่วยเหลือ",
      },
      login: "เข้าสู่ระบบ",
      goToLogin: "เข้าสู่ระบบ",
    },
  },
  lo: {
    verify: {
      success: {
        title: "ຢືນຢັນອີເມວສຳເລັດແລ້ວ",
        description:
          "ຊົມເຊີຍ! ອີເມວຂອງທ່ານໄດ້ຮັບການຢັ້ງຢືນສຳເລັດແລ້ວ ໃນປັດຈຸບັນທ່ານສາມາດເຂົ້າເຖິງການບໍລິການຂອງພວກເຮົາທັງຫມົດ ຂໍຂອບໃຈສຳລັບການຢືນຢັນອີເມວຂອງທ່ານ",
      },
      reject: {
        title: "ການຢືນຢັນອີເມວບໍ່ສຳເລັດ",
        description:
          "ພວກເຮົາເສຍໃຈທີ່ຈະແຈ້ງໃຫ້ທ່ານຮູ້ວ່າການຢັ້ງຢືນອີເມວ ຂອງທ່ານບໍ່ສໍາເລັດ ກະລຸນາກວດເບິ່ງລິ້ງ ຫຼືຕິດຕໍ່ທີມງານຊ່ວຍເຫຼືອຂອງພວກເຮົາເພື່ອຂໍຄວາມຊ່ວຍເຫຼືອ",
      },
      login: "ເຂົ້າ​ສູ່​ລະ​ບົບ",
      goToLogin: "ໄປທີ່ເຂົ້າສູ່ລະບົບ",
    },
  },
  en: {
    verify: {
      success: {
        title: "Email Verification Successful",
        description:
          "Congratulations! Your email has been successfully verified. You can now access all our services. Thank you for confirming your email.",
      },
      reject: {
        title: "Email Verification Unsuccessful",
        description:
          "We regret to inform you that your email verification was unsuccessful. Please recheck the link or contact our support team for assistance.",
      },
      login: "Login",
      goToLogin: "Go to login",
    },
  },
  hi: {
    verify: {
      success: {
        title: "ईमेल सत्यापन सफल",
        description:
          "बधाई हो! आपका ईमेल सफलतापूर्वक सत्यापित हो गया है. अब आप हमारी सभी सेवाओं तक पहुँच सकते हैं। अपने ईमेल की पुष्टि करने के लिए धन्यवाद.",
      },
      reject: {
        title: "ईमेल सत्यापन असफल",
        description:
          "हमें आपको यह बताते हुए खेद है कि आपका ईमेल सत्यापन असफल रहा। कृपया लिंक दोबारा जांचें या सहायता के लिए हमारी सहायता टीम से संपर्क करें।",
      },
      login: "लॉग इन करें",
      goToLogin: "लॉगइन पर जाएं",
    },
  },
  ms: {
    verify: {
      success: {
        title: "Pengesahan E-mel Berjaya",
        description:
          "Tahniah! E-mel anda telah berjaya disahkan. Anda kini boleh mengakses semua perkhidmatan kami. Terima kasih kerana mengesahkan e-mel anda.",
      },
      reject: {
        title: "Pengesahan e -mel tidak berjaya",
        description:
          "Dukacita dimaklumkan kepada anda bahawa pengesahan e-mel anda tidak berjaya. Sila semak semula pautan atau hubungi pasukan sokongan kami untuk mendapatkan bantuan.",
      },
      login: "Log Masuk",
      goToLogin: "Log Masuk",
    },
  },
  id: {
    verify: {
      success: {
        title: "Verifikasi Email Berhasil",
        description:
          "Selamat! Email Anda telah berhasil diverifikasi. Anda sekarang dapat mengakses semua layanan kami. Terima kasih telah mengkonfirmasi email Anda.",
      },
      reject: {
        title: "Verifikasi Email Tidak Berhasil",
        description:
          "Dengan menyesal kami informasikan kepada Anda bahwa verifikasi email Anda tidak berhasil. Silakan periksa kembali tautan tersebut atau hubungi tim dukungan kami untuk mendapatkan bantuan.",
      },
      login: "Masuk",
      goToLogin: "Masuk",
    },
  },
  zh: {
    verify: {
      success: {
        title: "电子邮件验证成功",
        description:
          "恭喜！ 您的电子邮件已成功验证。 您现在可以访问我们的所有服务。 感谢您确认您的电子邮件。",
      },
      reject: {
        title: "电子邮件验证失败",
        description:
          "我们很遗憾地通知您，您的电子邮件验证没有成功。 请重新检查链接或与我们的支持团队联系以寻求帮助。",
      },
      login: "登录",
      goToLogin: "登录",
    },
  },
  vi: {
    verify: {
      success: {
        title: "Xác minh email thành công",
        description:
          "Chúc mừng! Email của bạn đã được xác minh thành công. Bây giờ bạn có thể truy cập tất cả các dịch vụ của chúng tôi. Cảm ơn bạn đã xác nhận email của bạn.",
      },
      reject: {
        title: "Xác minh email không thành công",
        description:
          "Chúng tôi rất tiếc phải thông báo với bạn rằng việc xác minh email của bạn không thành công. Vui lòng kiểm tra lại liên kết hoặc liên hệ với nhóm hỗ trợ của chúng tôi để được hỗ trợ.",
      },
      login: "Đăng nhập",
      goToLogin: "Đi đến đăng nhập",
    },
  },
  ko: {
    verify: {
      success: {
        title: "이메일 확인 성공",
        description:
          "축하해요! 귀하의 이메일이 성공적으로 확인되었습니다. 이제 모든 서비스에 액세스 할 수 있습니다. 이메일을 확인해 주셔서 감사합니다.",
      },
      reject: {
        title: "이메일 확인 실패",
        description:
          "귀하의 이메일 확인이 실패했음을 알려 드리게되어 유감입니다. 도움을 받으려면 링크를 다시 확인하거나 지원 팀에 문의하십시오.",
      },
      login: "로그인",
      goToLogin: "로그인",
    },
  },
  fil: {
    verify: {
      success: {
        title: "Matagumpay na Pag-verify sa Email",
        description:
          "Binabati kita! Ang iyong email ay matagumpay na na-verify. Maaari mo na ngayong ma-access ang lahat ng aming mga serbisyo. Salamat sa pagkumpirma ng iyong email.",
      },
      reject: {
        title: "Hindi Matagumpay na Pag-verify sa Email",
        description:
          "Ikinalulungkot naming ipaalam sa iyo na hindi matagumpay ang iyong pag-verify sa email. Pakisuri muli ang link o makipag-ugnayan sa aming team ng suporta para sa tulong.",
      },
      login: "Mag log in",
      goToLogin: "Mag log in",
    },
  },
  ja: {
    verify: {
      success: {
        title: "メール認証に成功しました",
        description:
          "おめでとう！ あなたのメールアドレスは正常に認証されました。 すべてのサービスにアクセスできるようになりました。 メールをご確認いただきありがとうございます。",
      },
      reject: {
        title: "メール認証が失敗しました",
        description:
          "申し訳ございませんが、メール認証が失敗したことをお知らせいたします。 リンクを再確認するか、サポート チームにお問い合わせください。",
      },
      login: "ログイン",
      goToLogin: "ログインに移動",
    },
  },
  es: {
    verify: {
      success: {
        title: "Verificación de Correo Electrónico Exitosa",
        description:
          "¡Felicidades! Tu correo electrónico ha sido verificado con éxito. Ahora puedes acceder a todos nuestros servicios. Gracias por confirmar tu correo electrónico.",
      },
      reject: {
        title: "Verificación de Correo Electrónico Fallida",
        description:
          "Lamentamos informarte que la verificación de tu correo electrónico no fue exitosa. Por favor, revisa el enlace o contacta a nuestro equipo de soporte para obtener ayuda.",
      },
      login: "Iniciar sesión",
      goToLogin: "Ir a iniciar sesión",
    },
  },
};
