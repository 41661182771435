import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Layout,
  Button,
  Modal,
  Input,
  Select,
  Form,
  message,
  Alert,
  Divider,
} from "antd";
import DemoAccountCard from "../../components/account-card/DemoAccountCard";
import { post, get } from "../../helper/request";
import { addAccount, updateLaverage, deleteAccount } from "../../actions";
import { connect, useSelector } from "react-redux";
import { StyledAccountMain, SubStyledAccountWallet } from "../../components";
import { useDispatch } from "react-redux";
import { getAccount } from "../../actions";
import styled from "styled-components";
import e from "cors";
import { useHistory } from "react-router-dom";
import { renderApiDomain } from "../../helper/renderApiDomain";
const locale = require("react-redux-i18n").I18n;
const { confirm } = Modal;
const { Option } = Select;
const refAdd = React.createRef();
const refEdit = React.createRef();
const refEditInvestor = React.createRef();
const refLeverage = React.createRef();
const styleInput = {
  // height: 42,
};

function DemoAccount(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const isPlatform = props.selectPlatform.toUpperCase();
  const accountTypeListDemo = useSelector(
    (state) => state.account.accountTypeListDemo
  );
  let accplaceholder = accountTypeListDemo
    ?.filter((e) => e.active === 1)
    ?.map(function (item) {
      return item["display"];
    });
  const isLeverages = useSelector((state) => state.user.leverages);
  const [accountTypeId, setAccountTypeId] = useState(null);
  const [form] = Form.useForm();
  const [accounts, setAccounts] = useState(props.user.accounts);
  const [currency, setCurrency] = useState(
    isPlatform === "MT4" ? "GBP" : "USD"
  );

  const [modalCreateVisible, setModalCreateVisible] = useState(false);
  const [modalEditPassword, setModalEditPassword] = useState(null);
  const [modalEditInvestorPassword, setModalEditInvestorPassword] =
    useState(null);
  const [mobile, setMobile] = useState(false);
  const [active, setActive] = useState({ mt4: false, mt5: false, key: "MT4" });
  const user = useSelector((state) => state.user);
  const [modalEditLeverage, setModalEditLeverage] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const selectAccountType = ["Standard", "StandardBonus"];
  const [accType, setAccType] = useState([]);
  const [leverType, setLeverType] = useState("");

  const isMobile = window.innerWidth < 800;
  useEffect(() => {
    setActive({ mt4: true, mt5: false, key: "MT4" });
  }, []);
  useEffect(() => {
    if (props.user.leverages.length > 0) {
      setAccType(props.user.leverages[selectAccountType[0]]);
      setLeverType(props.user.leverages[selectAccountType[0]][0]);
    }
  }, [props.user.leverages]);
  useEffect(() => {
    if (isPlatform === "MT4") {
      setCurrency("GBP");
    } else {
      setCurrency("USD");
    }
  }, [isPlatform, currency]);

  const handleaccountTypeChange = (value, title) => {
    setAccType(isLeverages[title.key]);
  };

  const onSecondCityChange = (value) => {
    setLeverType(value);
  };

  useEffect(() => {
    if (accounts !== props.user.accounts) setAccounts(props.user.accounts);
    return;
  });

  const onCreate = async () => {
    try {
      const values = await form.validateFields();
      const data = {
        currency: isPlatform === "MT4" ? "GBP" : "USD",
        // account_type: values.type,
        account_type_id: values.type,
        platform: isPlatform,
        password: values.password,
        password_confirmation: values.password,
        leverage: +leverType,
        // password_investor: values.password_investor,
        deposit: parseFloat(values.deposit),
      };

      setButtonLoading(true);
      post(renderApiDomain() + "user/account/trade-demo/add", data)
        .then((res) => {
          let { data } = res;

          const newaccount = {
            account_group: { display: data.data?.account_type, type: "demo" },
            id: data.data?.id,
            display: data.data?.account_type,
            group: data.data?.group,
            user_id: data.data?.user_id,
            account_number: data.data?.account_number.toString(),
            platform: data.data?.platform,
            currency: data.data?.currency,
            api_server: data.data?.api_server,
            account_type: data.data?.account_type,
            password: data.data?.password,
            status: data.data?.status,
            leverage: data.data?.leverage,
            current_leverage: data.data?.current_leverage,
            current_balance:
              parseFloat(values.deposit).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) || 0,
            current_credit: data.data?.current_credit || 0,
            deletedAt: null,
            type: data.data?.type,
            reset_code: null,
            password_investor: data.data?.password_investor,
            reset_code_investor: null,
            createdAt: data.data?.createdAt,
            updatedAt: data.data?.updatedAt,
            isOrderPlaced: false,
            avilableWithdrawNonCalculated: 0,
            avilableWithdraw: 0,
          };

          refAdd.current.resetFields();

          props.addAccount([...accounts, newaccount]);

          setButtonLoading(false);
          setModalCreateVisible(false);
          setMobile(false);
          const temp =
            data.data.platform === "MT5"
              ? "dashboardWallet.your_mt5"
              : "dashboardWallet.your_mt4";
          Modal.info({
            title: locale.t(temp),
            content: (
              <Alert message={data.data?.account_number} type="success" />
            ),
          });
        })
        .catch((e) => {
          console.log(e);
          setButtonLoading(false);
          try {
            if (e.response.data.code === 10) {
              Modal.error({
                title: locale.t("dashboardWallet.your_account"),
                content: (
                  <Alert
                    message={locale.t("dashboardWallet.error_10")}
                    type="success"
                  />
                ),
              });
            } else {
              message.error(e.response.data.error);
            }
          } catch (error) {
            console.log(error);
          }
        });
    } catch (errorInfo) {
      setButtonLoading(false);
    }
  };

  const onEdit = async () => {
    try {
      setButtonLoading(true);
      const values = await form.validateFields();
      post(renderApiDomain() + "user/account/password/change", {
        account_trade_id: modalEditPassword.id,
        newPassword: values.new_password,
        reset_code: values.reset_code,
      })
        .then((res) => {
          refEdit.current.resetFields();
          setModalEditPassword(null);
          // window.location.reload();
          message.success("Update success!");
          window.location.reload();
        })
        .catch((e) => {
          message.error(e.response.data.error);
          setButtonLoading(false);
        });
    } catch (error) {
      console.log(error);
      setButtonLoading(false);
    }
  };

  const onEditInvestor = async () => {
    try {
      setButtonLoading(true);
      const values = await form.validateFields();
      post(renderApiDomain() + "user/account/password-investor/change", {
        account_trade_id: modalEditInvestorPassword.id,
        newPassword: values.new_password_investor,
        reset_code_investor: values.reset_code_investor,
      })
        .then((res) => {
          refEditInvestor.current.resetFields();
          setModalEditPassword(null);
          message.success("Update success!");
          window.location.reload();
        })
        .catch((e) => {
          message.error(e.response.data.error);
          setButtonLoading(false);
        });
    } catch (error) {
      message.error(error.response.data.error);
      setButtonLoading(false);
    }
  };

  const onEditLeverage = async () => {
    try {
      setButtonLoading(true);
      const values = await form.validateFields();
      post(renderApiDomain() + "user/account/leverage/change", {
        account_trade_id: modalEditLeverage.id,
        leverage: values.edit_leverage,
      })
        .then((res) => {
          refLeverage.current.resetFields();
          setModalEditLeverage(null);
          message.success("Update success!");
          dispatch(getAccount());
          setButtonLoading(false);
        })
        .catch((e) => {
          try {
            message.error(e.response.data.message);
          } catch (error) {
            message.error(error.message);
          }
        });
    } catch (error) {
      console.log("error", error);
      message.error(error.response.data.error);
      setButtonLoading(false);
    }
  };

  const onSync = async (account) => {
    setButtonLoading(true);
    const index = accounts.indexOf(account);
    accounts[index].loading = true;
    setAccounts(accounts);
    try {
      get(renderApiDomain() + `user/account/sync/${accounts[index].id}`)
        .then((res) => {
          message.success("Sync Success..");
          accounts[index].current_balance = res.data.data.balance;
          accounts[index].current_credit = res.data.data.credit;
          accounts[index].loading = false;
          setAccounts(accounts);
          setButtonLoading(false);
          dispatch(getAccount());
        })
        .catch((e) => {
          console.log("e", e);
          try {
            message.error(e.response.data.error);
          } catch (error) {
            message.error(error.message);
          }
          accounts[index].loading = false;
          setAccounts(accounts);
        });
    } catch (error) {
      setButtonLoading(false);
    }
  };

  function onEditLeverageShow(account) {
    setAccType(props.user.leverages[account.account_type]);
    setModalEditLeverage(account);
  }

  function onEditPasswordShow(account) {
    get(
      renderApiDomain() +
        `user/account/password/request-reset_code/${account.id}`
    );
    setModalEditPassword(account);
  }

  function onDelete(account) {
    confirm({
      title: `${locale.t("dashboardWallet.menu.confirm_dialog.title")} : ${
        account.account_number
      }`,
      content: locale.t("dashboardWallet.menu.confirm_dialog.content"),
      onOk() {
        return new Promise((resolve, reject) => {
          post(renderApiDomain() + "user/account/destroy", {
            account_trade_id: account.id,
          })
            .then((res) => {
              props.deleteAccount(accounts, account);
              resolve();
            })
            .catch((e) => {
              message.error(e.response.data.error);
              reject();
            });
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  function onEditInvestorPasswordShow(account) {
    get(
      renderApiDomain() +
        `user/account/password-investor/request-reset_code/${account.id}`
    );
    setModalEditInvestorPassword(account);
  }

  const validatorPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      // const regex = new RegExp(
      //   /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
      // );
      const regex = new RegExp(
        /^(?=.*[0-9])(?=.*[~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]{8,}$/
      );
      if (value) {
        if (value?.match(regex)) {
          return Promise.resolve();
        } else {
          return Promise.reject(
            locale.t("dashboardWallet.modal.accountPassword_validate")
          );
        }
      } else {
        return Promise.reject(locale.t("dashboardWallet.input_error"));
      }

      // if (value) {
      //   return Promise.reject(
      //     locale.t("dashboardWallet.modal.accountPassword_validate")
      //   );
      // }
      // } else {
      //   return Promise.reject(locale.t("dashboardWallet.input_error"));
      // }
    },
  });

  const validatorInvestorPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = new RegExp(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
      );
      if (value.match(regex)) {
        return Promise.resolve();
      }
      return Promise.reject(
        locale.t("dashboardWallet.modal.accountPasswordInvestor")
      );
    },
  });

  return (
    <Layout className="max-width-layout">
      <SubStyledAccountWallet>
        <div className="d-flex iux-borderBottom pl-3 pr-3">
          <div
            className="d-flex"
            style={{ padding: "0px 6px 0px 6px", borderRight: "1px dashed" }}
          >
            <div className="d-flex">
              <div className="demo-account-logo">
                <div className="m-auto">DEMO</div>
              </div>
            </div>
            <div className="col-md">
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "700",
                  fontFamily: "sans-serif",
                }}
              >
                <b>Demo</b>
              </p>
              <p style={{ fontSize: "12px", textTransform: "uppercase" }}>
                Account
              </p>
            </div>
          </div>
          <div className="col-md" style={{ padding: "0px 18px" }}>
            <div className="d-flex">
              <div className="minWallet-w100">
                <p></p>
                <p></p>
              </div>
              <div className="color-textbox">
                <p className="hide-responsive"></p>
                <p className="show-responsive"></p>
                <p></p>
              </div>
            </div>
          </div>

          {accountTypeListDemo?.length > 0 && (
            <Button
              className="btn-primary-new-outline d-lg-block d-md-block d-none d-xl-block"
              onClick={() =>
                history.replace(
                  `/${localStorage.getItem(
                    "locale"
                  )}/wallet/account/openaccount`
                )
              }
            >
              <p className="">{locale.t("dashboardWallet.createAccount")}</p>
              <p className="hide-desktop">+</p>
            </Button>
          )}
        </div>
        {/* BTN2 */}
        <div className="d-md-none mt-3 mt-md-0 text-center">
          <Button
            disabled={isPlatform === "MT4"}
            className={
              isPlatform !== "MT4" ? " btn-outline-iux" : "btn-disabled-iux"
            }
            onClick={() => {
              isPlatform !== "MT4" &&
                history.replace(
                  `/${localStorage.getItem(
                    "locale"
                  )}/wallet/account/openaccount`
                );
            }}
          >
            <p className="">
              {isPlatform !== "MT4"
                ? locale.t("dashboardWallet.createAccount")
                : locale.t("dashboardWallet.only_mt5")}
            </p>
          </Button>
        </div>
        <div className="mt-4">
          <Row>
            {/**
             * Edit leverage
             */}
            <Form form={form} ref={refLeverage}>
              <Modal
                closable={false}
                className="w-modal width-modal-openAccount user"
                visible={modalEditLeverage !== null}
                title={locale.t("dashboardWallet.menu.leverage_edit")}
                onCancel={() => {
                  setModalEditLeverage(null);
                }}
                footer={[
                  <div className="d-flex justify-content-between text-center">
                    <Button
                      className="btn-outline-iux"
                      key="back"
                      onClick={() => {
                        setModalEditLeverage(null);
                      }}
                    >
                      {locale.t("dashboardWallet.modal.cancel")}
                    </Button>
                    <Button
                      className="btn-primary-new-iux w-100"
                      key="submit"
                      type="primary"
                      onClick={() => {
                        onEditLeverage();
                      }}
                    >
                      {locale.t("dashboardWallet.modal.confirm")}
                    </Button>
                  </div>,
                ]}
              >
                <div>
                  <Form.Item
                    name="edit_leverage"
                    // label={locale.t("dashboardWallet.accountLeverage")}
                    rules={[
                      {
                        required: true,
                        message: locale.t("dashboardWallet.input_error"),
                      },
                    ]}
                  >
                    <Select size="large" placeholder="Leverage">
                      {accType.map((e, i) => (
                        <Option value={e.values} key={i}>
                          <div>
                            <div className="row">
                              <div className="col-8 pr-0">
                                <div className="">1:{e.values} &nbsp;</div>
                                <Divider className="m-0" />
                              </div>
                              {(e.values === 1 ||
                                e.values === 100 ||
                                e.values === 1000) && (
                                <div
                                  className={`${
                                    e.values >= 1000
                                      ? "text-danger"
                                      : e.values < 100
                                      ? "text-primary-new-iux"
                                      : "text-yellow-new-iux"
                                  } col-4 text-right pl-0`}
                                >
                                  <div className=""> {e.title}</div>
                                  <Divider
                                    className={`${
                                      e.values >= 1000
                                        ? "bg-danger"
                                        : e.values < 100
                                        ? "bg-primary-iux"
                                        : "bg-yellow-iux"
                                    } m-0`}
                                    style={{
                                      background:
                                        e.values >= 1000
                                          ? "#B44844"
                                          : e.values < 100
                                          ? "#1cb894"
                                          : "rgb(241, 158, 3)",
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Modal>
            </Form>

            {/**
             * Edit Password Modal
             */}
            <Form form={form} ref={refEdit}>
              <Modal
                closable={false}
                className="w-modal width-modal-openAccount user-change"
                visible={modalEditPassword !== null}
                title={locale.t("dashboardWallet.menu.account_edit")}
                onCancel={() => {
                  setModalEditPassword(null);
                }}
                footer={[
                  <div className="d-flex justify-content-between text-center">
                    <Button
                      className="btn-outline-iux"
                      key="back"
                      onClick={() => {
                        setModalEditPassword(null);
                      }}
                    >
                      {locale.t("dashboardWallet.modal.cancel")}
                    </Button>
                    <Button
                      className="btn-primary-new-iux w-100"
                      key="submit"
                      type="primary"
                      // loading={this.state.loading}
                      loading={buttonLoading}
                      onClick={() => {
                        onEdit();
                      }}
                    >
                      {locale.t("dashboardWallet.modal.confirm")}
                    </Button>
                  </div>,
                ]}
              >
                <div className="user">
                  <Form.Item
                    name="new_password"
                    rules={[
                      {
                        required: true,
                        message: locale.t("dashboardWallet.input_error"),
                      },
                      validatorPassword,
                    ]}
                  >
                    <Input.Password
                      type="password"
                      className="icon-user user-password"
                      placeholder={locale.t(
                        "dashboardWallet.modal.accountPassword"
                      )}
                      style={styleInput}
                    />
                  </Form.Item>
                  <Form.Item
                    name="new_password_confirm"
                    dependencies={["new_password"]}
                    rules={[
                      {
                        required: true,
                        message: locale.t("dashboardWallet.input_error"),
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (
                            !value ||
                            getFieldValue("new_password") === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            locale.t("menuWallet.notmathPassword")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      type="password"
                      className="icon-user user-password"
                      placeholder={locale.t(
                        "dashboardWallet.modal.accountConfirmPassword"
                      )}
                      style={styleInput}
                    />
                  </Form.Item>
                  <Form.Item
                    name="reset_code"
                    // label="Code"
                    rules={[
                      {
                        required: true,
                        message: locale.t("dashboardWallet.input_error"),
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder={`${locale.t("dashboardWallet.modal.code")} ${
                        user.otp_to === "phone" ? "SMS" : "Email"
                      }`}
                      style={styleInput}
                    />
                  </Form.Item>
                </div>
              </Modal>
            </Form>
            {/***
             * Edit Investor Modal
             */}
            <Form form={form} ref={refEditInvestor}>
              <Modal
                closable={false}
                className="w-modal width-modal-openAccount user-change"
                visible={modalEditInvestorPassword !== null}
                title={locale.t("dashboardWallet.menu.investor_edit")}
                onCancel={() => {
                  setModalEditInvestorPassword(null);
                }}
                footer={[
                  <div className="d-flex justify-content-between text-center">
                    <Button
                      className="btn-outline-iux"
                      key="back"
                      onClick={() => {
                        setModalEditInvestorPassword(null);
                      }}
                    >
                      {locale.t("dashboardWallet.modal.cancel")}
                    </Button>
                    <Button
                      className="btn-primary-new-iux w-100"
                      key="submit"
                      type="primary"
                      onClick={() => {
                        onEditInvestor();
                      }}
                    >
                      {locale.t("dashboardWallet.modal.confirm")}
                    </Button>
                  </div>,
                ]}
              >
                <div>
                  <Form.Item
                    name="new_password_investor"
                    rules={[
                      {
                        required: true,
                        message: locale.t("dashboardWallet.input_error"),
                      },
                      validatorPassword,
                    ]}
                  >
                    <Input.Password
                      type="password"
                      className="icon-user user-password"
                      placeholder={locale.t(
                        "dashboardWallet.modal.accountPasswordInvestor"
                      )}
                      style={styleInput}
                    />
                  </Form.Item>
                  <Form.Item
                    name="new_password_investor_confirm"
                    dependencies={["new_password_investor"]}
                    // label={locale.t(
                    //   "dashboardWallet.modal.accountConfirmPassword"
                    // )}
                    rules={[
                      {
                        required: true,
                        message: locale.t("dashboardWallet.input_error"),
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (
                            !value ||
                            getFieldValue("new_password_investor") === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            locale.t("menuWallet.notmathPassword")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      type="password"
                      className="icon-user user-password"
                      placeholder={locale.t(
                        "dashboardWallet.modal.accountConfirmPasswordInvestor"
                      )}
                      style={styleInput}
                    />
                  </Form.Item>
                  <Form.Item
                    name="reset_code_investor"
                    // label="Code"
                    rules={[
                      {
                        required: true,
                        message: locale.t("dashboardWallet.input_error"),
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder={`${locale.t("dashboardWallet.modal.code")} ${
                        user.otp_to === "phone" ? "SMS" : "Email"
                      }`}
                      style={styleInput}
                    />
                  </Form.Item>
                </div>
              </Modal>
            </Form>
            {/***
             * Create Account Modal
             */}
            <Form
              form={form}
              ref={refAdd}
              initialValues={{
                currency: currency,
                deposit: 1000,
              }}
            >
              <Modal
                closable={false}
                className="w-modal width-modal-openAccount user-change user"
                style={{ padding: 0 }}
                visible={modalCreateVisible}
                title={locale.t("dashboardWallet.modal.title")}
                onCancel={() => {
                  // form.resetFields();
                  setModalCreateVisible(false);
                }}
                footer={[
                  <div className="d-flex justify-content-between text-center">
                    <Button
                      className="btn-primary-new-outline"
                      key="back"
                      onClick={() => {
                        // form.resetFields();
                        setModalCreateVisible(false);
                      }}
                    >
                      {locale.t("dashboardWallet.modal.cancel")}
                    </Button>
                    <Button
                      className="btn-primary-new-iux w-100"
                      key="submit"
                      type="primary"
                      loading={buttonLoading}
                      onClick={onCreate}
                    >
                      {locale.t("dashboardWallet.modal.confirm")}
                    </Button>
                  </div>,
                ]}
              >
                <div>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        name="mt_type"
                        rules={[
                          {
                            message: locale.t("dashboardWallet.input_error"),
                          },
                        ]}
                      >
                        {isPlatform === "MT4" && (
                          <Input
                            disabled
                            defaultValue={"Metatrader 4"}
                            style={styleInput}
                          />
                        )}
                        {isPlatform === "MT5" && (
                          <Input
                            disabled
                            defaultValue={"Metatrader 5"}
                            style={styleInput}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="currency"
                        rules={[
                          {
                            message: locale.t("dashboardWallet.input_error"),
                          },
                        ]}
                      >
                        {isPlatform === "MT4" && (
                          <Input
                            style={styleInput}
                            defaultValue="GBP"
                            disabled
                          />
                        )}
                        {isPlatform === "MT5" && (
                          <Input
                            style={styleInput}
                            defaultValue="USD"
                            disabled
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="type"
                        rules={[
                          {
                            required: true,
                            message: locale.t("dashboardWallet.input_error"),
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          onChange={handleaccountTypeChange}
                          placeholder={accplaceholder?.toString()}
                        >
                          {accountTypeListDemo
                            ?.filter((e) => e.active === 1)
                            ?.map((e, i) => (
                              <Option key={e.name} value={e.id}>
                                {e?.display}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="select"
                        rules={[
                          {
                            required: true,
                            message: locale.t("dashboardWallet.input_error"),
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          value={leverType}
                          onChange={onSecondCityChange}
                          placeholder="Leverage"
                        >
                          {accType.map((e, i) => (
                            <Option value={e.values} key={i}>
                              <div>
                                <div className="row">
                                  <div className="col-8 pr-0">
                                    <div className="">1:{e.values} &nbsp;</div>
                                    <Divider className="m-0" />
                                  </div>
                                  {(e.values === 1 ||
                                    e.values === 100 ||
                                    e.values === 1000) && (
                                    <div
                                      className={`${
                                        e.values >= 1000
                                          ? "text-danger"
                                          : e.values < 100
                                          ? "text-primary-new-iux"
                                          : "text-yellow-new-iux"
                                      } col-4 text-right pl-0`}
                                    >
                                      <div className=""> {e.title}</div>
                                      <Divider
                                        className={`${
                                          e.values >= 1000
                                            ? "bg-danger"
                                            : e.values < 100
                                            ? "bg-primary-iux"
                                            : "bg-yellow-iux"
                                        } m-0`}
                                        style={{
                                          background:
                                            e.values >= 1000
                                              ? "#B44844"
                                              : e.values < 100
                                              ? "#1cb894"
                                              : "rgb(241, 158, 3)",
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: locale.t("dashboardWallet.input_error"),
                          },
                          validatorPassword,
                        ]}
                      >
                        <Input.Password
                          type="password"
                          className="icon-user user-password"
                          placeholder={locale.t(
                            "dashboardWallet.modal.accountPassword"
                          )}
                          style={styleInput}
                        />
                      </Form.Item>
                    </Col>
                    {/* <Col span={24}>
                      <Form.Item
                        name="password_confirmation"
                        dependencies={["password"]}
                        rules={[
                          {
                            required: true,
                            message: locale.t("dashboardWallet.input_error"),
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                locale.t("menuWallet.notmathPassword")
                              );
                            },
                          }),
                        ]}
                      >
                        <Input
                          type="password"
                          className="icon-user"
                          placeholder={locale.t(
                            "dashboardWallet.modal.accountConfirmPassword"
                          )}
                          style={styleInput}
                        />
                      </Form.Item>
                    </Col> */}
                    {/* <Col span={24}>
                      <Form.Item
                        name="password_investor"
                        dependencies={["password"]}
                        rules={[
                          {
                            required: true,
                            message: locale.t("dashboardWallet.input_error"),
                          },
                          validatorInvestorPassword,
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (
                                !value ||
                                getFieldValue("password") !== value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                locale.t("menuWallet.notmathPassword_investor")
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          type="password"
                          className="icon-user user-password"
                          placeholder={locale.t(
                            "dashboardWallet.modal.accountPasswordInvestor"
                          )}
                          style={styleInput}
                        />
                      </Form.Item>
                    </Col> */}
                    <Col span={24}>
                      <Form.Item
                        name="deposit"
                        rules={[
                          {
                            required: true,
                            message: locale.t("dashboardWallet.input_error"),
                          },
                        ]}
                      >
                        <Input
                          style={styleInput}
                          type="number"
                          placeholder={`Amount (${
                            isPlatform === "MT4" ? "GBP" : "USD"
                          })`}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Modal>
            </Form>
          </Row>
        </div>

        {accounts.filter(
          (account) =>
            account.type === "demo" && account.platform === isPlatform
        ).length > 0 ? (
          <div
            className="row"
            style={{
              margin: "12px",
              display: isMobile ? (mobile ? "none" : "") : "",
            }}
          >
            {accounts
              .filter(
                (account) =>
                  account.type === "demo" && account.platform === isPlatform
              )
              ?.map((account, index) => (
                <div key={index} className="col-md minandmax-width">
                  <DemoAccountCard
                    account={account}
                    onEditLeverageShow={onEditLeverageShow}
                    onEditPasswordShow={onEditPasswordShow}
                    onEditInvestorPasswordShow={onEditInvestorPasswordShow}
                    onDelete={onDelete}
                    key={"DemoAccountCard_" + index}
                    onSync={onSync.bind(this, index)}
                    loading={account.loading}
                    isDetail={accountTypeListDemo?.find(
                      (e) => e.name === account?.account_type
                    )}
                  />
                </div>
              ))}
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center flex-column pt-5 pb-5">
            <img
              src="/assets/images/icons/none-account.svg"
              alt="tranfer-icons"
            />
            <p className="mb-4 mt-2 text-center mx-2">
              {locale.t("dashboardWallet.notAccount")}
            </p>
            <Button
              onClick={() =>
                history.replace(
                  `/${localStorage.getItem(
                    "locale"
                  )}/wallet/account/openaccount`
                )
              }
              className="mt-4 btn-primary-new-iux"
              htmlType="submit"
              type="primary"
            >
              <p className="">+ {locale.t("dashboardWallet.createAccount")}</p>
            </Button>
          </div>
        )}
      </SubStyledAccountWallet>
    </Layout>
  );
}

const mapStateToProps = ({ user, i18n }) => {
  return { user, locale: i18n.locale };
};

export default connect(mapStateToProps, {
  addAccount,
  updateLaverage,
  deleteAccount,
})(DemoAccount);
