export const CheckFormatDateChart = (id) => {
  switch (id) {
    case "th":
      return "DD MMMM YYYY";
    case "en":
      return "DD MMMM YYYY";
    case "zh":
      return "DD MMMM YYYY";
    case "ms":
      return "DD MMMM YYYY";
    case "hi":
      return "DD MMMM YYYY";
    case "lo":
      return "DD MMMM YYYY";
    case "id":
      return "DD MMMM YYYY";
    case "vi":
      return "DD MMMM YYYY";
    case "fil":
      return "DD MMMM YYYY";
    case "ja":
      return "YYYY年 MMM D日";
    case "ko":
      return "DD MMMM YYYY";
    default:
      return "DD MMMM YYYY";
  }
};
//
export const CheckFormatMonthChart = (id) => {
  switch (id) {
    case "th":
      return "MMM YYYY";
    case "en":
      return "MMM YYYY";
    case "zh":
      return "MMM YYYY";
    case "ms":
      return "MMM YYYY";
    case "hi":
      return "MMM YYYY";
    case "lo":
      return "MMM YYYY";
    case "id":
      return "MMM YYYY";
    case "vi":
      return "MMM YYYY";
    case "fil":
      return "MMM YYYY";
    case "ja":
      return "YYYY年 MMM";
    case "ko":
      return "MMM YYYY";
    default:
      return "MMM YYYY";
  }
};
