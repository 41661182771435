import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";
import { fncToFixed } from "../../../../helper/toFixedCustom";
const locale = require("react-redux-i18n").I18n;
function CryptoConfirm({
  cryptoRate,
  account,
  amount,
  currencySelected,
  cryptoFee,
  walletAddress,
}) {
  // const renderRateWithdraw = () => {
  //   if (account?.account_type === "Cent") {
  //     return ` ${((amount / 100) * parseFloat(cryptoRate))
  //       .toFixed(currencySelected?.fixed)
  //       .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
  //   } else {
  //     return ` ${(amount * parseFloat(cryptoRate))
  //       .toFixed(currencySelected?.fixed)
  //       .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
  //   }
  // };
  const renderRateWithdraw = () => {
    if (account?.account_type === "Cent") {
      let total = (amount / 100) * parseFloat(cryptoRate);
      return `${fncToFixed(total, currencySelected?.fixed)}`;
    } else {
      let total = amount * parseFloat(cryptoRate);
      return `${fncToFixed(total, currencySelected?.fixed)}`;
    }
  };
  const renderFeeRate = () => {
    return ` ${(parseFloat(cryptoFee) / parseFloat(cryptoRate))
      .toFixed(currencySelected?.fixed)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
  };

  return (
    <div className="p-3">
      <div className=" d-flex flex-column justify-content-between">
        <div className="fw-300">
          <div className="mb-3 text-lightgray-iux row">
            <div className="col-6 px-0 px-sm-3">
              {locale.t("withdraw_info.wallet_address")}
            </div>
            <div className="col-6 ">
              <p className="text-right">{walletAddress}</p>
            </div>
          </div>
          <div className="mb-3 text-lightgray-iux d-flex justify-content-between">
            {locale.t("deposit_step.second_step.description")}
            <span>
              {" "}
              {renderRateWithdraw()}&nbsp;
              {currencySelected?.currency}
            </span>
          </div>
          <div className="mb-3 text-lightgray-iux d-flex justify-content-between">
            <div>
              <div> {locale.t("withdraw_info.bockchain_fee")}</div>
              <div>({locale.t("withdraw_info.approximate")})</div>
            </div>
            <div className="text-end">
              <div className="text-right">
                {cryptoFee} {currencySelected?.currency}
              </div>
              <div>≈ {renderFeeRate()} USD</div>
            </div>
          </div>
          <div className="mb-3 text-lightgray-iux d-flex justify-content-between">
            {locale.t("deposit_step.second_step.fee")}

            <span>0.00 {currencySelected?.currency}</span>
          </div>
          <div className="mb-3 text-lightgray-iux d-flex align-items-center justify-content-between">
            <div>
              {locale.t("withdraw_info.rate")}
              &nbsp;{" "}
              <Tooltip
                placement="topLeft"
                title={<div className="fs-10">{locale.t("faq.notice")}</div>}
              >
                <InfoCircleOutlined className="text-lightgray fs-16" />
              </Tooltip>
            </div>

            <span>
              {cryptoRate} {currencySelected?.currency}USD
            </span>
          </div>
        </div>
      </div>{" "}
      <div
        className="text-center py-2"
        style={{ background: "#F8D04626", borderRadius: 4, padding: "0 44px" }}
      >
        {locale.t("withdraw_info.crypto_warning")}
      </div>
    </div>
  );
}

export default CryptoConfirm;
