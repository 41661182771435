export const CheckFormatDate = (id) => {
  switch (id) {
    case "th":
      return "DDMMMYY";
    case "en":
      return "DDMMMYY";
    case "zh":
      return "DDMMMYY";
    case "ms":
      return "DDMMMYY";
    case "hi":
      return "DDMMMYY";
    case "lo":
      return "DDMMMYY";
    case "id":
      return "DDMMMYY";
    case "vi":
      return "DDMMMYY";
    case "fil":
      return "DDMMMYY";
    case "ja":
      return "YYYY年 MMM D日";
    case "ko":
      return "DDMMMYY";
    default:
      return "DDMMMYY";
  }
};
//
