import { getFirebaseCustom } from "../api/account";
export const getCustomToken = async () => {
  const { data } = await getFirebaseCustom();

  let isLink =
    typeof window !== "undefined" &&
    window.location.origin.replace("portal", "trading");

  try {
    window.open(`${isLink}/login/${data?.data?.token}`);
  } catch (error) {
    console.log(error);
  }
};
