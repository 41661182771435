import { Col, Row, Spin, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { get, post } from "../../../../helper/request";
import { renderApiDomain } from "../../../../helper/renderApiDomain";

const ItemSelect = styled.div`
  box-shadow: 0 0 7px #80808033;
  padding: 12px;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.3s;
  height: 100%;
  &:hover {
    background: #1f73b7;
    color: #fff;
  }
`;
export const postFormPayment = ({
  amount_thb,
  account_trade_id,
  ipaddress,
  payment_key,
}) => {
  return post(`${renderApiDomain() + `deposit/${payment_key}`}`, {
    amount_thb: amount_thb,
    account_trade_id: account_trade_id,
  });
};

const FormPayment = ({
  amount_thb,
  account_trade_id,
  payment_id,
  bank,
  ip,
  payment_key,
  paymentId,
  amount,
  account,
  onBackStep,
}) => {
  const selectIp = useSelector(({ user: { ipAddress } }) => ipAddress);
  //   const { paymentId } = props;
  const lang = localStorage.getItem("locale");
  const refPerfect = useRef(null);
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState({
    host: "",
    api_token: "",
    website_id: "",
    action: "",
    price: "",
    curr: "",
    product_name: "",
    fullname: "",
    email: "",
    bill_street_1: "",
    bill_city: "",
    bill_country: "",
    bill_zip: "",
    bill_phone: "",
    id_order: "",
    notify_url: "",
    success_url: "",
    error_url: "",
    cardsend: "",
    source: "",
  });

  useEffect(() => {
    onFinish();
  }, [selectIp]);
  const onFinish = async (el) => {
    try {
      setLoading(true);
      const payload = {
        account_trade_id: account.id,
        amount_thb: +amount,
        payment_key: payment_key,
      };
      const { data } = await postFormPayment(payload);

      if (data) {
        setLoading(true);

        await Promise.all([setPaymentData(data.data)]).then(() => {
          refPerfect.current.click();
        });
      }
    } catch (error) {
      message.error(error.response.data.error);
      onBackStep("error");
      console.log(error);
    }
  };

  return (
    <Spin spinning={loading}>
      <form id="paymentForm" method="post" action={paymentData.host}>
        ​
        {Object.keys(paymentData).map((e, i) => (
          <input type="hidden" name={e} value={Object.values(paymentData)[i]} />
        ))}
        {/* <input type="hidden" name="api_token" value={paymentData.api_token} />
        <input type="hidden" name="website_id" value={paymentData.website_id} />
        <input type="hidden" name="action" value={paymentData.action} />
        <input type="hidden" name="price" value={paymentData.price} />
        <input type="hidden" name="curr" value={paymentData.curr} />
        <input
          type="hidden"
          name="product_name"
          value={paymentData.product_name}
        />
        <input type="hidden" name="fullname" value={paymentData.fullname} />
        <input type="hidden" name="email" value={paymentData.email} />
        <input
          type="hidden"
          name="bill_street_1"
          value={paymentData.bill_street_1}
        />
        <input type="hidden" name="bill_city" value={paymentData.bill_city} />
        <input
          type="hidden"
          name="bill_country"
          value={paymentData.bill_country}
        />
        <input type="hidden" name="bill_zip" value={paymentData.bill_zip} />
        <input type="hidden" name="bill_phone" value={paymentData.bill_phone} />
        <input type="hidden" name="id_order" value={paymentData.id_order} />
        <input type="hidden" name="notify_url" value={paymentData.notify_url} />
        <input
          type="hidden"
          name="success_url"
          value={paymentData.success_url}
        />
        <input type="hidden" name="error_url" value={paymentData.error_url} />
        <input type="hidden" name="cardsend" value={paymentData.cardsend} />
        <input type="hidden" name="source" value={paymentData.source} /> */}
        <input
          ref={refPerfect}
          type="submit"
          name="Submit"
          value="Submit"
          hidden
        />
      </form>
    </Spin>
  );
};

export default FormPayment;
