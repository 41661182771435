import React from "react";
import { Col, Row, Button, Divider } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import RcQueueAnim from "rc-queue-anim";
import { StyledAccountMain, SubStyledAccountWallet } from "../../components";
import styled from "styled-components";
import { useSelector } from "react-redux";
const locale = require("react-redux-i18n").I18n;

const BoxMain = styled.div`
  border-radius: 20px;
  margin-top: 16px;
  width: 268px;
`;

const Popluar = styled.div`
  background: #1cb894;
  color: #fff;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  text-transform: uppercase;
  padding: 4px 12px 4px 12px;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
`;

export default function AccountType(props) {
  const account = useSelector((state) => state.account);
  const accountReal = account?.accountTypeList?.filter(
    (e) => e.active === 1 && e.type === "real"
  );
  return (
    <RcQueueAnim className="bg-white" style={{ borderRadius: 20 }}>
      <p className="text-header-account-wallet p-4">
        {locale.t("accounttype")}
      </p>
      <div className="row">
        <div className="col-12 col-md">
          <div
            style={{ padding: 24 }}
            className="row justify-content-md-center"
          >
            {account?.accountTypeList
              ?.filter((e) => e.active === 1 && e.type === "real")
              ?.map((e, i) => (
                <>
                  <BoxMain className="col-12 col-lg px-2" key={i}>
                    <div
                      className=" d-flex flex-column m-0 p-3 "
                      style={{
                        border: "1px solid #f2f2f2",
                        borderRadius: " 12px",
                        position: "relative",
                        background: i > 0 ? "#fff" : "#FFF",
                        height: "100%",
                      }}
                    >
                      {(i === 0 || i === 2) && (
                        <Popluar>{locale.t("funding.section_2.hot")}</Popluar>
                      )}
                      <div className="text-center w-100 fw-700 fs-20 mt-5">
                        {e.display}
                      </div>
                      <div className="text-center fs-10" style={{ height: 32 }}>
                        {" "}
                        {locale.t(`${e.description}`)}
                      </div>
                      <div className="mt-4">
                        <div className="text-lightgray fs-12">
                          {locale.t("deposit_step.second_step.minimun_deposit")}
                        </div>
                        <div className="fs-14 fw-700">
                          From{" "}
                          <span className="fs-16">${e?.minimum_deposit}</span>
                        </div>
                      </div>
                      <div className="mt-4">
                        <div className="text-lightgray fs-12">
                          {locale.t("account_detail.detail1")}
                        </div>
                        <div className="fs-14 fw-700">
                          From{" "}
                          <span className="fs-16">
                            {e?.minimum_spread} pips
                          </span>
                        </div>
                      </div>
                      <div className="mt-4">
                        <div className="text-lightgray fs-12">
                          {locale.t("account_detail.detail3")}
                        </div>
                        <div className="fs-14 fw-700">
                          <span className="fs-14">
                            ${e?.commission} per lot per side
                          </span>
                        </div>
                      </div>
                      <div className="mt-4">
                        <div className="text-lightgray fs-12">
                          {locale.t("account_detail.detail4")}
                        </div>
                        <div className="fs-14 fw-700">
                          <span className="fs-16">1:{e?.maximum_leverage}</span>
                        </div>
                      </div>
                      {e.name === "StandardBonus" && (
                        <div className="mt-4">
                          <div className="text-lightgray fs-12">
                            {locale.t("account_detail.detail7")}
                          </div>
                          <div className="fs-14 fw-700">
                            <span className="fs-16">35% and 25%</span>
                          </div>
                        </div>
                      )}
                      <div className="mt-4 d-flex h-100 align-items-end">
                        <Button
                          onClick={() =>
                            props.history.push(
                              `../wallet/account/openaccount?typeAccount=${
                                e.display === "Raw" || e.display === "Pro"
                                  ? "Professional"
                                  : "Recommended"
                              }`
                            )
                          }
                          className="w-100 btn-primary-new-iux"
                        >
                          {locale.t("open_an_account")}
                        </Button>
                      </div>
                    </div>
                  </BoxMain>
                </>
              ))}
          </div>
        </div>
      </div>
      <div className="mt-2 ">
        <SubStyledAccountWallet></SubStyledAccountWallet>
      </div>
    </RcQueueAnim>
  );
}
