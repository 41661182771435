import React, { Component } from "react";
import { Button } from "antd";
import {
  CheckOutlined,
  AndroidOutlined,
  AppleOutlined,
  WindowsOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import FooterBanner from "../../components/banner/FooterBanner";
import Banner from "../../components/banner/Banner";
import RcQueueAnim from "rc-queue-anim";
import { connect } from "react-redux";
import { setLocale } from "react-redux-i18n";
import { setLocation } from "../../actions";
import FooterLandingPage from "../../components/footer-landing-page/FooterDark";
import Globalmarket from "../../components/section-global/Globalmarket";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Row, Col, Divider } from "antd";
import { Link } from "react-router-dom";
import FooterTFN from "../../components/footertfn";
import { isIOS, isAndroid, isBrowser, browserName } from "react-device-detect";
import FooterContact from "../../components/footer-contact";
const TITLE = "IUX Markets";
const locale = require("react-redux-i18n").I18n;

const BoxGive = styled.div`
  border-radius: 8px;
`;

const BoxSection6 = styled.div`
  border-radius: 8px;
  padding: 8px 15px;
  text-align: center;
  display: flex;
  width: 330px;
`;

const FingertipsBox = styled.div`
  padding: 38px 45px;
  margin: auto;
  text-align: center;
`;

class MainPage extends Component {
  // state = { visible: false };
  // state = { checkbox: false };
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      checkbox: false,
    };
    const bannerList = [
      {
        imagePath:
          process.env.PUBLIC_URL + "/assets/images/covers/slide-page.png",
        title: locale.t("banner_title"),
        content: locale.t("banner_content"),
        buttonText: locale.t("banner_buttonText"),
        buttonText2: locale.t("banner_buttonText2"),
        buttonText3: locale.t("banner_buttonText_standardx"),
        leftTextBanner_1: locale.t("leftTextBanner_1"),
        rightTextBanner_1: locale.t("rightTextBanner_1"),
        leftTextBanner_2: locale.t("leftTextBanner_2"),
        rightTextBanner_2: locale.t("rightTextBanner_2"),
        leftTextBanner_3: locale.t("leftTextBanner_3"),
        rightTextBanner_3: locale.t("rightTextBanner_3"),
      },
    ];

    const tutorials = [
      {
        imagePath: process.env.PUBLIC_URL + "/assets/images/step1.png",
        number: 1,
        title: locale.t("tutorials_title_section_1"),
        description: "วิธีใช้งาน IUXmarket",
      },
      {
        imagePath: process.env.PUBLIC_URL + "/assets/images/step2.png",
        number: 2,
        title: locale.t("tutorials_title_section_2"),
        description:
          "เลือกประเภทบัญชีเทรดที่ท่านต้องการโดยจะมีบัญชี Standard และ ECN",
      },
      {
        imagePath: process.env.PUBLIC_URL + "/assets/images/step3.png",
        number: 3,
        title: locale.t("tutorials_title_section_3"),
        description:
          "ติดตั้งลงบน Smartphone หรือบน PC จากนั้นทำการล็อกอินรหัส ที่ท่านได้รับจากการเปิดบัญชีเทรด",
      },
      {
        imagePath: process.env.PUBLIC_URL + "/assets/images/step4.png",
        number: 4,
        title: locale.t("tutorials_title_section_4"),
        description:
          "ด้วยความเร็ว และเทคโนโลยีการซื้อ-ขายที่ทรงประสิทธิภาพที่สุดของเรา",
      },
    ];

    const tutorialsteam = [
      {
        icon: process.env.PUBLIC_URL + "/assets/images/person/Mask Group 2.png",
        title: locale.t("tutorialsteam_section_1"),
        // description: "Wyatt Donald"
      },
      {
        icon: process.env.PUBLIC_URL + "/assets/images/person/Mask Group 6.png",
        title: locale.t("tutorialsteam_section_2"),
        // description: "Sana Markham"
      },
      {
        icon: process.env.PUBLIC_URL + "/assets/images/person/Mask Group 7.png",
        title: locale.t("tutorialsteam_section_3"),
        // description: "Amiyah Knapp"
      },
    ];

    const textCoverList = [
      {
        videoPath: process.env.PUBLIC_URL + "/assets/vdo/iuxmarket.pm4",
        title: locale.t("cover_title_section_1"),
        content: locale.t("cover_content_section_1"),
      },
      {
        imagePath:
          process.env.PUBLIC_URL + "/assets/images/covers/covernew2.png",
        title: locale.t("cover_title_section_2"),
        content: locale.t("cover_content_section_2"),
      },
      {
        imagePath: process.env.PUBLIC_URL + "/assets/images/covers/cover5.png",
        title: locale.t("cover_title_section_3"),
        content: locale.t("cover_content_section_3"),
      },
    ];

    const whyChooses = [
      {
        icon: process.env.PUBLIC_URL + "/assets/images/icons/iux-safe.svg",
        title: locale.t("why_iux_box_title_1"),
        description: locale.t("why_iux_box_description_1"),
      },
      {
        icon: process.env.PUBLIC_URL + "/assets/images/icons/iux-cash.svg",
        title: locale.t("why_iux_box_title_2"),
        description: locale.t("why_iux_box_description_2"),
      },
      {
        icon: process.env.PUBLIC_URL + "/assets/images/icons/iux-fast.svg",
        title: locale.t("why_iux_box_title_3"),
        description: locale.t("why_iux_box_description_3"),
      },
      {
        icon: process.env.PUBLIC_URL + "/assets/images/icons/iux-coupon.svg",
        title: locale.t("why_iux_box_title_4"),
        description: locale.t("why_iux_box_description_4"),
      },
    ];

    const listGiving = [
      {
        icon: process.env.PUBLIC_URL + "/assets/images/icons/iux-platforms.svg",
        description: locale.t("section_4_description_1"),
        title: "",
      },
      {
        icon: process.env.PUBLIC_URL + "/assets/images/icons/iux-stack.svg",
        description: locale.t("section_4_description_2"),
        title: "",
      },
    ];

    this.state = {
      locale: this.props.locale,
      listGiving: listGiving,
      whyChooses: whyChooses,
      tutorials: tutorials,
      bannerList: bannerList,
      textCoverList: textCoverList,
      tutorialsteam: tutorialsteam,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.locale !== this.props.locale) {
      this.setState({ locale: nextProps.locale });
    }
  }
  scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  render() {
    const payment = [
      "mastercard",
      "visa",
      "online",
      "nett",
      "skrill",
      "paypal",
      "thaiqr",
    ];

    return (
      <RcQueueAnim>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <section key={1} id="banner" style={{ width: "100%" }}>
          <Banner
            bannerList={[
              {
                imagePath:
                  process.env.PUBLIC_URL +
                  "/assets/images/covers/slide-page.png",
                title: locale.t("banner_title"),
                content: locale.t("banner_content"),
                buttonText: locale.t("banner_buttonText"),
                buttonText2: locale.t("banner_buttonText2"),
                buttonText3: locale.t("banner_buttonText_standardx"),
                leftTextBanner_1: locale.t("leftTextBanner_1"),
                rightTextBanner_1: locale.t("rightTextBanner_1"),
                leftTextBanner_2: locale.t("leftTextBanner_2"),
                rightTextBanner_2: locale.t("rightTextBanner_2"),
                leftTextBanner_3: locale.t("leftTextBanner_3"),
                rightTextBanner_3: locale.t("rightTextBanner_3"),
              },
            ]}
          />
          <FooterBanner />
        </section>
        <section key={"spread0"} className="bg-black">
          <div className="flex-column-reverse flex-lg-row row paddig-responsive-landingpage container ml-auto mr-auto align-items-center justify-content-between">
            <div
              span={12}
              style={{ padding: "4rem 12px" }}
              className="col-12 col-lg-8 w-100"
            >
              <h2 className="text-center text-lg-left text-white fs-32 text-semibold">
                {locale.t("land_section_2_1")}
              </h2>
              <div className="text-left text-white mt-4 fs-16 d-flex">
                <div>
                  <img src="/assets/images/icons/true.svg" alt="true" />
                </div>

                <div className="ml-3">{locale.t("land_section_2_1_1")}</div>
              </div>
              <div className="text-left text-white mt-2 fs-16 d-flex">
                <div>
                  <img src="/assets/images/icons/true.svg" alt="true" />
                </div>
                <div className="ml-3">{locale.t("land_section_2_1_2")}</div>
              </div>
              <div className="text-left text-white mt-2 fs-16 d-flex">
                <div>
                  <img src="/assets/images/icons/true.svg" alt="true" />
                </div>
                <div className="ml-3">{locale.t("land_section_2_1_3")}</div>
              </div>
            </div>
            <div className="d-none d-sm-flex d-lg-flex d-xl-flex col-lg-4  text-end w-100 justify-content-center">
              <img
                src="/assets/images/landing/img_spreads.svg"
                alt="img_spreads"
              />

              {/* <TextColorNewIux fontSize="52px">Spread </TextColorNewIux>
              <TextColorNewIux fontSize="162px">0.0</TextColorNewIux> */}
            </div>
          </div>
          {/* </div> */}
        </section>
        <section key={"lowspread"} className="bg-black">
          {/* <div className="align-items-center container d-flex justify-content-between paddig-responsive-landingpage"> */}
          <div className="row paddig-responsive-landingpage container mr-auto ml-auto align-items-center justify-content-between">
            <div className="d-none d-sm-flex d-lg-flex d-xl-flex col-12 col-lg-4 text-center justify-content-center ">
              <img src="/assets/images/landing/img_low.svg" alt="img_low" />
            </div>
            <div
              style={{ padding: "4rem 12px" }}
              className="w-100 col-12 col-lg-6"
            >
              <h2 className="text-center text-lg-left text-white text-semibold">
                {locale.t("land_section_3_1")} {locale.t("land_section_3_2")}
              </h2>
              <div className="text-left text-white fs-16  mt-2 d-flex">
                <div>
                  <img src="/assets/images/icons/true.svg" alt="true" />
                </div>
                <div className="ml-3"> {locale.t("land_section_3_1_1")}</div>
              </div>
              <div className="text-left text-white fs-16  mt-2 d-flex">
                <div>
                  {" "}
                  <img src="/assets/images/icons/true.svg" alt="true" />
                </div>
                <div className="ml-3"> {locale.t("land_section_3_1_2")}</div>
              </div>
              <div className="text-left text-white fs-16  mt-2 d-flex">
                <div>
                  {" "}
                  <img src="/assets/images/icons/true.svg" alt="true" />
                </div>
                <div className="ml-3"> {locale.t("land_section_3_1_3")}</div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </section>
        <section key={"fastorder"} className="pb-5 bg-black">
          <div className="row paddig-responsive-landingpage container ml-auto mr-auto align-items-center justify-content-between">
            <div className="col-12 col-lg-6" style={{ padding: "4rem 12px" }}>
              <h2 className="text-center text-sm-left text-white text-semibold">
                {locale.t("land_section_4_1")} {locale.t("land_section_4_2")}
              </h2>
              <div className="text-white fs-16 mt-2 d-flex">
                <div>
                  {" "}
                  <img src="/assets/images/icons/true.svg" alt="true" />
                </div>
                <div className="ml-3"> {locale.t("land_section_4_1_1")}</div>
              </div>
              <div className="text-white fs-16 mt-2 d-flex">
                <div>
                  {" "}
                  <img src="/assets/images/icons/true.svg" alt="true" />
                </div>
                <div className="ml-3"> {locale.t("land_section_4_1_2")}</div>
              </div>
              <div className="text-white fs-16 mt-2 d-flex">
                <div>
                  {" "}
                  <img src="/assets/images/icons/true.svg" alt="true" />
                </div>
                <div className="ml-3"> {locale.t("land_section_4_1_3")}</div>
              </div>
            </div>
            <div
              className="d-none d-sm-block d-lg-block d-xl-block col-12 col-lg-6"
              style={{ letterSpacing: ".1rem" }}
            >
              <div
                className="text-primary-new-iux font-weight-bold font-italic text-lg-right text-center "
                style={{ fontSize: "6rem" }}
              >
                ORDER
              </div>
              <div
                className="text-primary-new-iux font-weight-bold font-italic text-center text-lg-right"
                style={{ fontSize: "10rem", marginTop: "-5rem" }}
              >
                30ms
              </div>
              {/* <img src="/assets/images/landing/img_order.svg" alt="img_order" /> */}
              {/* <TextColorNewIux fontSize="84px">Order</TextColorNewIux>
              <TextColorNewIux fontSize="112px">40/min</TextColorNewIux> */}
            </div>
          </div>
        </section>
        <section key={"lowspreadADVANTAGE"} className="bg-black">
          <div style={{ backgroundColor: "rgb(0 206 255 / 5%)" }}>
            <div className="container paddig-responsive-landingpage ">
              <div className="mr-auto ml-auto container-landing-title">
                <h2
                  className="text-semibold text-white text-center text-uppercase"
                  style={{ whiteSpace: "break-spaces" }}
                >
                  {locale.t("land_section_1_1")}
                </h2>
                <p className="mt-3  text-center text-white  fs-20">
                  {locale.t("land_section_5_2")}
                </p>
              </div>
              <div className="row mt-4">
                <div className="col-md-4 mt-2 mt-md-2 mt-lg-0 mt-xl-0">
                  <BoxGive className="text-center">
                    <div className="p-3">
                      <div className="text-gray fs-12">
                        {locale.t("land_section_5_1_1")}
                      </div>
                      <h2 className="text-primary-new-iux text-bold fs-32 font-italic">
                        {locale.t("land_section_5_1_2")}
                      </h2>
                    </div>
                  </BoxGive>
                </div>
                <div className="col-md-4 mt-2 mt-md-2 mt-lg-0 mt-xl-0">
                  <BoxGive className="text-center">
                    <div className="p-3">
                      <div className="text-gray fs-12">
                        {locale.t("land_section_5_1_3")}
                      </div>
                      <h2 className="text-primary-new-iux text-bold fs-32 font-italic">
                        500,000
                      </h2>
                    </div>
                  </BoxGive>
                </div>
                <div className="col-md-4 mt-2 mt-md-2 mt-lg-0 mt-xl-0">
                  <BoxGive className="text-center">
                    <div className="p-3">
                      <div className="text-gray fs-12">
                        {locale.t("land_section_5_1_5")}
                      </div>
                      <h2 className="text-primary-new-iux text-bold fs-32 font-italic">
                        60%
                      </h2>
                    </div>
                  </BoxGive>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Globalmarket />

        <section key={"globalmarket"} className="bg-black">
          <div style={{ backgroundColor: "rgb(0 206 255 / 5%)" }}>
            <div className="container paddig-responsive-landingpage">
              <div className="d-sm-block d-lg-block d-md-block d-xl-block mx-auto container-landing-title">
                <h2 className="text-center text-white text-semibold text-uppercase">
                  {locale.t("land_section_8_1")}
                </h2>
                <div className="text-white fs-20 text-center">
                  {locale.t("land_section_8_2")}
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-4 mt-2 mt-md-2 mt-lg-0 mt-xl-0">
                  <BoxGive className="text-center">
                    <div className="p-3">
                      <div className="text-gray fs-16">
                        {locale.t("land_section_8_1_1")}
                      </div>
                      <div className="text-primary-new-iux fs-32 font-weight-bold font-italic">
                        {locale.t("land_section_8_1_2")}
                      </div>
                    </div>
                  </BoxGive>
                </div>
                <div className="col-md-4 mt-2 mt-md-2 mt-lg-0 mt-xl-0">
                  <BoxGive className="text-center">
                    <div className="p-3">
                      <div className="text-gray fs-16">
                        {locale.t("land_section_8_1_3")}
                      </div>
                      <div className="text-primary-new-iux fs-32 font-weight-bold font-italic">
                        {locale.t("land_section_8_1_4")}
                      </div>
                    </div>
                  </BoxGive>
                </div>
                <div className="col-md-4 mt-2 mt-md-2 mt-lg-0 mt-xl-0">
                  <BoxGive className="text-center fs-16">
                    <div className="p-3">
                      <div className="text-gray">
                        {locale.t("land_section_8_1_5")}
                      </div>
                      <div className="text-primary-new-iux fs-32 font-weight-bold font-italic">
                        {locale.t("land_section_8_1_6")}
                      </div>
                    </div>
                  </BoxGive>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          key={"OURHEART"}
          className=""
          style={{
            background: "#000",
            backgroundImage: `url('/assets/images/covers/bg-app.png')`,
            backgroundSize: "cover",
          }}
        >
          <div className="container-landing-title mr-auto ml-auto pt-5">
            <h2 className="text-center text-white text-semibold text-uppercase">
              {locale.t("land_section_6_1")}
            </h2>
            <div className="text-white fs-16 text-center pr-5 pl-5">
              {locale.t("land_section_6_2")}
            </div>
          </div>
          <Row
            className=" paddig-responsive-landingpage container mr-auto ml-auto align-items-center justify-content-center"
            gutter={16}
          >
            <Col xs={24} md={12} className="d-flex justify-center w-100 h-100 ">
              <img
                alt="main-com-mobile-new"
                className=""
                src={"/assets/images/covers/main-com-mobile-new.png"}
                width={"100%"}
                height={"100%"}
              />
            </Col>
            <Col xs={24} md={12} className="d-flex justify-center ">
              <div className="" style={{ padding: "0 27px" }}>
                <BoxSection6 className="align-items-center">
                  <div className="ml-2 text-white fs-16">
                    <img src="/assets/images/icons/true.svg" alt="true" />
                    &nbsp; {locale.t("land_section_6_1_1")}
                  </div>
                </BoxSection6>
                <BoxSection6 className="align-items-center mt-1">
                  <div className="ml-2 text-white fs-16">
                    <img src="/assets/images/icons/true.svg" alt="true" />
                    &nbsp; {locale.t("land_section_6_1_2")}
                  </div>
                </BoxSection6>
                <BoxSection6 className="align-items-center mt-1">
                  <div className="ml-2 text-white fs-16">
                    <img src="/assets/images/icons/true.svg" alt="true" />
                    &nbsp; {locale.t("land_section_6_1_3")}
                  </div>
                </BoxSection6>
                <BoxSection6 className="align-items-center mt-1">
                  <div className="ml-2 text-white fs-16">
                    <img src="/assets/images/icons/true.svg" alt="true" />
                    &nbsp; {locale.t("land_section_6_1_4")}
                  </div>
                </BoxSection6>
                <div className="text-white fs-16 text-center pr-2 pl-2 mt-4">
                  {(isIOS || isBrowser) && (
                    <a
                      href="https://apps.apple.com/th/app/iux-trader/id6444305135"
                      target={"_blank"}
                      className="text-primary-new-iux cursor-pointer"
                    >
                      <img
                        src="/assets/images/mobileapp/download-on-the-app-store.svg"
                        alt="ios"
                      />
                    </a>
                  )}
                  {(isAndroid ||
                    (browserName !== "Safari" &&
                      browserName !== "Mobile Safari")) && (
                    <a
                      href="https://play.google.com/store/apps/details?id=com.iuxmarket.app"
                      target={"_blank"}
                      className="text-primary-new-iux cursor-pointer"
                    >
                      <img
                        src="/assets/images/mobileapp/google-play-badge.png"
                        alt="android"
                        height={60}
                      />
                    </a>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </section>
        <section key={"tradeonmobile"} className="bg-black">
          <div style={{ backgroundColor: "rgb(0 206 255 / 5%)" }}>
            <div className="container ">
              <div style={{ padding: "3rem 12px" }}>
                <h2 className="text-center text-white font-weight-bold">
                  {locale.t("std.box_1.titile_4")}
                </h2>
                <div
                  className="d-flex justify-content-between flex-column flex-sm-row w-100"
                  style={{ marginTop: 87 }}
                >
                  <div className="text-left mb-2 text-white fs-16">
                    <img src="/assets/images/icons/true.svg" alt="true" />
                    &nbsp; {locale.t("land_section_10_1_1")}
                  </div>
                  <div className="text-left mb-2 text-white fs-16">
                    <img src="/assets/images/icons/true.svg" alt="true" />
                    &nbsp; {locale.t("land_section_10_1_2")}
                  </div>
                  <div className="text-left mb-2 text-white fs-16">
                    <img src="/assets/images/icons/true.svg" alt="true" />
                    &nbsp; {locale.t("land_section_10_1_3")}
                  </div>
                </div>
                <div className="custom-border-divider">
                  <Divider
                    plain
                    className="text-gray"
                    style={{ color: "#8D8D8D", fontSize: 12 }}
                  >
                    {locale.t("depositPage.SectionTitle2")}
                  </Divider>
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="payment"
                    className="mt-5"
                    style={{ width: "100%", height: "auto" }}
                    src={`/assets/images/landing/paymentmethod.svg`}
                  />{" "}
                </div>{" "}
                <div className="d-flex justify-content-center mt-5 ">
                  <Link
                    to={`/${localStorage.getItem("locale")}/payment-option#`}
                    onClick={() => this.scrollToTop()}
                  >
                    <Button
                      className="btn-outline-iux text-uppercase"
                      style={{ width: 280 }}
                    >
                      See our payment
                    </Button>
                  </Link>
                </div>
                {/* <div></div> */}
              </div>
            </div>
          </div>
        </section>
        <section key={"mt5"} className="bg-black">
          <Row
            className="d-sm-block container d-md-block d-lg-flex d-xl-flex justify-center"
            style={{ padding: "4rem 12px" }}
          >
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Row gutter={16}>
                <Col xs={{ span: 24 }} md={{ span: 6 }}>
                  <div>
                    <img
                      alt="IUX"
                      className=""
                      src="/assets/images/covers/mt5-logo.svg"
                      width="100%"
                      height={71}
                    />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 18 }} className="">
                  <h2 className=" text-white font-weight-bold">
                    MetaTrader&nbsp;&nbsp;
                    <span style={{ fontSize: 46, color: "#FF7C25" }}>5</span>
                  </h2>
                </Col>
                <Col xs={{ span: 24 }}>
                  <div className="text-white ml-0 mt-0  mt-md-4 fs-20">
                    Powerful trading terminals
                  </div>
                  <div className="text-white ml-0 mt-0 mt-md-4">
                    {locale.t("land_section_11_5")} <br />
                    MetaTrader is the best choice for the modern trader.
                  </div>
                  <div className="mt-5">
                    <div className="text-white ml-0 mb-0  mb-md-4">
                      Download MetaTrader on the{" "}
                    </div>
                    <div>
                      <Link
                        to={`/${localStorage.getItem("locale")}/platforms/mt5#`}
                        onClick={() => this.scrollToTop()}
                      >
                        <img
                          alt="IUX"
                          src="/assets/images/covers/download-mt5.svg"
                          width="100%"
                          height={25}
                          className="mb-2 mb-sm-4 mb-lg-0"
                        />
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <img
                alt="IUX"
                className=""
                src={"/assets/images/landing/metatrader-5.png"}
                width="100%"
                height="100%"
              />
            </Col>
          </Row>
        </section>
        {/* <FooterContact /> */}
        <FooterTFN />

        <section>
          <FooterLandingPage locale={locale} />
        </section>
      </RcQueueAnim>
    );
  }
}

const mapStateToProps = ({ i18n }) => ({
  locale: i18n.locale,
});

export default connect(mapStateToProps, { setLocale, setLocation })(MainPage);
