export const renderKeyMicroblink = () => {
  let isLink = typeof window !== "undefined" && window.location.origin;

  switch (isLink) {
    case "https://portal.iuxvn.com":
      return process.env.REACT_APP_MICROBLINK_VN;
    case "https://portal.id-iux.com":
      return process.env.REACT_APP_MICROBLINK_ID;
    case "https://portal.iuxvn-trading.com":
      return process.env.REACT_APP_MICROBLINK_VN_TRADING;
    default:
      return process.env.REACT_APP_MICROBLINK;
  }
};
