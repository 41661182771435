export default {
  th: {
    step1tutorial: {
      title: "การเลือกประเภทเอกสารและการป้อนข้อมูล",
      description: "เลือกประเภทเอกสารและป้อนหมายเลขเอกสาร",
    },
    step2tutorial: {
      title: "สแกนหรืออัปโหลดเอกสารของคุณ",
      description:
        "สแกนหรืออัปโหลดการ์ดที่คุณเลือก หากสำเร็จแล้วจะแสดงหน้าการยืนยัน",
      manual: {
        title: "อัปโหลดการ์ดของคุณ",
        description: "อัปโหลดการ์ดที่คุณเลือก หากสำเร็จแล้วจะแสดงหน้าการยืนยัน",
      },
    },
    step3tutorial: {
      title: "ข้อมูลส่วนตัว",
      description:
        "กรอกข้อมูลส่วนบุคคลของคุณและหลังจากนั้นกระบวนการจะเสร็จสมบูรณ์",
    },
    step4tutorial: {
      title: "การตรวจสอบไม่สำเร็จ",
      description:
        "ในกรณีที่การตรวจสอบไม่สำเร็จให้เลือก <b> การตรวจสอบอัตโนมัติ </b> สำหรับการ rescanning หรือ <b> การตรวจสอบด้วยตนเอง </b> เพื่อป้อนรายละเอียดสำหรับการตรวจสอบผู้ดูแลระบบ",
    },
    nextTutorial: "ต่อไป",
    understoodTutorial: "ฉันเข้าใจแล้ว",
  },
  lo: {
    step1tutorial: {
      title: "ການຄັດເລືອກແລະການຕື່ມຂໍ້ມູນ",
      description: "ເລືອກປະເພດເອກະສານແລະປ້ອນເລກເອກະສານ.",
    },
    step2tutorial: {
      title: "ສະແກນຫຼືອັບໂຫລດບັດຂອງທ່ານ",
      description:
        "ສະແກນຫຼືອັບໂຫລດບັດທີ່ທ່ານເລືອກຂອງທ່ານ. ຖ້າປະສົບຜົນສໍາເລັດ, ຫນ້າຢືນຢັນຈະຖືກສະແດງ.",
      manual: {
        title: "ອັບໂຫລດບັດຂອງທ່ານ",
        description:
          "ອັບໂຫລດບັດທີ່ທ່ານເລືອກຂອງທ່ານ. ຖ້າປະສົບຜົນສໍາເລັດ, ຫນ້າຢືນຢັນຈະຖືກສະແດງ.",
      },
    },
    step3tutorial: {
      title: "ຂໍ້ມູນສ່ວນຕົວ",
      description:
        "ຕື່ມຂໍ້ມູນໃສ່ໃນຂໍ້ມູນສ່ວນຕົວຂອງທ່ານ, ແລະຫຼັງຈາກນັ້ນ, ຂະບວນການຈະສໍາເລັດ.",
    },
    step4tutorial: {
      title: "ການຢັ້ງຢືນທີ່ບໍ່ປະສົບຜົນສໍາເລັດ",
      description:
        "ໃນກໍລະນີຂອງການຢັ້ງຢືນທີ່ບໍ່ປະສົບຜົນສໍາເລັດ, ເລືອກ </ b> ການກວດສອບໂດຍອັດຕະໂນມັດຫຼື </ b> ເຂົ້າໃນລາຍລະອຽດຂອງ admin ສໍາລັບການທົບທວນ admin.",
    },
    nextTutorial: "ຕໍ່ໄປ",
    understoodTutorial: "ຂ້ອຍເຂົ້າໃຈ",
  },
  en: {
    step1tutorial: {
      title: "Selection and Filling",
      description: "Choose the document type and input the document number.",
    },
    step2tutorial: {
      title: "Scan or Upload Your Card",
      description:
        "Scan or upload your selected card. If successful completion, a confirmation page will be displayed.",
      manual: {
        title: "Upload Your Card",
        description:
          "Upload your selected card. If successful completion, a confirmation page will be displayed.",
      },
    },
    step3tutorial: {
      title: "Personal Info",
      description:
        "Fill in your personal information, and after that, the process will be completed.",
    },
    step4tutorial: {
      title: "Unsuccessful Verification",
      description:
        "In case of unsuccessful verification, select <b>Automatic Verification</b> for rescanning or <b>Manual Verification</b> to input details for admin review.",
    },
    nextTutorial: "Next",
    understoodTutorial: "I understood",
  },
  hi: {
    step1tutorial: {
      title: "चयन और भरने",
      description: "दस्तावेज़ प्रकार चुनें और दस्तावेज़ संख्या को इनपुट करें।",
    },
    step2tutorial: {
      title: "अपना कार्ड स्कैन या अपलोड करें",
      description:
        "अपने चयनित कार्ड को स्कैन या अपलोड करें। यदि सफल समापन होता है, तो एक पुष्टिकरण पृष्ठ प्रदर्शित किया जाएगा।",
      manual: {
        title: "अपना कार्ड अपलोड करें",
        description:
          "अपना चयनित कार्ड अपलोड करें। यदि सफल समापन होता है, तो एक पुष्टिकरण पृष्ठ प्रदर्शित किया जाएगा।",
      },
    },
    step3tutorial: {
      title: "व्यक्तिगत जानकारी",
      description:
        "अपनी व्यक्तिगत जानकारी भरें, और उसके बाद, प्रक्रिया पूरी हो जाएगी।",
    },
    step4tutorial: {
      title: "असफल सत्यापन",
      description:
        "असफल सत्यापन के मामले में, व्यवस्थापक समीक्षा के लिए इनपुट विवरण के लिए <b> स्वचालित सत्यापन </b> rescanning या <b> मैनुअल सत्यापन </b> के लिए चुनें।",
    },
    nextTutorial: "अगला",
    understoodTutorial: "मै समझा",
  },
  ms: {
    step1tutorial: {
      title: "Pemilihan dan pengisian",
      description: "Pilih jenis dokumen dan masukkan nombor dokumen.",
    },
    step2tutorial: {
      title: "Mengimbas atau memuat naik kad anda",
      description:
        "Imbas atau muat naik kad yang anda pilih. Jika siap siap, halaman pengesahan akan dipaparkan.",
      manual: {
        title: "Muat naik kad anda",
        description:
          "Muat naik kad yang anda pilih. Jika siap siap, halaman pengesahan akan dipaparkan.",
      },
    },
    step3tutorial: {
      title: "Maklumat peribadi",
      description:
        "Isi maklumat peribadi anda, dan selepas itu, proses akan selesai.",
    },
    step4tutorial: {
      title: "Pengesahan yang tidak berjaya",
      description:
        "Dalam kes pengesahan yang tidak berjaya, pilih <b> Pengesahan Automatik </b> untuk Rescanning atau <b> Pengesahan Manual </b> untuk input butiran untuk semakan admin.",
    },
    nextTutorial: "Seterusnya",
    understoodTutorial: "Saya memahami",
  },
  id: {
    step1tutorial: {
      title: "Pilihan dan pengisian",
      description: "Pilih jenis dokumen dan masukkan nomor dokumen.",
    },
    step2tutorial: {
      title: "Pindai atau unggah kartu Anda",
      description:
        "Pindai atau unggah kartu yang Anda pilih. Jika berhasil selesai, halaman konfirmasi akan ditampilkan.",
      manual: {
        title: "Unggah kartu Anda",
        description:
          "Unggah kartu yang Anda pilih. Jika berhasil selesai, halaman konfirmasi akan ditampilkan.",
      },
    },
    step3tutorial: {
      title: "Informasi pribadi",
      description:
        "Isi informasi pribadi Anda, dan setelah itu, prosesnya akan selesai.",
    },
    step4tutorial: {
      title: "Verifikasi yang tidak berhasil",
      description:
        "Dalam kes pengesahan yang tidak berjaya, pilih <b> Pengesahan Automatik </b> untuk Rescanning atau <b> Pengesahan Manual </b> untuk input butiran untuk semakan admin.",
    },
    nextTutorial: "Berikutnya",
    understoodTutorial: "saya mengerti",
  },
  zh: {
    step1tutorial: {
      title: "选择和填充",
      description: "选择文档类型并输入文档编号。",
    },
    step2tutorial: {
      title: "扫描或上传您的卡",
      description: "扫描或上传您所选的卡。 如果成功完成，将显示一个确认页面。",
      manual: {
        title: "上传您的卡",
        description: "上传您所选的卡。 如果成功完成，将显示一个确认页面。",
      },
    },
    step3tutorial: {
      title: "个人信息",
      description: "填写您的个人信息，此后，该过程将完成。",
    },
    step4tutorial: {
      title: "验证失败",
      description:
        "如果没有成功验证，请选择<b>自动验证</b>进行撤销或手动验证</b>以输入详细信息以供管理员审核。",
    },
    nextTutorial: "下一个",
    understoodTutorial: "我明白",
  },
  vi: {
    step1tutorial: {
      title: "Lựa chọn và điền vào",
      description: "Chọn loại tài liệu và nhập số tài liệu.",
    },
    step2tutorial: {
      title: "Quét hoặc tải lên thẻ của bạn",
      description:
        "Quét hoặc tải lên thẻ đã chọn của bạn. Nếu hoàn thành thành công, một trang xác nhận sẽ được hiển thị.",
      manual: {
        title: "Tải lên thẻ của bạn",
        description:
          "Tải lên thẻ đã chọn của bạn. Nếu hoàn thành thành công, một trang xác nhận sẽ được hiển thị.",
      },
    },
    step3tutorial: {
      title: "Thông tin cá nhân",
      description:
        "Điền vào thông tin cá nhân của bạn, và sau đó, quá trình sẽ được hoàn thành.",
    },
    step4tutorial: {
      title: "Xác minh không thành công",
      description:
        "Trong trường hợp xác minh không thành công, hãy chọn <b> Xác minh tự động </b> để xác minh lại hoặc <b> Xác minh thủ công </b> để đầu vào chi tiết để xem xét quản trị viên.",
    },
    nextTutorial: "Kế tiếp",
    understoodTutorial: "Tôi hiểu",
  },
  ko: {
    step1tutorial: {
      title: "선택 및 충전",
      description: "문서 유형을 선택하고 문서 번호를 입력하십시오.",
    },
    step2tutorial: {
      title: "카드를 스캔하거나 업로드하십시오",
      description:
        "선택한 카드를 스캔하거나 업로드하십시오. 성공적인 완료가 있으면 확인 페이지가 표시됩니다.",
      manual: {
        title: "카드를 업로드하십시오",
        description:
          "선택한 카드를 업로드하십시오. 성공적인 완료가 있으면 확인 페이지가 표시됩니다.",
      },
    },
    step3tutorial: {
      title: "개인적인 정보",
      description: "개인 정보를 작성하면 프로세스가 완료됩니다.",
    },
    step4tutorial: {
      title: "실패한 검증",
      description:
        "검증이 실패한 경우, <b> 자동 검증 </b> 재조정 또는 <b> 수동 검증 </b>를 선택하여 관리자 검토에 대한 세부 정보를 입력하십시오.",
    },
    nextTutorial: "다음",
    understoodTutorial: "나는 이해했다",
  },
  fil: {
    step1tutorial: {
      title: "Pagpili at pagpuno",
      description:
        "Piliin ang uri ng dokumento at i -input ang numero ng dokumento.",
    },
    step2tutorial: {
      title: "I -scan o i -upload ang iyong card",
      description:
        "I -scan o i -upload ang iyong napiling card. Kung matagumpay na pagkumpleto, ipapakita ang isang pahina ng kumpirmasyon.",
      manual: {
        title: "I -upload ang iyong card",
        description:
          "I -upload ang iyong napiling card. Kung matagumpay na pagkumpleto, ipapakita ang isang pahina ng kumpirmasyon.",
      },
    },
    step3tutorial: {
      title: "Personal na impormasyon",
      description:
        "Punan ang iyong personal na impormasyon, at pagkatapos nito, makumpleto ang proseso.",
    },
    step4tutorial: {
      title: "Hindi matagumpay na pag -verify",
      description:
        "Sa kaso ng hindi matagumpay na pag -verify, piliin ang <b> Awtomatikong pag -verify </b> para sa pag -rescan o <b> manu -manong pagpapatunay </b> upang mag -input ng mga detalye para sa pagsusuri ng admin.",
    },
    nextTutorial: "Susunod",
    understoodTutorial: "Naintindihan ko",
  },
  ja: {
    step1tutorial: {
      title: "選択と充填",
      description: "ドキュメントタイプを選択し、ドキュメント番号を入力します。",
    },
    step2tutorial: {
      title: "カードをスキャンまたはアップロードします",
      description:
        "選択したカードをスキャンまたはアップロードします。 正常に完了した場合、確認ページが表示されます。",
      manual: {
        title: "カードをアップロードします",
        description:
          "選択したカードをアップロードします。 正常に完了した場合、確認ページが表示されます。",
      },
    },
    step3tutorial: {
      title: "個人情報",
      description: "あなたの個人情報を入力し、その後、プロセスが完了します。",
    },
    step4tutorial: {
      title: "検証に失敗しました",
      description:
        "検証に失敗した場合は、<b>自動検証</b> </b> </b> </b> </b>は、管理レビューの詳細を入力するために<b>操作検証</b>を選択します。",
    },
    nextTutorial: "次",
    understoodTutorial: "私は理解しました",
  },
  es: {
    step1tutorial: {
      title: "Selección y Relleno",
      description:
        "Elija el tipo de documento e ingrese el número del documento.",
    },
    step2tutorial: {
      title: "Escanea o Sube Tu Tarjeta",
      description:
        "Escanea o sube tu tarjeta seleccionada. Si se completa con éxito, se mostrará una página de confirmación.",
      manual: {
        title: "Sube Tu Tarjeta",
        description:
          "Sube tu tarjeta seleccionada. Si se completa con éxito, se mostrará una página de confirmación.",
      },
    },
    step3tutorial: {
      title: "Información Personal",
      description:
        "Rellena tu información personal, y después de eso, el proceso se completará.",
    },
    step4tutorial: {
      title: "Verificación No Exitosa",
      description:
        "En caso de verificación no exitosa, selecciona <b>Verificación Automática</b> para volver a escanear o <b>Verificación Manual</b> para ingresar detalles para la revisión del administrador.",
    },
    nextTutorial: "Siguiente",
    understoodTutorial: "Entendido",
  },
};
