export default {
  th: {
    promo: {
      header: `เทรดด้วยสเปรดต่ำเริ่มที่ 0.0 PIPs`,
      title_1: `IUX MARKETS MT5`,
      title_2: `ค่าธรรมเนียมต่ำกว่า 10%`,
      title_3: `แพลตฟอร์มที่ทรงพลังสำหรับตลาด CFDs และ Exchange`,
      description_1: `MetaTrader 5 เป็นตัวเลือกที่ดีที่สุดสำหรับผู้ซื้อขายสมัยใหม่`,
      description_2: `การซื้อขายที่ประสบความสำเร็จเริ่มต้นด้วยการซื้อขายที่สะดวกและใช้งานได้จริง`,
      button: `ติดตั้งตอนนี้`,
      label: `ไม่ต้องแสดงหน้านี้อีก`,
      choose_partner: "เลือกพาร์ทเนอร์",
      label_partner: "พาร์ทเนอร์",
      select_partner: "เลือกพาร์ทเนอร์",
      noti_partner: {
        title:
          "ในวันที่ 15 กันยายน เราจะเปลี่ยนโมเดลพันธมิตร หากคุณไม่เลือกจนถึงวันที่ 15 กันยายน บัญชีของคุณจะอยู่ภายใต้พันธมิตร",
        title1: "โดยอัตโนมัติ",
        button: "เลือกพาร์ทเนอร์",
      },
    },
  },
  lo: {
    promo: {
      header: `ການຊື້ຂາຍທີ່ມີ Spreadຕໍ່າ ເລີ່ມຈາກ 0.0 PIPs`,
      title_1: `IUX MARKETS MT5`,
      title_2: `IUX MARKETS MT5 ຄ່າທຳນຽມຕ່ ຳກວ່າ 10%`,
      title_3: `Platform ທີ່ມີປະສິດທິພາບສຳລັບຕະຫຼາດ CFDs ແລະການແລກປ່ຽນ`,
      description_1: `ການຊື້ຂາຍທີ່ປະສົບຜົນສໍາເລັດເລີ່ມຕົ້ນດ້ວຍ ການຊື້ຂາຍສະດວກແລະເຮັດໄດ້`,
      description_2: `Metatrader 5 ເປັນທາງເລືອກທີ່ດີທີ່ສຸດສໍາລັບຜູ້ຊື້ຂາຍ ທີ່ທັນສະໄຫມ`,
      button: `ຕິດຕັ້ງດຽວນີ້`,
      label: `ຢ່າສະແດງໜ້ານີ້ອີກ`,
      choose_partner: "ເລືອກຄູ່ຮ່ວມງານ",
      label_partner: "ຄູ່ຮ່ວມງານ",
      select_partner: "ເລືອກຄູ່ຮ່ວມງານ",
      noti_partner: {
        title:
          "ໃນວັນທີ 1 ເດືອນກັນຍາ, ພວກເຮົາຈະປ່ຽນຮູບແບບພັນທະມິດ. ຖ້າທ່ານບໍ່ເຂົ້າຮ່ວມຈົນກ່ວາວັນທີ 1 ກັນຍາ, ບັນຊີຂອງທ່ານຈະຖືກວາງໄວ້ພາຍໃຕ້ຄູ່ຮ່ວມງານໂດຍອັດຕະໂນມັດ.",
        title1: "ອັດຕະໂນມັດ",
        button: "ເລືອກຄູ່ຮ່ວມງານ",
      },
    },
  },
  en: {
    promo: {
      header: `Low-spread trading begins from 0.0 PIPs`,
      title_1: `IUX MARKETS MT5`,
      title_2: `Fees below 10%`,
      title_3: `Powerful Platform for CFDs and Exchange Markets`,
      description_1: `MetaTrader 5 is the best choice for modern traders.`,
      description_2: `Successful trading starts with convenient and practical trading.`,
      button: `Install now`,
      label: `Do not show this page again.`,
      choose_partner: "Choose partner",
      label_partner: "Partner",
      select_partner: "Select Partner",
      noti_partner: {
        title:
          "On 15 September we will change model partner if you don’t choose till 15 September your account will be under partner",
        title1: "automatically",
        button: "Choose partner",
      },
    },
  },
  hi: {
    promo: {
      header: `Low-spread trading begins from 0.0 PIPs`,
      title_1: `IUX MARKETS MT5`,
      title_2: `Fees below 10%`,
      title_3: `Powerful Platform for CFDs and Exchange Markets`,
      description_1: `MetaTrader 5 is the best choice for modern traders.`,
      description_2: `Successful trading starts with convenient and practical trading.`,
      button: `Install now`,
      label: `Do not show this page again.`,
      choose_partner: "Choose partner",
      label_partner: "Partner",
      select_partner: "Select Partner",
      noti_partner: {
        title:
          "On 15 September we will change model partner if you don’t choose till 15 September your account will be under partner",
        title1: "automatically",
        button: "Choose partner",
      },
    },
  },
  ms: {
    promo: {
      header: `Berdagang dengan spread rendah mula dari 0.0 PIPs.`,
      title_1: `IUX MARKETS MT5`,
      title_2: `Yuran IUX MARKETS MT5 di bawah 10%`,
      title_3: `Platform yang berkuasa dalam pasaran CFDs dan Pertukaran`,
      description_1: `Perdagangan yang berjaya bermula dengan Perdagangan yang mudah dan praktikal`,
      description_2: `Metatrader 5 adalah pilihan terbaik untuk pedagang moden`,
      button: `Muat turun sekarang`,
      label: `Jangan paparkan halaman ini lagi`,
      choose_partner: "Choose partner",
      label_partner: "Partner",
      select_partner: "Select Partner",
      noti_partner: {
        title:
          "On 15 September we will change model partner if you don’t choose till 15 September your account will be under partner",
        title1: "automatically",
        button: "Choose partner",
      },
    },
  },
  id: {
    promo: {
      header: `Trading dengan spread rendah mulai dari 0,0 PIPs.`,
      title_1: `IUX MARKETS MT5`,
      title_2: `IUX MARKETS MT5 Biaya di bawah 10%`,
      title_3: ` Platform yang kuat untuk pasar Valas dan Pertukaran`,
      description_1: `Perdagangan yang sukses dimulai dengan Perdagangan yang nyaman dan praktis`,
      description_2: `Metatrader 5 adalah pilihan terbaik bagi pembeli penjualan modern`,
      button: `Instal sekarang`,
      label: `Jangan tampilkan halaman ini lagi`,
      choose_partner: "Choose partner",
      label_partner: "Partner",
      select_partner: "Select Partner",
      noti_partner: {
        title:
          "On 15 September we will change model partner if you don’t choose till 15 September your account will be under partner",
        title1: "automatically",
        button: "Choose partner",
      },
    },
  },
  zh: {
    promo: {
      header: `低點差從 0.0 點開始交易。`,
      title_1: `IUX MARKETS MT5`,
      title_2: `IUX MARKETS MT5 費用低於 10%`,
      title_3: `強大的市場平台 外彙和交易`,
      description_1: `成功的交易始於 方便實用的交易`,
      description_2: `Metatrader 5 是買家的最佳選擇。現代銷售`,
      button: `立即安裝`,
      label: `不再顯示此頁面。`,
      choose_partner: "Choose partner",
      label_partner: "Partner",
      select_partner: "Select Partner",
      noti_partner: {
        title:
          "On 15 September we will change model partner if you don’t choose till 15 September your account will be under partner",
        title1: "automatically",
        button: "Choose partner",
      },
    },
  },
  vi: {
    promo: {
      header: `Giao dịch với mức chênh lệch thấp bắt đầu từ 0,0 PIPs.`,
      title_1: `IUX MARKETS MT5`,
      title_2: `Phí dưới hơn 10% 10%`,
      title_3: `Nền tảng mạnh mẽ cho thị trường CFDs và Exchange`,
      description_1: `MetaTrader 5 là sự lựa chọn tốt nhất cho người giao dịch hiện đại`,
      description_2: `Việc giao dịch thành công bắt đầu với giao dịch thuận tiện và thiết thực`,
      button: `Cài đặt bây giờ`,
      label: `Không hiển thị lại trang này`,
      choose_partner: "Choose partner",
      label_partner: "Partner",
      select_partner: "Select Partner",
      noti_partner: {
        title:
          "On 15 September we will change model partner if you don’t choose till 15 September your account will be under partner",
        title1: "automatically",
        button: "Choose partner",
      },
    },
  },
  ko: {
    promo: {
      header: `0.0핍부터 낮은 스프레드로 거래하세요.`,
      title_1: `IUX MARKETS MT5`,
      title_2: `유스마켓 MT5 수수료 10% 미만`,
      title_3: `시장을 위한 강력한 플랫폼 외환 및 교환`,
      description_1: `성공적인 거래는 다음과 같이 시작됩니다. 편리하고 실용적인 거래`,
      description_2: `Metatrader 5는 구매자를 위한 최고의 선택입니다. 현대 판매`,
      button: `지금 설치`,
      label: `이 페이지를 다시 표시하지 마십시오.`,
      choose_partner: "Choose partner",
      label_partner: "Partner",
      select_partner: "Select Partner",
      noti_partner: {
        title:
          "On 15 September we will change model partner if you don’t choose till 15 September your account will be under partner",
        title1: "automatically",
        button: "Choose partner",
      },
    },
  },
  fil: {
    promo: {
      header: `Kalakal na may mababang pagkalat simula sa 0.0 PIPs.`,
      title_1: `IUX MARKETS MT5`,
      title_2: `IUX MARKETS MT5 Bayad sa ibaba 10%`,
      title_3: `Napakahusay na platform para sa merkado CFDs at Palitan`,
      description_1: `Ang matagumpay na pangangalakal ay nagsisimula sa Maginhawa at praktikal na kalakalan`,
      description_2: `AngMethereum 5 ay ang pinakamahusay na pagpipilian para sa mga mamimili modernong benta`,
      button: `I-install na ngayon`,
      label: `Huwag ipakita muli ang pahinang ito`,
      choose_partner: "Choose partner",
      label_partner: "Partner",
      select_partner: "Select Partner",
      noti_partner: {
        title:
          "On 15 September we will change model partner if you don’t choose till 15 September your account will be under partner",
        title1: "automatically",
        button: "Choose partner",
      },
    },
  },
  ja: {
    promo: {
      header: "低い取引は0.0ピップから始まります",
      title_1: "IUX Markets MT5",
      title_2: "10％未満の料金",
      title_3: "CFDおよび交換市場向けの強力なプラットフォーム",
      description_1: "Metatrader 5は、現代のトレーダーに最適です。",
      description_2: "取引の成功は、便利で実用的な取引から始まります。",
      button: "今すぐインストール",
      label: "二度とこのページを表示しないでください。",
      choose_partner: "パートナーを選択します",
      label_partner: "相棒",
      select_partner: "パートナーを選択します",
      noti_partner: {
        title: "9月15日までにモデルパートナーを変更します。",
        title1: "自動的に",
        button: "パートナーを選択します",
      },
    },
  },
};
