export default {
  th: {
    banner_title: "เทรดด้วยสเปรดต่ำเริ่มที่ \n0.0 PIPs",
    banner_content: `Spread ต่ำเป็นสิ่งสำคัญของการซื้อขาย CFDs เราเป็นผู้นำด้านเทคโนโลยี \nของการซื้อขายสเปรดต่ำ และเรามีนวัตกรรมการซื้อขายที่ดีที่สุด`,
    banner_buttonText: "เปิดบัญชีรับ $30",
    banner_buttonText_standardx: "เปิดบัญชี Standard",
    banner_buttonText2: "เทรดด้วยสเปรด 0.0 PIPs",
    banner_slide_text1: "สเปรดต่ำ",
    banner_slide_text2: "ราคาแม่นยำ",
    banner_slide_text3: "ผลิตภัณฑ์กว่า 250+ ตัว",
    banner_slide_text4: "24/7 Support",
    banner_slide_text5: "ประสบการณ์มากกว่า 7+ ปี",
    leftTextBanner_1: "รับฟรี",
    rightTextBanner_1: "$30",
    leftTextBanner_2: "โบนัสเงินฝาก",
    rightTextBanner_2: "35%",
    leftTextBanner_3: "โบนัสสูงสุด",
    rightTextBanner_3: "3,500$",
    section2_label_1: "โบนัสเงินฝาก",
    section2_label_2: "สเปรดน้อยที่สุด 0.0 PIPs",
    section2_label_3: "เลเวอเรจ",
    section2_label_4: "บัญชี",
    section2_label_5: "คอมมิชชั่น",
    //
    title_how_to_use: "วิธีใช้งาน IUX Markets",
    tutorials_title_section_1: "สมัครสมาชิกกับ IUX Markets.com",
    tutorials_title_section_2: "เปิดบัญชีเทรด",
    tutorials_title_section_3: "ติดตั้ง Meta Trader 4",
    tutorials_title_section_4: "เริ่มทำการซื้อขาย",
    tutorials_description_section_1: "วิธีใช้งาน IUX Markets",
    tutorials_description_section_2:
      "เลือกประเภทบัญชีเทรดที่ท่านต้องการโดยจะมีบัญชี Standard และ ECN",
    tutorials_description_section_3:
      "ติดตั้งลงบน Smartphone หรือบน PC จากนั้นทำการล็อกอินรหัส ที่ท่านได้รับจากการเปิดบัญชีเทรด",
    tutorials_description_section_4:
      "ด้วยความเร็ว และเทคโนโลยีการซื้อ-ขายที่ทรงประสิทธิภาพที่สุดของเรา",
    //
    cover_title_section_1: "เข้าถึงการเทรดที่มากที่สุด",
    cover_title_section_2: "สามารถเทรดที่ไหนก็ได้เพียงมือถือเครื่องเดียว",
    cover_title_section_3: "เข้าถึงการเทรดที่มากที่สุด",
    cover_content_section_1:
      "เมื่อคุณเริ่มทำการซื้อขายกับ IUX Markets นอกจากคุณจะไม่ต้องกังวลเรื่องความปลอดภัยแล้ว " +
      "คุณยังได้รับข้อเสนอที่พิเศษสุดๆ สำหรับสมาชิก IUX Markets (เริ่มซื้อขาย)",
    cover_content_section_button: "ชมวีดีโอแบบเต็ม ที่นี่",
    cover_content_section_2:
      "เมื่อคุณเริ่มทำการซื้อขายกับ IUX Markets นอกจากคุณจะไม่ต้องกังวลเรื่องความปลอดภัยแล้ว " +
      "คุณยังได้รับข้อเสนอที่พิเศษสุดๆ สำหรับสมาชิก IUX Markets (เริ่มซื้อขาย)",
    cover_content_section_3:
      "เปิดบัญชี ซื้อ-ขาย กับ  IUX Markets บนแพลตฟอร์ม MT4 จากนั้นทำการดาวน์โหลด Mt4 " +
      "แล้วทำการเทรดผ่านมือถือของท่าน โดยเลือกเทรดสินค้าที่คุณมีความมั่นใจและสามารถถอนได้ทันทีที่คุณพอใจในการซื้อขายแต่ละครั้ง สำคัญคือเงินเข้าทันที",
    why_iux_title: "ทำไมต้องเลือกใช้ IUX Markets",
    //
    why_iux_box_title_1: "ปลอดภัยมาตรฐานระดับโลก",
    why_iux_box_title_2: "การ ฝาก-ถอน ที่รวดเร็วที่สุด",
    why_iux_box_title_3: "การดำเนินคำสั่งที่รวดเร็วกว่า",
    why_iux_box_title_4: "รับเครดิตเงินฝาก สูงสุด 500$",

    why_iux_box_description_1:
      "เราเก็บรักษาข้อมูลทางการเงินของคุณไว้อย่างปลอดภัย ด้วยการเข้ารหัสและเฝ้าระวังการฉ้อโกงทุกวัน ตลอด 24 ชั่วโมง",
    why_iux_box_description_2:
      "เราตระหนักดีว่า การฝาก-ถอน ที่ง่ายและรวดเร็ว จะช่วยให้เทรดเดอร์ไม่พลาดโอกาสในการซื้อขายที่สำคัญต้อง ฝาก-ถอน ง่าย ไม่ซับซ้อน",
    why_iux_box_description_3:
      "ภารกิจของเราคือการให้บริการเทรดเดอร์ เพื่อให้ได้ราคาที่ดีที่สุด เราพยายามอย่างหนัก ในเทคโนโลยี เพื่อเพิ่มสภาพคล่องให้กับลูกค้า",
    why_iux_box_description_4:
      "เราร่วมมือกับธนาคารทั้งในประเทศและต่างประเทศ ให้คุณสามารถรับโบนัสเครดิต จากการฝากเงินเพื่อลดความเสี่ยงให้ได้มากที่สุด",
    //
    section_4_header: "สิ่งที่เรามีให้คุณ",
    section_4_description_1:
      "เทคโนโลยีการ Copy trade กับมาสเตอร์คุณภาพที่หลากหลายให้เลือกติดตามสัญญาณการเทรดด้วย Eiloy Platform ที่เป็นเทคโนโลยี Social Trade ร่วมเป็น partner กับเรา",
    section_4_description_2:
      "เครื่องมือการเทรดและแพลตฟอร์มที่พร้อมให้คุณ ซื้อ-ขายได้มั่นใจยิ่งขึ้น",
    section_4_more_detail: "ข้อมูลเพิ่มเติม",

    become_our: "ร่วมเป็นส่วนหนึ่งไปกับเรา",
    become_demo: "ทดลองบัญชี DEMO",
    become_title: "เริ่มเทรดเลย",
    open_account_now: "เปิดบัญชีตอนนี้",
    title_iux_team: "IUX Markets Team",

    //
    land_section_1_1: `ข้อดีของ สเปรดต่ำ`,
    land_section_1_2: `ข้อดีของ สเปรดต่ำ คือความแตกต่างที่คุณกำลังรอคอย การเทรดแบบ Low Spread ที่มีเสปรดเริ่มตั้งแต่ 0.0 PIPs*, ไม่มีการรีโควต, ไม่มีการปั่นราคา และข้อจำกัดต่าง ๆ ทำให้ IUX Markets เป็นผู้ให้บริการ CFDs ออนไลน์ที่ดีที่สุดสำหรับเทรดเดอร์ที่มีปริมาณการซื้อขายสูง, นักเก็งกำไรระยะสั้น และโรบอท`,
    //
    land_section_2_1: `ตราสารอนุพันธ์ของทั้งโลก`,
    land_section_2_1_1: `สเปรดเฉลี่ยของ EURUSD ที่ EURUSD เท่ากับ 0.0 PIPs ซึ่งดีที่สุดในโลก`,
    land_section_2_1_2: `สเปรดต่ำแบบไม่มี ‘ค่าธรรมเนียมแอบแฝง’ หมายความว่าเราสามารถไปถึง 0.0 PIPs ได้จริงๆ`,
    land_section_2_1_3: `สภาพคล่องที่หลากหลายของเราช่วยให้สเปรดแคบได้ตลอด 24/7`,
    //
    land_section_3_1: `สภาพการเทรดเดียวกับ`,
    land_section_3_2: `สถาบันทางการเงิน`,
    land_section_3_1_1: `สภาพคล่องที่มีอยู่จริง และมีความหลากหลาย เพื่อให้คุณเทรดได้ง่ายขึ้น`,
    land_section_3_1_2: `ไม่มีการนำสภาพคล่องมาใช้ใหม่เพื่อความคลาดเคลื่อนของราคาที่ลดลง`,
    land_section_3_1_3: `มีการดำเนินคำสั่งซื้อขาย CFDs กว่า 1.5 พันล้านดอลลาร์สหรัฐในแต่ละวัน`,
    //
    land_section_4_1: `การดำเนินคำสั่ง`,
    land_section_4_2: `ที่รวดเร็ว`,
    land_section_4_1_1: `ความเร็วในการดำเนินคำสั่งโดยเฉลี่ยต่ำกว่า 30ms***`,
    land_section_4_1_2: `การเชื่อมต่อแบบไขว้ผ่านสายใยแก้วนำแสงไปยัง LPs`,
    land_section_4_1_3: `มีการสปอนเซอร์ VPS ให้ใช้ฟรี หากเทรดเดอร์เข้าเงื่อนไข`,
    //
    land_section_5_1: `ยกระดับระบบการเทรดแบบอัตโนมัติของคุณขึ้นไปอีกขั้น`,
    land_section_5_2: `IUX Markets เป็นตัวเลือกที่ดีสำหรับเทรดเดอร์ที่ทำการเทรดแบบอัตโนมัติ เครื่องมือจับคู่คำสั่งของเราซึ่งตั้งอยู่ในศูนย์ข้อมูล Equinix LD4 ในลอนดอน ได้มีการดำเนินคำสั่งกว่า 500,000 ครั้งต่อวัน โดยที่คำสั่งซื้อขายกว่า 2 ใน 3 นั้นมาจากระบบการเทรดแบบอัตโนมัติ`,
    land_section_5_1_1: `ศูนย์กลางการเทรดที่ลอนดอน`,
    land_section_5_1_2: `Equinix LD4`,
    land_section_5_1_3: `ครั้งต่อวัน`,
    land_section_5_1_4: `250,000`,
    land_section_5_1_5: `ALGO TRADES (% OF ALL TRADES)`,
    land_section_5_1_6: `60%`,
    //
    land_section_6_1: `IUX Markets Application`,
    land_section_6_2: `แลกเปลี่ยนตลาด CFDs ได้ทุกที่ทุกเวลาด้วยแอปพลิเคชันมือถือของเราและรับประโยชน์จากโครงสร้างพื้นฐานการซื้อขายที่มีความหน่วงแฝงต่ำการดำเนินการคำสั่งซื้อที่ได้รับรางวัลและสภาพคล่องที่ลึกซึ้ง`,
    land_section_6_3: `พร้อมใช้งานสำหรับอุปกรณ์ iOS และ Android`,
    land_section_6_3_1: `พร้อมใช้งานสำหรับอุปกรณ์`,
    land_section_6_3_2: `IOS`,
    land_section_6_3_3: `และ`,
    land_section_6_3_4: `Android`,

    land_section_6_1_1: `Secure IUX Markets wallet`,
    land_section_6_1_2: `Metatrader 5`,
    land_section_6_1_3: `Variety of payment methods`,
    land_section_6_1_4: `Updated economic event`,
    //
    land_section_7_1: `ตราสารอนุพันธ์ \nของทั้งโลก`,
    land_section_7_2: `ที่ปลายนิ้วของคุณ`,
    land_section_7_3: `ตัว`,
    land_section_7_3_1: `ตลาดCFDs`,
    land_section_7_3_2: `สินค้าโภคภัณฑ์`,
    land_section_7_3_3: `หุ้น`,
    land_section_7_3_4: `ดัชนี`,
    land_section_7_3_5: `พันธบัตร`,
    //
    land_section_8_1: `หัวใจของเรา \nคือความมั่นคง \nและความเร็ว`,
    land_section_8_2: `IUX Markets เป็นผู้ให้บริการ CFDs ที่ใหญ่ที่สุดในโลกตามปริมาณ CFDs ****`,
    land_section_8_1_1: `ปริมาณการซื้อขาย`,
    land_section_8_1_2: `$ 0.5 Trillion`,
    land_section_8_1_3: `ผู้ใช้งานทั้งหมด`,
    land_section_8_1_4: `OVER 250,000`,
    land_section_8_1_5: `ถอนอย่างรวดเร็ว`,
    land_section_8_1_6: `ทันที`,
    //
    land_section_9_1: `POWERFUL`,
    land_section_9_2: `TRADING TERMINALS`,
    //
    land_section_10_1: `YOUR MONEY`,
    land_section_10_2: `YOUR WAY`,
    land_section_10_1_1: `Instant Deposit`,
    land_section_10_1_2: `Transfer your funds securely with 0% Commission`,
    land_section_10_1_3: `Fast Withdrawal`,
    land_section_10_3: `SEGREGATED CLIENT FUNDS`,
    //
    land_section_11_1: `IUX Markets MT5`,
    land_section_11_2: `ค่าธรรมเนียมต่ำกว่า 10%`,
    land_section_11_3: `แพลตฟอร์มที่ทรงพลังสำหรับตลาด CFDs และ Exchange`,
    land_section_11_4: `MetaTrader 5 เป็นตัวเลือกที่ดีที่สุดสำหรับผู้ซื้อขายสมัยใหม่`,
    land_section_11_5: `การซื้อขายที่ประสบความสำเร็จเริ่มต้นด้วยการซื้อขายที่สะดวกและใช้งานได้จริง`,
    cookie_landing_page: `เราใช้คุกกี้ (cookie) เพื่อพัฒนาประสบการณ์การใช้งานจากการเยี่ยมชมเว็บไซต์ของเราและเพื่อสนับสนุนประสิทธิภาพในการนำเสนอข้อมูลและ เนื้อหาต่างๆ ที่ผู้เข้าใช้งานจะได้รับชมอ่านรายละเอียดเพิ่มเติม`,
  },
  lo: {
    banner_title: "ຊື້ຂາຍແມ່ນມີຄວາມໄວສູງສຸດກັບ IUX Markets",
    banner_content: `"ຄວາມໄວແມ່ນຈຸດໃຈກາງຂອງຕະຫຼາດ CFDs. ໃນແງ່ຂອງຄວາມໄວ, ພວກເຮົາແມ່ນຜູ້ ນຳ ດ້ານເຕັກໂນໂລຢີການເປີດບັນຊີເທຣດ ອັນດັບ 1 ໃນການຊື້ຂາຍ ຊອກຫານະວັດຕະກຳ ທີ່ດີທີ່ສຸດຈາກ IUX Markets"`,
    banner_buttonText: "ເປີດບັນຊີຮັບ 30",
    banner_buttonText_standardx: "START WITH Standard",
    banner_buttonText2: "Trade with Spread 0.0 PIPs",
    banner_slide_text1: "ສະເປຮດຕ່ຳ",
    banner_slide_text2: "ຮາຄາແມ່ນຍຳ",
    banner_slide_text3: "250+ ຜະ​ລິດ​ຕະ​ພັນ",
    banner_slide_text4: "24/7 Support",
    banner_slide_text5: "ປະສົບການຫຼາຍກວ່າ 7+ ປີ",
    leftTextBanner_1: "รับฟรี",
    rightTextBanner_1: "$30",
    leftTextBanner_2: "ໂບນັດເງິນຝາກ",
    rightTextBanner_2: "35%",
    leftTextBanner_3: "ເງິນໂບນັດສູງສຸດ",
    rightTextBanner_3: "3,500$",
    section2_label_1: "ໂບນັດເງິນຝາກ",
    section2_label_2: "spred ໜ້ອຍທີ່ສຸດແມ່ນ 0.0 PIPs.",
    section2_label_3: "Leverage",
    section2_label_4: "ບັນຊີ",
    section2_label_5: "Commission",
    //
    title_how_to_use: "ວິທີການ ນຳ ໃຊ້ຕະຫຼາດ IUX Markets",
    tutorials_title_section_1: "ລົງທະບຽນກັບ IUX Markets.com",
    tutorials_title_section_2: "ເປີດບັນຊີເທຣດ",
    tutorials_title_section_3: "ຕິດຕັ້ງ Meta Trader 4",
    tutorials_title_section_4: "ເລີ່ມຕົ້ນການຊື້ຂາຍ",
    tutorials_description_section_1: "ວິທີການ ນຳ ໃຊ້ຕະຫຼາດ IUX Markets",
    tutorials_description_section_2:
      "ເລືອກປະເພດຂອງບັນຊີການຄ້າທີ່ທ່ານຕ້ອງການ, ມີບັນຊີມາດຕະຖານແລະ ECN.",
    tutorials_description_section_3:
      "ຕິດຕັ້ງມັນໃສ່ໂທລະສັບສະມາດໂຟນຫລື PC ຂອງທ່ານ, ແລ້ວເຂົ້າສູ່ລະຫັດ. ວ່າທ່ານໄດ້ຮັບຈາກການເປີດບັນຊີການຄ້າ",
    tutorials_description_section_4:
      "ດ້ວຍເຕັກໂນໂລຢີການຄ້າທີ່ມີຄວາມໄວແລະມີປະສິດທິພາບສູງສຸດຂອງພວກເຮົາ",
    //
    cover_title_section_1: "ການເຂົ້າເຖິງການເທຣດທີ່ສຸດ",
    cover_title_section_2: "ສາມາດຊື້ຂາຍໄດ້ທຸກທີ່ໂດຍໃຊ້ໂທລະສັບມືຖືດ",
    cover_title_section_3: "เການເຂົ້າເຖິງການເທຣດທີ່ສຸດ",
    cover_content_section_1:
      "ເມື່ອທ່ານເລີ່ມຊື້ຂາຍກັບ IUX Markets, ທ່ານຈະບໍ່ຕ້ອງກັງວົນກ່ຽວກັບຄວາມປອດໄພ" +
      "ທ່ານຍັງໄດ້ຮັບຂໍ້ສະ ເໜີ ພິເສດ ສຳ ລັບສະມາຊິກຕະຫຼາດ IUX (ເລີ່ມຊື້ຂາຍ).",
    cover_content_section_button: "ເບິ່ງວີດີໂອເຕັມ",
    cover_content_section_2:
      "ເມື່ອທ່ານເລີ່ມຊື້ຂາຍກັບ IUX Markets, ທ່ານຈະບໍ່ຕ້ອງກັງວົນກ່ຽວກັບຄວາມປອດໄພ" +
      "ທ່ານຍັງໄດ້ຮັບຂໍ້ສະ ເໜີ ພິເສດ ສຳ ລັບສະມາຊິກຕະຫຼາດ IUX (ເລີ່ມຊື້ຂາຍ).",
    cover_content_section_3:
      "ເປີດບັນຊີການຄ້າກັບຕະຫລາດ IUX ໃນເວທີ MT4 ແລະດາວໂຫລດ Mt4." +
      "ເປີດບັນຊີຊື້ - ຂາຍກັບ IUX Markets ໃນ MT4 Platform, ດາວໂຫລດ Mt4 ແລະຊື້ຂາຍຜ່ານໂທລະສັບມືຖືຂອງທ່ານ ໂດຍການເລືອກຊື້ຂາຍສິນຄ້າ ທີ່ທ່ານໝັ້ນໃຈແລະສາມາດຖອນໄດ້ທັນທີທີ່ທ່ານພໍໃຈ ສຳ ລັບການຄ້າແຕ່ລະຢ່າງ",
    why_iux_title: "ເປັນຫຍັງເລືອກຕະຫຼາດ IUX?",
    //
    why_iux_box_title_1: "ປອດໄພກັບມາດຕະຖານລະດັບໂລກ",
    why_iux_box_title_2: "ການຝາກ-ຖອນເງິນໄວທີ່ສຸດ",
    why_iux_box_title_3: "ການປະຕິບັດຄໍາສັ່ງທີ່ໄວກວ່າ",
    why_iux_box_title_4: "ໄດ້ຮັບເຄດິດເງິນຝາກສູງເຖິງ 500 $.",

    why_iux_box_description_1:
      "ພວກເຮົາຮັກສາຂໍ້ມູນການເງິນຂອງທ່ານໃຫ້ປອດໄພ ດ້ວຍການເຂົ້າລະຫັດແລະການເຝົ້າລະວັງການໂກງທຸກມື້ ຕະຫຼອດເວລາ",
    why_iux_box_description_2:
      "ພວກເຮົາຮັບຮູ້ວ່າການຝາກ - ຖອນເງິນແມ່ນງ່າຍແລະວ່ອງໄວ ນີ້ຈະຊ່ວຍໃຫ້ເທຣດເດີບໍ່ພາດໂອກາດການເທຣດທີ່ ສຳຄັນ",
    why_iux_box_description_3:
      "ພາລະກິດຂອງພວກເຮົາແມ່ນເພື່ອໃຫ້ບໍລິການເທຣດເດີ ເພື່ອໃຫ້ໄດ້ລາຄາທີ່ດີທີ່ສຸດ ພວກເຮົາພະຍາຍາມຢ່າງໜັກ ໃນເຕັກໂນໂລຢີເພື່ອເພີ່ມສະພາບຄ່ອງໃຫ້ແກ່ລູກຄ້າ",
    why_iux_box_description_4:
      "ພວກເຮົາຮ່ວມມືກັບທັງທະນາຄານພາຍໃນແລະຕ່າງປະເທດ ໃຫ້ທ່ານສາ​ມາດຮັບໂບນັດເຄດິດ ຈາກການຝາກເງິນເພື່ອຫຼຸດຜ່ອນຄວາມສ່ຽງໃຫ້ ໜ້ອຍ ທີ່ສຸດເທົ່າທີ່ຈະເປັນໄປໄດ້",
    //
    section_4_header: "ສິ່ງທີ່ພວກເຮົາມີຢູ່ໃນຮ້ານ ສຳ ລັບທ່ານ",
    section_4_description_1:
      '"ເຕັກໂນໂລຢີການ Copy trade ກັບ…ທີ່ມີຄຸນນະພາບຫລາຍ ເພື່ອເລືອກ, ຕິດຕາມສັນຍານການເທຣດ ກັບ Eiloy Platform ທີ່ເປັນເຕັກໂນໂລຢີ Social Trade ຮ່ວມກັນເປັນ partner ກັບພວກເຮົາ"',
    section_4_description_2:
      "ເຄື່ອງມືການເທຣດແລະ Platform ນັ້ນແມ່ນກຽມພ້ອມສໍາລັບທ່ານ ສາມາດຊື້ - ຂາຍໄດ້ຢ່າງ ໝັ້ນ ໃຈ",
    section_4_more_detail: "ຂໍ້​ມູນ​ເພີ່ມ​ເຕີມ",

    become_our: "ເຂົ້າຮ່ວມເປັນສ່ວນ ໜຶ່ງ ຂອງພວກເຮົາ",
    become_demo: "ໃຊ້ບັນຊີສາທິດ",
    become_title: "Start trading",
    open_account_now: "ເປີດບັນຊີດຽວນີ້",
    title_iux_team: "IUX Markets Team",
    //
    land_section_1_1: `ຂໍ້ດີຂອງ \nSpreadຕ່ຳ`,
    land_section_1_2: `ຂໍ້ດີຂອງ Spreadຕ່ຳ ແມ່ນຄວາມແຕກຕ່າງທີ່ທ່ານກຳລັງລໍຖ້າ ການຊື້-ຂາຍແບບ Low Spread ຕັ້ງແຕ່0.0 PIPs* , ບໍ່ມີ Requotes, ບໍ່ມີການເຮັດໃຫ້ລາຄາເຮັດໃຫ້ລາຄາສູງແລະຂາຍເພື່ອ ກຳ ໄລຂໍ້ຈຳກັດແລະຂໍ້ ຈຳ ກັດຂອງມັນເຮັດໃຫ້ IUX Markets ກາຍເປັນຜູ້ໃຫ້ບໍລິການ CFDs online ທີ່ດີທີ່ສຸດ ສຳ ລັບພໍ່ຄ້າທີ່ມີປະລິມານການຊື້ຂາຍສູງ, ຜູ້ຄາດຄະເນໄລຍະສັ້ນແລະ daytrade`,
    //
    land_section_2_1: `ອະນຸພັນຂອງໂລກທັງ ໝົດ`,
    land_section_2_1_1: `Spread ສະເລ່ຍຂອງ EURUSD ຢູ່ EURUSD ແມ່ນ 0.0 PIPs ທີ່ດີທີ່ສຸດໃນໂລກ`,
    land_section_2_1_2: `Spread ຕໍ່າໂດຍບໍ່ມີ ຄ່າທຳນຽມທີ່ເຊື່ອງໄວ້ ໝາຍ ຄວາມວ່າພວກເຮົາສາມາດໄດ້ 0.0 PIPs ແທ້ๆ`,
    land_section_2_1_3: `ສະພາບຄ່ອງຢ່າງກວ້າງຂວາງຂອງພວກເຮົາຊ່ວຍໃຫ້ Spread ຕະຫຼອດ 24/7`,
    //
    land_section_3_1: `ສະພາບການຊື້-ຂາຍຄືກັນ`,
    land_section_3_2: `ສະ​ຖາ​ບັນ​ການ​ເງິນ`,
    land_section_3_1_1: `ສະພາບຄ່ອງທີ່ແທ້ຈິງ ແລະຫຼາກຫຼາຍ ເພື່ອເຮັດໃຫ້ທ່ານຄ້າຂາຍງ່າຍຂື້ນ`,
    land_section_3_1_2: `ບໍ່ມີສະພາບຄ່ອງທີ່ຖືກ ນຳ ມາໃຊ້ ສຳ ລັບການຫຼຸດຜ່ອນຄວາມຜິດພາດ`,
    land_section_3_1_3: `ຫຼາຍກວ່າ 1.5 ພັນລ້ານຂອງຄໍາສັ່ງ FX ຖືກປະຕິບັດໃນແຕ່ລະມື້ ການປະຕິບັ ດຄໍາສັ່ງນັ້ນໄວ`,
    //
    land_section_4_1: `ການປະຕິບັດຄໍາສັ່ງ`,
    land_section_4_2: `ນັ້ນໄວ`,
    land_section_4_1_1: `ຄວາມໄວປະຕິບັດການສັ່ງຊື້ໂດຍສະເລ່ຍແມ່ນຕໍ່າກວ່າ 30ms ***`,
    land_section_4_1_2: `ການເຊື່ອມຕໍ່ສາຍໄຟສາຍໃຍແກ້ວກັບ LPs`,
    land_section_4_1_3: `ການສະຫນັບສະຫນູນ VPS ແມ່ນມີໃຫ້ໂດຍບໍ່ເສຍຄ່າຖ້າພໍ່ຄ້າຕອບສະ ໜອງ ເງື່ອນໄຂ`,
    //
    land_section_5_1: `ຍົກລະດັບລະບົບການຊື້-ຂາຍອັດຕະໂນມັດຂອງທ່ານໄປໃນລະດັບຕໍ່ໄປ`,
    land_section_5_2: `IUX Markets ແມ່ນທາງເລືອກທີ່ດີ ສຳ ລັບພໍ່ຄ້າອັດຕະໂນມັດ ເຄື່ອງມືການຈັບຄູ່ການສັ່ງຊື້ຂອງພວກເຮົາ, ຕັ້ງຢູ່ສູນຂໍ້ມູນ Equinix LD4 ໃນລອນດອນ, ປະມວນຜົນຫຼາຍກວ່າ  500,000 ຄຳ ສັ່ງຕໍ່ມື້, ເຊິ່ງຫຼາຍກວ່າສອງສ່ວນສາມຂອງ ຄຳ ສັ່ງທີ່ມາຈາກລະບົບການຄ້າແບບອັດຕະໂນມັດ`,
    land_section_5_1_1: `ສູນການຄ້າໃນລອນດອນ`,
    land_section_5_1_2: `Equinix LD4`,
    land_section_5_1_3: `ເທື່ອຕໍ່ມື້`,
    land_section_5_1_4: `250,000`,
    land_section_5_1_5: `ALGO TRADES (% OF ALL TRADES)`,
    land_section_5_1_6: `60%`,
    //
    land_section_6_1: `IUX Markets Application`,
    land_section_6_2: `ຊື້-ຂາຍຕະຫຼາດ CFDs ທຸກເວລາ, ທຸກບ່ອນທີ່ມີແອັບ mobile ມືຖືຂອງພວກເຮົາແລະໄດ້ຮັບຜົນປະໂຫຍດຈາກໂຄງລ່າງການຄ້າທີ່ມີຄວາມຊ້າ, ການປະຕິບັດ ຄຳ ສັ່ງທີ່ໄດ້ຮັບລາງວັນແລະສະພາບຄ່ອງທີ່ເລິກເຊິ່ງ`,
    land_section_6_3: `ໃຊ້ໄດ້ກັບອຸປະກອນ iOS ແລະ Android`,
    land_section_6_3_1: `ໃຊ້ໄດ້ກັບອຸປະກອນ`,
    land_section_6_3_2: `IOS`,
    land_section_6_3_3: `ແລະ`,
    land_section_6_3_4: `Android`,

    land_section_6_1_1: `Secure IUX Markets wallet`,
    land_section_6_1_2: `Metatrader 5`,
    land_section_6_1_3: `Variety of payment methods`,
    land_section_6_1_4: `Updated economic event`,
    //
    land_section_7_1: `ອະນຸພັນ \nຂອງໂລກທັງໝົດ`,
    land_section_7_2: `ຢູ່ປາຍນິ້ວຂອງທ່ານ`,
    land_section_7_3: `ຕົວ`,
    land_section_7_3_1: `ຕະຫຼາດ CFDs`,
    land_section_7_3_2: `ສິນຄ້າ`,
    land_section_7_3_3: `ຫຸ້ນ`,
    land_section_7_3_4: `ດັດຊະນີ`,
    land_section_7_3_5: `ພັນທະບັດ`,
    //
    land_section_8_1: `Our strength is stability and speed`,
    land_section_8_2: `IUX Markets is the largest CFDs Provider in the world by CFDs volume`,
    land_section_8_1_1: `ປະລິມານການຊື້ຂາຍ`,
    land_section_8_1_2: `$ 0.5 Trillion`,
    land_section_8_1_3: `ຜູ້ໃຊ້ທັງໝົດ`,
    land_section_8_1_4: `OVER 250,000`,
    land_section_8_1_5: `ຖອນເງິນໄວ`,
    land_section_8_1_6: `Instant`,
    //
    land_section_9_1: `POWERFUL`,
    land_section_9_2: `TRADING TERMINALS`,
    //
    land_section_10_1: `YOUR MONEY`,
    land_section_10_2: `YOUR WAY`,
    land_section_10_1_1: `Instant Deposit`,
    land_section_10_1_2: `Transfer your funds securely with 0% Commission`,
    land_section_10_1_3: `Fast Withdrawal`,
    land_section_10_3: `SEGREGATED CLIENT FUNDS`,
    //
    land_section_11_1: `IUX Markets MT5`,
    land_section_11_2: `ຄ່າ ທຳ ນຽມຕ່ ຳ ກວ່າ 10%`,
    land_section_11_3: `ແພລະຕະຟອມທີ່ມີປະສິດທິພາບສໍາລັບຕະຫຼາດແລກປ່ຽນເງິນຕາ CFDs`,
    land_section_11_4: `MetaTrader 5 ເປັນທາງເລືອກທີ່ດີທີ່ສຸດສໍາລັບພໍ່ຄ້າສະໄຫມໃຫມ່`,
    land_section_11_5: `ການຊື້ຂາຍທີ່ປະສົບຜົນສໍາເລັດເລີ່ມຕົ້ນດ້ວຍການຊື້ຂາຍທີ່ສະດວກແລະປະຕິບັດໄດ້.`,
    cookie_landing_page: `ພວກເຮົາໃຊ້ຄຸກກີ້ (ຄຸກກີ້) ເພື່ອພັດທະນາປະສົບການການ ນຳ ໃຊ້ວຽກຈາກການເບິ່ງເວັບໄຊທของຂອງພວກເຮົາແລະເພື່ອສະ ໜັບ ສະ ໜູນ ຄຸນນະພາບໃນການ ນຳ ສະ ເໜີ ຂໍ້ມູນແລະເນື້ອຫາຕ່າງต่างๆທີ່ຜູ້ເຂົ້າມາໃຊ້ວຽກຈະໄດ້ຮັບການອ່ານລາຍລະອຽດເພີ່ມເຕີມ`,
  },
  en: {
    banner_title: "Low-spread trading begins from 0.0 PIPs",
    banner_content: `Low-spread trading begins from 0.0 PIPs
    The important aspect of CFDs Trading is low-spread.
    We are the market leader in low-spread trading technology and trading innovations.`,
    banner_buttonText: "Start with $30 bonus",
    banner_buttonText_standardx: "START WITH Standard",
    banner_buttonText2: "Trade with Spread 0.0 PIPs",
    banner_slide_text1: "Low spread",
    banner_slide_text2: "Sharp price",
    banner_slide_text3: "250+ Instruments",
    banner_slide_text4: "24/7 Support",
    banner_slide_text5: "7+ Years of experiences",
    leftTextBanner_1: "Get a free",
    rightTextBanner_1: "$30",
    leftTextBanner_2: "Deposit bonus",
    rightTextBanner_2: "35%",
    leftTextBanner_3: "Maximum",
    rightTextBanner_3: "3,500$",
    section2_label_1: "Deposit Bonus",
    section2_label_2: "Low spread",
    section2_label_3: "Leverage",
    section2_label_4: "Account",
    section2_label_5: "Commission",
    title_how_to_use: "Easy account opening in 4 steps",
    tutorials_title_section_1: "Register with IUX Markets.com",
    tutorials_title_section_2: "Trade with Spread 0.0 PIPs",
    tutorials_title_section_3: "Install Meta Trader 4",
    tutorials_title_section_4: "Start trading",
    tutorialsteam_section_1: "test",
    tutorialsteam_section_2: "test",
    tutorialsteam_section_3: "test",

    tutorials_description_section_1: "Help IUX Markets",
    tutorials_description_section_2: "",
    tutorials_description_section_3: "",
    tutorials_description_section_4: "",
    cover_title_section_1: "Access to the most trades",
    cover_title_section_2: "You can trade anywhere with just one mobile phone.",
    cover_title_section_3: "Access to the most trades",

    cover_content_section_1: `When you start trading with IUX Markets. You won't have to worry about security. You also get a very special offer from IUX Markets members (start trading).`,
    cover_content_section_button: "Watch the full video here",
    cover_content_section_2: `When you start trading with IUX Markets. You won't have to worry about security. You also get a very special offer from IUX Markets members (start trading).`,
    cover_content_section_3: `Open a buy-sell account with IUX Markets on the MT4 platform then download Mt4 and trade on your mobile phone, by choosing to trade products that you are confident in and can withdraw as soon as you are satisfied with each trade. \nThe most important thing that is money goes in immediately.`,

    //
    why_iux_box_title_1: "World class standard safety",
    why_iux_box_title_2: `The fastest deposit and withdrawal`,
    why_iux_box_title_3: "Get Fast Order Execution.",
    why_iux_box_title_4: "Get credit deposit up to 500$.",

    why_iux_box_description_1: `We keep your financial information safe with complicated encryption and 24/7 fraud surveillance.`,
    why_iux_box_description_2: `"We realize that deposit - withdraw fast and easy will help traders don’t miss important trading opportunitie. The most important that is easy to deposit and withdraw.`,
    why_iux_box_description_3: `Our mission is to service traders to get the best price. We work so hard in technology to increase liquidity for our customers`,
    why_iux_box_description_4:
      "We co-operate with local banks and international banks, that makes you can get credit bonus from the deposit, to risk reduction as much as possible.",
    //
    section_4_header: "What we have for you",
    section_4_description_1:
      "Copytrade  With Eiloy Platfrom the technology trading social as our Partner.",
    section_4_description_2: "Trading platforms and tools available to you.",
    section_4_more_detail: "more",

    become_our: "global markets today!",
    become_demo: "TRY A FREE DEMO",
    become_title: "Start trading",
    open_account_now: "Trade with 0.0 PIPs",
    title_iux_team: "IUX Markets Team",

    //
    land_section_1_1: "The Low Spread Advantage",
    land_section_1_2: `The difference you've been looking for is Low Spread. Trade with a spread starting from 0.0 PIPs*, without requotes, at the best rates, and without limitations. IUX Markets is the best online CFDs provider for scalpers,robots and high volume trading systems.`,
    //
    land_section_2_1: `Start with `,
    land_section_2_1_1: `IUX Markets provide EURUSD Avg spread of 0.0 which is the best spread in the world`,
    land_section_2_1_2: `Low spread start from 0.0 PIPs`,
    land_section_2_1_3: `Our diverse and proprietary liquidity mix keeps spread tight 24/7`,
    //
    land_section_3_1: `Institutional`,
    land_section_3_2: `grade trading`,
    land_section_3_1_1: `Real, deep, deverse liquidity you can trade on`,
    land_section_3_1_2: ` Reduced slippage`,
    land_section_3_1_3: ` Over 1.5 billion USD in CFDs trades processed daily`,
    //
    land_section_4_1: `FAST ORDER`,
    land_section_4_2: `EXECUTION`,
    land_section_4_1_1: `Average execution speeds of under 30ms`,
    land_section_4_1_2: `Low latency fibre optic and Equinix LD4 server`,
    land_section_4_1_3: `Free Low latency collocated VPS available`,
    //
    land_section_5_1: `GIVE YOUR \nAUTOMATED \nTRADING \nSYSTEM \nTHE EDGE`,
    land_section_5_2: `IUX Markets is one of the best choices for automated traders. Our order matching engine located in the London Equinix LD4 data center, processes over 500,000 trades per day with over 60% of all trades coming from automated trading systems. `,
    land_section_5_1_1: `TRADING HUB AT London`,
    land_section_5_1_2: `Equinix LD4`,
    land_section_5_1_3: `TRADER PER DAY`,
    land_section_5_1_4: `250,000`,
    land_section_5_1_5: `ALGO TRADES (% OF ALL TRADES)`,
    land_section_5_1_6: `60%`,
    //
    land_section_6_1: `TRADE ON \nMOBILE`,
    land_section_6_2: `Trade CFDs market on our mobile application and benefit from ultra-low latency trading instructure, fast order execution and deep liquidity.`,
    land_section_6_3: `Available for iOS and Android devices.`,
    land_section_6_3_1: `Available for`,
    land_section_6_3_2: `IOS`,
    land_section_6_3_3: `and`,
    land_section_6_3_4: `Android`,

    land_section_6_1_1: `Secure IUX Markets wallet`,
    land_section_6_1_2: `Metatrader 5`,
    land_section_6_1_3: `Variety of payment methods`,
    land_section_6_1_4: `Updated economic event`,
    //
    land_section_7_1: `GLOBAL MARKETS AT`,
    land_section_7_2: `YOUR FINGERTIPS`,
    land_section_7_3: `products`,
    land_section_7_3_1: `CFDs Market`,
    land_section_7_3_2: `Commodities`,
    land_section_7_3_3: `Stocks`,
    land_section_7_3_4: `Indices`,
    land_section_7_3_5: `Bonds`,
    //
    land_section_8_1: `Our heart \nis stability \nand speed \nabove all`,
    land_section_8_2: `IUX Markets is the largest CFDs Provider in the world by CFDs volume`,
    land_section_8_1_1: `TRADING VOLUMES`,
    land_section_8_1_2: `$ 0.5 Trillion`,
    land_section_8_1_3: `ACTIVE CLIENT `,
    land_section_8_1_4: `OVER 250,000`,
    land_section_8_1_5: `Fast withdraw`,
    land_section_8_1_6: `Instant`,
    //
    land_section_9_1: `POWERFUL`,
    land_section_9_2: `TRADING TERMINALS`,
    //
    land_section_10_1: `YOUR MONEY`,
    land_section_10_2: `YOUR WAY`,
    land_section_10_1_1: `Instant Deposit`,
    land_section_10_1_2: `Transfer your funds securely with 0% Commission`,
    land_section_10_1_3: `Fast Withdrawal`,
    land_section_10_3: `SEGREGATED CLIENT FUNDS`,
    // check
    land_section_11_1: `IUX Markets MT5`,
    land_section_11_2: `Fees below 10%`,
    land_section_11_3: `Powerful Platform for CFDs and Exchange Markets`,
    land_section_11_4: `MetaTrader 5 is the best choice for modern traders.`,
    land_section_11_5: `Successful trading starts with convenient and practical trading.`,
    cookie_landing_page: `We use cookies to improve the user experience of visiting our website and to support the effectiveness of the information and content that our visitors will see.`,
  },
  hi: {
    banner_title: "Low-spread trading begins from 0.0 PIPs",
    banner_content: `Low-spread trading begins from 0.0 PIPs
    The important aspect of CFDs Trading is low-spread.
    We are the market leader in low-spread trading technology and trading innovations.`,
    banner_buttonText: "Start with $30 bonus",
    banner_buttonText_standardx: "START WITH Standard",
    banner_buttonText2: "Trade with Spread 0.0 PIPs",
    banner_slide_text1: "Low spread",
    banner_slide_text2: "Sharp price",
    banner_slide_text3: "250+ Instruments",
    banner_slide_text4: "24/7 Support",
    banner_slide_text5: "7+ Years of experiences",
    leftTextBanner_1: "Get a free",
    rightTextBanner_1: "$30",
    leftTextBanner_2: "Deposit bonus",
    rightTextBanner_2: "35%",
    leftTextBanner_3: "Maximum",
    rightTextBanner_3: "3,500$",
    section2_label_1: "Deposit Bonus",
    section2_label_2: "Low spread",
    section2_label_3: "Leverage",
    section2_label_4: "Account",
    section2_label_5: "Commission",
    title_how_to_use: "Easy account opening in 4 steps",
    tutorials_title_section_1: "Register with IUX Markets.com",
    tutorials_title_section_2: "Trade with Spread 0.0 PIPs",
    tutorials_title_section_3: "Install Meta Trader 4",
    tutorials_title_section_4: "Start trading",
    tutorialsteam_section_1: "test",
    tutorialsteam_section_2: "test",
    tutorialsteam_section_3: "test",

    tutorials_description_section_1: "Help IUX Markets",
    tutorials_description_section_2: "",
    tutorials_description_section_3: "",
    tutorials_description_section_4: "",
    cover_title_section_1: "Access to the most trades",
    cover_title_section_2: "You can trade anywhere with just one mobile phone.",
    cover_title_section_3: "Access to the most trades",

    cover_content_section_1: `When you start trading with IUX Markets. You won't have to worry about security. You also get a very special offer from IUX Markets members (start trading).`,
    cover_content_section_button: "Watch the full video here",
    cover_content_section_2: `When you start trading with IUX Markets. You won't have to worry about security. You also get a very special offer from IUX Markets members (start trading).`,
    cover_content_section_3: `Open a buy-sell account with IUX Markets on the MT4 platform then download Mt4 and trade on your mobile phone, by choosing to trade products that you are confident in and can withdraw as soon as you are satisfied with each trade. \nThe most important thing that is money goes in immediately.`,

    //
    why_iux_box_title_1: "World class standard safety",
    why_iux_box_title_2: `The fastest deposit and withdrawal`,
    why_iux_box_title_3: "Get Fast Order Execution.",
    why_iux_box_title_4: "Get credit deposit up to 500$.",

    why_iux_box_description_1: `We keep your financial information safe with complicated encryption and 24/7 fraud surveillance.`,
    why_iux_box_description_2: `"We realize that deposit - withdraw fast and easy will help traders don’t miss important trading opportunitie. The most important that is easy to deposit and withdraw.`,
    why_iux_box_description_3: `Our mission is to service traders to get the best price. We work so hard in technology to increase liquidity for our customers`,
    why_iux_box_description_4:
      "We co-operate with local banks and international banks, that makes you can get credit bonus from the deposit, to risk reduction as much as possible.",
    //
    section_4_header: "What we have for you",
    section_4_description_1:
      "Copytrade  With Eiloy Platfrom the technology trading social as our Partner.",
    section_4_description_2: "Trading platforms and tools available to you.",
    section_4_more_detail: "more",

    become_our: "global markets today!",
    become_demo: "TRY A FREE DEMO",
    become_title: "Start trading",
    open_account_now: "Trade with 0.0 PIPs",
    title_iux_team: "IUX Markets Team",

    //
    land_section_1_1: "The Low Spread Advantage",
    land_section_1_2: `The difference you've been looking for is Low Spread. Trade with a spread starting from 0.0 PIPs*, without requotes, at the best rates, and without limitations. IUX Markets is the best online CFDs provider for scalpers,robots and high volume trading systems.`,
    //
    land_section_2_1: `Start with `,
    land_section_2_1_1: `IUX Markets provide EURUSD Avg spread of 0.0 which is the best spread in the world`,
    land_section_2_1_2: `Low spread start from 0.0 PIPs`,
    land_section_2_1_3: `Our diverse and proprietary liquidity mix keeps spread tight 24/7`,
    //
    land_section_3_1: `Institutional`,
    land_section_3_2: `grade trading`,
    land_section_3_1_1: `Real, deep, deverse liquidity you can trade on`,
    land_section_3_1_2: ` Reduced slippage`,
    land_section_3_1_3: ` Over 1.5 billion USD in CFDs trades processed daily`,
    //
    land_section_4_1: `FAST ORDER`,
    land_section_4_2: `EXECUTION`,
    land_section_4_1_1: `Average execution speeds of under 30ms`,
    land_section_4_1_2: `Low latency fibre optic and Equinix LD4 server`,
    land_section_4_1_3: `Free Low latency collocated VPS available`,
    //
    land_section_5_1: `GIVE YOUR \nAUTOMATED \nTRADING \nSYSTEM \nTHE EDGE`,
    land_section_5_2: `IUX Markets is one of the best choices for automated traders. Our order matching engine located in the London Equinix LD4 data center, processes over 500,000 trades per day with over 60% of all trades coming from automated trading systems. `,
    land_section_5_1_1: `TRADING HUB AT London`,
    land_section_5_1_2: `Equinix LD4`,
    land_section_5_1_3: `TRADER PER DAY`,
    land_section_5_1_4: `250,000`,
    land_section_5_1_5: `ALGO TRADES (% OF ALL TRADES)`,
    land_section_5_1_6: `60%`,
    //
    land_section_6_1: `TRADE ON \nMOBILE`,
    land_section_6_2: `Trade CFDs market on our mobile application and benefit from ultra-low latency trading instructure, fast order execution and deep liquidity.`,
    land_section_6_3: `Available for iOS and Android devices.`,
    land_section_6_3_1: `Available for`,
    land_section_6_3_2: `IOS`,
    land_section_6_3_3: `and`,
    land_section_6_3_4: `Android`,

    land_section_6_1_1: `Secure IUX Markets wallet`,
    land_section_6_1_2: `Metatrader 5`,
    land_section_6_1_3: `Variety of payment methods`,
    land_section_6_1_4: `Updated economic event`,
    //
    land_section_7_1: `GLOBAL MARKETS AT`,
    land_section_7_2: `YOUR FINGERTIPS`,
    land_section_7_3: `products`,
    land_section_7_3_1: `CFDs Market`,
    land_section_7_3_2: `Commodities`,
    land_section_7_3_3: `Stocks`,
    land_section_7_3_4: `Indices`,
    land_section_7_3_5: `Bonds`,
    //
    land_section_8_1: `Our heart \nis stability \nand speed \nabove all`,
    land_section_8_2: `IUX Markets is the largest CFDs Provider in the world by CFDs volume`,
    land_section_8_1_1: `TRADING VOLUMES`,
    land_section_8_1_2: `$ 0.5 Trillion`,
    land_section_8_1_3: `ACTIVE CLIENT `,
    land_section_8_1_4: `OVER 250,000`,
    land_section_8_1_5: `Fast withdraw`,
    land_section_8_1_6: `Instant`,
    //
    land_section_9_1: `POWERFUL`,
    land_section_9_2: `TRADING TERMINALS`,
    //
    land_section_10_1: `YOUR MONEY`,
    land_section_10_2: `YOUR WAY`,
    land_section_10_1_1: `Instant Deposit`,
    land_section_10_1_2: `Transfer your funds securely with 0% Commission`,
    land_section_10_1_3: `Fast Withdrawal`,
    land_section_10_3: `SEGREGATED CLIENT FUNDS`,
    // check
    land_section_11_1: `IUX Markets MT5`,
    land_section_11_2: `Fees below 10%`,
    land_section_11_3: `Powerful Platform for CFDs and Exchange Markets`,
    land_section_11_4: `MetaTrader 5 is the best choice for modern traders.`,
    land_section_11_5: `Successful trading starts with convenient and practical trading.`,
    cookie_landing_page: `We use cookies to improve the user experience of visiting our website and to support the effectiveness of the information and content that our visitors will see.`,
  },
  ms: {
    banner_title: `Berdagang dengan spread rendah mula dari 0.0 PIPs.`,
    banner_content: `Spread rendah adalah intipati dagangan CFDs Kami adalah peneraju teknologi dagangan spread rendah dan kami mempunyai inovasi dagangan terbaik.`,
    banner_buttonText: `Buka akaun dan dapatkan $30`,
    banner_buttonText2: "Buka akaun disini",
    banner_buttonText_standardx: "Buka akaun Standard",
    banner_slide_text1: "Spread rendah",
    banner_slide_text2: "Harga jitu",
    banner_slide_text3: "250+ Instrumen",
    banner_slide_text4: "Sokongan 24/7",
    banner_slide_text5: "7+ Tahun pengalaman",
    leftTextBanner_1: `Percuma`,
    rightTextBanner_1: `30 $`,
    leftTextBanner_2: `deposit`,
    rightTextBanner_2: `35%`,
    leftTextBanner_3: `bonus maksimum`,
    rightTextBanner_3: `3500 $`,
    section2_label_1: `Bonus Deposit`,
    section2_label_2: `Spread serendah 0.0 PIPs`,
    section2_label_3: `leverage`,
    section2_label_4: `akaun`,
    section2_label_5: "Komisen",
    title_how_to_use: `Pembukaan akaun dengan cara mudah dalam 4 langkah`,
    tutorials_title_section_1: `Daftar dengan IUX Markets.com`,
    tutorials_title_section_2: `Pembukaan akaun`,
    tutorials_title_section_3: `Memasang Meta Trader 4`,
    tutorials_title_section_4: `Mula Trading`,
    tutorialsteam_section_1: `test`,
    tutorialsteam_section_2: `test`,
    tutorialsteam_section_3: `test`,

    tutorials_description_section_1: `Cara menggunakan IUX Markets`,
    tutorials_description_section_2:
      "Pilih jenis akaun dagangan yang anda mahukan, akaun Standard dan ECN tersedia",
    tutorials_description_section_3:
      "Pasang pada Telefon Pintar atau PC anda, kemudian log masuk kod yang anda dapat dengan membuka akaun dagangan",
    tutorials_description_section_4:
      "Dengan kelajuan dan teknologi dagangan kami yang paling berkuasa",
    cover_title_section_1: `Akses kepada perdagangan terbesar`,
    cover_title_section_2: `Boleh berdagang di mana-mana dengan hanya satu peranti mudah alih.`,
    cover_title_section_3: `Akses kepada perdagangan terbesar`,

    cover_content_section_1: `Apabila anda mula berdagang dengan IUX Markets, anda tidak perlu risau tentang keselamatan." +
    "Anda juga mendapat tawaran yang sangat istimewa untuk ahli IUX Markets (mulakan dagangan)`,
    cover_content_section_button: "Tonton video penuh disini",
    cover_content_section_2: `Apabila anda mula berdagang dengan IUX Markets, anda tidak perlu risau tentang keselamatan." +
    "Anda juga mendapat tawaran yang sangat istimewa untuk ahli IUX Markets (mulakan dagangan)`,
    cover_content_section_3: `Buka akaun, jual beli dengan IUX Markets pada platform MT4, kemudian muat turun Mt4. dan berdagang melalui telefon bimbit anda Dengan memilih untuk berdagang produk yang anda yakin dan mampu menarik diri sebaik sahaja anda berpuas hati dengan setiap dagangan, yang terpenting duit masuk segera`,

    //
    why_iux_box_title_1: `Keselamatan bertaraf dunia`,
    why_iux_box_title_2: `Pengeluaran-deposit terpantas.`,
    why_iux_box_title_3: `Pelaksanaan pesanan yang lebih cepat.`,
    why_iux_box_title_4: `Dapatkan Bonus Kredit sehingga $ 500.`,

    why_iux_box_description_1: `Kami memastikan maklumat kewangan anda selamat dengan penyulitan 24 jam dan pemantauan penipuan`,
    why_iux_box_description_2: `Kami menyedari bahawa deposit dan pengeluaran yang cepat dan mudah akan membantu pedagang tidak terlepas peluang perdagangan penting yang memerlukan pengeluaran deposit mudah, tidak rumit`,
    why_iux_box_description_3: `Misi kami adalah untuk berkhidmat kepada peniaga supaya mendapatkan harga terbaik. Kami bekerja keras membangun teknologi untuk meningkatkan kecairan untuk pelanggan kami`,
    why_iux_box_description_4: `Kami bekerjasama dengan bank tempatan dan antarabangsa. Membolehkan anda menerima kredit bonus daripada mendepositkan wang untuk mengurangkan risiko sebanyak mungkin.`,
    //
    section_4_header: `Apa yang kami ada untuk anda.`,
    section_4_description_1: `Teknologi Copy Trade dengan pelbagai master kualiti untuk dipilih. Ikuti isyarat dagangan dengan Eiloy Platform, teknologi Perdagangan Sosial. Jadi rakan kongsi kami.`,
    section_4_description_2: `Alat dan platform dagangan yang anda boleh gunakan untuk Beli dan jual dengan lebih yakin.`,
    section_4_more_detail: `Maklumat Tambahan`,

    become_our: `Jadilah sebahagian daripada kami`,
    become_demo: "Gunakan akaun demo",
    become_title: "Mula trading",
    open_account_now: `Buka Akaun sekarang`,
    title_iux_team: `IUX Markets Team`,
    //
    land_section_1_1: "Kelebihan Spread Rendah",
    land_section_1_2: `Spread Rendah ialah perbezaan yang anda tunggu-tunggu. Berdagang dengan spread daripada 0.0 PIPs*, tiada sebut harga semula, harga terbaik dan tiada sekatan. IUX Markets ialah penyedia CFDs dalam talian pilihan untuk pedagang volum tinggi, scalper dan robot.`,
    //
    land_section_2_1: `SPREAD DARI`,
    land_section_2_1_1: `IUX Markets, EURUSD Purata spread 0.0 adalah salah satu yang terbaik di dunia`,
    land_section_2_1_2: `Tiada 'yuran Spread tersembunyi' bermakna secara nyata kami boleh naik sehingga 0.0 PIPs.`,
    land_section_2_1_3: `Pelbagai kecairan kami dapat memastikan spread yang ketat 24/7`,
    //
    land_section_3_1: `Syarat dagangan yang sama seperti`,
    land_section_3_2: `institusi kewangan`,
    land_section_3_1_1: `kecairan nyata dan mempunyai kepelbagaian untuk memudahkan dagangan`,
    land_section_3_1_2: `Tiada  penggunaan semula kecairan untuk mengurangkan perbezaan harga`,
    land_section_3_1_3: `Lebih 1.5 Billion USD dalam dagangan FX diproses setiap hari`,
    //
    land_section_4_1: `Pelaksanaan pesanan`,
    land_section_4_2: `Dengan Cepat`,
    land_section_4_1_1: `Purata kelajuan pelaksanaan pesanan adalah kurang daripada 30ms***`,
    land_section_4_1_2: `Sambungan silang gentian optik ke LP`,
    land_section_4_1_3: `Penaja VPS percuma disediakan jika peniaga memenuhi syarat.`,
    //
    land_section_5_1: `Bawa sistem perdagangan automatik anda ke peringkat seterusnya`,
    land_section_5_2: `IUX Markets ialah pilihan yang baik untuk pedagang automatik. Alat pemadanan pesanan kami, yang terletak di pusat data Equinix LD4 di London, memproses  500,000 pesanan sehari, dengan lebih dua pertiga daripada pesanan kami datang daripada sistem perdagangan automatik.`,
    land_section_5_1_1: `Pusat Dagangan London`,
    land_section_5_1_2: `Equinix LD4`,
    land_section_5_1_3: `Harian`,
    land_section_5_1_4: `250,000`,
    land_section_5_1_5: `ALGO TRADES (% OF ALL TRADES)`,
    land_section_5_1_6: `60%`,
    //
    land_section_6_1: `Aplikas IUX Markets`,
    land_section_6_2: `Perdagangkan pasaran CFDs pada bila-bila masa, di mana-mana sahaja dengan aplikasi mudah alih kami dan mendapat manfaat daripada infrastruktur dagangan kependaman rendah, pelaksanaan pesanan yang memenangi anugerah dan kecairan yang mendalam.`,
    land_section_6_3: `Tersedia untuk peranti iOS dan Android..`,
    land_section_6_3_1: `Tersedia untuk peranti`,
    land_section_6_3_2: `IOS`,
    land_section_6_3_3: `dan`,
    land_section_6_3_4: `Android`,

    land_section_6_1_1: `WALLET IUX Markets yang selamat`,
    land_section_6_1_2: `Metatrader 5`,
    land_section_6_1_3: `Kepelbagaian kaedah pembayaran`,
    land_section_6_1_4: ` Peristiwa ekonomi terkini`,
    //
    land_section_7_1: `GLOBAL MARKETS DI`,
    land_section_7_2: `Jari Anda`,
    land_section_7_3: `produk`,
    land_section_7_3_1: `CFDs Market`,
    land_section_7_3_2: `Komoditi`,
    land_section_7_3_3: `Saham`,
    land_section_7_3_4: `Indeks`,
    land_section_7_3_5: `Bonds`,
    //
    land_section_8_1: `Hati kami \n adalah kestabilan. \ndan kelajuan`,
    land_section_8_2: `IUX Markets ialah Pembekal CFDs Terbesar di Dunia mengikut Volum CFDs.****`,
    land_section_8_1_1: `NILAI TRADING`,
    land_section_8_1_2: `$ 0.5 Trillion`,
    land_section_8_1_3: `Pelanggan Aktif `,
    land_section_8_1_4: `LEBIH 250,000`,
    land_section_8_1_5: `pengeluaran cepat`,
    land_section_8_1_6: `Instant`,
    //
    land_section_9_1: `BERKUASA`,
    land_section_9_2: `TERMINAL PERDAGANGAN`,
    //
    land_section_10_1: `Dana Anda`,
    land_section_10_2: `Jalan Anda`,
    land_section_10_1_1: `DepositSegera`,
    land_section_10_1_2: `Pindahkan dana anda dengan selamat dengan 0% Komisen`,
    land_section_10_1_3: `Pengeluaran cepat`,
    land_section_10_3: `DANA PELANGGAN DIASINGKAN`,
    //
    land_section_11_1: `IUX Markets MT5`,
    land_section_11_2: `Yuran di bawah 10%`,
    land_section_11_3: `Platform Berkuasa untuk Pasaran CFDs dan Pertukaran`,
    land_section_11_4: `MetaTrader 5 ialah pilihan terbaik untuk pedagang moden.`,
    land_section_11_5: `Dagangan yang berjaya bermula dengan perdagangan yang mudah dan praktikal.`,
    cookie_landing_page: `Kami menggunakan cookies untuk meningkatkan pengalaman pengguna melawati laman web kami dan untuk menyokong keberkesanan maklumat dan kandungan yang akan dilihat oleh pelawat kami. Baca lagi.`,
  },
  id: {
    banner_title: `Trading tercepat oleh pasar IUX`,
    banner_content: `Kecepatan merupakan aspek penting dari perdagangan CFDs Kami aspek pemimpin teknologi perdagangan speedly. Dan kami memiliki inovasi trading yang terbaik.`,
    banner_buttonText: `Mulailah dengan 30 $`,
    banner_buttonText_standardx: "START WITH Standard",
    banner_buttonText2: "Open an Account Here",
    banner_slide_text1: "Low spread",
    banner_slide_text2: "Sharp price",
    banner_slide_text3: "250+ Instruments",
    banner_slide_text4: "24/7 Support",
    banner_slide_text5: "7+ Years of experiences",
    leftTextBanner_1: `Dapatkan gratis`,
    rightTextBanner_1: `30 $`,
    leftTextBanner_2: `Menyetorkan`,
    rightTextBanner_2: `35%`,
    leftTextBanner_3: `bonus untuk`,
    rightTextBanner_3: `3.500 $`,
    section2_label_1: `Deposit Bonus`,
    section2_label_2: `Spread serendah 0,1 PIPs`,
    section2_label_3: `Pengaruh`,
    section2_label_4: `Akun`,
    section2_label_5: "Commission",
    title_how_to_use: `pembukaan rekening mudah dalam 4 langkah`,
    tutorials_title_section_1: `Daftar dengan IUX Markets.com`,
    tutorials_title_section_2: `pembukaan akun`,
    tutorials_title_section_3: `Install Meta Trader 4`,
    tutorials_title_section_4: `Perdagangan`,
    tutorialsteam_section_1: `test`,
    tutorialsteam_section_2: `test`,
    tutorialsteam_section_3: `test`,

    tutorials_description_section_1: `Bantuan IUX Markets`,
    tutorials_description_section_2: "",
    tutorials_description_section_3: "",
    tutorials_description_section_4: "",
    cover_title_section_1: `Akses ke perdagangan yang paling`,
    cover_title_section_2: `Perdagangan dengan hanya satu smartphone.`,
    cover_title_section_3: `Akses ke perdagangan yang paling`,

    cover_content_section_1: `Ketika Anda memulai trading dengan IUX Markets, Anda tidak perlu khawatir tentang keamanan. Anda juga mendapatkan penawaran khusus untuk anggota IUX Markets`,
    cover_content_section_button: "video penuh",
    cover_content_section_2: `Ketika Anda memulai trading dengan IUX Markets, Anda tidak perlu khawatir tentang keamanan. Anda juga mendapatkan penawaran khusus untuk anggota IUX Markets`,
    cover_content_section_3: `Pembukaan - The IUX Markets pada platform MT4 kemudian men-download MT4 maka perdagangan melalui ponsel Anda. Item perdagangan bahwa Anda percaya diri dan dapat ditarik segera setelah Anda puas dalam setiap perdagangan. Apakah uang segera`,

    //
    why_iux_box_title_1: `Standar keamanan`,
    why_iux_box_title_2: `Deposit tercepat - penarikan.`,
    why_iux_box_title_3: `Dapatkan Cepat Eksekusi Order.`,
    why_iux_box_title_4: `Dapatkan Bonus Kredit sampai dengan $ 500.`,

    why_iux_box_description_1: `Kami menjaga informasi keuangan Anda aman. Dengan enkripsi dan pemantauan setiap 24 jam penipuan`,
    why_iux_box_description_2: `Kami memberikan pentingnya dana cepat mudah. Sangat mudah dan cepat untuk deposit dan penarikan`,
    why_iux_box_description_3: `server VPS yang terletak di pusat data yang sama dengan server perdagangan, ping server perdagangan sangat cepat (0,4-1,25 ms), sehingga kutipan tiba langsung dan pesanan pedagang dikirim ke server tanpa penundaan.`,
    why_iux_box_description_4: `Kami bekerja sama dengan kedua bank domestik dan asing. Anda bisa mendapatkan kredit bonus Untuk mengurangi risiko sebanyak mungkin dengan kredit bonus.`,
    //
    section_4_header: `Apa yang kita harus menawarkan lebih banyak.`,
    section_4_description_1: `Copytrade Dengan Eiloy platfrom perdagangan teknologi sosial sebagai Mitra kami.`,
    section_4_description_2: `Perdagangan platform dan alat yang tersedia untuk Anda.`,
    section_4_more_detail: `lebih`,

    become_our: `MULAI DENGAN 30 $ GRATIS`,
    become_demo: "TRY A FREE DEMO",
    become_title: "Start trading",
    open_account_now: `Buka sekarang`,
    title_iux_team: `Tim IUX Markets`,
    //
    //
    land_section_1_1: "Keuntungan Spread Rendah",
    land_section_1_2: `Perbedaan yang Anda cari adalah Spread Rendah. Trading dengan spread mulai dari 0.0 PIPs*, tanpa requote, dengan tarif terbaik, dan tanpa batasan. IUX Markets adalah penyedia CFDs online terbaik untuk para scalper, robot, dan sistem perdagangan bervolume tinggi.`,
    //
    land_section_2_1: `Mulai dengan `,
    land_section_2_1_1: `IUX Markets memberikan spread rata-rata EURUSD sebesar 0.0 yang merupakan spread terbaik di dunia`,
    land_section_2_1_2: `Spread rendah mulai dari 0.0 PIPs`,
    land_section_2_1_3: `Campuran likuiditas kami yang beragam dan eksklusif menjaga spread tetap ketat 24/7`,
    //
    land_section_3_1: `Trading kelas `,
    land_section_3_2: `institusional`,
    land_section_3_1_1: `Likuiditas nyata, dalam, dan beragam yang dapat Anda perdagangkan`,
    land_section_3_1_2: `Slippage yang dikurangi`,
    land_section_3_1_3: `Lebih dari 1.5 miliar USD dalam perdagangan CFDs diproses setiap hari`,
    //
    land_section_4_1: `EKSEKUSI ORDER `,
    land_section_4_2: `CEPAT`,
    land_section_4_1_1: `Kecepatan eksekusi rata-rata di bawah 30ms`,
    land_section_4_1_2: `Fiber optik berlatensi rendah dan server Equinix LD4`,
    land_section_4_1_3: `VPS berlatensi rendah yang terkolokasi tersedia secara gratis`,
    //
    land_section_5_1: `BERI KEUNGGULAN \nSISTEM PERDAGANGAN \nOTOMATIS ANDA`,
    land_section_5_2: `IUX Markets adalah salah satu pilihan terbaik untuk trader otomatis. Mesin pencocokan pesanan kami yang terletak di pusat data Equinix LD4 London, memproses lebih dari 500,000 perdagangan per hari dengan lebih dari 60% dari semua perdagangan berasal dari sistem perdagangan otomatis.`,
    land_section_5_1_1: `MARKET TRADING DI London`,
    land_section_5_1_2: `Equinix LD4`,
    land_section_5_1_3: `TRADER PER HARI`,
    land_section_5_1_4: `250,000`,
    land_section_5_1_5: `PERDAGANGAN ALGORITMIK (% DARI SEMUA PERDAGANGAN)`,
    land_section_5_1_6: `60%`,
    //
    land_section_6_1: `TRADING DI \nMOBILE`,
    land_section_6_2: `Trading pasar CFDs pada aplikasi seluler kami dan manfaatkan infrastruktur perdagangan berlatensi ultra rendah, eksekusi order cepat, dan likuiditas dalam.`,
    land_section_6_3: `Tersedia untuk perangkat iOS dan Android.`,
    land_section_6_3_1: `Tersedia untuk`,
    land_section_6_3_2: `IOS`,
    land_section_6_3_3: `dan`,
    land_section_6_3_4: `Android`,

    land_section_6_1_1: `Dompet IUX Markets yang Aman`,
    land_section_6_1_2: `Metatrader 5`,
    land_section_6_1_3: `Berbagai metode pembayaran`,
    land_section_6_1_4: `Pembaruan acara ekonomi`,
    //
    land_section_7_1: `MARKET GLOBAL DI`,
    land_section_7_2: `UJUNG JARI ANDA`,
    land_section_7_3: `produk`,
    land_section_7_3_1: `Pasaran CFDs`,
    land_section_7_3_2: `Komoditas`,
    land_section_7_3_3: `Saham`,
    land_section_7_3_4: `Indeks`,
    land_section_7_3_5: `Obligasi`,
    //
    land_section_8_1: `HATI KAMI \nADALAH STABILITAS \nDAN KECEPATAN \nDI ATAS SEMUA`,
    land_section_8_2: `IUX Markets adalah penyedia CFDs terbesar di dunia berdasarkan volume CFDs`,
    land_section_8_1_1: `VOLUME PERDAGANGAN`,
    land_section_8_1_2: `$ 0.5 Triliun`,
    land_section_8_1_3: `KLIEN AKTIF`,
    land_section_8_1_4: `LEBIH DARI 250,000`,
    land_section_8_1_5: `Penarikan cepat`,
    land_section_8_1_6: `Instan`,
    //
    land_section_9_1: `TERMINAL`,
    land_section_9_2: `PERDAGANGAN YANG KUAT`,
    //
    land_section_10_1: `UANG ANDA`,
    land_section_10_2: `CARA ANDA`,
    land_section_10_1_1: `Deposit Instan`,
    land_section_10_1_2: `Transfer dana Anda secara aman dengan Komisi 0%`,
    land_section_10_1_3: `Penarikan Cepat`,
    land_section_10_3: `DANA KLIEN TERSEGREGASI`,
    // check
    land_section_11_1: `IUX Markets MT5`,
    land_section_11_2: `Biaya di bawah 10%`,
    land_section_11_3: `Platform Kuat untuk CFDs dan Bursa`,
    land_section_11_4: `MetaTrader 5 adalah pilihan terbaik untuk trader modern.`,
    land_section_11_5: `Trading yang sukses dimulai dengan trading yang nyaman dan praktis.`,
    cookie_landing_page: `Kami menggunakan cookies untuk meningkatkan pengalaman pengguna yang mengunjungi situs web kami dan untuk mendukung efektivitas informasi dan konten yang akan dilihat pengunjung kami.`,
  },
  zh: {
    banner_title: `通过IUX Markets上速度最快的交易`,
    banner_content: `速度是外汇交易的一个重要方面。我们speedly交易的技术领先方面。我们有最好的交易创新。`,
    banner_buttonText: `先从$ 30`,
    banner_buttonText_standardx: "START WITH Standard",
    banner_buttonText2: "Open an Account Here",
    banner_slide_text1: "Low spread",
    banner_slide_text2: "Sharp price",
    banner_slide_text3: "250+ Instruments",
    banner_slide_text4: "24/7 Support",
    banner_slide_text5: "7+ Years of experiences",
    leftTextBanner_1: `获得免费`,
    rightTextBanner_1: `30 $`,
    leftTextBanner_2: `存款`,
    rightTextBanner_2: `50％`,
    leftTextBanner_3: `奖金`,
    rightTextBanner_3: `$ 3,500`,
    section2_label_1: `交易工具`,
    section2_label_2: `差低至0.0点子`,
    section2_label_3: `杠杆作用`,
    section2_label_4: `帐户`,
    section2_label_5: "Commission",
    title_how_to_use: `易开户4个步骤`,
    tutorials_title_section_1: `与IUX Markets.com注册`,
    tutorials_title_section_2: `开户`,
    tutorials_title_section_3: `安装MetaTrader 4的`,
    tutorials_title_section_4: `贸易`,
    tutorialsteam_section_1: `测试'`,
    tutorialsteam_section_2: `测试'`,
    tutorialsteam_section_3: `测试'`,

    tutorials_description_section_1: `帮助IUX Markets`,
    tutorials_description_section_2: "",
    tutorials_description_section_3: "",
    tutorials_description_section_4: "",
    cover_title_section_1: `获得贸易最`,
    cover_title_section_2: `仅由一个智能手机进行交易。`,
    cover_title_section_3: `获得贸易最`,

    cover_content_section_1: `当你开始IUX Markets交易，你不必对安全的担心。您还可以得到特别优惠的IUX Markets成员`,
    cover_content_section_button: "全部视频",
    cover_content_section_2: `当你开始IUX Markets交易，你不必对安全的担心。您还可以得到特别优惠的IUX Markets成员`,
    cover_content_section_3: `开幕 -  MT4平台上的IUX Markets然后下载MT4交易，然后通过你的手机。贸易项目，你有信心，因为你是在各个行业满足可以撤回尽快。是马上把钱`,

    //
    why_iux_box_title_1: `安全标准`,
    why_iux_box_title_2: `最快的存款 - 取款。`,
    why_iux_box_title_3: `得到了快速的订单执行。`,
    why_iux_box_title_4: `获得奖金积分高达$ 500。`,

    why_iux_box_description_1: `我们会确保您的财务信息的安全。加密和欺诈行为，每24小时监控`,
    why_iux_box_description_2: `我们给予重视，迅速方便的资金。这很容易和快速地存取款`,
    why_iux_box_description_3: `在VPS服务器都位于相同的数据中心为交易服务器，ping一台服务器的交易速度非常快（0.4  -  1.25毫秒），所以报价立刻到达和贸易商的订单没有延迟地发送到服务器。`,
    why_iux_box_description_4: `我们与国内和外资银行合作。你可以得到的奖金积分对于通过奖金积分，尽可能降低风险。`,
    //
    section_4_header: `我们已经向您提供更多。`,
    section_4_description_1: `Copytrade随着Eiloy洗车台的技术交易的社会作为我们的合作伙伴。`,
    section_4_description_2: `交易平台，并提供工具给你。`,
    section_4_more_detail: `更多`,

    become_our: `$30免费`,
    become_demo: "免费试试 Demo 账户",
    become_title: "开始贸易就收到免费",
    open_account_now: `现在开门了`,
    title_iux_team: `IUX Markets团队`,
    //
    land_section_1_1: "低点差优势",
    land_section_1_2: `您一直在寻找的是低点差。在最好的价格、没有重新报价的情况下，以及没有限制的情况下，进行点差从0.0 PIPs*起的交易。IUX Markets是最适合剥头皮交易、机器人和高交易量交易系统的最佳在线差价合约提供商。`,
    //
    land_section_2_1: `从这里开始`,
    land_section_2_1_1: `IUX Markets提供的EURUSD平均点差为0.0，是世界上最好的点差`,
    land_section_2_1_2: `低点差从0.0 PIPs起`,
    land_section_2_1_3: `我们多样化和专有的流动性组合保持24/7的点差紧密`,
    //
    land_section_3_1: `机构级别交易`,
    land_section_3_2: ``,
    land_section_3_1_1: `您可以进行真实、深刻、多样化的流动性交易`,
    land_section_3_1_2: `减少滑点`,
    land_section_3_1_3: `每天处理超过15亿美元的差价合约交易`,
    //
    land_section_4_1: `快速下单`,
    land_section_4_2: `执行`,
    land_section_4_1_1: `平均执行速度低于30毫秒`,
    land_section_4_1_2: `低延迟光纤和Equinix LD4服务器`,
    land_section_4_1_3: `免费提供低延迟的协同VPS`,
    //
    land_section_5_1: `为您的`,
    land_section_5_2: `自动交易系统`,
    land_section_5_1_1: `IUX Markets是自动交易者的最佳选择之一。我们位于伦敦Equinix LD4数据中心的订单匹配引擎，每天处理超过50万笔交易，其中超过60%的交易来自自动交易系统。`,
    land_section_5_1_2: `伦敦的交易中心`,
    land_section_5_1_3: `每天的交易者数量`,
    land_section_5_1_4: `250,000`,
    land_section_5_1_5: `算法交易（所有交易的比例）`,
    land_section_5_1_6: `60%`,
    //
    land_section_6_1: `在移动上进行交易`,
    land_section_6_2: `在我们的移动应用程序上交易差价合约市场，并从超低延迟的交易基础设施、快速的订单执行和深度流动性中受益。`,
    land_section_6_3: `适用于iOS和Android设备。`,
    land_section_6_3_1: `适用于`,
    land_section_6_3_2: `iOS`,
    land_section_6_3_3: `和`,
    land_section_6_3_4: `Android`,

    land_section_6_1_1: `安全的IUX Markets钱包`,
    land_section_6_1_2: `MetaTrader 5`,
    land_section_6_1_3: `各种支付方式`,
    land_section_6_1_4: `更新的经济事件`,
    //
    land_section_7_1: `全球市场`,
    land_section_7_2: `触手可及`,
    land_section_7_3: `产品`,
    land_section_7_3_1: `差价合约市场`,
    land_section_7_3_2: `大宗商品`,
    land_section_7_3_3: `股票`,
    land_section_7_3_4: `指数`,
    land_section_7_3_5: `债券`,
    //
    land_section_8_1: `我们的优势是稳定和速度`,
    land_section_8_2: `IUX Markets是全球最大的差价合约供应商，按差价合约交易量计算`,
    land_section_8_1_1: `交易量`,
    land_section_8_1_2: `$ 0.5万亿`,
    land_section_8_1_3: `活跃客户数量`,
    land_section_8_1_4: `超过250,000`,
    land_section_8_1_5: `快速提款`,
    land_section_8_1_6: `即时`,
    //
    land_section_9_1: `强大的`,
    land_section_9_2: `交易终端`,
    //
    land_section_10_1: `您的资金`,
    land_section_10_2: `您的方式`,
    land_section_10_1_1: `即时存款`,
    land_section_10_1_2: `以0%佣金安全转移您的资金`,
    land_section_10_1_3: `快速提款`,
    land_section_10_3: `隔离客户资金`,
    //
    land_section_11_1: `IUX Markets MT5`,
    land_section_11_2: `低于10%的费用`,
    land_section_11_3: `差价合约和交易市场的强大平台`,
    land_section_11_4: `MetaTrader 5是现代交易者的最佳选择。`,
    land_section_11_5: `成功的交易始于便捷而实用的交易。`,
    cookie_landing_page: `我們使用 cookie 來改善訪問我們網站的用戶體驗，並支持我們的訪問者將看到的信息和內容的有效性。閱讀更多。`,
  },
  vi: {
    banner_title: "Giao dịch với chênh lệch thấp bắt đầu từ 0.0 PIPs",
    banner_content: `Spread thấp là điều quan trọng trong giao dịch CFDs Chúng tôi là những người dẫn đầu về công nghệ giao dịch spread thấp và chúng tôi có cải tiến giao dịch tốt nhất!`,
    banner_buttonText: "Tài khoản nhận 30$",
    banner_buttonText_standardx: "Tài khoản Standard",
    banner_buttonText2: "Giao dịch với chênh lệch 0.0 PIPs",
    banner_slide_text1: "Low spread",
    banner_slide_text2: "Sharp price",
    banner_slide_text3: "250+ Instruments",
    banner_slide_text4: "24/7 Support",
    banner_slide_text5: "7+ Years of experiences",
    leftTextBanner_1: "Miễn phí",
    rightTextBanner_1: "30$",
    leftTextBanner_2: "Tiền thưởng nạp",
    rightTextBanner_2: "35%",
    leftTextBanner_3: "Tiền thưởng tối đa",
    rightTextBanner_3: "3,500$",
    section2_label_1: "Tiền thưởng nạp",
    section2_label_2: "Spread thấp nhất 0.0 PIPs",
    section2_label_3: "Leverage ",
    section2_label_4: "Tài khoản",
    section2_label_5: "Hoa hồng",
    //
    title_how_to_use: "Cách sử dụng IUX Markets",
    tutorials_title_section_1: "Đăng ký với IUX Markets.com",
    tutorials_title_section_2: "Mở tài khoản giao dịch",
    tutorials_title_section_3: "Cài đặt  Meta Trader 4",
    tutorials_title_section_4: "Bắt đầu giao dịch",
    tutorials_description_section_1: "Cách sử dụng  IUX Markets",
    tutorials_description_section_2:
      "Chọn loại tài khoản giao dịch bạn muốn và sẽ có các tài khoản Standard và ECN",
    tutorials_description_section_3:
      "Cài đặt trên Smartphone hoặc trên PC rồi đăng nhập mã. mà bạn nhận được khi mở tài khoản giao dịch.",
    tutorials_description_section_4:
      "với tốc độ nhanh và công nghệ giao dịch mạnh mẽ nhất của chúng tôi.",
    //
    cover_title_section_1: "Tiếp cận giao dịch  nhiều nhất",
    cover_title_section_2:
      "Bạn có thể giao dịch ở bất kỳ đâu chỉ với một điện thoại di động.",
    cover_title_section_3: `Tiếp cận giao dịch  nhiều nhất
      Khi bạn bắt đầu giao dịch với IUX Markets ngoài ra, bạn sẽ không lo lắng về vấn đề bảo mật +
      Bạn cũng nhận được ưu đãi rất đặc biệt đối với thành viên  IUX Markets (bắt đầu giao dịch)`,
    cover_content_section_button: "Xem đầy đủ video tại đây.",
    cover_content_section_2:
      "Khi bạn bắt đầu giao dịch với IUX Markets ngoài ra, bạn sẽ không lo lắng về vấn đề bảo mật " +
      "Bạn cũng nhận được ưu đãi rất đặc biệt dành đối với IUX Markets (bắt đầu giao dịch)",
    cover_content_section_3:
      "Mở tài khoản Mua-Bán với IUX Markets trên Nền tảng MT4  sau đó tải xuống Mt4 " +
      "và giao dịch qua điện thoại di động của bạn. Bằng cách chọn giao dịch các sản phẩm mà bạn tin tưởng và có thể rút ngay khi bạn hài lòng với mỗi giao dịch. Điều quan trọng là tiền vào ngay lập tức.",
    why_iux_title: "Tại sao phải chọn  IUX Markets",
    //
    why_iux_box_title_1: "An toàn tiêu chuẩn toàn cầu",
    why_iux_box_title_2: "Nạp-rút tiền nhanh nhất",
    why_iux_box_title_3: "Thực thi lệnh nhanh hơn",
    why_iux_box_title_4: "Nhận tín dụng tiền nạp tối đa 500$",

    why_iux_box_description_1:
      "Chúng tôi giữ thông tin tài chính của bạn an toàn. với mã hóa hàng ngày và giám sát gian lận 24 giờ.",
    why_iux_box_description_2:
      "Chúng tôi nhận thấy rằng việc nạp-rút tiền rất dễ dàng và nhanh chóng. sẽ giúp các nhà giao dịch không bỏ lỡ các cơ hội giao dịch quan trọng yêu cầu rút/nạp tiền dễ dàng, không phức tạp.",
    why_iux_box_description_3:
      "Sứ mệnh của chúng tôi là phục vụ các nhà giao dịch. để có giá tốt nhất Chúng tôi làm việc chăm chỉ trong công nghệ để tăng tính thanh khoản cho khách hàng.",
    why_iux_box_description_4:
      "เราร่วมมือกับธนาคารทั้งในประเทศและต่างประเทศ ให้คุณสามารถรับโบนัสเครดิต จากการฝากเงินเพื่อลดความเสี่ยงให้ได้มากที่สุด",
    //
    section_4_header: "Điều chúng tôi cho bạn",
    section_4_description_1:
      "Công nghệ Copy trade có giao dịch với nhiều bản gốc chất lượng khác nhau để chọn theo tín hiệu giao dịch với Eiloy Platform mà là Công nghệ Social Trade cùng partner với chúng tôi.",
    section_4_description_2:
      "Các công cụ và nền tảng giao dịch theo ý của bạn mua-bán tự tin hơn.",
    section_4_more_detail: "Thông tin bổ sung",

    become_our: "Trở thành một phần với chúng tôi",
    become_demo: "Sử dụng tài khoản Demo",
    become_title: "Bắt đầu giao dịch ngay bây giờ",
    open_account_now: "Mở tài khoản ngay bây giờ",
    title_iux_team: "IUX Markets Team",

    //
    land_section_1_1: `Lợi thế Spread thấp`,
    land_section_1_2: `Lợi thế Spread thấp là sự khác biệt mà bạn đang chờ, giao dịch chênh lệch thấp với chênh lệch bắt đầu từ 0.0 PIPs *, Không có báo giá lại, không có đầu cơ và các hạn chế khác.  Khiến IUX Markets trở thành nhà cung cấp ngoại hối trực tuyến CFDs tốt nhất cho các nhà đầu tư có số lượng giao dịch cao , nhà đầu cơ ngắn hạn và rô bốt.`,
    //
    land_section_2_1: `Công cụ dẫn xuất toàn cầu`,
    land_section_2_1_1: `Chênh lệch trung bình của EURUSD tại EURUSD bằng 0.0 PIPs tốt nhất thế giới.`,
    land_section_2_1_2: `Mức chênh lệch thấp mà không có 'Phí ẩn' có nghĩa là chúng tôi thực sự có thể đạt được 0.0 PIPs `,
    land_section_2_1_3: `Thanh khoản đa dạng của chúng tôi luôn giữ mức chênh lệch chặt chẽ 24/7`,
    //
    land_section_3_1: `Điều kiện giao dịch giống nhau`,
    land_section_3_2: `tổ chức tài chính`,
    land_section_3_1_1: `Thanh khoản hiện tại và đa dạng để giúp giao dịch dễ dàng hơn.`,
    land_section_3_1_2: `Không sử dụng lại tính thanh khoản để sự biến đồng của giá đã giảm.`,
    land_section_3_1_3: `Có thực hiện giao dịch FX hơn 1.5 tỷ đô la hàng ngày.`,
    //
    land_section_4_1: `Thực hiện lệnh`,
    land_section_4_2: `nhanh chóng`,
    land_section_4_1_1: `Tốc độ thực hiện trung bình thấp hơn 30ms***`,
    land_section_4_1_2: `Kết nối chéo qua cáp quang đến LPs.`,
    land_section_4_1_3: `Có nhà tài trợ VPS sử dụng miễn phí,  nếu nhà giao dịch đáp ứng điều kiện.`,
    //
    land_section_5_1: `Nâng cao hệ thống giao dịch tự động của bạn cao lên`,
    land_section_5_2: `IUX Markets là một lựa chọn tốt cho các nhà giao dịch tự động . Công cụ khớp lệnh của chúng tôi đặt tại trung tâm dữ liệu Equinix LD4 ở London, thực hiện  500,000 lệnh trên ngày,  do lệnh giao dịch 2 trong 3 đến từ hệ thống giao dịch tự động.`,
    land_section_5_1_1: `Trung tâm Thương mại London`,
    land_section_5_1_2: `Equinix LD4`,
    land_section_5_1_3: `lần trên ngày`,
    land_section_5_1_4: `250,000`,
    land_section_5_1_5: `ALGO TRADES (% OF ALL TRADES)`,
    land_section_5_1_6: `60%`,
    //
    land_section_6_1: `IUX Markets Application`,
    land_section_6_2: `Thay đổi thị trường CFDs bất kỳ lúc nào với ứng dụng di động của chúng tôi và hưởng lợi từ cơ sở hạ tầng giao dịch có độ trễ thấp, thực hiện lệnh giao dịch từng được nhận giải thưởng và tính thanh khoản sâu sắc.`,
    land_section_6_3: `Sẵn sử dụng đối với thiết bị iOS và Android.`,
    land_section_6_3_1: `Sẵn sử dụng đối với thiết bị`,
    land_section_6_3_2: `IOS`,
    land_section_6_3_3: `và`,
    land_section_6_3_4: `Android`,

    land_section_6_1_1: `Secure IUX Markets wallet`,
    land_section_6_1_2: `Metatrader 5`,
    land_section_6_1_3: `Variety of payment methods`,
    land_section_6_1_4: `Updated economic event`,
    //
    land_section_7_1: `Công cụ phái sinh \ cả thế giới`,
    land_section_7_2: `tại tầm tay của bạn`,
    land_section_7_3: `cái`,
    land_section_7_3_1: `Thị trường ngoại hối`,
    land_section_7_3_2: `Hàng hóa`,
    land_section_7_3_3: `Cổ phiếu`,
    land_section_7_3_4: `Chỉ số`,
    land_section_7_3_5: `Phiếu`,
    //
    land_section_8_1: `Trái tim của chúng tôi là sự ổn định và tốc độ`,
    land_section_8_2: `IUX Markets là nhà cung cấp CFDs lớn nhất thế giới theo khối lượng CFDs ****`,
    land_section_8_1_1: `Khối lượng giao dịch`,
    land_section_8_1_2: `$ 0.5 Trillion`,
    land_section_8_1_3: `Tổng số người dùng`,
    land_section_8_1_4: `OVER 250,000`,
    land_section_8_1_5: `Rút tiền nhanh chóng`,
    land_section_8_1_6: `Instant`,
    //
    land_section_9_1: `POWERFUL`,
    land_section_9_2: `TRADING TERMINALS`,
    //
    land_section_10_1: `YOUR MONEY`,
    land_section_10_2: `YOUR WAY`,
    land_section_10_1_1: `Instant Deposit`,
    land_section_10_1_2: `Transfer your funds securely with 0% Commission`,
    land_section_10_1_3: `Fast Withdrawal`,
    land_section_10_3: `SEGREGATED CLIENT FUNDS`,
    //
    land_section_11_1: `IUX Markets MT5`,
    land_section_11_2: `Phí thấp hơn 10%`,
    land_section_11_3: `Nền tảng mạnh mẽ đối với thị trường CFDs và Exchange`,
    land_section_11_4: `MetaTrader 5 là sự lựa chọn tốt nhất cho các nhà giao dịch hiện đại.`,
    land_section_11_5: `Việc giao dịch thành công bắt đầu bằng một giao dịch thuận tiện và thiết thực.`,
    cookie_landing_page: `Chúng tôi sử dụng cookie để phát triển, cải thiện trải nghiệm sử dụng thông qua truy cập trang web của chúng tôi và hỗ trợ hiệu quả trong việc trình bày thông tin. Người sử dụng có thể nhìn thấy và đọc các chi tiết thêm.`,
  },
  ko: {
    banner_title: `IUX 시장에 의한 speediest 거래`,
    banner_content: `속도는 외환 거래의 중요한 측면이다. 우리는 speedly 거래의 기술 리더 측면입니다. 그리고 우리는 최고의 무역 혁신 있습니다.`,
    banner_buttonText: `30 $로 시작`,
    banner_buttonText_standardx: "스탠다드로 시작",
    banner_buttonText2: "여기에서 계정 열기",
    banner_slide_text1: "낮은 스프레드",
    banner_slide_text2: "경쟁력 있는 가격",
    banner_slide_text3: "250개 이상의 상품",
    banner_slide_text4: "24/7 고객 지원",
    banner_slide_text5: "5년 이상의 경험",
    leftTextBanner_1: `가져 오기 무료`,
    rightTextBanner_1: `30 $`,
    leftTextBanner_2: `예금`,
    rightTextBanner_2: `50 %`,
    leftTextBanner_3: `에 보너스`,
    rightTextBanner_3: `3,500 $`,
    section2_label_1: `무역 악기`,
    section2_label_2: `주사위 0.0의 낮은 스프레드`,
    section2_label_3: `이점`,
    section2_label_4: `계정`,
    section2_label_5: "Commission",
    title_how_to_use: `4 단계의 간편한 계좌 개설`,
    tutorials_title_section_1: `IUX Markets.com에 등록`,
    tutorials_title_section_2: `계좌 개설`,
    tutorials_title_section_3: `메타 트레이더 4를 설치`,
    tutorials_title_section_4: `거래`,
    tutorialsteam_section_1: `테스트'`,
    tutorialsteam_section_2: `테스트'`,
    tutorialsteam_section_3: `테스트'`,

    tutorials_description_section_1: `도움말 IUX Markets`,
    tutorials_description_section_2: "",
    tutorials_description_section_3: "",
    tutorials_description_section_4: "",
    cover_title_section_1: `액세스는 대부분의 무역`,
    cover_title_section_2: `하나의 스마트 폰으로 거래.`,
    cover_title_section_3: `액세스는 대부분의 무역`,

    cover_content_section_1: `당신이 IUX Markets와 거래를 시작하면 보안에 대해 걱정할 필요가 없습니다. 또한 IUX Markets 회원을위한 특별한 거래를 얻을`,
    cover_content_section_button: "전체 비디오",
    cover_content_section_2: `당신이 IUX Markets와 거래를 시작하면 보안에 대해 걱정할 필요가 없습니다. 또한 IUX Markets 회원을위한 특별한 거래를 얻을`,
    cover_content_section_3: `열기 - MT4 플랫폼에 IUX Markets는 MT4 다음 모바일을 통해 거래 다운로드합니다. 당신이 확신하고 각 무역에 만족하는 한 빨리 철회 될 수 있다는 무역 아이템입니다. 돈은 바로인가`,

    //
    why_iux_box_title_1: `안전 기준`,
    why_iux_box_title_2: `가장 빠른 예금 - 철수.`,
    why_iux_box_title_3: `빠른 주문 실행을 가져옵니다.`,
    why_iux_box_title_4: `$ 500 보너스 신용 최대를 가져옵니다.`,

    why_iux_box_description_1: `우리는 당신의 금융 정보의 보안을 유지합니다. 24 시간마다 모니터링 암호화 및 사기`,
    why_iux_box_description_2: `우리는 빨리 쉽게 자금에 대한 중요성을 제공합니다. 그것은 쉽고 빠르게 입출금에의`,
    why_iux_box_description_3: `따옴표 즉시 도착 상인의 주문이 지체없이 서버로 전송되도록, - VPS 서버가 무역 서버 핑, 거래 서버와 동일한 데이터 센터에 위치하여 매우 빠른 (1.25 MS 0.4)입니다.`,
    why_iux_box_description_4: `우리는 국내 및 외국 은행들과 협력한다. 당신은 보너스 신용에 의해 가능한 한 위험을 감소 보너스 크레딧을 얻을 수 있습니다.`,
    //
    section_4_header: `우리는 당신을 더 제공해야합니다.`,
    section_4_description_1: `Copytrade와 Eiloy Platfrom 우리의 파트너 사회적 기술 거래.`,
    section_4_description_2: `당신에게 플랫폼과 사용 가능한 도구를 무역.`,
    section_4_more_detail: `더`,

    become_our: `START 30 $ 무료`,
    become_demo: "무료 데모를 사용해 보세요",
    become_title: "Start trading",
    open_account_now: `지금 열다`,
    title_iux_team: `IUX Markets 팀`,
    //
    land_section_1_1: "낮은 스프레드의 이점",
    land_section_1_2: `찾고 있던 차이는 낮은 스프레드입니다. 0.0 PIPs*에서 시작하는 스프레드로 거래하며, 리퀘트 없이 최고의 비율로 그리고 제한 없이 거래합니다. IUX Markets는 스캘퍼, 로봇 및 대량 거래 시스템을 위한 최고의 온라인 CFD 제공업체입니다.`,
    //
    land_section_2_1: `다음과 함께 시작`,
    land_section_2_1_1: `IUX Markets는 세계 최고의 스프레드인 EURUSD 평균 스프레드 0.0을 제공합니다.`,
    land_section_2_1_2: `0.0 PIPs에서 시작하는 낮은 스프레드`,
    land_section_2_1_3: `다양하고 독점적인 유동성 혼합으로 24/7 스프레드를 유지합니다.`,
    //
    land_section_3_1: `기관`,
    land_section_3_2: `등급 거래`,
    land_section_3_1_1: `거래할 수 있는 실제, 심층, 다양한 유동성`,
    land_section_3_1_2: `슬리피지 감소`,
    land_section_3_1_3: `일일 15억 달러 이상의 CFD 거래 처리`,
    //
    land_section_4_1: `빠른 주문`,
    land_section_4_2: `실행`,
    land_section_4_1_1: `평균 실행 속도는 30ms 이하`,
    land_section_4_1_2: `저렴한 레이턴시 광섬유 및 Equinix LD4 서버`,
    land_section_4_1_3: `무료로 제공되는 저렴한 레이턴시 콜로케이트 VPS`,
    //
    land_section_5_1: `자동화된`,
    land_section_5_2: `거래 시스템에 경계를`,
    land_section_5_1_1: `런던의 거래 허브`,
    land_section_5_1_2: `Equinix LD4`,
    land_section_5_1_3: `하루에 거래자`,
    land_section_5_1_4: `250,000명`,
    land_section_5_1_5: `알고 거래 (% OF ALL TRADES)`,
    land_section_5_1_6: `60%`,
    //
    land_section_6_1: `모바일에서 거래`,
    land_section_6_2: `모바일 애플리케이션에서 CFD 시장을 거래하고 초저지연 거래 인프라, 빠른 주문 실행 및 심층 유동성 혜택을 누리세요.`,
    land_section_6_3: `iOS 및 Android 기기에서 사용 가능합니다.`,
    land_section_6_3_1: `다음에서 사용 가능`,
    land_section_6_3_2: `IOS`,
    land_section_6_3_3: `및`,
    land_section_6_3_4: `Android`,

    land_section_6_1_1: `안전한 IUX Markets 지갑`,
    land_section_6_1_2: `Metatrader 5`,
    land_section_6_1_3: `다양한 결제 수단`,
    land_section_6_1_4: `최신 경제 이벤트`,
    //
    land_section_7_1: `글로벌 시장에서`,
    land_section_7_2: `손끝에`,
    land_section_7_3: `제품`,
    land_section_7_3_1: `CFDs 시장`,
    land_section_7_3_2: `상품`,
    land_section_7_3_3: `주식`,
    land_section_7_3_4: `지수`,
    land_section_7_3_5: `채권`,
    //
    land_section_8_1: `우리의 강점은 안정성과 속도입니다`,
    land_section_8_2: `IUX Markets는 세계 최대의 CFD 제공업체로 CFD 거래량 기준입니다`,
    land_section_8_1_1: `거래량`,
    land_section_8_1_2: `$ 0.5 트리리언`,
    land_section_8_1_3: `활성 클라이언트`,
    land_section_8_1_4: `250,000명 이상`,
    land_section_8_1_5: `빠른 인출`,
    land_section_8_1_6: `즉시`,
    //
    land_section_9_1: `강력한`,
    land_section_9_2: `거래 터미널`,
    //
    land_section_10_1: `당신의 돈`,
    land_section_10_2: `당신의 방식으로`,
    land_section_10_1_1: `즉시 예금`,
    land_section_10_1_2: `수수료 없이 안전하게 자금 이체`,
    land_section_10_1_3: `빠른 인출`,
    land_section_10_3: `분리된 클라이언트 자금`,
    //
    land_section_11_1: `IUX Markets MT5`,
    land_section_11_2: `10% 미만의 수수료`,
    land_section_11_3: `CFD 및 거래 시장을 위한 강력한 플랫폼`,
    land_section_11_4: `MetaTrader 5는 현대 트레이더들에게 가장 좋은 선택입니다.`,
    land_section_11_5: `편리하고 실용적인 거래로 성공적인 거래가 시작됩니다.`,
    cookie_landing_page: `우리는 방문자들의 웹사이트 방문 경험을 개선하고 방문자가 볼 정보와 콘텐츠의 효과를 지원하기 위해 쿠키를 사용합니다.`,
  },
  fil: {
    banner_title: `Ang speediest Trading sa pamamagitan IUX Markets`,
    banner_content: `Ang bilis ay isang mahalagang aspeto ng CFDs kalakalan. Humihingi kami ng teknolohiya ng lider aspeto ng speedly kalakalan. At kami ay may ang pinakamahusay na mga makabagong-likha kalakalan.`,
    banner_buttonText: `Magsimula sa 30 $`,
    banner_buttonText_standardx: "START WITH Standard",
    banner_buttonText2: "Open an Account Here",
    banner_slide_text1: "Low spread",
    banner_slide_text2: "Sharp price",
    banner_slide_text3: "250+ Instruments",
    banner_slide_text4: "24/7 Support",
    banner_slide_text5: "7+ Years of experiences",
    leftTextBanner_1: `Kumuha ng isang libreng`,
    rightTextBanner_1: `30 $`,
    leftTextBanner_2: `deposito`,
    rightTextBanner_2: `35%`,
    leftTextBanner_3: `bonus na`,
    rightTextBanner_3: `3,500 $`,
    section2_label_1: `Deposit Bonus`,
    section2_label_2: `Spread bilang mababang bilang 0.0 PIPs`,
    section2_label_3: `pagkilos`,
    section2_label_4: `account`,
    section2_label_5: "Commission",
    title_how_to_use: `Madaling pambungad na account sa 4 na hakbang`,
    tutorials_title_section_1: `Magrehistro sa IUX Markets.com`,
    tutorials_title_section_2: `pagbubukas ng account`,
    tutorials_title_section_3: `I-install Meta Trader 4`,
    tutorials_title_section_4: `Trading`,
    tutorialsteam_section_1: `test`,
    tutorialsteam_section_2: `test`,
    tutorialsteam_section_3: `test`,

    tutorials_description_section_1: `Tulong IUX Markets`,
    tutorials_description_section_2: "",
    tutorials_description_section_3: "",
    tutorials_description_section_4: "",
    cover_title_section_1: `May access sa ikakalakal ang pinaka`,
    cover_title_section_2: `Kalakalan sa pamamagitan lamang ng isang smartphone.`,
    cover_title_section_3: `May access sa ikakalakal ang pinaka`,

    cover_content_section_1: `Kapag nagsimula ka ng kalakalan na may IUX Markets, hindi mo na kailangang mag-alala tungkol sa seguridad. Ka ring makakuha ng mga espesyal na deal para sa IUX Markets miyembro`,
    cover_content_section_button: "Buong video",
    cover_content_section_2: `Kapag nagsimula ka ng kalakalan na may IUX Markets, hindi mo na kailangang mag-alala tungkol sa seguridad. Ka ring makakuha ng mga espesyal na deal para sa IUX Markets miyembro`,
    cover_content_section_3: `Pagbubukas - Ang IUX Markets sa MT4 platform pagkatapos ay i-download ang Mt4 pagkatapos ay kalakalan sa pamamagitan ng iyong mobile. Ang trade item na ikaw ay may tiwala at maaaring withdraw sa lalong madaling ikaw ay nasiyahan sa bawat trade. Ay ang pera kaagad`,

    //
    why_iux_box_title_1: `Pamantayang pangkaligtasan`,
    why_iux_box_title_2: `Ang pinakamabilis na deposito - withdrawal.`,
    why_iux_box_title_3: `Kumuha ng Mabilis Order execution.`,
    why_iux_box_title_4: `Kumuha ng Bonus Credit hanggang sa $ 500.`,

    why_iux_box_description_1: `Panatilihin namin ang iyong impormasyon sa pananalapi secure. Sa pamamagitan ng pag-encrypt at panloloko sa pagsubaybay sa bawat 24 oras`,
    why_iux_box_description_2: `Binibigyan namin ng kahalagahan upang mabilis na madaling pagpopondo. Ito ay madali at mabilis sa deposito at withdrawal`,
    why_iux_box_description_3: `ang VPS server ay matatagpuan sa parehong data center at pati na ang mga server ng kalakalan, pinging ng kalakalan server ay lubhang mabilis (0.4-1.25 ms), kaya quotes makarating agad at mangangalakal order ay naipadala sa server nang walang pagkaantala.`,
    why_iux_box_description_4: `tumulong kami sa parehong domestic at dayuhang mga bangko. Maaari kang makakuha ng bonus credit Para sa pagbabawas ng panganib hangga't maaari sa pamamagitan ng bonus credit.`,
    //
    section_4_header: `Kung ano ang mayroon kaming mag-alok sa iyo ng karagdagang.`,
    section_4_description_1: `Copytrade Sa Eiloy platfrom ang teknolohiya kalakalan panlipunan bilang aming Partner.`,
    section_4_description_2: `Kalakalan platform at mga tool na magagamit sa iyo.`,
    section_4_more_detail: `pa`,

    become_our: `START MAY 30 $ LIBRE`,
    become_demo: "TRY A FREE DEMO",
    become_title: "Start trading",
    open_account_now: `bukas Ngayon`,
    title_iux_team: `IUX Markets Team`,
    land_section_1_1: "Ang Kakaibang Benepisyo ng Mababang Spread",
    land_section_1_2: `Ang pagkakaiba na matagal mo nang hinahanap ay ang Mababang Spread. Mag-trade nang may spread na nagsisimula sa 0.0 PIPs*, walang mga requote, sa pinakamahusay na rate, at walang mga limitasyon. Ang IUX Markets ay ang pinakamahusay na online na nagbibigay ng CFDs para sa mga scalper, robot, at mga high volume trading system.`,
    //
    land_section_2_1: `Magsimula sa `,
    land_section_2_1_1: `Ang IUX Markets ay nagbibigay ng Avg spread ng EURUSD na 0.0 na itinuturing na pinakamahusay na spread sa buong mundo`,
    land_section_2_1_2: `Mababang spread mula sa 0.0 PIPs`,
    land_section_2_1_3: `Ang aming iba't ibang likas at pribadong mix ng liquidity ay nagpapanatili ng kakaibang kahigpitan ng spread 24/7`,
    //
    land_section_3_1: `Institusyonal`,
    land_section_3_2: `na uri ng kalakalan`,
    land_section_3_1_1: `Tunay, malalim, at magkakaibang liquidity na maaari mong gamitin`,
    land_section_3_1_2: `Pinababa ang slippage`,
    land_section_3_1_3: `Higit sa 1.5 bilyong USD na halaga ng CFDs trades ang naiproseso kada araw`,
    //
    land_section_4_1: `MAKAKAKUHA NG MABILIS NA ORDER`,
    land_section_4_2: `ESEKUSYON`,
    land_section_4_1_1: `Karaniwang bilis ng eksekusyon na mas mababa sa 30ms`,
    land_section_4_1_2: `Mababang latency na fiber optic at Equinix LD4 server`,
    land_section_4_1_3: `Libreng mababang latency na collocated VPS na magagamit`,
    //
    land_section_5_1: `BIGYAN NG \nSARILING \nLAKAS ANG \nINYONG \nAUTOMATED \nTRADING \nSYSTEM`,
    land_section_5_2: `Ang IUX Markets ay isa sa pinakamahusay na pagpipilian para sa mga automated trader. Ang aming order matching engine na matatagpuan sa London Equinix LD4 data center, ay namoproseso ng higit sa 500,000 na mga kalakalan kada araw, at higit sa 60% ng lahat ng kalakalan ay nagmumula sa automated trading systems.`,
    land_section_5_1_1: `TRADING HUB SA London`,
    land_section_5_1_2: `Equinix LD4`,
    land_section_5_1_3: `TRADER KADA ARAW`,
    land_section_5_1_4: `250,000`,
    land_section_5_1_5: `ALGO TRADES (% NG LAHAT NG KALAKALAN)`,
    land_section_5_1_6: `60%`,
    //
    land_section_6_1: `MAG-TRADE SA \nMOBILE`,
    land_section_6_2: `Mag-trade ng CFDs market sa aming mobile application at makinabang mula sa ultra-low latency trading infrastructure, mabilis na eksekusyon ng order, at malalim na liquidity.`,
    land_section_6_3: `Magagamit para sa iOS at Android devices.`,
    land_section_6_3_1: `Magagamit para sa`,
    land_section_6_3_2: `IOS`,
    land_section_6_3_3: `at`,
    land_section_6_3_4: `Android`,

    land_section_6_1_1: `Secure na IUX Markets wallet`,
    land_section_6_1_2: `Metatrader 5`,
    land_section_6_1_3: `Iba't ibang paraan ng pagbabayad`,
    land_section_6_1_4: `Updated na economic event`,
    //
    land_section_7_1: `MGA GLOBAL NA MERCADO SA`,
    land_section_7_2: `INYO'Y NAGLALAKBAY`,
    land_section_7_3: `mga produkto`,
    land_section_7_3_1: `CFDs Market`,
    land_section_7_3_2: `Mga Kalakal`,
    land_section_7_3_3: `Mga Stock`,
    land_section_7_3_4: `Mga Indices`,
    land_section_7_3_5: `Mga Bond`,
    //
    land_section_8_1: `Ang Aming Lakas ay Katatagan at Bilis`,
    land_section_8_2: `Ang IUX Markets ang pinakamalaking Provider ng CFDs sa buong mundo batay sa CFDs volume`,
    land_section_8_1_1: `MGA VOLUME NG KALAKAL`,
    land_section_8_1_2: `$ 0.5 Trilyon`,
    land_section_8_1_3: `AKTIBONG KLIYENTE `,
    land_section_8_1_4: `HIGIT SA 250,000`,
    land_section_8_1_5: `Mabilis na Pag-wiwithdraw`,
    land_section_8_1_6: `Instant`,
    //
    land_section_9_1: `MAKAPANGYARIHANG`,
    land_section_9_2: `MGA TERMINAL NG KALAKALAN`,
    //
    land_section_10_1: `ANG INYONG PERA`,
    land_section_10_2: `ANG INYONG PARAAN`,
    land_section_10_1_1: `Instant Deposit`,
    land_section_10_1_2: `Ilipat ang iyong pondo nang ligtas na may 0% Komisyon`,
    land_section_10_1_3: `Mabilis na Pag-Wiwithdraw`,
    land_section_10_3: `ITINATANGING PONDO NG CLIENTE`,
    //
    land_section_11_1: `IUX Markets MT5`,
    land_section_11_2: `Mga bayad na mas mababa sa 10%`,
    land_section_11_3: `Makapangyarihang Plataporma para sa CFDs at Exchange Markets`,
    land_section_11_4: `Ang MetaTrader 5 ang pinakamahusay na pagpipilian para sa modernong mga trader.`,
    land_section_11_5: `Ang matagumpay na kalakalan ay nagsisimula sa kumportableng at praktikal na kalakaran.`,
    cookie_landing_page: `Gumagamit kami ng cookies upang mapabuti ang karanasan ng user sa pagbisita sa aming website at suportahan ang epektibidad ng impormasyon at nilalaman na makikita ng aming mga bisita.`,
  },
  ja: {
    banner_title: "低い取引は0.0ピップから始まります",
    banner_content:
      "低いトレーディングは0.0から始まります。CFDS取引の重要な側面は低価格です。 私たちは、低価格の取引技術と取引革新のマーケットリーダーです。",
    banner_buttonText: "30ドルのボーナスから始めます",
    banner_buttonText_standardx: "標準から始めます",
    banner_buttonText2: "スプレッド0.0ピップで取引",
    banner_slide_text1: "低スプレッド",
    banner_slide_text2: "急激な価格",
    banner_slide_text3: "250以上の楽器",
    banner_slide_text4: "24時間年中無休のサポート",
    banner_slide_text5: "7年以上の経験",
    leftTextBanner_1: "無料を入手してください",
    rightTextBanner_1: "30ドル",
    leftTextBanner_2: "デポジットボーナス",
    rightTextBanner_2: "35％",
    leftTextBanner_3: "最大",
    rightTextBanner_3: "3,500$",
    section2_label_1: "デポジットボーナス",
    section2_label_2: "低スプレッド",
    section2_label_3: "てこの作用",
    section2_label_4: "アカウント",
    section2_label_5: "手数料",
    title_how_to_use: "4つのステップで簡単なアカウント開設",
    tutorials_title_section_1: "iux Markets.comに登録します",
    tutorials_title_section_2: "スプレッド0.0ピップで取引",
    tutorials_title_section_3: "メタトレーダー4をインストールします",
    tutorials_title_section_4: "取引を開始します",
    tutorialsteam_section_1: "テスト",
    tutorialsteam_section_2: "テスト",
    tutorialsteam_section_3: "テスト",

    tutorials_description_section_1: "IUX市場を支援します",
    tutorials_description_section_2: "",
    tutorials_description_section_3: "",
    tutorials_description_section_4: "",
    cover_title_section_1: "ほとんどの取引へのアクセス",
    cover_title_section_2: "携帯電話は1つだけで取引できます。",
    cover_title_section_3: "ほとんどの取引へのアクセス",

    cover_content_section_1:
      "IUX市場との取引を開始するとき。 セキュリティについて心配する必要はありません。 また、IUX Marketsメンバー（取引を開始）から非常に特別なオファーを受け取ります。",
    cover_content_section_button: "ここで完全なビデオをご覧ください",
    cover_content_section_2:
      "IUX市場との取引を開始するとき。 セキュリティについて心配する必要はありません。 また、IUX Marketsメンバー（取引を開始）から非常に特別なオファーを受け取ります。",
    cover_content_section_3:
      "MT4プラットフォームでIUXマーケットを使用して購入販売アカウントを開き、MT4をダウンロードして携帯電話で取引します。各取引に満足したらすぐに撤回できる製品を取引することができます。  nお金である最も重要なことはすぐに入ります。",

    //
    why_iux_box_title_1: "世界クラスの標準的な安全",
    why_iux_box_title_2: "最速の預金と撤退",
    why_iux_box_title_3: "高速注文実行を取得します。",
    why_iux_box_title_4: "500ドルまでのクレジットデポジットを取得します。",

    why_iux_box_description_1:
      "複雑な暗号化と24時間年中無休の詐欺監視で財務情報を安全に保ちます。",
    why_iux_box_description_2:
      "私たちは、預金 - 迅速かつ簡単なことは、トレーダーが重要な取引の機会を見逃さないのに役立つことを認識しています。 堆積して撤回しやすい最も重要なもの。",
    why_iux_box_description_3:
      "私たちの使命は、最高の価格を得るためにトレーダーにサービスを提供することです。 私たちはお客様の流動性を高めるためにテクノロジーで一生懸命働いています",
    why_iux_box_description_4:
      "私たちは地元の銀行や国際銀行と協力しているため、預金からクレジットボーナスを得ることができ、可能な限りリスク削減を行うことができます。",
    //
    section_4_header: "私たちがあなたのために持っているもの",
    section_4_description_1:
      "コピートレードは、私たちのパートナーとしてソーシャルを取引するテクノロジーのプラットフロムです。",
    section_4_description_2:
      "利用可能なトレーディングプラットフォームとツール。",
    section_4_more_detail: "もっと",

    become_our: "今日のグローバル市場！",
    become_demo: "無料のデモをお試しください",
    become_title: "取引を開始します",
    open_account_now: "0.0ピップで取引",
    title_iux_team: "IUX Marketsチーム",

    //
    land_section_1_1: "スプレッドの低いアドバンテージ",
    land_section_1_2:
      "あなたが探していた違いは低いスプレッドです。 0.0ピップ*から始まるスプレッドとの取引、レクオットなし、最高のレートで、制限なし。 IUX Marketsは、メス、ロボット、大量の取引システムに最適なオンラインCFDSプロバイダーです。",
    //
    land_section_2_1: "皮切りに",
    land_section_2_1_1:
      "IUX市場はEurusd Avgスプレッドを0.0に提供します。これは世界で最高の広がりです",
    land_section_2_1_2: "0.0ピップから低いスプレッドスタート",
    land_section_2_1_3:
      "私たちの多様で独自の流動性ミックスは、24時間年中無休で緊密に広がり続けます",
    //
    land_section_3_1: "制度的",
    land_section_3_2: "グレード取引",
    land_section_3_1_1: "あなたが取引できる本物の、深い、代表的な流動性",
    land_section_3_1_2: "滑りの減少",
    land_section_3_1_3: "毎日処理されたCFDS取引で15億米ドル以上",
    //
    land_section_4_1: "高速注文",
    land_section_4_2: "実行",
    land_section_4_1_1: "30ミリ秒未満の平均実行速度",
    land_section_4_1_2: "低レイテンシファイバーおよびEquinix LD4サーバー",
    land_section_4_1_3: "利用可能な無料の低レイテンシコロケートVP",
    //
    land_section_5_1: " automated  trading  system を与える",
    land_section_5_2:
      "IUX Marketsは、自動化されたトレーダーにとって最良の選択肢の1つです。 ロンドンEquinix LD4データセンターにある注文マッチングエンジンは、自動取引システムからのすべての取引の60％以上が1日あたり500,000を超える取引を処理しています。",
    land_section_5_1_1: "ロンドンでのトレーディングハブ",
    land_section_5_1_2: "Equinix LD4",
    land_section_5_1_3: "1日あたりのトレーダー",
    land_section_5_1_4: "250,000",
    land_section_5_1_5: "アルゴ取引（すべての取引の％）",
    land_section_5_1_6: "60％",
    //
    land_section_6_1: "モバイルで取引",
    land_section_6_2:
      "モバイルアプリケーションでCFDS市場を取引し、超低レイテンシトレーディングインストラクル、迅速な注文実行、および深い流動性の恩恵を受けます。",
    land_section_6_3: "iOSおよびAndroidデバイスで利用できます。",
    land_section_6_3_1: "以下のために利用可能",
    land_section_6_3_2: "iOS",
    land_section_6_3_3: "そして",
    land_section_6_3_4: "アンドロイド",

    land_section_6_1_1: "安全なiux市場ウォレット",
    land_section_6_1_2: "Metatrader 5",
    land_section_6_1_3: "さまざまな支払い方法",
    land_section_6_1_4: "更新された経済イベント",
    //
    land_section_7_1: "でグローバル市場",
    land_section_7_2: "あなたの指先",
    land_section_7_3: "製品",
    land_section_7_3_1: "CFDS市場",
    land_section_7_3_2: "商品",
    land_section_7_3_3: "在庫",
    land_section_7_3_4: "インデックス",
    land_section_7_3_5: "債券",
    //
    land_section_8_1: "私たちの心は安定性と速度です",
    land_section_8_2:
      "IUX Marketsは、CFDSボリュームによって世界最大のCFDSプロバイダーです",
    land_section_8_1_1: "取引量",
    land_section_8_1_2: "0.5兆ドル",
    land_section_8_1_3: "アクティブクライアント",
    land_section_8_1_4: "250,000を超える",
    land_section_8_1_5: "速い撤退",
    land_section_8_1_6: "インスタント",
    //
    land_section_9_1: "強力",
    land_section_9_2: "取引端子",
    //
    land_section_10_1: "あなたのお金",
    land_section_10_2: "あなたのやり方で",
    land_section_10_1_1: "インスタントデポジット",
    land_section_10_1_2: "0％の手数料で資金を安全に譲渡します",
    land_section_10_1_3: "急速な撤退",
    land_section_10_3: "隔離されたクライアントファンド",
    // check
    land_section_11_1: "IUX Markets MT5",
    land_section_11_2: "10％未満の料金",
    land_section_11_3: "CFDおよび交換市場向けの強力なプラットフォーム",
    land_section_11_4: "Metatrader 5は、現代のトレーダーに最適です。",
    land_section_11_5: "取引の成功は、便利で実用的な取引から始まります。",
    cookie_landing_page:
      "Cookieを使用して、当社のWebサイトにアクセスするユーザーエクスペリエンスを向上させ、訪問者が見る情報とコンテンツの有効性をサポートしています。",
  },
  es: {
    banner_title: "Low-spread trading begins from 0.0 PIPs",
    banner_content: `Low-spread trading begins from 0.0 PIPs
    The important aspect of CFDs Trading is low-spread.
    We are the market leader in low-spread trading technology and trading innovations.`,
    banner_buttonText: "Start with $30 bonus",
    banner_buttonText_standardx: "START WITH Standard",
    banner_buttonText2: "Trade with Spread 0.0 PIPs",
    banner_slide_text1: "Low spread",
    banner_slide_text2: "Sharp price",
    banner_slide_text3: "250+ Instruments",
    banner_slide_text4: "24/7 Support",
    banner_slide_text5: "7+ Years of experiences",
    leftTextBanner_1: "Get a free",
    rightTextBanner_1: "$30",
    leftTextBanner_2: "Deposit bonus",
    rightTextBanner_2: "35%",
    leftTextBanner_3: "Maximum",
    rightTextBanner_3: "3,500$",
    section2_label_1: "Deposit Bonus",
    section2_label_2: "Low spread",
    section2_label_3: "Leverage",
    section2_label_4: "Account",
    section2_label_5: "Commission",
    title_how_to_use: "Easy account opening in 4 steps",
    tutorials_title_section_1: "Register with IUX Markets.com",
    tutorials_title_section_2: "Trade with Spread 0.0 PIPs",
    tutorials_title_section_3: "Install Meta Trader 4",
    tutorials_title_section_4: "Start trading",
    tutorialsteam_section_1: "test",
    tutorialsteam_section_2: "test",
    tutorialsteam_section_3: "test",

    tutorials_description_section_1: "Help IUX Markets",
    tutorials_description_section_2: "",
    tutorials_description_section_3: "",
    tutorials_description_section_4: "",
    cover_title_section_1: "Access to the most trades",
    cover_title_section_2: "You can trade anywhere with just one mobile phone.",
    cover_title_section_3: "Access to the most trades",

    cover_content_section_1: `When you start trading with IUX Markets. You won't have to worry about security. You also get a very special offer from IUX Markets members (start trading).`,
    cover_content_section_button: "Watch the full video here",
    cover_content_section_2: `When you start trading with IUX Markets. You won't have to worry about security. You also get a very special offer from IUX Markets members (start trading).`,
    cover_content_section_3: `Open a buy-sell account with IUX Markets on the MT4 platform then download Mt4 and trade on your mobile phone, by choosing to trade products that you are confident in and can withdraw as soon as you are satisfied with each trade. \nThe most important thing that is money goes in immediately.`,

    //
    why_iux_box_title_1: "World class standard safety",
    why_iux_box_title_2: `The fastest deposit and withdrawal`,
    why_iux_box_title_3: "Get Fast Order Execution.",
    why_iux_box_title_4: "Get credit deposit up to 500$.",

    why_iux_box_description_1: `We keep your financial information safe with complicated encryption and 24/7 fraud surveillance.`,
    why_iux_box_description_2: `"We realize that deposit - withdraw fast and easy will help traders don’t miss important trading opportunitie. The most important that is easy to deposit and withdraw.`,
    why_iux_box_description_3: `Our mission is to service traders to get the best price. We work so hard in technology to increase liquidity for our customers`,
    why_iux_box_description_4:
      "We co-operate with local banks and international banks, that makes you can get credit bonus from the deposit, to risk reduction as much as possible.",
    //
    section_4_header: "What we have for you",
    section_4_description_1:
      "Copytrade  With Eiloy Platfrom the technology trading social as our Partner.",
    section_4_description_2: "Trading platforms and tools available to you.",
    section_4_more_detail: "more",

    become_our: "global markets today!",
    become_demo: "TRY A FREE DEMO",
    become_title: "Start trading",
    open_account_now: "Trade with 0.0 PIPs",
    title_iux_team: "IUX Markets Team",

    //
    land_section_1_1: "The Low Spread Advantage",
    land_section_1_2: `The difference you've been looking for is Low Spread. Trade with a spread starting from 0.0 PIPs*, without requotes, at the best rates, and without limitations. IUX Markets is the best online CFDs provider for scalpers,robots and high volume trading systems.`,
    //
    land_section_2_1: `Start with `,
    land_section_2_1_1: `IUX Markets provide EURUSD Avg spread of 0.0 which is the best spread in the world`,
    land_section_2_1_2: `Low spread start from 0.0 PIPs`,
    land_section_2_1_3: `Our diverse and proprietary liquidity mix keeps spread tight 24/7`,
    //
    land_section_3_1: `Institutional`,
    land_section_3_2: `grade trading`,
    land_section_3_1_1: `Real, deep, deverse liquidity you can trade on`,
    land_section_3_1_2: ` Reduced slippage`,
    land_section_3_1_3: ` Over 1.5 billion USD in CFDs trades processed daily`,
    //
    land_section_4_1: `FAST ORDER`,
    land_section_4_2: `EXECUTION`,
    land_section_4_1_1: `Average execution speeds of under 30ms`,
    land_section_4_1_2: `Low latency fibre optic and Equinix LD4 server`,
    land_section_4_1_3: `Free Low latency collocated VPS available`,
    //
    land_section_5_1: `GIVE YOUR \nAUTOMATED \nTRADING \nSYSTEM \nTHE EDGE`,
    land_section_5_2: `IUX Markets is one of the best choices for automated traders. Our order matching engine located in the London Equinix LD4 data center, processes over 500,000 trades per day with over 60% of all trades coming from automated trading systems. `,
    land_section_5_1_1: `TRADING HUB AT London`,
    land_section_5_1_2: `Equinix LD4`,
    land_section_5_1_3: `TRADER PER DAY`,
    land_section_5_1_4: `250,000`,
    land_section_5_1_5: `ALGO TRADES (% OF ALL TRADES)`,
    land_section_5_1_6: `60%`,
    //
    land_section_6_1: `TRADE ON \nMOBILE`,
    land_section_6_2: `Trade CFDs market on our mobile application and benefit from ultra-low latency trading instructure, fast order execution and deep liquidity.`,
    land_section_6_3: `Available for iOS and Android devices.`,
    land_section_6_3_1: `Available for`,
    land_section_6_3_2: `IOS`,
    land_section_6_3_3: `and`,
    land_section_6_3_4: `Android`,

    land_section_6_1_1: `Secure IUX Markets wallet`,
    land_section_6_1_2: `Metatrader 5`,
    land_section_6_1_3: `Variety of payment methods`,
    land_section_6_1_4: `Updated economic event`,
    //
    land_section_7_1: `GLOBAL MARKETS AT`,
    land_section_7_2: `YOUR FINGERTIPS`,
    land_section_7_3: `products`,
    land_section_7_3_1: `CFDs Market`,
    land_section_7_3_2: `Commodities`,
    land_section_7_3_3: `Stocks`,
    land_section_7_3_4: `Indices`,
    land_section_7_3_5: `Bonds`,
    //
    land_section_8_1: `Our heart \nis stability \nand speed \nabove all`,
    land_section_8_2: `IUX Markets is the largest CFDs Provider in the world by CFDs volume`,
    land_section_8_1_1: `TRADING VOLUMES`,
    land_section_8_1_2: `$ 0.5 Trillion`,
    land_section_8_1_3: `ACTIVE CLIENT `,
    land_section_8_1_4: `OVER 250,000`,
    land_section_8_1_5: `Fast withdraw`,
    land_section_8_1_6: `Instant`,
    //
    land_section_9_1: `POWERFUL`,
    land_section_9_2: `TRADING TERMINALS`,
    //
    land_section_10_1: `YOUR MONEY`,
    land_section_10_2: `YOUR WAY`,
    land_section_10_1_1: `Instant Deposit`,
    land_section_10_1_2: `Transfer your funds securely with 0% Commission`,
    land_section_10_1_3: `Fast Withdrawal`,
    land_section_10_3: `SEGREGATED CLIENT FUNDS`,
    // check
    land_section_11_1: `IUX Markets MT5`,
    land_section_11_2: `Fees below 10%`,
    land_section_11_3: `Powerful Platform for CFDs and Exchange Markets`,
    land_section_11_4: `MetaTrader 5 is the best choice for modern traders.`,
    land_section_11_5: `Successful trading starts with convenient and practical trading.`,
    cookie_landing_page:
      "Utilizamos cookies para mejorar la experiencia del usuario al visitar nuestro sitio web y para apoyar la efectividad de la información y el contenido que nuestros visitantes verán.",
  },
};
