import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import scriptLoader from "react-async-script-loader";
import { post } from "../../../../helper/request";
import { message, Modal } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { renderApiDomain } from "../../../../helper/renderApiDomain";
const locale = require("react-redux-i18n").I18n;

let PayPalButton = null;
class PaypalButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showButtons: false,
      loading: true,
      paid: false,
      account_trade_id: props.account.id,
      amount: props.amount,
      reference: "",
    };
    this.ref = React.createRef(null);
    window.React = React;
    window.ReactDOM = ReactDOM;
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;

    if (isScriptLoaded && isScriptLoadSucceed) {
      PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
      this.setState({ loading: false, showButtons: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

    const scriptJustLoaded =
      !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

    if (scriptJustLoaded) {
      if (isScriptLoadSucceed) {
        PayPalButton = window.paypal.Buttons.driver("react", {
          React,
          ReactDOM,
        });
        this.setState({ loading: false, showButtons: true });
      }
    }
  }

  createOrder = (values, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          description: +"",
          amount: {
            currency_code: "THB",
            value: this.props.amount,
          },
        },
      ],
    });
  };

  onApprove = (data, actions) => {
    const self = this;
    return actions.order.capture().then(function (actions) {
      post(renderApiDomain() + "v2/deposit/paypal", {
        account_trade_id: self.props.account.id,
        reference: actions.id,
        amount_thb: self.props.amount,
      })
        .then((res) => {
          if (res.status === 200) {
            message.success(
              "Transaction completed by " + actions.payer.name.given_name
            );
            Modal.info({
              title: "Transaction completed",
              content: (
                <div>
                  <p>Go to Wallet</p>
                </div>
              ),
              onOk() {
                window.location.href =
                  window.origin + `/${localStorage.getItem("locale")}/wallet`;
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  render() {
    const { showButtons, loading, paid } = this.state;
    // if (this.props.paymentState) this.ref.current.click();
    return (
      <div className="main">
        {/* <div className="align-items-center d-flex justify-center">
          <SettingOutlined spin />
          <div className="ml-3">{locale.t("temporarily")}</div>
        </div> */}
        {showButtons && (
          <div className="col-md-12 text-center mb-3">
            <PayPalButton
              // ref={this.ref}
              funding={{
                allowed: [
                  window.paypal.FUNDING.CARD,
                  window.paypal.FUNDING.PAYPAL,
                ],
                disallowed: [window.paypal.FUNDING.CREDIT],
              }}
              createOrder={(data, actions) => this.createOrder(data, actions)}
              onApprove={(data, actions) => this.onApprove(data, actions)}
            />
          </div>
        )}
        {paid && alert("success")}
      </div>
    );
  }
}

const mapStateToProps = ({ user, i18n }) => ({
  locale: i18n.locale,
  user: user,
});

export default scriptLoader(
  `https://www.paypal.com/sdk/js?currency=THB&client-id=AYETSzhALX260Wfgt7J-_O2hNV0t5EUyefj_GUwQQ1w6o69Kg9g4dvlsH2oej_afPJk6LlkZBuWcAG7r&disable-funding=credit,card`
)(connect(mapStateToProps, null)(PaypalButton));
