import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "antd";
const locale = require("react-redux-i18n").I18n;
function ModalRegister({ isOpen, onClose }) {
  return (
    <Modal
      className="w-modal width-modal-openAccountBonus user user-change"
      closable={false}
      visible={isOpen}
      //   style={{ maxWidth: 348 }}
      onCancel={() => {
        onClose();
      }}
      //   footer={[
      //     <div className="d-flex justify-content-between text-center px-5 py-3">
      //       <Button
      //         className="btn-transparent-iux w-100 fw-700 "
      //         key="submit"
      //         type="primary"
      //         // loading={buttonLoading}
      //         // disabled={!isChecked}
      //         style={{}}
      //         onClick={() => {
      //           onClose();
      //         }}
      //       >
      //         {locale.t("register_webinar.btn_not")}
      //       </Button>
      //       <Button
      //         className="btn-primary-new-iux w-100 fw-700"
      //         key="submit"
      //         type="primary"
      //         // loading={buttonLoading}
      //         // disabled={!isChecked}
      //         style={{}}
      //         onClick={() => {
      //           window.location.replace(
      //             `https://www.iux.com/${localStorage.getItem("locale")}/webinar`
      //           );
      //         }}
      //       >
      //         {locale.t("register_webinar.btn_register")}
      //       </Button>
      //     </div>,
      //   ]}
      footer={null}
    >
      <div className="">
        <div className="d-flex justify-content-center w-100 fs-20 fw-700">
          {locale.t("register_webinar.title")}
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column w-100 fs-16 mt-4">
          <p style={{ color: "#A3A3A3" }}>{locale.t("register_webinar.des")}</p>
        </div>
        <div className="d-flex justify-content-between text-center pt-3">
          <Button
            className="btn-transparent-iux w-100 fw-700 "
            key="submit"
            type="primary"
            // loading={buttonLoading}
            // disabled={!isChecked}
            style={{}}
            onClick={() => {
              onClose();
            }}
          >
            {locale.t("register_webinar.btn_not")}
          </Button>
          <Button
            className="btn-primary-new-iux w-100 fw-700"
            key="submit"
            type="primary"
            // loading={buttonLoading}
            // disabled={!isChecked}
            style={{}}
            onClick={() => {
              window.location.replace(
                `https://www.iux.com/${localStorage.getItem("locale")}/webinar`
              );
            }}
          >
            {locale.t("register_webinar.btn_register")}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

ModalRegister.propTypes = {};

export default ModalRegister;
