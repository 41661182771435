import { Button, Input, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";

const SelectRatio = ({ title, setValue, value, select, setSelect }) => {
  return (
    <div className={"select-ration"}>
      <div className={"title-d"}>{title}</div>
      <div
        className={select === "yes" ? "button-d-true" : "button-d"}
        onClick={() => setSelect("yes")}
      >
        <div className={select === "yes" ? "ratio-true" : "ratio"} />
        Yes
      </div>
      <div
        className={select === "no" ? "button-d-true" : "button-d"}
        onClick={() => setSelect("no")}
      >
        <div className={select === "no" ? "ratio-true" : "ratio"} />
        No
      </div>
      {select === "no" && (
        <Input
          placeholder={"If no, please specify why?"}
          size="large"
          onChange={(e) => setValue(e.target.value)}
        />
      )}
    </div>
  );
};

const InputCustom = ({ title, placeholder, setValue, value }) => {
  return (
    <div className={"input-custom"}>
      <div className={"title-d"}>{title}</div>
      <div>
        <Input
          placeholder={placeholder}
          size="large"
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    </div>
  );
};

const InputAreaCustom = ({
  no,
  title,
  highlight,
  placeholder,
  setValue,
  value,
}) => {
  return (
    <div className={"input-custom"}>
      <div className={"title-d"}>
        <span className={"number"}>{no}</span>
        <span className={"highlight"}>{highlight}</span>
        {title}
      </div>
      <div>
        <TextArea
          placeholder={placeholder}
          size="large"
          autoSize={{ minRows: 3, maxRows: 5 }}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    </div>
  );
};

const SelectScore = ({
  no,
  title,
  highlight,
  setValue,
  value,
  lowTitle,
  highTitle,
}) => {
  return (
    <div className={"select-score"}>
      <div>
        <span className={"number"}>{no}</span>
        <span className={"highlight"}>{highlight}</span>
        {title}
      </div>
      <div className={"score-container"}>
        <div
          className={value === 1 ? "score-true" : "score"}
          onClick={() => setValue(1)}
        >
          1
        </div>
        <div
          className={value === 2 ? "score-true" : "score"}
          onClick={() => setValue(2)}
        >
          2
        </div>
        <div
          className={value === 3 ? "score-true" : "score"}
          onClick={() => setValue(3)}
        >
          3
        </div>
        <div
          className={value === 4 ? "score-true" : "score"}
          onClick={() => setValue(4)}
        >
          4
        </div>
        <div
          className={value === 5 ? "score-true" : "score"}
          onClick={() => setValue(5)}
        >
          5
        </div>
      </div>
      <div className={"under-score"}>
        <div>{lowTitle ? lowTitle : "Very Unsatisfied"}</div>
        <div>{highTitle ? highTitle : "Very Satisfied"}</div>
      </div>
    </div>
  );
};

const TraderFairSingapore = () => {
  const [section1, setSection1] = useState();
  const [section2, setSection2] = useState();
  const [section3, setSection3] = useState();
  const [section4, setSection4] = useState();
  const [section5, setSection5] = useState();
  const [section6, setSection6] = useState();
  const [section7, setSection7] = useState();
  const [section8, setSection8] = useState();
  const [section8select, setSection8select] = useState();
  const [section9, setSection9] = useState();
  const [section10, setSection10] = useState();

  const [loading, setLoading] = useState();

  const [success, setSuccess] = useState(false);

  const check =
    !section1 ||
    !section2 ||
    !section3 ||
    !section4 ||
    !section5 ||
    !section6 ||
    !section7 ||
    !(section8 || section8select === "yes") ||
    !section9 ||
    !section10;

  useEffect(() => {
    setSection8();
  }, [section8select]);

  const submit = () => {
    const data = {
      section1: section1,
      section2: section2,
      section3: section3,
      section4: section4,
      section5: section5,
      section6: section6,
      section7: section7,
      section8: section8select === "yes" ? "yes" : section8,
      section9: section9,
      section10: section10,
    };

    setLoading(true);

    fetch(
      "https://script.google.com/macros/s/AKfycbyUbaxKk82c_hPsMyQZon70gg8Rd9TDj9ZShXxAgBZc7Iar2j97R4nVUAkJV4BjcbY/exec?action=addUser",
      {
        redirect: "follow",
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "text/plain;charset=utf-8",
        },
      }
    )
      .then(() => {
        setSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        alert("เกิดข้อผิดพลาด");
      });
  };

  return (
    <>
      <Helmet>
        <title>IUX Markets Traders Fair Singapore 2024</title>
        <meta
          name="description"
          content="Don't miss Traders Fair Singapore 2024 with IUX Markets! Explore insights and networking opportunities."
        />
        <meta name="keywords" content="react, meta tags, seo" />
        <meta
          property="og:title"
          content="IUX Markets Traders Fair Singapore 2024"
        />
        <meta
          property="og:description"
          content="Don't miss Traders Fair Singapore 2024 with IUX Markets! Explore insights and networking opportunities."
        />
        <meta property="og:image" content="/meta_trader-fair-singapore.png" />
        <meta
          property="og:url"
          content="https://www.iuxmarkets.com/trader-fair-singapore"
        />
      </Helmet>
      <div className={"trader-fair-singapore"}>
        <div className={"header"}>
          <div className={"inside"}>
            <img
              src={process.env.PUBLIC_URL + "/assets/images/icons/iux-logo.png"}
              alt={""}
              width={36}
              height={36}
              className="border-logo"
            />
            <div>
              <div>March 2, 2024</div>
              <div>Traders Fair Singapore</div>
            </div>
          </div>
        </div>
        <div className={"content-p"}>
          {success ? (
            <div className={"success-contain"}>
              <div className={"success-title"}>Thank You!</div>
              <svg
                width="100"
                height="100"
                viewBox="0 0 100 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="50"
                  cy="50"
                  r="50"
                  fill="#1DA382"
                  fill-opacity="0.2"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M44.6501 55.3494L66.3335 33.666L69.6333 36.9658L44.6501 61.9491L31.3335 48.6325L34.6333 45.3327L44.6501 55.3494Z"
                  fill="#1DA382"
                />
              </svg>
              <div className={"success-description"}>
                Thank you for taking the time to share your feedback with us.
                Your input is incredibly valuable and will help us enhance our
                services.
              </div>
            </div>
          ) : (
            <>
              <div className={"title"}>Share Your Booth Experience.</div>
              <div className={"description"}>
                Thank you for visiting our booth! Your opinion matters to us.
                Help us improve by sharing your thoughts in our quick survey and
                receive a special souvenir as our token of appreciation.
              </div>
              <SelectScore
                no={"1. "}
                highlight={"Booth Presentation: "}
                title={"Rate the overall appearance and layout of our booth."}
                value={section1}
                setValue={setSection1}
              />
              <SelectScore
                no={"2. "}
                highlight={"Product Information Provided: "}
                title={
                  "Evaluate the clarity and usefulness of the product information."
                }
                value={section2}
                setValue={setSection2}
              />
              <SelectScore
                no={"3. "}
                highlight={"Staff Knowledgeability: "}
                title={
                  "Assess the knowledge level of our staff regarding our products and services."
                }
                value={section3}
                setValue={setSection3}
              />
              <SelectScore
                no={"4. "}
                highlight={"Staff Professionalism: "}
                title={
                  "Rate the professionalism and courtesy of our staff members."
                }
                value={section4}
                setValue={setSection4}
              />
              <SelectScore
                no={"5. "}
                highlight={"Engagement Activities: "}
                title={
                  "Share your thoughts on any interactive or engaging activities at our booth."
                }
                value={section5}
                setValue={setSection5}
              />
              <InputCustom
                title={
                  "6. Which products or services are you most interested in after visiting our booth?"
                }
                placeholder={"Share your interests briefly"}
                value={section6}
                setValue={setSection6}
              />
              <SelectScore
                title={
                  "7. How likely are you to recommend IUX Markets to a colleague or friend?"
                }
                lowTitle={"Very unlikely"}
                highTitle={"Very likely"}
                value={section7}
                setValue={setSection7}
              />
              <SelectRatio
                title={"8. Did the booth meet your expectations?"}
                value={section8}
                setValue={setSection8}
                select={section8select}
                setSelect={setSection8select}
              />
              <InputCustom
                title={
                  "9. What can we do to enhance your experience at future events?"
                }
                placeholder={"Share your thoughts briefly"}
                value={section9}
                setValue={setSection9}
              />
              <InputAreaCustom
                no={"10. "}
                highlight={"Additional Comments or Suggestions: "}
                title={
                  "Please share any further thoughts or suggestions you have."
                }
                placeholder={"Share your feedback"}
                value={section10}
                setValue={setSection10}
              />
              <div
                className={"button-d"}
                style={check ? { opacity: 0.2 } : { opacity: 1 }}
              >
                {loading ? (
                  <Spin />
                ) : (
                  <Button
                    className="btn-primary-new-iux"
                    onClick={submit}
                    disabled={check}
                  >
                    Submit
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
        <div className={"bg"}>
          <img
            src={"/trader-fair-singapore-bg.jpg"}
            width="100%"
            height="100%"
            style={{ objectFit: "cover", objectPosition: "center" }}
            loading="lazy"
          />
        </div>
      </div>
    </>
  );
};

export default TraderFairSingapore;
