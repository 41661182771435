import { useEffect, useState } from "react";
import moment from "moment";
import { getTimeAgoText } from "../../helper/getTimeAgo";
const locale = require("react-redux-i18n").I18n;
const NotificationMessage = ({ type, dataNoti }) => {
  const [open, setOpen] = useState(false);

  const renderIcons = (value) => {
    switch (value) {
      case "accepted":
        return (
          <div className="badge-icons-noti-success">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clip-path="url(#clip0_8300_9815)">
                <path
                  d="M8 16C3.58862 16 0 12.4114 0 8C0 3.58862 3.58862 0 8 0C12.4114 0 16 3.58862 16 8C16 12.4114 12.4114 16 8 16ZM8 1C4.14001 1 1 4.14001 1 8C1 11.86 4.14001 15 8 15C11.86 15 15 11.86 15 8C15 4.14001 11.86 1 8 1Z"
                  fill="#1CB894"
                />
                <path
                  d="M7.25049 10.6665C7.12244 10.6665 6.99451 10.6179 6.89709 10.5199L4.73047 8.35327C4.53516 8.15784 4.53516 7.84119 4.73047 7.64587C4.92578 7.45056 5.24243 7.45056 5.43787 7.64587L7.2511 9.45923L11.2312 5.47925C11.4265 5.28394 11.7432 5.28394 11.9385 5.47925C12.1338 5.67456 12.1338 5.99121 11.9385 6.18652L7.6051 10.5199C7.50647 10.6179 7.37854 10.6665 7.25049 10.6665Z"
                  fill="#1CB894"
                />
              </g>
              <defs>
                <clipPath id="clip0_8300_9815">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        );
      case "rejected":
        return (
          <div className="badge-icons-noti-reject">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.666016 8.00008C0.666016 12.0502 3.94926 15.3334 7.99935 15.3334C12.0494 15.3334 15.3327 12.0502 15.3327 8.00008C15.3327 3.94999 12.0494 0.666748 7.99935 0.666748C3.94926 0.666748 0.666016 3.94999 0.666016 8.00008ZM13.9993 8.00008C13.9993 11.3138 11.3131 14.0001 7.99935 14.0001C4.68564 14.0001 1.99935 11.3138 1.99935 8.00008C1.99935 4.68637 4.68564 2.00008 7.99935 2.00008C11.3131 2.00008 13.9993 4.68637 13.9993 8.00008ZM7.99957 11.999C8.36788 11.999 8.66645 11.7005 8.66645 11.3323C8.66645 10.9641 8.36788 10.6656 7.99957 10.6656C7.63126 10.6656 7.33268 10.9641 7.33268 11.3323C7.33268 11.7005 7.63126 11.999 7.99957 11.999ZM8.66842 3.99898H7.33465V9.33231H8.66842V3.99898Z"
                fill="#E3673F"
              />
            </svg>
          </div>
        );
      case "pending":
        return (
          <div className="badge-icons-noti-progress">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clip-path="url(#clip0_8300_2622)">
                <path
                  d="M9.61547 14.5732C9.48353 14.6061 9.34902 14.6353 9.2155 14.6598C8.86647 14.7247 8.63572 15.0604 8.70028 15.4098C8.73216 15.5816 8.82977 15.7248 8.96229 15.8182C9.09887 15.9144 9.2727 15.9578 9.44983 15.9248C9.60883 15.8953 9.76907 15.8606 9.92632 15.8214C10.271 15.7356 10.481 15.3864 10.395 15.0419C10.3092 14.6971 9.96033 14.4873 9.61547 14.5732Z"
                  fill="#009EFF"
                />
                <path
                  d="M14.3722 5.95178C14.4173 6.08756 14.5033 6.19842 14.6119 6.27501C14.773 6.38848 14.9838 6.42665 15.185 6.36014C15.5223 6.24815 15.705 5.88447 15.5934 5.54738C15.5426 5.3937 15.4862 5.23955 15.4261 5.08946C15.2942 4.75962 14.92 4.59903 14.59 4.73097C14.2604 4.86284 14.0997 5.23707 14.2318 5.56701C14.2822 5.69317 14.3295 5.82266 14.3722 5.95178Z"
                  fill="#009EFF"
                />
                <path
                  d="M11.7056 13.6548C11.5921 13.7297 11.475 13.8022 11.3571 13.8701C11.0493 14.0477 10.9439 14.4411 11.1213 14.7487C11.1695 14.8324 11.2337 14.9008 11.3076 14.9531C11.506 15.0927 11.7755 15.1136 11.9998 14.9844C12.1401 14.9035 12.2796 14.8174 12.4147 14.7281C12.711 14.5323 12.7925 14.1333 12.5967 13.8369C12.4009 13.5403 12.002 13.4589 11.7056 13.6548Z"
                  fill="#009EFF"
                />
                <path
                  d="M15.9929 7.74834C15.9789 7.39333 15.6799 7.11701 15.3248 7.13087C14.9701 7.14487 14.6936 7.44399 14.7075 7.79887C14.7129 7.93455 14.7142 8.07226 14.7111 8.20781C14.7061 8.43044 14.815 8.62889 14.9842 8.74817C15.085 8.81916 15.2074 8.86212 15.34 8.86514C15.6951 8.87302 15.9893 8.59144 15.9971 8.23625C16.0007 8.07429 15.9993 7.9102 15.9929 7.74834Z"
                  fill="#009EFF"
                />
                <path
                  d="M14.2687 11.9629C13.984 11.7493 13.5812 11.8073 13.368 12.0914C13.2864 12.2003 13.2004 12.3077 13.1123 12.4112C12.8823 12.6816 12.9148 13.0876 13.1851 13.3179C13.2005 13.331 13.2161 13.343 13.2322 13.3542C13.501 13.5437 13.8745 13.5002 14.0918 13.2452C14.197 13.1217 14.2994 12.9933 14.397 12.8633C14.6103 12.5792 14.5526 12.1762 14.2687 11.9629Z"
                  fill="#009EFF"
                />
                <path
                  d="M15.2132 9.63754C14.8743 9.53126 14.5133 9.71994 14.4071 10.0589C14.3664 10.1884 14.3214 10.3185 14.2728 10.4459C14.166 10.7264 14.2683 11.0351 14.5028 11.2005C14.5459 11.2307 14.5933 11.2564 14.6448 11.2758C14.9766 11.4025 15.3482 11.236 15.4747 10.904C15.5325 10.7524 15.5861 10.5976 15.6345 10.4437C15.7406 10.1047 15.5521 9.74381 15.2132 9.63754Z"
                  fill="#009EFF"
                />
                <path
                  d="M6.80993 14.6657C6.23486 14.5624 5.68322 14.3866 5.16012 14.1414C5.15393 14.1381 5.1484 14.1344 5.1419 14.1315C5.01863 14.0735 4.89557 14.0113 4.77629 13.9464C4.77588 13.9459 4.77512 13.9456 4.77447 13.9453C4.55562 13.8248 4.34203 13.6914 4.13454 13.5454C1.10884 11.414 0.381345 7.21836 2.51287 4.1927C2.97636 3.53502 3.53725 2.98634 4.16257 2.55146C4.17027 2.5461 4.17797 2.54077 4.18561 2.53537C6.38912 1.01709 9.38114 0.914764 11.7209 2.47494L11.2184 3.20102C11.0787 3.40312 11.1646 3.5504 11.4092 3.52838L13.5921 3.33296C13.837 3.31095 13.9834 3.09911 13.9175 2.86262L13.3314 0.750637C13.2657 0.51387 13.0977 0.485529 12.9579 0.687593L12.4542 1.4154C10.7371 0.26276 8.67765 -0.176758 6.63352 0.177704C6.42764 0.213336 6.22465 0.256948 6.02444 0.307713C6.02289 0.307988 6.02166 0.30816 6.02042 0.308435C6.01268 0.310327 6.00484 0.312838 5.9973 0.314936C4.23462 0.767593 2.6967 1.79566 1.5993 3.26249C1.59005 3.27346 1.58052 3.28419 1.57178 3.29612C1.53529 3.34527 1.49908 3.39555 1.46362 3.44584C1.40563 3.52825 1.34847 3.61272 1.29378 3.69719C1.28693 3.70737 1.28171 3.71772 1.27572 3.72801C0.370133 5.13131 -0.0666682 6.75366 0.00824147 8.40532C0.00841344 8.41076 0.0081039 8.41623 0.00824147 8.4218C0.0154986 8.58314 0.0282931 8.74672 0.0456619 8.90775C0.0465906 8.91813 0.048895 8.92797 0.050649 8.93836C0.0686026 9.10028 0.0911306 9.26259 0.119368 9.42486C0.40635 11.0805 1.18736 12.5703 2.35768 13.7296C2.3604 13.7323 2.36322 13.7353 2.36597 13.7381C2.36693 13.7392 2.368 13.7397 2.36893 13.7407C2.68336 14.0508 3.02537 14.3377 3.39362 14.597C4.35734 15.2761 5.43046 15.725 6.58293 15.9318C6.93261 15.9946 7.26664 15.7618 7.32938 15.4123C7.39208 15.0626 7.15951 14.7283 6.80993 14.6657Z"
                  fill="#009EFF"
                />
                <path
                  d="M7.6046 2.86157C7.31697 2.86157 7.08398 3.09476 7.08398 3.38199V8.56675L11.8259 11.018C11.9022 11.0575 11.9839 11.0762 12.0643 11.0762C12.2527 11.0762 12.4346 10.9736 12.527 10.7946C12.6589 10.5392 12.5591 10.2255 12.3037 10.0936L8.12464 7.93301V3.38199C8.1246 3.09476 7.89189 2.86157 7.6046 2.86157Z"
                  fill="#009EFF"
                />
              </g>
              <defs>
                <clipPath id="clip0_8300_2622">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        );

      default:
        return (
          <div className="badge-icons-noti-success">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clip-path="url(#clip0_8300_9815)">
                <path
                  d="M8 16C3.58862 16 0 12.4114 0 8C0 3.58862 3.58862 0 8 0C12.4114 0 16 3.58862 16 8C16 12.4114 12.4114 16 8 16ZM8 1C4.14001 1 1 4.14001 1 8C1 11.86 4.14001 15 8 15C11.86 15 15 11.86 15 8C15 4.14001 11.86 1 8 1Z"
                  fill="#1CB894"
                />
                <path
                  d="M7.25049 10.6665C7.12244 10.6665 6.99451 10.6179 6.89709 10.5199L4.73047 8.35327C4.53516 8.15784 4.53516 7.84119 4.73047 7.64587C4.92578 7.45056 5.24243 7.45056 5.43787 7.64587L7.2511 9.45923L11.2312 5.47925C11.4265 5.28394 11.7432 5.28394 11.9385 5.47925C12.1338 5.67456 12.1338 5.99121 11.9385 6.18652L7.6051 10.5199C7.50647 10.6179 7.37854 10.6665 7.25049 10.6665Z"
                  fill="#1CB894"
                />
              </g>
              <defs>
                <clipPath id="clip0_8300_9815">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        );
    }
  };

  const renderSubtitle = (sub_title, isValue) => {
    if (isValue) {
      const propsObject = JSON?.parse(isValue);
      sub_title = sub_title?.replace(/{{\w+}}/g, (match) => {
        const variable = match.slice(2, -2);

        if (propsObject.hasOwnProperty(variable)) {
          if (typeof propsObject[variable] === "number") {
            return Math?.abs(propsObject[variable]);
          } else {
            return propsObject[variable];
          }
        }
        return match;
      });
      return sub_title;
    } else {
      return sub_title;
    }
  };
  return (
    <div className={open && "noti-background"}>
      <div className="noti-message-container-unhover">
        <div className="">
          {renderIcons(dataNoti.status)}
          {/* <HistoryToggleOffIcon /> */}
        </div>
        <div className="noti-message-div">
          <div className="noti-message-title">{dataNoti.title}</div>
          <div className="noti-message-descripton">
            {renderSubtitle(dataNoti?.sub_title, dataNoti?.props)}
          </div>
          <div className="noti-message-time read">
            {getTimeAgoText(dataNoti.createdAt)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationMessage;
