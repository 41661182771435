import React from "react";
import { Row, Col } from "antd";
import styled from "styled-components";

const Background = styled.div`
  background-size: cover !important;
`;
const textStyle = {
  color: "white",
  margin: "40px 0",
};

const TextWithCoverPartner = (props) => {
  return (
    <Background className="cover-responsive-800">
      <div
        className="h-100"
        style={{
          backgroundImage: `url("${props.imagePath}")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          className="h-100"
          style={{
            background:
              "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)",
          }}
        >
          <Row className="d-flex container h-100 pt-4 pt-lg-0  w-100">
            <div
              className="d-flex flex-column align-items-center text-center  w-100 container-text-center justify-content-center mx-auto"
              style={{ alignSelf: "center" }}
            >
              <h1
                className="text-uppercase fs-56-lot font-bold-iux"
                style={textStyle}
              >
                {props.title}
              </h1>
              <h1 className="text-uppercase fs-56-lot font-bold-iux text-yellow-new-iux">
                {props.subtitle}
              </h1>
              <h5
                className="text-cneterresponsive-content fs-20  text-center"
                style={textStyle}
              >
                {props.content}
              </h5>
            </div>
          </Row>
        </div>
      </div>
    </Background>
  );
};

const mapStateToProps = ({ i18n }) => ({
  locale: i18n.locale,
});

export default TextWithCoverPartner;
