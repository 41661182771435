export default {
  th: {
    edit: "แก้ไข",
    setting_pages: `การตั้งค่า`,
    payment_method: `วิธีการชำระเงิน`,
    note_setting: `โปรดทราบว่าคุณไม่สามารถเปลี่ยนแปลงหรือแก้ไขข้อมูลส่วนบุคคลได้โดยตรง หากคุณต้องการการเปลี่ยนแปลงหรืออัปเดตใด ๆ เราขอให้คุณติดต่อทีมสนับสนุนเฉพาะของเราเพื่อขอความช่วยเหลือ`,
    otp_verify: `โปรดเลือกวิธีการรับการยืนยัน OTP`,
    main_profile: {
      user_id: `รหัสผู้ใช้`,
      title: `ข้อมูลผู้ใช้`,
      fullName: `ชื่อ - สกุล`,
      Address: `ที่อยู่`,
      emailAddress: `อีเมล`,
      phoneNumber: `เบอร์โทรศัพท์`,
      password: `รหัสผ่าน`,
    },
    modal_otp: {
      title: `เปลี่ยนเบอร์โทรศัพท์`,
      sub_title: `เพิ่มและยืนยันหมายเลขโทรศัพท์ใหม่`,
      label_input: `เบอร์โทรศัพท์`,
      request_again: `ขอรหัสอีกครั้ง`,
      button_otp: `ส่ง OTP ให้ฉัน`,
      title_confirm: `SMS แจ้งรหัส OTP ไปยังหมายเลขโทรศัพท์ที่ลูกค้าให้ไว้`,
      description_confirm: `หากคุณไม่ได้รับ OTP คุณสามารถกดปุ่ม “ขอ OTP อีกครั้ง” เพื่อรับรหัสใหม่`,
      description_confirm_2: `การคลิก "ยืนยัน" แสดงว่าคุณยอมรับข้อกำหนดการใช้งานและนโยบายความเป็นส่วนตัวของ IUX Markets คุณยินยอมที่จะรับสายโทรศัพท์และข้อความ SMS จาก IUX Markets เพื่อให้ข้อมูลอัปเดตเกี่ยวกับคำสั่งซื้อของคุณ หากต้องการหยุดส่งข้อความคำขอ โปรดติดต่อ support@iuxmarkets.com โดยส่งข้อความที่ระบุว่า "หยุดส่ง" ในหัวเรื่อง`,
      button_confirm: `ยืนยันรหัส OTP`,
      confirm: {
        success: `อัพเดทสำเร็จ`,
        error: `OTP ไม่ถูกต้อง`,
      },
      validate: {
        success: `OTP ถูกส่งไปยังเบอร์โทรศัพท์ของคุณ`,
        error: `เบอร์โทรศัพท์ไม่ถูกต้อง`,
      },
    },
    modal_bank: {
      title: `เปลี่ยนบัญชีธนาคาร`,
      description: `การตรวจสอบหลักฐานของธนาคาร (POB) จะช่วยให้เราดำเนินการตามคำขอถอนเงินของคุณได้อย่างราบรื่นและไม่มีความล่าช้า`,
      validate: {
        success: `ยืนยันบัญชีธนาคารสำเร็จ`,
        error: `ยืนยันบัญชีธนาคารไม่สำเร็จ`,
      },
    },
    switch_profile: {
      title: `เปลี่ยนการรับข้อมูล`,
    },
    bank: {
      title: `บัญชีธนาคาร`,
      description: `บัญชีธนาคารที่ใช้สำหรับการฝากและถอนในระบบ คุณต้องยืนยันตัวตนของคุณก่อนทำธุรกรรมใดๆ`,
      btnChange: `เปลี่ยนบัญชีธนาคาร`,
    },
    switchOtp: {
      success: `อัพเดทสำเร็จ`,
      error: `กรุณายืนยันเบอร์โทรศัพท์`,
    },
  },
  lo: {
    edit: "ແກ້ໄຂ",
    setting_pages: `ການຕັ້ງຄ່າ`,
    payment_method: `ວິທີການຊໍາລະເງິນ`,
    note_setting: `ກະລຸນາຮັບຊາບວ່າທ່ານບໍ່ສາມາດປ່ຽນແປງຫຼືດັດແກ້ຂໍ້ມູນສ່ວນຕົວໂດຍກົງ. ຖ້າທ່ານຕ້ອງການການປ່ຽນແປງຫຼືອັບເດດໃດໆ, ພວກເຮົາຂໍສະເຫນີໃຫ້ທ່ານຕິດຕໍ່ທີມງານສະຫນັບສະຫນູນທີ່ອຸທິດຕົນຂອງພວກເຮົາເພື່ອຂໍຄວາມຊ່ວຍເຫຼືອ.`,
    otp_verify: `ກະລຸນາເລືອກວິທີການເພື່ອຮັບການຢັ້ງຢືນ OTP.`,
    main_profile: {
      user_id: `ID ຜູ້ໃຊ້`,
      title: `ຂໍ້ມູນຜູ້ໃຊ້`,
      fullName: `ຊື່​ເຕັມ`,
      Address: `ປະເທດ`,
      emailAddress: `ທີ່​ຢູ່​ອີ​ເມວ`,
      phoneNumber: `ເບີ​ໂທລະ​ສັບ`,
      password: `ລະຫັດຜ່ານ`,
    },
    modal_otp: {
      title: `Change phone number`,
      sub_title: `Add and verify new phone number`,
      label_input: `New Phone number`,
      request_again: `Request code again`,
      button_otp: `Request OTP`,
      title_confirm: `SMS inform OTP code to the phone number provided by the customer.`,
      description_confirm: `If you do not receive the OTP, you can press the button “Request OTP again” to receive a new code.`,
      description_confirm_2: `By clicking “Request OTP” you consent to receive phone calls and SMS messages from IUX Markets regarding updates on your order.`,
      button_confirm: `Confirm OTP`,
      confirm: {
        success: `Update success`,
        error: `Invalid otp number`,
      },
      validate: {
        success: `Request otp success`,
        error: `Invalid phone number`,
      },
    },
    modal_bank: {
      title: `ປັບປຸງບັນຊີທະນາຄານ`,
      description: `ຫຼັກຖານສະແດງການຢັ້ງຢືນຂອງທະນາຄານ (POB) ຈະຊ່ວຍໃຫ້ພວກເຮົາດໍາເນີນການຮ້ອງຂໍການຖອນເງິນຂອງທ່ານໃຫ້ລຽບງ່າຍແລະບໍ່ມີຄວາມຊັກຊ້າໃດໆ.`,
      validate: {
        success: `Update success`,
        error: `Invalid Bank`,
      },
    },
    switch_profile: {
      title: `Switch OTP`,
    },
    bank: {
      title: `ບັນ​ຊີ​ທະ​ນາ​ຄານ`,
      description: `ບັນຊີທະນາຄານທີ່ໃຊ້ສໍາລັບເງິນຝາກແລະການຖອນເງິນໃນລະບົບທີ່ທ່ານຕ້ອງກວດສອບຕົວຕົນຂອງທ່ານກ່ອນທີ່ຈະເຮັດທຸລະກໍາໃດໆ.`,
      btnChange: `ປັບປຸງບັນຊີທະນາຄານ`,
    },
    switchOtp: {
      success: `ປັບປຸງຄວາມສໍາເລັດ`,
      error: `ກະລຸນາກວດສອບເບີໂທລະສັບຂອງທ່ານ.`,
    },
  },
  en: {
    edit: "Edit",
    setting_pages: `Setting`,
    payment_method: `Payment Methods`,
    note_setting: `Please note that you are unable to directly change or edit the personal information. If you require any changes or updates, we kindly request you to contact our dedicated support team for assistance.`,
    otp_verify: `OTP Verification via
    `,
    main_profile: {
      user_id: `User ID`,
      title: `User Information`,
      fullName: `Full name`,
      Address: `Country`,
      emailAddress: `Email address`,
      phoneNumber: `Phone number`,
      password: `Password`,
    },
    modal_otp: {
      title: `Change phone number`,
      sub_title: `Add and verify new phone number`,
      label_input: `New Phone number`,
      request_again: `Request code again`,
      button_otp: `Request OTP`,
      title_confirm: `SMS inform OTP code to the phone number provided by the customer.`,
      description_confirm: `If you do not receive the OTP, you can press the button “Request OTP again” to receive a new code.`,
      description_confirm_2: `By clicking “Request OTP” you consent to receive phone calls and SMS messages from IUX Markets regarding updates on your order.`,
      button_confirm: `Confirm OTP`,
      confirm: {
        success: `Update success`,
        error: `Invalid otp number`,
      },
      validate: {
        success: `Request otp success`,
        error: `Invalid phone number`,
      },
    },
    modal_bank: {
      title: `Update Bank Account`,
      description: `The Proof of Bank (POB) verification will help us to process your withdrawal request smoothly and without any delays.`,
      validate: {
        success: `Update success`,
        error: `Invalid Bank`,
      },
    },
    switch_profile: {
      title: `Switch OTP`,
    },
    bank: {
      title: `Bank Account`,
      description: `Bank account used for deposits and withdrawals in the system You must verify your identity before making any transactions.`,
      btnChange: `Update Bank Account`,
    },
    switchOtp: {
      success: `Update success`,
      error: `Please verify your phone number.`,
    },
  },
  hi: {
    edit: "संपादन करना",
    setting_pages: `सेटिंग`,
    payment_method: `भुगतान की विधि`,
    note_setting: `कृपया ध्यान दें कि आप व्यक्तिगत जानकारी को सीधे बदलने या संपादित करने में असमर्थ हैं। यदि आपको किसी भी बदलाव या अपडेट की आवश्यकता है, तो हम आपसे अनुरोध करते हैं कि आप सहायता के लिए हमारी समर्पित समर्थन टीम से संपर्क करें.`,
    otp_verify: `ओटीपी सत्यापन के माध्यम से`,
    main_profile: {
      user_id: `उपयोगकर्ता पहचान`,
      title: `यूजर जानकारी`,
      fullName: `पूरा नाम`,
      Address: `देश`,
      emailAddress: `मेल पता`,
      phoneNumber: `फ़ोन नंबर`,
      password: `पासवर्ड`,
    },
    modal_otp: {
      title: `Change phone number`,
      sub_title: `Add and verify new phone number`,
      label_input: `New Phone number`,
      request_again: `Request code again`,
      button_otp: `Request OTP`,
      title_confirm: `SMS inform OTP code to the phone number provided by the customer.`,
      description_confirm: `If you do not receive the OTP, you can press the button “Request OTP again” to receive a new code.`,
      description_confirm_2: `By clicking “Request OTP” you consent to receive phone calls and SMS messages from IUX Markets regarding updates on your order.`,
      button_confirm: `Confirm OTP`,
      confirm: {
        success: `Update success`,
        error: `Invalid otp number`,
      },
      validate: {
        success: `Request otp success`,
        error: `Invalid phone number`,
      },
    },
    modal_bank: {
      title: `अद्यतन बैंक खाता`,
      description: `बैंक (POB) सत्यापन का प्रमाण हमें आपके निकासी अनुरोध को सुचारू रूप से और बिना किसी देरी के संसाधित करने में मदद करेगा।`,
      validate: {
        success: `Update success`,
        error: `Invalid Bank`,
      },
    },
    switch_profile: {
      title: `Switch OTP`,
    },
    bank: {
      title: `Bank Account`,
      description: `Bank account used for deposits and withdrawals in the system You must verify your identity before making any transactions.`,
      btnChange: `Update Bank Account`,
    },
    switchOtp: {
      success: `Update success`,
      error: `Please verify your phone number.`,
    },
  },
  ms: {
    edit: "Edit",
    setting_pages: `Menetapkan`,
    payment_method: `cara bayaran`,
    note_setting: `Sila ambil perhatian bahawa anda tidak dapat mengubah atau mengedit maklumat peribadi secara langsung. Sekiranya anda memerlukan sebarang perubahan atau kemas kini, kami meminta anda untuk menghubungi pasukan sokongan khusus kami untuk mendapatkan bantuan.`,
    otp_verify: `Sila pilih kaedah untuk menerima pengesahan OTP`,
    main_profile: {
      user_id: `ID Pengguna`,
      title: `Maklumat pengguna`,
      fullName: `Nama penuh`,
      Address: `Alamat`,
      emailAddress: `Alamat Email`,
      phoneNumber: `Nombor Telefon`,
      password: `Kata Laluan`,
    },
    modal_otp: {
      title: `Tukar nombor telefon`,
      sub_title: `Tambah dan sahkan nombor telefon baharu`,
      label_input: `Nombor telefon`,
      request_again: `Minta kod sekali lagi`,
      button_otp: `Hantarkan OTP kepada saya`,
      title_confirm: `SMS maklumkan kod OTP ke nombor telefon yang diberikan oleh pelanggan.`,
      description_confirm: `Jika anda tidak menerima OTP, anda boleh menekan butang “Minta OTP sekali lagi” untuk menerima kod baharu.`,
      description_confirm_2: `Dengan mengklik "Sahkan", anda bersetuju menerima Syarat Penggunaan dan Dasar Privasi IUX Markets. Anda bersetuju untuk menerima panggilan telefon dan mesej SMS daripada IUX Markets untuk memberikan kemas kini pada pesanan anda. Untuk berhenti menghantar mesej permintaan, sila hubungi support@iuxmarkets.com Dengan menghantar mesej yang mengatakan "berhenti menghantar" dalam baris subjek
      .`,
      button_confirm: `Sahkan OTP`,
      confirm: {
        success: `Berjaya dikemaskini `,
        error: `Nombor otp tidak sah`,
      },
      validate: {
        success: `Permohonan OTP berjaya`,
        error: `Nombor telefon tidak sah`,
      },
    },
    modal_bank: {
      title: `Kemas kini akaun bank`,
      description: `Bukti pengesahan Bank (POB) akan membantu kami memproses permintaan pengeluaran anda dengan lancar dan tanpa sebarang kelewatan.`,
      validate: {
        success: `Berjaya dikemaskini`,
        error: `Akaun Bank tidak sah`,
      },
    },
    switch_profile: {
      title: `Tukar OTP`,
    },
    bank: {
      title: `Akaun Bank`,
      description: `Akaun bank yang digunakan untuk deposit dan pengeluaran dalam sistem anda mesti disahkan identiti dahulu sebelum membuat sebarang transaksi.`,
      btnChange: `Tukar akaun Bank`,
    },
    switchOtp: {
      success: `Berjaya dikemaskini`,
      error: `Sila sahkan nombor telefon anda.`,
    },
  },
  id: {
    edit: "Edit",
    setting_pages: `Pengaturan`,
    payment_method: `cara Pembayaran`,
    note_setting: `Harap dicatat bahwa Anda tidak dapat secara langsung mengubah atau mengedit informasi pribadi. Jika Anda memerlukan perubahan atau pembaruan, kami dengan baik meminta Anda untuk menghubungi tim dukungan khusus kami untuk mendapatkan bantuan.`,
    otp_verify: `Verifikasi OTP via`,
    main_profile: {
      user_id: `identitas pengguna`,
      title: `informasi pengguna`,
      fullName: `Nama lengkap`,
      Address: `Negara`,
      emailAddress: `Alamat email`,
      phoneNumber: `Nomor telepon`,
      password: `Kata sandi`,
    },
    modal_otp: {
      title: `Change phone number`,
      sub_title: `Add and verify new phone number`,
      label_input: `New Phone number`,
      request_again: `Request code again`,
      button_otp: `Request OTP`,
      title_confirm: `SMS inform OTP code to the phone number provided by the customer.`,
      description_confirm: `If you do not receive the OTP, you can press the button “Request OTP again” to receive a new code.`,
      description_confirm_2: `By clicking “Request OTP” you consent to receive phone calls and SMS messages from IUX Markets regarding updates on your order.`,
      button_confirm: `Confirm OTP`,
      confirm: {
        success: `Update success`,
        error: `Invalid otp number`,
      },
      validate: {
        success: `Request otp success`,
        error: `Invalid phone number`,
      },
    },
    modal_bank: {
      title: `Perbarui rekening bank`,
      description: `Verifikasi Bukti Bank (POB) akan membantu kami memproses permintaan penarikan Anda dengan lancar dan tanpa penundaan.`,
      validate: {
        success: `Update success`,
        error: `Invalid Bank`,
      },
    },
    switch_profile: {
      title: `Switch OTP`,
    },
    bank: {
      title: `Bank Account`,
      description: `Bank account used for deposits and withdrawals in the system You must verify your identity before making any transactions.`,
      btnChange: `Update Bank Account`,
    },
    switchOtp: {
      success: `Update success`,
      error: `Please verify your phone number.`,
    },
  },
  zh: {
    edit: "編輯",
    setting_pages: `环境`,
    payment_method: `支付方式`,
    note_setting: `请注意，您无法直接更改或编辑个人信息。 如果您需要任何更改或更新，我们请您与我们的专用支持团队联系以寻求帮助。`,
    otp_verify: `OTP驗證通過`,
    main_profile: {
      user_id: `用戶身份`,
      title: `用戶信息`,
      fullName: `全名`,
      Address: `國家`,
      emailAddress: `電子郵件地址`,
      phoneNumber: `電話號碼`,
      password: `密碼`,
    },
    modal_otp: {
      title: `เปลี่ยนเบอร์โทรศัพท์`,
      sub_title: `Add and verify new phone number`,
      label_input: `New Phone number`,
      request_again: `Request code again`,
      button_otp: `Request OTP`,
      title_confirm: `SMS inform OTP code to the phone number provided by the customer.`,
      description_confirm: `If you do not receive the OTP, you can press the button “Request OTP again” to receive a new code.`,
      description_confirm_2: `By clicking “Request OTP” you consent to receive phone calls and SMS messages from IUX Markets regarding updates on your order.`,
      button_confirm: `Confirm OTP`,
      confirm: {
        success: `Update success`,
        error: `Invalid otp number`,
      },
      validate: {
        success: `Request otp success`,
        error: `Invalid phone number`,
      },
    },
    modal_bank: {
      title: `更新銀行帳戶`,
      description: `銀行證明（POB）驗證將有助於我們順利處理您的提款請求，而無需任何延遲。.`,
      validate: {
        success: `Update success`,
        error: `Invalid Bank`,
      },
    },
    switch_profile: {
      title: `Switch OTP`,
    },
    bank: {
      title: `Bank Account`,
      description: `Bank account used for deposits and withdrawals in the system You must verify your identity before making any transactions.`,
      btnChange: `Update Bank Account`,
    },
    switchOtp: {
      success: `Update success`,
      error: `Please verify your phone number.`,
    },
  },
  vi: {
    edit: "Thay đổi",
    setting_pages: `Cài đặt`,
    payment_method: `Phương thức thanh toán`,
    note_setting: `Xin lưu ý rằng bạn không thể thay đổi trực tiếp hoặc chỉnh sửa thông tin cá nhân. Nếu bạn yêu cầu bất kỳ thay đổi hoặc cập nhật, chúng tôi vui lòng yêu cầu bạn liên hệ với nhóm hỗ trợ chuyên dụng của chúng tôi để được hỗ trợ.`,
    otp_verify: `Vui lòng chọn phương thức để nhận xác minh OTP`,
    main_profile: {
      user_id: `tên người dùng`,
      title: `Thông tin người dùng`,
      fullName: `Tên - Họ`,
      Address: `Địa chỉ`,
      emailAddress: `E-mail`,
      phoneNumber: `Số điện thoại`,
      password: `Mật khẩu`,
    },
    modal_otp: {
      title: `Thay đổi số điện thoạ`,
      sub_title: `Thêm và xác minh số điện thoại mới.`,
      label_input: `Số điện thoại`,
      request_again: `Hỏi lại mật khẩu`,
      button_otp: `Gửi cho tôi OTP `,
      title_confirm: `SMS thông báo mã OTP đến số điện thoại khách hàng cung cấp`,
      description_confirm: `Nếu không nhận được OTP, bạn có thể nhấn nút “Yêu cầu lại OTP” để nhận mã mới.`,
      description_confirm_2: `Cách nhấp "Xác nhận", bạn đồng ý với iều khoản sử dụng và Chính sách quyền riêng tư của IUX Markets. Bạn đồng ý nhận các cuộc gọi điện thoại và tin nhắn SMS từ IUX Markets để cung cấp cho bạn thông tin cập nhật về đơn đặt hàng của bạn. Để ngừng gửi tin nhắn yêu cầu, vui lòng liên hệ support@iuxmarkets.com. Bằng cách gửi một tin nhắn có nội dung "dừng gửi" trong dòng chủ đề.`,
      button_confirm: `Xác nhận OTP`,
      confirm: {
        success: `Cập nhật thành công`,
        error: `OTP không đúng`,
      },
      validate: {
        success: `OTP được gửi đếb số điện thoại của bạn`,
        error: `Số điện thoại không đúng`,
      },
    },
    modal_bank: {
      title: `Cập nhật tài khoản ngân hàng`,
      description: `Bằng chứng xác minh Ngân hàng (POB) sẽ giúp chúng tôi xử lý yêu cầu rút tiền của bạn một cách trơn tru và không có bất kỳ sự chậm trễ nào.`,
      validate: {
        success: `Xác nhận tài khoản thành công`,
        error: `Xác nhận tài khoản không thành công`,
      },
    },
    switch_profile: {
      title: `Thay đổi việc nhận dữ liệu`,
    },
    bank: {
      title: `Tài khoản ngân hàng`,
      description: `Tài khoản ngân hàng dùng để  và rút tiền trong hệ thống. Bạn phải xác minh danh tính của mình trước khi thực hiện bất kỳ giao dịch nào.`,
      btnChange: `Thay đổi tài khoản ngân hàng`,
    },
    switchOtp: {
      success: ` Cập nhật thành công`,
      error: `Vui lòng xác minh số điện thoại.`,
    },
  },
  ko: {
    edit: "편집하다",
    setting_pages: `환경`,
    payment_method: `지불 방법`,
    note_setting: `개인 정보를 직접 변경하거나 편집 할 수 없습니다. 변경 또는 업데이트가 필요한 경우 전용 지원 팀에 문의하여 도움을 요청하십시오.`,
    otp_verify: `OTP 인증을 받을 방법을 선택하세요.`,
    main_profile: {
      user_id: `사용자 ID`,
      title: `사용자 정보`,
      fullName: `전체 이름`,
      Address: `국가`,
      emailAddress: `이메일 주소`,
      phoneNumber: `전화 번호`,
      password: `비밀번호`,
    },
    modal_otp: {
      title: `전화 번호를 변경하십시오`,
      sub_title: `새 전화 번호를 추가하고 확인하십시오`,
      label_input: `새로운 전화 번호`,
      request_again: `코드를 다시 요청하십시오`,
      button_otp: `Request OTP`,
      title_confirm: `SMS inform OTP code to the phone number provided by the customer.`,
      description_confirm: `If you do not receive the OTP, you can press the button “Request OTP again” to receive a new code.`,
      description_confirm_2: `By clicking “Request OTP” you consent to receive phone calls and SMS messages from IUX Markets regarding updates on your order.`,
      button_confirm: `Confirm OTP`,
      confirm: {
        success: `Update success`,
        error: `Invalid otp number`,
      },
      validate: {
        success: `Request otp success`,
        error: `Invalid phone number`,
      },
    },
    modal_bank: {
      title: `은행 계좌를 업데이트하십시오`,
      description: `은행 (POB) 검증의 증거는 우리가 당신의 철수 요청을 매끄럽게 처리하지 않고 처리하는 데 도움이됩니다.`,
      validate: {
        success: `Update success`,
        error: `Invalid Bank`,
      },
    },
    switch_profile: {
      title: `Switch OTP`,
    },
    bank: {
      title: `Bank Account`,
      description: `Bank account used for deposits and withdrawals in the system You must verify your identity before making any transactions.`,
      btnChange: `Update Bank Account`,
    },
    switchOtp: {
      success: `Update success`,
      error: `Please verify your phone number.`,
    },
  },
  fil: {
    edit: "I -edit",
    setting_pages: `Setting`,
    payment_method: `Mga Paraan ng Pagbabayad`,
    note_setting: `Mangyaring tandaan na hindi mo direktang baguhin o i -edit ang personal na impormasyon. Kung nangangailangan ka ng anumang mga pagbabago o pag -update, mabait kaming humiling sa iyo na makipag -ugnay sa aming nakatuon na koponan ng suporta para sa tulong.`,
    otp_verify: `Mangyaring pumili ng paraan para sa pagtanggap ng OTP verification`,
    main_profile: {
      user_id: `User ID`,
      title: `Profile`,
      fullName: `Buong pangalan`,
      Address: `Country`,
      emailAddress: `Email address`,
      phoneNumber: `Phone number`,
      password: `Password`,
    },
    modal_otp: {
      title: `Change phone number`,
      sub_title: `Add and verify new phone number`,
      label_input: `New Phone number`,
      request_again: `Request code again`,
      button_otp: `Request OTP`,
      title_confirm: `SMS inform OTP code to the phone number provided by the customer.`,
      description_confirm: `If you do not receive the OTP, you can press the button “Request OTP again” to receive a new code.`,
      description_confirm_2: `By clicking “Request OTP” you consent to receive phone calls and SMS messages from IUX Markets regarding updates on your order.`,
      button_confirm: `Confirm OTP`,
      confirm: {
        success: `Update success`,
        error: `Invalid otp number`,
      },
      validate: {
        success: `Request otp success`,
        error: `Invalid phone number`,
      },
    },
    modal_bank: {
      title: `I -update ang bank account`,
      description: `Ang patunay ng pag -verify ng bangko (POB) ay makakatulong sa amin upang maproseso nang maayos ang iyong kahilingan sa pag -alis at walang mga pagkaantala.`,
      validate: {
        success: `Update success`,
        error: `Invalid Bank`,
      },
    },
    switch_profile: {
      title: `Switch OTP`,
    },
    bank: {
      title: `Bank Account`,
      description: `Bank account used for deposits and withdrawals in the system You must verify your identity before making any transactions.`,
      btnChange: `Update Bank Account`,
    },
    switchOtp: {
      success: `Update success`,
      error: `Please verify your phone number.`,
    },
  },
  ja: {
    edit: "編集",
    setting_pages: "設定",
    payment_method: "お支払い方法",
    note_setting:
      "個人情報を直接変更または編集できないことに注意してください。 変更や更新が必要な場合は、専用サポートチームにお問い合わせください。",
    otp_verify: "経由のOTP検証",
    main_profile: {
      user_id: "ユーザーID",
      title: "ユーザー情報",
      fullName: "フルネーム",
      Address: "国",
      emailAddress: "電子メールアドレス",
      phoneNumber: "電話番号",
      password: "パスワード",
    },
    modal_otp: {
      title: "電話番号を変更します",
      sub_title: "新しい電話番号を追加して確認します",
      label_input: "新しい電話番号",
      request_again: "もう一度コードをリクエストします",
      button_otp: "OTPをリクエストします",
      title_confirm: "SMSは、顧客が提供する電話番号にOTPコードに通知します。",
      description_confirm:
        "OTPを受信しない場合は、「OTPを再度リクエストする」ボタンを押して新しいコードを受信することができます。",
      description_confirm_2:
        "「Request OTP」をクリックすると、注文の更新に関してIUX市場から電話とSMSメッセージを受信することに同意します。",
      button_confirm: "OTPを確認します",
      confirm: {
        success: "成功を更新します",
        error: "無効なOTP番号",
      },
      validate: {
        success: "OTPの成功を要求します",
        error: "無効な電話番号",
      },
    },
    modal_bank: {
      title: "銀行口座を更新します",
      description:
        "銀行の証明（POB）の検証は、遅延なしで、撤退要求をスムーズに処理するのに役立ちます。",
      validate: {
        success: "成功を更新します",
        error: "無効な銀行",
      },
    },
    switch_profile: {
      title: "otpを切り替えます",
    },
    bank: {
      title: "銀行口座",
      description:
        "銀行口座は、預金とシステムの撤退に使用され、取引を行う前に身元を確認する必要があります。",
      btnChange: "銀行口座を更新します",
    },
    switchOtp: {
      success: "成功を更新します",
      error: "電話番号を確認してください。",
    },
  },
  es: {
    edit: "Editar",
    setting_pages: "Configuración",
    payment_method: "Métodos de pago",
    note_setting:
      "Ten en cuenta que no puedes cambiar o editar directamente la información personal. Si necesitas realizar algún cambio o actualización, te pedimos amablemente que contactes a nuestro equipo de soporte dedicado para recibir asistencia.",
    otp_verify: "Verificación OTP vía",
    main_profile: {
      user_id: "ID de usuario",
      title: "Información del usuario",
      fullName: "Nombre completo",
      Address: "País",
      emailAddress: "Correo electrónico",
      phoneNumber: "Número de teléfono",
      password: "Contraseña",
    },
    modal_otp: {
      title: "Cambiar número de teléfono",
      sub_title: "Agregar y verificar nuevo número de teléfono",
      label_input: "Nuevo número de teléfono",
      request_again: "Solicitar código nuevamente",
      button_otp: "Solicitar OTP",
      title_confirm:
        "El SMS informa el código OTP al número de teléfono proporcionado por el cliente.",
      description_confirm:
        "Si no recibes el OTP, puedes presionar el botón “Solicitar OTP nuevamente” para recibir un nuevo código.",
      description_confirm_2:
        "Al hacer clic en “Solicitar OTP”, aceptas recibir llamadas telefónicas y mensajes SMS de IUX Markets con respecto a actualizaciones sobre tu pedido.",
      button_confirm: "Confirmar OTP",
      confirm: {
        success: "Actualización exitosa",
        error: "Número OTP inválido",
      },
      validate: {
        success: "Solicitud de OTP exitosa",
        error: "Número de teléfono inválido",
      },
    },
    modal_bank: {
      title: "Actualizar cuenta bancaria",
      description:
        "La verificación de la Prueba de Banco (POB) nos ayudará a procesar tu solicitud de retiro sin problemas y sin demoras.",
      validate: {
        success: "Actualización exitosa",
        error: "Banco inválido",
      },
    },
    switch_profile: {
      title: "Cambiar OTP",
    },
    bank: {
      title: "Cuenta bancaria",
      description:
        "Cuenta bancaria utilizada para depósitos y retiros en el sistema. Debes verificar tu identidad antes de realizar cualquier transacción.",
      btnChange: "Actualizar cuenta bancaria",
    },
    switchOtp: {
      success: "Actualización exitosa",
      error: "Por favor verifica tu número de teléfono.",
    },
  },
};
