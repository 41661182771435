export default {
  th: {
    partner_header: {
      title: "เชิญเพื่อนของคุณเพื่อรับคอมมิชชั่นการซื้อขาย",
      description:
        "แบ่งปันความหลงใหลในการลงทุนของคุณโดยเชิญเพื่อน ๆ มาร่วมเทรดกับคุณและรับคอมมิชชั่นกับทุกคนที่คุณเชิญมาร่วมกับเรา",
      button: "ร่วมเป็นพันธมิตรกับเรา",
    },
    partner_header_list_box_1: {
      title: "ความร่วมมือเพื่อความสำเร็จ",
      text_1: "บอกเล่าความสำเร็จ\n",
      text_2: "รายได้จากการแนะนำนักลงทุน\n",
      text_3: "รับค่าคอมมิชชั่นสูงสุด $25 / Lot\n",
      text_4: "ไม่จำกัดค่าคอมมิชชั่นที่ท่านจะได้รับ\n",
    },
    partner_header_list_box_2: {
      title: "IUX Markets ช่วยลูกค้าของคุณได้อย่างไร\n",
      text_1:
        "การชำระเงินผ่านธนาคารในประเทศโดยไม่เสียค่าธรรมเนียม ที่ดำเนินการอย่างรวดเร็วและทันทีมีความปลอดภัยในการดำเนินการสูง\n",
      text_2: "การถอนเงินที่เข้าในทันที่ โดยไม่มีค่าธรรมเนียมในการถอน\n",
      text_3:
        "ลูกค้าของคุณได้รับโบนัสเงินฝาก 35% สูงสุด 300$ และโบนัสเงินฝาก 25% สูงสุด 3,000$ โดยไม่มีเงื่อนไขการถอน\n",
      text_4:
        "ลูกค้าของคุณใช้โปรแกรม IUX Lotback ที่สามารถใช้เป็นต้นทุนในการเทรดได้และถอนได้ทันที\n",
      text_5:
        "การป้องกันเงินฝากสูงถึง 1,000,000$ และรับประกันหากออเดอร์ของลูกค้าคุณเกิดการรีโควท\n",
      text_6:
        "ค่าธรรมเนียมการซื้อขายที่ต่ำและซื้อขายหุ้นได้สูงถึง 800+ ผลิตภัณฑ์\n",
    },
    partner_header_list_box_3: {
      title: "เพิ่มประสิทธิภาพในการชำระเงินสำหรับพันธมิตรทั่วโลก",
      SectionSubTitle2:
        "พันธมิตรของเราสร้างแพลตฟอร์มใหม่ๆ สำหรับผู้ขายที่ไว้วางใจให้ IUX Markets ประมวลผลการชำระเงิน",
    },
    partner_header_list_box_4: {
      title: "คำถามที่พบบ่อย",
    },
    partner_header_list_box_5: {
      title: "เข้าร่วมโครงการพันธมิตรของเราได้ฟรี เริ่มวันนี้เลย",
      text_1: "โปรดแจ้งข้อมูลเกี่ยวกับธุรกิจและโซลูชันของคุณโดยสังเขป",
      text_2: "เราจะติดต่อกลับเพื่อพูดคุยเกี่ยวกับความต้องการของคุณ",
      text_3: "ผนวกรวม IUX Markets และช่วยให้ผู้ขายของคุณได้รับเงิน",
    },
    form_partner: {
      title: "ร่วมเป็นพันธมิตรกับ IUX Markets",
      text_1:
        "คุณสนใจที่จะร่วมเป็นพันธมิตรกับเราไหมคะ แจ้งข้อมูลเล็กน้อยเกี่ยวกับธุรกิจและโซลูชันของคุณให้เราทราบ แล้วเราจะติดต่อกลับไปเพื่อคุยกันว่า เราจะสนับสนุนคุณและผู้ขายของคุณได้อย่างไรบ้าง\n",
      text_2: "ประสบการณ์ในด้านการลงทุน",
      validateFname: `กรุณาใส่ชื่อของคุณ!`,
      validateLname: `กรุณาใส่นามสกุลของคุณ!`,
      validateEmail: `กรุณาใส่อีเมลของคุณ!`,
      validatePhone: `กรุณาใส่หมายเลขโทรศัพท์ของคุณ!`,
      validateWebsite: `กรุณาใส่เว็บไซต์ของคุณ!`,
      validateNote: `กรุณาใส่หมายเหตุของคุณ!`,
      validateSelect: `กรุณาเลือก`,
    },
    radio_Partner: {
      text_1: "น้อยกว่า 1 ปี",
      text_2: "มากกว่า 1 ปี\n",
      text_3: "มากกว่า 3 ปี\n",
      text_4: "มากกว่า 5 ปี\n",
    },
    partner_question1: {
      header_quest: "โครงการพันธมิตร IUX Markets คืออะไร",
      anwser_1:
        "โครงการพันธมิตรของ IUX Markets เป็นโครงการที่ให้นักเทรดและผู้ลงทุนที่อยู่ในตลาดจริง สามารถบอกต่อผลลัพธ์หรือผลิตภัณฑ์ของทางการเงินของ IUX Markets เพื่อให้เพื่อนของคุณหรือลูกค้าของผู้แนะนำได้ประโยชน์และผลลัพธ์อย่างดีที่สุดจากเรา",
    },
    partner_question2: {
      header_quest:
        "การเป็นพันธมิตรกับ IUX Markets ให้ประโยชน์ต่อธุรกิจของฉันได้อย่างไร",
      anwser_1:
        "ลูกค้าของคุณสามารถซื้อผลิตภัณฑ์ทางการเงินได้มากถึง 800 ชนิดรวม คริปโต รวมถึง 1500 ผลิตภัณฑ์",
      anwser_2:
        "ลูกค้าของคุณได้รับโบนัสเงินฝาก 35% สูงถึง 500% และ 25% สูงถึง 3,000$",
      anwser_3:
        "คุณได้รับค่าคอมมิชชันรวมถึงค่า Swap รายวัน และสรุปรายได้ให้แก่คุณทุกวัน",
      anwser_4: "มีเครื่องมือดูแลและการตลาดให้กับคุณอย่างสมบูรณ์แบบที่สุด",
      anwser_5: "ไม่จำกัดค่าคอมมิชชันต่อคน",
    },
    partner_question3: {
      header_quest: "ใครสามารถเป็นพันธมิตรกับ IUX Markets ได้",
      anwser_1:
        "ทุกคนที่เป็นนักลงทุนและเป็นทักเทรดที่มีประสบการณ์จริงเท่านั้น ที่สามารถเป็นพันธมิตรได้ เนื่องจากเราต้องการผู้แนะนำที่มีประสิทธิภาพสูงสุด ไม่ใช่ทุกคนที่จะเป็นผู้แนะนำได้",
    },
    partner_question4: {
      header_quest: "ฉันสามารถเป็นผู้แนะนำได้หรือไม่?",
      anwser_1: "โปรดติดต่อเราโดยตรงและให้ข้อมูลของคุณแก่เราเพื่อพิจารณา",
    },
    partner_request: {
      success: "การสมัครเสร็จสิ้น กรุณา ตรวจสอบที่อีเมล์",
    },
    howtogetbonus: {
      title_1: "เปิดบัญชี",
      des_1: "ลงทะเบียนบนเว็บไซต์ของเราและเปิดบัญชีซื้อขาย",
      title_2: "ทำการฝากเงิน",
      des_2:
        "ฝากเงินอย่างน้อย $20 ไปยังบัญชีซื้อขายของคุณโดยใช้ระบบการชำระเงินใดๆ ที่คุณต้องการ และรับเงินฟรีเพิ่มอีก $30",
      title_3: "เริ่มซื้อขาย",
      des_3: "ด้วยสเปรดต่ำที่สุดในอุตสาหกรรมโดยไม่มีค่าคอมมิชชันใดๆ",
    },
  },
  lo: {
    partner_header: {
      title: "ເຊີນເພື່ອນໆເຂົ້າມາຄ້າຂາຍ",
      description:
        "ແບ່ງປັນຄວາມມັກໃນການລົງທຶນຂອງທ່ານໂດຍການເຊື້ອເຊີນເພື່ອນມາຊື້ຂາຍກັບທ່ານແລະໄດ້ຮັບຄ່ານາຍຫນ້າກັບທຸກໆຄົນທີ່ທ່ານເຊີນເຂົ້າຮ່ວມກັບພວກເຮົາ",
      button: "ຮ່ວມມືກັບພວກເຮົາ",
    },
    partner_header_list_box_1: {
      title: "ການຮ່ວມມືເພື່ອຄວາມ ສຳ ເລັດ",
      text_1: "ຄວາມສໍາເລັດຢ່າງຫລວງຫລາຍ\n",
      text_2: "รายได้จาก Swap รายวัน\n",
      text_3: "ທ່ານສາມາດໄດ້ຮັບສູງເຖິງ 25$ /Lot.\n",
      text_4: "ຄະນະ ກຳ ມະການການຄ້າທັງ ໝົດ\n",
    },
    partner_header_list_box_2: {
      title: "ເປັນຫຍັງຕ້ອງເລືອກ IUX Markets\n",
      text_1: "ວິທີແກ້ໄຂການ ຊຳ ລະເງິນເພື່ອຄວາມ ໝັ້ນ ຄົງສາກົນ.\n",
      text_2: "ສ້າງຄວາມ ໝັ້ນ ໃຈຂອງລູກຄ້າແລະເພີ່ມຍອດຂາຍ\n",
      text_3: "ເປັນຈັງຫວະໄວຂອງການຖອນເງິນແລະຕະຫລາດເງິນຝາກ.\n",
      text_4:
        "ລູກຄ້າຂອງທ່ານໃຊ້ໂປແກຼມ IUX Lotback, ເຊິ່ງສາມາດໃຊ້ເປັນຄ່າໃຊ້ຈ່າຍໃນການຊື້ຂາຍແລະຈະຖອນເງິນໄດ້ທັນທີ.\n",
      text_5:
        "ການປົກປ້ອງເງິນຝາກສູງເຖິງ 1,000,000 ໂດລາແລະຮັບປະກັນຖ້າ ຄຳ ສັ່ງຂອງລູກຄ້າທ່ານມີຄວາມຜິດພາດໃນການຊື້ຂາຍ.\n",
      text_6:
        "ຄ່າ ທຳ ນຽມການຊື້ຂາຍຕໍ່າແລະການຊື້ຂາຍຫຸ້ນ ສຳ ລັບສິນຄ້າສູງເຖິງ 800+.\n",
    },
    partner_header_list_box_3: {
      title: "ການຈ່າຍເງິນໄຟຟ້າ ສຳ ລັບຄູ່ຮ່ວມງານທົ່ວໂລກ",
      SectionSubTitle2:
        "ຄູ່ຮ່ວມງານຂອງພວກເຮົາສ້າງເວທີທີ່ມີນະວັດຕະ ກຳ ສຳ ລັບພໍ່ຄ້າທີ່ເຊື່ອຖືຕະຫຼາດ IUX ເພື່ອ ດຳ ເນີນການ ຊຳ ລະເງິນຂອງພວກເຂົາ",
    },
    partner_header_list_box_4: {
      title: "ຄໍາ​ຖາມ​ທີ່​ຖືກ​ຖາມ​ເລື້ອຍໆ",
    },
    partner_header_list_box_5: {
      title:
        "ມັນບໍ່ເສຍຄ່າທີ່ຈະເຂົ້າຮ່ວມໂປແກມຄູ່ຮ່ວມງານຂອງພວກເຮົາ. ເລີ່ມຕົ້ນມື້ນີ້",
      text_1: "ບອກພວກເຮົາບາງຢ່າງກ່ຽວກັບທຸລະກິດຂອງທ່ານແລະວິທີແກ້ໄຂ.",
      text_2: "ພວກເຮົາຈະຕິດຕໍ່ເພື່ອສົນທະນາຄວາມຕ້ອງການຂອງທ່ານ",
      text_3: "ລວມ PayPal ແລະຊ່ວຍໃຫ້ພໍ່ຄ້າຂອງທ່ານໄດ້ຮັບເງິນ.",
    },
    form_partner: {
      title: "ເປັນຄູ່ຮ່ວມງານທີ່ມີຕະຫຼາດ IUX.",
      text_1:
        "ທ່ານສົນໃຈທີ່ຈະເປັນຫຸ້ນສ່ວນກັບພວກເຮົາບໍ? ໃຫ້ຂໍ້ມູນນ້ອຍໆແກ່ພວກເຮົາກ່ຽວກັບທຸລະກິດແລະການແກ້ໄຂຂອງທ່ານ. ແລະພວກເຮົາຈະຕິດຕໍ່ຫາທ່ານຄືນເພື່ອປຶກສາຫາລືພວກເຮົາຈະສະ ໜັບ ສະ ໜູນ ທ່ານແລະຜູ້ຂາຍຂອງທ່ານໄດ້ແນວໃດ?\n",
      text_2: "ປະສົບການການລົງທືນ",
      validateFname: `Please input your first name!`,
      validateLname: `Please input your last name!`,
      validateEmail: `Please input your email!`,
      validatePhone: `Please input your number phone!`,
      validateWebsite: `Please input your website!`,
      validateNote: `Please input your note!`,
      validateSelect: `Please selected`,
    },
    radio_Partner: {
      text_1: "ຕ່ ຳ ກວ່າ 1 ປີ",
      text_2: "ຫຼາຍກວ່າ 1 ປີ\n",
      text_3: "ຫຼາຍກວ່າ 3 ປີ\n",
      text_4: "ຫຼາຍກວ່າ 5 ປີ\n",
    },
    partner_question1: {
      header_quest: "ໂປແກມຄູ່ຮ່ວມງານຂອງ IUX Markets ແມ່ນຫຍັງ?",
      anwser_1:
        "ໂປແກມຄູ່ຮ່ວມງານຂອງ IUX Markets ແມ່ນໂຄງການ ສຳ ລັບພໍ່ຄ້າແລະນັກລົງທືນໃນຕະຫລາດຕົວຈິງ. ສາມາດບອກຜົນໄດ້ຮັບຫລືຜະລິດຕະພັນການເງິນຂອງ IUX Markets ເພື່ອໃຫ້ ໝູ່ ເພື່ອນຫຼືລູກຄ້າຂອງຜູ້ສົ່ງຕໍ່ຂອງທ່ານໄດ້ຮັບຜົນປະໂຫຍດແລະຜົນໄດ້ຮັບທີ່ດີທີ່ສຸດຈາກພວກເຮົາ",
    },
    partner_question2: {
      header_quest:
        "ການເປັນຄູ່ຮ່ວມງານຂອງ IUX Markets ຈະເປັນປະໂຫຍດຕໍ່ທຸລະກິດຂອງຂ້ອຍໄດ້ແນວໃດ?",
      anwser_1:
        "ລູກຄ້າຂອງທ່ານສາມາດຊື້ຜະລິດຕະພັນການເງິນໄດ້ເຖິງ 800 ຜະລິດຕະພັນ, ລວມທັງ CFDs ແລະ crypto ລວມທັງຜະລິດຕະພັນ 1500.",
      anwser_2:
        "ລູກຄ້າຂອງທ່ານຮັບໂບນັດເງິນຝາກ 35% ເຖິງ 500% ແລະ 25% ເຖິງ 3,000 ໂດລາ",
      anwser_3:
        "ທ່ານໄດ້ຮັບຄະນະ ກຳ ມະການລວມທັງການແລກປ່ຽນປະ ຈຳ ວັນແລະບົດສະຫຼຸບລາຍຮັບ ສຳ ລັບທ່ານທຸກໆມື້",
      anwser_4: "ມີເຄື່ອງມືການເບິ່ງແຍງແລະກາລະຕະຫຼາດທີ່ຄົບຖ້ວນທີ່ສຸດ ສຳ ລັບທ່ານ",
      anwser_5: "ຄ່າ commission ບໍ່ ຈຳ ກັດ ",
    },
    partner_question3: {
      header_quest: "ໃຜສາມາດຮ່ວມມືກັບຕະຫຼາດ IUX?",
      anwser_1:
        "ທຸກໆຄົນທີ່ເປັນນັກລົງທືນແລະເປັນພໍ່ຄ້າທີ່ມີປະສົບການຕົວຈິງເທົ່ານັ້ນ. ນັ້ນສາມາດເປັນຄູ່ຮ່ວມງານໄດ້ເພາະວ່າພວກເຮົາຕ້ອງການທີ່ປຶກສາທີ່ມີປະສິດທິຜົນທີ່ສຸດບໍ່ແມ່ນທຸກໆຄົນສາມາດເປັນຄູ່ມືໄດ້.",
    },
    partner_question4: {
      header_quest: "ຂ້ອຍສາມາດເປັນຄູ່ຮ່ວມງານໄດ້ບໍ?",
      anwser_1:
        "ກະລຸນາຕິດຕໍ່ພວກເຮົາໂດຍກົງແລະໃຫ້ຂໍ້ມູນຂອງທ່ານແກ່ພວກເຮົາເພື່ອພິຈາລະນາ",
    },
    partner_request: {
      success: "การสมัครเสร็จสิ้น กรุณา ตรวจสอบที่อีเมล์",
    },
    howtogetbonus: {
      title_1: "Open an account",
      des_1: "Register on our website and open a trading account.",
      title_2: "Make a deposit",
      des_2:
        "Deposit with a minimum $20 to your trading account by any payment method, and get an additional $30 Bonus.",
      title_3: "Start trading",
      des_3: "Trade with the lowest spread in the industry without any fees.",
    },
  },
  en: {
    partner_header: {
      title: "Invite your friends to earn trading commissions.",
      description:
        "Share your passion by inviting friends to trade. The more you invite, the more you earn.",
      button: "Become our partner",
    },
    partner_header_list_box_1: {
      title: "Partnerships for Success",
      text_1: "Cooperation for success\n",
      text_2: "Daily swap income\n",
      text_3: "Get commission up to $ 25 / Lot.",
      text_4: "No limit on the commissions you can receive.\n",
    },
    partner_header_list_box_2: {
      title: "HOW IUX MARKETS HELPS YOUR CUSTOMERS",
      text_1:
        "Swift and secure payment with various methods including local banks without any fees.\n",
      text_2: "Immediate withdrawal without fees.\n",
      text_3:
        "Your clients receive 35% deposit bonus up to $300 and 25% deposit bonus up to $3,000 without conditions.\n",
      text_4:
        "Your clients use the IUX Lotback program, which can be used as a trading cost and withdraw immediately",
      text_5: `Deposit protection up to $ 1,000,000 and guaranteed if your orders are re-quoted.`,
      text_6: "Low trading fees and trade stocks for up to 800+ products.",
    },
    partner_header_list_box_3: {
      title: "Improve payment efficiency for partners around the world.",
      SectionSubTitle2: `Our partners create new platforms for merchants who trust in IUX Markets to process their payments.`,
    },
    partner_header_list_box_4: {
      title: "Frequently Asked Questions",
    },
    partner_header_list_box_5: {
      title: "It’s free to join our Partner Program. Get started today.",
      text_1: "Tell us a bit about your business and solution.",
      text_2: "We’ll get in touch to discuss your needs.",
      text_3: "Integrate PayPal and help your merchants get paid.",
    },
    form_partner: {
      title: "Become a partner with IUX Markets.",
      text_1:
        "Are you interested in becoming a partner with us? Give us a little information about your business and solution. And we will contact you back to discuss How can we support you and your sellers?\n",
      text_2: "Investment experience",
      validateFname: `Please input your first name!`,
      validateLname: `Please input your last name!`,
      validateEmail: `Please input your email!`,
      validatePhone: `Please input your number phone!`,
      validateWebsite: `Please input your website!`,
      validateNote: `Please input your note!`,
      validateSelect: `Please selected`,
    },
    radio_Partner: {
      text_1: "Less than 1 year",
      text_2: "More than 1 year",
      text_3: "More than 3 years",
      text_4: "More than 5 years",
    },
    partner_question1: {
      header_quest: "What is the IUX Markets Partner Program?",
      anwser_1:
        "The IUX Markets affiliate program is a program for traders and investors in the real market. Can tell the results or financial products of IUX Markets so that your friends or customers of the referrer can get the best benefits and results from us.",
    },
    partner_question2: {
      header_quest:
        "How would being a IUX Markets partner benefit my business?",
      anwser_1:
        "Your customers can buy up to 800 financial products, including CFDs and crypto, including 1500 products.",
      anwser_2:
        "Your customers receive a 35% deposit bonus up to 500% and 25% up to $3,000.",
      anwser_3:
        "You receive a commission including daily swap and income summary for you every day.",
      anwser_4: "Have the most complete care and marketing tools for you",
      anwser_5: "Unlimits commission",
    },
    partner_question3: {
      header_quest: "Who can partner with IUX Markets?",
      anwser_1:
        "Everyone who is an investor and is a trader with only real experience. That can be a partner Because we need the most effective advisor Not everyone can be a guide",
    },
    partner_question4: {
      header_quest: "Can I be a partner?",
      anwser_1:
        "Please contact us directly and give us your information for consideration.",
    },
    partner_request: {
      success: "Registration is complete Please check the email",
    },
    howtogetbonus: {
      title_1: "Open an account",
      des_1: "Register on our website and open a trading account.",
      title_2: "Make a deposit",
      des_2:
        "Deposit with a minimum $20 to your trading account by any payment method, and get an additional $30 Bonus.",
      title_3: "Start trading",
      des_3: "Trade with the lowest spread in the industry without any fees.",
    },
    partner_program: {
      introducing_broker: `INTRODUCING BROKER`,
      description_1: `Our IB program was developed with a focus on partnerships. Whether you are a single individual or a corporate with tens or hundreds of staff, we comprehend your business demands and requirements to increase your earnings. In addition to a versatile IB portal solution and a team of Partnership Managers ready to assist you and your clients, our IB program offers a number of rebate and compensation possibilities.`,
      title_desc_1: `Our IB portal has the following functionalities:`,
      des_1: `Real time commissions`,
      des_2: `Multi-level IB structure`,
      des_3: `Flexible commissions setting`,
      des_4: `Easy and swift deposit and withdrawal`,
      des_5: `IB relationship hierarchy`,
      des_6: `Client activity report`,
      detail_1: `Our marketing affiliates are an integral part of our success and we view their performance as our own. Therefore, we have developed a competitive model that remunerates affiliates properly while giving them the tools to monitor lead conversions, first account deposits, and client behaviour. Through our affiliate portal, you can access banners, educational material, and more.`,
      title_iux_help: `HOW IUX MARKETS HELPS YOUR CUSTOMERS`,
      des_iux_help_1: `Client-oriented`,
      des_iux_help_2: `Optimized Marketing`,
      des_iux_help_3: `24/7 Data And Analytics`,
      what_we_offer: `WHAT WE OFFER`,
    },
  },
  hi: {
    partner_header: {
      title: "Invite your friends to earn trading commissions.",
      description:
        "Share your passion by inviting friends to trade. The more you invite, the more you earn.",
      button: "Become our partner",
    },
    partner_header_list_box_1: {
      title: "Partnerships for Success",
      text_1: "Cooperation for success\n",
      text_2: "Daily swap income\n",
      text_3: "Get commission up to $ 25 / Lot.",
      text_4: "No limit on the commissions you can receive.\n",
    },
    partner_header_list_box_2: {
      title: "HOW IUX MARKETS HELPS YOUR CUSTOMERS",
      text_1:
        "Swift and secure payment with various methods including local banks without any fees.\n",
      text_2: "Immediate withdrawal without fees.\n",
      text_3:
        "Your clients receive 35% deposit bonus up to $300 and 25% deposit bonus up to $3,000 without conditions.\n",
      text_4:
        "Your clients use the IUX Lotback program, which can be used as a trading cost and withdraw immediately",
      text_5: `Deposit protection up to $ 1,000,000 and guaranteed if your orders are re-quoted.`,
      text_6: "Low trading fees and trade stocks for up to 800+ products.",
    },
    partner_header_list_box_3: {
      title: "Improve payment efficiency for partners around the world.",
      SectionSubTitle2: `Our partners create new platforms for merchants who trust in IUX Markets to process their payments.`,
    },
    partner_header_list_box_4: {
      title: "Frequently Asked Questions",
    },
    partner_header_list_box_5: {
      title: "It’s free to join our Partner Program. Get started today.",
      text_1: "Tell us a bit about your business and solution.",
      text_2: "We’ll get in touch to discuss your needs.",
      text_3: "Integrate PayPal and help your merchants get paid.",
    },
    form_partner: {
      title: "Become a partner with IUX Markets.",
      text_1:
        "Are you interested in becoming a partner with us? Give us a little information about your business and solution. And we will contact you back to discuss How can we support you and your sellers?\n",
      text_2: "Investment experience",
      validateFname: `Please input your first name!`,
      validateLname: `Please input your last name!`,
      validateEmail: `Please input your email!`,
      validatePhone: `Please input your number phone!`,
      validateWebsite: `Please input your website!`,
      validateNote: `Please input your note!`,
      validateSelect: `Please selected`,
    },
    radio_Partner: {
      text_1: "Less than 1 year",
      text_2: "More than 1 year",
      text_3: "More than 3 years",
      text_4: "More than 5 years",
    },
    partner_question1: {
      header_quest: "What is the IUX Markets Partner Program?",
      anwser_1:
        "The IUX Markets affiliate program is a program for traders and investors in the real market. Can tell the results or financial products of IUX Markets so that your friends or customers of the referrer can get the best benefits and results from us.",
    },
    partner_question2: {
      header_quest:
        "How would being a IUX Markets partner benefit my business?",
      anwser_1:
        "Your customers can buy up to 800 financial products, including CFDs and crypto, including 1500 products.",
      anwser_2:
        "Your customers receive a 35% deposit bonus up to 500% and 25% up to $3,000.",
      anwser_3:
        "You receive a commission including daily swap and income summary for you every day.",
      anwser_4: "Have the most complete care and marketing tools for you",
      anwser_5: "Unlimits commission",
    },
    partner_question3: {
      header_quest: "Who can partner with IUX Markets?",
      anwser_1:
        "Everyone who is an investor and is a trader with only real experience. That can be a partner Because we need the most effective advisor Not everyone can be a guide",
    },
    partner_question4: {
      header_quest: "Can I be a partner?",
      anwser_1:
        "Please contact us directly and give us your information for consideration.",
    },
    partner_request: {
      success: "Registration is complete Please check the email",
    },
    howtogetbonus: {
      title_1: "Open an account",
      des_1: "Register on our website and open a trading account.",
      title_2: "Make a deposit",
      des_2:
        "Deposit with a minimum $20 to your trading account by any payment method, and get an additional $30 Bonus.",
      title_3: "Start trading",
      des_3: "Trade with the lowest spread in the industry without any fees.",
    },
  },
  ms: {
    partner_header: {
      title: `Ajak rakan anda untuk mendapatkan komisen dagangan`,
      description: `kongsi minat pelaburan anda dengan mengajak rakan-rakan anda untuk melabur bersama dan dapatkan komisen dari setiap ajakan tersebut`,
      button: `Menjadi rakan kongsi kami`,
    },
    partner_header_list_box_1: {
      title: `Kerjasama untuk kejayaan`,
      text_1: `Menceritakan kejayaan`,
      text_2: `Pendapatan daripada rujukan pelabur`,
      text_3: `Anda boleh mendapatkan komisen sehingga 15 $ / Lot.`,
      text_4: `Tiada had komisen yang akan anda terima`,
    },
    partner_header_list_box_2: {
      title: `Bagaimana IUX Markets boleh membantu pelanggan anda`,
      text_1: `Bayaran bank tempatan percuma yang pantas dan serta-merta, mempunyai keselamatan operasi yang tinggi..`,
      text_2: `Pengeluaran dana segera tanpa yuran pengeluaran.`,
      text_3: `Pelanggan anda mendapat 35% bonus deposit sehingga 300$ dan 25% bonus deposit sehingga 3000$ tanpa syarat pengeluaran.`,
      text_4: `Pelanggan anda menggunakan program IUX Lotback yang boleh digunakan sebagai kos dagangan dan pengeluaran serta-merta.`,
      text_5: `Perlindungan deposit sehingga $1,000,000 dan dijamin jika pesanan pelanggan anda direquote.`,
      text_6: `Yuran dagangan rendah dan dagangan saham sehingga 800+ produk.`,
    },
    partner_header_list_box_3: {
      title: `Pembayaran dipertingkatkan untuk Rakan Kongsi Global.`,
      SectionSubTitle2: `Rakan kongsi kami mencipta platform baharu untuk penjual yang mempercayai IUX Markets untuk memproses pembayaran`,
    },
    partner_header_list_box_4: {
      title: `Pertanyaan Lazim`,
    },
    partner_header_list_box_5: {
      title: `Sertai program gabungan kami secara percuma. Mulakan hari ini.`,
      text_1: `Sila berikan maklumat ringkas tentang perniagaan dan penyelesaian anda.`,
      text_2: `Kami akan menghubungi untuk membincangkan keperluan anda.`,
      text_3: `Sepadukan IUX Markets dan bantu penjual anda mendapat dana.`,
    },
    form_partner: {
      title: `Menjadi rakan kongsi dengan IUX Markets.`,
      text_1: `Adakah anda berminat untuk menjadi rakan kongsi dengan kami? Beri kami sedikit maklumat tentang perniagaan dan penyelesaian anda. Dan kami akan menghubungi anda kembali untuk membincangkan Bagaimana kita boleh menyokong anda dan penjualan anda?`,
      text_2: `Pengalaman pelaburan`,
      validateFname: `Sila masukkan nama pertama anda!`,
      validateLname: `Sila masukkan nama terakhir anda!`,
      validateEmail: `Sila masukkan email anda!`,
      validatePhone: `Sila masukkan nombor telefon anda!`,
      validateWebsite: `Sila masukkan website anda!`,
      validateNote: `Sila masukkan nota anda!`,
      validateSelect: `Sila pilih`,
    },
    radio_Partner: {
      text_1: `Kurang daripada 1 tahun`,
      text_2: `Lebih daripada 1 tahun`,
      text_3: `Lebih daripada 3 tahun`,
      text_4: `Lebih daripada 5 tahun`,
    },
    partner_question1: {
      header_quest: `Apakah Program Partner IUX Markets?`,
      anwser_1: `Program Gabungan IUX Markets ialah program yang membolehkan pedagang dan pelabur aktif dalam pasaran sebenar. Boleh berkongsi hasil atau produk produk kewangan IUX Markets supaya rakan atau pelanggan rujukan anda mendapat manfaat dan hasil terbaik daripada kami.`,
    },
    partner_question2: {
      header_quest: `Bagaimanakah rakan kongsi IUX Markets memberi manfaat kepada perniagaan saya?`,
      anwser_1: `Pelanggan anda boleh membeli sehingga 800 produk kewangan yang berbeza termasuk CFDs dan Crypto, serta 1500 produk.`,
      anwser_2: `Pelanggan anda mendapat 35% sehingga 500% bonus deposit dan 25% sehingga 3,000.`,
      anwser_3: `Anda memperoleh komisen serta pertukaran harian dan kami akan membuat kesimpulan pendapatan kepada anda setiap hari.`,
      anwser_4: `Mempunyai alat penjagaan dan pemasaran yang paling lengkap untuk anda`,
      anwser_5: `Komisen tanpa had setiap orang`,
    },
    partner_question3: {
      header_quest: `Siapa yang boleh bekerjasama dengan IUX Markets?`,
      anwser_1: `Semua pelabur dan pedagang dengan pengalaman sebenar sahaja yang boleh jadi sekutu kerana kami memerlukan rujukan yang paling berkesan, tidak semua orang boleh menjadi rujukan.`,
    },
    partner_question4: {
      header_quest: `Bolehkah saya menjadi rakan kongsi?`,
      anwser_1: `Sila hubungi kami secara langsung dan memberi kami maklumat anda untuk pertimbangan.`,
    },
    howtogetbonus: {
      title_1: "Open an account",
      des_1: "Register on our website and open a trading account.",
      title_2: "Make a deposit",
      des_2:
        "Deposit with a minimum $20 to your trading account by any payment method, and get an additional $30 Bonus.",
      title_3: "Start trading",
      des_3: "Trade with the lowest spread in the industry without any fees.",
    },
  },
  id: {
    partner_header: {
      title: `Undang teman Anda untuk perdagangan`,
      description: `Share your passion by inviting friends to trade. The more you invite, the more you earn.`,
      button: `Bermitra dengan kami`,
    },
    partner_header_list_box_1: {
      title: `Kemitraan untuk Sukses`,
      text_1: `Untold Sukses`,
      text_2: `Teman Anda akan menerima $ 30`,
      text_3: `Anda bisa mendapatkan hingga 15 $ / Lot.`,
      text_4: `Komisi pada semua perdagangan`,
    },
    partner_header_list_box_2: {
      title: `Mengapa memilih IUX Markets`,
      text_1: `solusi pembayaran untuk keamanan internasional.`,
      text_2: `Membangun kepercayaan pelanggan dan meningkatkan penjualan.`,
      text_3: `Puasa laju penarikan dan deposito pasar.`,
      text_4: `klien Anda menggunakan program IUX Kerajaan Plus, yang dapat digunakan sebagai biaya perdagangan dan segera ditarik.`,
      text_5: `Deposit perlindungan hingga $ 1.000.000 dan dijamin jika pesanan klien Anda membuat kesalahan dalam perdagangan.`,
      text_6: `biaya perdagangan rendah dan saham diperdagangkan hingga 800 + produk.`,
    },
    partner_header_list_box_3: {
      title: `Powering pembayaran untuk mitra di seluruh dunia.`,
      SectionSubTitle2: `mitra kami menciptakan platform inovatif untuk pedagang bahwa kepercayaan IUX Markets untuk memproses pembayaran mereka`,
    },
    partner_header_list_box_4: {
      title: `Pertanyaan yang Sering Diajukan`,
    },
    partner_header_list_box_5: {
      title: `Ini gratis untuk bergabung Program Mitra kami. Memulai hari ini.`,
      text_1: `Beritahu kami sedikit tentang bisnis Anda dan solusi.`,
      text_2: `Kami akan menghubungi untuk mendiskusikan kebutuhan Anda.`,
      text_3: `Mengintegrasikan PayPal dan membantu pedagang Anda dibayar.`,
    },
    form_partner: {
      title: `Menjadi mitra dengan IUX Markets.`,
      text_1: `Apakah Anda tertarik menjadi mitra dengan kami? Beri kami sedikit informasi tentang bisnis Anda dan solusi. Dan kami akan menghubungi Anda kembali untuk membahas Bagaimana kita dapat mendukung Anda dan penjual Anda?`,
      text_2: `pengalaman investasi`,
      validateFname: `Please input your first name!`,
      validateLname: `Please input your last name!`,
      validateEmail: `Please input your email!`,
      validatePhone: `Please input your number phone!`,
      validateWebsite: `Please input your website!`,
      validateNote: `Please input your note!`,
      validateSelect: `Please selected`,
    },
    radio_Partner: {
      text_1: `kurang dari 1 tahun`,
      text_2: `Lebih dari 1 tahun`,
      text_3: `Lebih dari 3 tahun`,
      text_4: `Lebih dari 5 tahun`,
    },
    partner_question1: {
      header_quest: `Apa Program Mitra IUX Markets?`,
      anwser_1: `Program afiliasi IUX Markets adalah program bagi para pedagang dan investor di pasar nyata. Dapat memberitahu hasil atau produk keuangan IUX Markets sehingga teman Anda atau pelanggan dari pengarah bisa mendapatkan manfaat terbaik dan hasil dari kami.`,
    },
    partner_question2: {
      header_quest: `Bagaimana akan menjadi mitra IUX Markets menguntungkan bisnis saya?`,
      anwser_1: `pelanggan Anda dapat membeli hingga 800 produk keuangan, termasuk CFDs dan kripto, termasuk 1.500 produk.`,
      anwser_2: `pelanggan Anda menerima bonus deposit 35% hingga 500% dan 25% sampai dengan $ 3.500.`,
      anwser_3: `Anda menerima komisi termasuk pertukaran harian dan ringkasan pendapatan untuk Anda setiap hari.`,
      anwser_4: `Memiliki alat perawatan dan pemasaran yang paling lengkap untuk Anda`,
      anwser_5: `komisi Unlimits`,
    },
    partner_question3: {
      header_quest: `Yang dapat bermitra dengan IUX Markets?`,
      anwser_1: `Setiap orang yang merupakan investor dan pedagang dengan hanya pengalaman nyata. Itu bisa menjadi mitra Karena kita membutuhkan penasihat paling efektif Tidak semua orang bisa menjadi panduan`,
    },
    partner_question4: {
      header_quest: `Dapatkah saya menjadi mitra?`,
      anwser_1: `Silahkan hubungi kami langsung dan memberikan kami informasi Anda untuk dipertimbangkan.`,
    },
    howtogetbonus: {
      title_1: "Open an account",
      des_1: "Register on our website and open a trading account.",
      title_2: "Make a deposit",
      des_2:
        "Deposit with a minimum $20 to your trading account by any payment method, and get an additional $30 Bonus.",
      title_3: "Start trading",
      des_3: "Trade with the lowest spread in the industry without any fees.",
    },
  },
  zh: {
    partner_header: {
      title: `邀请你的朋友来交易`,
      description: `Share your passion by inviting friends to trade. The more you invite, the more you earn.`,
      button: `与我们合作`,
    },
    partner_header_list_box_1: {
      title: `伙伴关系的成功`,
      text_1: `无尽的成功`,
      text_2: `您的朋友将获得$ 30`,
      text_3: `你可以得到高达15 $ /批号。`,
      text_4: `委员会所有行业`,
    },
    partner_header_list_box_2: {
      title: `为什么选择IUX Markets`,
      text_1: `国际安全支付解决方案。`,
      text_2: `建立客户的信心，增加销售。`,
      text_3: `取款和存款市场的快节奏。`,
      text_4: `您的客户端使用IUX皇家Plus计划，可以用来作为贸易成本，并立即撤出。`,
      text_5: `保护存款高达$ 1,000,000，如果你的客户的订单犯了一个错误的交易保障。`,
      text_6: `低交易费及股票交易高达800+产品。`,
    },
    partner_header_list_box_3: {
      title: `供电，为世界各地的合作伙伴付款。`,
      SectionSubTitle2: `我们的合作伙伴创造客商的信任IUX Markets来处理他们的支付创新平台`,
    },
    partner_header_list_box_4: {
      title: `经常问的问题`,
    },
    partner_header_list_box_5: {
      title: `它是免费加入我们的合作伙伴计划。今天就开始。`,
      text_1: `告诉我们一些关于您的业务和解决方案。`,
      text_2: `我们会取得联系，讨论您的需求。`,
      text_3: `集成PayPal和帮助商家获得报酬。`,
    },
    form_partner: {
      title: `成为IUX Markets的合作伙伴。`,
      text_1: `难道你成为我们的合作伙伴有兴趣吗？给我们介绍一下您的业务和解决方案的少量信息。我们将与您联系，以便讨论我们如何支持你和你的卖家？`,
      text_2: `投资经验`,
      validateFname: `Please input your first name!`,
      validateLname: `Please input your last name!`,
      validateEmail: `Please input your email!`,
      validatePhone: `Please input your number phone!`,
      validateWebsite: `Please input your website!`,
      validateNote: `Please input your note!`,
      validateSelect: `Please selected`,
    },
    radio_Partner: {
      text_1: `不到1年`,
      text_2: `1年以上`,
      text_3: `3年以上`,
      text_4: `5年以上`,
    },
    partner_question1: {
      header_quest: `什么是IUX Markets合作伙伴计划？`,
      anwser_1: `该IUX Markets联盟计划是在真正的市场交易者和投资者的程序。能告诉结果或IUX Markets的金融产品，让你的指引者的朋友或客户可以从我们这里得到最好的效益和成果。`,
    },
    partner_question2: {
      header_quest: `怎么会被一个IUX Markets伙伴受益我的企业？`,
      anwser_1: `您的客户可以购买多达800种金融产品，包括外汇和加密，包括1500个系列产品。`,
      anwser_2: `您的客户获得30％的存款奖金高达500％和20％，最高达$ 3,000名。`,
      anwser_3: `您会收到包括日常交换，每天收入摘要你的佣金。`,
      anwser_4: `为您最完整的服务和营销工具`,
      anwser_5: `Unlimits佣金`,
    },
    partner_question3: {
      header_quest: `与IUX Markets谁能够合作伙伴？`,
      anwser_1: `大家谁是投资者，是唯一真正的经验的交易员。这可以是一个合作伙伴，因为我们需要最有效的顾问不是每个人都可以引导`,
    },
    partner_question4: {
      header_quest: `我能成为合作伙伴？`,
      anwser_1: `请直接联系我们，给我们您的信息审议。`,
    },
    howtogetbonus: {
      title_1: "Open an account",
      des_1: "Register on our website and open a trading account.",
      title_2: "Make a deposit",
      des_2:
        "Deposit with a minimum $20 to your trading account by any payment method, and get an additional $30 Bonus.",
      title_3: "Start trading",
      des_3: "Trade with the lowest spread in the industry without any fees.",
    },
  },
  vi: {
    partner_header: {
      title: "Mời bạn bè của bạn nhận hoa hồng giao dịch",
      description:
        "Share your passion by inviting friends to trade. The more you invite, the more you earn.",
      button: "Hợp tác với chúng tôi",
    },
    partner_header_list_box_1: {
      title: "Hợp tác để Thành công",
      text_1: "Kể chuyện thành công\n",
      text_2: "Thu nhập từ giới thiệu Nhà đầu tư\n",
      text_3: "Nhận hoa hồng tối đa 25$/Lot\n",
      text_4: "Bạn sẽ nhận được hoa hồng không giới hạn\n",
    },
    partner_header_list_box_2: {
      title: "IUX Markets giúp khách hàng của bạn như thế nào\n",
      text_1:
        "Thanh toán qua ngân hàng địa phương miễn phí, thực hiện nhanh chóng tức thời, có độ an toàn vận hành cao.",
      text_2: "Rút tiền ngay lập tức không mất phí \n",
      text_3:
        "Khách hàng của bạn được nhận 35% tối đa 300$ và tiền thưởng nạp 25% tối đa 3,000$ không có điều kiện rút tiền.\n",
      text_4:
        "Khách hàng của bạn dùng chương trình IUX Lotback có thể được sử dụng làm chi phí giao dịch và rút tiền ngay lập tức.\n",
      text_5:
        "Bảo vệ tiền nạp lên đến 1,000,000$ Và đảm bảo nếu đơn đặt hàng của khách hàng của bạn được yêu cầu.\n",
      text_6:
        "Phí giao dịch thấp và giao dịch cổ phiếu lên đến hơn 800 sản phẩm.\n",
    },
    partner_header_list_box_3: {
      title: "Có hiệu quả trong việc thanh toán đối với đối tác toàn cầu",
      SectionSubTitle2:
        "Các đối tác của chúng tôi tạo ra các nền tảng mới. Dành cho người bán tin tưởng Thị trường IUX Xử lý thanh toán.",
    },
    partner_header_list_box_4: {
      title: "cCaau hỏi thường gặp",
    },
    partner_header_list_box_5: {
      title:
        "Tham gia chương trình liên kết của chúng tôi miễn phí. Bắt đầu hôm nay",
      text_1:
        "Vui lòng cung cấp thông tin ngắn gọn về doanh nghiệp và solution của bạn",
      text_2:
        "Chúng tôi sẽ liên hệ lại với bạn để thảo luận về các yêu cầu của bạn",
      text_3: "Hợp nhất Thị trường IUX và giúp người giao dịch được thanh toán",
    },
    form_partner: {
      title: "Đối tác vớiIUX Markets",
      text_1:
        "Bạn có muốn hợp tác với chúng tôi không?Hãy cho chúng tôi biết một chút về doanh nghiệp và giải pháp của bạn. và chúng tôi sẽ liên hệ lại với bạn để thảo luận Chúng tôi có thể hỗ trợ bạn và nhà cung cấp của bạn như thế nào?\n",
      text_2: "Kinh nghiệm đầu tư",
      validateFname: `Vui lòng điền tên của bạn!`,
      validateLname: `Vui lòng điền họ của bạn!`,
      validateEmail: `Vui lòng điền e-mail của bạn!`,
      validatePhone: `Vui lòng điền số điện thoại của bạn!`,
      validateWebsite: `Vui lòng điền trang web của bạn!`,
      validateNote: `Vui lòng điền chú ý của bạn!`,
      validateSelect: `Vui lòng chọn`,
    },
    radio_Partner: {
      text_1: "ít hơn 1 năm",
      text_2: "nhiều hơn 1 năm\n",
      text_3: "nhiều hơn 3 năm\n",
      text_4: "nhiều hơn 5 năm\n",
    },
    partner_question1: {
      header_quest: "Chương trình đối tác IUX Markets là gì?",
      anwser_1:
        "Chương trình liên kết thị trường IUX ​là một dự án cung cấp cho các nhà giao dịch và nhà đầu tư trên thị trường thực. Có thể cho biết kết quả hoặc sản phẩm tài chính của IUX Markets để bạn bè hoặc khách hàng của bạn được giới thiệu có thể được hưởng lợi và kết quả tốt nhất từ chúng tôi",
    },
    partner_question2: {
      header_quest:
        "Hợp tác với IUX Markets mang lợi ích cho kinh doanh của tôi thế nào?",
      anwser_1:
        "Khách hàng của bạn có thể mua tối đa 800 kết hợp giữa CFDs và Crypto bao gồm 1500 sản phẩm",
      anwser_2:
        "Khách hàng được nhận tiền thưởng nạp 35% tối đa 500% và 25% tối đa 3,000$",
      anwser_3:
        "Được nhận giá hoa hồng đến Swap trong ngày à tổng hợp các khoản thu nhập của bạn hàng ngày.",
      anwser_4: "Có công cụ tiếp thị để cho bạn hoàn chỉnh nhất .",
      anwser_5: "Hoa hồng không giới hạn trên người.",
    },
    partner_question3: {
      header_quest: "Ai có thể hợp tác với IUX Markets",
      anwser_1:
        "Mọi người  nhà đầu tư và nhà giao dịch có kinh nghiệm thực tế. Có thể là đồng minh vì chúng tôi cần người giới thiệu  hiệu quả nhất. Không phải ai cũng có thể là người giới thiệu.",
    },
    partner_question4: {
      header_quest: "Tôi có thể trở thành người giới thiệu được không?",
      anwser_1:
        "Vui lòng liên hệ trực tiếp với chúng tôi và cung cấp thông tin của bạn để chúng tôi xem xét.",
    },
    partner_request: {
      success: "Đăng ký xong, vui lòng kiểm tra e-mail.",
    },
    howtogetbonus: {
      title_1: "Open an account",
      des_1: "Register on our website and open a trading account.",
      title_2: "Make a deposit",
      des_2:
        "Deposit with a minimum $20 to your trading account by any payment method, and get an additional $30 Bonus.",
      title_3: "Start trading",
      des_3: "Trade with the lowest spread in the industry without any fees.",
    },
  },
  ko: {
    partner_header: {
      title: `거래에 친구를 초대`,
      description: `Share your passion by inviting friends to trade. The more you invite, the more you earn.`,
      button: `우리와 파트너`,
    },
    partner_header_list_box_1: {
      title: `성공을위한 파트너십`,
      text_1: `말할 수없는 성공`,
      text_2: `당신의 친구는 $ 30받습니다`,
      text_3: `당신은 15 $ / 부지까지 얻을 수 있습니다.`,
      text_4: `모든 거래에위원회`,
    },
    partner_header_list_box_2: {
      title: `IUX Markets를 선택하는 이유`,
      text_1: `국제 안보에 대한 지불 솔루션을 제공합니다.`,
      text_2: `고객의 신뢰와 매출 증대를 구축 할 수 있습니다.`,
      text_3: `철수와 예금 시장의 빠른 속도.`,
      text_4: `고객은 거래의 원가로 사용 즉시 철회 할 수 IUX 로얄 플러스 프로그램을 사용합니다.`,
      text_5: `예금 보호 $ 1,000,000까지하고 고객의 주문이 거래에 실수를하는 경우 보장.`,
      text_6: `800 개 제품까지에 대한 거래 낮은 거래 수수료 및 주식.`,
    },
    partner_header_list_box_3: {
      title: `전 세계 파트너에 대한 지불을 켜기.`,
      SectionSubTitle2: `우리의 파트너는 신뢰 IUX 시장이 자신의 지불을 처리하는 것을 상인을위한 혁신적인 플랫폼을 만들`,
    },
    partner_header_list_box_4: {
      title: `자주 묻는 질문`,
    },
    partner_header_list_box_5: {
      title: `그것은 우리의 파트너 프로그램에 가입 무료입니다. 지금 시작하세요.`,
      text_1: `우리에게 당신의 사업 및 솔루션에 대해 조금 말해.`,
      text_2: `우리는 당신의 요구 사항을 논의하기 위해 연락을 얻을 것이다.`,
      text_3: `페이팔을 통합하고 상인이 돈을받을 수 있도록.`,
    },
    form_partner: {
      title: `IUX 마켓의 파트너가 되십시오.`,
      text_1: `당신은 우리와 함께 파트너가에 관심이 있습니까? 우리에게 당신의 사업 및 솔루션에 대한 약간의 정보를 제공합니다. 그리고 우리는 우리가 당신과 당신의 판매를 지원할 수있는 방법을 논의하기 위해 다시 연락을 드릴 것입니다?`,
      text_2: `투자 경험`,
      validateFname: `Please input your first name!`,
      validateLname: `Please input your last name!`,
      validateEmail: `Please input your email!`,
      validatePhone: `Please input your number phone!`,
      validateWebsite: `Please input your website!`,
      validateNote: `Please input your note!`,
      validateSelect: `Please selected`,
    },
    radio_Partner: {
      text_1: `1 년 미만`,
      text_2: `1 년 이상`,
      text_3: `3 년 이상`,
      text_4: `5 년 이상`,
    },
    partner_question1: {
      header_quest: `IUX Markets 파트너 프로그램은 무엇인가?`,
      anwser_1: `IUX Markets의 제휴 프로그램은 실제 시장에서 상인과 투자자를위한 프로그램입니다. 리퍼러의 친구 또는 고객이 우리에게 최고의 혜택과 결과를 얻을 수 있도록 결과 나 IUX Markets의 금융 상품을 알 수 있습니다.`,
    },
    partner_question2: {
      header_quest: `어떻게 IUX Markets 파트너가 내 비즈니스에 도움이되는 것?`,
      anwser_1: `고객은 1500 개 제품을 포함하여 외환 및 암호화를 포함하여 최대 800 개 금융 상품을 구입할 수 있습니다.`,
      anwser_2: `고객은 $ 3,000 500 %와 30 % 최대 50 % 입금 보너스를받을 수 있습니다.`,
      anwser_3: `당신은 매일 교체하고 소득 요약 매일을 포함하여 수수료를받을 수 있습니다.`,
      anwser_4: `당신을위한 가장 완벽한 관리 및 마케팅 도구를 가지고`,
      anwser_5: `Unlimits위원회`,
    },
    partner_question3: {
      header_quest: `IUX 시장에 누가 캔 파트너?`,
      anwser_1: `투자자이며, 모두가 유일한 경험을 가진 상인이다. 우리가 가장 효과적인 고문 모두가 가이드 할 수있다 할 필요가 있기 때문 파트너가 될 수 있습니다`,
    },
    partner_question4: {
      header_quest: `나는 파트너가 될 수 있을까?`,
      anwser_1: `저희에게 직접 연락하고 우리에게 고려 사항에 대한 정보를 제공하십시오.`,
    },
    howtogetbonus: {
      title_1: "Open an account",
      des_1: "Register on our website and open a trading account.",
      title_2: "Make a deposit",
      des_2:
        "Deposit with a minimum $20 to your trading account by any payment method, and get an additional $30 Bonus.",
      title_3: "Start trading",
      des_3: "Trade with the lowest spread in the industry without any fees.",
    },
  },
  fil: {
    partner_header: {
      title: `Anyayahan ang iyong mga kaibigan upang kalakalan`,
      description: `Share your passion by inviting friends to trade. The more you invite, the more you earn.`,
      button: `Partner sa amin`,
    },
    partner_header_list_box_1: {
      title: `Pakikipagsosyohan para sa Tagumpay`,
      text_1: `Untold Tagumpay`,
      text_2: `Ang iyong kaibigan ay makakatanggap ng $ 30`,
      text_3: `Maaari kang makakuha ng hanggang sa 15 $ / Lot.`,
      text_4: `Komisyon sa lahat ng trades`,
    },
    partner_header_list_box_2: {
      title: `Bakit pumili IUX Markets`,
      text_1: `solusyon ng pagbabayad para sa internasyonal na seguridad.`,
      text_2: `Buuin ang customer confidence at dagdagan ang benta.`,
      text_3: `Ang mabilis na bilis ng withdrawal at deposito market.`,
      text_4: `Ang iyong mga kliyente gamitin ang IUX Lotback programa, na kung saan ay maaaring gamitin bilang isang gastos ng kalakalan at agad na-withdraw.`,
      text_5: `Deposit proteksyon hanggang sa $ 1,000,000 at garantisadong kung order ng iyong kliyente gumawa ng isang pagkakamali sa kalakalan.`,
      text_6: `Mababang mga bayarin sa kalakalan at stock trading para sa hanggang sa 800 + mga produkto.`,
    },
    partner_header_list_box_3: {
      title: `Powering mga pagbabayad para sa mga kasosyo sa buong mundo.`,
      SectionSubTitle2: `Ang aming mga kasosyo lumikha ng makabagong platform para sa mga mangangalakal ang tiwala na iyon IUX Markets upang iproseso ang kanilang mga pagbabayad`,
    },
    partner_header_list_box_4: {
      title: `Mga Madalas Itanong`,
    },
    partner_header_list_box_5: {
      title: `Ito ay libre upang sumali sa aming Partner Program. Magsimula ngayon.`,
      text_1: `Sabihin sa amin ng kaunti tungkol sa iyong negosyo at solusyon.`,
      text_2: `Ipapakita namin makipag-ugnay upang talakayin ang iyong mga pangangailangan.`,
      text_3: `Isama PayPal at tulungan ang iyong mga mangangalakal mababayaran.`,
    },
    form_partner: {
      title: `Maging isang partner sa IUX Markets.`,
      text_1: `Sigurado ka interesado sa pagiging kasosyo sa amin? Bigyan kami ng isang maliit na impormasyon tungkol sa iyong negosyo at solusyon. At kami ay makipag-ugnayan ka pabalik upang talakayin Paano natin masusuportahan ka at ang iyong mga nagbebenta?`,
      text_2: `investment na karanasan`,
      validateFname: `Please input your first name!`,
      validateLname: `Please input your last name!`,
      validateEmail: `Please input your email!`,
      validatePhone: `Please input your number phone!`,
      validateWebsite: `Please input your website!`,
      validateNote: `Please input your note!`,
      validateSelect: `Please selected`,
    },
    radio_Partner: {
      text_1: `Mas mababa sa 1 taon`,
      text_2: `Higit sa 1 taon`,
      text_3: `Higit sa 3 taon`,
      text_4: `Higit sa 5 taon`,
    },
    partner_question1: {
      header_quest: `Ano ang IUX Markets Partner Program?`,
      anwser_1: `Ang IUX Markets affiliate program ay isang programa para sa mga mangangalakal at mamumuhunan sa tunay na merkado. Maaari sabihin sa mga resulta o pinansiyal na mga produkto ng IUX Markets upang ang iyong mga kaibigan o mga customer ng referrer ay maaaring makuha ang pinakamahusay na mga benepisyo at mga resulta mula sa amin.`,
    },
    partner_question2: {
      header_quest: `Paano ang pagiging IUX Markets partner makikinabang ang aking negosyo?`,
      anwser_1: `Ang iyong mga customer ay maaaring bumili ng hanggang sa 800 mga pinansiyal na mga produkto, kabilang ang CFDs at crypto, kabilang ang 1500 mga produkto.`,
      anwser_2: `Ang iyong mga customer makatanggap ng isang 35% deposit bonus ng hanggang sa 500% at 25% hanggang sa $ 3,000.`,
      anwser_3: `nakatanggap ka ng isang komisyon kabilang ang pang araw-araw swap at kita buod para sa iyo araw-araw.`,
      anwser_4: `May pinaka-kumpletong pag-aalaga at mga tool sa marketing para sa iyo`,
      anwser_5: `Unlimits komisyon`,
    },
    partner_question3: {
      header_quest: `Sino ang lata partner sa IUX Markets?`,
      anwser_1: `Ang bawat taong ay isang mamumuhunan at ito ay isang negosyante na may lamang ang tunay na karanasan. Iyon ay maaaring maging isang kasosyo Dahil kailangan namin ang pinaka-epektibong tagapayo Hindi lahat ng tao ay maaaring maging isang gabay`,
    },
    partner_question4: {
      header_quest: `Maaari ba akong maging isang kasosyo?`,
      anwser_1: `Mangyaring makipag-ugnay sa amin nang direkta at bigyan kami ng iyong impormasyon para sa pagsasaalang-alang.`,
    },
    howtogetbonus: {
      title_1: "Open an account",
      des_1: "Register on our website and open a trading account.",
      title_2: "Make a deposit",
      des_2:
        "Deposit with a minimum $20 to your trading account by any payment method, and get an additional $30 Bonus.",
      title_3: "Start trading",
      des_3: "Trade with the lowest spread in the industry without any fees.",
    },
  },
  ja: {
    partner_header: {
      title: "友達を招待して取引委員会を獲得してください。",
      description:
        "友達を交換に招待して情熱を共有してください。 招待すればするほど、獲得します。",
      button: "私たちのパートナーになります",
    },
    partner_header_list_box_1: {
      title: "成功のためのパートナーシップ",
      text_1: "成功のための協力 n",
      text_2: "毎日のスワップ収入 n",
      text_3: "最大$ 25 /ロットを取得してください。",
      text_4: "受け取ることができる委員会に制限はありません。 n",
    },
    partner_header_list_box_2: {
      title: "IUX市場が顧客にどのように役立つか",
      text_1:
        "料金なしの地元の銀行を含むさまざまな方法で迅速かつ安全な支払い。 n",
      text_2: "手数料なしの即時撤退。 n",
      text_3:
        "クライアントは、条件なしで最大300ドルまでの35％のデポジットボーナスを受け取り、最大3,000ドルのデポジットボーナスを受け取ります。 n",
      text_4:
        "クライアントはIUX Lotbackプログラムを使用します。これは取引コストとして使用してすぐに撤回できます",
      text_5:
        "最大1,000,000ドルまでの保護を預け入れ、注文が再度引用されている場合は保証されます。",
      text_6: "最大800以上の製品の低い取引料と貿易株式。",
    },
    partner_header_list_box_3: {
      title: "世界中のパートナーの支払い効率を向上させます。",
      SectionSubTitle2:
        "当社のパートナーは、IUX市場を信頼して支払いを処理するマーチャント向けの新しいプラットフォームを作成します。",
    },
    partner_header_list_box_4: {
      title: "よくある質問",
    },
    partner_header_list_box_5: {
      title: "パートナープログラムに無料で参加できます。 今日は始めましょう。",
      text_1: "あなたのビジネスとソリューションについて少し教えてください。",
      text_2: "お客様のニーズについて話し合うために連絡します。",
      text_3: "PayPalを統合し、商人が支払いを受けるのを支援します。",
    },
    form_partner: {
      title: "IUX Marketsのパートナーになります。",
      text_1:
        "あなたは私たちとのパートナーになることに興味がありますか？ あなたのビジネスとソリューションに関する少しの情報を教えてください。 そして、私たちはあなたに連絡して、あなたとあなたの売り手をどのようにサポートできるかを話し合いますか？ n",
      text_2: "投資経験",
      validateFname: "あなたの名を入力してください！",
      validateLname: "姓を入力してください！",
      validateEmail: "メールを入力してください！",
      validatePhone: "番号電話を入力してください！",
      validateWebsite: "あなたのウェブサイトを入力してください！",
      validateNote: "メモを入力してください！",
      validateSelect: "選択してください",
    },
    radio_Partner: {
      text_1: "1年未満",
      text_2: "1年以上",
      text_3: "3年以上",
      text_4: "5年以上",
    },
    partner_question1: {
      header_quest: "IUX Marketsパートナープログラムとは何ですか？",
      anwser_1:
        "IUX Markets Affiliateプログラムは、実際の市場のトレーダーと投資家向けのプログラムです。 IUX市場の結果または金融商品を伝えることができ、紹介者の友人や顧客が私たちから最高の利益と結果を得ることができます。",
    },
    partner_question2: {
      header_quest:
        "IUX市場のパートナーであることは私のビジネスにどのように利益をもたらしますか？",
      anwser_1:
        "顧客は、CFDや暗号を含む最大800の金融商品を1500の製品を含む購入できます。",
      anwser_2:
        "顧客は、最大500％と25％までの35％のデポジットボーナスを3,000ドルから受け取ります。",
      anwser_3: "毎日、毎日のスワップと収入の概要を含む手数料を受け取ります。",
      anwser_4:
        "あなたのために最も完全なケアとマーケティングツールを持っています",
      anwser_5: "無制限の委員会",
    },
    partner_question3: {
      header_quest: "IUX市場と提携できるのは誰ですか？",
      anwser_1:
        "投資家であり、実際の経験だけを持つトレーダーであるすべての人。 私たちが最も効果的なアドバイザーが必要なので、それはパートナーになることができます",
    },
    partner_question4: {
      header_quest: "私はパートナーになれますか？",
      anwser_1: "直接お問い合わせください。ご相談ください。ご相談ください。",
    },
    partner_request: {
      success: "登録が完了しましたメールを確認してください",
    },
    howtogetbonus: {
      title_1: "アカウントを開きます",
      des_1: "当社のウェブサイトに登録し、取引口座を開設します。",
      title_2: "預金します",
      des_2:
        "支払い方法で最低20ドルの取引口座に預金し、30ドルのボーナスを追加します。",
      title_3: "取引を開始します",
      des_3: "料金なしで業界で最も低い普及との取引。",
    },
    partner_program: {
      introducing_broker: "ブローカーの紹介",
      description_1:
        "IBプログラムは、パートナーシップに焦点を当てて開発されました。 あなたが単一の個人であろうと、数十人のスタッフを持つ企業であろうと、私たちはあなたのビジネスの要求と要件をあなたの収入を増やすことを理解しています。 汎用性の高いIBポータルソリューションと、お客様とお客様のクライアントを支援する準備ができているパートナーシップマネージャーのチームに加えて、IBプログラムは多くのリベートと補償の可能性を提供します。",
      title_desc_1: "IBポータルには次の機能があります。",
      des_1: "リアルタイムコミッション",
      des_2: "マルチレベルIB構造",
      des_3: "柔軟なコミッションの設定",
      des_4: "簡単で迅速な預金と撤退",
      des_5: "IB関係階層",
      des_6: "クライアントアクティビティレポート",
      detail_1:
        "私たちのマーケティングアフィリエイトは私たちの成功の不可欠な部分であり、私たちは彼らのパフォーマンスを私たち自身のものと見なしています。 したがって、リードコンバージョン、最初のアカウント預金、クライアントの動作を監視するためのツールを提供しながら、アフィリエイトに適切に報酬を与える競合モデルを開発しました。 アフィリエイトポータルを通じて、バナー、教育資料などにアクセスできます。",
      title_iux_help: "IUX市場が顧客にどのように役立つか",
      des_iux_help_1: "クライアント指向",
      des_iux_help_2: "最適化されたマーケティング",
      des_iux_help_3: "24時間年中無休のデータと分析",
      what_we_offer: "私たちが提供するもの",
    },
  },
};
