import React from "react";
import { Button, Col, Divider, Row } from "antd";
import { Link } from "react-router-dom";
import { SelectOutlined } from "@ant-design/icons";
const locale = require("react-redux-i18n").I18n;
const buttonStyle = {
  color: "black",
};

const FooterVerify = (props) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div
      style={{
        paddingBottom: 80,
        color: "#AAA",
        backgroundColor: "#272727",
        paddingTop: "40px",
      }}
    >
      {/* <div
        class="trustpilot-widget"
        data-locale="en-US"
        data-template-id="5419b732fbfb950b10de65e5"
        data-businessunit-id="602684c743e0060001fa2be5"
        data-style-height="24px"
        data-style-width="100%"
        data-theme="dark"
        data-text-color="#ffffff"
      >
        <a
          href="https://www.trustpilot.com/review/iuxmarket.com"
          target="_blank"
          rel="noopener"
        >
          Trustpilot
        </a>
      </div> */}{" "}
      <div className="landingPage-flex-block container justify-content-between align-items-center flex-column flex-md-row">
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ gap: "18px" }}
        >
          <img
            alt="IUX"
            src={process.env.PUBLIC_URL + "/assets/images/icons/iux-logo.png"}
            onClick={() => window.open("https://iuxmarket.com/", "_self")}
            className="cursor-pointer border-logo"
            width={60}
            height={60}
          />
          <div style={{ color: "white", fontSize: "24px", fontWeight: "700" }}>
            IUX Markets
          </div>
        </div>
        <div className="col-md-5 d-flex justify-content-center justify-content-md-end mt-4 mt-md-0">
          <div className="row landing-float-RL m-0" style={{ gap: "12px" }}>
            <img
              alt="IUX"
              src={"/assets/images/icon-outline/facebook.png"}
              width={48}
              height={48}
              onClick={() =>
                window.open("https://www.facebook.com/IUXMarkets/")
              }
              className="cursor-pointer"
            />
            <img
              alt="IUX"
              src={"/assets/images/icon-outline/youtube.png"}
              width={48}
              height={48}
              onClick={() =>
                window.open("https://www.youtube.com/@iuxmarketsglobal")
              }
              className="cursor-pointer"
            />
            <img
              alt="IUX"
              src={"/assets/images/icon-outline/instagram.png"}
              width={48}
              height={48}
              onClick={() =>
                window.open("https://www.instagram.com/iuxmarkets/")
              }
              className="cursor-pointer"
            />
            <img
              alt="IUX"
              src={"/assets/images/icon-outline/linkedin.png"}
              width={48}
              height={48}
              onClick={() =>
                window.open("https://www.linkedin.com/company/iux-market/")
              }
              className="cursor-pointer"
            />
          </div>
        </div>
      </div>
      <div
        style={{
          paddingBottom: 16,
          textAlign: "left",
          whiteSpace: "break-spaces",
          overflow: "hidden",
          marginTop: "34px",
          color: "#aaa",
        }}
        className="container fs-16"
      >
        {props.locale.t(`footer_title_2_1`)} &nbsp;
        <a
          href="/assets/IML-COE-ID.pdf"
          target="blank"
          className="text-primary-new-iux"
        >
          26183 BC 2021.
        </a>{" "}
        {props.locale.t(`footer_title_2_2`)}
        <br />
        <br />
        {locale.t(`footer_FSCA_text_1`)}
        &nbsp;
        <a
          href="https://www.fsca.co.za/Fais/Search_FSP.htm"
          target="_blank"
          className="text-primary-new-iux"
        >
          53103
        </a>
        {locale.t(`footer_FSCA_text_2`)}
        <br />
        <br />
        {props.locale.t(`footer_title_1_1`)} &nbsp;
        <a
          href="https://mwaliregistrar.com/list_of_entities/authorised_trust_companies"
          target="blank"
          className="text-primary-new-iux"
        >
          T2023172
        </a>
        &nbsp;
        {props.locale.t(`footer_title_1_2`)}
        <br />
        <div style={{ height: 20 }}></div>
        <div id="footer-warning">{props.locale.t("website_warning")}</div>
        <br />
        {props.locale.t("copyright")}
      </div>
    </div>
  );
};

export default FooterVerify;
