import React from "react";
import { Row } from "antd";
import { Link } from "react-router-dom";
const locale = require("react-redux-i18n").I18n;
export default function SectionTwo() {
  return (
    <section key={2} className="bg-black">
      <div className="container ">
        <div className="row">
          <div className="col mb-40">
            <h3 className="text-white text-center">
              {locale.t("why_iux_header.title")}
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-4 mb-40">
            <div className="card-iux-trade ">
              <div className="text-center d-flex justify-content-between flex-column h-100">
                <div className="f-20 font-title text-primary-new-iux">
                  {locale.t("why_iux_header.header_1")}
                </div>
                <div className="text-left text-white ">
                  {locale.t("why_iux_header.text_1")}
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-4 mb-40">
            <div className="card-iux-trade">
              <div className="text-center d-flex justify-content-between flex-column h-100">
                <p className="f-20 font-title text-primary-new-iux">
                  {locale.t("why_iux_header.header_2")}
                </p>
                <p className="text-left text-white">
                  {locale.t("why_iux_header.text_2")}
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-4 mb-40">
            <div className="card-iux-trade">
              <div className="text-center d-flex justify-content-between flex-column h-100">
                <p className="f-20 font-title text-primary-new-iux">
                  {locale.t("why_iux_header.header_3")}
                </p>
                <p className="text-left text-white ">
                  {locale.t("why_iux_header.text_3")}
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-4 mb-40">
            <div className="card-iux-trade">
              <div className="text-center d-flex justify-content-between flex-column h-100">
                <p className="f-20 font-title text-primary-new-iux">
                  {locale.t("why_iux_header.header_4")}
                </p>
                <p className="text-left text-white mt-3">
                  {locale.t("why_iux_header.text_4")}{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-4 mb-40">
            <div className="card-iux-trade">
              <div className="text-center d-flex justify-content-between flex-column h-100">
                <p className="f-20 font-title text-primary-new-iux">
                  {locale.t("why_iux_header.header_5")}
                </p>
                <p className="text-left text-white mt-3">
                  {locale.t("why_iux_header.text_5")}{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-4 mb-40">
            <div className="card-iux-trade">
              <div className="text-center d-flex justify-content-between flex-column h-100">
                <p className="f-20 font-title text-primary-new-iux">
                  {locale.t("why_iux_header.header_6")}
                </p>
                <p className="text-left text-white ">
                  {locale.t("why_iux_header.text_6")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
