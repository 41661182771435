import { Button } from "antd";
import ReactApexChart from "react-apexcharts";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import {
  fCurrency,
  fCurrencySymbol,
  nFormatter,
  nFormatterChart,
} from "../../helper/formatNumber";
import ScrollContainer from "react-indiana-drag-scroll";
import { CheckLanguageMoment } from "../../helper/countryLangMoment";
import {
  CheckFormatDateChart,
  CheckFormatMonthChart,
} from "../../helper/countryFormatDateChart";
const locale = require("react-redux-i18n").I18n;
const Chart = ({
  data,
  categories,
  max,
  min,
  typeFormat,
  dataAll,
  isAccount,
  isTypeFilter,
}) => {
  const chartDivRef = useRef();
  let avg = Math?.abs(max) > Math.abs(min) ? Math?.abs(max) : Math.abs(min);
  const options = {
    chart: {
      type: "bar",
      height: 350,
      background: "#fff",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      strokeDashArray: 2,
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          strokeDashArray: 0,
          borderColor: "#D9D9D9",
        },
      ],
    },
    fill: {
      opacity: 1,
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        borderRadiusApplication: "end",
        colors: {
          ranges: [
            {
              from: 0,
              to: max,
              color: "#1CB894",
            },
            {
              from: min,
              to: 0,
              color: "#F27669",
            },
          ],
        },

        columnWidth: "18px",
        dataLabels: {
          position: "top",
        },
      },
    },
    tooltip: {
      intersect: false,
      inverseOrder: false,
      fillSeriesColor: false,
      theme: false,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return ` <div>
        <div class="box-tooltip fs-14 text-black">
          ${moment(new Date(dataAll[dataPointIndex].date))
            .locale(CheckLanguageMoment(localStorage.getItem("locale")))
            .format(
              typeFormat === "MMM"
                ? CheckFormatMonthChart(localStorage.getItem("locale"))
                : CheckFormatDateChart(localStorage.getItem("locale"))
            )}
        </div>
        <div class="box-tooltip-detail">
          <div class="d-flex fs-12  align-items-center">
            ${locale.t(
              "summary"
            )}:  &nbsp;<span class="fs-14 fw-600 text-black">${
          dataAll[dataPointIndex].total > 0
            ? "+"
            : dataAll[dataPointIndex].total === 0
            ? ""
            : "-"
        }${isAccount.account_type === "Cent" ? "¢" : "$"}${fCurrencySymbol(
          Math.abs(dataAll[dataPointIndex]?.total)
        )}</span>
          </div>
          <div class="d-flex fs-12  align-items-center">
          ${locale.t("profit")}: &nbsp;
            <span class="text-primary-new-iux fs-14 fw-600 ">
            ${isAccount.account_type === "Cent" ? "¢" : "$"}${fCurrencySymbol(
          dataAll[dataPointIndex].profit
        )}
            </span>
          </div>
          <div class="d-flex fs-12 align-items-center">
          ${locale.t("loss")}:  &nbsp;
            <span class=" text-loss-iux  fs-14 fw-600">
            ${isAccount.account_type === "Cent" ? "¢" : "$"}${fCurrencySymbol(
          Math.abs(dataAll[dataPointIndex]?.loss)
        )}
            </span>
          </div>
        </div>
      </div>`;

        // 'div'+
        // '<div class="box-tooltip">' +
        // "<span>" +
        // series[seriesIndex][dataPointIndex] +
        // "</span>" +
        // "</div>"
        // <div>
      },

      // y: {
      //   formatter: function (val, data) {
      //     console.log(data, "data");
      //     return (
      //       <div>
      //         <div>{fCurrency(val.toFixed(2))}</div>
      //       </div>
      //     );
      //   },
      //   title: {
      //     formatter: (seriesName, val) => {
      //       return val.series[0][val.dataPointIndex] > 0 ? "Profit:" : "Loss:";
      //     },
      //   },
      // },
    },
    dataLabels: {
      formatter: function (val, opts) {
        return val !== 0 ? nFormatterChart(val) : "";
      },
      enabled: true,
      offsetY: -20,
      style: {
        colors: [
          function (opts) {
            if (opts.w.config.series[0].data[opts.dataPointIndex] > 0) {
              return "#1CB894";
            } else {
              return "#F27669";
            }
          },
        ],
        fontSize: "10px",
        fontWeight: 400,
      },
    },
    yaxis: {
      show: false,
      min: -avg - (avg * 10) / 100,
      max: avg + (avg * 10) / 100,
    },
    xaxis: {
      // tickAmount: 12,
      // type: "datetime",
      categories: categories,
      // labels: {
      //   format: typeFormat,
      //   style: {
      //     fontWeight: 400,
      //     fontSize: "10px",
      //   },
      // },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        rotate: 0,
        formatter: function (val) {
          return moment(new Date(val))
            .locale(CheckLanguageMoment(localStorage.getItem("locale")))
            .format(typeFormat);
        },
        style: {
          fontWeight: 400,
          fontSize: "10px",
        },
      },
    },
  };

  const series = [
    {
      data: data,
    },
  ];

  useEffect(() => {
    // chartDivRef.current.scrollLeft = chartDivRef.current.scrollWidth;
    if (isTypeFilter !== "Custom") {
      chartDivRef.current.scrollLeft = chartDivRef.current.scrollWidth;
    }
  }, []);

  const [leftArrowDisabled, setLeftArrowDisabled] = useState(false);
  const [rightArrowDisabled, setRightArrowDisabled] = useState(false);

  const changeScroll = () => {
    if (chartDivRef.current?.scrollLeft === 0) {
      setLeftArrowDisabled(true);
    } else {
      setLeftArrowDisabled(false);
    }
    if (
      chartDivRef.current?.scrollLeft ===
      chartDivRef.current?.scrollWidth - chartDivRef.current?.clientWidth
    ) {
      setRightArrowDisabled(true);
    } else {
      setRightArrowDisabled(false);
    }
  };

  return (
    <div className="custom-chart d-flex align-items-center">
      <Button
        className="left-btn-report d-none d-lg-block mb-3"
        shape="circle"
        icon={<LeftOutlined />}
        disabled={leftArrowDisabled}
        style={{ backgroundColor: "#D9D9D9" }}
        onClick={() => {
          chartDivRef.current.scrollLeft -= 250;
        }}
      />
      <ScrollContainer
        innerRef={chartDivRef}
        className="scroll-hidden"
        style={{ overflow: "auto", scrollBehavior: "smooth", flex: 1 }}
        onEndScroll={changeScroll}
      >
        <div
          className="position-relative"
          style={{ width: categories.length * 50, minWidth: "100%" }}
        >
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height="300"
          />
        </div>
      </ScrollContainer>
      <Button
        className="left-btn-report d-none d-lg-block mb-3"
        shape="circle"
        icon={<RightOutlined />}
        disabled={rightArrowDisabled}
        style={{ backgroundColor: "#D9D9D9" }}
        onClick={() => {
          chartDivRef.current.scrollLeft += 250;
        }}
      />
    </div>
  );
};

export default Chart;
