import { Button, Checkbox, Modal, message } from "antd";
import React, { useState } from "react";
import { onOpenPolicy } from "../../../helper/policy";
import { useDispatch, useSelector } from "react-redux";
import { setModalBonus } from "../../../actions";
import { useHistory } from "react-router-dom";

const locale = require("react-redux-i18n").I18n;

function ModalWelcomeBonus(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const { isOpen, onClose } = props;
  const [isChecked, setIsChecked] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  return (
    <Modal
      className="w-modal width-modal-openAccountBonus user user-change"
      closable={true}
      visible={isOpen}
      onCancel={() => {
        onClose();
      }}
      footer={[
        <div className="d-flex justify-content-between text-center px-5 py-3">
          <Button
            className="btn-primary-new-iux w-100 fw-700"
            key="submit"
            type="primary"
            loading={buttonLoading}
            disabled={!isChecked}
            style={{}}
            onClick={() => {
              onClose();
              if (
                user?.bookBankVerify?.status === "accepted" &&
                user?.userVerify?.status === "accepted"
              ) {
                dispatch(setModalBonus(true));
              } else if (user?.userVerify?.status !== "accepted") {
                message.error(locale.t("open_account.error_er"));
                history.push(
                  `/${localStorage.getItem("locale")}/wallet/account/Standard`
                );
              } else {
                message.error(locale.t("open_account.error_bank"));
                history.push(
                  `/${localStorage.getItem("locale")}/wallet/fund/withdraw`
                );
              }
            }}
          >
            Get bonus
          </Button>
        </div>,
      ]}
    >
      <div className="px-5">
        <div className="d-flex justify-content-center w-100 fs-20 fw-700">
          Welcome Bonus
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column w-100 fs-12 mt-4">
          <img
            src="/assets/images/icons/dashboard/bonus30modal.png"
            alt="bonus30"
          />
          <div className="row mt-4">
            <div className="col-1">
              <Checkbox
                className="w-100 "
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              ></Checkbox>
            </div>
            <div className="col-10 pr-0">
              <span className="fs-12">
                You have read the details and agree to the &nbsp;
                <span
                  className="cursor-pointer text-primary-new-iux w-100 fs-12"
                  onClick={() => onOpenPolicy("bonus_terms_conditions_30")}
                >
                  Welcome Bonus Terms and Conditions.
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalWelcomeBonus;
