import { Button, Divider, Form, Input, Select, Statistic, message } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import OtpInput from "../../../../components/otpinput/OtpInput";
import moment from "moment";
import { post } from "../../../../helper/request";
import { useDispatch } from "react-redux";
import { getUser } from "../../../../actions";
import { ClipLoader } from "react-spinners";
import { renderApiDomain } from "../../../../helper/renderApiDomain";
const { Option } = Select;
const { Countdown } = Statistic;
const locale = require("react-redux-i18n").I18n;
function OTPVerify({
  isModalVisible,
  handleCancel,
  profile,
  prefixPhone,
  numberPhone,
  verifyFrom,
  handleVerifyUser,
  isOTPError,
  form,
}) {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [newPhone, setNewPhone] = useState("");
  const [isNewPhone, setIsNewPhone] = useState(false);
  const [isStep, setIsStep] = useState("otp"); // otp || phone
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isTime, setIsTime] = useState(moment().add(60, "s").toDate());
  const [data, setData] = useState(null);
  const [isError, setIsError] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);

  const onChange = (value) => {
    setOtp(value);

    if (value.trim().length === 6) {
      submitOTP(value);
    } else {
      setIsError(false);
    }
  };
  const overviewMemo = useMemo(
    () => (
      <Countdown
        // title="Countdown"
        format="ss"
        value={isTime}
        onFinish={() => setIsTime(null)}
      />
    ),
    [isTime]
  );
  useEffect(() => {
    if (numberPhone) {
      requestOtp();
    }
  }, [numberPhone]);
  const submitOTP = async (isotp) => {
    try {
      setLoading(true);
      await post(renderApiDomain() + "phone/confirm", {
        code: +isotp,
      });
      setTimeout(async () => {
        try {
          await post(renderApiDomain() + "switch-otp", {
            to: "phone",
          });
        } catch (error) {
          console.log(error);
        }
      }, 5000);

      setLoading(false);
      setIsSuccess(true);
      handleVerifyUser("verify");
      // handleCancel();
      dispatch(getUser());
      message.success(locale.t("modal_otp.confirm.success"));
    } catch (e) {
      setIsError(true);
      setOtp("");
      message.error(locale.t("modal_otp.confirm.error"));
      setLoading(false);

      console.log(e);
      setIsTime(null);
    }
  };
  const requestOtp = async (el) => {
    try {
      const ph = `${
        numberPhone.charAt(0) === "0" ? numberPhone.substring(1) : numberPhone
      }`;
      const newph = `${
        newPhone.charAt(0) === "0" ? newPhone.substring(1) : newPhone
      }`;
      let phnumber = el === "newphone" ? newph : ph;
      form.setFieldsValue({
        phone: phnumber,
      });
      setLoading(true);
      const res = await post(renderApiDomain() + "phone/change", {
        phone_number: `${prefixPhone}${phnumber}`,
      });
      setData(res);
      setIsStep("otp");
      setIsTime(moment().add(60, "s").toDate());
      setLoading(false);
      message.success(locale.t("modal_otp.validate.success"));
    } catch (error) {
      message.error(error.response.data.error);
      setLoading(false);
      setIsStep("phone");
      setOtp("");
      console.log(error);
      setIsTime(null);
    }
  };
  return (
    <>
      {loading ? (
        <div
          className="h-100 d-flex justify-content-center align-items-center flex-column my-5"
          style={{ height: 500 }}
        >
          <ClipLoader
            color="#36d7b7"
            size={72}
            cssOverride={{ borderWidth: 8 }}
          />
          <p className="mt-5 text-lightgray-iux">{locale.t("loadingtitle")}</p>
        </div>
      ) : (
        <>
          {" "}
          <div className="d-flex justify-content-center my-5 pb-5 flex-column align-items-center">
            <div className="fs-20">{locale.t("otpverifytitle")}</div>
            {isStep === "otp" ? (
              <>
                <div className="fs-12 mt-3 mb-5">
                  {locale.t("enterotp")}
                  {prefixPhone} {newPhone || numberPhone}
                </div>

                <div className="mt-5">
                  <OtpInput
                    value={otp}
                    valueLength={6}
                    onChange={onChange}
                    isError={isError}
                    isSuccess={isSuccess}
                  />
                </div>
                <div className="fs-14 mt-3 text-center text-gray d-flex align-items-center justify-content-center">
                  {isTime ? (
                    <div className="d-flex align-items-center">
                      {locale.t("codeexpiresin")}&nbsp;{overviewMemo} &nbsp;
                      {locale.t("seconds")}
                    </div>
                  ) : isError ? (
                    <div className="fs-16 text-is-error">
                      {locale.t("codenotmatch")}&nbsp;
                      <span
                        className="text-primary-new-iux font-weight-bold cursor-pointer"
                        onClick={() => requestOtp(newPhone ? "newphone" : "")}
                      >
                        {locale.t("requestotp")}
                      </span>
                    </div>
                  ) : (
                    <div className="fs-16">
                      {locale.t("didotp")}
                      <span
                        className="text-primary-new-iux font-weight-bold cursor-pointer"
                        onClick={() => requestOtp(newPhone ? "newphone" : "")}
                      >
                        {locale.t("requestotp")}
                      </span>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="fs-12 mt-3 verify">
                  {locale.t("invalidphone")}
                </div>
                <div style={{ maxWidth: 500 }} className="mt-5">
                  <Form layout="vertical">
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: locale.t(
                            "dashboardFund.modalVerify.validatePhone"
                          ),
                        },
                      ]}
                      label={locale.t("dashboardFund.modalVerify.tel")}
                    >
                      <Input
                        addonBefore={<div>{prefixPhone}</div>}
                        placeholder="xxxxxxxxx"
                        style={{ width: "100%" }}
                        value={newPhone}
                        // readOnly={profile.phone_number !== null}
                        onChange={(e) => setNewPhone(e.target.value)}
                        autocomplete="off"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item style={{ float: "center" }}>
                      <div className="mt-5 d-flex justify-content-center">
                        <Button
                          type="primary"
                          htmlType="submit"
                          onClick={() => requestOtp("newphone")}
                          loading={buttonLoading}
                          disabled={newPhone.length < 1}
                          className="btn-primary-new-iux"
                        >
                          {locale.t("requestotp")}
                        </Button>
                      </div>
                    </Form.Item>
                  </Form>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default OTPVerify;
