export const PhilippinesLocale = {
  locale: "tl-ph",
  Pagination: {
    items_per_page: "/ pahina",
    jump_to: "Pumunta sa",
    jump_to_confirm: "kumpirmahin",
    page: "",
    prev_page: "Nakaraang Pahina",
    next_page: "Susunod na Pahina",
    prev_5: "Nakaraang 5 Pahina",
    next_5: "Susunod na 5 Pahina",
    prev_3: "Nakaraang 3 Pahina",
    next_3: "Susunod na 3 Pahina",
  },
  DatePicker: {
    lang: {
      placeholder: "Pumili ng petsa",
      yearPlaceholder: "Pumili ng taon",
      quarterPlaceholder: "Pumili ng quarter",
      monthPlaceholder: "Pumili ng buwan",
      weekPlaceholder: "Pumili ng linggo",
      rangePlaceholder: ["Simula ng petsa", "Wakas ng petsa"],
      rangeYearPlaceholder: ["Simula ng taon", "Wakas ng taon"],
      rangeMonthPlaceholder: ["Simula ng buwan", "Wakas ng buwan"],
      rangeWeekPlaceholder: ["Simula ng linggo", "Wakas ng linggo"],
      locale: "tl-ph",
      today: "Ngayon",
      now: "Ngayon",
      backToToday: "Bumalik sa ngayon",
      ok: "Ok",
      clear: "Burahin",
      month: "Buwan",
      year: "Taon",
      timeSelect: "pumili ng oras",
      dateSelect: "pumili ng petsa",
      weekSelect: "Pumili ng linggo",
      monthSelect: "Pumili ng buwan",
      yearSelect: "Pumili ng taon",
      decadeSelect: "Pumili ng dekada",
      yearFormat: "YYYY",
      dateFormat: "M/D/YYYY",
      dayFormat: "D",
      dateTimeFormat: "M/D/YYYY HH:mm:ss",
      monthBeforeYear: true,
      previousMonth: "Nakaraang buwan (PageUp)",
      nextMonth: "Susunod na buwan (PageDown)",
      previousYear: "Nakaraang taon (Control + kaliwa)",
      nextYear: "Susunod na taon (Control + kanan)",
      previousDecade: "Nakaraang dekada",
      nextDecade: "Susunod na dekada",
      previousCentury: "Nakaraang siglo",
      nextCentury: "Susunod na siglo",
    },
    timePickerLocale: {
      placeholder: "Pumili ng oras",
      rangePlaceholder: ["Simula ng oras", "Wakas ng oras"],
    },
  },
  TimePicker: {
    placeholder: "Pumili ng oras",
    rangePlaceholder: ["Simula ng oras", "Wakas ng oras"],
  },
  Calendar: {
    lang: {
      placeholder: "Pumili ng petsa",
      yearPlaceholder: "Pumili ng taon",
      quarterPlaceholder: "Pumili ng quarter",
      monthPlaceholder: "Pumili ng buwan",
      weekPlaceholder: "Pumili ng linggo",
      rangePlaceholder: ["Simula ng petsa", "Wakas ng petsa"],
      rangeYearPlaceholder: ["Simula ng taon", "Wakas ng taon"],
      rangeMonthPlaceholder: ["Simula ng buwan", "Wakas ng buwan"],
      rangeWeekPlaceholder: ["Simula ng linggo", "Wakas ng linggo"],
      locale: "fil_PH",
      today: "Ngayon",
      now: "Ngayon",
      backToToday: "Bumalik sa ngayon",
      ok: "Ok",
      clear: "Burahin",
      month: "Buwan",
      year: "Taon",
      timeSelect: "pumili ng oras",
      dateSelect: "pumili ng petsa",
      weekSelect: "Pumili ng linggo",
      monthSelect: "Pumili ng buwan",
      yearSelect: "Pumili ng taon",
      decadeSelect: "Pumili ng dekada",
      yearFormat: "YYYY",
      dateFormat: "M/D/YYYY",
      dayFormat: "D",
      dateTimeFormat: "M/D/YYYY HH:mm:ss",
      monthBeforeYear: true,
      previousMonth: "Nakaraang buwan (PageUp)",
      nextMonth: "Susunod na buwan (PageDown)",
      previousYear: "Nakaraang taon (Control + kaliwa)",
      nextYear: "Susunod na taon (Control + kanan)",
      previousDecade: "Nakaraang dekada",
      nextDecade: "Susunod na dekada",
      previousCentury: "Nakaraang siglo",
      nextCentury: "Susunod na siglo",
    },
    timePickerLocale: {
      placeholder: "Pumili ng oras",
      rangePlaceholder: ["Simula ng oras", "Wakas ng oras"],
    },
  },
  global: {
    placeholder: "Mangyaring pumili",
  },
  Table: {
    filterTitle: "Menu ng Filter",
    filterConfirm: "OK",
    filterReset: "I-reset",
    selectAll: "Pumili ng kasalukuyang pahina",
    selectInvert: "Baligtarin ang kasalukuyang pahina",
    selectionAll: "Pumili ng lahat ng data",
    sortTitle: "Ayusin",
    expand: "Buksan ang hilera",
    collapse: "I-collapse ang hilera",
    triggerDesc: "Mag-click upang ayusin pababa",
    triggerAsc: "Mag-click upang ayusin pataas",
    cancelSort: "Mag-click upang kanselahin ang ayos",
  },
  Modal: {
    okText: "OK",
    cancelText: "Kanselahin",
    justOkText: "OK",
  },
  Popconfirm: {
    okText: "OK",
    cancelText: "Kanselahin",
  },
  Transfer: {
    titles: ["", ""],
    searchPlaceholder: "Maghanap dito",
    itemUnit: "item",
    itemsUnit: "items",
  },
  Upload: {
    uploading: "Nag-u-upload...",
    removeFile: "Tanggalin ang file",
    uploadError: "Error sa pag-upload",
    previewFile: "Preview file",
    downloadFile: "I-download ang file",
  },
  Empty: {
    description: "Walang Data",
  },
  Icon: {
    icon: "icon",
  },
  Text: {
    edit: "I-edit",
    copy: "Kopyahin",
    copied: "Nakopya",
    expand: "Buksan",
  },
  PageHeader: {
    back: "Bumalik",
  },
};
