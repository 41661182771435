import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "antd";

function ModalComingSoon({ isOpen, onClose }) {
  return (
    <Modal
      className="w-modal width-modal-openAccountBonus user user-change"
      closable={false}
      visible={isOpen}
      onCancel={() => {
        onClose();
      }}
      // style={{ maxWidth: 348 }}
      footer={null}
      // footer={[
      //   <div className="d-flex justify-content-between text-center p-0">
      //     <Button
      //       className="btn-primary-new-iux w-100 fw-700 "
      //       key="submit"
      //       type="primary"
      //       // loading={buttonLoading}
      //       // disabled={!isChecked}
      //       style={{}}
      //       onClick={() => {
      //         onClose();
      //       }}
      //     >
      //       Close
      //     </Button>
      //   </div>,
      // ]}
    >
      <div className="">
        <div className="d-flex justify-content-center w-100 fs-20 fw-700">
          IUX Webinar
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column w-100 fs-16 mt-2">
          <p style={{ color: "#A3A3A3" }} className="text-center">
            coming soon...
          </p>
        </div>
        <div className="d-flex justify-content-between text-center p-0 mt-3">
          <Button
            className="btn-primary-new-iux w-100 fw-700 "
            key="submit"
            type="primary"
            // loading={buttonLoading}
            // disabled={!isChecked}
            style={{}}
            onClick={() => {
              onClose();
            }}
          >
            OK
          </Button>
        </div>
      </div>
    </Modal>
  );
}

ModalComingSoon.propTypes = {};

export default ModalComingSoon;
