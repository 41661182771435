import React from "react";
import { Row } from "antd";
const locale = require("react-redux-i18n").I18n;
export default function SectionThree(props) {
  return (
    <section key={4}>
      <div className="container">
        <div className="row">
          <div className="text-left col-md mb-40 mt-4">
            <h3 className="text-white text-center mt-4 ">{props.title}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 mb-4 mb-md-0">
            <div className="d-flex flex-column justify-content-between text-center iux-block">
              <div>
                <p className="text-left text-white">
                  {/* {locale.t(
                    "energies_what_is_block1.iux_what_is_discription_1"
                  )} */}
                  {props.box1}
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="d-flex flex-column justify-content-between text-center iux-block">
              <div>
                <p className="text-left text-white">
                  {/* {locale.t(
                    "energies_what_is_block2.iux_what_is_discription_1"
                  )} */}
                  {props.box2}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ paddingBottom: "30px" }}>
          <div className="mt-4 col text-center">
            {/* <Link
                  to={`/${localStorage.getItem("locale")}/register`}
                  className="btn btn-primary text-white btn-hover"
                  style={{ padding: "5px 60px" }}
                >
                  <span>
                    <p style={{ textAlign: "center" }}>
                      {locale.t("button.sub_button.title_1")}
                    </p>
                    <small>{locale.t("button.sub_button.title_2")}</small>
                  </span>
                </Link> */}
          </div>
        </div>
      </div>
    </section>
  );
}
