export default {
  th: {
    title_trading_view_1:
      "ราคาตลาดและการวิเคราะห์จะแสดงด้วยเทคโนโลยี TradingView เพื่อนำเสนอเครื่องมือและข้อมูลสำหรับการวิจัยตลาดอย่างครอบคลุม: ที่นี่คุณสามารถ",
    link_trading_view: "ติดตามเหตุการณ์ล่าสุดในปฏิทินเศรษฐกิจ",
    title_trading_view_2:
      "ดูราคาทองคำสดหรือสินทรัพย์อื่น ๆ บนแผนภูมิโดยละเอียด อ่านข่าวทางการเงินล่าสุด และแบ่งปันแนวคิดกับเทรดเดอร์รายอื่น",
  },
  lo: {
    title_trading_view_1:
      "ວົງຢືມຕະຫຼາດແລະການວິເຄາະແມ່ນສະແດງດ້ວຍເທກໂນໂລຍີ TradingView. TradingView ສະເຫນີເຄື່ອງມືແລະຂໍ້ມູນສໍາລັບການຄົ້ນຄວ້າຕະຫຼາດທີ່ສົມບູນແບບ: ທີ່ນີ້ທ່ານສາມາດ",
    link_trading_view: "ຕິດຕາມເຫດການຫລ້າສຸດໃນປະຕິທິນເສດຖະກິດ",
    title_trading_view_2:
      ", ເບິ່ງລາຄາຄໍາສົດຫຼືຊັບສິນອື່ນໆໃນຕາຕະລາງລາຍລະອຽດ, ອ່ານຂ່າວທາງດ້ານການເງິນສົດ, ແລະແບ່ງປັນແນວຄວາມຄິດກັບຜູ້ຄ້າອື່ນໆ.",
  },
  en: {
    title_trading_view_1:
      "Market quotes and analysis are displayed with TradingView technology. TradingView offers tools and data for comprehensive market research: here you can",
    link_trading_view: "track latest events in the economic calendar",
    title_trading_view_2:
      " watch live gold price or other assets on detailed charts, read fresh financial news, and share ideas with fellow traders.",
  },
  hi: {
    title_trading_view_1:
      "बाज़ार उद्धरण और विश्लेषण ट्रेडिंगव्यू तकनीक के साथ प्रदर्शित किए जाते हैं। ट्रेडिंगव्यू व्यापक बाजार अनुसंधान के लिए उपकरण और डेटा प्रदान करता है:",
    link_trading_view:
      "यहां आप आर्थिक कैलेंडर में नवीनतम घटनाओं को ट्रैक कर सकते हैं",
    title_trading_view_2:
      " विस्तृत चार्ट पर सोने या अन्य संपत्तियों की लाइव कीमत देख सकते हैं, ताजा वित्तीय समाचार पढ़ सकते हैं और साथी व्यापारियों के साथ विचार साझा कर सकते हैं।",
  },
  ms: {
    title_trading_view_1:
      "Sebut harga dan analisis pasaran dipaparkan dengan teknologi TradingView. TradingView menawarkan alat dan data untuk penyelidikan pasaran yang komprehensif: di sini anda boleh ",
    link_trading_view: "menjejaki peristiwa terkini dalam kalendar ekonomi",
    title_trading_view_2:
      "menonton harga emas secara langsung atau aset lain pada carta terperinci, membaca berita kewangan baharu dan berkongsi idea dengan rakan pedagang.",
  },
  id: {
    title_trading_view_1:
      "Kutipan dan analisis pasar ditampilkan dengan teknologi TradingView. TradingView menawarkan alat dan data untuk riset pasar yang komprehensif: di sini Anda dapat",
    link_trading_view: "melacak peristiwa terkini di kalender ekonomi",
    title_trading_view_2:
      ", melihat harga emas langsung atau aset lainnya pada grafik terperinci, membaca berita keuangan terkini, dan berbagi ide dengan sesama pedagang.",
  },
  zh: {
    title_trading_view_1:
      "Market quotes and analysis are displayed with TradingView technology. TradingView offers tools and data for comprehensive market research: here you can",
    link_trading_view: "track latest events in the economic calendar",
    title_trading_view_2:
      " watch live gold price or other assets on detailed charts, read fresh financial news, and share ideas with fellow traders.",
  },
  vi: {
    title_trading_view_1:
      "Báo giá và phân tích thị trường được hiển thị bằng công nghệ TradingView. TradingView cung cấp các công cụ và dữ liệu để nghiên cứu thị trường toàn diện: tại đây bạn có thể",
    link_trading_view: "theo dõi các sự kiện mới nhất trong lịch kinh tế",
    title_trading_view_2:
      " xem giá vàng trực tiếp hoặc các tài sản khác trên biểu đồ chi tiết, đọc tin tức tài chính mới và chia sẻ ý tưởng với các nhà giao dịch đồng nghiệp.",
  },
  ko: {
    title_trading_view_1:
      "Market quotes and analysis are displayed with TradingView technology. TradingView offers tools and data for comprehensive market research: here you can",
    link_trading_view: "track latest events in the economic calendar",
    title_trading_view_2:
      " watch live gold price or other assets on detailed charts, read fresh financial news, and share ideas with fellow traders.",
  },
  fil: {
    title_trading_view_1:
      "Ang mga quote at pagsusuri sa merkado ay ipinapakita gamit ang teknolohiya ng TradingView. Nag-aalok ang TradingView ng mga tool at data para sa komprehensibong pananaliksik sa merkado: dito maaari mong",
    link_trading_view:
      "subaybayan ang pinakabagong mga kaganapan sa kalendaryong pang-ekonomiya,",
    title_trading_view_2:
      "manood ng live na presyo ng ginto o iba pang mga asset sa mga detalyadong chart, magbasa ng mga bagong balita sa pananalapi, at magbahagi ng mga ideya sa mga kapwa mangangalakal.",
  },
  ja: {
    title_trading_view_1:
      "市場相場と分析はTradingViewテクノロジーを使用して表示されます。 TradingViewは、包括的な市場調査のためのツールとデータを提供します。ここでは、",
    link_trading_view: "経済カレンダーで最新のイベントを追跡したり",
    title_trading_view_2:
      "、詳細なチャートでライブの金価格やその他の資産を監視したり、最新の金融ニュースを読んだり、仲間のトレーダーとアイデアを共有したりできます。",
  },
  es: {
    title_trading_view_1:
      "Las cotizaciones y análisis del mercado se muestran con la tecnología de TradingView. TradingView ofrece herramientas y datos para una investigación de mercado integral: aquí puede",
    link_trading_view: "seguir los últimos eventos en el calendario económico",
    title_trading_view_2:
      " ver el precio del oro en vivo u otros activos en gráficos detallados, leer noticias financieras frescas y compartir ideas con otros comerciantes.",
  },
};
