import React from "react";
import { Layout } from "antd";
import styled from "styled-components";
const LayoutMain = styled.div`
  background: #085a8c;
  color: #fff;
  border-radius: 2px;
  padding: 0px 2px;
  font-size: 12px;
  display: inline-block;
`;
const { Content } = Layout;
export const StyledContent = (props) => <Content>{props.children}</Content>;
