import { Col, Row, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { get, post } from "../../../../helper/request";
import Nodata from "../../../../components/nodata/Nodata";
import { renderApiDomain } from "../../../../helper/renderApiDomain";

const ItemSelect = styled.div`
  box-shadow: 0 0 7px #80808033;
  padding: 12px;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.3s;
  height: 100%;
  &:hover {
    background: #169376;
    color: #fff;
    box-shadow: 0 0 12px #169376;
  }
`;
export const postMyanPay = ({ amount_thb, account_trade_id, bank }) => {
  return post(`${renderApiDomain() + "deposit/myanpay"}`, {
    amount_thb: amount_thb,
    account_trade_id: account_trade_id,
    bank: bank,
  });
};

export const getMyanPay = () => {
  return get(`${renderApiDomain() + "deposit/myanpay"}`);
};

const MyanPay = (props) => {
  const selectIp = useSelector(({ user: { ipAddress } }) => ipAddress);
  const { paymentId } = props;
  const lang = localStorage.getItem("locale");
  const refPerfect = useRef(null);
  const [loading, setLoading] = useState(false);
  const [linkPay, setLinkPay] = useState(null);
  const [paymentData, setPaymentData] = useState({
    banks: {},
  });
  useEffect(() => {
    const postApiPayment = async () => {
      setLoading(true);
      const { data } = await getMyanPay();
      setPaymentData((prev) => ({
        ...prev,
        banks: data?.data,
      }));
      setLoading(false);
    };
    postApiPayment();
  }, []);

  const onFinish = async (el) => {
    setLoading(true);
    const payload = {
      account_trade_id: props.account.id,
      amount_thb: +props.amount,
      bank: el,
    };
    try {
      const { data } = await postMyanPay(payload);
      if (data) {
        await Promise.all([setLinkPay(data?.data?.redirect_link)]).then(() => {
          refPerfect.current.click();
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="row">
        {paymentData?.banks ? (
          Object.values(paymentData?.banks)?.map((e, i) => (
            <div className="col-4 text-center" key={i}>
              <ItemSelect
                onClick={() => onFinish(Object.keys(paymentData?.banks)[i])}
              >
                {e}
              </ItemSelect>
            </div>
          ))
        ) : (
          <Nodata />
        )}
      </div>
      <a ref={refPerfect} href={linkPay} hidden>
        Money space
      </a>
    </Spin>
  );
};

export default MyanPay;
