import React from "react";
import { Row, Col, Button } from "antd";
import { Link } from "react-router-dom";
import {
  CrownOutlined,
  TeamOutlined,
  ReconciliationOutlined,
  LeftOutlined,
} from "@ant-design/icons";

function LoyalRissing1() {
  return (
    <>
      <h5>
        <Link to={`/${localStorage.getItem("locale")}/wallet/loyalty`}>
          <span>
            {" "}
            <LeftOutlined />
          </span>
        </Link>{" "}
        แลกเปลี่ยนคะแนนเป็นเงินสด
      </h5>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-4 row">
        <Col className="gutter-row mt-4" span={24}>
          <div
            className="d-flex box-shadow row"
            style={{
              padding: "16px 0",
              textAlign: "center",
              color: "#000000c4",
            }}
          >
            <div className="col-md-2 f-18 lo-profile-content">คุณมี</div>
            <hr className="auto-hr"></hr>
            <div className="col-md-4 f-18 broder-left lo-profile-content">
              <span>
                <CrownOutlined />
              </span>
              <b> 96.42</b>
              <span> คะแนนของรางวัล IUX</span>
            </div>
            <hr className="auto-hr"></hr>
            <div className="col-md-3 f-18 broder-left lo-profile-content">
              <span>
                <TeamOutlined />
              </span>
              <b> 96.42</b>
              <span> ลูกค้าที่มีการซื้อขาย</span>
            </div>
            <hr className="auto-hr"></hr>
            <div className="col-md-3 f-18 broder-left lo-profile-content">
              <span>
                <ReconciliationOutlined />
              </span>
              <b> 96.42</b>
              <span> วันที่มีการซื้อขาย</span>
            </div>
          </div>
        </Col>
        <Col className="gutter-row mt-4" span={24}>
          <div className="text-center">
            <div className="text-center d-block box-shadow">
              <img
                className="lo1-content-img"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/loyalty/cash_pr_155.jpg"
                }
                alt="royalty"
              />
              <div style={{ maxWidth: "480px" }} className="p-12">
                <p className="text-left">
                  เพิ่มผลกำไรของคุณด้วยการแปลงคะแนนของรางวัลเป็นเงิน
                  แล้วคุณจะได้ทุกสิ่งที่เงินซื้อได้!
                </p>
                <p className="text-left">
                  เพียงทำการซื้อขาย, รับ 100+ คะแนน,
                  แล้วนำไปแลกเป็นเงินได้ด้วยอัตรแลกเปลี่ยน 1 คะแนน = 1 USD
                  จะถอนหรือเอาไปเทรดต่อก็ได้ตามสบาย
                </p>
                <p className="text-left">
                  ตัวเลือก "แลกคะแนนเป็นเงิน" มีอยู่ในบัญชีทุกประเภท
                  (ยกเว้นบัญชี ECN) และสามารถถอนผ่านได้ทุกระบบ
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col className="gutter-row mt-4" span={24}>
          <div className="text-center">
            <div
              className="box-shadow d-block p-12"
              style={{ maxWidth: "480px", width: "100%" }}
            >
              <div style={{ borderBottom: "1px solid rgb(57 68 76 / 11%)" }}>
                <p className="text-left" style={{ padding: "18px 0" }}>
                  คุณจะต้องจ่าย
                </p>
              </div>
              <div
                className=""
                style={{ borderBottom: "1px solid rgb(57 68 76 / 11%)" }}
              >
                <p
                  className="text-center "
                  style={{ color: "red", padding: "22px 0" }}
                >
                  คุณไม่มีบัญชีซื้อขายเพื่อขอรางวัลนี้
                </p>
              </div>
              <div
                className=""
                style={{ borderBottom: "1px solid rgb(57 68 76 / 11%)" }}
              >
                <h4 className="text-left" style={{ padding: "22px 0" }}>
                  <CrownOutlined /> <b>100</b> คะแนนของรางวัล
                </h4>
              </div>
              <div className="text-left">
                <Button
                  className="mt-4"
                  type="primary"
                  style={{
                    backgroundColor: "rgb(124, 179, 36)",
                    border: "none",
                  }}
                >
                  ดำเนินการต่อ
                </Button>
                <Button type="link">
                  <Link
                    to={`/${localStorage.getItem("locale")}/wallet/loyalty`}
                    className="effect-button"
                  >
                    ย้อนกลับ
                  </Link>
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default LoyalRissing1;
