import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Upload,
  Icon,
  message,
  Tooltip,
  Radio,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import jobsJson from "../../../../mock/jobs.json";
import { InfoCircleOutlined } from "@ant-design/icons";
import { getCareerList } from "../../../../api/verifyuser";
import { CheckProvince } from "../../../../components/checkprovince";
const locale = require("react-redux-i18n").I18n;
const { Option } = Select;

function FormVerify(props) {
  const {
    isValidationData,
    provinceList,
    form,
    handleVerifyUser,
    prefixPhone,
    onClickRadio,
    setNumberPhone,
    setState,
    state,
    loading,
    checkTypeVerify,
    userIsVerify,
  } = props;
  const user = useSelector((state) => state.user);
  const profile = user?.profile;
  const lang = localStorage.getItem("locale");
  const [file, setFile] = useState(null);
  const [fileIndia, setFileIndia] = useState(null);
  const [careerList, setCareerList] = useState(undefined);
  const validatorName = ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = new RegExp(/^[a-zA-Z @'/-]+$/);

      if (value.match(regex)) {
        return Promise.resolve();
      } else if (value === "" || value === undefined) {
        return Promise.resolve();
      } else {
        return Promise.reject("Please enter only characters");
      }
    },
  });
  const validatorID = (regxpattern, validation) => ({
    validator(rule, value) {
      const regex = new RegExp(regxpattern);

      if (value.match(regex)) {
        return Promise.resolve();
      } else if (value === "" || value === undefined) {
        return Promise.resolve();
      } else {
        return Promise.reject(validation);
      }
    },
  });
  const validatorLastName = ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = new RegExp(/^[a-zA-Z @'/-]+$/);

      if (value) {
        if (value.match(regex)) {
          return Promise.resolve();
        } else {
          return Promise.reject("Please enter only characters");
        }
      } else {
        return Promise.resolve();
      }
    },
  });

  // const uploadOption = {
  //   onRemove: async (file) => {
  //     setFile(null);
  //     await form.setFieldsValue({ upload: null });
  //     await form.validateFields(["upload"]);
  //   },

  //   beforeUpload: (file) => {
  //     const isPNG = file.type === "image/png";
  //     const isJPG = file.type === "image/jpg";
  //     const isJPEG = file.type === "image/jpeg";

  //     if (!isPNG && !isJPG && !isJPEG) {
  //       message.error(`${file.name} is not a image file`);
  //     } else {
  //       setFile([file]);
  //     }

  //     return false;
  //   },
  // };
  // const uploadIndiaOption = {
  //   onRemove: async (fileIndia) => {
  //     setFileIndia(null);

  //     await form.setFieldsValue({ selfie: null });
  //     await form.validateFields(["selfie"]);
  //   },
  //   beforeUpload: (file) => {
  //     const isPNG = file.type === "image/png";
  //     const isJPG = file.type === "image/jpg";
  //     const isJPEG = file.type === "image/jpeg";

  //     if (!isPNG && !isJPG && !isJPEG) {
  //       message.error(`${file.name} is not a image file`);
  //     } else {
  //       setFileIndia([file]);
  //     }

  //     return false;
  //   },
  // };
  const fetchCareerList = async () => {
    try {
      const { data } = await getCareerList();
      setCareerList(data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchCareerList();
  }, []);
  return (
    <div className="user">
      {/* <h2 className="font-weight-bold mt-5">
        {locale.t("dashboardFund.modalVerify.titleVerifyUser")}
      </h2>
      <hr /> */}
      <Row gutter={24}>
        {form.getFieldValue("last_name") === "" ? (
          <>
            <Col span={12} sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item
                name="first_name"
                label={locale.t("main_profile.fullName")}
                rules={[
                  {
                    required: true,
                    message: locale.t(
                      "dashboardFund.modalVerify.validateFirstName"
                    ),
                  },
                  // validatorName,
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </>
        ) : (
          <>
            <Col span={12} sm={24} xs={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="first_name"
                label={locale.t("dashboardFund.modalVerify.fnameLabel")}
                rules={[
                  {
                    required: true,
                    message: locale.t(
                      "dashboardFund.modalVerify.validateFirstName"
                    ),
                  },
                  // validatorName,
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12} sm={24} xs={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="last_name"
                rules={[
                  {
                    required: false,
                    message: locale.t(
                      "dashboardFund.modalVerify.validateLastName"
                    ),
                  },
                  // validatorLastName,
                ]}
                label={locale.t("dashboardFund.modalVerify.lnameLabel")}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </>
        )}
        <Col span={12} sm={24} xs={24} md={24} lg={12} xl={12} xxl={12}>
          <Form.Item
            name="email"
            rules={[{ required: true }]}
            label={locale.t("dashboardFund.modalVerify.email")}
          >
            <Input disabled />
          </Form.Item>
        </Col>

        <Col span={12} sm={24} xs={24} md={24} lg={12} xl={12} xxl={12}>
          <Form.Item
            name="id_card_number"
            rules={[
              {
                required: true,
                message:
                  locale.t("dashboardFund.modalVerify.validateIdCard") +
                    " " +
                    isValidationData?.label || userIsVerify?.document_type,
              },
              // validatorID(
              //   isValidationData?.regex_pattern,
              //   isValidationData?.validation
              // ),
            ]}
            label={isValidationData?.label || userIsVerify?.document_type}
          >
            <Input
              disabled
              maxLength={profile?.country_id === 2 ? 13 : 99}
              placeholder={
                isValidationData?.label || userIsVerify?.document_type
              }
              autocomplete="off"
              name="id_card_number"
              onKeyPress={(event) => {
                if (!/[a-zA-Z0-9.]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={(event) => {
                event.preventDefault();
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12} sm={24} xs={24} md={24} lg={12} xl={12} xxl={12}>
          <Form.Item
            name="birthday"
            rules={[
              {
                required: true,
                message: locale.t("dashboardFund.modalVerify.validateBirth"),
              },
            ]}
            label={locale.t("dashboardFund.modalVerify.birthdayLabel")}
          >
            <DatePicker
              disabled
              placeholder={locale.t(
                "dashboardFund.modalVerify.birthdayPlaceHolder"
              )}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12} sm={24} xs={24} md={24} lg={12} xl={12} xxl={12}>
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: locale.t("dashboardFund.modalVerify.validatePhone"),
              },
            ]}
            label={locale.t("dashboardFund.modalVerify.tel")}
          >
            <Input
              addonBefore={<div>{prefixPhone}</div>}
              placeholder="xxxxxxxxx"
              style={{ width: "100%" }}
              // readOnly={profile.phone_number !== null}
              onChange={(e) => setNumberPhone(e.target.value)}
              autocomplete="off"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24} sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            name="address"
            rules={[
              {
                required: true,
                message: locale.t("dashboardFund.modalVerify.validateAddress"),
              },
            ]}
            label={locale.t("dashboardFund.modalVerify.addressLabel")}
          >
            <Input.TextArea
              type="textarea"
              autoSize={{ minRows: 3 }}
              placeholder={locale.t(
                "dashboardFund.modalVerify.addressPlaceHolder"
              )}
            />
          </Form.Item>
        </Col>

        {provinceList?.data?.length > 0 ? (
          <Col span={12} sm={24} xs={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="province"
              rules={[
                {
                  required: true,
                  message:
                    locale.t("dashboardFund.modalVerify.validateInput") +
                    locale.t(`${CheckProvince(profile?.country_id).label}`),
                },
              ]}
              label={locale.t(`${CheckProvince(profile?.country_id).label}`)}
            >
              <Select
                placeholder={locale.t(
                  "dashboardFund.modalVerify.provincePlaceHolder"
                )}
                style={{ backgroundColor: "#fff" }}
              >
                {provinceList?.data?.map((e, index) => (
                  <Option key={"province_" + index} value={e.id}>
                    {e.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        ) : (
          <Col span={12} sm={24} xs={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="province"
              rules={[
                {
                  required: true,
                  message:
                    locale.t("dashboardFund.modalVerify.validateInput") +
                    locale.t(`${CheckProvince(profile?.country_id).label}`),
                },
              ]}
              label={locale.t(`${CheckProvince(profile?.country_id).label}`)}
            >
              <Input
                placeholder={locale.t(
                  `${CheckProvince(profile?.country_id).placholder}`
                )}
              />
            </Form.Item>
          </Col>
        )}
        <Col span={12} sm={24} xs={24} md={24} lg={12} xl={12} xxl={12}>
          <Form.Item
            name="country"
            rules={[
              {
                required: true,
                message: locale.t("dashboardFund.modalVerify.validatePost"),
              },
            ]}
            label={locale.t("register_choose_region")}
          >
            <Input
              disabled
              placeholder={locale.t("register_choose_region")}
              name="country"
            />
          </Form.Item>
        </Col>
        <Col span={12} sm={24} xs={24} md={24} lg={12} xl={12} xxl={12}>
          <Form.Item
            name="post"
            rules={[
              {
                required: true,
                message: locale.t("dashboardFund.modalVerify.validatePost"),
              },
            ]}
            label={locale.t("dashboardFund.modalVerify.postLabel")}
          >
            <Input
              placeholder={locale.t("dashboardFund.modalVerify.postLabel")}
              name="post"
              type="number"
            />
          </Form.Item>
        </Col>
        <Col span={12} sm={24} xs={24} md={24} lg={12} xl={12} xxl={12}>
          <Form.Item
            name="job"
            label={locale.t("dashboardFund.modalVerify.jobLabel")}
            rules={[
              {
                required: true,
                message: locale.t("dashboardFund.modalVerify.validateCareer"),
              },
            ]}
          >
            <Select
              placeholder={locale.t("dashboardFund.modalVerify.jobLabel")}
              allowClear
              style={{ backgroundColor: "#fff" }}
            >
              {careerList?.map((job, index) => {
                return (
                  <Option
                    key={"career_" + index}
                    value={job.id}
                    style={{ textTransform: "capitalize" }}
                  >
                    {job.text}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.job !== currentValues.job
          }
        >
          {({ getFieldValue }) => {
            return getFieldValue("job") === "Other" ? (
              <Col span={12} sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  name="other"
                  label={locale.t("dashboardFund.modalVerify.otherLabel")}
                  rules={[
                    {
                      required: true,
                      message: locale.t(
                        "dashboardFund.modalVerify.validateCareer"
                      ),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>{" "}
              </Col>
            ) : null;
          }}
        </Form.Item>
      </Row>

      <div className="text-center">
        <Button
          type="submit"
          loading={loading}
          disabled={loading}
          onClick={() =>
            handleVerifyUser(
              checkTypeVerify?.verify_type === "auto_otp" ? "otp" : "verify"
            )
          }
          className="btn-primary-new-iux"
        >
          {locale.t("dashboardInnerTransfer.confirm")}
        </Button>
      </div>
    </div>
  );
}

export default FormVerify;
