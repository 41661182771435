import { Button, Form, message, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { partnerMove } from "../../api/partner";
const locale = require("react-redux-i18n").I18n;

const Tab = styled.div`
  background-color: #3e3e3e;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const { Option } = Select;

const PartnerNoti = ({ data = [] }) => {
  const [mode, setMode] = useState(false);
  const [id, setId] = useState(undefined);
  const [form] = Form.useForm();
  const handleChage = (e) => {
    setId(e);
  };

  const onFinish = async () => {
    try {
      await partnerMove({ affiliate_id: id });
      message.error("success");
      setMode(false);
      form.resetFields();
    } catch (error) {
      console.log(error.response.data.error);
      message.error(error.response.data.error);
    }
  };

  const handleClose = () => {
    setMode(false);
    form.resetFields();
  };

  return (
    <div>
      <Tab>
        <div className="mr-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <g
              id="Icon_feather-alert-circle"
              data-name="Icon feather-alert-circle"
              transform="translate(0.009)"
            >
              <path
                id="Path_24054"
                data-name="Path 24054"
                d="M0,0H16V16H0Z"
                transform="translate(-0.009)"
                fill="none"
              />
              <g id="Group_7293" data-name="Group 7293">
                <path
                  id="Path_24051"
                  data-name="Path 24051"
                  d="M14.667,8A6.667,6.667,0,1,1,8,1.333,6.667,6.667,0,0,1,14.667,8Z"
                  fill="none"
                  stroke="#f19e03"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.333"
                />
                <path
                  id="Path_24052"
                  data-name="Path 24052"
                  d="M8,5.333V8"
                  fill="none"
                  stroke="#f19e03"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.333"
                />
                <path
                  id="Path_24053"
                  data-name="Path 24053"
                  d="M8,10.667H8"
                  fill="none"
                  stroke="#f19e03"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.333"
                />
              </g>
            </g>
          </svg>
        </div>
        <div className="text-white">
          <span>
            {locale.t("promo.noti_partner.title")}
            {` ${data[0].user.personal_detail.first_name} `}
            {locale.t("promo.noti_partner.title1")}
          </span>
          {/* <button
            style={{
              color: "#F19E03",
              outline: "none",
              border: "none",
              background: "transparent",
            }}
            className="ml-2 mr-2"
          >
            information
          </button> */}
          <button
            className="ml-2"
            style={{
              color: "rgb(17, 173, 117)",
              outline: "none",
              border: "none",
              background: "transparent",
              // color: "#fff",
              // outline: "none",
              // border: "none",
              // background: "rgb(17, 173, 117)",
              // borderRadius: "6px",
            }}
            onClick={() => setMode(true)}
          >
            {locale.t("promo.noti_partner.button")}
          </button>
        </div>
      </Tab>
      <Modal
        title={false}
        footer={false}
        visible={mode}
        bodyStyle={{ padding: 0 }}
        className="width-modal-responsive"
        style={{ margin: "auto", top: "3px" }}
        // onOk={handleOk}
        // onCancel={handleCancel}
      >
        <div className="p-5">
          <div className="d-flex justify-content-between">
            <div></div>
            <div style={{ fontSize: 24, fontWeight: "bold" }}>
              {locale.t("promo.choose_partner")}
            </div>
            <div>
              <button
                style={{
                  border: "none",
                  outline: "none",
                  background: "transparent",
                }}
                onClick={handleClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.828"
                  height="14.828"
                  viewBox="0 0 14.828 14.828"
                >
                  <g id="x" transform="translate(1.414 1.414)">
                    <line
                      id="Line_149"
                      data-name="Line 149"
                      x1="12"
                      y2="12"
                      fill="none"
                      stroke="#8d8d8d"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <line
                      id="Line_150"
                      data-name="Line 150"
                      x2="12"
                      y2="12"
                      fill="none"
                      stroke="#8d8d8d"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </g>
                </svg>
              </button>
            </div>
          </div>
          <div style={{ marginTop: 40 }} className="user">
            <div style={{ fontSize: 12, color: "#8D8D8D", marginBottom: 8 }}>
              {locale.t("promo.label_partner")}
            </div>
            <Form form={form}>
              <Form.Item
                name="partner"
                // rules={[
                //   {
                //     required: true,
                //     message: locale.t("dashboardWallet.input_error"),
                //   },
                // ]}
              >
                <Select
                  placeholder={locale.t("promo.select_partner")}
                  size="large"
                  onChange={handleChage}
                >
                  {data.map((e) => (
                    <Option
                      value={e.id}
                    >{`${e.user.personal_detail.first_name}`}</Option>
                  ))}
                </Select>
              </Form.Item>
              <div className="text-center">
                <Button
                  className="btn-primary-new-iux"
                  style={{ width: 84, height: 37 }}
                  onClick={onFinish}
                >
                  {locale.t("register_button_text")}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PartnerNoti;
