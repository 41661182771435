import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { Drawer, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { TextCapitalized } from "../../helper/text-capitalized";
import { Langs } from "./listlang";
import { useSelector } from "react-redux";
import { CheckLanguageImg } from "../../helper/countryLangImg";
const locale = require("react-redux-i18n").I18n;
function MobileDrawer() {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [onLan, setonLan] = useState(false);
  const [openKeys, setOpenKeys] = useState([""]);
  const [isMenu, setisMenu] = useState([]);
  const rootSubmenuKeys = ["sub1", "sub2", "sub4"];
  const accountTypelist = useSelector((state) => state.account.accountTypeList);
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  const onSelectedMenu = (keys) => {
    // history.push(keys);
    onClose();
  };
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const onCloseLang = () => {
    setonLan(false);
  };
  const showDrawerLang = () => {
    setonLan(true);
  };
  // const handleChangLanguageDesk = (newLang) => {
  //   const oldLang = localStorage.getItem("locale");
  //   localStorage.setItem("locale", newLang);
  //   const newPath = this.props.history.location.pathname.replace(
  //     oldLang,
  //     newLang
  //   );
  //   this.props.history.push(newPath);
  //   window.location.reload();
  // };
  const handleChangLanguageDesk = (newLang) => {
    // console.log(newLang, "newLang");
    const oldLang = localStorage.getItem("locale");
    localStorage.setItem("locale", newLang);
    const newPath = history?.location?.pathname.replace(oldLang, newLang);
    // console.log(newPath, "newPath");
    history?.push(newPath);
    window.location?.reload();
  };
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  useEffect(() => {
    if (accountTypelist) {
      const items = [
        getItem(
          <div className="fw-light">{locale.t("market")}</div>,
          "sub1",
          null,
          [
            getItem(
              <Link
                className="text-white fw-light "
                to={`/${localStorage.getItem("locale")}/market/cfds`}
              >
                CFDs
              </Link>,
              "/market/cfds"
            ),
            getItem(
              <Link
                className="text-white fw-light"
                to={`/${localStorage.getItem("locale")}/market/futures`}
              >
                Futures
              </Link>,
              "/market/futures"
            ),
            getItem(
              <Link
                to={`/${localStorage.getItem("locale")}/market/indices`}
                className="text-white fw-light"
              >
                {TextCapitalized(locale.t("Indices"))}
              </Link>,
              "/market/indices"
            ),
            getItem(
              <Link
                to={`/${localStorage.getItem("locale")}/market/shares`}
                className="text-white fw-light"
              >
                {TextCapitalized(locale.t("Shares"))}
              </Link>,
              "/market/shares"
            ),
            getItem(
              <Link
                to={`/${localStorage.getItem("locale")}/market/metals`}
                className="text-white fw-light"
              >
                {TextCapitalized(locale.t("Metals"))}
              </Link>,
              "/market/metals"
            ),
            getItem(
              <Link
                to={`/${localStorage.getItem("locale")}/market/energies`}
                className="text-white fw-light"
              >
                {TextCapitalized(locale.t("Energies"))}
              </Link>,
              "/market/ "
            ),
            getItem(
              <Link
                to={`/${localStorage.getItem("locale")}/instrument`}
                className="text-white fw-light"
              >
                {TextCapitalized("instrument")}
              </Link>,
              "/instrument"
            ),
          ]
        ),
        getItem(
          <div className="fw-light">
            {TextCapitalized(locale.t("account"))}
          </div>,
          "sub2",
          null,
          [
            getItem(
              <Link
                to={`/${localStorage.getItem("locale")}/accounts`}
                className="text-white fw-light"
              >
                {TextCapitalized(locale.t("cover.accover.title"))}
              </Link>,
              "/accounts"
            ),
            ...accountTypelist
              ?.filter((e) => e.active === 1 && e.type === "real")
              ?.map((e, i) => {
                return getItem(
                  <Link
                    to={`/${localStorage.getItem("locale")}/accounts/${
                      e.display
                    }`}
                    className="text-white fw-light"
                  >
                    {e.display}
                  </Link>,
                  `/${e.display}`
                );
              }),

            getItem(
              <Link
                to={`/${localStorage.getItem("locale")}/accounts/funding`}
                className="text-white fw-light"
              >
                {TextCapitalized(locale.t("cover.deposit.title"))}
              </Link>,
              "/accounts/funding"
            ),

            getItem(
              <Link
                to={`/${localStorage.getItem(
                  "locale"
                )}/swap/non-islamic/Standard`}
                className="text-white fw-light"
              >
                Spread & Swap
              </Link>,
              "/swap/non-islamic/"
            ),
          ]
        ),
        getItem(
          <div className="fw-light">{locale.t("tools")}</div>,
          "sub3",
          null,
          [
            getItem(
              <Link
                to={`/${localStorage.getItem("locale")}/spread/marketresearch`}
                className="text-white fw-light"
              >
                {locale.t("analyze")}
              </Link>,
              "/marketresearch"
            ),
            getItem(
              <Link
                to={`/${localStorage.getItem("locale")}/platforms/mt5`}
                className="text-white fw-light"
              >
                {locale.t("MetaTrader 5")}
              </Link>,
              "/platforms/mt5"
            ),
            getItem(
              <Link
                to={`/${localStorage.getItem(
                  "locale"
                )}/webterminal/version5?sever=live`}
                className="text-white fw-light"
              >
                {locale.t("WebTrader 5")}
              </Link>,
              "/webterminal/version5?sever=live"
            ),
            getItem(
              <Link
                to={`/${localStorage.getItem("locale")}/help/calculators`}
                className="text-white fw-light"
              >
                {locale.t("fxcal_page_title")}
              </Link>,
              "/help/calculators"
            ),
            getItem(
              <Link
                to={`/${localStorage.getItem("locale")}/help/economic-calendar`}
                className="text-white fw-light"
              >
                {locale.t("economic")}
              </Link>,
              "/help/economic-calendar"
            ),
            getItem(
              <Link
                to={`/${localStorage.getItem("locale")}/payment-option`}
                className="text-white fw-light"
              >
                {locale.t("payment_option")}
              </Link>,
              "/payment-option"
            ),
            // getItem(
            //   <Link
            //     to={`/${localStorage.getItem("locale")}/accounts/withdrawal`}
            //     className="text-white fw-light"
            //   >
            //     {locale.t("cover.withdraw.title")}
            //   </Link>,
            //   "/accounts/withdrawal"
            // ),
          ]
        ),
        // getItem(
        //   <div className="fw-light">{locale.t("spread")}</div>,
        //   "sub4",
        //   null,
        //   [
        //     getItem(
        //       <Link
        //         to={`/${localStorage.getItem("locale")}/spread`}
        //         className="text-white fw-light"
        //       >
        //         {locale.t("symbol")}
        //       </Link>,
        //       "/spread"
        //     ),
        //     getItem(
        //       <Link
        //         to={`/${localStorage.getItem("locale")}/spread/marketresearch`}
        //         className="text-white fw-light"
        //       >
        //         {locale.t("analyze")}
        //       </Link>,
        //       "/spread/marketresearch"
        //     ),
        //   ]
        // ),
        getItem(
          <div className="fw-light">{locale.t("company")}</div>,
          "sub5",
          null,
          [
            getItem(
              <div
                onClick={() =>
                  window.open(`https://partner.iuxmarkets.com/en/`)
                }
                // to={`https://partner.iuxmarkets.com/en/`}
                className="text-white fw-light"
              >
                {locale.t("partnership")}
              </div>,
              "/partner"
            ),
            getItem(
              <Link
                to={`/${localStorage.getItem("locale")}/promotion`}
                className="text-white fw-light"
              >
                {locale.t("startbonus")}
              </Link>,
              "/promotion"
            ),
            getItem(
              <Link
                to={`/${localStorage.getItem("locale")}/lotback`}
                className="text-white fw-light"
              >
                {locale.t("loyaltybonus")}
              </Link>,
              "/lotback"
            ),

            getItem(
              <div
                onClick={() =>
                  window.open(
                    `https://help.iuxmarkets.com/${localStorage.getItem(
                      "locale"
                    )}/`
                  )
                }
                className="text-white fw-light"
              >
                {locale.t("help_cover_title")}
              </div>,
              "/help"
            ),

            getItem(
              <Link
                to={`/${localStorage.getItem("locale")}/about-us`}
                className="text-white fw-light"
              >
                {locale.t("about")}
              </Link>,
              "/about-us"
            ),
            getItem(
              <div
                onClick={() =>
                  window.open(
                    `https://help.iuxmarkets.com/${localStorage.getItem(
                      "locale"
                    )}/`
                  )
                }
                className="text-white fw-light"
              >
                Privacy & Terms
              </div>,
              "/Privacy"
            ),
          ]
        ),
      ];
      setisMenu(items);
    }
  }, [accountTypelist]);

  return (
    <div>
      <div onClick={() => showDrawer()} className="text-white fw-light">
        <MenuOutlined className="text-iux-primary fs-24" />
      </div>
      <Drawer
        title={
          <div className="text-center">
            <img
              alt="IUX"
              src={process.env.PUBLIC_URL + "/assets/images/icons/iux-logo.png"}
              width={36}
              onClick={() => {
                history.push(`/${localStorage.getItem("locale")}/`);
                onClose();
              }}
            />
          </div>
        }
        placement={"left"}
        className="bg-black text-white fw-light"
        // closable={false}
        onClose={onClose}
        closeIcon={<CloseOutlined className="text-iux-primary" />}
        open={open}
        key={"left"}
        width="100%"
        extra={
          <div>
            <div
              className="d-flex align-items-center text-white fw-light cursor-pointer"
              onClick={() => showDrawerLang()}
            >
              <div className="mr-1 d-flex">
                <span
                  className={`fi fi-${CheckLanguageImg(
                    localStorage.getItem("locale")
                  )}`}
                />
              </div>
              <div className="me-1" style={{ textTransform: "uppercase" }}>
                {localStorage.getItem("locale")}
              </div>
            </div>
            <Drawer
              title={<div className="text-white"> Language</div>}
              placement={"left"}
              className="bg-black"
              closeIcon={<CloseOutlined className="text-iux-primary" />}
              // closable={false}
              onClose={onCloseLang}
              open={onLan}
              key={"left"}
              width="100%"
              // extra={

              // }
            >
              <div>
                {Langs.map((e, i) => (
                  <div
                    key={i}
                    className="d-flex menu-hover-item align-items-center pl-2 pr-2"
                    style={{ padding: "8px 1rem" }}
                    onClick={() => handleChangLanguageDesk(e.lang)}
                  >
                    <div>
                      <span className={`fi fi-${e?.img}`} />
                    </div>
                    <div className="text-white">&nbsp;{e?.name}</div>
                  </div>
                ))}
              </div>
            </Drawer>
          </div>
        }
      >
        <Menu
          className="text-white fw-light"
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          onDeselect={() => onClose()}
          onSelect={onSelectedMenu}
          //   style={{
          //     width: 256,
          //   }}
          items={isMenu}
          theme="dark"
        />
        <div className="d-flex flex-column align-items-center">
          <Link to={`/${localStorage.getItem("locale")}/register`}>
            <button className="btn-primary-new-iux mt-5" style={{ width: 200 }}>
              <p className="text-center" style={{ fontSize: 14 }}>
                {locale.t("register")}
              </p>
            </button>
          </Link>
          <Link to={`/${localStorage.getItem("locale")}/login`}>
            <button className="btn-outline-iux mt-3 " style={{ width: 200 }}>
              {locale.t("signin")}
            </button>
          </Link>
        </div>
      </Drawer>
    </div>
  );
}

export default MobileDrawer;
