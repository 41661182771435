import React from "react";
import RcQueueAnim from "rc-queue-anim";
import styled from "styled-components";
import {
  MailOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

const locale = require("react-redux-i18n").I18n;

const Abutton = styled.a`
  color: #fff;
  width: 100%;
  text-align: center;
`;
const Border = styled.div`
  width: 1px;
  height: 40px;
  background-color: gray;
  margin: auto;
  @media (max-width: 480px) {
    display: none;
  }
`;
const ContactUs = (props) => {
  return (
    <RcQueueAnim>
      <div key={1} className="container">
        <div className="p-2">
          <h2 className="text-white text-center fw-700">
            {/* {props.locale.t("contact_sidebar.title")} */}
            {locale.t("contactus_header")}
          </h2>
          <p className="text-white text-center">
            {locale.t("contactus_sub_header1")}
          </p>
          <p className="text-white text-center">
            {locale.t("contactus_sub_header2")}
          </p>
          <div className="text-center " style={{ marginTop: 84 }}>
            <div className="row">
              <div className="col">
                <div className="d-flex ">
                  <Abutton href="mailto:support@iuxmarkets.com" target="blank">
                    <div className="col d-flex align-items-center pl-2 pl-xs-5">
                      <div className="mr-3 ">
                        <MailOutlined style={{ color: "#1cb894" }} />
                      </div>
                      <p
                        className="text-white"
                        style={{ whiteSpace: "break-spaces" }}
                      >
                        Email: support@iuxmarkets.com
                      </p>
                    </div>
                  </Abutton>
                </div>
                <div className="d-flex mt-2">
                  <Abutton href="tel:+35725247681" target="blank">
                    <div className="col d-flex align-items-center pl-2 pl-xs-5">
                      <div className="mr-3 ">
                        <PhoneOutlined style={{ color: "#1cb894" }} />
                      </div>
                      <p
                        className="text-white"
                        style={{ whiteSpace: "break-spaces" }}
                      >
                        +35725247681
                      </p>
                    </div>
                  </Abutton>
                </div>
              </div>
              <Border />
              <div className="col">
                <div className="d-flex align-items-center justify-content-left pl-2 pl-xs-5 text-start">
                  <div className="mr-3 ">
                    <EnvironmentOutlined style={{ color: "#1cb894" }} />
                  </div>

                  <span
                    className="text-white text-left"
                    style={{ whiteSpace: "break-spaces" }}
                  >
                    P.B. 1257 Bonovo Road, Fomboni, Comoros, KM.
                    {/* {locale.t("aboutPage.section_3.description_2")} */}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-center pl-2 pl-xs-5 text-start">
                  <div className="mr-3 ">
                    <EnvironmentOutlined style={{ color: "#1cb894" }} />
                  </div>

                  <span
                    className="text-white text-left"
                    style={{ whiteSpace: "break-spaces" }}
                  >
                    Beachmont Business Centre, 321, Kingstown, St. Vincent and
                    the Grenadines.
                    {/* {locale.t("aboutPage.section_3.description_2")} */}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-left pl-2 pl-xs-5 text-start">
                  <div className="mr-3 ">
                    <EnvironmentOutlined style={{ color: "#1cb894" }} />
                  </div>

                  <span
                    className="text-white text-left"
                    style={{ whiteSpace: "break-spaces" }}
                  >
                    16 Foti Kolakidi, 1st Floor, Office A, Agia Zoni, 3031,
                    Limassol, Cyprus.
                    {/* {locale.t("aboutPage.section_3.description_2")} */}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </RcQueueAnim>
  );
};

export default ContactUs;
