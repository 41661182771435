export default {
  th: {
    platform_android_header: {
      title: "MetaTrader สำหรับ Android\n",
      sub_title:
        "ห้ามพลาดโอกาส! ติดตามตลาดตลอดเวลาด้วยแอพ MetaTrader สำหรับ Android\n",
      button_text: "ดาวน์โหลด Metatrader 5",
    },
  },
  lo: {
    platform_android_header: {
      title: "MetaTrader ສຳ ລັບ Android\n",
      sub_title:
        "ຢ່າພາດໂອກາດ! ຕິດຕາມຕະຫຼາດຕະຫຼອດເວລາດ້ວຍແອັບ MetaTrader ສຳ ລັບ Android\n",
      button_text: "ດາວໂຫລດ Metatrader 5",
    },
  },
  en: {
    platform_android_header: {
      title: "MetaTrader for Android\n",
      sub_title: `Donn't miss the opportunity! Follow the market all the time with the MetaTrader app for Android.`,
      button_text: "Download Metatrader 5",
    },
  },
  hi: {
    platform_android_header: {
      title: "MetaTrader for Android\n",
      sub_title: `Donn't miss the opportunity! Follow the market all the time with the MetaTrader app for Android.`,
      button_text: "Download Metatrader 5",
    },
  },
  ms: {
    platform_android_header: {
      title: `MetaTrader untuk Android\n`,
      sub_title: `Jangan lepaskan peluang! Ikut pasaran sepanjang masa dengan aplikasi MetaTrader untuk Android.`,
      button_text: `Muat turun Metatrader 5`,
    },
  },
  id: {
    platform_android_header: {
      title: `MetaTrader untuk Android\n`,
      sub_title: `Jangan lewatkan kesempatan! Ikuti pasar sepanjang waktu dengan aplikasi MetaTrader untuk Android.`,
      button_text: `Download Metatrader 5`,
    },
  },
  zh: {
    platform_android_header: {
      title: `MetaTrader的用于Android版\n`,
      sub_title: `千万不要错过机会！按照市场上所有Android版MetaTrader的应用程序的时间。`,
      button_text: `下载MetaTrader 5`,
    },
  },
  vi: {
    platform_android_header: {
      title: "MetaTrader đối với Android\n",
      sub_title:
        "Đừng bỏ lỡ cơ hội! Thực hiện theo thị trường mọi lúc với ứng dụng MetaTrader đối với Android\n",
      button_text: "Tải xuống Metatrader ",
    },
  },
  ko: {
    platform_android_header: {
      title: `안드로이드 에 대한 메타 트레이더 \n`,
      sub_title: `기회를 놓치지 마세요! 시장에게 안드로이드에 대한 메타 트레이더 앱의 모든 시간을 따르십시오.`,
      button_text: `다운로드 메타 트레이더 5`,
    },
  },
  fil: {
    platform_android_header: {
      title: `MetaTrader para sa Android \n`,
      sub_title: `Huwag palampasin ang pagkakataon! Sundin ang market sa lahat ng oras na may MetaTrader app para sa Android.`,
      button_text: `I-download ang Metatrader 5`,
    },
  },
  ja: {
    platform_android_header: {
      title: "Android Metatrader\n",
      sub_title:
        "機会をお見逃しなく！ Android用のMetatraderアプリで常に市場をフォローしてください。",
      button_text: "Metatrader 5をダウンロードします",
    },
  },
};
