import { Carousel } from "antd";
import React from "react";
const locale = require("react-redux-i18n").I18n;
function Slidebonus() {
  return (
    <Carousel>
      <div className="p-4">
        <div
          className="pb-4 px-2"
          style={{
            background: "url('/assets/images/promotions/bonus-30-mobile.png')",
            backgroundSize: "100% 100%",

            backgroundRepeat: "no-repeat",
            height: 600,
          }}
        >
          <div className="row justify-content-between align-items-end h-100 mt-0 mt-md-4">
            <div className="col-12 col-md-12 col-lg-6">
              <h4 className=" text-white fs-24 text-uppercase fw-light text-start">
                BONUS OPEN <br />
                ACCOUNT $30
              </h4>
              <div className="">
                {/* <h6
                  className="mt-2 text-white fw-light fs-16 d-flex"
                  style={{
                    lineHeight: "2",
                    textAlign: "left",
                  }}
                >
                  <img
                    src="/assets/images/icons/tick.svg"
                    alt="true"
                    className="me-3"
                  />
                  No deposit required
                </h6> */}
                <h6
                  className="mt-2 text-white fw-light d-flex "
                  style={{
                    lineHeight: "2",
                    textAlign: "left",
                  }}
                >
                  <img
                    src="/assets/images/icons/tick.svg"
                    alt="true"
                    className="me-3"
                  />
                  Automatically credited to your account
                </h6>
                <h6
                  className="mt-2 text-white fw-light d-flex "
                  style={{
                    lineHeight: "2",
                    textAlign: "left",
                  }}
                >
                  <img
                    src="/assets/images/icons/tick.svg"
                    alt="true"
                    className="me-3"
                  />
                  All profits earned can be withdrawn
                </h6>
                <h6
                  className="mt-2 text-white fw-light d-flex "
                  style={{
                    lineHeight: "2",
                    textAlign: "left",
                  }}
                >
                  <img
                    src="/assets/images/icons/tick.svg"
                    alt="true"
                    className="me-3"
                  />
                  Available to new clients
                </h6>
                <h6
                  className="mt-2 text-white fw-light d-flex "
                  style={{
                    lineHeight: "2",
                    textAlign: "left",
                  }}
                >
                  <img
                    src="/assets/images/icons/tick.svg"
                    alt="true"
                    className="me-3"
                  />
                  Non-withdrawable bonus
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4">
        <div
          className="pb-4 px-4"
          style={{
            background: "url('/assets/images/promotions/bonus-35-mobile.png')",
            height: 600,
            backgroundSize: "100% 100%",

            backgroundRepeat: "no-repeat",
          }}
        >
          <div className=" d-flex align-items-end h-100">
            <div className="f-lg-30 f-md-24 text-white ">
              DEPOSIT BONUS
              <br /> UP TO $300
            </div>
          </div>
        </div>
      </div>
      <div className="p-4">
        <div
          className="pb-4 px-4"
          style={{
            background: "url('/assets/images/promotions/bonus-25-mobile.png')",
            backgroundSize: "100% 100%",
            height: 600,

            backgroundRepeat: "no-repeat",
          }}
        >
          <div className=" d-flex align-items-end h-100">
            <div className="f-lg-30 f-md-24 text-white ">
              DEPOSIT BONUS
              <br /> UP TO $3000
            </div>
          </div>
        </div>
      </div>
    </Carousel>
  );
}

export default Slidebonus;
