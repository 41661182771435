import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from "firebase/messaging";
import { postTokenFCM } from "./api/notifications";
import { CheckLanguageISO } from "./helper/countryLangISO";
import {
  getAuth,
  signInWithEmailAndPassword,
  getIdToken,
  signInWithCustomToken,
  sendSignInLinkToEmail,
} from "firebase/auth";
import { useHistory } from "react-router-dom";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
// vapidKey: `BIpLutl5OxMMMaazFM1zyL3MbmnMauw_sju1IQ4aNWUdrDUWkDi914NtJ342AI706tweHkQhN4wMskJcENyj6Eg`,
const app = initializeApp(firebaseConfig);

// export const messaging = (await isSupported()) ? getMessaging(app) : null;
const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      console.log("support");
      return getMessaging(app);
    }
    console.log("Firebase not supported this browser");
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();
export const auth = getAuth(app);

export const requestPermission = async () => {
  Notification.requestPermission().then(async (permission) => {
    if (permission === "granted") {
      let isMessaging = await messaging;
      console.log(isMessaging);
      return getToken(isMessaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY,
      })
        .then(async (currentToken) => {
          if (currentToken) {
            let payload = {
              token: currentToken,
              device: "web",
              lang_iso: CheckLanguageISO(localStorage.getItem("locale")),
            };
            const { data } = await postTokenFCM(payload);
          } else {
            console.log("Failed to generate the app registration token.");
          }
        })
        .catch((err) => {
          console.log(
            "An error occurred when requesting to receive the token.",
            err
          );
        });
    } else {
      console.log("User Permission Denied.");
    }
  });
};
const onDeleteToken = () => {
  messaging.getToken().then((currentToken) => {
    if (currentToken) {
      // User is logged in and has a valid FCM token
      // Revoke the token when the user logs out
      messaging
        .deleteToken(currentToken)
        .then(() => {
          console.log("Token deleted successfully");
        })
        .catch((error) => {
          console.error("Error deleting token:", error);
        });
    }
  });
};
// requestPermission();

export const onMessageListener = () =>
  new Promise(async (resolve) => {
    let isMessaging = await messaging;
    onMessage(isMessaging, (payload) => {
      console.log(payload, "payload");
      resolve(payload);
    });
  });

export const checkUserFirebase = () => {
  return auth.currentUser;
};
export const getuser = async () => {
  return auth?.currentUser?.getIdToken()?.then((userCredential) => {
    return userCredential;
  });
};

export const handleSubmitAuth = ({ email, password }) => {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;

      user
        .getIdToken()
        .then((token) => {
          return { type: "success", token: token };

          // Use the token as needed
          // console.log(token, "token");
        })
        .catch((error) => {
          return { type: "error", message: error.message };
          // Handle error getting the token
        });
      // ...
    })
    .catch((error) => {
      return { type: "error", message: error.message };
    });
};
export const generateLoginLink = async (email) => {
  const actionCodeSettings = {
    // Configure the redirect URL to domain B's login page
    url: "https://trading.iuxmarkets.com/",
    // Add any additional parameters if needed
    // For example, you can pass a token or identifier as a query parameter
    // to be used for verification on domain B
    // dynamicLinkDomain: 'your_dynamic_link_domain',
  };

  try {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then((userCredential) => {
        // User logged in successfully on domain B
        console.log(
          "User logged in successfully on domain B:",
          userCredential.user
        );
      })
      .catch((error) => {
        console.error("Error signing in on domain B:", error);
      });
    // Save the user's email address to local storage or state
    // The email will be used to complete the login process on domain B
    // window.localStorage.setItem("emailForSignIn", "user@example.com");
    // console.log("Login link sent successfully");
  } catch (error) {
    console.error("Error sending login link:", error);
  }
};
export const authenticateWithToken = async (token) => {
  try {
    signInWithCustomToken(auth, token)
      .then((userCredential) => {
        if (userCredential) {
          return userCredential;
        }
        // console.log(userCredential);
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        // window.localStorage.setItem("emailForSignIn", email);
        // ...
      })
      .catch((error) => {
        console.error("Error signing in on domain B:", error);
        return { type: "error", message: error.message };
      });
  } catch (error) {
    console.error("Error authenticating with token:", error);
    return { type: "error", message: error.message };
  }
};
export const createWithToken = async (uid) => {
  try {
    // Authenticate the user on domain A using the token
    app
      .auth()
      .createCustomToken("dcf6c91d-52ca-4f3b-90e4-c4c75c1c5b15")
      .then((userCredential) => {
        // User logged in successfully on domain B
        console.log(
          "User logged in successfully on domain B:",
          userCredential.user
        );
      })
      .catch((error) => {
        console.error("Error signing in on domain B:", error);
      });
    // User authenticated successfully on domain A
    console.log("User authenticated successfully on domain A");
  } catch (error) {
    console.error("Error authenticating with token:", error);
  }
};
