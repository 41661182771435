import React from "react";
import RcQueueAnim from "rc-queue-anim";
import { get } from "../../../helper/request";
import { HOST } from "../../../config";
import { CheckLanguage } from "../../../helper/countryid";
import { onOpenPolicy } from "../../../helper/policy";
const locale = require("react-redux-i18n").I18n;

const Condition = (props) => {
  // const onOpenPolicy = async (keyname) => {
  //   try {
  //     const { data } = await get(
  //       `${
  //         process.env.REACT_APP_API_URL
  //       }v2/policy?key_name=${keyname}&country_id=${CheckLanguage(
  //         localStorage.getItem("locale")
  //       )}`
  //     );
  //     window.open(
  //       `${process.env.REACT_APP_API_URL}/uploads/${data?.data?.file_path}`
  //     );
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  return (
    <RcQueueAnim>
      <div key={1} className="container">
        <h2 className="text-white text-center text-uppercase">
          {locale.t("termCondition.title")}
        </h2>
        <p className="text-center text-white my-5">Legal documents</p>

        <div
          className="cursor-pointer"
          onClick={() => onOpenPolicy("condition_iux_market")}
        >
          <p className="mt-3 text-primary-new-iux text-center">
            {locale.t("termCondition.condition_1")}
          </p>
        </div>
        <div
          className="cursor-pointer"
          onClick={() => onOpenPolicy("iux_client_agreements")}
        >
          <p className="mt-3 text-primary-new-iux text-center">
            {locale.t("termCondition.clientAgreement")}
          </p>
        </div>

        <div
          className="cursor-pointer"
          onClick={() => onOpenPolicy("bonus_terms_conditions_30")}
        >
          <p className="mt-3 text-primary-new-iux text-center">
            {locale.t("termCondition.condition_2")}
          </p>
        </div>
        <div
          className="cursor-pointer"
          onClick={() => onOpenPolicy("bonus_deposit_35")}
        >
          <p className="mt-3 text-primary-new-iux text-center">
            {locale.t("termCondition.condition_3")}
          </p>
        </div>
        <div
          className="cursor-pointer"
          onClick={() => onOpenPolicy("bonus_deposit_25")}
        >
          <p className="mt-3 text-primary-new-iux text-center">
            {locale.t("termCondition.condition_4")}
          </p>
        </div>
        <div
          className="cursor-pointer"
          onClick={() => onOpenPolicy("general_business_terms")}
        >
          <p className="mt-3 text-primary-new-iux text-center">
            {locale.t("termCondition.generalBusiness")}
          </p>
        </div>
        <div
          className="cursor-pointer"
          onClick={() => onOpenPolicy("aml_policy")}
        >
          <p className="mt-3 text-primary-new-iux text-center">
            {locale.t("termCondition.amlPolicy")}
          </p>
        </div>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={process.env.PUBLIC_URL + locale.t("pdf.invitePartner")}
          hidden
        ></a>
        {/* <a rel="noopener noreferrer" target="_blank" href={process.env.PUBLIC_URL + locale.t('pdf.openClose')}><p className="mt-3">{locale.t('termCondition.condition_5')}</p></a> */}
      </div>
    </RcQueueAnim>
  );
};

export default Condition;
