export default {
  th: {
    pay_method: {
      mobile: `ให้คุณฝากเงินผ่านธนาคารออนไลน์`,
      platfrom: `ให้คุณฝากเงินผ่านแพลตฟอร์มออนไลน์`,
      platfrom_2: `ให้คุณฝากเงินผ่านแพลตฟอร์มที่ให้บริการ`,
    },
    pay_method_withdraw: {
      mobile: `ให้คุณถอนเงินผ่านธนาคารออนไลน์`,
      platfrom: `ให้คุณถอนเงินผ่านแพลตฟอร์มออนไลน์`,
      platfrom_2: `ให้คุณถอนเงินผ่านแพลตฟอร์มที่ให้บริการ`,
    },
    pay_currency: `สกุลเงินที่รองรับ:`,
    pay_amount: `(โดยเปรียบเทียบขั้นต่ำอยู่ที่ $10  สำหรับบัญชี std+ และ std ส่วนบัญชี Pro มีขั้นต่ำอยู่ที่ $1000)`,
    pay_amount_withdraw: `ถอนเงินขั้นต่อ $3`,
    pay_commission: {
      title_1: `เราไม่เรียกเก็บค่าคอมมิชชั่นใดๆ แต่ขึ้นอยู่กับประเภทการชำระเงินที่คุณเลือก คุณอาจถูกเรียกเก็บค่าคอมมิชชั่นตามนโยบายค่าธรรมเนียมของบริการที่เกี่ยวข้อง`,
      title_2: `ผู้ให้บริการการชำระเงินอาจใช้ข้อจำกัดและข้อกำหนดในการโอนเงิน คุณสามารถตรวจสอบดูได้ในเว็บไซต์ของพวกเขา`,
    },
    pay_title_time: {
      title_1: `3 - 15 นาที`,
      title_2: `ทันที`,
      title_3: `5 - 20 นาที`,
    },
    pay_time: `เราดำเนินการคำขอเหล่านี้ตลอด 24 ชั่วโมง 365 วัน`,
    pay_free: `ฟรี`,
  },
  lo: {},
  en: {
    pay_method: {
      mobile: `Deposit on your mobile banking application.`,
      platfrom: `Deposit on your online platform.`,
      platfrom_2: `Deposit on your online platform`,
    },
    pay_method_withdraw: {
      mobile: `Withdraw on your mobile banking application.`,
      platfrom: `Withdraw on your online platform.`,
      platfrom_2: `Withdraw on your online platform`,
    },
    pay_currency: `Accepted Currencies:`,
    pay_amount: `(Minimum rate is at $10 for Standard+ and Standard account, and $1000 for account Pro and Raw)`,
    pay_amount_withdraw: `(Minimum rate is at $3)`,
    pay_commission: {
      title_1: `We do not have commission fees. However, it depends on your payment method. You may be charged with a commission fee in accordance with the applicable service fee policy.`,
      title_2: `The provider may apply restrictions and requirements on money transfers. You can check it out on their website.`,
    },
    pay_title_time: {
      title_1: `3 - 15 Minute`,
      title_2: `Immediately`,
      title_3: `5 - 20 Minute`,
    },
    pay_time: `We process these requests 24 hours a day, 365 days a year.`,
    pay_free: `Free`,
  },
  hi: {
    pay_method: {
      mobile: `Deposit on your mobile banking application.`,
      platfrom: `Deposit on your online platform.`,
      platfrom_2: `Deposit on your online platform`,
    },
    pay_method_withdraw: {
      mobile: `Withdraw on your mobile banking application.`,
      platfrom: `Withdraw on your online platform.`,
      platfrom_2: `Withdraw on your online platform`,
    },
    pay_currency: `Accepted Currencies:`,
    pay_amount: `(Minimum rate is at $10 for Standard+ and Standard account, and $1000 for account Pro and Raw)`,
    pay_amount_withdraw: `(Minimum rate is at $3)`,
    pay_commission: {
      title_1: `We do not have commission fees. However, it depends on your payment method. You may be charged with a commission fee in accordance with the applicable service fee policy.`,
      title_2: `The provider may apply restrictions and requirements on money transfers. You can check it out on their website.`,
    },
    pay_title_time: {
      title_1: `3 - 15 Minute`,
      title_2: `Immediately`,
      title_3: `5 - 20 Minute`,
    },
    pay_time: `We process these requests 24 hours a day, 365 days a year.`,
    pay_free: `Free`,
  },
  ms: {
    pay_method: {
      mobile: `Deposit pada aplikasi perbankan mudah alih anda.`,
      platfrom: `Deposit pada platform dalam talian anda secara online.`,
      platfrom_2: `Deposit pada platform dalam talian anda dengan pembekal anda`,
    },
    pay_method_withdraw: {
      mobile: `Withdraw pada aplikasi perbankan mudah alih anda.`,
      platfrom: `Withdraw pada platform dalam talian anda secara online.`,
      platfrom_2: `Withdraw pada platform dalam talian anda dengan pembekal anda`,
    },
    pay_currency: `Mata Wang yang diterima:`,
    pay_amount: `(Kadar minimum ialah $10 untuk  Standard+ dan akaun Standard, dan $1000 untuk akaun Pro)`,
    pay_amount_withdraw: `(Minimum rate is at $3)`,
    pay_commission: {
      title_1: `Kami tidak mempunyai yuran komisen. Walau bagaimanapun, ia bergantung pada kaedah pembayaran anda. Anda mungkin dikenakan bayaran komisen mengikut polisi yuran perkhidmatan yang berkenaan.`,
      title_2: `Pembekal boleh mengenakan sekatan dan keperluan pada pemindahan wang. Anda boleh menyemaknya di laman web mereka.`,
    },
    pay_title_time: {
      title_1: `3 - 15 minit`,
      title_2: `Serta merta`,
      title_3: `5 - 20 minit`,
    },
    pay_time: `Kami memproses permintaan ini 24 jam sehari, 365 hari setahun.`,
    pay_free: `Percuma`,
  },
  id: {
    pay_method: {
      mobile: `Deposit on your mobile banking application.`,
      platfrom: `Deposit on your online platform.`,
      platfrom_2: `Deposit on your online platform`,
    },
    pay_method_withdraw: {
      mobile: `Withdraw on your mobile banking application.`,
      platfrom: `Withdraw on your online platform.`,
      platfrom_2: `Withdraw on your online platform`,
    },
    pay_currency: `Accepted Currencies:`,
    pay_amount: `(Minimum rate is at $10 for Standard+ and Standard account, and $1000 for account Pro and Raw)`,
    pay_amount_withdraw: `(Minimum rate is at $3)`,
    pay_commission: {
      title_1: `We do not have commission fees. However, it depends on your payment method. You may be charged with a commission fee in accordance with the applicable service fee policy.`,
      title_2: `The provider may apply restrictions and requirements on money transfers. You can check it out on their website.`,
    },
    pay_title_time: {
      title_1: `3 - 15 Minute`,
      title_2: `Immediately`,
      title_3: `5 - 20 Minute`,
    },
    pay_time: `We process these requests 24 hours a day, 365 days a year.`,
    pay_free: `Free`,
  },
  ms: {
    pay_method: {
      mobile: `Deposit pada aplikasi perbankan mudah alih anda.`,
      platfrom: `Deposit pada platform dalam talian anda secara online.`,
      platfrom_2: `Deposit pada platform dalam talian anda dengan pembekal anda`,
    },
    pay_method_withdraw: {
      mobile: `Withdraw pada aplikasi perbankan mudah alih anda.`,
      platfrom: `Withdraw pada platform dalam talian anda secara online.`,
      platfrom_2: `Withdraw pada platform dalam talian anda dengan pembekal anda`,
    },

    pay_currency: `Mata Wang yang diterima:`,
    pay_amount: `(Kadar minimum ialah $10 untuk  Standard+ dan akaun Standard, dan $1000 untuk akaun Pro)`,
    pay_amount_withdraw: `(Minimum rate is at $3)`,
    pay_commission: {
      title_1: `Kami tidak mempunyai yuran komisen. Walau bagaimanapun, ia bergantung pada kaedah pembayaran anda. Anda mungkin dikenakan bayaran komisen mengikut polisi yuran perkhidmatan yang berkenaan.`,
      title_2: `Pembekal boleh mengenakan sekatan dan keperluan pada pemindahan wang. Anda boleh menyemaknya di laman web mereka.`,
    },
    pay_title_time: {
      title_1: `3 - 15 minit`,
      title_2: `Serta merta`,
      title_3: `5 - 20 minit`,
    },
    pay_time: `Kami memproses permintaan ini 24 jam sehari, 365 hari setahun.`,
    pay_free: `Percuma`,
  },
  zh: {
    pay_method: {
      mobile: `Deposit on your mobile banking application.`,
      platfrom: `Deposit on your online platform.`,
      platfrom_2: `Deposit on your online platform`,
    },
    pay_method_withdraw: {
      mobile: `Withdraw on your mobile banking application.`,
      platfrom: `Withdraw on your online platform.`,
      platfrom_2: `Withdraw on your online platform`,
    },
    pay_currency: `Accepted Currencies:`,
    pay_amount: `(Minimum rate is at $10 for Standard+ and Standard account, and $1000 for account Pro and Raw)`,
    pay_amount_withdraw: `(Minimum rate is at $3)`,
    pay_commission: {
      title_1: `We do not have commission fees. However, it depends on your payment method. You may be charged with a commission fee in accordance with the applicable service fee policy.`,
      title_2: `The provider may apply restrictions and requirements on money transfers. You can check it out on their website.`,
    },
    pay_title_time: {
      title_1: `3 - 15 Minute`,
      title_2: `Immediately`,
      title_3: `5 - 20 Minute`,
    },
    pay_time: `We process these requests 24 hours a day, 365 days a year.`,
    pay_free: `Free`,
  },

  vi: {
    pay_method: {
      mobile: `Cho bạn nạp tiền thông qua ngân hàng trực tuyến.`,
      platfrom: `Cho bạn nạp tiền thông qua một nền tảng trực tuyến.`,
      platfrom_2: `Cho bạn nạp tiền thông qua một nền tảng mà cho dịch vụ.`,
    },
    pay_method_withdraw: {
      mobile: `Withdraw on your mobile banking application.`,
      platfrom: `Withdraw on your online platform.`,
      platfrom_2: `Withdraw on your online platform`,
    },
    pay_currency: `Tiền tệ được hỗ trợ:`,
    pay_amount: `(So sánh, mức tối thiểu là $10  đối với tài khoản std+ và std, và tài khản Pro mức tối thiểu là $1000)`,
    pay_amount_withdraw: `(Minimum rate is at $3)`,
    pay_commission: {
      title_1: `Chúng tôi không tính phí hoa hồng. Nhưng phụ thuộc vào hình thức thanh toán bạn chọn. Bạn có thể được tính hoa hồng theo chính sách phí dịch vụ hiện hành liên quan.`,
      title_2: `Các nhà cung cấp dịch vụ thanh toán, có thể áp dụng các hạn chế và điều kiện đối với việc chuyển tiền. Bạn có thể kiểm tra nó trên trang web của chúng tôi.`,
    },
    pay_title_time: {
      title_1: `3 - 15 phút`,
      title_2: `ngay`,
      title_3: `5 - 20 phút`,
    },
    pay_time: `Chúng tôi thực hiện những yêu cầu này 24 giờ một ngày, 365 ngày.`,
    pay_free: `Miễn phí`,
  },
  ko: {
    pay_method: {
      mobile: `Deposit on your mobile banking application.`,
      platfrom: `Deposit on your online platform.`,
      platfrom_2: `Deposit on your online platform`,
    },
    pay_method_withdraw: {
      mobile: `Withdraw on your mobile banking application.`,
      platfrom: `Withdraw on your online platform.`,
      platfrom_2: `Withdraw on your online platform`,
    },
    pay_currency: `Accepted Currencies:`,
    pay_amount: `(Minimum rate is at $10 for Standard+ and Standard account, and $1000 for account Pro and Raw)`,
    pay_amount_withdraw: `(Minimum rate is at $3)`,
    pay_commission: {
      title_1: `We do not have commission fees. However, it depends on your payment method. You may be charged with a commission fee in accordance with the applicable service fee policy.`,
      title_2: `The provider may apply restrictions and requirements on money transfers. You can check it out on their website.`,
    },
    pay_title_time: {
      title_1: `3 - 15 Minute`,
      title_2: `Immediately`,
      title_3: `5 - 20 Minute`,
    },
    pay_time: `We process these requests 24 hours a day, 365 days a year.`,
    pay_free: `Free`,
  },

  fil: {
    pay_method: {
      mobile: `Deposit on your mobile banking application.`,
      platfrom: `Deposit on your online platform.`,
      platfrom_2: `Deposit on your online platform`,
    },
    pay_method_withdraw: {
      mobile: `Withdraw on your mobile banking application.`,
      platfrom: `Withdraw on your online platform.`,
      platfrom_2: `Withdraw on your online platform`,
    },
    pay_currency: `Accepted Currencies:`,
    pay_amount: `(Minimum rate is at $10 for Standard+ and Standard account, and $1000 for account Pro and Raw)`,
    pay_amount_withdraw: `(Minimum rate is at $3)`,
    pay_commission: {
      title_1: `We do not have commission fees. However, it depends on your payment method. You may be charged with a commission fee in accordance with the applicable service fee policy.`,
      title_2: `The provider may apply restrictions and requirements on money transfers. You can check it out on their website.`,
    },
    pay_title_time: {
      title_1: `3 - 15 Minute`,
      title_2: `Immediately`,
      title_3: `5 - 20 Minute`,
    },
    pay_time: `We process these requests 24 hours a day, 365 days a year.`,
    pay_free: `Free`,
  },
  ja: {
    pay_method: {
      mobile: "モバイルバンキングアプリケーションに預け入れます。",
      platfrom: "オンラインプラットフォームに入金します。",
      platfrom_2: "オンラインプラットフォームに入金します",
    },
    pay_method_withdraw: {
      mobile: "モバイルバンキングアプリケーションで撤退します。",
      platfrom: "オンラインプラットフォームで撤退します。",
      platfrom_2: "オンラインプラットフォームで撤退します",
    },
    pay_currency: "受け入れられた通貨：",
    pay_amount:
      "（標準+および標準アカウントで最低料金は10ドル、アカウントProおよびrawで1000ドルです）",
    pay_amount_withdraw: "（最低料金は3ドルです）",
    pay_commission: {
      title_1:
        "手数料はありません。 ただし、支払い方法に依存します。 該当するサービス料金に従って、手数料が請求される場合があります。",
      title_2:
        "プロバイダーは、送金に制限と要件を適用する場合があります。 あなたは彼らのウェブサイトでそれをチェックすることができます。",
    },
    pay_title_time: {
      title_1: "3-15分",
      title_2: "すぐに",
      title_3: "5 - 20 分",
    },
    pay_time: "これらの要求を1日24時間、365日処理します。",
    pay_free: "無料",
  },
  es: {
    pay_method: {
      mobile: "Depósito en su aplicación bancaria móvil.",
      platfrom: "Depósito en su plataforma en línea.",
      platfrom_2: "Depósito en su plataforma en línea",
    },
    pay_method_withdraw: {
      mobile: "Retiro en su aplicación bancaria móvil.",
      platfrom: "Retiro en su plataforma en línea.",
      platfrom_2: "Retiro en su plataforma en línea",
    },
    pay_currency: "Monedas aceptadas:",
    pay_amount:
      "(La tasa mínima es de $10 para cuentas Standard+ y Standard, y $1000 para cuentas Pro y Raw)",
    pay_amount_withdraw: "(La tasa mínima es de $3)",
    pay_commission: {
      title_1:
        "No tenemos tarifas de comisión. Sin embargo, depende de su método de pago. Puede que se le cobre una tarifa de comisión de acuerdo con la política de tarifas de servicio aplicable.",
      title_2:
        "El proveedor puede aplicar restricciones y requisitos en las transferencias de dinero. Puede verificarlo en su sitio web.",
    },
    pay_title_time: {
      title_1: "3 - 15 Minutos",
      title_2: "Inmediatamente",
      title_3: "5 - 20 Minutos",
    },
    pay_time:
      "Procesamos estas solicitudes las 24 horas del día, los 365 días del año.",
    pay_free: "Gratis",
  },
};
