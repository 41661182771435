import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  Col,
  Row,
  Layout,
  Button,
  Modal,
  Input,
  Select,
  Form,
  message,
  Alert,
  Popover,
  Divider,
} from "antd";
import { post } from "../../helper/request";
import {
  addAccount,
  updateLaverage,
  deleteAccount,
  archiveAccount,
  getArchive,
} from "../../actions";
import { useParams, Link, useHistory } from "react-router-dom";
import { renderApiDomain } from "../../helper/renderApiDomain";
const { Option } = Select;
const locale = require("react-redux-i18n").I18n;
export const OpenAccount = (props) => {
  const { confirm } = Modal;
  const history = useHistory();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [accounts, setAccounts] = useState(props.user.accounts);
  const accountTypeList = useSelector((state) => state.account.accountTypeList);
  // const [currency] = useState("USD");
  const [currency, setCurrency] = useState("USD");
  const refAdd = React.createRef();
  const [accountId, setAccountId] = useState(null);
  const selectAccountType = ["Standard", "StandardBonus", "Raw", "Cent"];
  const selectLeverage = {
    Standard: ["1", "20", "50", "100", "200", "500", "1000", "2000"],
    StandardBonus: ["1", "20", "50", "100", "200", "500", "1000"],
    Raw: ["1", "20", "50", "100", "200", "500", "1000"],
    Cent: ["1", "20", "50", "100", "200", "500", "1000", "2000"],
  };
  const [leverType, setLeverType] = useState(null);

  const [form] = Form.useForm();
  const [accType, setAccType] = useState(selectLeverage[selectAccountType[0]]);
  useEffect(() => {
    setAccounts(props.user.accounts);
  }, [props.user]);
  const handleaccountTypeChange = (value, option) => {
    if (value === "Cent") {
      setCurrency("USC");
    } else {
      setCurrency("USD");
    }
    setAccountId(option.key);
    setAccType(selectLeverage[value]);
    setLeverType(selectLeverage[value][0]);
  };
  const onCreate = async () => {
    try {
      const values = await form.validateFields();
      const data = {
        currency: currency,
        platform: "MT5",
        account_type_id: accountId,
        // account_type: values.type,
        password: values.password,
        password_confirmation: values.password,
        leverage: leverType,
        // password_investor: values.password_investor,
        code: localStorage.getItem("code"),
      };

      setButtonLoading(true);
      post(renderApiDomain() + "user/account/trade-real/add", data)
        .then((res) => {
          let { data } = res;

          const account = {
            id: data.data.id,
            user_id: data.data.user_id,
            account_number: data.data.account_number.toString(),
            platform: data.data.platform,
            currency: data.data.currency,
            api_server: data.data.api_server,
            account_type: values.type,
            password: data.data.password,
            status: data.data.status,
            leverage: data.data.leverage,
            current_leverage: data.data.current_leverage,
            current_balance: data.data.current_balance || 0,
            current_credit: data.data.current_credit || 0,
            deletedAt: null,
            type: data.data.type,
            reset_code: null,
            password_investor: data.data.password_investor,
            reset_code_investor: null,
            createdAt: data.data.createdAt,
            updatedAt: data.data.updatedAt,
            isOrderPlaced: false,
            avilableWithdrawNonCalculated: 0,
            avilableWithdraw: 0,
          };

          props.addAccount([...accounts, account]);
          setButtonLoading(false);

          Modal.info({
            title: locale.t("dashboardWallet.your_mt5"),
            content: (
              <Alert message={data.data.account_number} type="success" />
            ),
          });
          refAdd.current.resetFields();
        })
        .catch((e) => {
          console.log(e);
          setButtonLoading(false);
          try {
            if (e.response.data.code === 10) {
              Modal.error({
                title: locale.t("dashboardWallet.your_account"),
                content: (
                  <Alert
                    message={locale.t("dashboardWallet.error_10")}
                    type="success"
                  />
                ),
              });
            } else {
              message.error(e.response.data.error);
            }
          } catch (error) {
            console.log(error);
          }
        });
    } catch (errorInfo) {
      setButtonLoading(false);
    }
  };
  const validatorPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = new RegExp(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
      );
      if (value.match(regex)) {
        return Promise.resolve();
      }
      return Promise.reject(
        locale.t("dashboardWallet.modal.accountPassword_validate")
      );
    },
  });

  const validatorInvestorPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = new RegExp(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
      );
      if (value.match(regex)) {
        return Promise.resolve();
      }
      return Promise.reject(
        locale.t("dashboardWallet.modal.accountPasswordInvestor")
      );
    },
  });

  const onSecondCityChange = (value) => {
    setLeverType(value);
  };
  return (
    <div className="user">
      <div className="d-flex justify-content-center align-items-center flex-column pt-5 pb-5">
        <img src="/assets/images/icons/none-account.svg" alt="tranfer-icons" />
        <p className="my-4">{locale.t("dashboardWallet.notAccount")}</p>
        <Button
          onClick={() => {
            history.replace(
              `/${localStorage.getItem(
                "locale"
              )}/wallet/account/openaccount?typeAccount=Recommended
                }`
            );
          }}
          className="mt-4 btn-primary-new-iux"
          htmlType="submit"
          type="primary"
        >
          <p className="">+ {locale.t("dashboardWallet.createAccount")}</p>
        </Button>
      </div>
    </div>
  );
};

OpenAccount.propTypes = {};

const mapStateToProps = ({ user, i18n }) => {
  return { user, locale: i18n.locale };
};

const mapDispatchToProps = {
  addAccount,
  getArchive,
  updateLaverage,
  archiveAccount,
  deleteAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenAccount);
