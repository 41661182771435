import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, Form, Input, Select } from "antd";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { calcCurrency, calcCurrencyMt5 } from "./Cacl";
import Recommendation from "./components/Recommendation";
const { Option } = Select;
const locale = require("react-redux-i18n").I18n;
const BoxContentCal = styled.div`
  padding: 12px;
  border-radius: 20px;
  border: 1px solid #8080803d;
  background: #242424;
`;
const BoxErrors = styled.div`
  color: red;
`;

const styleInput = {
  height: 40,
  borderRadius: 4,
};

const styleDisabled = {
  backgroundColor: "transparent",
  color: "#fff",
  height: 40,
  borderRadius: 4,
};

export default function Currency(props) {
  const [form] = Form.useForm();
  const refSelect = useRef();
  const [valueFromUser, setValueFromUser] = useState();
  const [currencyTo, setCurrencyTo] = useState();
  const [currencyFrom, setCurrencyFrom] = useState();
  const [converseCurrency, setConverseCurrency] = useState();
  const [sumExchange, setSumExchange] = useState();

  const [loading, setLoading] = useState(false);

  const [errors, setError] = useState([]);

  const metaTrader = props.mtType;
  const { handleChangeSymbol } = props;

  function onChangeSelectFrom(value) {
    setCurrencyFrom(value);
  }

  function onChangeSelectTo(value) {
    setCurrencyTo(value);
  }

  function handleChangeValue(value) {
    setValueFromUser(value);
  }

  const handleSum = async () => {
    if (metaTrader.mt4) {
      if (currencyFrom === undefined) {
        setError(locale.t("dashboardWallet.input_error"));
      } else {
        setLoading(true);
        const { converse } = await calcCurrency(currencyFrom, currencyTo);
        setConverseCurrency(converse);
        setSumExchange(valueFromUser * converse);
        setLoading(false);
      }
    } else {
      if (currencyFrom === undefined) {
        setError(locale.t("dashboardWallet.input_error"));
      } else {
        setLoading(true);
        const { converse } = await calcCurrencyMt5(currencyFrom, currencyTo);
        setConverseCurrency(converse);
        setSumExchange(valueFromUser * converse);
        setLoading(false);
      }
    }
  };
  const handleReset = () => {
    setConverseCurrency(0);
    setSumExchange(0);
  };

  return (
    <>
      <Form form={form} className="col-md-8 mb-4">
        <BoxContentCal>
          <Col span={12}>
            <Form.Item name="1">
              <div className="mt-3 text-gray">
                {locale.t("fxcal_list_1_box.Platform")}
              </div>
              <Select
                size="large"
                className="w-100"
                onChange={(value) => {
                  handleChangeSymbol(value);
                  form.resetFields();
                  handleReset();
                }}
                defaultValue="Metatrader5"
              >
                <Option value={"mt5"}>Metatrader5</Option>
              </Select>
            </Form.Item>
          </Col>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name="1">
                <div className="mt-3  text-gray">
                  {locale.t("fxcal_list_2_box.title_1")}
                </div>
                <Select
                  onChange={(value) => onChangeSelectFrom(value)}
                  defaultValue={locale.t("calculator_select")}
                  size="large"
                  className="w-100"
                >
                  <Option value="AUD">AUD</Option>
                  <Option value="CAD">CAD</Option>
                  <Option value="CHF">CHF</Option>
                  <Option value="CNH">CNH</Option>
                  <Option value="CZK">CZK</Option>
                  <Option value="DKK">DKK</Option>
                  <Option value="EUR">EUR</Option>
                  <Option value="GBP">GBP</Option>
                  <Option value="HKD">HKD</Option>
                  <Option value="HUF">HUF</Option>
                  <Option value="JPY">JPY</Option>
                  <Option value="MXN">MXN</Option>
                  <Option value="NOK">NOK</Option>
                  <Option value="NZD">NZD</Option>
                  <Option value="PLN">PLN</Option>
                  <Option value="RUB">RUB</Option>
                  <Option value="SGD">SGD</Option>
                  <Option value="USD">USD</Option>
                  <Option value="ZAR">ZAR</Option>
                  {/* {reutnOptionSelect()} */}
                </Select>
              </Form.Item>
              <Form.Item name="1">
                <div className="mt-3 text-gray">
                  {locale.t("fxcal_list_2_box.title_3")}
                </div>
                <Input
                  style={styleInput}
                  placeholder={"Please enter value"}
                  onChange={(e) => handleChangeValue(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col
              span={12}
              className="d-flex flex-column justify-content-between"
            >
              <Form.Item name="1">
                <div className="mt-3 text-gray">
                  {locale.t("fxcal_list_2_box.title_2")}
                </div>
                <Select
                  onChange={(value) => onChangeSelectTo(value)}
                  defaultValue={locale.t("calculator_select")}
                  size="large"
                  className="w-100"
                >
                  <Option value="AUD">AUD</Option>
                  <Option value="CAD">CAD</Option>
                  <Option value="CHF">CHF</Option>
                  <Option value="CNH">CNH</Option>
                  <Option value="CZK">CZK</Option>
                  <Option value="DKK">DKK</Option>
                  <Option value="EUR">EUR</Option>
                  <Option value="GBP">GBP</Option>
                  <Option value="HKD">HKD</Option>
                  <Option value="HUF">HUF</Option>
                  <Option value="JPY">JPY</Option>
                  <Option value="MXN">MXN</Option>
                  <Option value="NOK">NOK</Option>
                  <Option value="NZD">NZD</Option>
                  <Option value="PLN">PLN</Option>
                  <Option value="RUB">RUB</Option>
                  <Option value="SGD">SGD</Option>
                  <Option value="USD">USD</Option>
                  <Option value="ZAR">ZAR</Option>
                  {/* {reutnOptionSelect()} */}
                </Select>
              </Form.Item>
              <Form.Item name="1">
                <div className="mt-3  text-gray">
                  {locale.t("fxcal_list_2_box.title_4")}
                </div>
                <Input
                  value={converseCurrency ? converseCurrency.toFixed(5) : ""}
                  style={styleDisabled}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
          <div style={{ margin: "1rem 0" }}>
            <BoxErrors>{errors}</BoxErrors>
            <button
              loading={loading}
              onClick={() => handleSum()}
              className="w-sm-100 btn-primary-new-iux"
            >
              {locale.t("btn_calculator")}
            </button>
          </div>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="text-gray">
                {locale.t("fxcal_list_2_box.title_5")}
              </div>
              <Input
                value={sumExchange !== undefined ? sumExchange.toFixed(2) : ""}
                style={styleDisabled}
                disabled
              />
            </Col>
          </Row>
        </BoxContentCal>
      </Form>
      <Recommendation />
    </>
  );
}
