import { useEffect, useState } from "react";
import moment from "moment";
import { Image } from "antd";

import { getTimeAgoText } from "../../helper/getTimeAgo";
import { patchReadNotification } from "../../api/notifications";
import { CheckLanguageMoment } from "../../helper/countryLangMoment";
import { CheckFormatDateChart } from "../../helper/countryFormatDateChart";
const locale = require("react-redux-i18n").I18n;
const NotificationUpdate = ({
  type,
  dataNoti,
  onRead,
  onShowDetail,
  isKey,
  onImagesPreview,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {}, []);
  const renderIcons = (value) => {
    switch (value) {
      case 0:
        return (
          <div className="badge-icons-noti-success">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clip-path="url(#clip0_8513_14501)">
                <path
                  d="M12.6667 0.666748H3.33333C2.4496 0.667807 1.60237 1.01934 0.97748 1.64423C0.352588 2.26912 0.00105857 3.11635 0 4.00008L0 12.0001C0.00105857 12.8838 0.352588 13.731 0.97748 14.3559C1.60237 14.9808 2.4496 15.3324 3.33333 15.3334H12.6667C13.5504 15.3324 14.3976 14.9808 15.0225 14.3559C15.6474 13.731 15.9989 12.8838 16 12.0001V4.00008C15.9989 3.11635 15.6474 2.26912 15.0225 1.64423C14.3976 1.01934 13.5504 0.667807 12.6667 0.666748ZM3.33333 2.00008H12.6667C13.0659 2.00087 13.4557 2.12109 13.786 2.34528C14.1163 2.56948 14.3719 2.88738 14.52 3.25808L9.41467 8.36408C9.03895 8.73829 8.53028 8.94839 8 8.94839C7.46972 8.94839 6.96105 8.73829 6.58533 8.36408L1.48 3.25808C1.6281 2.88738 1.88374 2.56948 2.21403 2.34528C2.54432 2.12109 2.93414 2.00087 3.33333 2.00008ZM12.6667 14.0001H3.33333C2.8029 14.0001 2.29419 13.7894 1.91912 13.4143C1.54405 13.0392 1.33333 12.5305 1.33333 12.0001V5.00008L5.64267 9.30675C6.26842 9.93092 7.11617 10.2814 8 10.2814C8.88383 10.2814 9.73158 9.93092 10.3573 9.30675L14.6667 5.00008V12.0001C14.6667 12.5305 14.456 13.0392 14.0809 13.4143C13.7058 13.7894 13.1971 14.0001 12.6667 14.0001Z"
                  fill="#1CB894"
                />
              </g>
              <defs>
                <clipPath id="clip0_8513_14501">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        );
      case 1:
        return (
          <div className="badge-icons-noti-read">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clip-path="url(#clip0_8513_12214)">
                <path
                  d="M14.9727 5.4L10.3567 0.975996C9.7311 0.356994 8.88711 0.0089588 8.00705 0.00708766C7.12699 0.00521653 6.28153 0.349659 5.65333 0.965996L1.02667 5.4C0.703077 5.71166 0.445442 6.0852 0.269073 6.4984C0.0927037 6.91161 0.00120015 7.35606 0 7.80533L0 12.6667C0.00105857 13.5504 0.352588 14.3976 0.97748 15.0225C1.60237 15.6474 2.4496 15.9989 3.33333 16H12.6667C13.5504 15.9989 14.3976 15.6474 15.0225 15.0225C15.6474 14.3976 15.9989 13.5504 16 12.6667V7.80533C15.9988 7.35599 15.9073 6.91148 15.7308 6.49826C15.5543 6.08503 15.2965 5.71154 14.9727 5.4ZM6.586 1.91866C6.96403 1.54399 7.4753 1.33467 8.00754 1.33667C8.53978 1.33867 9.04947 1.55183 9.42467 1.92933L13.9227 6.23866L9.414 10.748C9.03288 11.1113 8.52654 11.314 8 11.314C7.47346 11.314 6.96712 11.1113 6.586 10.748L2.07667 6.23866L6.586 1.91866ZM14.6667 12.6667C14.6667 13.1971 14.456 13.7058 14.0809 14.0809C13.7058 14.4559 13.1971 14.6667 12.6667 14.6667H3.33333C2.8029 14.6667 2.29419 14.4559 1.91912 14.0809C1.54405 13.7058 1.33333 13.1971 1.33333 12.6667V7.80533C1.334 7.67592 1.34718 7.54687 1.37267 7.42L5.64333 11.6907C6.27048 12.3117 7.1174 12.66 8 12.66C8.8826 12.66 9.72952 12.3117 10.3567 11.6907L14.6273 7.42C14.6528 7.54687 14.666 7.67592 14.6667 7.80533V12.6667Z"
                  fill="#999999"
                />
              </g>
              <defs>
                <clipPath id="clip0_8513_12214">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        );

        return (
          <div className="badge-icons-noti-progress">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clip-path="url(#clip0_8300_2622)">
                <path
                  d="M9.61547 14.5732C9.48353 14.6061 9.34902 14.6353 9.2155 14.6598C8.86647 14.7247 8.63572 15.0604 8.70028 15.4098C8.73216 15.5816 8.82977 15.7248 8.96229 15.8182C9.09887 15.9144 9.2727 15.9578 9.44983 15.9248C9.60883 15.8953 9.76907 15.8606 9.92632 15.8214C10.271 15.7356 10.481 15.3864 10.395 15.0419C10.3092 14.6971 9.96033 14.4873 9.61547 14.5732Z"
                  fill="#009EFF"
                />
                <path
                  d="M14.3722 5.95178C14.4173 6.08756 14.5033 6.19842 14.6119 6.27501C14.773 6.38848 14.9838 6.42665 15.185 6.36014C15.5223 6.24815 15.705 5.88447 15.5934 5.54738C15.5426 5.3937 15.4862 5.23955 15.4261 5.08946C15.2942 4.75962 14.92 4.59903 14.59 4.73097C14.2604 4.86284 14.0997 5.23707 14.2318 5.56701C14.2822 5.69317 14.3295 5.82266 14.3722 5.95178Z"
                  fill="#009EFF"
                />
                <path
                  d="M11.7056 13.6548C11.5921 13.7297 11.475 13.8022 11.3571 13.8701C11.0493 14.0477 10.9439 14.4411 11.1213 14.7487C11.1695 14.8324 11.2337 14.9008 11.3076 14.9531C11.506 15.0927 11.7755 15.1136 11.9998 14.9844C12.1401 14.9035 12.2796 14.8174 12.4147 14.7281C12.711 14.5323 12.7925 14.1333 12.5967 13.8369C12.4009 13.5403 12.002 13.4589 11.7056 13.6548Z"
                  fill="#009EFF"
                />
                <path
                  d="M15.9929 7.74834C15.9789 7.39333 15.6799 7.11701 15.3248 7.13087C14.9701 7.14487 14.6936 7.44399 14.7075 7.79887C14.7129 7.93455 14.7142 8.07226 14.7111 8.20781C14.7061 8.43044 14.815 8.62889 14.9842 8.74817C15.085 8.81916 15.2074 8.86212 15.34 8.86514C15.6951 8.87302 15.9893 8.59144 15.9971 8.23625C16.0007 8.07429 15.9993 7.9102 15.9929 7.74834Z"
                  fill="#009EFF"
                />
                <path
                  d="M14.2687 11.9629C13.984 11.7493 13.5812 11.8073 13.368 12.0914C13.2864 12.2003 13.2004 12.3077 13.1123 12.4112C12.8823 12.6816 12.9148 13.0876 13.1851 13.3179C13.2005 13.331 13.2161 13.343 13.2322 13.3542C13.501 13.5437 13.8745 13.5002 14.0918 13.2452C14.197 13.1217 14.2994 12.9933 14.397 12.8633C14.6103 12.5792 14.5526 12.1762 14.2687 11.9629Z"
                  fill="#009EFF"
                />
                <path
                  d="M15.2132 9.63754C14.8743 9.53126 14.5133 9.71994 14.4071 10.0589C14.3664 10.1884 14.3214 10.3185 14.2728 10.4459C14.166 10.7264 14.2683 11.0351 14.5028 11.2005C14.5459 11.2307 14.5933 11.2564 14.6448 11.2758C14.9766 11.4025 15.3482 11.236 15.4747 10.904C15.5325 10.7524 15.5861 10.5976 15.6345 10.4437C15.7406 10.1047 15.5521 9.74381 15.2132 9.63754Z"
                  fill="#009EFF"
                />
                <path
                  d="M6.80993 14.6657C6.23486 14.5624 5.68322 14.3866 5.16012 14.1414C5.15393 14.1381 5.1484 14.1344 5.1419 14.1315C5.01863 14.0735 4.89557 14.0113 4.77629 13.9464C4.77588 13.9459 4.77512 13.9456 4.77447 13.9453C4.55562 13.8248 4.34203 13.6914 4.13454 13.5454C1.10884 11.414 0.381345 7.21836 2.51287 4.1927C2.97636 3.53502 3.53725 2.98634 4.16257 2.55146C4.17027 2.5461 4.17797 2.54077 4.18561 2.53537C6.38912 1.01709 9.38114 0.914764 11.7209 2.47494L11.2184 3.20102C11.0787 3.40312 11.1646 3.5504 11.4092 3.52838L13.5921 3.33296C13.837 3.31095 13.9834 3.09911 13.9175 2.86262L13.3314 0.750637C13.2657 0.51387 13.0977 0.485529 12.9579 0.687593L12.4542 1.4154C10.7371 0.26276 8.67765 -0.176758 6.63352 0.177704C6.42764 0.213336 6.22465 0.256948 6.02444 0.307713C6.02289 0.307988 6.02166 0.30816 6.02042 0.308435C6.01268 0.310327 6.00484 0.312838 5.9973 0.314936C4.23462 0.767593 2.6967 1.79566 1.5993 3.26249C1.59005 3.27346 1.58052 3.28419 1.57178 3.29612C1.53529 3.34527 1.49908 3.39555 1.46362 3.44584C1.40563 3.52825 1.34847 3.61272 1.29378 3.69719C1.28693 3.70737 1.28171 3.71772 1.27572 3.72801C0.370133 5.13131 -0.0666682 6.75366 0.00824147 8.40532C0.00841344 8.41076 0.0081039 8.41623 0.00824147 8.4218C0.0154986 8.58314 0.0282931 8.74672 0.0456619 8.90775C0.0465906 8.91813 0.048895 8.92797 0.050649 8.93836C0.0686026 9.10028 0.0911306 9.26259 0.119368 9.42486C0.40635 11.0805 1.18736 12.5703 2.35768 13.7296C2.3604 13.7323 2.36322 13.7353 2.36597 13.7381C2.36693 13.7392 2.368 13.7397 2.36893 13.7407C2.68336 14.0508 3.02537 14.3377 3.39362 14.597C4.35734 15.2761 5.43046 15.725 6.58293 15.9318C6.93261 15.9946 7.26664 15.7618 7.32938 15.4123C7.39208 15.0626 7.15951 14.7283 6.80993 14.6657Z"
                  fill="#009EFF"
                />
                <path
                  d="M7.6046 2.86157C7.31697 2.86157 7.08398 3.09476 7.08398 3.38199V8.56675L11.8259 11.018C11.9022 11.0575 11.9839 11.0762 12.0643 11.0762C12.2527 11.0762 12.4346 10.9736 12.527 10.7946C12.6589 10.5392 12.5591 10.2255 12.3037 10.0936L8.12464 7.93301V3.38199C8.1246 3.09476 7.89189 2.86157 7.6046 2.86157Z"
                  fill="#009EFF"
                />
              </g>
              <defs>
                <clipPath id="clip0_8300_2622">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        );

      default:
        return (
          <div className="badge-icons-noti-success">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clip-path="url(#clip0_8513_14501)">
                <path
                  d="M12.6667 0.666748H3.33333C2.4496 0.667807 1.60237 1.01934 0.97748 1.64423C0.352588 2.26912 0.00105857 3.11635 0 4.00008L0 12.0001C0.00105857 12.8838 0.352588 13.731 0.97748 14.3559C1.60237 14.9808 2.4496 15.3324 3.33333 15.3334H12.6667C13.5504 15.3324 14.3976 14.9808 15.0225 14.3559C15.6474 13.731 15.9989 12.8838 16 12.0001V4.00008C15.9989 3.11635 15.6474 2.26912 15.0225 1.64423C14.3976 1.01934 13.5504 0.667807 12.6667 0.666748ZM3.33333 2.00008H12.6667C13.0659 2.00087 13.4557 2.12109 13.786 2.34528C14.1163 2.56948 14.3719 2.88738 14.52 3.25808L9.41467 8.36408C9.03895 8.73829 8.53028 8.94839 8 8.94839C7.46972 8.94839 6.96105 8.73829 6.58533 8.36408L1.48 3.25808C1.6281 2.88738 1.88374 2.56948 2.21403 2.34528C2.54432 2.12109 2.93414 2.00087 3.33333 2.00008ZM12.6667 14.0001H3.33333C2.8029 14.0001 2.29419 13.7894 1.91912 13.4143C1.54405 13.0392 1.33333 12.5305 1.33333 12.0001V5.00008L5.64267 9.30675C6.26842 9.93092 7.11617 10.2814 8 10.2814C8.88383 10.2814 9.73158 9.93092 10.3573 9.30675L14.6667 5.00008V12.0001C14.6667 12.5305 14.456 13.0392 14.0809 13.4143C13.7058 13.7894 13.1971 14.0001 12.6667 14.0001Z"
                  fill="#1CB894"
                />
              </g>
              <defs>
                <clipPath id="clip0_8513_14501">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        );
    }
  };
  const [visible, setVisible] = useState(false);
  return (
    <div className={dataNoti?.show && "noti-background"}>
      <div
        className="noti-message-container"
        onClick={() => {
          onShowDetail(isKey);
          if (dataNoti.is_read === 0) {
            onRead(dataNoti.notification_template_id, dataNoti.createdAt);
          }
        }}
      >
        <div className="">
          {renderIcons(dataNoti.is_read)}
          {/* <HistoryToggleOffIcon /> */}
        </div>
        <div className="noti-message-div">
          <div className="noti-message-title">{dataNoti.title}</div>
          <div className="noti-message-descripton">{dataNoti.sub_title}</div>
          <div
            className={`noti-message-time ${
              dataNoti.is_read === 0 ? "unread" : "read"
            }`}
          >
            {getTimeAgoText(dataNoti.createdAt)}
          </div>
        </div>
        {dataNoti.is_read === 0 && (
          <div className=" align-items-center d-flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
            >
              <circle cx="4" cy="4" r="4" fill="#1CB894" />
            </svg>
          </div>
        )}
      </div>
      {dataNoti?.show && (
        <div>
          <div className="d-flex justify-content-center px-3">
            {dataNoti.image && (
              <Image
                className="noti-image "
                // width={200}
                src={`${process.env.REACT_APP_POLICY}${dataNoti.image}`}
              />
            )}
            {/* <img
              src={`${process.env.REACT_APP_SYMBOL}${dataNoti.image}`}
              alt={dataNoti.title}
              className="noti-image"
            /> */}
          </div>
          <div className="noto-description mt-4">{dataNoti.description}</div>
          <div className="noto-description text-right">
            {moment(dataNoti.createdAt)
              .locale(CheckLanguageMoment(localStorage.getItem("locale")))
              .format(CheckFormatDateChart(localStorage.getItem("locale")))}
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationUpdate;
