import React from "react";
import { Card, Row, Col } from "antd";
import styled from "styled-components";
const locale = require("react-redux-i18n").I18n;
const Pointer = styled.div`
  cursor: pointer;
  margin: 16px;
  @media (max-width: 768px) {
    margin: 0;
  }
`;

const STDPLUS = styled.div`
  background: #06a0ff;
  color: #fff;
  border-radius: 2px;
  padding: 0px 2px;
  font-size: 12px;
  display: inline-block;
`;

const STD = styled.div`
  background: #085a8c;
  color: #fff;
  border-radius: 2px;
  padding: 0px 2px;
  font-size: 12px;
  display: inline-block;
`;

const ECN = styled.div`
  background: #085a8c;
  color: #fff;
  border-radius: 2px;
  padding: 0px 2px;
  font-size: 12px;
  display: inline-block;
`;

const AccountButton = (props) => {
  const account = props.item.account_type;
  const typeX = account.replace("Standard", "STD");
  const typeB = account.replace("StandardBonus", "STDB");
  return (
    <Pointer className="select-card">
      <Card
        style={
          props.style
            ? props.style
            : {
                borderRadius: 20,
                boxShadow: "unset",
                border: "1px solid #F2F2F2",
              }
        }
        className="account-card"
        // style={}
        title={
          <div className="d-flex flex-column text-left">
            <div className="">
              <div className="fs-14 fw-700">{props.item.account_number}</div>

              <div className="d-flex fs-10">
                {props.item.account_name_show}
                {" • "}
                <div>{props.item.platform}</div>
              </div>
            </div>
          </div>
        }
      >
        <Row>
          <div
            span={12}
            className="ant-respon w-100"
            style={{ padding: "6px" }}
          >
            <div className="d-flex justify-content-between w-100 mb-3">
              <div className="fs-12">
                {locale.t("dashboardWallet.accountBalance")} (
                {props.item.account_type === "Cent" ? "USC" : "USD"})
              </div>
              <div className="fs-14 fw-700">
                {props.item.current_balance.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </div>
            </div>
            <div className="d-flex justify-content-between w-100 mb-3">
              <div className="fs-12 ">
                {locale.t("dashboardWallet.accountCredit")} (
                {props.item.account_type === "Cent" ? "USC" : "USD"})
              </div>
              <div className="fs-14 fw-700">
                {props.item.current_credit.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </div>
            </div>

            <div className="d-flex justify-content-between w-100 ">
              <div className="fs-12 ">
                {locale.t("dashboardWallet.accountLeverage")}
              </div>
              <div className="fs-14 fw-700">1:{props.item.leverage}</div>
            </div>
          </div>
        </Row>
      </Card>
    </Pointer>
  );
};

export default AccountButton;
