import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { StyledAccountMain } from "../../components";
import Banner from "./banner";
import { CheckOutlined, StarFilled, CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";

const TITLE = "Compare | IUX Markets";
const locale = require("react-redux-i18n").I18n;

const Box = styled.div`
  padding: 12px;
  border: ${(props) => (props.active ? "2px solid #009eff" : "")};
  border-radius: 6px;
  width: 240px;
  text-align: center;
  box-shadow: 0 0 4px #80808073;
`;

const Star = styled.div`
  position: absolute;
  top: -42px;
  color: #fff;
`;

const Compare = (props) => {
  const item = [
    locale.t("compare.mt5.text_1"),
    locale.t("compare.mt5.text_2"),
    locale.t("compare.mt5.text_3"),
    locale.t("compare.mt5.text_4"),
    locale.t("compare.mt5.text_5"),
    locale.t("compare.mt5.text_6"),
    locale.t("compare.mt5.text_7"),
    locale.t("compare.mt5.text_8"),
    locale.t("compare.mt5.text_9"),
  ];
  const item2 = [
    locale.t("compare.mt4.text_1"),
    locale.t("compare.mt4.text_2"),
    locale.t("compare.mt4.text_3"),
    locale.t("compare.mt4.text_4"),
    locale.t("compare.mt4.text_5"),
    locale.t("compare.mt4.text_6"),
    locale.t("compare.mt4.text_7"),
    locale.t("compare.mt4.text_8"),
    locale.t("compare.mt4.text_9"),
  ];
  return (
    <div>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Banner />
      <div style={{ height: 30 }} />
      <StyledAccountMain>
        <div className="pt-4 pb-4">
          <h5 className="text-center font-bold-iux">
            MetaTrader 4 Vs MetaTrader 5
          </h5>
          <div className="text-center">
            <img
              className="w-sm-100"
              src={
                process.env.PUBLIC_URL + "/assets/images/comparison_macbook.svg"
              }
              alt=".."
            />
          </div>
          <div className="justify-center row">
            <div className="col-md-8">
              <p className="text-center">{locale.t("compare.des_1")}</p>
              <p className="mt-4 text-center">{locale.t("compare.des_2")}</p>
              <p className="mt-4 text-center">{locale.t("compare.des_3")}</p>
            </div>
          </div>
          <h5 className="text-center font-bold-iux mt-5">
            {locale.t("compare.titile_2")}
          </h5>
          <h6 className="text-center font-bold-iux">
            {locale.t("compare.sub_titile_2")}
          </h6>
          <div className="row mt-4">
            <div className="align-items-center col-md-6 d-flex flex-column border-md-right">
              <div className="d-flex justify-center">
                <Box active className="d-inline-block position-relative">
                  <div className="hot-banner">
                    <Star>
                      <StarFilled />
                    </Star>
                  </div>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/compare-mt5.svg"
                    }
                    alt=".."
                  />
                </Box>
              </div>
              <div className="ml-5">
                {item.map((e) => (
                  <div className="d-flex algin-items-center mt-4">
                    <div className="mr-1" style={{ color: "#1CB894" }}>
                      <CheckOutlined />
                    </div>
                    <div>{e}</div>
                  </div>
                ))}
              </div>
              <div className="mt-4">
                <Button
                  className="btn-primary-nex-iux text-uppercase"
                  onClick={() =>
                    props.history.push(
                      `/${localStorage.getItem("locale")}/wallet`
                    )
                  }
                >
                  {locale.t("btn.mt5")}
                </Button>
              </div>
            </div>
            <div className="align-items-center col-md-6 d-flex flex-column mt-5 mt-md-0">
              <div className="d-flex justify-center">
                <Box className="d-inline-block position-relative">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/compare-mt4.svg"
                    }
                    alt=".."
                  />
                </Box>
              </div>
              <div className="ml-4">
                {item2.map((e) => (
                  <div className="d-flex algin-items-center mt-4">
                    <div className="mr-1" style={{ color: "#D75554" }}>
                      <CloseOutlined />
                    </div>
                    <div>{e}</div>
                  </div>
                ))}
              </div>
              <div className="mt-4">
                <Button
                  className="btn-primary-outline text-uppercase"
                  onClick={() =>
                    props.history.push(
                      `/${localStorage.getItem("locale")}/wallet`
                    )
                  }
                >
                  {locale.t("btn.mt5")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </StyledAccountMain>
    </div>
  );
};

export default Compare;
