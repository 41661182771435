import React, { useState, useEffect } from "react";
import { Button, Input, Icon, Form, message } from "antd";
import {
  authentication,
  getUser,
  LogoutAuth,
  getBank,
  getUserv2,
  getUserLogin,
} from "../../actions";
import { connect, useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import FooterAuthPage from "../../components/footer-landing-page/FooterAuth";
import { Times } from "../forgotpassword/ForgotPasswordForm";
import { CloseOutlined } from "@ant-design/icons";
import { post } from "../../helper/request";
import {
  auth,
  authenticateWithToken,
  getuser,
  handleSubmitAuth,
} from "../../firebase";
import { signInWithEmailAndPassword } from "@firebase/auth";
import { async } from "q";
import { renderApiDomain } from "../../helper/renderApiDomain";

const TITLE = "Login | IUX Markets";
const locale = require("react-redux-i18n").I18n;

const WarningBox = styled.div`
  border: 1px solid #d1870054;
  background: #ffa50012;
  display: flex;
  padding: 12px 12px;
  align-items: center;
`;
const textcenter = {
  textAlign: "center",
  padding: "10px 0px 0px",
  fontSize: "24px",
  fontWeight: 700,

  color: "#FFF",
};
const inputlabel = {
  height: "42px",
};

export const LoginPage = (props) => {
  let urlParams = new URLSearchParams(window.location.search);
  let access_token = urlParams?.get("access_token");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [warning, setWarning] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const [error, setError] = useState("");
  const [countDown, setCountDown] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const dispatch = useDispatch();

  const onResend = async () => {
    try {
      setLoadingResend(true);
      await axios
        .post(renderApiDomain() + "resend-verify-email", {
          email: email,
        })
        .then((res) => {
          setCountDown(59);
          setError(locale.t("validate_email_seccuss"));
        });
      setLoadingResend(false);
    } catch (e) {
      setError(e.message);
      setLoadingResend(false);
    }
  };
  // useEffect(() => {
  //   onJoinus();
  // }, []);

  // useEffect(() => {
  //   if (props?.user?.isAuth) {
  //     if (access_token) {
  //       onJoinus();
  //     } else {
  //       props.history.replace(`/${localStorage.getItem("locale")}/wallet`);
  //     }
  //   }
  //   if (access_token) {
  //     onJoinus();
  //   }
  // }, [access_token]);
  useEffect(() => {
    const myInterval = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
      }
      if (countDown === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
          setLoading(false);
        } else {
          setMinutes(minutes - 1);
          setCountDown(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [countDown]);

  const getAccessToken = async (props) => {
    localStorage.removeItem("tutorialHide");

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;

        if (user.emailVerified) {
          user
            .getIdToken()
            .then((token) => {
              props.getUserv2().then(async (response) => {
                let sessionId = await window.seon.getBase64Session();
                let payload = {
                  session: sessionId,
                  session_id: response?.data?.data?.session_id,
                  source: "web",
                };
                try {
                  const { data } = await post(
                    `${process.env.REACT_APP_API_URL}seon`,
                    payload
                  );
                } catch (error) {
                  console.log(error);
                }
                dispatch(
                  getBank(response.data?.data?.personal_detail?.country_id)
                );
              });
              dispatch(getUserLogin());
            })
            .catch((error) => {
              setError(error.message);
            });
        } else {
          auth?.signOut();
          setWarning(true);
          message.error("Please verify your email first");
        }

        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        switch (errorCode) {
          case "auth/user-not-found":
            message.error(
              "Incorrect email or password. Please check your credentials and try again."
            );
            // setError("User  not found");
            // Handle the error for when the user is not found
            break;
          case "auth/wrong-password":
            message.error(
              "Incorrect email or password. Please check your credentials and try again."
            );
            // setError("password is incorrect");
            // Handle the error for when the password is incorrect
            break;
          case "auth/invalid-email":
            message.error(
              "Incorrect email or password. Please check your credentials and try again."
            );
            // setError("email is invalid");
            // Handle the error for when the email is invalid
            break;
          // Handle other error codes as needed
          default:
            message.error("Something went wrong");
            // setError("Something went wrong");
            // Handle any other errors
            break;
        }
      });
  };
  const onInputChangeEmail = (event) => {
    const {
      target: { name, value },
    } = event;
    setEmail(value);
  };
  const onInputChangePassword = (event) => {
    const {
      target: { name, value },
    } = event;
    setPassword(value);
  };

  // render() {
  // const handleAuthWithToken = async () => {
  //   try {
  //     await authenticateWithToken(
  //       "eyJhbGciOiJSUzI1NiIsImtpZCI6IjAzMmNjMWNiMjg5ZGQ0NjI2YTQzNWQ3Mjk4OWFlNDMyMTJkZWZlNzgiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vaXV4ZGV2IiwiYXVkIjoiaXV4ZGV2IiwiYXV0aF90aW1lIjoxNzAzNTgzNTk1LCJ1c2VyX2lkIjoiZGNmNmM5MWQtNTJjYS00ZjNiLTkwZTQtYzRjNzVjMWM1YjE1Iiwic3ViIjoiZGNmNmM5MWQtNTJjYS00ZjNiLTkwZTQtYzRjNzVjMWM1YjE1IiwiaWF0IjoxNzAzNTgzNTk1LCJleHAiOjE3MDM1ODcxOTUsImVtYWlsIjoic3VyZHV2b3Jrb0BndWZ1bS5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJzdXJkdXZvcmtvQGd1ZnVtLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.EFOM30SdxNPMIc9eeZUdPEfRnLZagGT3p5mtS9FvqqkftSpQt9evvu_QBzCUQmXWaP5vLNUc_TT8i_J8uO2U3ibj9tOrgphtn2POGn53CgqXZASqUxJ8bTOC_lEmBld8ntqZ1AJipwe7hMurNs2LxnQaFNEZJ5TgRh57g9dvwzpSFZIJeCi0K2B5rb7Mk384gfNpYqNBg9h2eFnt9ZRBt2GCPG5Bmf8WZiqa9x8tlD7gIZFPAVOihcPDuEX7IuqgJJcL48eAHCMc62jXAc8_0ojwaw4kR2ITBAfy7M11IxnAcwMpq3iTjROyiqaOLra3HnNpIccZRzCGlpn-DFojqQ"
  //     );
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="bg-black">
        <div className="container">
          <div className="pt-4 pb-4 text-center text-white">
            {locale.t("login_top_1")}&nbsp;
            <span className="font-bold-iux">
              {locale.t("login_top_2")}&nbsp;
            </span>
            {locale.t("login_top_3")}
          </div>
        </div>
      </div>
      <section className="d-flex flex-column h-100 bg-black">
        <div className="text-center rectangle-card rectangle-card-login ml-auto mr-auto mt-4 mb-4 position-relative">
          {/* <Times
            className="font-bold-iux position-absolute"
            onClick={() =>
              props.history.push(`/${localStorage.getItem("locale")}`)
            }
          >
            <CloseOutlined style={{ color: "#A0A0A0" }} />
          </Times> */}
          <Form onFinish={() => getAccessToken(props)}>
            <div className="logo">
              {/* <Link
                to={`/${localStorage.getItem("locale")}`}
                style={{ padding: 0, overflowY: "auto", margin: "auto" }}
              > */}
              <a href="http://iux.com/">
                <img
                  alt="IUX"
                  className="registerlogo border-logo"
                  src={
                    process.env.PUBLIC_URL + "/assets/images/icons/iux-logo.png"
                  }
                />
              </a>
              {/* </Link> */}
            </div>
            <h5 style={textcenter}>{locale.t("login_title")}</h5>
            <div>
              <p className="d-flex justify-content-between mt-4 mb-4 flex-wrap">
                <span className="text-white">{locale.t("login_registrt")}</span>
                <Link to={`/${localStorage.getItem("locale")}/register`}>
                  <span
                    style={{
                      fontSize: "16px",
                      fontFamily: "sans-serif",
                      fontWeight: "700",
                      marginLeft: "6px",
                    }}
                    className="text-primary-new-iux"
                  >
                    {locale.t("register")}
                  </span>
                </Link>
              </p>
            </div>
            <div style={{ marginTop: "10px" }}></div>
            <div className="text-left text-gray mb-1">
              {locale.t("login_your_email")}
            </div>
            <Form.Item
              style={{ height: "40px" }}
              placeholder={locale.t("login_your_email")}
              defaultValue={email}
              name="email"
              onChange={onInputChangeEmail}
              // prefix={<Icon type="mail" style={{ color: "#a0a0a0" }} />}
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input
                className="login"
                name="email"
                style={inputlabel}
                placeholder={locale.t("login_your_email")}
              />
            </Form.Item>
            <div className="text-left text-gray mb-1">
              {locale.t("login_your_password")}
            </div>
            <Form.Item
              placeholder={locale.t("login_your_password")}
              defaultValue={password}
              name="password"
              type="password"
              onChange={onInputChangePassword}
              style={{ background: "#242424" }}
              // prefix={
              //   <Icon type="lock" style={{ color: "#a0a0a0 !important" }} />
              // }
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
            >
              <Input.Password
                name="password"
                type="password"
                style={inputlabel}
                placeholder={locale.t("login_your_password")}
                // visibilityToggle={false}
                // iconRender={
                //   <Icon type="lock" style={{ color: "#fff !important" }} />
                // }
              />
            </Form.Item>
            <Link
              to={`/${localStorage.getItem("locale")}/forgot-password-form`}
            >
              <div
                type="link"
                className="w-100 text-right pr-4 text-primary-new-iux cursor-pointer"
              >
                {locale.t("forgot_your_password")}
              </div>
            </Link>
            {warning && (
              <WarningBox className="justify-content-between">
                <div className="text-white">
                  {locale.t("validate_email_resend")}
                </div>
                <div>
                  <Button
                    disabled={countDown !== 0}
                    loading={loadingResend}
                    className="btn-primary-new-iux"
                    onClick={() => onResend()}
                  >
                    {countDown !== 0 ? `${countDown}s` : locale.t("click_send")}
                  </Button>
                </div>
              </WarningBox>
            )}
            {error && (
              <div className="d-flex m-2 justify-content-center">
                <p style={{ color: "red" }}>{error}</p>
              </div>
            )}
            <Button
              htmlType="submit"
              style={{ textTransform: "uppercase" }}
              loading={props?.user?.loading}
              className="w-100 btn-primary-new-iux mt-4"
              block
              // onClick={() => {
              //   getAccessToken(props);
              // }}
            >
              {locale.t("login_button_text")}
            </Button>
            {/* <Button
              style={{ textTransform: "uppercase" }}
              loading={props.user.loading}
              className="w-100 btn-primary-new-iux mt-4"
              block
              onClick={() => {
                authenticateWithToken();
              }}
            >
              {locale.t("login_button_text")} with token
            </Button> */}
            <p style={{ color: "gray" }} className="mt-4">
              {locale.t("login_lable_bottom")}
            </p>
          </Form>
        </div>
        <FooterAuthPage locale={locale} />
      </section>
    </>
  );
};
// }

const mapStateToProps = ({ user, i18n }) => {
  return { user, locale: i18n.locale };
};
export default connect(mapStateToProps, {
  authentication,
  getUser,
  getBank,
  LogoutAuth,
  getUserv2,
})(LoginPage);
