import React from "react";
import { Icon, Collapse } from "antd";
import RcQueueAnim from "rc-queue-anim";
import contact from "../../mock/contact.json";

const { Panel } = Collapse;

function ContactMobile(props) {
  const questions = [
    {
      question: props.locale.t("question.question_1"),
      answer: props.locale.t("question.answer_1"),
    },
    {
      question: props.locale.t("question.question_2"),
      answer: props.locale.t("question.answer_2"),
    },
    {
      question: props.locale.t("question.question_3"),
      answer: props.locale.t("question.answer_3"),
    },
    {
      question: props.locale.t("question.question_4"),
      answer: props.locale.t("question.answer_4"),
    },
    {
      question: props.locale.t("question.question_5"),
      answer: props.locale.t("question.answer_5"),
    },
    {
      question: props.locale.t("question.question_6"),
      answer: props.locale.t("question.answer_6"),
    },
    {
      question: props.locale.t("question.question_7"),
      answer: props.locale.t("question.answer_7"),
    },
    {
      question: props.locale.t("question.question_8"),
      answer: props.locale.t("question.answer_8"),
    },
    {
      question: props.locale.t("question.question_9"),
      answer: props.locale.t("question.answer_9"),
    },
    {
      question: props.locale.t("question.question_10"),
      answer: props.locale.t("question.answer_10"),
    },
    {
      question: props.locale.t("question.question_11"),
      answer: props.locale.t("question.answer_11"),
    },
    {
      question: props.locale.t("question.question_12"),
      answer: props.locale.t("question.answer_12"),
    },
    {
      question: props.locale.t("question.question_13"),
      answer: props.locale.t("question.answer_13"),
    },
  ];
  const documents = [
    {
      question: props.locale.t("document.question_1"),
      answer: props.locale.t("document.answer_1"),
    },
    {
      question: props.locale.t("document.question_2"),
      answer: props.locale.t("document.answer_2"),
    },
    {
      question: props.locale.t("document.question_3"),
      answer: props.locale.t("document.answer_3"),
    },
    {
      question: props.locale.t("document.question_4"),
      answer: props.locale.t("document.answer_4"),
    },
  ];
  const iux_types = [
    {
      question: props.locale.t("iux_type.question_1"),
      answer: props.locale.t("iux_type.answer_1"),
    },
    {
      question: props.locale.t("iux_type.question_2"),
      answer: props.locale.t("iux_type.answer_2"),
    },
    {
      question: props.locale.t("iux_type.question_3"),
      answer: props.locale.t("iux_type.answer_3"),
    },
    {
      question: props.locale.t("iux_type.question_4"),
      answer: props.locale.t("iux_type.answer_4"),
    },
    // {
    //   question: props.locale.t("iux_type.question_5"),
    //   answer: props.locale.t("iux_type.answer_5"),
    // },
    {
      question: props.locale.t("iux_type.question_6"),
      answer: props.locale.t("iux_type.answer_6"),
    },
    {
      question: props.locale.t("iux_type.question_7"),
      answer: props.locale.t("iux_type.answer_7"),
    },
    {
      question: props.locale.t("iux_type.question_8"),
      answer: props.locale.t("iux_type.answer_8"),
    },
    {
      question: props.locale.t("iux_type.question_9"),
      answer: props.locale.t("iux_type.answer_9"),
    },
    {
      question: props.locale.t("iux_type.question_10"),
      answer: props.locale.t("iux_type.answer_10"),
    },
    {
      question: props.locale.t("iux_type.question_11"),
      answer: props.locale.t("iux_type.answer_11"),
    },
    {
      question: props.locale.t("iux_type.question_12"),
      answer: props.locale.t("iux_type.answer_12"),
    },
    {
      question: props.locale.t("iux_type.question_13"),
      answer: props.locale.t("iux_type.answer_13"),
    },
    {
      question: props.locale.t("iux_type.question_14"),
      answer: props.locale.t("iux_type.answer_14"),
    },
    {
      question: props.locale.t("iux_type.question_15"),
      answer: props.locale.t("iux_type.answer_15"),
    },
    {
      question: props.locale.t("iux_type.question_16"),
      answer: props.locale.t("iux_type.answer_16"),
    },
    {
      question: props.locale.t("iux_type.question_17"),
      answer: props.locale.t("iux_type.answer_17"),
    },
    {
      question: props.locale.t("iux_type.question_18"),
      answer: props.locale.t("iux_type.answer_18"),
    },
    {
      question: props.locale.t("iux_type.question_19"),
      answer: props.locale.t("iux_type.answer_19"),
    },
    {
      question: props.locale.t("iux_type.question_20"),
      answer: props.locale.t("iux_type.answer_20"),
    },
    {
      question: props.locale.t("iux_type.question_21"),
      answer: props.locale.t("iux_type.answer_21"),
    },
    {
      question: props.locale.t("iux_type.question_22"),
      answer: props.locale.t("iux_type.answer_22"),
    },
    {
      question: props.locale.t("iux_type.question_23"),
      answer: props.locale.t("iux_type.answer_23"),
    },
    // {
    //   question: props.locale.t("iux_type.question_24"),
    //   answer: props.locale.t("iux_type.answer_24"),
    // },
    {
      question: props.locale.t("iux_type.question_25"),
      answer: props.locale.t("iux_type.answer_25"),
    },
    {
      question: props.locale.t("iux_type.question_26"),
      answer: props.locale.t("iux_type.answer_26"),
    },
    {
      question: props.locale.t("iux_type.question_27"),
      answer: props.locale.t("iux_type.answer_27"),
    },
    {
      question: props.locale.t("iux_type.question_28"),
      answer: props.locale.t("iux_type.answer_28"),
    },
    {
      question: props.locale.t("iux_type.question_29"),
      answer: props.locale.t("iux_type.answer_29"),
    },
    {
      question: props.locale.t("iux_type.question_30"),
      answer: props.locale.t("iux_type.answer_30"),
    },
    {
      question: props.locale.t("iux_type.question_31"),
      answer: props.locale.t("iux_type.answer_31"),
    },
    {
      question: props.locale.t("iux_type.question_32"),
      answer: props.locale.t("iux_type.answer_32"),
    },
    {
      question: props.locale.t("iux_type.question_33"),
      answer: props.locale.t("iux_type.answer_33"),
    },
    {
      question: props.locale.t("iux_type.question_34"),
      answer: props.locale.t("iux_type.answer_34"),
    },
    {
      question: props.locale.t("iux_type.question_35"),
      answer: props.locale.t("iux_type.answer_35"),
    },
  ];
  const deposit_withdraw = [
    {
      question: props.locale.t("deposit_withdraw.question_1"),
      answer: props.locale.t("deposit_withdraw.answer_1"),
    },
    {
      question: props.locale.t("deposit_withdraw.question_2"),
      answer: props.locale.t("deposit_withdraw.answer_2"),
    },
    {
      question: props.locale.t("deposit_withdraw.question_3"),
      answer: props.locale.t("deposit_withdraw.answer_3"),
    },
    {
      question: props.locale.t("deposit_withdraw.question_4"),
      answer: props.locale.t("deposit_withdraw.answer_4"),
    },
    {
      question: props.locale.t("deposit_withdraw.question_5"),
      answer: props.locale.t("deposit_withdraw.answer_5"),
    },
    {
      question: props.locale.t("deposit_withdraw.question_6"),
      answer: props.locale.t("deposit_withdraw.answer_6"),
    },
    {
      question: props.locale.t("deposit_withdraw.question_7"),
      answer: props.locale.t("deposit_withdraw.answer_7"),
    },
    {
      question: props.locale.t("deposit_withdraw.question_8"),
      answer: props.locale.t("deposit_withdraw.answer_8"),
    },
    {
      question: props.locale.t("deposit_withdraw.question_9"),
      answer: props.locale.t("deposit_withdraw.answer_9"),
    },
    {
      question: props.locale.t("deposit_withdraw.question_10"),
      answer: props.locale.t("deposit_withdraw.answer_10"),
    },
    {
      question: props.locale.t("deposit_withdraw.question_11"),
      answer: props.locale.t("deposit_withdraw.answer_11"),
    },
    {
      question: props.locale.t("deposit_withdraw.question_12"),
      answer: props.locale.t("deposit_withdraw.answer_12"),
    },
    {
      question: props.locale.t("deposit_withdraw.question_13"),
      answer: props.locale.t("deposit_withdraw.answer_13"),
    },
    {
      question: props.locale.t("deposit_withdraw.question_14"),
      answer: props.locale.t("deposit_withdraw.answer_14"),
    },
  ];
  return (
    <RcQueueAnim>
      <div key={1}>
        <div className="p-2">
          <h2
            style={{
              fontFamily: "sans-serif",
              fontWeight: "700",
              color: "#000000c4",
            }}
          >
            {props.locale.t("contact_sidebar.title")}
          </h2>
          <div>
            {contact.map((item, index) => {
              return (
                <div key={index} className="p-2">
                  {/* <Icon className="p-2" type={item.icon} theme="filled" /> */}
                  <span>{item.text}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="p-2">
          <h2
            style={{
              fontFamily: "sans-serif",
              fontWeight: "700",
              color: "#000000c4",
            }}
          >
            {props.locale.t("question.title")}
          </h2>
          <div>
            {questions.map((item, index) => {
              return (
                <Collapse key={index} bordered={false}>
                  <Panel header={item.question} key={index}>
                    <p style={{ color: "#ff4e4e", paddingLeft: 24 }}>
                      <strong>{item.answer}</strong>
                    </p>
                  </Panel>
                </Collapse>
              );
            })}
          </div>
        </div>
        <div className="p-2">
          <h2
            style={{
              fontFamily: "sans-serif",
              fontWeight: "700",
              color: "#000000c4",
            }}
          >
            {props.locale.t("document.title")}
          </h2>
          <div>
            {documents.map((item, index) => {
              return (
                <Collapse key={index} bordered={false}>
                  <Panel header={item.question} key={index}>
                    <p style={{ color: "#ff4e4e", paddingLeft: 24 }}>
                      <strong>{item.answer}</strong>
                    </p>
                  </Panel>
                </Collapse>
              );
            })}
          </div>
        </div>
        <div className="p-2">
          <h2
            style={{
              fontFamily: "sans-serif",
              fontWeight: "700",
              color: "#000000c4",
            }}
          >
            {props.locale.t("iux_type.title")}
          </h2>
          <div>
            {iux_types.map((item, index) => {
              return (
                <Collapse key={index} bordered={false}>
                  <Panel header={item.question} key={index}>
                    <p style={{ color: "#ff4e4e", paddingLeft: 24 }}>
                      <strong>{item.answer}</strong>
                    </p>
                  </Panel>
                </Collapse>
              );
            })}
          </div>
        </div>
        <div className="p-2">
          <h2
            style={{
              fontFamily: "sans-serif",
              fontWeight: "700",
              color: "#000000c4",
            }}
          >
            {props.locale.t("deposit_withdraw.title")}
          </h2>
          <div>
            {deposit_withdraw.map((item, index) => {
              return (
                <Collapse key={index} bordered={false}>
                  <Panel header={item.question} key={index}>
                    <p style={{ color: "#ff4e4e", paddingLeft: 24 }}>
                      <strong>{item.answer}</strong>
                    </p>
                  </Panel>
                </Collapse>
              );
            })}
          </div>
        </div>
      </div>
    </RcQueueAnim>
  );
}
export default ContactMobile;
