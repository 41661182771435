import { renderApiDomain } from "../helper/renderApiDomain";
import { get, post, patch, deleted } from "../helper/request";
export const postTokenFCM = async (data) => {
  return post(`${renderApiDomain()}notification/token`, data);
};
export const deleteTokenFCM = async (data) => {
  return deleted(`${renderApiDomain()}notification/token?device=web`);
};
export const patchTokenFCM = async (data) => {
  return patch(`${renderApiDomain()}notification/token/language`, data);
};
export const getNotifications = async (page, limit, type, lang) => {
  return get(
    `${renderApiDomain()}notification?page=${page}&limit=${limit}&type=${type}&lang_iso=${lang}`
  );
};
export const getNotificationsDetail = async (notification_template_id) => {
  return get(`${renderApiDomain()}notification/${notification_template_id}`);
};
export const patchReadNotification = async (
  notification_template_id,
  createdAt
) => {
  return patch(`${renderApiDomain()}notification/${notification_template_id}`, {
    is_read: 1,
    createdAt: createdAt,
  });
};
export const getNotificationsUnread = async () => {
  return get(`${renderApiDomain()}notification/unread/count?type=update`);
};
