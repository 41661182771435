import React from "react";

const TimesIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <path
        id="Icon_material-close"
        data-name="Icon material-close"
        d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
        transform="translate(-5 -5)"
        fill={color}
      />
    </svg>
  );
};

export default TimesIcon;
