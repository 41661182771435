import React from 'react'
import ArrowIcon from './arrow';
import ArrowLeftIcon from './arrow-left';
import TimesIcon from './times';

const IuxIcon = ({ icon: Icon, color }) => {
    return <Icon color={color} />;
}

export default IuxIcon

export {
  ArrowLeftIcon,
  ArrowIcon,
  TimesIcon,
}
