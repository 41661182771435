export default {
  th: {
    securityPage: {
      title: "ข้อมูลความปลอดภัย\n",
      subtitle: "เอกสารด้านกฏหมาย\n",
      document_1: "เอกสารทางกฏหมาย-ข้อกำหนดในการใช้งานโปรโมชัน\n",
      document_2: "เอกสารทางกฏหมาย-คำเตือนเรื่องความเสี่ยง\n",
      document_3: "เอกสารทางกฏหมาย-นโยบายต่อต้านการฟอกเงิน\n",
      warningTitle: "คำเตือน คำแนะนำทั่วไป\n",
      warningSubTitle:
        "ข้อมูลในเว็บไซต์นี้เป็นข้อมูลทั่วไปเท่านั้นและอาจมีคำแนะนำที่ไม่ได้ขึ้นอยู่กับวัตถุประสงค์ส่วนบุคคล สถานการณ์ ทางการเงินหรือความต้องการส่วนบุคคลของท่าน ดังนั้นท่านควรพิจารณาถึงความเหมาะสมของคำแนะนำ (ถ้ามี) ที่มีต่อวัตถุประสงค์ สถานการณ์ทางการเงินและความต้องการเหล่านั้นก่อนที่จะปฏิบัติตามคำแนะนำ",
    },
  },
  lo: {
    securityPage: {
      title: "ຂໍ້ມູນກ່ຽວກັບຄວາມປອດໄພ\n",
      subtitle: "ເອກະສານນິຕິ ກຳ\n",
      document_1: "ເອກະສານທາງກົດ ໝາຍ - ຂໍ້ ກຳ ນົດກ່ຽວກັບການ ນຳ ໃຊ້\n",
      document_2: "ເອກະສານທາງກົດ ໝາຍ - ຄຳ ເຕືອນກ່ຽວກັບຄວາມສ່ຽງ\n",
      document_3: "ເອກະສານນິຕິ ກຳ - ນະໂຍບາຍຕ້ານການຟອກເງິນ\n",
      warningTitle: "ຄຳ ເຕືອນ, ຄຳ ແນະ ນຳ ທົ່ວໄປ\n",
      warningSubTitle:
        "ຂໍ້ມູນໃນເວບໄຊທ໌ນີ້ແມ່ນຂໍ້ມູນທົ່ວໄປເທົ່ານັ້ນແລະອາດຈະມີຂໍ້ສະ ເໜີ ແນະທີ່ບໍ່ໄດ້ອີງໃສ່ຈຸດປະສົງສ່ວນຕົວ, ສະຖານະການດ້ານການເງິນຫຼືຄວາມຕ້ອງການສ່ວນຕົວຂອງທ່ານ. ສະນັ້ນ, ທ່ານຄວນພິຈາລະນາຄວາມ ເໝາະ ສົມຂອງຂໍ້ສະ ເໜີ ແນະ (ຖ້າມີ) ຕໍ່ກັບຈຸດປະສົງ. ສະຖານະການທາງການເງິນແລະຄວາມຕ້ອງການເຫຼົ່ານັ້ນກ່ອນທີ່ຈະຍຶດ ໝັ້ນ ກັບຂໍ້ສະ ເໜີ ແນະ",
    },
  },
  en: {
    securityPage: {
      title: "Safety information\n",
      subtitle: "Legal documents\n",
      document_1: "Legal documents - promo terms of use\n",
      document_2: "Legal documents - risk warnings\n",
      document_3: "Legal documents - anti-money laundering policy\n",
      warningTitle: "Warning, general recommendations\n",
      warningSubTitle:
        "The information on this website is general information only and may contain recommendations that are not based on your personal objectives, financial situation or personal needs. Therefore, you should consider the suitability of the recommendations (if any) to the objectives. Financial situations and those needs before adhering to recommendations\n" +
        " \n",
    },
  },
  hi: {
    securityPage: {
      title: "Safety information\n",
      subtitle: "Legal documents\n",
      document_1: "Legal documents - promo terms of use\n",
      document_2: "Legal documents - risk warnings\n",
      document_3: "Legal documents - anti-money laundering policy\n",
      warningTitle: "Warning, general recommendations\n",
      warningSubTitle:
        "The information on this website is general information only and may contain recommendations that are not based on your personal objectives, financial situation or personal needs. Therefore, you should consider the suitability of the recommendations (if any) to the objectives. Financial situations and those needs before adhering to recommendations\n" +
        " \n",
    },
  },
  ms: {
    securityPage: {
      title: `Maklumat keselamatan`,
      subtitle: `Dokumen undang-undang`,
      document_1: `Dokumen Undang-undang Syarat Penggunaan Promosi`,
      document_2: `Dokumen undang-undang Amaran risiko`,
      document_3: `Dokumen Undang-undang Dasar Pencegahan Pengubahan Wang Haram`,
      warningTitle: `Amaran, cadangan umum`,
      warningSubTitle: `Maklumat di laman web ini adalah maklumat am sahaja dan mungkin mengandungi nasihat yang tidak berdasarkan tujuan peribadi anda, keadaan kewangan atau keperluan peribadi anda. Oleh itu, anda harus mempertimbangkan kesesuaian nasihat (jika ada) yang ada pada tujuan keadaan kewangan dan keperluan tersebut sebelum ikuti nasihat`,
    },
  },
  id: {
    securityPage: {
      title: `Informasi keselamatan`,
      subtitle: `Dokumen legal`,
      document_1: `dokumen hukum - hal promo penggunaan`,
      document_2: `dokumen hukum - peringatan risiko`,
      document_3: `dokumen hukum - anti-uang kebijakan pencucian`,
      warningTitle: `Peringatan, rekomendasi umum`,
      warningSubTitle: `Informasi dalam situs ini adalah informasi umum saja dan dapat berisi rekomendasi yang tidak didasarkan pada tujuan pribadi Anda, situasi keuangan atau kebutuhan pribadi. Oleh karena itu, Anda harus mempertimbangkan kesesuaian rekomendasi (jika ada) dengan tujuan. situasi keuangan dan kebutuhan orang-orang sebelum mengikuti rekomendasi`,
    },
  },
  zh: {
    securityPage: {
      title: `安全信息`,
      subtitle: `合法文件`,
      document_1: `法律文件 - 使用的促销条款`,
      document_2: `法律文件 - 风险警告`,
      document_3: `法律文件 - 反洗钱政策`,
      warningTitle: `警告，一般建议`,
      warningSubTitle: `本网站上的资料只供一般信息和可能含有未根据您的个人目标，财务状况或个人需求的建议。因此，你应该考虑的建议（如果有的话）到目标的适用性。秉承建议之前，财务状况和需求者`,
    },
  },
  vi: {
    securityPage: {
      title: "An toàn thông tin\n",
      subtitle: "Văn bản pháp lý\n",
      document_1: "Văn bản pháp lý-Điều khoản sử dụng khuyến mại\n",
      document_2: "Văn bản pháp lý- Cảnh báo rủi ro\n",
      document_3: "Văn bản pháp lý-Chính sách phòng chống rửa tiền\n",
      warningTitle: "Cảnh báo Lời khuyên chung\n",
      warningSubTitle:
        "Thông tin trên trang web này chỉ là thông tin chung và có thể chứa những lời khuyên không dựa trên mục tiêu cá nhân, tình hình tài chính hoặc nhu cầu cá nhân của bạn. ì vậy, bạn nên xem xét tính phù hợp của các khuyến nghị (nếu có)  cho các mục tiêu. Tình hình tài chính và những nhu cầu trước khi thực hiện theo các khuyến nghị",
    },
  },
  ko: {
    securityPage: {
      title: `안전 정보`,
      subtitle: `법률 문서`,
      document_1: `법률 문서 - 사용의 프로모션 약관`,
      document_2: `법률 문서 - 위험 경고`,
      document_3: `법률 문서 - 자금 세탁 방지 정책`,
      warningTitle: `일반적인 권장 사항을 경고`,
      warningSubTitle: `이 웹 사이트의 정보는 일반적인 정보이며, 개인 목적, 재무 상황이나 개인의 필요에 따라되지 않습니다 권장 사항을 포함 할 수있다. 따라서, 당신은 목표에 대한 권고 사항 (있는 경우)의 적합성을 고려해야합니다. 권장 사항을 준수하기 전에 금융 상황과 이러한 요구`,
    },
  },
  fil: {
    securityPage: {
      title: `Impormasyong pangkaligtasan`,
      subtitle: `mga legal na dokumento`,
      document_1: `Legal na mga dokumento - mga tuntunin ng paggamit ng promo`,
      document_2: `Legal na mga dokumento - panganib babala`,
      document_3: `Legal na mga dokumento - anti-money laundering sa patakaran`,
      warningTitle: `Babala, pangkalahatang rekomendasyon`,
      warningSubTitle: `Ang impormasyon sa website na ito ay pangkalahatang impormasyon lamang at maaaring maglaman ng mga rekomendasyon na hindi batay sa iyong personal na mga layunin, pinansyal na sitwasyon o personal na pangangailangan. Samakatuwid, dapat mong isaalang-alang ang pagiging angkop ng mga rekomendasyon (kung mayroon man) sa mga layunin. Financial sitwasyon at mga pangangailangan bago adhering sa mga rekomendasyon`,
    },
  },
  ja: {
    securityPage: {
      title: "安全情報 \n",
      subtitle: "法的文書 \n",
      document_1: "法的文書 - プロモーション条件 \n",
      document_2: "法的文書 - リスク警告 \n",
      document_3: "法的文書 - マネーロンダリング防止ポリシー \n",
      warningTitle: "警告、一般的な推奨事項 \n",
      warningSubTitle:
        "このWebサイトの情報は一般的な情報のみであり、個人の目的、財務状況、または個人的なニーズに基づいていない推奨事項が含まれている場合があります。 したがって、目的に対する推奨事項（ある場合）の適合性を考慮する必要があります。 勧告を順守する前の金融状況とそれらのニーズ n" +
        " \n",
    },
  },
  es: {
    securityPage: {
      title: "Información de seguridad\n",
      subtitle: "Documentos legales\n",
      document_1: "Documentos legales - términos de uso de promociones\n",
      document_2: "Documentos legales - advertencias de riesgo\n",
      document_3: "Documentos legales - política contra el lavado de dinero\n",
      warningTitle: "Advertencia, recomendaciones generales\n",
      warningSubTitle:
        "La información en este sitio web es solo información general y puede contener recomendaciones que no se basan en tus objetivos personales, situación financiera o necesidades personales. Por lo tanto, debes considerar la idoneidad de las recomendaciones (si las hay) en relación con tus objetivos, situaciones financieras y esas necesidades antes de seguir las recomendaciones\n" +
        " \n",
    },
  },
};
