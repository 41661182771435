import React, { useState, useEffect, useRef } from "react";

import { onMessageListener } from "../../firebase";

import { Badge, Drawer, Dropdown, Popover } from "antd";
import NotificationInside from "../noti/NotificationInside";
import { CloseOutlined } from "@ant-design/icons";
import {
  getNotifications,
  getNotificationsUnread,
  patchReadNotification,
} from "../../api/notifications";
import { CheckLanguageISO } from "../../helper/countryLangISO";

function Notification() {
  const [notiChildrenDrawer, setNotiChildrenDrawer] = useState(false);
  const [isUnread, setIsUnread] = useState(false);
  const [isClose, setIsClose] = useState(false);
  const [tabs, setTabs] = useState("message");
  const [dataNotiMessages, setdataNotiMessages] = useState([]);
  const [dataNotiUpdates, setdataNotiUpdates] = useState();
  const [dataNotification, setDataNotification] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [nextPage, setNextPage] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenNoti, setIsOpenNoti] = useState(false);
  const elementRef = useRef(null);
  const [clickedOutside, setClickedOutside] = useState(false);

  const windowWidth = window.innerWidth;
  const showNotiChildrenDrawer = () => {
    setNotiChildrenDrawer(true);
  };

  const onNotiChildrenDrawerClose = () => {
    let isData = dataNotification.map(
      (e, i) => (dataNotification[i].show = false)
    );
    setDataNotification([...dataNotification]);
    setNotiChildrenDrawer(false);
  };
  useEffect(() => {
    fetchCountNoti();
  }, []);
  const fetchCountNoti = async () => {
    try {
      const { data } = await getNotificationsUnread();
      setIsUnread(data.data.count > 0);
    } catch (error) {
      console.log(error);
    }
  };

  const onCheckNotiCountRead = (is_read) => {
    setIsUnread(is_read > 0);
  };
  const onNotiUpdate = (is_read) => {
    setIsUnread(is_read);
  };

  const drawerStyles = {
    body: {
      padding: 0,
    },
  };
  useEffect(() => {
    const channel = new BroadcastChannel("firebase-background-channel");

    channel?.addEventListener("message", (event) => {
      if (event.data.type === "backgroundMessage") {
        const payload = event.data.payload;
        payload.data.is_read = parseInt(payload.data.is_read);
        payload.data.show = false;
        setdataNotiUpdates(payload.data);

        if (payload?.data?.type === "update") {
          setIsUnread(true);
        }
      }
    });

    return () => {
      channel?.removeEventListener("message", null);
    };
  }, []);

  const elementWithClass = document.querySelector(".ant-dropdown-hidden");
  useEffect(() => {
    if (elementWithClass) {
      let isData = dataNotification.map(
        (e, i) => (dataNotification[i].show = false)
      );
      setDataNotification([...dataNotification]);
    }
  }, [elementWithClass]);
  useEffect(() => {
    fetchNotification("init", tabs);
  }, [tabs]);

  const fetchNotification = async (type) => {
    try {
      setIsLoading(type && true);
      const { data } = await getNotifications(
        page,
        10,
        tabs,
        CheckLanguageISO(localStorage.getItem("locale"))
      );
      let isData = data.data.notifications.map(
        (e, i) => (data.data.notifications[i].show = false)
      );
      if (tabs === "message") {
        if (type) {
          setdataNotiMessages([...data.data.notifications]);
        } else {
          setdataNotiMessages([
            ...dataNotiMessages,
            ...data.data.notifications,
          ]);
        }
      } else {
        if (type) {
          setDataNotification([...data.data.notifications]);
        } else {
          setDataNotification([
            ...dataNotification,
            ...data.data.notifications,
          ]);
        }
      }

      setNextPage(data.next);
      setIsLoading(false);
      if (data.next) {
        setPage(page + 1);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  onMessageListener()
    .then((payload) => {
      payload.data.is_read = parseInt(payload.data.is_read);
      payload.data.show = false;
      setdataNotiUpdates(payload.data);

      if (payload?.data?.type === "update") {
        setIsUnread(true);
      }
    })
    .catch((err) => console.log("failed: ", err));
  useEffect(() => {
    if (dataNotiUpdates && dataNotiUpdates?.type === "update") {
      setDataNotification([dataNotiUpdates, ...dataNotification]);
      onNotiUpdate(true);
    } else {
      setdataNotiMessages([dataNotiUpdates, ...dataNotiMessages]);
    }
  }, [dataNotiUpdates]);
  const onShowDetail = (index) => {
    dataNotification[index].show = !dataNotification[index].show;
    setDataNotification([...dataNotification]);
  };
  const handlechangeTabs = (isTabs) => {
    setTabs(isTabs);
    setPage(1);
    setNextPage(true);
  };
  const onRead = async (notification_template_id, createdAt) => {
    try {
      const { data } = await patchReadNotification(
        notification_template_id,
        createdAt
      );
      let isIndex = dataNotification.findIndex(
        (e) =>
          e.notification_template_id === notification_template_id &&
          e.createdAt === createdAt
      );
      dataNotification[isIndex].is_read = 1;
      let checkUnRead = await dataNotification?.filter((e) => e.is_read !== 1);
      onCheckNotiCountRead(checkUnRead.length);
      setDataNotification([...dataNotification]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {windowWidth > 768 ? (
        <div className="cursor-pointer hide-navWallet">
          <Dropdown
            open={isOpenNoti}
            overlay={
              <NotificationInside
                dataNotiMessages={dataNotiMessages}
                dataNotification={dataNotification}
                fetchNotification={fetchNotification}
                nextPage={nextPage}
                tabs={tabs}
                handlechangeTabs={handlechangeTabs}
                isLoading={isLoading}
                onRead={onRead}
                onShowDetail={onShowDetail}
              />
            }
            onOpenChange={(open) => {
              const operationsWrapper = document.querySelector(
                ".ant-image-preview-operations-wrapper"
              );

              if (operationsWrapper) {
                setIsOpenNoti(true);
              } else {
                setIsOpenNoti(open);
              }

              setIsClose(open);
            }}
            trigger={["click"]}
            placement="bottomRight"
          >
            <Badge dot={isUnread} offset={[-4, 4]}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="24"
                viewBox="0 0 22 24"
                fill="none"
              >
                <path
                  d="M17.5029 8.04123C17.5029 6.17378 16.8075 4.38282 15.5694 3.06233C14.3315 1.74184 12.6525 1 10.9017 1C9.151 1 7.47197 1.74184 6.23401 3.06233C4.99605 4.38282 4.30058 6.17378 4.30058 8.04123C4.30058 16.256 1 18.6031 1 18.6031H20.8035C20.8035 18.6031 17.5029 16.256 17.5029 8.04123Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.8047 21.9038C12.6113 22.2373 12.3337 22.5141 11.9996 22.7064C11.6656 22.8988 11.2869 23.0002 10.9014 23.0002C10.5159 23.0002 10.1372 22.8988 9.80317 22.7064C9.46915 22.5141 9.19146 22.2373 8.99805 21.9038"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Badge>
          </Dropdown>
        </div>
      ) : (
        <div className="hamburger">
          <div
            className="cursor-pointer"
            onClick={() => showNotiChildrenDrawer()}
          >
            <Badge dot={isUnread} offset={[-4, 4]}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="24"
                viewBox="0 0 22 24"
                fill="none"
              >
                <path
                  d="M17.5029 8.04123C17.5029 6.17378 16.8075 4.38282 15.5694 3.06233C14.3315 1.74184 12.6525 1 10.9017 1C9.151 1 7.47197 1.74184 6.23401 3.06233C4.99605 4.38282 4.30058 6.17378 4.30058 8.04123C4.30058 16.256 1 18.6031 1 18.6031H20.8035C20.8035 18.6031 17.5029 16.256 17.5029 8.04123Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.8047 21.9038C12.6113 22.2373 12.3337 22.5141 11.9996 22.7064C11.6656 22.8988 11.2869 23.0002 10.9014 23.0002C10.5159 23.0002 10.1372 22.8988 9.80317 22.7064C9.46915 22.5141 9.19146 22.2373 8.99805 21.9038"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Badge>
          </div>
          <Drawer
            className=""
            width="100%"
            height="100%"
            closable={false}
            placement="left"
            onClose={onNotiChildrenDrawerClose}
            visible={notiChildrenDrawer}
            // classNames={classNames}
            styles={drawerStyles}
          >
            <NotificationInside
              onNotiChildrenDrawerClose={onNotiChildrenDrawerClose}
              dataNotiMessages={dataNotiMessages}
              dataNotification={dataNotification}
              fetchNotification={fetchNotification}
              nextPage={nextPage}
              tabs={tabs}
              handlechangeTabs={handlechangeTabs}
              isLoading={isLoading}
              onRead={onRead}
              onShowDetail={onShowDetail}
              showClose={true}
            />
          </Drawer>
        </div>
      )}
    </>
  );
}
export default Notification;
