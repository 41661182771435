export default {
  th: {
    stock_screener: "ตัวคัดกรองหุ้น",
    forex_screener: "ตัวคัดกรอง CFD",
    crypto_screener: "ตัวคัดกรอง Crypto",
    top_invest: "การลงทุนสูงสุด",
    analytics: "	การวิเคราะห์	",
    report: "	รายงาน	",
    account_summary: "	สรุปบัญชี	",
    account_summary_detail:
      "	สรุปบัญชีแสดงผลกำไรการซื้อขายหรือขาดทุนของคุณในช่วงเวลาที่กำหนด หากสรุปกราฟแท่งน้อยกว่า 0 USD มันจะไม่แสดงบนแผนภูมิ	",
    filter: "	ตัวกรอง	",
    all: "	ทั้งหมด	",
    last_year: "	ปีที่แล้ว	",
    last_month: "	เดือนที่แล้ว	",
    custom: "	กำหนดเอง	",
    profit: "	กำไร	",
    loss: "	ขาดทุน	",
    summary: "	สรุป	",
    export: "	ส่งออก	",
    please_Select: "	โปรดเลือก	",
    light: "	สว่าง	",
    dark: "	มืด	",
  },
  lo: {
    stock_screener: "Stock Screener",
    forex_screener: "CFDs Screener",
    crypto_screener: "Crypto Screener",
    top_invest: "ການລົງທຶນສູງສຸດ",
    analytics: "	ການວິເຄາະ	",
    report: "	ລາຍງານ	",
    account_summary: "	ບົດສະຫຼຸບບັນຊີ	",
    account_summary_detail:
      "	ບົດສະຫຼຸບບັນຊີສະແດງຜົນກໍາໄລການຊື້ຂາຍຫຼືການສູນເສຍຂອງທ່ານໃນໄລຍະເວລາທີ່ກໍານົດໄວ້. ຖ້າສະຫຼຸບຫຍໍ້ຂອງບາແມ່ນນ້ອຍກວ່າ 0 ໂດລາສະຫະລັດ, ມັນຈະບໍ່ສະແດງຢູ່ໃນຕາຕະລາງ.	",
    filter: "	ຕອງ	",
    all: "	ທັງຫມົດ	",
    last_year: "	ປີ​ທີ່​ແລ້ວ	",
    last_month: "	ເດືອນກ່ອນ	",
    custom: "	ກຳນົດເອງ	",
    profit: "	ກໍາໄລ	",
    loss: "	ຂາດທຶນ	",
    summary: "	ສະຫຼຸບຄວາມ	",
    export: "	ສົ່ງອອກ	",
    please_Select: "	ກະ​ລຸ​ນາ​ເລືອກ	",
    light: "	ແສງສະຫວ່າງ	",
    dark: "	ມືດ	",
  },
  en: {
    stock_screener: "Stock Screener",
    forex_screener: "CFDs Screener",
    crypto_screener: "Crypto Screener",
    top_invest: "Top Invest",
    analytics: "	Analytics	",
    report: "	Report	",
    account_summary: "	Account summary	",
    account_summary_detail:
      "	The account summary displays your trading profits or losses over a set period. If the bar graph summary is less than 0 USD, it won’t displayed on the chart.	",
    filter: "	Filter	",
    all: "	All	",
    last_year: "	Last Year	",
    last_month: "	Last Month	",
    custom: "	Custom	",
    profit: "	Profit	",
    loss: "	Loss	",
    summary: "	summary	",
    export: "	Export	",
    please_Select: "	Please Select	",
    light: "	Light	",
    dark: "	Dark	",
  },
  hi: {
    stock_screener: "स्टॉक स्क्रीनर",
    forex_screener: "सीएफडी स्क्रीनर",
    crypto_screener: "क्रिप्टो स्क्रीनर",
    top_invest: "शीर्ष निवेश",
    analytics: "	एनालिटिक्स	",
    report: "	प्रतिवेदन	",
    account_summary: "	खाते की सारांश	",
    account_summary_detail:
      "	खाता सारांश एक निर्धारित अवधि में आपके ट्रेडिंग मुनाफे या नुकसान को प्रदर्शित करता है। यदि बार ग्राफ सारांश 0 USD से कम है, तो यह चार्ट पर प्रदर्शित नहीं होता है।	",
    filter: "	फ़िल्टर	",
    all: "	सभी	",
    last_year: "	पिछले साल	",
    last_month: "	पिछला महीना	",
    custom: "	रिवाज़	",
    profit: "	लाभ	",
    loss: "	पूंजी की कमी	",
    summary: "	सारांश	",
    export: "	निर्यात	",
    please_Select: "	कृपया चयन कीजिए	",
    light: "	रोशनी	",
    dark: "	अँधेरा	",
  },
  ms: {
    stock_screener: "Penyaring Saham",
    forex_screener: "Penyaring CFD",
    crypto_screener: "Penyaring Kripto",
    top_invest: "pelaburan teratas",
    analytics: "	Analisis	",
    report: "	Laporan	",
    account_summary: "	Ringkasan Akaun	",
    account_summary_detail:
      "	Ringkasan Akaun memaparkan keuntungan atau kerugian perdagangan anda dalam tempoh yang ditetapkan. Jika ringkasan graf bar kurang daripada 0 USD, ia tidak akan dipaparkan pada carta.	",
    filter: "	Penapis	",
    all: "	Semua	",
    last_year: "	Tahun lepas	",
    last_month: "	Bulan lepas	",
    custom: "	Adat	",
    profit: "	Keuntungan	",
    loss: "	Kerugian	",
    summary: "	Ringkasan	",
    export: "	eksport	",
    please_Select: "	Sila pilih	",
    light: "	Cahaya	",
    dark: "	Gelap	",
  },
  id: {
    stock_screener: "Penyaring Saham",
    forex_screener: "Penyaring CFD",
    crypto_screener: "Penyaring Kripto",
    top_invest: "investasi top",
    analytics: "	Analitik	",
    report: "	Laporan	",
    account_summary: "	Ringkasan Akun	",
    account_summary_detail:
      "	Ringkasan akun menampilkan keuntungan atau kerugian perdagangan Anda selama periode yang ditetapkan. Jika ringkasan grafik batang kurang dari 0 USD, itu tidak akan ditampilkan di bagan.	",
    filter: "	Saring	",
    all: "	Semua	",
    last_year: "	Tahun lalu	",
    last_month: "	Bulan lalu	",
    custom: "	Kebiasaan	",
    profit: "	Laba	",
    loss: "	Kehilangan	",
    summary: "	ringkasan	",
    export: "	ekspor	",
    please_Select: "	Silahkan pilih	",
    light: "	Lampu	",
    dark: "	Gelap	",
  },
  zh: {
    stock_screener: "股票筛选器",
    forex_screener: "CFDs筛选器",
    crypto_screener: "加密货币筛选器",
    top_invest: "顶级投资",
    analytics: "分析",
    report: "报告",
    account_summary: "账户摘要",
    account_summary_detail:
      "账户摘要显示您在设定期间内的交易盈亏。如果柱状图摘要低于0美元，则不会在图表上显示。",
    filter: "筛选",
    all: "全部",
    last_year: "去年",
    last_month: "上个月",
    custom: "自定义",
    profit: "利润",
    loss: "亏损",
    summary: "摘要",
    export: "导出",
    please_Select: "请选择",
    light: "浅色",
    dark: "深色",
  },
  vi: {
    stock_screener: "Trình sàng lọc cổ phiếu",
    forex_screener: "Trình sàng lọc CFD",
    crypto_screener: "Trình sàng lọc tiền điện tử",
    top_invest: "Đầu tư hàng đầu",
    analytics: "	phân tích	",
    report: "	Báo cáo	",
    account_summary: "	Tóm tắt tài khoản	",
    account_summary_detail:
      "	Tóm tắt tài khoản hiển thị lợi nhuận hoặc lỗ giao dịch của bạn trong một khoảng thời gian đã đặt. Nếu bản tóm tắt biểu đồ thanh nhỏ hơn 0 USD, thì nó đã giành được hiển thị trên biểu đồ.	",
    filter: "	Lọc	",
    all: "	Tất cả	",
    last_year: "	Năm ngoái	",
    last_month: "	Tháng trước	",
    custom: "	Phong tục	",
    profit: "	Lợi nhuận	",
    loss: "	Thua lỗ	",
    summary: "	bản tóm tắt	",
    export: "	xuất khẩu	",
    please_Select: "	Vui lòng chọn	",
    light: "	Ánh sáng	",
    dark: "	Tối tăm	",
  },
  ko: {
    stock_screener: "주식 스크리너",
    forex_screener: "CFD 스크리너",
    crypto_screener: "암호화폐 스크리너",
    top_invest: "최고의 투자",
    analytics: "	해석학	",
    report: "	보고서	",
    account_summary: "	계정 요약	",
    account_summary_detail:
      "	계정 요약은 정해진 기간 동안 거래 이익 또는 손실을 표시합니다. 막대 그래프 요약이 0 USD 미만인 경우 차트에 표시되지 않습니다.	",
    filter: "	필터	",
    all: "	모두	",
    last_year: "	작년	",
    last_month: "	지난 달	",
    custom: "	관습	",
    profit: "	이익	",
    loss: "	손실	",
    summary: "	요약	",
    export: "	내보내다	",
    please_Select: "	선택 해주세요	",
    light: "	빛	",
    dark: "	어두운	",
  },
  fil: {
    stock_screener: "Stock Screener",
    forex_screener: "CFDs Screener",
    crypto_screener: "Crypto Screener",
    top_invest: "Nangungunang pamumuhunan",
    analytics: "	Pagsusuri	",
    report: "	Ulat	",
    account_summary: "	Buod ng account	",
    account_summary_detail:
      "	Ipinapakita ng buod ng account ang iyong kita sa kalakalan o pagkalugi sa isang itinakdang panahon. Kung ang buod ng graph ng bar ay mas mababa sa 0 USD, hindi ito ipinapakita sa tsart.	",
    filter: "	Filter	",
    all: "	Lahat	",
    last_year: "Nakaraang taon	",
    last_month: "Nakaraang buwan	",
    custom: "	Pasadya	",
    profit: "	Tubo	",
    loss: "	Pagkawala	",
    summary: "	Buod	",
    export: "	Export	",
    please_Select: "	Pakipili	",
    light: "	Magaan	",
    dark: "	Madilim	",
  },
  ja: {
    stock_screener: "ストックスクリーナー",
    forex_screener: "CFDSクリーナー",
    crypto_screener: "暗号スクリーナー",
    top_invest: "トップ投資",
    analytics: "分析",
    report: "報告",
    account_summary: "アカウントの概要",
    account_summary_detail:
      "アカウントの概要には、設定された期間にわたって取引利益または損失が表示されます。 棒グラフの概要が0米ドル未満の場合、チャートには表示されません。",
    filter: "フィルター",
    all: "全て",
    last_year: "去年",
    last_month: "先月",
    custom: "カスタム",
    profit: "利益",
    loss: "資本不足",
    summary: "まとめ",
    export: "輸出",
    please_Select: "選んでください",
    light: "ライト",
    dark: "暗い",
  },
  en: {
    stock_screener: "Stock Screener",
    forex_screener: "CFDs Screener",
    crypto_screener: "Crypto Screener",
    top_invest: "Top Invest",
    analytics: "	Analytics	",
    report: "	Report	",
    account_summary: "	Account summary	",
    account_summary_detail:
      "	The account summary displays your trading profits or losses over a set period. If the bar graph summary is less than 0 USD, it won’t displayed on the chart.	",
    filter: "	Filter	",
    all: "	All	",
    last_year: "	Last Year	",
    last_month: "	Last Month	",
    custom: "	Custom	",
    profit: "	Profit	",
    loss: "	Loss	",
    summary: "	summary	",
    export: "	Export	",
    please_Select: "	Please Select	",
    light: "	Light	",
    dark: "	Dark	",
  },
  es: {
    stock_screener: "Filtro de acciones",
    forex_screener: "Filtro de CFDs",
    crypto_screener: "Filtro de criptomonedas",
    top_invest: "Inversiones destacadas",
    analytics: "Análisis",
    report: "Informe",
    account_summary: "Resumen de cuenta",
    account_summary_detail:
      "El resumen de la cuenta muestra sus ganancias o pérdidas comerciales durante un período determinado. Si el resumen del gráfico de barras es inferior a 0 USD, no se mostrará en el gráfico.",
    filter: "Filtrar",
    all: "Todo",
    last_year: "El año pasado",
    last_month: "El mes pasado",
    custom: "Personalizado",
    profit: "Ganancia",
    loss: "Pérdida",
    summary: "Resumen",
    export: "Exportar",
    please_Select: "Por favor seleccione",
    light: "Claro",
    dark: "Oscuro",
  },
};
