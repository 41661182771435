export default {
  th: {
    partner_header_introducing: "	แนะนำพาร์ทเนอร์	",
    partner_des:
      "	โปรแกรม IB ของเราได้รับการพัฒนาโดยมุ่งเน้นไปที่พันธมิตร ไม่ว่าคุณจะเป็นบุคคลเดียวหรือองค์กรที่มีพนักงานหลายสิบหรือหลายร้อยคนเราเข้าใจความต้องการและข้อกำหนดทางธุรกิจของคุณเพื่อเพิ่มรายได้ของคุณ นอกเหนือจากโซลูชัน IB พอร์ทัลอเนกประสงค์และทีมผู้จัดการหุ้นส่วนพร้อมที่จะช่วยเหลือคุณและลูกค้าของคุณโปรแกรม IB ของเรามีความเป็นไปได้และการชดเชยจำนวนมาก	",
    title_ourib: "	พอร์ทัล IB ของเรามีฟังก์ชันต่อไปนี้:	",
    function_detail_1: "	ค่าคอมมิชชั่นเรียลไทม์	",
    function_detail_2: "	โครงสร้าง IB หลายระดับ	",
    function_detail_3: "	การตั้งค่าค่าคอมมิชชั่นที่ยืดหยุ่น	",
    function_detail_4: "	เงินฝากและถอนง่ายและรวดเร็ว	",
    function_detail_5: "	ลำดับชั้นความสัมพันธ์ของ IB	",
    function_detail_6: "	รายงานกิจกรรมของลูกค้า	",
    partner_des2:
      "	บริษัท ในเครือทางการตลาดของเราเป็นส่วนสำคัญของความสำเร็จของเราและเรามองว่าประสิทธิภาพของพวกเขาเป็นของเราเอง ดังนั้นเราจึงได้พัฒนารูปแบบการแข่งขันที่ทำให้ บริษัท ในเครือได้รับผลตอบแทนอย่างเหมาะสมในขณะที่ให้เครื่องมือในการตรวจสอบการแปลงตะกั่วเงินฝากบัญชีแรกและพฤติกรรมของลูกค้า ผ่านพอร์ทัลพันธมิตรของเราคุณสามารถเข้าถึงแบนเนอร์วัสดุการศึกษาและอื่น ๆ	",
    partner_help_1: "	มุ่งเน้นลูกค้า	",
    partner_help_2: "	การตลาดที่ปรับให้เหมาะสม	",
    partner_help_3: "	ข้อมูลและการวิเคราะห์ 24/7	",
    offer_title: "	สิ่งที่เราเสนอ	",
    offer_title_box1: "	แนะนำโปรแกรมพาร์ทเนอร์	",
    offer_subtitle_box1: "	คุณได้รับรายได้มากถึง 40%	",
    offer_detail_box1:
      "	รับรายได้มากถึง 40% จากผู้ค้าที่ใช้งานอยู่ทุกคนที่คุณอ้างถึงเรา หากพวกเขากำลังซื้อขายคุณจะได้รับ	",
    offer_title_box2: "	โปรแกรมพันธมิตร	",
    offer_subtitle_box2: "	คุณสูงถึง $ 1770 สำหรับลูกค้าทุกคน	",
    offer_detail_box2:
      "	เรากำลังเป็นผู้นำอุตสาหกรรมด้วยการจ่ายเงินที่กำหนดและน่าสนใจ เราเสนอแผนค่าคอมมิชชั่นที่หลากหลายเพื่อให้คุณสามารถเลือกสิ่งที่เหมาะสมที่สุดสำหรับคุณ	",
    offer_note:
      "	นอกจากนี้เรายังเสนอรูปแบบการเป็นหุ้นส่วนที่กำหนดเองอื่น ๆ ที่สามารถพูดคุยในรายละเอียดเพิ่มเติมกับผู้จัดการบัญชีพันธมิตรของเรา	",
    get_pro_title: "	รับตราพันธมิตรมืออาชีพของคุณ!	",
    get_pro_detail:
      "	แสดงความร่วมมือ IUX Market ของคุณบนเว็บไซต์ของคุณด้วยป้ายอย่างเป็นทางการของเราและเน้นความร่วมมือกับพาร์ทเนอร์อันดับ 1 ของโลก	",
    partner_des3:
      "	บริษัท ในเครือทางการตลาดของเราเป็นส่วนสำคัญของความสำเร็จของเราและเรามองว่าประสิทธิภาพของพวกเขาเป็นของเราเอง ดังนั้นเราจึงได้พัฒนารูปแบบการแข่งขันที่ทำให้ บริษัท ในเครือได้รับผลตอบแทนอย่างเหมาะสมในขณะที่ให้เครื่องมือในการตรวจสอบการแปลงตะกั่วเงินฝากบัญชีแรกและพฤติกรรมของลูกค้า ผ่านพอร์ทัลพันธมิตรของเราคุณสามารถเข้าถึงแบนเนอร์วัสดุการศึกษาและอื่น ๆ	",
    auto_rebate_title: "	ระบบการฟื้นฟูอัตโนมัติ	",
    auto_rebate_1: "	พันธมิตร	",
    auto_rebate_2: "	iuxmarkets พันธมิตร	",
    auto_rebate_3: "	การคืนเงินอัตโนมัติ	",
    auto_rebate_4: "	ลูกค้า	",
    auto_rebate_detail1: "	ดึงดูดลูกค้าได้อย่างง่ายดายด้วยการมอบเงินคืน	",
    auto_rebate_detail2: "	ปรับเปอร์เซ็นต์ของการคืนเงินที่ให้กับลูกค้าแต่ละราย	",
    auto_rebate_detail3: "	กำหนดเวลาคืนลูกค้าอัตโนมัติ	",
    auto_rebate_detail4: "	เลือกที่จะควบคุมการคืนเงินด้วยตนเอง	",
    auto_rebate_detail5: "	พึ่งพาเราสำหรับการดำเนินงานด้านหลังของคุณ	",
    why_iux_title: "	ทำไมต้องตลาด iux?	",
    why_iux_detail1:
      "	ความสำเร็จของคุณคือความสำเร็จของเรา โปรแกรมพันธมิตรแนะนำของเราได้รับการปรับให้เหมาะกับการเติบโตทางธุรกิจของคุณ IUX Markets เป็นผู้นำที่น่าเชื่อถือในตลาดด้วยภารกิจเพื่อให้ผู้ค้ามีสเปรดต่ำสุดโดยไม่คำนึงถึงสภาวะตลาด",
    why_iux_detail2:
      "	เราภูมิใจที่จะนำเสนอเทคโนโลยีการซื้อขายที่ดีที่สุดในโลกเพื่อให้แน่ใจว่าประสบการณ์ของผู้ค้ามีประสิทธิภาพและเงื่อนไขการซื้อขายที่ยอดเยี่ยม ด้วยความสำเร็จของพันธมิตรที่เราต้องการอย่างแน่นหนาเรามุ่งมั่นที่จะให้การสนับสนุนที่ครอบคลุมเพื่อให้บรรลุมากขึ้น	",
    how_work_title: "	มันทำงานอย่างไร	",
    how_work_detail1:
      "	เราจะตรวจสอบใบสมัครของคุณและติดต่อกลับภายในหนึ่งวันทำการ เข้าร่วมพันธมิตรพันธมิตรของเราโดยทำตามขั้นตอนการสมัครอย่างง่ายของเรา	",
    how_work_detail2:
      "	หลังจากลงทะเบียนสมาชิกในทีมของเราคนหนึ่งจะติดต่อและแนะนำคุณเกี่ยวกับกระบวนการเกี่ยวกับวิธีการเริ่มต้นการอ้างอิงของคุณ	",
    how_work_detail3:
      "	ไม่ต้องกังวล! เราจะอัปเดตและสนับสนุนคุณตลอดเส้นทางการเติบโตของคุณ	",
    how_work_detail4:
      "	หลังจากเสร็จสิ้นการตรวจสอบลูกค้าที่อ้างอิงของคุณรางวัลจะปรากฏขึ้นโดยอัตโนมัติในกระเป๋าเงินของคุณ (สำหรับลูกค้าที่มีสิทธิ์เท่านั้น)	",
  },
  lo: {
    partner_header_introducing: "	ນາຍຫນ້າແນະນໍາ	",
    partner_des:
      "	ໂຄງການ IB ຂອງພວກເຮົາໄດ້ຖືກພັດທະນາດ້ວຍການສຸມໃສ່ການຮ່ວມມືກັນ. ບໍ່ວ່າທ່ານຈະເປັນບຸກຄົນທີ່ມີບຸກຄົນດຽວຫລືບໍລິສັດທີ່ມີພະນັກງານຫລາຍສິບຄົນຫຼືຫຼາຍຮ້ອຍຄົນ, ພວກເຮົາເຂົ້າໃຈຄວາມຮຽກຮ້ອງຕ້ອງການດ້ານທຸລະກິດຂອງທ່ານແລະຄວາມຕ້ອງການເພື່ອເພີ່ມລາຍໄດ້ຂອງທ່ານ. ນອກເຫນືອໄປຈາກວິທີແກ້ໄຂປະຕູທີ່ຫລາກຫລາຍແລະທີມງານຜູ້ຈັດການຄູ່ຮ່ວມງານພ້ອມທີ່ຈະຊ່ວຍທ່ານແລະລູກຄ້າຂອງທ່ານ, ໂຄງການ IB ຂອງພວກເຮົາສະເຫນີຄວາມເປັນໄປໄດ້ຂອງເງິນຄືນແລະການຊົດເຊີຍ.	",
    title_ourib: "	ປະຕູ IB ຂອງພວກເຮົາມີຫນ້າທີ່ດັ່ງຕໍ່ໄປນີ້:	",
    function_detail_1: "	ຄະນະກໍາມະການເວລາຈິງ	",
    function_detail_2: "	ໂຄງສ້າງ IB ທີ່ມີຫຼາຍລະດັບ	",
    function_detail_3: "	ການຕັ້ງຄ່າຄະນະກໍາມະການທີ່ຍືດຫຍຸ່ນໄດ້	",
    function_detail_4: "	ງ່າຍແລະວ່ອງໄວການຝາກເງິນແລະການຖອນເງິນ	",
    function_detail_5: "	ລໍາດັບສາຍພົວພັນ IB	",
    function_detail_6: "	ບົດລາຍງານກິດຈະກໍາຂອງລູກຄ້າ	",
    partner_des2:
      "	ການຕະຫຼາດການຕະຫລາດຂອງພວກເຮົາແມ່ນສ່ວນຫນຶ່ງທີ່ສໍາຄັນຂອງຄວາມສໍາເລັດຂອງພວກເຮົາແລະພວກເຮົາຖືວ່າການປະຕິບັດຂອງພວກເຂົາເປັນຂອງພວກເຮົາເອງ. ເພາະສະນັ້ນ, ພວກເຮົາໄດ້ພັດທະນາຮູບແບບການແຂ່ງຂັນທີ່ໄດ້ຮັບການຄຸ້ມຄອງສາຂາຢ່າງຖືກຕ້ອງໃນຂະນະທີ່ໃຫ້ເຄື່ອງມືໃນການນໍາໃຊ້ບັນຊີຜູ້ນໍາ, ແລະການປະພຶດຂອງລູກຄ້າ. ຜ່ານປະຕູທີ່ເປັນພີ່ນ້ອງຂອງພວກເຮົາ, ທ່ານສາມາດເຂົ້າເຖິງປ້າຍໂຄສະນາ, ອຸປະກອນການສຶກສາ, ແລະອື່ນໆ.	",
    partner_help_1: "	ຈຸດສຸມ	",
    partner_help_2: "	ການຕະຫຼາດທີ່ດີທີ່ສຸດ	",
    partner_help_3: "	24/7 ຂໍ້ມູນແລະການວິເຄາະ	",
    offer_title: "	ສິ່ງທີ່ພວກເຮົາສະເຫນີ	",
    offer_title_box1: "	ໂຄງການແນະນໍາໂຄງການນາຍຫນ້າ	",
    offer_subtitle_box1: "	ທ່ານໄດ້ຮັບເຖິງ 40% ຂອງລາຍໄດ້ຂອງພວກເຮົາ	",
    offer_detail_box1:
      "	ມີລາຍໄດ້ເຖິງ 40% ຂອງລາຍໄດ້ຈາກທຸກໆຄ້າຂາຍທີ່ໃຊ້ງານທີ່ທ່ານກ່າວເຖິງພວກເຮົາ. ຖ້າພວກເຂົາຊື້ຂາຍ, ທ່ານໄດ້ຮັບ.	",
    offer_title_box2: "	ໂຄງການເປັນພີ່ນ້ອງກັນ	",
    offer_subtitle_box2: "	ທ່ານໄດ້ຮັບເຖິງ $ 1770 ສໍາລັບລູກຄ້າທຸກຄົນ	",
    offer_detail_box2:
      "	ພວກເຮົາກໍາລັງນໍາພາອຸດສາຫະກໍາກັບການຈ່າຍເງິນທີ່ກໍານົດແລະການຈ່າຍເງິນທີ່ຫນ້າສົນໃຈ. ພວກເຮົາສະເຫນີແຜນການຄະນະກໍາມະການຫລາກຫລາຍ, ດັ່ງນັ້ນທ່ານສາມາດເລືອກທີ່ດີທີ່ສຸດສໍາລັບທ່ານ.	",
    offer_note:
      "	ພວກເຮົາຍັງສະເຫນີລະບົບການພົວພັນຄູ່ມືອື່ນໆທີ່ສາມາດປຶກສາໃນລາຍລະອຽດເພີ່ມເຕີມກັບຜູ້ຈັດການບັນຊີຂອງພວກເຮົາ.	",
    get_pro_title: "	ໄດ້ຮັບປ້າຍຄູ່ຮ່ວມມືຂອງທ່ານ!	",
    get_pro_detail:
      "	ສະແດງເຄື່ອງມືການພົວພັນກັບຕະຫຼາດ IX ຂອງທ່ານໃນເວັບໄຊທ໌ຂອງທ່ານດ້ວຍປ້າຍຊື່ທາງການຂອງພວກເຮົາແລະຍົກໃຫ້ເຫັນການເຊື່ອມໂຍງຂອງທ່ານກັບນາຍຫນ້າເວັບໄຊທ໌ຂອງທ່ານ.	",
    partner_des3:
      "	ການຕະຫຼາດການຕະຫລາດຂອງພວກເຮົາແມ່ນສ່ວນຫນຶ່ງທີ່ສໍາຄັນຂອງຄວາມສໍາເລັດຂອງພວກເຮົາແລະພວກເຮົາຖືວ່າການປະຕິບັດຂອງພວກເຂົາເປັນຂອງພວກເຮົາເອງ. ເພາະສະນັ້ນ, ພວກເຮົາໄດ້ພັດທະນາຮູບແບບການແຂ່ງຂັນທີ່ໄດ້ຮັບການຄຸ້ມຄອງສາຂາຢ່າງຖືກຕ້ອງໃນຂະນະທີ່ໃຫ້ເຄື່ອງມືໃນການນໍາໃຊ້ບັນຊີຜູ້ນໍາ, ແລະການປະພຶດຂອງລູກຄ້າ. ຜ່ານປະຕູທີ່ເປັນພີ່ນ້ອງຂອງພວກເຮົາ, ທ່ານສາມາດເຂົ້າເຖິງປ້າຍໂຄສະນາ, ອຸປະກອນການສຶກສາ, ແລະອື່ນໆ.	",
    auto_rebate_title: "	ລະບົບ remoate ອັດຕະໂນມັດ	",
    auto_rebate_1: "	comemiliate ພັນລະຍາ	",
    auto_rebate_2: "	ເປັນພີ່ນ້ອງຂອງ iuxchmarets	",
    auto_rebate_3: "	ການໂອນຄືນອັດຕະໂນມັດ	",
    auto_rebate_4: "	ລູກ​ຄ້າ	",
    auto_rebate_detail1: "	ດຶງດູດລູກຄ້າໄດ້ງ່າຍໂດຍການມອບເງິນຄືນ	",
    auto_rebate_detail2: "	ປັບອັດຕາສ່ວນຂອງການສົ່ງເງິນຄືນໃຫ້ກັບລູກຄ້າແຕ່ລະຄົນ	",
    auto_rebate_detail3: "	ຈັດຕາຕະລາງການສົ່ງເງິນຄືນຂອງລູກຄ້າອັດຕະໂນມັດ	",
    auto_rebate_detail4: "	ເລືອກທີ່ຈະຄວບຄຸມການປົດຕໍາແຫນ່ງດ້ວຍຕົນເອງ	",
    auto_rebate_detail5: "	ອີງໃສ່ພວກເຮົາສໍາລັບການດໍາເນີນງານຫ້ອງການຂອງທ່ານ	",
    why_iux_title: "	ເປັນຫຍັງ iux oux?	",
    why_iux_detail1:
      "	ຄວາມສໍາເລັດຂອງທ່ານແມ່ນຜົນສໍາເລັດຂອງພວກເຮົາ. ໂປແກຼມແນະນໍາດ້ານການຮ່ວມມືຂອງພວກເຮົາແມ່ນເຫມາະສົມກັບການເຕີບໂຕຂອງທຸລະກິດຂອງທ່ານ. ຕະຫຼາດ IIUX ແມ່ນຜູ້ນໍາທີ່ຫນ້າເຊື່ອຖືໃນຕະຫຼາດກັບພາລະກິດເພື່ອໃຫ້ພໍ່ຄ້າອາຍຸຕ່ໍາສຸດໂດຍບໍ່ສົນເລື່ອງຂອງຕະຫຼາດ",
    why_iux_detail2:
      "	ພວກເຮົາມີຄວາມພູມໃຈທີ່ໄດ້ສະເຫນີເຕັກໂນໂລຢີການຄ້າທີ່ດີທີ່ສຸດໃນໂລກເພື່ອຮັບປະກັນການປະຕິບັດການປະຕິບັດຂອງພໍ່ຄ້າແລະສະພາບການຄ້າທີ່ບໍ່ມີປະສິດຕິພາບ. ດ້ວຍຄວາມສໍາເລັດທີ່ພວກເຮົາມັກທີ່ສຸດຂອງພວກເຮົາຢ່າງຫນັກແຫນ້ນ, ພວກເຮົາພະຍາຍາມສະເຫນີການສະຫນັບສະຫນູນທີ່ສົມບູນແບບເພື່ອໃຫ້ບັນລຸໄດ້ຫຼາຍຂື້ນ.	",
    how_work_title: "	ເຮັດແນວໃດມັນເຮັດວຽກ	",
    how_work_detail1:
      "	ພວກເຮົາຈະກວດສອບໃບສະຫມັກຂອງທ່ານແລະກັບມາຫາທ່ານພາຍໃນມື້ຫນຶ່ງ. ເຂົ້າຮ່ວມຄູ່ຮ່ວມງານທີ່ມີຄວາມສາມາດຂອງພວກເຮົາໂດຍປະຕິບັດຕາມຂະບວນການສະຫມັກງ່າຍດາຍຂອງພວກເຮົາ	",
    how_work_detail2:
      "	ຫລັງຈາກລົງທະບຽນ, ຫນຶ່ງໃນສະມາຊິກທີມງານຂອງພວກເຮົາຈະຕິດຕໍ່ແລະນໍາພາທ່ານຜ່ານຂັ້ນຕອນກ່ຽວກັບວິທີການເລີ່ມຕົ້ນການສົ່ງຕໍ່ຂອງທ່ານ.	",
    how_work_detail3:
      "	ຢ່າກັງວົນ! ພວກເຮົາຈະປັບປຸງແລະສະຫນັບສະຫນູນທ່ານຕາມການເດີນທາງການເຕີບໂຕຂອງທ່ານ.	",
    how_work_detail4:
      "	ຫຼັງຈາກການທົບທວນຄືນການທົບທວນຄືນລູກຄ້າທີ່ໄດ້ຮັບການອ້າງອີງຂອງທ່ານ, ລາງວັນຈະປາກົດຢູ່ໃນກະເປົາເງິນຂອງທ່ານໂດຍອັດຕະໂນມັດ (ພຽງແຕ່ລູກຄ້າທີ່ມີເງື່ອນໄຂ)	",
  },

  en: {
    partner_header_introducing: "	INTRODUCING BROKER	",
    partner_des:
      "	Our IB program was developed with a focus on partnerships. Whether you are a single individual or a corporate with tens or hundreds of staff, we comprehend your business demands and requirements to increase your earnings. In addition to a versatile IB portal solution and a team of Partnership Managers ready to assist you and your clients, our IB program offers a number of rebate and compensation possibilities.	",
    title_ourib: "	Our IB portal has the following functionalities:	",
    function_detail_1: "	Real time commissions	",
    function_detail_2: "	Multi-level IB structure	",
    function_detail_3: "	Flexible commissions setting	",
    function_detail_4: "	Easy and swift deposit and withdrawal	",
    function_detail_5: "	IB relationship hierarchy	",
    function_detail_6: "	Client activity report	",
    partner_des2:
      "	Our marketing affiliates are an integral part of our success and we view their performance as our own. Therefore, we have developed a competitive model that remunerates affiliates properly while giving them the tools to monitor lead conversions, first account deposits, and client behaviour. Through our affiliate portal, you can access banners, educational material, and more.	",
    partner_help_1: "	Client-oriented	",
    partner_help_2: "	Optimized Marketing	",
    partner_help_3: "	24/7 Data And Analytics	",
    offer_title: "	WHAT WE OFFER	",
    offer_title_box1: "	Introducing Broker Program	",
    offer_subtitle_box1: "	You get up to 40% of our revenue	",
    offer_detail_box1:
      "	Earn up to 40% of our revenue from every active trader you refer to us. If they’re trading, you’re earning.	",
    offer_title_box2: "	Affiliate Program	",
    offer_subtitle_box2: "	You get up to $1770 for every client	",
    offer_detail_box2:
      "	We are leading the industry with scheduled and attractive payouts. We offer a range of commission plans, so you can choose the best fit for you.	",
    offer_note:
      "	We also offer other customised partnership schemes that can be discussed in more details with our Partner Account Managers.	",
    get_pro_title: "	Get your Pro Partner Badge!	",
    get_pro_detail:
      "	Showcase your IUX Markets Partnership on your website with our official badge and highlight your affiliation with the world’s #1 broker.	",
    partner_des3:
      "	Our marketing affiliates are an integral part of our success and we view their performance as our own. Therefore, we have developed a competitive model that remunerates affiliates properly while giving them the tools to monitor lead conversions, first account deposits, and client behaviour. Through our affiliate portal, you can access banners, educational material, and more.	",
    auto_rebate_title: "	AUTO-REBATE SYSTEM	",
    auto_rebate_1: "	Affiliate Comission	",
    auto_rebate_2: "	IUXMARKETS Affiliate	",
    auto_rebate_3: "	Auto Rebate	",
    auto_rebate_4: "	Client	",
    auto_rebate_detail1: "	Easily attract clients by awarding rebates	",
    auto_rebate_detail2: "	Adjust the percentage of rebate given to each client	",
    auto_rebate_detail3: "	Schedule automatic client rebates	",
    auto_rebate_detail4: "	Choose to control rebates manually	",
    auto_rebate_detail5: "	Rely on us for your Back Office operations	",
    why_iux_title: "	Why IUX Markets?	",
    why_iux_detail1:
      "	Your success is our success. Our Introducing Affiliate Program is tailored to your business growth. IUX Markets is a trustworthy leader in the market with the mission to provide traders with the lowest spread regardless of market conditions",
    why_iux_detail2:
      "	We are proud to offer world’s best trading technology to ensure traders’ experiences unrivaled performance and trading conditions. With our Preferred Partners success firmly in focus, we strive to offer comprehensive support to achieve more.	",
    how_work_title: "	HOW IT WORKS	",
    how_work_detail1:
      "	We will review your application and get back to you within one business day. Join our Affiliate Partner by follow our simple application process	",
    how_work_detail2:
      "	After signing up, one of our team members will contact and guide you through the process on how to start your referrals.	",
    how_work_detail3:
      "	Don’t worry! We will update and support you along your growth journey.	",
    how_work_detail4:
      "	After finishing reviewing your referred clients, the rewards will automatically appear in your wallet (only for eligible clients)	",
  },
  hi: {
    partner_header_introducing: "	दलाल का परिचय करा रहे हैं	",
    partner_des:
      "	हमारा आईबी कार्यक्रम साझेदारी पर ध्यान देने के साथ विकसित किया गया था। चाहे आप एक एकल व्यक्ति हों या दसियों या सैकड़ों कर्मचारियों के साथ एक कॉर्पोरेट हों, हम आपकी कमाई बढ़ाने के लिए आपकी व्यावसायिक मांगों और आवश्यकताओं को समझते हैं। एक बहुमुखी आईबी पोर्टल समाधान और साझेदारी प्रबंधकों की एक टीम के अलावा, जो आपकी और आपके ग्राहकों की सहायता के लिए तैयार हैं, हमारा आईबी कार्यक्रम कई छूट और मुआवजा संभावनाएं प्रदान करता है।	",
    title_ourib: "	हमारे आईबी पोर्टल में निम्नलिखित कार्यक्षमताएं हैं:	",
    function_detail_1: "	वास्तविक समय आयोग	",
    function_detail_2: "	बहु-स्तरीय आईबी संरचना	",
    function_detail_3: "	लचीला आयोगों की स्थापना	",
    function_detail_4: "	आसान और तेज जमा और निकासी	",
    function_detail_5: "	आईबी रिलेशनशिप पदानुक्रम	",
    function_detail_6: "	ग्राहक गतिविधि रिपोर्ट	",
    partner_des2:
      "	हमारे विपणन सहयोगी हमारी सफलता का एक अभिन्न अंग हैं और हम उनके प्रदर्शन को अपने रूप में देखते हैं। इसलिए, हमने एक प्रतिस्पर्धी मॉडल विकसित किया है जो लीड रूपांतरणों, पहले खाता जमा और क्लाइंट व्यवहार की निगरानी के लिए उपकरण देते हुए संबद्धता को ठीक से पारिश्रमिक देता है। हमारे संबद्ध पोर्टल के माध्यम से, आप बैनर, शैक्षिक सामग्री, और बहुत कुछ तक पहुंच सकते हैं।	",
    partner_help_1: "	ग्राहक उन्मुख	",
    partner_help_2: "	अनुकूलित विपणन	",
    partner_help_3: "	24/7 डेटा और विश्लेषण	",
    offer_title: "	हमारी पेशकश	",
    offer_title_box1: "	ब्रोकर कार्यक्रम का परिचय	",
    offer_subtitle_box1: "	आप हमारे राजस्व का 40% तक प्राप्त करते हैं	",
    offer_detail_box1:
      "	हमारे द्वारा संदर्भित प्रत्येक सक्रिय व्यापारी से हमारे राजस्व का 40% तक कमाएँ। यदि वे व्यापार कर रहे हैं, तो आप कमा रहे हैं।	",
    offer_title_box2: "	संबद्ध कार्यक्रम	",
    offer_subtitle_box2: "	आप हर ग्राहक के लिए $ 1770 तक प्राप्त करते हैं	",
    offer_detail_box2:
      "	हम अनुसूचित और आकर्षक भुगतान के साथ उद्योग का नेतृत्व कर रहे हैं। हम आयोग की एक श्रृंखला प्रदान करते हैं, इसलिए आप आपके लिए सबसे अच्छा फिट चुन सकते हैं।	",
    offer_note:
      "	हम अन्य अनुकूलित साझेदारी योजनाओं की भी पेशकश करते हैं जिन पर हमारे भागीदार खाता प्रबंधकों के साथ अधिक विवरण में चर्चा की जा सकती है।	",
    get_pro_title: "	अपने समर्थक साथी बैज प्राप्त करें!	",
    get_pro_detail:
      "	हमारी आधिकारिक बैज के साथ अपनी वेबसाइट पर अपनी IUX मार्केट्स पार्टनरशिप का प्रदर्शन करें और दुनिया के #1 ब्रोकर के साथ अपनी संबद्धता को उजागर करें।	",
    partner_des3:
      "	हमारे विपणन सहयोगी हमारी सफलता का एक अभिन्न अंग हैं और हम उनके प्रदर्शन को अपने रूप में देखते हैं। इसलिए, हमने एक प्रतिस्पर्धी मॉडल विकसित किया है जो लीड रूपांतरणों, पहले खाता जमा और क्लाइंट व्यवहार की निगरानी के लिए उपकरण देते हुए संबद्धता को ठीक से पारिश्रमिक देता है। हमारे संबद्ध पोर्टल के माध्यम से, आप बैनर, शैक्षिक सामग्री, और बहुत कुछ तक पहुंच सकते हैं।	",
    auto_rebate_title: "	ऑटो-रीबेट सिस्टम	",
    auto_rebate_1: "	संबद्ध कॉमिशन	",
    auto_rebate_2: "	Iuxmarkets संबद्ध	",
    auto_rebate_3: "	ऑटो रिबेट	",
    auto_rebate_4: "	ग्राहक	",
    auto_rebate_detail1:
      "	आसानी से ग्राहकों को छूट देकर ग्राहकों को आकर्षित करें	",
    auto_rebate_detail2:
      "	प्रत्येक ग्राहक को दी गई छूट के प्रतिशत को समायोजित करें	",
    auto_rebate_detail3: "	स्वचालित ग्राहक छूट अनुसूची	",
    auto_rebate_detail4: "	मैन्युअल रूप से छूट को नियंत्रित करने के लिए चुनें	",
    auto_rebate_detail5: "	अपने बैक ऑफिस के संचालन के लिए हम पर भरोसा करें	",
    why_iux_title: "	IUX बाजार क्यों?	",
    why_iux_detail1:
      "आपकी सफ़लता हमारी सफ़लता है। हमारा परिचय संबद्ध कार्यक्रम आपके व्यवसाय के विकास के अनुरूप है। IUX मार्केट्स बाजार में एक भरोसेमंद नेता है, जो व्यापारियों को बाजार की स्थितियों की परवाह किए बिना सबसे कम प्रसार के साथ प्रदान करने के लिए मिशन के साथ है",
    why_iux_detail2:
      "	व्यापारियों के अनुभवों को बेजोड़ प्रदर्शन और व्यापारिक स्थितियों को सुनिश्चित करने के लिए हमें दुनिया की सर्वश्रेष्ठ ट्रेडिंग तकनीक की पेशकश करने पर गर्व है। हमारे पसंदीदा भागीदारों की सफलता के साथ दृढ़ता से ध्यान में, हम अधिक प्राप्त करने के लिए व्यापक समर्थन प्रदान करने का प्रयास करते हैं।	",
    how_work_title: "	यह काम किस प्रकार करता है	",
    how_work_detail1:
      "	हम आपके आवेदन की समीक्षा करेंगे और एक व्यावसायिक दिन के भीतर आपके पास वापस आ जाएंगे। हमारी सरल आवेदन प्रक्रिया का पालन करके हमारे संबद्ध साथी से जुड़ें	",
    how_work_detail2:
      "	साइन अप करने के बाद, हमारी टीम के सदस्य आपके रेफरल को शुरू करने के तरीके के बारे में इस प्रक्रिया के माध्यम से संपर्क और मार्गदर्शन करेंगे।	",
    how_work_detail3:
      "	चिंता मत करो! हम आपकी वृद्धि यात्रा के साथ आपको अपडेट और समर्थन करेंगे।	",
    how_work_detail4:
      "	अपने संदर्भित ग्राहकों की समीक्षा करने के बाद, पुरस्कार स्वचालित रूप से आपके वॉलेट में दिखाई देंगे (केवल पात्र ग्राहकों के लिए)	",
  },
  ms: {
    partner_header_introducing: "	Memperkenalkan Broker	",
    partner_des:
      "	Program IB kami dibangunkan dengan tumpuan kepada perkongsian. Sama ada anda seorang individu atau korporat dengan puluhan atau beratus -ratus kakitangan, kami memahami tuntutan dan keperluan perniagaan anda untuk meningkatkan pendapatan anda. Sebagai tambahan kepada penyelesaian portal IB yang serba boleh dan satu pasukan pengurus perkongsian yang bersedia membantu anda dan pelanggan anda, program IB kami menawarkan beberapa kemungkinan rebat dan pampasan.	",
    title_ourib: "	Portal IB kami mempunyai fungsi berikut:	",
    function_detail_1: "	Komisen masa nyata	",
    function_detail_2: "	Struktur IB pelbagai peringkat	",
    function_detail_3: "	Tetapan komisen yang fleksibel	",
    function_detail_4: "	Deposit dan penarikan balik yang mudah dan cepat	",
    function_detail_5: "	IB Hubungan Hierarki	",
    function_detail_6: "	Laporan Aktiviti Pelanggan	",
    partner_des2:
      "	Gabungan pemasaran kami adalah sebahagian daripada kejayaan kami dan kami melihat prestasi mereka sebagai milik kami sendiri. Oleh itu, kami telah membangunkan model yang kompetitif yang menyerupai sekutu dengan betul sambil memberi mereka alat untuk memantau penukaran utama, deposit akaun pertama, dan tingkah laku pelanggan. Melalui portal gabungan kami, anda boleh mengakses sepanduk, bahan pendidikan, dan banyak lagi.	",
    partner_help_1: "	Berorientasikan pelanggan	",
    partner_help_2: "	Pemasaran yang dioptimumkan	",
    partner_help_3: "	24/7 data dan analisis	",
    offer_title: "	APA YANG KAMI TAWARKAN	",
    offer_title_box1: "	Memperkenalkan program broker	",
    offer_subtitle_box1: "	Anda mendapat sehingga 40% pendapatan kami	",
    offer_detail_box1:
      "	Dapatkan sehingga 40% daripada pendapatan kami dari setiap peniaga aktif yang anda rujuk kepada kami. Jika mereka berdagang, anda pendapatan.	",
    offer_title_box2: "	Program Gabungan	",
    offer_subtitle_box2: "	Anda mendapat sehingga $ 1770 untuk setiap pelanggan	",
    offer_detail_box2:
      "	Kami memimpin industri dengan pembayaran yang dijadualkan dan menarik. Kami menawarkan pelbagai rancangan komisen, jadi anda boleh memilih yang terbaik untuk anda.	",
    offer_note:
      "	Kami juga menawarkan skim perkongsian tersuai lain yang boleh dibincangkan dengan lebih terperinci dengan pengurus akaun rakan kongsi kami.	",
    get_pro_title: "	Dapatkan lencana rakan kongsi anda!	",
    get_pro_detail:
      "	Mempamerkan Perkongsian Pasaran IUX anda di laman web anda dengan lencana rasmi kami dan menyerlahkan gabungan anda dengan broker #1 di dunia.	",
    partner_des3:
      "	Gabungan pemasaran kami adalah sebahagian daripada kejayaan kami dan kami melihat prestasi mereka sebagai milik kami sendiri. Oleh itu, kami telah membangunkan model yang kompetitif yang menyerupai sekutu dengan betul sambil memberi mereka alat untuk memantau penukaran utama, deposit akaun pertama, dan tingkah laku pelanggan. Melalui portal gabungan kami, anda boleh mengakses sepanduk, bahan pendidikan, dan banyak lagi.	",
    auto_rebate_title: "	Sistem Auto-Rebate	",
    auto_rebate_1: "	Komisi afiliasi	",
    auto_rebate_2: "	Afiliasi iuxmarkets	",
    auto_rebate_3: "	Rebat Auto	",
    auto_rebate_4: "	Pelanggan	",
    auto_rebate_detail1: "	Mudah menarik pelanggan dengan memberikan rebat	",
    auto_rebate_detail2:
      "	Laraskan peratusan rebat yang diberikan kepada setiap pelanggan	",
    auto_rebate_detail3: "	Jadualkan Rebat Pelanggan Automatik	",
    auto_rebate_detail4: "	Memilih untuk mengawal rebat secara manual	",
    auto_rebate_detail5:
      "	Bergantung pada kami untuk operasi pejabat belakang anda	",
    why_iux_title: "	Mengapa pasaran iux?	",
    why_iux_detail1:
      "Kejayaan anda adalah kejayaan kami. Program afiliasi kami disesuaikan dengan pertumbuhan perniagaan anda. Pasar IUX adalah pemimpin yang boleh dipercayai di pasaran dengan misi untuk menyediakan peniaga dengan penyebaran terendah tanpa mengira keadaan pasaran",
    why_iux_detail2:
      "	Kami berbangga untuk menawarkan teknologi perdagangan terbaik di dunia untuk memastikan pengalaman peniaga -peniaga yang tidak dapat ditandingi dan keadaan perdagangan. Dengan kejayaan rakan kongsi pilihan kami dengan tegas, kami berusaha untuk menawarkan sokongan yang komprehensif untuk mencapai lebih banyak lagi.	",
    how_work_title: "	BAGAIMANA IA BERFUNGSI	",
    how_work_detail1:
      "	Kami akan menyemak permohonan anda dan kembali kepada anda dalam satu hari perniagaan. Sertailah rakan sekutu kami dengan ikuti proses permohonan mudah kami	",
    how_work_detail2:
      "	Selepas mendaftar, salah seorang ahli pasukan kami akan menghubungi dan membimbing anda melalui proses bagaimana untuk memulakan rujukan anda.	",
    how_work_detail3:
      "	Jangan risau! Kami akan mengemas kini dan menyokong anda sepanjang perjalanan pertumbuhan anda.	",
    how_work_detail4:
      "	Setelah menyelesaikan mengkaji semula pelanggan yang dirujuk, ganjaran akan muncul secara automatik di dompet anda (hanya untuk pelanggan yang layak)	",
  },
  id: {
    partner_header_introducing: "	Memperkenalkan broker	",
    partner_des:
      "	Program IB kami dikembangkan dengan fokus pada kemitraan. Apakah Anda seorang individu tunggal atau perusahaan dengan puluhan atau ratusan staf, kami memahami tuntutan dan persyaratan bisnis Anda untuk meningkatkan penghasilan Anda. Selain solusi portal IB yang serba guna dan tim manajer kemitraan yang siap membantu Anda dan klien Anda, program IB kami menawarkan sejumlah kemungkinan rabat dan kompensasi.	",
    title_ourib: "	Portal IB kami memiliki fungsionalitas berikut:	",
    function_detail_1: "	Komisi waktu nyata	",
    function_detail_2: "	Struktur IB multi-level	",
    function_detail_3: "	Pengaturan Komisi Fleksibel	",
    function_detail_4: "	Setoran dan penarikan yang mudah dan cepat	",
    function_detail_5: "	Hierarki hubungan IB	",
    function_detail_6: "	Laporan Aktivitas Klien	",
    partner_des2:
      "	Afiliasi pemasaran kami adalah bagian integral dari kesuksesan kami dan kami memandang kinerja mereka sebagai milik kami. Oleh karena itu, kami telah mengembangkan model kompetitif yang mengimbangi afiliasi dengan benar sambil memberi mereka alat untuk memantau konversi timbal, setoran akun pertama, dan perilaku klien. Melalui portal afiliasi kami, Anda dapat mengakses spanduk, materi pendidikan, dan banyak lagi.	",
    partner_help_1: "	Berorientasi klien	",
    partner_help_2: "	Pemasaran yang dioptimalkan	",
    partner_help_3: "	Data dan analitik 24/7	",
    offer_title: "	APA YANG KITA TAWARKAN	",
    offer_title_box1: "	Memperkenalkan program broker	",
    offer_subtitle_box1: "	Anda mendapatkan hingga 40% dari pendapatan kami	",
    offer_detail_box1:
      "	Hasilkan hingga 40% dari pendapatan kami dari setiap pedagang aktif yang Anda rujuk kepada kami. Jika mereka berdagang, Anda berpenghasilan.	",
    offer_title_box2: "	Program Afiliasi	",
    offer_subtitle_box2: "	Anda mendapatkan hingga $ 1770 untuk setiap klien	",
    offer_detail_box2:
      "	Kami memimpin industri dengan pembayaran yang dijadwalkan dan menarik. Kami menawarkan berbagai paket komisi, sehingga Anda dapat memilih yang paling cocok untuk Anda.	",
    offer_note:
      "	Kami juga menawarkan skema kemitraan khusus lainnya yang dapat dibahas secara lebih rinci dengan manajer akun mitra kami.	",
    get_pro_title: "	Dapatkan Lencana Mitra Pro Anda!	",
    get_pro_detail:
      "	Pamerkan kemitraan IUX Markets Anda di situs web Anda dengan lencana resmi kami dan sorot afiliasi Anda dengan broker #1 dunia.	",
    partner_des3:
      "	Afiliasi pemasaran kami adalah bagian integral dari kesuksesan kami dan kami memandang kinerja mereka sebagai milik kami. Oleh karena itu, kami telah mengembangkan model kompetitif yang mengimbangi afiliasi dengan benar sambil memberi mereka alat untuk memantau konversi timbal, setoran akun pertama, dan perilaku klien. Melalui portal afiliasi kami, Anda dapat mengakses spanduk, materi pendidikan, dan banyak lagi.	",
    auto_rebate_title: "	Sistem Auto-Rebate	",
    auto_rebate_1: "	Komisi Afiliasi	",
    auto_rebate_2: "	Afiliasi iuxmarket	",
    auto_rebate_3: "	Rabat Otomatis	",
    auto_rebate_4: "	Klien	",
    auto_rebate_detail1:
      "	Dengan mudah menarik klien dengan memberikan potongan harga	",
    auto_rebate_detail2:
      "	Sesuaikan persentase rabat yang diberikan kepada setiap klien	",
    auto_rebate_detail3: "	Jadwalkan Rabat Klien Otomatis	",
    auto_rebate_detail4: "	Pilih untuk mengontrol rabat secara manual	",
    auto_rebate_detail5: "	Mengandalkan kami untuk operasi back office Anda	",
    why_iux_title: "	Mengapa IUX Markets?	",
    why_iux_detail1:
      "Kesuksesan Anda adalah kesuksesan kami. Program Afiliasi Pengenalan kami disesuaikan dengan pertumbuhan bisnis Anda. IUX Markets adalah pemimpin yang dapat dipercaya di pasar dengan misi untuk memberi para pedagang spread terendah terlepas dari kondisi pasar	",
    why_iux_detail2:
      "	Kami bangga menawarkan teknologi perdagangan terbaik dunia untuk memastikan pengalaman para pedagang dan kondisi perdagangan yang tak tertandingi. Dengan mitra pilihan kami berhasil dengan kuat dalam fokus, kami berusaha untuk menawarkan dukungan komprehensif untuk mencapai lebih banyak.	",
    how_work_title: "	BAGAIMANA ITU BEKERJA	",
    how_work_detail1:
      "	Kami akan meninjau aplikasi Anda dan menghubungi Anda dalam satu hari kerja. Bergabunglah dengan mitra afiliasi kami dengan mengikuti proses aplikasi sederhana kami	",
    how_work_detail2:
      "	Setelah mendaftar, salah satu anggota tim kami akan menghubungi dan memandu Anda melalui proses cara memulai referensi Anda.	",
    how_work_detail3:
      "	Jangan khawatir! Kami akan memperbarui dan mendukung Anda sepanjang perjalanan pertumbuhan Anda.	",
    how_work_detail4:
      "	Setelah selesai meninjau klien yang Anda rujuk, hadiah akan secara otomatis muncul di dompet Anda (hanya untuk klien yang memenuhi syarat)	",
  },
  zh: {
    partner_header_introducing: "	介紹經紀人	",
    partner_des:
      "	我們的IB計劃的開發為重點是合作夥伴關係。無論您是一個人還是擁有數十人或數百名員工的公司，我們都會理解您的業務要求和要求以增加收入。除了多功能的IB門戶解決方案和一組合作夥伴管理者準備幫助您和您的客戶的團隊外，我們的IB計劃還提供了許多回扣和薪酬的可能性。	",
    title_ourib: "	我們的IB門戶具有以下功能：	",
    function_detail_1: "	實時委員會	",
    function_detail_2: "	多層IB結構	",
    function_detail_3: "	靈活的佣金設置	",
    function_detail_4: "	輕鬆而迅速的存款和退出	",
    function_detail_5: "	IB關係層次結構	",
    function_detail_6: "	客戶活動報告	",
    partner_des2:
      "	我們的營銷分支機構是我們成功的組成部分，我們將其表現視為自己的表現。因此，我們開發了一個競爭模型，該模型可以正確地授予分支機構，同時為他們提供監視潛在客戶轉換，第一帳戶存款和客戶行為的工具。通過我們的會員門戶，您可以訪問橫幅，教育材料等。	",
    partner_help_1: "	面向客戶的	",
    partner_help_2: "	優化的營銷	",
    partner_help_3: "	24/7數據和分析	",
    offer_title: "	我們提供的	",
    offer_title_box1: "	介紹經紀計劃	",
    offer_subtitle_box1: "	您最多獲得我們收入的40％	",
    offer_detail_box1:
      "	從您提到的每個活躍交易者那裡，最多可賺取我們的收入40％。如果他們正在交易，那麼您正在賺錢。	",
    offer_title_box2: "	會員計劃	",
    offer_subtitle_box2: "	每個客戶最多可獲得$ 1770	",
    offer_detail_box2:
      "	我們以計劃和有吸引力的支出領導該行業。我們提供一系列佣金計劃，因此您可以選擇最適合您的佣金計劃。	",
    offer_note:
      "	我們還提供其他定制的合作夥伴計劃，可以與我們的合作夥伴帳戶經理進行更多詳細信息討論。	",
    get_pro_title: "	獲取您的職業夥伴徽章！	",
    get_pro_detail:
      "	通過我們的官方徽章在您的網站上展示您的IUX市場合作夥伴關係，並突出您與世界第一經紀人的隸屬關係。	",
    partner_des3:
      "	我們的營銷分支機構是我們成功的組成部分，我們將其表現視為自己的表現。因此，我們開發了一個競爭模型，該模型可以正確地授予分支機構，同時為他們提供監視潛在客戶轉換，第一帳戶存款和客戶行為的工具。通過我們的會員門戶，您可以訪問橫幅，教育材料等。	",
    auto_rebate_title: "	自動雷管系統	",
    auto_rebate_1: "	會員委員會	",
    auto_rebate_2: "	Iuxmarkets會員	",
    auto_rebate_3: "	自動折扣	",
    auto_rebate_4: "	客戶	",
    auto_rebate_detail1: "	通過授予回扣輕鬆吸引客戶	",
    auto_rebate_detail2: "	調整給每個客戶的回扣百分比	",
    auto_rebate_detail3: "	安排自動客戶回扣	",
    auto_rebate_detail4: "	選擇手動控制回扣	",
    auto_rebate_detail5: "	依靠我們進行您的後台操作	",
    why_iux_title: "	為什麼IUX市場？	",
    why_iux_detail1:
      "您的成功就是我們的成功。我們介紹的會員計劃是針對您的業務增長量身定制的。 IUX Markets是市場上值得信賴的領導者，其使命是為交易者提供最低價差的使命",
    why_iux_detail2:
      "	我們很自豪地提供世界上最好的交易技術，以確保交易者的經驗無與倫比的績效和交易條件。隨著我們偏愛的合作夥伴的成功，我們努力提供全面的支持以取得更多的成就。	",
    how_work_title: "	怎麼運行的	",
    how_work_detail1:
      "	我們將在一個工作日內查看您的應用程序，並在一個工作日內與您聯繫。通過遵循我們的簡單申請流程來加入我們的會員合作夥伴	",
    how_work_detail2:
      "	註冊後，我們的一名團隊成員將與您聯繫並指導您完成如何開始推薦的過程。	",
    how_work_detail3: "	不用擔心！我們將在您的成長過程中更新和支持您。	",
    how_work_detail4:
      "	在審查了您的推薦客戶端后，獎勵將自動出現在您的錢包中（僅適用於合格的客戶）	",
  },
  vi: {
    partner_header_introducing: "	Giới thiệu môi giới	",
    partner_des:
      "	Chương trình IB của chúng tôi được phát triển với trọng tâm là quan hệ đối tác. Cho dù bạn là một cá nhân duy nhất hay một công ty có hàng chục hoặc hàng trăm nhân viên, chúng tôi hiểu được nhu cầu và yêu cầu kinh doanh của bạn để tăng thu nhập của bạn. Ngoài giải pháp cổng thông tin IB đa năng và một nhóm các nhà quản lý hợp tác sẵn sàng hỗ trợ bạn và khách hàng của bạn, chương trình IB của chúng tôi cung cấp một số khả năng giảm giá và bồi thường.	",
    title_ourib: "	Cổng IB của chúng tôi có các chức năng sau:	",
    function_detail_1: "	Hoa hồng thời gian thực	",
    function_detail_2: "	Cấu trúc IB đa cấp	",
    function_detail_3: "	Cài đặt hoa hồng linh hoạt	",
    function_detail_4: "	Tiền gửi và rút tiền dễ dàng và nhanh chóng	",
    function_detail_5: "	Phân cấp mối quan hệ IB	",
    function_detail_6: "	Báo cáo hoạt động của khách hàng	",
    partner_des2:
      "	Các chi nhánh tiếp thị của chúng tôi là một phần không thể thiếu trong thành công của chúng tôi và chúng tôi xem hiệu suất của họ là của riêng chúng tôi. Do đó, chúng tôi đã phát triển một mô hình cạnh tranh giúp trả thù lao đúng với các chi nhánh trong khi cung cấp cho họ các công cụ để giám sát chuyển đổi dẫn, tiền gửi tài khoản đầu tiên và hành vi của khách hàng. Thông qua cổng thông tin liên kết của chúng tôi, bạn có thể truy cập các biểu ngữ, tài liệu giáo dục, v.v.	",
    partner_help_1: "	Định hướng khách hàng	",
    partner_help_2: "	Tiếp thị được tối ưu hóa	",
    partner_help_3: "	Dữ liệu và phân tích 24/7	",
    offer_title: "	Những gì chúng tôi cung cấp	",
    offer_title_box1: "	Giới thiệu chương trình môi giới	",
    offer_subtitle_box1: "	Bạn nhận được tới 40% doanh thu của chúng tôi	",
    offer_detail_box1:
      "	Kiếm tới 40% doanh thu của chúng tôi từ mọi nhà giao dịch đang hoạt động mà bạn đề cập đến chúng tôi. Nếu họ giao dịch, bạn sẽ kiếm được.	",
    offer_title_box2: "	Chương trình liên kết	",
    offer_subtitle_box2: "	Bạn nhận được tới $ 1770 cho mỗi khách hàng	",
    offer_detail_box2:
      "	Chúng tôi đang dẫn đầu ngành công nghiệp với các khoản thanh toán theo lịch trình và hấp dẫn. Chúng tôi cung cấp một loạt các kế hoạch hoa hồng, vì vậy bạn có thể chọn phù hợp nhất cho bạn.	",
    offer_note:
      "	Chúng tôi cũng cung cấp các chương trình hợp tác tùy chỉnh khác có thể được thảo luận chi tiết hơn với người quản lý tài khoản đối tác của chúng tôi.	",
    get_pro_title: "	Nhận huy hiệu đối tác chuyên nghiệp của bạn!	",
    get_pro_detail:
      "	Hiển thị quan hệ đối tác của IUX Market trên trang web của bạn với huy hiệu chính thức của chúng tôi và nêu bật sự liên kết của bạn với nhà môi giới số 1 thế giới.	",
    partner_des3:
      "	Các chi nhánh tiếp thị của chúng tôi là một phần không thể thiếu trong thành công của chúng tôi và chúng tôi xem hiệu suất của họ là của riêng chúng tôi. Do đó, chúng tôi đã phát triển một mô hình cạnh tranh giúp trả thù lao đúng với các chi nhánh trong khi cung cấp cho họ các công cụ để giám sát chuyển đổi dẫn, tiền gửi tài khoản đầu tiên và hành vi của khách hàng. Thông qua cổng thông tin liên kết của chúng tôi, bạn có thể truy cập các biểu ngữ, tài liệu giáo dục, v.v.	",
    auto_rebate_title: "	Hệ thống tự động phát triển	",
    auto_rebate_1: "	Liên kết comsions	",
    auto_rebate_2: "	IUXMarkets Chi nhánh	",
    auto_rebate_3: "	Giảm giá tự động	",
    auto_rebate_4: "	Khách hàng	",
    auto_rebate_detail1:
      "	Dễ dàng thu hút khách hàng bằng cách thưởng tiền giảm giá	",
    auto_rebate_detail2:
      "	Điều chỉnh tỷ lệ phần trăm giảm giá được cung cấp cho mỗi khách hàng	",
    auto_rebate_detail3: "	Lên lịch giảm giá khách hàng tự động	",
    auto_rebate_detail4: "	Chọn kiểm soát giảm giá bằng tay	",
    auto_rebate_detail5:
      "	Dựa vào chúng tôi cho các hoạt động văn phòng của bạn	",
    why_iux_title: "	Tại sao iux thị trường?	",
    why_iux_detail1:
      "Thành công của bạn là thành công của chúng tôi. Chương trình liên kết giới thiệu của chúng tôi phù hợp với sự tăng trưởng kinh doanh của bạn. IUX Markets là một nhà lãnh đạo đáng tin cậy trên thị trường với sứ mệnh cung cấp cho các nhà giao dịch mức lây lan thấp nhất bất kể điều kiện thị trường",
    why_iux_detail2:
      "	Chúng tôi tự hào cung cấp công nghệ giao dịch tốt nhất thế giới để đảm bảo các nhà giao dịch có kinh nghiệm về hiệu suất và điều kiện giao dịch vô song. Với các đối tác ưa thích của chúng tôi thành công vững chắc trong trọng tâm, chúng tôi cố gắng cung cấp hỗ trợ toàn diện để đạt được nhiều hơn.	",
    how_work_title: "	LÀM THẾ NÀO NÓ HOẠT ĐỘNG	",
    how_work_detail1:
      "	Chúng tôi sẽ xem lại ứng dụng của bạn và liên hệ lại với bạn trong vòng một ngày làm việc. Tham gia đối tác liên kết của chúng tôi bằng cách làm theo quy trình đăng ký đơn giản của chúng tôi	",
    how_work_detail2:
      "	Sau khi đăng ký, một trong những thành viên trong nhóm của chúng tôi sẽ liên hệ và hướng dẫn bạn trong quá trình về cách bắt đầu giới thiệu của bạn.	",
    how_work_detail3:
      "	Đừng lo lắng! Chúng tôi sẽ cập nhật và hỗ trợ bạn trong suốt hành trình tăng trưởng của bạn.	",
    how_work_detail4:
      "	Sau khi hoàn thành việc xem xét các máy khách được giới thiệu của bạn, phần thưởng sẽ tự động xuất hiện trong ví của bạn (chỉ dành cho khách hàng đủ điều kiện)	",
  },
  ko: {
    partner_header_introducing: "	중개인 소개	",
    partner_des:
      "	우리의 IB 프로그램은 파트너십에 중점을두고 개발되었습니다. 귀하가 단일 개인이든 수십 개 또는 수백 명의 직원이든, 당사는 귀하의 수입을 늘리기위한 비즈니스 요구와 요구 사항을 이해합니다. 다재다능한 IB 포털 솔루션 및 귀하와 고객을 지원할 준비가 된 파트너십 관리자 팀 외에도 IB 프로그램은 여러 리베이트 및 보상 가능성을 제공합니다.	",
    title_ourib: "	우리의 IB 포털에는 다음과 같은 기능이 있습니다.	",
    function_detail_1: "	실시간 커미션	",
    function_detail_2: "	다단계 IB 구조	",
    function_detail_3: "	유연한 커미션 설정	",
    function_detail_4: "	쉽고 신속하게 입금 및 철수	",
    function_detail_5: "	IB 관계 계층	",
    function_detail_6: "	클라이언트 활동 보고서	",
    partner_des2:
      "	우리의 마케팅 계열사는 성공의 필수 요소이며 우리는 그들의 성능을 우리 자신의 것으로 간주합니다. 따라서 우리는 제휴사를 제대로 보수하는 동시에 리드 전환, 첫 번째 계정 예금 및 고객 행동을 모니터링하는 도구를 제공하는 경쟁 모델을 개발했습니다. 우리의 제휴 포털을 통해 배너, 교육 자료 등에 액세스 할 수 있습니다.	",
    partner_help_1: "	클라이언트 지향	",
    partner_help_2: "	최적화 된 마케팅	",
    partner_help_3: "	24/7 데이터 및 분석	",
    offer_title: "	우리가 제공하는 것	",
    offer_title_box1: "	중개인 프로그램 소개	",
    offer_subtitle_box1: "	당신은 우리의 수익의 최대 40%를 얻습니다	",
    offer_detail_box1:
      "	귀하가 참조하는 모든 활동 상인으로부터 수익의 최대 40%를 얻습니다. 그들이 거래하는 경우, 당신은 벌고 있습니다.	",
    offer_title_box2: "	제휴 프로그램	",
    offer_subtitle_box2: "	모든 고객에 대해 최대 $ 1770를 얻습니다	",
    offer_detail_box2:
      "	우리는 예약되고 매력적인 지불금으로 업계를 이끌고 있습니다. 우리는 다양한 커미션 계획을 제공하므로 귀하에게 가장 적합한 것을 선택할 수 있습니다.	",
    offer_note:
      "	또한 파트너 계정 관리자와 자세한 내용으로 논의 할 수있는 다른 맞춤형 파트너십 체계도 제공합니다.	",
    get_pro_title: "	프로 파트너 배지를 얻으십시오!	",
    get_pro_detail:
      "	공식 배지로 웹 사이트에서 IUX 시장 파트너십을 보여주고 세계 #1 브로커와의 제휴를 강조하십시오.	",
    partner_des3:
      "	우리의 마케팅 계열사는 성공의 필수 요소이며 우리는 그들의 성능을 우리 자신의 것으로 간주합니다. 따라서 우리는 제휴사를 제대로 보수하는 동시에 리드 전환, 첫 번째 계정 예금 및 고객 행동을 모니터링하는 도구를 제공하는 경쟁 모델을 개발했습니다. 우리의 제휴 포털을 통해 배너, 교육 자료 등에 액세스 할 수 있습니다.	",
    auto_rebate_title: "	자동 회복 시스템	",
    auto_rebate_1: "	제휴사 동반	",
    auto_rebate_2: "	iuxmarkets 계열사	",
    auto_rebate_3: "	자동 리베이트	",
    auto_rebate_4: "	고객	",
    auto_rebate_detail1: "	리베이트를 수여하여 고객을 쉽게 유치합니다	",
    auto_rebate_detail2:
      "	각 클라이언트에게 주어진 리베이트 백분율을 조정하십시오	",
    auto_rebate_detail3: "	자동 클라이언트 리베이트를 예약하십시오	",
    auto_rebate_detail4: "	리베이트를 수동으로 제어하도록 선택하십시오	",
    auto_rebate_detail5: "	백 오피스 운영에 우리에게 의존하십시오	",
    why_iux_title: "	왜 IUX 시장?	",
    why_iux_detail1:
      "당신의 성공은 우리의 성공입니다. 소개 제휴 프로그램은 비즈니스 성장에 맞게 조정됩니다. IUX Markets는 시장에서 신뢰할 수있는 리더입니다.",
    why_iux_detail2:
      "	우리는 트레이더의 경험이 다루지 않은 성과와 거래 조건을 보장하기 위해 세계 최고의 거래 기술을 제공하게 된 것을 자랑스럽게 생각합니다. 우리가 선호하는 파트너의 성공에 중점을 두면서 우리는 더 많은 것을 달성하기 위해 포괄적 인 지원을 제공하기 위해 노력합니다.	",
    how_work_title: "	작동 방식	",
    how_work_detail1:
      "	우리는 귀하의 신청서를 검토하고 영업일 기준 1 일 이내에 다시 연락 할 것입니다. 간단한 신청 프로세스를 따르면 계열사 파트너 가입	",
    how_work_detail2:
      "	가입 후 팀원 중 한 명이 연락하여 추천을 시작하는 방법에 대한 프로세스를 안내합니다.	",
    how_work_detail3:
      "	괜찮아요! 우리는 당신의 성장 여정을 따라 당신을 업데이트하고 지원할 것입니다.	",
    how_work_detail4:
      "	추천 클라이언트 검토를 마치면 보상이 지갑에 자동으로 나타납니다 (적격 클라이언트에만 해당)	",
  },
  fil: {
    partner_header_introducing: "	Ipinakikilala ang broker	",
    partner_des:
      "	Ang aming programa sa IB ay binuo na may pagtuon sa mga pakikipagsosyo. Kung ikaw ay isang solong indibidwal o isang korporasyon na may mga sampu o daan -daang kawani, naiintindihan namin ang iyong mga kahilingan sa negosyo at mga kinakailangan upang madagdagan ang iyong mga kita. Bilang karagdagan sa isang maraming nalalaman na solusyon sa portal ng IB at isang koponan ng mga tagapamahala ng pakikipagtulungan na handa na tulungan ka at ang iyong mga kliyente, ang aming programa sa IB ay nag -aalok ng isang bilang ng mga posibilidad ng rebate at kabayaran.	",
    title_ourib: "	Ang aming portal ng IB ay may mga sumusunod na pag -andar:	",
    function_detail_1: "	Mga Komisyon sa Tunay na Oras	",
    function_detail_2: "	Multi-level na istraktura ng IB	",
    function_detail_3: "	Flexible Setting ng Komisyon	",
    function_detail_4: "	Madali at mabilis na deposito at pag -atras	",
    function_detail_5: "	IB relasyon hierarchy	",
    function_detail_6: "	Ulat sa Aktibidad ng Kliyente	",
    partner_des2:
      "	Ang aming mga kaakibat sa marketing ay isang mahalagang bahagi ng aming tagumpay at tiningnan namin ang kanilang pagganap bilang aming sarili. Samakatuwid, nakabuo kami ng isang mapagkumpitensyang modelo na maayos ang mga kaakibat ng mga kaakibat habang binibigyan sila ng mga tool upang masubaybayan ang mga pagbabagong tingga, mga deposito ng unang account, at pag -uugali ng kliyente. Sa pamamagitan ng aming portal ng kaakibat, maaari mong ma -access ang mga banner, materyal na pang -edukasyon, at marami pa.	",
    partner_help_1: "	Nakatuon sa kliyente	",
    partner_help_2: "	Na -optimize na marketing	",
    partner_help_3: "	24/7 data at analytics	",
    offer_title: "	Kung ano ang inaalok namin	",
    offer_title_box1: "	Ipinakikilala ang programa ng broker	",
    offer_subtitle_box1: "	Makakakuha ka ng hanggang sa 40% ng aming kita	",
    offer_detail_box1:
      "	Kumita ng hanggang sa 40% ng aming kita mula sa bawat aktibong negosyante na tinutukoy mo sa amin. Kung nangangalakal sila, kumikita ka.	",
    offer_title_box2: "	Programang kaakibat	",
    offer_subtitle_box2:
      "	Makakakuha ka ng hanggang sa $ 1770 para sa bawat kliyente	",
    offer_detail_box2:
      "	Pinangunahan namin ang industriya na may naka -iskedyul at kaakit -akit na payout. Nag -aalok kami ng isang hanay ng mga plano ng komisyon, kaya maaari mong piliin ang pinakamahusay na akma para sa iyo.	",
    offer_note:
      "	Nag -aalok din kami ng iba pang mga pasadyang mga scheme ng pakikipagtulungan na maaaring talakayin sa higit pang mga detalye sa aming mga tagapamahala ng account sa kasosyo.	",
    get_pro_title: "	Kunin ang iyong pro partner badge!	",
    get_pro_detail:
      "	Ipakita ang iyong pakikipagtulungan ng IUX Markets sa iyong website gamit ang aming opisyal na badge at i -highlight ang iyong pakikipag -ugnay sa #1 broker ng mundo.	",
    partner_des3:
      "	Ang aming mga kaakibat sa marketing ay isang mahalagang bahagi ng aming tagumpay at tiningnan namin ang kanilang pagganap bilang aming sarili. Samakatuwid, nakabuo kami ng isang mapagkumpitensyang modelo na maayos ang mga kaakibat ng mga kaakibat habang binibigyan sila ng mga tool upang masubaybayan ang mga pagbabagong tingga, mga deposito ng unang account, at pag -uugali ng kliyente. Sa pamamagitan ng aming portal ng kaakibat, maaari mong ma -access ang mga banner, materyal na pang -edukasyon, at marami pa.	",
    auto_rebate_title: "	Auto-Rebate System	",
    auto_rebate_1: "	Kaakibat na komisyon	",
    auto_rebate_2: "	Ang kaakibat ng Iuxmarkets	",
    auto_rebate_3: "	Auto rebate	",
    auto_rebate_4: "	Kliyente	",
    auto_rebate_detail1:
      "	Madaling maakit ang mga kliyente sa pamamagitan ng pagbibigay ng mga rebate	",
    auto_rebate_detail2:
      "	Ayusin ang porsyento ng rebate na ibinigay sa bawat kliyente	",
    auto_rebate_detail3: "	Mag -iskedyul ng mga awtomatikong rebate ng kliyente	",
    auto_rebate_detail4:
      "	Piliin upang makontrol nang manu -mano ang mga rebate	",
    auto_rebate_detail5:
      "	Umaasa sa amin para sa iyong mga operasyon sa likod ng tanggapan	",
    why_iux_title: "	Bakit Iux Markets?	",
    why_iux_detail1:
      "Ang iyong tagumpay ay ang aming tagumpay. Ang aming pagpapakilala ng kaakibat na programa ay naayon sa iyong paglago ng negosyo. Ang Iux Markets ay isang mapagkakatiwalaang pinuno sa merkado kasama ang misyon upang mabigyan ang mga negosyante ng pinakamababang pagkalat anuman ang mga kondisyon ng merkado",
    why_iux_detail2:
      "	Ipinagmamalaki naming mag -alok ng pinakamahusay na teknolohiya sa pangangalakal ng mundo upang matiyak ang mga karanasan ng mga negosyante na walang kapantay na pagganap at mga kondisyon sa pangangalakal. Sa aming ginustong tagumpay ng tagumpay na mahigpit na nakatuon, nagsusumikap kaming mag -alok ng komprehensibong suporta upang makamit ang higit pa.	",
    how_work_title: "	Paano ito gumagana	",
    how_work_detail1:
      "	Susuriin namin ang iyong aplikasyon at babalik sa iyo sa loob ng isang araw ng negosyo. Sumali sa aming kasosyo sa kaakibat sa pamamagitan ng pagsunod sa aming simpleng proseso ng aplikasyon	",
    how_work_detail2:
      "	Matapos mag -sign up, ang isa sa mga miyembro ng aming koponan ay makikipag -ugnay at gagabayan ka sa proseso kung paano simulan ang iyong mga referral.	",
    how_work_detail3:
      "	Huwag kang mag -alala! I -update at susuportahan ka namin kasama ang iyong paglalakbay sa paglago.	",
    how_work_detail4:
      "	Matapos tapusin ang pagsusuri sa iyong mga tinukoy na kliyente, ang mga gantimpala ay awtomatikong lilitaw sa iyong pitaka (para lamang sa mga karapat -dapat na kliyente)	",
  },
  ja: {
    partner_header_introducing: "ブローカーの紹介",
    partner_des:
      "IBプログラムは、パートナーシップに焦点を当てて開発されました。 あなたが単一の個人であろうと、数十人のスタッフを持つ企業であろうと、私たちはあなたのビジネスの要求と要件をあなたの収入を増やすことを理解しています。 汎用性の高いIBポータルソリューションと、お客様とお客様のクライアントを支援する準備ができているパートナーシップマネージャーのチームに加えて、IBプログラムは多くのリベートと補償の可能性を提供します。",
    title_ourib: "IBポータルには次の機能があります。",
    function_detail_1: "リアルタイムコミッション",
    function_detail_2: "マルチレベルIB構造",
    function_detail_3: "柔軟なコミッションの設定",
    function_detail_4: "簡単で迅速な預金と撤退",
    function_detail_5: "IB関係階層",
    function_detail_6: "クライアントアクティビティレポート",
    partner_des2:
      "私たちのマーケティングアフィリエイトは私たちの成功の不可欠な部分であり、私たちは彼らのパフォーマンスを私たち自身のものと見なしています。 したがって、リードコンバージョン、最初のアカウント預金、クライアントの動作を監視するためのツールを提供しながら、アフィリエイトに適切に報酬を与える競合モデルを開発しました。 アフィリエイトポータルを通じて、バナー、教育資料などにアクセスできます。",
    partner_help_1: "クライアント指向",
    partner_help_2: "最適化されたマーケティング",
    partner_help_3: "24時間年中無休のデータと分析",
    offer_title: "私たちが提供するもの",
    offer_title_box1: "ブローカープログラムの紹介",
    offer_subtitle_box1: "あなたは私たちの収益の最大40％を獲得します",
    offer_detail_box1:
      "あなたが私たちに紹介するすべてのアクティブなトレーダーから当社の収益の最大40％を稼ぎます。 彼らが取引している場合、あなたは稼いでいます。",
    offer_title_box2: "アフィリエイトプログラム",
    offer_subtitle_box2: "クライアントごとに最大1770ドルを獲得します",
    offer_detail_box2:
      "私たちは、予定された魅力的な支払いで業界をリードしています。 さまざまな手数料プランを提供しているため、最適なものを選択できます。",
    offer_note:
      "また、パートナーアカウントマネージャーと詳細に説明できる他のカスタマイズされたパートナーシップスキームも提供しています。",
    get_pro_title: "プロパートナーのバッジを入手してください！",
    get_pro_detail:
      "私たちの公式バッジであなたのウェブサイトでIUX Marketsパートナーシップを紹介し、世界の第1ブローカーとの提携を強調してください。",
    partner_des3:
      "私たちのマーケティングアフィリエイトは私たちの成功の不可欠な部分であり、私たちは彼らのパフォーマンスを私たち自身のものと見なしています。 したがって、リードコンバージョン、最初のアカウント預金、クライアントの動作を監視するためのツールを提供しながら、アフィリエイトに適切に報酬を与える競合モデルを開発しました。 アフィリエイトポータルを通じて、バナー、教育資料などにアクセスできます。",
    auto_rebate_title: "自動回復システム",
    auto_rebate_1: "アフィリエイト委員会",
    auto_rebate_2: "iuxMarketsのアフィリエイト",
    auto_rebate_3: "オートリベート",
    auto_rebate_4: "クライアント",
    auto_rebate_detail1:
      "リベートを授与することで、簡単にクライアントを引き付けます",
    auto_rebate_detail2: "各クライアントに与えられたリベートの割合を調整します",
    auto_rebate_detail3: "自動クライアントリベートをスケジュールします",
    auto_rebate_detail4: "リベートを手動で制御することを選択します",
    auto_rebate_detail5: "あなたのバックオフィスの運用を私たちに頼ってください",
    why_iux_title: "なぜIUX市場？",
    why_iux_detail1:
      "あなたの成功は私たちの成功です。 紹介するアフィリエイトプログラムは、ビジネスの成長に合わせて調整されています。 IUX Marketsは、市場の状況に関係なく、トレーダーに最低の広がりを提供するという使命を備えた市場の信頼できるリーダーです。",
    why_iux_detail2:
      "トレーダーの経験と取引条件の経験を確保するために、世界最高の取引技術を提供できることを誇りに思っています。 優先パートナーがしっかりと焦点を合わせて成功したことで、私たちはより多くのことを達成するために包括的なサポートを提供するよう努めています。",
    how_work_title: "使い方",
    how_work_detail1:
      "お客様のアプリケーションを確認し、1営業日以内にご連絡いたします。 アフィリエイトパートナーに参加して、簡単な申請プロセスをフォローしてください",
    how_work_detail2:
      "サインアップ後、チームメンバーの1人が紹介を開始する方法についてのプロセスについて連絡してガイドします。",
    how_work_detail3:
      "心配しないで！ 私たちはあなたの成長の旅に沿ってあなたを更新してサポートします。",
    how_work_detail4:
      "紹介されたクライアントのレビューを終えた後、報酬は自動的にウォレットに表示されます（対象となるクライアントのみ）",
  },
};
