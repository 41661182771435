import React from "react";
import RcQueueAnim from "rc-queue-anim";
import { Row, Col } from "antd";

const locale = require("react-redux-i18n").I18n;

const Aboutus = (props) => {
  return (
    <RcQueueAnim>
      <div className="pl-2 pr-2 pl-sm-5 pl-sm-5">
        <div className=" container-text-center mx-auto">
          <div>
            <div>
              <h2 className="text-white text-center mb-5 ">
                {locale.t("aboutPage.section_1.title_1")}
              </h2>
              <p className="text-white text-center">
                {locale.t("aboutPage.section_1.description_1")}
              </p>
            </div>
          </div>
        </div>
        <div style={{ margin: "4rem 0" }} className="bg-line ">
          <div className="container">
            <div className="m-0 pt-4 pb-4 row justify-content-md-center">
              <div className="col-12 col-sm-12 col-md-6 col-lg-4  mt-4 mt-md-4 mt-lg-0">
                <div className="card-iux-about-page ">
                  <h5 className="text-primary-new-iux text-center">
                    {locale.t("aboutPage.section_1.title_5")}
                  </h5>
                  <p className="text-white mt-4">
                    {locale.t("aboutPage.section_1.description_5")}
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 mt-4 mt-md-4 mt-lg-0">
                <div className="card-iux-about-page ">
                  <h5 className="text-primary-new-iux text-center">
                    {locale.t("aboutPage.section_1.title_3")}
                  </h5>
                  <p className="text-white mt-4">
                    {locale.t("aboutPage.section_1.description_3")}
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 mt-4 mt-md-4 mt-lg-0">
                <div className="card-iux-about-page">
                  <h5 className="text-primary-new-iux text-center">
                    {locale.t("aboutPage.section_2.title")}
                  </h5>
                  <p className="text-white mt-4">
                    {locale.t("aboutPage.section_1.description_4")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container">
          <h2 className="text-white text-center mb-5 font-weight-bold">
            {locale.t("aboutPage.section_3.sub_head_title_1")}
          </h2>
          <div className="d-flex justify-content-center">
            <img
              src="/assets/images/location/map.png"
              alt="location"
              className="img-location-aboutus "
            />
          </div>
        </div> */}
      </div>
    </RcQueueAnim>
  );
};

export default Aboutus;
