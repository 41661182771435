import React, { useEffect, useState } from "react";
import { getAccountBenefit } from "../../../../api/benefit";
import { fCurrencySymbol } from "../../../../helper/formatNumber";
import moment from "moment";
import { Col, Divider, Grid, Row } from "antd";
const locale = require("react-redux-i18n").I18n;

function NegativeBalanceProtection() {
  const [benefit, setBenefit] = useState();

  const getBenefit = async () => {
    const data = await getAccountBenefit();
    setBenefit(data.data.data);
  };

  useEffect(() => {
    getBenefit();
  }, []);

  return (
    <div>
      <div className=" p-3">
        <Row className="nbp-bg-swap-status">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={11}
            className="nbp-bg-balance-protection p-4"
          >
            <h6 className="fw-700">{locale.t("stopOut.title")}</h6>
            <div className="d-flex justify-center mt-3">
              {" "}
              <div className="box-nbp">
                <div>
                  <p className="text-center text-neutral-grey fs-12">
                    We’ve covered you
                  </p>
                  <p className="text-center fs-20 fw-700 mtc-12">
                    {fCurrencySymbol(
                      benefit?.negative_balance_protection.balance.toFixed(2)
                    )}
                  </p>
                  <p className="text-center text-neutral-grey fs-12 mt-2">
                    USD
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} lg={2}>
            <div className="d-flex d-lg-none">
              <Divider
                className="h-100 text-white"
                style={{ borderColor: "white" }}
              />
            </div>
            <div className=" justify-center h-100 d-none d-lg-flex">
              <Divider
                type="vertical"
                className="h-100 text-white"
                style={{ borderColor: "white" }}
              />
            </div>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={11}
            className=" nbp-bg-balance-recovertime"
          >
            <div className="w-100">
              <h6 className="fw-700 px-4 pt-4">
                Recover Times ({benefit?.negative_balance_protection.count})
              </h6>
              <div className="d-flex justify-content-between mtc-12 px-4">
                <div className="text-neutral-grey">
                  {locale.t("dashboardInnerTransfer.date")}
                </div>
                <div className="fw-700">
                  {locale.t("deposit_step.second_step.description")}
                </div>
              </div>
              <div
                style={{
                  maxHeight: 144,
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
                className="px-4 custom-scrollbar-recovertime pb-4"
              >
                {benefit?.negative_balance_protection?.deals?.length > 0 ? (
                  benefit?.negative_balance_protection?.deals
                    ?.sort((a, b) => new Date(b.date) - new Date(a.date))
                    .map((deal, index) => (
                      <div
                        className="d-flex justify-content-between mt-2"
                        key={index + "recover"}
                      >
                        <div className="text-neutral-grey">
                          {moment(deal.date).format("YYYY-MM-DD")}
                        </div>
                        <div className="fw-700">
                          {deal?.profit?.toFixed(2)} USD
                        </div>
                      </div>
                    ))
                ) : (
                  <div className="d-flex justify-center align-items-center mt-4">
                    <img src="/assets/images/benefits/negative/nodata.png" />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
        {/* <div className="row " style={{ gap: "2px", flexWrap: "nowrap" }}>
          <div className="col-12 col-lg-6 px-2 nbp-bg-balance-protection p-4">
            <h6 className="fw-700">{locale.t("stopOut.title")}</h6>
            <div className="d-flex justify-center mt-3">
              {" "}
              <div className="box-nbp">
                <div>
                  <p className="text-center text-neutral-grey fs-12">
                    We’ve covered you
                  </p>
                  <p className="text-center fs-20 fw-700 mtc-12">
                    {fCurrencySymbol(
                      benefit?.negative_balance_protection.balance.toFixed(2)
                    )}
                  </p>
                  <p className="text-center text-neutral-grey fs-12 mt-2">
                    USD
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 p-0  nbp-bg-balance-recovertime py-4"></div>
        </div> */}
        <div className="my-4">
          <p className="fw-700">
            {/* Advantage of Negative Balance Protection{" "} */}
            {locale.t("negative.advantageNegativeBalanceProtection")}{" "}
          </p>
          <p className="fs-12 mtc-12">
            {locale.t("negative.worryForBeginners")}{" "}
          </p>
        </div>
        <Row>
          <Col xs={24} sm={24} md={24} lg={11}>
            <div className="fw-700 text-center">IUX</div>
            <div className="d-flex justify-center">
              <img
                src={`/assets/images/benefits/negative/negative-balance/${
                  localStorage.getItem("locale") !== "es" &&
                  localStorage.getItem("locale") !== "zh"
                    ? localStorage.getItem("locale")
                    : "en"
                }/nbp-1.png`}
                width={"100%"}
              />
            </div>
          </Col>
          <Col xs={24} lg={2}>
            <div className="d-flex d-lg-none">
              <Divider
                className="h-100 text-white"
                style={{ borderColor: "#a3a3a3" }}
              />
            </div>
            <div className=" justify-center h-100 d-none d-lg-flex">
              {" "}
              <Divider
                type="vertical"
                className="h-auto text-neutral-grey"
                style={{ borderColor: "#a3a3a3" }}
              />
            </div>
          </Col>

          <Col xs={24} sm={24} md={24} lg={11}>
            <div className="fw-700 text-center">Other</div>
            <div className="d-flex justify-center">
              <img
                src={`/assets/images/benefits/negative/negative-balance/${
                  localStorage.getItem("locale") !== "es" &&
                  localStorage.getItem("locale") !== "zh"
                    ? localStorage.getItem("locale")
                    : "en"
                }/nbp-2.png`}
                width={"100%"}
              />
            </div>
          </Col>
        </Row>
        <p className="fs-12 mt-4">{locale.t("negative.exampleExplanation")}</p>
        {/* <div className="d-flex justify-content-between mt-3">
          <div>{locale.t("stopOut.resetBalance")}</div>
          <div>
            {fCurrencySymbol(benefit?.negative_balance_protection.balance)} USD
          </div>
        </div>
        <div className="d-flex justify-content-between mt-2">
          <div>{locale.t("stopOut.broken")}</div>
          <div>
            {benefit?.negative_balance_protection.count}{" "}
            {locale.t("stopOut.brokenTimes")}
          </div>
        </div>
        <div className="mt-4 fs-12 text-lightgray">
          {locale.t("stopOut.des1")}
        </div>
        <div className="mt-3 fs-12 text-lightgray">
          {locale.t("stopOut.des2")}
        </div> */}
      </div>
    </div>
  );
}

export default NegativeBalanceProtection;
