import { MessageOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

function FooterTFN() {
  useEffect(() => {
    // window.addEventListener("load", (event) => {
    loadWidget();
    // });

    // window?.addEventListener("load", (event) => loadWidget());
    // // Remove the event listener when component unmounts
    // return () =>
    //   window?.removeEventListener("load", (event) => {
    //     console.log("unmountload");
    //   });
  }, []);

  function loadWidget() {
    try {
      var dom = document.getElementsByClassName("trustfinance-widget");

      if (!dom["0"].dataset.templateId || !dom["0"].dataset.companyId) return;
      var uri = `https://widget.trustfinance.com/widgets?templateId=${
        dom["0"].dataset.templateId
      }&businessId=${dom["0"].dataset.companyId}&theme=${
        dom["0"].dataset.theme === "dark" ? "dark" : "light"
      }`;
      var htmlString = `<iframe src="${uri}"></iframe>`;
      if (typeof dom["0"] !== undefined) {
        var wdg = new DOMParser().parseFromString(htmlString, "text/html");
        var tf = wdg.body.children[0];
        tf.setAttribute(
          "style",
          `position: relative; border-style: none; display: block; overflow: hidden; width:${
            dom["0"].dataset.styleWidth ?? "inherit"
          }; height:${dom["0"].dataset.styleHeight ?? "inherit"}`
        );

        dom["0"].appendChild(tf);
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      {/* <div
        className="position-fixed d-flex d-md-none"
        style={{ bottom: 88, right: 16 }}
      >
        <Link to={`/${localStorage.getItem("locale")}/register`}>
          <button className="btn-primary-new-iux w-100 fs-footer-widget ">
            Open an Account
          </button>
        </Link>
        <Link
          to={`/${localStorage.getItem("locale")}/help`}
          // className="d-block d-md-none position-absolute"
        >
          <button className="btn-outline-iux  w-100 ml-2 fs-footer-widget bg-white">
            <MessageOutlined /> 24/7 Support
          </button>
        </Link>
      </div> */}
      <div className=" footer-contact">
        <div className="container row px-1 ">
          <div className="col-9 col-md-8 px-0  m-auto">
            <div
              className="trustfinance-widget "
              data-template-id={"8"}
              data-company-id="iux-markets"
              data-style-height={"50px"}
              data-style-width="100%"
            />
            {/* <div
              class="trustpilot-widget"
              data-locale="en-US"
              data-template-id="5419b6a8b0d04a076446a9ad"
              data-businessunit-id="637490ec7a94ae1d104bde22"
              data-style-height="24px"
              data-style-width="100%"
              data-theme="light"
              data-min-review-count="10"
              data-style-alignment="center"
            >
              <a
                href="https://www.trustpilot.com/review/fxrebate.app"
                target="_blank"
                rel="noopener"
              >
                Trustpilot
              </a>
            </div> */}
          </div>

          <div className="col-3 col-md-4 d-flex  justify-content-center align-items-center  px-0">
            <Link to={`/${localStorage.getItem("locale")}/register`}>
              <button className="btn-primary-new-iux w-100 fs-footer-widget d-none d-md-block">
                Open an Account
              </button>
            </Link>
            <a
              href={`https://help.iuxmarkets.com/${localStorage.getItem(
                "locale"
              )}/`}
              className="d-none d-md-block"
            >
              <button className="btn-outline-iux  w-100 ml-2 fs-footer-widget">
                <MessageOutlined /> 24/7 Support
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default FooterTFN;
