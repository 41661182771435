import React from "react";
import RcQueueAnim from "rc-queue-anim";
import TextWithCover from "../../components/text-with-cover/TextWithCover";
import { Helmet } from "react-helmet";
import FooterLandingPage from "../../components/footer-landing-page/FooterDark";
import { Link, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import NonIslamic from "./nonIslamic";
import Islamic from "./islamic";
import FooterSwap from "../../components/footer-landing-page/FooterSwap";
const locale = require("react-redux-i18n").I18n;
const MenuItem = styled.div`
  padding: 0.8rem 1rem;
  color: ${(props) => props.color};
  border-bottom: ${(props) => props.borderBottom};
  white-space: pre;
  font-weight: ${(props) => props.fontWeight};
`;
function Swap() {
  const pathName = window.location.pathname;
  const [isIdex, setIndex] = React.useState(0);
  const menu = [
    {
      id: 0,
      link: `/${localStorage.getItem("locale")}/swap/non-islamic/Standard`,
      text: locale.t("tabPrimaryTitle"),
      path: "non-islamic",
    },
    {
      id: 1,
      link: `/${localStorage.getItem("locale")}/swap/islamic/Standard`,
      text: locale.t("tabSecondaryTitle"),
      path: "islamic",
    },
  ];
  return (
    <div>
      <RcQueueAnim delay={300}>
        <Helmet>
          <title>Spread & Swap</title>
        </Helmet>
        <div className="bg-black">
          <TextWithCover
            key={1}
            imagePath={
              process.env.PUBLIC_URL + "/assets/images/covers/cover-forex.jpg"
            }
            title={"Spread & Swap"}
            content={
              <>
                <div>
                  <p>{locale.t("descriptHead")}</p>
                  {/* {renderButtonCover()} */}
                </div>
              </>
            }
          />
          <div className="sub-menu-page">
            <div className="container">
              <div className="d-flex overflow-auto scroll-menu-hide">
                {menu?.map((e, i) => (
                  <Link to={e.link} key={i}>
                    <MenuItem
                      onClick={() => setIndex(i)}
                      color={
                        e.path === pathName.split("/")[3] ? "#FFF" : "#8D8D8D"
                      }
                      borderBottom={
                        e.path === pathName.split("/")[3]
                          ? "4px solid #1CB894"
                          : "2px solid #ff000000"
                      }
                      fontWeight={
                        e.path === pathName.split("/")[3] ? 400 : "unset"
                      }
                    >
                      {e.text}
                    </MenuItem>
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <Switch>
            <Route
              path="/:lang/swap/non-islamic"
              render={() => {
                return <NonIslamic />;
              }}
            />
            <Route
              path="/:lang/swap/islamic"
              render={() => {
                return <Islamic />;
              }}
            />
          </Switch>
          <div className="container">
            <div className="row text-white mt-5">
              <div className="col-12 col-md-6 text-white">
                <h4 className="text-white mb-3" style={{ fontWeight: 400 }}>
                  {locale.t("swapConditionsTitle")}
                </h4>
                <p>{locale.t("swapConditionsDescription")}</p>
              </div>
              <div className="col-12 col-md-6 text-white">
                <h4 className="text-white mb-3" style={{ fontWeight: 400 }}>
                  {locale.t("spreadConditionsTitle")}
                </h4>
                <p>{locale.t("spreadConditionsDescription")}</p>
              </div>
            </div>
          </div>
          <div>
            <FooterSwap locale={locale} />
          </div>
        </div>
      </RcQueueAnim>
    </div>
  );
}

export default Swap;
