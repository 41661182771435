export default {
  th: {
    register_webinar: {
      title: "เข้าร่วมการสัมมนาผ่านเว็บของเรา!",
      des: "เข้าถึง IUX Webinar สุดพิเศษ",
      btn_register: "ลงทะเบียน",
      btn_not: "ไม่ใช่ตอนนี้",
    },
  },
  lo: {
    register_webinar: {
      title: "ເຂົ້າຮ່ວມ Webinar ຂອງພວກເຮົາ!",
      des: "ເຂົ້າເຖິງ IUX Webinar ພິເສດຫຼາຍ",
      btn_register: "ລົງທະບຽນ",
      btn_not: "ບໍ່​ແມ່ນ​ຕອນ​ນີ້",
    },
  },
  en: {
    register_webinar: {
      title: "Join Our Webinar!",
      des: "Get access to exclusive IUX Webinar",
      btn_register: "Register",
      btn_not: "Not now",
    },
  },
  hi: {
    register_webinar: {
      title: "हमारे वेबिनार में शामिल हों!",
      des: "विशेष IUX वेबिनार तक पहुंच प्राप्त करें",
      btn_register: "पंजीकरण करवाना",
      btn_not: "अभी नहीं",
    },
  },
  ms: {
    register_webinar: {
      title: "Sertai Webinar Kami!",
      des: "Dapatkan akses kepada Webinar IUX eksklusif",
      btn_register: "Daftar",
      btn_not: "Bukan sekarang",
    },
  },
  id: {
    register_webinar: {
      title: "Bergabunglah dengan Webinar Kami!",
      des: "Dapatkan akses ke Webinar IUX eksklusif",
      btn_register: "Daftar",
      btn_not: "Tidak sekarang",
    },
  },
  zh: {
    register_webinar: {
      title: "加入我们的网络研讨会！",
      des: "参加独家 IUX 网络研讨会",
      btn_register: "登记",
      btn_not: "不要",
    },
  },
  vi: {
    register_webinar: {
      title: "Tham gia hội thảo trên web của chúng tôi!",
      des: "Có quyền truy cập vào Hội thảo trên web IUX độc quyền",
      btn_register: "Đăng ký",
      btn_not: "Không phải bây giờ",
    },
  },
  ko: {
    register_webinar: {
      title: "웹 세미나에 참여하세요!",
      des: "독점 IUX 웹 세미나에 액세스하세요",
      btn_register: "등록하다",
      btn_not: "지금은 아님",
    },
  },
  fil: {
    register_webinar: {
      title: "Sumali sa Aming Webinar!",
      des: "Kumuha ng access sa eksklusibong IUX Webinar",
      btn_register: "Magrehistro",
      btn_not: "Hindi ngayon",
    },
  },
  ja: {
    register_webinar: {
      title: "ウェビナーに参加してください！",
      des: "限定の IUX ウェビナーにアクセスする",
      btn_register: "登録する",
      btn_not: "今じゃない",
    },
  },
  es: {
    register_webinar: {
      title: "¡Únete a Nuestro Seminario Web!",
      des: "Obtén acceso exclusivo al Seminario Web de IUX",
      btn_register: "Registrar",
      btn_not: "Ahora no",
    },
  },
};
