import {
  SET_ACCOUNT_TYPE_LIST,
  LOADING_ACCOUNT_TYPE_LIST,
  SET_ACCOUNT_TYPE_LIST_DEMO,
  SET_MODAL_BONUS,
} from "../types";

const INITIALIZE_STATE = {
  accountTypeList: null,
  accountTypeListDemo: null,
  LOADING_ACCOUNT_TYPE_LIST: false,
  modalbonus: false,
};

export default (state = INITIALIZE_STATE, action) => {
  switch (action.type) {
    case SET_ACCOUNT_TYPE_LIST:
      return {
        ...state,
        loading: false,
        accountTypeList: action.payload,
      };
    case SET_MODAL_BONUS:
      return {
        ...state,
        loading: false,
        modalbonus: action.payload,
      };
    case SET_ACCOUNT_TYPE_LIST_DEMO:
      return {
        ...state,
        loading: false,
        accountTypeListDemo: action.payload,
      };
    case LOADING_ACCOUNT_TYPE_LIST:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};
