import lottie6 from "../../assets/lottie/tutorial/SET3_Lottie6.json";
import lottie7 from "../../assets/lottie/tutorial/SET3_Lottie7.json";
import lottie8 from "../../assets/lottie/tutorial/SET3_Lottie8.json";
import lottie9 from "../../assets/lottie/tutorial/SET3_Lottie9.json";
import lottie10 from "../../assets/lottie/tutorial/SET3_Lottie10.json";

import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { Button, Carousel, Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useInView } from "react-intersection-observer";
import Lottie from "lottie-react";
const locale = require("react-redux-i18n").I18n;

const Tutorial = ({ isButton, countryId, status }) => {
  const refTutorial = useRef();
  const [index, setIndex] = useState(0);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [active, setActive] = useState(false);

  const [timeLeft, setTimeLeft] = useState(isButton ? 0 : 5);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if ((status === "not_verify" || status === "rejected") && !isButton) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [status, isButton]);

  const user = useSelector((state) => state.user);
  // const type = user?.countries?.find((e) => e.id === countryId)?.verify_type;
  const type = user?.profileCountry?.verify_type;

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    setIndex(0);
    refTutorial.current?.goTo(0);
  }, [open]);

  useEffect(() => {
    if (timeLeft === 0) {
    } else {
      const timerId = setInterval(() => {
        setTimeLeft((time) => time - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [timeLeft]);

  const setOpenHandler = () => {
    setOpen(true);
  };

  const setCloseHander = () => {
    setOpen(false);
  };

  const handleNext = () => {
    setActive(true);
    refTutorial.current.next();
  };

  const handlePrev = () => {
    setActive(true);
    refTutorial.current.prev();
  };

  const afterChangeHandler = (index) => {
    setIndex(index);
    setActive(false);
  };
  useEffect(() => {
    if (index === 0) {
      setTitle(locale.t("step1tutorial.title"));
      setDescription(locale.t("step1tutorial.description"));
    } else if (index === 1) {
      if (type.includes("auto")) {
        setTitle(locale.t("step2tutorial.title"));
        setDescription(locale.t("step2tutorial.description"));
      } else {
        setTitle(locale.t("step2tutorial.manual.title"));
        setDescription(locale.t("step2tutorial.manual.description"));
      }
    } else if (index === 2) {
      setTitle(locale.t("step3tutorial.title"));
      setDescription(locale.t("step3tutorial.description"));
    } else if (index === 3) {
      setTitle(locale.t("step4tutorial.title"));
      setDescription(locale.t("step4tutorial.description"));
    }
  }, [index]);

  const nextHandler = () => {
    if (index === (type.includes("auto") ? 3 : 2)) {
      setOpen(false);
      localStorage.setItem("tutorialHide", true);
    } else {
      handleNext();
    }
  };

  useEffect(() => {
    if (inView) {
      if (!isButton) {
        setTimeLeft(5);
      }
    }
  }, [inView]);

  return (
    <div>
      {isButton && <div onClick={setOpenHandler}>{locale.t("tutorial")}</div>}
      {type && (
        <Modal
          open={
            open &&
            (localStorage.getItem("tutorialHide") !== "true" || isButton)
          }
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
          style={{ maxWidth: "100%", width: "100%", padding: "10px" }}
          onCancel={isButton && setCloseHander}
          closeIcon={!isButton ? null : true}
          className="no-scroll tutorial-modal"
        >
          <div
            style={{
              padding: "25px",
              position: "relative",
              height: "100vh",
              maxHeight: "590px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            className="no-scroll"
            ref={(node) => {
              ref(node);
            }}
          >
            <div style={{ overflow: "scroll" }} className="no-scroll">
              <Carousel
                ref={refTutorial}
                style={{ padding: "20px", marginBottom: "30px" }}
                afterChange={afterChangeHandler}
                className="tutorial-default"
              >
                <div>
                  <div className="m-auto">
                    <Lottie animationData={lottie6} />
                  </div>
                </div>
                <div>
                  <div className="m-auto" style={{ paddingBottom: "20px" }}>
                    {type.includes("auto") ? (
                      <Lottie animationData={lottie7} />
                    ) : (
                      <Lottie animationData={lottie10} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="m-auto">
                    <Lottie animationData={lottie8} />
                  </div>
                </div>
                {type.includes("auto") && (
                  <div>
                    <div className="m-auto">
                      <Lottie animationData={lottie9} />
                    </div>
                  </div>
                )}
              </Carousel>
              <div
                className="d-flex justify-content-between"
                style={{
                  width: "100%",
                  marginTop: "-70px",
                  marginBottom: "45px",
                  zIndex: 99,
                  position: "relative",
                }}
              >
                {index !== 0 ? (
                  <LeftCircleOutlined
                    onClick={!active && handlePrev}
                    style={{ fontSize: "24px", color: "#9F9F9F" }}
                    className=" cursor-pointer"
                  />
                ) : (
                  <div></div>
                )}
                {index !== (type.includes("auto") ? 3 : 2) ? (
                  <RightCircleOutlined
                    onClick={!active && handleNext}
                    style={{
                      fontSize: "24px",
                      color: "#9F9F9F",
                      cursor: "pointer",
                    }}
                    className=" cursor-pointer"
                  />
                ) : (
                  <div></div>
                )}
              </div>
              <div
                className="text-center mb-5"
                style={{ fontSize: "20px", fontWeight: "600" }}
              >
                <div className="mb-2">{title}</div>
                <div
                  style={{ fontSize: "16px", fontWeight: "400", color: "#999" }}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
            </div>
            <Button
              className="btn-primary-new-iux w-100 "
              type="primary"
              style={{ borderRadius: 8 }}
              disabled={timeLeft}
              onClick={!active ? nextHandler : () => {}}
            >
              {index !== (type.includes("auto") ? 3 : 2)
                ? locale.t("nextTutorial")
                : locale.t("understoodTutorial")}{" "}
              {timeLeft !== 0 && `(${timeLeft})`}
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Tutorial;
