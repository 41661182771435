import React from "react";
import { Layout } from "antd";
import styled from "styled-components";

const { Content } = Layout;

const CustomContent = styled(Content)`
  background: rgb(255, 255, 255);
  margin-top: 0px !important;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
`;
export const StyledAccountWallet = (props) => (
  <Content
    style={{
      background: "#fff",
      marginTop: 0,
      borderRadius: 5,
      WebkitBoxShadow: "0px 3px 6px 0px rgba(0,0,0,0.16)",
      MozBoxShadow: "0px 3px 6px 0px rgba(0,0,0,0.16)",
      boxShadow: "0px 3px 6px 0px rgba(0,0,0,0.16)",
    }}
  >
    {props.children}
  </Content>
);

export const SubStyledAccountWallet = (props) => (
  <CustomContent>{props.children}</CustomContent>
);
