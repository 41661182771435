import zhCN from "antd/locale/zh_CN";
import enUS from "antd/locale/en_US";
import thTH from "antd/locale/th_TH";
import msMY from "antd/locale/ms_MY";
import idID from "antd/locale/id_ID";
import hiIN from "antd/locale/hi_IN";
import viVN from "antd/locale/vi_VN";
import jaJP from "antd/locale/ja_JP";
import koKR from "antd/locale/ko_KR";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/lo";
import "dayjs/locale/th";
import "dayjs/locale/ms-my";
import "dayjs/locale/id";
import "dayjs/locale/vi";
import "dayjs/locale/ja";
import "dayjs/locale/ko";
import "dayjs/locale/hi";
import "dayjs/locale/zh-cn";
import "dayjs/locale/tl-ph";
import { loasLocale } from "./antdLocale/lo";
import { PhilippinesLocale } from "./antdLocale/ph";

export const CheckLanguageAntd = (id) => {
  switch (id) {
    case "th":
      return thTH;
    case "en":
      return enUS;
    case "zh":
      return zhCN;
    case "ms":
      return msMY;
    case "hi":
      return hiIN;
    case "lo":
      return loasLocale;
    case "id":
      return idID;
    case "vi":
      return viVN;
    case "fil":
      return PhilippinesLocale;
    case "ja":
      return jaJP;
    case "ko":
      return koKR;
    default:
      return enUS;
  }
};
//
