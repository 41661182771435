import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { Link } from "react-router-dom";
const locale = require("react-redux-i18n").I18n;

const BoxContent = styled.div`
  border: none;
  font-size: 14px;

  color: #fff;
  padding: 24px;
  border-radius: 20px;
  background: #242424;
`;
export default function Recommendation() {
  return (
    <div className="col-md-4">
      <div className="mb-4">
        <BoxContent>
          <h5 className="text-white mb-3">
            {locale.t("fxcal_list_1_box_how.title_1")}
          </h5>
          <p>{locale.t("fxcal_list_1_box_how.title_2")}</p>
          <p>{locale.t("fxcal_list_1_box_how.title_3")}</p>
        </BoxContent>
      </div>
      <div className="mb-4 text-center text-lg-left">
        <BoxContent>
          <h5 className="text-white mb-3">
            {locale.t("fxcal_list_1_box_how_2.title_1")}
          </h5>
          <p className="word-break-all text-white">
            {locale.t("fxcal_list_1_box_how_2.title_2")}
          </p>
          <Link to={`/${localStorage.getItem("locale")}/register`}>
            <Button className="btn-primary-new-iux mt-2">
              {locale.t("open_account_now")}
            </Button>
          </Link>
        </BoxContent>
      </div>
    </div>
  );
}
