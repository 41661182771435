import { useCallback, useEffect, useState } from "react";
import NotificationMessage from "./NotificationMessage";
import NotificationUpdate from "./NotificationUpdate";
import {
  getNotifications,
  patchReadNotification,
} from "../../api/notifications";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingIux from "../loading";

import { ClipLoader } from "react-spinners";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
const locale = require("react-redux-i18n").I18n;

const NotificationInside = ({
  dataNotification,
  fetchNotification,
  nextPage,
  tabs,
  handlechangeTabs,
  isLoading,
  onRead,
  onShowDetail,
  dataNotiMessages,

  onNotiChildrenDrawerClose,
  showClose,
}) => {
  const renderEmpty = (
    <div className="d-flex align-items-center justify-content-center flex-column noti-empty">
      <div>
        <img src="/assets/images/Notification/empty-noti.svg" alt="Empty" />
      </div>
      <div> {locale.t("nomessages")}</div>
    </div>
  );
  // const onShowDetail = (index) => {
  //   dataNotification[index].show = !dataNotification[index].show;
  //   setDataNotification([...dataNotification]);
  // };

  return (
    <div className="noti-container">
      <div className="d-flex justify-content-between">
        <div>{locale.t("notifications")}</div>
        {showClose && (
          <div className="d-flex justify-content-end  ">
            <div className="d-flex justify-content-end" style={{ order: "2" }}>
              <button
                className="d-flex btn-transparent mr-2"
                onClick={onNotiChildrenDrawerClose}
              >
                <span>
                  <CloseOutlined />
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="noti-tabs">
        <div
          className={
            tabs === "message" ? "noti-selected-tab fs-16" : "noti-tab fs-16"
          }
          onClick={() => {
            handlechangeTabs("message");
          }}
        >
          {locale.t("messages")}
        </div>
        <div
          className={tabs === "update" ? "noti-selected-tab" : "noti-tab"}
          onClick={() => {
            handlechangeTabs("update");
          }}
        >
          {locale.t("updates")}
        </div>
      </div>
      {isLoading ? (
        <LoadingIux />
      ) : tabs === "message" ? (
        <div className="noti-message" id="scrollableDiv">
          {dataNotiMessages.length > 0 ? (
            <InfiniteScroll
              dataLength={dataNotiMessages?.length || 10}
              next={fetchNotification}
              hasMore={nextPage}
              loader={
                <div className="d-flex justify-content-center">
                  <ClipLoader
                    color="#36d7b7"
                    size={24}
                    cssOverride={{ borderWidth: 2 }}
                  />
                </div>
              }
              // endMessage={<p>No more data to load.</p>}
              scrollableTarget="scrollableDiv"
            >
              {dataNotiMessages?.map((item) => (
                <div key={item.id + "message"}>
                  <NotificationMessage dataNoti={item} />
                </div>
              ))}
            </InfiniteScroll>
          ) : (
            renderEmpty
          )}
        </div>
      ) : (
        <div className="noti-message" id="scrollableDivUpdate">
          {dataNotification.length > 0 ? (
            <InfiniteScroll
              dataLength={dataNotification?.length || 10}
              next={fetchNotification}
              hasMore={nextPage}
              loader={
                <div className="d-flex justify-content-center">
                  <ClipLoader
                    color="#36d7b7"
                    size={24}
                    cssOverride={{ borderWidth: 2 }}
                  />
                </div>
              }
              // endMessage={<p>No more data to load.</p>}
              scrollableTarget="scrollableDivUpdate"
            >
              {dataNotification?.map((item, index) => (
                <div key={item.id + "update"}>
                  <NotificationUpdate
                    dataNoti={item}
                    onRead={onRead}
                    onShowDetail={onShowDetail}
                    isKey={index}
                  />
                </div>
              ))}
            </InfiniteScroll>
          ) : (
            renderEmpty
          )}
        </div>
      )}
    </div>
  );
};

export default NotificationInside;
