import Lotback from "./Lotback";
import Bonus from "./Bonus";
import Navbar from "./Navbar";
import LadningPage from "./LandingPage";
import Footer from "./Footer";
import SocialTrade from "./SocialTrade";
import Partner from "./Partner";
import Partner2 from "./Partner2";
import Futures from "./Futures";
import Contact from "./Contact";
import Login from "./Login";
import Help from "./Help";
import Register from "./Register";
import PlatformIphone from "./PlatformIphone";
import PlatformAndroid from "./PlatformAndroid";
import PlatformMac from "./PlatformMac";
import PlatformWindows from "./PlatformWindows";
import PlatformMt4 from "./PlatformMt4";
import Deposit from "./Deposit";
import AboutMe from "./AboutMe";
import Condition from "./Condition";
import Security from "./Security";
import Policy from "./Policy";
import TermCondition from "./TermCondition";
import MenuWallet from "./MenuWallet";
import Dashboard from "./Dashboard";
import Pdf from "./Pdf";
import WithDraw from "./WithDraw";
import Commission from "./Commission";
import Account from "./Account";
import Setting from "./Settig";
import Promote from "./Promote";
import PaymentOption from "./PaymentOption";
import GuidelinePartner from "./GuidelinePartner";
import ContactUs from "./ContactUs";
import Verify from "./Verify";
import ChangePassword from "./ChangePassword";
import ChangePhone from "./ChangePhone";
import Swap from "./Swap";
import Platform from "./Platform";
import Calendar from "./Calendar";
import Analytics from "./Analytics";
import Instrument from "./Instrument";
import Datetime from "./Datetime";
import Notification from "./Notification";
import VerifyEmail from "./VerifyEmail";
import Tutorial from "./Tutorial";
import Promotion from "./Promotion";
import Banner from "./Banner";
import StopOut from "./StopOut";
import Webinar from "./Webinar";
// LIST FILE TRANSLATION
export const translationsObject = {
  th: {
    ...Webinar.th,
    ...Notification.th,
    ...Datetime.th,
    ...Instrument.th,
    ...Analytics.th,
    ...Calendar.th,
    ...Platform.th,
    ...ChangePhone.th,
    ...ChangePassword.th,
    ...Verify.th,
    ...ContactUs.th,
    ...GuidelinePartner.th,
    ...Account.th,
    ...Navbar.th,
    ...LadningPage.th,
    ...Footer.th,
    ...SocialTrade.th,
    ...Partner.th,
    ...Partner2.th,
    ...Futures.th,
    ...Contact.th,
    ...Login.th,
    ...Register.th,
    ...PlatformIphone.th,
    ...PlatformAndroid.th,
    ...PlatformMac.th,
    ...PlatformWindows.th,
    ...Deposit.th,
    ...PlatformMt4.th,
    ...AboutMe.th,
    ...Condition.th,
    ...Security.th,
    ...Policy.th,
    ...TermCondition.th,
    ...MenuWallet.th,
    ...Dashboard.th,
    ...Pdf.th,
    ...WithDraw.th,
    ...Commission.th,
    ...Bonus.th,
    ...Lotback.th,
    ...Help.th,
    ...Setting.th,
    ...Promote.th,
    ...PaymentOption.th,
    ...Swap.th,

    next: "ถัดไป",
    prev: "ย้อนกลับ",
    goconsole: "กลับไปหน้าหลัก",
    comming_soon: "ฟีเจอร์ยังไม่พร้อมใช้งาน",
    click_send: "ส่ง",
    validate_email: "กรุณายืนยันอีเมลของคุณ",
    validate_email_resend: "ส่งอีเมลเพื่อยืนยันอีกครั้ง",
    validate_email_seccuss: "กรุณาตรวจสอบ Email ของคุณอีกครั้ง",
    ...VerifyEmail.th,
    ...Tutorial.th,
    ...Promotion.th,
    ...Banner.th,
    ...StopOut.th,
  },
  lo: {
    ...Webinar.lo,
    ...Notification.lo,
    ...Datetime.lo,
    ...Instrument.lo,
    ...Analytics.lo,
    ...Calendar.lo,
    ...Platform.lo,
    ...ChangePhone.lo,
    ...ChangePassword.lo,
    ...Verify.lo,
    ...ContactUs.lo,
    ...GuidelinePartner.lo,
    ...Account.lo,
    ...Navbar.lo,
    ...LadningPage.lo,
    ...Footer.lo,
    ...SocialTrade.lo,
    ...Partner2.lo,
    ...Partner.lo,
    ...Futures.lo,
    ...Contact.lo,
    ...Login.lo,
    ...Register.lo,
    ...PlatformIphone.lo,
    ...PlatformAndroid.lo,
    ...PlatformMac.lo,
    ...PlatformWindows.lo,
    ...Deposit.lo,
    ...PlatformMt4.lo,
    ...AboutMe.lo,
    ...Condition.lo,
    ...Security.lo,
    ...Policy.lo,
    ...TermCondition.lo,
    ...MenuWallet.lo,
    ...Dashboard.lo,
    ...Pdf.lo,
    ...WithDraw.lo,
    ...Commission.lo,
    ...Bonus.lo,
    ...Lotback.lo,
    ...Help.lo,
    ...Setting.lo,
    ...Promote.lo,
    ...PaymentOption.lo,
    ...Swap.lo,
    next: "ຕໍ່ໄປ",
    prev: "ກ່ອນໜ້າ",
    goconsole: "ກັບໄປຫຼັກ",
    comming_soon: "ຫນ້ານີ້ຍັງບໍ່ມີຢູ່",
    click_send: "ສົ່ງ",
    validate_email: "ກະລຸນາຢືນຢັນອີເມວຂອງທ່ານ",
    validate_email_resend: "ສົ່ງອີເມວເພື່ອຢືນຢັນອີເມວອື່ນ",
    validate_email_seccuss: "ກະລຸນາກວດສອບອີເມວຂອງທ່ານອີເມວອື່ນ",
    ...VerifyEmail.lo,
    ...Tutorial.lo,
    ...Promotion.lo,
    ...Banner.lo,
    ...StopOut.lo,
  },
  en: {
    ...Webinar.en,
    ...Notification.en,
    ...Datetime.en,
    ...Instrument.en,
    ...Analytics.en,
    ...Calendar.en,
    ...Platform.en,
    ...ChangePhone.en,
    ...ChangePassword.en,
    ...Verify.en,
    ...ContactUs.en,
    ...GuidelinePartner.en,
    ...Account.en,
    ...Navbar.en,
    ...LadningPage.en,
    ...Footer.en,
    ...SocialTrade.en,
    ...Partner2.en,
    ...Partner.en,
    ...Futures.en,
    ...Contact.en,
    ...Login.en,
    ...Register.en,
    ...PlatformIphone.en,
    ...PlatformAndroid.en,
    ...PlatformMac.en,
    ...PlatformWindows.en,
    ...Deposit.en,
    ...PlatformMt4.en,
    ...AboutMe.en,
    ...Condition.en,
    ...Security.en,
    ...Policy.en,
    ...TermCondition.en,
    ...MenuWallet.en,
    ...Dashboard.en,
    ...Pdf.en,
    ...WithDraw.en,
    ...Commission.en,
    ...Bonus.en,
    ...Lotback.en,
    ...Help.en,
    ...Setting.en,
    ...Promote.en,
    ...PaymentOption.en,
    ...Swap.en,
    next: "Next",
    prev: "Previous",
    goconsole: "Back to main page",
    comming_soon: "This page is not available.",
    click_send: "Send",
    validate_email: "Please verify your email",
    validate_email_resend: "Send email to verify again",
    validate_email_seccuss: "Please check your email again.",
    ...VerifyEmail.en,
    ...Tutorial.en,
    ...Promotion.en,
    ...Banner.en,
    ...StopOut.en,
  },
  hi: {
    ...Webinar.hi,
    ...Notification.hi,
    ...Datetime.hi,
    ...Instrument.hi,
    ...Analytics.hi,
    ...Calendar.hi,
    ...Platform.hi,
    ...ChangePhone.hi,
    ...ChangePassword.hi,
    ...Verify.hi,
    ...ContactUs.hi,
    ...GuidelinePartner.hi,
    ...Account.hi,
    ...Navbar.hi,
    ...LadningPage.en,
    ...Footer.hi,
    ...SocialTrade.en,
    ...Partner.en,
    ...Partner2.en,
    ...Futures.en,
    ...Contact.hi,
    ...Login.en,
    ...Register.hi,
    ...PlatformIphone.en,
    ...PlatformAndroid.en,
    ...PlatformMac.en,
    ...PlatformWindows.en,
    ...Deposit.hi,
    ...PlatformMt4.en,
    ...AboutMe.hi,
    ...Condition.en,
    ...Security.en,
    ...Policy.en,
    ...TermCondition.en,
    ...MenuWallet.hi,
    ...Dashboard.hi,
    ...Pdf.en,
    ...WithDraw.hi,
    ...Commission.hi,
    ...Bonus.hi,
    ...Lotback.hi,
    ...Help.en,
    ...Setting.hi,
    ...Promote.en,
    ...PaymentOption.en,
    ...Swap.hi,
    next: "आगे",
    prev: "पिछला",
    goconsole: "मुख्य पृष्ठ पर वापस जाएं",
    comming_soon: "यह पृष्ठ उपलब्ध नहीं है।",
    click_send: "भेजें",
    validate_email: "कृपया अपना ईमेल सत्यापित करें",
    validate_email_resend: "फिर से ईमेल भेजें और सत्यापित करें।",
    validate_email_seccuss: "कृपया अपना ईमेल फिर से जाँचें।",
    ...VerifyEmail.hi,
    ...Tutorial.hi,
    ...Promotion.hi,
    ...Banner.hi,
    ...StopOut.hi,
  },
  ms: {
    ...Webinar.ms,
    ...Notification.ms,
    ...Datetime.ms,
    ...Instrument.ms,
    ...Analytics.ms,
    ...Calendar.ms,
    ...Platform.ms,
    ...ChangePhone.ms,
    ...ChangePassword.ms,
    ...Verify.ms,
    ...ContactUs.ms,
    ...GuidelinePartner.ms,
    ...Account.ms,
    ...Navbar.ms,
    ...LadningPage.ms,
    ...Footer.ms,
    ...SocialTrade.ms,
    ...Partner.ms,
    ...Partner2.ms,
    ...Futures.ms,
    ...Contact.ms,
    ...Login.ms,
    ...Register.ms,
    ...PlatformIphone.ms,
    ...PlatformAndroid.ms,
    ...PlatformMac.ms,
    ...PlatformWindows.ms,
    ...Deposit.ms,
    ...PlatformMt4.ms,
    ...AboutMe.ms,
    ...Condition.ms,
    ...Security.ms,
    ...Policy.ms,
    ...TermCondition.ms,
    ...MenuWallet.ms,
    ...Dashboard.ms,
    ...Pdf.ms,
    ...WithDraw.ms,
    ...Commission.ms,
    ...Bonus.ms,
    ...Lotback.ms,
    ...Help.ms,
    ...Setting.ms,
    ...Promote.ms,
    ...PaymentOption.ms,
    ...Swap.ms,
    next: "Seterusnya",
    prev: "Sebelumnya",
    goconsole: "Kembali ke halaman utama",
    comming_soon: "Halaman ini belum tersedia.",
    click_send: "Hantar",
    validate_email: "Sila sahkan emel anda",
    validate_email_resend: "Hantar emel untuk sahkan semula.",
    validate_email_seccuss: "Sila semak emel anda sekali lagi.",
    ...VerifyEmail.ms,
    ...Tutorial.ms,
    ...Promotion.ms,
    ...Banner.ms,
    ...StopOut.ms,
  },
  id: {
    ...Webinar.id,
    ...Notification.id,
    ...Datetime.id,
    ...Instrument.id,
    ...Analytics.id,
    ...Calendar.id,
    ...Platform.id,
    ...ChangePhone.id,
    ...ChangePassword.id,
    ...Verify.id,
    ...ContactUs.id,
    ...GuidelinePartner.id,
    ...Account.id,
    ...Navbar.id,
    ...LadningPage.id,
    ...Footer.id,
    ...SocialTrade.id,
    ...Partner.id,
    ...Partner2.id,
    ...Futures.id,
    ...Contact.id,
    ...Login.id,
    ...Register.id,
    ...PlatformIphone.id,
    ...PlatformAndroid.id,
    ...PlatformMac.id,
    ...PlatformWindows.id,
    ...Deposit.id,
    ...PlatformMt4.id,
    ...AboutMe.id,
    ...Condition.id,
    ...Security.id,
    ...Policy.id,
    ...TermCondition.id,
    ...MenuWallet.id,
    ...Dashboard.id,
    ...Pdf.id,
    ...WithDraw.id,
    ...Commission.id,
    ...Bonus.id,
    ...Lotback.id,
    ...Help.id,
    ...Setting.id,
    ...Promote.id,
    ...PaymentOption.id,
    ...Swap.id,
    next: "Berikutnya",
    prev: "Sebelumnya",
    goconsole: "Kembali ke halaman utama",
    comming_soon: "Halaman ini belum tersedia.",
    click_send: "Kirim",
    validate_email: "Harap verifikasi email Anda",
    validate_email_resend: "Kirim email untuk verifikasi lagi.",
    validate_email_seccuss: "Harap cek email Anda lagi.",
    ...VerifyEmail.id,
    ...Tutorial.id,
    ...Promotion.id,
    ...Banner.id,
    ...StopOut.id,
  },
  zh: {
    ...Webinar.zh,
    ...Notification.zh,
    ...Datetime.zh,
    ...Instrument.zh,
    ...Analytics.zh,
    ...Calendar.zh,
    ...Platform.zh,
    ...ChangePhone.zh,
    ...ChangePassword.zh,
    ...Verify.zh,
    ...ContactUs.zh,
    ...GuidelinePartner.zh,
    ...Account.zh,
    ...Navbar.zh,
    ...LadningPage.zh,
    ...Footer.zh,
    ...SocialTrade.zh,
    ...Partner.zh,
    ...Partner2.zh,
    ...Futures.zh,
    ...Contact.zh,
    ...Login.zh,
    ...Register.zh,
    ...PlatformIphone.zh,
    ...PlatformAndroid.zh,
    ...PlatformMac.zh,
    ...PlatformWindows.zh,
    ...Deposit.zh,
    ...PlatformMt4.zh,
    ...AboutMe.zh,
    ...Condition.zh,
    ...Security.zh,
    ...Policy.zh,
    ...TermCondition.zh,
    ...MenuWallet.zh,
    ...Dashboard.zh,
    ...Pdf.zh,
    ...WithDraw.zh,
    ...Commission.zh,
    ...Bonus.zh,
    ...Lotback.zh,
    ...Help.zh,
    ...Setting.zh,
    ...Promote.zh,
    ...PaymentOption.zh,
    ...Swap.zh,
    next: "下一页",
    prev: "上一页",
    goconsole: "返回主页",
    comming_soon: "此页面尚不可用。",
    click_send: "发送",
    validate_email: "请验证您的电子邮件",
    validate_email_resend: "重新发送验证电子邮件。",
    validate_email_seccuss: "请再次检查您的电子邮件。",
    ...VerifyEmail.zh,
    ...Tutorial.zh,
    ...Promotion.zh,
    ...Banner.zh,
    ...StopOut.zh,
  },
  vi: {
    ...Webinar.vi,
    ...Notification.vi,
    ...Datetime.vi,
    ...Instrument.vi,
    ...Analytics.vi,
    ...Calendar.vi,
    ...Platform.vi,
    ...ChangePhone.vi,
    ...ChangePassword.vi,
    ...Verify.vi,
    ...ContactUs.vi,
    ...GuidelinePartner.vi,
    ...Account.vi,
    ...Navbar.vi,
    ...LadningPage.vi,
    ...Footer.vi,
    ...SocialTrade.vi,
    ...Partner.vi,
    ...Partner2.vi,
    ...Futures.vi,
    ...Contact.vi,
    ...Login.vi,
    ...Register.vi,
    ...PlatformIphone.vi,
    ...PlatformAndroid.vi,
    ...PlatformMac.vi,
    ...PlatformWindows.vi,
    ...Deposit.vi,
    ...PlatformMt4.vi,
    ...AboutMe.vi,
    ...Condition.vi,
    ...Security.vi,
    ...Policy.vi,
    ...TermCondition.vi,
    ...MenuWallet.vi,
    ...Dashboard.vi,
    ...Pdf.vi,
    ...WithDraw.vi,
    ...Commission.vi,
    ...Bonus.vi,
    ...Lotback.vi,
    ...Help.vi,
    ...Setting.vi,
    ...Promote.vi,
    ...PaymentOption.vi,
    ...Swap.vi,
    next: "Tiếp theo",
    prev: "Trước",
    goconsole: "Quay lại trang chính",
    comming_soon: "Trang này chưa sẵn sàng.",
    click_send: "Gửi",
    validate_email: "Vui lòng xác nhận email của bạn",
    validate_email_resend: "Gửi email để xác nhận lại.",
    validate_email_seccuss: "Vui lòng kiểm tra email của bạn lại.",
    ...VerifyEmail.vi,
    ...Tutorial.vi,
    ...Promotion.vi,
    ...Banner.vi,
    ...StopOut.vi,
  },
  ko: {
    ...Webinar.ko,
    ...Notification.ko,
    ...Datetime.ko,
    ...Instrument.ko,
    ...Analytics.ko,
    ...Calendar.ko,
    ...Platform.ko,
    ...ChangePhone.ko,
    ...ChangePassword.ko,
    ...Verify.ko,
    ...ContactUs.ko,
    ...GuidelinePartner.ko,
    ...Account.ko,
    ...Navbar.ko,
    ...LadningPage.ko,
    ...Footer.ko,
    ...SocialTrade.ko,
    ...Partner.ko,
    ...Partner2.ko,
    ...Futures.ko,
    ...Contact.ko,
    ...Login.ko,
    ...Register.ko,
    ...PlatformIphone.ko,
    ...PlatformAndroid.ko,
    ...PlatformMac.ko,
    ...PlatformWindows.ko,
    ...Deposit.ko,
    ...PlatformMt4.ko,
    ...AboutMe.ko,
    ...Condition.ko,
    ...Security.ko,
    ...Policy.ko,
    ...TermCondition.ko,
    ...MenuWallet.ko,
    ...Dashboard.ko,
    ...Pdf.ko,
    ...WithDraw.ko,
    ...Commission.ko,
    ...Bonus.ko,
    ...Lotback.ko,
    ...Help.ko,
    ...Setting.ko,
    ...Promote.ko,
    ...PaymentOption.ko,
    ...Swap.ko,
    next: "다음",
    prev: "이전",
    goconsole: "메인 페이지로 돌아가기",
    comming_soon: "이 페이지는 사용할 수 없습니다.",
    click_send: "보내기",
    validate_email: "이메일을 확인하세요",
    validate_email_resend: "다시 확인하려면 이메일을 보내세요.",
    validate_email_seccuss: "이메일을 다시 확인하세요.",
    ...VerifyEmail.ko,
    ...Tutorial.ko,
    ...Promotion.ko,
    ...Banner.ko,
    ...StopOut.ko,
  },
  fil: {
    ...Webinar.fil,
    ...Notification.fil,
    ...Datetime.fil,
    ...Instrument.fil,
    ...Analytics.fil,
    ...Calendar.fil,
    ...Platform.fil,
    ...ChangePhone.fil,
    ...ChangePassword.fil,
    ...Verify.fil,
    ...GuidelinePartner.fil,
    ...Account.fil,
    ...Navbar.fil,
    ...LadningPage.fil,
    ...Footer.fil,
    ...SocialTrade.fil,
    ...Partner.fil,
    ...Partner2.fil,
    ...Futures.fil,
    ...Contact.fil,
    ...Login.fil,
    ...Register.fil,
    ...PlatformIphone.fil,
    ...PlatformAndroid.fil,
    ...PlatformMac.fil,
    ...PlatformWindows.fil,
    ...Deposit.fil,
    ...PlatformMt4.fil,
    ...AboutMe.fil,
    ...Condition.fil,
    ...Security.fil,
    ...Policy.fil,
    ...TermCondition.fil,
    ...MenuWallet.fil,
    ...Dashboard.fil,
    ...Pdf.fil,
    ...WithDraw.fil,
    ...Commission.fil,
    ...Bonus.fil,
    ...Lotback.fil,
    ...Help.fil,
    ...Setting.fil,
    ...Promote.fil,
    ...PaymentOption.fil,
    ...Swap.fil,
    next: "Susunod",
    prev: "Nakaraan",
    goconsole: "Bumalik sa pangunahing pahina",
    comming_soon: "Ang pahinang ito ay hindi pa available.",
    click_send: "Ipadala",
    validate_email: "Pakipatunayan ang iyong email",
    validate_email_resend: "Ipadala ang email upang patunayan muli.",
    validate_email_seccuss: "Pakipuntahan ang iyong email muli.",
    ...VerifyEmail.fil,
    ...Tutorial.fil,
    ...Promotion.fil,
    ...Banner.fil,
    ...StopOut.fil,
  },
  ja: {
    ...Webinar.ja,
    ...Notification.ja,
    ...Datetime.ja,
    ...Instrument.ja,
    ...Analytics.ja,
    ...Calendar.ja,
    ...Platform.ja,
    ...ChangePhone.ja,
    ...ChangePassword.ja,
    ...Verify.ja,
    ...ContactUs.ja,
    ...GuidelinePartner.ja,
    ...Account.ja,
    ...Navbar.ja,
    ...LadningPage.ja,
    ...Footer.ja,
    ...SocialTrade.ja,
    ...Partner.ja,
    ...Partner2.ja,
    ...Futures.ja,
    ...Contact.ja,
    ...Login.ja,
    ...Register.ja,
    ...PlatformIphone.ja,
    ...PlatformAndroid.ja,
    ...PlatformMac.ja,
    ...PlatformWindows.ja,
    ...Deposit.ja,
    ...PlatformMt4.ja,
    ...AboutMe.ja,
    ...Condition.ja,
    ...Security.ja,
    ...Policy.ja,
    ...TermCondition.ja,
    ...MenuWallet.ja,
    ...Dashboard.ja,
    ...Pdf.ja,
    ...WithDraw.ja,
    ...Commission.ja,
    ...Bonus.ja,
    ...Lotback.ja,
    ...Help.ja,
    ...Setting.ja,
    ...Promote.ja,
    ...PaymentOption.ja,
    ...Swap.ja,
    next: "次へ",
    prev: "前へ",
    goconsole: "メインページに戻る",
    comming_soon: "このページは利用できません。",
    click_send: "送信",
    validate_email: "メールアドレスを確認してください",
    validate_email_resend: "再度メールを送信する",
    validate_email_seccuss: "もう一度メールを確認してください。",
    ...VerifyEmail.ja,
    ...Tutorial.ja,
    ...Promotion.ja,
    ...Banner.ja,
    ...StopOut.ja,
  },
  es: {
    ...Webinar.es,
    ...Notification.es,
    ...Datetime.es,
    ...Instrument.es,
    ...Analytics.es,
    ...Calendar.es,
    ...Platform.es,
    ...ChangePhone.es,
    ...ChangePassword.es,
    ...Verify.es,
    ...ContactUs.es,
    ...GuidelinePartner.es,
    ...Account.es,
    ...Navbar.es,
    ...LadningPage.es,
    ...Footer.es,
    ...SocialTrade.es,
    ...Partner.es,
    ...Partner2.es,
    ...Futures.es,
    ...Contact.es,
    ...Login.es,
    ...Register.es,
    ...PlatformIphone.es,
    ...PlatformAndroid.es,
    ...PlatformMac.es,
    ...PlatformWindows.es,
    ...Deposit.es,
    ...PlatformMt4.es,
    ...AboutMe.es,
    ...Condition.es,
    ...Security.es,
    ...Policy.es,
    ...TermCondition.es,
    ...MenuWallet.es,
    ...Dashboard.es,
    ...Pdf.es,
    ...WithDraw.es,
    ...Commission.es,
    ...Bonus.es,
    ...Lotback.es,
    ...Help.es,
    ...Setting.es,
    ...Promote.es,
    ...PaymentOption.es,
    ...Swap.es,

    next: "Siguiente",
    prev: "Anterior",
    goconsole: "Volver a la página principal",
    comming_soon: "Esta página no está disponible.",
    click_send: "Enviar",
    validate_email: "Por favor verifica tu correo electrónico",
    validate_email_resend: "Enviar correo electrónico para verificar de nuevo",
    validate_email_seccuss:
      "Por favor revisa tu correo electrónico nuevamente.",
    ...VerifyEmail.es,
    ...Tutorial.es,
    ...Promotion.es,
    ...Banner.es,
    ...StopOut.es,
  },
};
