import {
  CalendarOutlined,
  ClockCircleOutlined,
  ExclamationCircleFilled,
  FilterFilled,
} from "@ant-design/icons";
import RcQueueAnim from "rc-queue-anim";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { DatePicker } from "antd";
import moment from "moment";
import IuxIcon, { ArrowIcon } from "../../components/svg/iux-icon";
import { Link } from "react-router-dom";
import { Calender } from "./calendar";
const locale = require("react-redux-i18n").I18n;
const { RangePicker } = DatePicker;

const Select = styled.select`
  border: none;
  width: 100%;
  height: 35px;
  outline: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: aliceblue;
  }
`;

const BoxCalendar = styled.div`
  border: 1px solid #00000038;
  // padding: 12px;
  border-radius: 5px;
  margin-top: 18px;
`;

const Hr = styled.div`
  border-top: 1px solid #00000038;
`;

const Event = styled.div`
  cursor: pointer;
`;

const GroupCandle = styled.div`
  display: inline-flex;
  position: relative;
  height: 30px;
`;

const CandlestickBox = styled.div`
  height: 25px;
  position: relative;
  display: flex;
  ${(props) => props.center && `margin-top: auto`};
  ${(props) => !props.center && `margin: 0px 1px`};
`;

const CandlestickItem = styled.div`
  background: ${(props) =>
    props.impact === 0
      ? "#A0A0A0"
      : props.impact === 1
      ? "#F5C861"
      : props.impact === 2
      ? "#E4871C"
      : "#D5412D"};
  width: ${(props) => (props.center ? "2px" : "3px")};
  height: ${(props) => (props.center ? "80%" : "60%")};
  margin: auto 0;
`;

const BoxItemInside = styled.div`
  display: flex;
  border: 1px solid #00000038;
  border-radius: 5px;
  padding: 12px;
  align-items: center;
`;

const CustomButton = styled.button`
  border: none;
  outline: none !important;
  border-radius: 5px;
  width: 35px;
  height: 35px;
`;

const CustomButtonFilter = styled.button`
  border: none;
  outline: none !important;
  border-radius: 5px;
  height: 35px;
`;

const Tabs = styled.div`
  background: aliceblue;
  border-radius: 4px;
  padding: 2px 2px;
`;

const Tab = styled.div`
  width: 100%;
  text-align: center;
  cursor: pointer;
  padding: 5px 0;
  ${(props) =>
    props.active &&
    `
  background: #fff;
  border-radius: 5px;
  `}
`;

const CustomDatePicker = styled(RangePicker)`
  border-radius: 4px !important;
  height: 100% !important;
`;

const MiniMenu = styled.div`
  cursor: pointer;
  ${(props) => props.active && `font-weight: bold`};
  font-family: sans-serif;
  height: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 16px;
  width: 100px;
  text-align: center;
`;

const ActiveMenu = styled.div`
  height: 4px;
  width: 100%;
  background: ${(props) => (props.active ? "#009eff" : "transparant")};
  border-radius: 20px;
`;

const Hide = styled.div`
  width: 100%;
  height: 30px;
  background: #ffffff;
  margin-top: -34px;
  position: relative;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: 1px solid #d7d7d7;
  border-right: 1px solid #d7d7d7;
`;

const Economic = () => {
  const exampData = [
    {
      time: "07:00",
      event: {
        icon: "https://upload.wikimedia.org/wikipedia/en/b/b9/Flag_of_Australia.svg",
        symbol: "AUD",
        title: "Building Approvals (MoM) (Sep)",
        sourceOfReport: "Australian Bureau of Statistics (Release URL)",
      },
      impact: 3,
      actual: -4.3,
      forecast: "-2.0%",
      previous: "11.7%",
      overview: `Building Approvals (also known as Building Permits) reports the change in the number of new building approvals issued by the government. Building approvals are an important indicator of new housing starts.
      \nA reading that is stronger than forecast is generally supportive (bullish) for the AUD, while a weaker than forecast reading is generally negative (bearish) for the AUD.`,
      chart: [],
      history: [],
    },
    {
      time: "09:00",
      event: {
        icon: "https://upload.wikimedia.org/wikipedia/en/b/b9/Flag_of_Australia.svg",
        symbol: "AUD",
        title: "Building Approvals (MoM) (Sep)",
        sourceOfReport: "Australian Bureau of Statistics (Release URL)",
      },
      impact: 1,
      actual: 4,
      forecast: "-2.0%",
      previous: "11.7%",
      overview: `Building Approvals (also known as Building Permits) reports the change in the number of new building approvals issued by the government. Building approvals are an important indicator of new housing starts.
      \nA reading that is stronger than forecast is generally supportive (bullish) for the AUD, while a weaker than forecast reading is generally negative (bearish) for the AUD.`,
      chart: [],
      history: [],
    },
    {
      time: "10:00",
      event: {
        icon: "https://upload.wikimedia.org/wikipedia/en/b/b9/Flag_of_Australia.svg",
        symbol: "AUD",
        title: "Building Approvals (MoM) (Sep)",
        sourceOfReport: "Australian Bureau of Statistics (Release URL)",
      },
      impact: 2,
      actual: 3,
      forecast: "-2.0%",
      previous: "11.7%",
      overview: `Building Approvals (also known as Building Permits) reports the change in the number of new building approvals issued by the government. Building approvals are an important indicator of new housing starts.
      \nA reading that is stronger than forecast is generally supportive (bullish) for the AUD, while a weaker than forecast reading is generally negative (bearish) for the AUD.`,
      chart: [],
      history: [],
    },
    {
      time: "09:00",
      event: {
        icon: "https://upload.wikimedia.org/wikipedia/en/b/b9/Flag_of_Australia.svg",
        symbol: "AUD",
        title: "Building Approvals (MoM) (Sep)",
        sourceOfReport: "Australian Bureau of Statistics (Release URL)",
      },
      impact: 3,
      actual: 1,
      forecast: "-2.0%",
      previous: "11.7%",
      overview: `Building Approvals (also known as Building Permits) reports the change in the number of new building approvals issued by the government. Building approvals are an important indicator of new housing starts.
      \nA reading that is stronger than forecast is generally supportive (bullish) for the AUD, while a weaker than forecast reading is generally negative (bearish) for the AUD.`,
      chart: [],
      history: [],
    },
    {
      time: "10:00",
      event: {
        icon: "https://upload.wikimedia.org/wikipedia/en/b/b9/Flag_of_Australia.svg",
        symbol: "AUD",
        title: "Building Approvals (MoM) (Sep)",
        sourceOfReport: "Australian Bureau of Statistics (Release URL)",
      },
      impact: 0,
      actual: -3,
      forecast: "-2.0%",
      previous: "11.7%",
      overview: `Building Approvals (also known as Building Permits) reports the change in the number of new building approvals issued by the government. Building approvals are an important indicator of new housing starts.
      \nA reading that is stronger than forecast is generally supportive (bullish) for the AUD, while a weaker than forecast reading is generally negative (bearish) for the AUD.`,
      chart: [],
      history: [],
    },
  ];

  const [show, setShow] = useState(undefined);
  const [menu, setMenu] = useState(0);
  const [isMenu, setIsMenu] = useState(0);

  const handleEvent = (el) => {
    if (show === el) {
      setShow(undefined);
    } else {
      setShow(el);
    }
  };

  const handleMenu = (el) => {
    setMenu(el);
  };

  const checkImpact = (imp) => {
    if (imp === 0) {
      return (
        <GroupCandle className="mr-2">
          <CandlestickBox>
            <CandlestickItem impact={0} />
            <CandlestickItem center impact={0} />
            <CandlestickItem impact={0} />
          </CandlestickBox>
          <CandlestickBox center>
            <CandlestickItem impact={0} />
            <CandlestickItem center impact={0} />
            <CandlestickItem impact={0} />
          </CandlestickBox>
          <CandlestickBox>
            <CandlestickItem impact={0} />
            <CandlestickItem center impact={0} />
            <CandlestickItem impact={0} />
          </CandlestickBox>
        </GroupCandle>
      );
    } else if (imp === 1) {
      return (
        <GroupCandle className="mr-2">
          <CandlestickBox>
            <CandlestickItem impact={1} />
            <CandlestickItem center impact={1} />
            <CandlestickItem impact={1} />
          </CandlestickBox>
          <CandlestickBox center>
            <CandlestickItem impact={0} />
            <CandlestickItem center impact={0} />
            <CandlestickItem impact={0} />
          </CandlestickBox>
          <CandlestickBox>
            <CandlestickItem impact={0} />
            <CandlestickItem center impact={0} />
            <CandlestickItem impact={0} />
          </CandlestickBox>
        </GroupCandle>
      );
    } else if (imp === 2) {
      return (
        <GroupCandle className="mr-2">
          <CandlestickBox>
            <CandlestickItem impact={2} />
            <CandlestickItem center impact={2} />
            <CandlestickItem impact={2} />
          </CandlestickBox>
          <CandlestickBox center>
            <CandlestickItem impact={2} />
            <CandlestickItem center impact={2} />
            <CandlestickItem impact={2} />
          </CandlestickBox>
          <CandlestickBox>
            <CandlestickItem impact={0} />
            <CandlestickItem center impact={0} />
            <CandlestickItem impact={0} />
          </CandlestickBox>
        </GroupCandle>
      );
    } else if (imp === 3) {
      return (
        <GroupCandle className="mr-2">
          <CandlestickBox>
            <CandlestickItem impact={3} />
            <CandlestickItem center impact={3} />
            <CandlestickItem impact={3} />
          </CandlestickBox>
          <CandlestickBox center>
            <CandlestickItem impact={3} />
            <CandlestickItem center impact={3} />
            <CandlestickItem impact={3} />
          </CandlestickBox>
          <CandlestickBox>
            <CandlestickItem impact={3} />
            <CandlestickItem center impact={3} />
            <CandlestickItem impact={3} />
          </CandlestickBox>
        </GroupCandle>
      );
    }
  };

  const insideMenu = ["Overview", "Chart", "History"];

  return (
    <RcQueueAnim>
      <div className="bg-black text-white pb-5">
        <div
          className="container position-relative"
          style={{ marginTop: "-50px" }}
        >
          <div className="f-40  text-center text-lg-left mb-4 pt-4">
            {locale.t("economic")}
          </div>
          <div className="row pb-3">
            <p className="col-lg-10 text-break text-left">
              {locale.t("economic_des")}
            </p>
          </div>
          {/* <div className="mt-3 row">
            <div className="col-lg-3">
              <Select>
                <option>Current Time : 10:20 (GMT)</option>
                <option>Current Time : 10:20 (GMT)</option>
                <option>Current Time : 10:20 (GMT)</option>
              </Select>
            </div>
          </div>
          <div className="mt-3 row">
            <div className="col-lg-4">
              <div className="w-100 h-100">
                <CustomDatePicker
                  suffixIcon={<CalendarOutlined />}
                  format={"LLL"}
                />
              </div>
            </div>
            <div className="col-lg-4 d-flex">
              <Tabs className="m-auto d-flex w-100">
                <Tab active={menu === 0} onClick={() => handleMenu(0)}>
                  Yesterday
                </Tab>
                <Tab active={menu === 1} onClick={() => handleMenu(1)}>
                  Today
                </Tab>
                <Tab active={menu === 2} onClick={() => handleMenu(2)}>
                  Tomorrow
                </Tab>
                <Tab active={menu === 3} onClick={() => handleMenu(3)}>
                  This week
                </Tab>
              </Tabs>
            </div>
            <div className="col-lg-4 d-flex justify-content-end">
              <CustomButton>
                <ExclamationCircleFilled />
              </CustomButton>
              <CustomButtonFilter className="ml-0 ml-md-1">
                <FilterFilled /> Filter
              </CustomButtonFilter>
            </div>
          </div> */}
          <BoxCalendar>
            <Calender />
            <Hide />
            {/* <div className="row p-2">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-3">Time</div>
                  <div className="col-lg-9">Event</div>
                </div>
              </div>
              <div className="col-lg-2"></div>
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-lg-3 text-right">Impact</div>
                  <div className="col-lg-3 text-right">Actual</div>
                  <div className="col-lg-3 text-right">Forecast</div>
                  <div className="col-lg-3 text-right">Previous</div>
                </div>
              </div>
            </div>
            {exampData.map((e, i) => (
              <div key={i}>
                <Hr />
                <Event className="row p-2" onClick={() => handleEvent(i)}>
                  <div className="col-lg-6">
                    <div className="row h-100">
                      <div className="col-lg-3 mt-auto mb-auto">{e.time}</div>
                      <div className="col-lg-9 mt-auto mb-auto">
                        <div className="d-flex">
                          <span className="d-flex align-items-center mr-1">
                            <img src={e.event.icon} alt="..." width="20px" />
                          </span>
                          <span>
                            <span className="font-bold-iux mr-2">
                              {e.event.symbol}
                            </span>
                            {`${e.event.title}`}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                  <div className="col-lg-4">
                    <div className="row">
                      <div className="col-lg-3 text-right mt-auto mb-auto">
                        {checkImpact(e.impact)}
                      </div>
                      <div
                        className={`col-lg-3 text-right mt-auto mb-auto font-bold-iux ${
                          e.actual > 0 ? "text-success" : "text-danger"
                        }`}
                      >{`${e.actual}%`}</div>
                      <div className="col-lg-3 text-right mt-auto mb-auto">
                        {e.forecast}
                      </div>
                      <div className="col-lg-3 text-right mt-auto mb-auto">
                        {e.previous}
                      </div>
                    </div>
                  </div>
                </Event>
                {show === i && (
                  <div className="p-2">
                    <div>
                      <div className="d-flex font-bold-iux">
                        <span className="d-flex align-items-center mr-1">
                          <img src={e.event.icon} alt="..." width="20px" />
                        </span>
                        <span>
                          <span className="mr-2">{e.event.symbol}</span>
                          {`${e.event.title}`}
                        </span>
                      </div>
                      <div className="row mt-3">
                        <div className="col-lg-3">
                          <BoxItemInside>
                            <div className="mr-2">
                              {e.actual > 0 ? (
                                <div style={{ transform: "rotate(180deg)" }}>
                                  <IuxIcon icon={ArrowIcon} color="green" />
                                </div>
                              ) : (
                                <IuxIcon icon={ArrowIcon} color="#d5412d" />
                              )}
                            </div>
                            <div className="w-100">
                              <div className="d-flex align-items-center justify-content-between">
                                <div>Actual :</div>
                                <div
                                  className={`font-bold-iux ${
                                    e.actual > 0
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                >
                                  {e.actual}
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between">
                                <div>Forecast :</div>
                                <div>{e.forecast}</div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between">
                                <div>Previous :</div>
                                <div>{e.previous}</div>
                              </div>
                            </div>
                          </BoxItemInside>
                        </div>
                        <div className="col-lg-9 d-flex flex-column justify-content-between">
                          <div className="row w-100">
                            <div className="col-lg-3 text-gray mt-auto mb-auto">
                              Impact
                            </div>
                            <div className="col-lg-9">
                              {checkImpact(e.impact)}
                            </div>
                          </div>
                          <div className="row w-100">
                            <div className="col-lg-3 text-gray">Currency</div>
                            <div className="col-lg-9 font-bold-iux">
                              {e.event.symbol}
                            </div>
                          </div>
                          <div className="row w-100">
                            <div className="col-lg-3 text-gray">
                              Source Of Report
                            </div>
                            <div className="col-lg-9">
                              {e.event.sourceOfReport}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        <div className="row">
                          <div className="col-lg-10">
                            <div className="d-flex b-b-gray">
                              {insideMenu.map((e, i) => (
                                <MiniMenu
                                  active={i === isMenu}
                                  onClick={() => setIsMenu(i)}
                                >
                                  <div />
                                  <div className="pl-2 pr-2">{e}</div>
                                  <ActiveMenu active={i === isMenu} />
                                </MiniMenu>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="mt-3">
                          {isMenu === 0 && <div>{e.overview}</div>}
                          {isMenu === 1 && <div>Chart</div>}
                          {isMenu === 2 && <div>History</div>}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))} */}
          </BoxCalendar>
        </div>
      </div>
    </RcQueueAnim>
  );
};

export default Economic;
