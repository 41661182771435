import { Button, Divider } from "antd";
import React from "react";
import { CheckOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const locale = require("react-redux-i18n").I18n;

const paddinigSection = {
  paddingTop: "3rem",
  paddingBottom: "3rem",
};
function AccountEcn(props) {
  const account = useSelector((state) => state.account);
  const accountECN = account?.accountTypeList?.find(
    (e) => e.name === "ECN" && e.type === "real"
  );
  const stdBox = [
    {
      text: locale.t("accecn.box_1.titile_2"),
    },
    {
      text: locale.t("accecn.box_1.titile_3"),
    },
    {
      text: locale.t("accecn.box_1.titile_4"),
    },
    {
      text: locale.t("accecn.box_1.titile_5"),
    },
    {
      text: locale.t("accecn.box_1.titile_6"),
    },
    {
      text: locale.t("accecn.box_1.titile_7"),
    },
  ];
  const loc = localStorage.getItem("locale");
  return (
    <div>
      <section key={1} className="bg-section-dark py-5">
        <div className="container position-relative d-flex">
          <div className="container pb-4" style={{ marginRight: 80 }}>
            <div className="width-section-lg-md-sm">
              <h2 className="text-white text-left">
                {locale.t("accecn.section_1.titile_1")}
              </h2>
              <p className="text-left text-white">
                {locale.t("accecn.section_1.description_1")}
              </p>
            </div>
          </div>
          <div className="w-auto">
            <div className="div-transform">
              <div
                className="p-3 "
                style={{ borderRadius: "20px 20px 0 0", background: "#171717" }}
              >
                <div className="text-white text-bold fs-16 text-center text-uppercase">
                  ECN
                </div>
              </div>
              <div className="p-3">
                <div className="d-flex justify-content-between">
                  <div className="text-white fs-12 w-100">Popularity</div>
                  <div className="d-flex">
                    {[...Array(accountECN?.popularity)].map((e) => (
                      <img
                        src="/assets/images/icons/star_black_24dp.svg"
                        alt="true"
                      />
                    ))}
                  </div>
                </div>
                <Divider style={{ background: "#3E3E3E", margin: "20px 0" }} />
                {stdBox.map((e, i) => (
                  <div className="mt-3 fs-12 text-white">
                    <img
                      src="/assets/images/icons/true.svg"
                      alt="true"
                      className="mr-1"
                    />
                    &nbsp; &nbsp;
                    {e.text}
                  </div>
                ))}
                <Divider style={{ background: "#3E3E3E", margin: "20px 0" }} />
                <Link to={`/${loc}/wallet/fund`}>
                  <Button className="btn-primary-new-iux w-100">
                    Open Real Account
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section key={2}>
        <div
          className="container container-text-center"
          style={paddinigSection}
        >
          <div className="width-section-lg-md-sm">
            <h2 className="text-center text-white mt-4">
              {locale.t("accecn.section_2.titile_1")}
            </h2>
            <p className="text-center text-white">
              {locale.t("accecn.section_2.description_1")}
            </p>
          </div>
        </div>
        <div className="container container-text-center">
          <div style={paddinigSection}>
            {/* <h2 className="text-left text-primary-iux text-left">
              {locale.t("accecn.section_3.titile_1")}
            </h2>
            <p className="mt-2 text-left">
              {locale.t("accecn.section_3.description_1")}
            </p> */}
            <h2 className="text-center text-white mt-4 text-uppercase ">
              {locale.t("accecn.section_3.titile_2")}
            </h2>
            <p className="mt-2 text-center text-white">
              {locale.t("accecn.section_3.description_2")}
            </p>
          </div>
          <div style={paddinigSection}>
            <h4 className="text-center text-white mt-4 text-uppercase ">
              {locale.t("accecn.section_4.titile_1")}
            </h4>
            <p className="mt-2 text-center text-white">
              {locale.t("accecn.section_4.description_1")}
            </p>
            <div style={paddinigSection}>
              <h4 className="text-center text-white mt-4 text-uppercase ">
                {locale.t("accecn.section_4.titile_2")}
              </h4>
              <p className="mt-2 text-center text-white">
                {locale.t("accecn.section_4.description_2")}
              </p>
            </div>
            <div style={paddinigSection}>
              <h4 className="text-center text-white mt-4 text-uppercase ">
                {locale.t("accecn.section_4.titile_3")}
              </h4>
              <p className="mt-2 text-center text-white">
                {locale.t("accecn.section_4.description_3")}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AccountEcn;
