export const CheckProvince = (id) => {
  switch (id) {
    case 1:
      return {
        label: "dashboardFund.modalVerify.provinceLabel",
        placholder: "dashboardFund.modalVerify.provincePlaceHolder",
      };
    case 2:
      return {
        label: "dashboardFund.modalVerify.provinceLabel",
        placholder: "dashboardFund.modalVerify.provincePlaceHolder",
      };
    case 3:
      return {
        label: "dashboardFund.modalVerify.provinceLabel",
        placholder: "dashboardFund.modalVerify.provincePlaceHolder",
      };
    case 4:
      return {
        label: "dashboardFund.modalVerify.provinceLabel",
        placholder: "dashboardFund.modalVerify.provincePlaceHolder",
      };
    case 5:
      return {
        label: "dashboardFund.modalVerify.regionLabel",
        placholder: "dashboardFund.modalVerify.regionPlaceHolder",
      };
    case 6:
      return {
        label: "dashboardFund.modalVerify.stateLabel",
        placholder: "dashboardFund.modalVerify.statePlaceHolder",
      };
    case 7:
      return {
        label: "dashboardFund.modalVerify.stateUnionLabel",
        placholder: "dashboardFund.modalVerify.stateUnionPlaceHolder",
      };
    case 8:
      return {
        label: "dashboardFund.modalVerify.provinceLabel",
        placholder: "dashboardFund.modalVerify.provincePlaceHolder",
      };
    case 9:
      return {
        label: "dashboardFund.modalVerify.provinceLabel",
        placholder: "dashboardFund.modalVerify.provincePlaceHolder",
      };
    case 10:
      return {
        label: "dashboardFund.modalVerify.municipalityLabel",
        placholder: "dashboardFund.modalVerify.municipalityPlaceHolder",
      };
    case 11:
      return {
        label: "dashboardFund.modalVerify.regionStateLabel",
        placholder: "dashboardFund.modalVerify.regionStatePlaceHolder",
      };
    case 12:
      return {
        label: "dashboardFund.modalVerify.provinceLabel",
        placholder: "dashboardFund.modalVerify.provincePlaceHolder",
      };
    case 13:
      return {
        label: "dashboardFund.modalVerify.provinceLabel",
        placholder: "dashboardFund.modalVerify.provincePlaceHolder",
      };
    default:
      return {
        label: "dashboardFund.modalVerify.provinceLabel",
        placholder: "dashboardFund.modalVerify.provincePlaceHolder",
      };
  }
};
//
