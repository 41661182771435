import React from "react";
import ReactApexCharts from "react-apexcharts";
function CardReportLight(props) {
  const chartOptions = {
    plotOptions: { bar: { columnWidth: "16%" } },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    chart: {
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    grid: {
      show: false,
    },
    // labels: false,
    stroke: {
      curve: "smooth",
      width: 2,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#52AB8C"],
    xaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
    yaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: { show: false },
    },
    toolbar: {
      show: false,
    },
    tooltip: {
      enabled: false,
      x: {
        show: false,
      },
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },

    // tooltip: false,
  };
  return (
    <div
      className={`card-report-light ${props.previews ? "ispreviews" : ""}`}
      style={{ fontFamily: "Inter,sans-serif" }}
    >
      <div className="d-flex justify-content-between">
        <div>
          <div className="text-lightgray fs-12">Account Summary</div>
          <div
            className="text-primary-sub-iux fs-46 fw-700"
            style={{ lineHeight: 1 }}
          >
            + $20,000
          </div>
        </div>
        <div>
          <img
            alt="IUX"
            src="/assets/images/icons/iux-logo.png"
            width={48}
            className="border-logo"
          />
        </div>
      </div>
      <div className="text-white">
        <ReactApexCharts
          type="area"
          series={[
            {
              name: "series1",
              data: [1, 40, 28, 51, 2, 70, 50, 28, 51, 5, 50],
            },
          ]}
          options={chartOptions}
          height={148}
        />
      </div>
      <div className="d-flex justify-content-between">
        <div>
          <div className="d-flex">
            <div className="bage-free-swap-isfree d-flex aligns-item-center">
              Most Invest
            </div>
          </div>
          <div>
            {["aapl", "tsla", "eurusd"].map((e, i) => (
              <img
                src={`https://ik.imagekit.io/awonar/public/images/markets/${e}.png?tr=w-100`}
                width={24}
                alt={e.toLowerCase()}
                className="rounded-circle mr-2"
              />
            ))}
          </div>
        </div>
        <div className="text-lightgray fs-8 d-flex justify-content-end align-items-end">
          Sep 2023
        </div>
      </div>
    </div>
  );
}

export default CardReportLight;
