import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Menu as BaseMenu } from "antd";
import { Link, useHistory, withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { setLocale } from "react-redux-i18n";
import { setLocation } from "../../actions";
import styled from "styled-components";
import { DownOutlined, MailOutlined } from "@ant-design/icons";
import { TextCapitalized } from "../../helper/text-capitalized";

const locale = require("react-redux-i18n").I18n;
const MenuDropdownCustom = styled.div`
  position: fixed;
  z-index: 1;
  background: black;
  width: auto;
  // margin-left: -10px;
  text-align: center;
  min-width: 180px;
`;
export const Menu = styled(BaseMenu)`
  // add our style
  .ant-menu-item-selected {
    border-bottom: none;
    color: #1cb894 !important;
  }

  & .ant-menu-item:hover {
    border-bottom: none;
  }

  // remove ant design blue border
  .ant-menu-item-selected::after {
    content: none;
    border-bottom: none !important;
  }

  & .ant-menu-item:hover::after {
    content: none;
    border-bottom: none !important;
  }
`;
function CenterMenu(props) {
  //   const [locale, setLocale] = useState(props.locale);
  const [isHovered, setIsHovered] = useState(false);
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState("mail");
  const onClick = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
  };
  const history = useHistory();
  const accountTypelist = useSelector((state) => state.account.accountTypeList);
  const handleHover = () => setIsHovered(!isHovered);

  const marketlist = [
    {
      name: "CFDs",
      link: `/${localStorage.getItem("locale")}/market/cfds`,
      type: "insite",
    },
    {
      name: "Futures",
      link: `/${localStorage.getItem("locale")}/market/futures`,
      type: "insite",
    },
    {
      name: locale.t("Indices"),
      link: `/${localStorage.getItem("locale")}/market/indices`,
      type: "insite",
    },
    {
      name: locale.t("Shares"),
      link: `/${localStorage.getItem("locale")}/market/shares`,
      type: "insite",
    },
    {
      name: locale.t("Metals"),
      link: `/${localStorage.getItem("locale")}/market/metals`,
      type: "insite",
    },
    {
      name: locale.t("Energies"),
      link: `/${localStorage.getItem("locale")}/market/energies`,
      type: "insite",
    },
    {
      name: locale.t("Instrument"),
      link: `/${localStorage.getItem("locale")}/instrument`,
      type: "insite",
    },
  ];

  const accountlist = [
    {
      name: locale.t("cover.accover.title"),
      link: `/${localStorage.getItem("locale")}/accounts`,
      type: "insite",
    },
    {
      name: locale.t("cover.accstd.title"),
      link: `/${localStorage.getItem("locale")}/accounts/standard`,
      type: "insite",
    },
    {
      name: locale.t("cover.accbonus.title"),
      link: `/${localStorage.getItem("locale")}/accounts/bonus`,
      type: "insite",
    },
    {
      name: locale.t("cover.accraw.title"),
      link: `/${localStorage.getItem("locale")}/accounts/raw`,
      type: "insite",
    },
    {
      name: locale.t("cover.deposit.title"),
      link: `/${localStorage.getItem("locale")}/accounts/funding`,
      type: "insite",
    },
    {
      name: locale.t("cover.withdraw.title"),
      link: `/${localStorage.getItem("locale")}/accounts/withdrawal`,
      type: "insite",
    },

    {
      name: ` Spread & Swap`,
      link: `/${localStorage.getItem("locale")}/swap/non-islamic/Standard`,
      type: "insite",
    },
  ];
  const toolslist = [
    {
      name: locale.t("analyze"),
      link: `/${localStorage.getItem("locale")}/spread/marketresearch`,
      type: "insite",
    },

    {
      name: locale.t("MetaTrader 5"),
      link: `/${localStorage.getItem("locale")}/platforms/mt5`,
      type: "insite",
    },
    {
      name: locale.t("WebTrader 5"),
      link: `/${localStorage.getItem(
        "locale"
      )}/webterminal/version5?sever=live`,
      type: "insite",
    },

    {
      name: locale.t("fxcal_page_title"),
      link: `/${localStorage.getItem("locale")}/help/calculators`,
      type: "insite",
    },
    // {
    //   name: locale.t("economic"),
    //   link: `/${localStorage.getItem("locale")}/help/economic-calendar`,
    // },
    {
      name: locale.t("payment_option"),
      link: `/${localStorage.getItem("locale")}/payment-option`,
      type: "insite",
    },
    // {
    //   name: locale.t("cover.withdraw.title"),
    //   link: `/${localStorage.getItem("locale")}/accounts/withdrawal`,
    // },
  ];
  // const spreadlist = [
  //   {
  //     name: locale.t("symbol"),
  //     link: `/${localStorage.getItem("locale")}/spread`,
  //   },
  //   {
  //     name: locale.t("analyze"),
  //     link: `/${localStorage.getItem("locale")}/spread/marketresearch`,
  //   },
  // ];
  const companylist = [
    {
      name: locale.t("partnership"),
      link: `https://partner.iuxmarkets.com/en/`,
      type: "link",
    },
    {
      name: locale.t("startbonus"),
      link: `/${localStorage.getItem("locale")}/promotion`,
      type: "insite",
    },
    {
      name: locale.t("loyaltybonus"),
      link: `/${localStorage.getItem("locale")}/lotback#`,
      type: "insite",
    },
    {
      name: locale.t("help_cover_title"),
      link: `https://help.iuxmarkets.com/${localStorage.getItem("locale")}/`,
      type: "link",
    },
    {
      name: locale.t("about"),
      link: `/${localStorage.getItem("locale")}/about-us`,
      type: "insite",
    },
    {
      name: `Privacy & Terms`,
      link: `https://policy.iuxmarkets.com/${localStorage.getItem("locale")}`,
      type: "link",
    },
  ];
  useEffect(() => {
    if (open) {
      const handleClick = () => setOpen(false);
      window.addEventListener("click", handleClick);
      return () => window.removeEventListener("click", handleClick);
    }
  }, []);

  const onCloseMenuItem = (el) => {
    setOpen(el.key);
  };

  const onClickClose = () => {
    setOpen(false);
  };
  const onClickLink = (link, type) => {
    if (type === "link") {
      window.open(link);
    } else {
      history.push(link);
    }
  };

  return (
    <>
      <Menu
        mode="horizontal"
        className="d-flex align-items-center justify-content-end"
      >
        <Menu.Item
          key="Market"
          onClick={onCloseMenuItem}
          onMouseLeave={() => setOpen(false)}
        >
          <div>
            <div onMouseEnter={() => setOpen("market")}>
              <div
                to="#"
                className="nav-menu-head w-100 fw-bold ant-dropdown-link p-3"
              >
                {locale.t("market")}
                <DownOutlined />
              </div>
            </div>
            <MenuDropdownCustom
              onMouseLeave={() => setOpen(false)}
              style={{
                display: open === "market" ? "block" : "none",
              }}
            >
              {marketlist?.map((market, index) => (
                <div
                  className={"menu-hover-item fw-bold d-flex w-100 text-left"}
                  key={index + "market"}
                >
                  <div
                    className="nav-menu-item w-100 w-100"
                    style={{ padding: "0px 12px" }}
                    to={market?.link}
                    onClick={() => {
                      onClickLink(market?.link);
                    }}
                  >
                    {market?.name}
                    {/* {TextCapitalized(market?.name)} */}
                  </div>
                </div>
              ))}
            </MenuDropdownCustom>
          </div>
        </Menu.Item>
        <Menu.Item
          key="account"
          onClick={onCloseMenuItem}
          onMouseLeave={() => setOpen(false)}
        >
          <div>
            <div onMouseEnter={() => setOpen("account")}>
              <div
                to="#"
                className="nav-menu-head w-100 fw-bold ant-dropdown-link p-3"
              >
                {locale.t("account")} <DownOutlined />
              </div>
            </div>
            <MenuDropdownCustom
              onMouseLeave={() => setOpen(false)}
              style={{
                display: open === "account" ? "block" : "none",
              }}
            >
              <div className={"menu-hover-item fw-bold d-flex w-100 text-left"}>
                <div
                  className="nav-menu-item w-100 w-100"
                  style={{ padding: "0px 12px" }}
                  onClick={() => {
                    onClickLink(`/${localStorage.getItem("locale")}/accounts`);
                  }}
                >
                  {locale.t("cover.accover.title")}
                </div>
              </div>

              {accountTypelist
                ?.filter((e) => e.active === 1 && e.type === "real")
                .map((e, i) => (
                  <div
                    className={"menu-hover-item fw-bold d-flex w-100 text-left"}
                    key={i + "market"}
                  >
                    <div
                      className="nav-menu-item w-100 w-100"
                      style={{ padding: "0px 12px" }}
                      onClick={() => {
                        onClickLink(
                          `/${localStorage.getItem("locale")}/accounts/${
                            e.display
                          }`
                        );
                      }}
                    >
                      {e.display}
                    </div>
                  </div>
                ))}
              <div
                className={"menu-hover-item fw-bold d-flex w-100 text-left"}
                key={"funding"}
              >
                <div
                  className="nav-menu-item w-100 w-100"
                  style={{ padding: "0px 12px" }}
                  onClick={() => {
                    onClickLink(
                      `/${localStorage.getItem("locale")}/accounts/funding`
                    );
                  }}
                >
                  {locale.t("cover.deposit.title")}
                </div>
              </div>
              {/* <div
                className={"menu-hover-item fw-bold d-flex w-100 text-left"}
                key="withdrawal"
              >
                <div
                  className="nav-menu-item w-100 w-100"
                  style={{ padding: "0px 12px" }}
                  onClick={() => {
                    onClickLink(
                      `/${localStorage.getItem("locale")}/accounts/withdrawal`
                    );
                  }}
                >
                  {locale.t("cover.withdraw.title")}
                </div>
              </div> */}
              <div
                className={"menu-hover-item fw-bold d-flex w-100 text-left"}
                key=" Spread & Swap"
              >
                <div
                  className="nav-menu-item w-100 w-100"
                  style={{ padding: "0px 12px" }}
                  onClick={() => {
                    onClickLink(
                      `/${localStorage.getItem(
                        "locale"
                      )}/swap/non-islamic/Standard`
                    );
                  }}
                >
                  Spread & Swap
                </div>
              </div>
            </MenuDropdownCustom>
          </div>
        </Menu.Item>
        {/* <Menu.Item
          key="market"
          onClick={() => onCloseMenuItem()}
          onMouseLeave={() => setOpen(false)}
        >
          <div>
            <div onMouseEnter={() => setOpen("market")}>
              <div
                to="#"
                className="nav-menu-head w-100 fw-bold ant-dropdown-link p-3"
              >
                {locale.t("market")}
              </div>
            </div>
            <MenuDropdownCustom
              onMouseLeave={() => setOpen(false)}
              style={{
                display: open === "market" ? "block" : "none",
                // display: "block",
              }}
            >
              <div>
                {marketlist?.map((market, index) => (
                  <div
                    className={
                      "menu-hover-item fw-bold d-flex w-100 text-left"
                    }
                    key={index + "market"}
                  >
                    <div
                      className="nav-menu-item w-100 w-100"
                      style={{ padding: "0px 12px" }}
                      to={market?.link}
                      onClick={() => {
                        onClickLink(market?.link);
                      }}
                    >
                      {TextCapitalized(market?.name)}
                    </div>
                  </div>
                ))}
              </div>
            </MenuDropdownCustom>
          </div>
        </Menu.Item> */}
        {/* <Menu.Item
          key="account"
          onClick={() => onCloseMenuItem()}
          onMouseLeave={() => setOpen(false)}
        >
          <div>
            <div onMouseEnter={() => setOpen("account")}>
              <div
                to="#"
                className="nav-menu-head w-100 fw-bold ant-dropdown-link p-3"
              >
                {locale.t("account")}
              </div>
            </div>
            <MenuDropdownCustom
              onMouseLeave={() => setOpen(false)}
              style={{
                display: open === "account" ? "block" : "none",
              }}
            >
              {accountlist?.map((market, index) => (
                <div
                  className={"menu-hover-item fw-bold d-flex w-100 text-left"}
                  key={index + "market"}
                >
                  <div
                    className="nav-menu-item w-100 w-100"
                    style={{ padding: "0px 12px" }}
                    to={market?.link}
                    onClick={() => {
                      onClickLink(market?.link);
                    }}
                  >
                    {TextCapitalized(market?.name)}
                  </div>
                </div>
              ))}
            </MenuDropdownCustom>
          </div>
        </Menu.Item> */}
        <Menu.Item
          key="tools"
          onClick={onCloseMenuItem}
          onMouseLeave={() => setOpen(false)}
        >
          <div>
            <div onMouseEnter={() => setOpen("tools")}>
              <div
                to="#"
                className="nav-menu-head w-100 fw-bold ant-dropdown-link p-3 "
              >
                {locale.t("tools")} <DownOutlined />
              </div>
            </div>
            <MenuDropdownCustom
              onMouseLeave={() => setOpen(false)}
              style={{
                display: open === "tools" ? "block" : "none",
              }}
            >
              {" "}
              {toolslist?.map((market, index) => (
                <div
                  className={"menu-hover-item fw-bold d-flex w-100 text-left"}
                  key={index + "market"}
                >
                  <div
                    className="nav-menu-item w-100 w-100"
                    style={{ padding: "0px 12px" }}
                    to={market?.link}
                    onClick={() => {
                      onClickLink(market?.link);
                    }}
                  >
                    {market?.name}
                  </div>
                </div>
              ))}
            </MenuDropdownCustom>
          </div>
        </Menu.Item>
        {/* <Menu.Item
          key="spread"
          onClick={onCloseMenuItem}
          onMouseLeave={() => setOpen(false)}
        >
          <div>
            <div onMouseEnter={() => setOpen("spread")}>
              <div
                to={`/${localStorage.getItem("locale")}/spread`}
                className="nav-menu-head w-100 fw-bold ant-dropdown-link p-3"
              >
                {locale.t("spread")}&nbsp;
              </div>
            </div>
            <MenuDropdownCustom
              onMouseLeave={() => setOpen(false)}
              style={{
                display: open === "spread" ? "block" : "none",
              }}
            >
              {spreadlist?.map((market, index) => (
                <div
                  className={"menu-hover-item fw-bold d-flex w-100 text-left"}
                  key={index + "market"}
                >
                  <div
                    className="nav-menu-item w-100 w-100"
                    style={{ padding: "0px 12px" }}
                    to={market?.link}
                    onClick={() => {
                      onClickLink(market?.link);
                    }}
                  >
                    {market?.name}
                  </div>
                </div>
              ))}
            </MenuDropdownCustom>
          </div>
        </Menu.Item> */}
        <Menu.Item
          key="company"
          onClick={onCloseMenuItem}
          onMouseLeave={() => setOpen(false)}
        >
          <div>
            <div onMouseEnter={() => setOpen("company")}>
              <div
                to="#"
                className="nav-menu-head w-100 fw-bold ant-dropdown-link p-3"
              >
                {locale.t("company")} <DownOutlined />
              </div>
            </div>
            <MenuDropdownCustom
              onMouseLeave={() => setOpen(false)}
              style={{
                display: open === "company" ? "block" : "none",
              }}
            >
              <>
                {companylist?.map((market, index) => (
                  <div
                    className={"menu-hover-item fw-bold d-flex w-100 text-left"}
                    key={index + "market"}
                  >
                    <div
                      className="nav-menu-item w-100 w-100"
                      style={{ padding: "0px 12px" }}
                      to={market?.link}
                      onClick={() => {
                        onClickLink(market?.link, market.type);
                      }}
                    >
                      {market?.name}
                    </div>
                  </div>
                ))}

                {/* <div
                  className="menu-hover-item  fw-bold d-flex text-left"
                  key="about"
                >
                  <div
                    target={"_blank"}
                    href={`https://iuxmarketsupport.zendesk.com/hc/th`}
                    className="nav-menu-item w-100"
                    style={{ padding: "0px 12px" }}
                    onClick={() =>
                      window.open("https://iuxmarketsupport.zendesk.com/hc/th")
                    }
                  >
                    {locale.t("help_cover_title")}
                  </div>
                </div> */}
              </>
            </MenuDropdownCustom>
          </div>
        </Menu.Item>
      </Menu>
    </>
  );
}

CenterMenu.propTypes = {};

const mapStateToProps = ({ i18n, user }) => ({
  locale: i18n.locale,
  user,
});

export default connect(mapStateToProps, { setLocale, setLocation })(
  withRouter(CenterMenu)
);
