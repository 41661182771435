import React from "react";
import { Row } from "antd";
const locale = require("react-redux-i18n").I18n;
export default function SectionOne() {
  return (
    <section key={1} className="bg-black">
      <div className="container">
        <div className="col-md" style={{ padding: "3rem 0" }}>
          <Row className="d-flex">
            <div className="col-md-4 mb-40">
              <div className="text-center ">
                <img
                  src={"/assets/images/icons/average.svg"}
                  className="mb-4 f-40"
                  alt="..."
                />
                <p className="text-center text-white fs-20">
                  {locale.t("iux_forex_market.title_1")}
                </p>
              </div>
            </div>
            <div className="col-md-4 icon__block mb-40">
              <div className="text-center ">
                <img
                  src={"/assets/images/icons/order-persecond.svg"}
                  className="mb-4 f-40"
                  alt="..."
                />
                <p className="text-center text-white fs-20">
                  {locale.t("iux_forex_market.title_2")}
                </p>
              </div>
            </div>
            <div className="col-md-4 icon__block mb-40">
              <div className="text-center ">
                <img
                  src={"/assets/images/icons/datacentre.svg"}
                  className="mb-4 f-40"
                  alt="..."
                />
                <p className="text-center text-white fs-20 white-space-pre-line">
                  {locale.t("iux_forex_market.title_3")}
                </p>
              </div>
            </div>
          </Row>
        </div>
      </div>
    </section>
  );
}
