import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { StyledAccountMain } from "../../components";
import { Link, Route, Switch } from "react-router-dom";
import TableCalendar from "../../components/tablecalendar/TableCalendar";
import TableHoliday from "../../components/tableholiday/TableHoliday";
import { get } from "../../helper/request";
import Axios from "axios";
import styled from "styled-components";
import { getHolidayList } from "../../api/holiday";
import { Skeleton } from "antd";
import moment from "moment";
import { CheckLanguageMoment } from "../../helper/countryLangMoment";
import Tabletest from "../../components/tablecalendar/Tabletest";
import { renderApiDomain } from "../../helper/renderApiDomain";
const locale = require("react-redux-i18n").I18n;
const MenuItem = styled.div`
  color: ${(props) => props.color};
  white-space: pre;
  padding: 0.8rem 0rem;
  text-align: center;
  width: 100px;
  cursor: pointer;
  font-weight: ${(props) => props.fontWeight};
  &:after {
    display: block;
    content: "";
    margin-top: 0.8rem;
    border-bottom: 4px solid #1cb894;
    transform: scaleX(${(props) => (props.borderBottom ? 1 : 0)});
    transition: transform 250ms ease-in-out;
  }
  &:hover:after {
    transform: scaleX(1);
  }
`;
export const Economic = (props) => {
  const pathName = window.location.pathname;

  const [select, setSelect] = useState("MT5");
  const [isIdex, setIndex] = React.useState(5);
  const [listmenu, setListmenu] = useState([]);
  const [datefromto, setDatefromto] = useState({
    from: moment().format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
  });
  const [dataCalendar, setDataCalendar] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchEconomic = async (date) => {
    try {
      setIsLoading(true);
      const { data } = await get(
        renderApiDomain() +
          `calendar?from=${datefromto.from}&to=${datefromto.to}`
      );
      if (data.data) {
        setDataCalendar(data.data);
        setIsLoading(false);
      } else {
        setDataCalendar([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    // fetchEconomic();
    let listmenuDay = [
      moment().add(1, "days"),
      moment().add(2, "days"),
      moment().add(3, "days"),
      moment().add(4, "days"),
    ];
    setListmenu(listmenuDay);
  }, []);
  useEffect(() => {
    fetchEconomic();
  }, [datefromto]);
  const onChangeDate = (index) => {
    setIndex(index);
    setDatefromto({
      from: moment()
        .add(index + 1, "days")
        .format("YYYY-MM-DD"),
      to: moment()
        .add(index + 1, "days")
        .format("YYYY-MM-DD"),
    });
  };

  return (
    <>
      <StyledAccountMain style={{ borderRadius: 20 }}>
        <div className="">
          <span className="text-header-account-wallet ">
            {locale.t("calendar_title")}
          </span>
          <span className="fs-9">({locale.t("calendar_subtitle")})</span>

          <div className="sub-menu-page-user mt-4" key={2}>
            <div className="container">
              <div className="d-flex overflow-auto scroll-menu-hide">
                <MenuItem
                  onClick={() => {
                    setDatefromto({
                      from: moment().add(-1, "days").format("YYYY-MM-DD"),
                      to: moment().format("YYYY-MM-DD"),
                    });
                    setIndex(5);
                  }}
                  color={5 === isIdex ? "#000" : "#8D8D8D"}
                  borderBottom={5 === isIdex}
                  fontWeight={5 === isIdex ? 700 : "unset"}
                >
                  {locale.t("today")}
                </MenuItem>

                {listmenu.map((e, i) => (
                  <MenuItem
                    key={i}
                    onClick={() => onChangeDate(i)}
                    color={i === isIdex ? "#000" : "#8D8D8D"}
                    borderBottom={i === isIdex}
                    fontWeight={i === isIdex ? 700 : "unset"}
                  >
                    {moment(e)
                      .locale(
                        CheckLanguageMoment(localStorage.getItem("locale"))
                      )
                      .format("DD MMM")}
                  </MenuItem>
                ))}
              </div>

              {isLoading ? (
                <Skeleton active={isLoading} paragraph={{ rows: 10 }} />
              ) : (
                <TableCalendar dataCalendar={dataCalendar} />
              )}
            </div>
          </div>
        </div>
      </StyledAccountMain>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Economic);
