export default {
  th: {
    "In the future": "ในอนาคต",
    Now: "ตอนนี้",
    "minute ago": "นาทีที่แล้ว",
    "minutes ago": "นาทีที่แล้ว",
    "hour ago": "ชั่วโมงที่แล้ว",
    "hours ago": "ชั่วโมงที่แล้ว",
    "day ago": "วันที่แล้ว",
    "days ago": "วันที่แล้ว",
    "week ago": "สัปดาห์ที่แล้ว",
    "weeks ago": "สัปดาห์ที่แล้ว",
    "month ago": "เดือนที่แล้ว",
    "months ago": "เดือนที่แล้ว",
    "year ago": "ปีที่แล้ว",
    "years ago": "ปีที่แล้ว",
  },
  lo: {
    "In the future": "ເພີ້ມຕົນ",
    Now: "ດຽວ",
    "minute ago": "ນາທີກ່ອນ",
    "minutes ago": "ນາທີກ່ອນ",
    "hour ago": "ຊົ່ວໂມງກ່ອນ",
    "hours ago": "ຊົ່ວໂມງກ່ອນ",
    "day ago": "ມື້ກ່ອນ",
    "days ago": "ມື້ກ່ອນ",
    "week ago": "ອາທິກ່ອນ",
    "weeks ago": "ອາທິກ່ອນ",
    "month ago": "ເດືອນກ່ອນ",
    "months ago": "ເດືອນກ່ອນ",
    "year ago": "ປີກ່ອນ",
    "years ago": "ປີກ່ອນ",
  },
  en: {
    "In the future": "In the future",
    Now: "Now",
    "minute ago": "minute ago",
    "minutes ago": "minutes ago",
    "hour ago": "hour ago",
    "hours ago": "hours ago",
    "day ago": "day ago",
    "days ago": "days ago",
    "week ago": "week ago",
    "weeks ago": "weeks ago",
    "month ago": "month ago",
    "months ago": "months ago",
    "year ago": "year ago",
    "years ago": "years ago",
  },

  hi: {
    "In the future": "भविष्य में",
    Now: "अब",
    "minute ago": "मिनट पहले",
    "minutes ago": "मिनट पहले",
    "hour ago": "घंटे पहले",
    "hours ago": "घंटे पहले",
    "day ago": "दिन पहले",
    "days ago": "दिन पहले",
    "week ago": "सप्ताह पहले",
    "weeks ago": "सप्ताह पहले",
    "month ago": "महीना पहले",
    "months ago": "महीना पहले",
    "year ago": "साल पहले",
    "years ago": "साल पहले",
  },
  ms: {
    "In the future": "Di masa hadapan",
    Now: "Sekarang",
    "minute ago": "minit yang lalu",
    "minutes ago": "minit yang lalu",
    "hour ago": "jam yang lalu",
    "hours ago": "jam yang lalu",
    "day ago": "hari yang lalu",
    "days ago": "hari yang lalu",
    "week ago": "minggu yang lalu",
    "weeks ago": "minggu yang lalu",
    "month ago": "bulan yang lalu",
    "months ago": "bulan yang lalu",
    "year ago": "tahun yang lalu",
    "years ago": "tahun yang lalu",
  },
  id: {
    "In the future": "Di masa depan",
    Now: "Sekarang",
    "minute ago": "menit yang lalu",
    "minutes ago": "menit yang lalu",
    "hour ago": "jam yang lalu",
    "hours ago": "jam yang lalu",
    "day ago": "hari yang lalu",
    "days ago": "hari yang lalu",
    "week ago": "minggu yang lalu",
    "weeks ago": "minggu yang lalu",
    "month ago": "bulan yang lalu",
    "months ago": "bulan yang lalu",
    "year ago": "tahun yang lalu",
    "years ago": "tahun yang lalu",
  },
  fil: {
    "In the future": "Sa hinaharap",
    Now: "Ngayon",
    "minute ago": "minuto ang nakalipas",
    "minutes ago": "minuto ang nakalipas",
    "hour ago": "oras ang nakalipas",
    "hours ago": "oras ang nakalipas",
    "day ago": "araw ang nakalipas",
    "days ago": "araw ang nakalipas",
    "week ago": "linggo ang nakalipas",
    "weeks ago": "linggo ang nakalipas",
    "month ago": "buwan ang nakalipas",
    "months ago": "buwan ang nakalipas",
    "year ago": "taon ang nakalipas",
    "years ago": "taon ang nakalipas",
  },
  vi: {
    "In the future": "Trong tương lai",
    Now: "Bây giờ",
    "minute ago": "phút trước",
    "minutes ago": "phút trước",
    "hour ago": "giờ trước",
    "hours ago": "giờ trước",
    "day ago": "ngày trước",
    "days ago": "ngày trước",
    "week ago": "tuần trước",
    "weeks ago": "tuần trước",
    "month ago": "tháng trước",
    "months ago": "tháng trước",
    "year ago": "năm trước",
    "years ago": "năm trước",
  },
  ja: {
    "In the future": "未来",
    Now: "現在",
    "minute ago": "分前",
    "minutes ago": "分前",
    "hour ago": "時間前",
    "hours ago": "時間前",
    "day ago": "日前",
    "days ago": "日前",
    "week ago": "週前",
    "weeks ago": "週前",
    "month ago": "月前",
    "months ago": "月前",
    "year ago": "年前",
    "years ago": "年前",
  },
  es: {
    "In the future": "En el futuro",
    Now: "Ahora",
    "minute ago": "hace un minuto",
    "minutes ago": "hace minutos",
    "hour ago": "hace una hora",
    "hours ago": "hace horas",
    "day ago": "hace un día",
    "days ago": "hace días",
    "week ago": "hace una semana",
    "weeks ago": "hace semanas",
    "month ago": "hace un mes",
    "months ago": "hace meses",
    "year ago": "hace un año",
    "years ago": "hace años",
  },
};
