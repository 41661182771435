import React from "react";
import { Button, Row, Col, Card, Divider } from "antd";
import { Link } from "react-router-dom";

const locale = require("react-redux-i18n").I18n;
function FooterBanner() {
  return (
    <div style={{ backgroundColor: "#000" }} className="container-lg ">
      <Row
        style={{
          backgroundColor: "#171717",
          borderRadius: 6,
          paddingTop: 82,
        }}
        // gutter={16}
        className="d-flex justify-content-between p-2  align-items-center"
      >
        <Col className="col-md main-resonsive-banner text-center ">
          {" "}
          <Link to={`/${localStorage.getItem("locale")}/accounts/bonus`}>
            <Row style={{ display: "inline-block" }}>
              <Col>
                <h1 className="d-flex justify-content-center h1-responsive24">
                  35%
                </h1>
              </Col>
              <Col>
                <span className="subbanner-text">
                  {" "}
                  {locale.t("section2_label_1")}
                </span>
              </Col>
            </Row>{" "}
          </Link>
        </Col>

        <Divider
          type="vertical"
          style={{ height: 40, background: "#8D8D8D" }}
          className="d-none d-sm-block"
        />
        <Divider
          style={{ minWidth: "auto", background: "#8D8D8D", width: 60 }}
          orientation="center"
          className="d-sm-none mr-auto ml-auto"
        />

        <Col className="col-md  main-resonsive-banner text-center ">
          <Link to={`/${localStorage.getItem("locale")}/accounts`}>
            <Row style={{ display: "inline-block" }}>
              <Col>
                <h1 className="d-flex justify-content-center h1-responsive24">
                  0.0
                </h1>
              </Col>
              <Col>
                <span className="subbanner-text">
                  {locale.t("section2_label_2")}
                </span>
              </Col>
            </Row>
          </Link>
        </Col>

        {/* <Divider
          type="vertical"
          style={{ height: 40, background: "#8D8D8D" }}
          className="d-none d-sm-block"
        />
        <Divider
          style={{ minWidth: "auto", background: "#8D8D8D", width: 60 }}
          orientation="center"
          className="d-sm-none mr-auto ml-auto"
        />
        <Col className="col-md main-resonsive-banner text-center ">
          <Link to={`/${localStorage.getItem("locale")}/accounts`}>
            <Row style={{ display: "inline-block" }}>
              <Col>
                <h1 className="d-flex justify-content-center h1-responsive24">
                  1 : 3000
                </h1>
              </Col>
              <Col>
                <span className="subbanner-text">
                  {locale.t("section2_label_3")}
                </span>
              </Col>
            </Row>
          </Link>
        </Col> */}
        <Divider
          type="vertical"
          style={{ height: 40, background: "#8D8D8D" }}
          className="d-none d-sm-block"
        />
        <Divider
          style={{ minWidth: "auto", background: "#8D8D8D", width: 60 }}
          orientation="center"
          className="d-sm-none mr-auto ml-auto"
        />
        <Col className="col-xs col-md main-resonsive-banner text-center ">
          <Link to={`/${localStorage.getItem("locale")}/accounts`}>
            <Row style={{ display: "inline-block" }}>
              <Col>
                <h1 className="d-flex justify-content-center h1-responsive24">
                  STP
                </h1>
              </Col>
              <Col>
                <span className="subbanner-text">
                  {locale.t("section2_label_4")}
                </span>
              </Col>
            </Row>
          </Link>
        </Col>
        <Divider
          type="vertical"
          style={{ height: 40, background: "#8D8D8D" }}
          className="d-none d-sm-block"
        />
        <Divider
          style={{ minWidth: "auto", background: "#8D8D8D", width: 60 }}
          orientation="center"
          className="d-sm-none mr-auto ml-auto"
        />
        <Col className=" col-md text-center">
          <Link to={`/${localStorage.getItem("locale")}/accounts`}>
            <Row style={{ display: "inline-block" }}>
              <Col>
                <h1 className="d-flex justify-content-center h1-responsive24">
                  FREE SWAP
                </h1>
              </Col>
              <Col>
                <span className="subbanner-text">
                  {locale.t("section2_label_5")}
                </span>
              </Col>
            </Row>
          </Link>
        </Col>
      </Row>
      <div className="p-3 p-lg-0 p-xl-0 container mx-auto container-text-center ">
        <div className="pt-0 pt-md-5 pt-lg-5 pt-xl-5">
          <h2 className="fw-normal text-white text-center text-uppercase fs-32">
            {locale.t("land_section_1_1")}
          </h2>
          <p
            className="mt-3  text-center text-white "
            style={{ whiteSpace: "pre-line" }}
          >
            {locale.t("land_section_1_2")}
          </p>
        </div>
      </div>
    </div>
  );
}

export default FooterBanner;
