import React, { useCallback, useEffect, useRef, useState } from "react";
import { StyledAccountMain } from "../../../../components";
import { Skeleton } from "antd";
import TableHoliday from "../../../../components/tableholiday/TableHoliday";
import { getHolidayList } from "../../../../api/holiday";
import ModalReport from "../Report/ModalReport/ModalReport";
import CardReportDark from "../Report/ModalReport/CardReport";
import { toPng } from "html-to-image";
import CardReportLight from "../Report/ModalReport/CardReportLight";

const locale = require("react-redux-i18n").I18n;

function Holiday() {
  const [isLoadingHoliday, setIsLoadingHoliday] = useState(false);
  const [dataHolidayList, setDataHolidayList] = useState([]);

  const fetchHolidayList = async () => {
    try {
      const { data } = await getHolidayList();
      data.data.map((e, i) => (e.key = i + 1));

      setDataHolidayList(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchHolidayList();
  }, []);

  return (
    <div>
      {" "}
      <StyledAccountMain style={{ borderRadius: 20 }}>
        <div className=" position-relative">
          <span className="text-header-account-wallet ">
            {locale.t("holiday_calendar")}
          </span>
          <span className="fs-9">&nbsp;({locale.t("calendar_subtitle")})</span>
          <div></div>
          <div className="sub-menu-page-user mt-4" key={2}>
            <div className="container">
              {isLoadingHoliday ? (
                <Skeleton active={isLoadingHoliday} paragraph={{ rows: 10 }} />
              ) : (
                <TableHoliday dataCalendar={dataHolidayList} />
              )}
            </div>
          </div>
        </div>
      </StyledAccountMain>
    </div>
  );
}

export default Holiday;
