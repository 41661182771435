import React from "react";
import PaymentOption from "../../components/payment/payment-option";
import FooterLandingPage from "../../components/footer-landing-page/FooterDark";
const locale = require("react-redux-i18n").I18n;
function index() {
  return (
    <div>
      <PaymentOption />
      <FooterLandingPage locale={locale} />
    </div>
  );
}

export default index;
