import React, { useState, useEffect } from "react";
import { Alert, Divider, Skeleton, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import BoxScanQrCode from "../../../../components/boxpayment/BoxScanQrCode";
import { postPayment } from "../../../../helper/request";
import { renderApiDomain } from "../../../../helper/renderApiDomain";

const locale = require("react-redux-i18n").I18n;
const Hoventon = (props) => {
  const [prompt, setPrompt] = useState(null);

  useEffect(() => {
    fetchHoventon();
  }, [props.amount]);
  const fetchHoventon = async () => {
    try {
      const request = {
        amount: (props.amount / props.rate).toFixed(2),
        amount_thb: props.amount,
        account_trade_id: props.account.id,
      };
      const { data } = await postPayment(
        `${renderApiDomain()}deposit/hoventon`,
        request
      );
      var blob = data;
      var str = btoa(String.fromCharCode.apply(null, new Uint8Array(blob)));
      setPrompt(`data:image/octet-stream;base64,${str}`);
    } catch (error) {
      var isblob = error.response.data;

      var textDecoder = new TextDecoder("utf-8");
      var jsonString = textDecoder.decode(new Uint8Array(isblob));

      var responseObject = JSON?.parse(jsonString);

      message.error(responseObject.error);
    }
  };
  return (
    <>
      {prompt ? (
        <>
          <BoxScanQrCode
            isQrcode={prompt}
            account={props.account}
            rate={props.rate}
            paymentSelected={props.paymentSelected}
            amount={props.amount}
          />
          {/* <Alert
            message={locale.t("deposit_info.prompt.title")}
            description={locale.t("deposit_info.prompt.description")}
            type="info"
          /> */}
          {/* <Divider /> */}
          {/* <div className="text-center">
            <div className="col">
              <img
                src={prompt}
                alt="qr code"
                width="100%"
                style={{ maxWidth: "400px" }}
              />
            </div>
          </div> */}
          {/* <Divider /> */}
          {/* <Button
              className="btn-primary-outline"
              onClick={() => {
                props.onBackStep();
              }}
            >
              Prev
            </Button> */}
        </>
      ) : (
        <Skeleton.Button
          active={true}
          size={300}
          shape={"square"}
          block={false}
        />
      )}
    </>
  );
};

export default Hoventon;
