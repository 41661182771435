import { Button, Modal, Radio } from "antd";
import React, { useState } from "react";
import CardReportDark from "./CardReport";
import CardReportLight from "./CardReportLight";
import styled from "styled-components";
const locale = require("react-redux-i18n").I18n;
const RadioOutside = styled.div`
  width: 16px;
  height: 16px;
  background: transparent;
  border-radius: 50%;
  border: 1px solid ${(props) => (props.active ? "#1CB894" : "#f2f2f2")};
  position: relative;
`;

const RadioInside = styled.div`
  position: absolute;
  left: 50%;
  top:50%
  transform:translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background: ${(props) => (props.active ? "#1CB894" : "#fff")};
  border-radius: 50%;
`;
function ModalReport(props) {
  const {
    isOpen,
    onClose,
    dataReport,
    onDownload,
    imagePreviewLight,
    imagePreviewDark,
  } = props;
  const [valueType, setValueType] = useState(2);
  const onChangeType = (event) => {
    setValueType(event.target.value);
  };
  const onSubmit = () => {
    onDownload(valueType);
  };
  return (
    <Modal
      className="w-modal width-modal-export user user-change"
      closable={true}
      visible={isOpen}
      title={false}
      onCancel={() => {
        onClose();
      }}
      footer={false}
    >
      <div className="">
        <div className="text-center fw-700 fs-20">
          {locale.t("please_Select")}
        </div>
        {/* <Radio.Group onChange={onChangeType} value={valueType}> */}
        <div className="row mt-4">
          {" "}
          <div className="col-12 col-sm-6">
            <img
              src={imagePreviewLight}
              alt="light"
              width={"100%"}
              className="box-img-shadow-light"
            />
            <div
              className="d-flex justify-content-center cursor-pointer"
              onClick={() => setValueType(2)}
            >
              <div className="d-flex align-items-center mt-2">
                <RadioOutside active={valueType === 2}>
                  <RadioInside active={valueType === 2} />
                </RadioOutside>
                <div className="fs-12"> &nbsp;{locale.t("light")}</div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 mt-4 mt-md-0">
            <img
              src={imagePreviewDark}
              alt="dark"
              width={"100%"}
              className="box-img-shadow-dark"
            />
            <div
              className="d-flex justify-content-center cursor-pointer"
              onClick={() => setValueType(1)}
            >
              <div className="d-flex align-items-center mt-2">
                <RadioOutside active={valueType === 1}>
                  <RadioInside active={valueType === 1} />
                </RadioOutside>
                <span className="fs-12"> &nbsp;{locale.t("dark")}</span>
              </div>
            </div>
          </div>
        </div>
        {/* </Radio.Group> */}
        <div className="d-flex mt-4 justify-content-end">
          <Button
            className="btn-primary-new-iux ml-2 "
            onClick={() => onSubmit()}
          >
            {locale.t("export")}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalReport;
