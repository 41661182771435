import React from "react";
import { Divider } from "antd";
import { Link } from "react-router-dom";
import { SelectOutlined } from "@ant-design/icons";
const buttonStyle = {
  color: "black",
};

const FooterLandingPage = (props) => {
  return (
    <div className="text-center landingPage-footerSelection">
      <div className="landingPage-flex-block container pt-4">
        <div className="col-md-9 p-0-important">
          <div className="row">
            <div style={buttonStyle} type="link" className="col-md-landingPage">
              <Link
                className="text-white text-left"
                to={`/${localStorage.getItem("locale")}/about-us`}
              >
                {props.locale.t("about")}
              </Link>
            </div>
            <div style={buttonStyle} type="link" className="col-md-landingPage">
              <Link
                className="text-white text-left"
                to={`/${localStorage.getItem("locale")}/contact/`}
              >
                {props.locale.t("contact")}
              </Link>
            </div>
            <div style={buttonStyle} type="link" className="col-md-landingPage">
              <Link
                className="text-white text-left"
                to={`/${localStorage.getItem("locale")}/contact/condition`}
              >
                {props.locale.t("condition")}
              </Link>
            </div>
            <div style={buttonStyle} type="link" className="col-md-landingPage">
              <Link
                className="text-white text-left"
                to={`/${localStorage.getItem("locale")}/contact/security`}
              >
                {props.locale.t("security")}
              </Link>
            </div>
            <div style={buttonStyle} type="link" className="col-md-landingPage">
              <Link
                className="text-white text-left"
                to={`/${localStorage.getItem("locale")}/contact/policy`}
              >
                {props.locale.t("policy")}
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-3 p-0-important">
          <div className="row landing-float-RL">
            {/* <Button style={buttonStyle} type="link" size="large" ghost>
                        <p>TH / ENG</p>
                    </Button> */}
            <div
              style={buttonStyle}
              className="lading-icon-socail"
              type="link"
              size="large"
              ghost
            >
              <a href="https://www.instagram.com/iuxmarkets/" target="blank">
                <img
                  className="iux-contactsocial landingPage-footer-iconSocia"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/v1/instagram-brands.png"
                  }
                  alt="iuxmarket"
                />
              </a>
            </div>
            <div
              style={buttonStyle}
              className="lading-icon-socail"
              type="link"
              size="large"
              ghost
            >
              <a
                href=" https://www.youtube.com/@iuxmarketsglobal"
                target="blank"
              >
                <img
                  className="iux-contactsocial landingPage-footer-iconSocia"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/v1/youtube-brands.png"
                  }
                  alt="iuxmarket"
                />
              </a>
            </div>
            <div
              style={buttonStyle}
              className="lading-icon-socail"
              type="link"
              size="large"
              ghost
            >
              <a href="https://www.facebook.com/IUXMarkets/" target="blank">
                <img
                  className="iux-contactsocial landingPage-footer-iconSocia"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/v1/facebook-square-brands.png"
                  }
                  alt="iuxmarket"
                />
              </a>
            </div>
            <div
              style={buttonStyle}
              className="lading-icon-socail"
              type="link"
              size="large"
              ghost
            >
              <a
                href="https://www.linkedin.com/company/iux-market/"
                target="blank"
              >
                <img
                  className="iux-contactsocial landingPage-footer-iconSocia"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/v1/linkedin-brands.png"
                  }
                  alt="iuxmarket"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <Divider />
      </div>
      <div
        style={{
          color: "#7998af",
          paddingBottom: 16,
          textAlign: "left",
          whiteSpace: "break-spaces",
        }}
        className="container"
      >
        {props.locale.t("website_power_by")}
        <a
          href="https://mwaliregistrar.com/list_of_entities/authorised_trust_companies"
          target="blank"
          className="text-primary-new-iux"
        >
          T2023172
        </a>
        <div style={{ height: 20 }}></div>
        <div id="footer-warning">{props.locale.t("website_warning")}</div>
        <br />
        {props.locale.t("copyright")}
      </div>
    </div>
  );
};

export default FooterLandingPage;
