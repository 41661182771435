export default {
  th: {
    account_create_demo: `บัญชีสาธิตของคุณคือการลงทุนที่ปราศจากความเสี่ยงซึ่งคุณสามารถฝึกการซื้อขายกลยุทธ์ทดสอบและรู้สึกสะดวกสบายกับแพลตฟอร์มของเรา`,
    account_create_success_islamic: `บัญชีนี้ถูกตั้งค่าอัตโนมัติเป็นสถานะ Swap-Free กรุณาบันทึกรหัสผ่านการซื้อขายของคุณทันที ไม่สามารถส่งทางอีเมลเนื่องจากเหตุผลด้านความปลอดภัย`,
    account_create_success: `ไม่มีค่าใช้จ่ายค้างคืนอีกต่อไป สถานะการแลกเปลี่ยนฟรีของคุณถูกกำหนดโดยกิจกรรมการซื้อขายของคุณ เพื่อรักษาสถานะการแลกเปลี่ยนที่ใช้งานอยู่คุณต้องซื้อขายส่วนใหญ่ภายในวัน`,
    open_an_account: "เปิดบัญชี",
    low_risk: "	ความเสี่ยงต่ำ	",
    high_risk: "	มีความเสี่ยงสูง	",
    medium_risk: "	ความเสี่ยงปานกลาง	",
    standard_account_description:
      "	ที่นิยมมากที่สุด! บัญชีที่ยอดเยี่ยมสำหรับผู้ค้าทุกประเภท	",
    standard_bonus_account_description:
      "	บัญชีการดำเนินการทันทีของเรา กรอกที่ยอดเยี่ยมโดยไม่มีคณะกรรมการการซื้อขาย	",
    raw_account_description: "	สเปรดดิบต่ำสุดและค่าคอมมิชชั่นคงที่ต่ำ	",
    pro_account_description: "	บัญชีมืออาชีพพร้อมค่าคอมมิชชั่นฟรี 0%	",
    button: {
      title: `ฝากเงินทันที`,
    },
    cover: {
      accover: {
        title: `ภาพรวมบัญชี`,
        description: `IUX Markets ข้อเสนอสเปรดต่ำและกลุ่มผลิตภัณฑ์ขนาดใหญ่รวมอยู่ในบัญชีทุกประเภท เหมาะสำหรับเทรดเดอร์มือใหม่และผู้มีประสบการณ์เพียงเลือกแพลตฟอร์มการซื้อขายและรูปแบบการกำหนดราคาที่คุณต้องการเพื่อเริ่มต้น`,
      },
      accstd: {
        title: `Standard`,
        description: `ให้คุณได้เปรียบด้วยสเปรดที่ต่ำบนแพลตฟอร์มการซื้อขายที่ได้รับความนิยมมากที่สุดของโลกด้วยเครื่องมือรวบรวมข้อมูลบริดจ์ที่เป็นกรรมสิทธิ์ของเราซึ่งผสมผสานการเลือกผู้ให้บริการราคาอันดับ 1`,
      },
      acccnt: {
        title: `Cent Account`,
        description: `บัญชีสำหรับนักลงทุนเริ่มต้นโดยใช้หน่วยลงทุนน้อยกว่า 100 เท่า เหมาะสำหรับการเทรดทุกรูปแบบ คุณสามารถใช้กลยุทธ์การซื้อขายได้ตามที่ต้องการ ด้วยการที่คุณได้รับสเปรดที่ต่ำมากเช่นเดียวกับบัญชี Standard ทำให้คุณมีโอกาสชนะได้มากขึ้น`,
      },
      accecn: {
        title: `ECN `,
        description: `เทรดง่ายๆ ด้วยบัญชี IUX Markets Standard สัมผัสกับสิทธิประโยชน์และการกำหนดราคา Low Spread ของเราด้วยสเปรดที่รวมทุกอย่างแล้วด้วยสเปรดที่ต่ำที่สุด`,
      },
      accraw: {
        title: `Raw `,
        description: `เทรดง่ายๆ ด้วยบัญชี IUX Markets Raw สัมผัสกับสิทธิประโยชน์และการกำหนดราคา Low Spread ของเราด้วยสเปรดที่รวมทุกอย่างแล้วด้วยสเปรดที่ต่ำที่สุด `,
      },
      accpro: {
        title: `Pro `,
        description: `เทรดง่ายๆ ด้วยบัญชี IUX Markets Pro สัมผัสกับสิทธิประโยชน์และการกำหนดราคา Low Spread ของเราด้วยสเปรดที่รวมทุกอย่างแล้วด้วยสเปรดที่ต่ำที่สุด `,
      },
      accbonus: {
        title: `Standard+`,
        description: `เทรดง่ายๆ ด้วยบัญชี IUX Markets Standard สัมผัสกับสิทธิประโยชน์และการกำหนดราคา Low Spread ของเราด้วยสเปรดที่รวมทุกอย่างแล้วไม่มีค่าคอมมิชชั่น`,
      },
      deposit: {
        title: `การเพิ่มเงินไปยังบัญชี`,
        description: `IUX Markets มีตัวเลือกการเพิ่มเงินไปยังบัญชีกว่า 15 แบบใน 10 สกุลเงินหลักที่แตกต่างกัน ฝากเงินทันทีและฟรีค่าธรรมเนียมด้วยวิธีการที่หลากหลาย`,
      },
      withdraw: {
        title: `การถอนเงิน`,
        description: `เทรดง่ายๆ ด้วยบัญชี IUX Markets Standard สัมผัสกับสิทธิประโยชน์และการกำหนดราคา Low Spread ของเราด้วยสเปรดที่รวมทุกอย่างแล้วไม่มีค่าคอมมิชชั่น`,
      },
    },
    menu: {
      menu_1: "ภาพรวมบัญชี",
    },
    section_1: {
      title: "ทำไมต้องเลือกบัญชี Standard ของเรา?",
      description:
        "เราเสนอสเปรดที่ต่ำที่สุดเท่าที่จะเป็นไปได้ สเปรด EUR / USD โดยเฉลี่ยของเราคือ 0.2 PIPs โดยไม่มีค่าคอมมิชชั่น ด้วยแหล่งที่มาของการกำหนดราคาโดยรวมจากแหล่งที่มาของเกรดสถาบันมากถึง 25 แหล่งบัญชี IUX Markets Standard ถูกสร้างขึ้นสำหรับผู้ค้ารายวันนักเทรดและที่ปรึกษาผู้เชี่ยวชาญ แพลตฟอร์ม IUX Markets  Meta Trader5 เป็นตัวเลือกที่ต้องการสำหรับเทรดเดอร์ประจำวันและนักเทรดทั่วโลก ..",
    },
    std: {
      box_1: {
        titile_1: `CFDs เทรด`,
        titile_2: `ราคาสเปรดต่ำ`,
        titile_3: `สเปรดเริ่มต้นที่ 0.2 PIPs`,
        titile_4: `ดำเนินการคำสั่งซื้ออย่างรวดเร็ว`,
        titile_5: `1:3000 เลเวอเรจ`,
        titile_6: `สภาพคล่องสูง`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: ` Standard เสนอค่าสเปรดที่ต่ำที่สุด สเปรด EUR / USD โดยเฉลี่ยของเราคือ 0.2 PIPs โดยไม่มีค่าคอมมิชชั่นในการซื้อขาย
        ตลาดที่มีสภาพคล่องสูงและการดำเนินการที่รวดเร็ว บัญชี IUX Markets Low Spread 
        สร้างขึ้นสำหรับผู้ค้ารายวัน, และที่ปรึกษาผู้เชียวชาญ `,
        titile_2: `เซิร์ฟเวอร์  Meta Trader5 Low Spread ของเราตั้งอยู่ในศูนย์ข้อมูล Equinix LD4 
        ในลอนดอนทำให้คุณเข้าใกล้ศุนย์ข้อมูลบริดจ์ของเรามากขึ้นช่วยให้ขจัดความหน่วงแฝงที่ไม่จำเป็นออกไป แพลตฟอร์ม IUX Markets 
         Meta Trader5 เป็นตัวเลือกที่ดีสำหรับนักเทรดรายวันและนักเทรดทั่วโลก`,
      },
      section_2: {
        titile_1: `ราคาสเปรดต่ำ`,
        description_1: `IUX Markets สามารถเสนอราคาตลาดและเงื่อนไขการซื้อขายที่ดีที่สุดผ่านแพลตฟอร์ม  MT5 โดยให้บริการลูกค้าด้วยราคาสเปรดที่ต่ำ \nสภาพแวดล้อมการกำหนดราคาสเปรดต่ำช่วยให้คุณสามารถซื้อขายแลกเปลี่ยนกำหนดราคาได้ในระดับเดียวกับสถานที่ดำเนินการคำสั่งซื้อชั้นนำของโลก ราคาสตรีมมิง (ESP) ที่ดำเนินการจะถูกส่งจากผู้ให้บริการการกำหนดราคาของ IUX Markets และเสนอให้กับลูกค้าของเราโดยไม่มีโต๊ะซื้อขาย การปรับราคาหรือใบเสนอราคา IUX Markets เป็นผู้ให้บริการ CFDs ที่เป็นทางเลือกสำหรับนักเทรดจำนวนมากรวมถึง scalper และผู้ใช้ robots ที่ต้องการสเปรดที่ต่ำและการดำเนินการที่ดีที่สุดเท่าที่จะเป็นไปได้`,
      },
      section_3: {
        titile_1: `ดำเนินการคำสั่งซื้ออย่างรวดเร็ว`,
        description_1: `เซิร์ฟเวอร์ IUX Markets  Meta Trader5 ตั้งอยู่ในศูนย์ข้อมูล Equinix LD4 ในลอนดอน ศูนย์ข้อมูล NY4 คือระบบนิเวศทางการเงินเป็นที่ตั้งของบริษัทด้านการซื้อและขายการแลกเปลี่ยน มีสถานที่ซื้อขายข้อมูลตลาดและผู้ให้บริการมากกว่า 600 แห่ง เซิร์ฟเวอร์  Meta Trader5 เชื่อมต่อกับเครือข่ายการสื่อสารของเราเพื่อให้แน่ใจว่าจะมีเวลาแฝงที่ต่ำและการดำเนินการซื้อขายของคุณอย่างรวดเร็วผ่านสภาพแวดล้อมการซื้อขายของ IUX Markets เซิร์ฟเวอร์ซื้อขาย IUX Markets  Meta Trader5  มีเวลาแฝงน้อยกว่า 1 มิลลิวินาทีไปยังผู้ให้บริการ VPS หลัก ไม่ว่าจะตั้งอยู่ในศูนย์ข้อมูล NY4 หรือผ่านทางสายแบบเฉพาะไปยังศูนย์ข้อมูล`,
        titile_2: `สเปรดเริ่มที่ 0.2 PIPs`,
        description_2: `IUX Markets มีสเปรดที่แคบที่สุดของโบรกเกอร์ CFDs ทั้งหมดทั่วโลก สเปรดเริ่มต้นที่ 0.2 PIPs บนแพลตฟอร์ม  Meta Trader5 โดยค่าเฉลี่ยสเปรดของ EURUSD อยู่ที่ 0.2 PIPs ตลอด 24 ชั่วโมงทุกวัน ปัจจุบันโดยเฉลี่ย EUR/USD เป็นหนึ่งในคู่เงินที่สเปรดแคบที่สุดทั่วโลก \nเครื่องมือเชื่อมต่อการกำหนดราคาของเรารวบรวมส่วนผสมจากผู้ให้บริการด้านราคาที่แตกต่างกันมากถึง 25 รายซึ่งหมายความว่าเราสามารถจัดหาราคาที่ดีที่สุดให้กับลูกค้าของเราและรักษาสเปรดของเราให้แคบโดยเฉพาะในช่วงที่มีความผันผวนสูงเช่นการประกาศข่าว`,
        titile_3: `ไม่มีข้อจำกัดในการซื้อขาย - อนุญาตให้ Scalping`,
        description_3: `แพลตฟอร์ม IUX Markets  Meta Trader5 ไม่มีข้อจำกัดในการซื้อขาย เรามีเงื่อนไขการซื้อขายที่ดีที่สุดสำหรับการซื้อขายแบบทำกำไรในช่วงสั้น ๆ (Scalping) และรองรับการซื้อขายที่มีความถี่สูง อนุญาตให้ผู้ค้าทำการสั่งซื้อระหว่างสเปรด เนื่องจากไม่มีระยะทางการสั่งซื้อขั้นต่ำและระดับการตรึงเป็น 0 ซึ่งหมายความว่าคำสั่งซื้อรวมถึงคำสั่งหยุดขาดทุนสามารถวางได้ใกล้เคียงกับราคาตลาดเท่าที่คุณต้องการ \nผู้ซื้อขายยังสามารถประกันความเสี่ยงของการลงทุน (hedge positions) ได้เนื่องจากไม่มีกฎการเข้าก่อนออกก่อน (FIFO) กับ IUX Markets ผู้ซื้อขายไม่ต้องจ่ายเงินประกันในการซื้อขายที่มีการป้องกันความเสี่ยงและได้รับประโยชน์จากการทำกำไร`,
        titile_4: `ราคาระดับ II - ความลึกของตลาด`,
        description_4: `ความลึกของตลาดแสดงราคาทั้งหมดที่ดำเนินการได้โดยตรงจากผู้ให้บริการราคาของเรา ความลึกของตลาดทำให้มีความโปร่งใสอย่างสมบูรณ์ของสภาพคล่องของแต่ละคู่สกุลเงินโดยการแสดงปริมาณที่มีอยู่สำหรับแต่ละระดับราคาในช่วงเวลาใดก็ได้ สภาพคล่องสูงราคาสปอตแบบอะซิงโครนัสและเวลาในการตอบสนองต่ำรับประกันสเปรดที่ต่ำที่สุด`,
      },
      section_4: {
        titile_1: `ตัวเลือกการฝากเงินและการถอนที่หลากหลาย`,
        description_1: `เมื่อคุณเปิดบัญชีของคุณแล้วคุณสามารถฝากเงินโดยใช้ตัวเลือกการฝากเงินของเรา ได้แก่ : บัตรเครดิต / เดบิต, Skrill, PayPal, Neteller, รหัส QR Thai และ การฝากเงินโดยการโอนจากโบรกเกอร์ไปอีกโบรกเกอร์`,
        titile_2: `การปรับขนาดล็อตไซส์ที่ยืดหยุ่น`,
        description_2: `ไม่มีข้อจำกัดหรือข้อจำกัดเกี่ยวกับขนาดการซื้อขาย คุณสามารถทำการซื้อขายด้วยล็อตไซส์ขนาดเล็กถึงหนึ่งไมโครล็อต (0.01) ขนาดล็อตที่ยืดหยุ่นของเราช่วยให้คุณสามารถทดลองใช้แพลตฟอร์มโดยมีความเสี่ยงน้อยที่สุดและจัดการขนาดการซื้อขายของคุณให้สอดคล้องกับยอดเงินในบัญชีของคุณ`,
      },
      section_5: {
        titile_1: `64 คู่สกุลเงินและโลหะ + 15 CFDs`,
        description_1: `เทรด 64 คู่สกุลเงินบวกกับ 15 ดัชนีหุ้นหลัก ๆ รวมถึง UK 100, S & P / AUS 200 และ Dow Jones Index ตลอด 24 ชั่วโมงโดยมีสเปรดเพียง 1 จุด`,
        titile_2: `เลเวอเรจสูงสุดที่ 1:3000`,
        description_2: `บัญชีมีเลเวอเรจสูงสุด 1: 2000 บนแพลตฟอร์ม IUX Markets  Meta Trader5 นักเทรดสามารถใช้เลเวอเรจที่สูงขึ้นเพื่อให้เหมาะกับสไตล์การเทรดของพวกเขาและใช้ประโยชน์จากกลยุทธ์การเทรดด้วยตนเองและการเทรดแบบอัตโนมัติได้ดีที่สุด`,
        titile_3: `รองรับสกุลเงินหลักของบัญชีทั้งหมด`,
        description_3: `เราทราบดีว่านักเทรดบางรายต้องการซื้อขายในสกุลเงินท้องถิ่นของตน เราให้ทางเลือกแก่นักลงทุนในการเปิดบัญชี 1 ใน 10 สกุลเงินหลักที่รองรับ: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD`,
      },
    },
    bonus: {
      box_1: {
        titile_1: `CFDs เทรด`,
        titile_2: `โบนัสเงินฝาก  $35`,
        titile_3: `ไม่มีค่าคอมมิชชั่น`,
        titile_4: `ดำเนินการคำสั่งซื้ออย่างรวดเร็ว`,
        titile_5: `1:1000 เลเวอเรจ`,
        titile_6: `สภาพคล่องสูง`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `ทำไมต้องเลือกบัญชี Standard+ ของเรา?`,
        description_1: `บัญชีโบนัสมาตรฐานจาก IUX Markets มอบความเร็วในการดำเนินการโดยไม่มีคู่แข่ง พร้อมกับสเปรดที่รวมทุกอย่างเริ่มต้นจาก 1 PIPs เซิร์ฟเวอร์ Equinix LD4 ในลอนดอนกลุ่มผู้ให้บริการราคามากถึง 25 รายแล้วคุณจะเข้าใจว่าเหตุใด Standard+ ของเราจึงสามารถมอบประสบการณ์การซื้อขายที่เหนือชั้นให้คุณได้ทุกวัน`,
      },
      section_2: {
        titile_1: `ดำเนินการคำสั่งซื้ออย่างรวดเร็ว`,
        description_1: `เซิร์ฟเวอร์ IUX Markets  Meta Trader5 ตั้งอยู่ในศูนย์ข้อมูล Equinix LD4 ในลอนดอน ศูนย์ข้อมูล NY4 คือระบบนิเวศทางการเงินเป็นที่ตั้งของบริษัทด้านการซื้อและขายการแลกเปลี่ยน มีสถานที่ซื้อขายข้อมูลตลาดและผู้ให้บริการมากกว่า 600 แห่ง เซิร์ฟเวอร์  Meta Trader5 เชื่อมต่อกับเครือข่ายการสื่อสารของเราเพื่อให้แน่ใจว่าจะมีเวลาแฝงที่ต่ำและการดำเนินการซื้อขายของคุณอย่างรวดเร็วผ่านสภาพแวดล้อมการซื้อขายของ IUX Markets

        \nเซิร์ฟเวอร์ซื้อขาย IUX Markets  Meta Trader5 มีเวลาแฝงน้อยกว่า 1 มิลลิวินาทีไปยังผู้ให้บริการ VPS หลัก ไม่ว่าจะตั้งอยู่ในศูนย์ข้อมูล NY4 หรือผ่านทางสายแบบเฉพาะไปยังศูนย์ข้อมูล`,
      },
      section_3: {
        titile_1: `ไม่มีข้อจำกัดในการซื้อขาย - อนุญาตให้ทำการ scalping`,
        description_1: `แพลตฟอร์ม IUX Markets  Meta Trader5 ไม่มีข้อจำกัดในการซื้อขาย เรามีเงื่อนไขการซื้อขายที่ดีที่สุดสำหรับการซื้อขายแบบทำกำไรในช่วงสั้น ๆ (Scalping) และรองรับการซื้อขายที่มีความถี่สูง 
        อนุญาตให้ผู้ค้าทำการสั่งซื้อระหว่างสเปรด เนื่องจากไม่มีระยะทางการสั่งซื้อขั้นต่ำและระดับการตรึงเป็น 0 ซึ่งหมายความว่าคำสั่งซื้อรวมถึงคำสั่งหยุดขาดทุนสามารถวางได้ใกล้เคียงกับราคาตลาดเท่าที่คุณต้องการ
        
        \nผู้ซื้อขายยังสามารถประกันความเสี่ยงของการลงทุน (hedge positions) ได้เนื่องจากไม่มีกฎการเข้าก่อนออกก่อน (FIFO) กับ IUX Markets ผู้ซื้อขายไม่ต้องจ่ายเงินประกันในการซื้อขายที่มีการป้องกันความเสี่ยงและได้รับประโยชน์จากการทำกำไร`,
        titile_2: `ราคาระดับ II - ความลึกของตลาด`,
        description_2: `ความลึกของตลาดแสดงราคาทั้งหมดที่ดำเนินการได้โดยตรงจากผู้ให้บริการราคาของเรา ความลึกของตลาดทำให้มีความโปร่งใสอย่างสมบูรณ์ของสภาพคล่องของแต่ละคู่สกุลเงินโดยการแสดงปริมาณที่มีอยู่สำหรับแต่ละระดับราคาในช่วงเวลาใดก็ได้ สภาพคล่องสูงราคาสปอตแบบอะซิงโครนัสและเวลาในการตอบสนองต่ำรับประกันสเปรดที่ต่ำที่สุด`,
      },
      section_4: {
        titile_1: `64 คู่สกุลเงินและโลหะ + 15 CFDs`,
        description_1: `เทรด 64 คู่สกุลเงินบวกกับ 15 ดัชนีหุ้นหลัก ๆ รวมถึง UK 100, S & P / AUS 200 และ Dow Jones Index ตลอด 24 ชั่วโมงโดยมีสเปรดเพียง 1 จุด`,
        titile_2: `เลเวอเรจสูงสุดที่ 1:1000`,
        description_2: `บัญชีมีเลเวอเรจสูงสุด 1: 1000 บนแพลตฟอร์ม IUX Markets  Meta Trader5 นักเทรดสามารถใช้เลเวอเรจที่สูงขึ้นเพื่อให้เหมาะกับสไตล์การเทรดของพวกเขาและใช้ประโยชน์จากกลยุทธ์การเทรดด้วยตนเองและการ เทรดแบบอัตโนมัติได้ดีที่สุด`,
        titile_3: `รองรับสกุลเงินหลักของบัญชีทั้งหมด`,
        description_3: `เราทราบดีว่านักเทรดบางรายต้องการซื้อขายในสกุลเงินท้องถิ่นของตน เราให้ทางเลือกแก่นักลงทุนในการเปิดบัญชี 1 ใน 10 สกุลเงินหลักที่รองรับ: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD`,
      },
    },
    accecn: {
      box_1: {
        titile_1: `CFDs Trading `,
        titile_2: `ซื้อขายตราสารมากกว่า 90 ชนิด`,
        titile_3: `ค่าคอมมิชชั่น $3.5`,
        titile_4: `ดำเนินการคำสั่งซื้ออย่างรวดเร็ว`,
        titile_5: `1:3000 เลเวอเรจ`,
        titile_6: `สเปรดต่ำสุดที่ 0.0 PIPs`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `ทำไมต้องเลือกบัญชี ECN ของเรา?`,
        description_1: `บัญชี ECN จาก IUX Markets มอบความเร็วในการดำเนินการโดยไม่มีคู่แข่ง พร้อมกับสเปรดที่รวมทุกอย่างเริ่มต้นจาก 0.0 PIPs เซิร์ฟเวอร์ Equinix LD4 ในลอนดอนกลุ่มผู้ให้บริการราคามากถึง 25 รายแล้วคุณจะเข้าใจว่าเหตุใด Standard ของเราจึงสามารถมอบประสบการณ์การซื้อขายที่เหนือชั้นให้คุณได้ทุกวัน`,
      },
      section_2: {
        titile_1: `ดำเนินการคำสั่งซื้ออย่างรวดเร็ว`,
        description_1: `เซิร์ฟเวอร์ IUX Markets  Meta Trader5 ตั้งอยู่ในศูนย์ข้อมูล Equinix LD4 ในลอนดอน ศูนย์ข้อมูล NY4 คือระบบนิเวศทางการเงินเป็นที่ตั้งของบริษัทด้านการซื้อและขายการแลกเปลี่ยน มีสถานที่ซื้อขายข้อมูลตลาดและผู้ให้บริการมากกว่า 600 แห่ง เซิร์ฟเวอร์  Meta Trader5 เชื่อมต่อกับเครือข่ายการสื่อสารของเราเพื่อให้แน่ใจว่าจะมีเวลาแฝงที่ต่ำและการดำเนินการซื้อขายของคุณอย่างรวดเร็วผ่านสภาพแวดล้อมการซื้อขายของ IUX Markets

        \nเซิร์ฟเวอร์ซื้อขาย IUX Markets  Meta Trader5 มีเวลาแฝงน้อยกว่า 1 มิลลิวินาทีไปยังผู้ให้บริการ VPS หลัก ไม่ว่าจะตั้งอยู่ในศูนย์ข้อมูล NY4 หรือผ่านทางสายแบบเฉพาะไปยังศูนย์ข้อมูล`,
      },
      section_3: {
        titile_1: `ไม่มีข้อจำกัดในการซื้อขาย - อนุญาตให้ทำการ scalping`,
        description_1: `แพลตฟอร์ม IUX Markets  Meta Trader5 ไม่มีข้อจำกัดในการซื้อขาย เรามีเงื่อนไขการซื้อขายที่ดีที่สุดสำหรับการซื้อขายแบบทำกำไรในช่วงสั้น ๆ (Scalping) และรองรับการซื้อขายที่มีความถี่สูง 
        อนุญาตให้ผู้ค้าทำการสั่งซื้อระหว่างสเปรด เนื่องจากไม่มีระยะทางการสั่งซื้อขั้นต่ำและระดับการตรึงเป็น 0 ซึ่งหมายความว่าคำสั่งซื้อรวมถึงคำสั่งหยุดขาดทุนสามารถวางได้ใกล้เคียงกับราคาตลาดเท่าที่คุณต้องการ
        
        \nผู้ซื้อขายยังสามารถประกันความเสี่ยงของการลงทุน (hedge positions) ได้เนื่องจากไม่มีกฎการเข้าก่อนออกก่อน (FIFO) กับ IUX Markets ผู้ซื้อขายไม่ต้องจ่ายเงินประกันในการซื้อขายที่มีการป้องกันความเสี่ยงและได้รับประโยชน์จากการทำกำไร`,
        titile_2: `ราคาระดับ II - ความลึกของตลาด`,
        description_2: `ความลึกของตลาดแสดงราคาทั้งหมดที่ดำเนินการได้โดยตรงจากผู้ให้บริการราคาของเรา ความลึกของตลาดทำให้มีความโปร่งใสอย่างสมบูรณ์ของสภาพคล่องของแต่ละคู่สกุลเงินโดยการแสดงปริมาณที่มีอยู่สำหรับแต่ละระดับราคาในช่วงเวลาใดก็ได้ สภาพคล่องสูงราคาสปอตแบบอะซิงโครนัสและเวลาในการตอบสนองต่ำรับประกันสเปรดที่ต่ำที่สุด`,
      },
      section_4: {
        titile_1: `64 คู่สกุลเงินและโลหะ + 15 CFDs`,
        description_1: `เทรด 64 คู่สกุลเงินบวกกับ 15 ดัชนีหุ้นหลัก ๆ รวมถึง UK 100, S & P / AUS 200 และ Dow Jones Index ตลอด 24 ชั่วโมงโดยมีสเปรดเพียง 1 จุด`,
        titile_2: `เลเวอเรจสูงสุดที่ 1:3000`,
        description_2: `บัญชีมีเลเวอเรจสูงสุด 1: 3000 บนแพลตฟอร์ม IUX Markets  Meta Trader5 นักเทรดสามารถใช้เลเวอเรจที่สูงขึ้นเพื่อให้เหมาะกับสไตล์การเทรดของพวกเขาและใช้ประโยชน์จากกลยุทธ์การเทรดด้วยตนเองและแบบอัตโนมัติได้ดีที่สุด`,
        titile_3: `รองรับสกุลเงินหลักของบัญชีทั้งหมด`,
        description_3: `เราทราบดีว่านักเทรดบางรายต้องการซื้อขายในสกุลเงินท้องถิ่นของตน เราให้ทางเลือกแก่นักลงทุนในการเปิดบัญชี 1 ใน 10 สกุลเงินหลักที่รองรับ: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD`,
      },
    },
    accraw: {
      box_1: {
        titile_1: `CFDs Trading `,
        titile_2: `ซื้อขายตราสารมากกว่า 90 ชนิด`,
        titile_3: `ค่าคอมมิชชั่น $6`,
        titile_4: `ดำเนินการคำสั่งซื้ออย่างรวดเร็ว`,
        titile_5: `1:3000 เลเวอเรจ`,
        titile_6: `สเปรดต่ำสุดที่ 0.0 PIPs`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `ทำไมต้องเลือกบัญชี Raw ของเรา?`,
        description_1: `บัญชี Raw จาก IUX Markets มอบความเร็วในการดำเนินการโดยไม่มีคู่แข่ง พร้อมกับสเปรดที่รวมทุกอย่างเริ่มต้นจาก 0.0 PIPs เซิร์ฟเวอร์ Equinix LD4 ในลอนดอนกลุ่มผู้ให้บริการราคามากถึง 25 รายแล้วคุณจะเข้าใจว่าเหตุใด Raw ของเราจึงสามารถมอบประสบการณ์การซื้อขายที่เหนือชั้นให้คุณได้ทุกวัน`,
      },
      section_2: {
        titile_1: `ดำเนินการคำสั่งซื้ออย่างรวดเร็ว`,
        description_1: `เซิร์ฟเวอร์ IUX Markets  Meta Trader5 ตั้งอยู่ในศูนย์ข้อมูล Equinix LD4 ในลอนดอน ศูนย์ข้อมูล NY4 คือระบบนิเวศทางการเงินเป็นที่ตั้งของบริษัทด้านการซื้อและขายการแลกเปลี่ยน มีสถานที่ซื้อขายข้อมูลตลาดและผู้ให้บริการมากกว่า 600 แห่ง เซิร์ฟเวอร์  Meta Trader5 เชื่อมต่อกับเครือข่ายการสื่อสารของเราเพื่อให้แน่ใจว่าจะมีเวลาแฝงที่ต่ำและการดำเนินการซื้อขายของคุณอย่างรวดเร็วผ่านสภาพแวดล้อมการซื้อขายของ IUX Markets

        \nเซิร์ฟเวอร์ซื้อขาย IUX Markets  Meta Trader5 มีเวลาแฝงน้อยกว่า 1 มิลลิวินาทีไปยังผู้ให้บริการ VPS หลัก ไม่ว่าจะตั้งอยู่ในศูนย์ข้อมูล NY4 หรือผ่านทางสายแบบเฉพาะไปยังศูนย์ข้อมูล`,
      },
      section_3: {
        titile_1: `ไม่มีข้อจำกัดในการซื้อขาย - อนุญาตให้ทำการ scalping`,
        description_1: `แพลตฟอร์ม IUX Markets  Meta Trader5 ไม่มีข้อจำกัดในการซื้อขาย เรามีเงื่อนไขการซื้อขายที่ดีที่สุดสำหรับการซื้อขายแบบทำกำไรในช่วงสั้น ๆ (Scalping) และรองรับการซื้อขายที่มีความถี่สูง 
        อนุญาตให้ผู้ค้าทำการสั่งซื้อระหว่างสเปรด เนื่องจากไม่มีระยะทางการสั่งซื้อขั้นต่ำและระดับการตรึงเป็น 0 ซึ่งหมายความว่าคำสั่งซื้อรวมถึงคำสั่งหยุดขาดทุนสามารถวางได้ใกล้เคียงกับราคาตลาดเท่าที่คุณต้องการ
        
        \nผู้ซื้อขายยังสามารถประกันความเสี่ยงของการลงทุน (hedge positions) ได้เนื่องจากไม่มีกฎการเข้าก่อนออกก่อน (FIFO) กับ IUX Markets ผู้ซื้อขายไม่ต้องจ่ายเงินประกันในการซื้อขายที่มีการป้องกันความเสี่ยงและได้รับประโยชน์จากการทำกำไร`,
        titile_2: `ราคาระดับ II - ความลึกของตลาด`,
        description_2: `ความลึกของตลาดแสดงราคาทั้งหมดที่ดำเนินการได้โดยตรงจากผู้ให้บริการราคาของเรา ความลึกของตลาดทำให้มีความโปร่งใสอย่างสมบูรณ์ของสภาพคล่องของแต่ละคู่สกุลเงินโดยการแสดงปริมาณที่มีอยู่สำหรับแต่ละระดับราคาในช่วงเวลาใดก็ได้ สภาพคล่องสูงราคาสปอตแบบอะซิงโครนัสและเวลาในการตอบสนองต่ำรับประกันสเปรดที่ต่ำที่สุด`,
      },
      section_4: {
        titile_1: `64 คู่สกุลเงินและโลหะ + 15 CFDs`,
        description_1: `เทรด 64 คู่สกุลเงินบวกกับ 15 ดัชนีหุ้นหลัก ๆ รวมถึง UK 100, S & P / AUS 200 และ Dow Jones Index ตลอด 24 ชั่วโมงโดยมีสเปรดเพียง 1 จุด`,
        titile_2: `เลเวอเรจสูงสุดที่ 1:3000`,
        description_2: `บัญชีมีเลเวอเรจสูงสุด 1: 3000 บนแพลตฟอร์ม IUX Markets  Meta Trader5 นักเทรดสามารถใช้เลเวอเรจที่สูงขึ้นเพื่อให้เหมาะกับสไตล์การเทรดของพวกเขาและใช้ประโยชน์จากกลยุทธ์การเทรดด้วยตนเองและแบบอัตโนมัติได้ดีที่สุด`,
        titile_3: `รองรับสกุลเงินหลักของบัญชีทั้งหมด`,
        description_3: `เราทราบดีว่านักเทรดบางรายต้องการซื้อขายในสกุลเงินท้องถิ่นของตน เราให้ทางเลือกแก่นักลงทุนในการเปิดบัญชี 1 ใน 10 สกุลเงินหลักที่รองรับ: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD`,
      },
    },
    accpro: {
      box_1: {
        titile_1: `CFDs Trading `,
        titile_2: `ซื้อขายตราสารมากกว่า 90 ชนิด`,
        titile_3: `ค่าคอมมิชชั่น $0`,
        titile_4: `ดำเนินการคำสั่งซื้ออย่างรวดเร็ว`,
        titile_5: `1:3000 เลเวอเรจ`,
        titile_6: `สเปรดต่ำสุดที่ 0.1 PIPs`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `ทำไมต้องเลือกบัญชี Pro ของเรา?`,
        description_1: `บัญชี Pro จาก IUX Markets มอบความเร็วในการดำเนินการโดยไม่มีคู่แข่ง พร้อมกับสเปรดที่รวมทุกอย่างเริ่มต้นจาก 0.0 PIPs เซิร์ฟเวอร์ Equinix LD4 ในลอนดอนกลุ่มผู้ให้บริการราคามากถึง 25 รายแล้วคุณจะเข้าใจว่าเหตุใด Pro ของเราจึงสามารถมอบประสบการณ์การซื้อขายที่เหนือชั้นให้คุณได้ทุกวัน`,
      },
      section_2: {
        titile_1: `ดำเนินการคำสั่งซื้ออย่างรวดเร็ว`,
        description_1: `เซิร์ฟเวอร์ IUX Markets  Meta Trader5 ตั้งอยู่ในศูนย์ข้อมูล Equinix LD4 ในลอนดอน ศูนย์ข้อมูล NY4 คือระบบนิเวศทางการเงินเป็นที่ตั้งของบริษัทด้านการซื้อและขายการแลกเปลี่ยน มีสถานที่ซื้อขายข้อมูลตลาดและผู้ให้บริการมากกว่า 600 แห่ง เซิร์ฟเวอร์  Meta Trader5 เชื่อมต่อกับเครือข่ายการสื่อสารของเราเพื่อให้แน่ใจว่าจะมีเวลาแฝงที่ต่ำและการดำเนินการซื้อขายของคุณอย่างรวดเร็วผ่านสภาพแวดล้อมการซื้อขายของ IUX Markets

        \nเซิร์ฟเวอร์ซื้อขาย IUX Markets  Meta Trader5 มีเวลาแฝงน้อยกว่า 1 มิลลิวินาทีไปยังผู้ให้บริการ VPS หลัก ไม่ว่าจะตั้งอยู่ในศูนย์ข้อมูล NY4 หรือผ่านทางสายแบบเฉพาะไปยังศูนย์ข้อมูล`,
      },
      section_3: {
        titile_1: `ไม่มีข้อจำกัดในการซื้อขาย - อนุญาตให้ทำการ scalping`,
        description_1: `แพลตฟอร์ม IUX Markets  Meta Trader5 ไม่มีข้อจำกัดในการซื้อขาย เรามีเงื่อนไขการซื้อขายที่ดีที่สุดสำหรับการซื้อขายแบบทำกำไรในช่วงสั้น ๆ (Scalping) และรองรับการซื้อขายที่มีความถี่สูง 
        อนุญาตให้ผู้ค้าทำการสั่งซื้อระหว่างสเปรด เนื่องจากไม่มีระยะทางการสั่งซื้อขั้นต่ำและระดับการตรึงเป็น 0 ซึ่งหมายความว่าคำสั่งซื้อรวมถึงคำสั่งหยุดขาดทุนสามารถวางได้ใกล้เคียงกับราคาตลาดเท่าที่คุณต้องการ
        
        \nผู้ซื้อขายยังสามารถประกันความเสี่ยงของการลงทุน (hedge positions) ได้เนื่องจากไม่มีกฎการเข้าก่อนออกก่อน (FIFO) กับ IUX Markets ผู้ซื้อขายไม่ต้องจ่ายเงินประกันในการซื้อขายที่มีการป้องกันความเสี่ยงและได้รับประโยชน์จากการทำกำไร`,
        titile_2: `ราคาระดับ II - ความลึกของตลาด`,
        description_2: `ความลึกของตลาดแสดงราคาทั้งหมดที่ดำเนินการได้โดยตรงจากผู้ให้บริการราคาของเรา ความลึกของตลาดทำให้มีความโปร่งใสอย่างสมบูรณ์ของสภาพคล่องของแต่ละคู่สกุลเงินโดยการแสดงปริมาณที่มีอยู่สำหรับแต่ละระดับราคาในช่วงเวลาใดก็ได้ สภาพคล่องสูงราคาสปอตแบบอะซิงโครนัสและเวลาในการตอบสนองต่ำรับประกันสเปรดที่ต่ำที่สุด`,
      },
      section_4: {
        titile_1: `64 คู่สกุลเงินและโลหะ + 15 CFDs`,
        description_1: `เทรด 64 คู่สกุลเงินบวกกับ 15 ดัชนีหุ้นหลัก ๆ รวมถึง UK 100, S & P / AUS 200 และ Dow Jones Index ตลอด 24 ชั่วโมงโดยมีสเปรดเพียง 1 จุด`,
        titile_2: `เลเวอเรจสูงสุดที่ 1:3000`,
        description_2: `บัญชีมีเลเวอเรจสูงสุด 1: 3000 บนแพลตฟอร์ม IUX Markets  Meta Trader5 นักเทรดสามารถใช้เลเวอเรจที่สูงขึ้นเพื่อให้เหมาะกับสไตล์การเทรดของพวกเขาและใช้ประโยชน์จากกลยุทธ์การเทรดด้วยตนเองและแบบอัตโนมัติได้ดีที่สุด`,
        titile_3: `รองรับสกุลเงินหลักของบัญชีทั้งหมด`,
        description_3: `เราทราบดีว่านักเทรดบางรายต้องการซื้อขายในสกุลเงินท้องถิ่นของตน เราให้ทางเลือกแก่นักลงทุนในการเปิดบัญชี 1 ใน 10 สกุลเงินหลักที่รองรับ: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD`,
      },
    },
    funding: {
      section_1: {
        title: `ฝาก / ถอน ทำอย่างไร ?`,
        titile_1: `ลงทะเบียนหรือเข้าสู่ระบบ`,
        titile_2: `เลือกช่องทางดำเนินการฝากเงิน / ถอน`,
        titile_3: `ฟรีค่าธรรมเนียมการฝาก / ถอน`,
        titile_4: `ถอนเงินรวดเร็วในไม่กี่นาที`,
      },
      section_2: {
        title: `ตัวเลือกการฝาก / ถอน`,
        description: `เรารักษาข้อมูลทางการเงินของคุณให้ปลอดภัยด้วยการเข้ารหัส คุณจึงสามารถชำระเงินออนไลน์ได้อย่างมั่นใจ`,
        option: `ตัวเลือกการฝากเงิน`,
        hot: `ได้รับความนิยม`,
        titile_1: `บัตรเครดิต / เดบิต`,
        titile_2: `Skrill`,
        titile_3: `Neteller`,
        titile_4: `Thai QR Payment`,
        titile_5: `ธนาคารออนไลน์`,
      },
      section_3: {
        titile_1: `ขั้นตอนการชำระเงิน`,
        description_1: `เพื่อให้เกิดการดำเนินการที่รวดเร็วยิ่งขึ้น เราขอแนะนำให้เจ้าของบัญชีทำการฝากเงินไปยังบัญชีซื้อขายจากภายใน Secure Client Area 
        โดยที่ Secure Client Area ของคุณจะช่วยให้คุณสามารถฝากเงินเข้าบัญชีได้แบบเรียลไทม์โดยใช้บัตรเครดิต และ Skrill
        
        \nถ้าหากคุณยังไม่สามารถเข้าถึง Secure Client Area ของเราได้ โปรดทำตามคำแนะนำในการเข้าใช้งาน`,
        titile_2: `ความปลอดภัยของเงินทุน`,
        description_2: `เมื่อคุณทำฝากเงินเข้าบัญชีซื้อขายของคุณ เงินทุนของลูกค้าจะถูกเก็บแยกไว้ในบัญชีทรัสต์ของลูกค้าที่ National Australia Bank (NAB) และ Westpac Banking Corporation (Westpac) ซึ่งธนาคารทั้งสองเป็นธนาคารออสเตรเลียเรท AA การจ่ายเงินทางอิเล็กทรอนิกส์จะถูกดำเนินการโดยใช้เทคโนโลยี SSL (Secure Socket Layer) และถูกเข้ารหัสเพื่อความปลอดภัย ข้อมูลการจ่ายเงินทั้งหมดจะถูกเก็บเป็นความลับและถูกใช้เพื่อวัตถุประสงค์ในการฝากเงินเข้าบัญชีซื้อขายของคุณกับ IUX Markets เท่านั้น`,
        titile_3: `ค่าธรรมเนียมธนาคาร`,
        description_3: `IUX Markets ไม่มีการเรียกเก็บค่าธรรมเนียมเพิ่มเติมสำหรับการฝากเงินหรือถอนเงิน อย่างไรก็ตามคุณควรทราบว่า คุณอาจถูกเรียกเก็บค่าธรรมเนียมสำหรับการนำเงินเข้าหรือออกจากธนาคารระหว่างประเทศบางแห่ง IUX Markets ไม่มีส่วนรับผิดชอบในค่าธรรมเนียมธนาคารดังกล่าว`,
        titile_4: `การจ่ายเงินโดยบุคคลที่สาม`,
        description_4: `IUX Markets ไม่ยอมรับการจ่ายเงินจากบุคคลที่สาม โปรดตรวจสอบให้แน่ใจว่าเงินฝากทั้งหมดที่ถูกส่งไปยังบัญชีซื้อขายของคุณมาจากธนาคารภายใต้ชื่อของคุณ การจ่ายเงินจากบัญชีธนาคารที่มีชื่อร่วมกับผู้อื่น / บัตรเครดิตร่วม สามารถทำได้ถ้าหากเจ้าของบัญชีซื้อขายมีชื่ออยู่ในกลุ่มเจ้าของบัญชีธนาคาร / บัตรเครดิต`,
      },
    },
    withdraw: {
      section_1: {
        title: `เพื่อให้ดำเนินการได้เร็วขึ้นเจ้าของบัญชีทุกคนจะต้องส่งคำขอถอนเงินจากภายใน Secure Client Area`,
        content: `หากคุณยังไม่สามารถเข้าถึง Secure Client Area ของคุณได้โปรดปฏิบัติตามคำแนะนำในการเข้าถึง`,
      },
      section_2: {
        title: `หากคุณไม่ได้ทำการถอนในอดีตโปรดทราบ:`,
        title_1: `คำขอถอนเงินจะถูกตัดเวลาคือ 12:00 AEST / AEDT หากการถอนเงินของคุณถูกส่งก่อนเวลานี้จะดำเนินการในวันที่ได้รับ
        หากการถอนเงินของคุณถูกส่งหลังจากเวลานี้จะได้รับการดำเนินการในวันทำการถัดไป`,
        title_2: `IUX Markets ไม่เรียกเก็บค่าธรรมเนียมเพิ่มเติมสำหรับการฝากหรือถอนเงิน อย่างไรก็ตามคุณควรทราบว่าคุณอาจต้องเสียค่าธรรมเนียมในการชำระเงินเข้าและออกจากสถาบันการเงินระหว่างประเทศบางแห่ง IUX Markets ไม่รับผิดชอบต่อค่าธรรมเนียมธนาคารดังกล่าว`,
        title_3: `สำหรับการโอนเงินผ่านธนาคารระหว่างประเทศจะมีการเรียกเก็บค่าธรรมเนียมการดำเนินการ 20 AUD หรือเทียบเท่าในสกุลเงินโดยสถาบันการเงินของเรา เราจะหักค่าธรรมเนียมนี้จากยอดถอนของคุณ อย่างไรก็ตามอาจแตกต่างกันไปขึ้นอยู่กับเขตอำนาจศาลของคุณและธนาคารที่คุณใช้และอาจใช้เวลาถึง 14 วันและต้องเสียค่าธรรมเนียมตัวกลางเพิ่มเติม`,
        title_4: `การถอนเงินด้วยบัตรเครดิต / เดบิตจะดำเนินการโดยไม่เสียค่าใช้จ่าย เมื่อดำเนินการแล้วการถอนด้วยบัตรเครดิต / เดบิตอาจใช้เวลา 3-5 วันทำการกว่าจะถึงบัตรเครดิตของคุณ อย่างไรก็ตามคุณควรทราบว่าในบางครั้งที่เกิดขึ้นไม่บ่อยบางครั้งอาจใช้เวลาถึง 10 วันทำการขึ้นอยู่กับธนาคารที่สิ้นสุด \n* โปรดทราบว่าการถอนด้วยบัตรเครดิตอาจไม่สามารถใช้ได้ในบางประเทศ`,
        title_5: `การถอนแบบ Safecharge สามารถคืนเงินที่ฝากได้เท่านั้น คุณสามารถเลือกวิธีการฝากอื่นที่ใช้ก่อนหน้านี้หรือวิธีการโอนเงินผ่านธนาคารเพื่อถอน`,
        title_6: `การถอนเงิน Paypal / Neteller / Skrill จะต้องทำจากบัญชีเดียวกันกับที่ที่มีการส่งเงินครั้งแรก ธุรกรรมเหล่านี้ได้รับการดำเนินการโดยไม่เสียค่าใช้จ่ายและจะดำเนินการทันที`,
        title_7: `หากบัตรเครดิต / เดบิตที่อัปโหลดของคุณหมดอายุแล้วโปรดอัปโหลดบัตรใหม่ในพื้นที่ลูกค้าของคุณเพื่อใช้บริการฝากและถอนเงินต่อไปโดยไม่มีการหยุดชะงัก หากหมายเลขบัตรใหม่แตกต่างจากหมายเลขบัตรหมดอายุคุณจะต้องส่งจดหมายที่ออกจากธนาคารผู้ออกบัตรใบเก่าเพื่อยืนยันว่าได้ออกบัตรใหม่แทนบัตรใบเก่า`,
        title_8: `หากบัตรเครดิต / เดบิตที่อัปโหลดของคุณสูญหาย / ถูกขโมย / เสียหาย / ยกเลิกคุณจะต้องส่งจดหมายที่ออกจากธนาคารผู้ออกบัตรใบเก่าเพื่อยืนยันว่าบัตรเก่าใช้ไม่ได้อีกต่อไป`,
        title_9: `IUX Markets ตามดุลยพินิจของตนเองอาจขอให้คุณส่งเอกสารประกอบ (ตัวอย่างเช่นใบเสร็จรับเงินสำหรับการชำระเงินที่ดำเนินการผ่านบัตรใบเก่าหรือใบแจ้งยอดบัตรที่แสดงธุรกรรมการฝากเงิน) ก่อนที่จะปล่อยเงินไปยังบัตรใหม่`,
        title_10: `หากต้องการถอนจำนวนเงินที่มากกว่าจำนวนเงินที่ฝากโดยบัตรเครดิต / เดบิต Verified by Visa / MasterCard Secure คุณจะต้องใช้วิธีการฝากอื่นที่ใช้ก่อนหน้านี้หรือตัวเลือกการโอนเงินผ่านธนาคาร`,
        title_11: `หากคุณกำลังถอนเงินของคุณที่ฝากผ่านตัวเลือก Thai Internet Banking ไปยังบัญชีธนาคารในประเทศไทยคุณจะต้องอัปโหลดภาพที่ชัดเจนหรือสำเนาสแกนสีของธนาคารหนังสือของคุณ เมื่อดำเนินการแล้วเงินอาจใช้เวลาหนึ่งวันทำการในการเข้าถึงบัญชีของคุณ`,
        title_12: `IUX Markets ไม่ประมวลผลการชำระเงินให้กับบุคคลที่สาม โปรดตรวจสอบให้แน่ใจว่าคำขอถอนเงินทั้งหมดจากบัญชีซื้อขายของคุณไปที่บัญชีธนาคารหรือแหล่งที่มาในชื่อของคุณ การชำระเงินไปยังบัญชีธนาคารร่วม / บัตรเครดิตได้รับการยอมรับหากเจ้าของบัญชีซื้อขายเป็นบุคคลที่อยู่ในบัญชีธนาคาร / บัตรเครดิต`,
      },
    },
  },
  lo: {
    account_create_demo: `ບັນຊີຕົວຢ່າງຂອງທ່ານແມ່ນການລົງທືນທີ່ບໍ່ມີຄວາມສ່ຽງທີ່ທ່ານສາມາດຝຶກຍຸດທະສາດ, ທົດສອບຍຸດທະສາດ, ແລະມີຄວາມສະບາຍກັບເວທີຂອງພວກເຮົາ.`,
    account_create_success_islamic: `ບັນມີລາຍລະອຽດຊູສຳແວນ Swap-Free ກະລຸນາທຳບັນດາລະຫັດລະຫັດການຊື້ຂາຍຂອງທ່ານໃນຄວາມປະຕິເນຍ ບໍ່ສາມັນສົດສໍາລົບໄດ້ເປັນສາມັນສົດສໍາລົບໄດ້ເປັນເວັບອີเมลຢ່າງຄົງ`,
    account_create_success: `ບໍ່ມີຄ່າບໍລິການໃນເວລາກາງຄືນອີກຕໍ່ໄປ. ສະຖານະພາບທີ່ບໍ່ເສຍຄ່າຂອງທ່ານແມ່ນຖືກກໍານົດໂດຍກິດຈະກໍາການຄ້າຂອງທ່ານ. ເພື່ອຮັກສາສະຖານະພາບທີ່ບໍ່ເສຍຄ່າ, ທ່ານຈໍາເປັນຕ້ອງຄ້າຂາຍສ່ວນໃຫຍ່ພາຍໃນມື້.`,
    open_an_account: "ເປີດບັນຊີ",
    low_risk: "	ຕ່ໍກັນ	",
    high_risk: "	ມີ​ຄວາມ​ສ່ຽງ​ສູງ	",
    medium_risk: "	ຄວາມສ່ຽງປານກາງ	",
    standard_account_description:
      "	ທີ່ນິຍົມທີ່ສຸດ! ບັນຊີທີ່ດີສໍາລັບພໍ່ຄ້າທຸກປະເພດ.	",
    standard_bonus_account_description:
      "	ບັນຊີການປະຕິບັດທັນທີຂອງພວກເຮົາ. ການຕື່ມຂໍ້ມູນທີ່ດີເລີດໂດຍບໍ່ມີຄະນະກໍາມະການການຄ້າໃດໆ.	",
    raw_account_description: "	ການແຜ່ກະຈາຍດິບຕໍ່າສຸດແລະຄະນະກໍາມະການຄົງທີ່ຕ່ໍາ.	",
    pro_account_description: "	ບັນຊີທີ່ເປັນມືອາຊີບທີ່ມີຄະນະກໍາມະການຟຣີ 0%.	",
    button: {
      title: `ຝາກເງິນດ່ວນ`,
    },
    cover: {
      accover: {
        title: `ພາບລວມບັນຊີ`,
        description: `IUX Markets ສະ​ເປດ​ທີ່ຕໍ່າແລະບັນດາຫຸ້ນສ່ວນໃຫຍ່ລວມທັງທຸກປະເພດບັນຊີດີເລີດສຳ ລັບຜູ້ເລີ່ມຕົ້ນແລະຜູ້ຄ້າຂາຍທີ່ມີປະສົບການ, ພຽງແຕ່ເລືອກເວທີການຄ້າທີ່ທ່ານຕ້ອງການແລະຮູບແບບການກຳນົດລາຄາເພື່ອເລີ່ມຕົ້ນ`,
      },
      accstd: {
        title: `Standard `,
        description: `ໃຫ້ຕົວທ່ານເອງໄດ້ຮັບປະໂຫຍດຈາກສະ​ເປດ​ທີ່ຕໍ່າ ໃນໜຶ່ງ ຂອງເວທີການຄ້າທີ່ນິຍົມທີ່ສຸດໃນໂລກດ້ວຍຜູ້ລວມຕົວຜູ້ໃຫ້ບໍລິການດ້ານລາຄາຂອງພວກເຮົາ`,
      },
      acccnt: {
        title: `Cent `,
        description: `ບັນຊີຂອງຜູ້ເລີ່ມຕົ້ນທີ່ມີຫົວຫນ່ວຍການລົງທຶນຫນ້ອຍກວ່າ 100 ເທື່ອ, ເຫມາະສົມກັບທຸກຮູບແບບການຊື້ຂາຍ. ທ່ານສາມາດນໍາໃຊ້ຍຸດທະສາດການຄ້າໃດໆຕາມທີ່ທ່ານຕ້ອງການ. ດ້ວຍການແຜ່ກະຈາຍທີ່ຕໍ່າທີ່ສຸດເທົ່າກັບບັນຊີມາດຕະຖານ, ທ່ານມີໂອກາດທີ່ຈະຊະນະໄດ້ຫຼາຍຂຶ້ນ`,
      },
      accecn: {
        title: `ECN `,
        description: `ຮັກສາມັນງ່າຍດ້ວຍບັນຊີ IUX Markets Standard ສຳຜັດກັບປະໂຫຍດທັງໝົດຂອງການກຳນົດລາຄາສະ​ເປດ​ທີ່ຕໍ່າຂອງພວກເຮົາແລະບໍ່ມີຄ່າຄອມມິດຊັ່ນ`,
      },
      accraw: {
        title: `Raw `,
        description: `ຮັກສາມັນງ່າຍດ້ວຍບັນຊີ IUX Markets Standard ສຳຜັດກັບປະໂຫຍດທັງໝົດຂອງການກຳນົດລາຄາສະ​ເປດ​ທີ່ຕໍ່າຂອງພວກເຮົາແລະບໍ່ມີຄ່າຄອມມິດຊັ່ນ`,
      },
      accpro: {
        title: `Pro `,
        description: `ຮັກສາມັນງ່າຍດ້ວຍບັນຊີ IUX Markets Standard ສຳຜັດກັບປະໂຫຍດທັງໝົດຂອງການກຳນົດລາຄາສະ​ເປດ​ທີ່ຕໍ່າຂອງພວກເຮົາແລະບໍ່ມີຄ່າຄອມມິດຊັ່ນ`,
      },
      accbonus: {
        title: `Standard+ `,
        description: `ຮັກສາມັນງ່າຍດ້ວຍບັນຊີ IUX Markets Standard ສຳຜັດກັບປະໂຫຍດທັງໝົດຂອງການກຳນົດລາຄາສະ​ເປດ​ທີ່ຕໍ່າຂອງພວກເຮົາແລະບໍ່ມີຄ່າຄອມມິດຊັ່ນ`,
      },
      deposit: {
        title: `ການເພີ່ມເງິນເຂົ້າບັນຊີ`,
        description: `IUX Markets ມີຫຼາຍກວ່າ 15 ທາງເລືອກໃນການເພີ່ມເງິນເຂົ້າບັນຊີໃນ 10 ສະກຸນເງິນທີ່ແຕກຕ່າງກັນ ຝາກເງິນທັນທີ ແລະບໍ່ເສຍຄ່າໃຊ້ຫຼາຍໃນທຸກໆທາງ`,
      },
      withdraw: {
        title: `ການຖອນເງິນ`,
        description: `ຮັກສາມັນງ່າຍດ້ວຍບັນຊີ IUX Markets Standard ສຳຜັດກັບປະໂຫຍດທັງໝົດຂອງການກຳນົດລາຄາສະ​ເປດ​ທີ່ຕໍ່າຂອງພວກເຮົາແລະບໍ່ມີຄ່າຄອມມິດຊັ່ນ`,
      },
    },
    menu: {
      menu_1: "ພາບລວມບັນຊີ",
    },
    section_1: {
      title: "ເປັນຫຍັງເລືອກບັນຊີ Standard ຂອງພວກເຮົາ?",
      description:
        "ບັນຊີ Raw Spread ຂອງພວກເຮົາໃຫ້ການແຜ່ກະຈາຍຕ່ ຳ ທີ່ສຸດເທົ່າທີ່ເປັນໄປໄດ້ .Our ສະເລ່ຍການແຜ່ກະຈາຍ EUR / USD ແມ່ນ 0.1 PIPs ໂດຍມີຄະນະ ກຳ ມະການເລັກໆນ້ອຍ $ 0.2 ຕໍ່ຫຼາຍຈ່າຍຕໍ່ຂ້າງ. ດ້ວຍແຫຼ່ງ ກຳ ນົດລາຄາໂດຍລວມຈາກສູງເຖິງ 25 ແຫລ່ງລະດັບສະຖາບັນ, ບັນຊີ IUX Markets ມາດຕະຖານຖືກສ້າງຂື້ນ ສຳ ລັບເທຮດເດ້ກາງເວັນ, ຜູ້ຄ້າຂາຍແລະທີ່ປຶກສາຜູ້ຊ່ຽວຊານ, ແພດຟອມ IUX Markets  Meta Trader5 ແມ່ນຕົວເລືອກທີ່ຕ້ອງການ ສຳ ລັບເທຮດເດ້ຕະຫຼອດມື້ແລະຜູ້ຄ້າຂາຍຕະຫຼອດ ໂລກ ..",
    },
    std: {
      box_1: {
        titile_1: `CFDs Trading `,
        titile_2: `ລາຄາສະ​ເປດ​ທີ່ຕໍ່າ`,
        titile_3: `ສະ​ເປດເລີ່ມຕົ້ນ​ທີ່ 0.2 PIPs`,
        titile_4: `ການປະຕິບັດຄໍາສັ່ງໄວ`,
        titile_5: `1:3000 ການ​ເພີ່ມ​ຄວາມ​ສາມາດ`,
        titile_6: `ສະພາບຄ່ອງສູງ`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `ບັນຊີມາດຕະຖານສະເໜີສະ​ເປດທີ່ຕ່ຳທີ່ສຸດເທົ່າທີ່ເປັນໄປໄດ້ ສະ​ເປດ EUR / USD ສະເລ່ຍຂອງພວກເຮົາແມ່ນ 0.1 PIPs ໂດຍມີຄະນະກຳມະການເລັກໆນ້ອຍ $ 3.50 ຕໍ່ຫຼາຍຈ່າຍຕໍ່ຂ້າງດ້ວຍສະພາບຄ່ອງສູງແລະມີການປະຕິບັດໄວ,ບັນຊີການແຜ່ກະຈາຍຕໍ່າ ຂອງຕະຫຼາດ IUX ຖືກສ້າງຂຶ້ນສຳລັບຜູ້ຄ້າຄົນກາງເວັນ, ຜູ້ຄ້າ ແລະທີ່ປຶກສາຊ່ຽວຊານ`,
        titile_2: `ເຄື່ອງແມ່ຂ່າຍຂອງ  Meta Trader5 Low Spread ຂອງພວກເຮົາແມ່ນຕັ້ງຢູ່ສູນຂໍ້ມູນ Equinix LD4 ໃນລອນດອນ, ເຮັດໃຫ້ທ່ານໃກ້ຊິດກັບ  bridge ພວກເຮົາ, ກຳ ຈັດຄວາມບໍ່ ຈຳ ເປັນໃດໆແພລະຕະຟອມ IUX Markets  Meta Trader5 ແມ່ນຕົວເລືອກທີ່ຕ້ອງການສຳ ລັບຜູ້ຄ້າຂາຍປະຈຳວັນແລະຜູ້ຄ້າຂາຍຕະຫຼອດ ໂລກ`,
      },
      section_2: {
        titile_1: `ລາຄາສະ​ເປດ​ທີ່ຕໍ່າ`,
        description_1: `IUX Markets ສາມາດສະເໜີລາຄາຕະຫລາດແລະເງື່ອນໄຂການຄ້າທີ່ດີທີ່ສຸດຜ່ານແພລະຕະຟອມ  MT5 ໂດຍໃຫ້ລູກຄ້າມີລາຄາທີ່ສະ​ເປດ​ທີ່ຕໍ່າ \nສະພາບແວດລ້ອມການກຳນົດລາຄາຕ່ຳ ຊ່ວຍໃຫ້ທ່ານສາມາດຊື້ຂາຍໃນລະດັບລາຄາສະຖາບັນໃນລະດັບເກືອບຄືກັນ ສະຖານຂອງໂລກລາຄາກະແສທີ່ສາມາດປະຕິບັດໄດ້ (ESP) ແມ່ນຖືກສົ່ງມາຈາກຜູ້ໃຫ້ບໍລິການດ້ານລາຄາຂອງ IUX Markets ໃຫ້ພວກເຮົາແລະສະ ເໜີ ໃຫ້ລູກຄ້າຂອງພວກເຮົາໂດຍບໍ່ມີໂຕະຈັດການ, ການຈັດການລາຄາຫຼືວົງຢືມ IUX Markets ແມ່ນຜູ້ໃຫ້ບໍລິການທາງເລືອກ CFDs ສຳ ລັບຜູ້ຄ້າຂາຍ, scalpers ແລະ robotsທີ່ມີຄວາມຕ້ອງການສະ​ເປດ​ທີ່ຕໍ່າແລະຕ້ອງການການປະຕິບັດທີ່ດີທີ່ສຸດ`,
      },
      section_3: {
        titile_1: `ການປະຕິບັດຄໍາສັ່ງໄວ`,
        description_1: `ເຄື່ອງແມ່ຂ່າຍຂອງ IUX Markets  Meta Trader5 ແມ່ນຕັ້ງຢູ່ໃນສູນຂໍ້ມູນຂອງ Equinix LD4 ໃນລອນດອນ. ສູນຂໍ້ມູນ NY4 ທີ່ກ່າວເຖິງເປັນລະບົບນິເວດທາງການເງິນເປັນບ່ອນຢູ່ອາໃສຂອງຜູ້ຊື້ແລະຂາຍບໍລິສັດຂ້າງຄຽງຫຼາຍກວ່າ 600 ແຫ່ງ, ການແລກປ່ຽນ, ສະຖານທີ່ການຄ້າ, ຂໍ້ມູນຕະຫຼາດແລະຜູ້ໃຫ້ບໍລິການ. ເຄື່ອງແມ່ຂ່າຍ  Meta Trader5 ແລະ5 ແມ່ນຂ້າມເຊື່ອມຕໍ່ກັບເຄືອຂ່າຍຂອງພວກເຮົາເພື່ອຮັບປະກັນແລະການປະຕິບັດການຄ້າຂອງທ່ານໄດ້ໄວ \nເຄື່ອງແມ່ຂ່າຍການຄ້າຂອງ IUX Markets  Meta Trader5 ມີຄວາມຍືດຍຸ່ນໂດຍສະເລ່ຍຕໍ່າກວ່າ 1 ມິນລິລິດຕໍ່ VPS ຫຼັກຜູ້ໃຫ້ບໍລິການຈັດສັນຢູ່ສູນຂໍ້ມູນ NY4 ຫຼືຜ່ານສາຍທີ່ອຸທິດໃຫ້ສູນຂໍ້ມູນໃກ້ຄຽງ ສະພາບແວດລ້ອມທີ່ຕ່ຳຊ້ານີ້ເໝາະສຳລັບການຊື້ຂາຍອັດຕະໂນມັດແລະຄວາມຖີ່ສູງລວມທັງscalping`,
        titile_2: `ສະ​ເປດເລີ່ມຕົ້ນ​ທີ່ 0.2 PIPs`,
        description_2: `ບໍລິສັດ IUX Markets ມີບາງການແຜ່ກະຈາຍທີ່ແຄບທີ່ສຸດຂອງບັນດານາຍໜ້າຊື້ຂາຍ CFDs ທົ່ວໂລກ. ການແຜ່ກະຈາຍເລີ່ມຕົ້ນທີ່ 0.2 PIPs ໃນເວທີ  Meta Trader5 ໂດຍສະເລ່ຍຕໍ່ EURUSD ແມ່ນ 0.2 PIPs 24/7. ປະຈຸບັນນີ້ແມ່ນ ໜຶ່ງ ໃນ EURUSD ສະເລ່ຍທີ່ເຄັ່ງຄັດທີ່ສຸດທີ່ແຜ່ລາມໄປທົ່ວໂລກ \nຕົວເຊື່ອມຕໍ່ລາຄາຂອງພວກເຮົາ, ລວບລວມການຜະສົມຜະສານຈາກຜູ້ໃຫ້ບໍລິການລາຄາທີ່ແຕກຕ່າງກັນເຖິງ 25 ຄົນ, ນັ້ນ ໝາຍ ຄວາມວ່າພວກເຮົາສາມາດສະ ເໜີ ລາຄາທີ່ດີທີ່ສຸດ ສຳ ລັບລູກຄ້າຂອງພວກເຮົາແລະຮັກສາການແຜ່ກະຈາຍຂອງພວກເຮົາໃຫ້ ແໜ້ນ ແຟ້ນ, ໂດຍສະເພາະໃນຊ່ວງເວລາທີ່ມີການໂດຍສະເພາະໃນຊ່ວງເວລາທີ່ມີການເໜັງຕີງສູງເຊັ່ນການປະກາດຂ່າວ`,
        titile_3: `ບໍ່ມີຂໍ້ຈຳກັດໃນການຊື້ - ອະນຸຍາດ scalping`,
        description_3: `ແພລະຕະຟອມ IUX Markets  Meta Trader5 ບໍ່ມີຂໍ້ຈຳກັດໃນການຊື້ຂາຍພວກເຮົາມີເງື່ອນໄຂການຄ້າທີ່ດີທີ່ສຸດສຳລັບ scalping ແລະການຊື້ຂາຍຄວາມຖີ່ສູງໃນທົ່ວໂລກ ອະນຸຍາດໃຫ້ເທຮດເດ້ວາງຄຳສັ່ງລະຫວ່າງການແຜ່ກະຈາຍຍ້ອນວ່າບໍ່ມີໄລຍະຫ່າງໃນການສັ່ງຊື້ຂັ້ນຕ່ຳແລະລະດັບຄົງທີ່ຂອງ 0. ນີ້ຫມາຍຄວາມວ່າຄໍາສັ່ງລວມທັງຄໍາສັ່ງການສູນເສຍຢຸດສາມາດຖືກຈັດໃສ່ກັບລາຄາຕະຫຼາດຕາມທີ່ທ່ານຕ້ອງການ \nເທຮດເດ້ຍັງສາມາດກີດກັນຕຳແໜ່ງໄດ້ຍ້ອນວ່າບໍ່ມີກົດລະບຽບທຳອິດໃນອອກທຳອິດ (FIFO) ກັບ IUX Markets ຜູ້ຄ້າຂາຍບໍ່ໄດ້ຈ່າຍເງິນໃຫ້ກັບການຊື້ຂາຍທີ່ຖືກກັກຂັງແລະເພີດເພີນກັບຜົນປະໂຫຍດຈາກການຄ້າຕາໜ່າງ`,
        titile_4: `ລາຄາລະດັບ II - ຄວາມເລິກຂອງຕະຫຼາດ`,
        description_4: `ຄວາມເລິກຂອງຕະຫຼາດສະແດງລາຄາທັງໝົດນັ້ນແມ່ນດຳເນີນໂດຍກົງໂດຍຜູ້ໃຫ້ບໍລິການດ້ານລາຄາຂອງພວກເຮົາ ຄວາມເລິກຂອງຕະຫຼາດສະແດງໃຫ້ເຫັນຄວາມໂປ່ງໃສຂອງແຕ່ລະຄູ່ເງີນໂດຍການສະແດງປະລິມານທີ່ມີຢູ່ ສຳລັບແຕ່ລະລະດັບລາຄາໃນແຕ່ລະຊ່ວງເວລາ ສະພາບຄ່ອງສູງ, ລາຄາຈຸດທີ່ບໍ່ສະໝໍ່າສະເໝີແລະລາຄາຊ້າຈະຮັບປະກັນສະ​ເປດ​ທີ່ຕໍ່າທີ່ສຸດ`,
      },
      section_4: {
        titile_1: `ທາງເລືອກໃນການເພີ່ມເງິນເຂົ້າບັນຊີແລະການຖອນເງິນແບບຍືດຫຍຸ່ນ`,
        description_1: `ເມື່ອທ່ານເປີດບັນຊີຂອງທ່ານທ່ານສາມາດລະດົມທຶນໂດຍໃຊ້ທາງເລືອກໃນການສະໜອງທຶນຂອງພວກເຮົາລວມທັງ: ບັດເຄດິດ / ບັດເດບິດ, Skrill, PayPal, Neteller, ລະຫັດໄທ QR, ແລະໂບຣກເກີ່ໂອນເງິນໃຫ້ນາຍ ໜ້າ, ຝາກເງິນ`,
        titile_2: `ຂະໜາດ Lot ຍືດຫຍຸ່ນ`,
        description_2: `ບໍ່ມີຂໍ້ຈຳກັດຫລືຂໍ້ຈຳກັດກ່ຽວກັບຂະໜາດການຄ້າ ທ່ານສາມາດຊື້ຂາຍເປັນຂະໜາດນ້ອຍເທົ່າກັບຈຸນລະພາກ ໜຶ່ງ (0.01)  ການປັບຂະໜາດ ຫຼາຍແບບຍືດຫຍຸ່ນຂອງພວກເຮົາຊ່ວຍໃຫ້ທ່ານສາມາດທົດລອງເວທີດ້ວຍຄວາມສ່ຽງ ໜ້ອຍທີ່ສຸດແລະຄຸ້ມຄອງຂະໜາດການຄ້າຂອງທ່ານໂດຍອີງຕາມຍອດເງິນຂອງບັນຊີຂອງທ່ານ`,
      },
      section_5: {
        titile_1: `64 ສະກຸນເງິນແລະໂລຫະ + 15 CFDs`,
        description_1: `ການຄ້າ 64 ຄູ່ສະກຸນເງິນບວກກັບ 15 ຕົວຊີ້ວັດຮຸ້ນທີ່ໃຫຍ່ລວມທັງ UK 100, S & P / AUS 200 ແລະດັດສະນີ Dow Jones ຕະຫຼອດ 24 ຊົ່ວໂມງຕໍ່ມື້ດ້ວຍການແຜ່ກະຈາຍ 1 ຈຸດ`,
        titile_2: `ການ​ເພີ່ມ​ຄວາມ​ສາມາດ 1:3000`,
        description_2: `ບັນຊີເພີ່ມຄວາມ​ສາມາດສູງສຸດ 1: 2000 ໃນເວທີ IUX Markets  Meta Trader5 ເທຮດເດ້ສາມາດໃຊ້ການ​ເພີ່ມ​ຄວາມ​ສາມາດເພື່ອໃຫ້ ເໝາະ ສົມກັບຮູບແບບການຄ້າຂອງພວກເຂົາແລະໄດ້ຮັບຜົນດີທີ່ສຸດຈາກກົນລະຍຸດການຄ້າທີ່ມີຄູ່ມືແລະອັດຕະໂນມັດ`,
        titile_3: `ສະໜັບສະໜູນທຸກໆສະກຸນເງິນຕົ້ນຕໍໃນບັນຊີ`,
        description_3: `ພວກເຮົາຮູ້ວ່າເທຮດເດ້ບາງຄົນມັກຈັດການກັບສະກຸນເງິນທ້ອງຖິ່ນຂອງພວກເຂົາ. ພວກເຮົາໃຫ້ເທຮດເດ້ມີທາງເລືອກໃນການເປີດບັນຊີໃນ 1 ໃນ 10 ສະກຸນເງິນທີ່ຮອງຮັບ: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD`,
      },
    },
    bonus: {
      box_1: {
        titile_1: `ການຄ້າຂາຍ CFDs`,
        titile_2: `ໂບນັດ $ 35`,
        titile_3: `ບໍ່ເສຍຄ່າຄ່າທຳນຽມ`,
        titile_4: `ການປະຕິບັດຄໍາສັ່ງໄວ`,
        titile_5: `1:1000 ການ​ເພີ່ມ​ຄວາມ​ສາມາດ`,
        titile_6: `ສະພາບຄ່ອງສູງ`,
        titile_7: ` Meta Trader5`,
      },
      section_1: {
        titile_1: `ເປັນຫຍັງເລືອກບັນຊີມາດຕະຖານຂອງພວກເຮົາ?`,
        description_1: `ບັນຊີໂບນັດມາດຕະຖານຈາກ IUX Markets ໃຫ້ຄວາມໄວໃນການປະຕິບັດລວມກັບສະ​ເປດ​ທີ່ຕໍ່າລວມທັງໝົດ ເລີ່ມຕັ້ງແຕ່ 1 PIPs ເຄື່ອງແມ່ຂ່າຍຂອງ Equinix LD4 ໃນລອນດອນ, ເປັນກຸ່ມຂອງຜູ້ໃຫ້ບໍລິການລາຄາເຖິງ 25 ທ່ານແລະທ່ານຈະເຂົ້າໃຈວ່າເປັນຫຍັງບັນຊີມາດຕະຖານຂອງພວກເຮົາສາມາດໃຫ້ທ່ານມີປະສົບການການຄ້າທີ່ບໍ່ມີການທຽບເທົ່າທຸກໆມື້`,
      },
      section_2: {
        titile_1: `ການປະຕິບັດຄໍາສັ່ງໄວ`,
        description_1: `ເຄື່ອງແມ່ຂ່າຍຂອງ IUX Markets  Meta Trader5 ແມ່ນຕັ້ງຢູ່ໃນສູນຂໍ້ມູນຂອງ Equinix LD4 ໃນລອນດອນ. ສູນຂໍ້ມູນ NY4 ທີ່ກ່າວເຖິງເປັນລະບົບນິເວດທາງການເງິນເປັນບ່ອນຢູ່ອາໃສຂອງຜູ້ຊື້ແລະຂາຍບໍລິສັດຂ້າງຄຽງຫຼາຍກວ່າ 600 ແຫ່ງ, ການແລກປ່ຽນ, ສະຖານທີ່ການຄ້າ, ຂໍ້ມູນຕະຫຼາດແລະຜູ້ໃຫ້ບໍລິການ. ເຄື່ອງແມ່ຂ່າຍ  Meta Trader5 ແລະ5 ແມ່ນຂ້າມເຊື່ອມຕໍ່ກັບເຄືອຂ່າຍຂອງພວກເຮົາເພື່ອຮັບປະກັນແລະການປະຕິບັດການຄ້າຂອງທ່ານໄດ້ໄວ

        \nເຄື່ອງແມ່ຂ່າຍການຄ້າຂອງ IUX Markets  Meta Trader5 ມີຄວາມຍືດຍຸ່ນໂດຍສະເລ່ຍຕໍ່າກວ່າ 1 ມິນລິລິດຕໍ່ VPS ຫຼັກຜູ້ໃຫ້ບໍລິການຈັດສັນຢູ່ສູນຂໍ້ມູນ NY4 ຫຼືຜ່ານສາຍທີ່ອຸທິດໃຫ້ສູນຂໍ້ມູນໃກ້ຄຽງ
        ສະພາບແວດລ້ອມທີ່ຕ່ຳຊ້ານີ້ເໝາະສຳລັບການຊື້ຂາຍອັດຕະໂນມັດແລະຄວາມຖີ່ສູງລວມທັງscalping`,
      },
      section_3: {
        titile_1: `ບໍ່ມີຂໍ້ຈຳກັດໃນການຊື້ - ອະນຸຍາດ scalping`,
        description_1: `ແພລະຕະຟອມ IUX Markets  Meta Trader5 ບໍ່ມີຂໍ້ຈຳກັດໃນການຊື້ຂາຍພວກເຮົາມີເງື່ອນໄຂການຄ້າທີ່ດີທີ່ສຸດສຳລັບ scalping ແລະການຊື້ຂາຍຄວາມຖີ່ສູງໃນທົ່ວໂລກ
        ອະນຸຍາດໃຫ້ເທຮດເດ້ວາງຄຳສັ່ງລະຫວ່າງການແຜ່ກະຈາຍຍ້ອນວ່າບໍ່ມີໄລຍະຫ່າງໃນການສັ່ງຊື້ຂັ້ນຕ່ຳແລະລະດັບຄົງທີ່ຂອງ 0.
        ນີ້ຫມາຍຄວາມວ່າຄໍາສັ່ງລວມທັງຄໍາສັ່ງການສູນເສຍຢຸດສາມາດຖືກຈັດໃສ່ກັບລາຄາຕະຫຼາດຕາມທີ່ທ່ານຕ້ອງການ
        
        \nເທຮດເດ້ຍັງສາມາດກີດກັນຕຳແໜ່ງໄດ້ຍ້ອນວ່າບໍ່ມີກົດລະບຽບທຳອິດໃນອອກທຳອິດ (FIFO) ກັບ IUX Markets ຜູ້ຄ້າຂາຍບໍ່ໄດ້ຈ່າຍເງິນໃຫ້ກັບການຊື້ຂາຍທີ່ຖືກກັກຂັງແລະເພີດເພີນກັບຜົນປະໂຫຍດຈາກການຄ້າຕາໜ່າງ`,
        titile_2: `ລາຄາລະດັບ II - ຄວາມເລິກຂອງຕະຫຼາດ`,
        description_2: `ຄວາມເລິກຂອງຕະຫຼາດສະແດງລາຄາທັງໝົດນັ້ນແມ່ນດຳເນີນໂດຍກົງໂດຍຜູ້ໃຫ້ບໍລິການດ້ານລາຄາຂອງພວກເຮົາ ຄວາມເລິກຂອງຕະຫຼາດສະແດງໃຫ້ເຫັນຄວາມໂປ່ງໃສຂອງແຕ່ລະຄູ່ເງີນໂດຍການສະແດງປະລິມານທີ່ມີຢູ່ ສຳລັບແຕ່ລະລະດັບລາຄາໃນແຕ່ລະຊ່ວງເວລາສະພາບຄ່ອງສູງ, ລາຄາຈຸດທີ່ບໍ່ສະໝໍ່າສະເໝີແລະລາຄາຊ້າຈະຮັບປະກັນສະ​ເປດ​ທີ່ຕໍ່າທີ່ສຸດ`,
      },
      section_4: {
        titile_1: `64 ສະກຸນເງິນແລະໂລຫະ + 15 CFDs`,
        description_1: `ການຄ້າ 64 ຄູ່ສະກຸນເງິນບວກກັບ 15 ຕົວຊີ້ວັດຮຸ້ນທີ່ໃຫຍ່ລວມທັງ UK 100, S & P / AUS 200 ແລະດັດສະນີ Dow Jones ຕະຫຼອດ 24 ຊົ່ວໂມງຕໍ່ມື້ດ້ວຍການແຜ່ກະຈາຍ 1 ຈຸດ.`,
        titile_2: `ການ​ເພີ່ມ​ຄວາມ​ສາມາດ 1:1000`,
        description_2: `ບັນຊີເພີ່ມຄວາມ​ສາມາດສູງສຸດ 1: 1000 ໃນເວທີ IUX Markets  Meta Trader5. ເທຮດເດ້ສາມາດໃຊ້ການ​ເພີ່ມ​ຄວາມ​ສາມາດເພື່ອໃຫ້ ເໝາະ ສົມກັບຮູບແບບການຄ້າຂອງພວກເຂົາແລະໄດ້ຮັບຜົນດີທີ່ສຸດຈາກກົນລະຍຸດການຄ້າທີ່ມີຄູ່ມືແລະອັດຕະໂນມັດ`,
        titile_3: `ສະໜັບສະໜູນທຸກໆສະກຸນເງິນຕົ້ນຕໍໃນບັນຊີ`,
        description_3: `ພວກເຮົາຮູ້ວ່າເທຮດເດ້ບາງຄົນມັກຈັດການກັບສະກຸນເງິນທ້ອງຖິ່ນຂອງພວກເຂົາ ພວກເຮົາໃຫ້ເທຮດເດ້ມີທາງເລືອກໃນການເປີດບັນຊີໃນ 1 ໃນ 10 ສະກຸນເງິນທີ່ຮອງຮັບ: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD`,
      },
    },
    accecn: {
      box_1: {
        titile_1: `CFDs Trading `,
        titile_2: `ການຄ້າຫລາຍກວ່າ 90 ສິ່ງ`,
        titile_3: `ຄ່າ ທຳ ນຽມຄະນະ ກຳ ມະການ 3.5 $`,
        titile_4: `ການປະຕິບັດຄໍາສັ່ງໄວ`,
        titile_5: `1:3000 ການ​ເພີ່ມ​ຄວາມ​ສາມາດ`,
        titile_6: `ສະ​ເປດຕ່ຳເຖິງ 0.0 PIPs`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `Why Choose Our ECN Account?`,
        description_1: `ບັນຊີ ECN ຈາກ IUX Markets ໃຫ້ຄວາມໄວໃນການປະຕິບັດໂດຍບໍ່ມີຄູ່ແຂ່ງກັບສະ​ເປດ​ທີ່ຕໍ່າລວມທັງໝົດ ເລີ່ມຕັ້ງແຕ່ 1 PIPs ເຄື່ອງແມ່ຂ່າຍຂອງ Equinix LD4 ໃນລອນດອນ, ເປັນກຸ່ມຂອງຜູ້ໃຫ້ບໍລິການລາຄາເຖິງ 25 ທ່ານແລະທ່ານຈະເຂົ້າໃຈວ່າເປັນຫຍັງບັນຊີມາດຕະຖານຂອງພວກເຮົາສາມາດໃຫ້ທ່ານມີປະສົບການການຄ້າທີ່ບໍ່ມີການທຽບເທົ່າ, ທຸກໆມື້`,
      },
      section_2: {
        titile_1: `ການປະຕິບັດຄໍາສັ່ງໄວ`,
        description_1: `ເຄື່ອງແມ່ຂ່າຍຂອງ IUX Markets  Meta Trader5 ແມ່ນຕັ້ງຢູ່ໃນສູນຂໍ້ມູນຂອງ Equinix LD4 ໃນລອນດອນ. ສູນຂໍ້ມູນ NY4 ທີ່ກ່າວເຖິງເປັນລະບົບນິເວດທາງການເງິນເປັນບ່ອນຢູ່ອາໃສຂອງຜູ້ຊື້ແລະຂາຍບໍລິສັດຂ້າງຄຽງຫຼາຍກວ່າ 600 ແຫ່ງ, ການແລກປ່ຽນ, ສະຖານທີ່ການຄ້າ, ຂໍ້ມູນຕະຫຼາດແລະຜູ້ໃຫ້ບໍລິການ. ເຄື່ອງແມ່ຂ່າຍ  Meta Trader5 ແລະ5 ແມ່ນຂ້າມເຊື່ອມຕໍ່ກັບເຄືອຂ່າຍຂອງພວກເຮົາເພື່ອຮັບປະກັນແລະການປະຕິບັດການຄ້າຂອງທ່ານໄດ້ໄວ

        \nເຄື່ອງແມ່ຂ່າຍການຄ້າຂອງ IUX Markets  Meta Trader5 ມີຄວາມຍືດຍຸ່ນໂດຍສະເລ່ຍຕໍ່າກວ່າ 1 ມິນລິລິດຕໍ່ VPS ຫຼັກຜູ້ໃຫ້ບໍລິການຈັດສັນຢູ່ສູນຂໍ້ມູນ NY4 ຫຼືຜ່ານສາຍທີ່ອຸທິດໃຫ້ສູນຂໍ້ມູນໃກ້ຄຽງ
        ສະພາບແວດລ້ອມທີ່ຕ່ຳຊ້ານີ້ເໝາະສຳລັບການຊື້ຂາຍອັດຕະໂນມັດແລະຄວາມຖີ່ສູງລວມທັງscalping`,
      },
      section_3: {
        titile_1: `ບໍ່ມີຂໍ້ຈຳກັດໃນການຊື້ - ອະນຸຍາດ scalping`,
        description_1: `ແພລະຕະຟອມ IUX Markets  Meta Trader5 ບໍ່ມີຂໍ້ຈຳກັດໃນການຊື້ຂາຍພວກເຮົາມີເງື່ອນໄຂການຄ້າທີ່ດີທີ່ສຸດສຳລັບ scalping ແລະການຊື້ຂາຍຄວາມຖີ່ສູງໃນທົ່ວໂລກ
        ອະນຸຍາດໃຫ້ເທຮດເດ້ວາງຄຳສັ່ງລະຫວ່າງການແຜ່ກະຈາຍຍ້ອນວ່າບໍ່ມີໄລຍະຫ່າງໃນການສັ່ງຊື້ຂັ້ນຕ່ຳແລະລະດັບຄົງທີ່ຂອງ 0.
        ນີ້ຫມາຍຄວາມວ່າຄໍາສັ່ງລວມທັງຄໍາສັ່ງການສູນເສຍຢຸດສາມາດຖືກຈັດໃສ່ກັບລາຄາຕະຫຼາດຕາມທີ່ທ່ານຕ້ອງການ
        
        \nເທຮດເດ້ຍັງສາມາດກີດກັນຕຳແໜ່ງໄດ້ຍ້ອນວ່າບໍ່ມີກົດລະບຽບທຳອິດໃນອອກທຳອິດ (FIFO) ກັບ IUX Markets ຜູ້ຄ້າຂາຍບໍ່ໄດ້ຈ່າຍເງິນໃຫ້ກັບການຊື້ຂາຍທີ່ຖືກກັກຂັງແລະເພີດເພີນກັບຜົນປະໂຫຍດຈາກການຄ້າຕາໜ່າງ`,
        titile_2: `ລາຄາລະດັບ II - ຄວາມເລິກຂອງຕະຫຼາດ`,
        description_2: `ຄວາມເລິກຂອງຕະຫຼາດສະແດງລາຄາທັງໝົດນັ້ນແມ່ນດຳເນີນໂດຍກົງໂດຍຜູ້ໃຫ້ບໍລິການດ້ານລາຄາຂອງພວກເຮົາ ຄວາມເລິກຂອງຕະຫຼາດສະແດງໃຫ້ເຫັນຄວາມໂປ່ງໃສຂອງແຕ່ລະຄູ່ເງີນໂດຍການສະແດງປະລິມານທີ່ມີຢູ່ ສຳລັບແຕ່ລະລະດັບລາຄາໃນແຕ່ລະຊ່ວງເວລາສະພາບຄ່ອງສູງ, ລາຄາຈຸດທີ່ບໍ່ສະໝໍ່າສະເໝີແລະລາຄາຊ້າຈະຮັບປະກັນສະ​ເປດ​ທີ່ຕໍ່າທີ່ສຸດ`,
      },
      section_4: {
        titile_1: `64 ສະກຸນເງິນແລະໂລຫະ + 15 CFDs`,
        description_1: `ການຄ້າ 64 ຄູ່ສະກຸນເງິນບວກກັບ 15 ຕົວຊີ້ວັດຮຸ້ນທີ່ໃຫຍ່ລວມທັງ UK 100, S & P / AUS 200 ແລະດັດສະນີ Dow Jones ຕະຫຼອດ 24 ຊົ່ວໂມງຕໍ່ມື້ດ້ວຍການແຜ່ກະຈາຍ 1 ຈຸດ.`,
        titile_2: `ການ​ເພີ່ມ​ຄວາມ​ສາມາດ 1:3000`,
        description_2: `ບັນຊີເພີ່ມຄວາມ​ສາມາດສູງສຸດ 1: 3000 ໃນເວທີ IUX Markets  Meta Trader5. ເທຮດເດ້ສາມາດໃຊ້ການ​ເພີ່ມ​ຄວາມ​ສາມາດເພື່ອໃຫ້ ເໝາະສົມກັບຮູບແບບການຄ້າຂອງພວກເຂົາແລະໄດ້ຮັບຜົນດີທີ່ສຸດຈາກກົນລະຍຸດການຄ້າທີ່ມີຄູ່ມືແລະອັດຕະໂນມັດ`,
        titile_3: `ສະໜັບສະໜູນທຸກໆສະກຸນເງິນຕົ້ນຕໍໃນບັນຊີ`,
        description_3: `ພວກເຮົາຮູ້ວ່າເທຮດເດ້ບາງຄົນມັກຈັດການກັບສະກຸນເງິນທ້ອງຖິ່ນຂອງພວກເຂົາ ພວກເຮົາໃຫ້ເທຮດເດ້ມີທາງເລືອກໃນການເປີດບັນຊີໃນ 1 ໃນ 10 ສະກຸນເງິນທີ່ຮອງຮັບ: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD`,
      },
    },
    accraw: {
      box_1: {
        titile_1: `CFDs Trading `,
        titile_2: `ການຄ້າຫລາຍກວ່າ 90 ສິ່ງ`,
        titile_3: `ຄ່າ ທຳ ນຽມຄະນະ ກຳ ມະການ $6`,
        titile_4: `ການປະຕິບັດຄໍາສັ່ງໄວ`,
        titile_5: `1:3000 ການ​ເພີ່ມ​ຄວາມ​ສາມາດ`,
        titile_6: `ສະ​ເປດຕ່ຳເຖິງ 0.0 PIPs`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `ຫາກ່ອນເລືອກ Raw`,
        description_1: `ບັນຊີ Raw ຈາກ IUX Markets ໃຫ້ຄວາມໄວໃນການປະຕິບັດໂດຍບໍ່ມີຄູ່ແຂ່ງກັບສະ​ເປດ​ທີ່ຕໍ່າລວມທັງໝົດ ເລີ່ມຕັ້ງແຕ່ 0.0 PIPs ເຄື່ອງແມ່ຂ່າຍຂອງ Equinix LD4 ໃນລອນດອນ, ເປັນກຸ່ມຂອງຜູ້ໃຫ້ບໍລິການລາຄາເຖິງ 25 ທ່ານແລະທ່ານຈະເຂົ້າໃຈວ່າເປັນຫຍັງບັນຊີມາດຕະຖານຂອງພວກເຮົາສາມາດໃຫ້ທ່ານມີປະສົບການການຄ້າທີ່ບໍ່ມີການທຽບເທົ່າ, ທຸກໆມື້`,
      },
      section_2: {
        titile_1: `ການປະຕິບັດຄໍາສັ່ງໄວ`,
        description_1: `ເຄື່ອງແມ່ຂ່າຍຂອງ IUX Markets  Meta Trader5 ແມ່ນຕັ້ງຢູ່ໃນສູນຂໍ້ມູນຂອງ Equinix LD4 ໃນລອນດອນ. ສູນຂໍ້ມູນ NY4 ທີ່ກ່າວເຖິງເປັນລະບົບນິເວດທາງການເງິນເປັນບ່ອນຢູ່ອາໃສຂອງຜູ້ຊື້ແລະຂາຍບໍລິສັດຂ້າງຄຽງຫຼາຍກວ່າ 600 ແຫ່ງ, ການແລກປ່ຽນ, ສະຖານທີ່ການຄ້າ, ຂໍ້ມູນຕະຫຼາດແລະຜູ້ໃຫ້ບໍລິການ. ເຄື່ອງແມ່ຂ່າຍ  Meta Trader5 ແລະ5 ແມ່ນຂ້າມເຊື່ອມຕໍ່ກັບເຄືອຂ່າຍຂອງພວກເຮົາເພື່ອຮັບປະກັນແລະການປະຕິບັດການຄ້າຂອງທ່ານໄດ້ໄວ

        \nເຄື່ອງແມ່ຂ່າຍການຄ້າຂອງ IUX Markets  Meta Trader5 ມີຄວາມຍືດຍຸ່ນໂດຍສະເລ່ຍຕໍ່າກວ່າ 1 ມິນລິລິດຕໍ່ VPS ຫຼັກຜູ້ໃຫ້ບໍລິການຈັດສັນຢູ່ສູນຂໍ້ມູນ NY4 ຫຼືຜ່ານສາຍທີ່ອຸທິດໃຫ້ສູນຂໍ້ມູນໃກ້ຄຽງ
        ສະພາບແວດລ້ອມທີ່ຕ່ຳຊ້ານີ້ເໝາະສຳລັບການຊື້ຂາຍອັດຕະໂນມັດແລະຄວາມຖີ່ສູງລວມທັງscalping`,
      },
      section_3: {
        titile_1: `ບໍ່ມີຂໍ້ຈຳກັດໃນການຊື້ - ອະນຸຍາດ scalping`,
        description_1: `ແພລະຕະຟອມ IUX Markets  Meta Trader5 ບໍ່ມີຂໍ້ຈຳກັດໃນການຊື້ຂາຍພວກເຮົາມີເງື່ອນໄຂການຄ້າທີ່ດີທີ່ສຸດສຳລັບ scalping ແລະການຊື້ຂາຍຄວາມຖີ່ສູງໃນທົ່ວໂລກ
        ອະນຸຍາດໃຫ້ເທຮດເດ້ວາງຄຳສັ່ງລະຫວ່າງການແຜ່ກະຈາຍຍ້ອນວ່າບໍ່ມີໄລຍະຫ່າງໃນການສັ່ງຊື້ຂັ້ນຕ່ຳແລະລະດັບຄົງທີ່ຂອງ 0.
        ນີ້ຫມາຍຄວາມວ່າຄໍາສັ່ງລວມທັງຄໍາສັ່ງການສູນເສຍຢຸດສາມາດຖືກຈັດໃສ່ກັບລາຄາຕະຫຼາດຕາມທີ່ທ່ານຕ້ອງການ
        
        \nເທຮດເດ້ຍັງສາມາດກີດກັນຕຳແໜ່ງໄດ້ຍ້ອນວ່າບໍ່ມີກົດລະບຽບທຳອິດໃນອອກທຳອິດ (FIFO) ກັບ IUX Markets ຜູ້ຄ້າຂາຍບໍ່ໄດ້ຈ່າຍເງິນໃຫ້ກັບການຊື້ຂາຍທີ່ຖືກກັກຂັງແລະເພີດເພີນກັບຜົນປະໂຫຍດຈາກການຄ້າຕາໜ່າງ`,
        titile_2: `ລາຄາລະດັບ II - ຄວາມເລິກຂອງຕະຫຼາດ`,
        description_2: `ຄວາມເລິກຂອງຕະຫຼາດສະແດງລາຄາທັງໝົດນັ້ນແມ່ນດຳເນີນໂດຍກົງໂດຍຜູ້ໃຫ້ບໍລິການດ້ານລາຄາຂອງພວກເຮົາ ຄວາມເລິກຂອງຕະຫຼາດສະແດງໃຫ້ເຫັນຄວາມໂປ່ງໃສຂອງແຕ່ລະຄູ່ເງີນໂດຍການສະແດງປະລິມານທີ່ມີຢູ່ ສຳລັບແຕ່ລະລະດັບລາຄາໃນແຕ່ລະຊ່ວງເວລາສະພາບຄ່ອງສູງ, ລາຄາຈຸດທີ່ບໍ່ສະໝໍ່າສະເໝີແລະລາຄາຊ້າຈະຮັບປະກັນສະ​ເປດ​ທີ່ຕໍ່າທີ່ສຸດ`,
      },
      section_4: {
        titile_1: `64 ສະກຸນເງິນແລະໂລຫະ + 15 CFDs`,
        description_1: `ການຄ້າ 64 ຄູ່ສະກຸນເງິນບວກກັບ 15 ຕົວຊີ້ວັດຮຸ້ນທີ່ໃຫຍ່ລວມທັງ UK 100, S & P / AUS 200 ແລະດັດສະນີ Dow Jones ຕະຫຼອດ 24 ຊົ່ວໂມງຕໍ່ມື້ດ້ວຍການແຜ່ກະຈາຍ 1 ຈຸດ.`,
        titile_2: `ການ​ເພີ່ມ​ຄວາມ​ສາມາດ 1:3000`,
        description_2: `ບັນຊີເພີ່ມຄວາມ​ສາມາດສູງສຸດ 1: 3000 ໃນເວທີ IUX Markets  Meta Trader5. ເທຮດເດ້ສາມາດໃຊ້ການ​ເພີ່ມ​ຄວາມ​ສາມາດເພື່ອໃຫ້ ເໝາະສົມກັບຮູບແບບການຄ້າຂອງພວກເຂົາແລະໄດ້ຮັບຜົນດີທີ່ສຸດຈາກກົນລະຍຸດການຄ້າທີ່ມີຄູ່ມືແລະອັດຕະໂນມັດ`,
        titile_3: `ສະໜັບສະໜູນທຸກໆສະກຸນເງິນຕົ້ນຕໍໃນບັນຊີ`,
        description_3: `ພວກເຮົາຮູ້ວ່າເທຮດເດ້ບາງຄົນມັກຈັດການກັບສະກຸນເງິນທ້ອງຖິ່ນຂອງພວກເຂົາ ພວກເຮົາໃຫ້ເທຮດເດ້ມີທາງເລືອກໃນການເປີດບັນຊີໃນ 1 ໃນ 10 ສະກຸນເງິນທີ່ຮອງຮັບ: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD`,
      },
    },
    accpro: {
      box_1: {
        titile_1: `CFDs Trading `,
        titile_2: `ການຄ້າຫລາຍກວ່າ 90 ສິ່ງ`,
        titile_3: `ຄ່າ ທຳ ນຽມຄະນະ ກຳ ມະການ $0`,
        titile_4: `ການປະຕິບັດຄໍາສັ່ງໄວ`,
        titile_5: `1:3000 ການ​ເພີ່ມ​ຄວາມ​ສາມາດ`,
        titile_6: `ສະ​ເປດຕ່ຳເຖິງ 0.1 PIPs`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `ຫາກ່ອນເລືອກ Pro`,
        description_1: `ບັນຊີ Pro ຈາກ IUX Markets ໃຫ້ຄວາມໄວໃນການປະຕິບັດໂດຍບໍ່ມີຄູ່ແຂ່ງກັບສະ​ເປດ​ທີ່ຕໍ່າລວມທັງໝົດ ເລີ່ມຕັ້ງແຕ່ 0.0 PIPs ເຄື່ອງແມ່ຂ່າຍຂອງ Equinix LD4 ໃນລອນດອນ, ເປັນກຸ່ມຂອງຜູ້ໃຫ້ບໍລິການລາຄາເຖິງ 25 ທ່ານແລະທ່ານຈະເຂົ້າໃຈວ່າເປັນຫຍັງບັນຊີມາດຕະຖານຂອງພວກເຮົາສາມາດໃຫ້ທ່ານມີປະສົບການການຄ້າທີ່ບໍ່ມີການທຽບເທົ່າ, ທຸກໆມື້`,
      },
      section_2: {
        titile_1: `ການປະຕິບັດຄໍາສັ່ງໄວ`,
        description_1: `ເຄື່ອງແມ່ຂ່າຍຂອງ IUX Markets  Meta Trader5 ແມ່ນຕັ້ງຢູ່ໃນສູນຂໍ້ມູນຂອງ Equinix LD4 ໃນລອນດອນ. ສູນຂໍ້ມູນ NY4 ທີ່ກ່າວເຖິງເປັນລະບົບນິເວດທາງການເງິນເປັນບ່ອນຢູ່ອາໃສຂອງຜູ້ຊື້ແລະຂາຍບໍລິສັດຂ້າງຄຽງຫຼາຍກວ່າ 600 ແຫ່ງ, ການແລກປ່ຽນ, ສະຖານທີ່ການຄ້າ, ຂໍ້ມູນຕະຫຼາດແລະຜູ້ໃຫ້ບໍລິການ. ເຄື່ອງແມ່ຂ່າຍ  Meta Trader5 ແລະ5 ແມ່ນຂ້າມເຊື່ອມຕໍ່ກັບເຄືອຂ່າຍຂອງພວກເຮົາເພື່ອຮັບປະກັນແລະການປະຕິບັດການຄ້າຂອງທ່ານໄດ້ໄວ

        \nເຄື່ອງແມ່ຂ່າຍການຄ້າຂອງ IUX Markets  Meta Trader5 ມີຄວາມຍືດຍຸ່ນໂດຍສະເລ່ຍຕໍ່າກວ່າ 1 ມິນລິລິດຕໍ່ VPS ຫຼັກຜູ້ໃຫ້ບໍລິການຈັດສັນຢູ່ສູນຂໍ້ມູນ NY4 ຫຼືຜ່ານສາຍທີ່ອຸທິດໃຫ້ສູນຂໍ້ມູນໃກ້ຄຽງ
        ສະພາບແວດລ້ອມທີ່ຕ່ຳຊ້ານີ້ເໝາະສຳລັບການຊື້ຂາຍອັດຕະໂນມັດແລະຄວາມຖີ່ສູງລວມທັງscalping`,
      },
      section_3: {
        titile_1: `ບໍ່ມີຂໍ້ຈຳກັດໃນການຊື້ - ອະນຸຍາດ scalping`,
        description_1: `ແພລະຕະຟອມ IUX Markets  Meta Trader5 ບໍ່ມີຂໍ້ຈຳກັດໃນການຊື້ຂາຍພວກເຮົາມີເງື່ອນໄຂການຄ້າທີ່ດີທີ່ສຸດສຳລັບ scalping ແລະການຊື້ຂາຍຄວາມຖີ່ສູງໃນທົ່ວໂລກ
        ອະນຸຍາດໃຫ້ເທຮດເດ້ວາງຄຳສັ່ງລະຫວ່າງການແຜ່ກະຈາຍຍ້ອນວ່າບໍ່ມີໄລຍະຫ່າງໃນການສັ່ງຊື້ຂັ້ນຕ່ຳແລະລະດັບຄົງທີ່ຂອງ 0.
        ນີ້ຫມາຍຄວາມວ່າຄໍາສັ່ງລວມທັງຄໍາສັ່ງການສູນເສຍຢຸດສາມາດຖືກຈັດໃສ່ກັບລາຄາຕະຫຼາດຕາມທີ່ທ່ານຕ້ອງການ
        
        \nເທຮດເດ້ຍັງສາມາດກີດກັນຕຳແໜ່ງໄດ້ຍ້ອນວ່າບໍ່ມີກົດລະບຽບທຳອິດໃນອອກທຳອິດ (FIFO) ກັບ IUX Markets ຜູ້ຄ້າຂາຍບໍ່ໄດ້ຈ່າຍເງິນໃຫ້ກັບການຊື້ຂາຍທີ່ຖືກກັກຂັງແລະເພີດເພີນກັບຜົນປະໂຫຍດຈາກການຄ້າຕາໜ່າງ`,
        titile_2: `ລາຄາລະດັບ II - ຄວາມເລິກຂອງຕະຫຼາດ`,
        description_2: `ຄວາມເລິກຂອງຕະຫຼາດສະແດງລາຄາທັງໝົດນັ້ນແມ່ນດຳເນີນໂດຍກົງໂດຍຜູ້ໃຫ້ບໍລິການດ້ານລາຄາຂອງພວກເຮົາ ຄວາມເລິກຂອງຕະຫຼາດສະແດງໃຫ້ເຫັນຄວາມໂປ່ງໃສຂອງແຕ່ລະຄູ່ເງີນໂດຍການສະແດງປະລິມານທີ່ມີຢູ່ ສຳລັບແຕ່ລະລະດັບລາຄາໃນແຕ່ລະຊ່ວງເວລາສະພາບຄ່ອງສູງ, ລາຄາຈຸດທີ່ບໍ່ສະໝໍ່າສະເໝີແລະລາຄາຊ້າຈະຮັບປະກັນສະ​ເປດ​ທີ່ຕໍ່າທີ່ສຸດ`,
      },
      section_4: {
        titile_1: `64 ສະກຸນເງິນແລະໂລຫະ + 15 CFDs`,
        description_1: `ການຄ້າ 64 ຄູ່ສະກຸນເງິນບວກກັບ 15 ຕົວຊີ້ວັດຮຸ້ນທີ່ໃຫຍ່ລວມທັງ UK 100, S & P / AUS 200 ແລະດັດສະນີ Dow Jones ຕະຫຼອດ 24 ຊົ່ວໂມງຕໍ່ມື້ດ້ວຍການແຜ່ກະຈາຍ 1 ຈຸດ.`,
        titile_2: `ການ​ເພີ່ມ​ຄວາມ​ສາມາດ 1:3000`,
        description_2: `ບັນຊີເພີ່ມຄວາມ​ສາມາດສູງສຸດ 1: 3000 ໃນເວທີ IUX Markets  Meta Trader5. ເທຮດເດ້ສາມາດໃຊ້ການ​ເພີ່ມ​ຄວາມ​ສາມາດເພື່ອໃຫ້ ເໝາະສົມກັບຮູບແບບການຄ້າຂອງພວກເຂົາແລະໄດ້ຮັບຜົນດີທີ່ສຸດຈາກກົນລະຍຸດການຄ້າທີ່ມີຄູ່ມືແລະອັດຕະໂນມັດ`,
        titile_3: `ສະໜັບສະໜູນທຸກໆສະກຸນເງິນຕົ້ນຕໍໃນບັນຊີ`,
        description_3: `ພວກເຮົາຮູ້ວ່າເທຮດເດ້ບາງຄົນມັກຈັດການກັບສະກຸນເງິນທ້ອງຖິ່ນຂອງພວກເຂົາ ພວກເຮົາໃຫ້ເທຮດເດ້ມີທາງເລືອກໃນການເປີດບັນຊີໃນ 1 ໃນ 10 ສະກຸນເງິນທີ່ຮອງຮັບ: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD`,
      },
    },
    funding: {
      section_1: {
        title: `ຂ້ອຍຈະຝາກແລະຖອນໄດ້ແນວໃດ?`,
        titile_1: `ລົງທະບຽນຫລືເຂົ້າສູ່ລະບົບ`,
        titile_2: `ເລືອກທະນາຄານທີ່ທ່ານກຳລັງໃຊ້ ແລະດຳເນີນການ ຝາກ / ຖອນ`,
        titile_3: `ຝາກເງິນຟຣີ / ຄ່າທຳນຽມຖອນເງິນ`,
        titile_4: `ການຖອນໄວໃນສອງສາມນາທີ`,
      },
      section_2: {
        title: `ທາງເລືອກໃນການຝາກແລະຖອນ`,
        description: `ພວກເຮົາຮັກສາຂໍ້ມູນການເງິນຂອງທ່ານໃຫ້ປອດໄພດ້ວຍການເຂົ້າລະຫັດ ດັ່ງນັ້ນທ່ານສາມາດຈ່າຍເງິນດ້ວຍຄວາມໝັ້ນໃຈທາງອິນເຕີເນັດ`,
        option: `ຕົວເລືອກການເພີ່ມເງິນ`,
        hot: `ມາແຮງ`,
        titile_1: `Credit and Debit`,
        titile_2: `Skrill`,
        titile_3: `Neteller`,
        titile_4: `Thai QR Payment`,
        titile_5: `Online Banking`,
      },
      section_3: {
        titile_1: `ຂັ້ນຕອນການຈ່າຍເງິນ`,
        description_1: `ເພື່ອໃຫ້ການເຮັດວຽກໄວຂຶ້ນພວກເຮົາແນະນຳໃຫ້ຜູ້ຖືບັນຊີທຸກຄົນຝາກເງິນເຂົ້າບັນຊີການຄ້າຂອງພວກເຂົາຈາກເຂດຄວາມປອດໄພຂອງລູກຄ້າ, ທ່ານຈະສາມາດລະດົມທຶນໃນບັນຊີຂອງທ່ານໃນເວລາຈິງໂດຍໃຊ້ບັດເຄຼດິດແລະບັດຂອງທ່ານ. Skrill ຖ້າທ່ານຍັງບໍ່ສາມາດເຂົ້າເຖິງ Secure Client Area ກະລຸນາປະຕິບັດຕາມຄຳແນະນຳສຳລັບການເຂົ້າເຖິງ`,
        titile_2: `ຄວາມປອດໄພຂອງເງິນທຶນ`,
        description_2: `ເມື່ອສະໜອງເງິນໃຫ້ລູກຄ້າໃນບັນຊີການຄ້າຂອງທ່ານເງິນຂອງທ່ານຖືກເກັບໄວ້ໃນບັນຊີລູກຄ້າທີ່ເຊື່ອຖືແຍກຕ່າງຫາກທີ່ National Australia Bank (NAB) (NAB) ແລະ Westpac Banking Corporation (Westpac),
        ທັງສອງແມ່ນທະນາຄານອົດສະຕາລີທີ່ຖືກຈັດອັນດັບ AA ການຊຳລະເງິນແບບເອເລັກໂຕຣນິກແມ່ນຖືກດຳເນີນການໂດຍນຳ ໃຊ້ເຕັກໂນໂລຢີ SSL (Secure Socket Layer) ແລະຖືກເຂົ້າລະຫັດເພື່ອຮັບປະກັນຄວາມປອດໄພ.
        ຂໍ້ມູນການຈ່າຍເງິນທັງໝົດແມ່ນເປັນຄວາມລັບແລະໃຊ້ເພື່ອຈຸດປະສົງໃນການສະໜອງບັນຊີການຄ້າຂອງທ່ານກັບ IUX Markets ເທົ່ານັ້ນ`,
        titile_3: `ຄ່າທຳນຽມທະນາຄານ`,
        description_3: `IUX Markets ບໍ່ຄິດຄ່າທຳນຽມເພີ່ມເຕີມສຳລັບການຝາກເງິນຫຼືການຖອນເງິນເຖິງຢ່າງໃດກໍ່ຕາມທ່ານຄວນຮູ້ວ່າທ່ານອາດຈະຕ້ອງເສຍຄ່າທຳ ນຽມໃນການຈ່າຍເງິນໃຫ້ແລະຈາກສະຖາບັນການທະນາຄານລະຫວ່າງປະເທດຈຳນວນໜຶ່ງບໍລິສັດ IUX Markets ບໍ່ຮັບຜິດຊອບຕໍ່ຄ່າທຳນຽມທະນາຄານໃດໆ`,
        titile_4: `ການຈ່າຍເງິນຂອງບຸກຄົນທີສາມ`,
        description_4: `IUX Markets ບໍ່ຍອມຮັບການຈ່າຍເງິນຈາກພາກສ່ວນທີສາມກະລຸນາຮັບປະກັນວ່າເງິນຝາກທັງໝົດເຂົ້າໃນບັນຊີການຄ້າຂອງທ່ານແມ່ນມາຈາກບັນຊີທະນາຄານໃນຊື່ຂອງທ່ານ
        ການຈ່າຍເງິນຈາກບັນຊີທະນາຄານຮ່ວມ / ບັດເຄດິດແມ່ນຖືກຍອມຮັບຖ້າຜູ້ຖືບັນຊີການຄ້າແມ່ນໜຶ່ງໃນບັນດາພາກສ່ວນທີ່ຢູ່ໃນບັນຊີທະນາຄານ / ບັດເຄດິດ`,
      },
    },
    withdraw: {
      section_1: {
        title: `ເພື່ອໃຫ້ການເຮັດວຽກໄວຂຶ້ນພວກເຮົາແນະນຳໃຫ້ຜູ້ຖືບັນຊີທຸກຄົນຝາກເງິນເຂົ້າບັນຊີການຄ້າຂອງພວກເຂົາຈາກ Secure Client`,
        content: `ຖ້າທ່ານຍັງບໍ່ສາມາດເຂົ້າເຖິງ Secure Client Area ກະລຸນາປະຕິບັດຕາມຄຳແນະນຳສຳລັບການເຂົ້າເຖິງ`,
      },
      section_2: {
        title: `ຖ້າທ່ານບໍ່ໄດ້ຖອນເງິນໃນອະດີດ, ກະລຸນາຮູ້`,
        title_1: `ການຮຽກຮ້ອງຖອນເງິນຕັດເວລາແມ່ນ 12:00 AEST / AEDT. ຖ້າການຖອນເງິນຂອງທ່ານຖືກສົ່ງມາກ່ອນໃນເວລານີ້ມັນຈະຖືກປະມວນຜົນໃນມື້ທີ່ໄດ້ຮັບ.
        ຖ້າການຖອນເງິນຂອງທ່ານຖືກສົ່ງພາຍຫຼັງເວລານີ້ມັນຈະຖືກດຳເນີນການໃນມື້ເຮັດວຽກຕໍ່ໄປ`,
        title_2: `IUX Markets ບໍ່ຄິດຄ່າ ທຳ ນຽມເພີ່ມເຕີມ ສຳ ລັບການຝາກເງິນຫຼືການຖອນເງິນ. ເຖິງຢ່າງໃດກໍ່ຕາມທ່ານຄວນຮູ້ວ່າທ່ານອາດຈະຕ້ອງເສຍຄ່າທຳ ນຽມໃນການຈ່າຍເງິນໃຫ້ແລະຈາກສະຖາບັນການທະນາຄານລະຫວ່າງປະເທດຈຳນວນໜຶ່ງບໍລິສັດ IUX Markets ບໍ່ຮັບຜິດຊອບຕໍ່ຄ່າທຳນຽມທະນາຄານໃດໆ`,
        title_3: `ສຳລັບການໂອນສາຍທະນາຄານສາກົນ, ຄ່າທຳນຽມປະມວນຜົນ 20 AUD ຫຼືທຽບເທົ່າເງິນຕາແມ່ນຖືກຄິດໄລ່ໂດຍສະຖາບັນການທະນາຄານຂອງພວກເຮົາ ພວກເຮົາຈະຫັກຄ່າທຳນຽມນີ້ຈາກຈຳນວນເງິນທີ່ທ່ານຖອນ. ເຖິງຢ່າງໃດກໍ່ຕາມ, ສິ່ງນີ້ອາດຈະແຕກຕ່າງກັນຂຶ້ນກັບອຳນາດການປົກຄອງຂອງທ່ານແລະທະນາຄານທີ່ທ່ານໃຊ້, ແລະມັນອາດຈະໃຊ້ເວລາເຖິງ 14 ວັນແລະຈະມີຄ່າທຳນຽມເພີ່ມເຕີມ`,
        title_4: `ການຖອນບັດເຄດິດ / ເດບິດແມ່ນຖືກປະມວນຜົນໂດຍບໍ່ເສຍຄ່າ. ເມື່ອຖືກ ດຳ ເນີນການແລ້ວ, ການຖອນບັດເຄດິດ / ບັດເດບິດອາດຈະໃຊ້ເວລາ 3-5 ວັນເຮັດວຽກເພື່ອເຂົ້າຫາບັດເຄດິດຂອງທ່ານ. ເຖິງຢ່າງໃດກໍ່ຕາມທ່ານຄວນຮັບຮູ້ວ່າໃນບາງໂອກາດທີ່ຫາຍາກ, ບາງຄັ້ງນີ້ອາດຈະໃຊ້ເວລາເຖິງ 10 ມື້ທຸລະກິດຂຶ້ນກັບທະນາຄານທີ່ສິ້ນສຸດ.\n* ກະລຸນາຮັບຊາບວ່າການຖອນບັດເຄຼດິດອາດຈະບໍ່ມີສຳລັບທຸກໆປະເທດ`,
        title_5: `ການຖອນເງິນແບບປອດໄພສາມາດປ່ຽນຄືນຈຳນວນເງິນທີ່ຝາກໄວ້ເທົ່ານັ້ນ ທ່ານສາມາດເລືອກວິທີການຝາກເງິນອື່ນທີ່ໃຊ້ຜ່ານມາຫຼືວິທີການໂອນເງິນຜ່ານທະນາຄານເພື່ອຖອນ`,
        title_6: `ເທຮດການຖອນເງິນ Paypal / Neteller / Skrill ຕ້ອງໄດ້ເຮັດຈາກບັນຊີດຽວກັນຈາກບ່ອນທີ່ເງິນໄດ້ຖືກສົ່ງໄປໃນຕອນ ທຳ ອິດ ການເຮັດທຸລະກຳເຫຼົ່ານີ້ແມ່ນດຳເນີນການໂດຍບໍ່ຕ້ອງເສຍເງິນແລະຖືກດຳເນີນການທັນທີເດ້`,
        title_7: `ຖ້າບັດເຄດິດ / ບັດເດບິດທີ່ທ່ານອັບໂຫລດໄດ້ ໝົດ ລົງແລ້ວ, ກະລຸນາອັບໂຫລດບັດ ໃໝ່ ໃນເຂດລູກຄ້າຂອງທ່ານເພື່ອສືບຕໍ່ ນຳ ໃຊ້ບໍລິການເງິນຝາກແລະຖອນເງິນໂດຍບໍ່ມີການຂັດຂວາງໃດໆ ຖ້າເລກບັດໃໝ່ ແຕກຕ່າງກັບເລກບັດທີ່ໝົດອາຍຸ, ທ່ານຈຳເປັນຕ້ອງສົ່ງຈົດໝາຍທີ່ອອກມາຈາກທະນາຄານອອກບັດເກົ່າຢືນຢັນວ່າບັດໃໝ່ໄດ້ຖືກອອກໃຫ້ແທນບັດໃໝ່`,
        title_8: `ຖ້າບັດເຄຼດິດ / ບັດເດບິດທີ່ທ່ານອັບໂຫຼດມາຖືກສູນເສຍ / ຖືກລັກ / ເສຍຫາຍ / ຖືກຍົກເລີກ, ທ່ານຈຳເປັນຕ້ອງສົ່ງໜັງສືທີ່ອອກຈາກທະນາຄານອອກບັດເກົ່າທີ່ຢືນຢັນວ່າບັດເກົ່າບໍ່ມີຜົນອີກແລ້ວ`,
        title_9: `ບໍລິສັດ IUX Markets, ຕາມການຕັດສິນໃຈຂອງຕົນເອງ, ອາດຈະຂໍໃຫ້ທ່ານສົ່ງເອກະສານສະໜັບສະໜູນ (ຕົວຢ່າງ, ໃບຮັບເງິນຝາກສຳລັບການຈ່າຍເງິນທີ່ດຳ ເນີນການຜ່ານໃບປະກາດເກົ່າຫຼືໃບຢັ້ງຢືນບັດທີ່ສະແດງທຸລະ ກຳ ເງິນຝາກ) ກ່ອນທີ່ຈະປ່ອຍເງິນໄປໃສ່ບັດ ໃໝ່`,
        title_10: `ຖ້າຢາກຖອນຈຳນວນທີ່ໃຫຍ່ກວ່າຈຳນວນເງິນຝາກໂດຍບັດເຄດິດ / ບັດເດບິດທີ່ປອດໄພ, ທ່ານຈຳເປັນຕ້ອງໃຊ້ວິທີການຝາກເງິນອື່ນທີ່ໄດ້ຖືກນຳໃຊ້ກ່ອນໜ້ານີ້ຫຼືທາງເລືອກການໂອນເງິນຜ່ານທະນາຄານ`,
        title_11: `ຖ້າທ່ານກຳລັງຖອນເງີນຂອງທ່ານທີ່ຝາກຜ່ານທາງທະນາຄານໄທທາງອິນເຕີເນັດເຂົ້າໃນບັນຊີທະນາຄານຂອງໄທ, ທ່ານຈະຕ້ອງໄດ້ອັບຮູບຫລືແຈ້ງສະແກນສີຂອງທະນາຄານ Book ຂອງທ່ານ. ເມື່ອຖືກດຳ ເນີນການແລ້ວ, ເງິນທຶນອາດຈະໃຊ້ເວລາ 1 ວັນເຮັດວຽກເພື່ອບັນຊີບັນຊີຂອງທ່ານ`,
        title_12: `IUX Markets ບໍ່ດຳເນີນການຈ່າຍເງິນໃຫ້ກັບບຸກຄົນທີສາມ. ກະລຸນາຮັບປະກັນວ່າການຮ້ອງຂໍການຖອນເງິນທັງໝົດຈາກບັນຊີການຄ້າຂອງທ່ານໄປທີ່ບັນຊີທະນາຄານຫລືແຫຼ່ງທີ່ຢູ່ໃນຊື່ຂອງທ່ານ ການຈ່າຍເງິນເຂົ້າບັນຊີທະນາຄານຮ່ວມກັນ / ບັດເຄດິດແມ່ນຖືກຍອມຮັບຖ້າຜູ້ຖືບັນຊີຊື້ຂາຍເປັນໜຶ່ງໃນບັນດາພາກສ່ວນທີ່ຢູ່ໃນບັນຊີທະນາຄານ / ບັດເຄດິດ`,
      },
    },
  },
  en: {
    account_create_demo: `Your demo account is a risk-free investment where you can practice trading, test strategies, and get comfortable with our platform.`,
    account_create_success_islamic: `This account is automatically set to Swap-Free status. Please save your trading password immediately. It cannot be emailed for security reasons.`,
    account_create_success: `No more overnight charges. Your Swap-Free status is determined by your trading activity. To maintain an active Swap-Free status, you need to trade mostly within the day.`,
    open_an_account: "Open an account",
    low_risk: "	Low risk	",
    high_risk: "	High risk	",
    medium_risk: "	Medium risk	",
    standard_account_description:
      "	Most popular! A great account for all types of traders.	",
    standard_bonus_account_description:
      "	Our Instant execution account. Excellent fill with no trading commission.	",
    raw_account_description:
      "	The lowest raw spreads and a low fixed commission.	",
    pro_account_description: "	Professional account with commission free 0%.	",
    button: {
      title: `Deposit now`,
    },
    cover: {
      accover: {
        title: `Account Overview`,
        description: `IUX Markets offers low spread instruments in all account types; suitable for all traders. Simply choose your preferred platform and get started. `,
      },
      accstd: {
        title: `Standard `,
        description: `With our proprietary bridge aggregator mixing chosen tier-1 price suppliers, you may gain the Low Spread advantage on one of the world's most popular trading platforms.`,
      },
      acccnt: {
        title: `Cent `,
        description: `A beginner account with less than 100 times investment units, suitable for all trading styles. You can use any trading strategy you prefer. With the same extremely low spread in the Standard account, you have more chances to make a profit.`,
      },
      accecn: {
        title: `ECN `,
        description: `Keep it simple with the IUX Markets Standard account. Experience all the benefits of our Low Spread Pricing with an all-inclusive spread with no commissions.`,
      },
      accraw: {
        title: `Raw `,
        description: `Keep it simple with the IUX Markets Raw account. Experience all the benefits of our Raw pricing with an all-inclusive spread.
`,
      },
      accpro: {
        title: `Pro `,
        description: `Suited to experienced and professional traders, for any trading style, with zero commission & low spread and no limit on orders that can be opened.`,
      },
      accbonus: {
        title: `Standard+ `,
        description: `Keep it simple with the IUX Markets Standard+ account. Experience all the benefits of our  Low Spread Pricing with an all-inclusive spread and no commissions.`,
      },
      deposit: {
        title: `Account Funding`,
        description: `IUX Markets offers over 15 flexible funding options in 10 different base CFDs. Deposit instantly and free using a range of methods.`,
      },
      withdraw: {
        title: `Fund Withdrawal`,
        description: `Keep it simple with the IUX Markets Standard account. Experience all the benefits of our  Low pricing  with an all-inclusive spread and no commissions.`,
      },
    },
    menu: {
      menu_1: "Account Overview",
    },
    section_1: {
      title: "WHY CHOOSE OUR STANDARD  ?",
      description:
        "We offer the lowest spread. Our average EUR/USD spread  is 0.2 PIPs with no commission. IUX Markets Standard Account is created for day traders, short term traders and expert traders with an overall pricing source of up to 25 institutional grade sources. IUX Markets MetaTrader5 platform is the preferred choice for traders all over the world.",
    },
    std: {
      box_1: {
        titile_1: `CFDs Trading `,
        titile_2: `Low Spread Pricing`,
        titile_3: `Spread from 0.2 PIPs`,
        titile_4: `Fast order execution`,
        titile_5: `1:3000 Leverage`,
        titile_6: `Deep liquidity`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `The Standard Account offers the lowest spread. Our average EUR/USD spread  is 0.2 PIPs with no commission  IUX Markets Standard Account is created for day traders, short term traders and expert traders with deep liquidity and fast execution. IUX Markets MetaTrader5 platform is the preferred choice for traders all over the world. `,
        titile_2: `Our MetaTrader5 servers are located in the Equinix LD4 Data Center in London, bring you closer to our bridge data center and help to eliminate unnecessary latency. MetaTrader5 is a great choice for day traders, short term traders and expert traders all over the world.`,
      },
      section_2: {
        titile_1: `Low Spread Pricing`,
        description_1: `IUX Markets is able to offer market pricing and the best trading conditions through the MT5 platforms by providing clients with Low Spread Pricing. The Low Spread Pricing environment allows you to trade on institutional grade pricing on almost the same level of the world’s leading execution venues. Executable Streaming Prices (ESP) are sent from IUX Markets ’ pricing providers to us and offered to our clients with no dealing desk, price manipulation or requotes. IUX Markets is the best CFDs provider for high volume traders, scalpers and robots who provide the tight spread and best possible execution.`,
      },
      section_3: {
        titile_1: `Fast order execution`,
        description_1: `IUX Markets MetaTrader5 server is located in the Equinix LD4 data center In London. The NY4 data center, referred to as a financial ecosystem, hosts over 600 buy and sell side firms, exchanges, trading venues, market data and service providers. The MetaTrader5 server is cross connected to our Communication Network to ensure low latency and the fast execution of your trades via IUX Markets trading environment. The IUX Markets MetaTrader5 and 5 trade servers have latency of less than 1 millisecond to major VPS providers either collocated in the NY4 data center or through dedicated lines to nearby data centres.This low latency environment is ideal for automated and high frequency trading and scalping.`,
        titile_2: `Spread from 0.2 PIPs`,
        description_2: `IUX Markets is some of the tightest spread of all CFDs brokers globally with spread starts at 0.2 PIPs on the MetaTrader5 platforms with the average on EURUSD being 0.1 PIPs 24/7. This is currently one of the tightest average EURUSD spread globally. Our pricing is connected with a mix of up to 25 different price providers. We are always able to source the best price for our clients and keep our spread tight, especially during high volatility times such as news announcements.`,
        titile_3: `No Restrictions on Trading – Scalping Allowed`,
        description_3: `IUX Markets  Meta Trader5 platforms have no restrictions on trading. allowing traders to place orders between the spread as there is no minimum order distance and a freeze level of 0. This means orders including stop loss orders can be placed as close to the market price as you like. \nTraders can also hedge positions as there is no first in first out (FIFO) rule with IUX Markets. Traders do not pay margin on hedged trades and enjoy the benefits of margin netting.`,
        titile_4: `Level II Pricing – Market Depth`,
        description_4: `Market depth shows the full range of executable prices coming directly from our prices providers. Market depth offers complete transparency of the liquidity of each currency pair by showing the available volumes for each price level at any given time. High liquidity, asynchronous spot prices, and low latency guarantee the tightest possible spread.`,
      },
      section_4: {
        titile_1: `Flexible Funding and Withdrawal Options`,
        description_1: `Once you’ve opened your account you can fund using any of our funding options including: credit/debit card, Skrill, PayPal, Neteller, Thai QR code, and broker to broker transfer, deposits.`,
        titile_2: `Flexible Lot Sizing`,
        description_2: `There are no limits or restrictions on trade sizes, you can place trades as small as one micro lot (0.01) . Our flexible lot sizing allows you to trial the platform with minimal risk and manages your trades sizes in accordance with your account balance.`,
      },
      section_5: {
        titile_1: `64 CFDs & METALS `,
        description_1: `Trade 64 CFDs pairs plus 15 major equity indices including the UK 100, S&P 500,AUS 200 and Dow Jones Index 24 hours a day with a spread of 1 point.`,
        titile_2: `Leverage up to 1:3000`,
        description_2: `Accounts go up to 1:3000 leverage on the IUX Markets  Meta Trader5 platforms. Traders can use higher leverage to suit their trading style and get the best out of their manual and automated trading strategies.`,
        titile_3: `All Major Account Currencies Supported`,
        description_3: `We know some traders prefer to deal in their local currency. We give traders the option to open an account in 1 of 10 supported base currencies: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.`,
      },
    },
    bonus: {
      box_1: {
        titile_1: `CFDs Trading `,
        titile_2: `Bonus Deposit $35`,
        titile_3: `Commission Free`,
        titile_4: `Fast order execution`,
        titile_5: `1:1000 Leverage`,
        titile_6: `Deep liquidity`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `Why Choose Standard+`,
        description_1: `The Standard+ Account from IUX Markets offers speed of execution with no rivals. It included an inclusive spread starting from 0.5 PIPs, Equinix LD4 data center In London,a group of up to 25 pricing providers. You will understand why our standard+ account can give you an unparalleled trading experience, all day every day.
`,
      },
      section_2: {
        titile_1: `Fast order execution`,
        description_1: `IUX Markets MetaTrader5 server is located in the Equinix LD4 data center In London. The NY4 data center, referred to as a financial ecosystem, hosts over 600 buy and sell side firms, exchanges, trading venues, market data and service providers. The MetaTrader5 server is cross connected to our Communication Network to ensure low latency and the fast execution of your trades via IUX Markets trading environment. The IUX Markets MetaTrader5 and 5 trade servers have latency of less than 1 millisecond to major VPS providers either collocated in the NY4 data center or through dedicated lines to nearby data centres.This low latency environment is ideal for automated and high frequency trading and scalping.`,
      },
      section_3: {
        titile_1: `No Restrictions on Trading – Scalping Allowed`,
        description_1: `IUX Markets  Meta Trader5 platforms have no restrictions on trading. allowing traders to place orders between the spread as there is no minimum order distance and a freeze level of 0. This means orders including stop loss orders can be placed as close to the market price as you like.

        \nTraders can also hedge positions as there is no first in first out (FIFO) rule with IUX Markets. Traders do not pay margin on hedged trades and enjoy the benefits of margin netting.`,
        titile_2: `Level II Pricing – Market Depth`,
        description_2: `Market depth shows the full range of executable prices coming directly from our prices providers. Market depth offers complete transparency of the liquidity of each currency pair by showing the available volumes for each price level at any given time. High liquidity, asynchronous spot prices, and low latency guarantee the tightest possible spread.`,
      },
      section_4: {
        titile_1: `64 CFDs & METALS `,
        description_1: `Trade 64 CFDs pairs plus 15 major equity indices including the UK 100, S&P 500,AUS 200 and Dow Jones Index 24 hours a day with a spread of 1 point.`,
        titile_2: `Leverage up to 1:1000`,
        description_2: `Accounts go up to 1:1000 leverage on the IUX Markets  Meta Trader5 platforms. Traders can use higher leverage to suit their trading style and get the best out of their manual and automated trading strategies.`,
        titile_3: `All Major Account Currencies Supported`,
        description_3: `We know some traders prefer to deal in their local currency. We give traders the option to open an account in 1 of 10 supported base currencies: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.`,
      },
    },
    accecn: {
      box_1: {
        titile_1: `CFDs Trading `,
        titile_2: `Trade over 90 instruments`,
        titile_3: `Commission $3.5`,
        titile_4: `Fast order execution`,
        titile_5: `1:3000 Leverage`,
        titile_6: `Spread as low as 0.0 PIPs`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `Why Choose Our ECN Account?`,
        description_1: `The ECN Account from IUX Markets offers speed of execution with no rivals. Combine this with an all inclusive spread starting from 0.0 PIPs, Equinix LD4 servers In London, a group of up to 25 pricing providers and you will understand why our standard account can give you an unparalleled trading experience, all day every day. `,
      },
      section_2: {
        titile_1: `Fast order execution`,
        description_1: `IUX Markets MetaTrader5 server is located in the Equinix LD4 data center In London. The NY4 data center, referred to as a financial ecosystem, hosts over 600 buy and sell side firms, exchanges, trading venues, market data and service providers. The MetaTrader5 server is cross connected to our Communication Network to ensure low latency and the fast execution of your trades via IUX Markets trading environment. The IUX Markets MetaTrader5 and 5 trade servers have latency of less than 1 millisecond to major VPS providers either collocated in the NY4 data center or through dedicated lines to nearby data centres.This low latency environment is ideal for automated and high frequency trading and scalping.`,
      },
      section_3: {
        titile_1: `No Restrictions on Trading – Scalping Allowed`,
        description_1: `IUX Markets  Meta Trader5 platforms have no restrictions on trading. allowing traders to place orders between the spread as there is no minimum order distance and a freeze level of 0, This means orders including stop loss orders can be placed as close to the market price as you like.

        \nTraders can also hedge positions as there is no first in first out (FIFO) rule with IUX Markets. Traders do not pay margin on hedged trades and enjoy the benefits of margin netting.`,
        titile_2: `Level II Pricing – Market Depth`,
        description_2: `Market depth shows the full range of executable prices coming directly from our prices providers. Market depth offers complete transparency of the liquidity of each currency pair by showing the available volumes for each price level at any given time. High liquidity, asynchronous spot prices, and low latency guarantee the tightest possible spread.`,
      },
      section_4: {
        titile_1: `64 CFDs & METALS `,
        description_1: `Trade 64 CFDs pairs plus 15 major equity indices including the UK 100, S&P 500,AUS 200 and Dow Jones Index 24 hours a day with a spread of 1 point.`,
        titile_2: `Leverage up to 1:3000`,
        description_2: `Accounts go up to 1:3000 leverage on the IUX Markets  Meta Trader5 platforms. Traders can use higher leverage to suit their trading style and get the best out of their manual and automated trading strategies.`,
        titile_3: `All Major Account Currencies Supported`,
        description_3: `We know some traders prefer to deal in their local currency. We give traders the option to open an account in 1 of 10 supported base currencies: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.`,
      },
    },
    accraw: {
      box_1: {
        titile_1: `CFDs Trading `,
        titile_2: `Trade over 90 instruments`,
        titile_3: `Commission $6`,
        titile_4: `Fast order execution`,
        titile_5: `1:3000 Leverage`,
        titile_6: `Spread as low as 0.0 PIPs`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `Why Choose Raw`,
        description_1: `The Raw Account from IUX Markets offers speed of execution with no rivals. It included an inclusive spread starting from 0.0 PIPs, Equinix LD4 data center In London,a group of up to 25 pricing providers. You will understand why our Raw account can give you an unparalleled trading experience, all day every day.
 `,
      },
      section_2: {
        titile_1: `Fast order execution`,
        description_1: `IUX Markets MetaTrader5 server is located in the Equinix LD4 data center In London. The NY4 data center, referred to as a financial ecosystem, hosts over 600 buy and sell side firms, exchanges, trading venues, market data and service providers. The MetaTrader5 server is cross connected to our Communication Network to ensure low latency and the fast execution of your trades via IUX Markets trading environment. The IUX Markets MetaTrader5 and 5 trade servers have latency of less than 1 millisecond to major VPS providers either collocated in the NY4 data center or through dedicated lines to nearby data centres.This low latency environment is ideal for automated and high frequency trading and scalping.`,
      },
      section_3: {
        titile_1: `No Restrictions on Trading – Scalping Allowed`,
        description_1: `IUX Markets  Meta Trader5 platforms have no restrictions on trading. allowing traders to place orders between the spread as there is no minimum order distance and a freeze level of 0, This means orders including stop loss orders can be placed as close to the market price as you like.

        \nTraders can also hedge positions as there is no first in first out (FIFO) rule with IUX Markets. Traders do not pay margin on hedged trades and enjoy the benefits of margin netting.`,
        titile_2: `Level II Pricing – Market Depth`,
        description_2: `Market depth shows the full range of executable prices coming directly from our prices providers. Market depth offers complete transparency of the liquidity of each currency pair by showing the available volumes for each price level at any given time. High liquidity, asynchronous spot prices, and low latency guarantee the tightest possible spread.`,
      },
      section_4: {
        titile_1: `64 CFDs & METALS `,
        description_1: `Trade 64 CFDs pairs plus 15 major equity indices including the UK 100, S&P 500,AUS 200 and Dow Jones Index 24 hours a day with a spread of 1 point.`,
        titile_2: `Leverage up to 1:3000`,
        description_2: `Accounts go up to 1:3000 leverage on the IUX Markets  Meta Trader5 platforms. Traders can use higher leverage to suit their trading style and get the best out of their manual and automated trading strategies.`,
        titile_3: `All Major Account Currencies Supported`,
        description_3: `We know some traders prefer to deal in their local currency. We give traders the option to open an account in 1 of 10 supported base currencies: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.`,
      },
    },
    accpro: {
      box_1: {
        titile_1: `CFDs Trading `,
        titile_2: `Trade over 90 instruments`,
        titile_3: `Commission $0`,
        titile_4: `Fast order execution`,
        titile_5: `1:3000 Leverage`,
        titile_6: `Spread as low as 0.1 PIPs`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `Why Choose Pro`,
        description_1: `If you are an experienced and professional trader, Pro account is a good fit for you.  The Pro account from IUX Markets come with raw spreads or even spread free starting from 0.0 pips. Also offers the speed of order executed with instant execution for a majority of the instruments. Our Pro accounts have execution to suit scalpers, day-traders and algotraders. `,
      },
      section_2: {
        titile_1: `Fast order execution`,
        description_1: `IUX Markets MetaTrader5 server is located in the Equinix LD4 data center In London. The NY4 data center, referred to as a financial ecosystem, hosts over 600 buy and sell side firms, exchanges, trading venues, market data and service providers. The MetaTrader5 server is cross connected to our Communication Network to ensure low latency and the fast execution of your trades via IUX Markets trading environment. The IUX Markets MetaTrader5 and 5 trade servers have latency of less than 1 millisecond to major VPS providers either collocated in the NY4 data center or through dedicated lines to nearby data centres.This low latency environment is ideal for automated and high frequency trading and scalping.`,
      },
      section_3: {
        titile_1: `No Restrictions on Trading – Scalping Allowed`,
        description_1: `IUX Markets  Meta Trader5 platforms have no restrictions on trading. allowing traders to place orders between the spread as there is no minimum order distance and a freeze level of 0, This means orders including stop loss orders can be placed as close to the market price as you like.

        \nTraders can also hedge positions as there is no first in first out (FIFO) rule with IUX Markets. Traders do not pay margin on hedged trades and enjoy the benefits of margin netting.`,
        titile_2: `Level II Pricing – Market Depth`,
        description_2: `Market depth shows the full range of executable prices coming directly from our prices providers. Market depth offers complete transparency of the liquidity of each currency pair by showing the available volumes for each price level at any given time. High liquidity, asynchronous spot prices, and low latency guarantee the tightest possible spread.`,
      },
      section_4: {
        titile_1: `64 CFDs & METALS `,
        description_1: `Trade 64 CFDs pairs plus 15 major equity indices including the UK 100, S&P 500,AUS 200 and Dow Jones Index 24 hours a day with a spread of 1 point.`,
        titile_2: `Leverage up to 1:3000`,
        description_2: `Accounts go up to 1:3000 leverage on the IUX Markets  Meta Trader5 platforms. Traders can use higher leverage to suit their trading style and get the best out of their manual and automated trading strategies.`,
        titile_3: `All Major Account Currencies Supported`,
        description_3: `We know some traders prefer to deal in their local currency. We give traders the option to open an account in 1 of 10 supported base currencies: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.`,
      },
    },
    funding: {
      section_1: {
        title: `How to Deposit/Withdrawal?`,
        titile_1: `Register and sign-in`,
        titile_2: `Select your preferred bank to proceed deposit and withdrawal`,
        titile_3: `Zero-fee for deposit and withdrawal`,
        titile_4: `Fast deposit and withdrawal in a few minutes`,
      },
      section_2: {
        title: `Deposit and withdrawal options`,
        description: `We keep your financial information secure with encryption. So you can pay online with confidence.`,
        option: `Funding Options`,
        hot: `Popular`,
        titile_1: `Credit and Debit`,
        titile_2: `Skrill`,
        titile_3: `Neteller`,
        titile_4: `Thai QR Payment`,
        titile_5: `Online Banking`,
      },
      section_3: {
        titile_1: `Payment Process`,
        description_1: `For faster processing, we recommend depositing funds from the Secure Client Area. The balance will be deposited in real time. If you have any problems with accessing the Secure Client Area, please follow the instructions. `,
        titile_2: `Security of Funds`,
        description_2: `Client funds are held in a Segregated Client Trust Account. Electronic payments are processed using SSL (Secure Socket Layer) technology and are encrypted to ensure security. All payment information is confidential and used only for the purpose of funding your trading account with IUX Markets.`,
        titile_3: `Bank Fees`,
        description_3: `IUX Markets provide zero charge for any additional fee for deposits and withdrawals. However, you should recognize that the charge may occur on international banking institutions payments. IUX Markets have no responsibility for any charges of banks or providers fees.`,
        titile_4: `Third Party Payments`,
        description_4: `IUX Markets do not allow payments from third parties. Please ensure that all deposits into your trading account from a bank account under your name. Payments from Joint Bank Account/ Credit Cards are allowed if the trading account holder is one of the parties on the Bank Account/ Credit Card. `,
      },
    },
    withdraw: {
      section_1: {
        title: `For faster processing all account holders are now required to submit withdrawal requests from inside their Secure Client Area.`,
        content: `If you have any problems with accessing the Secure Client Area, please follow the instructions. `,
      },
      section_2: {
        title: `If you do not have any experience in withdrawing funds, please follow the instructions:`,
        title_1: `Withdrawal requests cut off time is 12:00 AEST/AEDT. If you submit your withdrawal before this time, it will be processed on the day it is received. If you submit your withdrawal after this time, it will be processed the following business day.`,
        title_2: `IUX Markets provide zero charge for any additional fee for deposits and withdrawals. However, you should recognize that the charge may occur on international banking institutions payments. IUX Markets have no responsibility for any charges of banks or providers fees.`,
        title_3: `For International Bank Wire Transfers, a processing fee of currency equivalent is charged by our banking institution. This charge will be deducted from your withdrawal amount. This might take up to 14 days and entail additional intermediate costs depending on your jurisdiction and the bank you select.`,
        title_4: `Credit / Debit Card withdrawals are processed free of charge. Once processed, Credit / Debit Card withdrawals may take 3-5 business days to reach you credit card. However, you should recognize that in some rare occasions, this may sometimes take up to 10 business days depending on the banks end. *Please note Credit card withdrawals may not be available for all countries.`,
        title_5: `Paypal / Neteller / Skrill withdrawals must be made from the same account from where the funds were sent initially. These transactions are processed free of charge and are instant once processed.`,
        title_6: `If your credit / debit card has already expired, please upload the new card in your client area to continue using the deposit and withdrawals services without any interruptions. If the new card’s number is different from the expired card’s number, you would need to submit a letter issued from the old card’s issuer bank confirming that the new card has been issued in replacement of the old card.`,
        title_7: `If your credit /debit card has been lost , stolen , damaged or cancelled, you would need to submit a letter issued from the old card’s issuer bank confirming that the old card is no longer valid`,
        title_8: `IUX Markets , at its own discretion, may ask you to submit supporting documentation (for example, deposit receipts for the payments processed via old card or card statement showing deposit transactions) before releasing funds to the new card.`,
        title_9: `To withdraw an amount more than the amount placed via Verified by Visa / MasterCard Secure Credit / Debit Card, you must use another deposit method previously used or a bank wire transfer option. `,
        title_10: `If you are withdrawing your funds deposited via Thai Internet Banking option to a Thai bank account, you will require to upload a clear picture or a coloured scan copy of your Book bank. Once processed, the funds may take one business day to reach your account.`,
        title_11: `IUX Markets  does not process payments to third parties. please ensure that all withdrawal requests from your trading account go to a bank account or a source in your name. Payments to Joint Bank Accounts / Credit Cards are accepted if the trading account holder is one on the parties on the Bank Account / Credit Card.`,
        title_12: `IUX Markets  does not process payments to third parties. please ensure that all withdrawal requests from your trading account go to a bank account or a source in your name. Payments to Joint Bank Accounts / Credit Cards are accepted if the trading account holder is one on the parties on the Bank Account / Credit Card.`,
      },
    },
  },
  hi: {
    account_create_demo: `आपका डेमो खाता एक जोखिम-मुक्त निवेश है जहां आप ट्रेडिंग, परीक्षण रणनीतियों का अभ्यास कर सकते हैं, और हमारे मंच के साथ सहज हो सकते हैं।`,
    account_create_success_islamic: `इस खाता को स्वैप-मुक्त स्थिति में स्वचालित रूप से सेट किया गया है। कृपया तुरंत अपना व्यापारिक पासवर्ड सहेजें। सुरक्षा कारणों के लिए इसे ईमेल के माध्यम से नहीं भेजा जा सकता है।`,
    account_create_success: `रातोंरात कोई और शुल्क नहीं। आपकी स्वैप-मुक्त स्थिति आपकी ट्रेडिंग गतिविधि द्वारा निर्धारित की जाती है। एक सक्रिय स्वैप-मुक्त स्थिति बनाए रखने के लिए, आपको ज्यादातर दिन के भीतर व्यापार करने की आवश्यकता है।`,
    open_an_account: "खाता खोलें",
    low_risk: "	कम जोखिम	",
    high_risk: "	भारी जोखिम	",
    medium_risk: "	मध्यम जोखिम	",
    standard_account_description: `सबसे लोकप्रिय! सभी प्रकार के व्यापारियों के लिए एक बेहतरीन खाता।`,
    standard_bonus_account_description: `हमारा त्वरित निष्पादन खाता। बिना किसी ट्रेडिंग कमीशन के उत्कृष्ट भरण।`,
    raw_account_description: `सबसे कम कच्चा स्प्रेड और कम निश्चित कमीशन।`,
    pro_account_description: `0% कमीशन मुक्त व्यावसायिक खाता।`,
    button: {
      title: `अभी जमा करें`,
    },
    cover: {
      accover: {
        title: `खाता अवलोकन`,
        description: `IUX मार्केट्स सभी खाता प्रकारों में कम प्रसार वाले उपकरण प्रदान करता है; सभी व्यापारियों के लिए उपयुक्त. बस अपना पसंदीदा प्लेटफ़ॉर्म चुनें और आरंभ करें।`,
      },
      accstd: {
        title: `मानक`,
        description: `हमारे मालिकाना ब्रिज एग्रीगेटर द्वारा चयनित टियर-1 मूल्य आपूर्तिकर्ताओं को मिलाकर, आप दुनिया के सबसे लोकप्रिय ट्रेडिंग प्लेटफॉर्म में से एक पर लो स्प्रेड लाभ प्राप्त कर सकते हैं।`,
      },
      acccnt: {
        title: `प्रतिशत`,
        description: `100 गुना से कम निवेश इकाइयों वाला एक शुरुआती खाता, सभी ट्रेडिंग शैलियों के लिए उपयुक्त। आप अपनी पसंद की किसी भी ट्रेडिंग रणनीति का उपयोग कर सकते हैं। मानक खाते में समान बेहद कम प्रसार के साथ, आपके पास लाभ कमाने की अधिक संभावना है।`,
      },
      accecn: {
        title: `ईसीएन`,
        description: `IUX मार्केट्स स्टैंडर्ड खाते के साथ इसे सरल रखें। बिना किसी कमीशन के सर्व-समावेशी स्प्रेड के साथ हमारे कम स्प्रेड मूल्य निर्धारण के सभी लाभों का अनुभव करें।`,
      },
      accraw: {
        title: `कच्चा`,
        description: `IUX मार्केट्स रॉ खाते के साथ इसे सरल रखें। सर्व-समावेशी प्रसार के साथ हमारे रॉ मूल्य निर्धारण के सभी लाभों का अनुभव करें।`,
      },
      accpro: {
        title: `समर्थक`,
        description: `किसी भी ट्रेडिंग शैली के लिए अनुभवी और पेशेवर व्यापारियों के लिए उपयुक्त, शून्य कमीशन और कम प्रसार और खोले जा सकने वाले ऑर्डर की कोई सीमा नहीं।`,
      },
      accbonus: {
        title: `मानक+`,
        description: `IUX मार्केट्स स्टैंडर्ड+ खाते के साथ इसे सरल रखें। सर्व-समावेशी स्प्रेड और बिना किसी कमीशन के हमारे कम स्प्रेड मूल्य निर्धारण के सभी लाभों का अनुभव करें।`,
      },
      deposit: {
        title: `खाता निधि`,
        description: `IUX मार्केट्स 10 अलग-अलग आधार सीएफडी में 15 से अधिक लचीले फंडिंग विकल्प प्रदान करता है। विभिन्न तरीकों का उपयोग करके तुरंत और निःशुल्क जमा करें।`,
      },
      withdraw: {
        title: `निधि निकासी`,
        description: `IUX मार्केट्स स्टैंडर्ड खाते के साथ इसे सरल रखें। सर्व-समावेशी प्रसार और बिना किसी कमीशन के हमारे कम मूल्य निर्धारण के सभी लाभों का अनुभव करें।`,
      },
    },
    menu: {
      menu_1: `खाता अवलोकन`,
    },
    section_1: {
      title: `हमारा मानक खाता क्यों चुनें?`,
      description: `हम सबसे कम प्रसार की पेशकश करते हैं। हमारा औसत EUR/USD स्प्रेड बिना किसी कमीशन के 0.2 PIP है। IUX मार्केट्स स्टैंडर्ड अकाउंट 25 संस्थागत ग्रेड स्रोतों के समग्र मूल्य निर्धारण स्रोत के साथ दिन के व्यापारियों, अल्पकालिक व्यापारियों और विशेषज्ञ व्यापारियों के लिए बनाया गया है। IUX मार्केट्स मेटाट्रेडर5 प्लेटफॉर्म दुनिया भर के व्यापारियों के लिए पसंदीदा विकल्प है।`,
    },
    std: {
      box_1: {
        titile_1: `सीएफडी ट्रेडिंग`,
        titile_2: `कम स्प्रेड मूल्य निर्धारण`,
        titile_3: `0.2 पीआईपी से स्प्रेड`,
        titile_4: `त्वरित आदेश निष्पादन`,
        titile_5: `1:3000 उत्तोलन`,
        titile_6: `गहरी तरलता`,
        titile_7: `मेटाट्रेडर 5`,
      },
      section_1: {
        titile_1: `मानक खाता सबसे कम प्रसार प्रदान करता है। हमारा औसत EUR/USD स्प्रेड 0.2 PIP है जिसमें कोई कमीशन नहीं है IUX मार्केट्स स्टैंडर्ड अकाउंट डे ट्रेडर्स, अल्पावधि ट्रेडर्स और गहरी तरलता और तेज़ निष्पादन वाले विशेषज्ञ ट्रेडर्स के लिए बनाया गया है। IUX मार्केट्स मेटाट्रेडर5 प्लेटफॉर्म दुनिया भर के व्यापारियों के लिए पसंदीदा विकल्प है।`,
        titile_2: `हमारे मेटाट्रेडर5 सर्वर लंदन में इक्विनिक्स एलडी4 डेटा सेंटर में स्थित हैं, जो आपको हमारे ब्रिज डेटा सेंटर के करीब लाते हैं और अनावश्यक विलंबता को खत्म करने में मदद करते हैं। मेटाट्रेडर5 दुनिया भर के दैनिक व्यापारियों, अल्पकालिक व्यापारियों और विशेषज्ञ व्यापारियों के लिए एक बढ़िया विकल्प है।`,
      },
      section_2: {
        titile_1: `कम स्प्रेड मूल्य निर्धारण`,
        description_1: `IUX मार्केट्स ग्राहकों को कम स्प्रेड प्राइसिंग प्रदान करके MT5 प्लेटफॉर्म के माध्यम से बाजार मूल्य निर्धारण और सर्वोत्तम व्यापारिक स्थिति प्रदान करने में सक्षम है। कम स्प्रेड मूल्य निर्धारण वातावरण आपको दुनिया के अग्रणी निष्पादन स्थानों के लगभग समान स्तर पर संस्थागत ग्रेड मूल्य निर्धारण पर व्यापार करने की अनुमति देता है। निष्पादन योग्य स्ट्रीमिंग मूल्य (ईएसपी) आईयूएक्स मार्केट्स के मूल्य निर्धारण प्रदाताओं से हमें भेजे जाते हैं और हमारे ग्राहकों को बिना किसी डीलिंग डेस्क, मूल्य हेरफेर या रीकोट के पेश किए जाते हैं। आईयूएक्स मार्केट्स उच्च मात्रा वाले व्यापारियों, स्केलपर्स और रोबोटों के लिए सबसे अच्छा सीएफडी प्रदाता है जो सख्त प्रसार और सर्वोत्तम संभव निष्पादन प्रदान करता है।`,
      },
      section_3: {
        titile_1: `त्वरित आदेश निष्पादन`,
        description_1: `IUX मार्केट्स मेटाट्रेडर5 सर्वर लंदन में इक्विनिक्स LD4 डेटा सेंटर में स्थित है। NY4 डेटा सेंटर, जिसे वित्तीय पारिस्थितिकी तंत्र के रूप में जाना जाता है, 600 से अधिक खरीद और बिक्री पक्ष फर्मों, एक्सचेंजों, व्यापारिक स्थानों, बाजार डेटा और सेवा प्रदाताओं की मेजबानी करता है। IUX मार्केट्स ट्रेडिंग वातावरण के माध्यम से कम विलंबता और आपके ट्रेडों के तेज़ निष्पादन को सुनिश्चित करने के लिए मेटाट्रेडर5 सर्वर हमारे संचार नेटवर्क से क्रॉस कनेक्टेड है। IUX मार्केट्स मेटाट्रेडर5 और 5 ट्रेड सर्वरों में प्रमुख VPS प्रदाताओं के लिए 1 मिलीसेकंड से कम की विलंबता है, जो या तो NY4 डेटा सेंटर में या पास के डेटा केंद्रों के लिए समर्पित लाइनों के माध्यम से स्थित हैं। यह कम विलंबता वातावरण स्वचालित और उच्च आवृत्ति व्यापार और स्केलिंग के लिए आदर्श है।`,
        titile_2: `0.2 पीआईपी से स्प्रेड`,
        description_2: `आईयूएक्स मार्केट्स वैश्विक स्तर पर सभी सीएफडी ब्रोकरों में से सबसे सख्त प्रसार में से एक है, जिसका प्रसार मेटाट्रेडर5 प्लेटफॉर्म पर 0.2 पीआईपी से शुरू होता है और EURUSD पर औसत 0.1 पीआईपी 24/7 है। यह वर्तमान में विश्व स्तर पर सबसे कम औसत EURUSD प्रसार में से एक है। हमारा मूल्य निर्धारण 25 विभिन्न मूल्य प्रदाताओं के मिश्रण से जुड़ा हुआ है। हम हमेशा अपने ग्राहकों के लिए सर्वोत्तम मूल्य प्राप्त करने में सक्षम होते हैं और अपने प्रसार को सीमित रखते हैं, विशेष रूप से समाचार घोषणाओं जैसे उच्च अस्थिरता के समय के दौरान।`,
        titile_3: `ट्रेडिंग पर कोई प्रतिबंध नहीं - स्कैल्पिंग की अनुमति`,
        description_3: `IUX मार्केट्स मेटा ट्रेडर5 प्लेटफॉर्म पर ट्रेडिंग पर कोई प्रतिबंध नहीं है। व्यापारियों को स्प्रेड के बीच ऑर्डर देने की अनुमति मिलती है क्योंकि इसमें कोई न्यूनतम ऑर्डर दूरी नहीं होती है और 0 का फ़्रीज़ स्तर होता है। इसका मतलब है कि स्टॉप लॉस ऑर्डर सहित ऑर्डर को आपकी इच्छानुसार बाज़ार मूल्य के करीब रखा जा सकता है। \nव्यापारी पोजीशन को हेज भी कर सकते हैं क्योंकि IUX मार्केट्स में पहले आओ पहले बाहर करो (फीफो) नियम नहीं है। व्यापारी हेज्ड ट्रेडों पर मार्जिन का भुगतान नहीं करते हैं और मार्जिन नेटिंग के लाभों का आनंद लेते हैं।`,
        titile_4: `लेवल II मूल्य निर्धारण - बाज़ार की गहराई`,
        description_4: `बाज़ार की गहराई हमारे मूल्य प्रदाताओं से सीधे आने वाली निष्पादन योग्य कीमतों की पूरी श्रृंखला दिखाती है। बाज़ार की गहराई किसी भी समय प्रत्येक मूल्य स्तर के लिए उपलब्ध मात्रा दिखाकर प्रत्येक मुद्रा जोड़ी की तरलता की पूर्ण पारदर्शिता प्रदान करती है। उच्च तरलता, अतुल्यकालिक स्पॉट कीमतें और कम विलंबता सबसे सख्त संभव प्रसार की गारंटी देती हैं।`,
      },
      section_4: {
        titile_1: `लचीले फंडिंग और निकासी विकल्प`,
        description_1: `एक बार जब आप अपना खाता खोल लेते हैं तो आप हमारे किसी भी फंडिंग विकल्प का उपयोग करके फंडिंग कर सकते हैं: क्रेडिट/डेबिट कार्ड, स्क्रिल, पेपाल, नेटेलर, थाई क्यूआर कोड और ब्रोकर टू ब्रोकर ट्रांसफर, डिपॉजिट।`,
        titile_2: `लचीली लॉट साइजिंग`,
        description_2: `व्यापार के आकार पर कोई सीमा या प्रतिबंध नहीं है, आप एक माइक्रो लॉट (0.01) जितना छोटा व्यापार कर सकते हैं। हमारा लचीला लॉट साइज़ आपको न्यूनतम जोखिम के साथ प्लेटफ़ॉर्म का परीक्षण करने की अनुमति देता है और आपके खाते की शेष राशि के अनुसार आपके ट्रेड आकार का प्रबंधन करता है।`,
      },
      section_5: {
        titile_1: `64 सीएफडी और धातु`,
        description_1: `1 अंक के अंतर के साथ 64 सीएफडी जोड़े और यूके 100, एसएंडपी 500, एयूएस 200 और डॉव जोन्स इंडेक्स सहित 15 प्रमुख इक्विटी सूचकांकों का प्रतिदिन 24 घंटे व्यापार करें।`,
        titile_2: `1:3000 तक का लाभ उठाएं`,
        description_2: `IUX मार्केट्स मेटा ट्रेडर5 प्लेटफॉर्म पर खाते 1:3000 लीवरेज तक जाते हैं। व्यापारी अपनी ट्रेडिंग शैली के अनुरूप उच्च उत्तोलन का उपयोग कर सकते हैं और अपनी मैन्युअल और स्वचालित ट्रेडिंग रणनीतियों से सर्वोत्तम लाभ प्राप्त कर सकते हैं।`,
        titile_3: `सभी प्रमुख खाता मुद्राएँ समर्थित`,
        description_3: `हम जानते हैं कि कुछ व्यापारी अपनी स्थानीय मुद्रा में सौदा करना पसंद करते हैं। हम व्यापारियों को 10 समर्थित आधार मुद्राओं में से 1 में खाता खोलने का विकल्प देते हैं: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD।`,
      },
    },
    bonus: {
      box_1: {
        titile_1: `पेरडागंगन सीएफडी`,
        titile_2: `जमा बोनस $35`,
        titile_3: `सुरुहंजया पेरकुमा`,
        titile_4: `पेलकसानन पेसानन सीपत`,
        titile_5: `1:1000 उत्तोलन`,
        titile_6: `चिंता मत करो`,
        titile_7: `मेटाट्रेडर 5`,
      },
      section_1: {
        titile_1: `मानक+ क्यों चुनें?`,
        description_1: `IUX मार्केट्स का स्टैंडर्ड+ खाता बिना किसी प्रतिद्वंद्वी के निष्पादन की गति प्रदान करता है। इसमें 0.5 पीआईपी से शुरू होने वाला समावेशी प्रसार, लंदन में इक्विनिक्स एलडी4 डेटा सेंटर, 25 मूल्य निर्धारण प्रदाताओं का एक समूह शामिल था। आप समझ जाएंगे कि क्यों हमारा मानक+ खाता आपको पूरे दिन, हर दिन एक अद्वितीय ट्रेडिंग अनुभव दे सकता है।`,
      },
      section_2: {
        titile_1: `त्वरित आदेश निष्पादन`,
        description_1: `IUX मार्केट्स मेटाट्रेडर5 सर्वर लंदन में इक्विनिक्स LD4 डेटा सेंटर में स्थित है। NY4 डेटा सेंटर, जिसे वित्तीय पारिस्थितिकी तंत्र के रूप में जाना जाता है, 600 से अधिक खरीद और बिक्री पक्ष फर्मों, एक्सचेंजों, व्यापारिक स्थानों, बाजार डेटा और सेवा प्रदाताओं की मेजबानी करता है। IUX मार्केट्स ट्रेडिंग वातावरण के माध्यम से कम विलंबता और आपके ट्रेडों के तेज़ निष्पादन को सुनिश्चित करने के लिए मेटाट्रेडर5 सर्वर हमारे संचार नेटवर्क से क्रॉस कनेक्टेड है। IUX मार्केट्स मेटाट्रेडर5 और 5 ट्रेड सर्वरों में प्रमुख VPS प्रदाताओं के लिए 1 मिलीसेकंड से कम की विलंबता है, जो या तो NY4 डेटा सेंटर में या पास के डेटा केंद्रों के लिए समर्पित लाइनों के माध्यम से स्थित हैं। यह कम विलंबता वातावरण स्वचालित और उच्च आवृत्ति व्यापार और स्केलिंग के लिए आदर्श है।`,
      },
      section_3: {
        titile_1: `ट्रेडिंग पर कोई प्रतिबंध नहीं - स्कैल्पिंग की `,
        description_1: `IUX MaIUX मार्केट्स मेटा ट्रेडर5 प्लेटफॉर्म पर ट्रेडिंग पर कोई प्रतिबंध नहीं है। व्यापारियों को स्प्रेड के बीच ऑर्डर देने की अनुमति मिलती है क्योंकि इसमें कोई न्यूनतम ऑर्डर दूरी नहीं होती है और 0 का फ़्रीज़ स्तर होता है। इसका मतलब है कि स्टॉप लॉस ऑर्डर सहित ऑर्डर को आपकी इच्छानुसार बाज़ार मूल्य के करीब रखा जा सकता है। 

         \nव्यापारी पोजीशन को हेज भी कर सकते हैं क्योंकि IUX मार्केट्स में पहले आओ पहले बाहर करो (फीफो) नियम नहीं है। व्यापारी हेज्ड ट्रेडों पर मार्जिन का भुगतान नहीं करते हैं और मार्जिन नेटिंग के लाभों का आनंद लेते हैं।`,
        titile_2: `लेवल II मूल्य निर्धारण - बाज़ार की गहराई`,
        description_2: `बाज़ार की गहराई हमारे मूल्य प्रदाताओं से सीधे आने वाली निष्पादन योग्य कीमतों की पूरी श्रृंखला दिखाती है। बाज़ार की गहराई किसी भी समय प्रत्येक मूल्य स्तर के लिए उपलब्ध मात्रा दिखाकर प्रत्येक मुद्रा जोड़ी की तरलता की पूर्ण पारदर्शिता प्रदान करती है। उच्च तरलता, अतुल्यकालिक स्पॉट कीमतें और कम विलंबता सबसे सख्त संभव प्रसार की गारंटी देती हैं।`,
      },
      section_4: {
        titile_1: `64 सीएफडी और धातु`,
        description_1: `1 अंक के अंतर के साथ 64 सीएफडी जोड़े और यूके 100, एसएंडपी 500, एयूएस 200 और डॉव जोन्स इंडेक्स सहित 15 प्रमुख इक्विटी सूचकांकों का प्रतिदिन 24 घंटे व्यापार करें।`,
        titile_2: `1:1000 तक का लाभ उठाएं`,
        description_2: `IUX मार्केट्स मेटा ट्रेडर5 प्लेटफॉर्म पर खाते 1:1000 लीवरेज तक जाते हैं। व्यापारी अपनी ट्रेडिंग शैली के अनुरूप उच्च उत्तोलन का उपयोग कर सकते हैं और अपनी मैन्युअल और स्वचालित ट्रेडिंग रणनीतियों से सर्वोत्तम लाभ प्राप्त कर सकते हैं।`,
        titile_3: `सभी प्रमुख खाता मुद्राएँ समर्थित`,
        description_3: `हम जानते हैं कि कुछ व्यापारी अपनी स्थानीय मुद्रा में सौदा करना पसंद करते हैं। हम व्यापारियों को 10 समर्थित आधार मुद्राओं में से 1 में खाता खोलने का विकल्प देते हैं: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD।`,
      },
    },
    accecn: {
      box_1: {
        titile_1: `सीएफडी ट्रेडिंग`,
        titile_2: `90 से अधिक उपकरणों का व्यापार करें`,
        titile_3: `कमीशन $3.5`,
        titile_4: `त्वरित आदेश निष्पादन`,
        titile_5: `1:3000 उत्तोलन`,
        titile_6: `0.0 पीआईपी जितना कम स्प्रेड`,
        titile_7: `मेटाट्रेडर 5`,
      },
      section_1: {
        titile_1: `हमारा ECN खाता क्यों चुनें?`,
        description_1: `IUX मार्केट्स का ECN खाता बिना किसी प्रतिद्वंद्वी के निष्पादन की गति प्रदान करता है। इसे 0.0 पीआईपी, लंदन में इक्विनिक्स एलडी4 सर्वर, 25 मूल्य निर्धारण प्रदाताओं के समूह से शुरू होने वाले सर्व समावेशी प्रसार के साथ मिलाएं और आप समझ जाएंगे कि क्यों हमारा मानक खाता आपको पूरे दिन, हर दिन एक अद्वितीय ट्रेडिंग अनुभव दे सकता है।`,
      },
      section_2: {
        titile_1: `त्वरित आदेश निष्पादन`,
        description_1: `IUX मार्केट्स मेटाट्रेडर5 सर्वर लंदन में इक्विनिक्स LD4 डेटा सेंटर में स्थित है। NY4 डेटा सेंटर, जिसे वित्तीय पारिस्थितिकी तंत्र के रूप में जाना जाता है, 600 से अधिक खरीद और बिक्री पक्ष फर्मों, एक्सचेंजों, व्यापारिक स्थानों, बाजार डेटा और सेवा प्रदाताओं की मेजबानी करता है। IUX मार्केट्स ट्रेडिंग वातावरण के माध्यम से कम विलंबता और आपके ट्रेडों के तेज़ निष्पादन को सुनिश्चित करने के लिए मेटाट्रेडर5 सर्वर हमारे संचार नेटवर्क से क्रॉस कनेक्टेड है। IUX मार्केट्स मेटाट्रेडर5 और 5 ट्रेड सर्वरों में प्रमुख VPS प्रदाताओं के लिए 1 मिलीसेकंड से कम की विलंबता है, जो या तो NY4 डेटा सेंटर में या पास के डेटा केंद्रों के लिए समर्पित लाइनों के माध्यम से स्थित हैं। यह कम विलंबता वातावरण स्वचालित और उच्च आवृत्ति व्यापार और स्केलिंग के लिए आदर्श है।`,
      },
      section_3: {
        titile_1: `ट्रेडिंग पर कोई प्रतिबंध नहीं - स्कैल्पिंग की `,
        description_1: `IUX MaIUX मार्केट्स मेटा ट्रेडर5 प्लेटफॉर्म पर ट्रेडिंग पर कोई प्रतिबंध नहीं है। व्यापारियों को स्प्रेड के बीच ऑर्डर देने की अनुमति मिलती है क्योंकि कोई न्यूनतम ऑर्डर दूरी नहीं होती है और 0 का फ़्रीज़ स्तर होता है, इसका मतलब है कि स्टॉप लॉस ऑर्डर सहित ऑर्डर को आपकी इच्छानुसार बाज़ार मूल्य के करीब रखा जा सकता है। 

         \nव्यापारी पोजीशन को हेज भी कर सकते हैं क्योंकि IUX मार्केट्स में पहले आओ पहले बाहर करो (फीफो) नियम नहीं है। व्यापारी हेज्ड ट्रेडों पर मार्जिन का भुगतान नहीं करते हैं और मार्जिन नेटिंग के लाभों का आनंद लेते हैं।`,
        titile_2: `लेवल II मूल्य निर्धारण - बाज़ार की गहराई`,
        description_2: `बाज़ार की गहराई हमारे मूल्य प्रदाताओं से सीधे आने वाली निष्पादन योग्य कीमतों की पूरी श्रृंखला दिखाती है। बाज़ार की गहराई किसी भी समय प्रत्येक मूल्य स्तर के लिए उपलब्ध मात्रा दिखाकर प्रत्येक मुद्रा जोड़ी की तरलता की पूर्ण पारदर्शिता प्रदान करती है। उच्च तरलता, अतुल्यकालिक स्पॉट कीमतें और कम विलंबता सबसे सख्त संभव प्रसार की गारंटी देती हैं।`,
      },
      section_4: {
        titile_1: `64 सीएफडी और धातु`,
        description_1: `1 अंक के अंतर के साथ 64 सीएफडी जोड़े और यूके 100, एसएंडपी 500, एयूएस 200 और डॉव जोन्स इंडेक्स सहित 15 प्रमुख इक्विटी सूचकांकों का प्रतिदिन 24 घंटे व्यापार करें।`,
        titile_2: `1:3000 तक का लाभ उठाएं`,
        description_2: `IUX मार्केट्स मेटा ट्रेडर5 प्लेटफॉर्म पर खाते 1:3000 लीवरेज तक जाते हैं। व्यापारी अपनी ट्रेडिंग शैली के अनुरूप उच्च उत्तोलन का उपयोग कर सकते हैं और अपनी मैन्युअल और स्वचालित ट्रेडिंग रणनीतियों से सर्वोत्तम लाभ प्राप्त कर सकते हैं।`,
        titile_3: `सभी प्रमुख खाता मुद्राएँ समर्थित`,
        description_3: `हम जानते हैं कि कुछ व्यापारी अपनी स्थानीय मुद्रा में सौदा करना पसंद करते हैं। हम व्यापारियों को 10 समर्थित आधार मुद्राओं में से 1 में खाता खोलने का विकल्प देते हैं: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD।`,
      },
    },
    accraw: {
      box_1: {
        titile_1: `सीएफडी ट्रेडिंग`,
        titile_2: `90 से अधिक उपकरणों का व्यापार करें`,
        titile_3: `कमीशन $6`,
        titile_4: `त्वरित आदेश निष्पादन`,
        titile_5: `1:3000 उत्तोलन`,
        titile_6: `0.0 पीआईपी जितना कम स्प्रेड`,
        titile_7: `मेटाट्रेडर 5`,
      },
      section_1: {
        titile_1: `कच्चा क्यों चुन`,
        description_1: `The RaIUX मार्केट्स का रॉ अकाउंट बिना किसी प्रतिद्वंद्वी के निष्पादन की गति प्रदान करता है। इसमें 0.0 पीआईपी से शुरू होने वाला समावेशी प्रसार, लंदन में इक्विनिक्स एलडी4 डेटा सेंटर, 25 मूल्य निर्धारण प्रदाताओं का एक समूह शामिल था। आप समझ जाएंगे कि क्यों हमारा रॉ अकाउंट आपको पूरे दिन, हर दिन एक अद्वितीय ट्रेडिंग अनुभव दे सकता है।`,
      },
      section_2: {
        titile_1: `त्वरित आदेश निष्पादन`,
        description_1: `IUX मार्केट्स मेटाट्रेडर5 सर्वर लंदन में इक्विनिक्स LD4 डेटा सेंटर में स्थित है। NY4 डेटा सेंटर, जिसे वित्तीय पारिस्थितिकी तंत्र के रूप में जाना जाता है, 600 से अधिक खरीद और बिक्री पक्ष फर्मों, एक्सचेंजों, व्यापारिक स्थानों, बाजार डेटा और सेवा प्रदाताओं की मेजबानी करता है। IUX मार्केट्स ट्रेडिंग वातावरण के माध्यम से कम विलंबता और आपके ट्रेडों के तेज़ निष्पादन को सुनिश्चित करने के लिए मेटाट्रेडर5 सर्वर हमारे संचार नेटवर्क से क्रॉस कनेक्टेड है। IUX मार्केट्स मेटाट्रेडर5 और 5 ट्रेड सर्वरों में प्रमुख VPS प्रदाताओं के लिए 1 मिलीसेकंड से कम की विलंबता है, जो या तो NY4 डेटा सेंटर में या पास के डेटा केंद्रों के लिए समर्पित लाइनों के माध्यम से स्थित हैं। यह कम विलंबता वातावरण स्वचालित और उच्च आवृत्ति व्यापार और स्केलिंग के लिए आदर्श है।`,
      },
      section_3: {
        titile_1: `ट्रेडिंग पर कोई प्रतिबंध नहीं - स्कैल्पिंग की`,
        description_1: `IUX MaIUX मार्केट्स मेटा ट्रेडर5 प्लेटफॉर्म पर ट्रेडिंग पर कोई प्रतिबंध नहीं है। व्यापारियों को स्प्रेड के बीच ऑर्डर देने की अनुमति मिलती है क्योंकि कोई न्यूनतम ऑर्डर दूरी नहीं होती है और 0 का फ़्रीज़ स्तर होता है, इसका मतलब है कि स्टॉप लॉस ऑर्डर सहित ऑर्डर को आपकी इच्छानुसार बाज़ार मूल्य के करीब रखा जा सकता है। 
         \nव्यापारी पोजीशन को हेज भी कर सकते हैं क्योंकि IUX मार्केट्स में पहले आओ पहले बाहर करो (फीफो) नियम नहीं है। व्यापारी हेज्ड ट्रेडों पर मार्जिन का भुगतान नहीं करते हैं और मार्जिन नेटिंग के लाभों का आनंद लेते हैं।`,
        titile_2: `लेवल II मूल्य निर्धारण - बाज़ार की गहराई`,
        description_2: `बाज़ार की गहराई हमारे मूल्य प्रदाताओं से सीधे आने वाली निष्पादन योग्य कीमतों की पूरी श्रृंखला दिखाती है। बाज़ार की गहराई किसी भी समय प्रत्येक मूल्य स्तर के लिए उपलब्ध मात्रा दिखाकर प्रत्येक मुद्रा जोड़ी की तरलता की पूर्ण पारदर्शिता प्रदान करती है। उच्च तरलता, अतुल्यकालिक स्पॉट कीमतें और कम विलंबता सबसे सख्त संभव प्रसार की गारंटी देती हैं।`,
      },
      section_4: {
        titile_1: `64 सीएफडी और धातु`,
        description_1: `1 अंक के अंतर के साथ 64 सीएफडी जोड़े और यूके 100, एसएंडपी 500, एयूएस 200 और डॉव जोन्स इंडेक्स सहित 15 प्रमुख इक्विटी सूचकांकों का प्रतिदिन 24 घंटे व्यापार करें।`,
        titile_2: `1:3000 तक का लाभ उठाएं`,
        description_2: `IUX मार्केट्स मेटा ट्रेडर5 प्लेटफॉर्म पर खाते 1:3000 लीवरेज तक जाते हैं। व्यापारी अपनी ट्रेडिंग शैली के अनुरूप उच्च उत्तोलन का उपयोग कर सकते हैं और अपनी मैन्युअल और स्वचालित ट्रेडिंग रणनीतियों से सर्वोत्तम लाभ प्राप्त कर सकते हैं।`,
        titile_3: `सभी प्रमुख खाता मुद्राएँ समर्थित`,
        description_3: `हम जानते हैं कि कुछ व्यापारी अपनी स्थानीय मुद्रा में सौदा करना पसंद करते हैं। हम व्यापारियों को 10 समर्थित आधार मुद्राओं में से 1 में खाता खोलने का विकल्प देते हैं: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD।`,
      },
    },
    accpro: {
      box_1: {
        titile_1: `सीएफडी ट्रेडिंग`,
        titile_2: `90 से अधिक उपकरणों का व्यापार करें`,
        titile_3: `कमीशन $0`,
        titile_4: `त्वरित आदेश निष्पादन`,
        titile_5: `1:3000 उत्तोलन`,
        titile_6: `0.1 पीआईपी जितना कम स्प्रेड`,
        titile_7: `मेटाट्रेडर 5`,
      },
      section_1: {
        titile_1: `प्रो क्यों चुनें?`,
        description_1: `यदि आप एक अनुभवी और पेशेवर व्यापारी हैं, तो प्रो खाता आपके लिए उपयुक्त है। IUX मार्केट्स का प्रो अकाउंट कच्चे स्प्रेड के साथ आता है या 0.0 पिप्स से शुरू होने वाले फ्री स्प्रेड के साथ भी आता है। अधिकांश उपकरणों के लिए तत्काल निष्पादन के साथ निष्पादित ऑर्डर की गति भी प्रदान करता है। हमारे प्रो खातों में स्केलपर्स, डे-ट्रेडर्स और अल्गोट्रेडर्स के अनुरूप निष्पादन है।`,
      },
      section_2: {
        titile_1: `त्वरित आदेश निष्पादन`,
        description_1: `IUX मार्केट्स मेटाट्रेडर5 सर्वर लंदन में इक्विनिक्स LD4 डेटा सेंटर में स्थित है। NY4 डेटा सेंटर, जिसे वित्तीय पारिस्थितिकी तंत्र के रूप में जाना जाता है, 600 से अधिक खरीद और बिक्री पक्ष फर्मों, एक्सचेंजों, व्यापारिक स्थानों, बाजार डेटा और सेवा प्रदाताओं की मेजबानी करता है। IUX मार्केट्स ट्रेडिंग वातावरण के माध्यम से कम विलंबता और आपके ट्रेडों के तेज़ निष्पादन को सुनिश्चित करने के लिए मेटाट्रेडर5 सर्वर हमारे संचार नेटवर्क से क्रॉस कनेक्टेड है। IUX मार्केट्स मेटाट्रेडर5 और 5 ट्रेड सर्वरों में प्रमुख VPS प्रदाताओं के लिए 1 मिलीसेकंड से कम की विलंबता है, जो या तो NY4 डेटा सेंटर में या पास के डेटा केंद्रों के लिए समर्पित लाइनों के माध्यम से स्थित हैं। यह कम विलंबता वातावरण स्वचालित और उच्च आवृत्ति व्यापार और स्केलिंग के लिए आदर्श है।`,
      },
      section_3: {
        titile_1: `ट्रेडिंग पर कोई प्रतिबंध नहीं - स्कैल्पिंग की `,
        description_1: `IUX MaIUX मार्केट्स मेटा ट्रेडर5 प्लेटफॉर्म पर ट्रेडिंग पर कोई प्रतिबंध नहीं है। व्यापारियों को स्प्रेड के बीच ऑर्डर देने की अनुमति मिलती है क्योंकि कोई न्यूनतम ऑर्डर दूरी नहीं होती है और 0 का फ़्रीज़ स्तर होता है, इसका मतलब है कि स्टॉप लॉस ऑर्डर सहित ऑर्डर को आपकी इच्छानुसार बाज़ार मूल्य के करीब रखा जा सकता है। \nव्यापारी पोजीशन को हेज भी कर सकते हैं क्योंकि IUX मार्केट्स में पहले आओ पहले बाहर करो (फीफो) नियम नहीं है। व्यापारी हेज्ड ट्रेडों पर मार्जिन का भुगतान नहीं करते हैं और मार्जिन नेटिंग के लाभों का आनंद लेते हैं।`,
        titile_2: `लेवल II मूल्य निर्धारण - बाज़ार की गहराई`,
        description_2: `बाज़ार की गहराई हमारे मूल्य प्रदाताओं से सीधे आने वाली निष्पादन योग्य कीमतों की पूरी श्रृंखला दिखाती है। बाज़ार की गहराई किसी भी समय प्रत्येक मूल्य स्तर के लिए उपलब्ध मात्रा दिखाकर प्रत्येक मुद्रा जोड़ी की तरलता की पूर्ण पारदर्शिता प्रदान करती है। उच्च तरलता, अतुल्यकालिक स्पॉट कीमतें और कम विलंबता सबसे सख्त संभव प्रसार की गारंटी देती हैं।`,
      },
      section_4: {
        titile_1: `64 सीएफडी और धातु`,
        description_1: `1 अंक के अंतर के साथ 64 सीएफडी जोड़े और यूके 100, एसएंडपी 500, एयूएस 200 और डॉव जोन्स इंडेक्स सहित 15 प्रमुख इक्विटी सूचकांकों का प्रतिदिन 24 घंटे व्यापार करें।`,
        titile_2: `1:3000 तक का लाभ उठाएं`,
        description_2: `IUX मार्केट्स मेटा ट्रेडर5 प्लेटफॉर्म पर खाते 1:3000 लीवरेज तक जाते हैं। व्यापारी अपनी ट्रेडिंग शैली के अनुरूप उच्च उत्तोलन का उपयोग कर सकते हैं और अपनी मैन्युअल और स्वचालित ट्रेडिंग रणनीतियों से सर्वोत्तम लाभ प्राप्त कर सकते हैं।`,
        titile_3: `सभी प्रमुख खाता मुद्राएँ समर्थित`,
        description_3: `हम जानते हैं कि कुछ व्यापारी अपनी स्थानीय मुद्रा में सौदा करना पसंद करते हैं। हम व्यापारियों को 10 समर्थित आधार मुद्राओं में से 1 में खाता खोलने का विकल्प देते हैं: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD।`,
      },
    },
    funding: {
      section_1: {
        title: `जमा/निकासी कैसे करें?`,
        titile_1: `रजिस्टर करें और साइन-इन करें`,
        titile_2: `जमा और निकासी के लिए अपना पसंदीदा बैंक चुनें`,
        titile_3: `जमा और निकासी के लिए शून्य-शुल्क`,
        titile_4: `कुछ ही मिनटों में तेजी से जमा और निकासी`,
      },
      section_2: {
        title: `जमा और निकासी के विकल्प`,
        description: `हम आपकी वित्तीय जानकारी को एन्क्रिप्शन के साथ सुरक्षित रखते हैं। तो आप निश्चिंत होकर ऑनलाइन भुगतान कर सकते हैं।`,
        option: `फंडिंग विकल्प`,
        hot: `लोकप्रिय`,
        titile_1: `क्रेडिट और डेबिट`,
        titile_2: `Skrill`,
        titile_3: `Neteller`,
        titile_4: `थाई क्यूआर भुगतान`,
        titile_5: `ऑनलाइन बैंकिंग`,
      },
      section_3: {
        titile_1: `भुगतान प्रक्रिया`,
        description_1: `तेजी से प्रसंस्करण के लिए, हम सुरक्षित ग्राहक क्षेत्र से धनराशि जमा करने की सलाह देते हैं। शेष राशि वास्तविक समय में जमा की जाएगी। यदि आपको सुरक्षित ग्राहक क्षेत्र तक पहुँचने में कोई समस्या है, तो कृपया निर्देशों का पालन करें।`,
        titile_2: `निधियों की सुरक्षा`,
        description_2: `ग्राहक निधि एक अलग ग्राहक ट्रस्ट खाते में रखी जाती है। इलेक्ट्रॉनिक भुगतान एसएसएल (सिक्योर सॉकेट लेयर) तकनीक का उपयोग करके संसाधित किए जाते हैं और सुरक्षा सुनिश्चित करने के लिए एन्क्रिप्ट किए जाते हैं। सभी भुगतान जानकारी गोपनीय है और इसका उपयोग केवल IUX मार्केट्स के साथ आपके ट्रेडिंग खाते को वित्त पोषित करने के उद्देश्य से किया जाता है।`,
        titile_3: `बैंक शुल्क`,
        description_3: `IUX मार्केट जमा और निकासी के लिए किसी भी अतिरिक्त शुल्क पर शून्य शुल्क प्रदान करता है। हालाँकि, आपको यह समझना चाहिए कि शुल्क अंतरराष्ट्रीय बैंकिंग संस्थानों के भुगतान पर लग सकता है। IUX Markets की बैंकों या प्रदाताओं के शुल्क के किसी भी शुल्क के लिए कोई ज़िम्मेदारी नहीं है।`,
        titile_4: `तृतीय पक्ष भुगतान`,
        description_4: `IUX मार्केट तीसरे पक्ष से भुगतान की अनुमति नहीं देता है। कृपया सुनिश्चित करें कि आपके ट्रेडिंग खाते में सभी जमा आपके नाम के बैंक खाते से हों। यदि ट्रेडिंग खाता धारक बैंक खाते/क्रेडिट कार्ड के पक्षों में से एक है तो संयुक्त बैंक खाते/क्रेडिट कार्ड से भुगतान की अनुमति है।`,
      },
    },
    withdraw: {
      section_1: {
        title: `तेजी से प्रसंस्करण के लिए सभी खाताधारकों को अब अपने सुरक्षित ग्राहक क्षेत्र के अंदर से निकासी अनुरोध जमा करना आवश्यक है।`,
        content: `यदि आपको सुरक्षित ग्राहक क्षेत्र तक पहुँचने में कोई समस्या है, तो कृपया निर्देशों का पालन करें।`,
      },
      section_2: {
        title: `यदि आपको धनराशि निकालने का कोई अनुभव नहीं है, तो कृपया निर्देशों का पालन करें:`,
        title_1: `निकासी अनुरोधों का कट-ऑफ समय 12:00 एईएसटी/एईडीटी है। यदि आप इस समय से पहले अपनी निकासी जमा करते हैं, तो इसे प्राप्त होने वाले दिन ही संसाधित किया जाएगा। यदि आप इस समय के बाद अपनी निकासी जमा करते हैं, तो इसे अगले कार्य दिवस पर संसाधित किया जाएगा।`,
        title_2: `IUX मार्केट जमा और निकासी के लिए किसी भी अतिरिक्त शुल्क पर शून्य शुल्क प्रदान करता है। हालाँकि, आपको यह समझना चाहिए कि शुल्क अंतरराष्ट्रीय बैंकिंग संस्थानों के भुगतान पर लग सकता है। IUX Markets की बैंकों या प्रदाताओं के शुल्क के किसी भी शुल्क के लिए कोई ज़िम्मेदारी नहीं है।`,
        title_3: `अंतर्राष्ट्रीय बैंक वायर ट्रांसफ़र के लिए, हमारे बैंकिंग संस्थान द्वारा मुद्रा के बराबर प्रोसेसिंग शुल्क लिया जाता है। यह शुल्क आपकी निकासी राशि से काट लिया जाएगा। इसमें 14 दिन तक का समय लग सकता है और आपके अधिकार क्षेत्र और आपके द्वारा चुने गए बैंक के आधार पर अतिरिक्त मध्यवर्ती लागत शामिल हो सकती है।`,
        title_4: `क्रेडिट/डेबिट कार्ड से निकासी निःशुल्क की जाती है। एक बार संसाधित होने के बाद, क्रेडिट/डेबिट कार्ड से निकासी को आपके क्रेडिट कार्ड तक पहुंचने में 3-5 कार्यदिवस लग सकते हैं। हालाँकि, आपको यह समझना चाहिए कि कुछ दुर्लभ अवसरों में, बैंक की समाप्ति के आधार पर कभी-कभी 10 कार्य दिवस तक का समय लग सकता है। *कृपया ध्यान दें कि क्रेडिट कार्ड से निकासी सभी देशों के लिए उपलब्ध नहीं हो सकती है।`,
        title_5: `पेपैल/नेटेलर/स्क्रिल निकासी उसी खाते से की जानी चाहिए जहां से शुरुआत में धनराशि भेजी गई थी। ये लेनदेन नि:शुल्क संसाधित होते हैं और संसाधित होते ही तुरंत हो जाते हैं।`,
        title_6: `यदि आपका क्रेडिट/डेबिट कार्ड पहले ही समाप्त हो चुका है, तो बिना किसी रुकावट के जमा और निकासी सेवाओं का उपयोग जारी रखने के लिए कृपया अपने ग्राहक क्षेत्र में नया कार्ड अपलोड करें। यदि नए कार्ड का नंबर समाप्त हो चुके कार्ड के नंबर से अलग है, तो आपको पुराने कार्ड के जारीकर्ता बैंक से जारी एक पत्र जमा करना होगा जिसमें पुष्टि की गई हो कि पुराने कार्ड के स्थान पर नया कार्ड जारी किया गया है।`,
        title_7: `यदि आपका क्रेडिट/डेबिट कार्ड खो गया है, चोरी हो गया है, क्षतिग्रस्त हो गया है या रद्द कर दिया गया है, तो आपको पुराने कार्ड जारीकर्ता बैंक से जारी एक पत्र जमा करना होगा जिसमें यह पुष्टि की गई हो कि पुराना कार्ड अब वैध नहीं है।`,
        title_8: `आईयूएक्स मार्केट्स, अपने विवेक पर, नए कार्ड में धनराशि जारी करने से पहले आपसे सहायक दस्तावेज (उदाहरण के लिए, पुराने कार्ड या जमा लेनदेन दिखाने वाले कार्ड स्टेटमेंट के माध्यम से संसाधित भुगतान के लिए जमा रसीदें) जमा करने के लिए कह सकता है।`,
        title_9: `वीज़ा/मास्टरकार्ड द्वारा सत्यापित सुरक्षित क्रेडिट/डेबिट कार्ड के माध्यम से रखी गई राशि से अधिक राशि निकालने के लिए, आपको पहले इस्तेमाल की गई किसी अन्य जमा विधि या बैंक वायर ट्रांसफर विकल्प का उपयोग करना होगा।`,
        title_10: `यदि आप थाई इंटरनेट बैंकिंग विकल्प के माध्यम से जमा की गई अपनी धनराशि को थाई बैंक खाते में निकाल रहे हैं, तो आपको अपने बुक बैंक की एक स्पष्ट तस्वीर या रंगीन स्कैन कॉपी अपलोड करनी होगी। एक बार संसाधित होने के बाद, धनराशि आपके खाते तक पहुंचने में एक कार्यदिवस लग सकता है।`,
        title_11: `IUX मार्केट्स तीसरे पक्ष को भुगतान संसाधित नहीं करता है। कृपया सुनिश्चित करें कि आपके ट्रेडिंग खाते से सभी निकासी अनुरोध आपके नाम के बैंक खाते या किसी स्रोत पर जाएं। यदि ट्रेडिंग खाताधारक बैंक खाते/क्रेडिट कार्ड के पक्षों में से एक है तो संयुक्त बैंक खातों/क्रेडिट कार्ड से भुगतान स्वीकार किया जाता है।`,
        title_12: `IUX मार्केट्स तीसरे पक्ष को भुगतान संसाधित नहीं करता है। कृपया सुनिश्चित करें कि आपके ट्रेडिंग खाते से सभी निकासी अनुरोध आपके नाम के बैंक खाते या किसी स्रोत पर जाएं। यदि ट्रेडिंग खाताधारक बैंक खाते/क्रेडिट कार्ड के पक्षों में से एक है तो संयुक्त बैंक खातों/क्रेडिट कार्ड से भुगतान स्वीकार किया जाता है।`,
      },
    },
  },
  ms: {
    account_create_demo: `Akaun demo anda adalah pelaburan bebas risiko di mana anda boleh mengamalkan perdagangan, strategi ujian, dan selesa dengan platform kami.`,
    account_create_success_islamic: `Akaun ini secara automatik ditetapkan ke status Bebas Swap. Sila simpan kata laluan perdagangan anda dengan segera. Ia tidak dapat dihantar melalui emel atas sebab-sebab keselamatan.`,
    account_create_success: `Tiada lagi caj semalaman. Status bebas swap anda ditentukan oleh aktiviti dagangan anda. Untuk mengekalkan status bebas swap aktif, anda perlu berdagang kebanyakannya dalam masa sehari.`,
    open_an_account: "Buka akaun",
    low_risk: "	Risiko rendah	",
    high_risk: "	Berisiko tinggi	",
    medium_risk: "	Risiko sederhana	",
    standard_account_description:
      "	Paling popular! Akaun hebat untuk semua jenis peniaga.	",
    standard_bonus_account_description:
      "	Akaun Pelaksanaan Segera kami. Isi yang sangat baik tanpa komisen perdagangan.	",
    raw_account_description:
      "	Penyebaran mentah yang paling rendah dan komisen tetap yang rendah.	",
    pro_account_description: "	Akaun profesional dengan komisen percuma 0%.	",
    button: {
      title: `Deposit segera`,
    },
    cover: {
      accover: {
        title: `Gambaran keseluruhan akaun`,
        description: `IUX Markets menawarkan spread yang rendah dan kumpulan produk besar yang disertakan ke dalam semua jenis akaun. Sesuai untuk pedagang baru dan berpengalaman, cuma pilih platform dagangan dan model harga pilihan anda untuk bermula.`,
      },
      accstd: {
        title: ` Standard`,
        description: `Membuat anda mendapat kelebihan dengan spread rendah pada platform dagangan paling popular di dunia dengan alat pengumpulan data jambatan proprietari kami yang menggabungkan pemilihan penyedia harga no.1 kami.`,
      },
      acccnt: {
        title: `Cent`,
        description: `Akaun pemula dengan unit pelaburan kurang daripada 100 kali ganda, sesuai untuk semua gaya dagangan. Anda boleh menggunakan mana-mana strategi perdagangan yang anda suka. Dengan spread yang sangat rendah yang sama seperti akaun Standard, anda mempunyai lebih banyak peluang untuk menang`,
      },
      accecn: {
        title: `ECN`,
        description: `Perdagangan mudah dengan Akaun berstandard IUX Markets. Alami faedah dan harga spread rendah kami yang merangkumi semuanya tanpa komisen.`,
      },
      accraw: {
        title: `Raw`,
        description: `Perdagangan mudah dengan Akaun berstandard IUX Markets. Alami faedah dan harga spread rendah kami yang merangkumi semuanya tanpa komisen.`,
      },
      accpro: {
        title: `Pro`,
        description: `Perdagangan mudah dengan Akaun berstandard IUX Markets. Alami faedah dan harga spread rendah kami yang merangkumi semuanya tanpa komisen.`,
      },
      accbonus: {
        title: `Standard+`,
        description: `Perdagangan mudah dengan Akaun berstandard IUX Markets. Alami faedah dan harga spread rendah kami yang merangkumi semuanya tanpa komisen.`,
      },
      deposit: {
        title: `Deposit wang ke akaun`,
        description: `IUX Markets menawarkan 15+ pilihan untuk membiayai akaun anda dalam 10 mata wang asas yang berbeza. Deposit segera tanpa yuran dengan pelbagai kaedah.`,
      },
      withdraw: {
        title: `Pengeluaran wang`,
        description: `Perdagangan mudah dengan Akaun berstandard IUX Markets. Alami faedah dan harga spread rendah kami yang merangkumi semuanya tanpa komisen.`,
      },
    },
    menu: {
      menu_1: "Gambaran Keseluruhan Akaun",
    },
    section_1: {
      title: "Mengapa perlu memilih akaun Standard kami?",
      description:
        "Kami menawarkan spread serendah mungkin. Purata spread EUR/USD kami ialah 0.2 PIPs tanpa komisen. Dengan 25 sumber penetapan jumlah harga gred institusi, akaun IUX Markets Standard telah dicipta untuk pedagang harian, pedagang dan penasihat pakar. Platform IUX Markets Meta Trader5 ialah pilihan yang diinginkan pedagang harian dan pedagang di seluruh dunia.",
    },
    std: {
      box_1: {
        titile_1: `Pelaburan CFDs`,
        titile_2: `Harga spread yang rendah`,
        titile_3: `Spread dari 0.2 PIPs`,
        titile_4: `Pelaksanaan pesanan yang pantas`,
        titile_5: `1:3000 leverage`,
        titile_6: `Kecairan tinggi`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `Akaun Standard menawarkan spread terendah. Purata spread EUR/USD kami ialah 0.2 PIPs tanpa komisen dagangan.
        Pasaran Kecairan Tinggi dan Pelaksanaan Pantas. Akaun Spread Rendah IUX Markets.
        Dibuat untuk peniaga harian, dan penasihat pakar`,
        titile_2: `Server Meta Trader5 Low Spread kami terletak di pusat data Equinix LD4.
        London membawa anda lebih dekat dengan pusat data jambatan kami, menghapuskan kependaman yang tidak perlu. Platform IUX Markets.
        Meta Trader5 ialah pilihan yang baik untuk pedagang harian dan pedagang di seluruh dunia.`,
      },
      section_2: {
        titile_1: `Harga spread rendah`,
        description_1: `IUX Markets mampu menawarkan harga pasaran dan ketentuan terbaik melalui platform MT5, memberikan pelanggan harga spread yang rendah. \nPersekitaran penetapan harga spread rendah membolehkan anda berdagang, menetapkan harga pada tahap yang sama dengan lokasi pelaksanaan pesanan terkemuka dunia. Harga penstriman yang dilaksanakan (ESP) dihantar daripada penyedia harga IUX Markets dan menawarkan kepada pelanggan kami tanpa meja dagangan. Pelarasan Harga atau Sebut Harga IUX Markets ialah pembekal CFDs CFDs pilihan ramai pedagang termasuk pengguna scalper dan robot yang mahukan spread rendah dan pelaksanaan yang terbaik.`,
      },
      section_3: {
        titile_1: `Pelaksanaan pesanan yang pantas`,
        description_1: `Pelayan IUX Markets Meta Trader5 terletak di Pusat Data Equinix LD4 in London. Dengan lebih 600 lokasi dan pembekal dagangan data pasaran. Pelayan Meta Trader5 disambungkan ke rangkaian komunikasi kami untuk memastikan kependaman rendah dan proses dagangan anda dapat dilaksanakan dengan pantas melalui persekitaran dagangan IUX Markets. Server dagangan Meta Trader5 menawarkan kependaman kurang daripada 1 milisaat kepada utama Pembekal VPS Sama ada terletak di pusat data NY4 atau melalui talian khusus ke pusat data.`,
        titile_2: `Spread dari 0.2 PIPs`,
        description_2: `IUX Markets  mempunyai spread paling ketat daripada semua broker CFDs seluruh dunia. Spread bermula dari 0.2 PIPs pada platform Meta Trader5, purata spread EURUSD ialah 0.2 PIPs, 24 jam sehari. Pada masa ini, secara purata, EUR/USD ialah salah satu pasangan spread paling ketat di seluruh dunia. \n Alat penyambungan harga kami menghimpunkan gabungan sehingga 25 penyedia harga yang berbeza, bermakna kami boleh memberikan pelanggan kami harga terbaik dan memastikan spread kami ketat terutamanya semasa turun naik yang tinggi seperti pengumuman berita.`,
        titile_3: `Tiada Sekatan Dagangan - Benarkan Scalping`,
        description_3: `IUX Markets  Meta Trader5 platforms IUX Markets  Meta Trader5 platforms tiada sekatan ke atas perdagangan. Kami menawarkan keadaan dagangan terbaik untuk dagangan yang menguntungkan pada masa pendek (Scalping) dan menyokong dagangan frekuensi tinggi. Benarkan peniaga membuat pesanan ketika spread oleh kerana tiada jarak pesanan minimum dan tahap penetapan ialah 0, ini bermakna pesanan pembelian termasuk pesanan henti rugi boleh diletakkan sehampir dengan harga pasaran yang anda mahukan. \nPeniaga juga boleh menjamin risiko pelaburan mereka kerana tiada peraturan masuk dahulu, keluar dahulu (FIFO). Dengan IUX Markets,,ppeniaga tidak perlu perlu membayar wang jaminan pada dagangan yang dilindung nilai dan mendapat manfaat daripada keuntungan`,
        titile_4: `Harga Tahap II - Kedalaman Pasaran`,
        description_4: `Kedalaman Pasaran menunjukkan semua harga yang dilaksanakan secara langsung daripada pembekal harga kami. Kedalaman Pasaran memberikan ketelusan sempurna bagi setiap kecairan pasangan mata wang dengan menunjukkan nilai yang tersedia untuk setiap tahap harga pada bila-bila masa. Kecairan tinggi, harga spot tak segerak dan kependaman rendah menjamin spread terendah.`,
      },
      section_4: {
        titile_1: `Pelbagai pilihan deposit dan pengeluaran`,
        description_1: `Sebaik sahaja anda membuka akaun, anda boleh mendeposit menggunakan pilihan deposit kami termasuk: Kad Kredit/Debit, Skrill, PayPal, Neteller, Kod QR Thai dan Deposit melalui Pindahan daripada broker ke broker yang lain.`,
        titile_2: `Pelarasan saiz lot yang fleksibel`,
        description_2: `Tiada had atau sekatan pada saiz dagangan. Anda boleh berdagang dengan saiz lot sekecil satu lot mikro (0.01). Saiz lot fleksibel kami membolehkan anda mencuba platform dengan risiko minimum dan mengurus saiz dagangan anda dengan sewajarnya sesuai baki akaun anda.`,
      },
      section_5: {
        titile_1: `64 pasangan mata wang dan logam + 15 CFDs`,
        description_1: `Berdagang 64 pasangan mata wang serta 15 indeks saham utama termasuk UK 100, S&P 500,AUS 200 dan Indeks Dow Jones 24 jam sehari dengan sebaran hanya 1 mata.`,
        titile_2: `Leverage sehingga 1:1000`,
        description_2: `Akaun yang mempunyai leveraj sehingga 1:1000 pada platform IUX Markets Meta Trader5, pedagang boleh menggunakan leveraj yang lebih tinggi untuk disesuaikan dengan gaya dagangan mereka serta memanfaatkan strategi dagangan mereka sendiri dan dagangan automatik dengan terbaik`,
        titile_3: `Menyokong semua mata wang akaun utama`,
        description_3: `Kami sedar bahawa sesetengah peniaga lebih suka berdagang dalam mata wang tempatan mereka. Kami memberi pelabur pilihan untuk membuka akaun dalam 1 daripada 10 mata wang utama yang disokong: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.`,
      },
    },
    bonus: {
      box_1: {
        titile_1: `Dagangan CFDs `,
        titile_2: `Harga Spread rendah`,
        titile_3: `Suruhanjaya Percuma`,
        titile_4: `Pelaksanaan pesanan yang pantas`,
        titile_5: ` Leveraj 1:1000`,
        titile_6: `kecairan yang tinggi`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `Mengapa perlu memilih standard+`,
        description_1: `Akaun standard+ daripada IUX Markets menawarkan kelajuan pelaksanaan yang tiada tandingan. Dengan spread yang merangkumi semuanya bermula daripada 1 PIPs, server London Equinix LD4, kumpulan pembekal harga sehingga 25 penyedia , dan anda akan memahami sebab akaun standard kami boleh memberikan anda pengalaman dagangan di luar sangkaan setiap hari.`,
      },
      section_2: {
        titile_1: `Pelaksanaan pesanan yang pantas`,
        description_1: `IUX Markets Meta Trader5 Server terletak di Pusat Data Equinix LD4 di London. Dengan lebih 600 lokasi dan pembekal dagangan data pasaran, pelayan Meta Trader5 disambungkan ke rangkaian komunikasi kami untuk memastikan kependaman rendah dan pelaksanaan dagangan anda dengan pantas melalui persekitaran dagangan kami.

        \nServer IUX Markets Meta Trader5 dan 5 menyediakan daganagn kurang daripada 1 milisaat kependaman kepada penyedia VPS utama, sama ada terletak di pusat data NY4 atau melalui talian khusus ke pusat data.
        Persekitaran kependaman rendah ini sesuai untuk perdagangan automatik, berfrekuensi tinggi serta scalping.`,
      },
      section_3: {
        titile_1: `Tiada sekatan perdagangan - scalping dibenarkan`,
        description_1: `Platform IUX Markets Meta Trader5 tidak mempunyai sekatan dagangan. Kami menawarkan keadaan dagangan terbaik untuk dagangan yang menguntungkan pada masa pendek (Scalping) dan menyokong dagangan frekuensi tinggi.

        \nPedagang juga boleh melindung dari risiko dagangan (hedge position) kerana tiada peraturan masuk dahulu, keluar dahulu (FIFO). Dengan IUX Markets, pedagang tidak perlu membayar margin pada dagangan yang dilindung dari risiko dan mendapat manfaat daripada membuat keuntungan.`,
        titile_2: `Harga Tahap II - Kedalaman Pasaran`,
        description_2: `Kedalaman Pasaran menunjukkan semua harga yang dilaksanakan secara langsung daripada pembekal harga kami. Kedalaman Pasaran memberikan ketelusan sempurna bagi setiap kecairan pasangan mata wang dengan menunjukkan nilai yang tersedia untuk setiap tahap harga pada bila-bila masa. Kecairan tinggi, harga spot tak segerak dan kependaman rendah menjamin spread terendah.`,
      },
      section_4: {
        titile_1: `64 pasangan mata wang dan logam + 15 CFDs`,
        description_1: `Berdagang 64 pasangan mata wang serta 15 indeks saham utama termasuk UK 100, S&P 500,AUS 200 dan Indeks Dow Jones 24 jam sehari dengan sebaran hanya 1 mata.`,
        titile_2: `Leverej maksimum 1:1000`,
        description_2: `Akaun yang mempunyai leveraj sehingga 1:1000 pada platform IUX Markets Meta Trader5, pedagang boleh menggunakan leveraj yang lebih tinggi untuk disesuaikan dengan gaya dagangan mereka dan memanfaatkan strategi dagangan mereka sendiri dan dagangan automatik dengan terbaik.`,
        titile_3: `Menyokong semua mata wang akaun utama`,
        description_3: `Kami sedar bahawa sesetengah peniaga lebih suka berdagang dalam mata wang tempatan mereka. Kami memberi pelabur pilihan untuk membuka akaun dalam 1 daripada 10 mata wang utama yang disokong: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.`,
      },
    },
    accecn: {
      box_1: {
        titile_1: `Daganagan CFDs `,
        titile_2: `Berdagang lebih 90 instrumen`,
        titile_3: `komisen $3.5`,
        titile_4: `Pelaksanaan pesanan yang pantas`,
        titile_5: `leverej 1:3000 `,
        titile_6: `Spread serendah 0.0 PIPs`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `Mengapa perlu memilih akaun ECN kami?`,
        description_1: `Akaun ECN daripada IUX Markets menawarkan kecepatan pelaksanaan yang tiada tandingan. Dengan spread yang merangkumi semuanya bermula daripada 1 PIPs, server London Equinix LD4, kumpulan pembekal harga sehingga 25 penyedia , dan anda akan memahami sebab akaun standard kami boleh memberikan anda pengalaman dagangan di luar sangkaan setiap hari. `,
      },
      section_2: {
        titile_1: `Pelaksanaan pesanan yang pantas`,
        description_1: `IUX Markets Meta Trader5 Server terletak di Pusat Data Equinix LD4 di London. Dengan lebih 600 lokasi dan pembekal dagangan data pasaran, pelayan Meta Trader5 disambungkan ke rangkaian komunikasi kami untuk memastikan kependaman rendah dan pelaksanaan dagangan anda dengan pantas  melalui persekitaran dagangan kami.

        \n Server IUX Markets Meta Trader5 dan 5 menyediakan dagangan kurang daripada 1 milisaat kependaman kepada penyedia VPS utama, sama ada terletak di pusat data NY4 atau melalui talian khusus ke pusat data. Persekitaran kependaman rendah ini sesuai untuk Perdagangan Automatik dan Frekuensi Tinggi serta scalping.`,
      },
      section_3: {
        titile_1: `Tiada sekatan perdagangan - scalping dibenarkan`,
        description_1: `IUX Markets Platform Meta Trader5 tidak mempunyai sekatan ke atas dagangan. Membenarkan peniaga membuat pesanan di antara spread kerana tiada jarak pesanan minimum dan tahap pembekuan 0, Ini bermakna pesanan termasuk pesanan henti rugi boleh diletakkan sedekat mungkin dengan harga pasaran yang anda suka.

        \nPedagang juga boleh melindung dari risiko dagangan (hedge position) kerana tiada peraturan masuk dahulu, keluar dahulu (FIFO). Dengan IUX Markets, pedagang tidak perlu  membayar margin pada dagangan yang dilindung dari risiko dan mendapat manfaat daripada membuat keuntungan.`,
        titile_2: `Harga Tahap II - Kedalaman Pasaran`,
        description_2: `Kedalaman Pasaran menunjukkan semua harga yang dilaksanakan secara langsung daripada pembekal harga kami. Kedalaman Pasaran memberikan ketelusan sempurna bagi setiap kecairan pasangan mata wang dengan menunjukkan nilai yang tersedia untuk setiap tahap harga pada bila-bila masa. Kecairan tinggi, harga spot tak segerak dan kependaman rendah menjamin spread terendah. `,
      },
      section_4: {
        titile_1: `64 pasangan mata wang dan logam + 15 CFDs`,
        description_1: `Berdagang 64 pasangan mata wang serta 15 indeks saham utama termasuk UK 100, S&P 500,AUS 200 dan Indeks Dow Jones 24 jam sehari dengan sebaran hanya 1 mata.`,
        titile_2: `Leveraj maksimum 1:3000`,
        description_2: `Akaun yang mempunyai leveraj sehingga 1:3000 pada platform IUX Markets Meta Trader5, pedagang boleh menggunakan leveraj yang lebih tinggi untuk disesuaikan dengan gaya dagangan mereka dan memanfaatkan strategi dan leveraj dagangan mereka sendiri dan dagangan automatik dengan terbaik.`,
        titile_3: `Menyokong semua mata wang akaun utama`,
        description_3: `Kami sedar bahawa sesetengah peniaga lebih suka berdagang dalam mata wang tempatan mereka. Kami memberi pelabur pilihan untuk membuka akaun dalam 1 daripada 10 mata wang utama yang disokong: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.`,
      },
    },
    accraw: {
      box_1: {
        titile_1: `Daganagan CFDs `,
        titile_2: `Berdagang lebih 90 instrumen`,
        titile_3: `komisen $6`,
        titile_4: `Pelaksanaan pesanan yang pantas`,
        titile_5: `leverej 1:3000 `,
        titile_6: `Spread serendah 0.0 PIPs`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `Mengapa perlu memilih Raw`,
        description_1: `Akaun Raw daripada IUX Markets menawarkan kecepatan pelaksanaan yang tiada tandingan. Dengan spread yang merangkumi semuanya bermula daripada 0.0 PIPs, server London Equinix LD4, kumpulan pembekal harga sehingga 25 penyedia , dan anda akan memahami sebab akaun Raw kami boleh memberikan anda pengalaman dagangan di luar sangkaan setiap hari. `,
      },
      section_2: {
        titile_1: `Pelaksanaan pesanan yang pantas`,
        description_1: `IUX Markets Meta Trader5 Server terletak di Pusat Data Equinix LD4 di London. Dengan lebih 600 lokasi dan pembekal dagangan data pasaran, pelayan Meta Trader5 disambungkan ke rangkaian komunikasi kami untuk memastikan kependaman rendah dan pelaksanaan dagangan anda dengan pantas  melalui persekitaran dagangan kami.

        \n Server IUX Markets Meta Trader5 dan 5 menyediakan dagangan kurang daripada 1 milisaat kependaman kepada penyedia VPS utama, sama ada terletak di pusat data NY4 atau melalui talian khusus ke pusat data. Persekitaran kependaman rendah ini sesuai untuk Perdagangan Automatik dan Frekuensi Tinggi serta scalping.`,
      },
      section_3: {
        titile_1: `Tiada sekatan perdagangan - scalping dibenarkan`,
        description_1: `IUX Markets Platform Meta Trader5 tidak mempunyai sekatan ke atas dagangan. Membenarkan peniaga membuat pesanan di antara spread kerana tiada jarak pesanan minimum dan tahap pembekuan 0, Ini bermakna pesanan termasuk pesanan henti rugi boleh diletakkan sedekat mungkin dengan harga pasaran yang anda suka.

        \nPedagang juga boleh melindung dari risiko dagangan (hedge position) kerana tiada peraturan masuk dahulu, keluar dahulu (FIFO). Dengan IUX Markets, pedagang tidak perlu  membayar margin pada dagangan yang dilindung dari risiko dan mendapat manfaat daripada membuat keuntungan.`,
        titile_2: `Harga Tahap II - Kedalaman Pasaran`,
        description_2: `Kedalaman Pasaran menunjukkan semua harga yang dilaksanakan secara langsung daripada pembekal harga kami. Kedalaman Pasaran memberikan ketelusan sempurna bagi setiap kecairan pasangan mata wang dengan menunjukkan nilai yang tersedia untuk setiap tahap harga pada bila-bila masa. Kecairan tinggi, harga spot tak segerak dan kependaman rendah menjamin spread terendah. `,
      },
      section_4: {
        titile_1: `64 pasangan mata wang dan logam + 15 CFDs`,
        description_1: `Berdagang 64 pasangan mata wang serta 15 indeks saham utama termasuk UK 100, S&P 500,AUS 200 dan Indeks Dow Jones 24 jam sehari dengan sebaran hanya 1 mata.`,
        titile_2: `Leveraj maksimum 1:3000`,
        description_2: `Akaun yang mempunyai leveraj sehingga 1:3000 pada platform IUX Markets Meta Trader5, pedagang boleh menggunakan leveraj yang lebih tinggi untuk disesuaikan dengan gaya dagangan mereka dan memanfaatkan strategi dan leveraj dagangan mereka sendiri dan dagangan automatik dengan terbaik.`,
        titile_3: `Menyokong semua mata wang akaun utama`,
        description_3: `Kami sedar bahawa sesetengah peniaga lebih suka berdagang dalam mata wang tempatan mereka. Kami memberi pelabur pilihan untuk membuka akaun dalam 1 daripada 10 mata wang utama yang disokong: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.`,
      },
    },
    accpro: {
      box_1: {
        titile_1: `Daganagan CFDs `,
        titile_2: `Berdagang lebih 90 instrumen`,
        titile_3: `komisen $0`,
        titile_4: `Pelaksanaan pesanan yang pantas`,
        titile_5: `leverej 1:3000 `,
        titile_6: `Spread serendah 0.1 PIPs`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `Mengapa perlu memilih Pro`,
        description_1: `Akaun Pro daripada IUX Markets menawarkan kecepatan pelaksanaan yang tiada tandingan. Dengan spread yang merangkumi semuanya bermula daripada 0.0 PIPs, server London Equinix LD4, kumpulan pembekal harga sehingga 25 penyedia , dan anda akan memahami sebab akaun standard kami boleh memberikan anda pengalaman dagangan di luar sangkaan setiap hari. `,
      },
      section_2: {
        titile_1: `Pelaksanaan pesanan yang pantas`,
        description_1: `IUX Markets Meta Trader5 Server terletak di Pusat Data Equinix LD4 di London. Dengan lebih 600 lokasi dan pembekal dagangan data pasaran, pelayan Meta Trader5 disambungkan ke rangkaian komunikasi kami untuk memastikan kependaman rendah dan pelaksanaan dagangan anda dengan pantas  melalui persekitaran dagangan kami.

        \n Server IUX Markets Meta Trader5 dan 5 menyediakan dagangan kurang daripada 1 milisaat kependaman kepada penyedia VPS utama, sama ada terletak di pusat data NY4 atau melalui talian khusus ke pusat data. Persekitaran kependaman rendah ini sesuai untuk Perdagangan Automatik dan Frekuensi Tinggi serta scalping.`,
      },
      section_3: {
        titile_1: `Tiada sekatan perdagangan - scalping dibenarkan`,
        description_1: `IUX Markets Platform Meta Trader5 tidak mempunyai sekatan ke atas dagangan. Membenarkan peniaga membuat pesanan di antara spread kerana tiada jarak pesanan minimum dan tahap pembekuan 0, Ini bermakna pesanan termasuk pesanan henti rugi boleh diletakkan sedekat mungkin dengan harga pasaran yang anda suka.

        \nPedagang juga boleh melindung dari risiko dagangan (hedge position) kerana tiada peraturan masuk dahulu, keluar dahulu (FIFO). Dengan IUX Markets, pedagang tidak perlu  membayar margin pada dagangan yang dilindung dari risiko dan mendapat manfaat daripada membuat keuntungan.`,
        titile_2: `Harga Tahap II - Kedalaman Pasaran`,
        description_2: `Kedalaman Pasaran menunjukkan semua harga yang dilaksanakan secara langsung daripada pembekal harga kami. Kedalaman Pasaran memberikan ketelusan sempurna bagi setiap kecairan pasangan mata wang dengan menunjukkan nilai yang tersedia untuk setiap tahap harga pada bila-bila masa. Kecairan tinggi, harga spot tak segerak dan kependaman rendah menjamin spread terendah. `,
      },
      section_4: {
        titile_1: `64 pasangan mata wang dan logam + 15 CFDs`,
        description_1: `Berdagang 64 pasangan mata wang serta 15 indeks saham utama termasuk UK 100, S&P 500,AUS 200 dan Indeks Dow Jones 24 jam sehari dengan sebaran hanya 1 mata.`,
        titile_2: `Leveraj maksimum 1:3000`,
        description_2: `Akaun yang mempunyai leveraj sehingga 1:3000 pada platform IUX Markets Meta Trader5, pedagang boleh menggunakan leveraj yang lebih tinggi untuk disesuaikan dengan gaya dagangan mereka dan memanfaatkan strategi dan leveraj dagangan mereka sendiri dan dagangan automatik dengan terbaik.`,
        titile_3: `Menyokong semua mata wang akaun utama`,
        description_3: `Kami sedar bahawa sesetengah peniaga lebih suka berdagang dalam mata wang tempatan mereka. Kami memberi pelabur pilihan untuk membuka akaun dalam 1 daripada 10 mata wang utama yang disokong: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.`,
      },
    },
    funding: {
      section_1: {
        title: `Bagaimana cara Deposit/Pengeluaran?`,
        titile_1: `Daftar atau Log Masuk`,
        titile_2: `Pilih bank yang anda gunakan dan teruskan ke Deposit/Pengeluaran.`,
        titile_3: `Deposit Percuma / Yuran Pengeluaran.`,
        titile_4: `Pengeluaran pantas dalam beberapa minit.`,
      },
      section_2: {
        title: `Pilihan deposit dan pengeluaran`,
        description: `Kami memastikan maklumat kewangan anda selamat dengan penyulitan. Jadi anda boleh membayar secara online dengan yakin.`,
        option: `Pilihan deposit`,
        hot: `Popular`,
        titile_1: `Kad Kredit dan Debit `,
        titile_2: `Skrill`,
        titile_3: `Neteller`,
        titile_4: `Thai QR Payment`,
        titile_5: `Online Banking`,
      },
      section_3: {
        titile_1: `Langkah pembayaran`,
        description_1: `Untuk pelaksanaan yang lebih cepat Kami mengesyorkan agar pemegang akaun membuat deposit ke akaun dagangan dari dalam Secure Client Area.
        Dari Secure Client Area anda, anda akan dapat membiayai akaun anda dalam masa nyata menggunakan Kad Kredit dan Skrill.
        \n
        \nJika anda belum mempunyai akses ke Secure Client Area kami, sila ikuti Arahan Akses.`,
        titile_2: `Keselamatan dana`,
        description_2: `Apabila anda membuat deposit ke dalam akaun dagangan anda, dana pelanggan akan disimpan dalam akaun amanah pelanggan yang diasingkan di National Australia Bank (NAB) dan Westpac Banking Corporation (Westpac),
        kedua-duanya adalah bank Australia bertaraf AA. Pembayaran elektronik diproses menggunakan teknologi SSL. (Secure Socket Layer) dan disulitkan untuk keselamatan. 
        Semua maklumat pembayaran akan dirahsiakan dan digunakan hanya untuk tujuan mendepositkan dana ke dalam akaun dagangan anda dengan IUX Markets.`,
        titile_3: `Yuran bank`,
        description_3: `IUX Markets tidak mengenakan bayaran tambahan untuk deposit atau pengeluaran. Walau bagaimanapun, anda harus ketahui bahwa anda mungkin dikenakan bayaran untuk mengeluarkan dana ke atau dari bank antarabangsa tertentu. IUX Markets tidak bertanggungjawab untuk yuran bank tersebut.`,
        titile_4: `Bayaran pihak ketiga`,
        description_4: `IUX Markets tidak menerima bayaran daripada pihak ketiga. Sila pastikan semua deposit yang dihantar ke akaun dagangan anda adalah daripada bank di bawah nama anda. 
        Membayar daripada akaun bank yang dinamakan bersama / kad kredit bersama Ini boleh dilakukan jika pemegang akaun dagangan disenaraikan dalam kumpulan pemegang bank/kad kredit.`,
      },
    },
    withdraw: {
      section_1: {
        title: `Supaya dapat dilaksanakan dengan lebih pantas, semua pemegang akaun dikehendaki mengemukakan permintaan pengeluaran dari dalam Secure Client Area.`,
        content: `Jika anda belum mempunyai akses ke Secure Client Area kami, sila ikuti Arahan Akses.`,
      },
      section_2: {
        title: `Jika anda tidak membuat pengeluaran pada masa lalu, sila ambil perhatian:`,
        title_1: `Permintaan pengeluaran akan dihadkan masa pada 12:00 AEST / AEDT. Jika permintaan pengeluaran anda diserahkan sebelum waktu ini, ia akan diproses pada hari yang diterima. Jika permintaan pengeluaran anda dihantar selepas waktu ini, ia akan diproses pada hari kerja berikutnya..`,
        title_2: `IUX Markets tidak mengenakan bayaran tambahan untuk deposit atau pengeluaran. Walau bagaimanapun, anda harus ketahui bahwa anda mungkin dikenakan bayaran untuk mengeluarkan dana ke atau dari bank antarabangsa tertentu. IUX Markets tidak bertanggungjawab untuk yuran bank tersebut.`,
        title_3: `Untuk pemindahan bank antarabangsa, yuran pemprosesan adalah sebanyak 20 AUD atau setara dengan mata wang akan dikenakan oleh institusi kewangan kami. Kami akan menolak yuran ini daripada jumlah pengeluaran anda. Walau bagaimanapun, ini mungkin berbeza bergantung pada bidang kuasa anda dan bank yang anda gunakan. Perkara ini boleh mengambil masa sehingga 14 hari dan dikenakan yuran perantara tambahan.`,
        title_4: `Pengeluaran melalui kad kredit/debit diproses secara percuma. Setelah diproses, pengeluaran kad kredit/debit mungkin mengambil masa 3-5 hari kerja untuk sampai ke kad kredit anda. Walau bagaimanapun, anda harus sedar bahawa dalam keadaan yang jarang berlaku ia boleh mengambil masa sehingga 10 hari kerja bergantung pada bank terakhir. \n* Sila ambil perhatian bahawa pengeluaran kad kredit mungkin tidak tersedia di semua negara`,
        title_5: `Pengeluaran Safecharge hanya boleh dikembalikan dengan deposit. Anda boleh memilih kaedah deposit lain yang digunakan sebelum ini atau kaedah pindahan bank untuk mengeluarkan.`,
        title_6: `Pengeluaran Paypal / Neteller / Skrill mesti dibuat dari akaun yang sama dari mana wang asal dihantar. Urus niaga ini diproses secara percuma dan diproses serta-merta.`,
        title_7: `Jika kad kredit/debit anda yang dimuat naik telah tamat tempoh, sila muat naik kad baharu di kawasan pelanggan anda untuk terus menggunakan perkhidmatan deposit dan pengeluaran tanpa gangguan. Jika nombor kad baharu berbeza daripada nombor kad tamat tempoh, anda perlu menghantar surat daripada bank pengeluar kad lama yang mengesahkan bahawa kad baharu telah dikeluarkan menggantikan kad lama.`,
        title_8: `Jika kad kredit/debit anda yang dimuat naik hilang/dicuri/rosak/dibatalkan, anda perlu menghantar surat daripada bank pengeluar kad lama yang mengesahkan bahawa kad lama itu tidak lagi sah.`,
        title_9: `IUX Markets, mengikut pertimbangan sendiri, mungkin meminta anda menyerahkan dokumen sokongan. (Contohnya, resit untuk pembayaran yang dibuat pada kad lama atau penyata kad yang menunjukkan transaksi deposit) sebelum melepaskan dana kepada kad baharu.`,
        title_10: `Untuk mengeluarkan amaun yang lebih besar daripada amaun yang didepositkan oleh kad Kredit/Debit Verified by Visa / MasterCard Secure, anda perlu menggunakan kaedah deposit atau pilihan pindahan bank yang lain yang digunakan sebelum ini`,
        title_11: `Jika anda mengeluarkan dana anda yang didepositkan melalui pilihan Perbankan Internet Thai ke akaun bank di Thailand, anda perlu memuat naik gambar yang jelas atau salinan imbasan berwarna bagi buku bank anda. Setelah selesai, dana mungkin mengambil masa sehingga satu hari kerja untuk sampai ke akaun anda.`,
        title_12: `IUX Markets tidak memproses pembayaran kepada pihak ketiga. Sila pastikan bahawa semua permintaan pengeluaran akaun dagangan anda pergi ke akaun bank anda atau sumber atas nama anda. Pembayaran kepada akaun bank bersama/kad kredit diterima jika pemegang akaun dagangan adalah individu pada akaun bank/kad kredit.`,
      },
    },
  },
  id: {
    account_create_demo: `Akun demo Anda adalah investasi bebas risiko di mana Anda dapat berlatih perdagangan, menguji strategi, dan merasa nyaman dengan platform kami.`,
    account_create_success_islamic: `Akun ini secara otomatis diatur ke status Bebas Swap. Harap simpan kata sandi perdagangan Anda segera. Ini tidak dapat dikirim melalui email karena alasan keamanan.`,
    account_create_success: `Tidak ada lagi biaya semalam. Status bebas swap Anda ditentukan oleh aktivitas perdagangan Anda. Untuk mempertahankan status bebas swap aktif, Anda perlu berdagang sebagian besar dalam sehari.`,
    open_an_account: "Buka Akun",
    low_risk: "	Resiko rendah	",
    high_risk: "	Berisiko tinggi	",
    medium_risk: "	Risiko sedang	",
    standard_account_description:
      "	Paling populer! Akun yang bagus untuk semua jenis pedagang.	",
    standard_bonus_account_description:
      "	Akun Eksekusi Instan kami. Isi yang sangat baik tanpa komisi perdagangan.	",
    raw_account_description: "	Spread mentah terendah dan komisi tetap rendah.	",
    pro_account_description: "	Akun Profesional dengan Komisi Gratis 0%.	",
    button: {
      title: `Deposit now`,
    },
    cover: {
      accover: {
        title: `Account Overview`,
        description: `IUX Markets offers low spread instruments in all account types; suitable for all traders. Simply choose your preferred platform and get started. `,
      },
      accstd: {
        title: `Standard `,
        description: `With our proprietary bridge aggregator mixing chosen tier-1 price suppliers, you may gain the Low Spread advantage on one of the world's most popular trading platforms.`,
      },
      acccnt: {
        title: `Cent `,
        description: `A beginner account with less than 100 times investment units, suitable for all trading styles. You can use any trading strategy you prefer. With the same extremely low spread in the Standard account, you have more chances to make a profit.`,
      },
      accecn: {
        title: `ECN `,
        description: `Keep it simple with the IUX Markets Standard account. Experience all the benefits of our Low Spread Pricing with an all-inclusive spread with no commissions.`,
      },
      accraw: {
        title: `Raw `,
        description: `Keep it simple with the IUX Markets Raw account. Experience all the benefits of our Raw pricing with an all-inclusive spread.
`,
      },
      accpro: {
        title: `Pro `,
        description: `Suited to experienced and professional traders, for any trading style, with zero commission & low spread and no limit on orders that can be opened.`,
      },
      accbonus: {
        title: `Standard+ `,
        description: `Keep it simple with the IUX Markets Standard+ account. Experience all the benefits of our  Low Spread Pricing with an all-inclusive spread and no commissions.`,
      },
      deposit: {
        title: `Account Funding`,
        description: `IUX Markets offers over 15 flexible funding options in 10 different base CFDs. Deposit instantly and free using a range of methods.`,
      },
      withdraw: {
        title: `Fund Withdrawal`,
        description: `Keep it simple with the IUX Markets Standard account. Experience all the benefits of our  Low pricing  with an all-inclusive spread and no commissions.`,
      },
    },
    menu: {
      menu_1: "Gambaran Akun",
    },
    section_1: {
      title: "MENGAPA MEMILIH AKUN STANDAR KAMI?",
      description:
        "Kami menawarkan spread terendah. Spread rata-rata EUR/USD kami adalah 0.2 PIPs tanpa komisi. Akun Standar IUX Markets diciptakan untuk para pedagang harian, pedagang jangka pendek, dan pedagang ahli dengan sumber harga keseluruhan hingga 25 sumber kelas institusional. Platform MetaTrader5 IUX Markets adalah pilihan yang disukai oleh pedagang di seluruh dunia.",
    },
    std: {
      box_1: {
        titile_1: `Perdagangan CFDs `,
        titile_2: `Penetapan Spread Rendah`,
        titile_3: `Spread mulai dari 0.2 PIPs`,
        titile_4: `Eksekusi order cepat`,
        titile_5: `Leverage 1:3000`,
        titile_6: `Likuiditas tinggi`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `Akun Standar menawarkan spread terendah. Spread rata-rata EUR/USD kami adalah 0.2 PIPs tanpa komisi. Akun Standar IUX Markets diciptakan untuk para pedagang harian, pedagang jangka pendek, dan pedagang ahli dengan likuiditas tinggi dan eksekusi cepat. Platform MetaTrader5 IUX Markets adalah pilihan yang disukai oleh pedagang di seluruh dunia.`,
        titile_2: `Server MetaTrader5 kami berlokasi di Equinix LD4 Data Center di London, mendekatkan Anda ke pusat data jembatan kami dan membantu menghilangkan latensi yang tidak perlu. MetaTrader5 adalah pilihan yang bagus untuk pedagang harian, pedagang jangka pendek, dan pedagang ahli di seluruh dunia.`,
      },
      section_2: {
        titile_1: `Penetapan Spread Rendah`,
        description_1: `IUX Markets dapat menawarkan penetapan harga pasar dan kondisi perdagangan terbaik melalui platform MT5 dengan menyediakan Penetapan Spread Rendah kepada klien. Lingkungan Penetapan Spread Rendah memungkinkan Anda melakukan perdagangan dengan penetapan harga kelas institusional hampir selevel dengan venue eksekusi terkemuka di dunia. Executable Streaming Prices (ESP) dikirim dari penyedia harga IUX Markets kepada kami dan ditawarkan kepada klien kami tanpa dealing desk, manipulasi harga, atau requotes. IUX Markets adalah penyedia CFDs terbaik untuk pedagang bervolume tinggi, scalper, dan robot yang menyediakan spread ketat dan eksekusi terbaik.`,
      },
      section_3: {
        titile_1: `Eksekusi order cepat`,
        description_1: `Server MetaTrader5 IUX Markets berlokasi di pusat data Equinix LD4 di London. Pusat data NY4, yang disebut sebagai ekosistem keuangan, menjadi tuan rumah bagi lebih dari 600 perusahaan beli dan jual, bursa, tempat perdagangan, data pasar, dan penyedia layanan. Server MetaTrader5 terhubung silang ke Jaringan Komunikasi kami untuk memastikan latensi rendah dan eksekusi cepat dari perdagangan Anda melalui lingkungan perdagangan IUX Markets. Server perdagangan MetaTrader5 dan 5 IUX Markets memiliki latensi kurang dari 1 milidetik ke penyedia VPS utama yang terkolokasi di pusat data NY4 atau melalui jalur dedikasi ke pusat data terdekat. Lingkungan latensi rendah ini ideal untuk perdagangan otomatis dan frekuensi tinggi serta scalping.`,
        titile_2: `Spread mulai dari 0.2 PIPs`,
        description_2: `IUX Markets memiliki beberapa spread terkecil di antara semua broker CFDs secara global. Spread dimulai dari 0.2 PIPs pada platform MetaTrader5 dengan rata-rata pada EUR/USD menjadi 0.2 PIPs 24/7. Saat ini, ini merupakan spread rata-rata EUR/USD terkecil secara global. \nPenghubung penetapan harga kami menggabungkan campuran dari hingga 25 penyedia harga yang berbeda, yang berarti bahwa kami selalu dapat mencari harga terbaik untuk klien kami dan menjaga spread kami tetap ketat, terutama selama periode volatilitas tinggi seperti pengumuman berita..`,
        titile_3: `Tidak ada Batasan pada Perdagangan – Scalping Diperbolehkan`,
        description_3: `Platform MetaTrader5 IUX Markets tidak memiliki batasan pada perdagangan. memungkinkan pedagang menempatkan order di antara spread karena tidak ada jarak order minimum dan tingkat pembekuan 0. Ini berarti order termasuk order stop loss dapat ditempatkan sesuai dengan harga pasar sesuai keinginan Anda. \nPedagang juga dapat melindungi posisi karena tidak ada aturan first in first out (FIFO) dengan IUX Markets. Pedagang tidak membayar margin pada perdagangan yang dilindungi dan menikmati manfaat dari netting margin.`,
        titile_4: `Penetapan Harga Level II – Kedalaman Pasar`,
        description_4: `Kedalaman pasar menunjukkan seluruh rentang harga yang dapat dieksekusi langsung dari penyedia harga kami. Kedalaman pasar menawarkan transparansi penuh dari likuiditas setiap pasangan mata uang dengan menunjukkan volume yang tersedia untuk setiap tingkat harga pada waktu tertentu. Likuiditas tinggi, harga spot asinkron, dan latensi rendah menjamin spread yang paling ketat mungkin.`,
      },
      section_4: {
        titile_1: `Opsi Pendanaan dan Penarikan yang Fleksibel`,
        description_1: `Setelah Anda membuka akun, Anda dapat melakukan pendanaan menggunakan salah satu opsi pendanaan kami, termasuk: kartu kredit/debit, Skrill, PayPal, Neteller, kode QR Thailand, dan transfer broker ke broker, deposit.`,
        titile_2: `Penentuan Ukuran Lot yang Fleksibel`,
        description_2: `Tidak ada batasan atau pembatasan pada ukuran perdagangan, Anda dapat menempatkan perdagangan sekecil satu mikro lot (0.01). Penentuan ukuran lot yang fleksibel kami memungkinkan Anda mencoba platform dengan risiko minimal dan mengelola ukuran perdagangan sesuai dengan saldo akun Anda.`,
      },
      section_5: {
        titile_1: `64 CFDs & Logam `,
        description_1: `Perdagangkan 64 pasangan CFDs plus 15 indeks ekuitas utama termasuk UK 100, S&P 500, AUS 200, dan Dow Jones Index 24 jam sehari dengan spread 1 poin.`,
        titile_2: `Leverage hingga 1:3000`,
        description_2: `Akun mencapai leverage hingga 1:3000 pada platform MetaTrader5 IUX Markets. Pedagang dapat menggunakan leverage lebih tinggi sesuai gaya perdagangan mereka dan mendapatkan yang terbaik dari strategi perdagangan manual dan otomatis mereka.`,
        titile_3: `Semua Mata Uang Utama Didukung`,
        description_3: `Kami tahu beberapa pedagang lebih suka bertransaksi dalam mata uang lokal mereka. Kami memberi pedagang opsi untuk membuka akun dalam 1 dari 10 mata uang dasar yang didukung: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.`,
      },
    },

    bonus: {
      box_1: {
        titile_1: `Perdagangan CFDs `,
        titile_2: `Bonus Deposit $35`,
        titile_3: `Bebas Komisi`,
        titile_4: `Eksekusi order cepat`,
        titile_5: `Leverage 1:1000`,
        titile_6: `Likuiditas tinggi`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `Mengapa Memilih Standard+`,
        description_1: `Akun Standard+ dari IUX Markets menawarkan kecepatan eksekusi tanpa tanding. Ini mencakup spread inklusif mulai dari 0.5 PIPs, pusat data Equinix LD4 di London, dan kelompok hingga 25 penyedia harga. Anda akan mengerti mengapa akun Standard+ kami dapat memberikan pengalaman perdagangan yang tidak tertandingi, setiap hari sepanjang waktu.`,
      },
      section_2: {
        titile_1: `Eksekusi order cepat`,
        description_1: `Server MetaTrader5 IUX Markets berlokasi di pusat data Equinix LD4 di London. Pusat data NY4, yang disebut sebagai ekosistem keuangan, menjadi tuan rumah bagi lebih dari 600 perusahaan beli dan jual, bursa, tempat perdagangan, data pasar, dan penyedia layanan. Server MetaTrader5 terhubung silang ke Jaringan Komunikasi kami untuk memastikan latensi rendah dan eksekusi cepat dari perdagangan Anda melalui lingkungan perdagangan IUX Markets. Server perdagangan MetaTrader5 dan 5 IUX Markets memiliki latensi kurang dari 1 milidetik ke penyedia VPS utama yang terkolokasi di pusat data NY4 atau melalui jalur dedikasi ke pusat data terdekat. Lingkungan latensi rendah ini ideal untuk perdagangan otomatis dan frekuensi tinggi serta scalping.`,
      },
      section_3: {
        titile_1: `Tidak ada Batasan pada Perdagangan – Scalping Diperbolehkan`,
        description_1: `Platform MetaTrader5 IUX Markets tidak memiliki batasan pada perdagangan, memungkinkan pedagang menempatkan order di antara spread karena tidak ada jarak order minimum dan tingkat pembekuan 0. Ini berarti order termasuk order stop loss dapat ditempatkan sesuai dengan harga pasar sesuai keinginan Anda.
        
        \nPedagang juga dapat melindungi posisi karena tidak ada aturan first in first out (FIFO) dengan IUX Markets. Pedagang tidak membayar margin pada perdagangan yang dilindungi dan menikmati manfaat dari netting margin.`,
        titile_2: `Penetapan Harga Level II – Kedalaman Pasar`,
        description_2: `Kedalaman pasar menunjukkan seluruh rentang harga yang dapat dieksekusi langsung dari penyedia harga kami. Kedalaman pasar menawarkan transparansi penuh dari likuiditas setiap pasangan mata uang dengan menunjukkan volume yang tersedia untuk setiap tingkat harga pada waktu tertentu. Likuiditas tinggi, harga spot asinkron, dan latensi rendah menjamin spread yang paling ketat mungkin.`,
      },
      section_4: {
        titile_1: `64 CFDs & Logam `,
        description_1: `Perdagangkan 64 pasangan CFDs plus 15 indeks ekuitas utama termasuk UK 100, S&P 500, AUS 200, dan Dow Jones Index 24 jam sehari dengan spread 1 poin.`,
        titile_2: `Leverage hingga 1:1000`,
        description_2: `Akun mencapai leverage hingga 1:1000 pada platform MetaTrader5 IUX Markets. Pedagang dapat menggunakan leverage lebih tinggi sesuai gaya perdagangan mereka dan mendapatkan yang terbaik dari strategi perdagangan manual dan otomatis mereka.`,
        titile_3: `Semua Mata Uang Utama Didukung`,
        description_3: `Kami tahu beberapa pedagang lebih suka bertransaksi dalam mata uang lokal mereka. Kami memberi pedagang opsi untuk membuka akun dalam 1 dari 10 mata uang dasar yang didukung: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.`,
      },
    },

    accecn: {
      box_1: {
        titile_1: `Perdagangan CFDs `,
        titile_2: `Perdagangkan lebih dari 90 instrumen`,
        titile_3: `Komisi $3.5`,
        titile_4: `Eksekusi order cepat`,
        titile_5: `Leverage 1:3000`,
        titile_6: `Spread serendah 0.0 PIPs`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `Mengapa Memilih Akun ECN Kami?`,
        description_1: `Akun ECN dari IUX Markets menawarkan kecepatan eksekusi tanpa tanding. Gabungkan ini dengan spread inklusif mulai dari 0.0 PIPs, server Equinix LD4 di London, kelompok hingga 25 penyedia harga, dan Anda akan mengerti mengapa akun standar kami dapat memberikan pengalaman perdagangan yang tidak tertandingi, setiap hari sepanjang waktu.`,
      },
      section_2: {
        titile_1: `Eksekusi order cepat`,
        description_1: `Server MetaTrader5 IUX Markets berlokasi di pusat data Equinix LD4 di London. Pusat data NY4, yang disebut sebagai ekosistem keuangan, menjadi tuan rumah bagi lebih dari 600 perusahaan beli dan jual, bursa, tempat perdagangan, data pasar, dan penyedia layanan. Server MetaTrader5 terhubung silang ke Jaringan Komunikasi kami untuk memastikan latensi rendah dan eksekusi cepat dari perdagangan Anda melalui lingkungan perdagangan IUX Markets. Server perdagangan MetaTrader5 dan 5 IUX Markets memiliki latensi kurang dari 1 milidetik ke penyedia VPS utama yang terkolokasi di pusat data NY4 atau melalui jalur dedikasi ke pusat data terdekat. Lingkungan latensi rendah ini ideal untuk perdagangan otomatis dan frekuensi tinggi serta scalping.`,
      },
      section_3: {
        titile_1: `Tidak ada Batasan pada Perdagangan – Scalping Diperbolehkan`,
        description_1: `Platform MetaTrader5 IUX Markets tidak memiliki batasan pada perdagangan, memungkinkan pedagang menempatkan order di antara spread karena tidak ada jarak order minimum dan tingkat pembekuan 0. Ini berarti order termasuk order stop loss dapat ditempatkan sesuai dengan harga pasar sesuai keinginan Anda.
        
        \nPedagang juga dapat melindungi posisi karena tidak ada aturan first in first out (FIFO) dengan IUX Markets. Pedagang tidak membayar margin pada perdagangan yang dilindungi dan menikmati manfaat dari netting margin.`,
        titile_2: `Penetapan Harga Level II – Kedalaman Pasar`,
        description_2: `Kedalaman pasar menunjukkan seluruh rentang harga yang dapat dieksekusi langsung dari penyedia harga kami. Kedalaman pasar menawarkan transparansi penuh dari likuiditas setiap pasangan mata uang dengan menunjukkan volume yang tersedia untuk setiap tingkat harga pada waktu tertentu. Likuiditas tinggi, harga spot asinkron, dan latensi rendah menjamin spread yang paling ketat mungkin.`,
      },
      section_4: {
        titile_1: `64 CFDs & Logam `,
        description_1: `Perdagangkan 64 pasangan CFDs plus 15 indeks ekuitas utama termasuk UK 100, S&P 500, AUS 200, dan Dow Jones Index 24 jam sehari dengan spread 1 poin.`,
        titile_2: `Leverage hingga 1:3000`,
        description_2: `Akun mencapai leverage hingga 1:3000 pada platform MetaTrader5 IUX Markets. Pedagang dapat menggunakan leverage lebih tinggi sesuai gaya perdagangan mereka dan mendapatkan yang terbaik dari strategi perdagangan manual dan otomatis mereka.`,
        titile_3: `Semua Mata Uang Utama Didukung`,
        description_3: `Kami tahu beberapa pedagang lebih suka bertransaksi dalam mata uang lokal mereka. Kami memberi pedagang opsi untuk membuka akun dalam 1 dari 10 mata uang dasar yang didukung: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.`,
      },
    },

    accraw: {
      box_1: {
        titile_1: `Perdagangan CFDs `,
        titile_2: `Perdagangkan lebih dari 90 instrumen`,
        titile_3: `Komisi $6`,
        titile_4: `Eksekusi order cepat`,
        titile_5: `Leverage 1:3000`,
        titile_6: `Spread serendah 0.0 PIPs`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `Mengapa Memilih Akun Raw`,
        description_1: `Akun Raw dari IUX Markets menawarkan kecepatan eksekusi tanpa tanding. Gabungkan ini dengan spread inklusif mulai dari 0.0 PIPs, pusat data Equinix LD4 di London, kelompok hingga 25 penyedia harga, dan Anda akan mengerti mengapa akun Raw kami dapat memberikan pengalaman perdagangan yang tidak tertandingi, setiap hari sepanjang waktu.`,
      },
      section_2: {
        titile_1: `Eksekusi order cepat`,
        description_1: `Server MetaTrader5 IUX Markets berlokasi di pusat data Equinix LD4 di London. Pusat data NY4, yang disebut sebagai ekosistem keuangan, menjadi tuan rumah bagi lebih dari 600 perusahaan beli dan jual, bursa, tempat perdagangan, data pasar, dan penyedia layanan. Server MetaTrader5 terhubung silang ke Jaringan Komunikasi kami untuk memastikan latensi rendah dan eksekusi cepat dari perdagangan Anda melalui lingkungan perdagangan IUX Markets. Server perdagangan MetaTrader5 dan 5 IUX Markets memiliki latensi kurang dari 1 milidetik ke penyedia VPS utama yang terkolokasi di pusat data NY4 atau melalui jalur dedikasi ke pusat data terdekat. Lingkungan latensi rendah ini ideal untuk perdagangan otomatis dan frekuensi tinggi serta scalping.`,
      },
      section_3: {
        titile_1: `Tidak ada Batasan pada Perdagangan – Scalping Diperbolehkan`,
        description_1: `Platform MetaTrader5 IUX Markets tidak memiliki batasan pada perdagangan, memungkinkan pedagang menempatkan order di antara spread karena tidak ada jarak order minimum dan tingkat pembekuan 0. Ini berarti order termasuk order stop loss dapat ditempatkan sesuai dengan harga pasar sesuai keinginan Anda.
        
        \nPedagang juga dapat melindungi posisi karena tidak ada aturan first in first out (FIFO) dengan IUX Markets. Pedagang tidak membayar margin pada perdagangan yang dilindungi dan menikmati manfaat dari netting margin.`,
        titile_2: `Penetapan Harga Level II – Kedalaman Pasar`,
        description_2: `Kedalaman pasar menunjukkan seluruh rentang harga yang dapat dieksekusi langsung dari penyedia harga kami. Kedalaman pasar menawarkan transparansi penuh dari likuiditas setiap pasangan mata uang dengan menunjukkan volume yang tersedia untuk setiap tingkat harga pada waktu tertentu. Likuiditas tinggi, harga spot asinkron, dan latensi rendah menjamin spread yang paling ketat mungkin.`,
      },
      section_4: {
        titile_1: `64 CFDs & Logam `,
        description_1: `Perdagangkan 64 pasangan CFDs plus 15 indeks ekuitas utama termasuk UK 100, S&P 500, AUS 200, dan Dow Jones Index 24 jam sehari dengan spread 1 poin.`,
        titile_2: `Leverage hingga 1:3000`,
        description_2: `Akun mencapai leverage hingga 1:3000 pada platform MetaTrader5 IUX Markets. Pedagang dapat menggunakan leverage lebih tinggi sesuai gaya perdagangan mereka dan mendapatkan yang terbaik dari strategi perdagangan manual dan otomatis mereka.`,
        titile_3: `Semua Mata Uang Utama Didukung`,
        description_3: `Kami tahu beberapa pedagang lebih suka bertransaksi dalam mata uang lokal mereka. Kami memberi pedagang opsi untuk membuka akun dalam 1 dari 10 mata uang dasar yang didukung: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.`,
      },
    },

    accpro: {
      box_1: {
        titile_1: `Perdagangan CFDs `,
        titile_2: `Perdagangkan lebih dari 90 instrumen`,
        titile_3: `Komisi $0`,
        titile_4: `Eksekusi order cepat`,
        titile_5: `Leverage 1:3000`,
        titile_6: `Spread serendah 0.1 PIPs`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `Mengapa Memilih Akun Pro`,
        description_1: `Jika Anda adalah pedagang berpengalaman dan profesional, akun Pro adalah pilihan yang tepat untuk Anda. Akun Pro dari IUX Markets dilengkapi dengan spread mentah atau bahkan bebas spread mulai dari 0.0 pips. Juga menawarkan kecepatan eksekusi order dengan eksekusi instan untuk sebagian besar instrumen. Akun Pro kami memiliki eksekusi yang sesuai untuk scalper, day trader, dan algotrader.`,
      },
      section_2: {
        titile_1: `Eksekusi order cepat`,
        description_1: `Server MetaTrader5 IUX Markets berlokasi di pusat data Equinix LD4 di London. Pusat data NY4, yang disebut sebagai ekosistem keuangan, menjadi tuan rumah bagi lebih dari 600 perusahaan beli dan jual, bursa, tempat perdagangan, data pasar, dan penyedia layanan. Server MetaTrader5 terhubung silang ke Jaringan Komunikasi kami untuk memastikan latensi rendah dan eksekusi cepat dari perdagangan Anda melalui lingkungan perdagangan IUX Markets. Server perdagangan MetaTrader5 dan 5 IUX Markets memiliki latensi kurang dari 1 milidetik ke penyedia VPS utama yang terkolokasi di pusat data NY4 atau melalui jalur dedikasi ke pusat data terdekat. Lingkungan latensi rendah ini ideal untuk perdagangan otomatis dan frekuensi tinggi serta scalping.`,
      },
      section_3: {
        titile_1: `Tidak ada Batasan pada Perdagangan – Scalping Diperbolehkan`,
        description_1: `Platform MetaTrader5 IUX Markets tidak memiliki batasan pada perdagangan, memungkinkan pedagang menempatkan order di antara spread karena tidak ada jarak order minimum dan tingkat pembekuan 0. Ini berarti order termasuk order stop loss dapat ditempatkan sesuai dengan harga pasar sesuai keinginan Anda.
        
        \nPedagang juga dapat melindungi posisi karena tidak ada aturan first in first out (FIFO) dengan IUX Markets. Pedagang tidak membayar margin pada perdagangan yang dilindungi dan menikmati manfaat dari netting margin.`,
        titile_2: `Penetapan Harga Level II – Kedalaman Pasar`,
        description_2: `Kedalaman pasar menunjukkan seluruh rentang harga yang dapat dieksekusi langsung dari penyedia harga kami. Kedalaman pasar menawarkan transparansi penuh dari likuiditas setiap pasangan mata uang dengan menunjukkan volume yang tersedia untuk setiap tingkat harga pada waktu tertentu. Likuiditas tinggi, harga spot asinkron, dan latensi rendah menjamin spread yang paling ketat mungkin.`,
      },
      section_4: {
        titile_1: `64 CFDs & Logam `,
        description_1: `Perdagangkan 64 pasangan CFDs plus 15 indeks ekuitas utama termasuk UK 100, S&P 500, AUS 200, dan Dow Jones Index 24 jam sehari dengan spread 1 poin.`,
        titile_2: `Leverage hingga 1:3000`,
        description_2: `Akun mencapai leverage hingga 1:3000 pada platform MetaTrader5 IUX Markets. Pedagang dapat menggunakan leverage lebih tinggi sesuai gaya perdagangan mereka dan mendapatkan yang terbaik dari strategi perdagangan manual dan otomatis mereka.`,
        titile_3: `Semua Mata Uang Utama Didukung`,
        description_3: `Kami tahu beberapa pedagang lebih suka bertransaksi dalam mata uang lokal mereka. Kami memberi pedagang opsi untuk membuka akun dalam 1 dari 10 mata uang dasar yang didukung: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.`,
      },
    },

    funding: {
      section_1: {
        title: `Cara Deposit/Withdrawal`,
        titile_1: `Daftar dan masuk`,
        titile_2: `Pilih bank pilihan Anda untuk melakukan deposit dan penarikan`,
        titile_3: `Biaya nol untuk deposit dan penarikan`,
        titile_4: `Deposit dan penarikan cepat dalam beberapa menit`,
      },
      section_2: {
        title: `Opsi Deposit dan Penarikan`,
        description: `Kami menjaga informasi keuangan Anda aman dengan enkripsi. Sehingga Anda dapat membayar secara online dengan percaya diri.`,
        option: `Opsi Pendanaan`,
        hot: `Populer`,
        titile_1: `Kartu Kredit dan Debit`,
        titile_2: `Skrill`,
        titile_3: `Neteller`,
        titile_4: `Pembayaran Thai QR`,
        titile_5: `Perbankan Online`,
      },
      section_3: {
        titile_1: `Proses Pembayaran`,
        description_1: `Untuk pemrosesan yang lebih cepat, kami sarankan untuk mendepositkan dana dari Area Klien Aman. Saldo akan disetorkan secara langsung. Jika Anda mengalami masalah saat mengakses Area Klien Aman, silakan ikuti petunjuk tersebut.`,
        titile_2: `Keamanan Dana`,
        description_2: `Dana klien disimpan di Rekening Kepercayaan Klien Terpisah. Pembayaran elektronik diproses menggunakan teknologi SSL (Secure Socket Layer) dan dienkripsi untuk memastikan keamanan. Semua informasi pembayaran bersifat rahasia dan hanya digunakan untuk tujuan pendanaan akun trading Anda dengan IUX Markets.`,
        titile_3: `Biaya Bank`,
        description_3: `IUX Markets tidak memberikan biaya tambahan untuk deposit dan penarikan. Namun, Anda harus menyadari bahwa biaya dapat timbul pada pembayaran lembaga perbankan internasional. IUX Markets tidak bertanggung jawab atas biaya bank atau biaya penyedia lainnya.`,
        titile_4: `Pembayaran Pihak Ketiga`,
        description_4: `IUX Markets tidak mengizinkan pembayaran dari pihak ketiga. Pastikan bahwa semua deposit ke akun trading Anda berasal dari rekening bank atas nama Anda. Pembayaran dari Rekening Bank/ Kartu Kredit Bersama diperbolehkan jika pemegang akun trading adalah salah satu pihak pada Rekening Bank/ Kartu Kredit tersebut.`,
      },
    },

    withdraw: {
      section_1: {
        title: `Untuk pemrosesan yang lebih cepat, semua pemegang akun sekarang diharuskan mengirimkan permintaan penarikan dari dalam Area Klien Aman mereka.`,
        content: `Jika Anda mengalami masalah saat mengakses Area Klien Aman, silakan ikuti petunjuk tersebut.`,
      },
      section_2: {
        title: `Jika Anda tidak memiliki pengalaman dalam menarik dana, silakan ikuti petunjuk berikut:`,
        title_1: `Waktu batas permintaan penarikan adalah 12:00 AEST/AEDT. Jika Anda mengirimkan permintaan penarikan sebelum waktu ini, akan diproses pada hari yang diterima. Jika Anda mengirimkan penarikan setelah waktu ini, akan diproses pada hari kerja berikutnya.`,
        title_2: `IUX Markets tidak memberikan biaya tambahan untuk deposit dan penarikan. Namun, Anda harus menyadari bahwa biaya dapat timbul pada pembayaran lembaga perbankan internasional. IUX Markets tidak bertanggung jawab atas biaya bank atau biaya penyedia lainnya.`,
        title_3: `Untuk Transfer Kabel Bank Internasional, biaya pemrosesan setara dengan mata uang dikenakan oleh lembaga perbankan kami. Biaya ini akan dikurangkan dari jumlah penarikan Anda. Ini mungkin memakan waktu hingga 14 hari dan melibatkan biaya perantara tambahan tergantung pada yurisdiksi Anda dan bank yang Anda pilih.`,
        title_4: `Penarikan Kartu Kredit/Debit diproses tanpa biaya. Setelah diproses, penarikan Kartu Kredit/Debit mungkin memerlukan waktu 3-5 hari kerja untuk mencapai kartu kredit Anda. Namun, Anda harus menyadari bahwa dalam beberapa kesempatan langka, ini kadang-kadang dapat memakan waktu hingga 10 hari kerja tergantung pada bank.`,
        title_5: `Penarikan Paypal/Neteller/Skrill harus dilakukan dari akun yang sama dari mana dana dikirimkan awalnya. Transaksi ini diproses tanpa biaya dan instan setelah diproses.`,
        title_6: `Jika kartu kredit/debit Anda sudah kadaluwarsa, silakan unggah kartu baru di area klien Anda untuk terus menggunakan layanan deposit dan penarikan tanpa gangguan. Jika nomor kartu baru berbeda dari nomor kartu yang kadaluwarsa, Anda perlu mengajukan surat yang dikeluarkan dari bank penerbit kartu lama yang mengkonfirmasi bahwa kartu baru telah diterbitkan sebagai pengganti kartu lama.`,
        title_7: `Jika kartu kredit/debit Anda hilang, dicuri, rusak, atau dibatalkan, Anda perlu mengajukan surat yang dikeluarkan dari bank penerbit kartu lama yang mengkonfirmasi bahwa kartu lama tidak lagi valid.`,
        title_8: `IUX Markets, atas kebijakannya sendiri, dapat meminta Anda untuk mengirimkan dokumen pendukung (misalnya, bukti setoran untuk pembayaran yang diproses melalui kartu lama atau laporan kartu yang menunjukkan transaksi setoran) sebelum melepaskan dana ke kartu baru.`,
        title_9: `Untuk menarik jumlah lebih dari jumlah yang ditempatkan melalui Verified by Visa/MasterCard Secure Credit/Debit Card, Anda harus menggunakan metode deposit lain yang pernah digunakan atau opsi transfer kawat bank.`,
        title_10: `Jika Anda menarik dana yang telah didepositkan melalui opsi Perbankan Internet Thai ke rekening bank Thailand, Anda perlu mengunggah foto jelas atau salinan pemindaian berwarna dari buku tabungan Anda. Setelah diproses, dana dapat memakan waktu satu hari kerja untuk mencapai akun Anda.`,
        title_11: `Jika Anda menarik dana yang telah didepositkan melalui opsi Perbankan Internet Thai ke rekening bank Thailand, Anda perlu mengunggah foto jelas atau salinan pemindaian berwarna dari buku tabungan Anda. Setelah diproses, dana dapat memakan waktu satu hari kerja untuk mencapai akun Anda.`,
        title_12: `IUX Markets tidak memproses pembayaran kepada pihak ketiga. Pastikan bahwa semua permintaan penarikan dari akun trading Anda masuk ke rekening bank atau sumber atas nama Anda. Pembayaran ke Rekening Bank Bersama/Kartu Kredit diterima jika pemegang akun trading adalah salah satu pihak pada Rekening Bank/Kartu Kredit tersebut.`,
      },
    },
  },
  zh: {
    account_create_demo: `您的演示帳戶是一項無風險的投資，您可以在其中練習交易，測試策略並適應我們的平台。`,
    account_create_success_islamic: `此帐户已自动设置为无掉期状态。请立即保存您的交易密码。出于安全原因，无法通过电子邮件发送。`,
    account_create_success: `不再有一夜之間的費用。您的無交換狀態取決於您的交易活動。為了維持無主交換狀態，您需要大部分時間在一天之內進行交易。`,
    open_an_account: "開帳戶",
    low_risk: "	低風險	",
    high_risk: "	高風險	",
    medium_risk: "	中等風險	",
    standard_account_description:
      "	最受歡迎！對所有類型的交易者的一個很好的帳戶。	",
    standard_bonus_account_description:
      "	我們的即時執行帳戶。沒有交易委員會的出色填補。	",
    raw_account_description: "	最低的原始差價和低固定委員會。	",
    pro_account_description: "	佣金免費的專業帳戶0％。	",
    button: {
      title: `立即存款`,
    },
    cover: {
      accover: {
        title: `账户概况`,
        description: `IUX Markets在所有账户类型中提供低点差工具；适用于所有交易者。只需选择您喜欢的平台，开始交易。`,
      },
      accstd: {
        title: `标准 `,
        description: `通过我们的专有桥接聚合器，混合选择的一流价格供应商，您可以在世界上最受欢迎的交易平台之一上获得低点差优势。`,
      },
      acccnt: {
        title: `分 `,
        description: `初学者账户，投资单位不到100倍，适用于所有交易风格。您可以使用任何您喜欢的交易策略。与标准账户相同的极低点差，让您有更多赚取利润的机会。`,
      },
      accecn: {
        title: `ECN `,
        description: `使用IUX Markets标准账户，保持简单。体验我们低点差定价的所有优势，无需佣金的全包点差。`,
      },
      accraw: {
        title: `Raw `,
        description: `使用IUX Markets Raw账户，保持简单。体验我们原始定价的所有优势，包含点差。`,
      },
      accpro: {
        title: `专业 `,
        description: `专为有经验和专业的交易者设计，适用于任何交易风格，零佣金、低点差，对可开立的订单没有限制。`,
      },
      accbonus: {
        title: `标准+ `,
        description: `使用IUX Markets标准+账户，保持简单。体验我们低点差定价的所有优势，无需佣金的全包点差。`,
      },
      deposit: {
        title: `账户资金`,
        description: `IUX Markets提供超过15种灵活的资金选项，支持10种不同的基础CFDs。使用各种方式即时免费存款。`,
      },
      withdraw: {
        title: `提款`,
        description: `使用IUX Markets标准账户，保持简单。体验我们低定价的所有优势，无需佣金的全包点差。`,
      },
    },

    menu: {
      menu_1: "账户概览",
    },
    section_1: {
      title: "为什么选择我们的标准账户？",
      description:
        "我们提供最低点差。我们的平均EUR/USD点差为0.2点，无佣金。IUX Markets标准账户专为日间交易者、短期交易者和专业交易者设计，整体定价来源高达25个机构级来源。IUX Markets MetaTrader5平台是全球交易者的首选。",
    },
    std: {
      box_1: {
        titile_1: `CFDs交易`,
        titile_2: `低点差定价`,
        titile_3: `点差从0.2点开始`,
        titile_4: `快速执行订单`,
        titile_5: `1:3000杠杆`,
        titile_6: `深度流动性`,
        titile_7: `MetaTrader5`,
      },
      section_1: {
        titile_1: `标准账户提供最低点差。我们的平均EUR/USD点差为0.2点，无佣金。IUX Markets标准账户专为日间交易者、短期交易者和专业交易者设计，具有深度流动性和快速执行。IUX Markets MetaTrader5平台是全球交易者的首选。`,
        titile_2: `我们的MetaTrader5服务器位于伦敦Equinix LD4数据中心，让您更靠近我们的桥接数据中心，有助于消除不必要的延迟。MetaTrader5是全球日间交易者、短期交易者和专业交易者的绝佳选择。`,
      },
      section_2: {
        titile_1: `低点差定价`,
        description_1: `通过提供低点差定价，IUX Markets能够通过MT5平台为客户提供市场定价和最佳交易条件。低点差定价环境使您能够在几乎与世界领先执行场所相同的水平上进行机构级定价交易。可执行流价格（ESP）从IUX Markets的定价提供商发送到我们，无需交易桌、价格操纵或重新报价，即提供给我们的客户。IUX Markets是高交易量交易者、剃刀和提供紧密点差以及最佳可能执行的机器人的最佳CFDs提供商。`,
      },
      section_3: {
        titile_1: `快速执行订单`,
        description_1: `IUX Markets MetaTrader5服务器位于伦敦Equinix LD4数据中心。被称为金融生态系统的NY4数据中心托管了600多家买卖方公司、交易所、交易场所、市场数据和服务提供商。MetaTrader5服务器通过我们的通信网络与交叉连接，以确保低延迟和通过IUX Markets交易环境快速执行您的交易。IUX Markets MetaTrader5和5交易服务器与主要VPS提供商的延迟低于1毫秒，无论是在NY4数据中心内共同设立，还是通过专线连接到附近的数据中心。这种低延迟环境非常适合自动化和高频交易以及剃须。`,
        titile_2: `点差从0.2点开始`,
        description_2: `IUX Markets在全球所有CFDs经纪商中拥有一些最紧密的点差。在MetaTrader5平台上，EURUSD的点差从0.2点开始，全天候平均为0.2点。这是目前全球最紧密的平均EURUSD点差之一。\n我们的定价连接器从多达25个不同的价格提供商那里汇总混合，这意味着我们始终能够为客户找到最佳价格，并在高波动性时保持我们的点差稳定，例如新闻公告时。`,
        titile_3: `交易无限制 – 允许剃须`,
        description_3: `IUX Markets MetaTrader5平台在交易上没有限制。允许交易者在点差之间放置订单，因为没有最小订单距离，冻结水平为0。这意味着订单，包括止损订单，可以放置得离市场价格越近越好。\n由于IUX Markets没有先进先出（FIFO）规则，交易者不需要在对冲交易上支付保证金，并享受保证金清零的好处。`,
        titile_4: `Level II定价 – 市场深度`,
        description_4: `市场深度显示直接来自我们的价格提供商的完整的可执行价格范围。市场深度通过显示每个货币对的每个价格水平的可用数量，在任何给定时间都提供了对每个货币对流动性的完全透明。高流动性、异步现货价格和低延迟保证最紧密的可能点差。`,
      },
      section_4: {
        titile_1: `灵活的资金和提款选择`,
        description_1: `一旦您打开了您的账户，您可以使用任何我们的资金选项进行资金，包括：信用/借记卡、Skrill、PayPal、Neteller、泰国QR码和经纪人之间的转账、存款等。`,
        titile_2: `灵活的手数`,
        description_2: `在交易大小上没有限制或限制，您可以进行小至一微手（0.01）的交易。我们灵活的手数允许您以最小的风险试用该平台，并根据您的账户余额管理交易大小。`,
      },
      section_5: {
        titile_1: `64个CFDs和金属`,
        description_1: `全天候交易64个CFDs货币对以及包括英国100、标普500、澳大利亚200和道琼斯指数在内的15个主要股票指数，点差为1点。`,
        titile_2: `杠杆最高可达1:3000`,
        description_2: `IUX Markets MetaTrader5平台的账户杠杆可达1:3000。交易者可以使用更高的杠杆来适应他们的交易风格，并充分利用他们的手动和自动交易策略。`,
        titile_3: `支持所有主要账户货币`,
        description_3: `我们知道一些交易者更喜欢以本地货币进行交易。我们为交易者提供在10种支持的基本货币中选择开设账户的选项：美元、澳元、欧元、英镑、新加坡元、新西兰元、日元、瑞士法郎、港币、加拿大元。`,
      },
    },

    bonus: {
      box_1: {
        titile_1: `CFDs交易`,
        titile_2: `充值奖金 $35`,
        titile_3: `无佣金`,
        titile_4: `快速执行订单`,
        titile_5: `1:1000杠杆`,
        titile_6: `深度流动性`,
        titile_7: `MetaTrader5`,
      },
      section_1: {
        titile_1: `为什么选择标准+`,
        description_1: `IUX Markets的标准+账户提供无与伦比的执行速度。它包含了一个从0.5点开始的全包括点差，伦敦Equinix LD4数据中心，以及高达25个定价提供商。您将了解到为什么我们的标准+账户可以为您提供一种无与伦比的交易体验，全天候每一天。`,
      },
      section_2: {
        titile_1: `快速执行订单`,
        description_1: `IUX Markets MetaTrader5服务器位于伦敦Equinix LD4数据中心。被称为金融生态系统的NY4数据中心托管了600多家买卖方公司、交易所、交易场所、市场数据和服务提供商。MetaTrader5服务器通过我们的通信网络与交叉连接，以确保低延迟和通过IUX Markets交易环境快速执行您的交易。IUX Markets MetaTrader5和5交易服务器与主要VPS提供商的延迟低于1毫秒，无论是在NY4数据中心内共同设立，还是通过专线连接到附近的数据中心。这种低延迟环境非常适合自动化和高频交易以及剃须。`,
      },
      section_3: {
        titile_1: `交易无限制 – 允许剃须`,
        description_1: `IUX Markets MetaTrader5平台在交易上没有限制。允许交易者在点差之间放置订单，因为没有最小订单距离和冻结水平为0。这意味着订单，包括止损订单，可以放置得离市场价格越近越好。
    
    \n交易者还可以对冲头寸，因为在IUX Markets没有先进先出（FIFO）规则。交易者不需要在对冲交易上支付保证金，并享受保证金清零的好处。`,
        titile_2: `Level II定价 – 市场深度`,
        description_2: `市场深度显示直接来自我们的价格提供商的完整的可执行价格范围。市场深度通过显示每个货币对的每个价格水平的可用数量，在任何给定时间都提供了对每个货币对流动性的完全透明。高流动性、异步现货价格和低延迟保证最紧密的可能点差。`,
      },
      section_4: {
        titile_1: `64个CFDs和金属`,
        description_1: `全天候交易64个CFDs货币对以及包括英国100、标普500、澳大利亚200和道琼斯指数在内的15个主要股票指数，点差为1点。`,
        titile_2: `杠杆最高可达1:1000`,
        description_2: `IUX Markets MetaTrader5平台的账户杠杆可达1:1000。交易者可以使用更高的杠杆来适应他们的交易风格，并充分利用他们的手动和自动交易策略。`,
        titile_3: `支持所有主要账户货币`,
        description_3: `我们知道一些交易者更喜欢以本地货币进行交易。我们为交易者提供在10种支持的基本货币中选择开设账户的选项：美元、澳元、欧元、英镑、新加坡元、新西兰元、日元、瑞士法郎、港币、加拿大元。`,
      },
    },

    accecn: {
      box_1: {
        titile_1: `CFDs交易`,
        titile_2: `交易超过90种工具`,
        titile_3: `佣金 $3.5`,
        titile_4: `快速执行订单`,
        titile_5: `1:3000杠杆`,
        titile_6: `点差低至0.0 PIPs`,
        titile_7: `MetaTrader5`,
      },
      section_1: {
        titile_1: `为什么选择我们的ECN账户？`,
        description_1: `IUX Markets的ECN账户提供无与伦比的执行速度。结合全包括点差从0.0 PIPs开始，伦敦Equinix LD4服务器，高达25个定价提供商的群体，您将了解到为什么我们的标准账户可以为您提供一种无与伦比的交易体验，全天候每一天。`,
      },
      section_2: {
        titile_1: `快速执行订单`,
        description_1: `IUX Markets MetaTrader5服务器位于伦敦Equinix LD4数据中心。被称为金融生态系统的NY4数据中心托管了600多家买卖方公司、交易所、交易场所、市场数据和服务提供商。MetaTrader5服务器通过我们的通信网络与交叉连接，以确保低延迟和通过IUX Markets交易环境快速执行您的交易。IUX Markets MetaTrader5和5交易服务器与主要VPS提供商的延迟低于1毫秒，无论是在NY4数据中心内共同设立，还是通过专线连接到附近的数据中心。这种低延迟环境非常适合自动化和高频交易以及剃须。`,
      },
      section_3: {
        titile_1: `交易无限制 – 允许剃须`,
        description_1: `IUX Markets MetaTrader5平台在交易上没有限制。允许交易者在点差之间放置订单，因为没有最小订单距离和冻结水平为0，这意味着订单，包括止损订单，可以放置得离市场价格越近越好。
    
    \n交易者还可以对冲头寸，因为在IUX Markets没有先进先出（FIFO）规则。交易者不需要在对冲交易上支付保证金，并享受保证金清零的好处。`,
        titile_2: `Level II定价 – 市场深度`,
        description_2: `市场深度显示直接来自我们的价格提供商的完整的可执行价格范围。市场深度通过显示每个货币对的每个价格水平的可用数量，在任何给定时间都提供了对每个货币对流动性的完全透明。高流动性、异步现货价格和低延迟保证最紧密的可能点差。`,
      },
      section_4: {
        titile_1: `64个CFDs和金属`,
        description_1: `全天候交易64个CFDs货币对以及包括英国100、标普500、澳大利亚200和道琼斯指数在内的15个主要股票指数，点差为1点。`,
        titile_2: `杠杆最高可达1:3000`,
        description_2: `IUX Markets MetaTrader5平台的账户杠杆可达1:3000。交易者可以使用更高的杠杆来适应他们的交易风格，并充分利用他们的手动和自动交易策略。`,
        titile_3: `支持所有主要账户货币`,
        description_3: `我们知道一些交易者更喜欢用本地货币交易。我们为交易者提供了在10种支持的基础货币（美元、澳元、欧元、英镑、新加坡元、新西兰元、日元、瑞士法郎、港币、加拿大元）中选择开设账户的选项。`,
      },
    },

    accraw: {
      box_1: {
        titile_1: `CFDs交易`,
        titile_2: `交易超过90种工具`,
        titile_3: `佣金 $6`,
        titile_4: `快速执行订单`,
        titile_5: `1:1000杠杆`,
        titile_6: `点差低至0.0 PIPs`,
        titile_7: `MetaTrader5`,
      },
      section_1: {
        titile_1: `为什么选择Raw账户`,
        description_1: `IUX Markets的Raw账户提供无与伦比的执行速度。结合全包括点差从0.0 PIPs开始，伦敦Equinix LD4数据中心，高达25个定价提供商的群体，您将了解到为什么我们的Raw账户可以为您提供一种无与伦比的交易体验，全天候每一天。`,
      },
      section_2: {
        titile_1: `快速执行订单`,
        description_1: `IUX Markets MetaTrader5服务器位于伦敦Equinix LD4数据中心。被称为金融生态系统的NY4数据中心托管了600多家买卖方公司、交易所、交易场所、市场数据和服务提供商。MetaTrader5服务器通过我们的通信网络与交叉连接，以确保低延迟和通过IUX Markets交易环境快速执行您的交易。IUX Markets MetaTrader5和5交易服务器与主要VPS提供商的延迟低于1毫秒，无论是在NY4数据中心内共同设立，还是通过专线连接到附近的数据中心。这种低延迟环境非常适合自动化和高频交易以及剃须。`,
      },
      section_3: {
        titile_1: `交易无限制 – 允许剃须`,
        description_1: `IUX Markets MetaTrader5平台在交易上没有限制。允许交易者在点差之间放置订单，因为没有最小订单距离和冻结水平为0，这意味着订单，包括止损订单，可以放置得离市场价格越近越好。
    
    \n交易者还可以对冲头寸，因为在IUX Markets没有先进先出（FIFO）规则。交易者不需要在对冲交易上支付保证金，并享受保证金清零的好处。`,
        titile_2: `Level II定价 – 市场深度`,
        description_2: `市场深度显示直接来自我们的价格提供商的完整的可执行价格范围。市场深度通过显示每个货币对的每个价格水平的可用数量，在任何给定时间都提供了对每个货币对流动性的完全透明。高流动性、异步现货价格和低延迟保证最紧密的可能点差。`,
      },
      section_4: {
        titile_1: `64个CFDs和金属`,
        description_1: `全天候交易64个CFDs货币对以及包括英国100、标普500、澳大利亚200和道琼斯指数在内的15个主要股票指数，点差为1点。`,
        titile_2: `杠杆最高可达1:1000`,
        description_2: `IUX Markets MetaTrader5平台的账户杠杆可达1:1000。交易者可以使用更高的杠杆来适应他们的交易风格，并充分利用他们的手动和自动交易策略。`,
        titile_3: `支持所有主要账户货币`,
        description_3: `我们知道一些交易者更喜欢用本地货币交易。我们为交易者提供了在10种支持的基础货币（美元、澳元、欧元、英镑、新加坡元、新西兰元、日元、瑞士法郎、港币、加拿大元）中选择开设账户的选项。`,
      },
    },

    accpro: {
      box_1: {
        titile_1: `CFDs交易`,
        titile_2: `交易超过90种工具`,
        titile_3: `佣金 $0`,
        titile_4: `快速执行订单`,
        titile_5: `1:1000杠杆`,
        titile_6: `点差低至0.1 PIPs`,
        titile_7: `MetaTrader5`,
      },
      section_1: {
        titile_1: `为什么选择Pro账户`,
        description_1: `如果您是经验丰富的专业交易者，Pro账户非常适合您。IUX Markets的Pro账户提供原始点差，甚至从0.0点差开始。对于大多数工具，我们还提供即时执行的订单执行速度。我们的Pro账户具有适合剃须者、日间交易者和算法交易者的执行方式。`,
      },
      section_2: {
        titile_1: `快速执行订单`,
        description_1: `IUX Markets MetaTrader5服务器位于伦敦Equinix LD4数据中心。被称为金融生态系统的NY4数据中心托管了600多家买卖方公司、交易所、交易场所、市场数据和服务提供商。MetaTrader5服务器通过我们的通信网络与交叉连接，以确保低延迟和通过IUX Markets交易环境快速执行您的交易。IUX Markets MetaTrader5和5交易服务器与主要VPS提供商的延迟低于1毫秒，无论是在NY4数据中心内共同设立，还是通过专线连接到附近的数据中心。这种低延迟环境非常适合自动化和高频交易以及剃须。`,
      },
      section_3: {
        titile_1: `交易无限制 – 允许剃须`,
        description_1: `IUX Markets MetaTrader5平台在交易上没有限制。允许交易者在点差之间放置订单，因为没有最小订单距离和冻结水平为0，这意味着订单，包括止损订单，可以放置得离市场价格越近越好。
    
    \n交易者还可以对冲头寸，因为在IUX Markets没有先进先出（FIFO）规则。交易者不需要在对冲交易上支付保证金，并享受保证金清零的好处。`,
        titile_2: `Level II定价 – 市场深度`,
        description_2: `市场深度显示直接来自我们的价格提供商的完整的可执行价格范围。市场深度通过显示每个货币对的每个价格水平的可用数量，在任何给定时间都提供了对每个货币对流动性的完全透明。高流动性、异步现货价格和低延迟保证最紧密的可能点差。`,
      },
      section_4: {
        titile_1: `64个CFDs和金属`,
        description_1: `全天候交易64个CFDs货币对以及包括英国100、标普500、澳大利亚200和道琼斯指数在内的15个主要股票指数，点差为1点。`,
        titile_2: `杠杆最高可达1:1000`,
        description_2: `IUX Markets MetaTrader5平台的账户杠杆可达1:1000。交易者可以使用更高的杠杆来适应他们的交易风格，并充分利用他们的手动和自动交易策略。`,
        titile_3: `支持所有主要账户货币`,
        description_3: `我们知道一些交易者更喜欢用本地货币交易。我们为交易者提供了在10种支持的基础货币（美元、澳元、欧元、英镑、新加坡元、新西兰元、日元、瑞士法郎、港币、加拿大元）中选择开设账户的选项。`,
      },
    },

    funding: {
      section_1: {
        title: `如何存款/取款？`,
        titile_1: `注册并登录`,
        titile_2: `选择您首选的银行进行存款和取款`,
        titile_3: `存款和取款零手续费`,
        titile_4: `几分钟内快速存款和取款`,
      },
      section_2: {
        title: `存款和取款选项`,
        description: `我们通过加密确保您的财务信息安全。因此，您可以放心在线支付。`,
        option: `资金选项`,
        hot: `热门`,
        titile_1: `信用卡和借记卡`,
        titile_2: `Skrill`,
        titile_3: `Neteller`,
        titile_4: `泰国QR支付`,
        titile_5: `网上银行`,
      },
      section_3: {
        titile_1: `支付流程`,
        description_1: `为了更快的处理，我们建议从安全客户区存入资金。余额将实时存入。如果您无法访问安全客户区， 请按照说明操作。`,
        titile_2: `资金安全`,
        description_2: `客户资金存放在专用客户信托账户中。电子支付使用SSL（安全套接字层）技术进行处理，并进行加密以确保安全性。所有支付信息均为机密，仅用于资金注入您的IUX Markets交易账户的目的。`,
        titile_3: `银行费用`,
        description_3: `IUX Markets对存款和取款的任何额外费用不收取任何费用。然而，请注意国际银行机构支付可能产生费用。IUX Markets对银行或提供者费用的任何费用概不负责。`,
        titile_4: `第三方支付`,
        description_4: `IUX Markets不允许第三方支付。请确保所有存入您交易账户的款项来自您名下的银行账户。如果交易账户持有人是联名银行账户/信用卡的一方，则允许从联名银行账户/信用卡进行支付。`,
      },
    },

    withdraw: {
      section_1: {
        title: `为了更快的处理，现在要求所有账户持有人必须从其安全客户区提交取款请求。`,
        content: `如果您在访问安全客户区时遇到任何问题，请按照说明操作。`,
      },
      section_2: {
        title: `如果您没有提款经验，请按照以下说明操作：`,
        title_1: `提款请求截止时间为澳大利亚东部标准时间/澳大利亚东部夏令时间12:00。如果您在此时间之前提交提款申请，将在收到当天进行处理。如果您在此时间之后提交提款申请，将在下一个工作日进行处理。`,
        title_2: `IUX Markets对存款和提款的任何额外费用不收取任何费用。然而，请注意国际银行机构支付可能产生费用。IUX Markets对银行或提供者费用的任何费用概不负责。`,
        title_3: `对于国际银行电汇，我们的银行机构会收取等值货币的处理费。此费用将从您的提款金额中扣除。这可能需要最多14天，并可能产生根据您的司法管辖区和您选择的银行而异的额外中间成本。`,
        title_4: `信用卡/借记卡提款免费处理。一旦处理，信用卡/借记卡提款可能需要3-5个工作日才能到达您的信用卡。然而，请注意，在某些罕见情况下，这可能需要最多10个工作日，具体取决于银行。*请注意，信用卡提款可能并非适用于所有国家。`,
        title_5: `Paypal/Neteller/Skrill提款必须从最初发送款项的同一账户进行。这些交易免费处理，一旦处理即时到账。`,
        title_6: `如果您的信用/借记卡已经过期，请在客户区上传新卡，以便继续使用存款和提款服务，无中断。如果新卡的卡号与过期卡的卡号不同，您需要提交一封由旧卡发卡银行出具的确认新卡已替换旧卡的信函。`,
        title_7: `如果您的信用/借记卡遗失、被盗、损坏或已取消，您需要提交一封由旧卡发卡银行出具的确认旧卡已不再有效的信函。`,
        title_8: `IUX Markets会自行决定是否要求您在向新卡释放资金之前提交支持文件（例如通过旧卡或卡交易显示存款交易的存款收据）。`,
        title_9: `要提取超过通过Verified by Visa/MasterCard Secure信用卡/借记卡放置的金额，您必须使用先前使用过的其他存款方法或银行电汇选项。`,
        title_10: `如果您正在将通过泰国网上银行选项存入的资金提取到泰国银行账户，则需要上传您账户簿的清晰图片或彩色扫描副本。一旦处理，资金可能需要一个工作日才能到达您的账户。`,
        title_11: `如果您正在将通过泰国网上银行选项存入的资金提取到泰国银行账户，则需要上传您账户簿的清晰图片或彩色扫描副本。一旦处理，资金可能需要一个工作日才能到达您的账户。`,
        title_12: `IUX Markets不会向第三方支付款项。请确保您交易账户的所有提款请求都发送到以您的名义持有的银行账户或来源。如果交易账户持有人是银行账户/信用卡上的一方，则接受联名银行账户/信用卡的支付。`,
      },
    },
  },
  vi: {
    account_create_demo: `Tài khoản demo của bạn là một khoản đầu tư không có rủi ro, nơi bạn có thể thực hành giao dịch, chiến lược kiểm tra và thoải mái với nền tảng của chúng tôi.`,
    account_create_success_islamic: `Tài khoản này được tự động đặt thành trạng thái Không Swap. Vui lòng lưu mật khẩu giao dịch của bạn ngay lập tức. Không thể gửi qua email vì lý do bảo mật.`,
    account_create_success: `Không còn phí qua đêm. Trạng thái không hoán đổi của bạn được xác định bởi hoạt động giao dịch của bạn. Để duy trì trạng thái không hoán đổi hoạt động, bạn cần giao dịch chủ yếu trong ngày.`,
    open_an_account: "Mở một tài khoản",
    low_risk: "	Nguy cơ thấp	",
    high_risk: "	Rủi ro cao	",
    medium_risk: "	Rủi ro trung bình	",
    standard_account_description:
      "	Phổ biến nhất! Một tài khoản tuyệt vời cho tất cả các loại thương nhân.	",
    standard_bonus_account_description:
      "	Tài khoản thực thi tức thì của chúng tôi. Tuyệt vời điền vào không có hoa hồng giao dịch.	",
    raw_account_description:
      "	Sự lây lan thô thấp nhất và một khoản hoa hồng cố định thấp.	",
    pro_account_description:
      "	Tài khoản chuyên nghiệp với hoa hồng miễn phí 0%.	",
    button: {
      title: `Nạp tiền ngay tập tức.`,
    },
    cover: {
      accover: {
        title: `Tổng quan tài khảon.`,
        description: `IUX Marketss có đề nghị Spread thấp và nhóm sản phẩm quy mô lớn đã hợp trong tất cả loại tài khoản, phụ hợp với những người mới giao dịch và có kinh nghiệm, chỉ chọn nền tảng giao dịch và hình thức định giá mà bạn cần để bắt đàu.`,
      },
      accstd: {
        title: `Standard `,
        description: `Để bạn được lợi thế với Spread thấp trên nền tảng giao dịch được phổ biến nhất trên thế giới, Với công cụ thu thập dữ liệu Bridge cầu độc quyền của chúng tôi kết hợp việc lựa chọn nhà cung cấp giá số1.`,
      },
      acccnt: {
        title: `Cent `,
        description: `Tài khoản của người mới bắt đầu với đơn vị đầu tư ít hơn 100 lần, phù hợp với mọi phong cách giao dịch. Bạn có thể sử dụng bất kỳ chiến lược giao dịch nào tùy thích. Với mức chênh lệch cực kỳ thấp giống như tài khoản Chuẩn, bạn có nhiều cơ hội giành chiến thắng hơn`,
      },
      accecn: {
        title: `Tài khoản ECN `,
        description: `Dễ giao dịch vói tài khoản IUX Markets Standard cùng với lợi ích và việc định giá Low Spread của chúng tôi  được tập hợp tất cả và không có hoa hồng. `,
      },
      accraw: {
        title: `Tài khoản Raw `,
        description: `Dễ giao dịch vói tài khoản IUX Markets Standard cùng với lợi ích và việc định giá Low Spread của chúng tôi  được tập hợp tất cả và không có hoa hồng. `,
      },
      accpro: {
        title: `Tài khoản Pro `,
        description: `Dễ giao dịch vói tài khoản IUX Markets Standard cùng với lợi ích và việc định giá Low Spread của chúng tôi  được tập hợp tất cả và không có hoa hồng. `,
      },
      accbonus: {
        title: `Standard+ `,
        description: `Dễ giao dịch với tài khoản IUX Markets Standard, cùng với lợi ích và việc định giá Low Spread của chúng tôi  được tập hợp tất cả và không có hoa hồng.`,
      },
      deposit: {
        title: `Nạp tiền vào tài khoản`,
        description: `IUX Markets có tùy chọn nạp tiền sang tài khoản hơn 15 hình thức trong 10 tiền tệ chính khác nhau, nạp tiền ngay lập tức và miễn phí với nhiều phương thức.`,
      },
      withdraw: {
        title: `Việc rút tiền`,
        description: `Dễ giao dịch với tài khoản IUX Markets Standard, cùng với lợi ích và việc định gía Low Spread của chúng tôi  được tập hợp tất cả và không có hoa hồng.`,
      },
    },
    menu: {
      menu_1: "Tổng quan tài khảon",
    },
    section_1: {
      title: "Tại sao phải chọn tài khoản  Standard của chúng tôi?",
      description:
        "Chúng tôi đưa ra Spread EUR/USD thấp nhất mà có thể, giá trung bình của chúng tôi là 0.2 PIPs không có hoa hồng, với nguồn định giá nhiều hơn 25 tổ chức, nguồn tài khoản IUX Markets Standard đã tạo đối với người giao dịch trong ngày, nhà giao dịch và người chuyên gia. Nền tảng IUX Markets  Meta Trader5 là tùy chọn ưu tiên cho những người giao dịch trong ngày và các nhà giao dịch toàn thế giới...",
    },
    std: {
      box_1: {
        titile_1: `Giao dịch CFDs`,
        titile_2: `Giá Spread thấp`,
        titile_3: `Băt đầu Spread tại 0.2 PIPs`,
        titile_4: ` Hành động đơn đặt hàng nhanh chóng`,
        titile_5: `1:3000 Leverage`,
        titile_6: `Thanh khoản`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `Tài khoản Standard cung cấp mức Mức chênh lệch thấp nhất. Mức chênh lệch EUR/USD trung bình của chúng tôi là 0,2 PIPs, không có hoa hồng với việc giao dịch và không có . 
        Thị trường thanh khảon cao và thực hiện nhanh chóng. Tài khoản IUX Markets Low Spread ,tạo lên để nhà giao dịch trong ngày và chuyên gia tư vấn.`,
        titile_2: `server  Meta Trader5 Low Spread của chúng tôi được đặt tại trung tâm dữ liệu Equinix LD4
        Ở London làm cho bạn gần hơn với trung tâm dữ liệu Bridge của chúng tôi giúp bỏ độ trễ không cần thiết. Nền tảng IUX Markets   
         Meta Trader5 là tùy chọn tốt nhất đối với những người giao dịch trong ngày và nhà giao dịch toàn thế giới.`,
      },
      section_2: {
        titile_1: `Mức chênh lệnh thấp`,
        description_1: `IUX Markets có thể cung cấp giá thị trường và điều kiện giao dịch tốt nhất qua nền tảng  MT5 để phục vụ khách hàng với mức chênh lệnh thấp \ Môi trường sự định giá chênh lệnh thấp có thể giúup bạn giao dịch thay đổi định giá ngang với các địa điểm thực hiện đơn hàng dẫn đầu thế giới. Giá phát trực tuyến (ESP) đã thực hiện sẽ được gửi từ nhà cung cấp định giá IUX Markets và cung cấp cho khách hàng của chúng tôi mà không có bàn giao dịch, thay đổi giá hoặc bảng bóa giá. IUX Markets là nhà cung cấp CFDs là sự lựa chọn đối với nhiều nhà giao dịch bao gồm đến scalper Và người sử dụng robots mà cần chênh lệnh thấp và việc thực hiện tốt nhất mà có thể.`,
      },
      section_3: {
        titile_1: `Thực hiện đơn đặt hàng nhanh chóng`,
        description_1: `server IUX Markets  Meta Trader5 nằm trong trung tâm dữ liệu Equinix LD4 ở London, Trung tâm dữ liệu NY4 là hệ thống sinh thái tài chính, là nơi đặt công ty về việc giao dịch và trao đổi. Có địa điểm giao dịch dữ liệu thị trường và nhà cung cấp nhiều hơn 600 nơi. server  Meta Trader5, Kết nối với mạng truyền thông của chúng tôi để đảm bảo độ trễ thấp và thực hiện nhanh về việc giao dịch của bạn qua môi trường giao dịch IUX Markets. Server giao gịch IUX Markets  Meta Trader5, có độ trễ ít hơn 1 mili giây sang nhà cung cấp VPS chính. Mặc dù nằm trong trung tâm dữ liệu NY4 hoặc qua dòng đặt biệt sang trung tâm dữ liệu.`,
        titile_2: `Bắt đầu mức chênh lệnh 0.2 PIPs`,
        description_2: `IUX Markets có mức chênh lệnh thấp nhất của nhà môi giới CFDs toàn thế giới, chênh lệnh bắt đầu từ 0.2 PIPs trên nền tảng  Meta Trader5 và mức chênh lệnh trung bình của EURUSD là 0.2 PIPs cả 24/7. Hiện tại, giá trung bình  EUR/USD là một trong những cặp tiền mà mức chênh lệch thấp nhất trên thế giới. \ Công cụ kết nối định giá của chúng tôi tập hợp tối đa 25 nhà cung cấp dịch vụ định giá khác nhau. Điều này có ý nghĩa là chúng tôi có thể cung cấp giá tốt nhất cho khách hàng của chúng tôi và giữ chênh lệnh của chúng tôi thấp đặc biệt là trong thời gian có sự biến động cao như thông báo tin tức.`,
        titile_3: `không hạn chế về việc giao dịch - cho phép Scalping`,
        description_3: `Nền tảng IUX Markets  Meta Trader5, không có hạn chế về việc giao dịch,  Chúng tôi có điều kiện giao dịch tốt nhất đối với việc giao dịch làm lợi nhuận trong thời gian ngắn (Scalping) và hỗ trợ giao gịch có tần suất cao, cho phép các nhà giao dịch đặt đơn hàng giữa mức chênh lệch, bởi vì không có khoảng cách tối thiểu và  mức cố định là 0, có ý nghĩa là lệnh đơn đặt hàng và lệnh cắt lỗ có thể đặt gần với giá thị trường theo bạn mong muốn, nhà giao dịch có thể đảm bảo rủi ro khi đầu tư (hedge positions) vì không có quy tắc nhập trước, xuất trước (FIFO) với IUX Markets. Nhà giao gịch không cần trả tiền bảo hiểm về việc giao dịch đã ngăn ngừa rủi ro và được nhận lợi ích từ việc làm lợi nhuận.`,
        titile_4: `Mức giá II - Độ sâu của thị trường.`,
        description_4: `Mức độ sâu thị trường hiển thị tất cả các mức giá được thực hiện trực tiếp từ các nhà cung cấp giá của chúng tôi, độ sân thị trường làm cho có sự minh bạch hoàn toàn của tính thanh khoản từng cặp tiền tệ và  hiển thị khối lượng mỗi mức giá tại bất kỳ khoảng thời gian nào đó,tính thanh khoản cao như Asynchronous và thời gian phản hồi thấp, đảm bảo chênh lệnh thấp nhất.`,
      },
      section_4: {
        titile_1: `Nhiều tùy chọn nạp tiền`,
        description_1: `Khi bạn mở tài khoản rồi có thể nạp tiền bằng cách sử dụng các tùy chọn nạp tiền của chúng tôi như: thẻ tín dụng / thẻ ghi nợ, Skrill, PayPal, Neteller, mã QR Thai và nạp-rút tiền tù một nhà môi giới sang một nhà môi giới.`,
        titile_2: `Điều Chỉnh Kích Thước Linh Hoạt`,
        description_2: `Không có hạn chế hoặc hạn chế về quy mô giao dịch, bạn có thể giao dịch với quy mô lô nhỏ cho đến một lô siêu nhỏ (0.01) Kích thước lô linh hoạt của chúng tôi giúp bạn dùng thử nền tảng có rủi ro tối thiểu và quản lý quy mô giao dịch của bạn để phù hợp với số dư tài khoản của bạn`,
      },
      section_5: {
        titile_1: `64 Cặp tiền tệ và kim loại + 15 CFDs`,
        description_1: `Giao dịch 64 cặp tiền tệ cộng với 15 chỉ số chứng khoán chính gồm với UK 100, S & P / AUS 200 và Dow Jones Index cả 24 tiếng mà có chêng lệnh chỉ 1 điểm`,
        titile_2: `Leverage tối da 1:3000`,
        description_2: `Tài khoản có Leverage tối đa 1: 2000 trên nền tảng IUX Markets  Meta Trader5. Nhà giao dịch có thể dùng Leverage mức cao hơn để phụ hợp với phong  cách giao dịch của họ và sử dụng lợi ích từ chiến lược giao dịch của bản thân và giao dịch tự động tốt nhất.`,
        titile_3: `Hỗ trợ tiền tệ chính của tất cả tài khoản`,
        description_3: `Chúng tôi biết rằng một số nhà giao dịch muốn giao dịch bằng nội tệ của họ, chúng tôi cung cấp cho nhà đầu tư tùy chọn mở tài khoản bằng 1 trong 10 loại tiền tệ chính được hỗ trợ: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD`,
      },
    },
    bonus: {
      box_1: {
        titile_1: `Giao dịch CFDs`,
        titile_2: `Tiền thưởng 35 $`,
        titile_3: `Không có hoa hồng`,
        titile_4: `Thực hiện đơn đặt hàng nhanh chóng`,
        titile_5: `1:1000 Leverage`,
        titile_6: `Thanh khoản cao`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `Tại sao phải chọn tài khoản tiền thưởng tiêu chuẩn của chúng tôi?`,
        description_1: `Tài khoản tiền thưởng tiêu chuẩn từ IUX Markets, mang lại tốc độ thực hiện mà không có đối thủ canh tranh, cùng với chênh lệnh bao gồm tất cả, bắt đầu từ 1 PIPs Server Equinix LD4. tại London có nhiều hơn 25 nhóm nhà cung cấp phục vụ định giá và bạn sẽ hiểu tại sao tài khoản tiêu chuẩn của chúng tôi có thể cung cấp cho bạn trải nghiệm giao dịch vượt trội mỗi ngày.`,
      },
      section_2: {
        titile_1: `Thực hiện đơn đặt hàng nhanh chóng.`,
        description_1: `Server IUX Markets  Meta Trader5 nằm trong trung tâm dữ liệu Equinix LD4 tại London trung tâm dữ liệu NY4 là hệ sinh thái tài chính và địa điểm của công ty về việc thay đổi giao dịch, có hơn 600 địa điểm giao dịch dữ liệu thị trường và nhà cung cấp dịch vụ, Server  Meta Trader5, Kết nối với mạng truyền thông của chúng tôi để đảm bảo độ trễ thấp và thực hiện nhanh về việc giao dịch của bạn qua môi trường giao dịch IUX Markets. 

        \Server giao gịch IUX Markets  Meta Trader5, có độ trễ ít hơn 1 mili giây sang nhà cung cấp VPS chính. Mặc dù nằm trong trung tâm dữ liệu NY4 hoặc qua dòng đặt biệt sang trung tâm dữ liệu.`,
      },
      section_3: {
        titile_1: `Không có chế hạn giao dịch - cho phép thực hiện scalping`,
        description_1: `Nền tảng IUX Markets  Meta Trader5, không có hạn chế về việc giao dịch,  Chúng tôi có điều kiện giao dịch tốt nhất đối với việc giao dịch làm lợi nhuận trong thời gian ngắn (Scalping) và hỗ trợ giao gịch có tần suất cao. 
       cho phép các nhà giao dịch đặt đơn hàng giữa mức chênh lệch, bởi vì không có khoảng cách tối thiểu và  mức cố định là 0, có ý nghĩa là lệnh đơn đặt hàng và lệnh cắt lỗ có thể đặt gần với giá thị trường theo bạn mong muốn.
          
        \Nhà giao dịch có thể đảm bảo rủi ro khi đầu tư (hedge positions) vì không có quy tắc nhập trước, xuất trước (FIFO) với IUX Markets. Nhà giao gịch không cần trả tiền bảo hiểm về việc giao dịch đã ngăn ngừa rủi ro và được nhận lợi ích từ việc làm lợi nhuận. `,
        titile_2: `Mức giá II - độ sâu thị trường.`,
        description_2: `Độ sâu thị trường hiển thị tất cả các mức giá được thực hiện trực tiếp từ các nhà cung cấp giá của chúng tôi, độ sân thị trường làm cho có sự minh bạch hoàn toàn của tính thanh khoản từng cặp tiền tệ và  hiển thị khối lượng mỗi mức giá tại bất kỳ khoảng thời gian nào đó,tính thanh khoản cao như Asynchronous và thời gian phản hồi thấp, đảm bảo chênh lệnh thấp nhất.`,
      },
      section_4: {
        titile_1: ` 64 Cặp tiền tệ và kim loại + 15 CFDs`,
        description_1: `Giao dịch 64 cặp tiền tệ cộng với 15 chỉ số chứng khoán chính gồm với UK 100, S&P 500, AUS 200 và Dow Jones Index cả 24 tiếng mà có chêng lệnh chỉ 1 điểm`,
        titile_2: `Leverage tối da 1:1000`,
        description_2: `Tài khoản có Leverage tối đa 1:1000 trên nền tảng IUX Markets  Meta Trader5, Nhà giao dịch có thể dùng Leverage mức cao hơn để phụ hợp với phong cách giao dịch của họ và sử dụng lợi ích từ chiến lược giao dịch của bản thân và giao dịch tự động tốt nhất`,
        titile_3: `Hỗ trợ tiền tệ chính của tất cả tài khoản`,
        description_3: `Chúng tôi biết rằng một số nhà giao dịch muốn giao dịch bằng nội tệ của họ, chúng tôi cung cấp cho nhà đầu tư tùy chọn mở tài khoản bằng 1 trong 10 loại tiền tệ chính được hỗ trợ: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD`,
      },
    },
    accecn: {
      box_1: {
        titile_1: `CFDs Trading `,
        titile_2: `Giao dịch công cụ tài chính nhiều hoen 90 loại`,
        titile_3: `Giá hoa hồng $3.5`,
        titile_4: `Thực hiện đơn đặt hàng nhanh chóng.`,
        titile_5: `Leverage 1:3000 `,
        titile_6: `Mức chênh lệch thấp nhất tại 0.0 PIPs`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `Tại sao phải chọn tài khoản ECN của chúng tôi?`,
        description_1: `Tài khoản ECN từ IUX Markets, mang lại tốc độ thực hiện mà không có đối thủ canh tranh, cùng với chênh lệnh bao gồm tất cả, bắt đầu từ 1 PIPs Server Equinix LD4. tại London có nhiều hơn 25 nhóm nhà cung cấp phục vụ định giá và bạn sẽ hiểu tại sao tài khoản tiêu chuẩn của chúng tôi có thể cung cấp cho bạn trải nghiệm giao dịch vượt trội mỗi ngày`,
      },
      section_2: {
        titile_1: `Thực hiện đơn đặt hàng nhanh chóng.`,
        description_1: `Server IUX Markets  Meta Trader5 nằm vào trung tâm dữ liệu Equinix LD4 tại London trung tâm dữ liệu NY4  là hệ sinh thái tài chính và địa điểm của công ty về việc thay đổi giao dịch, có hơn 600 địa điểm giao dịch dữ liệu thị trường và nhà cung cấp dịch vụ, Server  Meta Trader5, Kết nối với mạng truyền thông của chúng tôi để đảm bảo độ trễ thấp và thực hiện nhanh về việc giao dịch của bạn qua môi trường giao dịch của IUX Markets.
                                                        
        \Server giao dịch IUX Markets  Meta Trader5 và có độ trễ ít hơn 1 mili giây sang nhà cung cấp VPS chính. Mặc dù nằm trong trung tâm dữ liệu NY4 hoặc qua dòng đặt biệt sang trung tâm dữ liệu`,
      },
      section_3: {
        titile_1: `Không có hạn chế giao dịch - cho phép thực hiện scalping`,
        description_1: `Nền tảng IUX Markets  Meta Trader5 không có hạn chế giao dịch, chúng tôi có điều kiện giao dịch tổt nhất đối với việc giao dịch làm lợi nhuận trong thời gian ngắn (Scalping) và hỗ trợ giao dịch tần suất cao.
        cho phép các nhà giao dịch đặt đơn hàng giữa mức chênh lệch, bởi vì không có khoảng cách tối thiểu và  mức cố định là 0, có ý nghĩa là lệnh đơn đặt hàng và lệnh cắt lỗ có thể đặt gần với giá thị trường theo bạn mong muốn.
        
        \Nhà giao dịch có thể đảm bảo rủi ro khi đầu tư (hedge positions) vì không có quy tắc nhập trước, xuất trước (FIFO) với IUX Markets. Nhà giao dịch không cần trả tiền bảo hiểm về việc giao dịch đã ngăn ngừa rủi ro và được nhận lợi ích từ việc làm lợi nhuận.`,

        titile_2: `Mức giá II - độ sâu thị trường.`,
        description_2: `Độ sâu thị trường hiển thị tất cả các mức giá được thực hiện trực tiếp từ các nhà cung cấp giá của chúng tôi, độ sân thị trường làm cho có sự minh bạch hoàn toàn của tính thanh khoản từng cặp tiền tệ và  hiển thị khối lượng mỗi mức giá tại bất kỳ khoảng thời gian nào đó,tính thanh khoản cao như Asynchronous và thời gian phản hồi thấp, đảm bảo chênh lệnh thấp nhất`,
      },
      section_4: {
        titile_1: `64 cặp tiền tệ và kim loại + 15 CFDs`,
        description_1: `Giao dịch 64 cặp tiền tệ cộng với 15 chỉ số chứng khoán chính gồm với UK 100, S & P / AUS 200 và Dow Jones Index cả 24 tiếng mà có chêng lệnh chỉ 1 điểm    `,
        titile_2: `Leverage tối đa đến 1:3000`,
        description_2: `Tài khoản có Leverage tối đa đến 1: 3000 trên nền tảng IUX Markets  Meta Trader5 Nhà giao dịch có thể dùng Leverage mức cao hơn để phụ hợp với phong cách giao dịch của họ và sử dụng lợi ích từ chiến lược giao dịch của bản thân và giao dịch tự động tốt nhất`,
        titile_3: `Hỗ trợ tiền tệ chính của tất cả tài khoản`,
        description_3: `Chúng tôi biết rằng một số nhà giao dịch muốn giao dịch bằng nội tệ của họ, chúng tôi cung cấp cho nhà đầu tư tùy chọn mở tài khoản bằng 1 trong 10 loại tiền tệ chính được hỗ trợ: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.`,
      },
    },
    accraw: {
      box_1: {
        titile_1: `CFDs Trading `,
        titile_2: `Giao dịch công cụ tài chính nhiều hoen 90 loại`,
        titile_3: `Giá hoa hồng $6`,
        titile_4: `Thực hiện đơn đặt hàng nhanh chóng.`,
        titile_5: `Leverage 1:3000 `,
        titile_6: `Mức chênh lệch thấp nhất tại 0.0 PIPs`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `Tại sao phải chọn tài khoản Raw của chúng tôi?`,
        description_1: `Tài khoản Raw từ IUX Markets, mang lại tốc độ thực hiện mà không có đối thủ canh tranh, cùng với chênh lệnh bao gồm tất cả, bắt đầu từ 1 PIPs Server Equinix LD4. tại London có nhiều hơn 25 nhóm nhà cung cấp phục vụ định giá và bạn sẽ hiểu tại sao tài khoản tiêu chuẩn của chúng tôi có thể cung cấp cho bạn trải nghiệm giao dịch vượt trội mỗi ngày`,
      },
      section_2: {
        titile_1: `Thực hiện đơn đặt hàng nhanh chóng.`,
        description_1: `Server IUX Markets  Meta Trader5 nằm vào trung tâm dữ liệu Equinix LD4 tại London trung tâm dữ liệu NY4  là hệ sinh thái tài chính và địa điểm của công ty về việc thay đổi giao dịch, có hơn 600 địa điểm giao dịch dữ liệu thị trường và nhà cung cấp dịch vụ, Server  Meta Trader5, Kết nối với mạng truyền thông của chúng tôi để đảm bảo độ trễ thấp và thực hiện nhanh về việc giao dịch của bạn qua môi trường giao dịch của IUX Markets.
                                                        
        \Server giao dịch IUX Markets  Meta Trader5 và có độ trễ ít hơn 1 mili giây sang nhà cung cấp VPS chính. Mặc dù nằm trong trung tâm dữ liệu NY4 hoặc qua dòng đặt biệt sang trung tâm dữ liệu`,
      },
      section_3: {
        titile_1: `Không có hạn chế giao dịch - cho phép thực hiện scalping`,
        description_1: `Nền tảng IUX Markets  Meta Trader5 không có hạn chế giao dịch, chúng tôi có điều kiện giao dịch tổt nhất đối với việc giao dịch làm lợi nhuận trong thời gian ngắn (Scalping) và hỗ trợ giao dịch tần suất cao.
        cho phép các nhà giao dịch đặt đơn hàng giữa mức chênh lệch, bởi vì không có khoảng cách tối thiểu và  mức cố định là 0, có ý nghĩa là lệnh đơn đặt hàng và lệnh cắt lỗ có thể đặt gần với giá thị trường theo bạn mong muốn.
        
        \Nhà giao dịch có thể đảm bảo rủi ro khi đầu tư (hedge positions) vì không có quy tắc nhập trước, xuất trước (FIFO) với IUX Markets. Nhà giao dịch không cần trả tiền bảo hiểm về việc giao dịch đã ngăn ngừa rủi ro và được nhận lợi ích từ việc làm lợi nhuận.`,

        titile_2: `Mức giá II - độ sâu thị trường.`,
        description_2: `Độ sâu thị trường hiển thị tất cả các mức giá được thực hiện trực tiếp từ các nhà cung cấp giá của chúng tôi, độ sân thị trường làm cho có sự minh bạch hoàn toàn của tính thanh khoản từng cặp tiền tệ và  hiển thị khối lượng mỗi mức giá tại bất kỳ khoảng thời gian nào đó,tính thanh khoản cao như Asynchronous và thời gian phản hồi thấp, đảm bảo chênh lệnh thấp nhất`,
      },
      section_4: {
        titile_1: `64 cặp tiền tệ và kim loại + 15 CFDs`,
        description_1: `Giao dịch 64 cặp tiền tệ cộng với 15 chỉ số chứng khoán chính gồm với UK 100, S & P / AUS 200 và Dow Jones Index cả 24 tiếng mà có chêng lệnh chỉ 1 điểm    `,
        titile_2: `Leverage tối đa đến 1:3000`,
        description_2: `Tài khoản có Leverage tối đa đến 1: 3000 trên nền tảng IUX Markets  Meta Trader5 Nhà giao dịch có thể dùng Leverage mức cao hơn để phụ hợp với phong cách giao dịch của họ và sử dụng lợi ích từ chiến lược giao dịch của bản thân và giao dịch tự động tốt nhất`,
        titile_3: `Hỗ trợ tiền tệ chính của tất cả tài khoản`,
        description_3: `Chúng tôi biết rằng một số nhà giao dịch muốn giao dịch bằng nội tệ của họ, chúng tôi cung cấp cho nhà đầu tư tùy chọn mở tài khoản bằng 1 trong 10 loại tiền tệ chính được hỗ trợ: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.`,
      },
    },
    accpro: {
      box_1: {
        titile_1: `CFDs Trading `,
        titile_2: `Giao dịch công cụ tài chính nhiều hoen 90 loại`,
        titile_3: `Giá hoa hồng $0`,
        titile_4: `Thực hiện đơn đặt hàng nhanh chóng.`,
        titile_5: `Leverage 1:3000 `,
        titile_6: `Mức chênh lệch thấp nhất tại 0.1 PIPs`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `Tại sao phải chọn tài khoản Pro của chúng tôi?`,
        description_1: `Tài khoản Pro từ IUX Markets, mang lại tốc độ thực hiện mà không có đối thủ canh tranh, cùng với chênh lệnh bao gồm tất cả, bắt đầu từ 1 PIPs Server Equinix LD4. tại London có nhiều hơn 25 nhóm nhà cung cấp phục vụ định giá và bạn sẽ hiểu tại sao tài khoản tiêu chuẩn của chúng tôi có thể cung cấp cho bạn trải nghiệm giao dịch vượt trội mỗi ngày`,
      },
      section_2: {
        titile_1: `Thực hiện đơn đặt hàng nhanh chóng.`,
        description_1: `Server IUX Markets  Meta Trader5 nằm vào trung tâm dữ liệu Equinix LD4 tại London trung tâm dữ liệu NY4  là hệ sinh thái tài chính và địa điểm của công ty về việc thay đổi giao dịch, có hơn 600 địa điểm giao dịch dữ liệu thị trường và nhà cung cấp dịch vụ, Server  Meta Trader5, Kết nối với mạng truyền thông của chúng tôi để đảm bảo độ trễ thấp và thực hiện nhanh về việc giao dịch của bạn qua môi trường giao dịch của IUX Markets.
                                                        
        \Server giao dịch IUX Markets  Meta Trader5 và có độ trễ ít hơn 1 mili giây sang nhà cung cấp VPS chính. Mặc dù nằm trong trung tâm dữ liệu NY4 hoặc qua dòng đặt biệt sang trung tâm dữ liệu`,
      },
      section_3: {
        titile_1: `Không có hạn chế giao dịch - cho phép thực hiện scalping`,
        description_1: `Nền tảng IUX Markets  Meta Trader5 không có hạn chế giao dịch, chúng tôi có điều kiện giao dịch tổt nhất đối với việc giao dịch làm lợi nhuận trong thời gian ngắn (Scalping) và hỗ trợ giao dịch tần suất cao.
        cho phép các nhà giao dịch đặt đơn hàng giữa mức chênh lệch, bởi vì không có khoảng cách tối thiểu và  mức cố định là 0, có ý nghĩa là lệnh đơn đặt hàng và lệnh cắt lỗ có thể đặt gần với giá thị trường theo bạn mong muốn.
        
        \Nhà giao dịch có thể đảm bảo rủi ro khi đầu tư (hedge positions) vì không có quy tắc nhập trước, xuất trước (FIFO) với IUX Markets. Nhà giao dịch không cần trả tiền bảo hiểm về việc giao dịch đã ngăn ngừa rủi ro và được nhận lợi ích từ việc làm lợi nhuận.`,

        titile_2: `Mức giá II - độ sâu thị trường.`,
        description_2: `Độ sâu thị trường hiển thị tất cả các mức giá được thực hiện trực tiếp từ các nhà cung cấp giá của chúng tôi, độ sân thị trường làm cho có sự minh bạch hoàn toàn của tính thanh khoản từng cặp tiền tệ và  hiển thị khối lượng mỗi mức giá tại bất kỳ khoảng thời gian nào đó,tính thanh khoản cao như Asynchronous và thời gian phản hồi thấp, đảm bảo chênh lệnh thấp nhất`,
      },
      section_4: {
        titile_1: `64 cặp tiền tệ và kim loại + 15 CFDs`,
        description_1: `Giao dịch 64 cặp tiền tệ cộng với 15 chỉ số chứng khoán chính gồm với UK 100, S & P / AUS 200 và Dow Jones Index cả 24 tiếng mà có chêng lệnh chỉ 1 điểm    `,
        titile_2: `Leverage tối đa đến 1:3000`,
        description_2: `Tài khoản có Leverage tối đa đến 1: 3000 trên nền tảng IUX Markets  Meta Trader5 Nhà giao dịch có thể dùng Leverage mức cao hơn để phụ hợp với phong cách giao dịch của họ và sử dụng lợi ích từ chiến lược giao dịch của bản thân và giao dịch tự động tốt nhất`,
        titile_3: `Hỗ trợ tiền tệ chính của tất cả tài khoản`,
        description_3: `Chúng tôi biết rằng một số nhà giao dịch muốn giao dịch bằng nội tệ của họ, chúng tôi cung cấp cho nhà đầu tư tùy chọn mở tài khoản bằng 1 trong 10 loại tiền tệ chính được hỗ trợ: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.`,
      },
    },
    funding: {
      section_1: {
        title: `Cách nạp/rút tiền?`,
        titile_1: `Đăng ký hoặc đăng nhập hệ thống.`,
        titile_2: `Chọn ngân hàng của bạn để dùng và thực hiện nạp/rút tiền.`,
        titile_3: `Miễn phí nạp/rút tiền.`,
        titile_4: `Rút tiền nhanh chóng trong vài phút`,
      },
      section_2: {
        title: `Tùy chọn nạp/rút tiền`,
        description: ` Chúng tôi giữ gìn thông tin tài chính của bạn an toàn bằng mã hóa. Vì vậy, bạn có thể thanh toán trực tuyến một cách tự tin.`,
        option: `Tùy chọn nạp tiền`,
        hot: `Được nhận phổ biến`,
        titile_1: `thẻ tín dụng/thẻ ghi nợ`,
        titile_2: `Skrill`,
        titile_3: `Neteller`,
        titile_4: `Thai QR Payment`,
        titile_5: `Ngân hàng trực tuyến`,
      },
      section_3: {
        titile_1: `Thủ tục thanh toán.`,
        description_1: `Để thực hiện nhanh chóng hơn, chúng tôi khuyên chủ tài khoản gửi tiền vào tài khoản giao dịch từ bên trong Secure Client Area.
        do Secure Client Area của bạn sẽ giúp bạn có thể nạp tiền vào tài khoản trong thời gian thực bằng thẻ tín dụng và Skrill.
        
        \Nếu bạn vẫn không có quyền truy cập vào Khu vực Khách hàng An toàn của chúng tôi Vui lòng làm theo hướng dẫn sử dụng`,
        titile_2: `Sự an toàn vốn đầu tư`,
        description_2: `Khi bạn nạp tiền vào tài khoản giao dịch của bạn, vốn đầu tư của khách hàng sẽ giữ gìn trong tài khoản ủy thác tại National Australia Bank (NAB) và Westpac Banking Corporation (Westpac) cả hai ngân hàng là ngân hàng Úc AA thanh toán điện tử được xử lý bằng công nghệ SSL (Secure Socket Layer) có mã hóa để sự an toàn,  Tất cả thông tin thanh toán sẽ được bảo mật và chỉ được sử dụng cho mục đích nạp tiền vào tài khoản giao dịch của bạn với IUX Markets.`,
        titile_3: `Phí ngân hàng`,
        description_3: `IUX Markets Không có phí đối với nạp hoặc rút tiền. Tuy nhiên, bạn nên biết rằng bạn có thể bị tính phí khi mang tiền vào hoặc ra một số ngân hàng quốc tế. IUX Markets không chịu trách nhiệm cho các khoản phí ngân hàng đó.                           `,
        titile_4: `Trả tiền với bên thứ ba`,
        description_4: `IUX Markets không chấp nhận vời việc trả tiền bên thứ ba, Hãy kiểm tra để chắc chắn là Hãy đảm bảo rằng tất cả các khoản tiền gửi đến tài khoản giao dịch của bạn là từ một ngân hàng dưới tên bạn. Thanh toán từ tài khoản ngân hàng chung đứng tên/thẻ tín dụng chung, điều này có thể thực hiện nếu chủ tài khoản giao dịch được liệt kê trong nhóm ngân hàng/chủ thẻ tín dụng.`,
      },
    },
    withdraw: {
      section_1: {
        title: `Để thực hiện nhanh chống, chủ tài khoản phải gửi yêu cầu rút tiền từ nôi bộ Secure Client Area`,
        content: `Nến bạn không thể vào Secure Client Area, vui lòng làm theo hướng dẫn truy cập`,
      },
      section_2: {
        title: `Nếu bạn chưa thực hiện rút tiền trong quá khứ, vui lòng biết rằng:`,
        title_1: `Yêu cầu rút tiền sẽ bị cắt thời gian là 12:00 AEST/AEDT. Nếu yêu cầu rút tiền của bạn được gửi trước thời gian này, sẽ được xử lý vào ngày nhận được.
        Nếu việc rút tiền của bạn được gửi sau thời gian này sẽ được xử lý vào ngày làm việc tiếp theo.`,
        title_2: `IUX Markets Không có phí bổ sung được tính cho tiền gửi hoặc rút tiền. Tuy nhiên, bạn nên biết rằng bạn có thể phải trả phí cho các khoản thanh toán đến và từ một số tổ chức tài chính quốc tế. IUX Markets không chịu trách nhiệm về các khoản phí ngân hàng đó.`,
        title_3: `Đối với chuyển khoản ngân hàng quốc tế, tổ chức tài chính của chúng tôi sẽ tính phí xử lý 20 AUD hoặc số tiền tương đương. Chúng tôi sẽ khấu trừ khoản phí này từ số tiền rút của bạn,Tuy nhiên, chúng có thể khác nhau , phụ thuộc vào khu vực tài phán của bạn và ngân hàng bạn sử dụng và có thể mất đến 14 ngày và phải chịu thêm phí trung gian.`,
        title_4: `Rút tiền bằng thẻ tín dụng/thẻ ghi nợ sẽ được thực hiện miễn phí, khi thực hiện rồi rút ​tiền từ thẻ tín dụng/thẻ ghi nợ, có thể dùng thời gian 3-5 ngày nên đến thể tín dụng của bạn,Tuy nhiên, bạn nên biết rằng trong những trường hợp hiếm hoi có thể dùng thời gian thực hiện đến 10 ngày tùy thuộc vào ngân hàng kết thúc.\* Vui làng rằng việc rút tiền với thẻ tín dụng có thể không khả dụng ở một số quốc gia.`,
        title_5: `Rút tiền Safecharge chỉ có thể hoàn lại tiền đã nạp, bạn có thể chọn cách nạp tiền khác mà bạn đã dùng trước đây hoặc cách chuyển tiền qua ngân hàng để rút tiền.`,
        title_6: `Rút tiền Paypal/ Neteller/Skrill Phải làm từ cùng một tài khoản đã có việc gửi tiền ban đầu, các giao dịch này được thực hiện miễn phí và thực hiện ngay lập tức.`,
        title_7: `Nếu thẻ tín dụng/thẻ ghi nợ đã tải lên hết hạn, vui làng tải lên mới trong khu vực khách hàng của bạn để dùng phục vụ nạp/rút tiền tiếp mà không bị gián đoạn. Nếu số thẻ mới khác với số thẻ đã hết hạn, bạn cần gửi thư từ ngân hàng phát hành thẻ cũ để xác nhận rằng thẻ mới đã được phát hành thay cho thẻ cũ.`,
        title_8: `Nếu thẻ tín dụng/thẻ ghi nợ đã tải lên của bạn bị mất/bị đánh cắp/bị hỏng/bị hủy, bạn cần gửi thư từ ngân hàng phát hành thẻ cũ xác nhận rằng thẻ cũ không còn hợp lệ.`,
        title_9: `IUX Markets Theo quyết định bản thân,bạn có thể gửi các tài liệu hỗ trợ. (Ví dụ: biên lai cho các khoản thanh toán được thực hiện trên thẻ cũ hoặc bảng sao kê thẻ hiển thị các giao dịch gửi tiền) trước khi chuyển tiền vào thẻ mới.`,
        title_10: `Nếu cần rút tiền nhiều hơn tiền đã nạp bằng thẻ tín dụng/thẻ ghi nợ Verified by Visa/MasterCard Secure. Bạn cần sử dụng  phương thức nạp tiền khác đã sử dụng trước đây hoặc tùy chọn chuyển khoản ngân hàng`,
        title_11: `Nếu bạn đang rút tiền của bạn đã nạp qua tùy chọn Thai Internet Banking Sang tài khoản ngân hàng ở Thái Lan, bạn phải tải lên bản sao, ảnh chụp rõ ràng hoặc bản quét màu của sổ ngân hàng của bạn, khi thực hiện xong có thể dụng 1 ngày thực hiện để đến tài khoản của bạn.`,
        title_12: `IUX Markets Không xử lý các khoản thanh toán cho bên thứ ba, hãy chắc chắn kiểm tra rằng tất cả các yêu cầu rút tiền từ tài khoản giao dịch của bạn sẽ được chuyển đến tài khoản ngân hàng hoặc nguồn do bạn đứng tên. Thanh toán sang Tài khoản Ngân hàng Chung/thẻ tín dụng được chấp nhận nếu chủ sở hữu của tài khoản giao dịch là cá nhân trong tài khoản ngân hàng/thr tín dụng.`,
      },
    },
  },
  ko: {
    account_create_demo: `데모 계정은 거래를 연습하고, 테스트 전략을 세우고, 플랫폼에 익숙해지는 위험없는 투자입니다.`,
    account_create_success_islamic: `이 계정은 자동으로 스왑 프리 상태로 설정됩니다. 거래 비밀번호를 즉시 저장하십시오. 보안 상의 이유로 이메일로 보낼 수 없습니다.`,
    account_create_success: `더 이상 밤새 청구되지 않습니다. 스왑이없는 상태는 거래 활동에 의해 결정됩니다. 적극적인 스왑이없는 상태를 유지하려면 대부분 매일 거래해야합니다.`,
    open_an_account: "계정을 엽니 다",
    low_risk: "	낮은 위험	",
    high_risk: "	위험	",
    medium_risk: "	중간 위험	",
    standard_account_description:
      "	가장 인기 많은! 모든 유형의 거래자에게 훌륭한 계정.	",
    standard_bonus_account_description:
      "	우리의 즉시 실행 계정. 거래위원회가없는 훌륭한 채우기.	",
    raw_account_description: "	가장 낮은 생 스프레드와 낮은 고정위원회.	",
    pro_account_description: "	커미션 무료 0%의 전문 계정.	",
    button: {
      title: `지금 입금하기`,
    },
    cover: {
      accover: {
        title: `계정 개요`,
        description: `IUX Markets는 모든 계정 유형에서 낮은 스프레드 기기를 제공합니다. 모든 트레이더에 적합합니다. 원하는 플랫폼을 선택하고 시작하세요.`,
      },
      accstd: {
        title: `표준 `,
        description: `우리의 독점적인 브리지 어그리게이터를 통해 선택한 티어-1 가격 공급업체를 혼합하여 세계에서 가장 인기 있는 거래 플랫폼 중 하나에서 낮은 스프레드 이점을 얻을 수 있습니다.`,
      },
      acccnt: {
        title: `센트 `,
        description: `100 배 이하의 투자 단위를 가진 초보자 계정으로 모든 거래 스타일에 적합합니다. 선호하는 거래 전략을 사용할 수 있습니다. 표준 계정과 동일한 매우 낮은 스프레드로 이익을 얻을 수 있는 기회가 더 많습니다.`,
      },
      accecn: {
        title: `ECN `,
        description: `IUX Markets 표준 계정으로 간단하게 유지하십시오. 저평가 스프레드 요금 체계의 모든 이점을 누리세요. 수수료 없이 모든 포함 스프레드를 경험하세요.`,
      },
      accraw: {
        title: `로우 `,
        description: `IUX Markets Raw 계정으로 간단하게 유지하십시오. 로우 가격 체계의 모든 이점을 누리세요. 모든 포함 스프레드가 포함되어 있습니다.`,
      },
      accpro: {
        title: `프로 `,
        description: `경험이 풍부한 전문 트레이더에게 적합하며 어떤 거래 스타일에도 제로 수수료 및 낮은 스프레드, 주문 개수에 제한이 없습니다.`,
      },
      accbonus: {
        title: `표준+ `,
        description: `IUX Markets 표준+ 계정으로 간단하게 유지하십시오. 모든 포함 스프레드와 수수료가 없는 낮은 스프레드 이점을 경험하세요.`,
      },
      deposit: {
        title: `계정 자금`,
        description: `IUX Markets는 10 가지 다른 기초 CFD에서 15 가지 이상의 유연한 자금 지원 옵션을 제공합니다. 다양한 방법을 사용하여 즉시 무료로 입금하세요.`,
      },
      withdraw: {
        title: `자금 인출`,
        description: `IUX Markets 표준 계정으로 간단하게 유지하십시오. 낮은 요금으로 모든 포함 스프레드 및 수수료 이점을 경험하세요.`,
      },
    },
    menu: {
      menu_1: "계정 개요",
    },
    section_1: {
      title: "왜 저희 표준 계정을 선택해야 할까요?",
      description:
        "저희는 최저 스프레드를 제공합니다. 평균 EUR/USD 스프레드는 0.2 PIP이며 수수료가 없습니다. IUX Markets 표준 계정은 높은 유동성과 신속한 실행이 특징인 일일 거래자, 단기 거래자 및 전문 거래자를 위해 만들어졌습니다. IUX Markets MetaTrader5 플랫폼은 전 세계 거래자들의 선호하는 선택입니다.",
    },
    std: {
      box_1: {
        titile_1: `CFDs 거래`,
        titile_2: `낮은 스프레드 가격`,
        titile_3: `0.2 PIPs에서 스프레드`,
        titile_4: `빠른 주문 실행`,
        titile_5: `1:3000 레버리지`,
        titile_6: `깊은 유동성`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `표준 계정은 최저 스프레드를 제공합니다. 평균 EUR/USD 스프레드는 0.2 PIP이며 수수료가 없습니다. IUX Markets 표준 계정은 높은 유동성과 빠른 실행이 특징인 일일 거래자, 단기 거래자 및 전문 거래자를 위해 만들어졌습니다. IUX Markets MetaTrader5 플랫폼은 전 세계 거래자들의 선호하는 선택입니다.`,
        titile_2: `저희의 MetaTrader5 서버는 런던의 Equinix LD4 데이터 센터에 위치해 있어 교량 데이터 센터에 가깝게 위치하여 불필요한 대기 시간을 제거합니다. MetaTrader5는 전 세계의 일일 거래자, 단기 거래자 및 전문 거래자들에게 좋은 선택입니다.`,
      },
      section_2: {
        titile_1: `낮은 스프레드 가격`,
        description_1: `IUX Markets는 고객에게 낮은 스프레드 가격을 제공함으로써 MT5 플랫폼을 통해 시장 가격 및 최상의 거래 조건을 제공할 수 있습니다. 낮은 스프레드 가격 환경은 전 세계 주요 실행 장소의 거의 동일한 수준에서 기관 등급 가격으로 거래할 수 있도록 합니다. 실행 가능한 스트리밍 가격(ESP)은 IUX Markets의 가격 제공 업체에서 클라이언트로 보내지며 딜링 데스크, 가격 조작 또는 다시 견적이 없습니다. IUX Markets는 고수익 거래자, 스캘퍼 및 터보가 낮은 스프레드와 최상의 실행을 제공하는 로봇들을 위한 최고의 CFD 제공 업체입니다.`,
      },
      section_3: {
        titile_1: `빠른 주문 실행`,
        description_1: `IUX Markets MetaTrader5 서버는 런던의 Equinix LD4 데이터 센터에 위치해 있습니다. 금융 생태계로 불리는 NY4 데이터 센터는 600개 이상의 매수 및 매도측 기업, 거래소, 거래 장소, 시장 데이터 및 서비스 제공 업체를 호스팅합니다. MetaTrader5 서버는 IUX Markets 거래 환경을 통한 거래의 저지연과 빠른 실행을 보장하기 위해 통신 네트워크에 교차 연결되어 있습니다. IUX Markets MetaTrader5 및 5 거래 서버는 NY4 데이터 센터에 직접 또는 인접한 데이터 센터로의 전용 라인을 통한 거래처까지의 지연 시간이 1밀리초 미만입니다. 이 낮은 지연 환경은 자동 및 고주파 거래 및 스캘핑에 이상적입니다.`,
        titile_2: `0.2 PIPs에서 스프레드`,
        description_2: `IUX Markets는 전 세계의 모든 CFD 브로커 중 가장 낮은 스프레드 중 일부를 자랑합니다. Meta Trader5 플랫폼에서 EURUSD의 평균이 0.2 PIPs이며 24/7에서 0.2 PIPs입니다. 현재 전 세계에서 가장 낮은 EURUSD 스프레드 중 하나입니다. \n저희의 가격 커넥터는 최대 25개의 다른 가격 공급자에서 가격을 혼합하므로 항상 고객을 위해 최상의 가격을 찾고 특히 뉴스 발표와 같은 고변동 시간에 스프레드를 유지할 수 있습니다..`,
        titile_3: `거래에 제한 없음 – 스캘핑 허용됨`,
        description_3: `IUX Markets Meta Trader5 플랫폼에는 거래에 제한이 없습니다. 최소 주문 거리가 없으며 동결 레벨이 0이므로 스프레드 내에서 주문을 배치할 수 있습니다. \nIUX Markets에서는 FIFO(먼저 들어온 것이 먼저 나가는) 규칙이 없으므로 거래자는 헷지 거래에 마진을 지불하지 않으며 마진 네팅의 혜택을 누릴 수 있습니다.`,
        titile_4: `레벨 II 가격 – 시장 깊이`,
        description_4: `시장 깊이는 가격 공급 업체에서 직접 제공되는 실행 가능한 가격의 전체 범위를 보여줍니다. 시장 깊이는 언제든지 각 통화 쌍의 사용 가능한 볼륨을 특정 시간에 각 가격 수준에 표시함으로써 각 통화 쌍의 유동성에 대한 완전한 투명성을 제공합니다. 높은 유동성, 비동기적 스팟 가격 및 낮은 지연 시간은 가능한 가장 낮은 스프레드를 보장합니다.`,
      },
      section_4: {
        titile_1: `유연한 자금 지급 및 인출 옵션`,
        description_1: `계정을 열면 신용카드/직불카드, Skrill, PayPal, Neteller, Thai QR 코드 및 브로커 간 이체 등 다양한 자금 지급 옵션을 사용할 수 있습니다.`,
        titile_2: `유연한 롯 사이징`,
        description_2: `거래 크기에 제한이나 제약이 없으며 0.01과 같은 작은 거래도 배치할 수 있습니다. 유연한 롯 사이징을 통해 최소한의 리스크로 플랫폼을 테스트하고 계정 잔액에 따라 거래 크기를 관리할 수 있습니다.`,
      },
      section_5: {
        titile_1: `64 CFD 및 금속 거래`,
        description_1: `64개의 CFD 통화 쌍 및 영국 100, S&P 500, AUS 200 및 Dow Jones 지수를 포함한 15개의 주요 지수와 1포인트 스프레드로 24시간 내내 거래할 수 있습니다.`,
        titile_2: `1:3000 레버리지`,
        description_2: `IUX Markets Meta Trader5 플랫폼에서는 최대 1:3000 레버리지까지 사용할 수 있습니다. 거래자는 더 높은 레버리지를 사용하여 자신의 거래 스타일에 맞게 조절하고 수동 및 자동 거래 전략에서 최상의 결과를 얻을 수 있습니다.`,
        titile_3: `모든 주요 계정 통화 지원`,
        description_3: `일부 거래자는 현지 통화로 거래하는 것을 선호합니다. 거래자는 10가지 지원되는 기본 통화 중 1가지에서 계정을 열 수 있는 옵션을 제공받습니다: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.`,
      },
    },
    bonus: {
      box_1: {
        titile_1: `CFDs Trading `,
        titile_2: `보너스 입금 $35`,
        titile_3: `수수료 없음`,
        titile_4: `빠른 주문 실행`,
        titile_5: `1:1000 레버리지`,
        titile_6: `깊은 유동성`,
        titile_7: `Meta Trader5 `,
      },
      section_1: {
        titile_1: `왜 Standard+를 선택해야 하나요`,
        description_1: `IUX Markets의 Standard+ 계정은 어떤 경쟁사도 따라갈 수 없는 실행 속도를 제공합니다. 포함된 포괄적인 스프레드는 0.5 PIPs부터 시작하며, 런던의 Equinix LD4 데이터 센터, 최대 25개의 가격 제공업체 그룹을 통합합니다. 당신은 왜 우리의 Standard+ 계정이 매일 매일 탁월한 거래 경험을 제공할 수 있는지 이해하게 될 것입니다.`,
      },
      section_2: {
        titile_1: `빠른 주문 실행`,
        description_1: `IUX Markets MetaTrader5 서버는 런던의 Equinix LD4 데이터 센터에 위치해 있습니다. NY4 데이터 센터는 금융 생태계로 불리며 600개 이상의 매수 및 매도사업자, 거래소, 거래 장소, 시장 데이터 및 서비스 제공자가 호스팅됩니다. MetaTrader5 서버는 저희의 통신 네트워크와 교차 연결되어 낮은 지연 및 빠른 주문 실행을 보장합니다. IUX Markets MetaTrader5 및 5 거래 서버는 NY4 데이터 센터에 직접 연결되었거나 근처 데이터 센터로 전용 라인을 통해 연결된 메이저 VPS 제공업체까지 1밀리초 미만의 지연을 갖습니다. 이 낮은 지연 환경은 자동 및 고빈도 거래 및 스캘핑에 이상적입니다.`,
      },
      section_3: {
        titile_1: `거래에 대한 제한 없음 – 스캘핑 허용`,
        description_1: `IUX Markets Meta Trader5 플랫폼에는 거래에 대한 제한이 없습니다. 최소 주문 거리나 동결 레벨이 없어 트레이더는 시장 가격에 가까이 주문을 놓을 수 있습니다. IUX Markets에서 FIFO(선입선출) 규칙이 없기 때문에 트레이더는 헷징 포지션을 할 수 있으며 헷지된 거래에 마진을 지불하지 않고 마진 네팅 혜택을 누릴 수 있습니다.`,
        titile_2: `Level II Pricing – 시장 깊이`,
        description_2: `시장 깊이는 가격 제공업체에서 직접 나오는 실행 가능한 가격의 전체 범위를 보여줍니다. 시장 깊이는 각 통화 쌍의 유동성을 가격 단계별로 언제든지 표시하여 제공합니다. 고유율, 비동기식 스팟 가격 및 낮은 지연은 가능한 최소의 스프레드를 보장합니다.`,
      },
      section_4: {
        titile_1: `64 CFD 및 금속 거래`,
        description_1: `64 CFD 통화 쌍 및 영국 100, S&P 500, AUS 200 및 Dow Jones 지수를 포함한 15개의 주요 지수를 1포인트 스프레드로 하루 24시간 거래하세요.`,
        titile_2: `1:1000 레버리지`,
        description_2: `IUX Markets Meta Trader5 플랫폼에서는 최대 1:1000 레버리지까지 가능합니다. 트레이더는 더 높은 레버리지를 사용하여 자신의 거래 스타일에 맞게 조절하고 수동 및 자동 거래 전략에서 최고의 성과를 얻을 수 있습니다.`,
        titile_3: `모든 주요 계정 통화 지원`,
        description_3: `일부 트레이더는 지역 통화로 거래하는 것을 선호합니다. 트레이더들에게는 USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD 중 1개의 기본 통화로 계정을 열 수 있는 옵션이 제공됩니다.`,
      },
    },

    accecn: {
      box_1: {
        titile_1: `CFDs Trading `,
        titile_2: `90종 이상의 금융상품 거래`,
        titile_3: `수수료 $3.5`,
        titile_4: `빠른 주문 실행`,
        titile_5: `1:3000 레버리지`,
        titile_6: `스프레드 최저 0.0 PIPs`,
        titile_7: `Meta Trader5 `,
      },
      section_1: {
        titile_1: `왜 우리의 ECN 계정을 선택해야 하나요?`,
        description_1: `IUX Markets의 ECN 계정은 어떤 경쟁사도 따라갈 수 없는 실행 속도를 제공합니다. 이것을 0.0 PIPs부터 시작하는 포괄적인 스프레드, 런던의 Equinix LD4 서버, 최대 25개의 가격 제공 업체 그룹과 결합하면 왜 우리의 표준 계정이 매일 매일 탁월한 거래 경험을 제공할 수 있는지 이해하게 될 것입니다.`,
      },
      section_2: {
        titile_1: `빠른 주문 실행`,
        description_1: `IUX Markets MetaTrader5 서버는 런던의 Equinix LD4 데이터 센터에 위치해 있습니다. NY4 데이터 센터는 금융 생태계로 불리며 600개 이상의 매수 및 매도 사이드 회사, 거래소, 거래 장소, 시장 데이터 및 서비스 제공 업체를 호스팅합니다. MetaTrader5 서버는 통신 네트워크와 교차 연결되어 낮은 지연과 IUX Markets 거래 환경을 통한 빠른 주문 실행을 보장합니다. IUX Markets MetaTrader5 및 5 거래 서버는 NY4 데이터 센터에 직접 연결되었거나 근처 데이터 센터로 전용 라인을 통해 메이저 VPS 제공 업체에게 1밀리초 미만의 지연이 발생합니다. 이 낮은 지연 환경은 자동 및 고빈도 거래 및 스캘핑에 이상적입니다.`,
      },
      section_3: {
        titile_1: `거래에 대한 제한 없음 – 스캘핑 허용`,
        description_1: `IUX Markets Meta Trader5 플랫폼은 거래에 대한 제한이 없습니다. 최소 주문 거리나 동결 레벨이 없어 트레이더는 시장 가격에 가까이 주문을 놓을 수 있습니다. IUX Markets에서 FIFO(선입선출) 규칙이 없기 때문에 트레이더는 헷지된 거래에 마진을 지불하지 않고 마진 네팅 혜택을 누릴 수 있습니다.`,
        titile_2: `Level II Pricing – 시장 깊이`,
        description_2: `시장 깊이는 가격 제공 업체에서 직접 나오는 실행 가능한 가격의 전체 범위를 보여줍니다. 시장 깊이는 각 통화 쌍의 유동성을 가격 단계별로 언제든지 표시하여 제공합니다. 고유율, 비동기식 스팟 가격 및 낮은 지연은 가능한 최소의 스프레드를 보장합니다.`,
      },
      section_4: {
        titile_1: `64 CFD 및 금속 거래`,
        description_1: `64 CFD 통화 쌍 및 영국 100, S&P 500, AUS 200 및 Dow Jones 지수를 포함한 15개의 주요 지수를 1포인트 스프레드로 하루 24시간 거래하세요.`,
        titile_2: `1:3000 레버리지`,
        description_2: `IUX Markets Meta Trader5 플랫폼에서는 최대 1:3000 레버리지까지 가능합니다. 트레이더는 더 높은 레버리지를 사용하여 자신의 거래 스타일에 맞게 조절하고 수동 및 자동 거래 전략에서 최고의 성과를 얻을 수 있습니다.`,
        titile_3: `모든 주요 계정 통화 지원`,
        description_3: `일부 트레이더는 지역 통화로 거래하는 것을 선호합니다. 트레이더들에게는 USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD 중 1개의 기본 통화로 계정을 열 수 있는 옵션이 제공됩니다.`,
      },
    },
    accraw: {
      box_1: {
        titile_1: `CFDs Trading `,
        titile_2: `90종 이상의 금융상품 거래`,
        titile_3: `수수료 $6`,
        titile_4: `빠른 주문 실행`,
        titile_5: `1:3000 레버리지`,
        titile_6: `스프레드 최저 0.0 PIPs`,
        titile_7: `Meta Trader5 `,
      },
      section_1: {
        titile_1: `왜 Raw를 선택해야 하나요?`,
        description_1: `IUX Markets의 Raw 계정은 어떤 경쟁사도 따라갈 수 없는 실행 속도를 제공합니다. 이것을 0.0 PIPs부터 시작하는 포괄적인 스프레드, 런던의 Equinix LD4 서버, 최대 25개의 가격 제공 업체 그룹과 결합하면 왜 우리의 Raw 계정이 매일 매일 탁월한 거래 경험을 제공할 수 있는지 이해하게 될 것입니다.`,
      },
      section_2: {
        titile_1: `빠른 주문 실행`,
        description_1: `IUX Markets MetaTrader5 서버는 런던의 Equinix LD4 데이터 센터에 위치해 있습니다. NY4 데이터 센터는 금융 생태계로 불리며 600개 이상의 매수 및 매도 사이드 회사, 거래소, 거래 장소, 시장 데이터 및 서비스 제공 업체를 호스팅합니다. MetaTrader5 서버는 통신 네트워크와 교차 연결되어 낮은 지연과 IUX Markets 거래 환경을 통한 빠른 주문 실행을 보장합니다. IUX Markets MetaTrader5 및 5 거래 서버는 NY4 데이터 센터에 직접 연결되었거나 근처 데이터 센터로 전용 라인을 통해 메이저 VPS 제공 업체에게 1밀리초 미만의 지연이 발생합니다. 이 낮은 지연 환경은 자동 및 고빈도 거래 및 스캘핑에 이상적입니다.`,
      },
      section_3: {
        titile_1: `거래에 대한 제한 없음 – 스캘핑 허용`,
        description_1: `IUX Markets Meta Trader5 플랫폼은 거래에 대한 제한이 없습니다. 최소 주문 거리나 동결 레벨이 없어 트레이더는 시장 가격에 가까이 주문을 놓을 수 있습니다. IUX Markets에서 FIFO(선입선출) 규칙이 없기 때문에 트레이더는 헷지된 거래에 마진을 지불하지 않고 마진 네팅 혜택을 누릴 수 있습니다.`,
        titile_2: `Level II Pricing – 시장 깊이`,
        description_2: `시장 깊이는 가격 제공 업체에서 직접 나오는 실행 가능한 가격의 전체 범위를 보여줍니다. 시장 깊이는 각 통화 쌍의 유동성을 가격 단계별로 언제든지 표시하여 제공합니다. 고유율, 비동기식 스팟 가격 및 낮은 지연은 가능한 최소의 스프레드를 보장합니다.`,
      },
      section_4: {
        titile_1: `64 CFD 및 금속 거래`,
        description_1: `64 CFD 통화 쌍 및 영국 100, S&P 500, AUS 200 및 Dow Jones 지수를 포함한 15개의 주요 지수를 1포인트 스프레드로 하루 24시간 거래하세요.`,
        titile_2: `1:3000 레버리지`,
        description_2: `IUX Markets Meta Trader5 플랫폼에서는 최대 1:3000 레버리지까지 가능합니다. 트레이더는 더 높은 레버리지를 사용하여 자신의 거래 스타일에 맞게 조절하고 수동 및 자동 거래 전략에서 최고의 성과를 얻을 수 있습니다.`,
        titile_3: `모든 주요 계정 통화 지원`,
        description_3: `일부 트레이더는 지역 통화로 거래하는 것을 선호합니다. 트레이더들에게는 USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD 중 1개의 기본 통화로 계정을 열 수 있는 옵션이 제공됩니다.`,
      },
    },

    accpro: {
      box_1: {
        titile_1: `CFDs Trading `,
        titile_2: `90종 이상의 금융상품 거래`,
        titile_3: `수수료 $0`,
        titile_4: `빠른 주문 실행`,
        titile_5: `1:3000 레버리지`,
        titile_6: `스프레드 최저 0.1 PIPs`,
        titile_7: `Meta Trader5 `,
      },
      section_1: {
        titile_1: `Pro를 선택하는 이유`,
        description_1: `경험이 풍부하고 전문적인 트레이더라면 Pro 계정이 적합합니다. IUX Markets의 Pro 계정은 0.0 픽셀부터 시작하는 원시 스프레드 또는 심지어 스프레드 무료로 제공됩니다. 또한 대부분의 금융상품에 대한 즉시 실행을 통한 주문 처리 속도를 제공합니다. Pro 계정은 스캘퍼, 데이 트레이더 및 알고 트레이더에게 적합한 실행을 갖추고 있습니다.`,
      },
      section_2: {
        titile_1: `빠른 주문 실행`,
        description_1: `IUX Markets MetaTrader5 서버는 런던의 Equinix LD4 데이터 센터에 위치해 있습니다. NY4 데이터 센터는 금융 생태계로 불리며 600개 이상의 매수 및 매도 사이드 회사, 거래소, 거래 장소, 시장 데이터 및 서비스 제공 업체를 호스팅합니다. MetaTrader5 서버는 통신 네트워크와 교차 연결되어 낮은 지연과 IUX Markets 거래 환경을 통한 빠른 주문 실행을 보장합니다. IUX Markets MetaTrader5 및 5 거래 서버는 NY4 데이터 센터에 직접 연결되었거나 근처 데이터 센터로 전용 라인을 통해 메이저 VPS 제공 업체에게 1밀리초 미만의 지연이 발생합니다. 이 낮은 지연 환경은 자동 및 고빈도 거래 및 스캘핑에 이상적입니다.`,
      },
      section_3: {
        titile_1: `거래에 대한 제한 없음 – 스캘핑 허용`,
        description_1: `IUX Markets Meta Trader5 플랫폼은 거래에 대한 제한이 없습니다. 최소 주문 거리나 동결 레벨이 없어 트레이더는 시장 가격에 가까이 주문을 놓을 수 있습니다. IUX Markets에서 FIFO(선입선출) 규칙이 없기 때문에 트레이더는 헷지된 거래에 마진을 지불하지 않고 마진 네팅 혜택을 누릴 수 있습니다.`,
        titile_2: `Level II Pricing – 시장 깊이`,
        description_2: `시장 깊이는 가격 제공 업체에서 직접 나오는 실행 가능한 가격의 전체 범위를 보여줍니다. 시장 깊이는 각 통화 쌍의 유동성을 가격 단계별로 언제든지 표시하여 제공합니다. 고유율, 비동기식 스팟 가격 및 낮은 지연은 가능한 최소의 스프레드를 보장합니다.`,
      },
      section_4: {
        titile_1: `64 CFD 및 금속 거래`,
        description_1: `64 CFD 통화 쌍 및 영국 100, S&P 500, AUS 200 및 Dow Jones 지수를 포함한 15개의 주요 지수를 1포인트 스프레드로 하루 24시간 거래하세요.`,
        titile_2: `1:3000 레버리지`,
        description_2: `IUX Markets Meta Trader5 플랫폼에서는 최대 1:3000 레버리지까지 가능합니다. 트레이더는 더 높은 레버리지를 사용하여 자신의 거래 스타일에 맞게 조절하고 수동 및 자동 거래 전략에서 최고의 성과를 얻을 수 있습니다.`,
        titile_3: `모든 주요 계정 통화 지원`,
        description_3: `일부 트레이더는 지역 통화로 거래하는 것을 선호합니다. 트레이더들에게는 USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD 중 1개의 기본 통화로 계정을 열 수 있는 옵션이 제공됩니다.`,
      },
    },
    funding: {
      section_1: {
        title: `입출금 방법`,
        titile_1: `등록 및 로그인`,
        titile_2: `선호하는 은행 선택하여 입출금 진행`,
        titile_3: `입출금 수수료 없음`,
        titile_4: `몇 분 내에 신속한 입출금`,
      },
      section_2: {
        title: `입출금 옵션`,
        description: `당사는 암호화를 사용하여 귀하의 금융 정보를 안전하게 보호합니다. 그래서 자신감을 가지고 온라인 결제할 수 있습니다.`,
        option: `자금 조달 옵션`,
        hot: `인기`,
        titile_1: `신용카드 및 직불카드`,
        titile_2: `스크릴`,
        titile_3: `네텔러`,
        titile_4: `태국 QR 결제`,
        titile_5: `온라인 뱅킹`,
      },
      section_3: {
        titile_1: `결제 과정`,
        description_1: `보다 신속한 처리를 위해 Secure Client Area에서 자금을 입금하는 것이 좋습니다. 잔액은 실시간으로 입금됩니다. Secure Client Area에 액세스하는 데 문제가 있는 경우 지침을 따르십시오.`,
        titile_2: `자금 안전성`,
        titile_3: `은행 수수료`,
        description_2: `고객 자금은 분리된 고객 신뢰 계좌에 보관됩니다. 전자 결제는 SSL (보안 소켓 레이어) 기술을 사용하여 처리되며 보안을 위해 암호화됩니다. 모든 결제 정보는 기밀이며 IUX Markets 거래 계좌에 자금을 공급하는 목적으로만 사용됩니다.`,
        description_3: `IUX Markets는 입출금에 대한 추가 수수료를 부과하지 않습니다. 그러나 국제 은행 기관의 결제에 대한 수수료가 발생할 수 있음을 인식해야 합니다. IUX Markets는 은행이나 제공 업체 수수료에 대한 어떠한 책임도 지지 않습니다.`,
        titile_4: `제3자 결제`,
        description_4: `IUX Markets는 제3자로부터의 결제를 허용하지 않습니다. 거래 계좌로의 모든 입금이 귀하의 이름으로 된 은행 계좌에서 이루어졌는지 확인하십시오. 은행 계좌/신용 카드의 공동 소유자인 경우 거래 계좌 보유자가 은행 계좌/신용 카드의 당사자 중 하나인 경우 입금이 허용됩니다.`,
      },
    },
    withdraw: {
      section_1: {
        title: `보다 신속한 처리를 위해 모든 계정 소유자는 이제 자신의 Secure Client Area에서 출금 요청을 제출해야 합니다.`,
        content: `Secure Client Area에 액세스하는 데 문제가 있는 경우 지침을 따르십시오.`,
      },
      section_2: {
        title: `자금 인출에 경험이 없는 경우 다음 지침을 따르십시오:`,
        title_1: `출금 요청 마감 시간은 12:00 AEST/AEDT입니다. 이 시간 이전에 출금을 제출하면 해당 날에 처리됩니다. 이 시간 이후에 출금을 제출하면 다음 영업일에 처리됩니다.`,
        title_2: `IUX Markets는 입출금에 대한 추가 수수료를 부과하지 않습니다. 그러나 국제 은행 기관의 결제에 대한 수수료가 발생할 수 있음을 인식해야 합니다. IUX Markets는 은행이나 제공 업체 수수료에 대한 어떠한 책임도 지지 않습니다.`,
        title_3: `국제 은행 송금의 경우 우리 은행 기관에서 통화 등가의 처리 수수료가 부과됩니다. 이 수수료는 출금 금액에서 차감됩니다. 이는 최대 14일이 소요될 수 있으며 귀하의 관할 지역 및 선택한 은행에 따라 추가 중간 비용이 발생할 수 있습니다.`,
        title_4: `신용카드/직불카드로의 출금은 수수료가 부과되지 않습니다. 처리된 후에는 신용카드/직불카드로의 출금이 3-5 영업일이 소요될 수 있습니다. 그러나 일부 특수한 경우에는 은행 종료에 따라 경우에 따라 최대 10 영업일이 소요될 수 있습니다. *카드 출금은 모든 국가에서 사용할 수 없을 수 있습니다.`,
        title_5: `Paypal/Neteller/Skrill로의 출금은 초기 자금이 보내진 계정에서 이루어져야 합니다. 이러한 거래는 수수료 없이 즉시 처리됩니다.`,
        title_6: `신용카드/직불카드의 만료일이 이미 지났다면, 클라이언트 영역에 새 카드를 업로드하여 입출금 서비스를 중단하지 않고 계속 사용하십시오. 새 카드의 번호가 만료된 카드의 번호와 다른 경우, 새 카드가 이전 카드의 대체로 발급되었음을 확인하는 이전 카드 발급 은행에서 발급된 편지를 제출해야 합니다.`,
        title_7: `신용카드/직불카드가 분실, 도난, 손상되거나 취소된 경우, 이전 카드 발급 은행에서 발급된 이전 카드가 더 이상 유효하지 않음을 확인하는 편지를 제출해야 합니다.`,
        title_8: `IUX Markets는 자체 재량에 따라 귀하에게 자금을 새 카드로 지급하기 전에 지원 문서 (예: 이전 카드를 통한 처리된 지불에 대한 입금 영수증 또는 입금 거래를 보여주는 카드 명세서)를 제출하도록 요청할 수 있습니다.`,
        title_9: `Verified by Visa/MasterCard Secure 신용/직불 카드로 예치된 금액보다 더 큰 금액을 인출하려면 이전에 사용한 다른 입금 방법이나 은행 송금 옵션을 사용해야 합니다.`,
        title_10: `태국 인터넷 뱅킹 옵션을 통해 예치한 자금을 태국 은행 계좌로 인출하는 경우, 통장의 명확한 사진 또는 색상이 있는 스캔 사본을 업로드해야 합니다. 처리된 후 자금은 계정에 도달하는 데 하루가 소요될 수 있습니다.`,
        title_11: `태국 인터넷 뱅킹 옵션을 통해 예치한 자금을 태국 은행 계좌로 인출하는 경우, 통장의 명확한 사진 또는 색상이 있는 스캔 사본을 업로드해야 합니다. 처리된 후 자금은 계정에 도달하는 데 하루가 소요될 수 있습니다.`,
        title_12: `IUX Markets는 제3자에게 지불을 처리하지 않습니다. 거래 계좌에서의 모든 인출 요청이 귀하의 이름으로 된 은행 계좌 또는 소스로 이동하도록하십시오. 거래 계좌/신용 카드의 공동 소유자로 지정된 경우 은행 계좌/신용 카드의 당사자 중 한 명이여야 합니다.`,
      },
    },
  },
  fil: {
    account_create_demo: `Ang iyong demo account ay isang pamumuhunan na walang panganib na kung saan maaari kang magsanay ng pangangalakal, mga diskarte sa pagsubok, at maging komportable sa aming platform.`,
    account_create_success_islamic: `Ang account na ito ay awtomatikong itinakda sa Swap-Free status. Mangyaring i-save ang iyong trading password kaagad. Hindi ito maaaring i-email para sa mga kadahilanan ng seguridad.`,
    account_create_success: `Wala nang magdamag na singil. Ang iyong katayuan na walang swap ay natutukoy ng iyong aktibidad sa pangangalakal. Upang mapanatili ang isang aktibong katayuan na walang swap, kailangan mong ipagpalit ang karamihan sa loob ng araw.`,
    open_an_account: "Buksan ang account",
    low_risk: "	Mababang panganib	",
    high_risk: "	Napakadelekado	",
    medium_risk: "	Katamtamang peligro	",
    standard_account_description:
      "	Pinaka sikat! Ang isang mahusay na account para sa lahat ng mga uri ng mangangalakal.	",
    standard_bonus_account_description:
      "	Ang aming instant na account sa pagpapatupad. Mahusay na punan na walang komisyon sa pangangalakal.	",
    raw_account_description:
      "	Ang pinakamababang hilaw na kumakalat at isang mababang nakapirming komisyon.	",
    pro_account_description:
      "	Propesyonal na account na may komisyon na libreng 0%.	",
    button: {
      title: `Deposit now`,
    },
    cover: {
      accover: {
        title: `Account Overview`,
        description: `IUX Markets offers low spread instruments in all account types; suitable for all traders. Simply choose your preferred platform and get started. `,
      },
      accstd: {
        title: `Standard `,
        description: `With our proprietary bridge aggregator mixing chosen tier-1 price suppliers, you may gain the Low Spread advantage on one of the world's most popular trading platforms.`,
      },
      acccnt: {
        title: `Cent `,
        description: `A beginner account with less than 100 times investment units, suitable for all trading styles. You can use any trading strategy you prefer. With the same extremely low spread in the Standard account, you have more chances to make a profit.`,
      },
      accecn: {
        title: `ECN `,
        description: `Keep it simple with the IUX Markets Standard account. Experience all the benefits of our Low Spread Pricing with an all-inclusive spread with no commissions.`,
      },
      accraw: {
        title: `Raw `,
        description: `Keep it simple with the IUX Markets Raw account. Experience all the benefits of our Raw pricing with an all-inclusive spread.
`,
      },
      accpro: {
        title: `Pro `,
        description: ` Suited to experienced and professional traders, for any trading style, with zero commission & low spread and no limit on orders that can be opened.`,
      },
      accbonus: {
        title: `Standard+ `,
        description: `Keep it simple with the IUX Markets Standard+ account. Experience all the benefits of our  Low Spread Pricing with an all-inclusive spread and no commissions.`,
      },
      deposit: {
        title: `Account Funding`,
        description: `IUX Markets offers over 15 flexible funding options in 10 different base CFDs. Deposit instantly and free using a range of methods.`,
      },
      withdraw: {
        title: `Fund Withdrawal`,
        description: `Keep it simple with the IUX Markets Standard account. Experience all the benefits of our  Low pricing  with an all-inclusive spread and no commissions.`,
      },
    },
    menu: {
      menu_1: "Overview ng Account",
    },
    section_1: {
      title: "BAKIT PILIIN ANG AMING STANDARD ACCOUNT?",
      description:
        "Nag-aalok kami ng pinakamababang spread. Ang aming average EUR/USD spread ay 0.2 PIPs nang walang komisyon. Ang IUX Markets Standard Account ay ginawa para sa mga day trader, short term trader, at expert trader na may kabuuang pinagmulan ng presyo mula sa hanggang sa 25 institutional grade sources. Ang IUX Markets MetaTrader5 platform ay ang pinipili ng mga mangangalakal sa buong mundo.",
    },

    std: {
      box_1: {
        titile_1: `CFDs Trading `,
        titile_2: `Mababang Spread Pricing`,
        titile_3: `Spread mula 0.2 PIPs`,
        titile_4: `Mabilis na Pagpapatupad ng Order`,
        titile_5: `1:3000 na Leverage`,
        titile_6: `Malalim na Liquidity`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `Ang Standard Account ay nag-aalok ng pinakamababang spread. Ang aming average EUR/USD spread ay 0.2 PIPs nang walang komisyon. Ang IUX Markets Standard Account ay ginawa para sa mga day trader, short term trader, at expert trader na may malalim na liquidity at mabilis na pagpapatupad. Ang IUX Markets MetaTrader5 platform ay ang pinipili ng mga mangangalakal sa buong mundo.`,
        titile_2: `Ang aming mga server ng MetaTrader5 ay matatagpuan sa Equinix LD4 Data Center sa London, na nagdadala sa iyo ng mas malapit sa aming bridge data center at tumutulong sa pag-alis ng hindi kinakailangang latency. Ang MetaTrader5 ay isang mahusay na pagpipilian para sa mga day trader, short term trader, at expert trader sa buong mundo.`,
      },
      section_2: {
        titile_1: `Mababang Spread Pricing`,
        description_1: `Ang IUX Markets ay kayang mag-alok ng market pricing at ng pinakamahusay na kondisyon sa trading sa pamamagitan ng MT5 platforms sa pamamagitan ng pagbibigay ng Low Spread Pricing sa mga kliyente. Ang Low Spread Pricing environment ay nagpapahintulot sa iyo na mag-trade sa institutional grade pricing na halos pareho sa antas ng mga pangunahing execution venues sa buong mundo. Ang Executable Streaming Prices (ESP) ay ipinapadala mula sa mga pricing provider ng IUX Markets sa atin at inaalok sa aming mga kliyente nang walang dealing desk, price manipulation, o requotes. Ang IUX Markets ay ang pinakamahusay na provider ng CFDs para sa mga high volume trader, scalpers, at mga robot na nagbibigay ng tight spread at pinakamahusay na execution.`,
      },
      section_3: {
        titile_1: `Mabilis na Pagpapatupad ng Order`,
        description_1: `Ang server ng MetaTrader5 ng IUX Markets ay matatagpuan sa Equinix LD4 data center sa London. Ang data center na NY4, tinatawag na financial ecosystem, ay nagho-host ng higit sa 600 na buy and sell side firms, exchanges, trading venues, market data, at mga nagbibigay ng serbisyo. Ang MetaTrader5 server ay cross connected sa aming Communication Network upang tiyakin ang mababang latency at mabilis na pagpapatupad ng iyong mga trades sa pamamagitan ng IUX Markets trading environment. Ang mga server ng IUX Markets MetaTrader5 at 5 trade ay may latency na mas mababa sa 1 millisecond papunta sa mga pangunahing VPS providers, maging ito ay nasa NY4 data center o sa pamamagitan ng mga dedicated lines patungo sa malapit na data centres. Ang mababang latency na ito ay ideal para sa automated at high frequency trading at scalping.`,
        titile_2: `Spread mula 0.2 PIPs`,
        description_2: `Ang IUX Markets ay may ilan sa pinakamalulupit na spread sa lahat ng mga CFDs brokers sa buong mundo. Ang spread ay nagsisimula sa 0.2 PIPs sa mga platform ng MetaTrader5, at ang average sa EURUSD ay 0.2 PIPs 24/7. Ito ang kasalukuyang isa sa pinakamababang average EURUSD spread sa buong mundo. Ang aming pricing connector ay nag-aaggregate ng isang mix mula sa hanggang sa 25 na iba't ibang pricing providers, ibig sabihin, laging kayang makakuha ng pinakamahusay na presyo para sa aming mga kliyente at panatilihin ang aming spread na mababa, lalo na sa mga oras ng mataas na volatility tulad ng mga pahayag sa balita.`,
        titile_3: `Walang mga Pagsasalig sa Trading – Pinapayagan ang Scalping`,
        description_3: `Ang mga platform ng MetaTrader5 ng IUX Markets ay walang mga pagsasalig sa trading, na nagpapahintulot sa mga mangangalakal na maglagay ng mga order sa pagitan ng spread dahil wala itong minimum order distance at isang freeze level na 0. Ibig sabihin, maaaring ilagay ang mga order, kabilang ang stop loss orders, na kasing lapit ng maaari sa presyo ng merkado na gusto mo. Ang mga mangangalakal ay maaari rin maghedge ng positions dahil wala itong first in first out (FIFO) rule sa IUX Markets. Hindi nagbabayad ng margin ang mga mangangalakal sa mga hedged trades at nakakatamasa ng mga benepisyo ng margin netting.`,
        titile_4: `Level II Pricing – Market Depth`,
        description_4: `Ang market depth ay nagpapakita ng buong range ng mga executable prices na direkta mula sa aming mga pricing provider. Ang market depth ay nagbibigay ng buong pagsiwalat sa liquidity ng bawat currency pair sa pamamagitan ng pagpapakita ng mga available volumes para sa bawat price level sa anumang oras. Ang mataas na liquidity, asynchronous spot prices, at mababang latency ay garantisadong ang pinakamasikip na possible spread.`,
      },
      section_4: {
        titile_1: `Flexible na Pagpapalitan at Pag-Withdraw ng Pondo`,
        description_1: `Kapag nabuksan mo na ang iyong account, maaari mong punan gamit ang alinman sa aming mga option sa pagpapalitan kabilang ang: credit/debit card, Skrill, PayPal, Neteller, Thai QR code, at broker-to-broker transfer, deposits.`,
        titile_2: `Flexible na Lot Sizing`,
        description_2: `Walang limitasyon o mga pagsasalig sa laki ng trades, maaari mong ilagay ang mga trades na kasing liit ng isang micro lot (0.01). Ang aming flexible na lot sizing ay nagbibigay-daan sa iyo na subukan ang platform na may minimal na risk at pamahalaan ang laki ng iyong mga trades ayon sa iyong account balance.`,
      },
      section_5: {
        titile_1: `64 na CFDs & METALS `,
        description_1: `Mag-trade ng 64 na pairs na CFDs kasama ang 15 na major equity indices kabilang ang UK 100, S&P 500, AUS 200, at Dow Jones Index 24 oras isang araw na may spread na 1 punto.`,
        titile_2: `Leverage hanggang sa 1:3000`,
        description_2: `Ang mga account ay umaabot hanggang sa 1:3000 na leverage sa mga platform ng IUX Markets Meta Trader5. Maaaring gamitin ng mga mangangalakal ang mas mataas na leverage para sa kanilang trading style at makakuha ng pinakamahusay sa kanilang mga manual at automated trading strategies.`,
        titile_3: `Sinusuportahan ang lahat ng Major Account Currencies`,
        description_3: `Nakakaalam kami na may ilang mga mangangalakal na gusto na gamitin ang kanilang lokal na pera. Binibigyan namin ang mga mangangalakal ng option na magbukas ng account sa isa sa 10 na sinusuportahang base currencies: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.`,
      },
    },

    bonus: {
      box_1: {
        titile_1: `CFDs Trading `,
        titile_2: `Bonus Deposit $35`,
        titile_3: `Walang Komisyon`,
        titile_4: `Mabilis na Pagpapatupad ng Order`,
        titile_5: `1:1000 na Leverage`,
        titile_6: `Malalim na Liquidity`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `Bakit Pumili ng Standard+`,
        description_1: `Ang Standard+ Account mula sa IUX Markets ay nag-aalok ng bilis ng pagpapatupad na walang kapantay. Kasama dito ang isang all-inclusive spread na nagsisimula mula 0.5 PIPs, mga data center sa Equinix LD4 sa London, isang grupo ng hanggang 25 na mga pricing provider. Makikita mo kung bakit ang aming Standard+ account ay maaaring magbigay sa iyo ng hindi kapani-paniwala karanasan sa trading, buong araw-araw.`,
      },
      section_2: {
        titile_1: `Mabilis na Pagpapatupad ng Order`,
        description_1: `Ang server ng MetaTrader5 ng IUX Markets ay matatagpuan sa Equinix LD4 data center sa London. Ang data center na NY4, tinatawag na financial ecosystem, ay nagho-host ng higit sa 600 na buy and sell side firms, exchanges, trading venues, market data, at mga nagbibigay ng serbisyo. Ang server ng MetaTrader5 ay cross connected sa aming Communication Network upang tiyakin ang mababang latency at mabilis na pagpapatupad ng iyong mga trades sa pamamagitan ng IUX Markets trading environment. Ang mga server ng IUX Markets MetaTrader5 at 5 ay may latency na mas mababa sa 1 millisecond papunta sa mga pangunahing VPS providers, maging ito ay nasa NY4 data center o sa pamamagitan ng mga dedicated lines papunta sa malapit na data centres. Ang mababang latency na ito ay ideal para sa automated at high-frequency trading at scalping.`,
      },
      section_3: {
        titile_1: `Walang mga Pagsasalig sa Trading – Pinapayagan ang Scalping`,
        description_1: `Walang mga pagsasalig sa trading ang mga platform ng MetaTrader5 ng IUX Markets. Pinapayagan nito ang mga mangangalakal na maglagay ng mga order sa pagitan ng spread dahil wala itong minimum order distance at isang freeze level na 0. Ibig sabihin, maaaring ilagay ang mga order, kabilang ang stop loss orders, na kasing lapit ng maaari sa presyo ng merkado na gusto mo.
    
        \nMaaari rin maghedge ng positions ang mga mangangalakal dahil wala itong first in first out (FIFO) rule sa IUX Markets. Hindi nagbabayad ng margin ang mga mangangalakal sa mga hedged trades at nakakatamasa ng mga benepisyo ng margin netting.`,
        titile_2: `Level II Pricing – Market Depth`,
        description_2: `Ang market depth ay nagpapakita ng buong range ng mga executable prices na direkta mula sa aming mga price provider. Nagbibigay ang market depth ng kumpletong pagsiwalat sa liquidity ng bawat currency pair sa pamamagitan ng pagpapakita ng mga available volumes para sa bawat price level sa anumang oras. Ang mataas na liquidity, asynchronous spot prices, at mababang latency ay garantisadong ang pinakamasikip na possible spread.`,
      },
      section_4: {
        titile_1: `64 na CFDs & METALS `,
        description_1: `Mag-trade ng 64 na pairs na CFDs kasama ang 15 na major equity indices kabilang ang UK 100, S&P 500, AUS 200, at Dow Jones Index 24 oras isang araw na may spread na 1 punto.`,
        titile_2: `Leverage hanggang sa 1:1000`,
        description_2: `Ang mga account ay umaabot hanggang sa 1:1000 na leverage sa mga platform ng IUX Markets Meta Trader5. Maaaring gamitin ng mga mangangalakal ang mas mataas na leverage para sa kanilang trading style at makakuha ng pinakamahusay sa kanilang mga manual at automated trading strategies.`,
        titile_3: `Sinusuportahan ang lahat ng Major Account Currencies`,
        description_3: `Nakakaalam kami na may ilang mga mangangalakal na gusto na gamitin ang kanilang lokal na pera. Binibigyan namin ang mga mangangalakal ng option na magbukas ng account sa isa sa 10 na sinusuportahang base currencies: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.`,
      },
    },

    accecn: {
      box_1: {
        titile_1: `CFDs Trading `,
        titile_2: `Mag-trade ng higit sa 90 na instrumento`,
        titile_3: `Komisyon $3.5`,
        titile_4: `Mabilis na pagpapatupad ng order`,
        titile_5: `1:3000 na Leverage`,
        titile_6: `Spread na mababa hanggang 0.0 PIPs`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `Bakit Pumili ng Aming ECN Account?`,
        description_1: `Ang ECN Account mula sa IUX Markets ay nag-aalok ng bilis ng pagpapatupad na walang kapantay. Ito ay pinagsanib sa isang all-inclusive spread na nagsisimula mula 0.0 PIPs, mga server sa Equinix LD4 sa London, isang grupo ng hanggang 25 na mga pricing provider, at mauunawaan mo kung bakit ang aming standard account ay maaaring magbigay sa iyo ng hindi kapani-paniwala karanasan sa trading, buong araw-araw.`,
      },
      section_2: {
        titile_1: `Mabilis na pagpapatupad ng order`,
        description_1: `Ang server ng MetaTrader5 ng IUX Markets ay matatagpuan sa Equinix LD4 data center sa London. Ang data center na NY4, tinatawag na financial ecosystem, ay nagho-host ng higit sa 600 na buy and sell side firms, exchanges, trading venues, market data, at mga nagbibigay ng serbisyo. Ang server ng MetaTrader5 ay cross connected sa aming Communication Network upang tiyakin ang mababang latency at mabilis na pagpapatupad ng iyong mga trades sa pamamagitan ng IUX Markets trading environment. Ang mga server ng IUX Markets MetaTrader5 at 5 ay may latency na mas mababa sa 1 millisecond papunta sa mga pangunahing VPS providers, maging ito ay nasa NY4 data center o sa pamamagitan ng mga dedicated lines papunta sa malapit na data centres. Ang mababang latency na ito ay ideal para sa automated at high-frequency trading at scalping.`,
      },
      section_3: {
        titile_1: `Walang mga Pagsasalig sa Trading – Pinapayagan ang Scalping`,
        description_1: `Walang mga pagsasalig sa trading ang mga platform ng MetaTrader5 ng IUX Markets. Pinapayagan nito ang mga mangangalakal na maglagay ng mga order sa pagitan ng spread dahil wala itong minimum order distance at isang freeze level na 0, Ibig sabihin, maaaring ilagay ang mga order, kabilang ang stop loss orders, na kasing lapit ng maaari sa presyo ng merkado na gusto mo.
    
        \nMaaari rin maghedge ng positions ang mga mangangalakal dahil wala itong first in first out (FIFO) rule sa IUX Markets. Hindi nagbabayad ng margin ang mga mangangalakal sa mga hedged trades at nakakatamasa ng mga benepisyo ng margin netting.`,
        titile_2: `Level II Pricing – Market Depth`,
        description_2: `Ang market depth ay nagpapakita ng buong range ng mga executable prices na direkta mula sa aming mga price provider. Nagbibigay ang market depth ng kumpletong pagsiwalat sa liquidity ng bawat currency pair sa pamamagitan ng pagpapakita ng mga available volumes para sa bawat price level sa anumang oras. Ang mataas na liquidity, asynchronous spot prices, at mababang latency ay garantisadong ang pinakamasikip na possible spread.`,
      },
      section_4: {
        titile_1: `64 na CFDs & METALS `,
        description_1: `Mag-trade ng 64 na pairs na CFDs kasama ang 15 na major equity indices kabilang ang UK 100, S&P 500, AUS 200, at Dow Jones Index 24 oras isang araw na may spread na 1 punto.`,
        titile_2: `Leverage hanggang sa 1:3000`,
        description_2: `Ang mga account ay umaabot hanggang sa 1:3000 na leverage sa mga platform ng IUX Markets Meta Trader5. Maaaring gamitin ng mga mangangalakal ang mas mataas na leverage para sa kanilang trading style at makakuha ng pinakamahusay sa kanilang mga manual at automated trading strategies.`,
        titile_3: `Sinusuportahan ang lahat ng Major Account Currencies`,
        description_3: `Nakakaalam kami na may ilang mga mangangalakal na gusto na gamitin ang kanilang lokal na pera. Binibigyan namin ng option ang mga mangangalakal na magbukas ng account sa isa sa 10 na sinusuportahang base currencies: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.`,
      },
    },

    accraw: {
      box_1: {
        titile_1: `CFDs Trading `,
        titile_2: `Mag-trade ng higit sa 90 na instrumento`,
        titile_3: `Komisyon $6`,
        titile_4: `Mabilis na pagpapatupad ng order`,
        titile_5: `1:3000 na Leverage`,
        titile_6: `Spread na mababa hanggang 0.0 PIPs`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `Bakit Pumili ng Raw`,
        description_1: `Ang Raw Account mula sa IUX Markets ay nag-aalok ng bilis ng pagpapatupad na walang kapantay. Kasama na dito ang isang kumpletong spread na nagsisimula mula 0.0 PIPs, ang Equinix LD4 data center sa London, isang grupo ng hanggang 25 na mga pricing provider. Makakatanto ka kung bakit ang aming Raw account ay maaaring magbigay sa iyo ng walang kahulihulihang karanasan sa trading, buong araw araw-araw.`,
      },
      section_2: {
        titile_1: `Mabilis na pagpapatupad ng order`,
        description_1: `Ang server ng MetaTrader5 ng IUX Markets ay matatagpuan sa Equinix LD4 data center sa London. Ang data center na NY4, na tinatawag na isang financial ecosystem, ay nagho-host ng higit sa 600 na buy and sell side firms, exchanges, trading venues, market data, at mga nagbibigay ng serbisyo. Ang server ng MetaTrader5 ay cross connected sa aming Communication Network upang tiyakin ang mababang latency at mabilis na pagpapatupad ng iyong mga trades sa pamamagitan ng IUX Markets trading environment. Ang mga server ng IUX Markets MetaTrader5 at 5 ay may latency na mas mababa sa 1 millisecond papunta sa mga pangunahing VPS providers, maging ito ay nasa NY4 data center o sa pamamagitan ng mga dedicated lines papunta sa malapit na data centres. Ang mababang latency na ito ay ideal para sa automated at high-frequency trading at scalping.`,
      },
      section_3: {
        titile_1: `Walang mga Pagsasalig sa Trading – Pinapayagan ang Scalping`,
        description_1: `Walang mga pagsasalig sa trading ang mga platform ng MetaTrader5 ng IUX Markets. Pinapayagan nito ang mga mangangalakal na maglagay ng mga order sa pagitan ng spread dahil wala itong minimum order distance at isang freeze level na 0. Ibig sabihin, maaaring ilagay ang mga order, kabilang ang stop loss orders, na kasing lapit ng maaari sa presyo ng merkado na gusto mo.
    
        \nMaaari rin maghedge ng positions ang mga mangangalakal dahil wala itong first in first out (FIFO) rule sa IUX Markets. Hindi nagbabayad ng margin ang mga mangangalakal sa mga hedged trades at nakakatamasa ng mga benepisyo ng margin netting.`,
        titile_2: `Level II Pricing – Market Depth`,
        description_2: `Ang market depth ay nagpapakita ng buong range ng mga executable prices na direkta mula sa aming mga price provider. Nagbibigay ang market depth ng kumpletong pagsiwalat sa liquidity ng bawat currency pair sa pamamagitan ng pagpapakita ng mga available volumes para sa bawat price level sa anumang oras. Ang mataas na liquidity, asynchronous spot prices, at mababang latency ay garantisadong ang pinakamasikip na possible spread.`,
      },
      section_4: {
        titile_1: `64 na CFDs & METALS `,
        description_1: `Mag-trade ng 64 na pairs na CFDs kasama ang 15 na major equity indices kabilang ang UK 100, S&P 500, AUS 200, at Dow Jones Index 24 oras isang araw na may spread na 1 punto.`,
        titile_2: `Leverage hanggang sa 1:3000`,
        description_2: `Ang mga account ay umaabot hanggang sa 1:3000 na leverage sa mga platform ng IUX Markets Meta Trader5. Maaaring gamitin ng mga mangangalakal ang mas mataas na leverage para sa kanilang trading style at makakuha ng pinakamahusay sa kanilang mga manual at automated trading strategies.`,
        titile_3: `Sinusuportahan ang lahat ng Major Account Currencies`,
        description_3: `Nakakaalam kami na may ilang mga mangangalakal na gusto na gamitin ang kanilang lokal na pera. Binibigyan namin ng option ang mga mangangalakal na buksan ang isang account sa isa sa 10 na supported na base currencies: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.`,
      },
    },

    accpro: {
      box_1: {
        titile_1: `CFDs Trading `,
        titile_2: `Mag-trade ng higit sa 90 na instrumento`,
        titile_3: `Komisyon $0`,
        titile_4: `Mabilis na pagpapatupad ng order`,
        titile_5: `1:3000 na Leverage`,
        titile_6: `Spread na mababa hanggang 0.1 PIPs`,
        titile_7: ` Meta Trader5 `,
      },
      section_1: {
        titile_1: `Bakit Pumili ng Pro`,
        description_1: `Kung ikaw ay isang may karanasan at propesyonal na mangangalakal, ang Pro account ay angkop para sa iyo. Ang Pro account mula sa IUX Markets ay may kasamang raw spreads o kahit spread-free mula 0.0 pips. Nag-aalok din ito ng bilis ng pagpapatupad ng order na may instant execution para sa karamihan sa mga instrumento. Ang aming mga Pro account ay may execution na angkop para sa mga scalper, day-traders, at algotraders.`,
      },
      section_2: {
        titile_1: `Mabilis na pagpapatupad ng order`,
        description_1: `Ang server ng MetaTrader5 ng IUX Markets ay matatagpuan sa Equinix LD4 data center sa London. Ang data center na NY4, na tinatawag na isang financial ecosystem, ay nagho-host ng higit sa 600 na buy and sell side firms, exchanges, trading venues, market data, at mga nagbibigay ng serbisyo. Ang server ng MetaTrader5 ay cross connected sa aming Communication Network upang tiyakin ang mababang latency at mabilis na pagpapatupad ng iyong mga trades sa pamamagitan ng IUX Markets trading environment. Ang mga server ng IUX Markets MetaTrader5 at 5 ay may latency na mas mababa sa 1 millisecond papunta sa mga pangunahing VPS providers, maging ito ay nasa NY4 data center o sa pamamagitan ng mga dedicated lines papunta sa malapit na data centres. Ang mababang latency na ito ay ideal para sa automated at high-frequency trading at scalping.`,
      },
      section_3: {
        titile_1: `Walang mga Pagsasalig sa Trading – Pinapayagan ang Scalping`,
        description_1: `Walang mga pagsasalig sa trading ang mga platform ng MetaTrader5 ng IUX Markets. Pinapayagan nito ang mga mangangalakal na maglagay ng mga order sa pagitan ng spread dahil wala itong minimum order distance at isang freeze level na 0. Ibig sabihin, maaaring ilagay ang mga order, kabilang ang stop loss orders, na kasing lapit ng maaari sa presyo ng merkado na gusto mo.
    
        \nMaaari rin maghedge ng positions ang mga mangangalakal dahil wala itong first in first out (FIFO) rule sa IUX Markets. Hindi nagbabayad ng margin ang mga mangangalakal sa mga hedged trades at nakakatamasa ng mga benepisyo ng margin netting.`,
        titile_2: `Level II Pricing – Market Depth`,
        description_2: `Ang market depth ay nagpapakita ng buong range ng mga executable prices na direkta mula sa aming mga price provider. Nagbibigay ang market depth ng kumpletong pagsiwalat sa liquidity ng bawat currency pair sa pamamagitan ng pagpapakita ng mga available volumes para sa bawat price level sa anumang oras. Ang mataas na liquidity, asynchronous spot prices, at mababang latency ay garantisadong ang pinakamasikip na possible spread.`,
      },
      section_4: {
        titile_1: `64 na CFDs & METALS `,
        description_1: `Mag-trade ng 64 na pairs na CFDs kasama ang 15 na major equity indices kabilang ang UK 100, S&P 500, AUS 200, at Dow Jones Index 24 oras isang araw na may spread na 1 punto.`,
        titile_2: `Leverage hanggang sa 1:3000`,
        description_2: `Ang mga account ay umaabot hanggang sa 1:3000 na leverage sa mga platform ng IUX Markets Meta Trader5. Maaaring gamitin ng mga mangangalakal ang mas mataas na leverage para sa kanilang trading style at makakuha ng pinakamahusay sa kanilang mga manual at automated trading strategies.`,
        titile_3: `Sinusuportahan ang lahat ng Major Account Currencies`,
        description_3: `Nakakaalam kami na may ilang mga mangangalakal na gusto na gamitin ang kanilang lokal na pera. Binibigyan namin ng option ang mga mangangalakal na buksan ang isang account sa isa sa 10 na supported na base currencies: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.`,
      },
    },

    funding: {
      section_1: {
        title: `Paano Magdeposito/Magwithdraw?`,
        titile_1: `Magparehistro at mag-sign in`,
        titile_2: `Pumili ng iyong pinipiling bangko para sa pagpapatuloy ng deposito at withdrawal`,
        titile_3: `Walang bayad para sa deposito at withdrawal`,
        titile_4: `Mabilis na deposito at withdrawal sa loob ng ilang minuto`,
      },
      section_2: {
        title: `Mga Pagpipilian sa Pagdeposito at Pagwithdraw`,
        description: `Pinoprotektahan namin ang iyong financial information gamit ang encryption. Kaya't maaari kang magbayad online na may tiwala.`,
        option: `Mga Pagpipilian sa Pondo`,
        hot: `Sikat`,
        titile_1: `Credit at Debit`,
        titile_2: `Skrill`,
        titile_3: `Neteller`,
        titile_4: `Thai QR Payment`,
        titile_5: `Online Banking`,
      },
      section_3: {
        titile_1: `Proseso ng Pagbabayad`,
        description_1: `Para sa mas mabilis na proseso, inirerekomenda naming ideposito ang pondo mula sa Secure Client Area. Ang balanse ay ide-deposito ng real-time. Kung mayroon kang anumang problema sa pag-access sa Secure Client Area, mangyaring sundan ang mga tagubilin.`,
        titile_2: `Seguridad ng Pondo`,
        description_2: `Ang pondo ng kliyente ay naka-hold sa isang Segregated Client Trust Account. Ang mga electronic payment ay isinasagawa gamit ang SSL (Secure Socket Layer) technology at ini-encrypt upang tiyakin ang seguridad. Ang lahat ng impormasyon sa pagbabayad ay kumpidensiyal at ginagamit lamang para sa layunin ng pagpapanday ng iyong trading account sa IUX Markets.`,
        titile_3: `Bayad ng Bangko`,
        description_3: `Ang IUX Markets ay nagbibigay ng serbisyong walang bayad para sa anumang karagdagang bayad para sa deposito at withdrawal. Ngunit, mangyaring tandaan na maaaring magkaruon ng bayad sa mga pandaigdigang institusyon ng bangko. Wala ang IUX Markets na pananagutan sa anumang bayad mula sa mga bangko o mga tagapagbigay ng serbisyo.`,
        titile_4: `Bayad mula sa Ikatlong Partido`,
        description_4: `Hindi pinapayagan ng IUX Markets ang mga bayad mula sa ikatlong partido. Mangyaring tiyakin na lahat ng mga deposito sa iyong trading account ay mula sa isang bank account sa iyong pangalan. Tinatanggap ang mga bayad mula sa Joint Bank Account/ Credit Cards kung ang may-ari ng trading account ay isa sa mga partido sa Bank Account/ Credit Card.`,
      },
    },

    withdraw: {
      section_1: {
        title: `Para sa mas mabilis na proseso, lahat ng may-ari ng account ngayon ay kinakailangang magsumite ng mga kahilingan sa pag-withdraw mula sa kanilang Secure Client Area.`,
        content: `Kung mayroon kang anumang problema sa pag-access sa Secure Client Area, mangyaring sundan ang mga tagubilin.`,
      },
      section_2: {
        title: `Kung wala kang karanasan sa pag-wiwithdraw ng pondo, mangyaring sundan ang mga tagubilin:`,
        title_1: `Ang oras ng pagputol ng mga hiling na withdrawal ay 12:00 AEST/AEDT. Kung isinusumite mo ang iyong withdrawal bago ang oras na ito, ito ay maaaring asikasuhin sa araw na ito ito natanggap. Ngunit kung isinusumite mo ang iyong withdrawal pagkatapos ng oras na ito, ito ay maaaring asikasuhin sa sumunod na araw ng negosyo.`,
        title_2: `Ang IUX Markets ay nagbibigay ng serbisyong walang bayad para sa anumang karagdagang bayad para sa deposito at withdrawal. Ngunit, mangyaring tandaan na maaaring magkaruon ng bayad sa mga pandaigdigang institusyon ng bangko. Wala ang IUX Markets na pananagutan sa anumang bayad mula sa mga bangko o mga tagapagbigay ng serbisyo.`,
        title_3: `Para sa International Bank Wire Transfers, may bayad na katumbas ng salapi na kinakaltas ng aming bangko. Ang bayad na ito ay ibabawas mula sa iyong withdrawal amount. Maaaring umabot ito ng hanggang 14 na araw at maaaring magkaruon ng karagdagang bayarin depende sa iyong hurisdiksyon at ang bangko na iyong pipiliin.`,
        title_4: `Ang Credit / Debit Card withdrawals ay ini-process ng libreng bayad. Pagkatapos ng proseso, maaaring umabot ng 3-5 na araw na negosyo bago ito maipadala sa iyong credit card. Ngunit, mangyaring tandaan na sa ilang bihirang pagkakataon, maaaring umabot ito ng hanggang 10 na araw na negosyo depende sa bangko. *Mangyaring tandaan na ang Credit card withdrawals ay maaaring hindi available para sa lahat ng bansa.`,
        title_5: `Ang Paypal / Neteller / Skrill withdrawals ay dapat gawin mula sa parehong account kung saan unang ipinadala ang pondo. Ang mga transaksyong ito ay ini-process nang libreng bayad at agad pagkatapos ng proseso.`,
        title_6: `Kung ang iyong credit / debit card ay nag-expire na, mangyaring i-upload ang bagong card sa iyong client area upang magpatuloy sa paggamit ng mga serbisyong deposito at withdrawal nang walang anumang putol. Kung ang numero ng bagong card ay iba sa numero ng expired card, kailangan mong isumite ang isang sulat na inisyu mula sa bangko ng lumang card na nagpapatunay na ang bagong card ay inisyu bilang kapalit ng lumang card.`,
        title_7: `Kung ang iyong credit / debit card ay nawala, ninakaw, nasira, o kanselado na, kailangan mong isumite ang isang sulat na inisyu mula sa bangko ng lumang card na nagpapatunay na ang lumang card ay hindi na balido.`,
        title_8: `Ang IUX Markets, sa sariling pagpapasya, maaaring hilingin sa iyo na isumite ang mga suporting dokumento (halimbawa, resibo ng deposito para sa mga bayad na ini-process sa pamamagitan ng lumang card o statement ng card na nagpapakita ng mga transaksyong deposito) bago ilabas ang pondo sa bagong card.`,
        title_9: `Upang i-withdraw ang halaga na higit sa inilagak sa pamamagitan ng Verified by Visa / MasterCard Secure Credit / Debit Card, dapat mong gamitin ang ibang paraang deposito na nauna nang ginamit o ang opsyon ng bank wire transfer.`,
        title_10: `Kung iwi-withdraw mo ang iyong mga pondo na ini-deposito gamit ang Thai Internet Banking option papunta sa isang Thai bank account, kailangan mong i-upload ang malinaw na larawan o kulay scan na kopya ng iyong Book bank. Pagkatapos ma-process, maaaring umabot ng isang araw na negosyo bago ito maabot ang iyong account.`,
        title_11: `Kung iwi-withdraw mo ang iyong mga pondo na ini-deposito gamit ang Thai Internet Banking option papunta sa isang Thai bank account, kailangan mong i-upload ang malinaw na larawan o kulay scan na kopya ng iyong Book bank. Pagkatapos ma-process, maaaring umabot ng isang araw na negosyo bago ito maabot ang iyong account.`,
        title_12: `Ang IUX Markets ay hindi nagsasagawa ng mga pagbabayad sa mga third party. Mangyaring tiyakin na lahat ng mga kahilingan sa withdrawal mula sa iyong trading account ay papunta sa isang bank account o source sa iyong pangalan. Tinatanggap ang mga pagbabayad sa Joint Bank Accounts / Credit Cards kung ang may-ari ng trading account ay isa sa mga partido sa Bank Account / Credit Card.`,
      },
    },
  },
  ja: {
    account_create_demo: `デモアカウントは、取引、テスト戦略を練習し、プラットフォームに慣れることができるリスクのない投資です。`,
    account_create_success_islamic: `このアカウントは自動的にスワップフリーステータスに設定されています。すぐに取引用のパスワードを保存してください。セキュリティの理由でメールで送信できません。`,
    account_create_success: `もう一晩の料金はありません。 スワップフリーステータスは、取引活動によって決定されます。 アクティブなスワップフリーステータスを維持するには、主に1日以内に取引する必要があります。`,
    open_an_account: `アカウントを開きます`,
    low_risk: `リスクが低い`,
    high_risk: `リスクが高い`,
    medium_risk: `中リスク`,
    standard_account_description: `最も人気のある！ あらゆるタイプのトレーダーのための優れたアカウント。`,
    standard_bonus_account_description: `インスタント実行アカウント。 取引委員会なしの優れた塗りつぶし。`,
    raw_account_description: `最低の生のスプレッドと低い固定委員会。`,
    pro_account_description: `手数料無料のプロフェッショナルアカウント0％。`,
    button: {
      title: `今すぐ入金してください`,
    },
    cover: {
      accover: {
        title: `口座[アカウント]状況`,
        description: `IUX Marketsは、すべてのアカウントタイプでスプレッドインストゥルメントが低いことを提供します。 すべてのトレーダーに適しています。 好みのプラットフォームを選択して開始するだけです。`,
      },
      accstd: {
        title: `標準`,
        description: `独自のBridge Aggregator Mixing Chosed Tier-1価格サプライヤーを使用すると、世界で最も人気のある取引プラットフォームの1つで低いスプレッドアドバンテージを獲得することができます。`,
      },
      acccnt: {
        title: `セント`,
        description: `すべての取引スタイルに適した100倍未満の投資ユニットを持つ初心者アカウント。 お好みの取引戦略を使用できます。 標準アカウントで同じ非常に低いスプレッドがあるため、利益を上げるチャンスが増えます。`,
      },
      accecn: {
        title: `ECN`,
        description: `IUX Marketsの標準アカウントでシンプルに保ちます。 コミッションなしで、すべての包括的なスプレッドで、スプレッド価格の低価格のすべての利点を体験してください。`,
      },
      accraw: {
        title: `生`,
        description: `IUX Markets Rawアカウントでシンプルに保ちます。 すべての包括的なスプレッドで生の価格設定のすべての利点を体験してください。`,
      },
      accpro: {
        title: `プロ`,
        description: `経験豊富でプロフェッショナルなトレーダーに適しており、あらゆる取引スタイル、ゼロコミッションと低いスプレッド、およびオープンできる注文の制限はありません。`,
      },
      accbonus: {
        title: `標準+`,
        description: `IUX Markets Standard+アカウントでシンプルに保ちます。 包括的なスプレッドとコミッションなしで、スプレッド価格の低価格のすべての利点を体験してください。`,
      },
      deposit: {
        title: `アカウント資金`,
        description: `IUX Marketsは、10種類のベースCFDで15を超える柔軟な資金調達オプションを提供しています。 さまざまな方法を使用して、即座に無料で無料で入金します。`,
      },
      withdraw: {
        title: `ファンド撤回`,
        description: `IUX Marketsの標準アカウントでシンプルに保ちます。 すべての包括的なスプレッドとコミッションなしで、私たちの低価格のすべての利点を体験してください。`,
      },
    },
    menu: {
      menu_1: `口座[アカウント]状況`,
    },
    section_1: {
      title: `なぜ私たちの標準を選ぶのですか？`,
      description: `最低のスプレッドを提供します。 私たちの平均EUR/USDスプレッドは、手数料なしの0.2ピップです。 IUX Markets Standardアカウントは、最大25の機関の成績ソースの全体的な価格設定ソースを持つデイトレーダー、短期トレーダー、専門家トレーダー向けに作成されています。 IUX Markets Metatrader5プラットフォームは、世界中のトレーダーにとって好ましい選択です。`,
    },
    std: {
      box_1: {
        titile_1: `CFDS取引`,
        titile_2: `低価格設定`,
        titile_3: `0.2ピップから広がります`,
        titile_4: `高速注文実行`,
        titile_5: `1：3000レバレッジ`,
        titile_6: `深い流動性`,
        titile_7: `Metatrader 5`,
      },
      section_1: {
        titile_1: `標準アカウントは、最低のスプレッドを提供します。 当社の平均EUR/USDスプレッドは0.2 PIPSであり、委員会のないIUX Marketsの標準アカウントがないため、Day Traders、短期トレーダー、および深い流動性と迅速な実行を備えた専門家トレーダー向けに作成されています。 IUX Markets Metatrader5プラットフォームは、世界中のトレーダーにとって好ましい選択です。`,
        titile_2: `当社のMetatrader5サーバーは、ロンドンのEquinix LD4データセンターにあり、ブリッジデータセンターに近づき、不必要なレイテンシを排除するのに役立ちます。 Metatrader5は、世界中のデイトレーダー、短期トレーダー、専門家のトレーダーに最適です。`,
      },
      section_2: {
        titile_1: `低価格設定`,
        description_1: `IUX Marketsは、クライアントにスプレッド価格の低い価格を提供することにより、MT5プラットフォームを通じて市場価格と最高の取引条件を提供することができます。 スプレッド価格の低い環境により、世界をリードする実行会場とほぼ同じレベルで施設のグレードの価格設定で取引することができます。 実行可能なストリーミング価格（ESP）は、IUXマーケットの価格設定プロバイダーから当社に送信され、ディールデスク、価格操作、またはレクオットなしでクライアントに提供されます。 IUX Marketsは、大量のトレーダー、スキャルパー、ロボットに最適なCFDSプロバイダーであり、緊密なスプレッドと最高の実行を提供します。`,
      },
      section_3: {
        titile_1: `高速注文実行`,
        description_1: `IUX Markets Metatrader5サーバーは、ロンドンのEquinix LD4データセンターにあります。 金融エコシステムと呼ばれるNY4データセンターは、600を超える売買企業、取引所、取引会場、市場データ、サービスプロバイダーをホストしています。 Metatrader5サーバーは、通信ネットワークに接続されており、IUX市場の取引環境を介して取引の低下と迅速な実行を確保しています。 IUX Markets Metatrader5および5 Trade Serverは、NY4データセンターで、または近くのデータセンターに専用のラインを介して、主要なVPSプロバイダーに1ミリ秒未満のレイテンシを持っています。`,
        titile_2: `0.2ピップから広がります`,
        description_2: `IUX Marketsは、SpreadsがMetatrader5プラットフォームで0.2 Pipsで始まるすべてのCFDSブローカーの中で最も厳しい広がりの一部であり、EurusDの平均は24時間年中無休です。 これは現在、世界中で最も厳しい平均EURUSDスプレッドの1つです。 当社の価格は、最大25の異なる価格プロバイダーの混合に関連しています。 特にニュースアナウンスなどの高ボラティリティ時間の間、私たちは常にクライアントにとって最高の価格を調達し、スプレッドを厳しく保つことができます。`,
        titile_3: `取引の制限はありません - スカルピングは許可されています`,
        description_3: `IUX Markets Meta Trader5プラットフォームには、取引に制限がありません。 最小注文距離とフリーズレベル0のために、トレーダーがスプレッド間に注文を配置できるようにすることができます。 \ ntradersは、最初のアウト（FIFO）ルールにIUX市場にないため、ポジションをヘッジすることもできます。 トレーダーは、ヘッジされた取引にマージンを支払うことはなく、マージンネットのメリットを享受しません。`,
        titile_4: `レベルIIの価格設定 - 市場の深さ`,
        description_4: `市場の深さは、当社の価格プロバイダーから直接来る実行可能な価格の全範囲を示しています。 市場の深さは、各価格レベルで利用可能なボリュームをいつでも表示することにより、各通貨ペアの流動性の完全な透明性を提供します。 高流動性、非同期スポット価格、および低潜伏期は、可能な限り厳しい広がりを保証します。`,
      },
      section_4: {
        titile_1: `柔軟な資金と撤退オプション`,
        description_1: `アカウントを開設したら、クレジット/デビットカード、Skrill、PayPal、Neteller、Thai QRコード、ブローカーへのブローカー譲渡、預金など、資金調達オプションを使用して資金を提供できます。`,
        titile_2: `柔軟なロットサイジング`,
        description_2: `貿易規模に制限や制限はありません。1つのマイクロロット（0.01）と同じくらい小さい取引を行うことができます。 当社の柔軟なロットサイジングにより、リスクを最小限に抑えてプラットフォームを試用することができ、アカウントの残高に従って取引サイズを管理できます。`,
      },
      section_5: {
        titile_1: `64 CFDSおよび金属`,
        description_1: `64 CFDSペアと、英国100、S＆P 500、AUS 200、ダウジョーンズインデックスを含む15の主要なエクイティインデックスを1日24時間、1ポイントのスプレッドを取引します。`,
        titile_2: `最大1：3000を活用します`,
        description_2: `アカウントは、IUX Markets Meta Trader5プラットフォームで1：3000のレバレッジになります。 トレーダーは、より高いレバレッジを使用して取引スタイルに合わせて、マニュアルおよび自動化された取引戦略を最大限に活用できます。`,
        titile_3: `すべての主要な口座通貨がサポートされています`,
        description_3: `一部のトレーダーは、地元の通貨で対処することを好むことを知っています。 トレーダーに、サポートされている10の基本通貨の1つでアカウントを開設するオプションを提供します：USD、AUD、EUR、GBP、SGD、NZD、JPY、CHF、HKD、CAD。`,
      },
    },
    bonus: {
      box_1: {
        titile_1: `CFDS取引`,
        titile_2: `ボーナスデポジット35ドル`,
        titile_3: `委員会無料`,
        titile_4: `高速注文実行`,
        titile_5: `1：1000レバレッジ`,
        titile_6: `深い流動性`,
        titile_7: `Metatrader 5`,
      },
      section_1: {
        titile_1: `標準+を選択する理由`,
        description_1: `IUX Marketsの標準+アカウントは、ライバルなしで実行速度を提供します。 0.5ピップから始まる包括的なスプレッド、ロンドンのEquinix LD4データセンター、最大25人の価格設定プロバイダーのグループが含まれていました。 私たちの標準+アカウントが毎日、比類のない取引体験を提供できる理由を理解するでしょう。`,
      },
      section_2: {
        titile_1: `高速注文実行`,
        description_1: `IUX Markets Metatrader5サーバーは、ロンドンのEquinix LD4データセンターにあります。 金融エコシステムと呼ばれるNY4データセンターは、600を超える売買企業、取引所、取引会場、市場データ、サービスプロバイダーをホストしています。 Metatrader5サーバーは、通信ネットワークに接続されており、IUX市場の取引環境を介して取引の低下と迅速な実行を確保しています。 IUX Markets Metatrader5および5 Trade Serverは、NY4データセンターで、または近くのデータセンターに専用のラインを介して、主要なVPSプロバイダーに1ミリ秒未満のレイテンシを持っています。`,
      },
      section_3: {
        titile_1: `取引の制限はありません - スカルピングは許可されています`,
        description_1: `IUX Markets Meta Trader5プラットフォームには、取引に制限がありません。 最小注文距離とフリーズレベル0のために、トレーダーがスプレッド間に注文を配置できるようにすることができます。 \ ntradersは、最初のアウト（FIFO）ルールにIUX市場にないため、ポジションをヘッジすることもできます。 トレーダーは、ヘッジされた取引にマージンを支払うことはなく、マージンネットのメリットを享受しません。`,
        titile_2: `レベルIIの価格設定 - 市場の深さ`,
        description_2: `市場の深さは、当社の価格プロバイダーから直接来る実行可能な価格の全範囲を示しています。 市場の深さは、各価格レベルで利用可能なボリュームをいつでも表示することにより、各通貨ペアの流動性の完全な透明性を提供します。 高流動性、非同期スポット価格、および低潜伏期は、可能な限り厳しい広がりを保証します。`,
      },
      section_4: {
        titile_1: `64 CFDSおよび金属`,
        description_1: `貿易64 CFDSペアと、UK 100、S＆P 500、AUS 200、Dow Jonesインデックスを含む15の主要なエクイティインデックスと、1ポイントのスプレッドで24時間。`,
        titile_2: `最大1：1000を活用します`,
        description_2: `アカウントは、IUX Markets Meta Trader5プラットフォームで1：1000のレバレッジになります。 トレーダーは、より高いレバレッジを使用して取引スタイルに合わせて、マニュアルおよび自動化された取引戦略を最大限に活用できます。`,
        titile_3: `すべての主要な口座通貨がサポートされています`,
        description_3: `一部のトレーダーは、地元の通貨で対処することを好むことを知っています。 トレーダーに、サポートされている10の基本通貨の1つでアカウントを開設するオプションを提供します：USD、AUD、EUR、GBP、SGD、NZD、JPY、CHF、HKD、CAD。`,
      },
    },
    accecn: {
      box_1: {
        titile_1: `CFDS取引`,
        titile_2: `90を超える楽器を取引します`,
        titile_3: `委員会$ 3.5`,
        titile_4: `高速注文実行`,
        titile_5: `1：3000レバレッジ`,
        titile_6: `0.0ピップの低さまで広がります`,
        titile_7: `Metatrader 5`,
      },
      section_1: {
        titile_1: `なぜECNアカウントを選択するのですか？`,
        description_1: `IUX MarketsのECNアカウントは、ライバルなしで実行速度を提供します。 これを、0.0ピップから始まるすべての包括的なスプレッド、ロンドンのEquinix LD4サーバー、最大25の価格設定プロバイダーのグループと組み合わせることができます。当社の標準アカウントが毎日、比類のない取引体験を提供できる理由を理解できます。`,
      },
      section_2: {
        titile_1: `高速注文実行`,
        description_1: `IUX Markets Metatrader5サーバーは、ロンドンのEquinix LD4データセンターにあります。 金融エコシステムと呼ばれるNY4データセンターは、600を超える売買企業、取引所、取引会場、市場データ、サービスプロバイダーをホストしています。 Metatrader5サーバーは、通信ネットワークに接続されており、IUX市場の取引環境を介して取引の低下と迅速な実行を確保しています。 IUX Markets Metatrader5および5 Trade Serverは、NY4データセンターで、または近くのデータセンターに専用のラインを介して、主要なVPSプロバイダーに1ミリ秒未満のレイテンシを持っています。`,
      },
      section_3: {
        titile_1: `取引の制限はありません - スカルピングは許可されています`,
        description_1: `IUX Markets Meta Trader5プラットフォームには、取引に制限がありません。 トレーダーは、最小注文距離とフリーズレベル0のためにスプレッド間に注文を行うことができます。これは、停止注文を含む注文を、好きなように市場価格に近づけることができることを意味します。 IUX市場を備えた最初のアウト（FIFO）ルールでは最初はありません。 トレーダーは、ヘッジされた取引にマージンを支払うことはなく、マージンネットのメリットを享受しません。`,
        titile_2: `レベルIIの価格設定 - 市場の深さ`,
        description_2: `市場の深さは、当社の価格プロバイダーから直接来る実行可能な価格の全範囲を示しています。 市場の深さは、各価格レベルで利用可能なボリュームをいつでも表示することにより、各通貨ペアの流動性の完全な透明性を提供します。 高流動性、非同期スポット価格、および低潜伏期は、可能な限り厳しい広がりを保証します。`,
      },
      section_4: {
        titile_1: `64 CFDSおよび金属`,
        description_1: `64 CFDSペアと、英国100、S＆P 500、AUS 200、ダウジョーンズインデックスを含む15の主要なエクイティインデックスを1日24時間、1ポイントのスプレッドを取引します。`,
        titile_2: `最大1：3000を活用します`,
        description_2: `アカウントは、IUX Markets Meta Trader5プラットフォームで1：3000のレバレッジになります。 トレーダーは、より高いレバレッジを使用して取引スタイルに合わせて、マニュアルおよび自動化された取引戦略を最大限に活用できます。`,
        titile_3: `すべての主要な口座通貨がサポートされています`,
        description_3: `一部のトレーダーは、地元の通貨で対処することを好むことを知っています。 トレーダーに、サポートされている10の基本通貨の1つでアカウントを開設するオプションを提供します：USD、AUD、EUR、GBP、SGD、NZD、JPY、CHF、HKD、CAD。`,
      },
    },
    accraw: {
      box_1: {
        titile_1: `CFDS取引`,
        titile_2: `90を超える楽器を取引します`,
        titile_3: `手数料7ドル`,
        titile_4: `高速注文実行`,
        titile_5: `1：3000レバレッジ`,
        titile_6: `0.0ピップの低さまで広がります`,
        titile_7: `Metatrader 5`,
      },
      section_1: {
        titile_1: `なぜ生を選ぶのか`,
        description_1: `IUX MarketsのRAWアカウントは、ライバルなしで実行速度を提供します。 0.0ピップから始まる包括的なスプレッド、ロンドンのEquinix LD4データセンター、最大25人の価格設定プロバイダーのグループが含まれていました。 私たちのRAWアカウントが毎日ずっと比類のない取引体験を提供できる理由を理解するでしょう。`,
      },
      section_2: {
        titile_1: `高速注文実行`,
        description_1: `IUX Markets Metatrader5サーバーは、ロンドンのEquinix LD4データセンターにあります。 金融エコシステムと呼ばれるNY4データセンターは、600を超える売買企業、取引所、取引会場、市場データ、サービスプロバイダーをホストしています。 Metatrader5サーバーは、通信ネットワークに接続されており、IUX市場の取引環境を介して取引の低下と迅速な実行を確保しています。 IUX Markets Metatrader5および5 Trade Serverは、NY4データセンターで、または近くのデータセンターに専用のラインを介して、主要なVPSプロバイダーに1ミリ秒未満のレイテンシを持っています。`,
      },
      section_3: {
        titile_1: `取引の制限はありません - スカルピングは許可されています`,
        description_1: `IUX Markets Meta Trader5プラットフォームには、取引に制限がありません。 トレーダーは、最小注文距離とフリーズレベル0のためにスプレッド間に注文を行うことができます。これは、停止注文を含む注文を、好きなように市場価格に近づけることができることを意味します。 IUX市場を備えた最初のアウト（FIFO）ルールでは最初はありません。 トレーダーは、ヘッジされた取引にマージンを支払うことはなく、マージンネットのメリットを享受しません。`,
        titile_2: `レベルIIの価格設定 - 市場の深さ`,
        description_2: `市場の深さは、当社の価格プロバイダーから直接来る実行可能な価格の全範囲を示しています。 市場の深さは、各価格レベルで利用可能なボリュームをいつでも表示することにより、各通貨ペアの流動性の完全な透明性を提供します。 高流動性、非同期スポット価格、および低潜伏期は、可能な限り厳しい広がりを保証します。`,
      },
      section_4: {
        titile_1: `64 CFDSおよび金属`,
        description_1: `貿易64 CFDSペアと、UK 100、S＆P 500、AUS 200、Dow Jonesインデックスを含む15の主要なエクイティインデックスと、1ポイントのスプレッドで24時間。`,
        titile_2: `最大1：3000を活用します`,
        description_2: `アカウントは、IUX Markets Meta Trader5プラットフォームで1：3000のレバレッジになります。 トレーダーは、より高いレバレッジを使用して取引スタイルに合わせて、マニュアルおよび自動化された取引戦略を最大限に活用できます。`,
        titile_3: `すべての主要な口座通貨がサポートされています`,
        description_3: `一部のトレーダーは、地元の通貨で対処することを好むことを知っています。 トレーダーに、サポートされている10の基本通貨の1つでアカウントを開設するオプションを提供します：USD、AUD、EUR、GBP、SGD、NZD、JPY、CHF、HKD、CAD。`,
      },
    },
    accpro: {
      box_1: {
        titile_1: `CFDS取引`,
        titile_2: `90を超える楽器を取引します`,
        titile_3: `手数料$ 0`,
        titile_4: `高速注文実行`,
        titile_5: `1：3000レバレッジ`,
        titile_6: `0.1ピップの低さまで広がります`,
        titile_7: `Metatrader 5`,
      },
      section_1: {
        titile_1: `なぜProを選択するのか`,
        description_1: `あなたが経験豊富でプロのトレーダーである場合、Proアカウントはあなたにぴったりです。 IUX市場のProアカウントには、生のスプレッドが備わっているか、0.0ピップからスプレッドを拡大します。 また、大部分の機器の即時実行で実行される注文速度も提供します。 私たちのProアカウントには、メス、デイトレーダー、アルゴトラダーに合わせて実行されています。`,
      },
      section_2: {
        titile_1: `高速注文実行`,
        description_1: `IUX Markets Metatrader5サーバーは、ロンドンのEquinix LD4データセンターにあります。 金融エコシステムと呼ばれるNY4データセンターは、600を超える売買企業、取引所、取引会場、市場データ、サービスプロバイダーをホストしています。 Metatrader5サーバーは、通信ネットワークに接続されており、IUX市場の取引環境を介して取引の低下と迅速な実行を確保しています。 IUX Markets Metatrader5および5 Trade Serverは、NY4データセンターで、または近くのデータセンターに専用のラインを介して、主要なVPSプロバイダーに1ミリ秒未満のレイテンシを持っています。`,
      },
      section_3: {
        titile_1: `取引の制限はありません - スカルピングは許可されています`,
        description_1: `IUX Markets Meta Trader5プラットフォームには、取引に制限がありません。 トレーダーは、最小注文距離と凍結レベル0のためにスプレッド間に注文を行うことができます。これは、停止注文を含む注文を、好きなように市場価格に近づけることができることを意味します。 \ ntradersは、最初のアウト（FIFO）ルールにIUX市場にないため、ポジションをヘッジすることもできます。 トレーダーは、ヘッジされた取引にマージンを支払うことはなく、マージンネットのメリットを享受しません。`,
        titile_2: `レベルIIの価格設定 - 市場の深さ`,
        description_2: `市場の深さは、当社の価格プロバイダーから直接来る実行可能な価格の全範囲を示しています。 市場の深さは、各価格レベルで利用可能なボリュームをいつでも表示することにより、各通貨ペアの流動性の完全な透明性を提供します。 高流動性、非同期スポット価格、および低潜伏期は、可能な限り厳しい広がりを保証します。`,
      },
      section_4: {
        titile_1: `64 CFDSおよび金属`,
        description_1: `64 CFDSペアと、英国100、S＆P 500、AUS 200、ダウジョーンズインデックスを含む15の主要なエクイティインデックスを1日24時間、1ポイントのスプレッドを取引します。`,
        titile_2: `最大1：3000を活用します`,
        description_2: `アカウントは、IUX Markets Meta Trader5プラットフォームで1：3000のレバレッジになります。 トレーダーは、より高いレバレッジを使用して取引スタイルに合わせて、マニュアルおよび自動化された取引戦略を最大限に活用できます。`,
        titile_3: `すべての主要な口座通貨がサポートされています`,
        description_3: `一部のトレーダーは、地元の通貨で対処することを好むことを知っています。 トレーダーに、サポートされている10の基本通貨の1つでアカウントを開設するオプションを提供します：USD、AUD、EUR、GBP、SGD、NZD、JPY、CHF、HKD、CAD。`,
      },
    },
    funding: {
      section_1: {
        title: `預け入れ/撤回する方法は？`,
        titile_1: `登録とサインイン`,
        titile_2: `預金と撤回を進めるには、お好みの銀行を選択してください`,
        titile_3: `デポジットと撤退のためのゼロフィー`,
        titile_4: `数分で迅速なデポジットと撤退`,
      },
      section_2: {
        title: `デポジットと撤回オプション`,
        description: `暗号化により財務情報を安全に保ちます。 そのため、自信を持ってオンラインで支払うことができます。`,
        option: `資金調達オプション`,
        hot: `人気のある`,
        titile_1: `クレジットとデビット`,
        titile_2: `スクリル`,
        titile_3: `ネター`,
        titile_4: `タイQR支払い`,
        titile_5: `オンラインバンキング`,
      },
      section_3: {
        titile_1: `支払いプロセス`,
        description_1: `より速い処理のために、安全なクライアントエリアから資金を預けることをお勧めします。 残高はリアルタイムで堆積します。 安全なクライアント領域にアクセスするのに問題がある場合は、指示に従ってください。`,
        titile_2: `資金のセキュリティ`,
        description_2: `クライアントファンドは、分離されたクライアントトラストアカウントに保有されます。 電子支払いは、SSL（セキュアソケットレイヤー）テクノロジーを使用して処理され、セキュリティを確保するために暗号化されます。 すべての支払い情報は機密であり、IUX市場で取引口座に資金を提供する目的でのみ使用されます。`,
        titile_3: `銀行手数料`,
        description_3: `IUX市場は、預金と引き出しの追加料金に対してゼロ料金を提供します。 ただし、請求は国際銀行機関の支払いで発生する可能性があることを認識する必要があります。 IUX市場は、銀行またはプロバイダーの料金の料金について責任を負いません。`,
        titile_4: `サードパーティの支払い`,
        description_4: `IUX市場では、第三者からの支払いを許可していません。 あなたの名前で銀行口座からすべての預金があなたの取引口座に預金することを確認してください。 取引口座保有者が銀行口座/クレジットカードの当事者の1つである場合、共同銀行口座/クレジットカードからの支払いは許可されます。`,
      },
    },
    withdraw: {
      section_1: {
        title: `より速い処理のために、すべてのアカウント所有者は、安全なクライアント領域内からの引き出しリクエストを提出する必要があります。`,
        content: `安全なクライアント領域にアクセスするのに問題がある場合は、指示に従ってください。`,
      },
      section_2: {
        title: `資金の撤回の経験がない場合は、指示に従ってください。`,
        title_1: `撤退リクエストは、削減時間は12:00 aest/aedtです。 この時間より前に撤退を提出すると、受け取った日に処理されます。 この時間以降に撤退を提出すると、次の営業日に処理されます。`,
        title_2: `IUX市場は、預金と引き出しの追加料金に対してゼロ料金を提供します。 ただし、請求は国際銀行機関の支払いで発生する可能性があることを認識する必要があります。 IUX市場は、銀行またはプロバイダーの料金の料金について責任を負いません。`,
        title_3: `国際銀行の電信送金の場合、通貨同等の処理手数料が当社の銀行機関によって請求されます。 この料金は、引き出し額から差し引かれます。 これには最大14日かかり、管轄権と選択した銀行に応じて追加の中間費用を伴う場合があります。`,
        title_4: `クレジット /デビットカードの引き出しは無料で処理されます。 処理されると、クレジット /デビットカードの引き出しは、クレジットカードに到達するのに3〜5営業日かかる場合があります。 ただし、まれな場合には、銀行の終わりに応じて最大10営業日かかる場合があることを認識する必要があります。 *すべての国でクレジットカードの引き出しが利用できない場合があることに注意してください。`,
        title_5: `PayPal / Neteller / Skrillの引き出しは、最初に資金が送られた場所から同じ口座から作成する必要があります。 これらのトランザクションは無料で処理され、処理されるとすぐになります。`,
        title_6: `クレジット /デビットカードがすでに期限切れになっている場合は、クライアントエリアに新しいカードをアップロードして、中断することなくデポジットおよび引き出しサービスを引き続き使用してください。 新しいカードの番号が期限切れのカードの番号と異なる場合は、古いカードが古いカードを交換して発行されたことを確認する古いカードの発行者銀行から発行された手紙を提出する必要があります。`,
        title_7: `クレジット /デビットカードが紛失、盗難、破損、またはキャンセルされた場合、古いカードがもはや有効でないことを確認する古いカードの発行者銀行から発行された手紙を提出する必要があります`,
        title_8: `IUX市場は、独自の裁量で、新しいカードに資金をリリースする前に、サポートドキュメント（たとえば、古いカードまたは預金取引を示すカードまたはカードの声明を介して処理された支払いの預金領収書）を提出するように求める場合があります。`,
        title_9: `Visa / MasterCard Secure Credit / Debitカードによって検証された金額よりも多くの金額を引き出すには、以前に使用された別のデポジット方法または銀行電信送金オプションを使用する必要があります。`,
        title_10: `タイのインターネットバンキングオプションを介して預け入れられた資金をタイの銀行口座に撤回している場合は、ブックバンクの明確な画像または色のスキャンコピーをアップロードする必要があります。 処理されると、資金はアカウントに到達するのに1営業日かかる場合があります。`,
        title_11: `IUX市場は、第三者への支払いを処理しません。 取引口座からのすべての引き出し要求が銀行口座またはあなたの名前のソースに送られるようにしてください。 銀行口座 /クレジットカードへの支払いは、取引口座保有者が銀行口座 /クレジットカードの当事者の所有者である場合、受け入れられます。`,
        title_12: `IUX市場は、第三者への支払いを処理しません。 取引口座からのすべての引き出し要求が銀行口座またはあなたの名前のソースに送られるようにしてください。 銀行口座 /クレジットカードへの支払いは、取引口座保有者が銀行口座 /クレジットカードの当事者の所有者である場合、受け入れられます。`,
      },
    },
  },
  es: {
    account_create_demo:
      "Tu cuenta demo es una inversión sin riesgo donde puedes practicar trading, probar estrategias y familiarizarte con nuestra plataforma.",
    account_create_success_islamic:
      "Esta cuenta se establece automáticamente en estado Swap-Free. Guarda inmediatamente tu contraseña de trading. No se puede enviar por correo electrónico por razones de seguridad.",
    account_create_success:
      "No más cargos nocturnos. Tu estado Swap-Free se determina por tu actividad de trading. Para mantener un estado Swap-Free activo, debes operar principalmente dentro del día.",
    open_an_account: "Abrir una cuenta",
    low_risk: "Bajo riesgo",
    high_risk: "Alto riesgo",
    medium_risk: "Riesgo medio",
    standard_account_description:
      "¡La más popular! Una gran cuenta para todo tipo de traders.",
    standard_bonus_account_description:
      "Nuestra cuenta de ejecución instantánea. Excelente llenado sin comisión de trading.",
    raw_account_description: "Los spreads más bajos y una comisión fija baja.",
    pro_account_description: "Cuenta profesional con comisión del 0%.",
    button: {
      title: "Depositar ahora",
    },
    cover: {
      accover: {
        title: "Resumen de la Cuenta",
        description:
          "IUX Markets ofrece instrumentos con bajos spreads en todos los tipos de cuentas; adecuado para todos los traders. Simplemente elige tu plataforma preferida y comienza.",
      },
      accstd: {
        title: "Estándar",
        description:
          "Con nuestro agregador de puentes propietario que mezcla proveedores de precios de primer nivel seleccionados, puedes obtener la ventaja de Low Spread en una de las plataformas de trading más populares del mundo.",
      },
      acccnt: {
        title: "Cent",
        description:
          "Una cuenta para principiantes con menos de 100 unidades de inversión, adecuada para todos los estilos de trading. Puedes usar cualquier estrategia de trading que prefieras. Con el mismo spread extremadamente bajo de la cuenta Estándar, tienes más oportunidades de obtener ganancias.",
      },
      accecn: {
        title: "ECN",
        description:
          "Manténlo simple con la cuenta Estándar de IUX Markets. Experimenta todos los beneficios de nuestra Low Spread Pricing con un spread todo incluido sin comisiones.",
      },
      accraw: {
        title: "Raw",
        description:
          "Manténlo simple con la cuenta Raw de IUX Markets. Experimenta todos los beneficios de nuestra Raw Pricing con un spread todo incluido.",
      },
      accpro: {
        title: "Pro",
        description:
          "Adecuado para traders experimentados y profesionales, para cualquier estilo de trading, con cero comisiones, bajo spread y sin límite en las órdenes que se pueden abrir.",
      },
      accbonus: {
        title: "Estándar+",
        description:
          "Manténlo simple con la cuenta Estándar+ de IUX Markets. Experimenta todos los beneficios de nuestra Low Spread Pricing con un spread todo incluido y sin comisiones.",
      },
      deposit: {
        title: "Financiación de la Cuenta",
        description:
          "IUX Markets ofrece más de 15 opciones de financiación flexibles en 10 CFDs base diferentes. Deposita al instante y gratis usando una variedad de métodos.",
      },
      withdraw: {
        title: "Retiro de Fondos",
        description:
          "Manténlo simple con la cuenta Estándar de IUX Markets. Experimenta todos los beneficios de nuestra Low Pricing con un spread todo incluido y sin comisiones.",
      },
    },
    menu: {
      menu_1: "Resumen de la Cuenta",
    },
    section_1: {
      title: "¿POR QUÉ ELEGIR NUESTRA CUENTA ESTÁNDAR?",
      description:
        "Ofrecemos el spread más bajo. Nuestro spread promedio EUR/USD es de 0.2 PIPs sin comisión. La cuenta Estándar de IUX Markets está creada para day traders, short term traders y expert traders con una fuente de precios general de hasta 25 fuentes de nivel institucional. La plataforma MetaTrader5 de IUX Markets es la opción preferida por los traders de todo el mundo.",
    },
    std: {
      box_1: {
        titile_1: "Trading de CFDs",
        titile_2: "Low Spread Pricing",
        titile_3: "Spread desde 0.2 PIPs",
        titile_4: "Ejecución rápida de órdenes",
        titile_5: "1:3000 Apalancamiento",
        titile_6: "Alta liquidez",
        titile_7: "Meta Trader5",
      },
      section_1: {
        titile_1:
          "La cuenta Estándar ofrece el spread más bajo. Nuestro spread promedio EUR/USD es de 0.2 PIPs sin comisión. La cuenta Estándar de IUX Markets está creada para day traders, short term traders y expert traders con alta liquidez y ejecución rápida. La plataforma MetaTrader5 de IUX Markets es la opción preferida por los traders de todo el mundo.",
        titile_2:
          "Nuestros servidores MetaTrader5 están ubicados en el Equinix LD4 Data Center en Londres, acercándote a nuestro centro de datos de puentes y ayudando a eliminar la latencia innecesaria. MetaTrader5 es una excelente opción para day traders, short term traders y expert traders de todo el mundo.",
      },
      section_2: {
        titile_1: "Low Spread Pricing",
        description_1:
          "IUX Markets puede ofrecer precios de mercado y las mejores condiciones de trading a través de las plataformas MT5 proporcionando a los clientes Low Spread Pricing. El entorno Low Spread Pricing te permite operar con precios de nivel institucional casi al mismo nivel que los principales lugares de ejecución del mundo. Los precios de transmisión ejecutables (ESP) son enviados por los proveedores de precios de IUX Markets y ofrecidos a nuestros clientes sin mesa de operaciones, manipulación de precios o recotizaciones. IUX Markets es el mejor proveedor de CFDs para traders de alto volumen, scalpers y robots que proporcionan el spread más ajustado y la mejor ejecución posible.",
      },
      section_3: {
        titile_1: "Ejecución rápida de órdenes",
        description_1:
          "El servidor MetaTrader5 de IUX Markets está ubicado en el centro de datos Equinix LD4 en Londres. El centro de datos NY4, conocido como un ecosistema financiero, alberga más de 600 firmas de compra y venta, intercambios, lugares de trading, proveedores de datos de mercado y servicios. El servidor MetaTrader5 está conectado a nuestra Red de Comunicación para asegurar baja latencia y la rápida ejecución de tus operaciones a través del entorno de trading de IUX Markets. Los servidores de trading MetaTrader5 y 5 de IUX Markets tienen una latencia de menos de 1 milisegundo con los principales proveedores de VPS, ya sea ubicados en el centro de datos NY4 o a través de líneas dedicadas a centros de datos cercanos. Este entorno de baja latencia es ideal para el trading automatizado y de alta frecuencia y el scalping.",
        titile_2: "Spread desde 0.2 PIPs",
        description_2:
          "IUX Markets tiene algunos de los spreads más ajustados de todos los brokers de CFDs a nivel mundial, con spreads que comienzan en 0.2 PIPs en las plataformas MetaTrader5 y un promedio en EURUSD de 0.1 PIPs las 24 horas del día, los 7 días de la semana. Este es actualmente uno de los spreads promedio más ajustados en EURUSD a nivel mundial. Nuestro pricing está conectado con una mezcla de hasta 25 proveedores de precios diferentes. Siempre podemos obtener el mejor precio para nuestros clientes y mantener nuestros spreads ajustados, especialmente durante tiempos de alta volatilidad como los anuncios de noticias.",
        titile_3: "Sin restricciones en el trading - Scalping permitido",
        description_3:
          "Las plataformas Meta Trader5 de IUX Markets no tienen restricciones en el trading, lo que permite a los traders colocar órdenes entre el spread ya que no hay una distancia mínima de órdenes y un nivel de congelación de 0. Esto significa que las órdenes, incluidas las órdenes stop loss, se pueden colocar tan cerca del precio del mercado como desees. Los traders también pueden cubrir posiciones ya que no hay una regla FIFO (primero en entrar, primero en salir) con IUX Markets. Los traders no pagan margen en las operaciones cubiertas y disfrutan de los beneficios de la compensación de márgenes.",
        titile_4: "Level II Pricing - Profundidad de Mercado",
        description_4:
          "La profundidad del mercado muestra la gama completa de precios ejecutables que provienen directamente de nuestros proveedores de precios. La profundidad del mercado ofrece transparencia completa de la liquidez de cada par de divisas al mostrar los volúmenes disponibles para cada nivel de precio en cualquier momento dado. Alta liquidez, precios spot asíncronos y baja latencia garantizan el spread más ajustado posible.",
      },
      section_4: {
        titile_1: "Opciones flexibles de financiación y retiro",
        description_1:
          "Una vez que hayas abierto tu cuenta, puedes financiarla usando cualquiera de nuestras opciones de financiación, incluyendo: tarjeta de crédito/débito, Skrill, PayPal, Neteller, código QR tailandés y transferencia entre brokers.",
        titile_2: "Tamaño de lote flexible",
        description_2:
          "No hay límites ni restricciones en los tamaños de las operaciones, puedes realizar operaciones tan pequeñas como un micro lote (0.01). Nuestro tamaño de lote flexible te permite probar la plataforma con un riesgo mínimo y gestionar tus tamaños de operaciones de acuerdo con tu saldo de cuenta.",
      },
      section_5: {
        titile_1: "64 CFDs y metales",
        description_1:
          "Opera 64 pares de CFDs más 15 principales índices de acciones, incluyendo el UK 100, S&P 500, AUS 200 y el índice Dow Jones las 24 horas del día con un spread de 1 punto.",
        titile_2: "Apalancamiento hasta 1:3000",
        description_2:
          "Las cuentas alcanzan hasta 1:3000 de apalancamiento en las plataformas Meta Trader5 de IUX Markets. Los traders pueden usar un apalancamiento más alto para adaptarse a su estilo de trading y obtener lo mejor de sus estrategias de trading manuales y automatizadas.",
        titile_3: "Todas las principales divisas de cuenta soportadas",
        description_3:
          "Sabemos que algunos traders prefieren operar en su moneda local. Les damos a los traders la opción de abrir una cuenta en 1 de las 10 divisas base soportadas: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.",
      },
    },
    bonus: {
      box_1: {
        titile_1: "Trading de CFDs",
        titile_2: "Bono de depósito de $35",
        titile_3: "Sin comisión",
        titile_4: "Ejecución rápida de órdenes",
        titile_5: "1:1000 Apalancamiento",
        titile_6: "Alta liquidez",
        titile_7: "Meta Trader5",
      },
      section_1: {
        titile_1: "¿Por qué elegir Standard+?",
        description_1:
          "La cuenta Standard+ de IUX Markets ofrece una velocidad de ejecución sin rivales. Incluye un spread todo incluido que comienza desde 0.5 PIPs, el centro de datos Equinix LD4 en Londres, un grupo de hasta 25 proveedores de precios. Entenderás por qué nuestra cuenta Standard+ puede ofrecerte una experiencia de trading inigualable, todos los días, todo el día.",
      },
      section_2: {
        titile_1: "Ejecución rápida de órdenes",
        description_1:
          "El servidor MetaTrader5 de IUX Markets está ubicado en el centro de datos Equinix LD4 en Londres. El centro de datos NY4, conocido como un ecosistema financiero, alberga más de 600 firmas de compra y venta, intercambios, lugares de trading, proveedores de datos de mercado y servicios. El servidor MetaTrader5 está conectado a nuestra Red de Comunicación para asegurar baja latencia y la rápida ejecución de tus operaciones a través del entorno de trading de IUX Markets. Los servidores de trading MetaTrader5 y 5 de IUX Markets tienen una latencia de menos de 1 milisegundo con los principales proveedores de VPS, ya sea ubicados en el centro de datos NY4 o a través de líneas dedicadas a centros de datos cercanos. Este entorno de baja latencia es ideal para el trading automatizado y de alta frecuencia y el scalping.",
      },
      section_3: {
        titile_1: "Sin restricciones en el trading - Scalping permitido",
        description_1:
          "Las plataformas Meta Trader5 de IUX Markets no tienen restricciones en el trading, lo que permite a los traders colocar órdenes entre el spread ya que no hay una distancia mínima de órdenes y un nivel de congelación de 0. Esto significa que las órdenes, incluidas las órdenes stop loss, se pueden colocar tan cerca del precio del mercado como desees. Los traders también pueden cubrir posiciones ya que no hay una regla FIFO (primero en entrar, primero en salir) con IUX Markets. Los traders no pagan margen en las operaciones cubiertas y disfrutan de los beneficios de la compensación de márgenes.",
        titile_2: "Level II Pricing - Profundidad de Mercado",
        description_2:
          "La profundidad del mercado muestra la gama completa de precios ejecutables que provienen directamente de nuestros proveedores de precios. La profundidad del mercado ofrece transparencia completa de la liquidez de cada par de divisas al mostrar los volúmenes disponibles para cada nivel de precio en cualquier momento dado. Alta liquidez, precios spot asíncronos y baja latencia garantizan el spread más ajustado posible.",
      },
      section_4: {
        titile_1: "64 CFDs y metales",
        description_1:
          "Opera 64 pares de CFDs más 15 principales índices de acciones, incluyendo el UK 100, S&P 500, AUS 200 y el índice Dow Jones las 24 horas del día con un spread de 1 punto.",
        titile_2: "Apalancamiento hasta 1:1000",
        description_2:
          "Las cuentas alcanzan hasta 1:1000 de apalancamiento en las plataformas Meta Trader5 de IUX Markets. Los traders pueden usar un apalancamiento más alto para adaptarse a su estilo de trading y obtener lo mejor de sus estrategias de trading manuales y automatizadas.",
        titile_3: "Todas las principales divisas de cuenta soportadas",
        description_3:
          "Sabemos que algunos traders prefieren operar en su moneda local. Les damos a los traders la opción de abrir una cuenta en 1 de las 10 divisas base soportadas: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.",
      },
    },
    accecn: {
      box_1: {
        titile_1: "Trading de CFDs",
        titile_2: "Opera más de 90 instrumentos",
        titile_3: "Comisión de $3.5",
        titile_4: "Ejecución rápida de órdenes",
        titile_5: "1:3000 Apalancamiento",
        titile_6: "Spread desde 0.0 PIPs",
        titile_7: "Meta Trader5",
      },
      section_1: {
        titile_1: "¿Por qué elegir nuestra cuenta ECN?",
        description_1:
          "La cuenta ECN de IUX Markets ofrece una velocidad de ejecución sin rivales. Combina esto con un spread todo incluido que comienza desde 0.0 PIPs, servidores Equinix LD4 en Londres, un grupo de hasta 25 proveedores de precios y entenderás por qué nuestra cuenta estándar puede ofrecerte una experiencia de trading inigualable, todos los días, todo el día.",
      },
      section_2: {
        titile_1: "Ejecución rápida de órdenes",
        description_1:
          "El servidor MetaTrader5 de IUX Markets está ubicado en el centro de datos Equinix LD4 en Londres. El centro de datos NY4, conocido como un ecosistema financiero, alberga más de 600 firmas de compra y venta, intercambios, lugares de trading, proveedores de datos de mercado y servicios. El servidor MetaTrader5 está conectado a nuestra Red de Comunicación para asegurar baja latencia y la rápida ejecución de tus operaciones a través del entorno de trading de IUX Markets. Los servidores de trading MetaTrader5 y 5 de IUX Markets tienen una latencia de menos de 1 milisegundo con los principales proveedores de VPS, ya sea ubicados en el centro de datos NY4 o a través de líneas dedicadas a centros de datos cercanos. Este entorno de baja latencia es ideal para el trading automatizado y de alta frecuencia y el scalping.",
      },
      section_3: {
        titile_1: "Sin restricciones en el trading - Scalping permitido",
        description_1:
          "Las plataformas Meta Trader5 de IUX Markets no tienen restricciones en el trading, lo que permite a los traders colocar órdenes entre el spread ya que no hay una distancia mínima de órdenes y un nivel de congelación de 0. Esto significa que las órdenes, incluidas las órdenes stop loss, se pueden colocar tan cerca del precio del mercado como desees. Los traders también pueden cubrir posiciones ya que no hay una regla FIFO (primero en entrar, primero en salir) con IUX Markets. Los traders no pagan margen en las operaciones cubiertas y disfrutan de los beneficios de la compensación de márgenes.",
        titile_2: "Level II Pricing - Profundidad de Mercado",
        description_2:
          "La profundidad del mercado muestra la gama completa de precios ejecutables que provienen directamente de nuestros proveedores de precios. La profundidad del mercado ofrece transparencia completa de la liquidez de cada par de divisas al mostrar los volúmenes disponibles para cada nivel de precio en cualquier momento dado. Alta liquidez, precios spot asíncronos y baja latencia garantizan el spread más ajustado posible.",
      },
      section_4: {
        titile_1: "64 CFDs y metales",
        description_1:
          "Opera 64 pares de CFDs más 15 principales índices de acciones, incluyendo el UK 100, S&P 500, AUS 200 y el índice Dow Jones las 24 horas del día con un spread de 1 punto.",
        titile_2: "Apalancamiento hasta 1:3000",
        description_2:
          "Las cuentas alcanzan hasta 1:3000 de apalancamiento en las plataformas Meta Trader5 de IUX Markets. Los traders pueden usar un apalancamiento más alto para adaptarse a su estilo de trading y obtener lo mejor de sus estrategias de trading manuales y automatizadas.",
        titile_3: "Todas las principales divisas de cuenta soportadas",
        description_3:
          "Sabemos que algunos traders prefieren operar en su moneda local. Les damos a los traders la opción de abrir una cuenta en 1 de las 10 divisas base soportadas: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.",
      },
    },
    accraw: {
      box_1: {
        titile_1: "Trading de CFDs",
        titile_2: "Opera más de 90 instrumentos",
        titile_3: "Comisión de $6",
        titile_4: "Ejecución rápida de órdenes",
        titile_5: "1:3000 Apalancamiento",
        titile_6: "Spread desde 0.0 PIPs",
        titile_7: "Meta Trader5",
      },
      section_1: {
        titile_1: "¿Por qué elegir Raw?",
        description_1:
          "La cuenta Raw de IUX Markets ofrece una velocidad de ejecución sin rivales. Incluye un spread todo incluido que comienza desde 0.0 PIPs, el centro de datos Equinix LD4 en Londres, un grupo de hasta 25 proveedores de precios. Entenderás por qué nuestra cuenta Raw puede ofrecerte una experiencia de trading inigualable, todos los días, todo el día.",
      },
      section_2: {
        titile_1: "Ejecución rápida de órdenes",
        description_1:
          "El servidor MetaTrader5 de IUX Markets está ubicado en el centro de datos Equinix LD4 en Londres. El centro de datos NY4, conocido como un ecosistema financiero, alberga más de 600 firmas de compra y venta, intercambios, lugares de trading, proveedores de datos de mercado y servicios. El servidor MetaTrader5 está conectado a nuestra Red de Comunicación para asegurar baja latencia y la rápida ejecución de tus operaciones a través del entorno de trading de IUX Markets. Los servidores de trading MetaTrader5 y 5 de IUX Markets tienen una latencia de menos de 1 milisegundo con los principales proveedores de VPS, ya sea ubicados en el centro de datos NY4 o a través de líneas dedicadas a centros de datos cercanos. Este entorno de baja latencia es ideal para el trading automatizado y de alta frecuencia y el scalping.",
      },
      section_3: {
        titile_1: "Sin restricciones en el trading - Scalping permitido",
        description_1:
          "Las plataformas Meta Trader5 de IUX Markets no tienen restricciones en el trading, lo que permite a los traders colocar órdenes entre el spread ya que no hay una distancia mínima de órdenes y un nivel de congelación de 0. Esto significa que las órdenes, incluidas las órdenes stop loss, se pueden colocar tan cerca del precio del mercado como desees. Los traders también pueden cubrir posiciones ya que no hay una regla FIFO (primero en entrar, primero en salir) con IUX Markets. Los traders no pagan margen en las operaciones cubiertas y disfrutan de los beneficios de la compensación de márgenes.",
        titile_2: "Level II Pricing - Profundidad de Mercado",
        description_2:
          "La profundidad del mercado muestra la gama completa de precios ejecutables que provienen directamente de nuestros proveedores de precios. La profundidad del mercado ofrece transparencia completa de la liquidez de cada par de divisas al mostrar los volúmenes disponibles para cada nivel de precio en cualquier momento dado. Alta liquidez, precios spot asíncronos y baja latencia garantizan el spread más ajustado posible.",
      },
      section_4: {
        titile_1: "64 CFDs y metales",
        description_1:
          "Opera 64 pares de CFDs más 15 principales índices de acciones, incluyendo el UK 100, S&P 500, AUS 200 y el índice Dow Jones las 24 horas del día con un spread de 1 punto.",
        titile_2: "Apalancamiento hasta 1:3000",
        description_2:
          "Las cuentas alcanzan hasta 1:3000 de apalancamiento en las plataformas Meta Trader5 de IUX Markets. Los traders pueden usar un apalancamiento más alto para adaptarse a su estilo de trading y obtener lo mejor de sus estrategias de trading manuales y automatizadas.",
        titile_3: "Todas las principales divisas de cuenta soportadas",
        description_3:
          "Sabemos que algunos traders prefieren operar en su moneda local. Les damos a los traders la opción de abrir una cuenta en 1 de las 10 divisas base soportadas: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.",
      },
    },
    accpro: {
      box_1: {
        titile_1: "Trading de CFDs",
        titile_2: "Opera más de 90 instrumentos",
        titile_3: "Comisión $0",
        titile_4: "Ejecución rápida de órdenes",
        titile_5: "1:3000 Apalancamiento",
        titile_6: "Spread desde 0.1 PIPs",
        titile_7: "Meta Trader5",
      },
      section_1: {
        titile_1: "¿Por qué elegir Pro?",
        description_1:
          "Si eres un trader experimentado y profesional, la cuenta Pro es una buena opción para ti. La cuenta Pro de IUX Markets viene con spreads raw o incluso sin spreads que comienzan desde 0.0 pips. También ofrece la velocidad de ejecución de órdenes con ejecución instantánea para la mayoría de los instrumentos. Nuestras cuentas Pro tienen ejecución para adaptarse a scalpers, day-traders y algotraders.",
      },
      section_2: {
        titile_1: "Ejecución rápida de órdenes",
        description_1:
          "El servidor MetaTrader5 de IUX Markets está ubicado en el centro de datos Equinix LD4 en Londres. El centro de datos NY4, conocido como un ecosistema financiero, alberga más de 600 firmas de compra y venta, intercambios, lugares de trading, proveedores de datos de mercado y servicios. El servidor MetaTrader5 está conectado a nuestra Red de Comunicación para asegurar baja latencia y la rápida ejecución de tus operaciones a través del entorno de trading de IUX Markets. Los servidores de trading MetaTrader5 y 5 de IUX Markets tienen una latencia de menos de 1 milisegundo con los principales proveedores de VPS, ya sea ubicados en el centro de datos NY4 o a través de líneas dedicadas a centros de datos cercanos. Este entorno de baja latencia es ideal para el trading automatizado y de alta frecuencia y el scalping.",
      },
      section_3: {
        titile_1: "Sin restricciones en el trading - Scalping permitido",
        description_1:
          "Las plataformas Meta Trader5 de IUX Markets no tienen restricciones en el trading, lo que permite a los traders colocar órdenes entre el spread ya que no hay una distancia mínima de órdenes y un nivel de congelación de 0. Esto significa que las órdenes, incluidas las órdenes stop loss, se pueden colocar tan cerca del precio del mercado como desees. Los traders también pueden cubrir posiciones ya que no hay una regla FIFO (primero en entrar, primero en salir) con IUX Markets. Los traders no pagan margen en las operaciones cubiertas y disfrutan de los beneficios de la compensación de márgenes.",
        titile_2: "Level II Pricing - Profundidad de Mercado",
        description_2:
          "La profundidad del mercado muestra la gama completa de precios ejecutables que provienen directamente de nuestros proveedores de precios. La profundidad del mercado ofrece transparencia completa de la liquidez de cada par de divisas al mostrar los volúmenes disponibles para cada nivel de precio en cualquier momento dado. Alta liquidez, precios spot asíncronos y baja latencia garantizan el spread más ajustado posible.",
      },
      section_4: {
        titile_1: "64 CFDs y metales",
        description_1:
          "Opera 64 pares de CFDs más 15 principales índices de acciones, incluyendo el UK 100, S&P 500, AUS 200 y el índice Dow Jones las 24 horas del día con un spread de 1 punto.",
        titile_2: "Apalancamiento hasta 1:3000",
        description_2:
          "Las cuentas alcanzan hasta 1:3000 de apalancamiento en las plataformas Meta Trader5 de IUX Markets. Los traders pueden usar un apalancamiento más alto para adaptarse a su estilo de trading y obtener lo mejor de sus estrategias de trading manuales y automatizadas.",
        titile_3: "Todas las principales divisas de cuenta soportadas",
        description_3:
          "Sabemos que algunos traders prefieren operar en su moneda local. Les damos a los traders la opción de abrir una cuenta en 1 de las 10 divisas base soportadas: USD, AUD, EUR, GBP, SGD, NZD, JPY, CHF, HKD, CAD.",
      },
    },
    funding: {
      section_1: {
        title: "¿Cómo depositar/retirar?",
        titile_1: "Registrarse e iniciar sesión",
        titile_2:
          "Selecciona tu banco preferido para proceder con el depósito y retiro",
        titile_3: "Sin comisión para depósito y retiro",
        titile_4: "Depósito y retiro rápido en unos minutos",
      },
      section_2: {
        title: "Opciones de depósito y retiro",
        description:
          "Mantenemos tu información financiera segura con cifrado. Así puedes pagar en línea con confianza.",
        option: "Opciones de financiación",
        hot: "Popular",
        titile_1: "Crédito y Débito",
        titile_2: "Skrill",
        titile_3: "Neteller",
        titile_4: "Pago con código QR tailandés",
        titile_5: "Banca en línea",
      },
      section_3: {
        titile_1: "Proceso de pago",
        description_1:
          "Para un procesamiento más rápido, recomendamos depositar fondos desde el área de clientes segura. El saldo se depositará en tiempo real. Si tienes problemas para acceder al área de clientes segura, sigue las instrucciones.",
        titile_2: "Seguridad de fondos",
        description_2:
          "Los fondos de los clientes se mantienen en una cuenta de fideicomiso segregada para clientes. Los pagos electrónicos se procesan utilizando tecnología SSL (Secure Socket Layer) y están cifrados para garantizar la seguridad. Toda la información de pago es confidencial y se utiliza solo para el propósito de financiar tu cuenta de trading con IUX Markets.",
        titile_3: "Comisiones bancarias",
        description_3:
          "IUX Markets no cobra ninguna comisión adicional por depósitos y retiros. Sin embargo, debes reconocer que puede haber un cargo en los pagos de instituciones bancarias internacionales. IUX Markets no tiene responsabilidad por los cargos de bancos o proveedores.",
        titile_4: "Pagos a terceros",
        description_4:
          "IUX Markets no permite pagos de terceros. Asegúrate de que todos los depósitos en tu cuenta de trading provengan de una cuenta bancaria a tu nombre. Se permiten pagos desde cuentas bancarias conjuntas / tarjetas de crédito si el titular de la cuenta de trading es una de las partes en la cuenta bancaria / tarjeta de crédito.",
      },
    },
    withdraw: {
      section_1: {
        title:
          "Para un procesamiento más rápido, ahora se requiere que todos los titulares de cuentas presenten solicitudes de retiro desde su área de clientes segura.",
        content:
          "Si tienes problemas para acceder al área de clientes segura, sigue las instrucciones.",
      },
      section_2: {
        title:
          "Si no tienes experiencia en retirar fondos, sigue las instrucciones:",
        title_1:
          "La hora límite para las solicitudes de retiro es las 12:00 AEST/AEDT. Si presentas tu solicitud de retiro antes de esta hora, se procesará el día en que se reciba. Si presentas tu solicitud de retiro después de esta hora, se procesará el siguiente día hábil.",
        title_2:
          "IUX Markets no cobra ninguna comisión adicional por depósitos y retiros. Sin embargo, debes reconocer que puede haber un cargo en los pagos de instituciones bancarias internacionales. IUX Markets no tiene responsabilidad por los cargos de bancos o proveedores.",
        title_3:
          "Para transferencias bancarias internacionales, nuestro banco cobra una comisión equivalente en moneda. Este cargo se deducirá de tu monto de retiro. Esto puede tardar hasta 14 días y conllevar costos adicionales dependiendo de tu jurisdicción y del banco que elijas.",
        title_4:
          "Los retiros con tarjeta de crédito / débito se procesan sin cargo. Una vez procesados, los retiros con tarjeta de crédito / débito pueden tardar de 3 a 5 días hábiles en llegar a tu tarjeta de crédito. Sin embargo, debes reconocer que en algunas raras ocasiones, esto puede tardar hasta 10 días hábiles dependiendo del banco. *Ten en cuenta que los retiros con tarjeta de crédito pueden no estar disponibles en todos los países.",
        title_5:
          "Los retiros a PayPal / Neteller / Skrill deben realizarse desde la misma cuenta desde la que se enviaron los fondos inicialmente. Estas transacciones se procesan sin cargo y son instantáneas una vez procesadas.",
        title_6:
          "Si tu tarjeta de crédito / débito ya ha caducado, sube la nueva tarjeta en tu área de clientes para seguir utilizando los servicios de depósito y retiros sin interrupciones. Si el número de la nueva tarjeta es diferente al de la tarjeta caducada, deberás presentar una carta emitida por el banco emisor de la tarjeta antigua que confirme que la nueva tarjeta se emitió en reemplazo de la antigua.",
        title_7:
          "Si tu tarjeta de crédito / débito se ha perdido, robado, dañado o cancelado, deberás presentar una carta emitida por el banco emisor de la tarjeta antigua que confirme que la tarjeta antigua ya no es válida.",
        title_8:
          "IUX Markets, a su discreción, puede solicitarte que presentes documentación de respaldo (por ejemplo, recibos de depósito para los pagos procesados a través de la tarjeta antigua o extractos de la tarjeta que muestren transacciones de depósito) antes de liberar fondos a la nueva tarjeta.",
        title_9:
          "Para retirar un monto mayor al depositado a través de la tarjeta de crédito / débito verificada por Visa / MasterCard Secure, debes usar otro método de depósito previamente utilizado o una opción de transferencia bancaria.",
        title_10:
          "Si estás retirando tus fondos depositados a través de la opción de banca por internet tailandesa a una cuenta bancaria tailandesa, deberás subir una imagen clara o una copia escaneada en color de tu libro bancario. Una vez procesados, los fondos pueden tardar un día hábil en llegar a tu cuenta.",
        title_11:
          "IUX Markets no procesa pagos a terceros. Asegúrate de que todas las solicitudes de retiro de tu cuenta de trading se realicen a una cuenta bancaria o fuente a tu nombre. Se aceptan pagos a cuentas bancarias conjuntas / tarjetas de crédito si el titular de la cuenta de trading es una de las partes en la cuenta bancaria / tarjeta de crédito.",
        title_12:
          "IUX Markets no procesa pagos a terceros. Asegúrate de que todas las solicitudes de retiro de tu cuenta de trading se realicen a una cuenta bancaria o fuente a tu nombre. Se aceptan pagos a cuentas bancarias conjuntas / tarjetas de crédito si el titular de la cuenta de trading es una de las partes en la cuenta bancaria / tarjeta de crédito.",
      },
    },
  },
};
