import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Button, Card, Divider, Menu, Popover, Tooltip } from "antd";
import {
  UserOutlined,
  TeamOutlined,
  DeleteOutlined,
  LineChartOutlined,
  SettingOutlined,
  SyncOutlined,
  FolderOpenOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { get } from "../../helper/request";
import ModalSwap from "../modal-swap/ModalSwap";
import { useSelector } from "react-redux";
const locale = require("react-redux-i18n").I18n;
const text = <span>Update</span>;
const menu = (props) => (
  <Menu style={{ border: "none" }} selectable={false}>
    <Menu.Item
      onClick={() => {
        props.onEditPasswordShow(props.account);
      }}
    >
      <UserOutlined className="mr-2" />

      {`${locale.t("dashboardWallet.menu.account_edit")} ${
        props.account.platform
      }`}
    </Menu.Item>
    <Menu.Item
      onClick={() => {
        props.onEditInvestorPasswordShow(props.account);
      }}
    >
      <TeamOutlined className="mr-2" />
      {locale.t("dashboardWallet.menu.investor_edit")}
    </Menu.Item>
    <Menu.Item
      onClick={() => {
        props.onEditLeverageShow(props.account);
      }}
    >
      <LineChartOutlined className="mr-2" />
      {locale.t("dashboardWallet.menu.leverage_edit")}
    </Menu.Item>
    {!props.isWelcome && (
      <Menu.Item
        onClick={() => {
          props.onDelete(props.account);
        }}
      >
        <DeleteOutlined className="mr-2" />
        {locale.t("dashboardWallet.menu.account_close")}
      </Menu.Item>
    )}
    {!props.isWelcome && (
      <Menu.Item
        onClick={() => {
          props.onArchive(props.account);
        }}
      >
        <FolderOpenOutlined className="mr-2" />
        {locale.t("dashboardWallet.menu.account_archive")}
      </Menu.Item>
    )}
  </Menu>
);

const DropdownSetting = (props) => (
  <>
    <Popover
      placement="bottom"
      content={menu(props)}
      trigger="click"
      className="cursor-pointer"
    >
      {/* <EditTwoTone twoToneColor="black" /> */}
      <img src="/assets/images/icons/icon-edit-account.svg" alt="edit" />
    </Popover>
  </>
);

const RealAccountCardX = (props) => {
  const account = props.account.account_type;
  const [isOpen, setIsOpen] = useState(false);
  const profileCountry = useSelector((state) => state.user.profileCountry);
  const onCloseSwap = () => {
    setIsOpen(false);
  };
  const onOpenSwap = () => {
    setIsOpen(true);
    // if (!profileCountry.isalamic) {
    //   setIsOpen(true);
    // }
  };
  return (
    <Card
      className="account-card"
      headStyle={{ padding: "14px !important" }}
      title={
        <>
          <div className="align-content-center d-flex justify-content-between w-100">
            <div className="d-flex align-items-center flex-column">
              <div className="">
                <div className="fs-14 fw-700 d-flex align-items-center">
                  {props.account.account_number}{" "}
                  <div
                    onClick={() => onOpenSwap()}
                    className={`${
                      profileCountry?.isalamic === 0 ? "cursor-pointer" : ""
                    } bage-free-swap-${
                      props?.account?.swap_charge ? "nonfree" : "isfree"
                    } ml-2`}
                  >
                    {props?.account?.swap_charge
                      ? "Non-Free Swap"
                      : "Free Swap"}
                    &nbsp;
                  </div>
                  {/* <div className="ml-2">
                    <InfoCircleOutlined
                      className="fs-16 text-lightgray"
                      onClick={() => onOpenSwap()}
                    />
                  </div> */}
                </div>

                <div className="d-flex fs-10">
                  {props.account?.type === "welcome"
                    ? "Welcome"
                    : props.account?.account_group?.display}
                  {/* {account} */}
                  {" • "}
                  <div className="ml-1">{props.account.platform}</div>
                </div>
              </div>
            </div>
            <div
              className="ml-2"
              style={{
                cursor: "pointer",
                outline: "none",
                boxShadow: "none",
                border: "none",
                fontSize: "14px",
                paddingRight: "8px",
                textAlign: "right",
                paddingLeft: "0",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => props.onSync(props.account)}
              {...props}
            >
              <Tooltip placement="bottom" title={text}>
                <SyncOutlined />
              </Tooltip>
            </div>
          </div>
        </>
      }
      extra={<DropdownSetting {...props} />}
    >
      <Row gutter={[8, 8]}>
        <div span={12} className="ant-respon w-100" style={{ padding: "6px" }}>
          <div className="d-flex justify-content-between w-100 mb-3">
            <div className="fs-12 ">{locale.t("dashboardWallet.server")}</div>
            <div className="fs-14 fw-700">
              {props.account.api_server === "api"
                ? "IUXMarkets-Live"
                : props.account.api_server === "live2"
                ? "IUXMarkets-Live2"
                : props.account.api_server === "live3"
                ? "IUXMarkets-Live3"
                : props.account.api_server === "demo"
                ? "IUXMarkets-Demo"
                : "IUXMarkets"}
            </div>
          </div>
          <div className="d-flex justify-content-between w-100 mb-3">
            <div className="fs-12 ">
              {locale.t("dashboardWallet.accountBalance")} (
              {account === "Cent" ? "USC" : "USD"})
            </div>
            <div className="fs-14 fw-700">
              {/* {(
                Math.floor(props.account.current_balance * 100) / 100
              ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} */}
              {props.account.current_balance.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
          <div className="d-flex justify-content-between w-100 mb-3">
            <div className="fs-12 ">
              {locale.t("dashboardWallet.accountCredit")} (
              {account === "Cent" ? "USC" : "USD"})
            </div>
            <div className="fs-14 fw-700">
              {/* {(
                Math.floor(props.account.current_credit * 100) / 100
              ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} */}
              {props.account.current_credit.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>

          <div className="d-flex justify-content-between w-100 mb-3">
            <div className="fs-12 ">
              {locale.t("dashboardWallet.accountLeverage")}
            </div>
            <div className="fs-14 fw-700">
              1 : {props.account.current_leverage}
            </div>
          </div>
          {/* <div className="d-flex justify-content-between w-100 ">
            <div className="fs-12 ">Swap </div>
            <div className="fs-14 fw-600 ">
              <span
                className={`${
                  props?.account?.swap_charge
                    ? "text-lightgray-iux"
                    : "text-primary-sub-iux"
                }`}
              >
                {props?.account?.swap_charge ? "Non-Free" : "Free"}
              </span>
              &nbsp;{" "}
              <span>
                <InfoCircleOutlined
                  className="fs-16 text-lightgray"
                  onClick={() => onOpenSwap()}
                />
              </span>
            </div>
          </div> */}
        </div>
      </Row>
      <Row className="w-100">
        {props.isWelcome ? (
          <div className="d-flex justify-content-between text-center w-100">
            <Button
              onClick={() => props.onWithdraw(true, props.account)}
              className="btn-primary-new-iux w-100 "
              type="primary"
              style={{ borderRadius: 8 }}
            >
              {locale.t("dashboardFund.withdraw")}
            </Button>

            <Button
              className="btn-primary-new-outline ml-2 w-100"
              onClick={() => props.onInfoBonus(true)}
            >
              {locale.t("conditions")}
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="14.919"
                viewBox="0 0 24 14.919"
              >
                <path
                  id="thefreeforty_withdraw_icon-icons.com_66307"
                  d="M22.092,5.8H2.308A2.164,2.164,0,0,0,.2,7.989v5.027a2.18,2.18,0,0,0,2.189,2.189H4.416a.64.64,0,0,0,.649-.649.6.6,0,0,0-.649-.649H2.308a.986.986,0,0,1-.973-.973V7.989a.986.986,0,0,1,.973-.973h19.7a.986.986,0,0,1,.973.973v4.865a.986.986,0,0,1-.973.973H19.416a.64.64,0,0,0-.649.649.6.6,0,0,0,.649.649h2.595A2.18,2.18,0,0,0,24.2,12.935V8.07A2.185,2.185,0,0,0,22.092,5.8Zm-.568,3.243a.64.64,0,0,0-.649-.649H3.443a.64.64,0,0,0-.649.649.6.6,0,0,0,.649.649H20.876A.64.64,0,0,0,21.524,9.043Zm-3.811,1.216a.592.592,0,0,0-.811.324,11.853,11.853,0,0,0-.324,2.838c-.081,2.027-.162,4.784-1.459,6.081H5.876c1.135-1.784,1.216-4.378,1.3-6.324a10.493,10.493,0,0,1,.162-2.027.6.6,0,0,0-1.054-.568A5.953,5.953,0,0,0,5.957,13.1c-.081,2.108-.162,5.27-1.784,6.568a.631.631,0,0,0-.162.649.61.61,0,0,0,.568.405H15.443a.619.619,0,0,0,.405-.162c1.865-1.622,1.946-4.784,2.027-7.135a8.266,8.266,0,0,1,.243-2.351A.669.669,0,0,0,17.714,10.259Zm-5.838,2.595c1.459,0,2.595-.892,2.595-2.027H9.281A2.443,2.443,0,0,0,11.876,12.854Z"
                  transform="translate(-0.2 -5.8)"
                  fill="#1cb894"
                />
              </svg> */}
            </Button>
          </div>
        ) : (
          <div className="d-flex justify-content-between text-center w-100">
            <Link
              to={{
                pathname: `/${localStorage.getItem(
                  "locale"
                )}/wallet/fund/deposit`,
                search: `?account=${props.account.account_number}`,
                state: { account: props.account },
              }}
              className="w-100"
            >
              <Button
                // onClick={setDeposit}
                className="btn-primary-new-iux w-100 "
                type="primary"
                style={{ borderRadius: 8 }}
              >
                {locale.t("dashboardFund.deposit")}
              </Button>
            </Link>{" "}
            <div className="px-1" />
            <Link
              to={{
                pathname: `/${localStorage.getItem(
                  "locale"
                )}/wallet/fund/withdraw`,
                search: `?account=${props.account.account_number}`,
              }}
              className="w-100 "
            >
              <Button className="btn-primary-new-outline w-100">
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="14.919"
                  viewBox="0 0 24 14.919"
                >
                  <path
                    id="thefreeforty_withdraw_icon-icons.com_66307"
                    d="M22.092,5.8H2.308A2.164,2.164,0,0,0,.2,7.989v5.027a2.18,2.18,0,0,0,2.189,2.189H4.416a.64.64,0,0,0,.649-.649.6.6,0,0,0-.649-.649H2.308a.986.986,0,0,1-.973-.973V7.989a.986.986,0,0,1,.973-.973h19.7a.986.986,0,0,1,.973.973v4.865a.986.986,0,0,1-.973.973H19.416a.64.64,0,0,0-.649.649.6.6,0,0,0,.649.649h2.595A2.18,2.18,0,0,0,24.2,12.935V8.07A2.185,2.185,0,0,0,22.092,5.8Zm-.568,3.243a.64.64,0,0,0-.649-.649H3.443a.64.64,0,0,0-.649.649.6.6,0,0,0,.649.649H20.876A.64.64,0,0,0,21.524,9.043Zm-3.811,1.216a.592.592,0,0,0-.811.324,11.853,11.853,0,0,0-.324,2.838c-.081,2.027-.162,4.784-1.459,6.081H5.876c1.135-1.784,1.216-4.378,1.3-6.324a10.493,10.493,0,0,1,.162-2.027.6.6,0,0,0-1.054-.568A5.953,5.953,0,0,0,5.957,13.1c-.081,2.108-.162,5.27-1.784,6.568a.631.631,0,0,0-.162.649.61.61,0,0,0,.568.405H15.443a.619.619,0,0,0,.405-.162c1.865-1.622,1.946-4.784,2.027-7.135a8.266,8.266,0,0,1,.243-2.351A.669.669,0,0,0,17.714,10.259Zm-5.838,2.595c1.459,0,2.595-.892,2.595-2.027H9.281A2.443,2.443,0,0,0,11.876,12.854Z"
                    transform="translate(-0.2 -5.8)"
                    fill="#1cb894"
                  />
                </svg> */}
                {locale.t("dashboardFund.withdraw")}
              </Button>
            </Link>{" "}
          </div>
        )}
      </Row>
      <ModalSwap
        isOpen={isOpen}
        onClose={onCloseSwap}
        isFree={props?.account?.swap_charge}
        isAccountId={props?.account?.id}
      />
    </Card>
  );
};
export default RealAccountCardX;
