import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Row } from "antd";
import { Link } from "react-router-dom";
import { SelectOutlined } from "@ant-design/icons";
import Axios from "axios";
const locale = require("react-redux-i18n").I18n;
const buttonStyle = {
  color: "black",
};

const FooterAuthPage = (props) => {
  const [isCountry, setIsCountry] = useState("th");
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const fetchcountry = async () => {
    try {
      const response = await Axios.get(`${process.env.REACT_APP_DOMAIN}`);
      const langIndex = response.data.indexOf('lang="');
      const langSubstring = response.data.substring(
        langIndex + 'lang="'.length
      );
      const langValue = langSubstring.substring(0, langSubstring.indexOf('"'));
      setIsCountry(langValue);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchcountry();
  }, []);
  return (
    <div
      className={
        props.dark ? "text-center bg-lotback pb-4" : "text-center pb-4"
      }
      style={{ background: props.isDashboard ? "transparent" : "#2f2f2f" }}
    >
      {/* <div>
        <div
          style={{
            paddingTop: "4%",
            paddingBottom: "4%",
          }}
        >
          <Row className="d-flex container">
            <Col
              className="text-in-cover col-md"
              style={{ alignSelf: "center" }}
            >
              <div>
                <h3
                  className="mt-2 text-white  fs-32 "
                  style={{ fontWeight: 400 }}
                >
                  {locale.t("become_title")} {locale.t("become_our")}
                </h3>
                <div className="text-white mt-5">
                  {locale.t("iux_award_trading.iux_sub_platform")}
                </div>
              </div>
              <div className="row pt-5 justify-center">
                <div className="col-12 col-sm-6 col-lg-4 text-righttresponsive">
                  <Link to={`/${localStorage.getItem("locale")}/register`}>
                    <Button
                      className="btn-primary-new-iux  text-uppercase"
                      style={{ width: 280 }}
                    >
                      {locale.t("open_account_now")}
                    </Button>
                  </Link>
                </div>
                <div className="col-12  col-sm-6 col-lg-4 text-leftresponsive mt-4 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0">
                  <Link to={`/${localStorage.getItem("locale")}/register`}>
                    <Button className="btn-outline-iux " style={{ width: 280 }}>
                      {locale.t("become_demo")}
                    </Button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div> */}
      {/* <div
        class="trustpilot-widget"
        data-locale="en-US"
        data-template-id="5419b732fbfb950b10de65e5"
        data-businessunit-id="602684c743e0060001fa2be5"
        data-style-height="24px"
        data-style-width="100%"
        data-theme="dark"
        data-text-color="#ffffff"
      >
        <a
          href="https://www.trustpilot.com/review/iuxmarket.com"
          target="_blank"
          rel="noopener"
        >
          Trustpilot
        </a>
      </div> */}
      {/* <div className="container">
        <Divider style={{ background: "#3E3E3E" }} />
      </div> */}
      {!props.isDashboard && (
        <div className="landingPage-flex-block container ">
          <div className="my-4 d-flex justify-content-between w-100 align-items-center">
            <a href="http://iux.com/">
              <img
                width={48}
                height={48}
                alt="IUX"
                className="registerlogo border-logo"
                src={
                  process.env.PUBLIC_URL + "/assets/images/icons/iux-logo.png"
                }
              />{" "}
              <span className="fs-24 text-white font-bold-iux ml-2"> IUX</span>
            </a>
            <div className="d-flex">
              {" "}
              <div className=" mr-2">
                <a href="https://www.facebook.com/iuxofficial/" target="blank">
                  <img
                    width={24}
                    className="w-[24px] md:w-[48px]"
                    src={"/assets/images/landing/social/facebook.svg"}
                    alt="facebook"
                  />
                </a>
              </div>
              <div className=" mr-2">
                <a
                  href="https://www.instagram.com/iux_official/"
                  target="blank"
                >
                  <img
                    width={24}
                    className="w-[24px] md:w-[48px]"
                    src={"/assets/images/landing/social/ig.svg"}
                    alt="instagram"
                  />
                </a>
              </div>
              <div className=" mr-2">
                <a href="https://x.com/iux_official" target="blank">
                  <img
                    width={24}
                    className="w-[24px] md:w-[48px]"
                    src={"/assets/images/landing/social/x.svg"}
                    alt="x"
                  />
                </a>
              </div>
              <div className=" mr-2">
                <a href="https://www.youtube.com/@iuxglobal" target="blank">
                  <img
                    width={24}
                    className="w-[24px] md:w-[48px]"
                    src={"/assets/images/landing/social/youtube.svg"}
                    alt="iuxmarket"
                  />
                </a>
              </div>
              <div className=" mr-2">
                <a
                  href="https://www.linkedin.com/company/iux-market/"
                  target="blank"
                >
                  <img
                    width={24}
                    className="w-[24px] md:w-[48px]"
                    src={"/assets/images/landing/social/linkedin.svg"}
                    alt="linkedin"
                  />
                </a>
              </div>{" "}
            </div>
          </div>
          {/* <div className="col-md-9 p-0-important">
          <div className="row">
            <div
              style={buttonStyle}
              type="link"
              className="col-md-landingPage"
              onClick={scrollToTop}
            >
              <Link
                className="text-white-hover text-left fs-12"
                to={`/${localStorage.getItem("locale")}/about-us`}
              >
                {props.locale.t("about")}
              </Link>
            </div>
            <div
              style={buttonStyle}
              type="link"
              className="col-md-landingPage"
              onClick={scrollToTop}
            >
              <Link
                className="text-white-hover text-left fs-12"
                to={`/${localStorage.getItem("locale")}/about-us/contact`}
              >
                {props.locale.t("contact")}
              </Link>
            </div>

            <div
              style={buttonStyle}
              type="link"
              className="col-md-landingPage"
              onClick={scrollToTop}
            >
              <a
                className="text-white-hover text-left fs-12"
                href={`https://policy.iuxmarkets.com/${localStorage.getItem(
                  "locale"
                )}`}
                target="_blank"
              >
                Privacy & Terms
              </a>
            </div>
            <div
              style={buttonStyle}
              type="link"
              className="col-md-landingPage"
              onClick={scrollToTop}
            >
              <Link
                className="text-white-hover text-left fs-12"
                to={`/${localStorage.getItem("locale")}/about-us/policy`}
              >
                {props.locale.t("policy")}
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-3 p-0-important">
          <div className="row landing-float-RL">
            <div
              style={buttonStyle}
              className="lading-icon-socail"
              type="link"
              size="large"
              ghost
            >
              <a href="https://www.instagram.com/iux_official/" target="blank">
                <img
                  className="iux-contactsocial landingPage-footer-iconSocia"
                  src={"/assets/images/icons/v2/instagram-brands.svg"}
                  alt="instagram"
                />
              </a>
            </div>
            <div
              style={buttonStyle}
              className="lading-icon-socail"
              type="link"
              size="large"
              ghost
            >
              <a href="https://www.facebook.com/iuxofficial/" target="blank">
                <img
                  className="iux-contactsocial landingPage-footer-iconSocia"
                  src={"/assets/images/icons/v2/facebook-brands.svg"}
                  alt="facebook"
                />
              </a>
            </div>
            <div
              style={buttonStyle}
              className="lading-icon-socail"
              type="link"
              size="large"
              ghost
            >
              <a
                href="https://www.linkedin.com/company/iux-market/"
                target="blank"
              >
                <img
                  className="iux-contactsocial landingPage-footer-iconSocia"
                  src={"/assets/images/icons/v2/linkedin-brands.svg"}
                  alt="linkedin"
                />
              </a>
            </div>{" "}
            <div
              style={buttonStyle}
              className="lading-icon-socail"
              type="link"
              size="large"
              ghost
            >
              <a href="https://www.youtube.com/@iuxglobal" target="blank">
                <img
                  className="iux-contactsocial landingPage-footer-iconSocia"
                  src={"/assets/images/icons/v2/youtube-brands.svg"}
                  alt="iuxmarket"
                />
              </a>
            </div>
          </div>
        </div> */}
        </div>
      )}
      {/* <div className="container">
        <Divider style={{ background: "#3E3E3E" }} />
      </div> */}
      <div
          style={{
            paddingBottom: 16,
            textAlign: "left",
            whiteSpace: "break-spaces",
            overflow: "hidden",
          }}
          className={`container ${
              props.isDashboard ? "text-black" : "text-white"
          } fs-12`}
      >
        <div>
          {props.locale.t(`footer_FSC`)}
        </div>
        <br/>
        {/* {props.locale.t(`footer_title_1_1`)} &nbsp;
        <a
          href="https://mwaliregistrar.com/list_of_entities/authorised_trust_companies"
          target="blank"
          className="text-primary-new-iux"
        >
          T2023172
        </a>{" "}
        &nbsp;
        {props.locale.t(`footer_title_1_2`)}
        <br />
        <br /> */}
        {props.locale.t(`footer_title_2_1`)} &nbsp;
        <a
            href="/assets/IML-COE-ID.pdf"
            target="blank"
            className="text-primary-new-iux"
        >
          26183 BC 2021
        </a>{" "}
        {props.locale.t(`footer_title_2_2`)}
        {/* <br />
        {props.locale.t(`footer_title_3_1`)}
        &nbsp;
        <a
          href="/assets/NFA-IUX_Markets_Ltd.pdf"
          target="blank"
          className="text-primary-new-iux"
        >
          NFA ID: 0549829
        </a> */}
        <br/>
        <br/>
        {locale.t(`footer_FSCA_text_1`)}
        &nbsp;
        <a
            href="https://www.fsca.co.za/Fais/Search_FSP.htm"
            target="_blank"
            className="text-primary-new-iux"
        >
          53103&nbsp;
        </a>
        {locale.t(`footer_FSCA_text_2`)}
        <br/>
        <div style={{height: 20}}></div>
        {isCountry !== "th" && isCountry !== "la" && (
            <div id="footer-warning-address">
              {props.locale.t("website_warning_address")}
              <br/>
            </div>
        )}
        <div id="footer-warning">{props.locale.t("website_warning")}</div>
        <br/>
        ©️ 2024 IUX Markets Limited.
        {/* {props.locale.t("copyright")} */}
      </div>
    </div>
  );
};

export default FooterAuthPage;
