import React, { useState } from "react";
import { isMobile } from "react-device-detect";

function DownloadApp() {
  const [hideLoadApp, setHideLoadApp] = useState(false);
  return (
    <>
      {!hideLoadApp && isMobile && (
        <div
          style={{ background: "#1E1E1E", padding: 16 }}
          className="text-white d-flex justify-content-between align-items-center "
        >
          <div className="">
            <span
              className="mr-3 cursor-pointer"
              onClick={() => setHideLoadApp(true)}
            >
              <svg
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_10143_26268)">
                  <path
                    d="M0.390698 8.00005C0.313429 8.00007 0.237892 7.97716 0.17364 7.93424C0.109389 7.89132 0.0593103 7.83031 0.0297388 7.75892C0.000167331 7.68754 -0.00756847 7.60898 0.00750991 7.5332C0.0225883 7.45742 0.0598034 7.38781 0.114448 7.33318L7.3332 0.114427C7.40646 0.0411604 7.50583 0 7.60945 0C7.71306 0 7.81243 0.0411604 7.8857 0.114427C7.95896 0.187693 8.00012 0.287063 8.00012 0.390676C8.00012 0.49429 7.95896 0.59366 7.8857 0.666927L0.666948 7.88568C0.630704 7.922 0.587641 7.9508 0.540234 7.97042C0.492826 7.99005 0.442008 8.00012 0.390698 8.00005Z"
                    fill="white"
                  />
                  <path
                    d="M7.60943 8.00005C7.55812 8.00012 7.5073 7.99005 7.45989 7.97042C7.41248 7.9508 7.36942 7.922 7.33318 7.88568L0.114427 0.666927C0.0411604 0.59366 0 0.49429 0 0.390676C0 0.287063 0.0411604 0.187693 0.114427 0.114427C0.187693 0.0411604 0.287063 0 0.390676 0C0.49429 0 0.59366 0.0411604 0.666927 0.114427L7.88568 7.33318C7.94032 7.38781 7.97754 7.45742 7.99261 7.5332C8.00769 7.60898 7.99996 7.68754 7.97039 7.75892C7.94081 7.83031 7.89074 7.89132 7.82648 7.93424C7.76223 7.97716 7.6867 8.00007 7.60943 8.00005Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_10143_26268">
                    <rect width="8" height="8" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </span>
            <img
              alt="IUX"
              src={process.env.PUBLIC_URL + "/assets/images/icons/iux-logo.png"}
              width={32}
              className="border-logo"
            />

            <span className="ml-3">IUX Markets App Trade</span>
          </div>
          <a
            className="text-primary-sub-iux fs-12"
            style={{ textDecoration: "underline" }}
            href="https://iuxmarkets.page.link/QYkB"
          >
            INSTALL
          </a>
        </div>
      )}
    </>
  );
}

export default DownloadApp;
