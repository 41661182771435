import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Link, Route, Switch } from "react-router-dom";
import TableSwap from "./tableSwap";
const locale = require("react-redux-i18n").I18n;
const MenuItem = styled.div`
  padding: 0.8rem 1rem;
  color: ${(props) => props.color};
  border-bottom: ${(props) => props.borderBottom};
  white-space: pre;
  font-weight: ${(props) => props.fontWeight};
`;
function Islamic() {
  const [isIdex, setIndex] = React.useState(0);
  const pathName = window.location.pathname;
  const accountList = useSelector((state) => state.account);
  return (
    <section key={4}>
      <div className="container">
        <div className="row">
          <div className="text-left col-md mb-40 mt-4">
            <h3 className="text-white text-center mt-4 ">
              {locale.t("tabSecondaryTitle")}
            </h3>
          </div>
          <p className="text-center text-white fs-16">
            {locale.t("tableDescriptionIsalamic")}
          </p>
        </div>
        <div className="d-flex overflow-auto scroll-menu-hide mt-5">
          {accountList?.accountTypeList
            ?.filter((e) => e.active === 1 && e.type === "real")
            ?.map((e, i) => (
              <Link
                to={`/${localStorage.getItem("locale")}/swap/islamic/${
                  e.display
                }`}
                key={i}
              >
                <MenuItem
                  onClick={() => setIndex(i)}
                  color={
                    `/${localStorage.getItem("locale")}/swap/islamic/${
                      e.display
                    }` === pathName
                      ? "#FFF"
                      : "#8D8D8D"
                  }
                  borderBottom={
                    `/${localStorage.getItem("locale")}/swap/islamic/${
                      e.display
                    }` === pathName
                      ? "4px solid #1CB894"
                      : "2px solid #ff000000"
                  }
                  fontWeight={
                    `/${localStorage.getItem("locale")}/swap/islamic/${
                      e.display
                    }` === pathName
                      ? 400
                      : "unset"
                  }
                >
                  {e.display}
                </MenuItem>
              </Link>
            ))}
        </div>
        <div className="mt-5 overflow-auto overflow-md-hidden ">
          <TableSwap islamic={true} />
        </div>
      </div>
    </section>
  );
}

export default Islamic;
