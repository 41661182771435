import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { StyledContent } from '../../components/content/Content';
import { Layout, Row, Col, Button } from 'antd';
import {connect} from "react-redux";
const locale = require('react-redux-i18n').I18n


class Swap extends Component {
    render() {
        return (
            <Layout style={{ padding: '24px 0px', position: 'relative' }}>
                <h1>{locale.t('dashboardSwapReturn.title')}</h1>
                <StyledContent>
                    <div className="d-flex justify-content-center pb-4">
                        <Row className="w-100">
                            <div style={{ border: "1px solid #c8d7e2", borderRadius: 5, boxShadow: "5px 5px 10px #c8d7e2" }}>
                                <Row gutter={16}>
                                    <Col className="p-5 text-center" span={12} sm={24} xs={24} lg={12}>
                                        <h5 style={{ color: "#0162ac" }}>0.00/50,000 THB</h5>
                                        <h5>Balance</h5>
                                        <Button className='w-100 mt-1' type="primary" shape="round" style={{ backgroundColor: "#7cb342", color: 'white' }}>
                                            <Link to="./fund">{locale.t('dashboardSwapReturn.deposit')}</Link>
                                        </Button>
                                    </Col>
                                    <Col className="p-5 text-center" span={12} sm={24} xs={24} lg={12}>
                                        <h5>0.00 THB</h5>
                                        <h5>Balance</h5>
                                        <Button className='w-100 mt-1' type="primary" shape="round" style={{ backgroundColor: "#f5f5f5", color: '#d9d9d9' }} disabled>
                                            {locale.t('dashboardSwapReturn.withdraw')}
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className="text-center p-3" style={{ backgroundColor: "#e2f3ff", fontWeight: 'b' }}>
                                    <h5>{locale.t('dashboardSwapReturn.myCredit')}<span style={{ color: "#7cb342", marginLeft: 8 }}>0.00 THB</span></h5>
                                </Row>
                            </div>
                        </Row>
                    </div>
                    <div className="d-flex justify-content-center p-2">
                        <Row>
                            <h6 style={{ color: '#0162ac' }}>{locale.t('dashboardSwapReturn.condition')}</h6>
                            <p>{locale.t('dashboardSwapReturn.conditionSeq1')}</p>
                            <p>{locale.t('dashboardSwapReturn.conditionSeq2')}</p>
                            <p>{locale.t('dashboardSwapReturn.conditionSeq3')}</p>
                            <p>{locale.t('dashboardSwapReturn.conditionSeq4')}</p>
                        </Row>
                    </div>
                </StyledContent>
            </Layout>
        );
    }
}

const mapStateToProps = ({ i18n }) => ({
    locale: i18n.locale
})

export default connect(mapStateToProps, null) (Swap);