export const CheckCerrency = (id) => {
  switch (id) {
    case 1:
      return { iso: "THB", symbol: "฿" };
    case 2:
      return { iso: "GBP", symbol: "£" };
    case 3:
      return { iso: "USD", symbol: "$" };
    case 4:
      return { iso: "CNH", symbol: "¥" };
    case 5:
      return { iso: "SGD", symbol: "$" };
    case 6:
      return { iso: "MYR", symbol: "RM" };
    case 7:
      return { iso: "INR", symbol: "₹" };
    case 8:
      return { iso: "LAK", symbol: "₭" };
    case 9:
      return { iso: "IDR", symbol: "Rp" };
    case 10:
      return { iso: "VND", symbol: "₫" };
    case 11:
      return { iso: "MMK", symbol: "K" };
    case 12:
      return { iso: "PHP", symbol: "₱" };
    case 13:
      return { iso: "JPY", symbol: "¥" };
    default:
      return { iso: "USD", symbol: "$" };
  }
};
//
