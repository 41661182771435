import React from "react";
import styled from "styled-components";

const Card = styled.div`
  background: #fff;
  box-shadow: 0px 0px 12px 0px #a7d3ff80;
  border-radius: 8px;
  padding: 30px;
`;
const IconPanel = (props) => {
  return (
    <Card className="col-md-4 col-sm-12 iux-icon-panal-bg">
      <div style={{ display: "block" }}>
        <img className="iuxicon-panal mt-3" src={props.item.icon} alt="" />
      </div>
      <div className="mt-2 mb-3 ">
        <div className="text-center">
          <h4
            className="p-2 text-break text-iconpanal font-bold-iux"
            style={props.style}
          >
            {props.item.title}
          </h4>
          <p className="text-left col-md" style={props.style}>
            {" "}
            {props.item.description}{" "}
          </p>
          {props.button}
        </div>
      </div>
    </Card>
  );
};

export default IconPanel;
