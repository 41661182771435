import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import Tutorial from "../../../../components/tutorial";
const locale = require("react-redux-i18n").I18n;
function RejectVerify(props) {
  const {
    isReason,
    onChangeManualVerify,
    dataAttempt,
    onChangeAutoVerify,
    checkTypeVerify,
  } = props;

  const history = useHistory();
  // console.log(checkTypeVerify);
  return (
    <div className="d-flex justify-content-center my-5 flex-column align-items-center">
      <div className="fs-16"> {locale.t("verificationFailed")}</div>
      <div className="iux-scan  d-flex justify-content-center mt-4">
        <img src="/assets/images/verify/reject.svg" alt="reject" />
      </div>
      <div className="fs-16 mt-4 text-lightgray">{locale.t("sorryverify")}</div>
      <div className="box-reason text-center mt-4 mx-5 flex-column p-2">
        <p>
          {isReason?.reason_data?.text ||
            isReason?.reason_topic?.trim() ||
            locale.t("something")}
        </p>
        <p>
          {isReason?.reason_data?.value ||
            isReason?.reason ||
            locale.t("tryagain")}
        </p>
      </div>
      <div className="mt-2 fs-12 fw-300 text-lightgray text-center">
        {locale.t("needsupport")}
      </div>
      <div className="text-center mt-3 text-primary-sub-iux cursor-pointer">
        {/* <u> {locale.t("tutorial")}</u> */}
        {!checkTypeVerify?.verify_type?.includes("jumio") && (
          <Tutorial isButton />
        )}
      </div>
      {checkTypeVerify?.verify_type?.includes("manual") ? (
        <>
          <Button
            className="mt-4 btn-primary-new-iux "
            type="primary"
            onClick={() => onChangeManualVerify()}
          >
            <p className="">{locale.t("manualverify")}</p>
          </Button>
        </>
      ) : (
        <>
          {isReason?.attempt < 3 || dataAttempt?.attempt === 0 ? (
            <div className="d-flex">
              <Button
                className={"mt-4 btn-primary-new-iux"}
                type="primary"
                onClick={() => onChangeAutoVerify()}
              >
                <p className=""> {locale.t("autoverify")}</p>
              </Button>
              <Button
                className="mt-4 btn-outline-iux ml-2"
                type="primary"
                onClick={() => onChangeManualVerify()}
              >
                <p className=""> {locale.t("manualverify")}</p>
              </Button>
            </div>
          ) : (
            <div className="d-flex">
              <Button
                className="mt-4 btn-primary-new-iux "
                type="primary"
                onClick={() => onChangeManualVerify()}
              >
                <p className="">{locale.t("manualverify")}</p>
              </Button>

              {isReason?.attempt < 4 && (
                <Button
                  className={"mt-4 btn-outline-iux  ml-2"}
                  type="primary"
                  onClick={() => onChangeAutoVerify()}
                >
                  <p className=""> {locale.t("autoverify")}</p>
                </Button>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default RejectVerify;
