import { Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { post } from "../../../../helper/request";
import { renderApiDomain } from "../../../../helper/renderApiDomain";

const locale = require("react-redux-i18n").I18n;

export const postPerfectMoney = ({ amount_thb, account_trade_id }) => {
  return post(`${renderApiDomain() + "deposit/perfectmoney"}`, {
    amount_thb: amount_thb,
    account_trade_id: account_trade_id,
  });
};

const PerfetcMoney = (props) => {
  const lang = localStorage.getItem("locale");
  const refPerfect = useRef(null);
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState({
    payee_account: "",
    payee_name: "",
    reference: "",
    status_url: "",
    amount: 0,
  });
  useEffect(() => {
    const postApiPayment = async () => {
      setLoading(true);
      const payload = {
        account_trade_id: props.account.id,
        amount_thb: props.amount,
      };
      const { data } = await postPerfectMoney(payload);
      setPaymentData({
        payee_account: data?.data?.payee_account,
        payee_name: data?.data?.payee_name,
        reference: data?.data?.reference,
        status_url: data?.data?.status_url,
        amount: parseFloat(data?.data?.amount).toFixed(2),
      });
      setLoading(true);
      refPerfect.current.click();
    };
    postApiPayment();
  }, []);

  return (
    <Spin spinning={loading}>
      <form action="https://perfectmoney.is/api/step1.asp" method="POST">
        <p>
          <input
            type="hidden"
            name="PAYEE_ACCOUNT"
            value={paymentData.payee_account}
          />
          <input
            type="hidden"
            name="PAYEE_NAME"
            value={paymentData.payee_name}
          />
          <input
            type="hidden"
            name="PAYMENT_AMOUNT"
            value={paymentData.amount}
          />
          <input type="hidden" name="PAYMENT_UNITS" value="USD" />
          <input
            type="hidden"
            name="PAYMENT_ID"
            value={paymentData.reference}
          />
          <input
            type="hidden"
            name="STATUS_URL"
            value="https://11f8-2a09-bac0-411-00-81e-ea17.ngrok.io/api/perfectmoney/background"
          />
          <input
            type="hidden"
            name="PAYMENT_URL"
            value={`https://www.iuxmarket.com/${lang}/wallet`}
          />
          <input type="hidden" name="PAYMENT_URL_METHOD" value="LINK" />
          <input
            type="hidden"
            name="NOPAYMENT_URL"
            value={`https://www.iuxmarket.com/${lang}/wallet`}
          />
          <input type="hidden" name="NOPAYMENT_URL_METHOD" value="LINK" />
          <input type="hidden" name="BAGGAGE_FIELDS" value="" />
          <input
            ref={refPerfect}
            type="submit"
            name="PAYMENT_METHOD"
            value="PerfectMoney account"
            hidden
          />
        </p>
      </form>
    </Spin>
  );
};

export default PerfetcMoney;
