import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Table, Badge, Collapse, ConfigProvider, Empty } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import moment from "moment";
import { CheckLanguageMoment } from "../../helper/countryLangMoment";
const locale = require("react-redux-i18n").I18n;
const { Panel } = Collapse;
const columns = [
  {
    title: <div className="fs-12 text-gray">Time</div>,
    dataIndex: "effect_date",
    key: "effect_date",
    render: (value) => (
      <div>
        {moment(value)
          .locale(CheckLanguageMoment(localStorage.getItem("locale")))
          .format("DD MMMM YYYY")}
      </div>
    ),
  },
  {
    title: <div className="fs-12 text-gray">Currency</div>,
    dataIndex: "symbol",
    key: "symbol",
  },
  {
    title: <div className="fs-12 text-gray">Topic</div>,
    dataIndex: "topic",
    key: "topic",
  },
];
const renderExpand = (data) => {
  return (
    <div className="overflow-hidden">
      <div className="fs-12 font-weight-bold">Other details</div>
      <div className="row mt-2">
        <div className="col-3">
          <div className="fs-12 text-gray">Source </div>
          <div className="fs-12 text-black">{data.source}</div>
        </div>
        <div className="col-9">
          <div className="fs-12 text-gray">Description </div>
          <div className="fs-12 text-black">{data.detail}</div>
        </div>
      </div>
    </div>
  );
};

export const TableHoliday = ({ dataCalendar }) => {
  return (
    <div className="economic">
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>{locale.t("nodata")}</span>}
          />
        )}
      >
        <Table
          columns={columns}
          expandIconColumnIndex={3}
          // expandIcon={() => }
          expandable={{
            expandRowByClick: true,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <img
                  src="/assets/images/icons/dashboard/arrow-up.svg"
                  type="up"
                  alt="arrow-up"
                  style={{ fontSize: "20px" }}
                  className="cursor-pointer"
                  onClick={(e) => onExpand(record, e)}
                />
              ) : (
                <img
                  src="/assets/images/icons/dashboard/arrow-down.svg"
                  type="down"
                  alt="arrow-down"
                  style={{ fontSize: "20px" }}
                  className="cursor-pointer"
                  onClick={(e) => onExpand(record, e)}
                />
              ),

            expandedRowRender: (record) => renderExpand(record),
            rowExpandable: (record) => record.source !== "",
          }}
          dataSource={dataCalendar}
        />
      </ConfigProvider>
    </div>
  );
};

TableHoliday.propTypes = {
  dataCalendar: PropTypes.array,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TableHoliday);
