import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Row,
  Select,
  Upload,
} from "antd";

import { get, post } from "../../helper/request";
import { connect, useDispatch, useSelector } from "react-redux";

import { postChangePassword } from "../../api/account";
const locale = require("react-redux-i18n").I18n;
const { Option } = Select;

const styleInput = {
  // height: 42,
  borderRadius: 8,
};

const flagStyle = {
  width: 20,
  height: 20,
};

const OtpButton = styled(Button)`
  background-color: ${(props) =>
    props.countDown !== 0 ? "#d0d0d02e " : "#fff"} !important;
  border: ${(props) =>
    props.countDown !== 0
      ? "1px solid #d0d0d02e "
      : "1px solid #1CB894"} !important;
  color: ${(props) => (props.countDown !== 0 ? "gray" : "#1CB894")} !important;
  border-radius: 4px;
  height: 40px;
`;

const ChangePassword = ({ isModalVisible, handleCancel }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [countDown, setCountDown] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [form] = Form.useForm();

  const [file, setFile] = useState(null);
  const [checked, setChecked] = useState(false);
  const [dataBankList, setDataBankList] = useState([]);

  const onChangePassword = async (values) => {
    setLoading(true);

    if (values.currentPassword === values.newPassword) {
      message.error(
        "The new password cannot be the same as your current password."
      );
      setLoading(false);
    } else {
      try {
        const payload = {
          password: values.currentPassword,
          new_password: values.newPassword,
          confirm_password: values.newPassword,
        };
        const { data } = await postChangePassword(payload);
        setLoading(false);
        setIsSuccess(true);
      } catch (error) {
        setLoading(false);
        if (error.response.data.code === 2000) {
          message.error("The current password is invalid.");
        } else {
          message.error(error.response.data.error);
        }
        console.log(error);
      }
    }
  };
  const validatorPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = new RegExp(
        /^(?=.*[0-9])(?=.*[A-Za-z])[a-zA-Z0-9~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]{8,15}$/
      );
      // /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}$/
      if (getFieldValue("newPassword") || getFieldValue("confirmNewPassword")) {
        if (value.match(regex)) {
          if (getFieldValue("newPassword") === value) {
            return Promise.resolve();
          } else {
            return Promise.reject(
              // new Error("The two passwords that you entered do not match!")
              ""
            );
          }
        } else {
          return Promise.reject(
            // "Set password must have uppercase and lowercase letters and numbers , minimum 8 characters , maximum 15 characters"
            ""
          );
        }
      } else {
        return Promise.reject(
          // "Set password must have uppercase and lowercase letters and numbers , minimum 8 characters , maximum 15 characters"
          ""
        );
      }
    },
  });
  const regex1 = /^.{8,15}$/;
  const regex2 = /[a-zA-Z]/;
  const regex3 = /\d/;
  return (
    <div className="user">
      <Modal
        visible={isModalVisible}
        footer={null}
        closable={true}
        onCancel={() => {
          handleCancel();
          form.resetFields();
        }}
        title={false}
        className="w-modal width-modal-openAccount user user-change "
      >
        <div className="text-center f-20 user mt-4">
          {isSuccess
            ? locale.t("success_password_title")
            : locale.t("change_password_title")}
        </div>
        {!isSuccess ? (
          <>
            <Form
              onFinish={onChangePassword}
              className="p-2 p-md-4"
              form={form}
              layout={"vertical"}
            >
              <Form.Item
                name="currentPassword"
                label={locale.t("current_password")}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                style={{ color: "#8D8D8D" }}
              >
                <Input.Password
                  placeholder={locale.t("current_password")}
                  className="user-password"
                />
              </Form.Item>
              <Form.Item
                name="newPassword"
                label={locale.t("new_password")}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  validatorPassword,
                ]}
                style={{ color: "#8D8D8D" }}
              >
                <Input.Password
                  placeholder={locale.t("new_password")}
                  className="user-password"
                />
              </Form.Item>
              {/* <Form.Item
              name="confirmNewPassword"
              label="Confirm new password"
              dependencies={["newPassword"]}
              rules={[
                {
                  required: true,
                  message: "Please enter Confirm new password",
                },
                validatorPassword,
              ]}
            >
              <Input.Password
                className="user-password"
                placeholder="Confirm new password"
              />
            </Form.Item> */}
              <Form.Item shouldUpdate>
                {({
                  getFieldsValue,
                  getFieldValue,
                  isFieldTouched,
                  getFieldsError,
                }) => (
                  <>
                    <div className="d-flex align-items-center">
                      <div
                        className={`badge-check${
                          getFieldValue("newPassword")?.match(regex1)
                            ? " success"
                            : ""
                        } `}
                      ></div>
                      <div
                        className={`ml-2 ${
                          getFieldValue("newPassword")?.match(regex1)
                            ? "text-primary-new-iux"
                            : "text-lightgray"
                        }`}
                      >
                        {locale.t("validation1")}
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div
                        className={`badge-check${
                          getFieldValue("newPassword")?.match(regex2)
                            ? " success"
                            : ""
                        } `}
                      ></div>
                      <div
                        className={`ml-2 ${
                          getFieldValue("newPassword")?.match(regex2)
                            ? "text-primary-new-iux"
                            : "text-lightgray"
                        }`}
                      >
                        {locale.t("validation2")}
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div
                        className={`badge-check${
                          getFieldValue("newPassword")?.match(regex3)
                            ? " success"
                            : ""
                        } `}
                      ></div>
                      <div
                        className={`ml-2 ${
                          getFieldValue("newPassword")?.match(regex3)
                            ? "text-primary-new-iux"
                            : "text-lightgray"
                        }`}
                      >
                        {locale.t("validation3")}
                      </div>
                    </div>
                    <Button
                      htmlType="submit"
                      shape="round"
                      loading={loading}
                      disabled={
                        !isFieldTouched("currentPassword") ||
                        !isFieldTouched("newPassword") ||
                        getFieldsError().filter(({ errors }) => errors.length)
                          .length
                      }
                      className="mt-5 w-100 btn-primary-new-iux"
                      type="primary"
                      block
                    >
                      {locale.t("dashboardWallet.modal.confirm")}
                    </Button>
                  </>
                )}
              </Form.Item>
            </Form>
          </>
        ) : (
          <div className="d-flex justify-content-center my-5 flex-column align-items-center">
            <div className="iux-scan success d-flex justify-content-center mt-4">
              <img src="/assets/images/verify/success.svg" alt="success" />
            </div>
            <div className="fs-16 mt-4 text-lightgray">
              {locale.t("success_password_detail")}
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ChangePassword;
