import React from "react";
import TextWithCover from "../../components/text-with-cover/TextWithCover";
import { Row, Col } from "antd";
import FooterLandingPage from "../../components/footer-landing-page/FooterDark";
import RcQueueAnim from "rc-queue-anim";
import styled from "styled-components";
import { Link, Route, Switch } from "react-router-dom";
import About from "../contact/about/About";
import Security from "../contact/security/Security";
import Policy from "../contact/policy/Policy";
import Condition from "../contact/Condition/Condition";
import ContactUs from "../contact/us/ContactUs";
import Aboutus from "../contact/about-us/AboutUs";
import { Button } from "antd";

const locale = require("react-redux-i18n").I18n;

const MenuItem = styled.div`
  padding: 0.8rem 1rem;
  color: ${(props) => props.color};
  border-bottom: ${(props) => props.borderBottom};
  white-space: pre;
`;

export default function AbountPage() {
  const [isIdex, setIndex] = React.useState(5);
  const contact = [
    {
      link: `/${localStorage.getItem("locale")}/about-us/iuxmarket`,
      text: locale.t("contact_sidebar.lable_1"),
    },
    // {
    //   link: `/${localStorage.getItem("locale")}/about-us/security`,
    //   text: locale.t("contact_sidebar.lable_2"),
    // },
    {
      link: `/${localStorage.getItem("locale")}/about-us/policy`,
      text: locale.t("contact_sidebar.lable_3"),
    },
    // {
    //   link: `/${localStorage.getItem("locale")}/about-us/condition`,
    //   text: locale.t("contact_sidebar.lable_4"),
    // },
    {
      link: `/${localStorage.getItem("locale")}/about-us/contact`,
      text: locale.t("contact_sidebar.lable_5"),
    },
    {
      link: `/${localStorage.getItem("locale")}/about-us`,
      text: locale.t("about"),
    },
  ];
  const pathName = window.location.pathname;

  React.useEffect(() => {
    const activeCover = () => {
      if (
        pathName === `/${localStorage.getItem("locale")}/about-us/iuxmarket`
      ) {
        setIndex(0);
      }
      if (pathName === `/${localStorage.getItem("locale")}/about-us/security`) {
        setIndex(1);
      }
      if (pathName === `/${localStorage.getItem("locale")}/about-us/policy`) {
        setIndex(2);
      }
      if (
        pathName === `/${localStorage.getItem("locale")}/about-us/condition`
      ) {
        setIndex(3);
      }
      if (pathName === `/${localStorage.getItem("locale")}/about-us/contact`) {
        setIndex(4);
      }
      if (pathName === `/${localStorage.getItem("locale")}/about-us`) {
        setIndex(5);
      }
    };
    activeCover();
  }, [pathName]);

  const imgcover = [
    {
      title: "IUX MARKETS",
      description: "",
      image: "/assets/images/covers/v2/cover-iux.png",
    },
    {
      title: "Safety information",
      description: "",
      image: "/assets/images/covers/v2/cover-sefety.png",
    },
    {
      title: "Privacy Policy",
      description: "",
      image: "/assets/images/covers/v2/cover-policy.png",
    },
    {
      title: "Terms and conditions",
      description: "",
      image: "/assets/images/covers/v2/cover-policy.png",
    },
    {
      title: "We are here for you, 24/7.",
      description: "",
      image: "/assets/images/covers/v2/cover-contact.png",
    },
    {
      title: locale.t("about"),
      description: locale.t("land_section_1_2"),
      image: "/assets/images/covers/v2/cover-iux.png",
    },
  ];

  const startWith = pathName.startsWith(
    `/${localStorage.getItem("locale")}/about-us/`
  );

  const renderButtonCover = () => {
    if (isIdex < 4) {
      return (
        <Link to={`/${localStorage.getItem("locale")}/login`}>
          <div className="d-flex justify-content-center justify-content-md-start">
            <div className="">
              <Button className="mt-4 col-md-12 mt-2 btn-primary-new-iux">
                {locale.t("button.sub_button.title_1")}
              </Button>
            </div>
          </div>
        </Link>
      );
    } else if (isIdex === 4) {
      return (
        <a
          href={`https://help.iuxmarkets.com/${localStorage.getItem(
            "locale"
          )}/`}
        >
          <div className="d-flex justify-content-center justify-content-md-start">
            <div className="">
              <Button className="mt-4 col-md-12 mt-2 btn-primary-new-iux">
                <img
                  src="/assets/images/icons/chat.svg"
                  alt="chat"
                  className="mr-3"
                />{" "}
                {locale.t("Launch live chat")}
              </Button>
            </div>
          </div>
        </a>
      );
    } else {
      return;
    }
  };
  return (
    <RcQueueAnim className="bg-black">
      <TextWithCover
        key={1}
        imagePath={process.env.PUBLIC_URL + imgcover[isIdex].image}
        title={imgcover[isIdex].title}
        content={
          <>
            <div>
              <p className="fs-16">{imgcover[isIdex].description}</p>
              {renderButtonCover()}
            </div>
          </>
        }
      />
      <div className="sub-menu-page">
        <div className="container">
          <div className="d-flex overflow-auto mt-4 scroll-menu-hide">
            {contact.map((e, i) => (
              <Link to={e.link}>
                <MenuItem
                  onClick={() => setIndex(i)}
                  color={e.link === pathName ? "#FFF" : "#8D8D8D"}
                  borderBottom={
                    e.link === pathName
                      ? "4px solid #1CB894"
                      : "2px solid #ff000000"
                  }
                  fontWeight={e.link === pathName ? 400 : "unset"}
                >
                  {e.text}
                </MenuItem>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <Switch>
        <div className="" style={{ display: startWith ? "none" : "block" }}>
          <Route
            path={`/:lang/about-us/`}
            render={() => {
              return <Aboutus locale={locale} />;
            }}
          />
        </div>
      </Switch>
      <Switch>
        <div className="" style={{ marginBottom: "5rem" }}>
          <Route
            path={`/:lang/about-us/iuxmarket`}
            render={() => {
              return <About locale={locale} />;
            }}
          />
          <Route
            path={`/:lang/about-us/security`}
            render={() => {
              return <Security locale={locale} />;
            }}
          />
          <Route
            path={`/:lang/about-us/policy`}
            render={() => {
              return <Policy locale={locale} />;
            }}
          />
          <Route
            path={`/:lang/about-us/condition`}
            render={() => {
              return <Condition locale={locale} />;
            }}
          />
          <Route
            path={`/:lang/about-us/contact`}
            render={() => {
              return <ContactUs locale={locale} />;
            }}
          />
        </div>
      </Switch>
      <FooterLandingPage locale={locale} />
    </RcQueueAnim>
  );
}
