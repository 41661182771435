export default {
  th: {
    notifications: "การแจ้งเตือน",
    messages: "ข้อความ",
    updates: "อัปเดต",
    nomessages: "ไม่มีข้อความแจ้งเตือน",
  },
  lo: {
    notifications: "ການແຈ້ງເຕືອນ",
    messages: "ຂໍ້ຄວາມ",
    updates: "ແປງປະສົມຜົນ",
    nomessages: "ບໍ່ມີຂໍ້ຄວາມການແຈ້ງເຕືອນ",
  },
  en: {
    notifications: "Notifications",
    messages: "Messages",
    updates: "Updates",
    nomessages: "No messages notifications",
  },
  hi: {
    notifications: "सूचनाएँ",
    messages: "संदेश",
    updates: "अपडेट",
    nomessages: "संदेश सूचनाएँ नहीं",
  },
  ms: {
    notifications: "Pemberitahuan",
    messages: "Mesej",
    updates: "Kemaskini",
    nomessages: "Tiada pemberitahuan mesej",
  },
  id: {
    notifications: "Pemberitahuan",
    messages: "Pesan",
    updates: "Pembaruan",
    nomessages: "Tidak ada pemberitahuan pesan",
  },
  zh: {
    notifications: "通知",
    messages: "消息",
    updates: "更新",
    nomessages: "无消息通知",
  },
  vi: {
    notifications: "Thông báo",
    messages: "Tin nhắn",
    updates: "Cập nhật",
    nomessages: "Không có thông báo tin nhắn",
  },
  ko: {
    notifications: "알림",
    messages: "메시지",
    updates: "업데이트",
    nomessages: "메시지 알림 없음",
  },
  fil: {
    notifications: "Abiso",
    messages: "Mensahe",
    updates: "Aksyon",
    nomessages: "Walang mga abiso ng mensahe",
  },
  ja: {
    notifications: "通知",
    messages: "メッセージ",
    updates: "更新",
    nomessages: "メッセージ通知なし",
  },
  es: {
    notifications: "Notificaciones",
    messages: "Mensajes",
    updates: "Actualizaciones",
    nomessages: "No hay notificaciones de mensajes",
  },
};
