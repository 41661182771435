import { Modal } from "antd";
import React from "react";
const locale = require("react-redux-i18n").I18n;
function ModalSeminar({ isOpen, handleClose }) {
  const modalStyles = {
    mask: {},
    content: {
      backgroundImage: "url('/assets/images/seminar/bg-seminar.png')",
      backgroundColor: "#000",
      backgroundSize: "100% 100%",
      backgroundRepeat: "no-repeat",
      zIndex: 1005,
    },
    close: {
      color: "#FFF",
    },
  };
  return (
    <div>
      <Modal
        open={isOpen}
        onOk={handleClose}
        onCancel={handleClose}
        styles={modalStyles}
        footer={null}
      >
        <div className="text-white p-4 mx-auto" style={{ maxWidth: 346 }}>
          <h1 className="text-center fs-24">
            {locale.t("seminar_malaysia.title")}
          </h1>
          <p className="text-center fs-16 mt-3" style={{ color: "#D9D9D9" }}>
            {locale.t("seminar_malaysia.description")}
          </p>
          <div className="d-flex w-100">
            <button
              className="btn-primary-new-outline-trade-web w-100 mt-5"
              onClick={() =>
                window.open(
                  "https://seminar.iuxmarkets.com/" +
                    (localStorage.getItem("locale") === "ms" ? "ms" : "en"),
                  "_blank"
                )
              }
            >
              {locale.t("seminar_malaysia.button")}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ModalSeminar;
