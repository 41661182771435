import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get } from "../../helper/request";
import { Link } from "react-router-dom";
import { renderApiDomain } from "../../helper/renderApiDomain";
const locale = require("react-redux-i18n").I18n;

export const Globalmarket = (props) => {
  const [symbol, setSymbol] = useState([]);
  const arrglobal = [
    {
      img: "/assets/images/icons/products/icon-products-new-1.svg",
      title: locale.t("land_section_7_3_1"),
      des: ` 60+ ${locale.t("land_section_7_3")}`,
      symbol: [
        "audusd",
        "gbpcad",
        "eurgbp",
        "eurjpy",
        "eurusd",
        "gbpusd",
        "nzdusd",
        "usdcad",
        "usdchf",
        "usdjpy",
        "audcad",
        "audchf",
      ],
    },
    {
      img: "/assets/images/icons/products/icon-products-new-2.svg",
      title: locale.t("land_section_7_3_2"),
      des: ` 19+ ${locale.t("land_section_7_3")}`,
      symbol: [
        "UKOUSD",
        "USOUSD",
        "xagusd",
        "xaueur",
        "XAUUSD",
        "XNGUSD",
        "xpdusd",
        "xptusd",
        "xrpusd",
      ],
    },
    {
      img: "/assets/images/icons/products/icon-products-new-3.svg",
      title: locale.t("land_section_7_3_3"),
      des: ` 120+ ${locale.t("land_section_7_3")}`,
      symbol: [
        "aapl",
        "tsla",
        "abnb",
        "amzn",
        "nflx",
        "msft",
        "pfe",
        "twtr",
        "goog",
        "amd",
        "c",
        "fb",
      ],
    },
    {
      img: "/assets/images/icons/products/icon-products-new-4.svg",
      title: locale.t("land_section_7_3_4"),
      des: ` 23+ ${locale.t("land_section_7_3")}`,
      symbol: [
        "ASX200",
        "DAX30",
        "DJI30",
        "F40",
        "FTSE100",
        "HK50",
        "IBX35",
        "NASDAQ100",
        "NIK225",
        "SPX500",
        "STOXX50",
      ],
    },
    {
      img: "/assets/images/icons/products/icon-products-new-5.svg",
      title: locale.t("land_section_7_3_5"),
      des: ` 5+ ${locale.t("land_section_7_3")}`,
      symbol: ["us10Y", "gb10y", "us10", "de10", "ca10y", "it10y"],
    },
  ];
  const addDefaultSrc = (ev) => {
    ev.target.src = "/assets/images/icons/globe.svg";
  };

  useEffect(() => {
    get(renderApiDomain() + `/calc/m5/symbols`).then((res) => {
      setSymbol(res.data);
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <section key={9} className="bg-black">
        <div className="align-items-center container justify-center">
          <div
            className="ml-0 ml-lg-2 ml-xl-2"
            style={{ padding: "4rem 12px" }}
          >
            <h2 className="text-center text-white text-semibold text-uppercase">
              {locale.t("land_section_7_1")} {locale.t("land_section_7_2")}
            </h2>
            <div className="d-flex flex-wrap mt-4">
              {arrglobal.map((e, i) => (
                <div
                  className="col-12 col-md mt-3 mt-md-0"
                  onClick={scrollToTop}
                >
                  <Link
                    to={`/${localStorage.getItem("locale")}/spread`}
                    key={i}
                  >
                    <div class="flip-card" tabIndex="0">
                      <div class="flip-card-inner">
                        <div class="flip-card-front">
                          <div className="">
                            <img
                              alt="CFDs Markets 60 products"
                              className=""
                              src={e.img}
                              height={60}
                              width="auto"
                            />
                            <div className="text-white mt-3">{e.title}</div>
                            <div className="text-white">{e.des}</div>
                          </div>
                        </div>
                        <div class="flip-card-back px-4">
                          <div className="text-center mt-3">{e.title}</div>
                          <div className=" row">
                            {e.symbol?.map((ele, i) => (
                              <div className="mt-3 col-3 p-0 d-flex align-items-center justify-content-center">
                                <img
                                  onError={addDefaultSrc}
                                  src={`https://ik.imagekit.io/awonar/public/images/markets/${ele}.png?tr=w-100`}
                                  width={28}
                                  alt={"tsla"}
                                  style={{ borderRadius: "10px" }}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Globalmarket);
