import { Col, Row } from "antd";
import React from "react";

function BoxSelectPayment({ images, title, subtitle, onSubmit }) {
  const addDefaultSrc = (ev) => {
    ev.target.src = "/assets/images/payment/alphapo.png";
  };
  return (
    <div className="radio-custom-payment box-select-payment" onClick={onSubmit}>
      <div className="d-flex align-items-center">
        {images && (
          <img
            src={images}
            alt="...."
            width={40}
            height={40}
            style={{ borderRadius: 4 }}
            onError={addDefaultSrc}
          />
        )}
        <div className="ml-2">
          {title && <div className="text-left w-100">{title}</div>}
          {subtitle && <div className="text-left w-100">{subtitle}</div>}
        </div>
      </div>
    </div>
  );
}

export default BoxSelectPayment;
