import React from "react";
import ReactApexCharts from "react-apexcharts";
import { fCurrency, fCurrencySymbol } from "../../../../../helper/formatNumber";
import moment from "moment";
import { CheckLanguageMoment } from "../../../../../helper/countryLangMoment";
import { CheckFormatDate } from "../../../../../helper/countryFormatDate";
const locale = require("react-redux-i18n").I18n;
function CardReport(props) {
  const {
    dataReport,
    isTheme,
    dataSymbolStats,
    isDisplayDate,
    isAccount,
    isTypeFilter,
  } = props;
  const dateshow = isDisplayDate.split(",");

  const chartOptions = {
    plotOptions: { bar: { columnWidth: "16%" } },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    chart: {
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    grid: {
      show: false,
    },
    // labels: false,
    stroke: {
      curve: "smooth",
      width: 2,
    },
    dataLabels: {
      enabled: false,
    },
    colors: [dataReport?.totalTotal > 0 ? "#52AB8C" : "#E3673F"],
    xaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
    yaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: { show: false },
    },
    toolbar: {
      show: false,
    },
    tooltip: {
      enabled: false,
      x: {
        show: false,
      },
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },

    // tooltip: false,
  };
  const addDefaultSrc = (ev) => {
    ev.target.src = `/assets/images/icons/${
      isTheme === "light" ? "globe-black" : "globe"
    }.svg`;
  };
  return (
    <div
      className={`card-report-${isTheme} ${
        props?.previews ? "ispreviews" : ""
      }`}
      style={{ fontFamily: "Inter,sans-serif" }}
    >
      <div className="d-flex justify-content-between">
        <div>
          <div className="text-lightgray fs-12">
            {locale.t("account_summary")}
          </div>
          <div
            className={`${
              dataReport?.totalTotal >= 0
                ? "text-primary-sub-iux"
                : "text-is-error "
            } fs-46 fw-700`}
            style={{ lineHeight: 1 }}
          >
            {dataReport?.totalTotal >= 0 ? "+" : "-"}

            {isAccount.account_type === "Cent" ? "¢" : "$"}
            {fCurrencySymbol(Math.abs(dataReport?.totalTotal))}
          </div>
        </div>
        <div>
          <img
            alt="IUX"
            src="/assets/images/icons/iux-logo.png"
            width={48}
            className="border-logo"
          />
        </div>
      </div>
      <div className="text-white">
        <ReactApexCharts
          type="area"
          series={[
            {
              name: "series1",
              data: dataReport.groupedArray?.map((e, i) => {
                return e.total;
              }),
            },
          ]}
          options={chartOptions}
          height={148}
        />
      </div>
      <div className="d-flex justify-content-between">
        <div>
          <div className="d-flex">
            <div className="bage-free-swap-isfree d-flex aligns-item-center mb-2">
              {locale.t("top_invest")}
            </div>
          </div>
          <div>
            {dataSymbolStats?.map((e, i) => (
              <img
                onError={addDefaultSrc}
                key={i}
                src={`${process.env.REACT_APP_POLICY}${e.symbol}`}
                width={24}
                alt={e?.name?.toLowerCase()}
                className="rounded-circle mr-2"
              />
            ))}
          </div>
        </div>
        <div className="text-lightgray fs-8 d-flex justify-content-end align-items-end">
          {isTypeFilter === "All" &&
          moment(isAccount?.createdAt).format("DDMMMYY") ===
            moment().format("DDMMMYY")
            ? moment(isAccount.createdAt)
                .locale(CheckLanguageMoment(localStorage.getItem("locale")))
                .format(CheckFormatDate(localStorage.getItem("locale")))
            : isTypeFilter === "All"
            ? `${moment(isAccount.createdAt)
                .locale(CheckLanguageMoment(localStorage.getItem("locale")))
                .format(
                  CheckFormatDate(localStorage.getItem("locale"))
                )}-${moment(dateshow[1])
                .locale(CheckLanguageMoment(localStorage.getItem("locale")))
                .format(CheckFormatDate(localStorage.getItem("locale")))}`
            : `${moment(dateshow[0])
                .locale(CheckLanguageMoment(localStorage.getItem("locale")))
                .format(
                  CheckFormatDate(localStorage.getItem("locale"))
                )}-${moment(dateshow[1])
                .locale(CheckLanguageMoment(localStorage.getItem("locale")))
                .format(CheckFormatDate(localStorage.getItem("locale")))}`}
        </div>
      </div>
    </div>
  );
}

export default CardReport;
