// utils/pixel.js
// export const initMetaPixel = (pixelId) => {
//   // eslint-disable-next-line no-unused-expressions
//   !(function (f, b, e, v, n, t, s) {
//     if (f.fbq) return;
//     n = f.fbq = function () {
//       n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
//     };
//     if (!f._fbq) f._fbq = n;
//     n.push = n;
//     n.loaded = !0;
//     n.version = "2.0";
//     n.queue = [];
//     t = b.createElement(e);
//     t.async = !0;
//     t.src = v;
//     s = b.getElementsByTagName(e)[0];
//     s.parentNode.insertBefore(t, s);
//   })(
//     window,
//     document,
//     "script",
//     "https://connect.facebook.net/en_US/fbevents.js"
//   );
//   // eslint-disable-next-line no-undef
//   window?.fbq("init", pixelId);
//   // eslint-disable-next-line no-undef
//   wondow?.fbq("track", "PageView");
// };
export const initMetaPixel = (pixelId) => {
  if (window.fbq) return;

  window.fbq = function () {
    window.fbq.callMethod
      ? window.fbq.callMethod.apply(window.fbq, arguments)
      : window.fbq.queue.push(arguments);
  };

  if (!window._fbq) window._fbq = window.fbq;

  window.fbq.push = window.fbq;
  window.fbq.loaded = true;
  window.fbq.version = "2.0";
  window.fbq.queue = [];

  window.fbq("init", pixelId);
  window.fbq("track", "PageView");
};
