import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, Input, Select } from "antd";
import styled from "styled-components";
import { post } from "../../helper/request";
import { Link } from "react-router-dom";
import { calcPipValue, calcPipValueMt5 } from "./Cacl";
import Recommendation from "./components/Recommendation";
import { renderApiDomain } from "../../helper/renderApiDomain";
const { Option } = Select;
const locale = require("react-redux-i18n").I18n;

const BoxContentCal = styled.div`
  padding: 12px;
  border-radius: 20px;
  border: 1px solid #8080803d;
  background: #242424;
`;

const BoxErrors = styled.div`
  color: red;
`;

const styleInput = {
  height: 40,
  borderRadius: 4,
};

const fontWeight = {
  fontWeight: 700,
  fontSize: 14,
};

const ButtonSum = styled(Button)`
  padding: 0px 34px;
  background-color: rgb(45 181 74) !important;
  color: rgb(255, 255, 255);
  font-size: 14px;
  height: 40px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(45 181 74);
  text-transform: initial;
`;

const styleDisabled = {
  backgroundColor: "transparent",
  color: "#fff",
  height: 40,
  borderRadius: 4,
};

export default function Pip(props) {
  const symbol = props.symbols;
  const metaTrader = props.mtType;
  const { handleChangeSymbol } = props;
  const [form] = Form.useForm();
  const [lot, setLot] = useState();
  const [accountType, setAccountType] = useState();
  const [currency, setCurrency] = useState();
  const [baseCurrency, setBaseCurrency] = useState("USD");
  const [fluctuation, setFluctuation] = useState();
  const [contractSize, setContractSize] = useState();
  const [converse, setConverse] = useState();
  const [bid, setBid] = useState();
  const [calcMode, setCalcMode] = useState();
  const [currencyPair, setCurrencyPair] = useState();
  // show
  const [currencyProfit, setCurrencyProfit] = useState();
  const [PipAccount1, setPipAccount1] = useState();
  const [PipAccount2, setPipAccount2] = useState();

  const [errors, setError] = useState([]);

  function onChangeSelectCurrency(value) {
    setCurrency(value);
    if (metaTrader.mt4) {
      post(renderApiDomain() + `calc/rate`, {
        account: baseCurrency,
        pair: value,
      })
        .then((response) => {
          setConverse(response.data.converse);
          setBid(response.data.symbol.bid);
          setContractSize(response.data.symbol.contract_size);
          setCurrencyPair(response.data.symbol.symbol);
          setFluctuation(
            Math.pow(10, -response.data.symbol.digits).toFixed(
              response.data.symbol.digits
            )
          );
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      post(renderApiDomain() + `calc/mt5/rate`, {
        account: baseCurrency,
        pair: value,
      }).then((response) => {
        setCurrencyProfit(response.data.symbol.currencyProfit);
        setConverse(response.data.converse);
        setCalcMode(response.data.symbol.calcMode);
        setBid(response.data.symbol.tickShort.bid);
        setContractSize(response.data.symbol.contractSize);
        setCurrencyPair(response.data.symbol.symbol);
        setFluctuation(
          Math.pow(10, -response.data.symbol.digits).toFixed(
            response.data.symbol.digits
          )
        );
      });
    }
  }

  function onChangeSelectBaseCurrency(value) {
    setBaseCurrency(value);
    if (currency !== undefined) {
      if (metaTrader.mt4) {
        post(renderApiDomain() + `calc/rate`, {
          account: value,
          pair: currency,
        })
          .then((response) => {
            setConverse(response.data.converse);
            setBid(response.data.symbol.bid);
            setContractSize(response.data.symbol.contract_size);
            setCurrencyPair(response.data.symbol.symbol);
            setFluctuation(
              Math.pow(10, -response.data.symbol.digits).toFixed(
                response.data.symbol.digits
              )
            );
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        post(renderApiDomain() + `calc/mt5/rate`, {
          account: value,
          pair: currency,
        }).then((response) => {
          setCurrencyProfit(response.data.symbol.currencyProfit);
          setConverse(response.data.converse);
          setCalcMode(response.data.symbol.calcMode);
          setBid(response.data.symbol.tickShort.bid);
          setContractSize(response.data.symbol.contractSize);
          setCurrencyPair(response.data.symbol.symbol);
          setFluctuation(
            Math.pow(10, -response.data.symbol.digits).toFixed(
              response.data.symbol.digits
            )
          );
        });
      }
    }
  }

  function handleChangeValue(value) {
    setLot(value);
  }
  function onChangeSelectAccountType(value) {
    setAccountType(value);
  }

  const onSubmit = () => {
    if (metaTrader.mt4) {
      if (
        baseCurrency === undefined ||
        lot === undefined ||
        currency === undefined ||
        accountType === undefined
      ) {
        setError(locale.t("dashboardWallet.input_error"));
      } else {
        setError("");
        const { pipBase, pipQoute } = calcPipValue(
          fluctuation,
          converse,
          lot,
          contractSize
        );
        setPipAccount1(pipBase);
        setPipAccount2(pipQoute);
      }
    } else {
      if (
        baseCurrency === undefined ||
        lot === undefined ||
        currency === undefined ||
        accountType === undefined
      ) {
        setError(locale.t("dashboardWallet.input_error"));
      } else {
        setError("");
        const { pipBase, pipQoute } = calcPipValueMt5(
          fluctuation,
          converse,
          lot,
          contractSize,
          calcMode
        );
        setPipAccount1(pipBase);
        setPipAccount2(pipQoute);
      }
    }
  };
  const handleReset = () => {
    setPipAccount1(0);
    setPipAccount2(0);
  };

  const pair =
    currencyPair !== undefined && currencyPair.substr(3, 5).replace(".i", "");
  return (
    <>
      <Form form={form} className="col-md-8 mb-4">
        <BoxContentCal>
          <Col span={12}>
            <Form.Item name="1">
              <div className="mt-3 text-gray">
                {locale.t("fxcal_list_1_box.Platform")}
              </div>
              <Select
                size="large"
                className="w-100"
                onChange={(value) => {
                  handleChangeSymbol(value);
                  form.resetFields();
                  handleReset();
                }}
                defaultValue="Metatrader5"
              >
                <Option value={"mt5"}>Metatrader5</Option>
              </Select>
            </Form.Item>
          </Col>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name="1">
                <div className="mt-3 text-gray">
                  {locale.t("fxcal_list_3_box.title_3")}
                </div>
                <Select
                  onChange={(value) => onChangeSelectBaseCurrency(value)}
                  defaultValue={locale.t("calculator_select")}
                  size="large"
                  className="w-100"
                >
                  <Option value={"USD"}>USD</Option>
                </Select>
              </Form.Item>
              <Form.Item name="1">
                <div className="mt-3 text-gray">
                  {locale.t("fxcal_list_3_box.title_1")}
                </div>
                <Select
                  onChange={(value) => onChangeSelectCurrency(value)}
                  defaultValue={locale.t("calculator_select")}
                  size="large"
                  className="w-100"
                >
                  {symbol !== null &&
                    symbol?.map((e, i) => {
                      return (
                        <Option key={i} value={e.name}>
                          {e.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item name="1">
                <div className="mt-3 text-gray">
                  {locale.t("fxcal_list_3_box.title_5")}
                </div>
                <Select
                  onChange={(value) => onChangeSelectAccountType(value)}
                  defaultValue={locale.t("calculator_select")}
                  size="large"
                  className="w-100"
                >
                  <Option value={"Standard"}>Standard</Option>
                </Select>
              </Form.Item>
            </Col>
            {/*  */}
            <Col span={12} className="d-flex flex-column">
              <Form.Item name="1">
                <div className="mt-3 text-gray">
                  {locale.t("fxcal_list_3_box.title_2")}
                </div>
                <Input
                  style={styleInput}
                  placeholder={"Please enter value"}
                  onChange={(e) => handleChangeValue(e.target.value)}
                />
              </Form.Item>
              <Form.Item name="1">
                <div className="mt-3 text-gray">
                  {locale.t("fxcal_list_3_box.title_4")}
                </div>
                <Input value={bid} style={styleDisabled} disabled />
              </Form.Item>
            </Col>
          </Row>
          <div style={{ margin: "1rem 0" }}>
            <BoxErrors>{errors}</BoxErrors>
            <button
              onClick={() => onSubmit()}
              className="w-sm-100 btn-primary-new-iux"
            >
              {locale.t("btn_calculator")}
            </button>
          </div>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <h6 style={fontWeight} className=" text-gray">
                {locale.t("fxcal_list_3_box.title_6_1.title")}{" "}
                {baseCurrency ? `(${baseCurrency})` : ""}
              </h6>
              <div className=" text-gray">
                {locale.t("fxcal_list_3_box.title_6")}
              </div>
              <Input
                value={PipAccount1 ? PipAccount1.toFixed(5) : ""}
                style={styleDisabled}
                disabled
              />
            </Col>
            <Col span={12}>
              <h6 style={fontWeight} className=" text-gray">
                {locale.t("fxcal_list_3_box.title_6_1.title")}{" "}
                {metaTrader.mt4
                  ? pair
                    ? `(${pair})`
                    : ""
                  : currencyProfit
                  ? `(${currencyProfit})`
                  : ""}
              </h6>
              <div className=" text-gray">
                {locale.t("fxcal_list_3_box.title_7")}
              </div>
              <Input
                value={PipAccount2 ? PipAccount2.toFixed(5) : ""}
                style={styleDisabled}
                disabled
              />
            </Col>
          </Row>
        </BoxContentCal>
      </Form>
      <Recommendation />
    </>
  );
}
