import React from "react";
import { Link, Route, Switch } from "react-router-dom";
import FooterLandingPage from "../../components/footer-landing-page/FooterDark";
import TextCoverForex from "../../components/text-with-cover/TextCoverForex";
import styled from "styled-components";
import HelpFn from "./Faq";
import LiveChat from "./liveChat";
import Calculator from "./helpCenter";
import RcQueueAnim from "rc-queue-anim";
import Economic from "../economic-calendar";

const locale = require("react-redux-i18n").I18n;

const MenuItem = styled.div`
  padding: 0.8rem 1rem;
  color: ${(props) => props.color};
  border-bottom: ${(props) => props.borderBottom};
  white-space: pre;
`;
// href={`https://help.iuxmarkets.com/${localStorage.getItem(
//   "locale"
// )}/`}
const HelpCenter = () => {
  const pathName = window.location.pathname;
  const [isIdex, setIndex] = React.useState(0);
  const menu = [
    {
      id: 0,
      text: locale.t("help_cover_title"),
      link: `https://help.iuxmarkets.com/${localStorage.getItem("locale")}/`,
      openlink: true,
    },
    {
      id: 1,
      text: locale.t("fxcal_page_title"),
      link: `/${localStorage.getItem("locale")}/help/calculators`,
      openlink: false,
    },
    // {
    //   id: 2,
    //   text: locale.t("economic"),
    //   link: `/${localStorage.getItem("locale")}/help/economic-calendar`,
    // },
  ];
  const imgcover = [
    {
      title: locale.t("help_cover_title"),
      description: locale.t("help_cover_sub_title"),
    },
    {
      title: locale.t("fxcal_cover_title"),
      description: locale.t("fxcal_cover_sub_title"),
    },
    {
      title: locale.t("economic"),
      description: locale.t("economic_sub_title"),
    },
  ];
  React.useEffect(() => {
    const activeCover = () => {
      if (pathName) {
        menu.filter((e, i) => e.link === pathName && setIndex(e.id));
      }
    };
    activeCover();
  }, [pathName]);
  return (
    <RcQueueAnim>
      <section key={1} className="bg-black">
        {/* <LiveChat /> */}
        <TextCoverForex
          key={1}
          styles={{
            backgroundPosition: "center !important",
            bagroundColor: "#1d263a",
          }}
          title={imgcover[isIdex].title}
          content={imgcover[isIdex].description}
        />
        <div className="sub-menu-page">
          <div className="container">
            <div
              className="d-flex overflow-auto scroll-menu-hide"
              style={{ borderBottom: "1px solid #3E3E3E" }}
            >
              {menu.map((e, i) =>
                e.openlink ? (
                  <a href={e.link}>
                    <MenuItem
                      onClick={() => setIndex(i)}
                      color={e.link === pathName ? "#FFF" : "#8D8D8D"}
                      borderBottom={
                        e.link === pathName
                          ? "4px solid #1CB894"
                          : "2px solid #ff000000"
                      }
                      fontWeight={e.link === pathName ? 400 : "unset"}
                    >
                      {e.text}
                    </MenuItem>
                  </a>
                ) : (
                  <Link to={e.link}>
                    <MenuItem
                      onClick={() => setIndex(i)}
                      color={e.link === pathName ? "#FFF" : "#8D8D8D"}
                      borderBottom={
                        e.link === pathName
                          ? "4px solid #1CB894"
                          : "2px solid #ff000000"
                      }
                      fontWeight={e.link === pathName ? 400 : "unset"}
                    >
                      {e.text}
                    </MenuItem>
                  </Link>
                )
              )}
            </div>
          </div>
        </div>

        <Switch>
          <Route
            path={`/:lang/help/calculators`}
            render={() => {
              return <Calculator locale={locale} />;
            }}
          />
          {/* <Route
            path="/:lang/help/economic-calendar"
            render={() => {
              return <Economic locale={locale} />;
            }}
          /> */}
          <Route
            path={`/:lang/help`}
            render={() => {
              return <HelpFn locale={locale} />;
            }}
          />
        </Switch>
      </section>
      <section key={5}>
        <FooterLandingPage locale={locale} />
      </section>
    </RcQueueAnim>
  );
};

export default HelpCenter;
