export default {
  th: {
    download_for: "ดาวน์โหลดสำหรับ",
    open_web_terminal: "เปิดเว็บเทอร์มินัล MT5",
    web_terminal: "เว็บเทอร์มินัล MetaTrader5",
    meta_download_app: "แอปมือถือ MetaTrader",
    iux_download_app: "แอป IUX Trade",
    iux_markets_web_trade: "เว็บเทรด IUX",
    open_web_trade: "เปิดเว็บเทรด",
  },
  lo: {
    download_for: "ດາວໂຫຼດສໍາລັບ",
    open_web_terminal: "ເປີດ MT5 WebTermina ",
    web_terminal: "MetaTrader5 WebTerminal",
    meta_download_app: "MetaTrader5 ໃນ​ໂທລະ​ສັບ​​",
    iux_download_app: "ແອັບການຄ້າ IUX ",
    iux_markets_web_trade: "ການຄ້າ IUX",
    open_web_trade: "ເປີດການຄ້າເວັບ",
  },
  en: {
    download_for: "Download for",
    open_web_terminal: "Open MT5 WebTerminal ",
    web_terminal: "MetaTrader5 WebTerminal",
    meta_download_app: "MetaTrader5 Mobile",
    iux_download_app: "IUX Trade App",
    iux_markets_web_trade: "IUX Web Trade",
    open_web_trade: "Open Web Trade",
  },
  hi: {
    download_for: "डाउनलोड करना",
    open_web_terminal: "खुला MT5 WebTerminal",
    web_terminal: "MetaTrader5 WebTerminal",
    meta_download_app: "MetaTrader5 गतिमान",
    iux_download_app: "IUX ऐप ट्रेड",
    iux_markets_web_trade: "IUX वेब व्यापार",
    open_web_trade: "खुला वेब व्यापार",
  },
  ms: {
    download_for: "Muat turun untuk",
    open_web_terminal: "Buka MT5 WebTerminal ",
    web_terminal: "MetaTrader5 WebTerminal",
    meta_download_app: "MetaTrader5 Mobile",
    iux_download_app: "IUX Trade App",
    iux_markets_web_trade: "IUX Perdagangan web",
    open_web_trade: "Buka perdagangan web",
  },
  id: {
    download_for: "Unduh untuk",
    open_web_terminal: "Buka Terminal Web MT5",
    web_terminal: "MetaTrader5 WebTerminal",
    meta_download_app: "Aplikasi Handphone MetaTrader",
    iux_download_app: "Aplikasi IUX Maekets Trade",
    iux_markets_web_trade: "IUX Perdagangan web",
    open_web_trade: "Buka Perdagangan Web",
  },
  zh: {
    download_for: "下载",
    open_web_terminal: "Open MT5 WebTerminal ",
    web_terminal: "MetaTrader5 WebTerminal",
    meta_download_app: "MetaTrader5 移动的",
    iux_download_app: "IUX 贸易应用程序",
    iux_markets_web_trade: "IUX 网络交易",
    open_web_trade: "开放韦伯交易",
  },
  vi: {
    download_for: "Tải xuống cho",
    open_web_terminal: "Mở MT5 WebTerminal",
    web_terminal: "MetaTrader5 WebTerminal",
    meta_download_app: "MetaTrader5 di động",
    iux_download_app: "IIUX Giao dịch ứng dụng",
    iux_markets_web_trade: "IUX Giao dịch web",
    open_web_trade: "Mở giao dịch web",
  },
  ko: {
    download_for: "다운로드",
    open_web_terminal: "Open MT5 WebTerminal ",
    web_terminal: "MetaTrader5 WebTerminal",
    meta_download_app: "MetaTrader5 이동하는",
    iux_download_app: "IUX 거래 앱",
    iux_markets_web_trade: "IUX 웹 거래",
    open_web_trade: "개방형 웹 거래",
  },
  fil: {
    download_for: "I-download",
    open_web_terminal: "Bukas MT5 WebTerminal ",
    web_terminal: "MetaTrader5 WebTerminal",
    meta_download_app: "MetaTrader5 Mobile",
    iux_download_app: "IUX Trade App",
    iux_markets_web_trade: "IUX Comercio web",
    open_web_trade: "Buksan ang Web Trade",
  },
  ja: {
    download_for: "ダウンロードしてください",
    open_web_terminal: "MT5 Web末端を開きます",
    web_terminal: "Metatrader5 Web末端",
    meta_download_app: "Metatrader5モバイル",
    iux_download_app: "IUX Trade App",
    iux_markets_web_trade: "IUX ウェブトレード",
    open_web_trade: "Webトレードをオープン",
  },
  es: {
    download_for: "Descargar para",
    open_web_terminal: "Abrir MT5 WebTerminal",
    web_terminal: "MetaTrader5 WebTerminal",
    meta_download_app: "MetaTrader5 Móvil",
    iux_download_app: "IUX Trade App",
    iux_markets_web_trade: "IUX Web Trade",
    open_web_trade: "Abrir Web Trade",
  },
};
