import { Empty } from "antd";
import React from "react";
const locale = require("react-redux-i18n").I18n;
function Nodata() {
  return (
    <div
      className="d-flex align-items-center justify-content-center text-lightgray w-100"
      style={{ height: 350 }}
    >
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<span>{locale.t("nodata")}</span>}
      />
      {/* {locale.t("nodata")} */}
    </div>
  );
}

export default Nodata;
