import {UPDATE_LOCALE} from '../types';

export * from './auth'
export * from './user'
export * from './account';

export const setLocation = (locale) => {
  localStorage.setItem('locale', locale)
  return dispatch => {
    dispatch({
      type: UPDATE_LOCALE,
      locale: locale
    })
  }
}
