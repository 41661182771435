export default {
  th: {
    you_will_get: "คุณจะได้รับ",
    exchange: "แลกเปลี่ยน",
    cover_loyalty_header: `โปรแกรม LotBack`,
    cover_loyalty_content: `แลกเปลี่ยนและรับคะแนน IUX Markets Points ที่สามารถแลกเป็นรางวัลโบนัสเครดิตได้ ไม่มีแบบฟอร์มให้กรอกและไม่จำเป็นต้องสมัครเพื่อเข้าร่วมทันทีที่คุณเริ่มทำการซื้อขายคุณจะได้รับรายได้`,
    cover_loyalty_button: `เปิดบัญชีตอนนี้`,
    loyalty_main: `โปรแกรม LotBack`,
    loyalty_rewards: {
      header: `รางวัลมีตลอดไม่สิ้นสุด`,
      title: `แลกเปลี่ยนและรับคะแนน IUX Markets ที่สามารถแลกเป็นรางวัลโบนัสเครดิตได้ ไม่มีแบบฟอร์มให้กรอกและไม่จำเป็นต้องสมัครเพื่อเข้าร่วมทันทีที่คุณเริ่มทำการซื้อขายคุณจะได้รับรายได้`,
    },
    loyalty_status: {
      header: `สถานะ LotBack`,
      title: `ลูกค้าบัญชีจริงทั้งหมดเริ่มต้นที่ระดับผู้บริหารและได้รับการอัพเกรดโดยอัตโนมัติหลังจากช่วงเวลาของกิจกรรมการซื้อขาย ด้วยการอัปเกรดสถานะความภักดีแต่ละครั้งจำนวน LotBack (คะแนน IUX Markets Points) ที่คุณได้รับต่อการซื้อขายจะเพิ่มขึ้นตามสัดส่วน วิธีนี้ช่วยให้คุณได้รับ Lotback ในอัตราที่เร็วขึ้น ยิ่งมี Lotback มากเท่าไหร่คุณก็ยิ่งสามารถแลกรับรางวัลเครดิตโบนัสได้มากเท่านั้น`,
    },
    rewards: {
      header: `รางวัล`,
      title: `
            เมื่อคุณทำการค้าคุณจะได้รับ LotBack ซึ่งสามารถแลกได้ตลอดเวลาสำหรับรางวัลโบนัสเครดิตในส่วนสมาชิก ในส่วนสมาชิกคุณสามารถติดตามยอดคงเหลือของ LotBack ได้ตลอดเวลารวมถึงมูลค่าเทียบเท่าของ LotBack ของคุณเป็นโบนัสเครดิต\n
            โบนัสจะเพิ่มเงินทุนให้กับบัญชีซื้อขายของคุณ แต่มันมีจุดประสงค์เพื่อการค้าเท่านั้น ด้านล่างคุณสามารถดูสูตรเฉพาะที่ใช้ในการคำนวณมูลค่าเทียบเท่าของ LotBack ของคุณเป็นโบนัสเครดิต`,
    },
    new_loyalty: {
      lo_1: "ต้องการอีก",
    },
  },
  lo: {
    you_will_get: "ທ່ານຈະໄດ້",
    exchange: "ແລກ​ປ່ຽນ",
    cover_loyalty_header: `ໂປແກມ Lotback`,
    cover_loyalty_content: `ແລກປ່ຽນແລະຮັບຄະແນນ IUX Markets Points  ສາມາດແລກປ່ຽນແມ່ນລາງວັນ ບໍ່ມີແບບຟອມທີ່ຈະຕື່ມໃສ່ ແລະບໍ່ຈຳເປັນຕ້ອງສະໝັກເຂົ້າຮ່ວມ, ທັນທີທີ່ທ່ານເລີ່ມຊື້ຂາຍ, ທ່ານຈະໄດ້ຮັບເງິນ`,
    cover_loyalty_button: `ເປີດບັນຊີດຽວນີ້`,
    loyalty_main: `ໂປແກມ Lotback`,
    loyalty_rewards: {
      header: `ລາງວັນແມ່ນບໍ່ມີວັນສິ້ນສຸດ`,
      title: `ແລກປ່ຽນແລະຮັບເອົາຄະແນນຂອງ IUX Markets ທີ່ສາມາດແລກກັບລາງວັນເຄດິດເງິນໄດ້. ບໍ່ມີຮູບແບບໃດທີ່ຈະຕື່ມຂໍ້ມູນແລະບໍ່ ຈຳ ເປັນຕ້ອງສະ ໝັກ ເຂົ້າຮ່ວມ. ທັນທີທີ່ທ່ານເລີ່ມຊື້ຂາຍ, ທ່ານຈະມີລາຍໄດ້`,
    },
    loyalty_status: {
      header: `ສະຖານະ LotBack`,
      title: `ລູກຄ້າບັນຊີທີ່ແທ້ຈິງທັງ ໝົດ ເລີ່ມຕົ້ນໃນລະດັບຜູ້ບໍລິຫານແລະຖືກຍົກລະດັບໂດຍອັດຕະໂນມັດຫຼັງຈາກໄລຍະກິດຈະ ກຳ ການຄ້າ. ມີການຍົກລະດັບສະຖານະພາບຄວາມສັດຊື່ຕໍ່ແຕ່ລະຄັ້ງ, ຈຳ ນວນຂອງ LotBack (ຄະແນນ IUX Markets) ທີ່ທ່ານມີລາຍໄດ້ຕໍ່ການຄ້າເພີ່ມຂື້ນຕາມອັດຕາສ່ວນ. ສິ່ງນີ້ຊ່ວຍໃຫ້ທ່ານສາມາດໄດ້ຮັບ Lotback ໃນອັດຕາທີ່ໄວຂື້ນ`,
    },
    rewards: {
      header: `ລາງວັນ`,
      title: `
            ເມື່ອທ່ານຊື້ຂາຍ, ທ່ານຈະໄດ້ຮັບ LotBack ເຊິ່ງສາມາດໄດ້ຮັບການແລກປ່ຽນຄືນໄດ້ທຸກເວລາ ສຳ ລັບລາງວັນໂບນັດສິນເຊື່ອໃນເຂດສະມາຊິກ. ໃນສ່ວນຂອງສະມາຊິກ, ທ່ານສາມາດຕິດຕາມຍອດເງິນຂອງ LotBack ໄດ້ທຸກເວລາ, ລວມທັງມູນຄ່າທຽບເທົ່າຂອງ LotBack ຂອງທ່ານເປັນເງິນໂບນັດ. ໂບນັດຈະເພີ່ມເງິນເຂົ້າໃນບັນຊີການຄ້າຂອງທ່ານ. ແຕ່ມັນແມ່ນເພື່ອຈຸດປະສົງທາງການຄ້າເທົ່ານັ້ນ. ຂ້າງລຸ່ມນີ້ທ່ານສາມາດເຫັນສູດທີ່ເປັນເອກະລັກທີ່ໃຊ້ໃນການຄິດໄລ່ມູນຄ່າທຽບເທົ່າ LotBack ຂອງທ່ານເປັນເງິນໂບນັດ`,
    },
    new_loyalty: {
      lo_1: "ຕ້ອງການເພີ່ມເຕີມ",
    },
  },
  en: {
    you_will_get: "You Receive",
    exchange: "Exchange",
    cover_loyalty_header: `LotBack program`,
    cover_loyalty_content: `Exchange and get IUX Markets Points that can be redeemed for bonus credit rewards.`,
    cover_loyalty_button: `Register now`,
    loyalty_main: `LotBack program`,
    loyalty_rewards: {
      header: `Rewards are endless.`,
      title: `Trade and earn IUX Markets Points that can be redeemed for credit bonus rewards. There are no forms to fill and nowhere you need to sign up to join, as soon as you start trading, you start earning.`,
    },
    loyalty_status: {
      header: `LotBack status`,
      title: `"All real account clients start at the executive level and are automatically upgraded after the trading activity period with each loyalty status upgrade, the amount of LotBack (IUX Markets Points) you get per trade increases proportionally.  This allows you to get Lotbacks at a faster rate.  The more Lotbacks you have, the more bonus credit you can redeem.`,
    },
    rewards: {
      header: `Reward`,
      title: `When you trade, you will receive a LotBack which can be redeemed anytime for a credit bonus reward in the member. For member, you can keep track of your LotBack balance at any time, including the equivalent value of your LotBack as a credit bonus. \n
            The bonus will add funds to your trading account but it is for commercial purposes only. Below you can see a unique formula used to calculate your LotBack equivalent value as a credit bonus.
            `,
    },
    new_loyalty: {
      lo_1: "Need more",
    },
  },
  hi: {
    you_will_get: "आपको मिल जायेगा",
    exchange: "अदला-बदली",
    cover_loyalty_header: `LotBack program`,
    cover_loyalty_content: `Exchange and get IUX Markets Points that can be redeemed for bonus credit rewards.`,
    cover_loyalty_button: `Register now`,
    loyalty_main: `LotBack program`,
    loyalty_rewards: {
      header: `Rewards are endless.`,
      title: `Trade and earn IUX Markets Points that can be redeemed for credit bonus rewards. There are no forms to fill and nowhere you need to sign up to join, as soon as you start trading, you start earning.`,
    },
    loyalty_status: {
      header: `LotBack status`,
      title: `"All real account clients start at the executive level and are automatically upgraded after the trading activity period with each loyalty status upgrade, the amount of LotBack (IUX Markets Points) you get per trade increases proportionally.  This allows you to get Lotbacks at a faster rate.  The more Lotbacks you have, the more bonus credit you can redeem.`,
    },
    rewards: {
      header: `Reward`,
      title: `When you trade, you will receive a LotBack which can be redeemed anytime for a credit bonus reward in the member. For member, you can keep track of your LotBack balance at any time, including the equivalent value of your LotBack as a credit bonus. \n
            The bonus will add funds to your trading account but it is for commercial purposes only. Below you can see a unique formula used to calculate your LotBack equivalent value as a credit bonus.
            `,
    },
    new_loyalty: {
      lo_1: "Need more",
    },
  },
  ms: {
    you_will_get: "Anda akan dapat",
    exchange: "Pertukaran",
    cover_loyalty_header: `Program LotBack`,
    cover_loyalty_content: `Berdagang dan dapatkan Poin IUX Markets yang boleh ditebus untuk ganjaran kredit bonus. Tanpa borang untuk diisi dan tiada pendaftaran diperlukan untuk menyertai sebaik sahaja anda mula berdagang, anda akan memperolehi`,
    cover_loyalty_button: `BUKA AKAUN SEKARANG`,
    loyalty_main: `LotBack`,
    loyalty_rewards: {
      header: `Ganjarannya tidak berkesudahan`,
      title: `Berdagang dan dapatkan poin IUX Markets yang boleh ditebus untuk ganjaran kredit bonus. Tiada borang untuk diisi dan tiada pendaftaran diperlukan untuk menyertai sebaik sahaja anda mula berdagang, anda akan memperolehi`,
    },
    loyalty_status: {
      header: `Status LotBack`,
      title: `Semua pelanggan akaun sebenar bermula pada peringkat eksekutif dan dinaik taraf secara automatik selepas tempoh aktiviti dagangan. Dengan setiap peningkatan status kesetiaan, jumlah LotBack (Poin IUX Markets) yang anda perolehi setiap dagangan meningkat secara berkadar. Ini membolehkan anda memperoleh Lotback pada kadar yang lebih pantas. Lebih banyak Lotback yang anda miliki, lebih banyak kredit bonus yang boleh anda tebus.`,
    },
    rewards: {
      header: `Ganjaran`,
      title: `Apabila anda berdagang, anda akan menerima LotBack yang boleh ditebus pada bila-bila masa untuk ganjaran bonus kredit di Member Area. Di members Area anda boleh menjejaki baki LotBack anda pada bila-bila masa serta nilai setara LotBack anda sebagai bonus kredit\n
            Bonus akan menambah dana ke akaun dagangan anda tetapi ia adalah untuk tujuan komersial sahaja. Di bawah anda boleh menemui formula khusus yang digunakan untuk mengira nilai setara LotBack anda sebagai bonus kredit`,
    },
    new_loyalty: {
      lo_1: "Perlu lebih",
    },
  },
  id: {
    you_will_get: "Kamu akan mendapatkan",
    exchange: "Menukarkan",
    cover_loyalty_header: `LOYALITAS`,
    cover_loyalty_content: `Perdagangan dan mendapatkan Poin IUX Markets yang dapat ditebus untuk hadiah bonus kredit. Tidak ada bentuk untuk mengisi dan tempat Anda perlu mendaftar untuk bergabung, segera setelah Anda memulai trading, Anda mulai produktif`,
    cover_loyalty_button: `BUKA AKUN`,
    loyalty_main: `LOYALITAS`,
    loyalty_rewards: {
      header: `Dapatkan Tak Terbatas LotBack Rewards`,
      title: `Perdagangan dan mendapatkan Poin PASAR IUX yang dapat ditebus untuk hadiah bonus kredit. Tidak ada bentuk untuk mengisi dan tempat Anda perlu mendaftar untuk bergabung, segera setelah Anda memulai trading, Anda mulai produktif`,
    },
    loyalty_status: {
      header: `loyalitas Status`,
      title: `Semua klien Akun Riil mulai dari tingkat eksekutif dan upgrade secara otomatis setelah periode aktivitas perdagangan. Dengan setiap upgrade status loyalitas, jumlah LotBack (PASAR iUX Points) yang anda peroleh per lot meningkat diperdagangkan secara proporsional. Hal ini memungkinkan Anda untuk mendapatkan Lotback pada tingkat yang lebih cepat. Semakin banyak Lotback yang Anda miliki, semakin Anda dapat menebus untuk hadiah bonus kredit.`,
    },
    rewards: {
      header: `imbalan`,
      title: `Seperti yang Anda perdagangan, Anda akan mendapatkan LotBack yang dapat ditebus setiap saat untuk hadiah bonus kredit di Area Anggota. Dalam Area Anggota Anda juga dapat memantau saldo Anda dari LotBack setiap saat, termasuk nilai setara dengan LotBack Anda sebagai bonus kredit\n
            Bonus menambahkan dana ke account trading Anda tetapi dimaksudkan untuk tujuan perdagangan saja. Di bawah ini Anda dapat melihat formula khusus yang digunakan untuk menghitung nilai setara dengan LotBack Anda untuk bonus kredit`,
    },
    new_loyalty: {
      lo_1: "Need more",
    },
  },
  zh: {
    you_will_get: "You will get",
    exchange: "Exchange",
    cover_loyalty_header: `忠诚`,
    cover_loyalty_content: `贸易和赚取可兑换积分奖励奖励IUX Markets点位。没有填写表格，也无处你需要，只要你开始交易，你就开始盈利报名参加，`,
    cover_loyalty_button: `开户口`,
    loyalty_main: `忠诚`,
    loyalty_rewards: {
      header: `赚取无限忠诚度奖励`,
      title: `贸易和赚取可兑换积分奖励奖励IUX Markets点位。没有填写表格，也无处你需要，只要你开始交易，你就开始盈利报名参加，`,
    },
    loyalty_status: {
      header: `忠诚度以下状态`,
      title: `所有真实帐户的客户开始在行政级别，并且交易活跃的时期后自动升级。随着每忠诚地位的提升，LotBack（IUX Markets点位）的量，你每手交易按比例增加收入。这可以让你以更快的速度赚取LotBack。更LotBack你有，你越能兑换积分奖励奖励。`,
    },
    rewards: {
      header: `奖励`,
      title: `当你交易，你将获得LotBack可以随时在会员区积分奖励奖励兑换。在会员区，您还可以监控您的LotBack余额在任何时候，包括你的LotBack的等效值作为积分奖励\n
            奖励资金增加您的交易账户，但它仅用于交易目的。下面你可以看到用来计算你的LotBack的等效值积分奖励的具体公式`,
    },
    new_loyalty: {
      lo_1: "Need more",
    },
  },
  vi: {
    you_will_get: "Bạn sẽ nhận được",
    exchange: "Trao đổi",
    cover_loyalty_header: `Chương trình LotBack`,
    cover_loyalty_content: `Trao đổi và kiếm điểm IUX Markets Points mà có thể được đổi lấy phần thưởng tín dụng Không có biểu mẫu để điền và không cần đăng ký để tham gia ngay khi bắt đầu giao dịch, bạn sẽ kiếm được tiền.`,
    cover_loyalty_button: `Mở tài khoản bây giở`,
    loyalty_main: `Chương trình LotBack`,
    loyalty_rewards: {
      header: `Giải thưởng là vô tận.`,
      title: `Trao đổi và kiếm điểm IUX Markets mà có thể được đổi lấy phần thưởng tín dụng Không có biểu mẫu để điền và không cần đăng ký để tham gia ngay khi bắt đầu giao dịch, bạn sẽ kiếm được tiền.`,
    },
    loyalty_status: {
      header: `Trạng thái LotBack`,
      title: `hách hàng sử dụng tài khoản thực đều bắt đầu ở cấp độ điều hành và được tự động nâng cấp sau khoảng thời gian hoạt động giao dịch. Với mỗi lần nâng cấp trạng thái khách hàng thân thiết, số lượng LotBack (Điểm IUX Markets) bạn kiếm được trên mỗi giao dịch sẽ tăng lên tương ứng. Điều này cho phép bạn kiếm được Lotback với tốc độ nhanh hơn. Bạn càng có nhiều Lotback, bạn càng có thể đổi được nhiều tín dụng thưởng.`,
    },
    rewards: {
      header: `Giải thưởng`,
      title: ` Khi bạn giao dịch, bạn sẽ nhận được LotBack có thể đổi được bất kỳ lúc nào để lấy phần thưởng tín dụng trong Khu vực thành viên. Trong Khu vực Thành viên, bạn có thể theo dõi số dư LotBack của mình bất kỳ lúc nào cũng như giá trị tương đương của LotBack dưới dạng tiền thưởng tín dụng.\n
            Tiền thưởng sẽ thêm tiền vào tài khoản giao dịch của bạn. Nhưng  mục đích chỉ giao . Dưới đây, bạn có thể tìm thấy công thức duy nhất được sử dụng để tính giá trị tương đương của LotBack của bạn dưới dạng tiền thưởng tín dụng`,
    },
    new_loyalty: {
      lo_1: "Cần thêm",
    },
  },
  ko: {
    you_will_get: "You will get",
    exchange: "Exchange",
    cover_loyalty_header: `충의`,
    cover_loyalty_content: `무역 및 신용 보너스 보상으로 교환 할 수있다 IUX 시장 포인트를 적립하실 수 있습니다. 이 기입 할 양식은 없습니다 아무데도 당신은 즉시 거래를 시작, 당신은 수입을 시작, 가입에 가입 할 필요가 없습니다`,
    cover_loyalty_button: `계좌 개설`,
    loyalty_main: `충의`,
    loyalty_rewards: {
      header: `무한 충성도 보상을 창출`,
      title: `무역 및 신용 보너스 보상으로 교환 할 수있다 IUX 시장 포인트를 적립하실 수 있습니다. 이 기입 할 양식은 없습니다 아무데도 당신은 즉시 거래를 시작, 당신은 수입을 시작, 가입에 가입 할 필요가 없습니다`,
    },
    loyalty_status: {
      header: `로열티 상태`,
      title: `모든 실제 계정 클라이언트는 집행 수준에서 시작하고 무역 활동 기간 후 자동으로 업그레이드됩니다. 각각의 충성도 상태 업그레이드를 통해, LotBack (IUX 시장 점)의 양이 당신은 비례 적으로 많은 거래 증가에 따라 적립됩니다. 이것은 당신이 빠른 속도로 LotBack를 적립 할 수 있습니다. 당신이 더 많은 LotBack는 더 신용 보너스 보상에 대한 구속 할 수 있습니다.`,
    },
    rewards: {
      header: `보상`,
      title: `당신이 무역으로, 당신은 회원 영역에서 신용 보너스 보상을 위해 언제든지 교환 할 수있는 LotBack가 적립됩니다. 구성원 영역에서 당신은 또한 신용 보너스로 당신 LotBack에 해당하는 값을 포함하여, 언제든지 LotBack의 균형을 모니터링 할 수 있습니다\n
            보너스는 거래 계정에 자금을 추가 있지만 거래 목적으로 만 사용할 수 있습니다. 당신은 신용 보너스로 LotBack에 해당하는 값을 계산하는 데 사용되는 특정 공식을 참조하실 수 있습니다`,
    },
    new_loyalty: {
      lo_1: "Need more",
    },
  },
  fil: {
    you_will_get: "Makukuha mo",
    exchange: "Palitan",
    cover_loyalty_header: `LOTBACK`,
    cover_loyalty_content: `Trade at kumita IUX Markets Mga puntos na maaaring matubos para sa premyo credit bonus. Walang mga form upang punan at wala saan kailangan mo upang mag-sign up upang sumali, sa lalong madaling simulan mo ang kalakalan, simulan mo kita`,
    cover_loyalty_button: `OPEN NG ACCOUNT`,
    loyalty_main: `LOTBACK`,
    loyalty_rewards: {
      header: `Kumita ng Walang-hanggan Katapatan Gantimpala`,
      title: `Trade at kumita IUX Markets punto na maaaring matubos para sa premyo credit bonus. Walang mga form upang punan at wala saan kailangan mo upang mag-sign up upang sumali, sa lalong madaling simulan mo ang kalakalan, simulan mo kita`,
    },
    loyalty_status: {
      header: `katapatan Mga katayuang`,
      title: `Ang lahat ng mga kliyente Real Account magsimula sa Executive antas at ay na-upgrade na awtomatiko pagkatapos ng panahon ng kalakalan aktibidad. Sa bawat status upgrade katapatan, ang halaga ng LotBack (IUX Markets Points) kikita ka ng per lot traded pagtaas pareho batay sa. Ito ay nagpapahintulot sa iyo na kumita ng Lotback sa isang mabilis na tulin. Ang mas Lotback mayroon ka, ang mas maraming nagagawa mong kunin ang premyo para credit bonus.`,
    },
    rewards: {
      header: `Premyo`,
      title: `Tulad ng kalakalan sa iyo, ikaw ay kumita ng LotBack na maaaring matubos sa anumang oras para gantimpala credit bonus sa mga Miyembro Area. Sa Lugar ng Kasapi maaari mo ring subaybayan ang iyong balanse ng LotBack sa anumang oras, kabilang ang katumbas na halaga ng iyong LotBack ng credit bonus\n
            Ang bonus ay nagdadagdag ng mga pondo sa iyong account sa kalakalan ngunit ito ay inilaan para sa layuning pangkalakal lamang. Sa ibaba maaari mong makita ang tukoy na mga formula na ginamit upang makalkula ang katumbas na halaga ng iyong LotBack sa credit bonus`,
    },
    new_loyalty: {
      lo_1: "Need more",
    },
  },
  ja: {
    you_will_get: "あなたは得るでしょう",
    exchange: "交換",
    cover_loyalty_header: "ロットバックプログラム",
    cover_loyalty_content:
      "ボーナスクレジットの報酬と引き換えることができるIUX市場のポイントを交換して取得します。",
    cover_loyalty_button: "今すぐ登録",
    loyalty_main: "ロットバックプログラム",
    loyalty_rewards: {
      header: "報酬は無限です。",
      title:
        "クレジットボーナスの報酬と引き換えることができるIUX市場のポイントを取引して獲得します。 埋めるフォームはありませんし、参加するためにサインアップする必要はありません。取引を開始するとすぐに獲得を開始します。",
    },
    loyalty_status: {
      header: "ロットバックのステータス",
      title:
        "すべての実際のアカウントクライアントは、エグゼクティブレベルで開始され、各ロイヤルティステータスのアップグレードで取引活動期間後に自動的にアップグレードされます。 これにより、より速いレートでロットバックを取得できます。 ロットバックが多いほど、より多くのボーナスクレジットを償還できます。",
    },
    rewards: {
      header: "褒美",
      title:
        "あなたが取引するとき、あなたはメンバーのクレジットボーナス報酬のためにいつでも引き換えることができるロットバックを受け取ります。 メンバーの場合、クレジットボーナスとしてロットバックの同等の価値を含め、いつでもロットバックのバランスを追跡できます。  nボーナスはあなたの取引口座に資金を追加しますが、それは商業目的のみを目的としています。 以下に、クレジットボーナスとしてロットバックの等価値を計算するために使用される一意の式を見ることができます。",
    },
    new_loyalty: {
      lo_1: "もっといります",
    },
  },
  es: {
    you_will_get: "Recibirás",
    exchange: "Intercambio",
    cover_loyalty_header: "Programa LotBack",
    cover_loyalty_content:
      "Intercambia y obtén puntos de IUX Markets que se pueden canjear por recompensas de crédito de bonificación.",
    cover_loyalty_button: "Regístrate ahora",
    loyalty_main: "Programa LotBack",
    loyalty_rewards: {
      header: "Las recompensas son interminables.",
      title:
        "Opera y gana puntos de IUX Markets que se pueden canjear por recompensas de crédito de bonificación. No hay formularios que llenar ni lugares donde inscribirse, tan pronto como comiences a operar, comienzas a ganar.",
    },
    loyalty_status: {
      header: "Estado LotBack",
      title:
        "Todos los clientes de cuentas reales comienzan en el nivel ejecutivo y se actualizan automáticamente después del período de actividad comercial. Con cada mejora de estado de lealtad, la cantidad de LotBack (puntos de IUX Markets) que obtienes por operación aumenta proporcionalmente. Esto te permite obtener Lotbacks a un ritmo más rápido. Cuantos más Lotbacks tengas, más crédito de bonificación podrás canjear.",
    },
    rewards: {
      header: "Recompensa",
      title:
        "Cuando operas, recibirás un LotBack que se puede canjear en cualquier momento por una recompensa de crédito de bonificación en el miembro. Como miembro, puedes seguir el saldo de tu LotBack en cualquier momento, incluido el valor equivalente de tu LotBack como crédito de bonificación.\nEl bono agregará fondos a tu cuenta de operaciones, pero es solo para fines comerciales. A continuación, puedes ver una fórmula única utilizada para calcular el valor equivalente de tu LotBack como crédito de bonificación.",
    },
    new_loyalty: {
      lo_1: "Necesitas más",
    },
  },
};
