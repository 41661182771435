import React from "react";
import { Row, Col } from "antd";
import styled from "styled-components";

const Background = styled.div`
  background-size: cover !important;
`;
const textStyle = {
  color: "white",
};

const TextWithCover = (props) => {
  return (
    <Background
      className="cover-responsive"
      style={{
        backgroundImage: `url("${props.imagePath}")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        className="h-100"
        style={{
          background:
            "linear-gradient(115deg, rgba(0,0,0,1) 55%, rgba(0,0,0,0.5) 100%)",
        }}
      >
        <Row className="d-flex container h-100 pt-4 pt-lg-0 ">
          <Col className="" span={24} style={{ alignSelf: "center" }}>
            <h2 className=" text-title-cover" style={textStyle}>
              {props.title}
            </h2>
            <h5
              className="text-leftresponsive-content mt-4 fs-20"
              style={textStyle}
            >
              {props.content}
            </h5>
          </Col>
        </Row>
      </div>
    </Background>
  );
};

const mapStateToProps = ({ i18n }) => ({
  locale: i18n.locale,
});

export default TextWithCover;
