import React, { useState, Fragment, useEffect } from "react";
import {
  Divider,
  Button,
  Form,
  Input,
  Row,
  Spin,
  Result,
  Select,
  Space,
  Radio,
  Collapse,
  List,
  Col,
} from "antd";
import { post } from "../../../../helper/request";
import { connect, useSelector } from "react-redux";
// import DepositFirstStep from "./deposit/DepositFirstStep";
// import DepositSecondStep from "./deposit/DepositSecondStep";
// import DepositThirdStep from "./deposit/DepositThirdStep";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { CheckCerrency } from "../../../../components/checkcurrency";
import { DownOutlined, InfoCircleOutlined } from "@ant-design/icons";
import BoxScanQrCode from "../../../../components/boxpayment/BoxScanQrCode";
import RcQueueAnim from "rc-queue-anim";
import QueueAnim from "rc-queue-anim";
import FadeInOut from "../../../../components/fadein/FadeInOut";
const locale = require("react-redux-i18n").I18n;
const { Option } = Select;

const ResultAmountStyled = styled.div`
  border: unser;
  border-radius: 12px;
  display: inline-block;
  width: 100%;
  background: #f2f2f2;
  width: 100%;

  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const DepositFirstStep = (props) => {
  // console.log(props, "props");
  // const {
  //   paymentId,
  //   paymentKey,
  //   account: { account_number },
  //   payment_config: { minimum_deposit, maximum_deposit },
  //   payment_name,

  // } = props;
  const profileCountry = useSelector((state) => state.user.profileCountry);
  const [spinnig, setSpinnig] = useState(true);
  const [bankAccount, setBankAccount] = useState("");
  const [showpaymentall, setShowpaymentall] = useState(false);
  const payment = useSelector(({ user }) => user.bank);
  // const [amount, setAmount] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [account, setAccount] = useState(props.account);
  const [paymentId, setPaymentId] = useState(2);
  const [paymentKey, setPaymentKey] = useState("thai_qr_code");
  const [payment_config, setPayment_config] = useState({
    minimum_deposit: 100,
    maximum_deposit: 1000000,
    minimum_withdraw: 500,
    maximum_withdraw: 1000000,
  });
  let { minimum_deposit, maximum_deposit } = payment_config;

  //       modalSuccess: false,
  //       spinning: true,
  //       step: 0,
  //       bank: undefined,
  //       bankAccountNumber: "",
  //       amount: 0,
  //       showSuccess: false,
  //       account: this.props.account || undefined,
  //       payment_id: 1,
  //       payment_key: "",
  //       payment_config: {},
  //       payment_name: "",

  // const [accountSelected, setAccountSelected] = useState(null);

  const [countryId, setCountryId] = useState(null);
  const [rate, setRate] = useState(0);
  const [step, setStep] = useState(0);
  const [defaultpayment, setDefaultpayment] = useState(2);
  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    props.onChangePayment(e.target.value);
  };
  useEffect(() => {
    setLoading(true);
    setCountryId(props?.user?.profile?.country_id);
  }, []);
  const addDefaultSrc = (ev) => {
    ev.target.src = "/assets/images/icons/globe-black.svg";
  };

  return (
    <Fragment>
      <span className="fs-20 fw-700 ">
        1.{locale.t("deposit_step.first_step.description")}
      </span>
      <div style={{ marginTop: 20 }} />

      <Form
        name="deposit_amount"
        onFinish={props.onNextStep}
        className="user mx-auto"
        layout={"vertical"}
      >
        <div>
          {/* <Form.Item
            style={{ borderTop: "0", fontSize: 12 }}
            name="account"
            label={locale.t("dashboardWallet.account")}
            disabled={
              props?.user?.accounts?.filter((e) => e.type === "real").length ===
              0
            }
          >
            <Select
              size="large"
              placeholder={locale.t("deposit_step.first_step.description")}
              // value={101335}
              defaultValue={
                props.accountSelected ? props.accountSelected.id : undefined
              }
              onChange={props.onAccountSelected}
            >
              {props?.user?.accounts?.filter((e) => e.type === "real").length >
                0 &&
                props.user?.accounts
                  ?.filter((e) => e.type === "real")
                  ?.map((e, i) => (
                    <Option value={e.id} key={e.id}>
                      <div className="d-flex justify-content-between">
                        <div>
                          {e.platform}&nbsp;
                          {e.account_number}
                          &nbsp; <b>({e?.account_group?.display})</b>
                        </div>
                        <div>
                          {e.account_type === "Cent" ? "¢" : "$"}
                          {e.current_balance
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </div>
                      </div>
                    </Option>
                  ))}
            </Select>
          </Form.Item> */}{" "}
          <div className="text-lightgray-iux  mb-2">
            {locale.t("dashboardWallet.account")}:
          </div>
          <Radio.Group
            onChange={(e) => props.onAccountSelected(e.target.value)}
            value={props?.accountSelected?.id}
            className="w-100"
          >
            <Row gutter={[16, 16]}>
              {props?.user?.accounts?.filter((e) => e.type === "real").length >
                0 &&
                props.user?.accounts
                  ?.filter((e) => e.type === "real")
                  ?.map((e, i) => (
                    <Col xs={24} md={12} key={i}>
                      <Radio
                        value={e.id}
                        key={e.id}
                        className={`radio-custom-payment ${
                          props?.accountSelected?.id === e.id ? "checked" : ""
                        }`}
                      >
                        <div className="d-flex flex-column text-lightgray-iux ">
                          <div className={"text-des-withdraw"}>
                            {e.platform}&nbsp;
                            {e.account_number}
                            &nbsp; ({e?.account_group?.display})
                          </div>
                          <div>
                            {e.account_type === "Cent" ? "¢" : "$"}
                            {e.current_balance
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                          </div>
                        </div>
                      </Radio>
                    </Col>
                  ))}
            </Row>
          </Radio.Group>
          {/* <Form.Item
            style={{ borderTop: "0", fontSize: 12 }}
            name="account"
            label={locale.t("dashboardWallet.account")}
            disabled={
              props?.user?.accounts?.filter((e) => e.type === "real").length ===
              0
            }
          ></Form.Item> */}
        </div>

        {props?.accountSelected && (
          <>
            {" "}
            <FadeInOut show={props.accountSelected} duration={500}>
              <div className="mt-3" />
              <div className="w-12 text-gray mb-4">
                {locale.t("deposit_step.first_step.deposit_with")}
              </div>

              {payment?.length > 0 ? (
                <>
                  <span className="bage-payment recommended ">
                    {locale.t("dashboardWallet.recommended")}
                  </span>
                  <Radio.Group
                    onChange={onChange}
                    value={props.paymentKey}
                    className="w-100 mt-3 "
                  >
                    <Row gutter={[16, 16]}>
                      {payment?.filter((e) => e.recommend === 1)?.length > 0 ? (
                        <>
                          {payment
                            ?.filter((e) => e.recommend === 1)
                            ?.map((item, index) => (
                              <Col xs={24} md={24} xl={12}>
                                <Radio
                                  key={index}
                                  value={item.payment_key}
                                  className={`radio-custom-payment ${
                                    props?.paymentSelected?.id === item.id
                                      ? "checked"
                                      : ""
                                  }`}
                                >
                                  <div className="d-flex align-items-center text-lightgray-iux  ">
                                    <img
                                      src={`${
                                        item.name === "DuitNow QR"
                                          ? `${process.env.PUBLIC_URL}/assets/images/bank/${item.logo}`
                                          : `${process.env.REACT_APP_POLICY}${item.logo} `
                                      }`}
                                      // src={`${process.env.PUBLIC_URL}/assets/images/bank/${item.logo}`}
                                      alt="...."
                                      width={40}
                                      height={40}
                                      style={{ borderRadius: 4 }}
                                      // onError={addDefaultSrc}
                                    />
                                    <div className="ml-2">
                                      <div className="text-left w-100 text-des-withdraw">
                                        {item?.name}
                                      </div>
                                      <div
                                        className={"text-des-withdraw"}
                                      >{`(${locale.t(
                                        "deposit_step.second_step.minimun_deposit"
                                      )} ${item?.payment_config[0]?.minimum_deposit
                                        ?.toFixed(2)
                                        ?.replace(
                                          /\d(?=(\d{3})+\.)/g,
                                          "$&,"
                                        )} ${profileCountry?.symbol})`}</div>
                                    </div>
                                  </div>
                                </Radio>
                              </Col>
                            ))}
                        </>
                      ) : (
                        <>
                          {payment?.slice(0, 1)?.map((item, index) => (
                            <Col xs={24} md={24} xl={12}>
                              <Radio
                                key={index}
                                value={item.payment_key}
                                className={`radio-custom-payment ${
                                  props?.paymentSelected?.id === item.id
                                    ? "checked"
                                    : ""
                                }`}
                              >
                                <div className="d-flex align-items-center text-lightgray-iux  ">
                                  <img
                                    src={`${
                                      item.name === "DuitNow QR"
                                        ? `${process.env.PUBLIC_URL}/assets/images/bank/${item.logo}`
                                        : `${process.env.REACT_APP_POLICY}${item.logo} `
                                    }`}
                                    // src={`${process.env.PUBLIC_URL}/assets/images/bank/${item.logo}`}
                                    alt="...."
                                    width={40}
                                    height={40}
                                    style={{ borderRadius: 4 }}
                                    onError={addDefaultSrc}
                                  />
                                  <div className="ml-2">
                                    <div className="text-left w-100">
                                      {item?.name}
                                    </div>
                                    <div>{`(${locale.t(
                                      "deposit_step.second_step.minimun_deposit"
                                    )} ${item?.payment_config[0]?.minimum_deposit
                                      ?.toFixed(2)
                                      ?.replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${
                                      profileCountry?.symbol
                                    })`}</div>
                                  </div>
                                </div>
                              </Radio>
                            </Col>
                          ))}
                        </>
                      )}
                    </Row>

                    {payment?.filter((e) => e.recommend === 0)?.length > 0 &&
                      payment?.slice(1).length > 0 && (
                        <div
                          className={`bage-payment other cursor-pointer fs-12 text-main-black my-3 ${
                            showpaymentall ? "active" : ""
                          }`}
                          onClick={() => setShowpaymentall(!showpaymentall)}
                        >
                          {locale.t("deposit_step.first_step.show_all_method")}
                          &nbsp;
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M2.20407 3.45407C2.04135 3.61679 2.04135 3.88061 2.20407 4.04333L4.70407 6.54332C4.86678 6.70603 5.13061 6.70603 5.29332 6.54332L7.79332 4.04333C7.95603 3.88061 7.95603 3.61679 7.79332 3.45407C7.63061 3.29135 7.36678 3.29135 7.20407 3.45407L4.9987 5.65945L2.79333 3.45407C2.63061 3.29135 2.36679 3.29135 2.20407 3.45407Z"
                              fill="#1E1E1E"
                            />
                          </svg>
                        </div>
                      )}

                    {showpaymentall && payment && (
                      <>
                        <FadeInOut show={showpaymentall} duration={500}>
                          <Row gutter={[16, 16]}>
                            {payment?.filter((e) => e.recommend === 1)?.length >
                            0 ? (
                              <>
                                {payment
                                  ?.filter((e) => e.recommend === 0)
                                  .map((item, index) => (
                                    <Col
                                      xs={24}
                                      md={24}
                                      xl={12}
                                      key={index + 1}
                                    >
                                      <Radio
                                        key={index}
                                        value={item.payment_key}
                                        className={`radio-custom-payment ${
                                          props?.paymentSelected?.id === item.id
                                            ? "checked"
                                            : ""
                                        }`}
                                      >
                                        <div className="d-flex align-items-center text-lightgray-iux ">
                                          <img
                                            src={`${process.env.REACT_APP_POLICY}${item.logo}`}
                                            // src={`${process.env.PUBLIC_URL}/assets/images/bank/${item.logo}`}
                                            alt="...."
                                            width={40}
                                            height={40}
                                            style={{ borderRadius: 4 }}
                                            onError={addDefaultSrc}
                                          />
                                          <div className="ml-2">
                                            <div className="text-left w-100 text-des-withdraw">
                                              {item?.name}
                                            </div>
                                            <div
                                              className={"text-des-withdraw"}
                                            >{`(${locale.t(
                                              "deposit_step.second_step.minimun_deposit"
                                            )} ${item?.payment_config[0]?.minimum_deposit
                                              ?.toFixed(2)
                                              ?.replace(
                                                /\d(?=(\d{3})+\.)/g,
                                                "$&,"
                                              )} ${
                                              profileCountry?.symbol
                                            })`}</div>
                                          </div>
                                        </div>
                                      </Radio>
                                    </Col>
                                  ))}
                              </>
                            ) : (
                              <>
                                {payment.slice(1).map((item, index) => (
                                  <Col xs={24} md={24} xl={12}>
                                    <Radio
                                      key={index}
                                      value={item.payment_key}
                                      className={`radio-custom-payment ${
                                        props?.paymentSelected?.id === item.id
                                          ? "checked"
                                          : ""
                                      }`}
                                    >
                                      <div className="d-flex align-items-center text-lightgray-iux ">
                                        <img
                                          src={`${process.env.REACT_APP_POLICY}${item.logo}`}
                                          // src={`${process.env.PUBLIC_URL}/assets/images/bank/${item.logo}`}
                                          alt="...."
                                          width={40}
                                          height={40}
                                          style={{ borderRadius: 4 }}
                                          onError={addDefaultSrc}
                                        />
                                        <div className="ml-2">
                                          <div className="text-left w-100">
                                            {item?.name}
                                          </div>
                                          <div>{`(${locale.t(
                                            "deposit_step.second_step.minimun_deposit"
                                          )} ${item?.payment_config[0]?.minimum_deposit
                                            ?.toFixed(2)
                                            ?.replace(
                                              /\d(?=(\d{3})+\.)/g,
                                              "$&,"
                                            )} ${
                                            profileCountry?.symbol
                                          })`}</div>
                                        </div>
                                      </div>
                                    </Radio>
                                  </Col>
                                ))}
                              </>
                            )}
                          </Row>
                        </FadeInOut>
                      </>
                    )}
                  </Radio.Group>{" "}
                </>
              ) : (
                <div className="text-center py-5 fs-14 text-lightgray">
                  <InfoCircleOutlined className="text-lightgray fs-16" />
                  {locale.t("withdraw_step.no_payment")}
                </div>
              )}
            </FadeInOut>
          </>
        )}
        <div
          className="mt-3"
          style={{
            display: "flex",
            width: "100%",

            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <Button
            className="btn-primary-new-iux button-default-w "
            onClick={props.onNextStep}
            disabled={!props.accountSelected || props.paymentKey === null}
          >
            {locale.t("next")}
          </Button>
        </div>
      </Form>
    </Fragment>
  );
};

const mapStateToProps = ({ user, i18n }) => {
  const { accounts, profile, account } = user;
  return { accounts, profile, account, locale: i18n.locale, user };
};

export default connect(mapStateToProps, null)(DepositFirstStep);
