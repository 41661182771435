import React, { Component } from "react";
import { Form, Input } from "antd";
import { Button } from "antd/lib/radio";

class ChangePasswordMT4Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values.password !== values.confirm) {
          this.setState({ errors: ["รหัสผ่านไม่ตรงกัน"] });
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div
        className="w-100 h-100"
        style={{ background: "url('/assets/images/covers/cover-login.png')" }}
      >
        <div className="d-flex justify-content-center align-items-center text-center">
          <div className="w-50 rectangle-card">
            <Form layout={"vertical"}>
              <Form.Item label="รหัสผ่านใหม่" hasFeedback>
                {getFieldDecorator("password", {
                  rules: [
                    {
                      required: true,
                      message: "กรุณาระบุรหัสผ่าน",
                    },
                    {
                      validator: this.validateToNextPassword,
                    },
                  ],
                })(<Input.Password />)}
              </Form.Item>
              <Form.Item label="ยืนยัน รหัสผ่านใหม่" hasFeedback>
                {getFieldDecorator("confirm", {
                  rules: [
                    {
                      required: true,
                      message: "กรุณายืนยันรหัสผ่าน",
                    },
                    {
                      validator: this.compareToFirstPassword,
                    },
                  ],
                })(<Input.Password onBlur={this.handleConfirmBlur} />)}
              </Form.Item>
              {this.state.errors.map((error) => {
                return (
                  <p style={{ color: "red", textAlign: "center", padding: 16 }}>
                    {error}
                  </p>
                );
              })}
              <Button onClick={this.handleSubmit} htmlType="submit">
                เปลี่ยนรหัสผ่าน
              </Button>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

// const ChangePasswordMT4Page = Form.create({ name: 'change_password_mt4' })(ChangePasswordMT4Form);

export default ChangePasswordMT4Page;
