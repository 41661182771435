export default {
  th: {
    faq_withdraw: {
      faq_withdraw_title_1: "สามารถถอนเงินได้หรือไม่หากมีคำสั่งซื้อขายเปิดอยู่",
      faq_withdraw_des_1:
        "สามารถทําการถอนได้ 90% ของจํานวน Free Margin คงเหลือ โดยไม่รวมเครดิต และจะถอนได้ไม่เกินบาลานซ์คงเหลือ",
      faq_withdraw_title_2: "ฉันสามารถถอนเงินในช่วงวันหยุดได้หรือไม่",
      faq_withdraw_des_2: "คุณสามารถถอนเงินในช่วงวันหยุดไต้ตามปกติ",
      faq_withdraw_title_3: "การถอนเงินใช้เวลาเท่าใด",
      faq_withdraw_des_3: "การถอนเงินเฉลี่ย 5-15 นาทีแต่ไม่เกิน 24 ชม.",
      faq_withdraw_title_4: "มีค่าธรรมเนียมในการถอนเงินหรือไม่",
      faq_withdraw_des_4: "การถอนเงินคุณจะไม่เสียค่าธรรมเนียม",
    },
    withdraw_info: {
      withdraw_warning_text:
        "โปรดทราบว่าหากมีคำสั่งซื้อขายเปิดอยู่ คุณสามารถถอนเงินได้ถึง 90% ของจำนวน Free Margin ที่เหลือ โดยไม่รวมเครดิต",

      fee: "ค่าธรรมเนียม",
      rate: "อัตรา",
      warning_wallet:
        "ตรวจสอบรายละเอียดการถอนของคุณอีกครั้ง หากพบข้อผิดพลาด คลิกย้อนกลับและแก้ไขรายละเอียดของคุณ",
      total_amount: "ยอดรวม",
      requested_date: "วันที่ที่ขอ",
      save: "บันทึก",
      bank_detail: "รายละเอียดธนาคาร",
      fund_detail: "รายละเอียดกองทุน",
      local_amount: "จำนวนเงินท้องถิ่น",
      request_success: "คำขอสำเร็จ",
      withdraw_processing:
        "การถอนเงินของคุณกำลังดำเนินการ โปรดรอประมาณ 2-10 นาที",
      result: "ยืนยันการถอนเงิน",
      warning_head:
        "ในการถอนเงิน หากมี Position ซื้อขายอยู่ท่านสามารถถอนเงินได้ 70% Equity โดยนับไม่รวมเครดิตหรือโบนัส",
      warning_bottom1:
        "โปรดทราบว่าการถอนเงินของท่านจะขึ้นอยู่กับอัตราแลกเปลี่ยนของตัวกลางที่ท่านใช้ในการถอนเงิน โปรดตรวจสอบให้แน่ใจว่าอัตราแลกเปลี่ยนใดเหมาะสมที่สุดในการถอนแต่ละครั้ง ",
      warning_bottom2:
        "และโปรดทราบว่าการฝากและการถอนของท่านจะเป็นต้องเป็นชื่อที่ตรงกันกับข้อมูลที่ท่านทำการยืนยันเข้ามาเท่านั้น เพื่อหลีกเลี่ยงปัญหาการถอนเงินที่อาจจะเกิดขึ้นหากข้อมูลไม่ตรงกันกับการลงทะเบียน ",
      account: {
        name: "ชื่อบัญชี",
        number: "เลขที่บัญชี",
        bank: "ธนาคาร",
        amount: "จำนวนเงิน (USD)",
        Code: "กรอกโค้ดที่ได้รับทาง",
      },
      success: {
        title: "ถอนเงินสำเร็จ",
        description:
          "เราได้รับคำร้องการถอนเงินของท่าน และจะถูกดำเนินการภายใน 24 ชั่วโมง โปรดทราบว่า จำนวนเงินที่ร้องขอได้ถูกหักออกจากบัญชีของท่านแล้ว",
        amount: "ถอนเงิน",
      },
      note: {
        title:
          "หมายเหตุ: โปรดทราบขณะที่ท่านติดออร์เดอร์การซื้อขาย การถอนเงินจะถอนได้ 70% ของ margin โดยมิได้นำเครดิตมาร่วมด้วย",
        description:
          "แนะนำ: หากต้องการถอนเงินควรจะปิดออเดอร์ที่ค้างอยู่ทั้งหมดก่อน",
      },
      amount: "จำนวนเงินที่ต้องการถอน",
      currency: "สกุลเงิน",
      wallet_address: "ที่อยู่กระเป๋าสตางค์",
      total_amount: "จำนวนเงินทั้งหมด",
      bockchain_fee: "ค่าธรรมเนียมบล็อกเชน",
      approximate: "ประมาณ",
      crypto_warning:
        "คุณมีความรับผิดชอบต่อความถูกต้องของหมายเลขบัญชี โปรดตรวจสอบรายละเอียดการถอนเงินของคุณอย่างละเอียด หากคุณพบข้อผิดพลาด คลิก ‘ย้อนกลับ’ เพื่อทำการแก้ไข",
      wallet_address_error: "ที่อยู่กระเป๋าสตางค์ไม่ถูกต้อง",
      currency_rate: "ค่าเงินปัจจุบัน",
    },
    withdraw_step: {
      no_payment: "ขออภัย ตัวเลือกการชำระเงินไม่สามารถใช้งานได้ชั่วคราว",
      no_account: "คุณยังไม่มีบัญชี MT5 กรุณาเปิดบัญชีก่อนดำเนินงาน",
      first_step: {
        title: "ขั้นตอนที่ 1",
        description: "เลือกบัญชี",
        withdraw_with: "ช่องทางการถอนเงิน",
        show_all_method: "แสดงช่องทางการถอนเงินทั้งหมด",
        minimun_withdraw: "	ถอนขั้นต่ำ	",
        withdraw_info:
          "	การถอนกองทุนหากมีตำแหน่งที่ใช้งานอยู่คุณสามารถถอนส่วนของผู้ถือหุ้น 70% ไม่รวมเครดิตหรือโบนัส	",
      },
      second_step: {
        title: "ขั้นตอนที่ 2",
        description: "เลือกจำนวนเงิน",
        transection_method: "วิธีการทำธุรกรรม",
      },
      third_step: {
        title: "ยืนยันการถอนเงิน",
        description: "สรุปการถอนเงิน",
      },
    },
    withdraw_error: {
      t_account: "กรุณาเลือกบัญชีของคุณ",
      t_amount: "ขั้นต่ำการถอนจำนวนเงินต้องมากกว่า",
      t_condition: "เงื่อนไข",
      t_error: "จำนวนเงินไม่เพียงพอ",
      t_max: "ถอนสูงสุด",
    },
    withdraw_success: {
      title: "การถอนสำเร็จ",
      detail: "The amount will transfer to your bank account in 3-5 minute",
      button: "Back to Account",
    },
  },
  lo: {
    faq_withdraw: {
      faq_withdraw_title_1: "ຂ້ອຍສາມາດຖອນເງິນໄດ້ບໍ່ຖ້າມີຄໍາສັ່ງຊື້ຂາຍເປີດຢູ່?",
      faq_withdraw_des_1:
        "ທ່ານສາມາດຖອນເງິນຂື້ນໄປຫາ 90% ຂອງ Free Margin ທີ່ຍັງເຫຼືອ, ບໍ່ລວມເຄຣດິດ, ແລະການຖອນຕ້ອງບໍ່ເກີນຍອດເງິນຄົງເຫຼືອ",
      faq_withdraw_title_2: "ຂ້ອຍສາມາດຖອນເງິນໃນຊ່ວງວັນພັກໄດ້ບໍ່?",
      faq_withdraw_des_2: "ທ່ານສາມາດຖອນເງິນໃນຊ່ວງວັນພັກຕາມປົກກະຕິ",
      faq_withdraw_title_3: "ການຖອນເງິນໃຊ້ເວລາເທົ່າໃດ?",
      faq_withdraw_des_3:
        "ການຖອນເງິນໃນທົ່ວໄປເອົາເວລາ 5-15 ນາທີ ແຕ່ບໍ່ເກີນ 24 ຊົ່ວໂມງ",
      faq_withdraw_title_4: "ມີຄ່າທຳນຽມການຖອນເງິນຫຼືບໍ່?",
      faq_withdraw_des_4: "ທ່ານຈະບໍ່ຖືກຄິດເກັບຄ່າທຳນຽມສຳລັບການຖອນເງິນ",
    },

    withdraw_info: {
      withdraw_warning_text:
        "ກະລຸນາຮູ້ວ່າຖ້າມີຄໍາສັ່ງຊື້ຂາຍເປີດຢູ່, ທ່ານສາມາດຖອນເງິນຂື້ນໄປຫາ 90% ຂອງຈຳນວນ Free Margin ທີ່ເຫຼືອ, ບໍ່ລວມເຄດິດ",
      fee: "ຄ່າທຳນຽມ",
      rate: "ອັດຕາ",
      warning_wallet:
        "ກວດສອບລາຍລະອຽດການຖອນເງິນຂອງທ່ານອີກຄັ້ງ ຖ້າພົບຂໍ້ຜິດພາດ ກົດກັບໄປບໍ່ທັງທໍ່ລົງແລະດັດແກ້ລາຍລະອຽດຂອງທ່ານ",
      total_amount: "ຈຳນວນທັງໝົດ",
      requested_date: "ວັນທີ່ຮ້ອນ",
      save: "ບັນທືກ",
      bank_detail: "ລາຍລະອຽດທະນາຄານ",
      fund_detail: "ລາຍລະອຽດຄຸນນະພາບ",
      local_amount: "ຈຳນວນເງິນຕົ້ນທຶນ",
      request_success: "ຮ່ວມສຳເລັດ",
      withdraw_processing:
        "ການຊື້ເງິນຂອງທ່ານກໍ່ຍັງຢູ່ໃນຂະບໍລິຄັບ, ກະລຸນາຖ້າຫາ 2-10 ນາທີ",
      result: "ການຢັ້ງຢືນ",
      warning_head:
        "Withdrawing funds, if there are active positions, you can withdraw 70% Equity, not including credit or bonus.",
      warning_bottom1:
        " Please note that your withdrawal will depend on the exchange rate of the intermediary you use. Withdrawal Please make sure which exchange rate is the most suitable for each withdrawal.",
      warning_bottom2:
        "And please note that your deposit and withdrawal must be in the same name as the information you have confirmed. In order to avoid any withdrawal problems that may occur if the information is inconsistent with the registration",
      account: {
        name: "ຊື່​ບັນ​ຊີ",
        number: "ເລກບັນຊີ",
        bank: "ຊື່ທະນາຄານ",
        amount: "ຈໍາ​ນວນ",
        Code: "ໃສ່ລະຫັດຈາກອີເມວ ",
      },
      success: {
        title: "ສໍາ​ເລັດ",
        description:
          "ວກເຮົາໄດ້ຮັບ ຄຳ ຮ້ອງຂໍຖອນເງິນຂອງທ່ານແລ້ວ. ແລະຈະປະມວນຜົນພາຍໃນ 24 ຊົ່ວໂມງ. ກະລຸນາຮັບຊາບວ່າ ຈຳ ນວນເງິນທີ່ຂໍໄດ້ຖືກຫັກອອກຈາກບັນຊີຂອງທ່ານແລ້ວ.",
        amount: "ຖອນເງິນ",
      },
      note: {
        title:
          "ຫມາຍ​ເຫດ​ : ຄວນຮູ້ໃນຂະນະທີ່ທ່ານສັ່ງຊື້ຢູ່​ ການຖອນເງິນແມ່ນຮັບ70% ຂອງ margin ໂດຍບໍ່ມີການ ນຳເຄດິດ ມາລວມນຳ ຍອດເງິນຂອງທ່ານ",
        description: "ແນະ ນຳ: ຖ້າຈະຖອນເງິນ, ທຸກ ຄຳ ສັ່ງທີ່ຍັງຄ້າງຄວນຈະປິດກ່ອນ.",
      },
      amount: "ຈຳ ນວນເງິນ (LAK)",
      currency: "ສະກຸນເງິນ",
      wallet_address: "ທີ່ຢູ່ກະຊວງ",
      total_amount: "ຈຳນວນທັງໝົດ",
      bockchain_fee: "ຄ່າທຳນຽມບັນຊີ",
      approximate: "ປະມານ",
      crypto_warning:
        "ທ່ານມີຄວາມຮັບຜິດຊອບສຳລັບຄວາມຖືກຕ້ອງຂອງບັນຊີ. ກວດສອບລາຍລະອຽດການຖອນເງິນຂອງທ່ານຢ່າງລະມັດ. ຖ້າທ່ານພົບຂໍ້ຜິດພາດ, ກົດ 'ກັບຄືນ' ເພື່ອດັດຂໍ້ຜິດພາດ.",
      wallet_address_error: "ທີ່ຢູ່ກະຊວງບໍ່ຖືກຕ້ອງ",
      currency_rate: "ອັດຕາເງິນຕາ",
    },
    withdraw_step: {
      no_payment: "ຂໍອະໄພ, ທາງເລືອກການຈ່າຍເງິນບໍ່ສາມາດໃຊ້ໄດ້ຊົ່ວຄາວ.",
      no_account:
        "ທ່ານບໍ່ມີບັນຊີ MT5. ກະລຸນາລົງທະບຽນບັນຊີ MT5 ເພື່ອເລີ່ມກ່ອນຂະບວນການນີ້",
      first_step: {
        title: "ຂັ້ນຕອນທີ 1",
        description: "ເລືອກບັນຊີ",
        withdraw_with: "ວິທີການຖອນເງິນ",
        show_all_method: "ສະແດງທຸກວິທີການ",
        minimun_withdraw: "	ການຖອນເງິນຂັ້ນຕ່ໍາ	",
        withdraw_info:
          "	ການຖອນເງິນ, ຖ້າມີຕໍາແຫນ່ງທີ່ໃຊ້ງານ, ທ່ານສາມາດຖອນຮຸ້ນ 70%, ບໍ່ລວມເອົາສິນເຊື່ອຫລືໂບນັດ.	",
      },
      second_step: {
        title: "ຂັ້ນຕອນທີ 2",
        description: "ຈຳ ນວນເງິນເຂົ້າ",
        transection_method: "ວິທີການເຮັດທຸລະກໍາ",
      },
      third_step: {
        title: "ຖອນການຢັ້ງຢືນ",
        description: "ຜົນໄດ້ຮັບ",
      },
    },
    withdraw_error: {
      t_account: "ບັນຊີຂອງທ່ານຍັງບໍ່ໄດ້ເລືອກເທື່ອ.",
      t_amount: "ຈຳ ນວນເງິນຄວນຫຼາຍກ່ວາ",
      t_condition: "ເງື່ອນໄຂ",
      t_error: "Insufficient balance",
      t_max: "Maximum withdraw",
    },
    withdraw_success: {
      title: "ການຊື້ເງິນຂອງທ່ານກໍ່ສຳເລັດ",
      detail: "The amount will transfer to your bank account in 3-5 minute",
      button: "Back to Account",
    },
  },
  en: {
    faq_withdraw: {
      faq_withdraw_title_1:
        "Can I withdraw funds if I have open trading orders?",
      faq_withdraw_des_1:
        "You can withdraw up to 90% of the remaining Free Margin, excluding credits, and the withdrawal cannot exceed the remaining balance.",
      faq_withdraw_title_2: "Can I withdraw money during holidays?",
      faq_withdraw_des_2: "You can withdraw money during holidays as usual.",
      faq_withdraw_title_3: "How long does it take to withdraw money?",
      faq_withdraw_des_3:
        "Withdrawals typically take 5-15 minutes but not more than 24 hours.",
      faq_withdraw_title_4: "Is there a fee for withdrawing money?",
      faq_withdraw_des_4:
        "You will not be charged a fee for withdrawing money.",
    },

    withdraw_info: {
      withdraw_warning_text:
        "Please note that if there is an open trade order, you can withdraw up to 90% of the remaining Free Margin amount, excluding credits.",
      fee: "Fee",
      rate: "Rate",
      warning_wallet:
        "Double-check your withdrawal details. If you find an error, click Back and correct your details.",
      total_amount: "Total Amount",
      requested_date: "Requested Date",
      save: "Save",
      bank_detail: "Bank Detail",
      fund_detail: "Fund Detail",
      local_amount: "Local amount",
      request_success: "Requested Success",
      withdraw_processing:
        "Your withdrawal is in process, please wait about 2-10 minutes",
      result: "Confirmation",
      warning_head:
        "Withdrawing funds, if there are active positions, you can withdraw 70% Equity, not including credit or bonus.",
      warning_bottom1:
        " Please note that your withdrawal will depend on the exchange rate of the intermediary you use. Withdrawal Please make sure which exchange rate is the most suitable for each withdrawal.",
      warning_bottom2:
        "And please note that your deposit and withdrawal must be in the same name as the information you have confirmed. In order to avoid any withdrawal problems that may occur if the information is inconsistent with the registration",
      account: {
        name: "Account Name",
        number: "Account Number",
        bank: "Bank name",
        amount: "Amount",
        Code: "Enter the code received via",
      },
      success: {
        title: "Success",
        description:
          "We have received your withdrawal request. And will be processed within 24 hours. Please note that the requested amount has been deducted from your account.",
        amount: "Withdraw",
      },
      note: {
        title:
          "Note: Please be aware while placing a trading order. Withdrawals can be withdrawn 70% of the margin without bringing credit.",
        description:
          "Recommend: If withdrawing funds, all pending orders should be closed first.",
      },
      amount: "Amount",
      currency: "Currency",
      wallet_address: "Wallet Address",
      total_amount: "Total Amount",
      bockchain_fee: "Blockchain Fee",
      approximate: "Approximate",
      crypto_warning:
        "You are responsible for the accuracy of the account number. Review your withdrawal details carefully. If you find an error, click ‘Back’ to correct.",

      wallet_address_error: "The wallet address is incorrect.",
      currency_rate: "Currency Rate",
    },
    withdraw_step: {
      no_payment: "Apologies, the payment option is temporarily unavailable.",
      no_account:
        "You don't have MT5 account. please register MT5 account before this process",
      first_step: {
        title: "Step 1",
        description: "Select Account",
        withdraw_with: "Withdraw by",
        show_all_method: "Other Method",
        minimun_withdraw: "	Minimum withdraw	",
        withdraw_info:
          "	Withdrawing funds, if there are active positions, you can withdraw 70% Equity, not including credit or bonus.	",
      },
      second_step: {
        title: "Step 2",
        description: "Input Amount",
        transection_method: "Transaction Method",
      },
      third_step: {
        title: "Withdraw Confirmation",
        description: "Result",
      },
    },
    withdraw_error: {
      t_account: "Your account not selected yet.",
      t_amount: "amount should more than",
      t_condition: "Not meet the minimum requirement",
      t_error: "Insufficient balance",
      t_max: "Maximum withdraw",
    },
    withdraw_success: {
      title: "Withdraw success",
      detail: "The amount will transfer to your bank account in 3-5 minute",
      button: "Back to Account",
    },
  },
  hi: {
    faq_withdraw: {
      faq_withdraw_title_1:
        "क्या मैं पैसे निकाल सकता हूँ अगर मेरे खुले ट्रेडिंग ऑर्डर हैं?",
      faq_withdraw_des_1:
        "आप शेष फ्री मार्जिन का 90% तक निकाल सकते हैं, क्रेडिट को छोड़कर, और निकासी शेष राशि से अधिक नहीं हो सकती।",
      faq_withdraw_title_2: "क्या मैं छुट्टियों के दौरान पैसे निकाल सकता हूँ?",
      faq_withdraw_des_2:
        "आप छुट्टियों के दौरान सामान्य रूप से पैसे निकाल सकते हैं।",
      faq_withdraw_title_3: "पैसे निकालने में कितना समय लगता है?",
      faq_withdraw_des_3:
        "निकासी आमतौर पर 5-15 मिनट लेती है लेकिन 24 घंटे से अधिक नहीं।",
      faq_withdraw_title_4: "क्या पैसे निकालने में कोई शुल्क लगता है?",
      faq_withdraw_des_4: "पैसे निकालने के लिए आपसे कोई शुल्क नहीं लिया जाएगा।",
    },

    withdraw_info: {
      withdraw_warning_text:
        "कृपया ध्यान दें कि यदि कोई खुला व्यापार आदेश है, तो आप क्रेडिट को छोड़कर, शेष फ्री मार्जिन राशि का 90% तक निकाल सकते हैं।",
      fee: "शुल्क",
      rate: "दर",
      warning_wallet:
        "अपने निकासी विवरण की दोबारा जाँच करें। यदि आपको कोई त्रुटि मिलती है, तो 'वापस' पर क्लिक करके अपने विवरण सही करें।",
      total_amount: "कुल राशि",
      requested_date: "अनुरोध की तारीख",
      save: "सहेजें",
      bank_detail: "बैंक विवरण",
      fund_detail: "फंड विवरण",
      local_amount: "स्थानीय राशि",
      request_success: "अनुरोध सफल",
      withdraw_processing:
        "आपका निकास प्रक्रिया में है, कृपया लगभग 2-10 मिनट का इंतजार करें",
      result: "Confirmation",
      warning_head:
        "Withdrawing funds, if there are active positions, you can withdraw 70% Equity, not including credit or bonus.",
      warning_bottom1:
        " Please note that your withdrawal will depend on the exchange rate of the intermediary you use. Withdrawal Please make sure which exchange rate is the most suitable for each withdrawal.",
      warning_bottom2:
        "And please note that your deposit and withdrawal must be in the same name as the information you have confirmed. In order to avoid any withdrawal problems that may occur if the information is inconsistent with the registration",
      account: {
        name: "Account Name",
        number: "Account Number",
        bank: "Bank name",
        amount: "Amount",
        Code: "Enter the code received via",
      },
      success: {
        title: "Success",
        description:
          "We have received your withdrawal request. And will be processed within 24 hours. Please note that the requested amount has been deducted from your account.",
        amount: "Withdraw",
      },
      note: {
        title:
          "Note: Please be aware while placing a trading order. Withdrawals can be withdrawn 70% of the margin without bringing credit.",
        description:
          "Recommend: If withdrawing funds, all pending orders should be closed first.",
      },
      amount: "Amount",
      currency: "मुद्रा",
      wallet_address: "वॉलेट पता",
      total_amount: "कुल राशि",
      bockchain_fee: "ब्लॉकचेन शुल्क",
      approximate: "लगभग",
      crypto_warning:
        "खाता संख्या की सटीकता के लिए आप जिम्मेदार हैं। अपने निकासी विवरणों की सावधानीपूर्वक समीक्षा करें। यदि आपको कोई त्रुटि मिलती है, तो सही करने के लिए 'वापस' पर क्लिक करें।",

      wallet_address_error: "वॉलेट पता गलत है।",
      currency_rate: "Currency Rate",
    },
    withdraw_step: {
      no_payment: "क्षमा करें, भुगतान विकल्प अस्थायी रूप से अनुपलब्ध है।",
      no_account:
        "You don't have MT5 account. please register MT5 account before this process",
      first_step: {
        title: "Step 1",
        description: "Select Account",
        withdraw_with: "Withdraw by",
        show_all_method: "Other Method",
        minimun_withdraw: "	Minimum withdraw	",
        withdraw_info:
          "	Withdrawing funds, if there are active positions, you can withdraw 70% Equity, not including credit or bonus.	",
      },
      second_step: {
        title: "Step 2",
        description: "Input Amount",
        transection_method: "Transaction Method",
      },
      third_step: {
        title: "Withdraw Confirmation",
        description: "Result",
      },
    },
    withdraw_error: {
      t_account: "Your account not selected yet.",
      t_amount: "amount should more than",
      t_condition: "Not meet the minimum requirement",
      t_error: "Insufficient balance",
      t_max: "Maximum withdraw",
    },
    withdraw_success: {
      title: "निकास सफल",
      detail: "The amount will transfer to your bank account in 3-5 minute",
      button: "Back to Account",
    },
  },
  ms: {
    faq_withdraw: {
      faq_withdraw_title_1:
        "Bolehkah saya mengeluarkan wang jika saya mempunyai pesanan dagangan terbuka?",
      faq_withdraw_des_1:
        "Anda boleh mengeluarkan sehingga 90% daripada Margin Bebas yang tinggal, tidak termasuk kredit, dan pengeluaran tidak boleh melebihi baki yang tinggal.",
      faq_withdraw_title_2: "Bolehkah saya mengeluarkan wang semasa cuti?",
      faq_withdraw_des_2:
        "Anda boleh mengeluarkan wang semasa cuti seperti biasa.",
      faq_withdraw_title_3:
        "Berapa lama masa yang diperlukan untuk mengeluarkan wang?",
      faq_withdraw_des_3:
        "Pengeluaran biasanya mengambil masa 5-15 minit tetapi tidak lebih daripada 24 jam.",
      faq_withdraw_title_4: "Adakah terdapat yuran untuk mengeluarkan wang?",
      faq_withdraw_des_4:
        "Anda tidak akan dikenakan yuran untuk mengeluarkan wang.",
    },

    withdraw_info: {
      withdraw_warning_text:
        "Sila ambil perhatian bahawa jika terdapat pesanan dagangan terbuka, anda boleh mengeluarkan sehingga 90% daripada jumlah Free Margin yang tinggal, tidak termasuk kredit",
      fee: "Yuran",
      rate: "Kadar",
      warning_wallet:
        "Periksa semula butiran pengeluaran anda. Jika anda mendapati kesilapan, klik Kembali dan betulkan butiran anda.",
      total_amount: "Jumlah Keseluruhan",
      requested_date: "Tarikh Diminta",
      save: "Simpan",
      bank_detail: "Butiran Bank",
      fund_detail: "Butiran Dana",
      local_amount: "Jumlah Tempatan",
      request_success: "Permintaan Berjaya",
      withdraw_processing:
        "Pengeluaran anda dalam proses, sila tunggu kira-kira 2-10 minit",
      result: `Pengesahan pengeluaran`,
      warning_head:
        "Pengeluaran dana, jika terdapat posisi aktif, anda boleh mengeluarkan 70% Ekuiti, tidak termasuk kredit atau bonus.",
      warning_bottom1:
        " Sila ambil perhatian bahawa pengeluaran anda akan bergantung pada kadar pertukaran pengantara yang anda gunakan.Pengeluaran Sila pastikan kadar pertukaran mana yang paling sesuai untuk setiap pengeluaran.",
      warning_bottom2:
        "Dan sila ambil perhatian bahawa deposit dan pengeluaran anda mestilah atas nama yang sama dengan maklumat yang telah anda sahkan. Bagi mengelakkan sebarang masalah pengeluaran yang mungkin berlaku sekiranya maklumat tidak konsisten dengan pendaftaran",
      account: {
        name: `Nama akaun`,
        number: `Nombor akaun`,
        bank: `Nama bank`,
        amount: `jumlah`,
        Code: `Masukkan kod yang diterima melalui`,
      },
      success: {
        title: `Pengeluaran berjaya`,
        description: `Kami telah menerima permintaan pengeluaran anda dan ia akan diproses dalam masa 24 jam. Sila ambil perhatian bahawa jumlah yang diminta telah ditolak daripada akaun anda`,
        amount: `Mengeluarkan`,
      },
      note: {
        title: `Nota: Jika ketika mengeluarkan wang, anda membuka pesanan yang belum selesai, anda akan dapat mengeluarkan hanya 70% daripada Baki sedia ada anda. (Perintah yang tidak termasuk, termasuk, stop, jual, stop, had, menjual)`,
        description: `Disyorkan: Untuk mengeluarkan dana, semua pesanan yang belum selesai hendaklah ditutup terlebih dahulu`,
      },
      amount: `Jumlah`,
      currency: "Mata Wang",
      wallet_address: "Alamat Wallet",
      total_amount: "Jumlah Keseluruhan",
      bockchain_fee: "Yuran Blockchain",
      approximate: "Anggaran",
      crypto_warning:
        "Anda bertanggungjawab atas ketepatan nombor akaun. Semak butiran pengeluaran anda dengan teliti. Jika anda mendapati ralat, klik 'Kembali' untuk membetulkan.",

      wallet_address_error: "Alamat wallet tidak betul.",
      currency_rate: `Kadar mata wang`,
    },
    withdraw_step: {
      no_payment:
        "Maaf, pilihan pembayaran tidak tersedia buat sementara waktu.",
      no_account: `Anda tidak mempunyai akaun MT5, sila buka akaun sebelum meneruskan`,
      first_step: {
        title: `Langkah 1`,
        description: `Pilih Akaun`,
        withdraw_with: "Menarik diri dengan",
        show_all_method: "Tunjukkan semua kaedah",
        minimun_withdraw: "	Minimum menarik balik	",
        withdraw_info:
          "	Mengeluarkan dana, jika terdapat kedudukan aktif, anda boleh mengeluarkan 70% ekuiti, tidak termasuk kredit atau bonus.	",
      },
      second_step: {
        title: `langkah 2`,
        description: `Pilih Jumlah`,
        transection_method: "Transaction Method",
      },
      third_step: {
        title: `Withdraw Confirmation`,
        description: `Ringkasan Pengeluaran`,
      },
    },
    withdraw_error: {
      t_account: `Sila pilih akaun anda.`,
      t_amount: `Jumlah pengeluaran minimum mestilah lebih besar daripada`,
      t_condition: "syarat",
      t_error: "jumlah dana tidak mencukupi",
      t_max: "Pengeluaran Maksimum",
    },
    withdraw_success: {
      title: "Pengeluaran Berjaya",
      detail: "The amount will transfer to your bank account in 3-5 minute",
      button: "Back to Account",
    },
  },
  id: {
    faq_withdraw: {
      faq_withdraw_title_1:
        "Apakah saya bisa menarik dana jika saya memiliki pesanan trading yang terbuka?",
      faq_withdraw_des_1:
        "Anda dapat menarik hingga 90% dari Free Margin yang tersisa, tidak termasuk kredit, dan penarikan tidak boleh melebihi saldo yang tersisa.",
      faq_withdraw_title_2: "Apakah saya bisa menarik uang selama liburan?",
      faq_withdraw_des_2:
        "Anda dapat menarik uang selama liburan seperti biasa.",
      faq_withdraw_title_3:
        "Berapa lama waktu yang diperlukan untuk menarik uang?",
      faq_withdraw_des_3:
        "Penarikan biasanya memakan waktu 5-15 menit tetapi tidak lebih dari 24 jam.",
      faq_withdraw_title_4: "Apakah ada biaya untuk menarik uang?",
      faq_withdraw_des_4: "Anda tidak akan dikenakan biaya untuk menarik uang.",
    },

    withdraw_info: {
      withdraw_warning_text:
        "Harap diperhatikan bahwa jika ada pesanan perdagangan yang terbuka, Anda dapat menarik hingga 90% dari jumlah Free Margin yang tersisa, tidak termasuk kredit.",
      fee: "Biaya",
      rate: "Tarif",
      warning_wallet:
        "Periksa kembali detail penarikan Anda. Jika Anda menemukan kesalahan, klik Kembali dan perbaiki detail Anda.",
      total_amount: "Jumlah Total",
      requested_date: "Tanggal Diminta",
      save: "Simpan",
      bank_detail: "Rincian Bank",
      fund_detail: "Rincian Dana",
      local_amount: "Jumlah Lokal",
      request_success: "Permintaan Berhasil",
      withdraw_processing:
        "Penarikan Anda sedang diproses, harap tunggu sekitar 2-10 menit",
      result: `Konfirmasi`,
      warning_head:
        "Withdrawing funds, if there are active positions, you can withdraw 70% Equity, not including credit or bonus.",
      warning_bottom1:
        " Please note that your withdrawal will depend on the exchange rate of the intermediary you use. Withdrawal Please make sure which exchange rate is the most suitable for each withdrawal.",
      warning_bottom2:
        "And please note that your deposit and withdrawal must be in the same name as the information you have confirmed. In order to avoid any withdrawal problems that may occur if the information is inconsistent with the registration",
      account: {
        name: `Nama akun`,
        number: `Nomor akun`,
        bank: `nama Bank`,
        amount: `Jumlah`,
        Code: `Enter the code received via`,
      },
      success: {
        title: `Keberhasilan`,
        description: `Kami telah menerima permintaan penarikan Anda. Dan akan diproses dalam waktu 24 jam. Harap dicatat bahwa jumlah yang diminta telah dipotong dari akun Anda`,
        amount: `Menarik`,
      },
      note: {
        title: `Catatan: Jika penarikan uang dalam hal membuka pending order Anda akan dapat menarik hanya 70% dari Equity yang ada. (Pesanan yang tidak termasuk, termasuk, berhenti, sell, stop, limit, sell)`,
        description: `Merekomendasikan: Jika penarikan dana, semua pesanan yang tertunda harus ditutup pertama`,
      },
      amount: `Jumlah`,
      currency: "Mata Uang",
      wallet_address: "Alamat Dompet",
      total_amount: "Jumlah Total",
      bockchain_fee: "Biaya Blockchain",
      approximate: "Perkiraan",
      crypto_warning:
        "Anda bertanggung jawab atas keakuratan nomor akun. Tinjau detail penarikan Anda dengan cermat. Jika Anda menemukan kesalahan, klik 'Kembali' untuk memperbaiki.",
      wallet_address_error: "Alamat dompet salah.",
      currency_rate: `Currency rate`,
    },
    withdraw_step: {
      no_payment: "Mohon maaf, opsi pembayaran untuk sementara tidak tersedia.",
      no_account: `Anda tidak memiliki akun MT5. silakan mendaftar akun MT5 sebelum proses ini`,
      first_step: {
        title: `Langkah 1`,
        description: `Pilih account`,
        withdraw_with: "Menarik diri dengan",
        show_all_method: "Tunjukkan semua kaedah",
        minimun_withdraw: "	Penarikan minimum	",
        withdraw_info:
          "	Menarik dana, jika ada posisi aktif, Anda dapat menarik 70% ekuitas, tidak termasuk kredit atau bonus.	",
      },
      second_step: {
        title: `Langkah 2`,
        description: `Jumlah masukan`,
        transection_method: "Transaction Method",
      },
      third_step: {
        title: `Withdraw Confirmation`,
        description: `Hasil`,
      },
    },
    withdraw_error: {
      t_account: `Akun Anda tidak dipilih belum.`,
      t_amount: `Jumlah harus lebih dari`,
      t_condition: "kondisi",
      t_error: "Insufficient balance",
      t_max: "Maximum withdraw",
    },
    withdraw_success: {
      title: "Penarikan Berhasil",
      detail: "The amount will transfer to your bank account in 3-5 minute",
      button: "Back to Account",
    },
  },
  zh: {
    faq_withdraw: {
      faq_withdraw_title_1: "如果我有未关闭的交易订单，我可以提款吗？",
      faq_withdraw_des_1:
        "您可以提取剩余自由保证金的90%，不包括信用额度，提款金额不能超过剩余余额。",
      faq_withdraw_title_2: "我可以在假期提款吗？",
      faq_withdraw_des_2: "您可以像平常一样在假期提款。",
      faq_withdraw_title_3: "提款需要多长时间？",
      faq_withdraw_des_3: "提款通常需要5-15分钟，但不超过24小时。",
      faq_withdraw_title_4: "提款有手续费吗？",
      faq_withdraw_des_4: "您提款时不会被收取任何手续费。",
    },

    withdraw_info: {
      withdraw_warning_text:
        "请注意，如果有未平仓交易订单，您可以提取剩余自由保证金金额的90%，不包括信用额度。",
      fee: "费用",
      rate: "费率",
      warning_wallet:
        "再次检查您的提款细节。如果您发现有错误，请点击返回并更正您的细节。",
      total_amount: "总金额",
      requested_date: "请求日期",
      save: "保存",
      bank_detail: "银行详情",
      fund_detail: "基金详情",
      local_amount: "本地金额",
      request_success: "请求成功",
      withdraw_processing: "您的提款正在处理中，请等待约2-10分钟",
      result: `确认`,
      warning_head:
        "Withdrawing funds, if there are active positions, you can withdraw 70% Equity, not including credit or bonus.",
      warning_bottom1:
        " Please note that your withdrawal will depend on the exchange rate of the intermediary you use. Withdrawal Please make sure which exchange rate is the most suitable for each withdrawal.",
      warning_bottom2:
        "And please note that your deposit and withdrawal must be in the same name as the information you have confirmed. In order to avoid any withdrawal problems that may occur if the information is inconsistent with the registration",
      account: {
        name: `用户名`,
        number: `账号`,
        bank: `银行名称`,
        amount: `量`,
        Code: `Enter the code received via`,
      },
      success: {
        title: `成功`,
        description: `，我们收到您的取款请求。而将在24小时内处理。请注意，请求量已经从您的账户中扣除`,
        amount: `退出`,
      },
      note: {
        title: `注：如果在打开挂单的情况下取钱，您将能够收回现有的余额只有70％。 （未包括订单，包括止损，卖出，止损，限制，卖出）`,
        description: `推荐：如果抽逃资金，所有挂单，应首先关闭`,
      },
      amount: `金额`,
      currency: "货币",
      wallet_address: "钱包地址",
      total_amount: "总金额",
      bockchain_fee: "区块链费用",
      approximate: "大约",
      crypto_warning:
        "您对账号的准确性负责。仔细检查您的提款详情。如果发现错误，请点击‘返回’进行更正。",

      wallet_address_error: "钱包地址不正确。",
      currency_rate: `货币汇率`,
    },
    withdraw_step: {
      no_payment: "Apologies, but the payment is temporarily unavailable.",
      no_account: `你不必MT5账户。请在此过程之前注册MT5账户`,
      first_step: {
        title: `第1步`,
        description: `选择帐户`,
        withdraw_with: "withdraw by",
        show_all_method: "Other Method",
        minimun_withdraw: "	最低撤回	",
        withdraw_info:
          "	撤回資金，如果有積極的職位，則可以撤回70％的股權，而不包括信貸或獎金。	",
      },
      second_step: {
        title: `第2步`,
        description: `投入量`,
        transection_method: "Transaction Method",
      },
      third_step: {
        title: `Withdraw Confirmation`,
        description: `结果`,
      },
    },
    withdraw_error: {
      t_account: `您的帐户尚未选定。`,
      t_amount: `量应超过3美元。`,
      t_condition: "状况",
      t_error: "Insufficient balance",
      t_max: "Maximum withdraw",
    },
    withdraw_success: {
      title: "提款成功",
      detail: "The amount will transfer to your bank account in 3-5 minute",
      button: "Back to Account",
    },
  },
  vi: {
    faq_withdraw: {
      faq_withdraw_title_1:
        "Tôi có thể rút tiền nếu tôi có các lệnh giao dịch đang mở không?",
      faq_withdraw_des_1:
        "Bạn có thể rút tới 90% của Số Dư Tự Do còn lại, không bao gồm tín dụng, và số tiền rút không được vượt quá số dư còn lại.",
      faq_withdraw_title_2: "Tôi có thể rút tiền trong kỳ nghỉ không?",
      faq_withdraw_des_2: "Bạn có thể rút tiền trong kỳ nghỉ như bình thường.",
      faq_withdraw_title_3: "Mất bao lâu để rút tiền?",
      faq_withdraw_des_3:
        "Rút tiền thường mất khoảng 5-15 phút nhưng không quá 24 giờ.",
      faq_withdraw_title_4: "Có phí rút tiền không?",
      faq_withdraw_des_4: "Bạn sẽ không phải trả phí khi rút tiền.",
    },

    withdraw_info: {
      withdraw_warning_text:
        "Xin lưu ý rằng nếu có lệnh giao dịch mở, bạn có thể rút tới 90% số tiền của Free Margin còn lại, không bao gồm tín dụng.",
      fee: "Phí",
      rate: "Tỷ lệ",
      warning_wallet:
        "Kiểm tra lại chi tiết rút tiền của bạn. Nếu bạn phát hiện lỗi, hãy nhấn Quay lại và sửa chữa thông tin của bạn.",
      total_amount: "Tổng số tiền",
      requested_date: "Ngày Yêu cầu",
      save: "Lưu",
      bank_detail: "Chi tiết Ngân hàng",
      fund_detail: "Chi tiết Quỹ",
      local_amount: "Số tiền địa phương",
      request_success: "Yêu cầu thành công",
      withdraw_processing:
        "Quá trình rút tiền của bạn đang được xử lý, vui lòng đợi khoảng 2-10 phút",
      result: "Xác nhận rút tiền",
      warning_head:
        "Withdrawing funds, if there are active positions, you can withdraw 70% Equity, not including credit or bonus.",
      warning_bottom1:
        " Please note that your withdrawal will depend on the exchange rate of the intermediary you use. Withdrawal Please make sure which exchange rate is the most suitable for each withdrawal.",
      warning_bottom2:
        "And please note that your deposit and withdrawal must be in the same name as the information you have confirmed. In order to avoid any withdrawal problems that may occur if the information is inconsistent with the registration",
      account: {
        name: "Tên tài khoản",
        number: "Số tài khoản",
        bank: "Tài khoản",
        amount: "Số tiền (USD)",
        Code: "Điền mã nhận được qua",
      },
      success: {
        title: "Rút tiền thành công",
        description:
          "Chúng tôi đã nhận được yêu cầu rút tiền của bạn. Và nó sẽ được xử lý trong vòng 24 giờ. Xin lưu ý rằng số tiền được yêu cầu đã bị trừ khỏi tài khoản của bạn.",
        amount: "Rút tiền",
      },
      note: {
        title:
          "“LƯU Ý: Hãy lưu ý rằng khi bạn có đặt lệnh giao dịch. Rút tiền có thể được rút 70% margin mà không cần tín dụng.",
        description: `Khuyến nghị: Nếu bạn muốn rút tiền, bạn nên đóng tất cả các lệnh đang chờ xử lý trước.`,
      },
      amount: "Số tiền muốn rút",
      currency: "Tiền tệ",
      wallet_address: "Địa chỉ Ví",
      total_amount: "Tổng Số Tiền",
      bockchain_fee: "Phí Blockchain",
      approximate: "Ước lượng",
      crypto_warning:
        "Bạn chịu trách nhiệm về độ chính xác của số tài khoản. Hãy kiểm tra kỹ thông tin rút tiền của bạn. Nếu bạn phát hiện lỗi, nhấp ‘Quay lại’ để sửa chữa.",

      wallet_address_error: "Địa chỉ ví không chính xác.",
      currency_rate: "Giá tiền hiện tại",
    },
    withdraw_step: {
      no_payment: "Xin lỗi, tùy chọn thanh toán tạm thời không khả dụng.",
      no_account:
        "Bạn chưa có tài khoản MT5 vui lòng mở tài khoản trước thực hiện.",
      first_step: {
        title: `Bước 1`,
        description: `Chọn tài khoản`,
        withdraw_with: "rút tiền với",
        show_all_method: "Hiển thị tất cả phương pháp",
        minimun_withdraw: "	Rút tối thiểu	",
        withdraw_info:
          "	Rút tiền, nếu có các vị trí hoạt động, bạn có thể rút 70% vốn chủ sở hữu, không bao gồm tín dụng hoặc tiền thưởng.	",
      },
      second_step: {
        title: `Bước 2`,
        description: "Chọn số tiền",
        transection_method: "Phương thức giao dịch",
      },
      third_step: {
        title: `Xác nhận rút tiền`,
        description: "Tóm tắt rút tiền",
      },
    },
    withdraw_error: {
      t_account: "Vui lòng chọn tài khoản của bạn",
      t_amount: "Số tiền rút tối thiểu phải nhiều hơn",
      t_condition: "tình trạng",
      t_error: "Insufficient balance",
      t_max: "Maximum withdraw",
    },
    withdraw_success: {
      title: "Rút tiền thành công",
      detail: "Số tiền sẽ chuyển vào tài khoản ngân hàng của bạn sau 3-5 phút",
      button: "Quay lại tài khoản",
    },
  },
  ko: {
    faq_withdraw: {
      faq_withdraw_title_1: "오픈된 거래 주문이 있을 때 돈을 인출할 수 있나요?",
      faq_withdraw_des_1:
        "여유 마진 잔액의 90%까지 인출할 수 있으며, 크레딧은 제외하며 인출 금액은 남은 잔액을 초과할 수 없습니다.",
      faq_withdraw_title_2: "휴일에 돈을 인출할 수 있나요?",
      faq_withdraw_des_2: "휴일에도 평소처럼 돈을 인출할 수 있습니다.",
      faq_withdraw_title_3: "돈을 인출하는데 얼마나 걸리나요?",
      faq_withdraw_des_3:
        "인출은 일반적으로 5-15분이 소요되지만 24시간을 초과하지 않습니다.",
      faq_withdraw_title_4: "돈을 인출할 때 수수료가 있나요?",
      faq_withdraw_des_4: "돈을 인출할 때 수수료가 부과되지 않습니다.",
    },

    withdraw_info: {
      withdraw_warning_text:
        "미결 주문이 있는 경우, 크레딧을 제외한 남은 프리 마진 금액의 최대 90%까지 출금할 수 있습니다.",
      fee: "수수료",
      rate: "율",
      warning_wallet:
        "출금 세부 정보를 다시 확인하세요. 오류를 발견하면 뒤로 가서 세부 정보를 수정하세요.",
      total_amount: "총액",
      requested_date: "요청 날짜",
      save: "저장",
      bank_detail: "은행 상세 정보",
      fund_detail: "기금 상세 정보",
      local_amount: "지역 통화",
      request_success: "요청 성공",
      withdraw_processing:
        "귀하의 인출이 진행 중입니다. 약 2-10 분 정도 기다려주세요",
      result: `확인`,
      warning_head:
        "Withdrawing funds, if there are active positions, you can withdraw 70% Equity, not including credit or bonus.",
      warning_bottom1:
        " Please note that your withdrawal will depend on the exchange rate of the intermediary you use. Withdrawal Please make sure which exchange rate is the most suitable for each withdrawal.",
      warning_bottom2:
        "And please note that your deposit and withdrawal must be in the same name as the information you have confirmed. In order to avoid any withdrawal problems that may occur if the information is inconsistent with the registration",
      account: {
        name: `계정 이름`,
        number: `계좌 번호`,
        bank: `은행 이름`,
        amount: `양`,
        Code: `Enter the code received via`,
      },
      success: {
        title: `성공`,
        description: `우리는 당신의 탈퇴 요청을 받았습니다. 24 시간 내에 처리 될 것이다. 요청 된 금액이 계정에서 공제되어 있습니다`,
        amount: `빼다`,
      },
      note: {
        title: `참고 : 보류중인 주문을 여는 이벤트에 돈을 철회하는 경우 당신은 당신의 기존 잔액의 70 %를 철회 할 수 있습니다. (포함되지 않은 주문은 포함, 정지, 판매, 정지, 제한, 판매)`,
        description: `추천 : 자금을 철수하면 모든 보류중인 주문을 먼저 닫아야합니다`,
      },
      amount: `금액`,
      currency: "화폐",
      wallet_address: "지갑 주소",
      total_amount: "총 금액",
      bockchain_fee: "블록체인 수수료",
      approximate: "대략적인",
      crypto_warning:
        "계좌 번호의 정확성에 대한 책임은 당신에게 있습니다. 인출 세부 사항을 주의 깊게 검토하십시오. 오류를 발견하면 ‘뒤로’를 클릭하여 수정하십시오.",

      wallet_address_error: "지갑 주소가 잘못되었습니다.",
      currency_rate: `환율 비율`,
    },
    withdraw_step: {
      no_payment: "Apologies, but the payment is temporarily unavailable.",
      no_account: `당신은 MT5 계정을 가지고 있지 않습니다. 이 과정 전에 MT5 계정을 등록하십시오`,
      first_step: {
        title: `1 단계`,
        description: `계정 선택`,
        withdraw_with: "withdraw by",
        show_all_method: "Other Method",
        minimun_withdraw: "	최소 철회	",
        withdraw_info:
          "	자금 인출, 활발한 직책이있는 경우 신용이나 보너스를 포함하지 않고 70% 지분을 인출 할 수 있습니다.	",
      },
      second_step: {
        title: `2 단계`,
        description: `입력 금액`,
        transection_method: "Transaction Method",
      },
      third_step: {
        title: `Withdraw Confirmation`,
        description: `결과`,
      },
    },
    withdraw_error: {
      t_account: `계정이 아직 선택하지.`,
      t_amount: `양은 3 달러를해야한다.`,
      t_condition: "",
      t_error: "Insufficient balance",
      t_max: "Maximum withdraw",
    },
    withdraw_success: {
      title: "인출 성공",
      detail: "The amount will transfer to your bank account in 3-5 minute",
      button: "Back to Account",
    },
  },
  fil: {
    faq_withdraw: {
      faq_withdraw_title_1:
        "Maaari ba akong mag-withdraw ng pondo kung mayroon akong bukas na trading orders?",
      faq_withdraw_des_1:
        "Maaari kang mag-withdraw ng hanggang 90% ng natitirang Free Margin, hindi kasama ang credits, at ang withdrawal ay hindi dapat lumampas sa natitirang balanse.",
      faq_withdraw_title_2:
        "Maaari ba akong mag-withdraw ng pera sa panahon ng mga holiday?",
      faq_withdraw_des_2:
        "Maaari kang mag-withdraw ng pera sa panahon ng mga holiday tulad ng karaniwan.",
      faq_withdraw_title_3: "Gaano katagal bago ma-withdraw ang pera?",
      faq_withdraw_des_3:
        "Karaniwan, ang pag-withdraw ay tumatagal ng 5-15 minuto ngunit hindi lalagpas sa 24 oras.",
      faq_withdraw_title_4: "May bayad ba sa pag-withdraw ng pera?",
      faq_withdraw_des_4:
        "Hindi ka sisingilin ng bayad sa pag-withdraw ng pera.",
    },

    withdraw_info: {
      withdraw_warning_text:
        "Pakitandaan na kung may bukas na trade order, maaari kang mag-withdraw ng hanggang 90% ng natitirang halaga ng Free Margin, hindi kasama ang mga kredito.",
      fee: "Bayad",
      rate: "Rate",
      warning_wallet:
        "Doblehin ang pag-check ng iyong mga detalye sa pag-withdraw. Kung may nakita kang error, i-click ang Bumalik at itama ang iyong mga detalye.",
      total_amount: "Kabuuang Halaga",
      requested_date: "Petsa ng Ikinahiling",
      save: "I-save",
      bank_detail: "Detalye ng Bangko",
      fund_detail: "Detalye ng Pondo",
      local_amount: "Lokal na Halaga",
      request_success: "Tagumpay ang Ikinahiling",
      withdraw_processing:
        "Ang iyong pag-withdraw ay nasa proseso, mangyaring maghintay ng mga 2-10 minuto",
      result: `pagpapatibay`,
      warning_head:
        "Withdrawing funds, if there are active positions, you can withdraw 70% Equity, not including credit or bonus.",
      warning_bottom1:
        " Please note that your withdrawal will depend on the exchange rate of the intermediary you use. Withdrawal Please make sure which exchange rate is the most suitable for each withdrawal.",
      warning_bottom2:
        "And please note that your deposit and withdrawal must be in the same name as the information you have confirmed. In order to avoid any withdrawal problems that may occur if the information is inconsistent with the registration",
      account: {
        name: `Pangalan ng account`,
        number: `account Number`,
        bank: `Pangalan ng bangko`,
        amount: `dami`,
        Code: `Enter the code received via`,
      },
      success: {
        title: `tagumpay`,
        description: `Natanggap namin ang iyong kahilingan withdrawal. At ay ipoproseso sa loob ng 24 na oras. Mangyaring tandaan na ang hiniling na halaga ay ibabawas mula sa iyong account`,
        amount: `bawiin`,
      },
      note: {
        title: `Tandaan: Kung ma-withdraw ang pera sa kaganapan ng pagbubukas nakabinbing mga order Makaka upang bawiin lamang ang 70% ng iyong mga umiiral na Balanse. (Mga order na hindi kasama, isama, stop, sell, stop, limit, sell)`,
        description: `Magrekomenda: Kung ma-withdraw ang mga pondo, ang lahat ng mga nakabinbing mga order ay dapat na unang sarado`,
      },
      amount: `Halaga`,
      currency: "Pera",
      wallet_address: "Address ng Wallet",
      total_amount: "Kabuuang Halaga",
      bockchain_fee: "Bayad sa Blockchain",
      approximate: "Tinatayang",
      crypto_warning:
        "Ikaw ay responsable para sa katumpakan ng numero ng account. Suriin nang mabuti ang iyong mga detalye ng pag-withdraw. Kung may makita kang error, i-click ang ‘Bumalik’ para itama ito.",

      wallet_address_error: "Mali ang address ng wallet.",
      currency_rate: `Rate ng pera`,
    },
    withdraw_step: {
      no_payment:
        "Paumanhin, pansamantalang hindi available ang opsyon sa pagbabayad.",
      no_account: `Hindi mo na kailangang MT5 account. mangyaring magparehistro MT5 account bago ang proseso na ito`,
      first_step: {
        title: `hakbang 1`,
        description: `Piliin ang Account`,
        withdraw_with: "Umatras kasama",
        show_all_method: "Ipakita ang lahat ng paraan",
        minimun_withdraw: "	Minimum na pag -atras	",
        withdraw_info:
          "	Ang pag -alis ng mga pondo, kung may mga aktibong posisyon, maaari kang mag -alis ng 70% equity, hindi kasama ang kredito o bonus.	",
      },
      second_step: {
        title: `hakbang 2`,
        description: `input Halaga`,
        transection_method: "Transaction Method",
      },
      third_step: {
        title: `Withdraw Confirmation`,
        description: `resulta`,
      },
    },
    withdraw_error: {
      t_account: `Ang iyong account ay hindi pa nakapili.`,
      t_amount: `halagang dapat higit sa`,
      t_condition: "kundisyon",
      t_error: "Insufficient balance",
      t_max: "Maximum withdraw",
    },
    withdraw_success: {
      title: "Tagumpay ang Pag-withdraw",
      detail: "The amount will transfer to your bank account in 3-5 minute",
      button: "Back to Account",
    },
  },
  ja: {
    faq_withdraw: {
      faq_withdraw_title_1:
        "オープン中のトレーディングオーダーがある場合、資金を引き出すことはできますか？",
      faq_withdraw_des_1:
        "残りのフリーマージンの90%まで引き出すことができますが、クレジットは除外され、引き出し額は残高を超えてはなりません。",
      faq_withdraw_title_2: "休日に資金を引き出すことはできますか？",
      faq_withdraw_des_2: "休日でも通常通り資金を引き出すことができます。",
      faq_withdraw_title_3: "資金の引き出しにはどのくらい時間がかかりますか？",
      faq_withdraw_des_3:
        "引き出しには通常5〜15分かかりますが、24時間を超えることはありません。",
      faq_withdraw_title_4: "資金を引き出す際に手数料はかかりますか？",
      faq_withdraw_des_4: "資金を引き出す際に手数料はかかりません。",
    },

    withdraw_info: {
      withdraw_warning_text:
        "未決済の取引注文がある場合、クレジットを除く残りのフリーマージン額の90％まで引き出すことができます。",
      fee: "手数料",
      rate: "レート",
      warning_wallet:
        "引き出しの詳細を再確認してください。エラーが見つかった場合は、戻るをクリックして詳細を修正してください。",
      total_amount: "総額",
      requested_date: "リクエスト日",
      save: "保存",
      bank_detail: "銀行の詳細",
      fund_detail: "ファンドの詳細",
      local_amount: "地元の金額",
      request_success: "リクエストが成功しました",
      withdraw_processing:
        "お使いの引き出しが処理中です。約2-10分お待ちください",
      result: "確認",
      warning_head:
        "資金を撤回して、アクティブなポジションがある場合、クレジットやボーナスを含めずに、70％の株式を引き出すことができます。",
      warning_bottom1:
        "撤退は、使用する仲介者の為替レートに依存することに注意してください。 撤退すると、どの為替レートが撤退ごとに最も適しているかを確認してください。",
      warning_bottom2:
        "また、預金と引き出しは、確認した情報と同じ名前でなければならないことに注意してください。 情報が登録と矛盾している場合に発生する可能性のある撤退の問題を回避するために",
      account: {
        name: "アカウント名",
        number: "口座番号",
        bank: "銀行名",
        amount: "額",
        Code: "byで受信したコードを入力します",
      },
      success: {
        title: "成功",
        description:
          "引き出しリクエストを受け取りました。 24時間以内に処理されます。 要求された金額はアカウントから控除されていることに注意してください。",
        amount: "撤回する",
      },
      note: {
        title:
          "注：取引注文の際は注意してください。 引き出しは、信用を得ることなく、マージンの70％を引き出すことができます。",
        description:
          "推奨：資金を撤回する場合、保留中のすべての注文は最初に閉鎖する必要があります。",
      },
      amount: "額",
      currency: "通貨",
      wallet_address: "ウォレットアドレス",
      total_amount: "合計金額",
      bockchain_fee: "ブロックチェーン手数料",
      approximate: "おおよそ",
      crypto_warning:
        "口座番号の正確性はあなたの責任です。引き出しの詳細を慎重に確認してください。誤りを発見した場合は、「戻る」をクリックして訂正してください。",
      wallet_address_error: "ウォレットアドレスが正しくありません。",
      currency_rate: "通貨レート",
    },
    withdraw_step: {
      no_payment:
        "申し訳ございませんが、お支払いオプションは一時的にご利用いただけません。",
      no_account:
        "MT5アカウントはありません。 このプロセスの前にMT5アカウントを登録してください",
      first_step: {
        title: "ステップ1",
        description: "アカウントを選択します",
        withdraw_with: "で撤退します",
        show_all_method: "すべての方法を表示します",
        minimun_withdraw: "最小撤回",
        withdraw_info:
          "資金を撤回して、アクティブなポジションがある場合、クレジットやボーナスを含めずに、70％の株式を引き出すことができます。",
      },
      second_step: {
        title: "ステップ2",
        description: "入力量",
        transection_method: "トランザクション方法",
      },
      third_step: {
        title: "確認を撤回します",
        description: "結果",
      },
    },
    withdraw_error: {
      t_account: "アカウントはまだ選択されていません。",
      t_amount: "金額はそれ以上のものです",
      t_condition: "最小要件を満たしていません",
      t_error: "残高不足",
      t_max: "最大撤回",
    },
    withdraw_success: {
      title: "成功を撤回します",
      detail: "金額は3〜5分で銀行口座に転送されます",
      button: "アカウントに戻ります",
    },
  },
  es: {
    faq_withdraw: {
      faq_withdraw_title_1:
        "¿Puedo retirar fondos si tengo órdenes de trading abiertas?",
      faq_withdraw_des_1:
        "Puede retirar hasta el 90% del margen libre restante, excluyendo créditos, y el retiro no puede exceder el saldo restante.",
      faq_withdraw_title_2: "¿Puedo retirar dinero durante los días festivos?",
      faq_withdraw_des_2:
        "Puede retirar dinero durante los días festivos como de costumbre.",
      faq_withdraw_title_3: "¿Cuánto tiempo tarda en retirarse el dinero?",
      faq_withdraw_des_3:
        "Los retiros suelen tardar entre 5 y 15 minutos, pero no más de 24 horas.",
      faq_withdraw_title_4: "¿Hay una tarifa por retirar dinero?",
      faq_withdraw_des_4: "No se le cobrará una tarifa por retirar dinero.",
    },
    withdraw_info: {
      withdraw_warning_text:
        "Tenga en cuenta que si hay una orden de comercio abierta, puede retirar hasta el 90% del monto del margen libre restante, excluyendo créditos.",
      fee: "Tarifa",
      rate: "Tasa",
      warning_wallet:
        "Verifique dos veces los detalles de su retiro. Si encuentra un error, haga clic en Atrás y corrija sus detalles.",
      total_amount: "Cantidad Total",
      requested_date: "Fecha Solicitada",
      save: "Guardar",
      bank_detail: "Detalles del Banco",
      fund_detail: "Detalles del Fondo",
      local_amount: "Cantidad local",
      request_success: "Solicitud Exitosa",
      withdraw_processing:
        "Su retiro está en proceso, por favor espere entre 2-10 minutos",
      result: "Confirmación",
      warning_head:
        "Al retirar fondos, si hay posiciones activas, puede retirar el 70% del Equity, sin incluir créditos o bonos.",
      warning_bottom1:
        "Tenga en cuenta que su retiro dependerá del tipo de cambio del intermediario que utilice. Por favor, asegúrese de cuál tipo de cambio es el más adecuado para cada retiro.",
      warning_bottom2:
        "Y tenga en cuenta que su depósito y retiro deben estar a nombre del mismo titular que ha confirmado. Para evitar problemas de retiro que puedan ocurrir si la información no coincide con la registrada.",
      account: {
        name: "Nombre de la Cuenta",
        number: "Número de la Cuenta",
        bank: "Nombre del Banco",
        amount: "Cantidad",
        Code: "Ingrese el código recibido vía",
      },
      success: {
        title: "Éxito",
        description:
          "Hemos recibido su solicitud de retiro. Y será procesado dentro de las 24 horas. Tenga en cuenta que el monto solicitado ha sido deducido de su cuenta.",
        amount: "Retirar",
      },
      note: {
        title:
          "Nota: Tenga en cuenta al realizar un pedido comercial. Los retiros pueden retirarse del 70% del margen sin traer crédito.",
        description:
          "Recomendación: Si va a retirar fondos, todas las órdenes pendientes deben cerrarse primero.",
      },
      amount: "Cantidad",
      currency: "Moneda",
      wallet_address: "Dirección de la Cartera",
      total_amount: "Cantidad Total",
      bockchain_fee: "Tarifa de Blockchain",
      approximate: "Aproximado",
      crypto_warning:
        "Usted es responsable de la precisión del número de cuenta. Revise cuidadosamente los detalles de su retiro. Si encuentra un error, haga clic en 'Atrás' para corregirlo.",
      wallet_address_error: "La dirección de la cartera es incorrecta.",
      currency_rate: "Tasa de Cambio",
    },
    withdraw_step: {
      no_payment:
        "Disculpe, la opción de pago no está disponible temporalmente.",
      no_account:
        "No tiene cuenta MT5. por favor registre una cuenta MT5 antes de este proceso",
      first_step: {
        title: "Paso 1",
        description: "Seleccionar Cuenta",
        withdraw_with: "Retirar con",
        show_all_method: "Otro Método",
        minimun_withdraw: "Retiro Mínimo",
        withdraw_info:
          "Al retirar fondos, si hay posiciones activas, puede retirar el 70% del Equity, sin incluir crédito o bono.",
      },
      second_step: {
        title: "Paso 2",
        description: "Ingresar Cantidad",
        transection_method: "Método de Transacción",
      },
      third_step: {
        title: "Confirmación de Retiro",
        description: "Resultado",
      },
    },
    withdraw_error: {
      t_account: "Su cuenta no está seleccionada todavía.",
      t_amount: "la cantidad debe ser mayor que",
      t_condition: "No cumple con el requisito mínimo",
      t_error: "Saldo insuficiente",
      t_max: "Retiro máximo",
    },
    withdraw_success: {
      title: "Retiro exitoso",
      detail: "La cantidad se transferirá a su cuenta bancaria en 3-5 minutos",
      button: "Volver a la Cuenta",
    },
  },
};
