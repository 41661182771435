import React, { useEffect, useMemo, useState } from "react";
import { CaretUpOutlined, RiseOutlined } from "@ant-design/icons";
import Pusher from "pusher-js";
import { Link } from "react-router-dom";

const styleFontTop = {
  fontSize: 20,
};
const styleFontCurrent = {
  fontSize: 35,
};

const styleHeader = {
  backgroundColor: "#171717",
  padding: "0.4rem 0.4rem",
  color: "rgb(167 167 167)",
  fontWeight: "bold",
  fontFamily: "sans-serif",
  borderTopRightRadius: 12,
  borderTopLeftRadius: 12,
};

const styleBottom = {
  backgroundColor: "#171717",
  padding: "0.4rem 0.4rem",
  color: "rgb(167 167 167)",
  fontWeight: "bold",
  fontFamily: "sans-serif",
  borderBottomRightRadius: 12,
  borderBottomLeftRadius: 12,
};

const styleSpread = {
  background: "rgb(0 0 0 /38%)",
  borderRadius: 11,
  padding: "2px 14px",
  fontSize: 12,
};

const styleBuy = {
  background: "rgb(0 0 0 /38%)",
  borderRadius: 11,
  padding: "4px 14px",
  color: "rgb(29 184 148)",
  fontWeight: "bold",
  fontFamily: "sans-serif",
  textTransform: "uppercase",
  fontSize: 10,
  cursor: "pointer",
};

const styleSell = {
  background: "rgb(0 0 0 /38%)",
  borderRadius: 11,
  padding: "4px 14px",
  color: "rgb(252 103 95)",
  fontWeight: "bold",
  fontFamily: "sans-serif",
  textTransform: "uppercase",
  fontSize: 10,
  cursor: "pointer",
};

const colorBit = {
  color: "rgb(29 184 148)",
};

const colorAsk = {
  color: "rgb(252 103 95)",
};

const styleBackground = {
  background: "#171717",
};

const TextGray = {
  color: "rgb(162 161 161)",
};

const styleBitAsk = {
  fontFamily: "sans-serif",
  fontWeight: 700,
  color: "rgb(167 167 167)",
};

export default function MarketBox() {
  const [data, setData] = useState(null);

  useEffect(() => {
    const pusher = new Pusher("44f2900f2b03ee38357a", {
      cluster: "ap1",
      appId: 1158859,
      encrypted: true,
    });

    const channel = pusher.subscribe("quotes");
    channel.bind("update", function (data) {
      if (data.length > 10) {
        setData(data);
      }

      return data;
    });
  }, []);

  const bidPrice = (data) => {
    let bidarr = data.b.toFixed(data.d).toString().substr(-3);
    if (data.d <= 2) {
      bidarr = data.b.toFixed(data.d).toString().substr(-2);
    }
    let bidarrr = data.b.toFixed(data.d).replace(bidarr, "");
    return (
      <div
        className="d-flex align-items-baseline justify-center"
        style={colorBit}
      >
        <div style={styleFontTop}>{bidarrr}</div>
        <div className="d-flex">
          <span style={styleFontCurrent}>{bidarr.toString().substr(0, 2)}</span>
          <div style={{ fontSize: 20 }}>
            {data.d > 2 && bidarr.toString().substr(2, 3)}
          </div>
        </div>
      </div>
    );
  };
  const askPrice = (data) => {
    let askarr = data.a.toFixed(data.d).toString().substr(-3);
    if (data.d <= 2) {
      askarr = data.a.toFixed(data.d).toString().substr(-2);
    }
    let askarrr = data.a.toFixed(data.d).replace(askarr, "");
    return (
      <div
        className="d-flex align-items-baseline justify-center"
        style={colorAsk}
      >
        <div style={styleFontTop}>{askarrr}</div>
        <div className="d-flex">
          <span style={styleFontCurrent}>{askarr.toString().substr(0, 2)}</span>
          <div style={{ fontSize: 20 }}>
            {data.d > 2 && askarr.toString().substr(2, 3)}
          </div>
        </div>
      </div>
    );
  };

  const spreadPrice = (data) => {
    let spread = data.a - data.b;
    let a = Math.pow(10, data.d - 1);
    let final = spread * a;
    return (
      <div className="ml-2 text-right" style={{ width: 32 }}>
        {final.toFixed(1)}
      </div>
    );
  };
  const addDefaultSrc = (ev) => {
    ev.target.src = "/assets/images/icons/globe.svg";
  };

  return (
    <div className="styleMainBox">
      <div className="row">
        {data !== null ? (
          data
            .filter(
              (e, i) =>
                e?.s === "XAUUSD" ||
                e?.s === "EURUSD" ||
                e?.s === "BTCUSD" ||
                e?.s === "GBPUSD"
            )
            .map((item, index) => (
              <>
                <div
                  className="col-12 col-sm-6 col-xl-3 w-100 pt-2"
                  key={index}
                >
                  <div className="box-market-landingpage">
                    <div
                      className="d-flex justify-content-between "
                      style={styleHeader}
                    >
                      {" "}
                      <div className="d-flex align-items-center">
                        <img
                          onError={addDefaultSrc}
                          src={`${
                            process.env.REACT_APP_SYMBOL
                          }symbol/${item?.s.toUpperCase()}.png`}
                          width={30}
                          alt={item?.s}
                          style={{ marginRight: 8, borderRadius: "50%" }}
                        />
                        &nbsp;
                        {item?.s}
                      </div>
                      {/* <div className="p-2">{item.symbol.replace(".", "")}</div> */}
                    </div>
                    <div
                      className="d-flex justify-content-between p-2"
                      style={styleBackground}
                    >
                      <div className="w-100">
                        <div style={styleBitAsk}>Bid</div>
                        {bidPrice(item)}
                      </div>
                      <div className="w-100">
                        <div style={styleBitAsk} className="ml-2">
                          Ask
                        </div>
                        {askPrice(item)}
                      </div>
                    </div>
                    <div
                      className="d-flex justify-content-between"
                      style={styleBottom}
                    >
                      <div
                        className="text-white d-flex justify-content-between"
                        style={styleSpread}
                      >
                        <div className="text-gray" style={TextGray}>
                          Spread
                        </div>
                        {spreadPrice(item)}
                      </div>
                      <div className="d-flex">
                        <Link to={`/${localStorage.getItem("locale")}/login`}>
                          <div style={styleBuy}>Buy</div>
                        </Link>
                        <Link to={`/${localStorage.getItem("locale")}/login`}>
                          <div className="ml-1" style={styleSell}>
                            Sell
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))
        ) : (
          <>
            <div className="col-12 col-sm-6 col-xl-3 w-100 pt-2">
              <div className="pr-3-5 box-market-landingpage">
                <div
                  className="d-flex justify-content-between"
                  style={styleHeader}
                >
                  <div className="d-flex align-items-center">
                    <img
                      src={`https://ik.imagekit.io/awonar/public/images/markets/btcusd.png?tr=w-100`}
                      width={30}
                      alt={"BTCUSD"}
                      style={{ marginRight: 8 }}
                    />
                    &nbsp; BTCUSD
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between p-2"
                  style={styleBackground}
                >
                  <div className="w-100">
                    <div style={styleBitAsk}>Bid</div>
                    <div
                      className="d-flex align-items-baseline justify-center"
                      style={colorBit}
                    >
                      <div style={styleFontTop}>0000</div>
                      <div className="d-flex">
                        <span style={styleFontCurrent}>0</span>
                        <div style={{ fontSize: 20 }}>0</div>
                      </div>
                    </div>
                  </div>
                  <div className="w-100">
                    <div style={styleBitAsk} className="ml-2">
                      Ask
                    </div>
                    <div
                      className="d-flex align-items-baseline justify-center"
                      style={colorAsk}
                    >
                      <div style={styleFontTop}>0000</div>
                      <div className="d-flex">
                        <span style={styleFontCurrent}>00</span>
                        <div style={{ fontSize: 20 }}>0</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={styleBottom}
                >
                  <div
                    className="text-white d-flex justify-content-between"
                    style={styleSpread}
                  >
                    <div className="text-gray" style={TextGray}>
                      Spread
                    </div>
                    <div className="ml-2">0.0</div>
                  </div>
                  <div className="d-flex">
                    <Link to={`/${localStorage.getItem("locale")}/login`}>
                      <div style={styleBuy}>Buy</div>
                    </Link>{" "}
                    <Link to={`/${localStorage.getItem("locale")}/login`}>
                      <div className="ml-1" style={styleSell}>
                        Sell
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-3 w-100 pt-2">
              <div className="px-3-5 box-market-landingpage">
                <div
                  className="d-flex justify-content-between"
                  style={styleHeader}
                >
                  <div className="d-flex align-items-center">
                    <img
                      src={`https://ik.imagekit.io/awonar/public/images/markets/eurusd.png?tr=w-100`}
                      width={30}
                      alt={"EURUSD"}
                      style={{ borderRadius: "50%", marginRight: 8 }}
                    />
                    &nbsp; EURUSD
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between p-2"
                  style={styleBackground}
                >
                  <div className="w-100">
                    <div style={styleBitAsk}>Bid</div>
                    <div
                      className="d-flex align-items-baseline justify-center"
                      style={colorBit}
                    >
                      <div style={styleFontTop}>0000</div>
                      <div className="d-flex">
                        <span style={styleFontCurrent}>0</span>
                        <div style={{ fontSize: 20 }}>0</div>
                      </div>
                    </div>
                  </div>
                  <div className="w-100">
                    <div style={styleBitAsk} className="ml-2">
                      Ask
                    </div>
                    <div
                      className="d-flex align-items-baseline justify-center"
                      style={colorAsk}
                    >
                      <div style={styleFontTop}>0000</div>
                      <div className="d-flex">
                        <span style={styleFontCurrent}>00</span>
                        <div style={{ fontSize: 20 }}>0</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={styleBottom}
                >
                  <div
                    className="text-white d-flex justify-content-between"
                    style={styleSpread}
                  >
                    <div className="text-gray" style={TextGray}>
                      Spread
                    </div>
                    <div className="ml-2">0.0</div>
                  </div>
                  <div className="d-flex">
                    <Link to={`/${localStorage.getItem("locale")}/login`}>
                      <div style={styleBuy}>Buy</div>
                    </Link>
                    <Link to={`/${localStorage.getItem("locale")}/login`}>
                      <div className="ml-1" style={styleSell}>
                        Sell
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-3 w-100 pt-2">
              <div className="px-3-5 box-market-landingpage">
                <div
                  className="d-flex justify-content-between p-3"
                  style={styleHeader}
                >
                  <div className="d-flex align-items-center">
                    <img
                      src={`https://ik.imagekit.io/awonar/public/images/markets/xauusd.png`}
                      width={30}
                      alt={"XAUUSD"}
                      style={{ borderRadius: "50%", marginRight: 8 }}
                    />
                    &nbsp; XAUUSD
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between p-2"
                  style={styleBackground}
                >
                  <div className="w-100">
                    <div style={styleBitAsk}>Bid</div>
                    <div
                      className="d-flex align-items-baseline justify-center"
                      style={colorBit}
                    >
                      <div style={styleFontTop}>0000</div>
                      <div className="d-flex">
                        <span style={styleFontCurrent}>0</span>
                        <div style={{ fontSize: 20 }}>0</div>
                      </div>
                    </div>
                  </div>
                  <div className="w-100">
                    <div style={styleBitAsk} className="ml-2">
                      Ask
                    </div>
                    <div
                      className="d-flex align-items-baseline justify-center"
                      style={colorAsk}
                    >
                      <div style={styleFontTop}>0000</div>
                      <div className="d-flex">
                        <span style={styleFontCurrent}>00</span>
                        <div style={{ fontSize: 20 }}>0</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={styleBottom}
                >
                  <div
                    className="text-white d-flex justify-content-between"
                    style={styleSpread}
                  >
                    <div className="text-gray" style={TextGray}>
                      Spread
                    </div>
                    <div className="ml-2">0.0</div>
                  </div>
                  <div className="d-flex">
                    <Link to={`/${localStorage.getItem("locale")}/login`}>
                      <div style={styleBuy}>Buy</div>
                    </Link>
                    <Link to={`/${localStorage.getItem("locale")}/login`}>
                      <div className="ml-1" style={styleSell}>
                        Sell
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-3 w-100 pt-2">
              <div className="pl-3-5 box-market-landingpage">
                <div
                  className="d-flex justify-content-between"
                  style={styleHeader}
                >
                  <div className="d-flex align-items-center">
                    <img
                      src={`https://ik.imagekit.io/awonar/public/images/markets/gbpusd.png?tr=w-100`}
                      width={30}
                      alt={"GBPUSD"}
                      style={{ borderRadius: "50%", marginRight: 8 }}
                    />
                    &nbsp; GBPUSD
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between p-2"
                  style={styleBackground}
                >
                  <div className="w-100">
                    <div style={styleBitAsk}>Bid</div>
                    <div
                      className="d-flex align-items-baseline justify-center"
                      style={colorBit}
                    >
                      <div style={styleFontTop}>0000</div>
                      <div className="d-flex">
                        <span style={styleFontCurrent}>0</span>
                        <div style={{ fontSize: 20 }}>0</div>
                      </div>
                    </div>
                  </div>
                  <div className="w-100">
                    <div style={styleBitAsk} className="ml-2">
                      Ask
                    </div>
                    <div
                      className="d-flex align-items-baseline justify-center"
                      style={colorAsk}
                    >
                      <div style={styleFontTop}>0000</div>
                      <div className="d-flex">
                        <span style={styleFontCurrent}>00</span>
                        <div style={{ fontSize: 20 }}>0</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={styleBottom}
                >
                  <div
                    className="text-white d-flex justify-content-between"
                    style={styleSpread}
                  >
                    <div className="text-gray" style={TextGray}>
                      Spread
                    </div>
                    <div className="ml-2">0.0</div>
                  </div>
                  <div className="d-flex">
                    <Link to={`/${localStorage.getItem("locale")}/login`}>
                      <div style={styleBuy}>Buy</div>
                    </Link>
                    <Link to={`/${localStorage.getItem("locale")}/login`}>
                      <div className="ml-1" style={styleSell}>
                        Sell
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
