import React, { Component } from "react";
import { Row, Button } from "antd";
import { Link } from "react-router-dom";
import QueueAnim from "rc-queue-anim";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import SectionOne from "../component/SectionOne";
import SectionTwo from "../component/SectionTwo";
import SectionThree from "../component/SectionThree";
const TITLE = "CFDs - Market | IUX Markets";
const locale = require("react-redux-i18n").I18n;

export class Forexforex extends Component {
  render() {
    return (
      <QueueAnim>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>{" "}
        <SectionThree
          title={locale.t("forex_what_is_block1.iux_what_is_header")}
          box1={
            <div>
              <p className="text-left">
                {locale.t("forex_what_is_block1.iux_what_is_discription_1")}
              </p>
              {/* <br />
              <p className="text-left">
                {locale.t("forex_what_is_block1.iux_what_is_discription_2")}
              </p> */}
              <br />
              <p className="text-left">
                {locale.t("forex_what_is_block1.iux_what_is_discription_3")}
              </p>
              <br />
              {/* <p className="text-left">
                {locale.t("forex_what_is_block1.iux_what_is_discription_4")}
              </p>
              <br />
              <p className="text-left">
                {locale.t("forex_what_is_block1.iux_what_is_discription_5")}
              </p>
              <br />
              <p className="text-left">
                {locale.t("forex_what_is_block1.iux_what_is_discription_6")}
              </p> */}
            </div>
          }
          box2={
            <div>
              <p className="text-left">
                {locale.t("forex_what_is_block1.iux_what_is_discription_2")}
              </p>
              {/* <p className="text-left">
                {locale.t("forex_what_is_block2.iux_what_is_discription_1")}
              </p>
              <br />
              <p className="text-left">
                {locale.t("forex_what_is_block2.iux_what_is_discription_2")}
              </p>
              <br />
              <p className="text-left">
                {locale.t("forex_what_is_block2.iux_what_is_discription_3")}
              </p>
              <br />
              <p className="text-left">
                {locale.t("forex_what_is_block2.iux_what_is_discription_4")}
              </p>
              <br />
              <p className="text-left">
                {locale.t("forex_what_is_block2.iux_what_is_discription_5")}
              </p>
              <br />
              <p className="text-left">
                {locale.t("forex_what_is_block2.iux_what_is_discription_6")}
              </p>
              <br />
              <p className="text-left">
                {locale.t("forex_what_is_block2.iux_what_is_discription_7")}
              </p>
              <br />
              <p className="text-left">
                {locale.t("forex_what_is_block2.iux_what_is_discription_8")}
              </p>
              <br />
              <p className="text-left">
                {locale.t("forex_what_is_block2.iux_what_is_discription_9")}
              </p> */}
            </div>
          }
        />
        <SectionOne />
        <SectionTwo />
        {/* <section key={3} className="bg-black">
          <div className="container">
            <div className="col-md" style={{ padding: "5rem 0" }}>
              <div className="col-md panel__heading">
                <div className="text-center">
                  <h3>{locale.t("iux_award_trading.iux_header_award")}</h3>
                </div>
                <div style={{ padding: "1rem 0" }}>
                  <p className="text-left">
                    {locale.t("iux_award_trading.iux_title_award")}
                  </p>
                </div>
              </div>
              <Row className="d-flex mt-4">
                <div className="col-md-5">
                  <div className="mb-5">
                    <div className="col-md offset-md-1 content-column">
                      <h3>{locale.t("iux_award_trading.iux_platform")}</h3>
                      <b>
                        <p className="text-left">
                          {locale.t("iux_award_trading.iux_sub_platform")}
                        </p>
                      </b>
                    </div>
                  </div>
                  <div className="col-md offset-md-1">
                    <Link to={`/${localStorage.getItem("locale")}/register`}>
                      <Button className="btn-primary-iux">
                        <div>{locale.t("button.title_2")}</div>
                      </Button>
                    </Link>
                  </div>
                </div>
                <div className="col-md-6 offset-md-1">
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/market/platform.png"
                      }
                      alt="..."
                      className="iux-platform-img"
                    />
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </section> */}
      </QueueAnim>
    );
  }
}
const mapStateToProps = ({ i18n }) => ({
  locale: i18n.locale,
});
export default connect(mapStateToProps, null)(Forexforex);
