import React from "react";
import PropTypes from "prop-types";
import FooterLandingPage from "../../components/footer-landing-page/FooterDark";
import { Helmet } from "react-helmet";
import RcQueueAnim from "rc-queue-anim";
import TextWithCover from "../../components/text-with-cover/TextWithCover";
import WindowsPage from "./windows";
import AndroidPage from "./android";
import IphonePage from "./iphone";
import MetatraderPage from "./mt4";
import MacPage from "./mac";
import { Link, Route, Switch } from "react-router-dom";
import styled from "styled-components";

const TITLE = "Metatrader | IUX Markets";
const locale = require("react-redux-i18n").I18n;

const MenuItem = styled.div`
  padding: 0.8rem 1rem;
  color: ${(props) => props.color};
  border-bottom: ${(props) => props.borderBottom};
  white-space: pre;
`;

const PlatformsFive = () => {
  const pathName = window.location.pathname;
  const [isIdex, setIndex] = React.useState(0);

  const menu = [
    {
      link: `/${localStorage.getItem("locale")}/platforms/mt5`,
      text: "MT5 Windows",
    },
    {
      link: `/${localStorage.getItem("locale")}/platforms/mt5/android`,
      text: "MT5 Android",
    },
    {
      link: `/${localStorage.getItem("locale")}/platforms/mt5/iphone`,
      text: "MT5 iPhone / iPad",
    },
    {
      link: `/${localStorage.getItem("locale")}/platforms/mt5/mac`,
      text: "MT5 Mac",
    },
  ];

  const imgcover = [
    {
      title: "MT5 Windows",
      description: locale.t("cover.accover.description"),
      image: "/assets/images/covers/v2/cover-policy.png",
    },
    {
      title: "MT5 Android",
      description: locale.t("cover.accstd.description"),
      image: "/assets/images/covers/v2/cover-sefety.png",
    },
    {
      title: "MT5 iPhone / iPad",
      description: locale.t("cover.accbonus.description"),
      image: "/assets/images/covers/v2/cover-iphone.png",
    },
    {
      title: "Meta Trader 5",
      description: locale.t("cover.accecn.description"),
      image: "/assets/images/covers/v2/cover-mac.png",
    },
  ];

  React.useEffect(() => {
    const activeCover = () => {
      if (pathName === `/${localStorage.getItem("locale")}/platforms/mt5`) {
        setIndex(0);
      }
      if (
        pathName === `/${localStorage.getItem("locale")}/platforms/mt5/android`
      ) {
        setIndex(1);
      }
      if (
        pathName === `/${localStorage.getItem("locale")}/platforms/mt5/iphone`
      ) {
        setIndex(2);
      }
      if (pathName === `/${localStorage.getItem("locale")}/platforms/mt5/mac`) {
        setIndex(3);
      }
    };
    activeCover();
  }, [pathName]);

  return (
    <div>
      <RcQueueAnim delay={300}>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <div className="bg-black">
          <TextWithCover
            key={1}
            imagePath={process.env.PUBLIC_URL + imgcover[isIdex].image}
            title={imgcover[isIdex].title}
            content={
              <>
                <div>
                  <p className="text-left">{imgcover[isIdex].description}</p>
                  {/* {renderButtonCover()} */}
                </div>
              </>
            }
          />
          <div className="sub-menu-page">
            <div className="container">
              <div className="d-flex overflow-auto scroll-menu-hide">
                {menu.map((e, i) => (
                  <Link to={e.link}>
                    <MenuItem
                      onClick={() => setIndex(i)}
                      color={e.link === pathName ? "#FFF" : "#8D8D8D"}
                      borderBottom={
                        e.link === pathName
                          ? "4px solid #1CB894"
                          : "2px solid #ff000000"
                      }
                      fontWeight={e.link === pathName ? 400 : "unset"}
                    >
                      {e.text}
                    </MenuItem>
                  </Link>
                ))}
              </div>
            </div>
          </div>

          <Switch>
            <Route
              path={`/:lang/platforms/mt5/iphone`}
              render={() => {
                return <IphonePage />;
              }}
            />
            <Route
              path={`/:lang/platforms/mt5/android`}
              render={() => {
                return <AndroidPage />;
              }}
            />
            <Route
              path={`/:lang/platforms/mt5/mac`}
              render={() => {
                return <MacPage />;
              }}
            />
            <Route
              path={`/:lang/platforms/mt5`}
              render={() => {
                return <WindowsPage />;
              }}
            />
          </Switch>
          <div>
            <FooterLandingPage locale={locale} />
          </div>
        </div>
      </RcQueueAnim>
    </div>
  );
};

export default PlatformsFive;
