export default {
  th: {
    iux_forex_market: {
      title_1: "เวลาดำเนินการโดยเฉลี่ย < 0.001 ms",
      title_2: "มีคำสั่งที่ได้ดำเนินงานถึง 1,000 รายการต่อวินาที",
      title_3: "ศูนย์ข้อมูลมีเวลาความล่าช้าต่ำมาก \nที่มีตำแหน่งเดียวกัน",
    },
    why_iux_header: {
      title: "ทำไมจึงควรเทรดกับ IUX Markets",
      header_1: "มากกว่า 6 ปีแห่งความสำเร็จ",
      text_1:
        "เลือกโบรกเกอร์ที่น่าเชื่อถือที่ให้บริการลูกค้ามากกว่า 170 ประเทศ",
      header_2: " เทรดมากกว่า 250 ตราสาร ",
      text_2:
        "เทรดสัญญาซื้อขายส่วนต่างCFDs หุ้น ฟิวเจอร์ส สปอตดัชนี สปอตโลหะ สปอตพลังงาน",
      header_3: "4 แพลตฟอร์มเทรด",
      text_3: "เทรดในแพลตฟอร์มที่คุณชอบ ได้แก่   MT5",
      header_4: "การดำเนินการอย่างรวดเร็วและสภาพคล่องดี",
      text_4:
        "ได้รับผลประโยชน์จากการดำเนินการออกออเดอร์อย่างรวดเร็วภายใน < 0.001 ms",
      header_5: "มากกว่า 10 รางวัลทั้งในระดับนานาชาติและสหราชอาณาจักร",
      text_5:
        "เทรดกับโบรกเกอร์ที่ได้รับการยอมรับอย่างต่อเนื่องเกี่ยวกับคุณภาพของการบริการ",
      header_6: "การสนับสนุนมากกว่า 4 ภาษา",
      text_6: `ฝ่ายช่วยเหลือลูกค้า 24/7  ทีมช่วยเหลือลูกค้าของเราที่ทุ่มเทอย่างเต็มที่เพื่อให้บริการคุณ ด้วยภาษาต่างประเทศกว่า 4+ ภาษา`,
    },
    iux_award_trading: {
      iux_header_award: "รางวัลการดำเนินการ NDD",
      iux_title_award:
        "คำสั่งทั้งหมดจะถูกดำเนินการโดยไม่ระบุชื่อโดยไม่มีการแทรกแซงการประมวลผล (NDD) มีคำสั่งที่ได้ดำเนินงานถึง 2,000 คำสั่งต่อวินาที โดยมากกว่า 87% ของคำสั่งที่ดำเนินงานภายใน< 10ms",
      iux_platform: "แพลตฟอร์มการซื้อขาย",
      iux_sub_platform:
        "เราให้บริการลูกค้าด้วยแพลตฟอร์มการซื้อขายต่างๆบนเดสก์ท็อป เว็บไซต์ และโทรศัพท์มือถือ ประกอบด้วย MetaTrader 5",
    },
    forex_what_is_block1: {
      iux_what_is_header: "CFDsคืออะไร",
      iux_what_is_discription_1:
        "สัญญาล่วงหน้า (เรียกว่า CFDs หรือตลาด CFDs)  5 ล้านล้านดอลลาร์ ",
      iux_what_is_discription_2:
        "สัญญาล่วงหน้าที่กระจายอำนาจ  ธุรกรรมจึงได้ปฏิบัตินอกเคาน์เตอร์ (OTC) ",
      iux_what_is_discription_3:
        "เป็นมูลค่าการกล่าวขวัญว่าตลาด spot CFDs นั้นเป็นตลาด OTC ที่ถูกครอบงำโดยธนาคารระหว่างประเทศขนาดใหญ่",
      iux_what_is_discription_4:
        "ในการซื้อขายCFDsซ์ราคาสปอตของคู่สกุลเงินได้รับอิทธิพลจากปัจจัยหลายประการเช่นแนวโน้มเศรษฐกิจและเหตุการณ์ทางการเมืองในภูมิภาคนั้นรวมถึงการเผยแพร่ข้อมูลข่าวซึ่งตลาดอาจรับรู้ในเชิงบวกหรือเชิงลบ",
      iux_what_is_discription_5:
        "สัญญาสำหรับความแตกต่าง (CFDs) อนุญาตให้ผู้ค้าซื้อ (ยาว) หรือขาย (สั้น) และทำกำไรหรือขาดทุนจากการเคลื่อนไหวของราคาโดยไม่ต้องซื้อและแลกเปลี่ยนสกุลเงินพื้นฐาน",
      iux_what_is_discription_6: `CFDs มีการเสนอราคาเป็นคู่โดยแต่ละสกุลเงินเป็นตัวแทนของโลกหรือเศรษฐกิจ สกุลเงินแรกเรียกว่าสกุลเงิน 'ฐาน' (แสดงถึงปริมาณที่คุณต้องการซื้อขาย) และสกุลเงินที่สองเรียกว่าสกุลเงิน 'คำ' หรือ 'ใบเสนอราคา' (แสดงถึงอัตราแลกเปลี่ยนปัจจุบัน)`,
    },
    forex_what_is_block2: {
      iux_what_is_discription_1:
        "ตัวอย่างเช่นราคาของ EUR / USD แสดงจำนวน $ USD ที่สามารถแลกเปลี่ยนเป็น€ 1 ได้",
      iux_what_is_discription_2: "EUR/USD = 1.11361",
      iux_what_is_discription_3: `ซึ่งหมายความว่าปัจจุบัน€ 1 เท่ากับ $ 1.11361`,
      iux_what_is_discription_4: "ราคามีความผันผวนอย่างต่อเนื่องตามสภาพตลาด",
      iux_what_is_discription_5:
        "หากต้องการกล่าวง่ายๆผู้ค้าจะต้องใช้เวลานานหากพวกเขาเชื่อว่าสกุลเงินฐานจะเพิ่มมูลค่าเมื่อเทียบกับสกุลเงินในระยะและจะได้กำไรจากการเพิ่มขึ้นของราคา ในทางกลับกันหากผู้ค้าเชื่อว่ามูลค่าของสกุลเงินหลักจะลดลงเมื่อเทียบกับคำเหล่านั้นพวกเขาจะทำการค้าขายเพื่อพยายามทำกำไรจากราคาที่ลดลง หากราคาเคลื่อนไหวไปในทิศทางตรงกันข้ามกับการคาดการณ์ของเทรดเดอร์พวกเขาจะขาดทุน",
      iux_what_is_discription_6:
        "โดยทั่วไปการซื้อขายสกุลเงิน CFDs จะคำนวณเป็น PIPs ซึ่งหมายความว่าขึ้นอยู่กับขนาดการค้าของคุณแต่ละ PIPs จะเท่ากับมูลค่าตัวเงินของสกุลเงินคำว่า ค่า PIPs นี้ใช้เพื่อกำหนด PnL (กำไรหรือขาดทุน) ขึ้นอยู่กับจำนวน PIPs ที่คุณได้รับหรือขาดทุนในการซื้อขายและใช้เพื่อแสดง Spread (ความแตกต่างระหว่างการเสนอราคาและราคาถาม)",
      iux_what_is_discription_7: "\n",
      iux_what_is_discription_8: "\n",
      iux_what_is_discription_9: "\n",
    },
    futures_what_is_block1: {
      iux_what_is_header: "สัญญาซื้อขายล่วงหน้าคืออะไร",
      iux_what_is_discription_1:
        "สัญญาซื้อขายล่วงหน้าเป็นสัญญาทางการเงินระหว่างผู้ซื้อและผู้ขาย ซึ่งสินทรัพย์บางอย่างจะถูกซื้อหรือขายในราคาและวันที่ที่เจรจาไว้ในอนาคต ราคาที่เจรจาโดยสองฝ่ายเรียกว่าราคาเปลี่ยน วันที่ชำระเงินในอนาคตที่ระบุเรียกว่าวันที่จัดส่งสินค้า",
    },
    futures_what_is_block2: {
      iux_what_is_discription_1:
        "สัญญาซื้อขายล่วงหน้าให้นักลงทุนคาดการในการเคลื่อนไหวด้านราคาของสินค้าได้หลากหลายประเภท ตัวอย่างเช่น หากนักลงทุนเชื่อว่าราคาของผลิตภัณฑ์หนึ่ง ๆ จะเพิ่มขึ้น พวกเขาสามารถซื้อสัญญาซื้อขายล่วงหน้าได้ในราคาที่ต่ำกว่า และขายในวันถัดไป เมื่อผลิตภัณฑ์มีมูลค่าเพิ่มขึ้น",
    },
    indices_what_is_block1: {
      iux_what_is_header: "ดัชนีคืออะไร",
      iux_what_is_discription_1:
        "ดัชนี้เป็นมูลค่ามาตรฐานของประสิทธิภาพดำเนินงานตลาดแห่งหนึ่ง หรือบางพื้นที่ของเศรษฐกิจ ค่าของดัชนีแสดงถึงประสิทธิภาพของบริษัททั้งหมดที่รวมอยู่ในดัชนีนั้น และถูกคำนวณเป็นค่าถัวเฉลี่ยมีน้ำหนัก",
      iux_what_is_discription_2: "\n",
      iux_what_is_discription_3: "\n",
      iux_what_is_discription_4: "\n",
    },
    indices_what_is_block2: {
      iux_what_is_discription_1:
        "ดัชนีให้การคาดการณ์เกี่ยวกับตลาดที่เฉพาะเจาะจง ตรงกันข้ามกับหุ้นแต่ละราย ดังนั้นจึงเป็นที่นิยมมากสำหรับนักลงทุนที่กำลังมองหาตำแหน่งอยู่บนการเปลี่ยนแปลงในภาคเศรษฐกิจที่ถูกแสดงโดยดัชนี",
      iux_what_is_discription_2: "\n",
      iux_what_is_discription_3: "\n",
      iux_what_is_discription_4: "\n",
    },
    shares_what_is_block1: {
      iux_what_is_header: "หุ้นคืออะไร",
      iux_what_is_discription_1:
        "ซื้อขาย CFDs กับหุ้นให้คุณเลือกทั้งคำสั่งซื้อและขาย เพื่อให้สามารถได้รับประโยชน์จากราคาที่เพิ่มขึ้นหรือลดลงตามลำดับ หุ้นของเราสะท้อนให้เห็นถึงการกระทำของบริษัท ดังนั้นคุณจะได้รับเงินปันผลเมื่อคุณซื้อ และจะต้องเสียค่าใช้จ่ายในเงินปันผลที่ขาย",
    },
    shares_what_is_block2: {
      iux_what_is_discription_1:
        "การซื้อขายหุ้นเป็นวิธีลงทุนที่นิยมมากที่สุด ที่ IUX Markets เราให้บริการ CFDs หุ้นของบริษัทบางราย ที่มีมูลค่ามากที่สุดในโลก เช่น Apple, Coca Cola และ Facebook อย่างไรก็ตาม นักลงทุนควรศึกษาข้อมูลอย่างรอบคอบก่อนซื้อขาย",
    },
    metals_what_is_block1: {
      iux_what_is_header: "ซื้อขายโลหะกับ IUX Markets",
      iux_what_is_discription_1:
        "การซื้อขายสปอตโลหะช่วยให้นักลงทุนสามารถขยายรายการลงทุนของตนได้ โดยการตั้งคำสั่งซื้อหรือขายสปอตทองคำและเงิน เรารับประกันได้ว่าคำสั่งของคุณถูกดำเนินในราคาที่ถูกต้องและมีการแข่งขันมากที่สุด สปอตทองคำมีค่ามากกว่าสปอตเงิน แต่ทั้งสองมีความผันผวนมากด้านราคา",
    },
    metals_what_is_block2: {
      iux_what_is_discription_1:
        "การซื้อขายสปอตโลหะมักถูกมองว่าเป็นการลงทุนที่ปลอดภัย ที่หลบภัย โดยเฉพาะอย่างยิ่งในช่วงเวลาที่เศรษฐกิจไม่คงที่ คุณสามารถทำธุรกรรม Spot Metals ได้ บัญชี demo ฟรีก่อนการทำธุรกรรมในบัญชีจริงของคุณ",
    },
    energies_what_is_block1: {
      iux_what_is_header: "ซื้อขายสปอตพลังงานกับ IUX Markets",
      iux_what_is_discription_1:
        "เริ่มต้นซื้อขายสปอตพลังงานกับ IUX Markets เพื่อทำให้หลากหลายรายการลงทุนและใช้ประโยชน์จากโอกาสในธุรกรรมใหม่ ๆ สปอตพลังงานสามารถถูกซื้อขายได้ในทุกแพลตฟอร์มการซื้อขายของเรา ประกอบด้วย  MT5 ",
    },
    energies_what_is_block2: {
      iux_what_is_discription_1:
        "สปอตพลังงานเป็นทางเลือกที่นิยมใช้สำหรับธุรกรรมระยะสั้น โดยเฉพาะอย่างยิ่งในช่วงเวลาที่การใช้พลังงานเพิ่มขึ้นตามอัตราคูณทั่วโลก ฝึกการซื้อขายสปอตพลังงาน ในบัญชี demo ฟรีของเรา ก่อนการทำรายการซื้อขายในบัญชีจริง",
    },

    iux_forex_page: {
      title_1: "ซื้อขาย CFDs ",
      title_2:
        "ซื้อขาย CFDs มากกว่า 70 คู่กับผู้ให้บริการ CFDs อันดับ 1 ของโลก เพื่อให้ได้รับสิทธิประโยชน์จากสเปรดที่ต่ำและการดำเนินการตามคำสั่งที่รวดเร็ว",
    },
    iux_futures_page: {
      title_1: "ซื้อขาย CFDs ในฟิวเจอร์",
      title_2:
        "ซื้อขาย CFDs บนสัญญาซื้อขายล่วงหน้าจากทั่วโลก และสำรวจโอกาสการซื้อขายที่ไม่มีที่สิ้นสุด",
    },
    iux_indices_page: {
      title_1: "ซื้อขาย CFDs ในดัชนี",
      title_2:
        "ซื้อขาย CFDs ในดัชนีที่เป็นที่นิยมจากยุโรป เอเชียและทวีปอเมริกา",
    },
    iux_shares_page: {
      title_1: "ซื้อขาย CFDs ในหุ้น",
      title_2:
        "ซื้อขาย CFDs บนหุ้น มีมากกว่า 150 บริษัทระดับโลก เพื่อได้รับประโยชน์จากการดำเนินการอย่างรวดเร็ว และจ่ายเงินปันผลสำหรับคำสั่งซื้อขายระยะยาว",
    },
    iux_metals_page: {
      title_1: "ซื้อขาย CFDs บนโลหะ",
      title_2: "ซื้อขาย CFDs บนสปอตโลหะและมีโอกาสการซื้อขายใหม่ ๆ",
    },
    iux_energies_page: {
      title_1: "ซื้อขาย CFDs ในพลังงาน",
      title_2:
        "ซื้อขาย CFDs บนสปอตพลังงาน เช่น น้ำมัน Brent, WTI และก๊าซธรรมชาติ",
    },
    button: {
      title_1: "MT4 ",
      title_2: "เปิดบัญชีทดลอง",
      sub_button: {
        title_1: "เริ่มเทรดตอนนี้",
        title_2: "เปิดบัญชีทดลอง",
      },
    },
  },
  lo: {
    iux_forex_market: {
      title_1: "ເວລາປະຕິບັດໂດຍສະເລ່ຍ <0.001 ms",
      title_2: "ມີຄຳສັ່ງຊື້ຂາຍ ເຖິງ 1,000 ຄຳ ສັ່ງຕໍ່ວິນາທີ",
      title_3: "ສູນຂໍ້ມູນມີເວລາການຊັກຊ້າຕ່ຳ​ຫຼາຍ ນັ້ນມີ ຕຳ ແໜ່ງ ດຽວກັນ",
    },
    why_iux_header: {
      title: "ເປັນຫຍັງເລືອກ IUX Markets",
      header_1: "ຫຼາຍກວ່າ 6 ປີຂອງຄວາມ ສຳ ເລັດ",
      text_1: "เເລືອກ broker ເຊື່ອຖືໄດ້ໃຫ້ບໍລິການລູກຄ້າໃນຫຼາຍກ່ວາ 170 ປະເທດ",
      header_2: "ເທຣດຫລາຍກວ່າ 250",
      text_2: `ການຄ້າ ຫຸ້ນ CFDs Futures ດັດຊະນີ  ໂລຫະພະລັງງານ`,
      header_3: "4 ແພລຕຟອມການຄ້າ",
      text_3: "ການຄ້າໃນPlatform ທີ່ທ່ານມັກ ແມ່ນ  MT5",
      header_4: "ປະຕິບັດໄດ້ໄວແລະມີສະພາບຄ່ອງທີ່ດີ",
      text_4:
        "ໄດ້ຮັບຜົນປະໂຫຍດຈາກການຈັດຕັ້ງ ປະຕິບັດຄໍາສັ່ງຢ່າງໄວວາພາຍໃນ <0.001 ms.",
      header_5: "ຫຼາຍກ່ວາ 10 ລາງວັນລະດັບສາກົນແລະອັງກິດ",
      text_5:
        "ການຄ້າກັບນາຍ ໜ້າ ຜູ້ທີ່ຖືກຮັບຮູ້ເປັນປະ ຈຳ ເພື່ອຄຸນນະພາບຂອງການບໍລິການຂອງພວກເຂົາ",
      header_6: "ທີມງານສະໜັບສະໜູນລູກຄ້າຂອງພວກເຮົາ",
      text_6: `ມີຄວາມຕັ້ງໃຈທີ່ຈະບໍລິການ ໂດຍມີ ພາສາຕ່າງປະເທດຫລາຍກວ່າ 4 ພາສາ`,
    },
    iux_award_trading: {
      iux_header_award: "ລາງວັນການປະຕິບັດງານຂອງ NDD",
      iux_title_award:
        "ຄຳ ສັ່ງທັງ ໝົດ ແມ່ນ ດຳ ເນີນການໂດຍບໍ່ເປີດເຜີຍຊື່ໂດຍບໍ່ມີການແຊກແຊງການປຸງແຕ່ງ (NDD)",
      iux_platform: "ເລືອກPlatformການຊື້ຂາຍຂອງທ່ານ",
      iux_sub_platform:
        "ພວກເຮົາໃຫ້ລູກຄ້າຂອງພວກເຮົາມີPlatformການຊື້ຂາຍ ສຳ ລັບ desktop, website ແລະ mobile ລວມທັງ   MetaTrader 5.",
    },
    forex_what_is_block1: {
      iux_what_is_header: "CFDs ແມ່ນຫຍັງ?",
      iux_what_is_discription_1:
        "ຕະຫຼາດແລກປ່ຽນເງິນຕາຕ່າງປະເທດ 5 ພັນຕື້ໂດລາ (ທີ່ເອີ້ນກັນວ່າຕະຫຼາດ CFDs ຫລື CFDs)",
      iux_what_is_discription_2:
        "ຕະຫຼາດແລກປ່ຽນເງິນຕາຕ່າງປະເທດທີ່ມີການແບ່ງຂັ້ນຄຸ້ມຄອງ ການເຮັດທຸລະ ກຳ ແມ່ນ ດຳ ເນີນຢູ່ນອກວຽກງານຕ້ານການຄ້າ (OTC).",
      iux_what_is_discription_3:
        "ມັນເປັນມູນຄ່າທີ່ຈະກ່າວວ່າຕະຫຼາດ CFDs ຈຸດແມ່ນຕະຫຼາດ OTC ທີ່ປົກຄອງໂດຍທະນາຄານສາກົນຂະ ໜາດ ໃຫຍ່.",
      iux_what_is_discription_4: `ໃນການຊື້ຂາຍ CFDs, ລາຄາຈຸດຂອງຄູ່ເງິນສະກຸນເງິນແມ່ນໄດ້ຮັບອິດທິພົນຈາກຫຼາຍໆປັດໃຈເຊັ່ນ: ທ່າອ່ຽງດ້ານເສດຖະກິດແລະເຫດການການເມືອງໃນຂົງເຂດນັ້ນ, ພ້ອມທັງການເຜີຍແຜ່ຂໍ້ມູນຂ່າວສານທີ່ຕະຫຼາດອາດຈະຮັບຮູ້ໃນແງ່ດີຫລືດ້ານບວກ. ລົບ`,
      iux_what_is_discription_5:
        "ສັນຍາເພື່ອຄວາມແຕກຕ່າງ (CFDs) ອະນຸຍາດໃຫ້ພໍ່ຄ້າຊື້ (ຍາວ) ຫລືຂາຍ (ສັ້ນ) ແລະສ້າງ ກຳ ໄລຫລືຂາດທຶນຈາກການເຄື່ອນໄຫວລາຄາໂດຍບໍ່ ຈຳ ເປັນຕ້ອງຊື້ແລະແລກປ່ຽນສະກຸນເງິນກ້ອນ.",
      iux_what_is_discription_6: `CFDs ແມ່ນອ້າງອີງເປັນຄູ່ກັບແຕ່ລະເງີນທີ່ເປັນຕົວແທນໃຫ້ແກ່ໂລກຫລືເສດຖະກິດ. ທຳ ອິດແມ່ນສະກຸນເງິນ 'ຖານ' (ສະແດງປະລິມານທີ່ທ່ານຕ້ອງການຊື້ຂາຍ) ແລະອັນທີສອງແມ່ນສະກຸນເງິນ 'ຄຳ' ຫລື 'ອ້າງອີງ' (ສະແດງເຖິງອັດຕາແລກປ່ຽນໃນປະຈຸບັນ).`,
    },
    forex_what_is_block2: {
      iux_what_is_discription_1:
        "ຕົວຢ່າງ ລາຄາຂອງ EUR / USD ສະແດງ ຈຳ ນວນເງິນໂດລາສະຫະລັດທີ່ສາມາດແລກປ່ຽນໄດ້ ສຳ ລັບ€ 1.",
      iux_what_is_discription_2: "EUR/USD = 1.11361",
      iux_what_is_discription_3: `ນີ້ຫມາຍຄວາມວ່າ€ 1 ປະຈຸບັນແມ່ນ $ 1,11361.`,
      iux_what_is_discription_4:
        "ລາຄາ ເໜັງ ຕີງຢ່າງຕໍ່ເນື່ອງຕາມເງື່ອນໄຂຂອງຕະຫຼາດ",
      iux_what_is_discription_5:
        " ເພື່ອເວົ້າງ່າຍໆ, ພໍ່ຄ້າຈະໃຊ້ເວລາດົນນານຖ້າພວກເຂົາເຊື່ອວ່າສະກຸນເງິນຖານຈະເພີ່ມມູນຄ່າທຽບໃສ່ສະກຸນເງິນ ຄຳ ແລະຈະໄດ້ ກຳ ໄລຈາກການເພີ່ມລາຄາ.",
      iux_what_is_discription_6: "\n",
      iux_what_is_discription_7: "\n",
      iux_what_is_discription_8: "\n",
      iux_what_is_discription_9: "\n",
    },
    futures_what_is_block1: {
      iux_what_is_header: "Futures ແມ່ນຫຍັງ?",
      iux_what_is_discription_1:
        "Futures ແມ່ນສັນຍາທາງດ້ານການເງິນສໍາລັບຊັບສິນສະເພາະທີ່ຈະຊື້ຫຼືຂາຍໃນເວລາທີ່ກໍານົດໄວ້ໃນອະນາຄົດ. ລະດັບລາຄາສຸດທ້າຍແມ່ນຖືກກໍານົດໂດຍທັງສອງຝ່າຍ, ຜູ້ຊື້ແລະຜູ້ຂາຍ, ແລະເປັນທີ່ຮູ້ຈັກເປັນລາຄາຕໍ່ຫນ້າ. ວັນທີທີ່ລະບຸຂອງການຈ່າຍເງິນໃນອະນາຄົດແມ່ນເອີ້ນວ່າວັນຫມົດອາຍຸ.",
    },
    futures_what_is_block2: {
      iux_what_is_discription_1:
        "ສັນຍາສໍາລັບຄວາມແຕກຕ່າງ (CFDs) ກ່ຽວກັບ futures ອະນຸຍາດໃຫ້ການຄ້າກ່ຽວກັບການເຫນັງຕີງຂອງລາຄາຂອງຊັບສິນທີ່ຫລາກຫລາຍ: ສິນຄ້າ, ດັດຊະນີແລະພະລັງງານ. ຖ້ານັກລົງທຶນເຮັດໃຫ້ການຄາດຄະເນກ່ຽວກັບວົງຢືມເພີ່ມຂຶ້ນ, ພວກເຂົາເປີດຕໍາແຫນ່ງຊື້ໃນເວທີການຄ້າ. ຖ້າພວກເຂົາຄິດວ່າຕາຕະລາງຈະເລື່ອນລົງ, ພວກເຂົາເຮັດຄໍາສັ່ງອອນໄລນ໌ເພື່ອຂາຍ. ເລີ່ມການຊື້ຂາຍອະນາຄົດກັບ IUX Markets ເພື່ອສ້າງຄວາມຫຼາກຫຼາຍຂອງບັນຊີນັກລົງທຶນຂອງທ່ານ!",
    },
    indices_what_is_block1: {
      iux_what_is_header: "Indices ແມ່ນຫຍັງ?",
      iux_what_is_discription_1:
        "indices ຂອງໂລກແມ່ນ indices ຂອງການປ່ຽນແປງລາຄາ ສຳ ລັບກຸ່ມຫຼັກຊັບທີ່ແນ່ນອນ. ດັດຊະນີຫຼັກຊັບສາມາດອະທິບາຍໄດ້ວ່າເປັນ“ ກະຕ່າ” ຂອງຮຸ້ນທີ່ເປັນເອກະພາບກັນໂດຍທົ່ວໄປ.indices ຊື້ຂາຍສາມາດຖືກປຽບທຽບກັບການເປີດ ຕຳ ແໜ່ງ ໃນຫຼັກສູດຂອງຫຸ້ນຫຼາຍໆ ໝື່ນ ບາດໃນເວລາດຽວກັນ.",
      iux_what_is_discription_2: `ສິ່ງທີ່ ສຳ ຄັນທີ່ສຸດແມ່ນການ ກຳ ນົດຮຸ້ນຫລືພັນທະບັດທີ່ແນ່ນອນແຕ່ລະດັດຊະນີຖືກສ້າງຕັ້ງຂື້ນມາຈາກ. ຊຸດຂອງຮຸ້ນທີ່ລວມຢູ່ໃນການຄິດໄລ່ມູນຄ່າດັດສະນີຈຸດ ກຳ ນົດຂໍ້ມູນທີ່ສາມາດໄດ້ຮັບໂດຍການສັງເກດນະໂຍບາຍດ້ານວິຊາການຂອງມັນ`,
      iux_what_is_discription_3:
        "ໂດຍທົ່ວໄປ, ຈຸດປະສົງຕົ້ນຕໍຂອງຕົວຊີ້ວັດໂລກແມ່ນເພື່ອສ້າງຕົວຊີ້ວັດທີ່ມີປະສິດທິພາບໃຫ້ແກ່ນັກລົງທືນເພື່ອສະແດງທິດທາງຂອງ ຄຳ ເວົ້າຂອງບໍລິສັດໃນອຸດສະຫະ ກຳ ໃດ ໜຶ່ງ. ການສຶກສາແບບເຄື່ອນໄຫວຂອງຕົວຊີ້ວັດທີ່ ສຳ ຄັນຊ່ວຍໃຫ້ເຂົ້າໃຈຜົນກະທົບຂອງເຫດການບາງຢ່າງຕໍ່ກັບຄຸນຄ່າຂອງຫຼັກຊັບ.",
      iux_what_is_discription_4:
        "ໃນລະຫວ່າງindicesການຊື້ຂາຍ, ຈົ່ງຈື່ໄວ້ວ່າປະຕິກິລິຍາຕໍ່ຂ່າວເສດຖະກິດທີ່ຖືກເຜີຍແຜ່ອາດຈະບໍ່ກົງກັບຄວາມຄາດຫວັງແລະການຄາດຄະເນ. ",
    },
    indices_what_is_block2: {
      iux_what_is_discription_1:
        "ຕົວຢ່າງ:ຖ້າມີການຂຶ້ນລາຄານ້ ຳ ມັນ, ມັນເປັນການສົມເຫດສົມຜົນທີ່ຄາດວ່າຈະມີການເພີ່ມຂື້ນຂອງຮຸ້ນຂອງບໍລິສັດນ້ ຳ ມັນທັງ ໝົດ.",
      iux_what_is_discription_2:
        "ເຖິງຢ່າງໃດກໍ່ຕາມ, ຮຸ້ນທີ່ແຕກຕ່າງກັນເຕີບໃຫຍ່ໃນຄວາມໄວທີ່ແຕກຕ່າງກັນ, ໃນຂະນະທີ່ບາງສ່ວນຂອງພວກມັນອາດຈະບໍ່ຕອບສະ ໜອງ ຕໍ່ຂ່າວດັ່ງກ່າວເລີຍ. ໃນກໍລະນີນີ້, ດັດສະນີຈຸດຊ່ວຍໃຫ້ພໍ່ຄ້າເຂົ້າໃຈແນວໂນ້ມລວມຂອງສ່ວນແບ່ງຕະຫລາດນີ້ໂດຍບໍ່ ຈຳ ເປັນຕ້ອງປະເມີນ ຕຳ ແໜ່ງ ຂອງຫລາຍໆບໍລິສັດທີ່ແຕກຕ່າງກັນ",
      iux_what_is_discription_3:
        "ຕົວຊີ້ວັດການສັງເກດແລະການຊື້ຂາຍໃຫ້ຄວາມເຂົ້າໃຈກ່ຽວກັບວິທີການທີ່ຂະ ແໜງ ການຕ່າງໆຂອງເສດຖະກິດປຽບທຽບກັບກັນແລະກັນ. ຢູ່ທີ່ IUX Markets ພວກເຮົາມີຄວາມຍິນດີທີ່ຈະສະ ເໜີ ການຊື້ຂາຍ CFDs ຕາມຕົວຊີ້ບອກທີ່ ສຳ ຄັນເຊິ່ງເຮັດໃຫ້ມັນສາມາດເຂົ້າຮ່ວມການເຄື່ອນໄຫວຂອງລາຄາບໍ່ພຽງແຕ່ເປັນການເພີ່ມຂື້ນເທົ່ານັ້ນແຕ່ຍັງເປັນການຫຼຸດລົງ",
      iux_what_is_discription_4:
        "ຕົວຊີ້ວັດການຊື້ຂາຍແມ່ນເປັນທີ່ນິຍົມຂອງບັນດາພໍ່ຄ້  IUX Markets ເນື່ອງຈາກມີເງື່ອນໄຂຄົບຖ້ວນ, ຄຳ ເວົ້າທີ່ຖືກຕ້ອງຈາກຜູ້ສະ ໜອງ ແລະການວິເຄາະທີ່ຫຼາກຫຼາຍ. ຫຼັງຈາກທີ່ທັງ ໝົດ, ເພື່ອໃຫ້ເຂົ້າໃຈເຫດຜົນຂອງພຶດຕິ ກຳ ດັດສະນີ, ທ່ານ ຈຳ ເປັນຕ້ອງເອົາໃຈໃສ່ຂ່າວສານຂອງບໍລິສັດຂອງແຕ່ລະບໍລິສັດລວມທັງເຫດການທີ່ມີຜົນກະທົບຕໍ່ອຸດສາຫະ ກຳ ທີ່ກວ້າງຂວາງກວ່າເກົ່າ",
    },
    shares_what_is_block1: {
      iux_what_is_header: "ຮຸ້ນແມ່ນຫຍັງ?",
      iux_what_is_discription_1:
        "ການຄ້າ CFDs ກັບຫຸ້ນ ສຳ ລັບທ່ານເພື່ອເລືອກຊື້ແລະຂາຍ. ເພື່ອໃຫ້ສາມາດໄດ້ຮັບຜົນປະໂຫຍດຈາກການເພີ່ມລາຄາຫຼືຫຼຸດລົງຕາມ ລຳ ດັບ, ຮຸ້ນຂອງພວກເຮົາສະທ້ອນໃຫ້ເຫັນເຖິງການກະ ທຳ ຂອງບໍລິສັດ. ດັ່ງນັ້ນທ່ານຈະໄດ້ຮັບເງິນປັນຜົນເມື່ອທ່ານຊື້. ແລະຈະຕ້ອງຈ່າຍເງິນປັນຜົນທີ່ຂາຍອອກ",
    },
    shares_what_is_block2: {
      iux_what_is_discription_1:
        "ການຊື້ຂາຍຫຸ້ນແມ່ນວິທີການລົງທືນທີ່ນິຍົມທີ່ສຸດ. ຢູ່ IUX Markets ພວກເຮົາສະ ໜອງ CFDs ສຳ ລັບຫຸ້ນຂອງບໍລິສັດ ຈຳ ນວນ ໜຶ່ງ. ສິ່ງທີ່ມີຄ່າທີ່ສຸດໃນໂລກ, ເຊັ່ນວ່າ Apple, Coca Cola ແລະ Facebook. ເຖິງຢ່າງໃດກໍ່ຕາມ, ນັກລົງທືນຄວນສຶກສາຂໍ້ມູນຢ່າງລະມັດລະວັງກ່ອນການຊື້ຂາຍ",
    },
    metals_what_is_block1: {
      iux_what_is_header: "ໂລຫະແມ່ນຫຍັງ?",
      iux_what_is_discription_1:
        "ການຊື້ຂາຍຈຸດໂລຫະເຮັດໃຫ້ນັກລົງທືນຂະຫຍາຍລາຍການລົງທືນ. ໂດຍການວາງ ຄຳ ສັ່ງຊື້ຫຼືຂາຍ ຄຳ ແລະເງິນ ຄຳ ພວກເຮົາຮັບປະກັນວ່າ ຄຳ ສັ່ງຂອງທ່ານຖືກປະຕິບັດໃນລາຄາທີ່ຖືກຕ້ອງແລະແຂ່ງຂັນທີ່ສຸດ. ຈຸດ ຄຳ ແມ່ນມີຄ່າຫລາຍກ່ວາຈຸດເງິນ. ແຕ່ທັງສອງແມ່ນມີການ ເໜັງ ຕີງຫຼາຍໃນລາຄາ",
    },
    metals_what_is_block2: {
      iux_what_is_discription_1:
        "ການຊື້ຂາຍຈຸດໂລຫະມັກຈະເຫັນວ່າເປັນການລົງທືນທີ່ປອດໄພ, ເປັນບ່ອນທີ່ປອດໄພ, ໂດຍສະເພາະໃນຊ່ວງເວລາທີ່ຄວາມບໍ່ແນ່ນອນດ້ານເສດຖະກິດ. ທ່ານສາມາດເຮັດທຸລະ ກຳ Spot Metals ສຳ ລັບບັນຊີສາທິດຟຣີກ່ອນການເຮັດທຸລະ ກຳ ໃນບັນຊີຈິງຂອງທ່ານ.",
    },
    energies_what_is_block1: {
      iux_what_is_header: "ພະລັງງານແມ່ນຫຍັງ?",
      iux_what_is_discription_1:
        "ເລີ່ມຕົ້ນການຊື້ຂາຍຈຸດພະລັງງານກັບ IUX Markets ເພື່ອເຮັດການລົງທືນທີ່ຫລາກຫລາຍແລະໃຊ້ໂອກາດທາງທຸລະກິດ ໃໝ່. ຈຸດໄຟຟ້າສາມາດຊື້ຂາຍໄດ້ໃນທຸກໆແພລະຕະຟອມການຄ້າຂອງພວກເຮົາ, ລວມທັງ MT5, cTrader ແລະ IUX Markets.",
    },
    energies_what_is_block2: {
      iux_what_is_discription_1:
        "ພະລັງງານແມ່ນທາງເລືອກທີ່ນິຍົມ ສຳ ລັບການເຮັດທຸລະ ກຳ ໄລຍະສັ້ນ. ໂດຍສະເພາະໃນຊ່ວງເວລາທີ່ການ ນຳ ໃຊ້ພະລັງງານເພີ່ມຂື້ນໃນລະດັບຄູນນະພາບທົ່ວໂລກປະຕິບັດການຊື້ຂາຍຈຸດແຂງໃນບັນຊີສາທິດຟຣີຂອງພວກເຮົາກ່ອນການຊື້ຂາຍໃນບັນຊີຈິງ",
    },

    iux_forex_page: {
      title_1: "ການຊື້ຂາຍ CFDs ",
      title_2: "ການຊື້ຂາຍ CFDs ໃນ 70+ຄູ່ CFDs ກັບ broker ເບີ 1 ຂອງໂລກ",
    },
    iux_futures_page: {
      title_1: "ການຊື້ຂາຍ Futures",
      title_2:
        "ການຄ້າ CFDs ໃນອະນາຄົດຈາກທົ່ວໂລກແລະຄົ້ນຫາໂອກາດການຄ້າທີ່ບໍ່ມີທີ່ສິ້ນສຸດ",
    },
    iux_indices_page: {
      title_1: " ການຊື້ຂາຍ Indices",
      title_2:
        "ການຄ້າ CFDs ໃນເຄື່ອງ ໝາຍ ທີ່ໄດ້ຮັບຄວາມນິຍົມຈາກທົ່ວເອີຣົບ, ອາຊີແລະອາເມລິກາ",
    },
    iux_shares_page: {
      title_1: "ການຊື້ຂາຍຮຸ້ນແລະກອງທິນ",
      title_2:
        "ການຄ້າ CFDs ໃນຮຸ້ນຂອງ 150+ ບໍລິສັດທົ່ວໂລກແລະໄດ້ຮັບຜົນປະໂຫຍດຈາກການປະຕິບັດ ຄຳ ສັ່ງທີ່ໄວແລະການຈ່າຍເງິນປັນຜົນໃນ ຕຳ ແໜ່ງ ຍາວ",
    },
    iux_metals_page: {
      title_1: "ການຊື້ຂາຍໂລຫະ",
      title_2: "ການຄ້າ CFDs ໃນ Spot Metals ແລະເປີດໂອກາດການຄ້າ ໃໝ່",
    },
    iux_energies_page: {
      title_1: "ການຄ້າ CFDs ກ່ຽວກັບພະລັງງານ",
      title_2:
        "ການຄ້າ CFDs oTrade CFDs ກ່ຽວກັບຈຸດແຂງເຊັ່ນ: ນ້ ຳ ມັນ Brent, WTI ແລະແກັສທຳ ມະຊາດແລະຄວາມຫຼາກຫຼາຍຂອງ portfolio Energies ຂອງທ່ານ",
    },
    button: {
      title_1: "MT4 ",
      title_2: "ເປີດບັນຊີສາທິດ",
      sub_button: {
        title_1: "เริ่มเทรดตอนนี้",
        title_2: "ເປີດບັນຊີສາທິດ",
      },
    },
  },
  en: {
    iux_forex_market: {
      title_1: `Average execution time < 0.001 ms`,
      title_2: "Up to 1,000 orders executed per second",
      title_3: "Ultra-low latency datacentre \nco-location",
    },
    why_iux_header: {
      title: "Why trade with IUX Markets",
      header_1: "6 Years of Excellence ",
      text_1:
        "Choose a trusted broker that serves clients in over 170 countries. ",
      header_2: "Trade 250+ Instruments ",
      text_2:
        "Trade CFDs, Shares, Futures, Spot Indices, Spot Metals and Spot Energies.",
      header_3: "4 Trading Platforms",
      text_3: "Trade on your preferred platform including IUX Markets MT5",
      header_4: "Fast Execution & Deep Liquidity",
      text_4:
        " Benefit from ultra-fast order execution with most orders executed in under 11.06ms.",
      header_5: "10+ UK & International Awards",
      text_5:
        "Trade with a broker that has been repeatedly recognized for the quality of its services.",
      header_6: "24/7 Multilingual Support",
      text_6:
        "Reap the benefits of our 24/7 Customer Support, providing assistance in more than 4 languages.",
    },
    iux_award_trading: {
      iux_header_award: "Award-Winning NDD Execution",
      iux_title_award:
        "All client trades are executed with No Dealing Desk1 intervention. Most trades are filled in under 10 milliseconds., with up to 2,000 trades executed per second.",
      iux_platform: "Trading platform",
      iux_sub_platform:
        "Available to trade in all platforms; desktop, website, mobile trading platform and MetaTrader5",
    },
    forex_what_is_block1: {
      iux_what_is_header: "What are CFDs ?",
      iux_what_is_discription_1:
        "Contract for difference (CFDs) as a whole, consists of many types of markets, including Spot CFDs, Future derivatives, Forward Derivatives, and finally the CFDs derivatives market, which is the most popular for retail clients. All CFDs Trading transactions combined make up the largest and most liquid financial market, with an average daily volume of over $5 trillion.",
      iux_what_is_discription_2:
        "The CFDs derivatives market is made up of buyers and sellers, the main participants being large international banks, who place orders via electronic trading systems. This market is traded OTC (not traded on any regulated exchange) and as such there is no uniform price but each of the main international banks is providing its own quotes with the spot market acting as the point of reference for the quotes provided.",
      iux_what_is_discription_3:
        "It is worth mentioning that the spot CFDs market is also an OTC market dominated by the large international banks.",
      iux_what_is_discription_4:
        "In CFDs Trading, spot price of a currency pair is influenced by several factors, such as the economic outlook and geopolitical events in that region, as well as news data releases which may be perceived positively or negatively by the market.",
      iux_what_is_discription_5:
        "Contracts for difference (CFDs), allow traders to buy (go long) or sell (go short), and make profit or loss from price movements, without having to physically purchase and exchange the underlying currency.",
      iux_what_is_discription_6:
        "CFDs is quoted in pairs, with each representing a global currency or economy. The first currency is called the ‘base’ currency (representing the volume you wish to trade) and the second is called the ‘term’ or ‘quote’ currency (representing the current exchange rate).",
    },
    forex_what_is_block2: {
      iux_what_is_discription_1:
        "For example, the price of EUR/USD represents the amount of $USD that can be exchanged for €1.",
      iux_what_is_discription_2: "EUR/USD = 1.11361",
      iux_what_is_discription_3:
        "This means that currently, €1 is equal to $1.11361",
      iux_what_is_discription_4:
        "Prices are constantly fluctuating based on market conditions.",
      iux_what_is_discription_5:
        "To put it simply, traders would go long if they believe that the base currency will rise in value against the term currency and would profit from an increase in price. On the other hand, if traders’ believe that the value of the base currency will fall in relation to the term, they will place a sell trade to try to profit from falling prices. If prices move in the opposite direction to the traders’ forecast, they will make a loss.",
      iux_what_is_discription_6:
        "CFDs currency trading is typically calculated in PIPs, meaning that depending on your trade size, each PIPs is equal to a specific monetary value of the ‘term’ currency. This PIPs value is used to determine the PnL (profit or loss), based on how many PIPs you gain or lose in a trade, and is also used to display spread (the difference between the bid and ask prices).",
      iux_what_is_discription_7:
        "At IUX Markets we quote all CFDs pairs to an extra digit after the PIPs, meaning that the last digit in any quote refers to a Point (10% of a PIPs).",
      iux_what_is_discription_8:
        "In CFDs currency trading, fractional pricing allows us to offer tighter spreads and provide more accurate pricing.",
      iux_what_is_discription_9:
        "If you are new to online CFDs Trading, we would recommend going through our online educational section to familiarize yourself with the market and how ‘Contracts for Difference’ trading works. We also provide ‘watch and learn’ videos and PDF guides.",
    },
    futures_what_is_block1: {
      iux_what_is_header: "What is Futures?",
      iux_what_is_discription_1:
        "Futures are financial contracts for the specific asset to be bought or sold at a set time in the future. The final price level is determined by both parties, a buyer and a seller, and is known as the forward price. The specified date of the future payment is known as the expiration date.",
    },
    futures_what_is_block2: {
      iux_what_is_discription_1:
        "Contract for difference (CFDs) on futures allow for trade on the price fluctuations of a wide range of assets: commodities, indices and energies. If investors make a forecast about the quotes increase, they open a Buy position on the trading platform. If they think the chart will move down, they make an online order to Sell. Start trading futures with IUX Markets to diversify your investor account!",
    },
    indices_what_is_block1: {
      iux_what_is_header: "What is Indices?",
      iux_what_is_discription_1: `World indices are indicators of price changes for a certain group of securities. The stock exchange index can be explained as a “basket” of shares united by a common basis. Trading indices can be compared to opening positions on the courses of several dozen stocks at once.`,
      iux_what_is_discription_2:
        "The most important thing is determining the exact stocks or bonds each index is formed from. The set of shares included in the spot index value calculation determines the information that can be obtained by observing the dynamics of its course. \n ",
      iux_what_is_discription_3:
        "In general, the main purpose of world indices is to create a powerful indicator for investors to characterise the direction of companies’ quotes in a particular industry. Studying the dynamics of major indices helps to understand the impact of certain events on the value of securities. \n ",
      iux_what_is_discription_4:
        "During trading indices, keep in mind that the reaction to the economic news published may not correspond with expectations and forecasts.\n",
    },
    indices_what_is_block2: {
      iux_what_is_discription_1:
        "The index gives a forecast about a specific market. In contrast to individual stocks Therefore, it is very popular for investors who are looking for a position on the changes in the sector of the economy that are represented by the index.",
      iux_what_is_discription_2:
        "However, different stocks grow at different speeds, while some of them may not respond to such news at all. In this case, the spot index helps traders to understand the overall trend of this market segment without the need to assess the position of lots of different companies.",
      iux_what_is_discription_3:
        "Observation and trading indices give insights into how the different sectors of the economy trade in comparison with each other. Here at IUX Markets we are glad to offer the trading of CFDs on major indices, which makes it possible to join the price movement not only for a rise, but also a fall.",
      iux_what_is_discription_4:
        "Trading indices is popular among IUX Markets traders due to its comprehensive terms, accurate quotes from several suppliers and versatile analytics. After all, in order to understand the logic of the index behaviour, you need to pay attention to the corporate news of each of the companies included, as well as on events affecting the wider industry as a whole.",
    },

    shares_what_is_block1: {
      iux_what_is_header: "What are shares ?",
      iux_what_is_discription_1:
        "Trade CFDs with stocks for you to choose to buy and sell. In order to be able to benefit from the price increase or decrease respectively Our shares reflect the actions of the company. So you will receive dividends when you buy. And will have to pay for the dividends sold",
    },
    shares_what_is_block2: {
      iux_what_is_discription_1: `"Trading stocks is one of the most popular investment methods. At IUX Markets, We offer some companies share CFDs. There are some of the most valuable in the world such as Apple, Coca Cola and Facebook. However, investors should study the information carefully before trading.`,
      iux_what_is_discription_2: "",
    },
    metals_what_is_block1: {
      iux_what_is_header: "Trade metals with IUX Markets",
      iux_what_is_discription_1: `Spot metal trading allows investors to expand their portfolio, By placing orders to buy or sell spot gold and silver.\n We guarantee that your order is executed at the most accurate and competitive prices. Spot gold is more valuable than spot silver but both have great price fluctuations.`,
    },
    metals_what_is_block2: {
      iux_what_is_discription_1:
        "Spot metal trading is often viewed as a safe haven investment, especially during times of unstable economy. You can do Spot Metals transactions for a our free demo account before placing any trades on a real account.",
    },
    energies_what_is_block1: {
      iux_what_is_header: "Trade spot energy with IUX Markets",
      iux_what_is_discription_1: `Start trading spot energy with IUX Markets to diversify your investments and take advantage of new trading opportunities.\n
            Spot energy can be traded on all of our trading platforms including  MT5.`,
    },
    energies_what_is_block2: {
      iux_what_is_discription_1:
        "Energy spots are a popular choice for short-term transactions, Especially at a time when energy use increases with the global multiplication rate. Practice trading Spot Energies on our free demo account before placing any trades on a real account.",
    },
    iux_forex_page: {
      title_1: "CFDs Trading",
      title_2:
        "Trade CFDs on 70+ FX pairs and benefit from tight spread and fast order execution.",
    },
    iux_futures_page: {
      title_1: "Futures trading",
      title_2:
        "Trade CFDs on Futures from around the world and explore endless trading opportunities",
    },
    iux_indices_page: {
      title_1: "Spot indices trading",
      title_2:
        "Trade CFDs on popular Indices from across Europe, Asia and America.",
    },
    iux_shares_page: {
      title_1: "Shares & Stocks trading",
      title_2:
        "Trade CFDs on the Shares of 150+ global companies and benefit from fast order execution and dividend payments on long positions.",
    },
    iux_metals_page: {
      title_1: "Precious metals trading",
      title_2: "Trade CFDs on Spot Metals and unmask new trading opportunities",
    },
    iux_energies_page: {
      title_1: "Trade CFDs on Energies",
      title_2:
        "Trade CFDs on Spot Energies such as Brent oil, WTI and Natural Gas and diversify your portfolio.",
    },
    button: {
      title_1: "MT4 ",
      title_2: "Demo Account",
      sub_button: {
        title_1: "START TRADING NOW",
        title_2: "Demo Account",
      },
    },
  },
  hi: {
    iux_forex_market: {
      title_1: `Average execution time < 0.001 ms`,
      title_2: "Up to 1,000 orders executed per second",
      title_3: "Ultra-low latency datacentre \nco-location",
    },
    why_iux_header: {
      title: "Why trade with IUX Markets",
      header_1: "6 Years of Excellence ",
      text_1:
        "Choose a trusted broker that serves clients in over 170 countries. ",
      header_2: "Trade 250+ Instruments ",
      text_2:
        "Trade CFDs, Shares, Futures, Spot Indices, Spot Metals and Spot Energies.",
      header_3: "4 Trading Platforms",
      text_3: "Trade on your preferred platform including IUX Markets MT5",
      header_4: "Fast Execution & Deep Liquidity",
      text_4:
        " Benefit from ultra-fast order execution with most orders executed in under 11.06ms.",
      header_5: "10+ UK & International Awards",
      text_5:
        "Trade with a broker that has been repeatedly recognized for the quality of its services.",
      header_6: "24/7 Multilingual Support",
      text_6:
        "Reap the benefits of our 24/7 Customer Support, providing assistance in more than 4 languages.",
    },
    iux_award_trading: {
      iux_header_award: "Award-Winning NDD Execution",
      iux_title_award:
        "All client trades are executed with No Dealing Desk1 intervention. Most trades are filled in under 10 milliseconds., with up to 2,000 trades executed per second.",
      iux_platform: "Trading platform",
      iux_sub_platform:
        "Available to trade in all platforms; desktop, website, mobile trading platform and MetaTrader5",
    },
    forex_what_is_block1: {
      iux_what_is_header: "What are CFDs ?",
      iux_what_is_discription_1:
        "Contract for difference (CFDs) as a whole, consists of many types of markets, including Spot CFDs, Future derivatives, Forward Derivatives, and finally the CFDs derivatives market, which is the most popular for retail clients. All CFDs Trading transactions combined make up the largest and most liquid financial market, with an average daily volume of over $5 trillion.",
      iux_what_is_discription_2:
        "The CFDs derivatives market is made up of buyers and sellers, the main participants being large international banks, who place orders via electronic trading systems. This market is traded OTC (not traded on any regulated exchange) and as such there is no uniform price but each of the main international banks is providing its own quotes with the spot market acting as the point of reference for the quotes provided.",
      iux_what_is_discription_3:
        "It is worth mentioning that the spot CFDs market is also an OTC market dominated by the large international banks.",
      iux_what_is_discription_4:
        "In CFDs Trading, spot price of a currency pair is influenced by several factors, such as the economic outlook and geopolitical events in that region, as well as news data releases which may be perceived positively or negatively by the market.",
      iux_what_is_discription_5:
        "Contracts for difference (CFDs), allow traders to buy (go long) or sell (go short), and make profit or loss from price movements, without having to physically purchase and exchange the underlying currency.",
      iux_what_is_discription_6:
        "CFDs is quoted in pairs, with each representing a global currency or economy. The first currency is called the ‘base’ currency (representing the volume you wish to trade) and the second is called the ‘term’ or ‘quote’ currency (representing the current exchange rate).",
    },
    forex_what_is_block2: {
      iux_what_is_discription_1:
        "For example, the price of EUR/USD represents the amount of $USD that can be exchanged for €1.",
      iux_what_is_discription_2: "EUR/USD = 1.11361",
      iux_what_is_discription_3:
        "This means that currently, €1 is equal to $1.11361",
      iux_what_is_discription_4:
        "Prices are constantly fluctuating based on market conditions.",
      iux_what_is_discription_5:
        "To put it simply, traders would go long if they believe that the base currency will rise in value against the term currency and would profit from an increase in price. On the other hand, if traders’ believe that the value of the base currency will fall in relation to the term, they will place a sell trade to try to profit from falling prices. If prices move in the opposite direction to the traders’ forecast, they will make a loss.",
      iux_what_is_discription_6:
        "CFDs currency trading is typically calculated in PIPs, meaning that depending on your trade size, each PIPs is equal to a specific monetary value of the ‘term’ currency. This PIPs value is used to determine the PnL (profit or loss), based on how many PIPs you gain or lose in a trade, and is also used to display spread (the difference between the bid and ask prices).",
      iux_what_is_discription_7:
        "At IUX Markets we quote all CFDs pairs to an extra digit after the PIPs, meaning that the last digit in any quote refers to a Point (10% of a PIPs).",
      iux_what_is_discription_8:
        "In CFDs currency trading, fractional pricing allows us to offer tighter spreads and provide more accurate pricing.",
      iux_what_is_discription_9:
        "If you are new to online CFDs Trading, we would recommend going through our online educational section to familiarize yourself with the market and how ‘Contracts for Difference’ trading works. We also provide ‘watch and learn’ videos and PDF guides.",
    },
    futures_what_is_block1: {
      iux_what_is_header: "What is Futures?",
      iux_what_is_discription_1:
        "Futures are financial contracts for the specific asset to be bought or sold at a set time in the future. The final price level is determined by both parties, a buyer and a seller, and is known as the forward price. The specified date of the future payment is known as the expiration date.",
    },
    futures_what_is_block2: {
      iux_what_is_discription_1:
        "Contract for difference (CFDs) on futures allow for trade on the price fluctuations of a wide range of assets: commodities, indices and energies. If investors make a forecast about the quotes increase, they open a Buy position on the trading platform. If they think the chart will move down, they make an online order to Sell. Start trading futures with IUX Markets to diversify your investor account!",
    },
    indices_what_is_block1: {
      iux_what_is_header: "What is Indices?",
      iux_what_is_discription_1: `World indices are indicators of price changes for a certain group of securities. The stock exchange index can be explained as a “basket” of shares united by a common basis. Trading indices can be compared to opening positions on the courses of several dozen stocks at once.`,
      iux_what_is_discription_2:
        "The most important thing is determining the exact stocks or bonds each index is formed from. The set of shares included in the spot index value calculation determines the information that can be obtained by observing the dynamics of its course. \n ",
      iux_what_is_discription_3:
        "In general, the main purpose of world indices is to create a powerful indicator for investors to characterise the direction of companies’ quotes in a particular industry. Studying the dynamics of major indices helps to understand the impact of certain events on the value of securities. \n ",
      iux_what_is_discription_4:
        "During trading indices, keep in mind that the reaction to the economic news published may not correspond with expectations and forecasts.\n",
    },
    indices_what_is_block2: {
      iux_what_is_discription_1:
        "The index gives a forecast about a specific market. In contrast to individual stocks Therefore, it is very popular for investors who are looking for a position on the changes in the sector of the economy that are represented by the index.",
      iux_what_is_discription_2:
        "However, different stocks grow at different speeds, while some of them may not respond to such news at all. In this case, the spot index helps traders to understand the overall trend of this market segment without the need to assess the position of lots of different companies.",
      iux_what_is_discription_3:
        "Observation and trading indices give insights into how the different sectors of the economy trade in comparison with each other. Here at IUX Markets we are glad to offer the trading of CFDs on major indices, which makes it possible to join the price movement not only for a rise, but also a fall.",
      iux_what_is_discription_4:
        "Trading indices is popular among IUX Markets traders due to its comprehensive terms, accurate quotes from several suppliers and versatile analytics. After all, in order to understand the logic of the index behaviour, you need to pay attention to the corporate news of each of the companies included, as well as on events affecting the wider industry as a whole.",
    },

    shares_what_is_block1: {
      iux_what_is_header: "What are shares ?",
      iux_what_is_discription_1:
        "Trade CFDs with stocks for you to choose to buy and sell. In order to be able to benefit from the price increase or decrease respectively Our shares reflect the actions of the company. So you will receive dividends when you buy. And will have to pay for the dividends sold",
    },
    shares_what_is_block2: {
      iux_what_is_discription_1: `"Trading stocks is one of the most popular investment methods. At IUX Markets, We offer some companies share CFDs. There are some of the most valuable in the world such as Apple, Coca Cola and Facebook. However, investors should study the information carefully before trading.`,
      iux_what_is_discription_2: "",
    },
    metals_what_is_block1: {
      iux_what_is_header: "Trade metals with IUX Markets",
      iux_what_is_discription_1: `Spot metal trading allows investors to expand their portfolio, By placing orders to buy or sell spot gold and silver.\n We guarantee that your order is executed at the most accurate and competitive prices. Spot gold is more valuable than spot silver but both have great price fluctuations.`,
    },
    metals_what_is_block2: {
      iux_what_is_discription_1:
        "Spot metal trading is often viewed as a safe haven investment, especially during times of unstable economy. You can do Spot Metals transactions for a our free demo account before placing any trades on a real account.",
    },
    energies_what_is_block1: {
      iux_what_is_header: "Trade spot energy with IUX Markets",
      iux_what_is_discription_1: `Start trading spot energy with IUX Markets to diversify your investments and take advantage of new trading opportunities.\n
            Spot energy can be traded on all of our trading platforms including  MT5.`,
    },
    energies_what_is_block2: {
      iux_what_is_discription_1:
        "Energy spots are a popular choice for short-term transactions, Especially at a time when energy use increases with the global multiplication rate. Practice trading Spot Energies on our free demo account before placing any trades on a real account.",
    },
    iux_forex_page: {
      title_1: "CFDs Trading",
      title_2:
        "Trade CFDs on 70+ FX pairs and benefit from tight spread and fast order execution.",
    },
    iux_futures_page: {
      title_1: "Futures trading",
      title_2:
        "Trade CFDs on Futures from around the world and explore endless trading opportunities",
    },
    iux_indices_page: {
      title_1: "Spot indices trading",
      title_2:
        "Trade CFDs on popular Indices from across Europe, Asia and America.",
    },
    iux_shares_page: {
      title_1: "Shares & Stocks trading",
      title_2:
        "Trade CFDs on the Shares of 150+ global companies and benefit from fast order execution and dividend payments on long positions.",
    },
    iux_metals_page: {
      title_1: "Precious metals trading",
      title_2: "Trade CFDs on Spot Metals and unmask new trading opportunities",
    },
    iux_energies_page: {
      title_1: "Trade CFDs on Energies",
      title_2:
        "Trade CFDs on Spot Energies such as Brent oil, WTI and Natural Gas and diversify your portfolio.",
    },
    button: {
      title_1: "MT4 ",
      title_2: "Demo Account",
      sub_button: {
        title_1: "START TRADING NOW",
        title_2: "Demo Account",
      },
    },
  },
  ms: {
    iux_forex_market: {
      title_1: `Purata masa pelaksanaan <0.001 ms`,
      title_2: `Pesanan dilaksanakan  sehingga 1000 senarai sesaat`,
      title_3: `Pusat data mempunyai kependaman yang sangat rendah, dengan kedudukan yang sama`,
    },
    button: {
      title_1: `MT4 `,
      title_2: `Akaun demo`,
      sub_button: {
        title_1: `MULA TRADING SEKARANG`,
        title_2: `Akaun demo`,
      },
    },
    why_iux_header: {
      title: `Mengapa anda memilih perdagangan dengan IUX Markets`,
      header_1: `6 Tahun Kecemerlangan`,
      text_1: `Pilih broker yang dipercayai yang menyajikan pelanggan di lebih 170 buah negara.`,
      header_2: `Perdagangan 250+ Instruments`,
      text_2: `Perdagangan CFDs , Saham, Futures, Spot Indeks, Spot Metals dan Spot Energies`,
      header_3: `4 Platform Dagangan`,
      text_3: `Berdagang pada platform kegemaran anda, MT5`,
      header_4: `Pelaksanaan yang cepat dan tahap kecairan yang baik`,
      text_4: ` Mendapat manfaat daripada pelaksanaan pesanan ultra-cepat dengan masa di bawah 0.001 ms`,
      header_5: `Lebih daripada 10 anugerah antarabangsa dan UK`,
      text_5: `Berdagang dengan broker yang diiktiraf secara konsisten terhadap kualiti perkhidmatan mereka.`,
      header_6: `Menyokong lebih daripada 4 bahasa`,
      text_6: `Rebut faedah Sokongan Pelanggan 24/7 kami, menyediakan bantuan dalam lebih daripada 4 bahasa.`,
    },
    iux_award_trading: {
      iux_header_award: `Memenangi Anugerah Pelaksanaan NDD `,
      iux_title_award: `Semua pesanan dilaksanakan tanpa menyatakan nama dan campur tangan pemprosesan (NDD). Sehingga 2000 pesanan dilaksanakan sesaat, dengan lebih daripada 87% pesanan dilaksanakan dalam masa < 10ms.`,
      iux_platform: `platform dagangan`,
      iux_sub_platform: `Kami menyediakan pelanggan kami pelbagai platform dagangan pada desktop, website dan telepon termasuk MetaTrader 5`,
    },
    forex_what_is_block1: {
      iux_what_is_header: `Apakah CFDs?`,
      iux_what_is_discription_1: `Pasaran pertukaran asing (dipanggil CFDs atau pasaran CFDs) $5 trilion.`,
      iux_what_is_discription_2: `Pasaran pertukaran asing terdesentralisasi. Oleh itu, transaksi dilakukan di luar kaunter (OTC).`,
      iux_what_is_discription_3: `Perlu dinyatakan bahawa pasaran spot CFDs ialah pasaran OTC yang dikuasai oleh bank antarabangsa bersaiz besar.`,
      iux_what_is_discription_4: `Dalam perdagangan CFDs, harga spot bagi pasangan mata wang dipengaruhi oleh beberapa faktor seperti arah aliran ekonomi dan peristiwa politik serantau serta siaran berita yang mungkin dilihat oleh pasaran secara positif atau negatif.`,
      iux_what_is_discription_5: `Kontrak untuk Perbezaan (CFDs) membenarkan peniaga membeli (panjang) atau menjual (singkat) dan membuat untung atau rugi pada pergerakan harga tanpa perlu membeli dan membuat pertukaran mata wang asas.`,
      iux_what_is_discription_6: `CFDs ditawar secara berpasangan, setiap mata wang mewakili dunia atau ekonomi. Mata wang pertama dikenali sebagai mata wang 'asas' (mewakili jumlah yang anda ingin berdagang) dan mata wang kedua dikenali sebagai mata wang 'perkataan' atau 'sebut harga' (mewakili kadar pertukaran semasa).`,
    },
    forex_what_is_block2: {
      iux_what_is_discription_1: `Sebagai contoh, harga EUR / USD mewakili jumlah $ USD yang boleh ditukar dengan € 1.`,
      iux_what_is_discription_2: `EUR / USD = 1,11361`,
      iux_what_is_discription_3: `Ini bermakna bahawa pada masa ini, € 1 adalah sama dengan $ 1,11361`,
      iux_what_is_discription_4: `Harga sentiasa turun naik mengikut keadaan pasaran.`,
      iux_what_is_discription_5: `Ringkasnya, peniaga akan menghabiskan masa yang lama untuk mempercayai bahawa mata wang asas akan meningkatkan nilainya berbanding mata wang dalam tempoh dan akan mendapat keuntungan daripada kenaikan harga. Sebaliknya, jika peniaga percaya bahawa nilai mata wang asas akan jatuh apabila dibandingkan dengan syarat tersebut, mereka akan berdagang dalam percubaan untuk mendapat keuntungan daripada kejatuhan harga. Jika harga bergerak dalam arah yang bertentangan dengan ramalan pedagang, mereka akan mengalami kerugian.`,
      iux_what_is_discription_6: `Dagangan mata wang CFDs biasanya dikira dalam PIPs. Bermakna bergantung pada saiz dagangan anda, setiap PIPs bersamaan dengan nilai monetari mata wang. Nilai PIPs ini digunakan untuk menentukan PnL (untung atau rugi) berdasarkan jumlah PIPs yang anda peroleh atau hilang dalam perdagangan dan gunakannya untuk menunjukkan spread (perbezaan antara harga bida dan permintaan)`,
      iux_what_is_discription_7: `Pada IUX Markets kami sebutkan semua pasangan CFDs kepada digit tambahan selepas PIPs, yang bermaksud bahawa angka terakhir dalam mana-mana sebut harga merujuk kepada Point (10% daripada PIPs).`,
      iux_what_is_discription_8: `Dalam CFDs perdagangan mata wang, harga pecahan membolehkan kami untuk menawarkan spread yang lebih ketat dan memberikan harga yang lebih tepat.`,
      iux_what_is_discription_9: `Jika anda baru dalam perdagangan CFDs secara online, kami akan bantu mengesyorkan melalui seksyen pendidikan dalam talian kami untuk membiasakan diri dengan pasaran dan belajar bagaimana proses 'kontrak untuk perbezaan' berlaku. Kami juga menyediakan video 'tonton dan belajar' dan panduan PDF.`,
    },
    futures_what_is_block1: {
      iux_what_is_header: `Apakah perdagangan niaga hadapan?`,
      iux_what_is_discription_1: `Kontrak Awal ialah kontrak kewangan antara pembeli dan penjual. Di mana aset tertentu akan dibeli atau dijual pada harga dan tarikh rundingan masa hadapan. Harga yang dirundingkan oleh kedua-dua pihak dipanggil harga perubahan. Tarikh pembayaran masa hadapan yang ditentukan dikenali sebagai tarikh penghantaran.`,
    },
    futures_what_is_block2: {
      iux_what_is_discription_1: `Kontrak niaga awal membolehkan pelabur meramalkan pergerakan harga pelbagai jenis komoditi. Contohnya, jika pelabur percaya bahawa harga produk tertentu akan meningkat, mereka boleh membeli kontrak niaga hadapan pada harga yang lebih rendah dan dijual keesokan harinya apabila produk meningkat nilainya.`,
    },
    indices_what_is_block1: {
      iux_what_is_header: `Apakah Indeks?`,
      iux_what_is_discription_1: `Indeks menyediakan ramalan tentang pasaran tertentu. Berbeza dengan saham individu. Oleh itu, ia sangat popular untuk pelabur yang mencari kedudukan mengenai perubahan dalam sektor ekonomi yang diwakili oleh indeks.`,
      iux_what_is_discription_2: `Perkara yang paling penting adalah menentukan saham tepat atau bon setiap indeks terbentuk daripada Set saham termasuk dalam pengiraan tempat nilai indeks menentukan maklumat yang boleh diperolehi dengan memerhatikan dinamik kursus.`,
      iux_what_is_discription_3: `Secara umum, tujuan utama indeks dunia adalah untuk mewujudkan satu petunjuk yang kuat kepada pelabur untuk mencirikan arah sebut harga syarikat dalam industri tertentu. Mengkaji dinamik indeks utama membantu untuk memahami kesan peristiwa tertentu pada nilai sekuriti.`,
      iux_what_is_discription_4: `Semasa indeks perdagangan, perlu diingat bahawa tindak balas kepada berita ekonomi yang dikeluarkan mungkin tidak sesuai dengan harapan dan ramalan.`,
    },
    indices_what_is_block2: {
      iux_what_is_discription_1: `Sebagai contoh, jika terdapat kenaikan harga minyak, ia adalah logik untuk menjangkakan kenaikan dalam saham semua syarikat-syarikat minyak.`,
      iux_what_is_discription_2: `Walau bagaimanapun, saham yang berbeza berkembang pada kelajuan yang berbeza, manakala sebahagian daripada mereka tidak boleh bertindak balas kepada berita itu sama sekali. Dalam kes ini, indeks tempat kejadian membantu peniaga untuk memahami trend keseluruhan segmen pasaran ini tanpa perlu menilai kedudukan banyak syarikat yang berbeza.`,
      iux_what_is_discription_3: `Pemerhatian dan perdagangan indeks memberikan wawasan ke dalam sektor perdagangan ekonomi berbanding dengan satu sama lain. Di sini, di IUX Markets kami gembira dapat menawarkan perdagangan CFDs pada indeks utama, yang menjadikan ia mungkin untuk menyertai pergerakan harga bukan sahaja untuk kenaikan, tetapi juga jatuh.`,
      iux_what_is_discription_4: `Berdagang indeks adalah popular di kalangan peniaga IUX Markets kerana syarat-syarat yang menyeluruh, sebut harga yang tepat daripada beberapa pembekal dan serba boleh analisis. Lagipun, untuk memahami logik tingkah laku indeks, anda perlu memberi perhatian kepada berita korporat setiap syarikat termasuk peristiwa yang menjejaskan industri yang lebih luas secara keseluruhan.`,
    },

    shares_what_is_block1: {
      iux_what_is_header: `Apakah Saham?`,
      iux_what_is_discription_1: `Perdagangan CFDs dengan saham memberi anda pilihan membeli dan menjual. Dalam usaha untuk mendapat manfaat daripada kenaikan atau penurunan harga secara tertib. Masing-masing saham mencerminkan tindakan syarikat. Jadi, anda akan menerima dividen apabila anda membeli dan akan dikenakan yuran kepad dividen yang dijual.`,
    },
    shares_what_is_block2: {
      iux_what_is_discription_1: `Perdagangan saham adalah kaedah pelaburan yang paling popular. Pada IUX Markets, kami menyediakan CFDs untuk saham syarikat lain yang paling berharga di dunia, seperti Apple, Coca Cola dan Facebook. Walau bagaimanapun, pelabur perlu mengkaji maklumat dengan teliti sebelum berdagang.`,
      // iux_what_is_discription_2: ''
    },
    metals_what_is_block1: {
      iux_what_is_header: `Apakah dagangan spot logam?`,
      iux_what_is_discription_1: `Dagangan spot logam membolehkan pelabur untuk mengembangkan item pelaburan mereka. Dengan meletakkan suatu perintah untuk membeli atau menjual emas dan bintik perak. Kami menjamin bahawa pesanan anda dilaksanakan dengan harga yang paling tepat dan berdaya saing. Spot emas bernilai lebih daripada spot perak tetapi kedua-duanya tidak menentu dalam harga`,
    },
    metals_what_is_block2: {
      iux_what_is_discription_1: `Perdagangan spot logam sering dilihat sebagai pelaburan dan tempat yang selamat terutamanya pada masa ekonomi tidak menentu. Anda boleh melakukan transaksi Spot logam untuk akaun demo percuma sebelum urus niaga dalam akaun sebenar anda.`,
    },
    energies_what_is_block1: {
      iux_what_is_header: `Berdagang Spot Tenaga dengan IUX Markets`,
      iux_what_is_discription_1: `Mula berdagang spot tenaga dengan IUX Markets untuk mempelbagaikan pelaburan anda dan memanfaatkan peluang transaksi baharu. Spot tenaga boleh didagangkan merentasi semua platform dagangan kami, termasuk MT5.`,
    },
    energies_what_is_block2: {
      iux_what_is_discription_1: `Spot tenaga ialah pilihan popular untuk urus niaga jangka pendek. Ini adalah benar terutamanya semasa penggunaan tenaga meningkat dengan kadar mendarab di seluruh dunia. Belajar melakukan perdagangan Spot Tenaga pada akaun demo percuma kami sebelum berdagang pada akaun sebenar.`,
    },
    iux_forex_page: {
      title_1: `Perdagangan CFDs`,
      title_2: `Perdagangan CFDs pada 70 + pasangan FX dan mendapat manfaat daripada spread yang ketat dan pelaksanaan yang pantas.`,
    },
    iux_futures_page: {
      title_1: `Perdagangan niaga hadapan`,
      title_2: `Perdagangan CFDs pada niaga hadapan dari seluruh dunia dan meneroka peluang perniagaan yang tidak berkesudahan`,
    },
    iux_indices_page: {
      title_1: `Perdagangan CFDs pada Indeks`,
      title_2: `Perdagangan CFDs pada Indeks popular dari seluruh Eropah, Asia dan Amerika.`,
    },
    iux_shares_page: {
      title_1: `Perdagangan CFDs pada Saham`,
      title_2: `Perdagangan CFDs pada Saham memiliki 150 + syarikat global dan mendapat manfaat daripada pesanan segera dan pembayaran dividen dalam tempoh masa panjang .`,
    },
    iux_metals_page: {
      title_1: `Perdagangan CFDs pada logam `,
      title_2: `Berdagang CFDs pada spot logam dan dapatkan peluang dagangan baharu`,
    },
    iux_energies_page: {
      title_1: `Perdagangan CFDs pada tenaga`,
      title_2: `Perdagangan CFDs pada Spot tenaga seperti minyak Brent, WTI dan Gas Asli.`,
    },
  },
  id: {
    iux_forex_market: {
      title_1: `Rata-rata waktu eksekusi <0,001 ms`,
      title_2: `Hingga 1.000 order dieksekusi per detik`,
      title_3: `Ultra-rendah latency pusat data \nco-location`,
    },
    button: {
      title_1: `MT4 `,
      title_2: `Akun demo`,
      sub_button: {
        title_1: `MULAI TRADING SEKARANG`,
        title_2: `Akun demo`,
      },
    },
    why_iux_header: {
      title: `Mengapa perdagangan dengan IUX Markets`,
      header_1: `6 Tahun Excellence`,
      text_1: `Memilih broker terpercaya yang melayani klien di lebih dari 170 negara.`,
      header_2: `Perdagangan 250 + Instrumen`,
      text_2: `Perdagangan CFDs, Saham, Futures, Spot Indices, Spot Logam dan Spot Energi`,
      header_3: `4 Platform Perdagangan`,
      text_3: `Perdagangan pada platform pilihan Anda termasuk IUX Markets  MT5`,
      header_4: `Cepat Eksekusi & Jauh Likuiditas`,
      text_4: ` Manfaat dari ultra-cepat eksekusi order dengan sebagian besar perintah dieksekusi di bawah 11.06ms`,
      header_5: `70 + UK & Internasional Choice`,
      text_5: ` Perdagangan dengan broker yang telah berulang kali diakui untuk kualitas layanan.`,
      header_6: `24/7 Dukungan multibahasa`,
      text_6: `Menuai keuntungan dari Support 24/7 Pelanggan kami, memberikan bantuan lebih dari 4 bahasa.`,
    },
    iux_award_trading: {
      iux_header_award: `Pemenang Penghargaan NDD Eksekusi`,
      iux_title_award: `Semua perdagangan klien dijalankan dengan No Dealing Desk1 intervensi. Kebanyakan perdagangan yang diisi di bawah 10 milidetik., Sampai dengan 2.000 transaksi dieksekusi per detik.`,
      iux_platform: `Beberapa pendanaan dan penarikan pilihan`,
      iux_sub_platform: `Kami menyediakan klien kami dengan berbagai desktop, website dan platform mobile trading termasuk   MetaTrader 5`,
    },
    forex_what_is_block1: {
      iux_what_is_header: `Apa itu CFDs?`,
      iux_what_is_discription_1: `Pasar valuta asing (FX) secara keseluruhan, terdiri dari banyak jenis pasar, termasuk Spot FX, derivatif Future, Derivatif Maju, dan akhirnya CFDs pasar derivatif, yang merupakan yang paling populer untuk nasabah ritel. Semua transaksi CFDs Trading dikombinasikan make up pasar keuangan terbesar dan paling likuid, dengan volume harian rata-rata lebih dari $ 5 triliun.`,
      iux_what_is_discription_2: `FX CFDs pasar derivatif terdiri dari pembeli dan penjual, para peserta utama menjadi bank internasional besar, yang menempatkan pesanan melalui sistem perdagangan elektronik. Pasar ini diperdagangkan OTC (tidak diperdagangkan di bursa diatur) dan dengan demikian tidak ada harga seragam tetapi masing-masing dari bank-bank internasional utama adalah menyediakan kutipan sendiri dengan pasar spot bertindak sebagai titik acuan untuk kutipan disediakan.`,
      iux_what_is_discription_3: `Perlu disebutkan bahwa pasar spot FX juga merupakan pasar OTC didominasi oleh bank-bank internasional besar.`,
      iux_what_is_discription_4: `Dalam perdagangan CFDs, harga spot pasangan mata uang dipengaruhi oleh beberapa faktor, seperti prospek ekonomi dan peristiwa geopolitik di wilayah itu, serta rilis data yang berita yang dapat dirasakan secara positif atau negatif oleh pasar.`,
      iux_what_is_discription_5: `Kontrak untuk perbedaan (CFDs), memungkinkan pedagang untuk membeli (pergi panjang) atau sell (short), dan laba membuat atau kerugian dari pergerakan harga, tanpa harus beli secara fisik dan pertukaran mata uang yang mendasari.`,
      iux_what_is_discription_6: `FX dikutip berpasangan, dengan masing-masing mewakili mata uang global atau ekonomi. Mata uang pertama disebut mata uang ‘dasar’ (mewakili volume Anda ingin perdagangan) dan yang kedua disebut ‘istilah’ atau mata uang ‘quote’ (mewakili kurs saat ini).`,
    },
    forex_what_is_block2: {
      iux_what_is_discription_1: `Sebagai contoh, harga EUR / USD mewakili jumlah $ USD yang dapat ditukar untuk € 1.`,
      iux_what_is_discription_2: `EUR / USD = 1,11361`,
      iux_what_is_discription_3: `Ini berarti bahwa saat ini, € 1 sama dengan $ 1,11361`,
      iux_what_is_discription_4: `Harga terus berfluktuasi berdasarkan kondisi pasar.`,
      iux_what_is_discription_5: `Sederhananya, pedagang akan pergi lama jika mereka percaya bahwa mata uang dasar akan naik nilainya terhadap mata uang jangka dan akan mendapatkan keuntungan dari kenaikan harga. Di sisi lain, jika pedagang percaya bahwa nilai mata uang dasar akan jatuh dalam kaitannya dengan istilah, mereka akan menempatkan perdagangan jual untuk mencoba keuntungan dari penurunan harga. Jika harga bergerak ke arah yang berlawanan dengan perkiraan para pedagang, mereka akan membuat kerugian.`,
      iux_what_is_discription_6: `FX perdagangan mata uang biasanya dihitung dalam PIPs, yang berarti bahwa tergantung pada ukuran perdagangan Anda, setiap PIPs sama dengan nilai moneter tertentu dari mata uang ‘istilah’. Nilai PIPs ini digunakan untuk menentukan PNL (laba atau rugi), berdasarkan berapa banyak PIPs Anda mendapatkan atau kehilangan dalam perdagangan, dan juga digunakan untuk display spread (selisih antara bid dan ask harga).`,
      iux_what_is_discription_7: `Pada IUX Markets kami mengutip semua pasangan FX ke digit tambahan setelah PIPs, yang berarti bahwa angka terakhir dalam kutipan setiap mengacu pada Point (10% dari PIPs).`,
      iux_what_is_discription_8: `Dalam perdagangan mata uang FX, harga fraksional memungkinkan kami untuk menawarkan spread ketat dan memberikan harga yang lebih akurat.`,
      iux_what_is_discription_9: `Jika Anda baru untuk trading CFDs online, kami akan merekomendasikan akan melalui bagian pendidikan online kami untuk membiasakan diri dengan pasar dan bagaimana ‘Kontrak untuk Perbedaan’ karya perdagangan. Kami juga menyediakan ‘menonton dan belajar’ video dan panduan PDF.`,
    },
    futures_what_is_block1: {
      iux_what_is_header: `Apa Futures?`,
      iux_what_is_discription_1: `Futures adalah kontrak keuangan untuk aset tertentu yang akan dibeli atau dijual pada waktu yang ditetapkan di masa depan. Tingkat harga akhir ditentukan oleh kedua belah pihak, pembeli dan penjual, dan dikenal sebagai harga ke depan. Tanggal yang ditentukan dari pembayaran masa depan dikenal sebagai tanggal kedaluwarsa.`,
    },
    futures_what_is_block2: {
      iux_what_is_discription_1: `Kontrak untuk perbedaan (CFDs) pada future memungkinkan untuk perdagangan pada fluktuasi harga berbagai aset: komoditas, indeks dan energi. Jika investor membuat perkiraan tentang peningkatan tanda kutip, mereka membuka posisi Buy pada platform perdagangan. Jika mereka berpikir grafik akan bergerak turun, mereka membuat pesanan online Menjual. Mulai perdagangan berjangka dengan IUX Markets untuk diversifikasi akun investor Anda!`,
    },
    indices_what_is_block1: {
      iux_what_is_header: `Apa Indeks?`,
      iux_what_is_discription_1: `Indeks Dunia adalah indikator perubahan harga untuk kelompok tertentu sekuritas. Indeks bursa dapat dijelaskan sebagai “keranjang” saham disatukan oleh dasar umum. indeks perdagangan dapat dibandingkan dengan membuka posisi pada kursus dari beberapa lusin saham sekaligus.`,
      iux_what_is_discription_2: `Perkara yang paling penting ialah menentukan saham atau bon yang tepat bagi setiap indeks terbentuk. Set saham yang termasuk dalam pengiraan nilai indeks spot menentukan maklumat yang boleh diperolehi dengan memerhatikan dinamik perjalanannya.`,
      iux_what_is_discription_3: `Secara umum, tujuan utama indeks dunia adalah untuk mencipta penunjuk yang berkuasa bagi pelabur untuk mencirikan hala tuju sebut harga syarikat dalam industri tertentu. Mempelajari dinamik indeks utama membantu memahami kesan peristiwa tertentu ke atas nilai sekuriti.`,
      iux_what_is_discription_4: `Selama indeks perdagangan, perlu diingat bahwa reaksi terhadap berita ekonomi yang diterbitkan mungkin tidak bersesuaian dengan harapan dan perkiraan.`,
    },
    indices_what_is_block2: {
      iux_what_is_discription_1: `Misalnya, jika ada kenaikan harga minyak, adalah logis untuk mengharapkan peningkatan saham semua perusahaan minyak.`,
      iux_what_is_discription_2: `Namun, saham yang berbeda tumbuh pada kecepatan yang berbeda, sementara beberapa dari mereka mungkin tidak menanggapi berita tersebut sama sekali. Dalam hal ini, indeks tempat membantu pedagang untuk memahami tren keseluruhan segmen pasar ini tanpa perlu menilai posisi banyak perusahaan yang berbeda.`,
      iux_what_is_discription_3: `Observasi dan perdagangan indeks memberikan wawasan mengenai bagaimana berbagai sektor perdagangan ekonomi dibandingkan dengan satu sama lain. Di sini, di IUX Markets kami senang untuk menawarkan perdagangan CFDs pada indeks utama, yang memungkinkan untuk bergabung dengan pergerakan harga tidak hanya untuk kenaikan, tetapi juga jatuh.`,
      iux_what_is_discription_4: `Perdagangan indeks adalah populer di kalangan pedagang IUX Markets karena istilah yang komprehensif, kutipan akurat dari beberapa pemasok dan serbaguna analisis. Setelah semua, untuk memahami logika perilaku indeks, Anda perlu memperhatikan dengan berita perusahaan dari masing-masing perusahaan yang termasuk, serta pada acara yang mempengaruhi industri yang lebih luas secara keseluruhan.`,
    },

    shares_what_is_block1: {
      iux_what_is_header: `Apa itu Saham?`,
      iux_what_is_discription_1: `PBerdagang CFDs pada Saham memberi anda pilihan untuk pesanan beli dan jual. Untuk mendapat manfaat daripada kenaikan atau penurunan harga sewajarnya. Saham kami mencerminkan tindakan syarikat. Jadi anda mendapat dividen apabila anda membeli. dan akan menanggung kos dividen yang dijual`,
    },
    shares_what_is_block2: {
      iux_what_is_discription_1: `Perdagangan saham ialah cara pelaburan yang paling popular. Di IUX Markets kami menawarkan CFDs saham pada sesetengah syarikat. Beberapa aset paling berharga di dunia termasuk Apple, Coca Cola dan Facebook. Bagaimanapun, pelabur harus menyelidik maklumat itu dengan teliti sebelum berdagang.`,
      // iux_what_is_discription_2: ''
    },
    metals_what_is_block1: {
      iux_what_is_header: `Apa itu Logam?`,
      iux_what_is_discription_1: `Logam perdagangan spot memungkinkan investor untuk memperluas item investasi mereka. Dengan menempatkan pesanan untuk membeli atau menjual emas dan bintik-bintik perak Kami menjamin bahwa order Anda dieksekusi pada harga yang paling akurat dan kompetitif. The spot emas bernilai lebih dari tempat perak. Tapi keduanya sangat fluktuatif harga`,
    },
    metals_what_is_block2: {
      iux_what_is_discription_1: `Logam perdagangan spot sering dipandang sebagai investasi yang aman, tempat yang aman, terutama selama masa ketidakpastian ekonomi. Anda dapat melakukan transaksi Spot Logam untuk akun demo gratis sebelum transaksi di real account Anda.`,
    },
    energies_what_is_block1: {
      iux_what_is_header: `Apa itu Energices?`,
      iux_what_is_discription_1: `Mulai perdagangan spot energi dengan IUX Markets untuk membuat berbagai investasi dan memanfaatkan peluang bisnis baru. bintik daya dapat diperdagangkan di semua platform perdagangan kami, termasuk MT5 , cTrader dan Pasar Pasar IUX.`,
    },
    energies_what_is_block2: {
      iux_what_is_discription_1: `Tenaga spot ialah pilihan popular untuk urus niaga jangka pendek. Ini adalah benar terutamanya semasa masa penggunaan tenaga meningkat dengan mendarabkan kadar di seluruh dunia. Amalkan perdagangan Spot Energies dalam akaun demo percuma kami sebelum berdagang pada akaun sebenar.`,
    },
    iux_forex_page: {
      title_1: `Berdagang CFDs`,
      title_2: `Berdagang CFDs pada 70+ pasangan FX dengan Broker Fx no.1 di Dunia untuk mendapat manfaat daripada spread rendah dan pelaksanaan pesanan pantas.`,
    },
    iux_futures_page: {
      title_1: `Berdagang CFDs pada niaga hadapan`,
      title_2: `Berdagang CFDs pada kontrak niaga hadapan dari seluruh dunia. dan meneroka peluang perdagangan yang tidak berkesudahan.`,
    },
    iux_indices_page: {
      title_1: `Berdagang CFDs pada Indeks`,
      title_2: `Perdagangan CFDs pada Indeks populer dari seluruh Eropa, Asia dan Amerika.`,
    },
    iux_shares_page: {
      title_1: `Berdagang CFDs pada Saham`,
      title_2: `Berdagang CFDs pada saham dengan lebih daripada 150 syarikat global. mendapat manfaat daripada tindakan pantas dan membayar dividen untuk pesanan jangka panjang.`,
    },
    iux_metals_page: {
      title_1: `perdagangan CFDs pada logam`,
      title_2: `Berdagang CFDs pada logam spot dan dapatkan peluang dagangan baharu`,
    },
    iux_energies_page: {
      title_1: `Perdagangan CFDs pada Energi`,
      title_2: `Perdagangan CFDs pada Spot Energi seperti minyak Brent, WTI dan Gas Alam .`,
    },
  },
  zh: {
    iux_forex_market: {
      title_1: `平均执行时间<0.001毫秒`,
      title_2: `高达每秒1000级的订单执行`,
      title_3: `超低延迟数据中心协同定位`,
    },
    button: {
      title_1: `MT4 `,
      title_2: `模拟账户`,
      sub_button: {
        title_1: `立即开始交易`,
        title_2: `模拟账户`,
      },
    },
    why_iux_header: {
      title: `为什么要与IUX Markets交易`,
      header_1: `6年的卓越的`,
      text_1: `选择一个值得信赖的经纪人，在170多个国家的客户提供服务。`,
      header_2: `贸易250+仪器`,
      text_2: `差价合约交易在外汇，股票，期货，现货指数，现货金属和能源现货`,
      header_3: `4个交易平台`,
      text_3: `与优良平台贸易，包括 IUX Markets MT5。`,
      header_4: `快速执行和深度的流动性`,
      text_4: ` 从超快速的订单执行与下11.06ms在执行大部分订单效益`,
      header_5: `70+英国及国际大奖`,
      text_5: ` 与已多次承认其服务质量的经纪人拉德。`,
      header_6: `24/7多语言支持`,
      text_6: `粒我们的24/7客户支持的优势，在超过4种语言提供援助。`,
    },
    iux_award_trading: {
      iux_header_award: `获奖NDD执行`,
      iux_title_award: `所有客户的交易与无交易分享帮助干预执行。大多数的交易都在10毫秒填充，具有高达每秒执行的2000个交易。`,
      iux_platform: `多的资金和取款选项`,
      iux_sub_platform: `我们为客户提供广泛的 Desktop，Web和移动交易平台MetaTrader5。`,
    },
    forex_what_is_block1: {
      iux_what_is_header: `什么是外汇？`,
      iux_what_is_discription_1: `外汇市场（CFDs）作为一个整体，包括许多类型的市场，包括即期外汇，期货衍生品，衍生品转发，最后CFD衍生产品市场，这是最流行的零售客户。所有外汇交易的交易合并组成规模最大，最具流动性的金融市场，平均日交易量的5万亿$。`,
      iux_what_is_discription_2: `外汇CFDs衍生产品市场是由买卖双方起来，主要参加者是大型国际银行，谁通过电子交易系统的订单。该市场交易的OTC（非交易在任何受监管的交易所），因此没有统一的价格，但各主要国际银行将提供它自己的报价与现货市场作为对所提供的报价的参考点。`,
      iux_what_is_discription_3: `值得一提的是，即期外汇市场也是一个场外交易市场由大型国际银行占主导地位。`,
      iux_what_is_discription_4: `在外汇交易，货币对现货价格是由几个因素，如经济前景和地缘政治事件，在该地区，以及可能受到市场的积极或消极的感知新闻数据公布的影响。`,
      iux_what_is_discription_5: `差价合约（差价合约），允许交易者买进（长）或卖出（做空），使利润或价格变动的损失，而无需亲自购买和Exchange中的基础货币。`,
      iux_what_is_discription_6: `FX是引用成对的，每一个代表一个全球性的货币或经济。第一个货币被称为“基础”货币（代表你希望交易量），第二个被称为“术语”或“报价”货币（代表当前汇率）。`,
    },
    forex_what_is_block2: {
      iux_what_is_discription_1: `例如，欧元/美元的价格表示可以为€1交换$ USD的量。`,
      iux_what_is_discription_2: `EUR / USD = 1.11361`,
      iux_what_is_discription_3: `这意味着，目前，€1等于$ 1.11361`,
      iux_what_is_discription_4: `价格是根据市场情况不断波动。`,
      iux_what_is_discription_5: `简单地说，如果他们认为，基础货币会升值对长期货币，并会从价格上升中获利交易者做多。在另一方面，如果交易员认为，基础货币的价值会相对下降的名词，他们会放置一个卖出交易，从价格下跌尝试盈利。如果价格向相反的方向移动到交易商的预测，他们将无所适从。`,
      iux_what_is_discription_6: `外汇货币交易通常在计算点子，这意味着根据您的交易规模，每个点子等于“术语”货币的具体的货币价值。该点的价值被用来确定损益（利润或亏损）的基础上，你有多少个点获得或在贸易中受损，而且也用于显示扩散（投标之间的差异和卖出价）。`,
      iux_what_is_discription_7: `在IUX Markets我们引用的所有FX对一个额外的数字的点子后，这意味着在任何报价的最后一位数字是指一个点（一个点的10％）。`,
      iux_what_is_discription_8: `在外汇货币交易，分数定价使我们能够提供的息差收窄，并提供更准确的定价。`,
      iux_what_is_discription_9: `如果你是新的网上外汇交易，我们建议要通过我们的在线教育部分，以熟悉市场，以及如何'差价合约的交易作品。我们还提供“观看并学习”的视频和PDF指南。`,
    },
    futures_what_is_block1: {
      iux_what_is_header: `什么是期货？`,
      iux_what_is_discription_1: `期货是金融合同的具体资产购买或在未来规定的时间出售。最终的价格水平是由双方当事人，买方和卖方决定的，被称为远期价格。未来支付的指定日期被称为到期日。`,
    },
    futures_what_is_block2: {
      iux_what_is_discription_1: `合约期货差异（CFD）允许在广泛的资产价格波动交易：商品，指数和能量。如果投资者做对行情预测的增加，他们打开交易平台上的买入位置。如果他们认为该图表将向下移动，它们使网上订单销售。开始交易的期货与IUX Markets分散你的投资帐户！`,
    },
    indices_what_is_block1: {
      iux_what_is_header: `什么是指数？`,
      iux_what_is_discription_1: `世界指数是一组证券价格变化的指标。证券交易所指数可以作为一个由一个共同的基础团结股“菜篮子”来解释。交易指数可以比作一次开上几十股课程位置。`,
      iux_what_is_discription_2: `最重要的是确定每个指数从形成确切的股票或债券。该组包括在所述点指标值计算股确定可以通过观察其过程的动力学来获得的信息。`,
      iux_what_is_discription_3: `在一般情况下，世界指数的主要目的是建立一个强有力的指标，为投资者表征某一特定行业公司的报价的方向。研究主要指数的动态有助于理解某些事件的证券的价值的影响。`,
      iux_what_is_discription_4: `在交易指数，记住，反应到经济消息公布可能与期望和预测相符。`,
    },
    indices_what_is_block2: {
      iux_what_is_discription_1: `例如，如果在石油价格的上涨，这是合乎逻辑的期望在所有石油公司的股份增加。`,
      iux_what_is_discription_2: `然而，不同的股票成长速度不同，而他们中的一些可能无法在所有这些消息作出回应。在这种情况下，现货指数有助于投资者了解这一细分市场的整体走势，而不需要评估许多不同的公司的地位。`,
      iux_what_is_discription_3: `观察及交易指数给予相互比较深入地了解经济贸易的不同部门。在这里，在IUX Markets，我们很高兴能够提供在主要指数差价合约的交易，这使得它能够加入到价格波动不仅对上升，而且下降。`,
      iux_what_is_discription_4: `买卖指数是IUX Markets交易商的青睐，由于其综合而言，从几个供应商准确的报价和灵活的分析。毕竟，以了解该指数的行为逻辑，就需要关注每一个包括公司的企业新闻，以及对影响更广泛的行业作为一个整体的事件。`,
    },

    shares_what_is_block1: {
      iux_what_is_header: `什么是股票？`,
      iux_what_is_discription_1: `买卖差价合约与股票供您选择，购买和出售。为了能够从涨价中获益或减少我们分别反映股份公司的行动。所以，当你购买，你会获得股息。而将要支付销售的分红`,
    },
    shares_what_is_block2: {
      iux_what_is_discription_1: `股票买卖是最流行的投资方法。在IUX Markets，我们提供了一些公司的股票差价合约。最有价值的世界，如苹果，可口可乐和Facebook。然而，投资者应在交易前仔细研究的信息。`,
      // iux_what_is_discription_2: ''
    },
    metals_what_is_block1: {
      iux_what_is_header: `什么是金属？`,
      iux_what_is_discription_1: `金属现货交易允许投资者扩大投资项目。通过将单买入或卖出黄金和白银的斑点，我们保证您的定单在最准确和最具竞争力的价格执行。黄金现货的价值超过了白银现货。但两者在价格上非常不稳定`,
    },
    metals_what_is_block2: {
      iux_what_is_discription_1: `金属现货交易通常被视为一个安全的投资，一个安全的避风港，尤其是在经济不稳定时期。您可以在真实账户交易之前免费模拟账户做现货金属交易。`,
    },
    energies_what_is_block1: {
      iux_what_is_header: `什么是能源？`,
      iux_what_is_discription_1: `开始交易与市场IUX能量点做出各种投资，并利用新的商业机会。电源点可以在我们所有的交易平台，包括，MT5，和IUX Markets市场进行交易。`,
    },
    energies_what_is_block2: {
      iux_what_is_discription_1: `能量点是短期交易的热门选择。特别是在的时候在全球倍增的速度练习交易现货能源在我们的免费模拟帐户在真实交易帐户前能源使用量增加。`,
    },
    iux_forex_page: {
      title_1: `外汇交易`,
      title_2: `买卖差价合约从低点差和快速订单执行70 + 对并从中受益。`,
    },
    iux_futures_page: {
      title_1: `期货交易`,
      title_2: `买卖差价合约期货来自世界各地，探索无尽的交易机会`,
    },
    iux_indices_page: {
      title_1: `现货指数交易`,
      title_2: `买卖差价合约对来自欧洲，亚洲和美洲的流行指数。`,
    },
    iux_shares_page: {
      title_1: `股票交易`,
      title_2: `买卖差价合约上的150多个全球性企业从多头快速订单执行和股息支付股份并从中受益。`,
    },
    iux_metals_page: {
      title_1: `贵金属交易`,
      title_2: `买卖差价合约对现货金属和揭露新的贸易机会`,
    },
    iux_energies_page: {
      title_1: `差价合约的能源交易`,
      title_2: `买卖差价合约在现货能源，如布伦特原油价格，WTI和天然气以及多元化投资组合。`,
    },
  },
  vi: {
    iux_forex_market: {
      title_1: "Thời gian thực hiện trung bình < 0.001 ms",
      title_2: "Có lệnh được thực hiện lên đến 1.000 mục mỗi giây.",
      title_3: "Trung tâm dữ liệu có thời gian trễ rất thấp với cùng vị trí",
    },
    why_iux_header: {
      title: "Tại sao bạn chọn IUX Markets",
      header_1: "Nhiều hơn 6 Năm đã thành công",
      text_1:
        "Chọn một nhà môi giới đáng tin cậy mà phục vụ khách hàng hơn 170 quốc gia.",
      header_2: " Giao dịch nhiều hơn 250 công cụ. ",
      text_2:
        "Giao dịch chênh lệch ngoại hối thương mại: Cổ phiếu, tương lai, Chỉ số giao ngay, Kim loại giao ngay, Năng lượng.",
      header_3: "4 nền tảng giao dịch",
      text_3: "Giao dịch trên các nền tảng yêu thích của bạn như:  MT5",
      header_4: "Khớp lệnh nhanh và thanh khoản tốt",
      text_4: "Được hưởng lợi từ việc khớp lệnh nhanh chóng trong< 0.001 ms.",
      header_5: "Nhiều hơn 10 giải thưởng Quốc tế và Vương quốc Anh",
      text_5:
        "Giao dịch với các nhà môi giới luôn được công nhận về chất lượng dịch vụ..",
      header_6: "Hỗ trợ hơn 4 ngôn ngữ",
      text_6: `Bên hỗ trợ khách hàng 24/7 ngày Đội ngũ hỗ trợ khách hàng của chúng tôi, những người hoàn toàn tận tâm phục vụ bạn bằng ngoại ngữ hơn 4+ ngôn ngữ.`,
    },
    iux_award_trading: {
      iux_header_award: "Giải thưởng hành động  NDD",
      iux_title_award:
        "Tất cả các lệnh sẽ được thực hiện ẩn danh mà không cần can thiệp xử lý ( NDD), có các lệnh đã thực hiện đến 2.000 lệnh mỗi giây và nhiều hơn 87% lệnh được thực hiện trong < 10ms",
      iux_platform: "Nền tảng giao dịch",
      iux_sub_platform:
        "Chúng tôi cung cấp cho khách hàng  nền tảng giao dịch khác nhau trên máy tính để bàn web và điện thoại di động bao gồm: MetaTrader 5",
    },
    forex_what_is_block1: {
      iux_what_is_header: "Ngoại tệ là gì? ",
      iux_what_is_discription_1:
        "Thị trường ngoại tệ (gọi là CFDs hoặc Thị trường CFDs) 5 nghìn tỷ đo la. ",
      iux_what_is_discription_2:
        "Thị trường ngoại tệ phi tập trung. Giao dịch đã được thực hiện bên ngoài quầy  (OTC)  ",
      iux_what_is_discription_3:
        "là điều đáng nói là thị trường spot là OTC ​thị trường thống trị bởi các ngân hàng quốc tế lớn.",
      iux_what_is_discription_4:
        "Trong giao dịch Ngoại hối, giá giao ngay của các cặp tiền tệ bị ảnh hưởng bởi một số yếu tố như xu hướng kinh tế và các sự kiện chính trị trong khu vực đó, ​cũng như phổ biến thông tin tin tức mà thị trường có thể nhận thức tích cực hoặc tiêu cực",
      iux_what_is_discription_5:
        "Hợp đồng  đối với  sự khác (CFDs) cho phép nhà giao  mua (dài) hoặc bán (ngắn) và kiếm lợi nhuận hoặc thua lỗ trên biến động giá mà không cần mua và trao đổi tiền tệ cơ bản.",
      iux_what_is_discription_6: `CFDs đang đấu thầu theo cặp, với mỗi loại tiền tệ đại diện cho thế giới hoặc nền kinh tế. Đồng tiền đầu tiên được gọi là tiền tệ ' cơ sở ' (hiện thị đến số lượng  bạn muốn giao dịch), và đồng tiền thứ hai được gọi là tiền tệ 'từ' hoặc 'bảng báo giá'(hiện thị  đến  tỷ giá hối đoái hiện tại)`,
    },
    forex_what_is_block2: {
      iux_what_is_discription_1:
        "Ví dụ: giá  EUR / USD hiển thị số $USD có thể đổi lấy € 1",
      iux_what_is_discription_2: "EUR/USD = 1.11361",
      iux_what_is_discription_3: `Có nghĩa là hiện tại€ 1 bằng $ 1.11361`,
      iux_what_is_discription_4:
        "Giá tiếp tục biến động theo điều kiện thị trường",
      iux_what_is_discription_5:
        "Nói một cách đơn giản, các nhà giao dịch sẽ phải dùng thời gian dài nếu họ tin rằng đồng tiền cơ sở sẽ tăng giá trị khi so với đồng tiền trong khoảng và sẽ được lợi nhuận từ việc tăng giá. Mặt khác, nếu các nhà giao dịch tin rằng giá trị của các loại tiền tệ chính sẽ giảm  so với những từ đó, họ sẽ giao dịch trong nỗ lực kiếm lợi nhuận từ giá. Nếu giá di chuyển theo hướng ngược lại với dự báo của nhà giao dịch, họ sẽ mất tiền.",
      iux_what_is_discription_6:
        "Thường giao dịch tiền tệ CFDs được tính là PIPs , có nghĩa là tùy thuộc vào quy mô giao dịch của bạn, mỗi PIPs sẽ bằng với giá trị tiền tệ của đồng tiền. PIPs Điều này được sử dụng để xác định  PnL (lãi hoặc lỗ) tùy thuộc vào số tiền pipmà bạn nhận hoặc bị mất trong giao dịch và được sử dụng để hiển thị Spread (chênh lệchgiữa giá thầu và giá chào bán).",
      iux_what_is_discription_7: "\n",
      iux_what_is_discription_8: "\n",
      iux_what_is_discription_9: "\n",
    },
    futures_what_is_block1: {
      iux_what_is_header: "Giao dịch tương lai là gì?",
      iux_what_is_discription_1:
        "Hợp đồng tương lai là hợp đồng tài chính giữa người mua và người bán, trong đó một số tài sản nhất định được mua hoặc bán với giá và ngày trong tương lai được đàm phán. Giá được đàm phán bởi hai bên được gọi là thay đổi giá. Ngày thanh toán trong tương lai được chỉ định được gọi là ngày giao hàng.",
    },
    futures_what_is_block2: {
      iux_what_is_discription_1:
        "Hợp đồng tương lai  cho nhà đầu tư có thể mong đợi biến động giá của một loạt các sản phẩm. Ví dụ, nếu một nhà đầu tư tin rằng giá của một sản phẩm cụ thể sẽ tăng lên, họ sẽ có thể mua ể mua hợp đồng tương lai với giá thấp hơn và bán nó vào ngày hôm sau. Khi sản phẩm tăng giá trị.",
    },
    indices_what_is_block1: {
      iux_what_is_header: "Chỉ mục là gì?",
      iux_what_is_discription_1:
        "Chỉ số là giá trị tiêu chuẩn của hiệu suất, điều hành một thị trường hoặc một số lĩnh vực nhất định của nền kinh tế. Giá trị chỉ số đại diện cho hiệu suất của tất cả các công ty được bao gồm trong chỉ số và được tính là giá trị trung bình có trọng lượng.",
      iux_what_is_discription_2: "\n",
      iux_what_is_discription_3: "\n",
      iux_what_is_discription_4: "\n",
    },
    indices_what_is_block2: {
      iux_what_is_discription_1:
        "Chỉ số cung cấp dự báo về các thị trường cụ thể, trái ngược với các cổ phiếu, vì vậy, rất phổ biến đối với các nhà đầu tư tìm kiếm một vị trí về những thay đổi trong lĩnh vực kinh tế được đại diện bởi chỉ số.",
      iux_what_is_discription_2: "\n",
      iux_what_is_discription_3: "\n",
      iux_what_is_discription_4: "\n",
    },
    shares_what_is_block1: {
      iux_what_is_header: "Cổ phiếu là gì?",
      iux_what_is_discription_1:
        "Việc giao dịch CFDs với cổ phiếu làm cho bạn chọn cả  đơn đặt hàng mua và bán để có thể được nhận hưởng lợi từ giá tăng lên  hoặc giảm xuống theo thứ tự. Cổ phiếu của chúng tôi phản ánh hành động của công ty. Do đó, bạn sẽ nhận được cổ tức khi mua và sẽ bị tính bằng cổ tức đã bán.",
    },
    shares_what_is_block2: {
      iux_what_is_discription_1:
        "Giao dịch chứng khoán là phương thức đầu tư phổ biến nhất tại IUX Markets.Chúng tôi cung cấp CFDs Cổ phiếu của một số công ty có giá trị nhất thế giới, chẳng hạn như: Apple, Coca Cola và Facebook. Tuy nhiên, nhà đầu tư nên tìm hiểu kỹ thông tin trước khi giao dịch.",
    },
    metals_what_is_block1: {
      iux_what_is_header: "Giao dịch Kim loại với IUX Markets",
      iux_what_is_discription_1:
        "Giao dịch kim loại giao ngay giúp cho các nhà đầu tư mở rộng danh sách đầu tư của họ. Bằng cách thiết lập các đơn đặt hàng hoặc bán vàng và bạc giao ngay. Chúng tôi đảm bảo rằng các đơn đặt hàng của bạn được thực hiện với mức giá chính xác và cạnh tranh nhất. Vàng giao ngay có giá trị hơn điểm bạc, nhưng cả hai đều rất biến động về giá.",
    },
    metals_what_is_block2: {
      iux_what_is_discription_1:
        "Giao dịch giao ngay kim loại thường được coi là một khoản đầu tư an toàn, một nơi trú ẩn an toàn. Đặc biệt trong thời điểm kinh tế không thống nhất, bạn có thể giao dịch Kim loại giao ngay tài khoản demo miễn phí trước khi giao dịch trong tài khoản thật của bạn.",
    },
    energies_what_is_block1: {
      iux_what_is_header: "Giao dịch năng lượng với IUX Markets",
      iux_what_is_discription_1: `Bắt đầu giao dịch năng lượng với IUX Markets, để thực hiện một loạt các khoản đầu tư và tận dụng cơ hội giao dịch mới. Năng lượng giao ngay có thể được giao dịch trên tất cả các nền tảng giao dịch của chúng tôi bao gồm  MT5`,
    },
    energies_what_is_block2: {
      iux_what_is_discription_1:
        "Điểm năng lượng là lựa chọn phổ biến cho các giao dịch ngắn hạn, đặc biệt là vào thời điểm việc sử dụng năng lượng tăng lên theo tốc độ nhân lên toàn cầu. Thực hành giao dịch Năng lượng giao ngay trên tài khoản demo miễn phí của chúng tôi trước khi thực hiện bất kỳ giao dịch nào trên tài khoản thực.",
    },
    iux_forex_page: {
      title_1: "Giao dịch CFDs",
      title_2:
        "Giao dịch CFDs trên 70+ cặp với nhà môi giới xếp hạng 1trên thế giới 1 để hưởng lợi từ chênh lệch thấp và khớp lệnh nhanh.",
    },
    iux_futures_page: {
      title_1: "Giao dịch CFDs trong tương lai",
      title_2:
        "Giao dịch CFDs trên hợp đồng giao dịch trước tương lai toàn cầu và khám phá các cơ hội giao dịch vô tận.",
    },
    iux_indices_page: {
      title_1: "Giao dịch CFDs trong chỉ mục",
      title_2:
        "Giao dịch CFDs trong các chỉ số phổ biến từ Châu Âu, Châu Á và Châu Mỹ.",
    },
    iux_shares_page: {
      title_1: "Giao dịch CFDs trong cổ phiếu",
      title_2:
        "Giao dịch CFDs trên cổ phiếu có nhiều hơn 150 công ty toàn cầu, Để hưởng lợi từ hoạt động nhanh chóng và trả cổ tức cho các đơn đặt hàng dài hạn.",
    },
    iux_metals_page: {
      title_1: "Giao dịch CFDs trên kim loại",
      title_2:
        "Giao dịch CFDs trên kim loại giao ngay và cơ hội giao dịch mới.",
    },
    iux_energies_page: {
      title_1: "Giao dịch CFDs trong năng lượng",
      title_2:
        "Giao dịch CFDs trên năng lượng như dầu Brent, WTI và khí tự nhiên.",
    },
    button: {
      title_1: "MT4 ",
      title_2: "Mở Tài khoản demo",
      sub_button: {
        title_1: "Bắt đầu giao dịch ngay",
        title_2: "Mở Tài khoản demo",
      },
    },
  },
  //
  ko: {
    iux_forex_market: {
      title_1: `평균 실행 시간 <0.001 밀리`,
      title_2: `최대 1,000 주문 초당 실행`,
      title_3: `데이터 센터 코 로케이션 지연 초저`,
    },
    button: {
      title_1: `MT4 `,
      title_2: `데모 계정`,
      sub_button: {
        title_1: `거래 시작`,
        title_2: `데모 계정`,
      },
    },
    why_iux_header: {
      title: `IUX Markets와 거래하는 이유`,
      header_1: `우수 6 년`,
      text_1: `170여 개국에서 고객들에게 서비스를 제공 신뢰할 수있는 브로커를 선택합니다.`,
      header_2: `250+ 이상의 상품을 거래하세요`,
      text_2: `CFDs, 주식, 선물, 현물 지수, 현물 금속 및 현물 에너지를 거래하세요.`,
      header_3: `4 개 거래 플랫폼`,
      text_3: `IUX Markets MT5를 포함하여 선호하는 플랫폼에서 거래하세요`,
      header_4: `빠른 실행 및 깊은 유동성`,
      text_4: ` 11.06ms에서 실행 대부분의 주문에 초고속 위해 수행 한 혜택`,
      header_5: `70 + 영국 및 국제 수상`,
      text_5: `서비스 품질을 반복적으로 인정받은 브로커와 거래하세요.`,
      header_6: `24/7 다국어 지원`,
      text_6: `4 개 이상의 언어로 지원을 제공, 우리의 24/7 고객 지원의 혜택을 얻을.`,
    },
    iux_award_trading: {
      iux_header_award: `수상 NDD 실행`,
      iux_title_award: `모든 클라이언트 거래는 없음 딜링 Desk1의 개입으로 실행됩니다. 대부분의 거래는 10 밀리 초 이내에 채워진다. 초당 2,000 거래를 실행하는쪽으로.`,
      iux_platform: `여러 자금 인출 옵션`,
      iux_sub_platform: `모든 플랫폼에서 거래 가능 데스크톱, 웹사이트, 모바일 거래 플랫폼 및 MetaTrader 5`,
    },
    forex_what_is_block1: {
      iux_what_is_header: `CFDs란 무엇입니까?`,
      iux_what_is_discription_1: `전체 외환 시장 (CFDs)는 소매 고객을위한 가장 인기 스팟 CFDs, 미래 유도체, 앞으로 파생 상품, 그리고 마지막으로 CFD의 파생 상품 시장을 포함한 시장의 많은 종류로 구성되어 있습니다. 이상 $ (5) 조 일일 평균 볼륨, 가장 큰 액체 금융 시장의 메이크업을 결합하여 모든 외환 거래 거래.`,
      iux_what_is_discription_2: `FX는 CFDs 파생 상품 시장은 구매자와 판매자로 구성되어, 전자 거래 시스템을 통해 주문을 대형 국제 은행을,되는 주요 참가자. 이 시장은 OTC를 거래되고있다 (모든 규제 거래소에 상장되지 않음)와 같은 더 균일 가격은 없습니다하지만 주요 국제 은행의 각 현물 시장이 제공하는 따옴표를위한 기준점 역할과 자신의 따옴표를 제공하고 있습니다.`,
      iux_what_is_discription_3: `그것은 현물 외환 시장도 대형 국제 은행에 의해 지배 장외 시장이라고 언급 할 가치가있다.`,
      iux_what_is_discription_4: `외환 거래에서는 통화 쌍 현물 가격은 같은 경제 전망과 지정 학적 이벤트 그 지역에서뿐만 아니라 시장에서 긍정적으로 또는 부정적으로 인식 될 수 있습니다 뉴스 자료 방출 등 여러 가지 요인에 의해 영향을 받는다.`,
      iux_what_is_discription_5: `차이에 대한 계약 (CFD는), 상인이 구입 (긴 이동) 또는 판매 (짧은 이동), 및 메이크업 이익 또는 가격 변동의 감소, 물리적으로 구매하지 않고도 기본 통화를 교환 할 수 있습니다.`,
      iux_what_is_discription_6: `FX는 각 글로벌 통화 또는 경제를 대표로, 쌍으로 인용된다. 첫 번째 통화는 (당신이 거래하고자하는 볼륨을 나타내는)가 '기본'통화라고하며 두 번째는 '용어'또는 (현재 환율을 나타내는) '인용'통화라고합니다.`,
    },
    forex_what_is_block2: {
      iux_what_is_discription_1: `예를 들어, EUR / USD의 가격은 € 1로 교환 할 수 있습니다 $ USD의 양을 나타냅니다.`,
      iux_what_is_discription_2: `EUR / USD = 1.11361`,
      iux_what_is_discription_3: `현재, € 1 $ 1.11361 동일이 수단`,
      iux_what_is_discription_4: `가격은 지속적으로 시장 상황에 따라 변동된다.`,
      iux_what_is_discription_5: `그들은 기본 통화 용어 통화에 대해 가치가 상승하고 가격 상승에서 이익 것이라고 믿는다면 간단히 말해, 상인은 오래 갈 것입니다. 상인이 '기본 통화의 값이 용어에 관하여 떨어질 것이라고 믿는다면 반면에, 그들은 가격 하락에서 이익을 시도 매도 거래를 배치합니다. 가격은 상인 '예측에 반대 방향으로 이동하면, 그들은 손실을 만들 것입니다.`,
      iux_what_is_discription_6: `FX 통화 거래는 일반적으로 무역의 크기에 따라, 각 핍은 '용어'통화의 특정 금전적 가치와 같음을 의미 핍에서 계산됩니다. 이 핍 값은 얻거나 트레이드 잃고 얼마나 많은 주사위에 따라 손익 (당기 손익)을 결정하는 데 사용됩니다, 또한 디스플레이 확산 (입찰의 차이 및 가격 문의) 사용됩니다.`,
      iux_what_is_discription_7: `IUX 시장에서 우리는 어떠한 인용의 마지막 자리는 포인트 (픽처 인 픽처의 10 %)을 의미한다는 것을 의미 핍 후 여분의 자리에 모든 FX 쌍을 인용.`,
      iux_what_is_discription_8: `FX 통화 거래에서 분수 가격은 우리가 엄격한 스프레드를 제공하고보다 정확한 가격을 제공 할 수 있습니다.`,
      iux_what_is_discription_9: `온라인 외환 거래를 처음 사용하는 경우, 우리는 시장 및 방법 '계약 차이에 대한'거래 작품에 익숙해 온라인 교육 섹션을 통해가는 것이 좋습니다. 우리는 또한 '시계를 배우고'제공하는 비디오 및 PDF 가이드를.`,
    },
    futures_what_is_block1: {
      iux_what_is_header: `선물 거래 이란 무엇입니까?`,
      iux_what_is_discription_1: `특정 자산 구입 또는 미래에 설정된 시간에 판매하는 선물은 금융 계약이다. 최종 가격 수준은 양 당사자, 구매자와 판매자에 의해 결정되며, 앞으로 가격으로 알려져있다. 미래의 지불의 지정된 날짜가 만료 날짜로 알려져있다.`,
    },
    futures_what_is_block2: {
      iux_what_is_discription_1: `선물 차액거래계약(CFDs)을 통해 상품, 지수, 에너지 등 광범위한 자산의 가격 변동에 따른 거래가 가능합니다. 투자자가 호가 증가에 대해 예측하면 거래 플랫폼에서 매수 포지션을 엽니다. 차트가 하락할 것이라고 생각하면 온라인으로 매도 주문을 합니다. IUX Markets에서 선물 거래를 시작하여 투자자 계좌를 다양화하세요!`,
    },
    indices_what_is_block1: {
      iux_what_is_header: `지수는 무엇입니까?`,
      iux_what_is_discription_1: `세계 지수는 유가 증권의 특정 그룹에 대한 가격 변동의 지표이다. 증권 거래소 지수는 공통 기준에 의해 연합 주식의 "바구니"로 설명 될 수있다. 무역 인덱스는 한 번에 수십 주식의 과정에 대한 위치를 열기에 비교 될 수있다.`,
      iux_what_is_discription_2: `가장 중요한 것은 각 인덱스가 형성되는 정확한 주식이나 채권을 결정하는 것입니다. 스폿 인덱스 값 계산에 포함 주 세트는 물론 동성을 관찰함으로써 수득 될 수있는 정보를 결정한다.`,
      iux_what_is_discription_3: `일반적으로, 세계 인덱스의 주요 목적은 투자자들이 특정 산업에서 기업의 따옴표의 방향을 특성화하기위한 강력한 지표를 만드는 것입니다. 주요 지표의 역학을 공부하는 것은 유가 증권의 가치에 특정 이벤트의 영향을 이해하는 데 도움이됩니다.`,
      iux_what_is_discription_4: `거래 지표 동안, 경제 뉴스에 대한 반응은 기대와 예측과 일치하지 않을 수 있습니다 출판 있음을 유의하십시오.`,
    },
    indices_what_is_block2: {
      iux_what_is_discription_1: `지수는 특정 시장에 대한 예측을 제공합니다. 따라서 개별 주식과 달리 지수로 대표되는 경제 부문의 변화에 대한 포지션을 찾는 투자자에게 매우 인기가 있습니다.`,
      iux_what_is_discription_2: `그러나 주식마다 성장 속도가 다르며 일부 주식은 그러한 뉴스에 전혀 반응하지 않을 수도 있습니다. 이 경우 현물 지수는 거래자가 여러 회사의 포지션을 평가할 필요 없이 이 시장 부문의 전반적인 추세를 이해하는 데 도움이 됩니다.`,
      iux_what_is_discription_3: `관찰 및 거래 지수는 경제의 다양한 부문이 서로 비교하여 어떻게 거래되는지에 대한 통찰력을 제공합니다. IUX Markets에서는 주요 지수에 대한 CFD 거래를 제공하게 되어 기쁘게 생각합니다. 이를 통해 가격 상승뿐만 아니라 하락에도 동참할 수 있습니다.`,
      iux_what_is_discription_4: `거래 지수는 포괄적인 조건, 여러 공급업체의 정확한 견적 및 다양한 분석으로 인해 IUX Markets 거래자들 사이에서 인기가 있습니다. 결국, 지수 행동의 논리를 이해하려면 포함된 각 회사의 기업 뉴스뿐만 아니라 더 넓은 산업 전반에 영향을 미치는 이벤트에 주의를 기울여야 합니다.`,
    },

    shares_what_is_block1: {
      iux_what_is_header: `주식은 무엇입니까?`,
      iux_what_is_discription_1: `당신을위한 주식 무역 CFD는 구매 및 판매하는 선택합니다. 가격 인상의 혜택 또는 감소 할 수 있도록하기 위해 각각 우리의 주식은 회사의 작업을 반영합니다. 당신이 살 때 그래서 당신은 배당금을 받게됩니다. 그리고 판매 배당금을 지불해야합니다`,
    },
    shares_what_is_block2: {
      iux_what_is_discription_1: `"주식 거래는 가장 인기 있는 투자 방법 중 하나입니다. IUX Markets에서는 일부 기업의 주식 CFD를 제공합니다. Apple, Coca Cola, Facebook과 같이 세계에서 가장 가치 있는 CFD가 있습니다. 그러나 투자자는 정보를 주의 깊게 연구해야 합니다. 거래하기 전에.`,
      // iux_what_is_discription_2: ''
    },
    metals_what_is_block1: {
      iux_what_is_header: `금속은 무엇입니까?`,
      iux_what_is_discription_1: `금속 현물 거래는 투자자가 자신의 투자 항목을 확장 할 수 있습니다. 구매 또는 판매 금은 반점 우리는 주문이 가장 정확하고 경쟁력있는 가격에 실행되는 것을 보장하기 위해 순서를 배치하여. 금 현물은 더 실버 자리보다 더 가치가있다. 그러나 모두는 가격이 매우 휘발성`,
    },
    metals_what_is_block2: {
      iux_what_is_discription_1: `금속 현물 거래는 종종 특히 경제적 불확실성의 시간 동안 안전한 투자, 안전한 피난처로 볼 수 있습니다. 당신은 당신의 진짜 계정 거래 전에 무료 데모 계정 스팟 금속 거래를 할 수 있습니다.`,
    },
    energies_what_is_block1: {
      iux_what_is_header: `Energices 무엇입니까?`,
      iux_what_is_discription_1: `IUX Markets에서 현물 에너지 거래를 시작하여 투자를 다양화하고 새로운 거래 기회를 활용하십시오. 현물 에너지는 MT5를 포함한 모든 거래 플랫폼에서 거래될 수 있습니다.`,
    },
    energies_what_is_block2: {
      iux_what_is_discription_1: `에너지 지점은 단기 거래를위한 인기있는 선택입니다. 특히 한 번에 할 때 실제 계정에 거래 전에 우리의 무료 데모 계정의 글로벌 곱한 비율 연습 거래 스팟 에너지에서 에너지 사용이 증가합니다.`,
    },
    iux_forex_page: {
      title_1: `CFDs 거래`,
      title_2: `70개 이상의 FX 쌍에 대한 CFDs 를 거래하고 긴밀한 스프레드와 빠른 주문 실행의 이점을 누리세요.`,
    },
    iux_futures_page: {
      title_1: `선물 거래`,
      title_2: `전시회 세계 각국의 선물에 레버리지 끝없는 거래 기회를 모색`,
    },
    iux_indices_page: {
      title_1: `스팟 지수 거래`,
      title_2: `유럽, 아시아, 미국 전역에서 인기 지수에 거래 레버리지.`,
    },
    iux_shares_page: {
      title_1: `공유 및 주식 거래`,
      title_2: `긴 위치에 빠른 주문 실행 및 배당금 지급에서 150 개 이상의 글로벌 기업의 주가와 이익에 거래 레버리지.`,
    },
    iux_metals_page: {
      title_1: `귀금속 거래`,
      title_2: `스팟 금속 및 해제 새로운 거래 기회에 대한 거래 레버리지`,
    },
    iux_energies_page: {
      title_1: `에너지 CFDs 거래`,
      title_2: `같은 브렌트 유, WTI와 천연 가스 및 포트폴리오를 다양 화 등의 스팟 에너지 CFDs 거래.`,
    },
  },
  //
  fil: {
    iux_forex_market: {
      title_1: `Average na oras ng pagpapatupad <0.001 ms`,
      title_2: `Hanggang sa 1,000 mga order pinaandar sa bawat segundo`,
      title_3: `Ultra-mababang latency datacentre co-location`,
    },
    button: {
      title_1: `MT4 `,
      title_2: `Demo ng Account`,
      sub_button: {
        title_1: `START TRADING NGAYON`,
        title_2: `Demo ng Account`,
      },
    },
    why_iux_header: {
      title: `Bakit pakikipagkalakalan sa IUX Markets`,
      header_1: `6 Years of Excellence`,
      text_1: `Pumili ng isang pinagkakatiwalaang broker na nagsisilbing mga kliyente sa higit sa 170 mga bansa.`,
      header_2: `Trade 250+ Instrumentong`,
      text_2: `Trade CFDs, Pagbabahagi, Futures, Spot Index, Spot Metals and Spot Energies`,
      header_3: `4 Trading Platform`,
      text_3: `Trade sa iyong mga ginustong platform kabilang ang IUX Markets  MT5`,
      header_4: `Mabilis na Pagpapatupad & Deep Pagkatubig`,
      text_4: ` Makinabang mula sa ultra-mabilis na pagkakasunod-sunod execution sa karamihan ng mga order pinaandar sa ilalim 11.06ms`,
      header_5: `70+ UK & International Awards`,
      text_5: `Trade sa isang broker na paulit-ulit na kinikilala para sa kalidad ng mga serbisyo nito.`,
      header_6: `24/7 Multilingual Support`,
      text_6: `Reap ang mga pakinabang ng aming 24/7 Customer Support, na nagbibigay ng tulong sa higit sa 4 na mga wika.`,
    },
    iux_award_trading: {
      iux_header_award: `Premyadong NDD execution`,
      iux_title_award: `Ang lahat ng client trades ay pinaandar na Walang Pakikitungo Desk1 interbensyon. Karamihan sa mga trades ay napunan wala pang 10 milliseconds., Na may hanggang sa 2,000 trades pinaandar sa bawat segundo.`,
      iux_platform: `Maramihang pagpopondo at withdrawal mga opsyon`,
      iux_sub_platform: `Kami ay nagbibigay ng aming mga kliyente na may isang malawak na hanay ng desktop, website at mobile na kalakalan platform kabilang ang   MetaTrader 5`,
    },
    forex_what_is_block1: {
      iux_what_is_header: `Ano ang CFDs?`,
      iux_what_is_discription_1: `Ang mga banyagang exchange market (FX) bilang isang kabuuan, ay binubuo ng maraming mga uri ng mga merkado, kabilang Spot FX, Future derivatives, Forward Derivatives, at panghuli ang CFDs derivatives merkado, kung saan ay ang pinaka-popular na para sa tingian kliyente. Lahat ng CFDs kalakalan mga transaksyon na sinamahan make up ang pinakamalaking at pinaka-likido pinansiyal na merkado, na may isang average na araw-araw na dami ng higit sa $ 5 trilyon.`,
      iux_what_is_discription_2: `Ang FX CFDs derivatives market ay binubuo ng mga mamimili at nagbebenta, ang pangunahing kalahok sa pagiging malaking internasyonal na mga bangko, na ilagay ang mga order sa pamamagitan ng electronic system trading. Market na ito ay traded OTC (hindi traded sa anumang regulated exchange) at dahil dito walang pare-parehong presyo ngunit bawat isa sa mga pangunahing internasyonal na mga bangko ay nagbibigay ng kanyang sariling mga quote sa mga spot market kumikilos bilang punto ng sanggunian para sa mga quotes na ibinigay.`,
      iux_what_is_discription_3: `Ito ay nagkakahalaga mentioning na ang mga spot FX market ay din ng isang OTC merkado dominado sa pamamagitan ng malaking internasyonal na mga bangko.`,
      iux_what_is_discription_4: `Sa CFDs kalakalan, lugar na presyo ng isang pares na pera ay naiimpluwensyahan ng maraming mga kadahilanan, tulad ng mga pang-ekonomiyang pananaw at geopolitical kaganapan sa rehiyon na iyon, pati na rin ang mga balita release ng data na maaaring ma-pinaghihinalaang positibo o negatibong sa pamamagitan ng merkado.`,
      iux_what_is_discription_5: `Kontrata para pagkakaiba (CFDs), payagan ang mga mangangalakal na bumili (pumunta mahaba) o ibenta (pumunta maikling), at make profit o pagkawala mula sa mga paggalaw ng presyo, nang hindi na kinakailangang pisikal na pagbili at makipagpalitan ng ang kalakip na pera.`,
      iux_what_is_discription_6: `FX ay naka-quote sa mga pares, sa bawat kumakatawan sa isang pandaigdigang pananalapi o ekonomiya. Ang unang pera ay tinatawag na 'base' ng pera (na kumakatawan sa lakas ng tunog na nais mong kalakalan) at ang pangalawa ay tinatawag na 'term' o 'quote' pera (na kumakatawan sa kasalukuyang exchange rate).`,
    },
    forex_what_is_block2: {
      iux_what_is_discription_1: `Halimbawa, ang presyo ng EUR / USD ay kumakatawan sa halaga na $ USD na maaaring ipalit sa € 1.`,
      iux_what_is_discription_2: `EUR / USD = 1.11361`,
      iux_what_is_discription_3: `Ito ay nangangahulugan na sa kasalukuyan, € 1 ay katumbas ng $ 1.11361`,
      iux_what_is_discription_4: `Ang mga presyo ay patuloy na nagbabago batay sa kundisyon ng merkado.`,
      iux_what_is_discription_5: `Upang ilagay ito lamang, mangangalakal ay pumunta mahaba kung naniniwala sila na ang batayang pera ay tumaas ang halaga laban sa matagalang pera at gusto kumita mula sa isang pagtaas sa mga presyo. Sa kabilang dako, kung mangangalakal 'naniniwala na ang halaga ng batayang pera ay mahulog na may kaugnayan sa mga kataga, sila maglagay ng sell ng kalakalan upang subukang kita mula sa bumabagsak na mga presyo. Kung ang mga presyo ilipat sa tapat ng direksyon sa mga mangangalakal 'forecast, sila ay gumawa ng isang pagkawala.`,
      iux_what_is_discription_6: `FX pera kalakalan ay karaniwang kinakalkula sa PIPs, ibig sabihin na depende sa iyong kalakalan na sukat, ang bawat PIPs ay katumbas ng ang halaga ng mga tiyak na hinggil sa pananalapi ng 'term na' pera. Ito PIPs halaga ng isang ay ginamit upang matukoy ang PNL (profit o pagkawala), batay sa kung gaano karaming mga PIPs kang makakuha ng o mawalan sa isang kalakalan, at ginagamit din upang display pagkalat (ang pagkakaiba sa pagitan ng mga bid at hilingin sa presyo).`,
      iux_what_is_discription_7: `Sa IUX Markets namin quote ang lahat ng FX pares sa isang dagdag na digit na pagkatapos ng PIPs, ibig sabihin na ang huling digit sa anumang quote ay tumutukoy sa isang Point (10% ng isang PIPs).`,
      iux_what_is_discription_8: `Sa FX pera kalakalan, fractional pagpepresyo ay nagbibigay-daan sa amin upang mag-alok mas mahigpit spread at magbigay ng mas tumpak na pagpepresyo.`,
      iux_what_is_discription_9: `Kung ikaw ay bago sa online CFDs kalakalan, nais naming inirerekumenda pagpunta sa pamamagitan ng aming online na seksyon pang-edukasyon na maging pamilyar sa mga merkado at kung paano 'Kontrata para sa Pagkakaiba' kalakalan gawa. Nagbibigay din kami ng 'relos at matuto' video at mga PDF gabay.`,
    },
    futures_what_is_block1: {
      iux_what_is_header: `Ano ang Futures?`,
      iux_what_is_discription_1: `Futures ay mga pinansiyal na mga kontrata para sa mga tiyak na mga asset na binili o ibinebenta sa isang set na oras sa hinaharap. Ang huling antas ng presyo ay natutukoy sa pamamagitan ng parehong partido, ang isang bumibili at nagbebenta, at ay kilala bilang ang forward presyo. Ang tinukoy na petsa sa hinaharap pagbabayad ay kilala bilang ang expiration date.`,
    },
    futures_what_is_block2: {
      iux_what_is_discription_1: `Kontrata para sa pagkakaiba (CFDs) sa futures pahintulutan para sa kalakalan sa presyo pagbabagu-bago ng isang malawak na hanay ng mga ari-arian: mga kailanganin, mga indeks at energies. Kung mamumuhunan gumawa ng isang pagtataya tungkol sa mga quotes dagdagan, binuksan nila ang isang Bilhin posisyon sa platform ng kalakalan. Kung sa tingin nila ang tsart ay bababa, na gumawa sila ng isang online na order sa Ibenta. Simulan ang kalakalan futures sa IUX Markets-iba-ibahin ang iyong mga mamumuhunan account!`,
    },
    indices_what_is_block1: {
      iux_what_is_header: `Ano ang Index?`,
      iux_what_is_discription_1: `World indeks ay mga tagapagpahiwatig ng mga pagbabago presyo para sa isang tiyak na grupo ng mga mahalagang papel. Ang stock exchange index maaaring ipinaliwanag bilang isang "basket" ng namamahagi nagkakaisa sa pamamagitan ng isang karaniwang na batayan. Kalakalan indeks maaaring maihambing sa pagbubukas ng mga posisyon sa mga kurso ng ilang dosenang mga stock nang sabay-sabay.`,
      iux_what_is_discription_2: `Ang pinaka-mahalagang bagay ay pagtukoy ng eksaktong stock o bono bawat index ay nabuo mula sa. Ang hanay ng mga namamahagi na kasama sa pagkalkula spot index halaga ay tumutukoy sa impormasyon na maaaring nakuha sa pamamagitan ng obserbahan ang dynamics ng kanyang kurso.`,
      iux_what_is_discription_3: `Sa pangkalahatan, ang pangunahing layunin ng mga indeks ng mundo ay upang lumikha ng isang malakas na tagapagpahiwatig para sa mga mamumuhunan upang makilala ang mga direksyon ng mga kompanya ng 'mga quote sa isang partikular na industriya. Pag-aaral ng dinamika ng mga pangunahing mga indeks ay tumutulong upang maunawaan ang mga epekto ng mga tiyak na mga kaganapan sa halaga ng mga mahalagang papel.`,
      iux_what_is_discription_4: `Sa panahon kalakalan indeks, panatilihin sa isip na ang reaksyon sa pang-ekonomiyang mga balita na-publish ay maaaring hindi tumutugma sa mga inaasahan at mga pagtataya.`,
    },
    indices_what_is_block2: {
      iux_what_is_discription_1: `Halimbawa, kung mayroong isang pagtaas sa mga presyo ng langis, ito ay lohikal na upang asahan ang isang pagtaas sa ang namamahagi ng lahat ng mga kompanya ng langis.`,
      iux_what_is_discription_2: `Gayunpaman, iba't-ibang mga stock lumago sa iba't ibang bilis, habang ang ilan sa kanila ay maaaring tumugon sa naturang balita sa lahat. Sa kasong ito, ang lugar index ay tumutulong sa mga mangangalakal upang maunawaan ang pangkalahatang trend ng market segment nang hindi nangangailangan upang masuri ang posisyon ng maraming iba't ibang mga kumpanya.`,
      iux_what_is_discription_3: `Pagmamasid at kalakalan indeks magbigay ng pananaw sa kung paano ang iba't-ibang mga sektor ng ekonomiya sa kalakalan sa paghahambing sa bawat isa. Dito sa IUX Markets kami ay nalulugod upang mag-alok ng kalakalan ng CFDs sa mga pangunahing mga indeks, na ginagawang posible upang sumali sa presyo kilusan ay hindi lamang para sa isang pagtaas, ngunit din ng isang tag-lagas.`,
      iux_what_is_discription_4: `Kalakalan mga indeks ay popular sa mga IUX Markets mangangalakal dahil sa kanyang komprehensibong mga tuntunin, tumpak na mga panipi mula sa ilang mga supplier at maraming nagagawa analytics. Matapos ang lahat, upang maunawaan ang logic ng pag-uugali index, kailangan mong magbayad ng pansin sa mga corporate balita ng bawat isa sa mga kumpanya kasama, pati na rin sa mga kaganapan na nakakaapekto sa mas malawak na industriya bilang isang buo.`,
    },

    shares_what_is_block1: {
      iux_what_is_header: `Ano ang Shares?`,
      iux_what_is_discription_1: `Trade CFDs na may mga stock para sa iyo upang pumili upang bumili at magbenta. Upang maging magagawang upang makinabang mula sa pagtaas ng presyo o bawasan ayon sa pagkakabanggit Ang aming mga namamahagi sumalamin ang mga aksyon ng kumpanya. Kaya makakatanggap ka ng dividends kapag bumili ka. At kailangang magbayad para sa mga dividends ibinebenta`,
    },
    shares_what_is_block2: {
      iux_what_is_discription_1: `Stock na kalakalan ay ang pinaka-popular na paraan ng investment. Sa IUX Markets, nagbibigay kami ng mga CFDs para sa ilang mga kompanya ng 'mga pagbabahagi. Ang pinaka-mahalagang sa mundo, tulad ng Apple, Coca Cola at Facebook. Gayunpaman, namumuhunan ay dapat pag-aralan ang impormasyon nang mabuti bago kalakalan.`,
      // iux_what_is_discription_2: ''
    },
    metals_what_is_block1: {
      iux_what_is_header: `Ano ang Metal?`,
      iux_what_is_discription_1: `Metal spot kalakalan ay nagbibigay-daan mamumuhunan upang palawakin ang kanilang mga item investment. Sa pamamagitan ng paglalagay ng isang order upang bumili o magbenta ng ginto at pilak spot Ginagarantiya namin na ang iyong order ay pinaandar sa pinaka-tumpak at mapagkumpitensya presyo. Ang ginto spot ay nagkakahalaga ng higit pa kaysa sa pilak spot. Ngunit ang pareho ay napaka salawahan sa presyo`,
    },
    metals_what_is_block2: {
      iux_what_is_discription_1: `Metal spot kalakalan ay madalas na makikita bilang isang ligtas na investment, isang ligtas na kanlungan, lalo na sa panahon ng mga oras ng pang-ekonomiya kawalan ng katiyakan. Maaari mong gawin Spot Metal transaksyon para sa isang libreng account demo bago ang transaksyon sa iyong tunay na account.`,
    },
    energies_what_is_block1: {
      iux_what_is_header: `Ano ang Energices?`,
      iux_what_is_discription_1: `Simulan ang kalakalan spot enerhiya na may IUX Markets upang gumawa ng isang iba't ibang mga pamumuhunan at samantalahin ng mga bagong pagkakataon sa negosyo. Power spot ay maaaring traded sa lahat ng aming mga platform kalakalan, kabilang ang MT5, cTrader at IUX Markets.`,
    },
    energies_what_is_block2: {
      iux_what_is_discription_1: `Ang enerhiya na lugar ay isang popular na pagpipilian para sa short-term na mga transaksyon. Lalo na sa isang pagkakataon kapag ang enerhiya ng paggamit nagdaragdag sa isang global pinaraming rate Practice kalakalan Spot Energies sa aming libreng account demo bago ang pangangalakal sa isang tunay na account.`,
    },
    iux_forex_page: {
      title_1: `CFDs kalakalan`,
      title_2: `Trade CFDs sa 70 + FX pares at makinabang sa masikip spread at mabilis na pagkakasunod-sunod execution.`,
    },
    iux_futures_page: {
      title_1: `futures kalakalan`,
      title_2: `Trade CFDs sa Futures mula sa buong mundo at tuklasin walang katapusang mga pagkakataon kalakalan`,
    },
    iux_indices_page: {
      title_1: `Spot indeks kalakalan`,
      title_2: `Trade CFDs sa mga sikat Index mula sa buong Europa, Asya at Amerika.`,
    },
    iux_shares_page: {
      title_1: `Ibahagi & Stock kalakalan`,
      title_2: `Trade CFDs sa Pagbabahagi ng 150+ pandaigdigang mga kumpanya at mga benepisyo mula sa mabilis na pagkakasunod-sunod execution at dibidendo kabayaran sa matagal na posisyon.`,
    },
    iux_metals_page: {
      title_1: `Precious metal kalakalan`,
      title_2: `Trade CFDs sa Spot Metal at alisin ang maskara ng mga bagong pagkakataon sa kalakalan`,
    },
    iux_energies_page: {
      title_1: `Trade CFDs sa Energies`,
      title_2: `Trade CFDs sa Spot Energies tulad ng Brent langis, WTI at Natural Gas at iba-ibahin ang iyong portfolio.`,
    },
  },
  ja: {
    iux_forex_market: {
      title_1: "平均実行時間<0.001 ms",
      title_2: "1秒あたりに最大1,000の注文が実行されました",
      title_3: "超低レイテンシデータセンター nco-location",
    },
    why_iux_header: {
      title: "IUX市場と取引する理由",
      header_1: "6年の卓越性",
      text_1:
        "170か国以上のクライアントにサービスを提供する信頼できるブローカーを選択してください。",
      header_2: "250以上の楽器を取引します",
      text_2:
        "CFD、株式、先物、スポットインデックス、スポット金属、スポットエネルギーを取引します。",
      header_3: "4つの取引プラットフォーム",
      text_3: "IUX Markets MT5を含むお好みのプラットフォームで取引",
      header_4: "迅速な実行と深い流動性",
      text_4:
        "11.06ms未満で実行されたほとんどの注文で、超高速注文実行の恩恵を受けます。",
      header_5: "10以上の英国および国際賞",
      text_5:
        "サービスの品質について繰り返し認められているブローカーとの取引。",
      header_6: "24/7の多言語サポート",
      text_6:
        "24時間年中無休のカスタマーサポートの利点を享受し、4つ以上の言語で支援を提供します。",
    },
    iux_award_trading: {
      iux_header_award: "受賞歴のあるNDD実行",
      iux_title_award:
        "すべてのクライアント取引は、DESK1介入なしで実行されます。 ほとんどの取引は10ミリ秒未満で埋められており、1秒あたり最大2,000の取引が実行されます。",
      iux_platform: "取引プラットフォーム",
      iux_sub_platform:
        "すべてのプラットフォームで取引できます。 デスクトップ、ウェブサイト、モバイルトレーディングプラットフォーム、Metatrader5",
    },
    forex_what_is_block1: {
      iux_what_is_header: "CFDとは何ですか？",
      iux_what_is_discription_1:
        "契約契約（CFDS）全体として、スポットCFD、将来のデリバティブ、フォワードデリバティブ、最後に小売クライアントに最も人気のあるCFDSデリバティブ市場など、多くのタイプの市場で構成されています。 すべてのCFDS取引トランザクションを組み合わせて、最大かつ最も流動的な金融市場を構成し、1日平均5兆ドルを超えています。",
      iux_what_is_discription_2:
        "CFDSデリバティブ市場は買い手と売り手で構成されており、主な参加者は大規模な国際銀行であり、電子取引システムを介して注文を行います。 この市場はOTC（規制された取引所で取引されていない）と取引されているため、均一な価格はありませんが、それぞれの国際銀行は、提供された引用の参照ポイントとして機能するスポット市場に独自の見積もりを提供しています。",
      iux_what_is_discription_3:
        "スポットCFDS市場は、大規模な国際銀行が支配するOTC市場でもあることに言及する価値があります。",
      iux_what_is_discription_4:
        "CFDS取引では、通貨ペアのスポット価格は、その地域の経済的見通しや地政学的なイベントなど、市場で積極的または否定的に認識される可能性のあるニュースデータリリースの影響を受けます。",
      iux_what_is_discription_5:
        "差異（CFD）の契約、トレーダーが購入（長く販売）または販売（短くする）、および基礎となる通貨を物理的に購入して交換することなく、価格の動きから利益または損失を出すことができます。",
      iux_what_is_discription_6:
        "CFDはペアで引用され、それぞれが世界の通貨または経済を表しています。 最初の通貨は「ベース」通貨（取引するボリュームを表す）と呼ばれ、2番目は「用語」または「引用」通貨（現在の為替レートを表す）と呼ばれます。",
    },
    forex_what_is_block2: {
      iux_what_is_discription_1:
        "たとえば、EUR/USDの価格は、1ユーロで交換できる$ USDの金額を表しています。",
      iux_what_is_discription_2: "EUR/USD = 1.11361",
      iux_what_is_discription_3:
        "これは、現在、1ユーロが1.11361ドルに相当することを意味します",
      iux_what_is_discription_4:
        "価格は市場の状況に基づいて常に変動しています。",
      iux_what_is_discription_5:
        "簡単に言えば、基本通貨の価値が期間通貨に対して上昇し、価格の上昇から利益を得ると信じれば、トレーダーは長く進むでしょう。 一方、トレーダーが基本通貨の価値が期間に関連して低下すると信じている場合、彼らは価格の下落から利益を得ようとするために売り取引を行います。 価格がトレーダーの予測とは反対の方向に移動すると、彼らは損失を出します。",
      iux_what_is_discription_6:
        "CFDS通貨取引は通常、PIPで計算されます。つまり、取引規模によっては、各PIPが「用語」通貨の特定の金銭的価値に等しいことを意味します。 このPIPS値は、貿易で得られるまたは失われたPIPの数に基づいて、PNL（利益または損失）を決定するために使用され、スプレッドを表示するためにも使用されます（入札価格とASK価格の違い）。",
      iux_what_is_discription_7:
        "IUX Marketsでは、PIPSの後にすべてのCFDペアが追加桁までペアを引用します。つまり、どの見積りの最後の数字がポイント（PIPSの10％）を指します。",
      iux_what_is_discription_8:
        "CFDS通貨取引では、分数価格設定により、より厳しいスプレッドを提供し、より正確な価格設定を提供することができます。",
      iux_what_is_discription_9:
        "オンラインのCFDS取引を初めて使用する場合は、オンライン教育セクションを調べて、市場と「違いの契約」がどのように機能するかに精通することをお勧めします。 また、「監視と学習」ビデオとPDFガイドも提供しています。",
    },
    futures_what_is_block1: {
      iux_what_is_header: "先物とは何ですか？",
      iux_what_is_discription_1:
        "先物は、特定の資産が将来設定された時間に売買されるための財務契約です。 最終的な価格レベルは、両当事者、買い手と売り手によって決定され、先物価格として知られています。 将来の支払いの指定日は、有効期限として知られています。",
    },
    futures_what_is_block2: {
      iux_what_is_discription_1:
        "先物の差異契約（CFD）により、商品、インデックス、エネルギーなど、幅広い資産の価格変動の貿易が可能になります。 投資家が見積もりの増加について予測を行うと、取引プラットフォームで購入ポジションを開きます。 チャートが下に移動すると考えている場合、彼らはオンラインで販売するために注文します。 IUX市場との取引を開始して、投資家アカウントを多様化してください！",
    },
    indices_what_is_block1: {
      iux_what_is_header: "インデックスとは何ですか？",
      iux_what_is_discription_1:
        "世界インデックスは、特定の証券グループの価格変更の指標です。 証券取引所指数は、共通の根拠によって結合された株式の「バスケット」として説明できます。 取引インデックスは、数十株のコース上のオープニングポジションと一度に比較できます。",
      iux_what_is_discription_2:
        "最も重要なことは、各インデックスが形成される正確な在庫または債券を決定することです。 スポットインデックス値の計算に含まれる株式のセットは、そのコースのダイナミクスを観察することで得られる情報を決定します。  n",
      iux_what_is_discription_3:
        "一般に、世界のインデックスの主な目的は、投資家が特定の業界における企業の引用の方向性を特徴付ける強力な指標を作成することです。 主要なインデックスのダイナミクスを研究することは、証券の価値に対する特定のイベントの影響を理解するのに役立ちます。  n",
      iux_what_is_discription_4:
        "取引指数中に、公開された経済ニュースに対する反応は、期待や予測に対応していない可能性があることに留意してください。 n",
    },
    indices_what_is_block2: {
      iux_what_is_discription_1:
        "インデックスは、特定の市場に関する予測を提供します。 したがって、個々の株式とは対照的に、インデックスで表される経済セクターの変化に関する立場を探している投資家にとって非常に人気があります。",
      iux_what_is_discription_2:
        "ただし、異なる株は異なる速度で成長しますが、その一部はそのようなニュースにまったく応答しない場合があります。 この場合、スポットインデックスは、多くの異なる企業の位置を評価する必要なく、トレーダーがこの市場セグメントの全体的な傾向を理解するのに役立ちます。",
      iux_what_is_discription_3:
        "観察と取引の指標は、経済のさまざまなセクターが互いに比較してどのように取引されるかについての洞察を与えます。 ここIUX Marketsでは、主要なインデックスでCFDの取引を提供できることを嬉しく思います。",
      iux_what_is_discription_4:
        "取引インデックスは、包括的な条件、いくつかのサプライヤーからの正確な引用、および多目的分析により、IUX市場トレーダーの間で人気があります。 結局のところ、インデックスの動作の論理を理解するには、含まれる各企業の企業ニュース、およびより広い業界全体に影響を与えるイベントに注意を払う必要があります。",
    },

    shares_what_is_block1: {
      iux_what_is_header: "株式とは何ですか？",
      iux_what_is_discription_1:
        "あなたが売買することを選択するための株式とCFDを取引します。 それぞれの株式が会社の行動を反映して、それぞれ価格上昇または引き下げから利益を得ることができるようにします。 したがって、購入すると配当を受け取ります。 販売された配当の代金を支払う必要があります",
    },
    shares_what_is_block2: {
      iux_what_is_discription_1:
        "取引株は、最も人気のある投資方法の1つです。 IUX Marketsでは、一部の企業にCFDを共有しています。 Apple、Coca Cola、Facebookなど、世界で最も価値のあるものがいくつかあります。 ただし、投資家は取引する前に情報を慎重に調査する必要があります。",
      iux_what_is_discription_2: "",
    },
    metals_what_is_block1: {
      iux_what_is_header: "IUX市場と取引します",
      iux_what_is_discription_1:
        "スポットメタルトレーディングにより、投資家はポートフォリオを拡大し、スポットゴールドとシルバーを売買する注文を出すことができます。 n注文が最も正確で競争力のある価格で実行されることを保証します。 Spot GoldはSpot Silverよりも価値がありますが、どちらも優れた価格の変動があります。",
    },
    metals_what_is_block2: {
      iux_what_is_discription_1:
        "スポットメタル取引は、特に不安定な経済の時代に、安全な避難所投資と見なされることがよくあります。 実際のアカウントに取引を配置する前に、無料のデモアカウントのSpot Metalsトランザクションを実行できます。",
    },
    energies_what_is_block1: {
      iux_what_is_header: "IUX市場とスポットエネルギーを取引します",
      iux_what_is_discription_1:
        "IUX市場でスポットエネルギーの取引を開始して、投資を多様化し、新しい取引の機会を活用してください。",
    },
    energies_what_is_block2: {
      iux_what_is_discription_1:
        "エネルギースポットは、特にグローバルな乗算率とともにエネルギー使用が増加する時期に、短期トランザクションに人気のある選択肢です。 実際のアカウントに取引を配置する前に、無料のデモアカウントでトレーディングスポットエネルギーを練習します。",
    },
    iux_forex_page: {
      title_1: "CFDS取引",
      title_2:
        "70以上のFXペアでCFDを取引し、緊密なスプレッドと速い注文の実行の恩恵を受けます。",
    },
    iux_futures_page: {
      title_1: "先物取引",
      title_2: "世界中の先物をCFDを取引し、無限の取引の機会を探求する",
    },
    iux_indices_page: {
      title_1: "スポットインデックスの取引",
      title_2:
        "ヨーロッパ、アジア、アメリカ全土の一般的なインデックスでCFDを取引しています。",
    },
    iux_shares_page: {
      title_1: "株式と株式取引",
      title_2:
        "150以上のグローバル企業の株式でCFDを取引し、長い職位での迅速な注文の実行と配当支払いの恩恵を受けます。",
    },
    iux_metals_page: {
      title_1: "貴金属取引",
      title_2: "スポットメタルでCFDを取引し、新しい取引の機会をマスクする",
    },
    iux_energies_page: {
      title_1: "エネルギーについてCFDを取引します",
      title_2:
        "ブレントオイル、WTI、天然ガスなどのスポットエネルギーでCFDを取引し、ポートフォリオを多様化します。",
    },
    button: {
      title_1: "MT4",
      title_2: "デモアカウント",
      sub_button: {
        title_1: "今すぐ取引を開始します",
        title_2: "デモアカウント",
      },
    },
  },
  es: {
    iux_forex_market: {
      title_1: "Tiempo medio de ejecución < 0.001 ms",
      title_2: "Hasta 1,000 órdenes ejecutadas por segundo",
      title_3: "Centro de datos de ultra baja latencia \nco-ubicación",
    },
    why_iux_header: {
      title: "Por qué comerciar con IUX Markets",
      header_1: "6 Años de Excelencia ",
      text_1:
        "Elija un corredor de confianza que atienda a clientes en más de 170 países.",
      header_2: "Comercie con más de 250 Instrumentos ",
      text_2:
        "Comercie con CFDs, Acciones, Futuros, Índices al Contado, Metales al Contado y Energías al Contado.",
      header_3: "4 Plataformas de Trading",
      text_3: "Comercie en su plataforma preferida, incluyendo IUX Markets MT5",
      header_4: "Ejecución Rápida y Liquidez Profunda",
      text_4:
        "Benefíciese de una ejecución de órdenes ultrarrápida con la mayoría de las órdenes ejecutadas en menos de 11.06ms.",
      header_5: "10+ Premios del Reino Unido e Internacionales",
      text_5:
        "Comercie con un corredor que ha sido repetidamente reconocido por la calidad de sus servicios.",
      header_6: "Soporte Multilingüe 24/7",
      text_6:
        "Aproveche los beneficios de nuestro Soporte al Cliente 24/7, proporcionando asistencia en más de 4 idiomas.",
    },
    iux_award_trading: {
      iux_header_award: "Ejecución NDD Premiada",
      iux_title_award:
        "Todas las operaciones de los clientes se ejecutan sin intervención de No Dealing Desk1. La mayoría de las operaciones se completan en menos de 10 milisegundos, con hasta 2,000 operaciones ejecutadas por segundo.",
      iux_platform: "Plataforma de Trading",
      iux_sub_platform:
        "Disponible para comerciar en todas las plataformas; escritorio, web, plataforma de trading móvil y MetaTrader5",
    },
    forex_what_is_block1: {
      iux_what_is_header: "¿Qué son los CFDs?",
      iux_what_is_discription_1:
        "El Contrato por Diferencia (CFDs) en su totalidad, consta de muchos tipos de mercados, incluidos los CFDs al contado, derivados futuros, derivados a plazo y, finalmente, el mercado de derivados CFDs, que es el más popular para los clientes minoristas. Todas las transacciones de comercio de CFDs combinadas forman el mercado financiero más grande y líquido, con un volumen diario promedio de más de $5 billones.",
      iux_what_is_discription_2:
        "El mercado de derivados CFDs está compuesto por compradores y vendedores, siendo los principales participantes los grandes bancos internacionales, que realizan pedidos a través de sistemas de comercio electrónico. Este mercado se comercia OTC (no se comercia en ninguna bolsa regulada) y como tal, no existe un precio uniforme, pero cada uno de los principales bancos internacionales proporciona sus propias cotizaciones, siendo el mercado al contado el punto de referencia para las cotizaciones proporcionadas.",
      iux_what_is_discription_3:
        "Vale la pena mencionar que el mercado al contado de CFDs también es un mercado OTC dominado por los grandes bancos internacionales.",
      iux_what_is_discription_4:
        "En el comercio de CFDs, el precio al contado de un par de divisas se ve influenciado por varios factores, como las perspectivas económicas y los eventos geopolíticos en esa región, así como las publicaciones de datos de noticias que pueden ser percibidas positiva o negativamente por el mercado.",
      iux_what_is_discription_5:
        "Los Contratos por Diferencia (CFDs), permiten a los comerciantes comprar (ir en largo) o vender (ir en corto) y obtener ganancias o pérdidas por los movimientos de precios, sin tener que comprar y cambiar físicamente la moneda subyacente.",
      iux_what_is_discription_6:
        "Los CFDs se cotizan en pares, y cada uno representa una moneda o economía global. La primera moneda se llama 'moneda base' (que representa el volumen que desea comerciar) y la segunda se llama 'moneda de término' o 'moneda de cotización' (que representa la tasa de cambio actual).",
    },
    forex_what_is_block2: {
      iux_what_is_discription_1:
        "Por ejemplo, el precio del EUR/USD representa la cantidad de USD que se puede cambiar por 1 EUR.",
      iux_what_is_discription_2: "EUR/USD = 1.11361",
      iux_what_is_discription_3:
        "Esto significa que actualmente, 1 EUR es igual a 1.11361 USD.",
      iux_what_is_discription_4:
        "Los precios fluctúan constantemente según las condiciones del mercado.",
      iux_what_is_discription_5:
        "En pocas palabras, los comerciantes irán en largo si creen que la moneda base aumentará en valor frente a la moneda de término y obtendrán ganancias con el aumento del precio. Por otro lado, si los comerciantes creen que el valor de la moneda base disminuirá en relación con la moneda de término, colocarán una operación de venta para intentar obtener ganancias con la caída de los precios. Si los precios se mueven en la dirección opuesta a la prevista por los comerciantes, sufrirán una pérdida.",
      iux_what_is_discription_6:
        "El comercio de divisas con CFDs generalmente se calcula en PIPs, lo que significa que dependiendo del tamaño de su operación, cada PIPs es igual a un valor monetario específico de la 'moneda de término'. Este valor de PIPs se utiliza para determinar el PnL (ganancia o pérdida), basado en cuántos PIPs gana o pierde en una operación, y también se utiliza para mostrar el spread (la diferencia entre los precios de compra y venta).",
      iux_what_is_discription_7:
        "En IUX Markets, cotizamos todos los pares de CFDs con un dígito extra después del PIPs, lo que significa que el último dígito en cualquier cotización se refiere a un Punto (10% de un PIPs).",
      iux_what_is_discription_8:
        "En el comercio de divisas con CFDs, la fijación de precios fraccionarios nos permite ofrecer spreads más ajustados y proporcionar precios más precisos.",
      iux_what_is_discription_9:
        "Si es nuevo en el comercio de CFDs en línea, le recomendamos que revise nuestra sección educativa en línea para familiarizarse con el mercado y cómo funciona el comercio de 'Contratos por Diferencia'. También proporcionamos videos de 'ver y aprender' y guías en PDF.",
    },
    futures_what_is_block1: {
      iux_what_is_header: "¿Qué es el Futuros?",
      iux_what_is_discription_1:
        "Los futuros son contratos financieros para que el activo específico sea comprado o vendido en un momento determinado en el futuro. El nivel de precio final es determinado por ambas partes, un comprador y un vendedor, y se conoce como el precio a futuro. La fecha especificada del pago futuro se conoce como la fecha de vencimiento.",
    },
    futures_what_is_block2: {
      iux_what_is_discription_1:
        "El contrato por diferencia (CFDs) en futuros permite comerciar con las fluctuaciones de precios de una amplia gama de activos: materias primas, índices y energías. Si los inversores hacen una previsión sobre el aumento de las cotizaciones, abren una posición de compra en la plataforma de comercio. Si creen que el gráfico se moverá hacia abajo, realizan una orden de venta en línea. ¡Comience a comerciar futuros con IUX Markets para diversificar su cuenta de inversor!",
    },
    indices_what_is_block1: {
      iux_what_is_header: "¿Qué es Índices?",
      iux_what_is_discription_1:
        "Los índices mundiales son indicadores de cambios de precios para un grupo determinado de valores. El índice bursátil puede explicarse como una 'cesta' de acciones unidas por una base común. Comerciar con índices puede compararse con abrir posiciones sobre los cursos de varias docenas de acciones a la vez.",
      iux_what_is_discription_2:
        "Lo más importante es determinar las acciones o bonos exactos de los que se compone cada índice. El conjunto de acciones incluidas en el cálculo del valor del índice al contado determina la información que puede obtenerse al observar la dinámica de su curso.",
      iux_what_is_discription_3:
        "En general, el propósito principal de los índices mundiales es crear un indicador poderoso para que los inversores caractericen la dirección de las cotizaciones de las empresas en una industria en particular. Estudiar la dinámica de los principales índices ayuda a comprender el impacto de ciertos eventos en el valor de los valores.",
      iux_what_is_discription_4:
        "Durante el comercio de índices, tenga en cuenta que la reacción a las noticias económicas publicadas puede no corresponder con las expectativas y previsiones.",
    },
    indices_what_is_block2: {
      iux_what_is_discription_1:
        "El índice ofrece una previsión sobre un mercado específico. En contraste con las acciones individuales, por lo tanto, es muy popular entre los inversores que buscan una posición sobre los cambios en el sector de la economía representado por el índice.",
      iux_what_is_discription_2:
        "Sin embargo, diferentes acciones crecen a diferentes velocidades, mientras que algunas de ellas pueden no responder a tales noticias en absoluto. En este caso, el índice al contado ayuda a los comerciantes a comprender la tendencia general de este segmento del mercado sin necesidad de evaluar la posición de muchas empresas diferentes.",
      iux_what_is_discription_3:
        "La observación y el comercio de índices ofrecen una visión de cómo los diferentes sectores de la economía comercian en comparación entre sí. Aquí en IUX Markets nos complace ofrecer el comercio de CFDs sobre los principales índices, lo que permite unirse al movimiento de precios no solo para una subida, sino también para una caída.",
      iux_what_is_discription_4:
        "Comerciar con índices es popular entre los comerciantes de IUX Markets debido a sus términos integrales, cotizaciones precisas de varios proveedores y análisis versátiles. Después de todo, para comprender la lógica del comportamiento del índice, debe prestar atención a las noticias corporativas de cada una de las empresas incluidas, así como a los eventos que afectan a la industria en general.",
    },
    shares_what_is_block1: {
      iux_what_is_header: "¿Qué son las acciones?",
      iux_what_is_discription_1:
        "Comerciar con CFDs de acciones le permite elegir comprar y vender. Para poder beneficiarse del aumento o disminución del precio, respectivamente, nuestras acciones reflejan las acciones de la empresa. Por lo tanto, recibirá dividendos cuando compre y tendrá que pagar los dividendos vendidos.",
    },
    shares_what_is_block2: {
      iux_what_is_discription_1:
        "Comerciar con acciones es uno de los métodos de inversión más populares. En IUX Markets, ofrecemos algunos CFDs de acciones de empresas. Algunas de las más valiosas del mundo, como Apple, Coca Cola y Facebook. Sin embargo, los inversores deben estudiar la información detenidamente antes de comerciar.",
      iux_what_is_discription_2: "",
    },
    metals_what_is_block1: {
      iux_what_is_header: "Comercie con metales en IUX Markets",
      iux_what_is_discription_1:
        "El comercio de metales al contado permite a los inversores expandir su cartera, realizando órdenes de compra o venta de oro y plata al contado. Garantizamos que su orden se ejecuta a los precios más precisos y competitivos. El oro al contado es más valioso que la plata al contado, pero ambos tienen grandes fluctuaciones de precios.",
    },
    metals_what_is_block2: {
      iux_what_is_discription_1:
        "El comercio de metales al contado se considera a menudo una inversión refugio, especialmente en tiempos de inestabilidad económica. Puede realizar transacciones de Metales al Contado en nuestra cuenta de demostración gratuita antes de realizar cualquier operación en una cuenta real.",
    },
    energies_what_is_block1: {
      iux_what_is_header: "Comercie con energía al contado en IUX Markets",
      iux_what_is_discription_1:
        "Comience a comerciar con energía al contado en IUX Markets para diversificar sus inversiones y aprovechar nuevas oportunidades de comercio. La energía al contado puede comerciarse en todas nuestras plataformas de trading, incluyendo MT5.",
    },
    energies_what_is_block2: {
      iux_what_is_discription_1:
        "Las energías al contado son una opción popular para transacciones a corto plazo, especialmente en un momento en que el uso de energía aumenta con la tasa de multiplicación global. Practique el comercio de Energías al Contado en nuestra cuenta de demostración gratuita antes de realizar cualquier operación en una cuenta real.",
    },
    iux_forex_page: {
      title_1: "Comercio de CFDs",
      title_2:
        "Comercie con CFDs en más de 70 pares de divisas y benefíciese de spreads ajustados y ejecución rápida de órdenes.",
    },
    iux_futures_page: {
      title_1: "Comercio de futuros",
      title_2:
        "Comercie con CFDs en futuros de todo el mundo y explore infinitas oportunidades de comercio.",
    },
    iux_indices_page: {
      title_1: "Comercio de índices al contado",
      title_2:
        "Comercie con CFDs en índices populares de Europa, Asia y América.",
    },
    iux_shares_page: {
      title_1: "Comercio de acciones y valores",
      title_2:
        "Comercie con CFDs en acciones de más de 150 empresas globales y benefíciese de una ejecución rápida de órdenes y pagos de dividendos en posiciones largas.",
    },
    iux_metals_page: {
      title_1: "Comercio de metales preciosos",
      title_2:
        "Comercie con CFDs en metales al contado y descubra nuevas oportunidades de comercio.",
    },
    iux_energies_page: {
      title_1: "Comercie con CFDs en energías",
      title_2:
        "Comercie con CFDs en energías al contado como el petróleo Brent, WTI y gas natural y diversifique su cartera.",
    },
    button: {
      title_1: "MT4 ",
      title_2: "Cuenta Demo",
      sub_button: {
        title_1: "COMIENCE A COMERCIAR AHORA",
        title_2: "Cuenta Demo",
      },
    },
  },
};
