import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, Input, Select } from "antd";
import styled from "styled-components";
import { post } from "../../helper/request";
import { Link } from "react-router-dom";
import { calcSwap, calcSwapMt5 } from "./Cacl";
import Recommendation from "./components/Recommendation";
import { renderApiDomain } from "../../helper/renderApiDomain";
const { Option } = Select;
const locale = require("react-redux-i18n").I18n;
const BoxContentCal = styled.div`
  padding: 12px;
  border-radius: 20px;
  border: 1px solid #8080803d;
  background: #242424;
`;
const BoxErrors = styled.div`
  color: red;
`;

const fontWeight = {
  fontWeight: 700,
  fontSize: 14,
};

const styleInput = {
  height: 40,
  borderRadius: 4,
};

const ButtonSum = styled(Button)`
  padding: 0px 34px;
  background-color: rgb(45 181 74) !important;
  color: rgb(255, 255, 255) !important;
  font-size: 14px;
  height: 40px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(45 181 74);
  text-transform: initial;
`;

const styleDisabled = {
  backgroundColor: "transparent",
  color: "#fff",
  height: 40,
  borderRadius: 4,
};

export default function Swap(props) {
  const symbol = props.symbols;
  const metaTrader = props.mtType;
  const { handleChangeSymbol } = props;
  const [form] = Form.useForm();
  const [lot, setLot] = useState();
  const [accountType, setAccountType] = useState();
  const [currency, setCurrency] = useState();
  const [leverage, setLeverage] = useState();
  const [baseCurrency, setBaseCurrency] = useState("USD");
  const [swapLong, setSwapLong] = useState();
  const [swapShort, setSwapShort] = useState();
  const [fluctuation, setFluctuation] = useState();
  const [contractSize, setContractSize] = useState();
  const [ask, setAsk] = useState();
  const [converse, setConverse] = useState();
  const [currencyPair, setCurrencyPair] = useState();
  // show
  const [calcMode, setCalcMode] = useState();
  const [SwLongAccount, setSwLongAccount] = useState();
  const [SwShortAccount, setSwShortAccount] = useState();
  const [currencyProfit, setCurrencyProfit] = useState();
  const [SwLongQuote, setSwLongQuote] = useState();
  const [SwShortQuote, setSwShortQuote] = useState();

  const [errors, setError] = useState([]);

  // base Currency
  function onChangeBaseCurrency(value) {
    setBaseCurrency(value);
  }
  // Lot
  function handleChangeLot(value) {
    setLot(value);
  }
  // currency
  function onChangeCurrency(value) {
    if (metaTrader.mt4) {
      setCurrency(value);
      post(renderApiDomain() + `calc/rate`, {
        account: baseCurrency,
        pair: value,
      }).then((response) => {
        setCurrencyPair(response.data.symbol.symbol);
        setConverse(response.data.converse);
        setAsk(response.data.symbol.ask);
        setContractSize(response.data.symbol.contract_size);
        setSwapLong(response.data.symbol.swap_long);
        setSwapShort(response.data.symbol.swap_short);
        setFluctuation(
          Math.pow(10, -response.data.symbol.digits).toFixed(
            response.data.symbol.digits
          )
        );
      });
    } else {
      setCurrency(value);
      post(renderApiDomain() + `calc/mt5/rate`, {
        account: baseCurrency,
        pair: value,
      }).then((response) => {
        setCurrencyProfit(response.data.symbol.currencyProfit);
        setCalcMode(1);
        setCurrencyPair(response.data.symbol.symbol);
        setConverse(response.data.converse);
        setAsk(response.data.symbol.tickShort.ask);
        setContractSize(response.data.symbol.contractSize);
        setSwapLong(response.data.symbol.swapLong);
        setSwapShort(response.data.symbol.swapShort);
        setFluctuation(
          Math.pow(10, -response.data.symbol.digits).toFixed(
            response.data.symbol.digits
          )
        );
      });
    }
  }

  // account type
  function onChangeLeverage(value) {
    setLeverage(value);
  }
  // account type
  function onChangeAccountType(value) {
    setAccountType(value);
  }

  const onSubmit = () => {
    if (metaTrader.mt4) {
      if (
        baseCurrency === undefined ||
        lot === undefined ||
        currency === undefined ||
        accountType === undefined
      ) {
        setError(locale.t("dashboardWallet.input_error"));
      } else {
        setError("");
        const {
          swaplongAccount,
          swapShortAccount,
          swapLongQuote,
          swapShortQuote,
        } = calcSwap(
          fluctuation,
          lot,
          contractSize,
          swapLong,
          swapShort,
          converse
        );
        setSwLongAccount(swaplongAccount);
        setSwShortAccount(swapShortAccount);
        setSwLongQuote(swapLongQuote);
        setSwShortQuote(swapShortQuote);
      }
    } else {
      if (
        baseCurrency === undefined ||
        lot === undefined ||
        currency === undefined ||
        accountType === undefined
      ) {
        setError(locale.t("dashboardWallet.input_error"));
      } else {
        setError("");
        const {
          swaplongAccount,
          swapShortAccount,
          swapLongQuote,
          swapShortQuote,
        } = calcSwapMt5(
          fluctuation,
          lot,
          contractSize,
          swapLong,
          swapShort,
          converse,
          calcMode
        );

        setSwLongAccount(swaplongAccount);
        setSwShortAccount(swapShortAccount);
        setSwLongQuote(swapLongQuote);
        setSwShortQuote(swapShortQuote);
      }
    }
  };
  const handleReset = () => {
    setSwLongAccount(0);
    setSwShortAccount(0);
    setSwLongQuote(0);
    setSwShortQuote(0);
  };

  const pair =
    currencyPair !== undefined && currencyPair.substr(3, 5).replace(".i", "");

  return (
    <>
      <Form form={form} className="col-md-8 mb-4">
        <BoxContentCal>
          <Col span={12}>
            <Form.Item name="1">
              <div className="mt-3 text-gray">
                {locale.t("fxcal_list_1_box.Platform")}
              </div>
              <Select
                size="large"
                className="w-100"
                onChange={(value) => {
                  handleChangeSymbol(value);
                  form.resetFields();
                  handleReset();
                }}
                defaultValue="Metatrader5"
              >
                <Option value={"mt5"}>Metatrader5</Option>
              </Select>
            </Form.Item>
          </Col>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name="1">
                <div className="mt-3 text-gray">
                  {locale.t("fxcal_list_5_box.title_1")}
                </div>
                <Select
                  onChange={(value) => onChangeBaseCurrency(value)}
                  defaultValue={locale.t("calculator_select")}
                  size="large"
                  className="w-100"
                >
                  <Option value={"USD"}>USD</Option>
                </Select>
              </Form.Item>
              <Form.Item name="1">
                <div className="mt-3 text-gray">
                  {locale.t("fxcal_list_5_box.title_3")}
                </div>
                <Select
                  onChange={(value) => onChangeCurrency(value)}
                  defaultValue={locale.t("calculator_select")}
                  size="large"
                  className="w-100"
                >
                  {symbol !== null &&
                    symbol?.map((e, i) => {
                      return (
                        <Option key={i} value={e.name}>
                          {e.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item name="1">
                <div className="mt-3 text-gray">
                  {locale.t("fxcal_list_5_box.title_5")}
                </div>
                <Select
                  onChange={(value) => onChangeAccountType(value)}
                  defaultValue={locale.t("calculator_select")}
                  size="large"
                  className="w-100"
                >
                  <Option value={"Standard"}>Standard</Option>
                </Select>
              </Form.Item>
              <Form.Item name="1">
                <div className="mt-3 text-gray">
                  {locale.t("fxcal_list_5_box.title_7")}
                </div>
                <Input
                  style={styleInput}
                  placeholder={"Please enter value"}
                  onChange={(e) => handleChangeLot(e.target.value)}
                />
              </Form.Item>
            </Col>
            {/*  */}
            <Col span={12}>
              <div className="mt-3 text-gray">Swap Long</div>
              <Input value={swapLong} style={styleDisabled} disabled />
              <div className="mt-3 text-gray">Swap Short</div>
              <Input value={swapShort} style={styleDisabled} disabled />
              <div className="mt-3 text-gray">
                {locale.t("fxcal_list_5_box.title_6")}
              </div>
              <Input value={ask} style={styleDisabled} disabled />
            </Col>
          </Row>
          <div style={{ margin: "1rem 0" }}>
            <BoxErrors>{errors}</BoxErrors>
            <button
              onClick={() => onSubmit()}
              className="w-sm-100 btn-primary-new-iux"
            >
              {locale.t("btn_calculator")}
            </button>
          </div>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <h6 style={fontWeight} className="text-gray">
                Swap Long {baseCurrency ? `(${baseCurrency})` : ""}
              </h6>
              <div className="text-gray">
                {locale.t("fxcal_list_5_box.title_8")}
              </div>
              <Input value={SwLongAccount} style={styleDisabled} disabled />
            </Col>
            <Col span={12}>
              <h6 style={fontWeight} className="text-gray">
                Swap Long{" "}
                {metaTrader.mt4
                  ? pair
                    ? `(${pair})`
                    : ""
                  : currencyProfit
                  ? `(${currencyProfit})`
                  : ""}
              </h6>
              <div className="text-gray">
                {locale.t("fxcal_list_5_box.title_9")}
              </div>
              <Input value={SwLongQuote} style={styleDisabled} disabled />
            </Col>
            <Col span={12}>
              <h6 style={fontWeight} className="text-gray">
                Swap Short {baseCurrency ? `(${baseCurrency})` : ""}
              </h6>
              <div className="text-gray">
                {locale.t("fxcal_list_5_box.title_8")}
              </div>
              <Input value={SwShortAccount} style={styleDisabled} disabled />
            </Col>
            <Col span={12}>
              <h6 style={fontWeight} className="text-gray">
                Swap Short{" "}
                {metaTrader.mt4
                  ? pair
                    ? `(${pair})`
                    : ""
                  : currencyProfit
                  ? `(${currencyProfit})`
                  : ""}
              </h6>
              <div className="text-gray">
                {locale.t("fxcal_list_5_box.title_9")}
              </div>
              <Input value={SwShortQuote} style={styleDisabled} disabled />
            </Col>
          </Row>
        </BoxContentCal>
      </Form>
      <Recommendation />
    </>
  );
}
