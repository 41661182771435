import React, { useState, useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import LandingPage from "./pages/LandingPage";
import { connect, useDispatch, useSelector } from "react-redux";
import { getUser, setLocation, getIpAddress } from "./actions";
import EmailVerifier from "./pages/EmailVerifier";
import ChangePasswordMT4Page from "./pages/change-password-mt4";
import { Guest, Private } from "./route";
import ResetPassword from "./pages/forgotpassword/ResetPassword";
import { setLocale } from "react-redux-i18n";
import ContactMobile from "./pages/dashboard-content/ContactMobile";
import RedirectPage from "./redirect";
import Axios from "axios";
import RedirectWebTrade from "./route/RedirectWebTrade";
import RedirectOpenAccount from "./route/RedirectOpenAccount";
import RedirectReferral from "./route/RedirectReferral";
import RedirectDashbord from "./route/RedirectDashbord";
import TraderFairSingapore from "./pages/trader-fair-singapore";

const Router = () => {
  const location = useLocation();

  const history = useHistory();
  const dispatch = useDispatch();

  const locale = useSelector((state) => state.i18n.locale);

  const [loading, setLoading] = useState(true);
  const [isSetLanguage, setIsSetLanguage] = useState(false);

  const fetchData = async () => {
    if (location.pathname === "/trader-fair-singapore") {
      return;
    }

    try {
      const response = await Axios.get(`${process.env.REACT_APP_DOMAIN}`);
      const langIndex = response.data.indexOf('lang="');

      if (langIndex !== -1) {
        const langSubstring = response.data.substring(
          langIndex + 'lang="'.length
        );
        const langValue = langSubstring.substring(
          0,
          langSubstring.indexOf('"')
        );

        let langsAll = ["en", "th", "ms", "id", "zh", "vi", "ko", "es"];
        const paths = location.pathname.split("/");
        if (langsAll.includes(langValue)) {
          if (localStorage.getItem("locale")) {
            handleChangLanguage(localStorage.getItem("locale"));
            if (paths[1]) {
              // console.log(paths[1], localStorage.getItem("locale"));
              history.push(
                location.pathname.replace(
                  paths[1],
                  localStorage.getItem("locale")
                ) + location.search
              );
            } else {
              history.push(localStorage.getItem("locale"));
            }

            //
            // window.location.reload();
          } else {
            handleChangLanguage(langValue);
            if (paths[1]) {
              history.push(location.pathname.replace(paths[1], langValue));
            } else {
              history.push(langValue);
            }
          }
        } else {
        }

        setLoading(false);
      } else {
        console.log("lang attribute not found");
      }
    } catch (error) {
      handleChangLanguage("en");
      history.push("en");
      console.log("Error fetching data:", error);
    }
  };
  const initializeRouter = async () => {
    // const savedLocation = localStorage.getItem("locale") || "en";
    // if (savedLocation) {
    //   fetchData();
    // } else {
    //   handleChangLanguage(savedLocation);
    //   onRedirect(savedLocation);
    // }
    fetchData();
  };
  useEffect(() => {
    const paths = location.pathname.split("/");
    let langs = ["en", "th", "ms", "id", "zh", "vi", "ko", "ja", "es"];

    if (paths[1] !== "reset-password") {
      if (!langs.includes(paths[1])) {
        initializeRouter();
      } else {
        handleChangLanguage(paths[1]);
        // history.push(location.pathname.replace(paths[1], "en"));
        // handleChangLanguage("en");

        // setLoading(false);
      }
    } else {
      setLoading(false);
    }

    // if (!savedLocation) {
    //   initializeRouter();
    // }
  }, []); // Empty dependency array ensures this effect runs once on mount
  const handleChangLanguage = async (lang) => {
    let alllangs = ["en", "th", "ms", "id", "zh", "vi", "ko", "ja", "es"];

    if (alllangs.includes(lang)) {
      dispatch(setLocale(lang));
      dispatch(setLocation(lang));
      setIsSetLanguage(true);
      setLoading(false);
      // history.push(lang);
    } else {
      try {
        let langfix = ["th", "lo", "vn", "us"];
        let aip = "";
        // const { data } = await Axios.get(`https://geo.ipify.org/api/v2/country?apiKey=at_WbaZkiyHdZ0pnQYmJbd2BPtc9XeOV&ipAddress=${aip}`);
        // let str = data.location.country;
        let res = document.getElementsByTagName("html")[0].getAttribute("lang");
        if (langfix.includes(res)) {
          if (res === "us") res = "en";
          dispatch(setLocale(res));
          dispatch(setLocation(res));
          setIsSetLanguage(true);
          // history.push(res);
          setLoading(false);
        } else {
          dispatch(setLocale("en"));
          dispatch(setLocation("en"));
          setIsSetLanguage(true);

          setLoading(false);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
  const onRedirect = (newlang) => {
    // history.push(`/${newlang}`);
    history.push(newlang);
    dispatch(setLocale(newlang));
    dispatch(setLocation(newlang));
    setLoading(false);
  };

  let now = new Date().valueOf();
  let urlParams = new URLSearchParams(window.location.search);
  let code = urlParams.get("code");

  if (code) {
    sessionStorage.setItem("code", code);
  }

  let check = localStorage.getItem("_exp_code");
  if (+check - now < 0) {
    localStorage.setItem("code", "");
  }

  if (location.pathname === "/trader-fair-singapore") {
    return <TraderFairSingapore />;
  }

  if (loading) {
    return (
      <div>
        <RedirectPage onRedirect={onRedirect} />
      </div>
    );
  } else {
    return (
      <Switch>
        <Route
          path={"/:lang/app/contact"}
          exact
          render={(props) => <ContactMobile locale={locale} {...props} />}
        />
        <Route exact path="/:lang/referral/" component={RedirectReferral} />
        <Route
          exact
          path="/reset-password/:id/:token"
          component={ResetPassword}
        />
        <Route
          exact
          path="/:lang/wallet/account/dashboard/:token"
          component={RedirectDashbord}
        />
        <Route
          exact
          path="/:lang/wallet/fund/deposit/:account/:token"
          component={RedirectWebTrade}
        />
        <Route
          exact
          path="/:lang/wallet/account/openaccount/:token"
          component={RedirectOpenAccount}
        />
        <Route
          path="/:lang"
          render={(props) => {
            if (!isSetLanguage) {
              // const paths = props.location.pathname.split("/");
              // console.log(paths);
              // handleChangLanguage(paths[1]);
            }
            return (
              <Switch>
                <Private path={`/:lang/wallet`} component={Dashboard} />
                {/* <Route path={`/:lang/wallet`} component={Dashboard} /> */}
                <Route path="/:lang/verify/:token" component={EmailVerifier} />
                <Route path="/:lang/verify" component={EmailVerifier} />
                <Route path="/:lang" component={LandingPage} />
                <Guest
                  path="/:lang/change-password-mt4"
                  component={ChangePasswordMT4Page}
                />
                <Redirect to={`/${location}/login`} />
              </Switch>
            );
          }}
        ></Route>{" "}
        <Route
          path={`/`}
          render={() => {
            if (code !== null) {
              sessionStorage.setItem("code", code);
              let check = localStorage.getItem("_exp_code");
              if (+check - now < 0) {
                localStorage.setItem("code", "");
              }
              return (
                <Redirect
                  to={
                    code !== null ? `/${location}?code=${code}` : `/${location}`
                  }
                />
              );
            }
          }}
        />
        <Route>
          <Redirect to={`/${location}`} />
        </Route>
      </Switch>
    );
  }
};

const mapStateToProps = ({ user, i18n }) => {
  return { user, locale: i18n.locale };
};

export default connect(mapStateToProps, {
  getUser,

  setLocale,
  setLocation,
  getIpAddress,
})(Router);
