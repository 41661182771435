import React, { Component } from "react";
import { Layout, Col, Row, Button, Divider } from "antd";
import {
  AndroidOutlined,
  AppleOutlined,
  WindowsOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Base64Encoder } from "../../helper/Base64Encoder";
import { getCustomToken } from "../../helper/goWebtrade";
const TITLE = "Trade Program | IUX Markets";
const locale = require("react-redux-i18n").I18n;
class TradeProgram extends Component {
  render() {
    let loc = localStorage.getItem("locale");
    return (
      <Layout style={{ position: "relative" }}>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>

        <div className="trading-program-content" style={{ borderRadius: 20 }}>
          {" "}
          <p className="text-header-account-wallet">
            {locale.t("dashboardProgram.title")}
          </p>
          {/* <div class="badge-overlay">
            <span class="top-right badge-custom gold">MOST POPULAR</span>
          </div> */}
          {/* <div className="d-flex align-items-center">
            <img
              alt="logo_metatrader"
              src={
                process.env.PUBLIC_URL +
                "/assets/images/trading-sofware/logo_metatrader.svg"
              }
            />
            <div style={{ fontSize: 20 }} className="font-bold-iux ml-3">
              MetaTrader5
            </div>
          </div> */}
          <div>
            <Divider />
            <div>
              <p className="fs-16 font-weight-bold">
                {" "}
                {locale.t("iux_download_app")}
              </p>
              <Row className="mt-2" gutter={24}>
                <Col
                  xs={24}
                  md={12}
                  xl={6}
                  className="padding-trade-program mr-2 mb-2"
                  style={{ width: 280 }}
                >
                  <div className="platform-col-md platform-border-col">
                    <div className="pb-4">
                      <div className="text-center">
                        <img
                          width={100}
                          className={"p-3"}
                          // className="platform-img-metatrader"
                          alt="mobile"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/trading-sofware/iux_trading_program.png"
                          }
                        />
                      </div>
                      <div className="col-md">
                        <a
                          href="https://apps.apple.com/th/app/iux-trader/id6444305135"
                          target={"_blank"}
                          className="text-primary-new-iux cursor-pointer"
                        >
                          <Button className=" btn-primary-new-iux w-100  ">
                            {locale.t("download_for")} IOS
                          </Button>
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col
                  xs={24}
                  md={12}
                  sm={12}
                  xl={6}
                  className="padding-trade-program mr-2 mb-2"
                  style={{ width: 280 }}
                >
                  <div className="platform-col-md platform-border-col">
                    <div className="pb-4">
                      <div className="text-center">
                        <img
                          width={100}
                          className={"p-3"}
                          // className="platform-img-metatrader"
                          alt="mobile"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/trading-sofware/iux_trading_program.png"
                          }
                        />
                      </div>
                      <div className="col-md">
                        <a
                          href="https://play.google.com/store/apps/details?id=com.iuxmarket.app"
                          target={"_blank"}
                          className="text-primary-new-iux cursor-pointer"
                        >
                          <Button className=" btn-primary-new-iux w-100  ">
                            {locale.t("download_for")} Android
                          </Button>
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Divider />
            </div>
            <div>
              <p className="fs-16 font-weight-bold">
                {locale.t("iux_markets_web_trade")}
              </p>
              <Row className="mt-2" gutter={24}>
                <Col
                  xs={24}
                  md={12}
                  sm={12}
                  xl={6}
                  className="padding-trade-program mr-2 mb-2"
                  style={{ width: 280 }}
                >
                  <div className="platform-col-md platform-border-col">
                    <div className="pb-4">
                      <div className="text-center my-2">
                        <img
                          width={210}
                          className={"p-3"}
                          // className="platform-img-metatrader"
                          alt="mobile"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/trading-sofware/cover-trade-web.png"
                          }
                        />
                      </div>
                      <div className="col-md">
                        <Button
                          className=" btn-primary-new-iux w-100  "
                          onClick={() => getCustomToken()}
                        >
                          {locale.t("open_web_trade")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Divider />
            </div>
            <p className=" fs-16 font-weight-bold">MetaTrader 5</p>
            <Row gutter={24} className="mt-2">
              <Col
                xs={24}
                md={12}
                sm={12}
                xl={6}
                className="padding-trade-program mr-2 mb-2"
                style={{ width: 280 }}
              >
                <div className="platform-col-md platform-border-col w-100">
                  <div className="pb-4">
                    <div className="text-center">
                      <img
                        className="platform-img-metatrader"
                        alt="Desktop"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/trading-sofware/cover-windows.png"
                        }
                      />
                    </div>
                    <div className="col-md">
                      <a
                        download
                        href="https://download.mql5.com/cdn/web/iux.markets.limited/mt5/iuxmarkets5setup.exe"
                        className="text-center mt-4"
                        style={{ cursor: "poiter" }}
                      >
                        <Button className=" btn-primary-new-iux w-100  ">
                          {locale.t("download_for")} Windows
                          {/* &nbsp;&nbsp;Download{" "}
                          {locale.t("dashboardProgram.boxMetatrader5Title")} */}
                        </Button>
                        {/* <WindowsOutlined /> */}
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                xs={24}
                md={12}
                sm={12}
                xl={6}
                className="padding-trade-program mr-2 mb-2"
                style={{ width: 280 }}
              >
                <div className="platform-col-md platform-border-col  w-100">
                  <div className="pb-4">
                    <div className="text-center">
                      <img
                        className="platform-img-metatrader"
                        alt="Desktop"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/trading-sofware/cover-mac.png"
                        }
                      />
                    </div>
                    <div className="col-md">
                      <a
                        download
                        href="https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/MetaTrader5.dmg?utm_source=www.metatrader5.com&utm_campaign=download.mt5.macos"
                        className="text-center mt-4"
                        style={{ cursor: "poiter" }}
                      >
                        <Button className=" btn-primary-new-iux w-100  ">
                          {locale.t("download_for")} macOS
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              {/* <Col
                xs={24}
                  md={12}
                  sm={12}
                  xl={6}
                className="padding-trade-program mr-2 mb-2"
                style={{ width: 280 }}
              >
                <div className="platform-col-md platform-border-col">
                  <div className="pb-4">
                    <div className="text-center">
                      <img
                        className="platform-img-metatrader"
                        alt="Desktop"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/trading-sofware/cover-web.png"
                        }
                      />
                    </div>
                    <div className="col-md">
                      <div className="mt-4">
                        <p
                          style={{
                            fontSize: "16px",
                            fontFamily: "sans-serif",
                            fontWeight: "700",
                          }}
                        >
                          {locale.t("dashboardProgram.boxwebtrader5Title")}
                        </p>
                      </div>
                      <Link
                        to={`/${loc}/webterminal/version5`}
                        className="text-center  mt-4"
                      >
                        <Button className=" btn-primary-new-iux w-100  ">
                          Open&nbsp;{" "}
                          {locale.t("dashboardProgram.boxwebtrader5Title")}
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col> */}
            </Row>
          </div>
          <div>
            <p className="fs-16 font-weight-bold">
              {" "}
              {locale.t("web_terminal")}
            </p>
            <Row className="mt-2" gutter={24}>
              <Col
                xs={24}
                md={12}
                sm={12}
                xl={6}
                className="padding-trade-program mr-2 mb-2"
                style={{ width: 280 }}
              >
                <div className="platform-col-md platform-border-col">
                  <div className="pb-4">
                    <div className="text-center">
                      <img
                        className="platform-img-metatrader"
                        alt="Desktop"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/trading-sofware/cover-web.png"
                        }
                      />
                    </div>
                    <div className="col-md">
                      <a
                        href={`/${loc}/webterminal/version5?sever=live`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-center  mt-4"
                      >
                        <Button className=" btn-primary-new-iux w-100  ">
                          {locale.t("open_web_terminal")} (Live1)
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                xs={24}
                md={12}
                sm={12}
                xl={6}
                className="padding-trade-program mr-2 mb-2"
                style={{ width: 280 }}
              >
                <div className="platform-col-md platform-border-col">
                  <div className="pb-4">
                    <div className="text-center">
                      <img
                        className="platform-img-metatrader"
                        alt="Desktop"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/trading-sofware/cover-web.png"
                        }
                      />
                    </div>
                    <div className="col-md">
                      <a
                        href={`/${loc}/webterminal/version5?sever=live2`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-center  mt-4"
                      >
                        <Button className=" btn-primary-new-iux w-100  ">
                          {locale.t("open_web_terminal")} (Live2)
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                xs={24}
                md={12}
                sm={12}
                xl={6}
                className="padding-trade-program mr-2 mb-2"
                style={{ width: 280 }}
              >
                <div className="platform-col-md platform-border-col">
                  <div className="pb-4">
                    <div className="text-center">
                      <img
                        className="platform-img-metatrader"
                        alt="Desktop"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/trading-sofware/cover-web.png"
                        }
                      />
                    </div>
                    <div className="col-md">
                      <a
                        href={`/${loc}/webterminal/version5?sever=live3`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-center  mt-4"
                      >
                        <Button className=" btn-primary-new-iux w-100  ">
                          {locale.t("open_web_terminal")} (Live3)
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                xs={24}
                md={12}
                sm={12}
                xl={6}
                className="padding-trade-program mr-2 mb-2"
                style={{ width: 280 }}
              >
                <div className="platform-col-md platform-border-col">
                  <div className="pb-4">
                    <div className="text-center">
                      <img
                        className="platform-img-metatrader"
                        alt="Desktop"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/trading-sofware/cover-web.png"
                        }
                      />
                    </div>
                    <div className="col-md">
                      <a
                        href={`/${loc}/webterminal/version5?sever=demo`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-center  mt-4"
                      >
                        <Button className=" btn-primary-new-iux w-100  ">
                          {locale.t("open_web_terminal")} (Demo)
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <p className="fs-16 font-weight-bold">
              {locale.t("meta_download_app")}
            </p>
            <Row className="mt-2" gutter={24}>
              <Col
                xs={24}
                md={12}
                sm={12}
                xl={6}
                className="padding-trade-program mr-2 mb-2"
                style={{ width: 280 }}
              >
                <div className="platform-col-md platform-border-col">
                  <div className="pb-4">
                    <div className="text-center">
                      <img
                        className="platform-img-metatrader"
                        alt="mobile"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/trading-sofware/cover-iphone.png"
                        }
                      />
                    </div>
                    <div className="col-md">
                      <a
                        download
                        href="https://download.mql5.com/cdn/mobile/mt5/ios?server=IUXMarkets-Demo,IUXMarkets-Live,IUXMarkets-Live2,IUXMarkets-Live3"
                        className="text-center  mt-4"
                        style={{ cursor: "poiter" }}
                      >
                        <Button className=" btn-primary-new-iux  w-100  ">
                          {locale.t("download_for")} IOS
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                xs={24}
                md={12}
                sm={12}
                xl={6}
                className="padding-trade-program mr-2 mb-2"
                style={{ width: 280 }}
              >
                <div className="platform-col-md platform-border-col">
                  <div className="pb-4">
                    <div className="text-center">
                      <img
                        className="platform-img-metatrader"
                        alt="mobile"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/trading-sofware/cover-android.png"
                        }
                      />
                    </div>
                    <div className="col-md">
                      <a
                        download
                        href="https://download.mql5.com/cdn/mobile/mt5/android?server=IUXMarkets-Demo,IUXMarkets-Live,IUXMarkets-Live2,IUXMarkets-Live3"
                        className="text-center  mt-4"
                        style={{ cursor: "poiter" }}
                      >
                        <Button className=" btn-primary-new-iux w-100  ">
                          {locale.t("download_for")} Android
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ user, i18n }) => {
  return { user, locale: i18n.locale };
};

export default connect(mapStateToProps, null)(TradeProgram);
