import { Button } from "antd";
import React from "react";
import { CheckOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Divider } from "antd";
import { useSelector } from "react-redux";
const paddinigSection = {
  paddingTop: "5rem",
  paddingBottom: "3rem",
};

const locale = require("react-redux-i18n").I18n;

const Accountstandard = (props) => {
  const account = useSelector((state) => state.account);
  const accountStandard = account?.accountTypeList?.find(
    (e) => e.name === "Standard" && e.type === "real"
  );
  const stdBox = [
    {
      text: locale.t("std.box_1.titile_2"),
    },
    {
      text: locale.t("std.box_1.titile_3"),
    },
    {
      text: locale.t("std.box_1.titile_4"),
    },
    {
      text: locale.t("std.box_1.titile_5"),
    },
    {
      text: locale.t("std.box_1.titile_6"),
    },
    {
      text: locale.t("std.box_1.titile_7"),
    },
  ];
  const loc = localStorage.getItem("locale");
  return (
    <div>
      <section key={1} className=" bg-section-dark py-5">
        <div className="container position-relative d-flex">
          <div className="container pb-4" style={{ marginRight: 80 }}>
            <div className="width-section-lg-md-sm">
              <h5 className="text-white text-left">
                {locale.t("std.section_1.titile_1")}
              </h5>
              <p className="text-left fs-16 text-white mt-5">
                {locale.t("std.section_1.titile_2")}
              </p>
            </div>
          </div>
          <div className="w-auto">
            <div className="div-transform">
              <div
                className="p-3 bg-primary-new-iux"
                style={{ borderRadius: "20px 20px 0 0" }}
              >
                <div className="text-white text-bold fs-16 text-center text-uppercase">
                  Standard
                </div>
              </div>
              <div className="p-3">
                <div className="d-flex justify-content-between">
                  <div className="text-white fs-12 w-100">Popularity</div>
                  <div className="w-100">
                    {[...Array(accountStandard?.popularity)].map((e) => (
                      <img
                        src="/assets/images/icons/star_black_24dp.svg"
                        alt="true"
                      />
                    ))}
                  </div>
                </div>
                <Divider style={{ background: "#3E3E3E", margin: "20px 0" }} />
                {stdBox.map((e, i) => (
                  <div className="mt-3 fs-12 text-primary-new-iux">
                    <img
                      src="/assets/images/icons/true.svg"
                      alt="true"
                      className="mr-1"
                    />
                    &nbsp; &nbsp;
                    {e.text}
                  </div>
                ))}
                <Divider style={{ background: "#3E3E3E", margin: "20px 0" }} />
                <Link to={`/${loc}/wallet/fund`}>
                  <Button className="btn-primary-new-iux w-100">
                    Open Real Account
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section key={2}>
        <div className="container container-text-center ">
          <div className="">
            <div className="container" style={paddinigSection}>
              <div>
                <h2 className="text-center text-white text-uppercase">
                  {locale.t("std.section_2.titile_1")}
                </h2>
                <p className="text-left text-white">
                  {locale.t("std.section_2.description_1")}
                </p>
              </div>
            </div>
          </div>
          <div style={paddinigSection}>
            <h2 className="text-center text-white text-uppercase">
              {locale.t("std.section_3.titile_1")}
            </h2>
            <p className="mt-2 text-center text-white ">
              {locale.t("std.section_3.description_1")}
            </p>
          </div>
          <div style={paddinigSection}>
            <h2 className="text-center text-white  mt-4 text-uppercase">
              {locale.t("std.section_3.titile_2")}
            </h2>
            <p className="mt-2 text-center text-white ">
              {locale.t("std.section_3.description_2")}
            </p>
          </div>
          {/* <h2 className="text-center text-primary-iux mt-4">
              {locale.t("std.section_3.titile_3")}
            </h2>
            <p className="mt-2">{locale.t("std.section_3.description_3")}</p> */}
          {/* <div style={paddinigSection}>
            <h2 className="text-center text-white  mt-4 text-uppercase">
              {locale.t("std.section_3.titile_4")}
            </h2>
            <p className="mt-2 text-center text-white ">
              {locale.t("std.section_3.description_4")}
            </p>
          </div> */}
        </div>
      </section>
    </div>
  );
};

export default Accountstandard;
