import React from "react";
import { ClipLoader } from "react-spinners";
const locale = require("react-redux-i18n").I18n;
function LoadingIux({ color = "text-lightgray-iux" }) {
  return (
    <div
      className="h-100 d-flex justify-content-center align-items-center flex-column my-5"
      style={{ height: 500 }}
    >
      <ClipLoader color="#36d7b7" size={72} cssOverride={{ borderWidth: 8 }} />
      <p className={`mt-5 text-center ${color}`}>{locale.t("loadingtitle")}</p>
    </div>
  );
}

export default LoadingIux;
