import React, { useEffect, useState } from "react";
import {
  Form,
  Icon,
  Result,
  Input,
  Select,
  Divider,
  Row,
  Col,
  DatePicker,
  Upload,
  Button,
  Alert,
  message,
  Radio,
  Tooltip,
} from "antd";
import { connect, useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { get, post } from "../../../../helper/request";
import { getUserVerifyv2, updateVerifierUser } from "../../../../actions";

import {
  ExclamationCircleOutlined,
  FileSearchOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import PhoneDialog from "../../../setting/dialog";
import styled from "styled-components";
import {
  getProvinceList,
  getBankList,
  getUserValidation,
  getCareerList,
} from "../../../../api/verifyuser";
import { CheckPhoneCode } from "../../../../components/check-phonecode";
import OTPVerify from "./OTPVerify";
import SelfieVerfy from "./SelfieVerfy";
import UploadVerify from "./UploadVerify";
import { useHistory } from "react-router-dom";
import InprogressVerify from "./InprogressVerify";
import { ClipLoader } from "react-spinners";
import SelectDocementType from "./SelectDocementType";
import UploadBothVerify from "./UploadBothVerify";
import { CheckProvince } from "../../../../components/checkprovince";
import { renderApiDomain } from "../../../../helper/renderApiDomain";

const locale = require("react-redux-i18n").I18n;
const { Option } = Select;

const OtpButton = styled(Button)`
  background-color: ${(props) =>
    props.countDown !== 0 ? "#d0d0d02e " : "#9dd3f400"} !important;
  border: ${(props) =>
    props.countDown !== 0
      ? "1px solid #d0d0d02e "
      : "1px solid #185f91"} !important;
  color: ${(props) => (props.countDown !== 0 ? "gray" : "#185f91")} !important;
  border-radius: 4px;
  height: 40px;
`;

function VerifyUserManual(props) {
  const { checkTypeVerify, setCurrentTabs } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [fileIndia, setFileIndia] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState({
    phone: false,
    bank: false,
  });
  const [state, setState] = useState({
    phone: false,
    email: false,
  });
  const [hoverInformation, setHoverInformation] = useState(false);
  const [numberPhone, setNumberPhone] = useState(undefined);
  const [prefixPhone, setPrefixPhone] = useState(undefined);
  const [verifyFrom, setVerifyFrom] = useState(undefined);
  const [bankList, setBankList] = useState(undefined);
  const [careerList, setCareerList] = useState(undefined);
  const [provinceList, setProvinceList] = useState(undefined);
  const [dataValidation, setDataValidation] = useState([]);
  const [posted, setPosted] = useState();
  const [showOTP, setShowOTP] = useState(false);
  const [checked, setChecked] = useState(1);
  const [isStep, setIsStep] = useState(0);
  const [form] = Form.useForm();
  const lang = localStorage.getItem("locale");
  const user = useSelector((state) => state.user);
  const [isValidationData, setIsValidationData] = useState({});
  const profile = user?.profile;
  useEffect(() => {}, [user.userVerify]);

  useEffect(() => {
    const initial = user.otp_to;

    if (user !== "") {
      setState({ phone: initial === "phone", email: initial === "email" });
    }
  }, [user]);
  useEffect(() => {
    const country = profile?.country_id;
    if (country > 0) {
      setPrefixPhone(CheckPhoneCode(country)?.code);
    }
  }, [profile]);
  useEffect(() => {
    fetchCareerList();
    fetchProvinList();
    // fetchBankList();
    fetchUserValidation();
  }, []);
  const showInformation = () => {
    setHoverInformation(!hoverInformation);
  };
  const fetchProvinList = async () => {
    try {
      const { data } = await getProvinceList();
      setProvinceList(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCareerList = async () => {
    try {
      const { data } = await getCareerList();
      setCareerList(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const onClickRadio = () => {
    if (profile.phone_number === null) {
      setIsModalVisible({ bank: false, phone: true });
      setVerifyFrom(true);
    }
  };
  // const fileToBase64 = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => {
  //       const base64String = reader.result
  //         .replace("data:", "")
  //         .replace(/^.+,/, "");
  //       resolve(base64String);
  //     };
  //     reader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  // };

  // const fileToBase64 = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       const base64String = reader.result
  //         .replace("data:", "")
  //         .replace(/^.+,/, "");
  //       resolve(base64String);
  //     };
  //     reader.readAsDataURL(file);
  //     reader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  // };
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");
        resolve(base64String);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleVerifyOTPSuccess = () => {
    setShowOTP(false);
    setCurrentTabs(2);
    setIsStep(4);
  };
  const handleVerifyUser = async (type, isform) => {
    if (
      type === "otp" &&
      (checkTypeVerify?.verify_type === "manual_otp" ||
        checkTypeVerify?.verify_type === "auto_otp")
    ) {
      // setShowOTP(true);
      if (profile.phone_number === null) {
        setShowOTP(true);
      }
    } else {
    }
    try {
      setLoading(true);

      const values = await form.getFieldValue();

      const fileidcard = values?.upload?.fileList[0]?.preview
        ? values?.upload?.fileList[0]?.preview
        : await fileToBase64(values?.upload?.fileList[0]?.originFileObj)
            .then((base64String) => {
              return base64String;
            })
            .catch((error) => {
              console.error(error);
            });

      const fileselfie = values?.selfie?.fileList[0]?.preview
        ? values?.selfie?.fileList[0]?.preview
        : await fileToBase64(values?.selfie?.fileList[0]?.originFileObj)
            .then((base64String) => {
              return base64String;
            })
            .catch((error) => {
              console.error(error);
            });

      const payload = {
        id_card: fileidcard,
        selfie: fileselfie ? fileselfie : null,
        birth_date: moment(values.birthday).format("YYYY-MM-DD"),
        address: values.address,
        phone:
          profile.phone_number !== null
            ? `${profile.phone_number}`
            : `${values.prefix}${
                values.phone?.charAt(0) === "0"
                  ? values.phone?.substring(1)
                  : values.phone
              }`,
        [provinceList?.data?.length > 0 ? "province_id" : "province"]:
          values.province,
        zipcode: values.post,
        career_id: values.job,
        first_name: values.first_name,
        last_name: values.last_name || "",
        id_card_number: values.id_card_number,
        document_type: values.document_type,
      };

      post(renderApiDomain() + "user/verify-user", payload)
        .then((res) => {
          if (
            checkTypeVerify?.verify_type !== "manual_otp" &&
            checkTypeVerify?.verify_type !== "auto_otp"
          ) {
            setCurrentTabs(2);
            dispatch(
              updateVerifierUser({
                status: "pending",
                userVerifyInfo: null,
              })
            );
            dispatch(getUserVerifyv2());
            setIsStep(4);
          }

          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);

          // message.error(e?.response?.data?.message);
          message.error(e?.response?.data?.error);
        });
    } catch (errorInfo) {
      message.error("Please complete the information.");
      console.log(errorInfo);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    if (profile.phone_number === null) {
      setState({
        phone: false,
        email: true,
      });
      setChecked(1);
    }
    setIsModalVisible({
      phone: false,
      bank: false,
    });
  };

  const uploadOption = {
    onRemove: async (file) => {
      setFile(null);
      await form.setFieldsValue({ upload: null });
      await form.validateFields(["upload"]);
    },

    beforeUpload: (file) => {
      const isPNG = file.type === "image/png";
      const isJPG = file.type === "image/jpg";
      const isJPEG = file.type === "image/jpeg";
      const isPDF = file.type === "application/pdf";
      if (!isPNG && !isJPG && !isJPEG && !isPDF) {
        message.error(`${file.name} is not a image file`);
      } else {
        setFile([file]);
      }

      return false;
    },
  };
  const uploadIndiaOption = {
    onRemove: async (fileIndia) => {
      setFileIndia(null);

      await form.setFieldsValue({ selfie: null });
      await form.validateFields(["selfie"]);
    },
    beforeUpload: (file) => {
      const isPNG = file.type === "image/png";
      const isJPG = file.type === "image/jpg";
      const isJPEG = file.type === "image/jpeg";

      if (!isPNG && !isJPG && !isJPEG) {
        message.error(`${file.name} is not a image file`);
      } else {
        setFileIndia([file]);
      }

      return false;
    },
  };
  const fetchUserValidation = async () => {
    try {
      const { data } = await getUserValidation();
      setDataValidation(data?.data);
      setIsValidationData(data?.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const validatorName = ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = new RegExp(
        /^[a-zA-ZàáãäâèéẽêìíĩỉôòóõöôơùúũưỳýỹỵđĐ @'/-]+$/
      );

      if (value.match(regex)) {
        return Promise.resolve();
      } else if (value === "" || value === undefined) {
        return Promise.resolve();
      } else {
        return Promise.reject(
          locale.t("dashboardFund.modalVerify.fnameLabelPlaceHolderJP")
        );
      }
    },
  });
  const validatorID = (regxpattern, validation) => ({
    validator(rule, value) {
      const regex = new RegExp(regxpattern);

      if (value.match(regex)) {
        return Promise.resolve();
      } else if (value === "" || value === undefined) {
        return Promise.resolve();
      } else {
        return Promise.reject(validation);
      }
    },
  });
  const validatorLastName = ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = new RegExp(
        /^[a-zA-ZàáãäâèéẽêìíĩỉôòóõöôơùúũưỳýỹỵđĐ @'/-]+$/
      );

      if (value) {
        if (value.match(regex)) {
          return Promise.resolve();
        } else {
          return Promise.reject(
            locale.t("dashboardFund.modalVerify.fnameLabelPlaceHolderJP")
          );
        }
      } else {
        return Promise.resolve();
      }
    },
  });
  const isOTPError = () => {
    setShowOTP(false);
  };
  const onChangeStep = (step) => {
    if (step === "upload") {
      // setCurrentTabs(1);
      setIsStep(3);
    } else {
      // setCurrentTabs(1);
      setIsStep(3);
    }
  };
  const onChangeDoc = (e) => {
    setIsValidationData(e);
  };
  const onNextStepVerify = () => {
    setIsStep(1);
  };
  const onBackStep = (step) => {
    if (step - 1 === 0) {
      form.resetFields();
      setIsStep(step - 1);
    }
    if (step === 3) {
      setIsStep(step - 2);
    }
  };
  return (
    <>
      {loading ? (
        <>
          <div
            className="h-100 d-flex justify-content-center align-items-center flex-column my-5"
            style={{ height: 500 }}
          >
            <ClipLoader
              color="#36d7b7"
              size={72}
              cssOverride={{ borderWidth: 8 }}
            />
            <p className="mt-5 text-lightgray-iux">
              {locale.t("loadingtitle")}
            </p>
          </div>
        </>
      ) : (
        <>
          {showOTP && (
            <div>
              {/* {checkTypeVerify?.verify_type === "manual_otp" && showOTP ? ( */}
              <OTPVerify
                verifyFrom={verifyFrom}
                numberPhone={form.getFieldValue("phone")}
                profile={profile}
                prefixPhone={prefixPhone}
                handleVerifyUser={handleVerifyOTPSuccess}
                form={form}
                isOTPError={isOTPError}
              />
            </div>
          )}
          <div className={`${!showOTP ? "d-block" : "d-none"}`}>
            <Form
              layout="vertical"
              style={{ padding: "28px" }}
              name="verify_user"
              form={form}
              initialValues={{
                email: profile?.email,
                first_name: profile?.first_name,
                last_name: profile?.last_name,
                prefix: CheckPhoneCode(profile.country_id)?.code,
                phone:
                  profile?.phone_number === null
                    ? ""
                    : profile?.phone_number?.replace(prefixPhone, ""),
                document_type: dataValidation[0]?.document_type,
                id_card_number: "",
                country: user?.countries?.find(
                  (e) => e.id === profile.country_id
                )?.name,
                upload: null,
              }}
              className="user"
            >
              {isStep === 0 && (
                <SelectDocementType
                  dataValidation={dataValidation}
                  form={form}
                  isValidationData={isValidationData}
                  onChangeDoc={onChangeDoc}
                  onNextStepVerify={onNextStepVerify}
                />
              )}
              {isStep === 1 &&
                (isValidationData.document_side === 2 ? (
                  <UploadBothVerify
                    onBackStep={onBackStep}
                    form={form}
                    uploadOption={uploadOption}
                    isValidationData={isValidationData}
                    fileList={file}
                    onChangeStep={onChangeStep}
                  />
                ) : (
                  <UploadVerify
                    onBackStep={onBackStep}
                    form={form}
                    uploadOption={uploadOption}
                    isValidationData={isValidationData}
                    fileList={file}
                    onChangeStep={onChangeStep}
                  />
                ))}
              {/* {props?.user?.profile?.country_id === 7 && isStep === 2 && (
                <SelfieVerfy
                  form={form}
                  uploadOption={uploadIndiaOption}
                  fileList={file}
                  onChangeStep={onChangeStep}
                  onBackStep={onBackStep}
                />
              )} */}
              {isStep === 3 && (
                <>
                  <Row gutter={16}>
                    <Col
                      span={12}
                      sm={24}
                      xs={24}
                      md={24}
                      lg={12}
                      xl={12}
                      xxl={12}
                    >
                      <Form.Item
                        name="first_name"
                        label={locale.t("dashboardFund.modalVerify.fnameLabel")}
                        rules={[
                          {
                            required: true,
                            message: locale.t(
                              "dashboardFund.modalVerify.validateFirstName"
                            ),
                          },
                          validatorName,
                        ]}
                      >
                        <Input
                          placeholder={locale.t(
                            "dashboardFund.modalVerify.fnameLabelPlaceHolderJP"
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={12}
                      sm={24}
                      xs={24}
                      md={24}
                      lg={12}
                      xl={12}
                      xxl={12}
                    >
                      <Form.Item
                        name="last_name"
                        rules={[
                          {
                            required: false,
                            message: locale.t(
                              "dashboardFund.modalVerify.validateLastName"
                            ),
                          },
                          validatorLastName,
                        ]}
                        label={locale.t("dashboardFund.modalVerify.lnameLabel")}
                      >
                        <Input
                          placeholder={locale.t(
                            "dashboardFund.modalVerify.lnameLabelPlaceHolderJP"
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={12}
                      sm={24}
                      xs={24}
                      md={24}
                      lg={12}
                      xl={12}
                      xxl={12}
                    >
                      <Form.Item
                        name="email"
                        rules={[{ required: true }]}
                        label={locale.t("dashboardFund.modalVerify.email")}
                        disabled
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                    <Col
                      span={12}
                      sm={24}
                      xs={24}
                      md={24}
                      lg={12}
                      xl={12}
                      xxl={12}
                    >
                      <Form.Item
                        name="birthday"
                        rules={[
                          {
                            required: true,
                            message: locale.t(
                              "dashboardFund.modalVerify.validateBirth"
                            ),
                          },
                        ]}
                        label={locale.t(
                          "dashboardFund.modalVerify.birthdayLabel"
                        )}
                      >
                        <DatePicker
                          placeholder={locale.t(
                            "dashboardFund.modalVerify.birthdayPlaceHolder"
                          )}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={12}
                      sm={24}
                      xs={24}
                      md={24}
                      lg={12}
                      xl={12}
                      xxl={12}
                      className="d-none"
                    >
                      <Form.Item
                        name="document_type"
                        rules={[
                          {
                            required: true,
                            message: locale.t(
                              "dashboardFund.modalVerify.validateProvince"
                            ),
                          },
                        ]}
                        label={"Document type"}
                        disabled
                      >
                        <Select
                          disabled
                          placeholder={"Document type"}
                          style={{ backgroundColor: "#fff" }}
                          onChange={(event) =>
                            setIsValidationData(
                              dataValidation?.find(
                                (e) => e?.document_type === event
                              )
                            )
                          }
                        >
                          {dataValidation?.map((e, index) => (
                            <Option
                              key={"province_" + index}
                              value={e?.document_type}
                            >
                              {e?.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      span={12}
                      sm={24}
                      xs={24}
                      md={24}
                      lg={12}
                      xl={12}
                      xxl={12}
                    >
                      <Form.Item
                        name="id_card_number"
                        rules={[
                          {
                            required: true,
                            message:
                              locale.t(
                                "dashboardFund.modalVerify.validateIdCard"
                              ) +
                              " " +
                              isValidationData?.label,
                          },
                          validatorID(
                            isValidationData?.regex_pattern,
                            isValidationData?.validation
                          ),
                        ]}
                        label={locale.t(
                          `documentType.${isValidationData.document_type}`
                        )}
                      >
                        <Input
                          disabled
                          maxLength={profile?.country_id === 2 ? 13 : 99}
                          placeholder={isValidationData?.label}
                          autocomplete="off"
                          name="id_card_number"
                          onKeyPress={(event) => {
                            if (!/[a-zA-Z0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onPaste={(event) => {
                            event.preventDefault();
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      span={12}
                      sm={24}
                      xs={24}
                      md={24}
                      lg={12}
                      xl={12}
                      xxl={12}
                    >
                      <Form.Item
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: locale.t(
                              "dashboardFund.modalVerify.validatePhone"
                            ),
                          },
                        ]}
                        label={locale.t("dashboardFund.modalVerify.tel")}
                      >
                        <Input
                          addonBefore={<div>{prefixPhone}</div>}
                          placeholder="xxxxxxxxx"
                          style={{ width: "100%" }}
                          readOnly={profile.phone_number !== null}
                          onChange={(e) => {
                            setNumberPhone(e.target.value);
                          }}
                          autocomplete="off"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      span={24}
                      sm={24}
                      xs={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                    >
                      <Form.Item
                        name="address"
                        rules={[
                          {
                            required: true,
                            message: locale.t(
                              "dashboardFund.modalVerify.validateAddress"
                            ),
                          },
                        ]}
                        label={locale.t(
                          "dashboardFund.modalVerify.addressLabel"
                        )}
                      >
                        <Input.TextArea
                          type="textarea"
                          autoSize={{ minRows: 3 }}
                          placeholder={locale.t(
                            "dashboardFund.modalVerify.addressPlaceHolder"
                          )}
                        />
                      </Form.Item>
                    </Col>
                    {provinceList?.data?.length > 0 ? (
                      <Col
                        span={12}
                        sm={24}
                        xs={24}
                        md={24}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item
                          name="province"
                          rules={[
                            {
                              required: true,
                              message:
                                locale.t(
                                  "dashboardFund.modalVerify.validateInput"
                                ) +
                                locale.t(
                                  `${CheckProvince(profile?.country_id).label}`
                                ),
                            },
                          ]}
                          label={locale.t(
                            `${CheckProvince(profile?.country_id).label}`
                          )}
                        >
                          <Select
                            placeholder={locale.t(
                              `${CheckProvince(profile?.country_id).placholder}`
                            )}
                            style={{ height: 48 }}
                          >
                            {provinceList?.data?.map((e, index) => (
                              <Option key={"province_" + index} value={e.id}>
                                {e.text}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    ) : (
                      <Col
                        span={12}
                        sm={24}
                        xs={24}
                        md={24}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item
                          name="province"
                          rules={[
                            {
                              required: true,
                              message:
                                locale.t(
                                  "dashboardFund.modalVerify.validateInput"
                                ) +
                                locale.t(
                                  `${CheckProvince(profile?.country_id).label}`
                                ),
                            },
                          ]}
                          label={locale.t(
                            `${CheckProvince(profile?.country_id).label}`
                          )}
                        >
                          <Input
                            placeholder={locale.t(
                              `${CheckProvince(profile?.country_id).placholder}`
                            )}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Col
                      span={12}
                      sm={24}
                      xs={24}
                      md={24}
                      lg={12}
                      xl={12}
                      xxl={12}
                    >
                      <Form.Item
                        name="country"
                        rules={[
                          {
                            required: true,
                            message: locale.t(
                              "dashboardFund.modalVerify.validatePost"
                            ),
                          },
                        ]}
                        label={locale.t("register_choose_region")}
                      >
                        <Input
                          disabled
                          placeholder={locale.t("register_choose_region")}
                          name="country"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={12}
                      sm={24}
                      xs={24}
                      md={24}
                      lg={12}
                      xl={12}
                      xxl={12}
                    >
                      <Form.Item
                        name="post"
                        rules={[
                          {
                            required: true,
                            message: locale.t(
                              "dashboardFund.modalVerify.validatePost"
                            ),
                          },
                        ]}
                        label={locale.t("dashboardFund.modalVerify.postLabel")}
                      >
                        <Input
                          placeholder={locale.t(
                            "dashboardFund.modalVerify.postLabel"
                          )}
                          name="post"
                          type="number"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={12}
                      sm={24}
                      xs={24}
                      md={24}
                      lg={12}
                      xl={12}
                      xxl={12}
                    >
                      <Form.Item
                        name="job"
                        label={locale.t("dashboardFund.modalVerify.jobLabel")}
                        rules={[
                          {
                            required: true,
                            message: locale.t(
                              "dashboardFund.modalVerify.validateCareer"
                            ),
                          },
                        ]}
                      >
                        <Select
                          placeholder={locale.t(
                            "dashboardFund.modalVerify.jobPlaceHolder"
                          )}
                          allowClear
                          style={{ backgroundColor: "#fff" }}
                        >
                          {careerList?.map((job, index) => {
                            return (
                              <Option
                                key={"career_" + index}
                                value={job.id}
                                style={{ textTransform: "capitalize" }}
                              >
                                {job.text}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.job !== currentValues.job
                      }
                    >
                      {({ getFieldValue }) => {
                        return getFieldValue("job") === "Other" ? (
                          <Col
                            span={12}
                            sm={24}
                            xs={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                          >
                            <Form.Item
                              name="other"
                              label={locale.t(
                                "dashboardFund.modalVerify.otherLabel"
                              )}
                              rules={[
                                {
                                  required: true,
                                  message: locale.t(
                                    "dashboardFund.modalVerify.validateCareer"
                                  ),
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>{" "}
                          </Col>
                        ) : null;
                      }}
                    </Form.Item>
                  </Row>{" "}
                  <Form.Item shouldUpdate>
                    {({ getFieldValue, isFieldTouched }) => (
                      <div className="text-center">
                        <Button
                          type="submit"
                          disabled={
                            loading ||
                            getFieldValue("phone") === "" ||
                            getFieldValue("first_name") === undefined ||
                            !getFieldValue("first_name").length > 0 ||
                            getFieldValue("address") === undefined ||
                            !getFieldValue("address").length > 0 ||
                            getFieldValue("province") === undefined ||
                            getFieldValue("post") === undefined ||
                            !getFieldValue("post").length > 0 ||
                            getFieldValue("job") === undefined ||
                            getFieldValue("birthday") === undefined ||
                            // !form.isFieldTouched(`birthday`) ||
                            // !form.isFieldTouched(`address`) ||
                            // !form.isFieldTouched(`province`) ||
                            // !form.isFieldTouched(`post`) ||
                            // !form.isFieldTouched(`job`) ||
                            form
                              .getFieldsError()
                              .filter(({ errors }) => errors.length).length
                          }
                          loading={loading}
                          onClick={() =>
                            handleVerifyUser(
                              checkTypeVerify?.verify_type === "manual_otp" ||
                                checkTypeVerify?.verify_type === "auto_otp"
                                ? "otp"
                                : "verify"
                            )
                          }
                          className="btn-primary-new-iux"
                        >
                          {locale.t("dashboardInnerTransfer.confirm")}
                        </Button>
                      </div>
                    )}
                  </Form.Item>
                  <div
                    className="text-center mt-3 text-primary-sub-iux cursor-pointer"
                    onClick={() => onBackStep(isStep)}
                  >
                    {locale.t("back")}
                  </div>
                </>
              )}
            </Form>
            {isStep === 4 && (
              <InprogressVerify
                type={"success"}
                des={locale.t("sendverifysuccess")}
              />
            )}
          </div>
        </>
      )}

      {/* <PhoneDialog
        verifyFrom={verifyFrom}
        numberPhone={numberPhone}
        profile={profile}
        prefixPhone={prefixPhone}
        locale={locale}
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
      /> */}
    </>
  );
}

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps, { updateVerifierUser })(
  VerifyUserManual
);
