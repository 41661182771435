import React from "react";

const ArrowLeftIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="Icon_material-arrow_back"
        data-name="Icon material-arrow_back"
        d="M20,11H7.83l5.59-5.59L12,4,4,12l8,8,1.41-1.41L7.83,13H20Z"
        transform="translate(-4 -4)"
        fill={color}
      />
    </svg>
  );
};

export default ArrowLeftIcon;
