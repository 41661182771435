export default {
  th: {
    termCondition: {
      title: "เงื่อนไขและข้อตกลง\n",
      condition_1: "เงื่อนไขและข้อตกลง(1)\n",
      condition_update: "เงื่อนไขและข้อตกลง อัพเดทล่าสุด 11 สิงหาคม 2564\n",
      condition_2: "เงื่อนไขและข้อตกลงโบนัส $30\n",
      condition_3: "เงื่อนไขและข้อตกลงโบนัสเงินฝาก 35%\n",
      condition_3_1: "เงื่อนไขและข้อตกลงโบนัสเงินฝาก 30%\n",
      condition_4: "เงื่อนไขและข้อตกลงโบนัสเงินฝาก 25%",
      condition_4_1: "เงื่อนไขและข้อตกลงโบนัสเงินฝาก 20%",
      condition_5: "เวลาเปิด - ปิด ของแต่ละผลิตภัณฑ์",
      clientAgreement: `เงื่อนไขและข้อตกลง(2)`,
      generalBusiness: `เงื่อนไขธุรกิจทั่วไป`,
      amlPolicy: `AML Policy`,
    },
  },
  lo: {
    termCondition: {
      title: "ຂໍ້ກໍານົດແລະເງື່ອນໄຂ\n",
      condition_1: "ຂໍ້ກໍານົດແລະເງື່ອນໄຂ(1)\n",
      condition_update: "ຂໍ້ກໍານົດແລະເງື່ອນໄຂ ອັບເດດລ່າສຸດ 11 ສິງຫາ 2021\n",
      condition_2: "ເງື່ອນໄຂການເງີນ 30 $\n",
      condition_3: "ຂໍ້ ກຳ ນົດແລະເງື່ອນໄຂ ສຳ ລັບໂບນັດເງິນຝາກ 35%\n",
      condition_3_1: "ຂໍ້ ກຳ ນົດແລະເງື່ອນໄຂ ສຳ ລັບໂບນັດເງິນຝາກ 30%\n",
      condition_4: "ຂໍ້ ກຳ ນົດແລະເງື່ອນໄຂ ສຳ ລັບໂບນັດເງິນຝາກ 25%",
      condition_4_1: "ຂໍ້ ກຳ ນົດແລະເງື່ອນໄຂ ສຳ ລັບໂບນັດເງິນຝາກ 20%",
      condition_5: "ເວລາເປີດ - ປິດຕະຫຼາດ",
      clientAgreement: `ຂໍ້ກໍານົດແລະເງື່ອນໄຂ(2)`,
      generalBusiness: `ທຸລະກິດທົ່ວໄປ`,
      amlPolicy: `AML Policy`,
    },
  },
  en: {
    termCondition: {
      title: "Terms and conditions\n",
      condition_1: "Terms and conditions(1)\n",
      condition_update: "Terms and conditions Latest update 11 Aug 2021\n",
      condition_2: "$30 bonus terms and conditions\n",
      condition_3: "Terms and conditions for 35% deposit bonus\n",
      condition_3_1: "Terms and conditions for 30% deposit bonus\n",
      condition_4: "Terms and conditions for 25% deposit bonus\n",
      condition_4_1: "Terms and conditions for 20% deposit bonus\n",
      condition_5: "Open-Close Market Time",
      clientAgreement: `Terms and conditions(2)\n`,
      generalBusiness: `Term General Business`,
      amlPolicy: `AML Policy`,
    },
  },
  hi: {
    termCondition: {
      title: "Terms and conditions\n",
      condition_1: "Terms and conditions(1)\n",
      condition_update: "Terms and conditions Latest update 11 Aug 2021\n",
      condition_2: "$30 bonus terms and conditions\n",
      condition_3: "Terms and conditions for 35% deposit bonus\n",
      condition_3_1: "Terms and conditions for 30% deposit bonus\n",
      condition_4: "Terms and conditions for 25% deposit bonus\n",
      condition_4_1: "Terms and conditions for 20% deposit bonus\n",
      condition_5: "Open-Close Market Time",
      clientAgreement: `Terms and conditions(2)\n`,
      generalBusiness: `Term General Business`,
      amlPolicy: `AML Policy`,
    },
  },
  ms: {
    termCondition: {
      title: `Syarat dan Ketentuan`,
      condition_1: `Syarat dan Ketentuan`,
      condition_update: `Syarat dan Ketentuan Kemas kini terkini 11 Ogos 2021`,
      condition_2: `Syarat dan Ketentuan 30 $ bonus`,
      condition_3: `Syarat dan Ketentuan untuk bonus deposit 35%`,
      condition_3_1: `Syarat dan Ketentuanuntuk bonus deposit 30%`,
      condition_4: `Syarat dan Ketentuanuntuk bonus deposit 25%`,
      condition_4_1: `Syarat dan Ketentuanuntuk bonus deposit 20%`,
      condition_5: `Masa Buka-Tutup Market`,
      clientAgreement: `Syarat dan Ketentuan\n`,
      generalBusiness: `Ketentuan am perdagangan`,
      amlPolicy: `Polisi AML `,
    },
  },
  id: {
    termCondition: {
      title: `Syarat dan ketentuan`,
      condition_1: `Syarat dan ketentuan`,
      condition_update: `Syarat dan ketentuan Pembaruan terbaru 11 Agustus 2021`,
      condition_2: `30 $ syarat dan ketentuan bonus`,
      condition_3: `Syarat dan ketentuan untuk deposit bonus 35%`,
      condition_3_1: `Syarat dan ketentuan untuk deposit bonus 30%`,
      condition_4: `Syarat dan ketentuan untuk deposit bonus 25%`,
      condition_4_1: `Syarat dan ketentuan untuk deposit bonus 20%`,
      condition_5: `Open-Tutup Time Market`,
      clientAgreement: `Syarat dan ketentuan(2)`,
      generalBusiness: `Istilah Bisnis Umum`,
      amlPolicy: `AML Policy`,
    },
  },
  zh: {
    termCondition: {
      title: `条款和条件\n`,
      condition_1: `\n`,
      condition_update: `条款和条件 最新更新 2021 年 8 月 11 日`,
      condition_2: `30项$奖金的条款和条件`,
      condition_3: `条款及条件为35％存款奖金`,
      condition_3_1: `条款及条件为30％存款奖金`,
      condition_4: `条款及条件的25％存款奖金`,
      condition_4_1: `条款及条件的20％存款奖金`,
      condition_5: `开闭时间市场`,
      clientAgreement: `条款和条件`,
      generalBusiness: `期限 一般業務`,
      amlPolicy: `AML Policy`,
    },
  },
  vi: {
    termCondition: {
      title: "Điều khoản và điều kiện\n",
      condition_1: "Điều khoản và điều kiện\n",
      condition_update:
        "Điều khoản và điều kiện, Cập nhật mới nhất ngày 11 tháng 8 năm 2021\n",
      condition_2: "Điều khoản và điều kiện tiền thưởng $30\n",
      condition_3: "Điều khoản và điều kiện tiền thưởng nạp 35%\n",
      condition_3_1: "Điều khoản và điều kiện tiền thưởng nạp30%\n",
      condition_4: "Điều khoản và điều kiện tiền thưởng nạp 25%",
      condition_4_1: "Điều khoản và điều kiện tiền thưởng nạp 20%",
      condition_5: "Thời gian mở/đóng của từng sản phẩm.",
      clientAgreement: `Điều khoản và điều kiện(2)`,
      generalBusiness: `Điều kiện kinh doanh chung`,
      amlPolicy: `AML Policy`,
    },
  },
  ko: {
    termCondition: {
      title: `약관과 조건`,
      condition_1: `약관과 조건`,
      condition_update: `약관과 조건 최신 업데이트 2021년 8월 11일`,
      condition_2: `30 개 $ 보너스 규정 및 조건`,
      condition_3: `35 % 입금 보너스 이용 약관`,
      condition_3_1: `30 % 입금 보너스 이용 약관`,
      condition_4: `25 % 입금 보너스 이용 약관`,
      condition_4_1: `20 % 입금 보너스 이용 약관`,
      condition_5: `개폐 시장 시간`,
      clientAgreement: `약관과 조건(2)`,
      generalBusiness: `기간 일반 사업`,
      amlPolicy: `AML Policy`,
    },
  },
  fil: {
    termCondition: {
      title: `Mga Tuntunin at kundisyon`,
      condition_1: `Mga Tuntunin at kundisyon`,
      condition_update: `Mga Tuntunin at kundisyon Pinakabagong pag-update 11 Ago 2021`,
      condition_2: `30 $ na bonus tuntunin at kundisyon`,
      condition_3: `Mga Tuntunin at kundisyon para sa 35% deposit bonus`,
      condition_3_1: `Mga Tuntunin at kundisyon para sa 30% deposit bonus`,
      condition_4: `Mga Tuntunin at kundisyon para sa 25% deposit bonus`,
      condition_4_1: `Mga Tuntunin at kundisyon para sa 20% deposit bonus`,
      condition_5: `Open-Close Market Time`,
      clientAgreement: `Mga Tuntunin at kundisyon(2)`,
      generalBusiness: `Term General Business`,
      amlPolicy: `AML Policy`,
    },
  },
  ja: {
    termCondition: {
      title: "利用規約",
      condition_1: "利用規約（1）",
      condition_update: "利用規約最新更新2021年8月11日",
      condition_2: "30ドルのボーナス条件",
      condition_3: "35％のデポジットボーナスの契約条件",
      condition_3_1: "30％のデポジットボーナスの契約条件",
      condition_4: "25％のデポジットボーナスの契約条件",
      condition_4_1: "20％のデポジットボーナスの契約条件",
      condition_5: "オープンクローズマーケットタイム",
      clientAgreement: "利用規約（2）",
      generalBusiness: "用語一般事業",
      amlPolicy: "AMLポリシー",
    },
  },
  es: {
    termCondition: {
      title: "Términos y condiciones\n",
      condition_1: "Términos y condiciones(1)\n",
      condition_update:
        "Última actualización de términos y condiciones 11 Ago 2021\n",
      condition_2: "Términos y condiciones del bono de $30\n",
      condition_3: "Términos y condiciones para el bono de depósito del 35%\n",
      condition_3_1:
        "Términos y condiciones para el bono de depósito del 30%\n",
      condition_4: "Términos y condiciones para el bono de depósito del 25%\n",
      condition_4_1:
        "Términos y condiciones para el bono de depósito del 20%\n",
      condition_5: "Horario de apertura y cierre del mercado",
      clientAgreement: "Términos y condiciones(2)\n",
      generalBusiness: "Condiciones Generales de Negocios",
      amlPolicy: "Política AML",
    },
  },
};
