export const CheckLanguageISO = (id) => {
  switch (id) {
    case "th":
      return "th";
    case "en":
      return "en";
    case "zh":
      return "zh";
    case "ms":
      return "ms";
    case "hi":
      return "hi";
    case "lo":
      return "lo";
    case "id":
      return "id";
    case "vi":
      return "vi";
    case "fil":
      return "fil";
    // case "ja":
    //   return "ja";
    case "ko":
      return "ko";
    default:
      return "en";
  }
};
//
