import moment from "moment";
const locale = require("react-redux-i18n").I18n;
export const getTimeAgoText = (targetTime) => {
  const currentTime = moment();
  const duration = moment.duration(currentTime.diff(targetTime));
  if (duration.asSeconds() < 60) {
    return locale.t("Now");
  } else if (duration.asMinutes() < 60) {
    const minutesAgo = Math.floor(duration.asMinutes());
    return `${minutesAgo}${
      localStorage.getItem("locale") === "ja" ? "" : " "
    }${locale.t(minutesAgo === 1 ? "minute ago" : "minutes ago")}`;
  } else if (duration.asHours() < 24) {
    const hoursAgo = Math.floor(duration.asHours());
    return `${hoursAgo}${
      localStorage.getItem("locale") === "ja" ? "" : " "
    }${locale.t(hoursAgo === 1 ? "hour ago" : "hours ago")}`;
  } else if (duration.asDays() < 7) {
    const daysAgo = Math.floor(duration.asDays());
    return `${daysAgo}${
      localStorage.getItem("locale") === "ja" ? "" : " "
    }${locale.t(daysAgo === 1 ? "day ago" : "days ago")}`;
  } else if (duration.asMonths() < 1) {
    const weeksAgo = Math.floor(duration.asWeeks());
    return `${weeksAgo}${
      localStorage.getItem("locale") === "ja" ? "" : " "
    }${locale.t(weeksAgo === 1 ? "week ago" : "weeks ago")}`;
  } else if (duration.asMonths() > 12) {
    const yearsAgo = Math.floor(duration.asYears());
    return `${yearsAgo}${
      localStorage.getItem("locale") === "ja" ? "" : " "
    }${locale.t(yearsAgo === 1 ? "year ago" : "years ago")}`;
  } else {
    if (moment(targetTime).isSame(currentTime, "month")) {
      const weeksAgo = Math.floor(duration.asWeeks());
      return `${weeksAgo}${
        localStorage.getItem("locale") === "ja" ? "" : " "
      }${locale.t(weeksAgo === 1 ? "week ago" : "weeks ago")}`;
    } else {
      const monthsAgo = Math.floor(duration.asMonths());
      return `${monthsAgo}${
        localStorage.getItem("locale") === "ja" ? "" : " "
      }${locale.t(monthsAgo === 1 ? "month ago" : "months ago")}`;
    }
  }
};
