import React from "react";
import { Layout } from "antd";
import styled from "styled-components";

const { Content } = Layout;
const TopMenu = styled(Content)`
  background: rgb(255, 255, 255);
  margin-top: 0px;
  border-radius: 10px;
`;
export const StyledAccountMain = (props) => <TopMenu>{props.children}</TopMenu>;
