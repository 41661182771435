import { Button } from "antd";
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
const locale = require("react-redux-i18n").I18n;

const paddinigSection = {
  paddingTop: "5rem",
};

const Popluar = styled.div`
  background: #1cb894;
  color: #fff;
  position: absolute;
  top: 0px;
  margin-left: -46px;
  left: 50%;
  text-transform: uppercase;
  padding: 4px 12px 4px 12px;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
`;
function AccountFunding(props) {
  const scOne = [
    {
      content: locale.t("funding.section_1.titile_1"),
      image: "/assets/images/icons/funding/i-lock.svg",
    },
    {
      content: locale.t("funding.section_1.titile_2"),
      image: "/assets/images/icons/funding/i-bank.svg",
    },
    {
      content: locale.t("funding.section_1.titile_3"),
      image: "/assets/images/icons/funding/i-money.svg",
    },
    {
      content: locale.t("funding.section_1.titile_4"),
      image: "/assets/images/icons/funding/i-fast.svg",
    },
  ];

  const scTwo = [
    {
      name: locale.t("funding.section_2.titile_1"),
      image: "/assets/images/icons/funding/i-visa.svg",
    },
    {
      name: locale.t("funding.section_2.titile_2"),
      image: "/assets/images/icons/funding/i-skrill.svg",
    },
    {
      name: locale.t("funding.section_2.titile_3"),
      image: "/assets/images/icons/funding/i-neteller.svg",
    },
    {
      name: locale.t("funding.section_2.titile_4"),
      image: "/assets/images/icons/funding/i-thaiqr.svg",
    },
    {
      name: locale.t("funding.section_2.titile_5"),
      image: "/assets/images/icons/funding/i-online.svg",
    },
  ];

  const loc = localStorage.getItem("locale");

  return (
    <div>
      <section key={1} className=" bg-section-dark py-5">
        <div className="container">
          <h2 className="text-white text-center">
            {locale.t("funding.section_1.title")}
          </h2>
          <div className="row mt-4 mb-5">
            {scOne.map((e, i) => (
              <div className="col-md-3 mt-4">
                <div className="about-card-responsive">
                  <div className="d-flex align-items-center">
                    <div
                      className="text-primary-new-iux font-italic mr-2 font-weight-bold"
                      style={{ fontSize: 60 }}
                    >
                      {i + 1}
                    </div>
                    <div className="text-white text-left ml-2">{e.content}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section key={2}>
        <div className="container">
          <div style={paddinigSection}>
            <h2 className="text-white text-center">
              {locale.t("funding.section_2.title")}
            </h2>
            <div className="text-white text-center">
              {locale.t("funding.section_2.description")}
            </div>
            <div className="row" style={paddinigSection}>
              {scTwo.map((e, i) => (
                <div className="col-12 col-md-6 col-lg-3 mt-4">
                  <div className="card-iux-landing-page">
                    {i === 0 && (
                      <Popluar>{locale.t("funding.section_2.hot")}</Popluar>
                    )}
                    <div className="text-center">
                      <small className="text-gray">
                        {locale.t("funding.section_2.option")}
                      </small>
                      <h5 className="text-primary-new-iux text-center">
                        {e.name}
                      </h5>
                      <div className="text-center" style={{ margin: "20px 0" }}>
                        <img
                          alt="IUX"
                          className=""
                          src={process.env.PUBLIC_URL + e.image}
                          height={60}
                          width="auto"
                        />
                      </div>
                    </div>
                    <Link to={`/${loc}/wallet/fund`}>
                      <Button className="btn-primary-new-iux w-100 text-uppercase">
                        Deposit now
                      </Button>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section key={3}>
        <div className="container container-text-center">
          <div style={paddinigSection}>
            <h2 className="text-center text-white text-uppercase">
              {locale.t("funding.section_3.titile_1")}
            </h2>
            <p className="mt-2 text-center text-white">
              {locale.t("funding.section_3.description_1")}
            </p>
          </div>
          <div style={paddinigSection}>
            <h2 className="text-center text-white  mt-4 text-uppercase ">
              {locale.t("funding.section_3.titile_2")}
            </h2>
            <p className="mt-2 text-center text-white">
              {locale.t("funding.section_3.description_2")}
            </p>
          </div>
          <div style={paddinigSection}>
            <h2 className="text-center text-white mt-4 text-uppercase">
              {locale.t("funding.section_3.titile_3")}
            </h2>
            <p className="mt-2 text-center text-white">
              {locale.t("funding.section_3.description_3")}
            </p>
          </div>
          <div style={paddinigSection}>
            <h2 className="text-center text-white mt-4 text-uppercase">
              {locale.t("funding.section_3.titile_4")}
            </h2>
            <p className="mt-2 text-center text-white">
              {locale.t("funding.section_3.description_4")}
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AccountFunding;
