import React from 'react';

const numberStepStyle= {
    width : '48px',
    height : '48px',
    margin : '5px',
    fontSize: '16px',
    border : '1px solid gray',
    borderRadius: '50%',
    lineHeight: '48px',
    backgroundClip : 'background-clip',
    textAlign: 'center',
    float: 'left'
}

const StepBox = (props) =>{
    return (
          <div className="col-md-4 col-sm-12 m-5" className="step-landing">
                <div className="display-responsive">
                    <img alt="" className="step-iuximg" src={props.stepItem.imagePath} />
                </div>
                <div className="mt-3" style={{width:250}}>
                    <span className="step-iux" >{props.stepItem.number}</span>
                    <div style={{display : 'table-cell'}}>
                        <h5 style={{wordWrap:'break-word' ,marginLeft:8}} className="f-res16">{props.stepItem.title}</h5>
                        <p>{props.stepItem.detail}</p>
                    </div>
                </div>
          </div>
    )
}

export default StepBox;