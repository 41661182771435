export default {
  th: {
    guildeline_banner: "	แนวทางการตลาด	",
    guildeline_sub_banner:
      "	เราอยู่ที่นี่เพื่อคุณ เรามุ่งเน้นไปที่การจัดหาประสิทธิภาพเครื่องมือและการสนับสนุนที่จำเป็นในการเติบโตและประสบความสำเร็จ	",
    guildeline_header: "	แนวทางการตลาดของพันธมิตร	",
    guildeline_sub_header1:
      "	ในฐานะพันธมิตรที่ต้องการ iuxmarkets เราอนุญาตให้คุณใช้แบรนด์ของเราเพื่อโปรโมต iuxmarkets อย่างไรก็ตามมีแนวทางที่ง่ายและง่ายที่เราต้องการให้คุณติดตามเมื่อคุณโปรโมตบริการของเรา	",
    guildeline_sub_header2:
      "	เป็นสิ่งสำคัญที่คุณจะไม่ละเมิดสิทธิในทรัพย์สินทางปัญญาของเราการปลอมตัวหรือการบิดเบือนความจริงของ Iuxmarkets หรือก่อให้เกิดความสับสนในตลาดเกี่ยวกับความสัมพันธ์ของคุณกับเรา โปรดดูรายการด้านล่างเกี่ยวกับวิธีการตรวจสอบให้แน่ใจว่าคุณอยู่ในแนวทางการตลาด	",
    guildeline_sub_header3_1: "	โปรดอย่าลังเล	",
    guildeline_sub_header3_2:
      "	ติดต่อเจ้าหน้าที่สนับสนุนที่เป็นมิตรของเราคนหนึ่ง	",
    guildeline_sub_header3_3: "	ถ้าคุณมีคำถามใด ๆ.	",
    guildeline_topic_1: "	การเรียกร้องการตลาดและงบเพื่อหลีกเลี่ยง	",
    guildeline_topic1_des:
      "	โปรดหลีกเลี่ยงการใช้ด้านล่างในสื่อโฆษณาหรือการตลาดของคุณ:	",
    guildeline_sub_topic1_1:
      "	งดเว้นการเรียกร้องหรือคำสั่งใด ๆ ที่มีความเสี่ยงเพียงเล็กน้อยหรือไม่มีเลยของนักลงทุนลูกค้าที่สูญเสีย	",
    guildeline_sub_topic1_2:
      "	อย่าทำสัญญาใด ๆ เกี่ยวกับผลประโยชน์ใด ๆ เมื่อมีแนวโน้มว่าสัญญาเหล่านี้จะไม่สามารถปฏิบัติตามได้	",
    guildeline_sub_topic1_3:
      "	หลีกเลี่ยงการเรียกร้องหรือคำสั่งหรือคำสั่งที่ไม่ถูกต้องที่ทำให้เข้าใจผิดเป็นเท็จหรือไม่ถูกต้องเพื่อดึงดูดหรือชักชวนบุคคลให้สมัครบัญชีกับเรา หากคุณไม่แน่ใจว่าจะพูดอะไรโปรดติดต่อเราทันทีและเราจะสามารถช่วยเหลือคุณได้	",
    guildeline_topic_2: "	ไม่แข่งขัน	",
    guildeline_topic2_des: "	คุณต้องไม่ทำตลาดกับผู้ค้าที่มีศักยภาพ:	",
    guildeline_sub_topic2_1: "	ในเว็บไซต์ใด ๆ ที่เราโปรโมตเว็บไซต์ของเรา	",
    guildeline_sub_topic2_2:
      "	บนเครื่องมือค้นหาทางอินเทอร์เน็ตที่เราโปรโมตเว็บไซต์ของเรา	",
    guildeline_sub_topic2_3:
      "	ในลักษณะอื่น ๆ ที่ส่งผลให้คุณแข่งขันกับเราเกี่ยวกับการส่งเสริมเว็บไซต์ของคุณรวมถึง แต่ไม่ จำกัด เฉพาะการส่งเสริมเว็บไซต์ของคุณผ่านทาง บริษัท ในเครืออื่น ๆ และ	",
    guildeline_sub_topic2_4:
      "	ซอฟต์แวร์ออนไลน์แอปพลิเคชันหรือแพลตฟอร์มอื่น ๆ ที่เปิดใช้งานการซื้อขายออนไลน์เช่นและ/หรือแข่งขันกับเรา	",
    guildeline_sub_topic2_5:
      "	การใช้โฆษณาการค้นหาแบบชำระเงินซึ่งกำหนดเป้าหมายคำหลักเช่น 'ตลาด iux' หรือ 'IUX Markets Global' หรือการเปลี่ยนแปลงใด ๆ	",
    guildeline_topic2_des2:
      "	หากคุณฝ่าฝืนบทบัญญัติเหล่านี้เราขอสงวนสิทธิ์ในการยกเลิกการเป็นสมาชิกของโปรแกรมและทำให้สิทธิค่าธรรมเนียมใด ๆ เกิดขึ้นเนื่องจากคุณ	",
    guildeline_topic_3: "	สิทธิในทรัพย์สินทางปัญญา	",
    guildeline_topic3_des1:
      "	1. คุณรับทราบว่าเราจะรักษาความเป็นเจ้าของสิทธิทั้งหมด (รวมถึงสิทธิในทรัพย์สินทางปัญญา) ในเนื้อหาที่เราพัฒนาก่อนหรือระหว่างเงื่อนไขของข้อกำหนดเหล่านี้รวมถึงหลักประกันในเครือ ไม่มีสิ่งใดในข้อกำหนดเหล่านี้ที่ควรตีความว่าเป็นการอนุญาตให้คุณมีสิทธิ์หรือสิทธิ์ในการใช้หลักประกันในเครือหรือเครื่องหมายการค้าอื่น ๆ ของเราและการสร้างแบรนด์อื่น ๆ นอกเหนือจากที่ได้รับอนุญาตภายใต้ข้อกำหนดเหล่านี้	",
    guildeline_topic3_des2:
      "	2. คุณสามารถแสดงแบนเนอร์และลิงก์ข้อความบนเว็บไซต์ของคุณใช้หลักประกันพันธมิตรอื่นและยึดมั่นในการเป็น 'IUX Markets Global Affiliate' เพื่อจุดประสงค์ในการดำเนินการอ้างอิงภายใต้โปรแกรมพันธมิตรนี้หากคุณ:	",
    guildeline_sub_topic3_1:
      "	รับการอนุมัติของเราก่อนก่อนที่คุณจะเผยแพร่หลักประกันในเครือ (ซึ่งเราจะไม่ระงับอย่างไม่มีเหตุผล);	",
    guildeline_sub_topic3_1i:
      "	ii. ละเว้นจากการใช้หลักประกันในเครือเป็นส่วนหนึ่งของชื่อผลิตภัณฑ์ของคุณ URL หรือเป็นส่วนประกอบของโลโก้อื่น และ	",
    guildeline_sub_topic3_1ii:
      "	สาม. แสดงหลักประกันในเครือในลักษณะที่สอดคล้องกับแนวทางแบรนด์ของเราและทิศทางอื่น ๆ ที่เราให้คุณเป็นครั้งคราว	",
    guildeline_sub_topic3_2:
      "	คุณจะแจ้งให้เราทราบทันทีเป็นลายลักษณ์อักษรหากคุณตระหนักถึงการละเมิดทรัพย์สินทางปัญญาหรือสิทธิ์การเป็นเจ้าของอื่น ๆ ของเราหรือหากคุณตระหนักถึงการเรียกร้องของฝ่ายใดฝ่ายหนึ่งว่าผลิตภัณฑ์เครื่องหมายการค้าหรือโลโก้ของเราละเมิดทางปัญญาของบุคคลที่สาม สิทธิในทรัพย์สินหรือความเป็นเจ้าของ	",
    guildeline_sub_topic3_3:
      "	หลีกเลี่ยงการเรียกร้องหรือคำสั่งหรือคำสั่งที่ไม่ถูกต้องที่ทำให้เข้าใจผิดเป็นเท็จหรือไม่ถูกต้องเพื่อดึงดูดหรือชักชวนบุคคลให้สมัครบัญชีกับเรา หากคุณไม่แน่ใจว่าจะพูดอะไรโปรดติดต่อเราทันทีและเราจะสามารถช่วยเหลือคุณได้	",
    guildeline_topic3_des3:
      "	หากคุณฝ่าฝืนบทบัญญัติเหล่านี้เราขอสงวนสิทธิ์ในการยกเลิกการเป็นสมาชิกของโปรแกรมและทำให้สิทธิค่าธรรมเนียมใด ๆ เกิดขึ้นเนื่องจากคุณ	",
    guildeline_topic_4: "	แบนเนอร์และลิงก์ข้อความ	",
    guildeline_topic4_des1:
      "	1. ใช้แบนเนอร์และลิงก์ข้อความของเราเฉพาะในลักษณะที่เรากำกับและไม่แก้ไขในทางใดทางหนึ่งโดยไม่ได้รับความยินยอมเป็นลายลักษณ์อักษรล่วงหน้า	",
    guildeline_topic4_des2:
      "	2. เพียงแบนเนอร์แสดงผลโดยตรงและลิงก์ข้อความไปยังหน้าแรกของเว็บไซต์ของเรา	",
    guildeline_topic_5: "	การดำเนินการอ้างอิงที่ต้องห้าม	",
    guildeline_sub_topic5_1:
      "	1. คุณจะต้องไม่มีส่วนร่วมในพฤติกรรมที่ผิดกฎหมายไม่เหมาะสมทำให้เข้าใจผิดหรือหลอกลวงหรือทำหน้าที่เป็นตัวแทนใด ๆ ที่อาจมีผลเสียต่อชื่อเสียงของเราหรือผลิตภัณฑ์และบริการของเรา	",
    guildeline_sub_topic5_2: "	2. คุณต้องไม่:	",
    guildeline_sub_topic5_2_1:
      "	มีส่วนร่วมในกิจกรรมที่ผิดกฎหมายใด ๆ เช่นการแสดงเนื้อหาที่ผิดกฎหมายบนเว็บไซต์ของคุณหรือในอีเมลการสมัครสมาชิกของคุณ	",
    guildeline_sub_topic5_2_2: "	เป้าหมายอย่างแข็งขัน:	",
    guildeline_sub_topic5_2_3:
      "	บุคคลใดก็ตามที่อายุต่ำกว่า 18 ปีโดยไม่คำนึงถึงอายุส่วนใหญ่ในสถานที่ที่คุณมีส่วนร่วมในกิจกรรมการอ้างอิง และ	",
    guildeline_sub_topic5_2_4:
      "	ผู้อยู่อาศัยในสหรัฐอเมริกาและประเทศอื่น ๆ ที่เราถูกแบนจากการลงทะเบียนผู้ค้าตามที่แจ้งให้คุณทราบเป็นครั้งคราว	",
    guildeline_sub_topic5_3:
      "	3. เข้าสู่ระบบบัญชีผู้ค้าหรือดำเนินกิจกรรมการซื้อขายในนามของพวกเขา	",
    guildeline_sub_topic5_4:
      "	4. ดำเนินการเว็บไซต์ที่มีหรือส่งเสริมเนื้อหาที่ทำให้เข้าใจผิดหมิ่นประมาทหรือผิดกฎหมายหรือลิงก์ไปยังเว็บไซต์ที่ทำเช่นนั้น เนื้อหาที่ทำให้เข้าใจผิดรวมถึงการทำสัญญาใด ๆ ที่เกี่ยวข้องกับการส่งคืนและแสดงข้อจำกัดความรับผิดชอบของเราโดยไม่ได้รับอนุญาตจากเรา	",
    guildeline_sub_topic5_5:
      "	5. ส่งข้อความอิเล็กทรอนิกส์เชิงพาณิชย์ที่ไม่พึงประสงค์ (สแปม)	",
    guildeline_sub_topic5_6:
      "	6. ส่งผู้ค้าที่มีศักยภาพโดยตรงไปยังเว็บไซต์ของเราผ่านลิงก์อ้างอิงของคุณไปยังเว็บไซต์ของเราในข้อความอิเล็กทรอนิกส์เชิงพาณิชย์, แสดงเครือข่ายโฆษณา, เคาน์เตอร์, สมุดเยี่ยม, ฟอรัม, บล็อก, ห้องแชทหรือแหล่งข้อมูลอินเทอร์เน็ตอื่น ๆ ที่คล้ายกัน	",
    guildeline_sub_topic5_7:
      "	7. เปิดใช้งานการทำธุรกรรมที่ไม่สุจริตรวมถึงผ่านอุปกรณ์ใด ๆ โปรแกรมหุ่นยนต์ที่อยู่ IP การเปลี่ยนเส้นทางและการรับส่งข้อมูล 'ปลอม'	",
    guildeline_sub_topic5_8:
      "	8. สร้างโปรโมชั่นใด ๆ ที่ให้รางวัลคะแนนหรือค่าตอบแทนแก่ผู้ค้าสำหรับการลงทะเบียนบัญชีกับเรารวมถึงข้อตกลงการชำระเงินประเภทใด ๆ โดยไม่ได้รับการอนุมัติล่วงหน้า	",
    guildeline_sub_topic5_9:
      "	9. ดำเนินการใด ๆ ที่จะทำให้ลดมูลค่าของเครื่องหมายการค้าหรือการสร้างแบรนด์ของเรา	",
  },
  lo: {
    guildeline_banner: "	ແນວທາງການຕະຫຼາດ	",
    guildeline_sub_banner:
      "	ພວກເຮົາຢູ່ທີ່ນີ້ສໍາລັບທ່ານ. ພວກເຮົາສຸມໃສ່ການສະຫນອງຄູ່ຮ່ວມງານຂອງພວກເຮົາການປະຕິບັດ, ເຄື່ອງມືແລະການສະຫນັບສະຫນູນທີ່ຈໍາເປັນເພື່ອຂະຫຍາຍຕົວແລະປະສົບຜົນສໍາເລັດ.	",
    guildeline_header: "	ຄູ່ຮ່ວມງານການຕະຫຼາດຄູ່ຮ່ວມງານ	",
    guildeline_sub_header1:
      "	ໃນຖານະເປັນຄູ່ຮ່ວມງານທີ່ທ່ານມັກທີ່ສຸດ, ພວກເຮົາອະນຸຍາດໃຫ້ທ່ານໃຊ້ຍີ່ຫໍ້ຂອງພວກເຮົາເພື່ອສົ່ງເສີມ Iuxeteets, ມັນຮຽກຮ້ອງໃຫ້ທ່ານປະຕິບັດຕາມເມື່ອທ່ານສົ່ງເສີມການບໍລິການຂອງພວກເຮົາ.	",
    guildeline_sub_header2:
      "	ມັນເປັນສິ່ງສໍາຄັນທີ່ທ່ານຈະບໍ່ລະເມີດສິດທິຊັບສິນທາງປັນຍາຂອງພວກເຮົາ, ເປັນຜູ້ທີ່ເປັນຫມັນ, ຫຼືຜິດເປື້ອນແລະເຮັດໃຫ້ເກີດຄວາມສັບສົນໃນການພົວພັນກັບພວກເຮົາ. ກະລຸນາອ້າງອີງໃສ່ບັນຊີລາຍຊື່ຂ້າງລຸ່ມນີ້ເພື່ອຮັບປະກັນວ່າທ່ານຢູ່ໃນແນວທາງການຕະຫຼາດ.	",
    guildeline_sub_header3_1: "	ກະລຸນາຮູ້ສຶກວ່າບໍ່ເສຍຄ່າ	",
    guildeline_sub_header3_2: "	ຕິດຕໍ່ພະນັກງານສະຫນັບສະຫນູນທີ່ເປັນມິດຂອງພວກເຮົາ	",
    guildeline_sub_header3_3: "	ຖ້າທ່ານມີຄໍາຖາມໃດໆ.	",
    guildeline_topic_1: "	ການຮຽກຮ້ອງການຕະຫຼາດແລະການຖະແຫຼງການເພື່ອຫລີກລ້ຽງ	",
    guildeline_topic1_des:
      "	ກະລຸນາຫລີກລ້ຽງການໃຊ້ດ້ານລຸ່ມໃນທຸກໆເອກະສານໂຄສະນາຫຼືວັດສະດຸການຕະຫຼາດຂອງທ່ານ:	",
    guildeline_sub_topic1_1:
      "	ງົດເວັ້ນຈາກການຮຽກຮ້ອງຫຼືຄໍາຖະແຫຼງທີ່ວ່າມີຄວາມສ່ຽງຫນ້ອຍຫຼືບໍ່ມີຄວາມສ່ຽງຕໍ່ນັກລົງທຶນລູກຄ້າທີ່ເຮັດໃຫ້ສູນເສຍ.	",
    guildeline_sub_topic1_2:
      "	ຢ່າເຮັດໃຫ້ຄໍາຫມັ້ນສັນຍາໃດໆຂອງຜົນປະໂຫຍດໃດໆໃນເວລາທີ່ມັນອາດຈະເປັນຄໍາຫມັ້ນສັນຍາເຫລົ່ານີ້ບໍ່ສາມາດເຮັດໄດ້.	",
    guildeline_sub_topic1_3:
      "	ຫລີກລ້ຽງການເຮັດຜິດພາດ, ການຮຽກຮ້ອງທີ່ບໍ່ຖືກຕ້ອງຫຼືຄໍາເວົ້າທີ່ບໍ່ຖືກຕ້ອງຫຼືຄໍາຖະແຫຼງທີ່ບໍ່ຖືກຕ້ອງຫຼືຊັກຊວນຫຼືຊັກຊວນໃຫ້ບຸກຄົນສະຫມັກເຂົ້າຮ່ວມກັບພວກເຮົາ. ຖ້າທ່ານບໍ່ແນ່ໃຈວ່າຈະເວົ້າຫຍັງກະລຸນາຕິດຕໍ່ຫາພວກເຮົາໂດຍກົງແລະພວກເຮົາຈະສາມາດຊ່ວຍທ່ານໄດ້.	",
    guildeline_topic_2: "	ບໍ່ແຂ່ງຂັນ	",
    guildeline_topic2_des: "	ທ່ານບໍ່ຕ້ອງຕະຫຼາດກັບພໍ່ຄ້າທີ່ມີທ່າແຮງ:	",
    guildeline_sub_topic2_1:
      "	ຢູ່ໃນເວັບໄຊທ໌້ທີ່ພວກເຮົາສົ່ງເສີມເວບໄຊທ໌ຂອງພວກເຮົາ.	",
    guildeline_sub_topic2_2:
      "	ໃນເຄື່ອງຈັກຊອກຫາທາງອິນເຕີເນັດທີ່ພວກເຮົາສົ່ງເສີມເວບໄຊທ໌ຂອງພວກເຮົາ.	",
    guildeline_sub_topic2_3:
      "	ໃນລັກສະນະອື່ນໆທີ່ສົ່ງໃຫ້ທ່ານແຂ່ງຂັນກັບພວກເຮົາທີ່ກ່ຽວຂ້ອງກັບການສົ່ງເສີມເວັບໄຊທ໌້ຂອງທ່ານລວມທັງການສົ່ງເສີມເວັບໄຊທ໌້ຂອງທ່ານໂດຍຜ່ານການສະຫນັບສະຫນູນອື່ນໆ; ແລະ	",
    guildeline_sub_topic2_4:
      "	ໂປແກຼມໂປຼແກຼມອິນເຕີເນັດອື່ນໆ, ການສະຫມັກຫຼືແພລະຕະຟອມອື່ນໆທີ່ເຮັດໃຫ້ການຊື້ຂາຍ online ເຊັ່ນ: ແລະ / ຫຼືແຂ່ງຂັນກັບພວກເຮົາ.	",
    guildeline_sub_topic2_5: "	ການນໍາໃຊ້ໂຄສະນາຄົ້ນຫາທີ່ຈ່າຍໃຫ້	",
    guildeline_topic2_des2:
      "	ຖ້າທ່ານລະເມີດຂໍ້ກໍານົດເຫຼົ່ານີ້, ພວກເຮົາສະຫງວນສິດໃນການຢຸດເຊົາການສະມາຊິກຂອງທ່ານຂອງໂຄງການແລະ VOID ໃຫ້ແກ່ທ່ານ.	",
    guildeline_topic_3: "	ສິດທິຊັບສິນທາງປັນຍາ	",
    guildeline_topic3_des1:
      "	1. ທ່ານຮັບຮູ້ວ່າພວກເຮົາຈະຮັກສາຄວາມເປັນເຈົ້າຂອງສິດທິທັງຫມົດ (ລວມທັງສິດຊັບສິນທາງປັນຍາທີ່ພວກເຮົາພັດທະນາກ່ອນຫຼືໃນໄລຍະຂອງຂໍ້ກໍານົດເຫຼົ່ານີ້. ບໍ່ມີຫຍັງໃນເງື່ອນໄຂເຫຼົ່ານີ້ຄວນຈະໄດ້ຮັບການອະນຸຍາດໃຫ້ທ່ານມີສິດອະນຸຍາດກ່ຽວກັບການເປັນພີ່ນ້ອງແດນແລະຍີ່ຫໍ້ອື່ນໆທີ່ໄດ້ຮັບອະນຸຍາດພາຍໃຕ້ເງື່ອນໄຂເຫຼົ່ານີ້.	",
    guildeline_topic3_des2:
      "	2. ທ່ານອາດຈະສະແດງປ້າຍໂຄສະນາແລະເຊື່ອມຕໍ່ຂໍ້ຄວາມໃນເວັບໄຊທ໌້ຂອງທ່ານ, ໃຊ້ຕົວທ່ານເອງເປັນຈຸດປະສົງຂອງການດໍາເນີນການພາຍໃຕ້ໂຄງການທີ່ເປັນພີ່ນ້ອງກັນ, ຖ້າທ່ານ:	",
    guildeline_sub_topic3_1:
      "	ໄດ້ຮັບການອະນຸມັດຂອງພວກເຮົາກ່ອນກ່ອນທີ່ທ່ານຈະເຜີຍແຜ່ຊັບສິນຄ້ໍາປະກັນທີ່ເປັນພີ່ນ້ອງກັນ (ເຊິ່ງພວກເຮົາຈະບໍ່ກັກຕົວຢ່າງສົມເຫດສົມຜົນ);	",
    guildeline_sub_topic3_1i:
      "	II. ຫຼີກລ່ຽງການນໍາໃຊ້ຫລັກປະກັນຂອງພັນທະມິດເປັນສ່ວນຫນຶ່ງຂອງຊື່ຜະລິດຕະພັນຂອງທ່ານເອງ, url ຫຼືເປັນສ່ວນປະກອບຂອງໂລໂກ້ອື່ນ; ແລະ	",
    guildeline_sub_topic3_1ii:
      "	III. ສະແດງຂໍ້ມູນປະກັນການເປັນພີ່ນ້ອງກັນໃນວິທີທີ່ປະຕິບັດຕາມຄໍາແນະນໍາກ່ຽວກັບຍີ່ຫໍ້ຂອງພວກເຮົາແລະທິດທາງອື່ນໆທີ່ພວກເຮົາໃຫ້ທ່ານເປັນບາງຄັ້ງຄາວ.	",
    guildeline_sub_topic3_2:
      "	ທ່ານຈະແຈ້ງໃຫ້ພວກເຮົາເປັນລາຍລັກອັກສອນທັນທີຖ້າທ່ານຮູ້ກ່ຽວກັບຊັບສິນທາງປັນຍາຫຼືສິດຂອງພວກເຮົາ, ຫຼືໂລໂກ້ລະເມີດໃນທາງປັນຍາຂອງບຸກຄົນທີສາມ ຊັບສິນຫລືສິດອໍານາດ.	",
    guildeline_sub_topic3_3:
      "	ຫລີກລ້ຽງການເຮັດຜິດພາດ, ການຮຽກຮ້ອງທີ່ບໍ່ຖືກຕ້ອງຫຼືຄໍາເວົ້າທີ່ບໍ່ຖືກຕ້ອງຫຼືຄໍາຖະແຫຼງທີ່ບໍ່ຖືກຕ້ອງຫຼືຊັກຊວນຫຼືຊັກຊວນໃຫ້ບຸກຄົນສະຫມັກເຂົ້າຮ່ວມກັບພວກເຮົາ. ຖ້າທ່ານບໍ່ແນ່ໃຈວ່າຈະເວົ້າຫຍັງກະລຸນາຕິດຕໍ່ຫາພວກເຮົາໂດຍກົງແລະພວກເຮົາຈະສາມາດຊ່ວຍທ່ານໄດ້.	",
    guildeline_topic3_des3:
      "	ຖ້າທ່ານລະເມີດຂໍ້ກໍານົດເຫຼົ່ານີ້, ພວກເຮົາສະຫງວນສິດໃນການຢຸດເຊົາການສະມາຊິກຂອງທ່ານຂອງໂຄງການແລະ VOID ໃຫ້ແກ່ທ່ານ.	",
    guildeline_topic_4: "	ປ້າຍໂຄສະນາແລະການເຊື່ອມຕໍ່ຂໍ້ຄວາມ	",
    guildeline_topic4_des1:
      "	1. ພຽງແຕ່ໃຊ້ປ້າຍໂຄສະນາຂອງພວກເຮົາແລະການເຊື່ອມໂຍງຂໍ້ຄວາມໃນແບບທີ່ກໍານົດໂດຍສະຫະລັດແລະບໍ່ດັດແປງພວກມັນໃນທາງໃດທາງຫນຶ່ງໂດຍບໍ່ມີການຍິນຍອມເປັນລາຍລັກອັກສອນກ່ອນ	",
    guildeline_topic4_des2:
      "	2. ພຽງແຕ່ສະແດງປ້າຍໂຄສະນາໂດຍກົງແລະການເຊື່ອມໂຍງຂໍ້ຄວາມກັບຫນ້າທໍາອິດຂອງເວັບໄຊທ໌້ຂອງພວກເຮົາ.	",
    guildeline_topic_5: "	ຫ້າມການສົ່ງຕໍ່	",
    guildeline_sub_topic5_1:
      "	1. ທ່ານບໍ່ຕ້ອງມີສ່ວນຮ່ວມໃນການປະພຶດທີ່ຜິດກົດຫມາຍ, ບໍ່ຖືກຕ້ອງ, ເຂົ້າໃຈຜິດ, ຫຼືເຮັດໃຫ້ຕົວແທນໃດໆທີ່ອາດຈະມີຜົນກະທົບຕໍ່ຊື່ສຽງແລະການບໍລິການຂອງພວກເຮົາ.	",
    guildeline_sub_topic5_2: "	2. ທ່ານບໍ່ຕ້ອງ:	",
    guildeline_sub_topic5_2_1:
      "	ເຂົ້າຮ່ວມໃນກິດຈະກໍາທີ່ຜິດກົດຫມາຍໃດໆຂອງປະເພດໃດກໍ່ຕາມ, ເຊັ່ນການສະແດງເນື້ອຫາທີ່ຜິດກົດຫມາຍໃນເວັບໄຊທ໌ຂອງທ່ານຫຼືໃນອີເມວຂອງທ່ານ.	",
    guildeline_sub_topic5_2_2: "	ເປົ້າຫມາຍຢ່າງຈິງຈັງ:	",
    guildeline_sub_topic5_2_3:
      "ບຸກຄົນຜູ້ໃດທີ່ມີອາຍຸຕ່ໍາກວ່າ 18 ປີ, ໂດຍບໍ່ສົນເລື່ອງອາຍຸສ່ວນໃຫຍ່ໃນສະຖານທີ່ທີ່ທ່ານມີສ່ວນຮ່ວມໃນກິດຈະກໍາສົ່ງຕໍ່; ແລະ",
    guildeline_sub_topic5_2_4:
      "	ຜູ້ທີ່ຢູ່ອາໄສຂອງສະຫະລັດອາເມລິກາ, ແລະປະເທດອື່ນໆທີ່ພວກເຮົາຖືກຫ້າມບໍ່ໃຫ້ລົງທະບຽນພໍ່ຄ້າ, ດັ່ງທີ່ໄດ້ແຈ້ງໃຫ້ທ່ານຊາບເປັນບາງຄັ້ງຄາວ.	",
    guildeline_sub_topic5_3:
      "	3. ເຂົ້າສູ່ລະບົບບັນຊີ Trader ຫຼືການດໍາເນີນກິດຈະກໍາການຄ້າໃນນາມຂອງພວກເຂົາ.	",
    guildeline_sub_topic5_4:
      "	4. ປະຕິບັດການເວບໄຊທ໌ທີ່ມີຫຼືສົ່ງເສີມການເຮັດໃຫ້ເຂົ້າໃຈຜິດ, ການຫຼອກລວງ, ຫຼືເນື້ອຫາທີ່ຜິດກົດຫມາຍ, ຫຼືການເຊື່ອມຕໍ່ກັບເວັບໄຊທ໌້ທີ່ເຮັດ. ເນື້ອໃນທີ່ຫຼອກລວງລວມມີການເຮັດຄໍາສັນຍາໃດໆທີ່ກ່ຽວຂ້ອງກັບການກັບຄືນມາແລະສະແດງການປະຕິເສດ, ໃບອະນຸຍາດໂດຍບໍ່ມີການອະນຸຍາດສະແດງອອກຂອງພວກເຮົາ.	",
    guildeline_sub_topic5_5:
      "	5. ສົ່ງຂໍ້ຄວາມເອເລັກໂຕຣນິກການຄ້າທີ່ບໍ່ໄດ້ຮຽກຮ້ອງໃຫ້ (ສະແປມ).	",
    guildeline_sub_topic5_6:
      "	6. ສົ່ງພໍ່ຄ້າທີ່ມີທ່າແຮງຢູ່ໃນເວັບໄຊທ໌ຂອງພວກເຮົາໂດຍຜ່ານການເຊື່ອມຕໍ່ທາງອີເລັກໂທຣນິກຂອງພວກເຮົາ, ເຄື່ອງສໍາອາງ, ຫ້ອງການ, ຫ້ອງການ, ບລັອກ, ຫ້ອງສົນທະນາຫຼືຊັບພະຍາກອນທີ່ຄ້າຍຄືກັນອື່ນໆ.	",
    guildeline_sub_topic5_7:
      "	7. ເປີດໃຊ້ການເຮັດທຸລະກໍາແປງສັດທາ, ລວມທັງອຸປະກອນ, ໂປແກຼມໃດຫນຶ່ງ, ຫຸ່ນຍົນ, ທີ່ຢູ່ IP, Redirects ແລະ 'ການເຂົ້າຊົມ' Bogus '.	",
    guildeline_sub_topic5_8:
      "	8. ສ້າງໂປໂມຊັ່ນໃດຫນຶ່ງທີ່ໃຫ້ລາງວັນ, ຈຸດ, ຫຼືຄ່າຊົດເຊີຍສໍາລັບພໍ່ຄ້າສໍາລັບການລົງທະບຽນບັນຊີກັບພວກເຮົາ, ໂດຍບໍ່ໄດ້ຮັບການອະນຸມັດກ່ອນ.	",
    guildeline_sub_topic5_9:
      "	9. ດໍາເນີນການໃດຫນຶ່ງທີ່ຈະເຈືອຈາງຫຼືຫົດນ້ໍາຄ່າຂອງເຄື່ອງຫມາຍການຄ້າຫລືຍີ່ຫໍ້ຂອງພວກເຮົາ.	",
  },

  en: {
    guildeline_banner: "	MARKETING GUIDELINES	",
    guildeline_sub_banner:
      "	We are here for you. We focus on providing our partners the performance, tools and support needed to grow and succeed.	",
    guildeline_header: "	PARTNERS MARKETING GUIDELINES	",
    guildeline_sub_header1:
      "	As an IUXMARKETS Preferred Partner, we authorise you to use our brand to promote IUXMARKETS, however, there are simple and easy guidelines that we require you to follow when you promote our services.	",
    guildeline_sub_header2:
      "	It is important that you do not infringe on our intellectual property rights, impersonate, or misrepresent IUXMARKETS, or cause confusion in the marketplace about your relationship with us. Please refer to the list below on how to ensure that you are within the marketing guidelines.	",
    guildeline_sub_header3_1: "	Please feel free to	",
    guildeline_sub_header3_2: "	contact one of our friendly support staff	",
    guildeline_sub_header3_3: "	if you have any questions.	",
    guildeline_topic_1: "	Marketing Claims and Statements to Avoid	",
    guildeline_topic1_des:
      "	Please avoid using the below in any of your advertising or marketing materials:	",
    guildeline_sub_topic1_1:
      "	Refrain from making any claim or statement that there is little or no risk of the client investor making a loss.	",
    guildeline_sub_topic1_2:
      "	Do not make any promises of any benefits when it is likely that these promises cannot be fulfilled.	",
    guildeline_sub_topic1_3:
      "	Avoid making any misleading, materially false or incorrect claims or statement to attract or persuade a person to apply for an account with us. If you are unsure of what to say please contact us straight away and we will be able to assist you.	",
    guildeline_topic_2: "	Non-Compete	",
    guildeline_topic2_des: "	You must not market to potential Traders:	",
    guildeline_sub_topic2_1: "	On any site where we promote our Website.	",
    guildeline_sub_topic2_2:
      "	On any internet search engine on which we promote our Website.	",
    guildeline_sub_topic2_3:
      "	In any other manner which results in you competing with us in relation to the promotion of your sites including but not limited to the promotion of your site(s) through other Affiliates; and	",
    guildeline_sub_topic2_4:
      "	Any other online software, application or other platform enabling online trading like and/or competitive with us.	",
    guildeline_sub_topic2_5:
      "	Using Paid search ads that target keywords like ‘IUX Markets’ or ‘IUX Markets Global’ or any variation of that	",
    guildeline_topic2_des2:
      "	If you breach these provisions, we reserve the right to terminate your membership of the Program and void any Fee entitlements due to you.	",
    guildeline_topic_3: "	Intellectual Property Rights	",
    guildeline_topic3_des1:
      "	1. You acknowledge that we will retain the ownership of all rights (including intellectual property rights) in the material we develop before or during the term of these Terms, including the Affiliate Collateral. Nothing in these Terms should be construed as granting you any license or right to use the Affiliate Collateral or any of our other trademarks and branding other than as permitted under these Terms.	",
    guildeline_topic3_des2:
      "	2. You may display Banners and Text Links on your site, use another Affiliate Collateral, and hold yourself out to be an ‘IUX Markets Global Affiliate’ for the purpose of conducting referrals under this Affiliate Program, if you:	",
    guildeline_sub_topic3_1:
      "	Obtain our approval first before you publish any Affiliate Collateral (which we will not unreasonably withhold);	",
    guildeline_sub_topic3_1i:
      "	ii. refrain from using Affiliate Collateral as part of your own product name, URL or as a component of another logo; and	",
    guildeline_sub_topic3_1ii:
      "	iii. display Affiliate Collateral in a way that complies with our brand guidelines and any other directions that we give you from time to time.	",
    guildeline_sub_topic3_2:
      "	You will notify us immediately in writing if you become aware of an infringement on any of our intellectual property or other ownership rights, or if you become aware of a claim by any party that our products, trademarks, or logos infringe on a third party’s intellectual property or ownership rights.	",
    guildeline_sub_topic3_3:
      "	Avoid making any misleading, materially false or incorrect claims or statement to attract or persuade a person to apply for an account with us. If you are unsure of what to say please contact us straight away and we will be able to assist you.	",
    guildeline_topic3_des3:
      "	If you breach these provisions, we reserve the right to terminate your membership of the Program and void any Fee entitlements due to you.	",
    guildeline_topic_4: "	Banners and Text Links	",
    guildeline_topic4_des1:
      "	1. Only use our Banners and Text Links in the manner directed by us and not modify them in any way without our prior written consent	",
    guildeline_topic4_des2:
      "	2. Only direct Display Banners and Text Links to the homepage of our Website.	",
    guildeline_topic_5: "	Prohibited Referral Conduct	",
    guildeline_sub_topic5_1:
      "	1. You must not engage in any illegal, improper, misleading, or deceptive conduct or make any representations which may have a detrimental effect on our reputation or those of our products and services.	",
    guildeline_sub_topic5_2: "	2. You must not:	",
    guildeline_sub_topic5_2_1:
      "	engage in any illegal activity of any type, such as displaying illegal content on your website or in your subscription emails.	",
    guildeline_sub_topic5_2_2: "	actively target:	",
    guildeline_sub_topic5_2_3:
      "	Any person who is under the age of 18 years old, regardless of the age of majority in the location where you are engaging in referral activities; and	",
    guildeline_sub_topic5_2_4:
      "	Any resident of the United States, and any other countries where we are banned from registering Traders, as notified to you from time to time.	",
    guildeline_sub_topic5_3:
      "	3. Log in to Trader accounts or conduct trading activities on their behalf.	",
    guildeline_sub_topic5_4:
      "	4. Operate a website that contains or promotes misleading, defamatory, or illegal content, or a link to a website that does so. Misleading content includes making any promises whatsoever in relation to returns and displaying our disclaimer, license without our express permission.	",
    guildeline_sub_topic5_5:
      "	5. Send unsolicited commercial electronic messages (spam).	",
    guildeline_sub_topic5_6:
      "	6. Send potential trader directly to our Website via your referral links to our Website(s) in commercial electronic messages, display advertising networks, counters, guestbooks, forums, blogs, chat rooms or another similar internet resources.	",
    guildeline_sub_topic5_7:
      "	7. Enable bad faith transactions, including via any device, program, robot, IP address, redirects and ‘bogus’ traffic.	",
    guildeline_sub_topic5_8:
      "	8. Establish any promotion that provides rewards, points, or compensation to a Trader for registering for an account with us, including any kind of payment deal, without our prior approval.	",
    guildeline_sub_topic5_9:
      "	9. Take any action that would dilute or tarnish the value of our trademarks or branding.	",
  },
  hi: {
    guildeline_banner: "	विपणन दिशानिर्देश	",
    guildeline_sub_banner:
      "	हम आप के लिए यहां हैं। हम अपने भागीदारों को बढ़ने और सफल होने के लिए आवश्यक प्रदर्शन, उपकरण और समर्थन प्रदान करने पर ध्यान केंद्रित करते हैं।	",
    guildeline_header: "	भागीदार विपणन दिशानिर्देश	",
    guildeline_sub_header1:
      "	Iuxmarkets पसंदीदा साथी के रूप में, हम आपको Iuxmarkets को बढ़ावा देने के लिए हमारे ब्रांड का उपयोग करने के लिए अधिकृत करते हैं, हालांकि, सरल और आसान दिशानिर्देश हैं जिनका हमें हमारी सेवाओं को बढ़ावा देने पर आपको पालन करने की आवश्यकता होती है।	",
    guildeline_sub_header2:
      "	यह महत्वपूर्ण है कि आप हमारे बौद्धिक संपदा अधिकारों का उल्लंघन न करें, impersonate, या गलत तरीके से प्रस्तुत करें, या हमारे साथ अपने संबंधों के बारे में बाज़ार में भ्रम का कारण बनें। कृपया नीचे दी गई सूची को देखें कि आप विपणन दिशानिर्देशों के भीतर कैसे हैं।	",
    guildeline_sub_header3_1: "	कृपया स्वतंत्र महसूस करें	",
    guildeline_sub_header3_2:
      "	हमारे अनुकूल सहायक कर्मचारियों में से एक से संपर्क करें	",
    guildeline_sub_header3_3: "	यदि आपके कोई प्रश्न हैं तो।	",
    guildeline_topic_1: "	बचने के लिए विपणन दावे और बयान	",
    guildeline_topic1_des:
      "	कृपया अपने किसी भी विज्ञापन या विपणन सामग्री में नीचे का उपयोग करने से बचें:	",
    guildeline_sub_topic1_1:
      "	कोई भी दावा या बयान देने से बचना चाहिए कि क्लाइंट निवेशक को नुकसान करने का कोई जोखिम कम या कोई जोखिम नहीं है।	",
    guildeline_sub_topic1_2:
      "	किसी भी लाभ का कोई वादा न करें जब यह संभावना हो कि इन वादों को पूरा नहीं किया जा सकता है।	",
    guildeline_sub_topic1_3:
      "	हमारे साथ एक खाते के लिए आवेदन करने के लिए किसी व्यक्ति को आकर्षित करने या मनाने के लिए किसी भी भ्रामक, भौतिक रूप से गलत या गलत दावों या बयान से बचें। यदि आप इस बात से अनिश्चित हैं कि कृपया क्या कहना है तो कृपया हमसे सीधे संपर्क करें और हम आपकी सहायता कर पाएंगे।	",
    guildeline_topic_2: "	गैर प्रतिस्पर्धा	",
    guildeline_topic2_des:
      "	आपको संभावित व्यापारियों के लिए बाजार नहीं होना चाहिए:	",
    guildeline_sub_topic2_1:
      "	किसी भी साइट पर जहां हम अपनी वेबसाइट को बढ़ावा देते हैं।	",
    guildeline_sub_topic2_2:
      "	किसी भी इंटरनेट खोज इंजन पर जिस पर हम अपनी वेबसाइट को बढ़ावा देते हैं।	",
    guildeline_sub_topic2_3:
      "	किसी भी अन्य तरीके से जिसके परिणामस्वरूप आप अपनी साइटों के प्रचार के संबंध में हमारे साथ प्रतिस्पर्धा कर रहे हैं, लेकिन अन्य सहयोगियों के माध्यम से आपकी साइट (ओं) के प्रचार तक सीमित नहीं हैं; और	",
    guildeline_sub_topic2_4:
      "	कोई अन्य ऑनलाइन सॉफ्टवेयर, एप्लिकेशन या अन्य प्लेटफ़ॉर्म ऑनलाइन ट्रेडिंग को सक्षम करता है जैसे और/या हमारे साथ प्रतिस्पर्धी।	",
    guildeline_sub_topic2_5:
      "	भुगतान किए गए खोज विज्ञापनों का उपयोग करना जो 'IUX मार्केट्स' या 'IUX मार्केट्स ग्लोबल' या उस की किसी भी भिन्नता जैसे कीवर्ड को लक्षित करते हैं	",
    guildeline_topic2_des2:
      "	यदि आप इन प्रावधानों का उल्लंघन करते हैं, तो हम कार्यक्रम की आपकी सदस्यता को समाप्त करने का अधिकार सुरक्षित रखते हैं और आपके कारण किसी भी शुल्क एंटाइटेलमेंट को शून्य करते हैं।	",
    guildeline_topic_3: "	बौद्धिक संपदा अधिकार	",
    guildeline_topic3_des1:
      "	1. आप स्वीकार करते हैं कि हम संबद्ध संपार्श्विक सहित इन शर्तों से पहले या उसके दौरान विकसित की गई सामग्री में सभी अधिकारों (बौद्धिक संपदा अधिकारों सहित) के स्वामित्व को बनाए रखेंगे। इन शर्तों में कुछ भी आपको संबद्ध संपार्श्विक या हमारे किसी अन्य ट्रेडमार्क का उपयोग करने के लिए किसी भी लाइसेंस या अधिकार के रूप में नहीं माना जाना चाहिए और इन शर्तों के तहत अनुमति के अलावा अन्य ब्रांडिंग।	",
    guildeline_topic3_des2:
      "	2. आप अपनी साइट पर बैनर और टेक्स्ट लिंक प्रदर्शित कर सकते हैं, एक और सहबद्ध संपार्श्विक का उपयोग कर सकते हैं, और इस संबद्ध कार्यक्रम के तहत रेफरल आयोजित करने के उद्देश्य से अपने आप को एक 'IUX बाजार वैश्विक संबद्ध' होने के लिए पकड़ सकते हैं, यदि आप:	",
    guildeline_sub_topic3_1:
      "	किसी भी संबद्ध संपार्श्विक प्रकाशित करने से पहले हमारी मंजूरी प्राप्त करें (जिसे हम अनुचित रूप से रोक नहीं पाएंगे);	",
    guildeline_sub_topic3_1i:
      "	ii। अपने स्वयं के उत्पाद नाम, URL या किसी अन्य लोगो के एक घटक के रूप में संबद्ध संपार्श्विक का उपयोग करने से बचना चाहिए; और	",
    guildeline_sub_topic3_1ii:
      "	iii। संबद्ध संपार्श्विक को इस तरह से प्रदर्शित करें जो हमारे ब्रांड दिशानिर्देशों और किसी भी अन्य दिशाओं का अनुपालन करता है जो हम आपको समय -समय पर देते हैं।	",
    guildeline_sub_topic3_2:
      "	यदि आप हमारी किसी भी बौद्धिक संपदा या अन्य स्वामित्व अधिकारों पर उल्लंघन के बारे में जानते हैं, या यदि आप किसी भी पार्टी द्वारा किसी भी पार्टी, ट्रेडमार्क, या लोगो का उल्लंघन करते हैं, तो आप किसी भी पार्टी के दावे से अवगत हो जाते हैं। संपत्ति या स्वामित्व अधिकार।	",
    guildeline_sub_topic3_3:
      "	हमारे साथ एक खाते के लिए आवेदन करने के लिए किसी व्यक्ति को आकर्षित करने या मनाने के लिए किसी भी भ्रामक, भौतिक रूप से गलत या गलत दावों या बयान से बचें। यदि आप इस बात से अनिश्चित हैं कि कृपया क्या कहना है तो कृपया हमसे सीधे संपर्क करें और हम आपकी सहायता कर पाएंगे।	",
    guildeline_topic3_des3:
      "	यदि आप इन प्रावधानों का उल्लंघन करते हैं, तो हम कार्यक्रम की आपकी सदस्यता को समाप्त करने का अधिकार सुरक्षित रखते हैं और आपके कारण किसी भी शुल्क एंटाइटेलमेंट को शून्य करते हैं।	",
    guildeline_topic_4: "	बैनर और पाठ लिंक	",
    guildeline_topic4_des1:
      "	1. हमारे द्वारा निर्देशित तरीके से हमारे बैनर और टेक्स्ट लिंक का उपयोग करें और हमारी पूर्व लिखित सहमति के बिना उन्हें किसी भी तरह से संशोधित न करें	",
    guildeline_topic4_des2:
      "	2. हमारी वेबसाइट के होमपेज पर केवल प्रत्यक्ष प्रदर्शन बैनर और टेक्स्ट लिंक।	",
    guildeline_topic_5: "	निषिद्ध रेफरल आचरण	",
    guildeline_sub_topic5_1:
      "	1. आपको किसी भी अवैध, अनुचित, भ्रामक, या भ्रामक आचरण में संलग्न नहीं होना चाहिए या कोई भी प्रतिनिधित्व करना चाहिए जो हमारी प्रतिष्ठा या हमारे उत्पादों और सेवाओं पर हानिकारक प्रभाव डाल सकता है।	",
    guildeline_sub_topic5_2: "	2. आपको नहीं करना चाहिए:	",
    guildeline_sub_topic5_2_1:
      "	किसी भी प्रकार की किसी भी अवैध गतिविधि में संलग्न करें, जैसे कि आपकी वेबसाइट पर या अपने सदस्यता ईमेल में अवैध सामग्री प्रदर्शित करना।	",
    guildeline_sub_topic5_2_2: "	सक्रिय रूप से लक्ष्य:	",
    guildeline_sub_topic5_2_3:
      "कोई भी व्यक्ति जो 18 वर्ष से कम उम्र का है, उस स्थान पर बहुमत की उम्र की परवाह किए बिना जहां आप रेफरल गतिविधियों में संलग्न हैं; और",
    guildeline_sub_topic5_2_4:
      "	संयुक्त राज्य अमेरिका के किसी भी निवासी, और किसी भी अन्य देश जहां हम व्यापारियों को पंजीकृत करने पर प्रतिबंध लगा रहे हैं, जैसा कि समय -समय पर आपको सूचित किया गया है।	",
    guildeline_sub_topic5_3:
      "	3. ट्रेडर खातों में लॉग इन करें या उनकी ओर से ट्रेडिंग गतिविधियों का संचालन करें।	",
    guildeline_sub_topic5_4:
      "	4. एक ऐसी वेबसाइट का संचालन करें जिसमें भ्रामक, मानहानि या अवैध सामग्री, या किसी वेबसाइट के लिंक को शामिल किया गया हो, जो ऐसा करता है। भ्रामक सामग्री में रिटर्न के संबंध में कोई भी वादे करना और हमारी अस्वीकरण, हमारी एक्सप्रेस अनुमति के बिना लाइसेंस को प्रदर्शित करना शामिल है।	",
    guildeline_sub_topic5_5:
      "	5. अवांछित वाणिज्यिक इलेक्ट्रॉनिक संदेश (SPAM) भेजें।	",
    guildeline_sub_topic5_6:
      "	6. वाणिज्यिक इलेक्ट्रॉनिक संदेशों में हमारी वेबसाइट (ओं) के लिए अपने रेफरल लिंक के माध्यम से संभावित व्यापारी को हमारी वेबसाइट पर सीधे भेजें, विज्ञापन नेटवर्क, काउंटर, गेस्टबुक, मंच, ब्लॉग, चैट रूम या किसी अन्य समान इंटरनेट संसाधनों को प्रदर्शित करें।	",
    guildeline_sub_topic5_7:
      "	7. किसी भी डिवाइस, प्रोग्राम, रोबोट, आईपी एड्रेस, रीडायरेक्ट और 'फर्जी' ट्रैफ़िक के माध्यम से खराब विश्वास लेनदेन को सक्षम करें।	",
    guildeline_sub_topic5_8:
      "	8. किसी भी पदोन्नति को स्थापित करें जो हमारे साथ एक खाते के लिए पंजीकरण करने के लिए एक व्यापारी को पुरस्कार, अंक, या मुआवजा प्रदान करता है, जिसमें हमारी पूर्व अनुमोदन के बिना किसी भी प्रकार के भुगतान सौदे सहित।	",
    guildeline_sub_topic5_9:
      "	9. कोई भी कार्रवाई करें जो हमारे ट्रेडमार्क या ब्रांडिंग के मूल्य को पतला या कलंकित करे।	",
  },
  ms: {
    guildeline_banner: "	Garis Panduan Pemasaran	",
    guildeline_sub_banner:
      "	Kami ada di sini untuk anda. Kami memberi tumpuan kepada menyediakan rakan kongsi prestasi, alat dan sokongan yang diperlukan untuk berkembang dan berjaya.	",
    guildeline_header: "	Garis Panduan Pemasaran Rakan Kongsi	",
    guildeline_sub_header1:
      "	Sebagai rakan kongsi pilihan iuxmarkets, kami memberi kuasa kepada anda untuk menggunakan jenama kami untuk mempromosikan iuxmarket, namun terdapat garis panduan yang mudah dan mudah yang kami perlukan untuk anda mengikuti apabila anda mempromosikan perkhidmatan kami.	",
    guildeline_sub_header2:
      "	Adalah penting bahawa anda tidak melanggar hak harta intelektual kami, menyamar, atau salah faham, atau menyebabkan kekeliruan di pasaran mengenai hubungan anda dengan kami. Sila rujuk senarai di bawah bagaimana untuk memastikan bahawa anda berada dalam garis panduan pemasaran.	",
    guildeline_sub_header3_1: "	Sila berasa bebas	",
    guildeline_sub_header3_2:
      "	Hubungi salah seorang kakitangan sokongan mesra kami	",
    guildeline_sub_header3_3: "	Jika anda mempunyai sebarang pertanyaan.	",
    guildeline_topic_1: "	Tuntutan pemasaran dan pernyataan untuk mengelakkan	",
    guildeline_topic1_des:
      "	Sila elakkan menggunakan di bawah dalam mana -mana bahan pengiklanan atau pemasaran anda:	",
    guildeline_sub_topic1_1:
      "	Berhenti daripada membuat sebarang tuntutan atau pernyataan bahawa terdapat sedikit atau tidak risiko pelabur klien yang membuat kerugian.	",
    guildeline_sub_topic1_2:
      "	Jangan membuat apa -apa janji dari apa -apa faedah apabila kemungkinan janji -janji ini tidak dapat dipenuhi.	",
    guildeline_sub_topic1_3:
      "	Elakkan membuat apa -apa tuntutan atau pernyataan yang salah atau salah untuk menarik atau memujuk seseorang untuk memohon akaun dengan kami. Jika anda tidak pasti apa yang hendak dikatakan sila hubungi kami dengan segera dan kami akan dapat membantu anda.	",
    guildeline_topic_2: "	Tidak bersaing	",
    guildeline_topic2_des:
      "	Anda tidak boleh memasarkan kepada peniaga yang berpotensi:	",
    guildeline_sub_topic2_1:
      "	Di mana -mana laman web di mana kami mempromosikan laman web kami.	",
    guildeline_sub_topic2_2:
      "	Pada mana -mana enjin carian internet di mana kami mempromosikan laman web kami.	",
    guildeline_sub_topic2_3:
      "	Dalam apa -apa cara lain yang mengakibatkan anda bersaing dengan kami berhubung dengan promosi laman web anda termasuk tetapi tidak terhad kepada promosi laman web anda melalui sekutu lain; dan	",
    guildeline_sub_topic2_4:
      "	Mana -mana perisian, aplikasi atau platform lain yang membolehkan perdagangan dalam talian seperti dan/atau kompetitif dengan kami.	",
    guildeline_sub_topic2_5:
      "	Menggunakan iklan carian berbayar yang mensasarkan kata kunci seperti 'pasaran iux' atau 'pasaran iux global' atau sebarang variasi itu	",
    guildeline_topic2_des2:
      "	Sekiranya anda melanggar peruntukan ini, kami berhak untuk menamatkan keahlian program anda dan membatalkan apa -apa kelebihan yuran yang disebabkan oleh anda.	",
    guildeline_topic_3: "	Hak harta Intelek	",
    guildeline_topic3_des1:
      "	1. Anda mengakui bahawa kami akan mengekalkan pemilikan semua hak (termasuk hak harta intelek) dalam bahan yang kami usahakan sebelum atau dalam tempoh terma ini, termasuk cagaran afiliasi. Tiada apa -apa pun dalam terma ini yang harus ditafsirkan sebagai memberi anda apa -apa lesen atau hak untuk menggunakan cagaran afiliasi atau mana -mana tanda niaga dan penjenamaan kami yang lain selain daripada yang dibenarkan di bawah Terma ini.	",
    guildeline_topic3_des2:
      "	2. Anda boleh memaparkan spanduk dan pautan teks di laman web anda, gunakan satu lagi cagaran afiliasi, dan tahan diri anda sebagai 'Gabungan Global Global IUX' untuk tujuan menjalankan rujukan di bawah program afiliasi ini, jika anda:	",
    guildeline_sub_topic3_1:
      "	Dapatkan kelulusan kami terlebih dahulu sebelum anda menerbitkan mana -mana cagaran afiliasi (yang kami tidak akan menahan diri tidak munasabah);	",
    guildeline_sub_topic3_1i:
      "	ii. Menahan diri daripada menggunakan cagaran afiliasi sebagai sebahagian daripada nama produk anda sendiri, URL atau sebagai komponen logo lain; dan	",
    guildeline_sub_topic3_1ii:
      "	iii. Paparkan cagaran afiliasi dengan cara yang mematuhi garis panduan jenama kami dan apa -apa arahan lain yang kami berikan kepada anda dari semasa ke semasa.	",
    guildeline_sub_topic3_2:
      "	Anda akan memberitahu kami dengan segera secara bertulis jika anda menyedari pelanggaran terhadap mana -mana harta intelektual kami atau hak pemilikan lain, atau jika anda menyedari tuntutan oleh mana -mana pihak bahawa produk, tanda dagangan, atau logo kami melanggar intelektual pihak ketiga hak harta atau pemilikan.	",
    guildeline_sub_topic3_3:
      "	Elakkan membuat apa -apa tuntutan atau pernyataan yang salah atau salah untuk menarik atau memujuk seseorang untuk memohon akaun dengan kami. Jika anda tidak pasti apa yang hendak dikatakan sila hubungi kami dengan segera dan kami akan dapat membantu anda.	",
    guildeline_topic3_des3:
      "	Sekiranya anda melanggar peruntukan ini, kami berhak untuk menamatkan keahlian program anda dan membatalkan apa -apa kelebihan yuran yang disebabkan oleh anda.	",
    guildeline_topic_4: "	Banner dan Pautan Teks	",
    guildeline_topic4_des1:
      "	1. Hanya gunakan spanduk dan pautan teks kami mengikut cara yang diarahkan oleh kami dan tidak mengubahnya dengan apa -apa cara tanpa persetujuan bertulis terlebih dahulu	",
    guildeline_topic4_des2:
      "	2. Hanya spanduk paparan langsung dan pautan teks ke laman utama laman web kami.	",
    guildeline_topic_5: "	Kelakuan rujukan yang dilarang	",
    guildeline_sub_topic5_1:
      "	1. Anda tidak boleh terlibat dalam kelakuan yang menyalahi undang -undang, tidak wajar, mengelirukan, atau menipu atau membuat apa -apa representasi yang mungkin memberi kesan buruk terhadap reputasi kami atau produk dan perkhidmatan kami.	",
    guildeline_sub_topic5_2: "	2. Anda tidak boleh:	",
    guildeline_sub_topic5_2_1:
      "	Terlibat dalam apa -apa aktiviti haram apa -apa jenis, seperti memaparkan kandungan haram di laman web anda atau dalam e -mel langganan anda.	",
    guildeline_sub_topic5_2_2: "	Sasaran secara aktif:	",
    guildeline_sub_topic5_2_3:
      "Mana -mana orang yang berumur di bawah 18 tahun, tanpa mengira umur majoriti di lokasi di mana anda terlibat dalam aktiviti rujukan; dan",
    guildeline_sub_topic5_2_4:
      "	Mana -mana penduduk Amerika Syarikat, dan mana -mana negara lain di mana kami dilarang mendaftarkan peniaga, seperti yang diberitahu kepada anda dari semasa ke semasa.	",
    guildeline_sub_topic5_3:
      "	3. Log masuk ke akaun pedagang atau menjalankan aktiviti perdagangan bagi pihak mereka.	",
    guildeline_sub_topic5_4:
      "	4. Mengendalikan laman web yang mengandungi atau menggalakkan kandungan yang mengelirukan, memfitnah, atau haram, atau pautan ke laman web yang berbuat demikian. Kandungan yang mengelirukan termasuk membuat apa -apa janji berhubung dengan pulangan dan memaparkan penafian kami, lesen tanpa izin kami.	",
    guildeline_sub_topic5_5:
      "	5. Hantar mesej elektronik komersial yang tidak diminta (SPAM).	",
    guildeline_sub_topic5_6:
      "	6. Hantar peniaga berpotensi terus ke laman web kami melalui pautan rujukan anda ke laman web kami dalam mesej elektronik komersial, memaparkan rangkaian pengiklanan, kaunter, buku tamu, forum, blog, bilik sembang atau sumber internet yang serupa.	",
    guildeline_sub_topic5_7:
      "	7. Membolehkan transaksi Bad Faith, termasuk melalui mana -mana peranti, program, robot, alamat IP, pengalihan dan lalu lintas 'palsu'.	",
    guildeline_sub_topic5_8:
      "	8. Menetapkan apa -apa promosi yang memberikan ganjaran, mata, atau pampasan kepada peniaga untuk mendaftar untuk akaun dengan kami, termasuk apa -apa jenis perjanjian pembayaran, tanpa kelulusan kami terlebih dahulu.	",
    guildeline_sub_topic5_9:
      "	9. Ambil sebarang tindakan yang akan mencairkan atau mencemarkan nilai tanda dagangan atau penjenamaan kami.	",
  },
  id: {
    guildeline_banner: "	Pedoman Pemasaran	",
    guildeline_sub_banner:
      "	Kami disini untukmu. Kami fokus pada memberikan mitra kami kinerja, alat, dan dukungan yang diperlukan untuk tumbuh dan berhasil.	",
    guildeline_header: "	Pedoman Pemasaran Mitra	",
    guildeline_sub_header1:
      "	Sebagai mitra yang disukai Iuxmarket, kami memberi wewenang kepada Anda untuk menggunakan merek kami untuk mempromosikan IUXMarket, namun, ada pedoman sederhana dan mudah yang kami perlukan untuk Anda ikuti ketika Anda mempromosikan layanan kami.	",
    guildeline_sub_header2:
      "	Penting bagi Anda untuk tidak melanggar hak kekayaan intelektual kami, menyamar sebagai iuxmarket yang salah, atau menyebabkan kebingungan di pasar tentang hubungan Anda dengan kami. Silakan merujuk ke daftar di bawah ini tentang cara memastikan bahwa Anda berada dalam pedoman pemasaran.	",
    guildeline_sub_header3_1: "	Mohon jangan ragu	",
    guildeline_sub_header3_2: "	Hubungi salah satu staf pendukung ramah kami	",
    guildeline_sub_header3_3: "	Jika Anda memiliki pertanyaan.	",
    guildeline_topic_1: "	Klaim dan pernyataan pemasaran yang harus dihindari	",
    guildeline_topic1_des:
      "	Harap hindari menggunakan di bawah ini di salah satu materi iklan atau pemasaran Anda:	",
    guildeline_sub_topic1_1:
      "	Jangan membuat klaim atau pernyataan bahwa ada sedikit atau tidak ada risiko investor klien membuat kerugian.	",
    guildeline_sub_topic1_2:
      "	Jangan membuat janji manfaat apa pun ketika kemungkinan janji -janji ini tidak dapat dipenuhi.	",
    guildeline_sub_topic1_3:
      "	Hindari membuat klaim atau pernyataan yang menyesatkan, secara material salah atau salah untuk menarik atau membujuk seseorang untuk mengajukan akun bersama kami. Jika Anda tidak yakin apa yang harus dikatakan, silakan hubungi kami langsung dan kami akan dapat membantu Anda.	",
    guildeline_topic_2: "	Non-persaingan	",
    guildeline_topic2_des: "	Anda tidak boleh memasarkan ke pedagang potensial:	",
    guildeline_sub_topic2_1:
      "	Di situs mana pun di mana kami mempromosikan situs web kami.	",
    guildeline_sub_topic2_2:
      "	Di mesin pencari internet apa pun yang kami promosikan situs web kami.	",
    guildeline_sub_topic2_3:
      "	Dengan cara lain yang mengakibatkan Anda bersaing dengan kami sehubungan dengan promosi situs Anda termasuk tetapi tidak terbatas pada promosi situs Anda melalui afiliasi lain; Dan	",
    guildeline_sub_topic2_4:
      "	Perangkat lunak online, aplikasi, atau platform lain yang memungkinkan perdagangan online seperti dan/atau kompetitif dengan kami.	",
    guildeline_sub_topic2_5:
      "	Menggunakan iklan pencarian berbayar yang menargetkan kata kunci seperti ‘IUX Markets’ atau ‘Iux Markets Global’ atau variasi apa pun	",
    guildeline_topic2_des2:
      "	Jika Anda melanggar ketentuan ini, kami berhak untuk mengakhiri keanggotaan program Anda dan membatalkan hak biaya apa pun karena Anda.	",
    guildeline_topic_3: "	Hak kekayaan intelektual	",
    guildeline_topic3_des1:
      "	1. Anda mengakui bahwa kami akan mempertahankan kepemilikan semua hak (termasuk hak kekayaan intelektual) dalam materi yang kami kembangkan sebelum atau selama masa jabatan ini, termasuk agunan afiliasi. Tidak ada dalam Ketentuan ini yang harus ditafsirkan sebagai memberi Anda lisensi atau hak untuk menggunakan agunan afiliasi atau merek dagang dan branding kami yang lain selain sebagaimana diizinkan berdasarkan Ketentuan ini.	",
    guildeline_topic3_des2:
      "	2. Anda dapat menampilkan spanduk dan tautan teks di situs Anda, menggunakan agunan afiliasi lain, dan menahan diri untuk menjadi 'afiliasi global pasar IUX' untuk tujuan melakukan rujukan di bawah program afiliasi ini, jika Anda:	",
    guildeline_sub_topic3_1:
      "	Dapatkan persetujuan kami terlebih dahulu sebelum Anda mempublikasikan jaminan afiliasi apa pun (yang tidak akan kami temukan secara tidak masuk akal);	",
    guildeline_sub_topic3_1i:
      "	ii. menahan diri dari menggunakan agunan afiliasi sebagai bagian dari nama produk Anda sendiri, URL atau sebagai komponen logo lain; Dan	",
    guildeline_sub_topic3_1ii:
      "	aku aku aku. Tampilkan agunan afiliasi dengan cara yang sesuai dengan pedoman merek kami dan arah lain yang kami berikan dari waktu ke waktu.	",
    guildeline_sub_topic3_2:
      "	You will notify us immediately in writing if you become aware of an infringement on any of our intellectual property or other ownership rights, or if you become aware of a claim by any party that our products, trademarks, or logos infringe on a third party's intellectual Hak Properti atau Kepemilikan.	",
    guildeline_sub_topic3_3:
      "	Hindari membuat klaim atau pernyataan yang menyesatkan, secara material salah atau salah untuk menarik atau membujuk seseorang untuk mengajukan akun bersama kami. Jika Anda tidak yakin apa yang harus dikatakan, silakan hubungi kami langsung dan kami akan dapat membantu Anda.	",
    guildeline_topic3_des3:
      "	Jika Anda melanggar ketentuan ini, kami berhak untuk mengakhiri keanggotaan program Anda dan membatalkan hak biaya apa pun karena Anda.	",
    guildeline_topic_4: "	Spanduk dan tautan teks	",
    guildeline_topic4_des1:
      "	1. Hanya gunakan spanduk dan tautan teks kami dengan cara yang diarahkan oleh kami dan tidak memodifikasinya dengan cara apa pun tanpa persetujuan tertulis kami sebelumnya	",
    guildeline_topic4_des2:
      "	2. Hanya spanduk tampilan langsung dan tautan teks ke beranda situs web kami.	",
    guildeline_topic_5: "	Perilaku rujukan yang dilarang	",
    guildeline_sub_topic5_1:
      "	1. Anda tidak boleh terlibat dalam perilaku ilegal, tidak patut, menyesatkan, atau menipu atau membuat representasi apa pun yang mungkin memiliki efek merugikan pada reputasi kami atau produk dan layanan kami.	",
    guildeline_sub_topic5_2: "	2. Anda tidak boleh:	",
    guildeline_sub_topic5_2_1:
      "	Terlibat dalam aktivitas ilegal apa pun dari jenis apa pun, seperti menampilkan konten ilegal di situs web Anda atau dalam email berlangganan Anda.	",
    guildeline_sub_topic5_2_2: "	Target aktif:	",
    guildeline_sub_topic5_2_3:
      "	Setiap orang yang berusia di bawah 18 tahun, terlepas dari usia mayoritas di lokasi di mana Anda terlibat dalam kegiatan rujukan; Dan	",
    guildeline_sub_topic5_2_4:
      "	Setiap penduduk Amerika Serikat, dan negara -negara lain di mana kami dilarang mendaftarkan pedagang, sebagaimana diberitahukan kepada Anda dari waktu ke waktu.	",
    guildeline_sub_topic5_3:
      "	3. Masuk ke akun pedagang atau melakukan kegiatan perdagangan atas nama mereka.	",
    guildeline_sub_topic5_4:
      "	4. Operasi situs web yang berisi atau mempromosikan konten yang menyesatkan, memfitnah, atau ilegal, atau tautan ke situs web yang melakukannya. Konten yang menyesatkan termasuk membuat janji apa pun dalam kaitannya dengan pengembalian dan menampilkan penafian kami, lisensi tanpa izin tegas kami.	",
    guildeline_sub_topic5_5:
      "	5. Kirim pesan elektronik komersial yang tidak diminta (spam).	",
    guildeline_sub_topic5_6:
      "	6. Kirim pedagang potensial langsung ke situs web kami melalui tautan rujukan Anda ke situs web kami dalam pesan elektronik komersial, tampilan jaringan iklan, penghitung, buku tamu, forum, blog, ruang obrolan atau sumber daya internet serupa lainnya.	",
    guildeline_sub_topic5_7:
      "	7. Aktifkan transaksi itikad buruk, termasuk melalui perangkat apa pun, program, robot, alamat IP, pengalihan dan lalu lintas 'palsu'.	",
    guildeline_sub_topic5_8:
      "	8. Menetapkan promosi apa pun yang memberikan hadiah, poin, atau kompensasi kepada pedagang untuk mendaftar untuk akun bersama kami, termasuk segala jenis kesepakatan pembayaran, tanpa persetujuan kami sebelumnya.	",
    guildeline_sub_topic5_9:
      "	9. Ambil tindakan apa pun yang akan mencairkan atau menodai nilai merek dagang atau branding kami.	",
  },
  zh: {
    guildeline_banner: "	營銷準則	",
    guildeline_sub_banner:
      "	我們在這里為您服務。我們專注於為合作夥伴提供成長和成功所需的績效，工具和支持。	",
    guildeline_header: "	合作夥伴營銷準則	",
    guildeline_sub_header1:
      "	作為IUXMarkets首選合作夥伴，我們授權您使用我們的品牌來推廣IUXMarkets，但是，當您推廣我們的服務時，我們需要遵循一些簡單簡便的準則。	",
    guildeline_sub_header2:
      "	重要的是，您必須不要侵犯我們的知識產權，假冒或歪曲iuxmarkets，或在市場上引起您與我們關係的混亂。請參閱下面的列表，介紹如何確保您符合營銷準則。	",
    guildeline_sub_header3_1: "	請隨時	",
    guildeline_sub_header3_2: "	聯繫我們友好的支持人員之一	",
    guildeline_sub_header3_3: "	如有任何問題。	",
    guildeline_topic_1: "	營銷主張和聲明以避免	",
    guildeline_topic1_des: "	請避免在您的任何廣告或營銷材料中使用以下內容：	",
    guildeline_sub_topic1_1:
      "	避免提出任何索賠或聲明，即客戶投資者造成損失的風險很小或沒有。	",
    guildeline_sub_topic1_2: "	當可能無法實現這些諾言時，請勿承諾任何利益。	",
    guildeline_sub_topic1_3:
      "	避免提出任何誤導，實質性錯誤或錯誤的索賠或陳述，以吸引或說服一個人向我們申請帳戶。如果您不確定該說些什麼，請立即與我們聯繫，我們將能夠為您提供幫助。	",
    guildeline_topic_2: "	非競爭	",
    guildeline_topic2_des: "	您不得向潛在交易者推銷：	",
    guildeline_sub_topic2_1: "	在我們推廣網站的任何網站上。	",
    guildeline_sub_topic2_2: "	在我們推廣網站的任何互聯網搜索引擎上。	",
    guildeline_sub_topic2_3:
      "	以任何其他方式，您與我們競爭有關促進網站的競爭，包括但不限於通過其他分支機構促進您的網站；和	",
    guildeline_sub_topic2_4:
      "	任何其他在線軟件，應用程序或其他平台，都可以與我們進行在線交易。	",
    guildeline_sub_topic2_5:
      "	使用針對“ IUX市場”或“ Iux Markets Globals”或任何變化等關鍵字的付費搜索廣告	",
    guildeline_topic2_des2:
      "	如果您違反這些規定，我們保留終止您的計劃會員資格的權利，並剝奪您造成的任何費用權利。	",
    guildeline_topic_3: "	知識產權	",
    guildeline_topic3_des1:
      "	1.您承認，我們將保留所有權利的所有權（包括知識產權），以在本條款期間或期間（包括會員抵押品）期間開發的材料中。這些條款中的任何內容均不應被解釋為授予您使用會員抵押品或我們的其他任何商標和品牌的任何許可或權利，而不是根據本條款允許的。	",
    guildeline_topic3_des2:
      "	2.您可以在您的網站上顯示橫幅和文本鏈接，使用另一個會員抵押品，並將自己視為“ IUX Markets全球會員”，目的是根據該會員計劃進行推薦，如果您以下內容：	",
    guildeline_sub_topic3_1:
      "	在發布任何會員抵押品之前，請先獲得我們的批准（我們不會不合理地扣留）；	",
    guildeline_sub_topic3_1i:
      "	ii。避免使用會員抵押品作為您自己的產品名稱，URL或其他徽標組成部分的一部分；和	",
    guildeline_sub_topic3_1ii:
      "	iii。以符合我們的品牌準則以及我們不時給您的任何其他指示的方式顯示會員抵押品。	",
    guildeline_sub_topic3_2:
      "	如果您意識到我們的任何知識產權或其他所有權的侵權，或者您意識到我們產品，商標或徽標侵犯了第三方知識分子的任何一方的主張，您將立即以書面形式通知我們。財產或所有權。	",
    guildeline_sub_topic3_3:
      "	避免提出任何誤導，實質性錯誤或錯誤的索賠或陳述，以吸引或說服一個人向我們申請帳戶。如果您不確定該說些什麼，請立即與我們聯繫，我們將能夠為您提供幫助。	",
    guildeline_topic3_des3:
      "	如果您違反這些規定，我們保留終止您的計劃會員資格的權利，並剝奪您造成的任何費用權利。	",
    guildeline_topic_4: "	橫幅和文字鏈接	",
    guildeline_topic4_des1:
      "	1.僅以我們的指導方式使用我們的橫幅和文本鏈接，而不必以任何方式修改它們	",
    guildeline_topic4_des2: "	2.僅直接顯示橫幅和文本鏈接到我們網站的主頁。	",
    guildeline_topic_5: "	禁止推薦行為	",
    guildeline_sub_topic5_1:
      "	1.您不得從事任何非法，不當，誤導或欺騙性的行為，或做出可能對我們的聲譽或我們的產品和服務的任何陳述。	",
    guildeline_sub_topic5_2: "	2.您一定不能：	",
    guildeline_sub_topic5_2_1:
      "	從事任何類型的非法活動，例如在您的網站上或訂閱電子郵件中顯示非法內容。	",
    guildeline_sub_topic5_2_2: "	積極針對：	",
    guildeline_sub_topic5_2_3:
      "任何18歲以下的人，無論您從事推薦活動的地點多數年齡如何；和",
    guildeline_sub_topic5_2_4:
      "	美國的任何居民以及任何其他我們被禁止註冊交易者的國家，並不時通知您。	",
    guildeline_sub_topic5_3: "	3.登錄交易者帳戶或代表他們進行交易活動。	",
    guildeline_sub_topic5_4:
      "	4.操作一個包含或促進誤導性，誹謗或非法內容的網站，或者是指向這樣做的網站的鏈接。誤導性內容包括在未經我們明確許可的情況下做出任何與退貨和顯示我們的免責聲明，許可證的承諾。	",
    guildeline_sub_topic5_5: "	5.發送未經請求的商業電子消息（垃圾郵件）。	",
    guildeline_sub_topic5_6:
      "	6.通過您的推薦鏈接將潛在的交易者直接發送到我們的網站，以商業電子消息，顯示廣告網絡，計數器，留言簿，論壇，博客，聊天室或其他類似的互聯網資源。	",
    guildeline_sub_topic5_7:
      "	7.啟用惡意交易，包括通過任何設備，程序，機器人，IP地址，重定向和“虛假”流量。	",
    guildeline_sub_topic5_8:
      "	8.在未經我們事先批准的情況下，建立向交易者提供獎勵，積分或補償的任何促銷活動，包括任何類型的付款協議，包括任何類型的付款協議。	",
    guildeline_sub_topic5_9: "	9.採取任何將稀釋或損害我們商標或品牌價值的行動。	",
  },
  vi: {
    guildeline_banner: "	Hướng dẫn tiếp thị	",
    guildeline_sub_banner:
      "	Chúng tôi ở đây vì bạn. Chúng tôi tập trung vào việc cung cấp cho các đối tác của chúng tôi hiệu suất, công cụ và hỗ trợ cần thiết để phát triển và thành công.	",
    guildeline_header: "	Đối tác hướng dẫn tiếp thị	",
    guildeline_sub_header1:
      "	Là đối tác ưu tiên của IUXMarkets, chúng tôi cho phép bạn sử dụng thương hiệu của chúng tôi để quảng bá IUXMarkets, tuy nhiên, có những hướng dẫn đơn giản và dễ dàng mà chúng tôi yêu cầu bạn phải tuân theo khi bạn quảng bá dịch vụ của chúng tôi.	",
    guildeline_sub_header2:
      "	Điều quan trọng là bạn không vi phạm quyền sở hữu trí tuệ, mạo danh hoặc trình bày sai các iuxmarkets hoặc gây nhầm lẫn trên thị trường về mối quan hệ của bạn với chúng tôi. Vui lòng tham khảo danh sách dưới đây về cách đảm bảo rằng bạn nằm trong các hướng dẫn tiếp thị.	",
    guildeline_sub_header3_1: "	Xin vui lòng	",
    guildeline_sub_header3_2:
      "	Liên hệ với một trong những nhân viên hỗ trợ thân thiện của chúng tôi	",
    guildeline_sub_header3_3: "	Nếu bạn có câu hỏi nào.	",
    guildeline_topic_1: "	Tuyên bố và tuyên bố tiếp thị để tránh	",
    guildeline_topic1_des:
      "	Vui lòng tránh sử dụng bên dưới trong bất kỳ tài liệu quảng cáo hoặc tiếp thị nào của bạn:	",
    guildeline_sub_topic1_1:
      "	Không đưa ra bất kỳ yêu cầu hoặc tuyên bố nào rằng có rất ít hoặc không có rủi ro của nhà đầu tư khách hàng bị tổn thất.	",
    guildeline_sub_topic1_2:
      "	Không đưa ra bất kỳ lời hứa nào về bất kỳ lợi ích nào khi có khả năng những lời hứa này không thể được thực hiện.	",
    guildeline_sub_topic1_3:
      "	Tránh đưa ra bất kỳ khiếu nại hoặc tuyên bố sai lệch về mặt cá hoặc không chính xác để thu hút hoặc thuyết phục một người nộp đơn xin tài khoản với chúng tôi. Nếu bạn không chắc chắn những gì cần nói, vui lòng liên hệ ngay với chúng tôi và chúng tôi sẽ có thể hỗ trợ bạn.	",
    guildeline_topic_2: "	Không cạnh tranh	",
    guildeline_topic2_des:
      "	Bạn không được tiếp thị cho các nhà giao dịch tiềm năng:	",
    guildeline_sub_topic2_1:
      "	Trên bất kỳ trang web nơi chúng tôi quảng bá trang web của chúng tôi.	",
    guildeline_sub_topic2_2:
      "	Trên bất kỳ công cụ tìm kiếm internet nào mà chúng tôi quảng bá trang web của chúng tôi.	",
    guildeline_sub_topic2_3:
      "	Theo bất kỳ cách nào khác dẫn đến việc bạn cạnh tranh với chúng tôi liên quan đến việc quảng bá các trang web của bạn bao gồm nhưng không giới hạn ở việc quảng bá (các) trang web của bạn thông qua các chi nhánh khác; Và	",
    guildeline_sub_topic2_4:
      "	Bất kỳ phần mềm trực tuyến, ứng dụng hoặc nền tảng nào khác cho phép giao dịch trực tuyến như và/hoặc cạnh tranh với chúng tôi.	",
    guildeline_sub_topic2_5:
      "	Sử dụng các quảng cáo tìm kiếm trả phí nhắm mục tiêu các từ khóa như ‘iux thị trường, hoặc iux thị trường toàn cầu hoặc bất kỳ biến thể nào của điều đó	",
    guildeline_topic2_des2:
      "	Nếu bạn vi phạm các điều khoản này, chúng tôi có quyền chấm dứt tư cách thành viên của chương trình và vô hiệu hóa bất kỳ quyền phí nào do bạn.	",
    guildeline_topic_3: "	Quyền sở hữu trí tuệ	",
    guildeline_topic3_des1:
      "	1. Bạn thừa nhận rằng chúng tôi sẽ giữ quyền sở hữu tất cả các quyền (bao gồm cả quyền sở hữu trí tuệ) trong tài liệu chúng tôi phát triển trước hoặc trong thời hạn của các Điều khoản này, bao gồm cả tài sản thế chấp liên kết. Không có gì trong các Điều khoản này nên được hiểu là cấp cho bạn bất kỳ giấy phép hoặc quyền sử dụng tài sản thế chấp liên kết hoặc bất kỳ nhãn hiệu nào khác và thương hiệu khác của chúng tôi ngoài được phép theo các Điều khoản này.	",
    guildeline_topic3_des2:
      "	2. Bạn có thể hiển thị các biểu ngữ và liên kết văn bản trên trang web của mình, sử dụng một tài sản thế chấp liên kết khác và tự mình trở thành một thị trường iux thị trường toàn cầu liên kết với mục đích tiến hành giới thiệu theo chương trình liên kết này, nếu bạn:	",
    guildeline_sub_topic3_1:
      "	Có được sự chấp thuận của chúng tôi trước khi bạn xuất bản bất kỳ tài sản thế chấp liên kết nào (mà chúng tôi sẽ không bị giữ lại một cách vô lý);	",
    guildeline_sub_topic3_1i:
      "	ii. Không sử dụng tài sản thế chấp liên kết như là một phần của tên sản phẩm, URL hoặc thành phần của logo khác; Và	",
    guildeline_sub_topic3_1ii:
      "	iii. Hiển thị tài sản thế chấp liên kết theo cách tuân thủ các hướng dẫn thương hiệu của chúng tôi và bất kỳ hướng dẫn nào khác mà chúng tôi cung cấp cho bạn theo thời gian.	",
    guildeline_sub_topic3_2:
      "	Bạn sẽ thông báo cho chúng tôi ngay lập tức bằng văn bản nếu bạn biết về việc vi phạm bất kỳ tài sản trí tuệ nào hoặc quyền sở hữu khác của chúng tôi, hoặc nếu bạn nhận thức được yêu cầu của bất kỳ bên nào rằng sản phẩm, nhãn hiệu hoặc logo của chúng tôi quyền sở hữu hoặc quyền sở hữu.	",
    guildeline_sub_topic3_3:
      "	Tránh đưa ra bất kỳ khiếu nại hoặc tuyên bố sai lệch về mặt cá hoặc không chính xác để thu hút hoặc thuyết phục một người nộp đơn xin tài khoản với chúng tôi. Nếu bạn không chắc chắn những gì cần nói, vui lòng liên hệ ngay với chúng tôi và chúng tôi sẽ có thể hỗ trợ bạn.	",
    guildeline_topic3_des3:
      "	Nếu bạn vi phạm các điều khoản này, chúng tôi có quyền chấm dứt tư cách thành viên của chương trình và vô hiệu hóa bất kỳ quyền phí nào do bạn.	",
    guildeline_topic_4: "	Biểu ngữ và liên kết văn bản	",
    guildeline_topic4_des1:
      "	1. Chỉ sử dụng các biểu ngữ và liên kết văn bản của chúng tôi theo cách do chúng tôi chỉ đạo và không sửa đổi chúng theo bất kỳ cách nào mà không có sự đồng ý trước bằng văn bản của chúng tôi	",
    guildeline_topic4_des2:
      "	2. Chỉ các biểu ngữ hiển thị trực tiếp và liên kết văn bản đến trang chủ của trang web của chúng tôi.	",
    guildeline_topic_5: "	Hành vi giới thiệu bị cấm	",
    guildeline_sub_topic5_1:
      "	1. Bạn không được tham gia vào bất kỳ hành vi bất hợp pháp, không chính đáng, sai lệch hoặc lừa đảo hoặc thực hiện bất kỳ đại diện nào có thể có ảnh hưởng bất lợi đến danh tiếng của chúng tôi hoặc các sản phẩm và dịch vụ của chúng tôi.	",
    guildeline_sub_topic5_2: "	2. Bạn không được:	",
    guildeline_sub_topic5_2_1:
      "	Tham gia vào bất kỳ hoạt động bất hợp pháp nào thuộc bất kỳ loại nào, chẳng hạn như hiển thị nội dung bất hợp pháp trên trang web của bạn hoặc trong email đăng ký của bạn.	",
    guildeline_sub_topic5_2_2: "	Tích cực nhắm mục tiêu:	",
    guildeline_sub_topic5_2_3:
      "	Bất kỳ ai dưới 18 tuổi, bất kể tuổi của đa số ở vị trí mà bạn đang tham gia vào các hoạt động giới thiệu; Và	",
    guildeline_sub_topic5_2_4:
      "	Bất kỳ cư dân nào của Hoa Kỳ và bất kỳ quốc gia nào khác nơi chúng tôi bị cấm đăng ký thương nhân, như được thông báo cho bạn theo thời gian.	",
    guildeline_sub_topic5_3:
      "	3. Đăng nhập vào tài khoản thương nhân hoặc thực hiện các hoạt động giao dịch thay mặt họ.	",
    guildeline_sub_topic5_4:
      "	4. Vận hành một trang web có chứa hoặc quảng bá nội dung sai lệch, phỉ báng hoặc bất hợp pháp hoặc liên kết đến một trang web làm như vậy. Nội dung sai lệch bao gồm đưa ra bất kỳ lời hứa nào liên quan đến lợi nhuận và hiển thị từ chối trách nhiệm, giấy phép mà không có sự cho phép rõ ràng của chúng tôi.	",
    guildeline_sub_topic5_5:
      "	5. Gửi tin nhắn điện tử thương mại không mong muốn (thư rác).	",
    guildeline_sub_topic5_6:
      "	6. Gửi giao dịch tiềm năng trực tiếp đến trang web của chúng tôi thông qua các liên kết giới thiệu của bạn đến (các) trang web của chúng tôi trong các tin nhắn điện tử thương mại, hiển thị mạng quảng cáo, quầy, sổ lưu niệm, diễn đàn, blog, phòng trò chuyện hoặc tài nguyên Internet tương tự khác.	",
    guildeline_sub_topic5_7:
      "	7. Kích hoạt các giao dịch đức tin xấu, bao gồm thông qua bất kỳ thiết bị, chương trình, robot, địa chỉ IP, chuyển hướng và lưu lượng ’không có thật.	",
    guildeline_sub_topic5_8:
      "	8. Thiết lập bất kỳ chương trình khuyến mãi nào cung cấp phần thưởng, điểm hoặc bồi thường cho một nhà giao dịch để đăng ký tài khoản với chúng tôi, bao gồm bất kỳ loại thỏa thuận thanh toán nào, mà không có sự chấp thuận trước của chúng tôi.	",
    guildeline_sub_topic5_9:
      "	9. Thực hiện bất kỳ hành động nào sẽ pha loãng hoặc làm mờ giá trị của nhãn hiệu hoặc thương hiệu của chúng tôi.	",
  },
  ko: {
    guildeline_banner: "	마케팅 지침	",
    guildeline_sub_banner:
      "	우리는 당신을 위해 여기 있습니다. 우리는 파트너에게 성장하고 성공하는 데 필요한 성능, 도구 및 지원을 제공하는 데 중점을 둡니다.	",
    guildeline_header: "	파트너 마케팅 지침	",
    guildeline_sub_header1:
      "	Iuxmarkets Preferred Partner로서 우리는 귀하가 브랜드를 사용하여 IUXMARKETS를 홍보 할 권한을 부여하지만 서비스를 홍보 할 때 따라야하는 간단하고 쉬운 지침이 있습니다.	",
    guildeline_sub_header2:
      "	우리의 지적 재산권을 침해하지 않거나, IUXMARKETS를 가장하거나 해당하는 것이 중요합니다. 마케팅 가이드 라인 내에 있는지 확인하는 방법은 아래 목록을 참조하십시오.	",
    guildeline_sub_header3_1: "	자유롭게 해주세요	",
    guildeline_sub_header3_2: "	친절한 지원 직원 중 한 명에게 연락하십시오	",
    guildeline_sub_header3_3: "	만약 질문이 있다면.	",
    guildeline_topic_1: "	피해야 할 마케팅 클레임 및 진술	",
    guildeline_topic1_des: "	광고 또는 마케팅 자료에 아래를 사용하지 마십시오.	",
    guildeline_sub_topic1_1:
      "	고객 투자자가 손실을 입을 위험이 거의 없거나 전혀 없다는 주장이나 진술을 자제하십시오.	",
    guildeline_sub_topic1_2:
      "	이러한 약속을 이행 할 수 없을 때 혜택을 약속하지 마십시오.	",
    guildeline_sub_topic1_3:
      "	사람이 우리와 계정을 신청하도록 유치하거나 설득하기 위해 오도, 실질적으로 허위 또는 잘못된 주장이나 진술을 피하십시오. 무엇을 말 해야할지 잘 모르겠다면 바로 저희에게 연락하시면 도와 드리겠습니다.	",
    guildeline_topic_2: "	비 경쟁	",
    guildeline_topic2_des: "	잠재적 인 거래자에게 마케팅해서는 안됩니다.	",
    guildeline_sub_topic2_1: "	웹 사이트를 홍보하는 모든 사이트에서.	",
    guildeline_sub_topic2_2: "	웹 사이트를 홍보하는 인터넷 검색 엔진에서.	",
    guildeline_sub_topic2_3:
      "	다른 어떤 방식 으로든 다른 계열사를 통한 귀하의 사이트 홍보를 포함하여 귀하의 사이트의 홍보와 관련하여 귀하가 당사와 경쟁하게합니다. 그리고	",
    guildeline_sub_topic2_4:
      "	기타 온라인 소프트웨어, 애플리케이션 또는 기타 플랫폼과 같은 온라인 거래 및/또는 경쟁력이 있습니다.	",
    guildeline_sub_topic2_5:
      "	'IUX Markets'또는 'IUX Markets Global'과 같은 키워드를 대상으로하는 유료 검색 광고 또는 그 변형	",
    guildeline_topic2_des2:
      "	귀하 가이 조항을 위반하는 경우, 당사는 프로그램의 회원 자격을 종료 할 권리를 보유하고 귀하로 인해 수수료 자격을 무효화합니다.	",
    guildeline_topic_3: "	지적 재산권	",
    guildeline_topic3_des1:
      "	1. 귀하는 우리가 계열사 담보를 포함하여 본 약관 전 또는 기간 동안 우리가 개발 한 자료에서 모든 권리의 소유권 (지적 재산권 포함)을 유지할 것임을 인정합니다. 본 약관의 어느 것도 귀하에게 귀하에게 계열사 담보 또는 기타 상표 및 기타 상표 및 브랜딩을 본 약관에 따라 허용하는 것 이외의 라이센스 또는 권리를 부여하는 것으로 해석되어서는 안됩니다.	",
    guildeline_topic3_des2:
      "	2. 귀하는 귀하의 사이트에 배너 및 텍스트 링크를 표시하고, 다른 계열사 담보를 사용하고,이 제휴 프로그램에 따라 추천을 수행 할 목적으로 다음과 같은 경우 'IUX Markets Global Affiliate'로 자신을 붙잡을 수 있습니다.	",
    guildeline_sub_topic3_1:
      "	제휴 담보물을 게시하기 전에 먼저 승인을 받으십시오 (우리는 비합리적으로 보류하지 않을 것입니다).	",
    guildeline_sub_topic3_1i:
      "	II. 자신의 제품 이름, URL 또는 다른 로고의 구성 요소로 제휴 담보물 사용을 자제하십시오. 그리고	",
    guildeline_sub_topic3_1ii:
      "	III. 브랜드 지침 및 때때로 귀하에게 제공하는 다른 방향을 준수하는 방식으로 제휴 담보를 표시하십시오.	",
    guildeline_sub_topic3_2:
      "	당사의 지적 재산 또는 기타 소유권에 대한 침해를 인식하거나 당사의 제품, 상표 또는 로고가 제 3 자의 지적에 대한 주장을 인식하는 경우 귀하는 즉시 서면으로 알려줍니다. 재산 또는 소유권.	",
    guildeline_sub_topic3_3:
      "	사람이 우리와 계정을 신청하도록 유치하거나 설득하기 위해 오도, 실질적으로 허위 또는 잘못된 주장이나 진술을 피하십시오. 무엇을 말 해야할지 잘 모르겠다면 바로 저희에게 연락하시면 도와 드리겠습니다.	",
    guildeline_topic3_des3:
      "	귀하 가이 조항을 위반하는 경우, 당사는 프로그램의 회원 자격을 종료 할 권리를 보유하고 귀하로 인해 수수료 자격을 무효화합니다.	",
    guildeline_topic_4: "	배너 및 텍스트 링크	",
    guildeline_topic4_des1:
      "	1. 우리가 지시하는 방식으로 배너와 텍스트 링크 만 사용하고 사전 서면 동의 없이는 어떤 식 으로든 수정하지 마십시오.	",
    guildeline_topic4_des2:
      "	2. 당사 웹 사이트의 홈페이지에 대한 직접 표시 배너 및 텍스트 링크 만 있습니다.	",
    guildeline_topic_5: "	금지 된 추천 행동	",
    guildeline_sub_topic5_1:
      "	1. 귀하는 불법적, 부적절한, 오해의 소지가 있거나기만적인 행동에 관여해서는 안됩니다. 또는 우리의 명성이나 제품 및 서비스의 명성에 해로운 영향을 줄 수있는 진술을하지 않아야합니다.	",
    guildeline_sub_topic5_2: "	2. 당신은 다음과 같아야합니다.	",
    guildeline_sub_topic5_2_1:
      "	웹 사이트 나 구독 이메일에 불법 콘텐츠 표시와 같은 모든 유형의 불법 활동에 참여하십시오.	",
    guildeline_sub_topic5_2_2: "	적극적으로 목표 :	",
    guildeline_sub_topic5_2_3:
      "	추천 활동에 참여하는 위치의 대다수 나이에 관계없이 18 세 미만의 사람; 그리고",
    guildeline_sub_topic5_2_4:
      "	미국 거주자 및 당사가 상인 등록을 금지하는 다른 국가는 때때로 귀하에게 통보됩니다.	",
    guildeline_sub_topic5_3:
      "	3. 상인 계정에 로그인하거나 자신을 대신하여 거래 활동을 수행하십시오.	",
    guildeline_sub_topic5_4:
      "	4. 오도, 명예 훼손 또는 불법 콘텐츠 또는 웹 사이트 링크를 포함하거나 홍보하는 웹 사이트를 운영합니다. 오해의 소지가있는 콘텐츠에는 반품과 관련하여 약속을하고 면책 조항, 명시적인 허가없이 라이센스 표시가 포함됩니다.	",
    guildeline_sub_topic5_5:
      "	5. 원치 않는 상업용 전자 메시지 (SPAM)를 보내십시오.	",
    guildeline_sub_topic5_6:
      "	6. 상용 전자 메시지, 광고 네트워크, 카운터, 방명록, 포럼, 블로그, 채팅방 또는 기타 유사한 인터넷 리소스의 웹 사이트에 대한 추천 링크를 통해 잠재적 인 상인을 당사 웹 사이트로 직접 보내십시오.	",
    guildeline_sub_topic5_7:
      "	7. 모든 장치, 프로그램, 로봇, IP 주소, 리디렉션 및 '가짜'트래픽을 포함한 악의 거래를 가능하게합니다.	",
    guildeline_sub_topic5_8:
      "	8. 사전 승인없이 모든 종류의 지불 거래를 포함하여 당사에 계정을 등록하기 위해 상인에게 보상, 포인트 또는 보상을 제공하는 프로모션을 설정합니다.	",
    guildeline_sub_topic5_9:
      "	9. 상표 나 브랜딩의 가치를 희석하거나 손상시키는 조치를 취하십시오.	",
  },
  fil: {
    guildeline_banner: "	Mga Alituntunin sa Marketing	",
    guildeline_sub_banner:
      "	Nandito kami para sa iyo. Tumutuon kami sa pagbibigay ng aming mga kasosyo sa pagganap, mga tool at suporta na kinakailangan upang lumago at magtagumpay.	",
    guildeline_header: "	Mga Alituntunin sa Marketing ng Kasosyo	",
    guildeline_sub_header1:
      "	Bilang isang Iuxmarkets na ginustong kasosyo, pinapayagan ka naming gamitin ang aming tatak upang maisulong ang mga Iuxmarkets, gayunpaman, may mga simple at madaling alituntunin na hinihiling namin na sundin ka kapag isinusulong mo ang aming mga serbisyo.	",
    guildeline_sub_header2:
      "	Mahalaga na hindi ka lumalabag sa aming mga karapatan sa intelektwal na pag -aari, magkamali, o maling impormasyon sa Iuxmarkets, o maging sanhi ng pagkalito sa pamilihan tungkol sa iyong relasyon sa amin. Mangyaring sumangguni sa listahan sa ibaba kung paano matiyak na nasa loob ka ng mga alituntunin sa marketing.	",
    guildeline_sub_header3_1: "	Mangyaring huwag mag -atubiling	",
    guildeline_sub_header3_2:
      "	Makipag -ugnay sa isa sa aming magiliw na kawani ng suporta	",
    guildeline_sub_header3_3: "	kung mayroon kang mga katanungan.	",
    guildeline_topic_1: "	Mga paghahabol sa marketing at pahayag upang maiwasan	",
    guildeline_topic1_des:
      "	Mangyaring iwasan ang paggamit sa ibaba sa alinman sa iyong mga materyales sa advertising o marketing:	",
    guildeline_sub_topic1_1:
      "	Tumanggi sa paggawa ng anumang pag -angkin o pahayag na may kaunti o walang panganib ng mamumuhunan ng kliyente na nawala.	",
    guildeline_sub_topic1_2:
      "	Huwag gumawa ng anumang mga pangako ng anumang mga benepisyo kung malamang na hindi matupad ang mga pangakong ito.	",
    guildeline_sub_topic1_3:
      "	Iwasan ang paggawa ng anumang nakaliligaw, materyal na hindi totoo o hindi tamang pag -angkin o pahayag upang maakit o hikayatin ang isang tao na mag -aplay para sa isang account sa amin. Kung hindi ka sigurado sa kung ano ang sasabihin mangyaring makipag -ugnay sa amin kaagad at magagawa naming tulungan ka.	",
    guildeline_topic_2: "	Hindi nakikipagkumpitensya	",
    guildeline_topic2_des:
      "	Hindi ka dapat mag -market sa mga potensyal na mangangalakal:	",
    guildeline_sub_topic2_1:
      "	Sa anumang site kung saan isinusulong namin ang aming website.	",
    guildeline_sub_topic2_2:
      "	Sa anumang Internet search engine kung saan isinusulong namin ang aming website.	",
    guildeline_sub_topic2_3:
      "	Sa anumang iba pang paraan na nagreresulta sa iyo na nakikipagkumpitensya sa amin na may kaugnayan sa pagsulong ng iyong mga site kabilang ang ngunit hindi limitado sa pagsulong ng iyong (mga) site sa pamamagitan ng iba pang mga kaakibat; at	",
    guildeline_sub_topic2_4:
      "	Anumang iba pang online software, application o iba pang platform na nagpapagana ng online trading tulad ng at/o mapagkumpitensya sa amin.	",
    guildeline_sub_topic2_5:
      "	Gamit ang bayad na mga ad sa paghahanap na target ang mga keyword tulad ng 'Iux market' o 'iux market global' o anumang pagkakaiba -iba ng iyon	",
    guildeline_topic2_des2:
      "	Kung nilabag mo ang mga probisyon na ito, may karapatan kaming wakasan ang iyong pagiging kasapi ng programa at walang bisa ang anumang mga karapatan sa bayad dahil sa iyo.	",
    guildeline_topic_3: "	Mga Karapatang Ari -arian ng Intelektwal	",
    guildeline_topic3_des1:
      "	1. Kinikilala mo na panatilihin namin ang pagmamay -ari ng lahat ng mga karapatan (kabilang ang mga karapatan sa intelektwal na pag -aari) sa materyal na ating binuo bago o sa panahon ng termino ng mga term na ito, kabilang ang kaakibat na collateral. Wala sa mga term na ito ang dapat maipahiwatig bilang pagbibigay sa iyo ng anumang lisensya o karapatan na gamitin ang kaakibat na collateral o alinman sa aming iba pang mga trademark at pagba -brand maliban sa pinapayagan sa ilalim ng mga Tuntunin.	",
    guildeline_topic3_des2:
      "	2. Maaari kang magpakita ng mga banner at mga link sa teksto sa iyong site, gumamit ng isa pang kaakibat na collateral, at hawakan ang iyong sarili upang maging isang 'Iux Markets Global Affiliate' para sa layunin ng pagsasagawa ng mga sanggunian sa ilalim ng programang ito ng kaakibat, kung ikaw:	",
    guildeline_sub_topic3_1:
      "	Kunin muna ang aming pag -apruba bago mo mai -publish ang anumang kaakibat na collateral (na hindi namin maiiwasang mapigil);	",
    guildeline_sub_topic3_1i:
      "	ii. Tumanggi sa paggamit ng kaakibat na collateral bilang bahagi ng iyong sariling pangalan ng produkto, URL o bilang isang bahagi ng isa pang logo; at	",
    guildeline_sub_topic3_1ii:
      "	III. Ipakita ang kaakibat na collateral sa isang paraan na sumusunod sa aming mga alituntunin ng tatak at anumang iba pang mga direksyon na ibinibigay namin sa iyo sa pana -panahon.	",
    guildeline_sub_topic3_2:
      "	Sasabihan mo kami kaagad sa pagsulat kung nalalaman mo ang isang paglabag sa alinman sa aming intelektwal na pag -aari o iba pang mga karapatan sa pagmamay -ari, o kung nalaman mo ang isang pag -angkin ng anumang partido na ang aming mga produkto, trademark, o mga logo ay lumabag sa intelektwal ng isang ikatlong partido mga karapatan sa pag -aari o pagmamay -ari.	",
    guildeline_sub_topic3_3:
      "	Iwasan ang paggawa ng anumang nakaliligaw, materyal na hindi totoo o hindi tamang pag -angkin o pahayag upang maakit o hikayatin ang isang tao na mag -aplay para sa isang account sa amin. Kung hindi ka sigurado sa kung ano ang sasabihin mangyaring makipag -ugnay sa amin kaagad at magagawa naming tulungan ka.	",
    guildeline_topic3_des3:
      "	Kung nilabag mo ang mga probisyon na ito, may karapatan kaming wakasan ang iyong pagiging kasapi ng programa at walang bisa ang anumang mga karapatan sa bayad dahil sa iyo.	",
    guildeline_topic_4: "	Mga banner at mga link sa teksto	",
    guildeline_topic4_des1:
      "	1. Gamitin lamang ang aming mga banner at mga link sa teksto sa paraang itinuro sa amin at hindi baguhin ang mga ito sa anumang paraan nang wala ang aming paunang nakasulat na pahintulot	",
    guildeline_topic4_des2:
      "	2. Direktang mga banner ng display at mga link sa teksto sa homepage ng aming website.	",
    guildeline_topic_5: "	Ipinagbabawal na pag -uugali ng referral	",
    guildeline_sub_topic5_1:
      "	1. Hindi ka dapat makisali sa anumang iligal, hindi wasto, nakaliligaw, o mapanlinlang na pag -uugali o gumawa ng anumang mga representasyon na maaaring magkaroon ng masamang epekto sa aming reputasyon o sa aming mga produkto at serbisyo.	",
    guildeline_sub_topic5_2: "	2. Hindi mo dapat:	",
    guildeline_sub_topic5_2_1:
      "	Makisali sa anumang iligal na aktibidad ng anumang uri, tulad ng pagpapakita ng iligal na nilalaman sa iyong website o sa iyong mga email sa subscription.	",
    guildeline_sub_topic5_2_2: "	Aktibong target:	",
    guildeline_sub_topic5_2_3:
      "Ang sinumang tao na wala pang 18 taong gulang, anuman ang edad ng karamihan sa lokasyon kung saan nakikipag -ugnayan ka sa mga aktibidad ng referral; at",
    guildeline_sub_topic5_2_4:
      "	Ang sinumang residente ng Estados Unidos, at anumang iba pang mga bansa kung saan tayo ay pinagbawalan mula sa pagrehistro ng mga mangangalakal, tulad ng na -notify sa iyo paminsan -minsan.	",
    guildeline_sub_topic5_3:
      "	3. Mag -log in sa Trader Account o magsagawa ng mga aktibidad sa pangangalakal sa kanilang ngalan.	",
    guildeline_sub_topic5_4:
      "	4. Patakbuhin ang isang website na naglalaman o nagtataguyod ng nakaliligaw, mapanirang -puri, o iligal na nilalaman, o isang link sa isang website na gumagawa nito. Kasama sa nakaliligaw na nilalaman ang paggawa ng anumang mga pangako kung ano ang may kaugnayan sa pagbabalik at pagpapakita ng aming pagtanggi, lisensya nang walang pahintulot ng aming ekspresyon.	",
    guildeline_sub_topic5_5:
      "	5. Magpadala ng hindi hinihinging komersyal na mga elektronikong mensahe (SPAM).	",
    guildeline_sub_topic5_6:
      "	6. Magpadala ng potensyal na negosyante nang direkta sa aming website sa pamamagitan ng iyong mga link sa referral sa aming (mga) website sa mga komersyal na mensahe ng elektronik, ipakita ang mga network ng advertising, counter, guestbook, forum, blog, chat room o isa pang katulad na mga mapagkukunan sa internet.	",
    guildeline_sub_topic5_7:
      "	7. Paganahin ang masamang mga transaksyon sa pananampalataya, kabilang ang sa pamamagitan ng anumang aparato, programa, robot, IP address, redirect at trapiko ng 'bogus'.	",
    guildeline_sub_topic5_8:
      "	8. Magtatag ng anumang promosyon na nagbibigay ng mga gantimpala, puntos, o kabayaran sa isang negosyante para sa pagrehistro para sa isang account sa amin, kasama ang anumang uri ng pakikitungo sa pagbabayad, nang walang aming naunang pag -apruba.	",
    guildeline_sub_topic5_9:
      "	9. Gumawa ng anumang aksyon na magpapawalang -bisa o masira ang halaga ng aming mga trademark o pagba -brand.	",
  },
  ja: {
    guildeline_banner: "マーケティングガイドライン",
    guildeline_sub_banner:
      "私たちはあなたのためにここにいます。 私たちは、成長と成功に必要なパフォーマンス、ツール、サポートをパートナーに提供することに焦点を当てています。",
    guildeline_header: "パートナーマーケティングガイドライン",
    guildeline_sub_header1:
      "iuxmarketsの好みのパートナーとして、当社のブランドを使用してiuxMarketsを宣伝することを許可しますが、サービスを宣伝するときに従う必要があるシンプルで簡単なガイドラインがあります。",
    guildeline_sub_header2:
      "私たちの知的財産権を侵害したり、IuxMarketsを偽装したり、誤って伝えたりすること、または私たちとの関係について市場で混乱を引き起こさないことが重要です。 マーケティングガイドライン内にいることを確認する方法については、以下のリストを参照してください。",
    guildeline_sub_header3_1: "お気軽にお願いします",
    guildeline_sub_header3_2:
      "フレンドリーなサポートスタッフの1人に連絡してください",
    guildeline_sub_header3_3: "ご質問がある場合。",
    guildeline_topic_1: "避けるべきマーケティングの主張と声明",
    guildeline_topic1_des:
      "あなたの広告またはマーケティング資料のいずれかで以下を使用しないでください：",
    guildeline_sub_topic1_1:
      "クライアントの投資家が損失を被るリスクはほとんどまたはまったくないという主張や声明を出さないでください。",
    guildeline_sub_topic1_2:
      "これらの約束を果たせない可能性が高い場合、利益の約束をしないでください。",
    guildeline_sub_topic1_3:
      "誤解を招く、実質的に虚偽、または誤った主張や声明を出して、人を引き付けたり説得したりするように、私たちと一緒にアカウントを申請するよう説得しないでください。 あなたが何を言うべきかわからない場合は、すぐに私たちに連絡してください、そして私たちはあなたを助けることができるでしょう。",
    guildeline_topic_2: "非競争",
    guildeline_topic2_des: "潜在的なトレーダーに販売してはなりません：",
    guildeline_sub_topic2_1: "当社のウェブサイトを宣伝するサイトで。",
    guildeline_sub_topic2_2:
      "当社のウェブサイトを宣伝するインターネット検索エンジンで。",
    guildeline_sub_topic2_3:
      "他の方法で、他のアフィリエイトを通じてサイトの宣伝を含むがこれらに限定されないサイトの促進に関連して私たちと競争することになります。 そして",
    guildeline_sub_topic2_4:
      "他のオンラインソフトウェア、アプリケーション、またはその他のプラットフォームは、当社とのようなオンライン取引および/または競争を可能にします。",
    guildeline_sub_topic2_5:
      "「IUXマーケット」や「IUX Markets Global」などのキーワード、またはそのバリエーションなどのキーワードをターゲットにする有料検索広告を使用する",
    guildeline_topic2_des2:
      "これらの規定に違反した場合、当社はプログラムのメンバーシップを終了する権利を留保し、お客様のために料金の資格を無効にします。",
    guildeline_topic_3: "知的財産権",
    guildeline_topic3_des1:
      "1.お客様は、アフィリエイトの担保を含むこれらの条件の前または期間中に開発する資料において、すべての権利（知的財産権を含む）の所有権を保持することを認めます。 これらの条件では、アフィリエイトの担保または他の商標およびブランドを使用するライセンスまたは権利をあなたに付与するものと解釈されるべきではありません。",
    guildeline_topic3_des2:
      "2.サイトにバナーとテキストリンクを表示し、別のアフィリエイトの担保を使用し、このアフィリエイトプログラムに基づいて紹介を実施する目的で「IUX Marketsグローバルアフィリエイト」になるように自分自身を控えることができます。",
    guildeline_sub_topic3_1:
      "アフィリエイトの担保を公開する前に、最初に承認を得ることができます（これは不当に差し控えられません）。",
    guildeline_sub_topic3_1i:
      "ii。 自分の製品名、URLの一部として、または別のロゴのコンポーネントとしてアフィリエイトの担保を使用しないでください。 そして",
    guildeline_sub_topic3_1ii:
      "iii。 アフィリエイトの担保を、当社のブランドガイドラインや、随時提供する他の方向に準拠している方法で表示します。",
    guildeline_sub_topic3_2:
      "当社の知的財産またはその他の所有権のいずれかの侵害に気付いた場合、または当社の製品、商標、または第三者の知的侵害を侵害している当事者による請求に気付くと、すぐに書面で通知します。 財産または所有権。",
    guildeline_sub_topic3_3:
      "誤解を招く、実質的に虚偽、または誤った主張や声明を出して、人を引き付けたり説得したりするように、私たちと一緒にアカウントを申請するよう説得しないでください。 あなたが何を言うべきかわからない場合は、すぐに私たちに連絡してください、そして私たちはあなたを助けることができるでしょう。",
    guildeline_topic3_des3:
      "これらの規定に違反した場合、当社はプログラムのメンバーシップを終了する権利を留保し、お客様のために料金の資格を無効にします。",
    guildeline_topic_4: "バナーとテキストリンク",
    guildeline_topic4_des1:
      "1.私たちが指示する方法でバナーとテキストリンクのみを使用し、事前の書面による同意なしにそれらを変更しないでください",
    guildeline_topic4_des2:
      "2.当社のウェブサイトのホームページへの直接ディスプレイバナーとテキストリンクのみ。",
    guildeline_topic_5: "禁止された紹介行動",
    guildeline_sub_topic5_1:
      "1.違法、不適切な、誤解を招く、または欺cept的な行為に従事したり、私たちの評判や製品やサービスに有害な影響を与える可能性のある表明をしてはなりません。",
    guildeline_sub_topic5_2: "2.してはいけません：",
    guildeline_sub_topic5_2_1:
      "Webサイトやサブスクリプションメールに違法なコンテンツを表示するなど、あらゆるタイプの違法行為に従事します。",
    guildeline_sub_topic5_2_2: "積極的にターゲット：",
    guildeline_sub_topic5_2_3:
      "紹介活動に従事している場所の過半数に関係なく、18歳未満の人。 そして",
    guildeline_sub_topic5_2_4:
      "米国の居住者、および私たちが登録トレーダーを禁止されている他の国々は、随時あなたに通知されます。",
    guildeline_sub_topic5_3:
      "3.トレーダーアカウントにログインするか、それに代わって取引活動を実施します。",
    guildeline_sub_topic5_4:
      "4.誤解を招く、中傷的、または違法なコンテンツを含むまたは促進するWebサイト、またはそうするWebサイトへのリンクを運営します。 誤解を招くコンテンツには、返品に関連して約束をして、免責事項の表示、明示的な許可なしにライセンスを付けることが含まれます。",
    guildeline_sub_topic5_5:
      "5.未承諾の商用電子メッセージ（スパム）を送信します。",
    guildeline_sub_topic5_6:
      "6.商用電子メッセージの当社のウェブサイトへの紹介リンク、ディスプレイ広告ネットワーク、カウンター、ゲストブック、フォーラム、ブログ、チャットルーム、または別の同様のインターネットリソースの紹介リンクを介して、潜在的なトレーダーを当社のウェブサイトに直接送信します。",
    guildeline_sub_topic5_7:
      "7.デバイス、プログラム、ロボット、IPアドレス、リダイレクト、「偽物」トラフィックを介して、悪意の取引を有効にします。",
    guildeline_sub_topic5_8:
      "8.事前の承認なしに、あらゆる種類の支払い契約を含め、当社とのアカウントに登録するためにトレーダーに報酬、ポイント、または報酬を提供するプロモーションを確立します。",
    guildeline_sub_topic5_9:
      "9.商標またはブランディングの価値を希釈または傷つけるアクションを実行します。",
  },
};
