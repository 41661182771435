export const renderApiDomain = () => {
  let isLink = typeof window !== "undefined" && window.location.origin;

  switch (isLink) {
    case "https://portal.iuxvn.com":
      return process.env.REACT_APP_API_URL_V2;
    case "https://portal.id-iux.com":
      return process.env.REACT_APP_API_URL_V2;
    case "https://portal.iuxvn-trading.com":
      return process.env.REACT_APP_API_URL_V2;
    default:
      return process.env.REACT_APP_API_URL_V2_NEW;
  }
};
