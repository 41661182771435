import { Button, Col, Modal, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import IuxIcon from "../../../../components/svg/iux-icon";
import { post } from "../../../../helper/request";
import { LoadingPanel, Pointer } from "./Paytrust";
import TimesIcon from "../../../../components/svg/times";
import { useHistory } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { renderApiDomain } from "../../../../helper/renderApiDomain";
const locale = require("react-redux-i18n").I18n;

const Clipboard = styled.div`
  // cursor: pointer;
  border: 1px solid #8080802e;
  padding: 1px 4px;
  border-radius: 5px 0px 0px 5px;
  border-right: 0;
  height: 40px;
  display: flex;
  overflow: auto;
  -webkit-box-align: center;
  align-items: center;
`;

const BoxAccept = styled.div`
  background: #80808014;
  border-radius: 5px;
  margin-bottom: 16px;
  margin-top: 16px;
`;

const Copy = styled.div`
  cursor: pointer;
  border: 1px solid #8080802e;
  padding: 1px 4px;
  border-radius: 0px 5px 5px 0px;
  border-left: 0;
  height: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 70px;
  justify-content: center;
  color: #1cb894;
  font-weight: bold;
  font-family: sans-serif;
`;

const Text = styled.div`
  font-size: 12px;
  color: red;
  position: absolute;
  right: 20px;
  top: -28px;
`;
const BoxTop = styled.div`
  border: 1px solid #8080802e;
  border-radius: 5px 5px 0px 0px;
  border-bottom: 0px;
`;

const BoxUnder = styled.div`
  border: 1px solid #8080802e;
  border-radius: 0px 0px 5px 5px;
  border-top: 0px;
  background: #f5f5f5;
`;

function PayAlphapo(props) {
  const { amountUsd } = props;
  const history = useHistory();
  const { paymentId, currency } = props;
  const [data, setData] = useState({
    id: 0,
    currency: "",
    convert_to: "",
    address: "",
    tag: null,
  });
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [loadImage, setLoadImage] = useState(false);
  const [check, setCheck] = useState(false);
  const [checkBox, setCheckBox] = useState();
  const { confirm } = Modal;
  useEffect(() => {
    setLoading(true);
    const payload = {
      account_trade_id: props.account.id,
      amount_thb: props.amount,
      payment_id: paymentId,
      currency: currency,
    };
    post(renderApiDomain() + "deposit/alphapo", payload).then(({ data }) => {
      setData(data);
      setModal(true);
      setLoading(false);
    });
  }, []);

  const copyToClipboard = (el) => {
    setPopUp(true);
    navigator.clipboard.writeText(data.address);
    setTimeout(() => {
      setPopUp(false);
    }, 1000);
  };

  const font = {
    fontSize: 16,
  };

  function showConfirm() {
    confirm({
      title: locale.t("depositPage.dialog.title"),
      icon: <ExclamationCircleOutlined />,
      content: locale.t("depositPage.dialog.description"),
      okText: locale.t("depositPage.dialog.okButton"),
      cancelText: locale.t("depositPage.dialog.cancelButton"),
      onOk() {
        history.push(`/${localStorage.getItem("locale")}/wallet`);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  const handleChange = (i) => {
    if (i === 1) {
      setCheckBox(true);
    } else {
      setCheckBox(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <Modal visible={modal && data.id !== 0} footer={null}>
        <div className="d-flex justify-content-between">
          <div />
          <Pointer onClick={() => showConfirm()}>
            <IuxIcon icon={TimesIcon} color="#A0A0A0" />
          </Pointer>
        </div>
        {check ? (
          <>
            <div
              className="position-relative d-flex align-items-center mt-3"
              style={{
                backgroundImage:
                  "linear-gradient(to right top, #007ad0, #0087c9, #0090b3, #009494, #009674)",
                height: 70,
              }}
            >
              <div className="d-inline-block position-absolute">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/iux-logo.svg`}
                  alt="..."
                />
              </div>
              <div
                className="font-bold-iux text-center text-white w-100"
                style={{ fontSize: 30 }}
              >
                QR code
              </div>
            </div>
            <>
              {!loadImage && (
                <LoadingPanel>
                  <div className="m-auto">Loading</div>
                </LoadingPanel>
              )}
              <div
                className={
                  loadImage
                    ? "d-block w-100 text-center"
                    : "d-none w-100 text-center"
                }
              >
                <img
                  src={`https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=bitcoin:${data.address}&choe=UTF-8`}
                  alt=".."
                  onLoad={() => {
                    setLoadImage(true);
                  }}
                />
                <BoxTop className="ml-1 mr-1 pt-4 row">
                  <div className="col-lg-3 font-bold-iux d-flex align-items-center">
                    Address
                  </div>
                  <div className="col-lg-12 position-relative d-flex w-100 mt-2">
                    {popUp && <Text>Copy to clipboard !!</Text>}
                    <Clipboard className="w-100">{data.address}</Clipboard>
                    <Copy onClick={() => copyToClipboard()}>COPY</Copy>
                  </div>
                  <div className="col-4 font-bold-iux d-flex align-items-center mt-2">
                    Currency
                  </div>
                  <div
                    className="col-8 text-right font-bold-iux mt-2"
                    style={font}
                  >
                    {data.currency === "USDTE" ? "USDT" : data.currency}
                  </div>
                  <div className="col-4 font-bold-iux d-flex align-items-center mt-2">
                    Convert
                  </div>
                  <div
                    className="col-8 text-right font-bold-iux mt-2"
                    style={font}
                  >
                    {data.convert_to}
                  </div>
                  <div className="col-4 font-bold-iux d-flex align-items-center mt-2">
                    Converted amount
                  </div>
                  <div
                    className="col-8 text-right font-bold-iux mt-2"
                    style={font}
                  >
                    {`${amountUsd} USD`}
                  </div>
                  <div className="col-4 font-bold-iux d-flex align-items-center mt-2">
                    Network
                  </div>
                  <div
                    className="col-8 text-right font-bold-iux mt-2"
                    style={font}
                  >
                    ERC20
                  </div>
                  <div className="w-100 mt-2" />
                </BoxTop>
                <BoxUnder className="ml-1 mr-1 pt-2 pb-2 row">
                  <div className="col-4 font-bold-iux mt-2 d-flex align-items-center">
                    Amount
                  </div>
                  <div
                    className="col-8 text-right font-bold-iux mt-2"
                    style={{ color: "#1CB894", fontSize: 20 }}
                  >{`${props.amount} ${
                    data.currency === "USDTE" ? "USDT" : data.currency
                  }`}</div>
                </BoxUnder>
              </div>
            </>
          </>
        ) : (
          <div className="pb-2">
            <div style={{ fontSize: 20 }} className="font-bold-iux">
              {locale.t("depositPage.accept.header")}
            </div>
            <div>{locale.t("depositPage.accept.subHeader")}</div>
            <BoxAccept className="p-2">
              <div className="font-bold-iux pt-2 pb-2">
                {locale.t("depositPage.accept.title")}
              </div>
              <div className="d-flex align-items-baseline mb-2">
                <input
                  type="checkbox"
                  onChange={() => handleChange(1)}
                  checked={checkBox}
                />
                <div className="ml-2">
                  {locale.t("depositPage.accept.acc_ok")}
                </div>
              </div>
              <div className="d-flex align-items-baseline pb-2">
                <input
                  type="checkbox"
                  onChange={() => handleChange(0)}
                  checked={checkBox === false}
                />
                <div className="ml-2">
                  {locale.t("depositPage.accept.acc_cancel")}
                </div>
              </div>
            </BoxAccept>
            <div className="pb-3">{locale.t("depositPage.accept.pl")}</div>
            <div className="d-flex justify-content-between">
              <Button
                onClick={() => {
                  setModal(false);
                  props.onBackStep();
                }}
                className="btn-disabled-iux"
              >
                {locale.t("depositPage.dialog.cancelButton")}
              </Button>
              <Button
                onClick={() => setCheck(true)}
                disabled={checkBox === undefined || !checkBox}
                className="btn-outline-iux"
              >
                {locale.t("depositPage.accept.confirm")}
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </Spin>
  );
}

export default PayAlphapo;
