import React from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Links, Collapse, Form, Input, Select } from "antd";
import RcQueueAnim from "rc-queue-anim";

const locale = require("react-redux-i18n").I18n;
const { Panel } = Collapse;
const { Option } = Select;
function HelpFn() {
  const questions = [
    {
      question: locale.t("question.question_1"),
      answer: locale.t("question.answer_1"),
    },
    {
      question: locale.t("question.question_2"),
      answer: locale.t("question.answer_2"),
    },
    {
      question: locale.t("question.question_3"),
      answer: locale.t("question.answer_3"),
    },
    {
      question: locale.t("question.question_4"),
      answer: locale.t("question.answer_4"),
    },
    {
      question: locale.t("question.question_5"),
      answer: locale.t("question.answer_5"),
    },
    {
      question: locale.t("question.question_6"),
      answer: locale.t("question.answer_6"),
    },
    {
      question: locale.t("question.question_7"),
      answer: locale.t("question.answer_7"),
    },
    {
      question: locale.t("question.question_8"),
      answer: locale.t("question.answer_8"),
    },
    {
      question: locale.t("question.question_9"),
      answer: locale.t("question.answer_9"),
    },
    {
      question: locale.t("question.question_10"),
      answer: locale.t("question.answer_10"),
    },
    {
      question: locale.t("question.question_11"),
      answer: locale.t("question.answer_11"),
    },
    {
      question: locale.t("question.question_12"),
      answer: locale.t("question.answer_12"),
    },
    {
      question: locale.t("question.question_13"),
      answer: locale.t("question.answer_13"),
    },
  ];
  const documents = [
    {
      question: locale.t("document.question_1"),
      answer: locale.t("document.answer_1"),
    },
    {
      question: locale.t("document.question_2"),
      answer: locale.t("document.answer_2"),
    },
    {
      question: locale.t("document.question_3"),
      answer: locale.t("document.answer_3"),
    },
    {
      question: locale.t("document.question_4"),
      answer: locale.t("document.answer_4"),
    },
  ];
  const iux_types = [
    {
      question: locale.t("iux_type.question_1"),
      answer: locale.t("iux_type.answer_1"),
    },
    {
      question: locale.t("iux_type.question_2"),
      answer: locale.t("iux_type.answer_2"),
    },
    {
      question: locale.t("iux_type.question_3"),
      answer: locale.t("iux_type.answer_3"),
    },
    {
      question: locale.t("iux_type.question_4"),
      answer: locale.t("iux_type.answer_4"),
    },
    // {
    //   question: locale.t("iux_type.question_5"),
    //   answer: locale.t("iux_type.answer_5"),
    // },
    {
      question: locale.t("iux_type.question_6"),
      answer: locale.t("iux_type.answer_6"),
    },
    {
      question: locale.t("iux_type.question_7"),
      answer: locale.t("iux_type.answer_7"),
    },
    {
      question: locale.t("iux_type.question_8"),
      answer: locale.t("iux_type.answer_8"),
    },
    {
      question: locale.t("iux_type.question_9"),
      answer: locale.t("iux_type.answer_9"),
    },
    {
      question: locale.t("iux_type.question_10"),
      answer: locale.t("iux_type.answer_10"),
    },
    {
      question: locale.t("iux_type.question_11"),
      answer: locale.t("iux_type.answer_11"),
    },
    {
      question: locale.t("iux_type.question_12"),
      answer: locale.t("iux_type.answer_12"),
    },
    {
      question: locale.t("iux_type.question_13"),
      answer: locale.t("iux_type.answer_13"),
    },
    {
      question: locale.t("iux_type.question_14"),
      answer: locale.t("iux_type.answer_14"),
    },
    {
      question: locale.t("iux_type.question_15"),
      answer: locale.t("iux_type.answer_15"),
    },
    {
      question: locale.t("iux_type.question_16"),
      answer: locale.t("iux_type.answer_16"),
    },
    {
      question: locale.t("iux_type.question_17"),
      answer: locale.t("iux_type.answer_17"),
    },
    {
      question: locale.t("iux_type.question_18"),
      answer: locale.t("iux_type.answer_18"),
    },
    {
      question: locale.t("iux_type.question_19"),
      answer: locale.t("iux_type.answer_19"),
    },
    // {
    //   question: locale.t("iux_type.question_20"),
    //   answer: locale.t("iux_type.answer_20"),
    // },
    // {
    //   question: locale.t("iux_type.question_21"),
    //   answer: locale.t("iux_type.answer_21"),
    // },
    {
      question: locale.t("iux_type.question_22"),
      answer: locale.t("iux_type.answer_22"),
    },
    {
      question: locale.t("iux_type.question_23"),
      answer: locale.t("iux_type.answer_23"),
    },
    // {
    //   question: locale.t('iux_type.question_24'),
    //   answer: locale.t('iux_type.answer_24'),
    // },
    // {
    //   question: locale.t("iux_type.question_25"),
    //   answer: locale.t("iux_type.answer_25"),
    // },
    {
      question: locale.t("iux_type.question_26"),
      answer: locale.t("iux_type.answer_26"),
    },
    {
      question: locale.t("iux_type.question_27"),
      answer: locale.t("iux_type.answer_27"),
    },
    {
      question: locale.t("iux_type.question_28"),
      answer: locale.t("iux_type.answer_28"),
    },
    {
      question: locale.t("iux_type.question_29"),
      answer: locale.t("iux_type.answer_29"),
    },
    {
      question: locale.t("iux_type.question_30"),
      answer: locale.t("iux_type.answer_30"),
    },
    {
      question: locale.t("iux_type.question_31"),
      answer: locale.t("iux_type.answer_31"),
    },
    {
      question: locale.t("iux_type.question_32"),
      answer: locale.t("iux_type.answer_32"),
    },
    {
      question: locale.t("iux_type.question_33"),
      answer: locale.t("iux_type.answer_33"),
    },
    {
      question: locale.t("iux_type.question_34"),
      answer: locale.t("iux_type.answer_34"),
    },
    {
      question: locale.t("iux_type.question_35"),
      answer: locale.t("iux_type.answer_35"),
    },
  ];
  const deposit_withdraw = [
    {
      question: locale.t("deposit_withdraw.question_1"),
      answer: locale.t("deposit_withdraw.answer_1"),
    },
    {
      question: locale.t("deposit_withdraw.question_2"),
      answer: locale.t("deposit_withdraw.answer_2"),
    },
    {
      question: locale.t("deposit_withdraw.question_3"),
      answer: locale.t("deposit_withdraw.answer_3"),
    },
    {
      question: locale.t("deposit_withdraw.question_4"),
      answer: locale.t("deposit_withdraw.answer_4"),
    },
    {
      question: locale.t("deposit_withdraw.question_5"),
      answer: locale.t("deposit_withdraw.answer_5"),
    },
    // {
    //   question: locale.t("deposit_withdraw.question_6"),
    //   answer: locale.t("deposit_withdraw.answer_6"),
    // },
    {
      question: locale.t("deposit_withdraw.question_7"),
      answer: locale.t("deposit_withdraw.answer_7"),
    },
    {
      question: locale.t("deposit_withdraw.question_8"),
      answer: locale.t("deposit_withdraw.answer_8"),
    },
    {
      question: locale.t("deposit_withdraw.question_9"),
      answer: locale.t("deposit_withdraw.answer_9"),
    },
    {
      question: locale.t("deposit_withdraw.question_10"),
      answer: locale.t("deposit_withdraw.answer_10"),
    },
    {
      question: locale.t("deposit_withdraw.question_11"),
      answer: locale.t("deposit_withdraw.answer_11"),
    },
    {
      question: locale.t("deposit_withdraw.question_12"),
      answer: locale.t("deposit_withdraw.answer_12"),
    },
    {
      question: locale.t("deposit_withdraw.question_13"),
      answer: locale.t("deposit_withdraw.answer_13"),
    },
    // {
    //   question: locale.t("deposit_withdraw.question_14"),
    //   answer: locale.t("deposit_withdraw.answer_14"),
    // },
  ];

  return (
    <RcQueueAnim className="container mt-4">
      <div key={1}>
        <div className="p-2">
          <h2
            className="text-white text-center"
            style={{
              fontWeight: "700",
            }}
          >
            {locale.t("question.title")}
          </h2>
          <p className="text-white text-center mt-3 pb-5">
            {locale.t("help_page_sub_title")}
          </p>
          <div className="mt-3">
            {questions.map((item, index) => {
              return (
                <Collapse
                  key={index}
                  bordered={false}
                  style={{ background: "#3E3E3E" }}
                  className="site-collapse-custom-collapse"
                >
                  <Panel
                    header={item.question}
                    key={index}
                    style={{ color: "#FFF" }}
                    className="site-collapse-custom-panel"
                  >
                    <p className="text-white" style={{ paddingLeft: 24 }}>
                      <strong>{item.answer}</strong>
                    </p>
                  </Panel>
                </Collapse>
              );
            })}
          </div>
        </div>
        <div className="p-2">
          <h2
            className="text-white text-center pb-5 pt-5"
            style={{
              fontWeight: "700",
            }}
          >
            {locale.t("document.title")}
          </h2>
          <div>
            {documents.map((item, index) => {
              return (
                <Collapse
                  key={index}
                  bordered={false}
                  style={{ background: "#3E3E3E" }}
                >
                  <Panel
                    header={item.question}
                    key={index}
                    className="site-collapse-custom-panel"
                  >
                    <p className="text-white" style={{ paddingLeft: 24 }}>
                      <strong>{item.answer}</strong>
                    </p>
                  </Panel>
                </Collapse>
              );
            })}
          </div>
        </div>
        <div className="p-2">
          <h2
            className="text-white text-center pb-5 pt-5"
            style={{
              fontWeight: "700",
            }}
          >
            {locale.t("iux_type.title")}
          </h2>
          <div>
            {iux_types.map((item, index) => {
              return (
                <Collapse
                  key={index}
                  bordered={false}
                  style={{ background: "#3E3E3E" }}
                >
                  <Panel
                    header={item.question}
                    key={index}
                    style={{ background: "#3E3E3E" }}
                  >
                    <p
                      className="text-white"
                      style={{ paddingLeft: 24, whiteSpace: "pre-wrap" }}
                    >
                      <strong>{item.answer}</strong>
                    </p>
                  </Panel>
                </Collapse>
              );
            })}
          </div>
        </div>
        <div className="p-2">
          <h2
            className="text-white text-center pb-5 pt-5"
            style={{
              fontWeight: "700",
            }}
          >
            {locale.t("deposit_withdraw.title")}
          </h2>
          <div>
            {deposit_withdraw.map((item, index) => {
              return (
                <Collapse
                  key={index}
                  bordered={false}
                  style={{ background: "#3E3E3E" }}
                >
                  <Panel header={item.question} key={index}>
                    <p className="text-white" style={{ paddingLeft: 24 }}>
                      <strong>{item.answer}</strong>
                    </p>
                  </Panel>
                </Collapse>
              );
            })}
          </div>
        </div>
      </div>
    </RcQueueAnim>
  );
}

const mapStateToProps = ({ i18n }) => ({
  locale: i18n.locale,
});

export default connect(mapStateToProps, null)(HelpFn);
