import { renderApiDomain } from "../helper/renderApiDomain";
import { get } from "../helper/request";

export const getCheckRegisterWebinar = async () => {
  return get(`${renderApiDomain()}webinar/user/status`);
};
export const getIframeWebinar = async () => {
  return get(`${renderApiDomain()}webinar/stream`);
};
export const getLiveWebinar = async () => {
  return get(`${renderApiDomain()}webinar/stream/status`);
};
