import React, { useEffect, useState } from "react";
import { Row, Col, Button, Input, Select, Divider, Form } from "antd";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { post } from "../../helper/request";
import Recommendation from "./components/Recommendation";
import { renderApiDomain } from "../../helper/renderApiDomain";
const { Option } = Select;
const locale = require("react-redux-i18n").I18n;

const BoxContentCal = styled.div`
  padding: 12px;
  border-radius: 20px;
  border: 1px solid #8080803d;
  background: #242424;
`;
const BoxErrors = styled.div`
  color: red;
`;

const BoxBuy = styled.div`
  background: ${(props) => props.BackgroundColor};
  padding: 5px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  width: 100%;
  cursor: pointer;
  color: ${(props) => props.Color};
  text-align: center;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
`;

const BoxSell = styled.div`
  background: ${(props) => props.BackgroundColor};
  padding: 5px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  width: 100%;
  cursor: pointer;
  color: ${(props) => props.Color};
  text-align: center;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
`;

const styleInput = {
  height: 40,
  borderRadius: 4,
};

const ButtonSum = styled(Button)`
  padding: 0px 34px;
  background-color: rgb(45 181 74) !important;
  color: rgb(255, 255, 255) !important;
  font-size: 14px;
  height: 40px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(45 181 74);
  text-transform: initial;
`;

const styleDisabled = {
  backgroundColor: "transparent",
  color: "#fff",
  height: 40,
  borderRadius: 4,
};

export default function Tpsl(props) {
  const symbol = props.symbols;
  const metaTrader = props.mtType;
  const { handleChangeSymbol } = props;
  const [form] = Form.useForm();
  const [lot, setLot] = useState();
  const [baseCurrency, setBaseCurrency] = useState();
  const [fluctuation, setFluctuation] = useState();
  const [contractSize, setContractSize] = useState();
  const [converse, setConverse] = useState();
  const [buy, setBuy] = useState(true);
  const [sell, setSell] = useState(false);
  const [OpenPrice, setOpenPrice] = useState();
  const [StopLoss, setStopLoss] = useState();
  const [TakeProfit, setTakeProfit] = useState();
  const [Pip, setPip] = useState();
  const [StopLossLevel, setStopLossLevel] = useState();
  const [TakeProfitLevel, setTakeProfitLevel] = useState();
  const [StopLossAt, setStopLossAt] = useState();
  const [TakeProfitAt, setTakeProfitAt] = useState();
  const [digit, setDigit] = useState();

  // f2
  const [SumStopLoss, setSumStopLoss] = useState();
  const [SumTakeProfit, setSumTakeProfit] = useState();
  const [lot2, setLot2] = useState();
  const [baseCurrency2, setBaseCurrency2] = useState();
  const [fluctuation2, setFluctuation2] = useState();
  const [contractSize2, setContractSize2] = useState();
  const [converse2, setConverse2] = useState();
  const [StopLoss2, setStopLoss2] = useState();
  const [TakeProfit2, setTakeProfit2] = useState();

  const [errors, setError] = useState([]);
  const [errors2, setError2] = useState([]);

  function onChangeBaseCurrency(value) {
    setBaseCurrency(value);
  }
  function handleChangeLot(value) {
    setLot(value);
  }
  function onChangeCurrency(value) {
    if (metaTrader.mt4) {
      post(renderApiDomain() + `calc/rate`, {
        account: baseCurrency,
        pair: value,
      }).then((response) => {
        setDigit(response.data.symbol.digits);
        setConverse(response.data.converse);
        setContractSize(response.data.symbol.contract_size);
        setFluctuation(
          Math.pow(10, -response.data.symbol.digits).toFixed(
            response.data.symbol.digits
          )
        );
      });
    } else {
      post(renderApiDomain() + `calc/mt5/rate`, {
        account: baseCurrency,
        pair: value,
      }).then((response) => {
        setDigit(response.data.symbol.digits);
        setConverse(response.data.converse);
        setContractSize(response.data.symbol.contractSize);
        setFluctuation(
          Math.pow(10, -response.data.symbol.digits).toFixed(
            response.data.symbol.digits
          )
        );
      });
    }
  }

  function onChangeOpenprice(value) {
    setOpenPrice(value);
  }
  function onChangeStopLoss(value) {
    setStopLoss(value);
  }
  function onChangeTakeProfit(value) {
    setTakeProfit(value);
  }

  const onSubmit = () => {
    if (OpenPrice === undefined) {
      setError(locale.t("dashboardWallet.input_error"));
    } else {
      setError([]);

      if (buy) {
        let pip_account = (fluctuation / converse) * lot * contractSize;
        let stop_loss_level = parseFloat(StopLoss) / pip_account;
        let take_profit_level = parseFloat(TakeProfit) / pip_account;
        let stop_loss_at =
          parseFloat(OpenPrice) - stop_loss_level * fluctuation;
        let take_profit_at =
          parseFloat(OpenPrice) + take_profit_level * fluctuation;
        if (stop_loss_level !== null) {
          setStopLossLevel(stop_loss_level);
          setTakeProfitLevel(take_profit_level);
          setStopLossAt(stop_loss_at);
          setTakeProfitAt(take_profit_at);
          setPip(pip_account);
        }
      } else {
        let pip_account = (fluctuation / converse) * lot * contractSize;
        let stop_loss_level = parseFloat(StopLoss) / pip_account;
        let take_profit_level = parseFloat(TakeProfit) / pip_account;
        let stop_loss_at =
          parseFloat(OpenPrice) + stop_loss_level * fluctuation;
        let take_profit_at =
          parseFloat(OpenPrice) - take_profit_level * fluctuation;
        if (stop_loss_level !== null) {
          setStopLossLevel(stop_loss_level);
          setTakeProfitLevel(take_profit_level);
          setStopLossAt(stop_loss_at);
          setTakeProfitAt(take_profit_at);
          setPip(pip_account);
        }
      }
    }
  };

  const onChangeBuy = () => {
    setBuy(true);
    setSell(false);
  };

  const onChangeSell = () => {
    setBuy(false);
    setSell(true);
  };

  // f2 =================================================================

  function onChangeBaseCurrency2(value) {
    setBaseCurrency2(value);
  }
  function handleChangeLot2(value) {
    setLot2(value);
  }
  function onChangeCurrency2(value) {
    if (metaTrader.mt4) {
      post(renderApiDomain() + `calc/rate`, {
        account: baseCurrency2,
        pair: value,
      }).then((response) => {
        setConverse2(response.data.converse);
        setContractSize2(response.data.symbol.contract_size);
        setFluctuation2(
          Math.pow(10, -response.data.symbol.digits).toFixed(
            response.data.symbol.digits
          )
        );
      });
    } else {
      post(renderApiDomain() + `calc/mt5/rate`, {
        account: baseCurrency2,
        pair: value,
      }).then((response) => {
        setConverse2(response.data.converse);
        setContractSize2(response.data.symbol.contractSize);
        setFluctuation2(
          Math.pow(10, -response.data.symbol.digits).toFixed(
            response.data.symbol.digits
          )
        );
      });
    }
  }

  function onChangeStopLoss2(value) {
    setStopLoss2(value);
  }
  function onChangeTakeProfit2(value) {
    setTakeProfit2(value);
  }

  const onSubmit_2 = () => {
    if (baseCurrency2 === undefined) {
      setError2(locale.t("dashboardWallet.input_error"));
    } else {
      let pip_account = (fluctuation2 / converse2) * (lot2 * contractSize2);
      let stop_loss = StopLoss2 * pip_account;
      let take_profit = TakeProfit2 * pip_account;
      if (stop_loss !== null) {
        setSumStopLoss(stop_loss);
        setSumTakeProfit(take_profit);
      }
    }
  };

  const handleReset = () => {
    setSumStopLoss(0);
    setSumTakeProfit(0);
    setStopLossLevel(0);
    setTakeProfitLevel(0);
    setStopLossAt(0);
    setTakeProfitAt(0);
    setPip(0);
  };

  return (
    <>
      <Form form={form} className="col-md-8 mb-4">
        <h4 className="mb-4 text-white">{locale.t("fxcal_page_6_title")}</h4>
        <BoxContentCal>
          <Col span={12}>
            <Form.Item name="1">
              <div className="mt-3 text-gray">
                {locale.t("fxcal_list_1_box.Platform")}
              </div>
              <Select
                size="large"
                className="w-100"
                onChange={(value) => {
                  handleChangeSymbol(value);
                  form.resetFields();
                  handleReset();
                }}
                defaultValue="Metatrader5"
              >
                <Option value={"mt5"}>Metatrader5</Option>
              </Select>
            </Form.Item>
          </Col>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name="1">
                <div className="text-gray">
                  {locale.t("fxcal_list_6_box.title_1")}
                </div>
                <Select
                  onChange={(value) => onChangeBaseCurrency(value)}
                  defaultValue={locale.t("calculator_select")}
                  size="large"
                  className="w-100"
                >
                  <Option value={"USD"}>USD</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="1">
                <div className="text-gray">
                  {locale.t("fxcal_list_6_box.title_2")}
                </div>
                <Input
                  style={styleInput}
                  placeholder={"1.5000"}
                  onChange={(e) => onChangeOpenprice(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="1">
                <div className="text-gray">
                  {locale.t("fxcal_list_6_box.title_3")}
                </div>
                <Select
                  onChange={(value) => onChangeCurrency(value)}
                  defaultValue={locale.t("calculator_select")}
                  size="large"
                  className="w-100"
                >
                  {symbol !== null &&
                    symbol?.map((e, i) => {
                      return (
                        <Option key={i} value={e.name}>
                          {e.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="1">
                <div className="text-gray">
                  {locale.t("fxcal_list_6_box.title_4")}
                </div>
                <Input
                  style={styleInput}
                  placeholder={"1.5000"}
                  onChange={(e) => onChangeStopLoss(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="1">
                <div className="text-gray">
                  {locale.t("fxcal_list_6_box.title_5")}
                </div>
                <Select
                  defaultValue={locale.t("calculator_select")}
                  size="large"
                  className="w-100"
                >
                  <Option value={"Standard"}>Standard</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="1">
                <div className="text-gray">
                  {locale.t("fxcal_list_6_box.title_6")}
                </div>
                <Input
                  style={styleInput}
                  placeholder={"1.5000"}
                  onChange={(e) => onChangeTakeProfit(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="1">
                <div className="text-gray">
                  {locale.t("fxcal_list_6_box.title_7")}
                </div>
                <Input
                  style={styleInput}
                  placeholder={"Please enter value"}
                  onChange={(e) => handleChangeLot(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <div className="text-gray">
                {locale.t("fxcal_list_6_box.title_8")}
              </div>
              <div className="d-flex justify-content-between">
                <BoxBuy
                  onClick={() => onChangeBuy()}
                  BackgroundColor={buy ? "#1cb894" : "#ebebeb"}
                  Color={buy ? "#fff" : ""}
                >
                  BUY / LONG
                </BoxBuy>
                <BoxSell
                  onClick={() => onChangeSell()}
                  BackgroundColor={sell ? "#1cb894" : "#ebebeb"}
                  Color={sell ? "#fff" : ""}
                >
                  SELL / SHORT
                </BoxSell>
              </div>
            </Col>
          </Row>
          <div style={{ margin: "1rem 0" }}>
            <BoxErrors>{errors}</BoxErrors>
            <button
              onClick={() => onSubmit()}
              className="w-sm-100 btn-primary-new-iux"
            >
              {locale.t("btn_calculator")}
            </button>
          </div>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="text-gray">
                {locale.t("fxcal_list_6_box.title_9")}
              </div>
              <Input
                value={StopLossAt ? StopLossAt.toFixed(digit || 5) : ""}
                style={styleDisabled}
                disabled
              />
            </Col>
            <Col span={12}>
              <div className="text-gray">
                {locale.t("fxcal_list_6_box.title_10")}
              </div>
              <Input
                value={StopLossLevel ? StopLossLevel.toFixed(5) : ""}
                style={styleDisabled}
                disabled
              />
            </Col>
            <Col span={12}>
              <div className="text-gray">
                {locale.t("fxcal_list_6_box.title_11")}
              </div>
              <Input
                value={TakeProfitAt ? TakeProfitAt.toFixed(digit || 5) : ""}
                style={styleDisabled}
                disabled
              />
            </Col>
            <Col span={12}>
              <div className="text-gray">
                {locale.t("fxcal_list_6_box.title_12")}
              </div>
              <Input
                value={TakeProfitLevel ? TakeProfitLevel.toFixed(5) : ""}
                style={styleDisabled}
                disabled
              />
            </Col>
            <Col span={12}></Col>
            <Col span={12}>
              <div className="text-gray">
                {locale.t("fxcal_list_6_box.title_13")}
              </div>
              <Input
                value={Pip ? Pip.toFixed(5) : ""}
                style={styleDisabled}
                disabled
              />
            </Col>
          </Row>
        </BoxContentCal>
      </Form>
      <Recommendation />
      {/* ++++++++++++++++++ */}
      <Divider />
      {/* ++++++++++++++++++ */}
      <Form form={form} className="col-md-8 mb-4">
        <h4 className="mb-4 text-white">{locale.t("fxcal_page_7_title")}</h4>
        <BoxContentCal>
          <Col span={12}>
            <Form.Item name="1">
              <div className="mt-3 text-gray">
                {locale.t("fxcal_list_1_box.Platform")}
              </div>
              <Select
                size="large"
                className="w-100"
                onChange={(value) => {
                  handleChangeSymbol(value);
                  form.resetFields();
                  handleReset();
                }}
                defaultValue="Metatrader5"
              >
                <Option value={"mt5"}>Metatrader5</Option>
              </Select>
            </Form.Item>
          </Col>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name="1">
                <div className="text-gray">
                  {locale.t("fxcal_list_7_box.title_1")}
                </div>
                <Select
                  onChange={(value) => onChangeBaseCurrency2(value)}
                  defaultValue={locale.t("calculator_select")}
                  size="large"
                  className="w-100"
                >
                  <Option value={"USD"}>USD</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="1">
                <div className="text-gray">
                  {locale.t("fxcal_list_7_box.title_2")}
                </div>
                <Input
                  style={styleInput}
                  placeholder={"Please enter value"}
                  onChange={(e) => handleChangeLot2(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="1">
                <div className="text-gray">
                  {locale.t("fxcal_list_7_box.title_3")}
                </div>
                <Select
                  onChange={(value) => onChangeCurrency2(value)}
                  defaultValue={locale.t("calculator_select")}
                  size="large"
                  className="w-100"
                >
                  {symbol !== null &&
                    symbol?.map((e, i) => {
                      return (
                        <Option key={i} value={e.name}>
                          {e.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="1">
                <div className="text-gray">
                  {locale.t("fxcal_list_7_box.title_4")}
                </div>
                <Input
                  style={styleInput}
                  placeholder={"200"}
                  onChange={(e) => onChangeStopLoss2(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="1">
                <div className="text-gray">
                  {locale.t("fxcal_list_7_box.title_5")}
                </div>
                <Select
                  defaultValue={locale.t("calculator_select")}
                  size="large"
                  className="w-100"
                >
                  <Option value={"Standard"}>Standard</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="1">
                <div className="text-gray">
                  {locale.t("fxcal_list_7_box.title_6")}
                </div>
                <Input
                  style={styleInput}
                  placeholder={"200"}
                  onChange={(e) => onChangeTakeProfit2(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <div style={{ margin: "1rem 0" }}>
            <BoxErrors>{errors2}</BoxErrors>
            <button
              onClick={() => onSubmit_2()}
              className="w-sm-100 btn-primary-new-iux"
            >
              {locale.t("btn_calculator")}
            </button>
          </div>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="text-gray">
                {locale.t("fxcal_list_7_box.title_7")}
              </div>
              <Input
                value={SumStopLoss ? SumStopLoss.toFixed(5) : ""}
                style={styleDisabled}
                disabled
              />
            </Col>
            <Col span={12}>
              <div className="text-gray">
                {locale.t("fxcal_list_7_box.title_8")}
              </div>
              <Input
                value={SumTakeProfit ? SumTakeProfit.toFixed(5) : ""}
                style={styleDisabled}
                disabled
              />
            </Col>
          </Row>
        </BoxContentCal>
      </Form>
      <Recommendation />
    </>
  );
}
