import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link, useHistory } from "react-router-dom";
import { LogoutAuth, getBank, getUser } from "../actions";
import { Base64Decoder } from "../helper/Base64Encoder";
import LoadingIux from "../components/loading";

function RedirectReferral() {
  const { account, token } = useParams();
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  let urlParams = new URLSearchParams(window.location.search);
  let code = urlParams.get("code");
  useEffect(() => {
    if (code) {
      sessionStorage.setItem("code", code);

      window.location.replace(
        `${process.env.REACT_APP_DOMAIN_IUX}/${localStorage.getItem(
          "locale"
        )}/register?code=${code}`
      );
      // history.replace(
      //   `/${localStorage.getItem("locale")}/register?code=${code}`
      // );
    } else {
      window.location.replace(
        `${process.env.REACT_APP_DOMAIN_IUX}/${localStorage.getItem("locale")}`
      );
      // history.replace(`/${localStorage.getItem("locale")}`);
    }
  }, [code]);

  return (
    <div>
      <LoadingIux />
    </div>
  );
}

export default RedirectReferral;
