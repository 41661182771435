import React from "react";
import { Button, Row, Col } from "antd";
import { Link } from "react-router-dom";
const locale = require("react-redux-i18n").I18n;

const TextWithCoverButton = (props) => {
  return (
    <div>
      <div
        style={{
          paddingTop: "4%",
          paddingBottom: "4%",
          backgroundImage:
            "radial-gradient(circle, rgb(29, 57, 74), rgb(26, 50, 62), rgb(25, 43, 51), rgb(23, 36, 41), rgb(22, 29, 31))",
        }}
      >
        <Row className="d-flex container">
          <Col className="text-in-cover col-md" style={{ alignSelf: "center" }}>
            <div style={{ fontSize: "24px", color: "white" }}>
              {locale.t("become_title")}
              <h3 className="mt-2 text-white">{locale.t("become_our")}</h3>
            </div>
            <div className="row mt-2">
              <div className="col-md text-righttresponsive">
                <Link to={`/${localStorage.getItem("locale")}/register`}>
                  <Button
                    className="col-md-5 btn-primary-nex-iux"
                    style={{ textTransform: "uppercase" }}
                  >
                    {locale.t("open_account_now")}
                  </Button>
                </Link>
              </div>
              <div className="col-md text-leftresponsive mt-2 mt-md-0 mt-lg-0 mt-xl-0">
                <Link to={`/${localStorage.getItem("locale")}/register`}>
                  <Button className="col-md-5 btn-outline-iux">
                    {locale.t("become_demo")}
                  </Button>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TextWithCoverButton;
