import React from "react";
const TITLE = "Account Overview | IUX Markets";
const locale = require("react-redux-i18n").I18n;

const paddinigSection = {
  paddingTop: "5rem",
  paddingBottom: "5rem",
};

function AccountWithdraw(props) {
  const scOne = [
    {
      content: locale.t("withdraw.section_2.title_1"),
    },
    {
      content: locale.t("withdraw.section_2.title_2"),
    },
    {
      content: locale.t("withdraw.section_2.title_3"),
    },
    {
      content: locale.t("withdraw.section_2.title_4"),
    },
    {
      content: locale.t("withdraw.section_2.title_5"),
    },
    {
      content: locale.t("withdraw.section_2.title_6"),
    },
    {
      content: locale.t("withdraw.section_2.title_7"),
    },
    {
      content: locale.t("withdraw.section_2.title_8"),
    },
    {
      content: locale.t("withdraw.section_2.title_9"),
    },
    {
      content: locale.t("withdraw.section_2.title_10"),
    },
    {
      content: locale.t("withdraw.section_2.title_11"),
    },
    // {
    //   content: locale.t("withdraw.section_2.title_12"),
    // },
  ];
  return (
    <div>
      <section key={1}>
        <div className=" bg-section-dark py-5">
          <div className="container  container-text-center">
            <h2 className="text-white text-center fs-20">
              {locale.t("withdraw.section_1.title")}
            </h2>
            <p className="text-center text-white mt-4">
              {locale.t("withdraw.section_1.content")}
            </p>
          </div>
        </div>
      </section>
      <section key={2}>
        <div className="container  container-text-center">
          <div style={paddinigSection}>
            <h2 className="text-white text-center text-uppercase">
              {locale.t("withdraw.section_2.title")}
            </h2>
          </div>
          <div>
            {scOne.map((e, i) => (
              <div className="d-flex mt-3 align-items-center">
                <h6 className="text-primary-new-iux font-italic font-weight-bold text-left font-number-withdraw">
                  {i + 1}
                </h6>
                <div className="ml-4 text-white fs-16 text-left">
                  {e.content}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default AccountWithdraw;
