import React from 'react';

const Title = (props) =>{
    return (
        <div className="d-flex p-2 justify-content-center">
            <h1 style={props.style} className="text-center align-self-center m-0 f-res16 font-bold-iux">
                {props.title}
            </h1>
        </div>
    )
}

export default Title;