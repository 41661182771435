import React, { Component } from "react";
import SpaceCard from "../../../components/space-card/SpaceCard";
import RcQueueAnim from "rc-queue-anim";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

const TITLE = "Metatrader | IUX Markets";
const locale = require("react-redux-i18n").I18n;

class IosPage extends Component {
  constructor(props) {
    super(props);

    const listGiving = [
      {
        icon: process.env.PUBLIC_URL + "/assets/images/icons/iux-average.svg",
        title: locale.t("platform_iphone_section_2.label_1"),
        detail:
          "บัญชีสามารถปรับเลเวอเรจได้สูงสุด 1:1000 บนแพลตฟอร์มiUXmarket MetaTrader 4 เทรดเดอร์สามารถใช้เลเวอเรจที่สูงกว่าเพื่อให้ตรงกับสไตล์การเทรดของตัวเอง และใช้ประโยชน์จากกลยุทธ์การเทรดให้ได้มากที่สุด",
      },
      {
        icon: process.env.PUBLIC_URL + "/assets/images/icons/iux-currency.svg",
        title: locale.t("platform_iphone_section_2.label_2"),
        detail:
          "เราทราบดีว่า เทรดเดอร์บางท่านต้องการใช้สกุลเงินในท้องถิ่น เราจึงให้เทรดเดอร์ได้เลือกเปิดบัญชีได้จาก 1 ใน 10 สกุลเงินหลัก: USD, AUD, EUR, GBP, SGD,NZD, JPY, CHF, HKD, CAD",
      },
    ];

    const iphoneInfo = {
      title: locale.t("platform_iphone_header.title"),
      listInfo: [
        {
          text: locale.t("social_trade_eiloy_social_side_box.text_1"),
        },
        {
          text: locale.t("social_trade_eiloy_social_side_box.text_2"),
        },
        {
          text: locale.t("social_trade_eiloy_social_side_box.text_3"),
        },
        {
          text: locale.t("social_trade_eiloy_social_side_box.text_4"),
        },
        {
          text: locale.t("social_trade_eiloy_social_side_box.text_5"),
        },
        {
          text: locale.t("social_trade_eiloy_social_side_box.text_6"),
        },
      ],
    };

    const space =
      "Phone iOS 7.0 or later is compatible with iPhone, iPad and iPod touch.";

    this.state = {
      current: props.current,
      listGiving: listGiving,
      iphoneInfo: iphoneInfo,
      space: space,
    };
  }

  render() {
    return (
      <RcQueueAnim delay={300}>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <div className="bg-white">
          <section id="info" className="background-section-platform">
            <div className="container">
              <div className="row justify-content-center w-100">
                <div className="col-md-6">
                  <div className="margin-description-platform text-left">
                    {locale.t("platform_iphone_description")}
                  </div>
                </div>
                <div className="col-md-6">
                  <img
                    className="img-platform-all-responsive"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/platforms-iphone.png"
                    }
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="bg-white pt-5 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <h3 className="text-primary-iux">
                    {locale.t("why_platform.iphone.title1")}
                    {locale.t("why_platform.iphone.title2")}
                  </h3>
                  <h2 className="font-responsive-subtitle">
                    {locale.t("why_platform.iphone.subTitle")}
                  </h2>
                  <p>{locale.t("why_platform.iphone.description")}</p>
                </div>
                <div className="col-md-4 mt-4 mb-4">
                  <SpaceCard space={this.state.space}/>
                  <div className="mt-4">
                    <a
                      href="https://apps.apple.com/th/app/metatrader-4-forex-trading/id496212596"
                      rel="noopener noreferrer"
                      download
                    >
                      <button className="w-100 windows-button">
                      <span>
                        {locale.t("platform_windows_header.button_text")}
                      </span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </RcQueueAnim>
    );
  }
}

const mapStateToProps = ({ i18n }) => ({
  locale: i18n.locale,
});

export default connect(mapStateToProps, null)(IosPage);
