export default {
  th: {
    seminar_malaysia: {
      title: "เข้าร่วมการสัมมนาผ่านเว็บ IUX",
      description:
        "อย่าพลาดโอกาสนี้! เสริมทักษะการซื้อขายของคุณด้วยข้อมูลเชิงลึกจากผู้เชี่ยวชาญ",
      button: "ลงทะเบียนเข้าร่วม",
    },
  },
  lo: {
    seminar_malaysia: {
      title: "ເຂົ້າຮ່ວມ IUX Webinar",
      description:
        "ຢ່າພາດໂອກາດນີ້! ເສີມຂະຫຍາຍທັກສະການຊື້ຂາຍຂອງທ່ານດ້ວຍຄວາມເຂົ້າໃຈຂອງຜູ້ຊ່ຽວຊານ",
      button: "ລົງ​ທະ​ບຽນ​ດຽວ​ນີ້",
    },
  },
  en: {
    seminar_malaysia: {
      title: "Join IUX Webinar",
      description:
        "Don’t miss out this way! Empower your trading skills with insights from the experts.",
      button: "Register Now",
    },
  },
  hi: {
    seminar_malaysia: {
      title: "IUX Markets सेमिनार मलेशिया 2024",
      description:
        "चूको मत! विशेष 100% जमा बोनस और $500 के लकी ड्रा के अवसर के लिए अभी पंजीकरण करें!",
      button: "पंजीकरण करवाना",
    },
  },
  ms: {
    seminar_malaysia: {
      title: "Sertai Webinar IUX",
      description:
        "Jangan ketinggalan cara ini! Perkasakan kemahiran dagangan anda dengan cerapan daripada pakar.",
      button: "Daftar sekarang",
    },
  },
  id: {
    seminar_malaysia: {
      title: "Bergabunglah dengan Webinar IUX",
      description:
        "Jangan lewatkan cara ini! Berdayakan keterampilan trading Anda dengan wawasan dari para ahli.",
      button: "Daftar sekarang",
    },
  },
  zh: {
    seminar_malaysia: {
      title: "Join IUX Webinar",
      description:
        "Don’t miss out this way! Empower your trading skills with insights from the experts.",
      button: "Register Now",
    },
  },
  vi: {
    seminar_malaysia: {
      title: "Tham gia hội thảo trực tuyến IUX",
      description:
        "Đừng bỏ lỡ cơ hội này! Trao quyền cho kỹ năng giao dịch của bạn với những hiểu biết sâu sắc từ các chuyên gia.",
      button: "Đăng ký ngay",
    },
  },
  ko: {
    seminar_malaysia: {
      title: "Join IUX Webinar",
      description:
        "Don’t miss out this way! Empower your trading skills with insights from the experts.",
      button: "Register Now",
    },
  },
  fil: {
    seminar_malaysia: {
      title: "Sumali sa IUX Webinar",
      description:
        "Huwag palampasin ang ganitong paraan! Palakasin ang iyong mga kasanayan sa pangangalakal gamit ang mga insight mula sa mga eksperto.",
      button: "Mag-rehistro na ngayon",
    },
  },
  ja: {
    seminar_malaysia: {
      title: "IUX ウェビナーに参加する",
      description:
        "この方法をお見逃しなく！ 専門家からの洞察であなたの取引スキルを強化します。",
      button: "今すぐ登録",
    },
  },
  es: {
    seminar_malaysia: {
      title: "Únete al Webinar de IUX",
      description:
        "¡No te pierdas esta oportunidad! Potencia tus habilidades de trading con las ideas de los expertos.",
      button: "Regístrate Ahora",
    },
  },
};
