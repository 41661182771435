import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Result, Button, Spin } from "antd";
import FooterVerify from "../components/footer-landing-page/FooterVerify";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import LoadingIux from "../components/loading";
import { renderApiDomain } from "../helper/renderApiDomain";
const locale = require("react-redux-i18n").I18n;

function EmailVerifier(props) {
  const [status, setStatus] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingResend, setLoadingResend] = useState(false);
  const [countDown, setCountDown] = useState(0);
  const [error, setError] = useState("");
  const [minutes, setMinutes] = useState(0);
  const { lang } = useParams();
  let isLink = typeof window !== "undefined" && window.location.origin;
  useEffect(() => {
    const myInterval = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
      }
      if (countDown === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
          setLoading(false);
        } else {
          setMinutes(minutes - 1);
          setCountDown(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [countDown]);
  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    let token = urlParams.get("token");

    let isToken = token || props.match.params.token;

    Axios.get(renderApiDomain() + "email-verifier?token=" + isToken)
      .then((res) => {
        console.log("success");
        setStatus("success");
        setTitle(locale.t("verify.success.title"));
        setDescription(locale.t("verify.success.description"));
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setStatus("error");
        setTitle(locale.t("verify.reject.title"));
        setDescription(locale.t("verify.reject.description"));
        setLoading(false);
      });

    const onClick = () => {
      props.history.replace(`/${localStorage.getItem("locale")}/login`);
    };
  }, []);

  const onResend = async () => {
    let urlParams = new URLSearchParams(window.location.search);
    let email = urlParams.get("email");
    try {
      setLoadingResend(true);
      await Axios.post(renderApiDomain() + "resend-verify-email", {
        email: email,
      }).then((res) => {
        setCountDown(59);
        setError(locale.t("validate_email_seccuss"));
      });
      setLoadingResend(false);
    } catch (e) {
      setError(e.message);
      setLoadingResend(false);
    }
  };

  // return (
  //   <>
  //     <div
  //       className="h-100 d-flex align-items-center justify-content-center"
  //       style={{ background: "url('/assets/images/covers/slide-page.jpg')" }}
  //     >
  //       <Result
  //         style={{
  //           backgroundColor: "white",
  //           borderRadius: 8,
  //           padding: 64,
  //         }}
  //         status={status || 404}
  //         title={title || ""}
  //         subTitle={content || ""}
  //         extra={[
  //           <Button type="primary" size="large" shape="round" onClick={onClick}>
  //             {locale.t("register_verify_go")}
  //           </Button>,
  //         ]}
  //       />
  //     </div>
  //   </>
  // );
  return (
    <div className="email-verify">
      <div className="verify-header">
        <img
          alt="IUX"
          src={process.env.PUBLIC_URL + "/assets/images/icons/iux-logo.png"}
          onClick={() => window.open(isLink, "_self")}
          className="cursor-pointer border-logo"
          width={44}
          height={44}
        />
        <div
          className="header-verify-button"
          onClick={() =>
            window.open(`${window.location.origin}/${lang}/login`, "_self")
          }
        >
          {locale.t("verify.login")}
        </div>
      </div>
      <img
        src="/assets/images/bg-earth.png"
        width="100%"
        height="100%"
        style={{ minHeight: "650px", objectFit: "cover" }}
      />
      {loading && (
        <div className="verify-container">
          <LoadingIux color="text-light" />
        </div>
      )}
      {!loading && (
        <div className="verify-container">
          {status === "success" ? (
            <img src="/assets/images/true-circle.png" width="52" height="52" />
          ) : (
            <img src="/assets/images/false-circle.png" width="52" height="52" />
          )}
          <div className="verify-title">{title}</div>
          <div className="verify-description">{description}</div>

          {status === "error" ? (
            <div className="justify-content-between mt-5">
              {/* <div className="text-white">
                {locale.t("validate_email_resend")}
              </div> */}
              {countDown !== 0 ? (
                <div className="text-white">{countDown}s</div>
              ) : (
                <div>
                  <button
                    disabled={countDown !== 0}
                    loading={loadingResend}
                    className="btn-primary-new-iux"
                    style={{ borderRadius: 20 }}
                    onClick={() => onResend()}
                  >
                    {countDown !== 0
                      ? `${countDown}s`
                      : locale.t("validate_email_resend")}
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div
              className="verify-button"
              onClick={() =>
                window.location.replace(`${isLink}/${lang}/login`, "_self")
              }
            >
              {locale.t("verify.goToLogin")}
            </div>
          )}
        </div>
      )}
      <FooterVerify locale={locale} />
    </div>
  );
}

export default EmailVerifier;
