import React, { useEffect, useState } from "react";
import { Row, Col, Button, Input, Select, Form } from "antd";
import styled from "styled-components";
import { post } from "../../helper/request";
import Recommendation from "./components/Recommendation";
import { renderApiDomain } from "../../helper/renderApiDomain";

const { Option } = Select;
const locale = require("react-redux-i18n").I18n;

const styleInput = {
  height: 40,
  borderRadius: 4,
};

const styleDisabled = {
  // backgroundColor: "rgb(230 230 230 / 51%)",
  color: "#fff",
  height: 40,
  borderRadius: 4,
};

const BoxContentCal = styled.div`
  padding: 12px;
  border-radius: 20px;
  border: 1px solid #8080803d;
  background: #242424;
`;

const BoxErrors = styled.div`
  color: red;
`;

export default function Versatile(props) {
  const [form] = Form.useForm();
  const [lot, setLot] = useState();
  const [accountType, setAccountType] = useState();
  const [currency, setCurrency] = useState();
  const [leverage, setLeverage] = useState();
  const [accountCurrency, setAccountCurrency] = useState();
  const [swapLong, setSwapLong] = useState();
  const [swapShort, setSwapShort] = useState();
  const [fluctuation, setFluctuation] = useState();
  const [contractSize, setContractSize] = useState();
  const [ask, setAsk] = useState();
  const [bid, setBid] = useState();
  const [converse, setConverse] = useState();
  const [calcMode, setCalcMode] = useState(1);
  const [marginRate, setMarginRate] = useState();
  const [MarginAccount, setMarginAccount] = useState();
  const [PipAccount, setPipAccount] = useState();
  const [SwLongAccount, setSwLongAccount] = useState();
  const [SwShortAccount, setSwShortAccount] = useState();
  const [loading, setLoading] = useState(false);
  const [base, setBase] = useState();
  const [error, setError] = useState([]);

  const symbol = props.symbols;
  const metaTrader = props.mtType;
  const { handleChangeSymbol } = props;

  function onChangeBaseCurrency(value) {
    setAccountCurrency(value);
  }
  function handleChangeLot(value) {
    setLot(value);
  }
  function onChangeCurrency(value) {
    if (metaTrader.mt5) {
      setCurrency(value);
      post(renderApiDomain() + "calc/mt5/rate", {
        account: accountCurrency,
        pair: value,
      }).then((response) => {
        setConverse(response.data.converse);
        setAsk(response.data.symbol.ask);
        setBid(response.data.symbol.tickShort.bid);
        setBase(response.data.symbol.currencyBase);
        setContractSize(response.data.symbol.contractSize);
        setSwapLong(response.data.symbol.swapLong);
        setSwapShort(response.data.symbol.swapShort);
        setMarginRate(response.data.symbol.marginRateCurrency);
        setCalcMode(1);
        setFluctuation(
          Math.pow(10, -response.data.symbol.digits).toFixed(
            response.data.symbol.digits
          )
        );
      });
    } else {
      setCurrency(value);
      post(process.env.REACT_APP_API_URL + "calc/rate", {
        account: accountCurrency,
        pair: value,
      }).then((response) => {
        setConverse(response.data.converse);
        setAsk(response.data.symbol.ask);
        setContractSize(response.data.symbol.contract_size);
        setSwapLong(response.data.symbol.swap_long);
        setSwapShort(response.data.symbol.swap_short);
        setFluctuation(
          Math.pow(10, -response.data.symbol.digits).toFixed(
            response.data.symbol.digits
          )
        );
      });
    }
  }
  function onChangeLeverage(value) {
    setLeverage(value);
  }
  function onChangeAccountType(value) {
    setAccountType(value);
  }

  const onSubmit = () => {
    if (metaTrader.mt4) {
      setLoading(true);
      const errors = [];
      if (
        accountCurrency === undefined ||
        lot === undefined ||
        currency === undefined ||
        leverage === undefined ||
        accountType === undefined
      ) {
        errors.push(locale.t("dashboardWallet.input_error"));
        setError(errors);
      } else {
        setError([]);
        let margin_account =
          (((lot * contractSize) / leverage) * ask) / converse;
        let pip_account = (fluctuation / converse) * (lot * contractSize);
        let swap_long_account = fluctuation * (lot * contractSize * swapLong);
        let swap_short_account = fluctuation * (lot * contractSize * swapShort);
        if (margin_account !== null) {
          setMarginAccount(margin_account);
          setPipAccount(pip_account);
          setSwLongAccount(swap_long_account);
          setSwShortAccount(swap_short_account);
        }
      }
      setLoading(false);
    } else {
      setLoading(true);
      const errors = [];
      if (
        accountCurrency === undefined ||
        lot === undefined ||
        currency === undefined ||
        leverage === undefined ||
        accountType === undefined
      ) {
        errors.push(locale.t("dashboardWallet.input_error"));
        setError(errors);
      } else {
        setError([]);

        if (calcMode === 0) {
          let pip_account_1 = lot * contractSize * (fluctuation / converse);
          let swap_long_account = 0;
          let swap_short_account = 0;
          setPipAccount(pip_account_1);
          setSwLongAccount(swap_long_account);
          setSwShortAccount(swap_short_account);
          const margin_used_base =
            ((lot * contractSize) / leverage) * marginRate;
          if (base === accountCurrency) {
            setMarginAccount(margin_used_base);
          } else {
            post(process.env.REACT_APP_API_URL + `calc/mt5/converse`, {
              base: base,
              pair: accountCurrency,
            }).then((res) => {
              const marginCalc = margin_used_base * res.data.converse;
              setMarginAccount(marginCalc);
            });
          }
        } else {
          let swap_long_account = fluctuation * (lot * contractSize * swapLong);
          let swap_short_account =
            fluctuation * (lot * contractSize * swapShort);
          setSwLongAccount(swap_long_account);
          setSwShortAccount(swap_short_account);
          const marginQuote =
            ((lot * contractSize * bid) / leverage) * marginRate;
          post(process.env.REACT_APP_API_URL + `calc/mt5/converse`, {
            base: base,
            pair: accountCurrency,
          }).then((res) => {
            const marginAcc = marginQuote * res.data.converse;
            setMarginAccount(marginAcc);
          });
        }
      }
      setLoading(false);
    }
  };
  const handleReset = () => {
    setSwLongAccount(0);
    setSwShortAccount(0);
    setMarginAccount(0);
  };
  return (
    <>
      <Form form={form} className="col-md-8 mb-4">
        <BoxContentCal>
          <Col span={12}>
            <Form.Item name="1">
              <div className="mt-3 text-gray">
                {locale.t("fxcal_list_1_box.Platform")}
              </div>
              <Select
                size="large"
                className="w-100 bg-gray-new-iux"
                onChange={(value) => {
                  handleChangeSymbol(value);
                  form.resetFields();
                  handleReset();
                }}
                defaultValue="Metatrader5"
              >
                <Option value={"mt5"}>Metatrader5</Option>
              </Select>
            </Form.Item>
          </Col>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name="1">
                <div className="mt-3 text-gray">
                  {locale.t("fxcal_list_1_box.title_1")}
                </div>
                <Select
                  size="large"
                  className="w-100"
                  onChange={(value) => onChangeBaseCurrency(value)}
                  defaultValue={locale.t("calculator_select")}
                >
                  <Option value={"USD"}>USD</Option>
                </Select>
              </Form.Item>
              <Form.Item name="2">
                <div className="mt-3 text-gray">
                  {locale.t("fxcal_list_1_box.title_3")}
                </div>
                <Select
                  onChange={(value) => onChangeCurrency(value)}
                  defaultValue={locale.t("calculator_select")}
                  className="w-100"
                  size="large"
                >
                  {symbol !== null &&
                    symbol?.map((e, i) => {
                      return (
                        <Option key={i} value={e.name}>
                          {e.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item name="3">
                <div className="mt-3 text-gray">
                  {locale.t("fxcal_list_1_box.title_5")}
                </div>
                <Select
                  onChange={(value) => onChangeLeverage(value)}
                  defaultValue={locale.t("calculator_select")}
                  className="w-100"
                  size="large"
                >
                  <Option value={50}>1:50</Option>
                  <Option value={100}>1:100</Option>
                  <Option value={200}>1:200</Option>
                  <Option value={500}>1:500</Option>
                  <Option value={1000}>1:1000</Option>
                  <Option value={2000}>1:2000</Option>
                  <Option value={3000}>1:3000</Option>
                </Select>
              </Form.Item>
              <Form.Item name="4">
                <div className="mt-3 text-gray">
                  {locale.t("fxcal_list_1_box.title_7")}
                </div>
                <Select
                  onChange={(value) => onChangeAccountType(value)}
                  defaultValue={locale.t("calculator_select")}
                  className="w-100"
                  size="large"
                >
                  <Option value={"Standard"}>Standard</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} className="">
              <Form.Item name="5">
                <div className="mt-3 text-gray">
                  {locale.t("fxcal_list_1_box.title_2")}
                </div>
                <Input
                  placeholder={"Please Enter value"}
                  onChange={(e) => handleChangeLot(e.target.value)}
                  style={styleInput}
                />
              </Form.Item>
              <Form.Item name="6">
                <div className="mt-3 text-gray">
                  {locale.t("fxcal_list_1_box.title_4")}
                </div>
                <Input value={swapLong} style={styleDisabled} readOnly />
              </Form.Item>
              <Form.Item name="7">
                <div className="mt-3 text-gray">
                  {locale.t("fxcal_list_1_box.title_6")}
                </div>
                <Input value={swapShort} style={styleDisabled} readOnly />
              </Form.Item>
              <Form.Item name="8">
                <div className="mt-3 text-gray">
                  {locale.t("fxcal_list_1_box.title_8")}
                </div>
                <Input value={fluctuation} style={styleDisabled} readOnly />
              </Form.Item>
            </Col>
          </Row>
          {/* submit */}
          <div style={{ margin: "1rem 0" }}>
            <BoxErrors>{error.length !== 0 && error[0]}</BoxErrors>
            <button
              loading={loading}
              onClick={() => onSubmit()}
              className="w-sm-100 btn-primary-new-iux"
            >
              {locale.t("btn_calculator")}
            </button>
          </div>
          {/* submit */}
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name="9">
                <div className="text-gray">
                  {locale.t("fxcal_list_1_box.title_9")}
                </div>
                <Input value={MarginAccount} style={styleDisabled} readOnly />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="10">
                <div className="text-gray">
                  {locale.t("fxcal_list_1_box.title_10")}
                </div>
                <Input value={SwLongAccount} style={styleDisabled} readOnly />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="11">
                <div className="text-gray">
                  {locale.t("fxcal_list_1_box.title_11")}
                </div>
                <Input value={PipAccount} style={styleDisabled} readOnly />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="12">
                <div className="text-gray">
                  {locale.t("fxcal_list_1_box.title_12")}
                </div>
                <Input value={SwShortAccount} style={styleDisabled} readOnly />
              </Form.Item>
            </Col>
          </Row>
        </BoxContentCal>
      </Form>
      <Recommendation />
    </>
  );
}
