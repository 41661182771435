import React from "react";

const SpaceCard = (props) => {
  return (
    <div className="308">
      <h4 className="text-white fs-20 mb-3">System requirements</h4>
      <p className="text-white text-center">{props.space}</p>
    </div>
  );
};

export default SpaceCard;
