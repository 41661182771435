import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
const locale = require("react-redux-i18n").I18n;
function BoxScanQrCode({ isQrcode, account, rate, paymentSelected, amount }) {
  const profileCountry = useSelector((state) => state.user.profileCountry);
  //   isQrcode={prompt}
  //   account={props.account}
  //   rate={props.rate}
  //   paymentSelected={props.paymentSelected}
  return (
    <Row className="container-scan-qrcode d-flex" gutter={[30, 30]}>
      <Col xs={24} md={12} lg={8}>
        <div className="box-scan-qrcode">
          {/* <div className="head-qrcode d-flex justify-content-center">
            <img
              src="/assets/images/icons/iux-logo-outline.png"
              alt="iux-logo-outline"
              width={32}
            />
          </div> */}
          <div className="detail-qrcode ">
            <img
              src={"/assets/images/qrcode/IUX.png"}
              alt="watermark"
              className="logo-qr-iux "
              // width="100%"
            />
            <img src={isQrcode} alt="qr code" width="100%" />
          </div>
        </div>
      </Col>
      <Col xs={24} md={12} lg={16}>
        <div className="fs-14" style={{ fontWeight: 500 }}>
          {locale.t("dashboardWallet.detail")}
        </div>
        <div className="mt-2">
          <span className="text-gray">
            {locale.t("deposit_info.payment.amount")}:
          </span>
          <b>
            {amount} {profileCountry?.symbol}
          </b>
        </div>
        <div className="mt-2 d-flex">
          <span className="text-gray">
            {" "}
            {locale.t("dashbordCommission.irpoint_account_to")}:&nbsp;
          </span>
          <div>
            {account?.platform}&nbsp;
            {account?.account_number}
            &nbsp; ({account?.account_group?.display})
          </div>
        </div>
        <div className="mt-2">
          <span className="text-gray">
            {locale.t("deposit_step.first_step.deposit_with")}:
          </span>{" "}
          {paymentSelected?.name}
        </div>
        <div className="mt-2">
          <span className="text-gray">
            {locale.t("deposit_info.currency_rate")}:{" "}
          </span>
          <span>
            {rate?.toFixed(2)}&nbsp;{profileCountry?.symbol}
          </span>
        </div>
        <div className="mt-2">
          <span className="text-gray">
            {locale.t("deposit_step.second_step.fee")}:
          </span>{" "}
          0.00 {profileCountry?.symbol}
        </div>
      </Col>
    </Row>
  );
}

export default BoxScanQrCode;
