import { Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { post } from "../../../../helper/request";
import { renderApiDomain } from "../../../../helper/renderApiDomain";

const locale = require("react-redux-i18n").I18n;

export const postPayxspace = ({ amount_thb, account_trade_id, payment_id }) => {
  return post(`${renderApiDomain() + "deposit/payxspace"}`, {
    amount_thb: amount_thb,
    account_trade_id: account_trade_id,
    payment_id: payment_id,
  });
};

const Payxspace = (props) => {
  const { paymentId } = props;
  const lang = localStorage.getItem("locale");
  const refPerfect = useRef(null);
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState({
    customer_name: "",
    message: "",
    payment_amount: "",
    payment_date: "",
    payment_link: "",
    phone_number: "",
    status: "",
    transaction_id: 0,
    type: "",
    type_fee: "",
  });
  useEffect(() => {
    const postApiPayment = async () => {
      setLoading(true);
      const payload = {
        account_trade_id: props.account.id,
        amount_thb: props.amount,
        payment_id: paymentId,
      };
      const { data } = await postPayxspace(payload);
      setPaymentData({
        customer_name: data.customer_name,
        message: data.message,
        payment_amount: parseFloat(data.payment_amount).toFixed(2),
        payment_date: data.payment_date,
        payment_link: data.payment_link,
        phone_number: data.phone_number,
        status: data.status,
        transaction_id: data.transaction_id,
        type: data.type,
        type_fee: data.type_fee,
        // amount: parseFloat(data.amount).toFixed(2),
      });
      setLoading(true);
      refPerfect.current.click();
    };
    postApiPayment();
  }, []);

  return (
    <Spin spinning={loading}>
      <form action={paymentData.payment_link} method="POST">
        <p>
          {/* <input
            type="hidden"
            name="customer_name"
            value={paymentData.payee_account}
          />
          <input
            type="hidden"
            name="payment_amount"
            value={paymentData.payee_name}
          />
          <input
            type="hidden"
            name="payment_date"
            value={paymentData.amount}
          />
          <input type="hidden" name="PAYMENT_UNITS" value="USD" />
          <input
            type="hidden"
            name="payment_link"
            value={paymentData.reference}
          />
          <input
            type="hidden"
            name="message"
            value={paymentData.reference}
          /> */}
          <input
            ref={refPerfect}
            type="submit"
            value="payxspace account"
            hidden
          />
        </p>
      </form>
    </Spin>
  );
};

export default Payxspace;
