import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import TextWithCover from "../text-with-cover/TextWithCover";
const locale = require("react-redux-i18n").I18n;

const TabMenu = styled.div`
  width: 100%;
  text-align: center;
  color: ${(props) => (props.active ? "#FFF" : "gray")};
  padding: 12px;
  cursor: pointer;

  border-bottom: ${(props) => (props.active ? "1px solid #1cb894" : "unset")}; ;
`;

const Button = styled.button`
  border: 2px solid #1cb894;
  background: transparent;
  font-size: 16px;
  padding: 8px 12px;
  border-radius: 5px;
  color: #1cb894;
  outline: none !important;
`;

const Grid = styled.div`
  cursor: pointer;
`;
const Border = styled.div`
  width: 1px;
  height: 40px;
  background-color: gray;
  margin: auto;
`;
const GridItem = styled.div`
  display: flex;
  align-items: center;
`;
const MenuItem = styled.div`
  padding: 0.8rem 1rem;
  color: ${(props) => props.color};
  border-bottom: ${(props) => props.borderBottom};
  white-space: pre;
  font-weight: ${(props) => props.fontWeight};
  cursor: pointer;
`;
const GridSubItem = styled.div`
  background: #fff;
  overflow: hidden;
  color: #000;
  transition: all 0.4s ease-in-out;
  height: ${(props) => (props.active ? "150px" : 0)};
`;

const Font = styled.div`
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
`;

const textGrayt = {
  color: "#8D8D8D",
  fontSize: 12,
  borderRight: "1px solid gray",
};
const textGraytlast = {
  color: "#8D8D8D",
  fontSize: 12,
  // borderRight: "1px solid gray",
};

const PaymentOption = () => {
  const user = useSelector((state) => state.user);
  const item = [
    {
      image: "/assets/images/bank/banklanding/1.png",
      maximum: "$50,000",
      minimum: "$10",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_1"),
    },
    {
      image: "/assets/images/bank/banklanding/2.png",
      maximum: "$50,000",
      minimum: "$10",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_1"),
    },
    {
      image: "/assets/images/bank/banklanding/3.png",
      maximum: "$50,000",
      minimum: "$10",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_1"),
    },
    {
      image: "/assets/images/bank/banklanding/4.png",
      maximum: "$50,000",
      minimum: "$10",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_1"),
    },
    {
      image: "/assets/images/bank/banklanding/5.png",
      maximum: "$50,000",
      minimum: "$10",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_1"),
    },
    {
      image: "/assets/images/bank/banklanding/6.png",
      maximum: "$50,000",
      minimum: "$10",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_2"),
    },
    {
      image: "/assets/images/bank/banklanding/7.png",
      maximum: "$50,000",
      minimum: "$10",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_2"),
    },
    {
      image: "/assets/images/bank/banklanding/8.png",
      maximum: "$50,000",
      minimum: "$10",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_2"),
    },
    {
      image: "/assets/images/bank/banklanding/9.png",
      maximum: "$50,000",
      minimum: "$10",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_2"),
    },
    {
      image: "/assets/images/bank/banklanding/10.png",
      maximum: "$50,000",
      minimum: "$10",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_2"),
    },
    {
      image: "/assets/images/bank/banklanding/11.png",
      maximum: "$50,000",
      minimum: "$10",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_2"),
    },
    {
      image: "/assets/images/bank/banklanding/12.png",
      maximum: "$50,000",
      minimum: "$10",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_3"),
    },
    {
      image: "/assets/images/bank/banklanding/13.png",
      maximum: "$50,000",
      minimum: "$10",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_3"),
    },
    {
      image: "/assets/images/bank/banklanding/14.png",
      maximum: "$50,000",
      minimum: "$10",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_1"),
    },
    {
      image: "/assets/images/bank/banklanding/15.png",
      maximum: "$50,000",
      minimum: "10 USDT",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_1"),
    },
    {
      image: "/assets/images/bank/banklanding/16.png",
      maximum: "$50,000",
      minimum: "360 THB",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_1"),
    },
    {
      image: "/assets/images/bank/banklanding/17.png",
      maximum: "$50,000",
      minimum: "360 THB",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_1"),
    },
    {
      image: "/assets/images/bank/banklanding/18.png",
      maximum: "$50,000",
      minimum: "0.00073 ETH",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_1"),
    },
    {
      image: "/assets/images/bank/banklanding/19.png",
      maximum: "$50,000",
      minimum: "0.015 LTC",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_1"),
    },
  ];
  const itemwithdraw = [
    {
      image: "/assets/images/bank/banklanding/1.png",
      maximum: "$50,000",
      minimum: "$3",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_1"),
    },
    {
      image: "/assets/images/bank/banklanding/2.png",
      maximum: "$50,000",
      minimum: "$3",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_1"),
    },
    {
      image: "/assets/images/bank/banklanding/3.png",
      maximum: "$50,000",
      minimum: "$3",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_1"),
    },
    {
      image: "/assets/images/bank/banklanding/4.png",
      maximum: "$50,000",
      minimum: "$3",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_1"),
    },
    {
      image: "/assets/images/bank/banklanding/5.png",
      maximum: "$50,000",
      minimum: "$3",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_1"),
    },
    {
      image: "/assets/images/bank/banklanding/6.png",
      maximum: "$50,000",
      minimum: "$3",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_2"),
    },
    {
      image: "/assets/images/bank/banklanding/7.png",
      maximum: "$50,000",
      minimum: "$3",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_2"),
    },
    {
      image: "/assets/images/bank/banklanding/8.png",
      maximum: "$50,000",
      minimum: "$3",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_2"),
    },
    {
      image: "/assets/images/bank/banklanding/9.png",
      maximum: "$50,000",
      minimum: "$3",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_2"),
    },
    {
      image: "/assets/images/bank/banklanding/10.png",
      maximum: "$50,000",
      minimum: "$3",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_2"),
    },
    {
      image: "/assets/images/bank/banklanding/11.png",
      maximum: "$50,000",
      minimum: "$3",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_2"),
    },
    {
      image: "/assets/images/bank/banklanding/12.png",
      maximum: "$50,000",
      minimum: "$3",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_3"),
    },
    {
      image: "/assets/images/bank/banklanding/13.png",
      maximum: "$50,000",
      minimum: "$3",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_3"),
    },
    {
      image: "/assets/images/bank/banklanding/14.png",
      maximum: "$50,000",
      minimum: "$3",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_1"),
    },
    {
      image: "/assets/images/bank/banklanding/15.png",
      maximum: "$50,000",
      minimum: "3 USDT",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_1"),
    },
    {
      image: "/assets/images/bank/banklanding/16.png",
      maximum: "$50,000",
      minimum: "101 THB",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_1"),
    },
    {
      image: "/assets/images/bank/banklanding/17.png",
      maximum: "$50,000",
      minimum: "101 THB",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_1"),
    },
    {
      image: "/assets/images/bank/banklanding/18.png",
      maximum: "$50,000",
      minimum: "0.00073 ETH",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_1"),
    },
    {
      image: "/assets/images/bank/banklanding/19.png",
      maximum: "$50,000",
      minimum: "0.015 LTC",
      commission: locale.t("pay_free"),
      execution: locale.t("pay_title_time.title_1"),
    },
  ];

  const option = [
    {
      symbol: `VND`,
      pay_method: locale.t("pay_method.mobile"),
      pay_method_withdraw: locale.t("pay_method_withdraw.mobile"),
      pay_amount: `${locale.t("pay_currency")} VND ${locale.t("pay_amount")}`,
      pay_amount_withdraw: `${locale.t("pay_amount_withdraw")}`,
      pay_commission: locale.t("pay_commission.title_1"),
      pay_time: locale.t("pay_time"),
    },
    {
      symbol: `VND`,
      pay_method: locale.t("pay_method.mobile"),
      pay_method_withdraw: locale.t("pay_method_withdraw.mobile"),
      pay_amount: `${locale.t("pay_currency")} VND ${locale.t("pay_amount")}`,
      pay_amount_withdraw: `${locale.t("pay_amount_withdraw")}`,
      pay_commission: locale.t("pay_commission.title_1"),
      pay_time: locale.t("pay_time"),
    },
    {
      symbol: `VND`,
      pay_method: locale.t("pay_method.mobile"),
      pay_method_withdraw: locale.t("pay_method_withdraw.mobile"),
      pay_amount: `${locale.t("pay_currency")} VND ${locale.t("pay_amount")}`,
      pay_amount_withdraw: `${locale.t("pay_amount_withdraw")}`,
      pay_commission: locale.t("pay_commission.title_1"),
      pay_time: locale.t("pay_time"),
    },
    {
      symbol: `VND`,
      pay_method: locale.t("pay_method.mobile"),
      pay_method_withdraw: locale.t("pay_method_withdraw.mobile"),
      pay_amount: `${locale.t("pay_currency")} VND ${locale.t("pay_amount")}`,
      pay_amount_withdraw: `${locale.t("pay_amount_withdraw")}`,
      pay_commission: locale.t("pay_commission.title_1"),
      pay_time: locale.t("pay_time"),
    },
    {
      symbol: `VND`,
      pay_method: locale.t("pay_method.mobile"),
      pay_method_withdraw: locale.t("pay_method_withdraw.mobile"),
      pay_amount: `${locale.t("pay_currency")} VND ${locale.t("pay_amount")}`,
      pay_amount_withdraw: `${locale.t("pay_amount_withdraw")}`,
      pay_commission: locale.t("pay_commission.title_1"),
      pay_time: locale.t("pay_time"),
    },
    {
      symbol: `THB`,
      pay_method: locale.t("pay_method.mobile"),
      pay_method_withdraw: locale.t("pay_method_withdraw.mobile"),
      pay_amount: `${locale.t("pay_currency")} THB ${locale.t("pay_amount")}`,
      pay_amount_withdraw: `${locale.t("pay_amount_withdraw")}`,
      pay_commission: locale.t("pay_commission.title_1"),
      pay_time: locale.t("pay_time"),
    },
    {
      symbol: `THB`,
      pay_method: locale.t("pay_method.mobile"),
      pay_method_withdraw: locale.t("pay_method_withdraw.mobile"),
      pay_amount: `${locale.t("pay_currency")} THB ${locale.t("pay_amount")}`,
      pay_amount_withdraw: `${locale.t("pay_amount_withdraw")}`,
      pay_commission: locale.t("pay_commission.title_1"),
      pay_time: locale.t("pay_time"),
    },
    {
      symbol: `THB`,
      pay_method: locale.t("pay_method.mobile"),
      pay_method_withdraw: locale.t("pay_method_withdraw.mobile"),
      pay_amount: `${locale.t("pay_currency")} THB ${locale.t("pay_amount")}`,
      pay_amount_withdraw: `${locale.t("pay_amount_withdraw")}`,
      pay_commission: locale.t("pay_commission.title_1"),
      pay_time: locale.t("pay_time"),
    },
    {
      symbol: `THB`,
      pay_method: locale.t("pay_method.mobile"),
      pay_amount: `${locale.t("pay_currency")} THB ${locale.t("pay_amount")}`,
      pay_amount_withdraw: `${locale.t("pay_amount_withdraw")}`,
      pay_commission: locale.t("pay_commission.title_1"),
      pay_time: locale.t("pay_time"),
    },
    {
      symbol: `THB`,
      pay_method: locale.t("pay_method.mobile"),
      pay_method_withdraw: locale.t("pay_method_withdraw.mobile"),
      pay_amount: `${locale.t("pay_currency")} THB ${locale.t("pay_amount")}`,
      pay_amount_withdraw: `${locale.t("pay_amount_withdraw")}`,
      pay_commission: locale.t("pay_commission.title_1"),
      pay_time: locale.t("pay_time"),
    },
    {
      symbol: `BTC`,
      pay_method: locale.t("pay_method.platfrom"),
      pay_method_withdraw: locale.t("pay_method_withdraw.mobile"),
      pay_amount: `${locale.t("pay_currency")} BTC ${locale.t("pay_amount")}`,
      pay_amount_withdraw: `${locale.t("pay_amount_withdraw")}`,
      pay_commission: locale.t("pay_commission.title_1"),
      pay_time: locale.t("pay_time"),
    },
    {
      symbol: `BTC`,
      pay_method: locale.t("pay_method.platfrom"),
      pay_method_withdraw: locale.t("pay_method_withdraw.mobile"),
      pay_amount: `${locale.t("pay_currency")} BTC ${locale.t("pay_amount")}`,
      pay_amount_withdraw: `${locale.t("pay_amount_withdraw")}`,
      pay_commission: locale.t("pay_commission.title_1"),
      pay_time: locale.t("pay_time"),
    },
    {
      symbol: `BTC`,
      pay_method: locale.t("pay_method.platfrom_2"),
      pay_method_withdraw: locale.t("pay_method_withdraw.platfrom_2"),
      pay_amount: `${locale.t("pay_currency")} BTC ${locale.t("pay_amount")}`,
      pay_amount_withdraw: `${locale.t("pay_amount_withdraw")}`,
      pay_commission: locale.t("pay_commission.title_1"),
      pay_time: locale.t("pay_time"),
    },
    {
      symbol: `DOGE`,
      pay_method: locale.t("pay_method.platfrom_2"),
      pay_method_withdraw: locale.t("pay_method_withdraw.platfrom_2"),
      pay_amount: `${locale.t("pay_currency")} DOGE ${locale.t("pay_amount")}`,
      pay_amount_withdraw: `${locale.t("pay_amount_withdraw")}`,
      pay_commission: locale.t("pay_commission.title_2"),
      pay_time: locale.t("pay_time"),
    },
    {
      symbol: `USDT`,
      pay_method: locale.t("pay_method.mobile"),
      pay_method_withdraw: locale.t("pay_method_withdraw.mobile"),
      pay_amount: `${locale.t("pay_currency")} USDT ${locale.t("pay_amount")}`,
      pay_amount_withdraw: `${locale.t("pay_amount_withdraw")}`,
      pay_commission: locale.t("pay_commission.title_1"),
      pay_time: locale.t("pay_time"),
    },
    {
      symbol: `THB`,
      pay_method: locale.t("pay_method.mobile"),
      pay_method_withdraw: locale.t("pay_method_withdraw.mobile"),
      pay_amount: `${locale.t("pay_currency")} THB ${locale.t("pay_amount")}`,
      pay_amount_withdraw: `${locale.t("pay_amount_withdraw")}`,
      pay_commission: locale.t("pay_commission.title_1"),
      pay_time: locale.t("pay_time"),
    },
    {
      symbol: `THB`,
      pay_method: locale.t("pay_method.mobile"),
      pay_method_withdraw: locale.t("pay_method_withdraw.mobile"),
      pay_amount: `${locale.t("pay_currency")} THB ${locale.t("pay_amount")}`,
      pay_amount_withdraw: `${locale.t("pay_amount_withdraw")}`,
      pay_commission: locale.t("pay_commission.title_1"),
      pay_time: locale.t("pay_time"),
    },
    {
      symbol: `ETH`,
      pay_method: locale.t("pay_method.platfrom_2"),
      pay_method_withdraw: locale.t("pay_method_withdraw.platfrom_2"),
      pay_amount: `${locale.t("pay_currency")} ETH ${locale.t("pay_amount")}`,
      pay_amount_withdraw: `${locale.t("pay_amount_withdraw")}`,
      pay_commission: locale.t("pay_commission.title_2"),
      pay_time: locale.t("pay_time"),
    },
    {
      symbol: `LTC`,
      pay_method: locale.t("pay_method.platfrom_2"),
      pay_method_withdraw: locale.t("pay_method_withdraw.platfrom_2"),
      pay_amount: `${locale.t("pay_currency")} LTC ${locale.t("pay_amount")}`,
      pay_amount_withdraw: `${locale.t("pay_amount_withdraw")}`,
      pay_commission: locale.t("pay_commission.title_2"),
      pay_time: locale.t("pay_time"),
    },
  ];
  const menu = [
    {
      id: 0,
      text: "Deposit",
    },
    {
      id: 1,
      text: "Withdrawal",
    },
  ];
  const [tab, setTab] = useState(0);
  const [active, setActive] = useState(undefined);
  const [isIdex, setIndex] = React.useState(0);
  const handleActive = (i) => {
    if (active === i) {
      setActive(undefined);
    } else {
      setActive(i);
    }
  };

  return (
    <div className="bg-black">
      <TextWithCover
        key={1}
        imagePath={
          process.env.PUBLIC_URL +
          "/assets/images/covers/cover-payment-option.png"
        }
        title="Deposits and withdrawals"
      />
      <div>
        <div className="container">
          <div className="pt-5 pb-5">
            <Font
              fontSize="28px"
              fontWeight="bold"
              className="text-center font-bold-iux text-white"
            >
              We accept all the most popular payment methods
            </Font>
          </div>
          <div className="sub-menu-page">
            <div className="container">
              <div className="d-flex overflow-auto scroll-menu-hide">
                {menu.map((e, i) => (
                  <MenuItem
                    onClick={() => setIndex(i)}
                    color={i === isIdex ? "#FFF" : "#8D8D8D"}
                    borderBottom={
                      i === isIdex ? "4px solid #1CB894" : "2px solid #ff000000"
                    }
                    fontWeight={i === isIdex ? 400 : "unset"}
                  >
                    {e.text}
                  </MenuItem>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" pb-5">
        <div
          className="container"
          style={{ background: "#242424", borderRadius: 20, padding: 20 }}
        >
          {isIdex === 0 && (
            <>
              <div
                className="row pt-5 pb-5 d-none d-lg-flex"
                style={{ borderBottom: "1px solid #3E3E3E" }}
              >
                <div className="col-lg-2 text-center" style={textGrayt}>
                  PAYMENT METHOD
                </div>

                <div className="col-lg-2 text-center" style={textGrayt}>
                  MINIMUM AMOUNT
                </div>
                <div className="col-lg-2 text-center" style={textGrayt}>
                  MAXIMUM AMOUNT
                </div>
                <div className="col-lg-3 text-center" style={textGrayt}>
                  COMMISSION
                </div>
                <div className="col-lg-3 text-center" style={textGraytlast}>
                  EXECUTION TIME
                </div>
                {/* <div className="col-lg-2" /> */}
              </div>
              {item.map((e, i) => (
                <>
                  <Grid
                    className="row mt-3"
                    key={i}
                    onClick={() => handleActive(i)}
                  >
                    <GridItem className="col-lg-2">
                      <img src={e.image} alt="..." width={50} />
                    </GridItem>
                    <GridItem className="col-lg-2 mt-2 mt-lg-0">
                      <div className="col-sm-6 d-lg-none p-0 mt-lg-0 text-white ">
                        Minimum amount
                      </div>
                      <div className="col-lg-12 col-sm-6 p-0 text-white text-center">
                        {e.minimum}
                      </div>
                    </GridItem>
                    <GridItem className="col-lg-2 mt-2 mt-lg-0">
                      <div className="col-sm-6 d-lg-none p-0 mt-lg-0 text-white">
                        Maximum amount
                      </div>
                      <div className="col-lg-12 col-sm-6 p-0 text-white text-center">
                        {e.maximum}
                      </div>
                    </GridItem>
                    <GridItem className="col-lg-3 mt-2 mt-lg-0">
                      <div className="col-sm-6 d-lg-none p-0 mt-lg-0 text-white">
                        Commmission
                      </div>
                      <div className="col-lg-12 col-sm-6 p-0 text-white text-center w-100">
                        {e.commission}
                      </div>
                    </GridItem>
                    <GridItem className="col-lg-3 d-none d-lg-flex text-white ">
                      <div className="text-center w-100"> {e.execution}</div>
                    </GridItem>
                    <div className="align-items-end col-lg-none d-flex justify-content-between justify-content-lg-end mt-3 mt-lg-0 text-lg-right w-100">
                      {/* <Link to={`/${localStorage.getItem("locale")}/login`}>
                        <Button
                          outline
                          border="1px solid #1B73EE"
                          color="#1B73EE"
                        >
                          {user.isAuth ? `Deposit` : `Get verified first`}
                        </Button>
                      </Link> */}
                      <Button className="d-lg-none btn-primary-new-iux">
                        Show detail
                      </Button>
                    </div>
                  </Grid>
                  <GridSubItem className="row bg-black" active={active === i}>
                    <div className="col-lg bg-gray-new-iux  pt-4">
                      <Font fontSize="12px" className="text-gray">
                        {active ? option[i].pay_method : option[i].pay_method}
                      </Font>
                    </div>
                    <div className="col-lg bg-gray-new-iux  pt-4">
                      <Font fontSize="12px" className="text-gray">
                        {active ? option[i].pay_amount : option[i].pay_amount}
                      </Font>
                    </div>
                    <div className="col-lg bg-gray-new-iux  pt-4">
                      <Font fontSize="12px" className="text-gray">
                        {active
                          ? option[i].pay_commission
                          : option[i].pay_commission}
                      </Font>
                    </div>
                    <div className="col-lg bg-gray-new-iux  pt-4">
                      <Font fontSize="12px" className="text-gray">
                        {active ? option[i].pay_time : option[i].pay_time}
                      </Font>
                    </div>
                  </GridSubItem>
                </>
              ))}
            </>
          )}
          {isIdex === 1 && (
            <>
              <div
                className="row pt-5 pb-5 d-none d-lg-flex"
                style={{ borderBottom: "1px solid #3E3E3E" }}
              >
                <div className="col-lg-2 text-center" style={textGrayt}>
                  PAYMENT METHOD
                </div>
                <div className="col-lg-2 text-center" style={textGrayt}>
                  MINIMUM AMOUNT
                </div>
                <div className="col-lg-2 text-center" style={textGrayt}>
                  MAXIMUM AMOUNT
                </div>
                <div className="col-lg-3 text-center" style={textGrayt}>
                  COMMISSION
                </div>
                <div className="col-lg-3 text-center" style={textGraytlast}>
                  EXECUTION TIME
                </div>
                {/* <div className="col-lg-2" /> */}
              </div>
              {itemwithdraw.map((e, i) => (
                <>
                  <Grid
                    className="row mt-3"
                    key={i}
                    onClick={() => handleActive(i)}
                  >
                    <div className="col-lg-2">
                      <img src={e.image} alt="..." width={50} />
                    </div>
                    <GridItem className="col-lg-2 mt-2 mt-lg-0">
                      <div className="col-sm-6 d-lg-none p-0 mt-lg-0 text-white">
                        Minimum amount
                      </div>
                      <div className="col-lg-12 col-sm-6 p-0 text-white text-center">
                        {e.minimum}
                      </div>
                    </GridItem>
                    <GridItem className="col-lg-2 mt-2 mt-lg-0">
                      <div className="col-sm-6 d-lg-none p-0 mt-lg-0 text-white">
                        Maximum amount
                      </div>
                      <div className="col-lg-12 col-sm-6 p-0 text-white text-center">
                        {e.maximum}
                      </div>
                    </GridItem>
                    <GridItem className="col-lg-3 mt-2 mt-lg-0">
                      <div className="col-sm-6 d-lg-none p-0 mt-lg-0 text-white">
                        Commmission
                      </div>
                      <div className="col-lg-12 col-sm-6 p-0 text-white text-center">
                        {e.commission}
                      </div>
                    </GridItem>
                    <GridItem className="col-lg-3 d-none d-lg-flex text-white">
                      <div className="text-center w-100"> {e.execution}</div>
                    </GridItem>
                    <div className="align-items-end col-lg-none d-flex justify-content-between justify-content-lg-end mt-3 mt-lg-0 text-lg-right w-100">
                      {/* <Link to={`/${localStorage.getItem("locale")}/login`}>
                        <Button
                          outline
                          border="1px solid #1B73EE"
                          color="#1B73EE"
                        >
                          {user.isAuth ? `Deposit` : `Get verified first`}
                        </Button>
                      </Link> */}
                      <Button className="d-lg-none btn-primary-new-iux">
                        Show detail
                      </Button>
                    </div>
                  </Grid>
                  <GridSubItem className="row" active={active === i}>
                    <div className="col-lg bg-gray-new-iux  pt-4">
                      <Font fontSize="12px" className="text-gray">
                        {active
                          ? option[i].pay_method_withdraw
                          : option[i].pay_method_withdraw}
                      </Font>
                    </div>
                    <div className="col-lg bg-gray-new-iux  pt-4">
                      <Font fontSize="12px" className="text-gray ">
                        {active
                          ? option[i].pay_amount_withdraw
                          : option[i].pay_amount_withdraw}
                      </Font>
                    </div>
                    <div className="col-lg bg-gray-new-iux  pt-4">
                      <Font fontSize="12px" className="text-gray">
                        {active
                          ? option[i].pay_commission
                          : option[i].pay_commission}
                      </Font>
                    </div>
                    <div className="col-lg bg-gray-new-iux  pt-4">
                      <Font fontSize="12px" className="text-gray">
                        {active ? option[i].pay_time : option[i].pay_time}
                      </Font>
                    </div>
                  </GridSubItem>
                </>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentOption;
