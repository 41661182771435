import { Button } from "antd";
import { Link } from "react-router-dom";
import Title from "../../components/title/Title";
import StepBox from "../../components/stepbox/StepBox";
import React, { Component } from "react";
import TextWithCover from "../../components/text-with-cover/TextWithCover";
import RcQueueAnim from "rc-queue-anim";
import { connect } from "react-redux";
import FooterLandingPage from "../../components/footer-landing-page/FooterLandingPage";
import TextWithCoverButton from "../../components/text-with-cover/TextWithCoverButton";
import { Helmet } from "react-helmet";

const TITLE = "Deposit - Withdraw | IUX Markets";

const locale = require("react-redux-i18n").I18n;
class DepositPage extends Component {
  constructor(props) {
    super(props);

    const whyChooses = [
      {
        icon: process.env.PUBLIC_URL + "/assets/images/icons/iux-safe.svg",
        title: "ปลอดภัยมาตรฐานระดับโลก",
        detail:
          "เราเก็บรักษาข้อมูลทางการเงินของคุณไว้อย่างปลอดภัย ด้วยการเข้ารหัสและเฝ้าระวังการฉ้อโกงทุกวัน ตลอด 24 ชั่วโมง",
      },
      {
        icon: process.env.PUBLIC_URL + "/assets/images/icons/iux-cash.svg",
        title: "การ ฝาก-ถอน ที่รวดเร็วที่สุด",
        detail:
          "เราตระหนักดีว่า การฝาก-ถอน ที่ง่ายและรวดเร็ว จะช่วยให้เทรดเดอร์ไม่พลาดโอกาสในการซื้อขายที่สำคัญต้อง ฝาก-ถอน ง่าย ไม่ซับซ้อน",
      },
      {
        icon: process.env.PUBLIC_URL + "/assets/images/icons/iux-fast.svg",
        title: "การดำเนินคำสั่งที่รวดเร็วกว่า",
        detail:
          "ภารกิจของเราคือการให้บริการเทรดเดอร์ เพื่อให้ได้ราคาที่ดีที่สุด เราพยายามอย่างหนัก ในเทคโนโลยี เพื่อเพิ่มสภาพคล่องให้กับลูกค้า",
      },
      {
        icon: process.env.PUBLIC_URL + "/assets/images/icons/iux-coupon.svg",
        title: "รับเครดิตเงินฝาก สูงสุด 500$",
        detail:
          "เราร่วมมือกับธนาคารทั้งในประเทศและต่างประเทศ ให้คุณสามารถรับโบนัสเครดิต จากการฝากเงินเพื่อลดความเสี่ยงให้ได้มากที่สุด",
      },
    ];

    const listGiving = [
      {
        icon: process.env.PUBLIC_URL + "/assets/images/icons/iux-platforms.svg",
        detail:
          "เทคโนโลยีการ Copy trade กับมาสเตอร์คุณภาพที่หลากหลายให้เลือกติดตามสัญญาณการเทรดด้วย Eiloy Platform ที่เป็นเทคโนโลยี Social Trade ร่วมเป็น partner กับเรา ",
        title: "",
      },
      {
        icon: process.env.PUBLIC_URL + "/assets/images/icons/iux-stack.svg",
        detail:
          "เครื่องมือการเทรดและแพลตฟอร์มที่พร้อมให้คุณ ซื้อ-ขายได้มั่นใจยิ่งขึ้น",
        title: "",
      },
    ];

    const tutorials = [
      {
        imagePath:
          process.env.PUBLIC_URL + "/assets/images/step/deposit-step1.png",
        number: 1,
        title: locale.t("depositPage.SectionBox1"),
        detail: "",
      },
      {
        imagePath:
          process.env.PUBLIC_URL + "/assets/images/step/deposit-step2.png",
        number: 2,
        title: locale.t("depositPage.SectionBox2"),
        detail: "",
      },
      {
        imagePath:
          process.env.PUBLIC_URL + "/assets/images/step/deposit-step3.png",
        number: 3,
        title: locale.t("depositPage.SectionBox3"),
        detail: "",
      },
      {
        imagePath:
          process.env.PUBLIC_URL + "/assets/images/step/deposit-step4.png",
        number: 4,
        title: locale.t("depositPage.SectionBox4"),
        detail: "",
      },
    ];

    const textCoverList = [
      {
        imagePath: process.env.PUBLIC_URL + "/assets/images/covers/3.jpg",
        title: "เข้าถึงการเทรดที่มากที่สุด",
        content:
          "เมื่อคุณเริ่มทำการซื้อขายกับ IUXmarket นอกจากคุณจะไม่ต้องกังวลเรื่องความปลอดภัยแล้ว " +
          "คุณยังได้รับข้อเสนอที่พิเศษสุดๆ สำหรับสมาชิก IUXmarket (เริ่มซื้อขาย)",
      },
      {
        imagePath: process.env.PUBLIC_URL + "/assets/images/covers/4.jpg",
        title: "สามารถเทรดที่ไหนก็ได้เพียงมือถือเครื่องเดียว",
        content:
          "เมื่อคุณเริ่มทำการซื้อขายกับ iUXmarket นอกจากคุณจะไม่ต้องกังวลเรื่องความปลอดภัยแล้ว " +
          "คุณยังได้รับข้อเสนอที่พิเศษสุดๆ สำหรับสมาชิก iUXmarket (เริ่มซื้อขาย)",
      },
      {
        imagePath: process.env.PUBLIC_URL + "/assets/images/covers/5.jpg",
        title: "เข้าถึงการเทรดที่มากที่สุด",
        content:
          "เปิดบัญชี ซื้อ-ขาย กับ  iUXmarket บนแพลตฟอร์ม MT4 จากนั้นทำการดาวน์โหลด Mt4 " +
          "แล้วทำการเทรดผ่านมือถือของท่าน โดยเลือกเทรดสินค้าที่คุณมีความมั่นใจและสามารถถอนได้ทันทีที่คุณพอใจในการซื้อขายแต่ละครั้ง สำคัญคือเงินเข้าทันที",
      },
    ];
    this.state = {
      listGiving: listGiving,
      whyChooses: whyChooses,
      tutorials: tutorials,
      textCoverList: textCoverList,
    };
  }

  render() {
    return (
      <RcQueueAnim>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <div className="bg-iux">
          <section key={1} id="banner" style={{ width: "100%" }}>
            <TextWithCover
              styles={{ backgroundPosition: "center !important" }}
              imagePath={
                process.env.PUBLIC_URL +
                "/assets/images/covers/cover-deposit.jpg"
              }
              title={locale.t("depositPage.CoverTitle")}
              content={
                <>
                  {locale.t("depositPage.CoverSubTitle")}

                  <Link to={`/${localStorage.getItem("locale")}/login`}>
                    <Button className="col-md-12 mt-2 btn-primary-new-iux">
                      {locale.t("depositPage.CoverButtonText")}
                    </Button>
                  </Link>
                </>
              }
            />
          </section>
          <section id="tutorial-iux" className="p-5">
            <Title title={locale.t("depositPage.SectionTitle1")} />
            <div className="row m-0 p-0 d-flex justify-content-center">
              {this.state.tutorials.map((item, index) => {
                return <StepBox key={index} stepItem={item} />;
              })}
            </div>
          </section>
          <section id="bank" className="p-5 container">
            <Title title={locale.t("depositPage.SectionTitle2")} />
            <p className="text-center">
              {locale.t("depositPage.SectionSubTitle2")}
            </p>
            <div className="row d-none d-md-block d-lg-block text-center mt-4">
              <div className="margin-card-img-deposit col-md-4">
                <img
                  alt=""
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/payment/online-bank.png"
                  }
                  className="bank_support"
                />
                <p className="text-center">Online Bank</p>
              </div>
              <div className="margin-card-img-deposit col-md-4">
                <img
                  alt=""
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/payment/iux-neteller.png"
                  }
                  className="bank_support"
                />
                <p className="text-center">Neteller</p>
              </div>
              <div className="margin-card-img-deposit col-md-4">
                <img
                  alt=""
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/payment/iux-paypal.png"
                  }
                  className="bank_support"
                />
                <p className="text-center">Paypal</p>
              </div>
            </div>
            <div className="row d-none d-md-block d-lg-block text-center">
              <div className="margin-card-img-deposit col-md-4">
                <img
                  alt=""
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/payment/iux-skrill.png"
                  }
                  className="bank_support"
                />
                <p className="text-center">Skrill</p>
              </div>
              <div className="margin-card-img-deposit col-md-4">
                <img
                  alt=""
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/payment/iux-thai.png"
                  }
                  className="bank_support"
                />
                <p className="text-center">Thai QR Payment</p>
              </div>
              <div className="margin-card-img-deposit col-md-4">
                <img
                  alt=""
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/payment/iux-visa.png"
                  }
                  className="bank_support"
                />
                <p className="text-center">Credit / Debit Card</p>
              </div>
            </div>
            {/* mobile */}
            <div className="d-lg-none d-md-none d-sm-block justify-content-center text-center mt-4">
              <div className="margin-card-img-deposit">
                <img
                  alt=""
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/payment/online-bank.png"
                  }
                  className="bank_support"
                />
                <p className="text-center">Online Bank</p>
              </div>
              <div className="margin-card-img-deposit">
                <img
                  alt=""
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/payment/iux-neteller.png"
                  }
                  className="bank_support"
                />
                <p className="text-center">Neteller</p>
              </div>
              <div className="margin-card-img-deposit">
                <img
                  alt=""
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/payment/iux-paypal.png"
                  }
                  className="bank_support"
                />
                <p className="text-center">Paypal</p>
              </div>
              <div className="margin-card-img-deposit">
                <img
                  alt=""
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/payment/iux-skrill.png"
                  }
                  className="bank_support"
                />
                <p className="text-center">Skrill</p>
              </div>
              <div className="margin-card-img-deposit">
                <img
                  alt=""
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/payment/iux-thai.png"
                  }
                  className="bank_support"
                />
                <p className="text-center">Thai QR Payment</p>
              </div>
              <div className="margin-card-img-deposit">
                <img
                  alt=""
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/payment/iux-visa.png"
                  }
                  className="bank_support"
                />
                <p className="text-center">Credit / Debit Card</p>
              </div>
            </div>
          </section>
          <section
            key={10}
            id="join-us"
            className="text-center coverexample-background"
          >
            <TextWithCoverButton />
          </section>
          <div>
            <FooterLandingPage locale={locale} />
          </div>
        </div>
      </RcQueueAnim>
    );
  }
}

const mapStateToProps = ({ i18n }) => ({
  locale: i18n.locale,
});

export default connect(mapStateToProps, null)(DepositPage);
