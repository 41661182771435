export const styleFontTop = {
  fontSize: 14,
};
export const styleFontCurrent = {
  fontSize: 32,
};
export const styleFontCurrentInt = {
  fontSize: 20,
};

export const styleHeader = {
  backgroundColor: "#171717",
  padding: "16px",
  color: "rgb(167 167 167)",
  fontWeight: "bold",
  fontFamily: "Inter",
  borderTopRightRadius: 6,
  borderTopLeftRadius: 6,
};

export const styleHeaderDashboard = {
  backgroundColor: "#fff",
  padding: "16px",
  color: "#000",
  fontWeight: "bold",
  fontFamily: "Inter",
  borderTopRightRadius: 6,
  borderTopLeftRadius: 6,
};

export const styleBottom = {
  backgroundColor: "#171717",
  padding: "16px 16px 16px 16px",
  color: "rgb(167 167 167)",
  fontWeight: "bold",
  fontFamily: "Inter",
  borderBottomRightRadius: 6,
  borderBottomLeftRadius: 6,
};

export const styleSpreadDashboard = {
  // backgroundColor: "#242424",
  // borderRadius: 16,
  height: 24,
  display: "flex",
  alignItems: "center",
  fontSize: 10,
  padding: "0px 8px",
};
export const styleSpread = {
  backgroundColor: "#242424",
  borderRadius: 16,
  height: 24,
  display: "flex",
  alignItems: "center",
  fontSize: 10,
  padding: "0px 8px",
};
export const styleBuyDashboard = {
  // backgroundColor: "#242424",
  // borderRadius: 16,
  color: "#1CB894",
  fontWeight: "bold",
  width: 40,
  height: 24,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "Inter",
  textTransform: "uppercase",
  fontSize: 10,
  cursor: "pointer",
};
export const styleBuy = {
  backgroundColor: "#242424",
  borderRadius: 16,
  color: "#1CB894",
  fontWeight: "bold",
  width: 40,
  height: 24,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "Inter",
  textTransform: "uppercase",
  fontSize: 10,
  cursor: "pointer",
};
export const styleClose = {
  backgroundColor: "#242424",
  borderRadius: 16,
  height: 24,
  fontSize: 12,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0px 8px",
  width: "100%",
};
export const styleCloseDashboard = {
  // backgroundColor: "#242424",
  // borderRadius: 16,
  height: 24,
  fontSize: 12,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0px 8px",
  width: "100%",
};
export const styleSell = {
  backgroundColor: "#242424",
  borderRadius: 16,
  height: 24,

  color: "#B81C1C",
  fontWeight: "bold",
  width: 40,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "Inter",
  textTransform: "uppercase",
  fontSize: 10,
  cursor: "pointer",
};
export const styleSellDashboard = {
  // backgroundColor: "#242424",
  // borderRadius: 16,
  height: 24,

  color: "#B81C1C",
  fontWeight: "bold",
  width: 40,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "Inter",
  textTransform: "uppercase",
  fontSize: 10,
  cursor: "pointer",
};

export const colorBit = {
  color: "#1CB894",
};

export const colorAsk = {
  color: "#B81C1C",
};

export const styleBackground = {
  backgroundColor: "#171717",
};

export const TextGray = {
  color: "#8D8D8D",
  fontWeight: 400,
};
export const TextSpead = {
  fontWeight: 400,
};

export const styleBitAsk = {
  fontSize: 10,
  fontFamily: "sans-serif",
  fontWeight: 700,
  color: "#363636",
};
