export default {
  th: {
    dashbordCommission: {
      irpoint_total_credit: "ยอดรวมโบนัสทั้งหมด",
      irpoint_total_balance: "ยอดรวมเงินสดที่ได้รับ",
      irpoint_confirm: "ยืนยัน",
      irpoint_cancel: "ยกเลิก",
      irpoint_status: "สถานะ LotBack ปัจจุบัน",
      irpoint_name: "ไม่มีสถานะ",
      irpoint_point: "จำนวนแต้ม LotBack ปัจจุบัน",
      irpoint_credit: "มูลค่าเครดิตโบนัสที่เทียบเท่า",
      irpoint_bonus: "โบนัส",
      irpoint_cash: "มูลค่ารางวัลเงินสดที่เทียบเท่า",
      botton_credit: "แลกในตอนนี้",
      botton_cash: "แลกในตอนนี้",
      worning_title: "แจ้งเตือน ไม่มีการใช้งาน!",
      worning_textcontent:
        "ใน -1 วัน LotBack ของท่านจะหมดอายุและสถานะของท่านจะถูกรีเซ็ตเป็น",
      about_FAQ: "คำถามที่พบบ่อยเกี่ยวกับโปรแกรม LotBack",
      howdo_1: "ฉันจะได้รับสถานะ LotBack ได้อย่างไร?",
      howdo_2: "ฉันจะสูญเสียสถานะนี้หรือไม่?",
      howdo_3: "LotBack มีค่าอย่างไร?",
      howdo_4: "สถานะ LotBackของฉันจะถูกอัพเกรดได้อย่างไร?",
      howdo_5: "LotBack คืออะไร?",
      howdo_6: "ฉันจะแลก LotBack เป็นโบนัสได้อย่างไร?",
      howdo_7: "สถานะของ LotBackแตกต่างกันอย่างไร?",
      howdo_8: "ฉันจะได้รับ LotBack ได้อย่างไร?",
      howdo_9: "มีข้อจำกัดใดๆ หรือไม่?",
      irpoint_changeicon: "แลกแต้ม LotBack",
      irpoint_change: "LotBack to แลกโบนัส",
      irpoint_change_cash: "LotBack to แลกบาลานซ์",
      irpoint_change_discription_credit:
        'โปรดใส่จำนวน LotBack (คะแนน LotBack) ที่ท่านต้องการแลกเป็นเครดิตโบนัสและคลิก"ยืนยัน" ',
      irpoint_change_discription_cash:
        'โปรดใส่จำนวน LotBack (คะแนน LotBack) ที่ท่านต้องการแลกเป็นบาลานซ์และคลิก"ยืนยัน" ',
      irpoint_current: "LotBack ที่มี (คะแนน LotBack):",
      irpoint_bonus_ofchange: "โบนัสสูงสุดที่มี:",
      irpoint_cash_ofchange: "บาลานซ์สูงสุดที่มี:",
      irpoint_reject: "จำนวน LotBack ของคุณไม่เพียงพอ",
      irpoint_more: "จำนวน LotBack ควรมากกว่า",
      irpoint_account_to: "ไปยังบัญชี",
    },
  },
  lo: {
    dashbordCommission: {
      irpoint_total_credit: "ເງິນຈໍານວນທັງຫມົດທີ່ໄດ້ຮັບ",
      irpoint_total_balance: "ຍອດເງິນທັງຫມົດທີ່ໄດ້ຮັບ",
      irpoint_confirm: "ຢືນຢັນ",
      irpoint_cancel: "ຍົກເລີກ",
      irpoint_status: "ສະຖານະ LotBack",
      irpoint_name: "ບໍ່ມີສະຖານະ",
      irpoint_point: "ຍອດຄະແນນໃນປະຈຸບັນ",
      irpoint_credit: "ມູນຄ່າໂບນັດສິນເຊື່ອທຽບເທົ່າ",
      irpoint_bonus: "ໂບນັດ",
      irpoint_cash: "ມູນຄ່າການຕອບແທນເງິນສົດ",
      botton_credit: "ຮັບໂບນັດດຽວນີ້",
      botton_cash: "ຮັບໂບນັດດຽວນີ້",
      worning_title: "ແຈ້ງເຕືອນ ບໍ່ມີການປະຕິບັດງານ!",
      worning_textcontent:
        "ໃນ -1 ວັນ Lotback ຂອງທ່ານຈະ ໝົດ ອາຍຸແລະສະຖານະພາບຂອງທ່ານຈະຖືກຕັ້ງຄ່າ ໃໝ່",
      about_FAQ: "ຄຳ ຖາມກ່ຽວກັບ Program Lotback",
      howdo_1: "ຂ້ອຍຈະໄດ້ຮັບການຊົດເຊີຍແນວໃດ?",
      howdo_2: "ມັນເປັນໄປໄດ້ທີ່ຈະສູນເສຍສະຖານະພາບ??",
      howdo_3: "ມູນຄ່າ Lotback ແມ່ນຫຍັງ?",
      howdo_4: "ສະຖານະພາບຜົນຕອບແທນຂອງຂ້ອຍຖືກຍົກລະດັບແນວໃດ?",
      howdo_5: "LotBack ແມ່ນຫຍັງ??",
      howdo_6: "ຂ້ອຍຈະເອົາເງິນ LotBack ເປັນເງິນແນວໃດ?",
      howdo_7: "ຄວາມແຕກຕ່າງລະຫວ່າງສະຖານະພາບຄວາມຈົງຮັກພັກດີແມ່ນຫຍັງ?",
      howdo_8: "ຂ້ອຍຈະໄດ້ຮັບ LotBack ໄດ້ແນວໃດ?",
      howdo_9: "ມີຂໍ້ ຈຳ ກັດບໍ່?",
      irpoint_changeicon: "ປ່ຽນ LotBack",
      irpoint_change: "LotBack ໄປ ໂບນັດ",
      irpoint_change_cash: "LotBack to ຍອດເງິນ",
      irpoint_change_discription_credit:
        'ກະລຸນາໃສ່ ຈຳ ນວນ LotBack ທີ່ທ່ານຕ້ອງການຈະແລກປ່ຽນກັບ. ແລະກົດ "ຢືນຢັນ"',
      irpoint_change_discription_cash:
        'ກະລຸນາໃສ່ ຈຳ ນວນ LotBack ທີ່ທ່ານຕ້ອງການຈະແລກປ່ຽນກັບ. ແລະກົດ "ຢືນຢັນ" ',
      irpoint_current: "LotBack ຂອງທ່ານ (ຄະແນນ LotBack):",
      irpoint_bonus_ofchange: "ໂບນັດຂອງທ່ານ:",
      irpoint_cash_ofchange: "ຍອດເງິນຂອງທ່ານ:",
      irpoint_reject: "LotBack ຂອງທ່ານແມ່ນບໍ່ພຽງພໍ.",
      irpoint_more: "ຈໍານວນ LotBack ຄວນຫຼາຍກວ່າ",
      irpoint_account_to: "ບັນຊີເພື່ອ",
    },
  },
  en: {
    dashbordCommission: {
      irpoint_total_credit: "Total Bonus Received",
      irpoint_total_balance: "Total Balance Received",
      irpoint_confirm: "Confirm",
      irpoint_cancel: "Cancel",
      irpoint_status: "Current LotBack Status",
      irpoint_name: "No Status",
      irpoint_point: "Current LotBack",
      irpoint_credit: "Equivalent Credit Bonus Value",
      irpoint_bonus: "Bonus",
      irpoint_cash: "Equivalent Cash Reward Value",
      botton_credit: "GET BONUS NOW",
      botton_cash: "GET CASH NOW",
      worning_title: "Inactivity Alert!",
      worning_textcontent:
        "In -1 days your LotBack will expire and your status will be reset to",
      about_FAQ: "LotBack Program FAQ",
      howdo_1: "How do I receive LotBack status?",
      howdo_2: "Is it possible to lose status?",
      howdo_3: "What is LotBack worth?",
      howdo_4: "How is my LotBack status upgraded?",
      howdo_5: "What is LotBack?",
      howdo_6: "How do I redeem LotBack as a bonus?",
      howdo_7: "What is the difference between LotBack statuses?",
      howdo_8: "How do I gain LotBack?",
      howdo_9: "Are there any limitations?",
      irpoint_changeicon: "Change LotBack",
      irpoint_change: "LotBack to Bonus",
      irpoint_change_cash: "LotBack to Balance",
      irpoint_change_discription_credit:
        "Please enter the LotBack amount you wish to exchange.",
      irpoint_change_discription_cash:
        "Please enter the LotBack amount you wish to exchange.",
      irpoint_current: "Total LotBack : ",
      irpoint_bonus_ofchange: "Your Bonus:",
      irpoint_cash_ofchange: "Your Balance:",
      irpoint_reject: "Your LotBack is not enough.",
      irpoint_more: "LotBack should be more than ",
      irpoint_account_to: "To Account",
    },
  },
  hi: {
    dashbordCommission: {
      irpoint_total_credit: "कुल बोनस प्राप्त हुआ",
      irpoint_total_balance: "कुल शेष प्राप्त हुआ",
      irpoint_confirm: "पुष्टि करना",
      irpoint_cancel: "रद्द करना",
      irpoint_status: "वर्तमान LotBack स्थिति",
      irpoint_name: "कोई स्थिति नहीं ",
      irpoint_point: "वर्तमान LotBack ",
      irpoint_credit: "समतुल्य नकद पुरस्कार मूल्य",
      irpoint_bonus: "बक्शीश",
      irpoint_cash: "समतुल्य नकद पुरस्कार मूल्य ",
      botton_credit: "अभी बोनस प्राप्त करें",
      botton_cash: "अभी बोनस प्राप्त करें",
      worning_title: "Inactivity Alert!",
      worning_textcontent:
        "In -1 days your LotBack will expire and your status will be reset to",
      about_FAQ: "LotBack Program FAQ",
      howdo_1: "How do I receive LotBack status?",
      howdo_2: "Is it possible to lose status?",
      howdo_3: "What is LotBack worth?",
      howdo_4: "How is my LotBack status upgraded?",
      howdo_5: "What is LotBack?",
      howdo_6: "How do I redeem LotBack as a bonus?",
      howdo_7: "What is the difference between LotBack statuses?",
      howdo_8: "How do I gain LotBack?",
      howdo_9: "Are there any limitations?",
      irpoint_changeicon: "Change LotBack",
      irpoint_change: "LotBack बोनस के लिए",
      irpoint_change_cash: "LotBack to Balance",
      irpoint_change_discription_credit:
        "Please enter the LotBack amount you wish to exchange.",
      irpoint_change_discription_cash:
        "Please enter the LotBack amount you wish to exchange.",
      irpoint_current: "Total LotBack : ",
      irpoint_bonus_ofchange: "Your Bonus:",
      irpoint_cash_ofchange: "Your Balance:",
      irpoint_reject: "Your LotBack is not enough.",
      irpoint_more: "LotBack should be more than ",
      irpoint_account_to: "खाते में",
    },
  },
  ms: {
    dashbordCommission: {
      irpoint_total_credit: "Jumlah bonus diterima",
      irpoint_total_balance: "Jumlah baki yang diterima",
      irpoint_confirm: `Sahkan`,
      irpoint_cancel: `Batal`,
      irpoint_status: `Status LotBack Semasa`,
      irpoint_name: `Tiada status`,
      irpoint_point: `Skor LotBack Semasa`,
      irpoint_credit: `Nilai bonus kredit yang setara`,
      irpoint_bonus: `Bonus`,
      irpoint_cash: `Nilai hadiah wang tunai yang setara`,
      botton_credit: `DAPATKAN BONUS SEKARANG`,
      botton_cash: `DAPATKAN WANG TUNAI SEKARANG`,
      worning_title: `Makluman Ketidakaktifan!`,
      worning_textcontent: `Dalam -1 hari LotBack anda akan tamat dan status anda akan ditetapkan semula kepada`,
      about_FAQ: `Soalan Lazim Mengenai Program LotBack`,
      howdo_1: `Bagaimanakah saya boleh mendapatkan status LotBack saya?`,
      howdo_2: `Adakah mungkin untuk kehilangan status?`,
      howdo_3: `Bagaimana LotBack bernilai?`,
      howdo_4: `Bagaimanakah status LotBack saya dinaik taraf?`,
      howdo_5: `Apa itu LotBack?`,
      howdo_6: `Bagaimanakah saya boleh menebus bonus LotBack saya?`,
      howdo_7: `Bagaimanakah status LotBack berbeza?`,
      howdo_8: `Bagaimana saya mendapat LotBack?`,
      howdo_9: `Adakah terdapat sebarang batasan?`,
      irpoint_changeicon: `Tebus LotBack`,
      irpoint_change: `LotBack to Bonus`,
      irpoint_change_cash: `LotBack to Baki`,
      irpoint_change_discription_credit: `Sila masukkan jumlah LotBack yang anda ingin tebus kepada bonus kredit dan klik "confirm"`,
      irpoint_change_discription_cash: `Sila masukkan jumlah LotBack yang anda ingin tebus kepada jumlah baki dan klik "confirm"`,
      irpoint_current: `LotBack anda (Poin IUX )`,
      irpoint_bonus_ofchange: `Bonus anda`,
      irpoint_cash_ofchange: `Baki anda`,
      irpoint_reject: `LotBack anda tidak cukup.`,
      irpoint_more: "LotBack mestilah lebih daripada ",
      irpoint_account_to: `Pergi Ke`,
    },
  },
  id: {
    dashbordCommission: {
      irpoint_total_credit: "Total bonus yang diterima",
      irpoint_total_balance: "Total saldo yang diterima",
      irpoint_confirm: `Konfirmasi`,
      irpoint_cancel: `Membatalkan`,
      irpoint_status: `Saat Status Loyalitas`,
      irpoint_name: `Tidak ada status`,
      irpoint_point: `IUX saat hai Balance`,
      irpoint_credit: `Setara Nilai Bonus Kredit`,
      irpoint_bonus: `Bonus`,
      irpoint_cash: `Setara Kas Nilai Reward`,
      botton_credit: `DAPATKAN BONUS SEKARANG`,
      botton_cash: `DAPATKAN CASH SEKARANG`,
      worning_title: `Ketidakaktifan Waspada!`,
      worning_textcontent: `Dalam -1 hari LotBack Anda akan berakhir dan status Anda akan diatur ulang ke`,
      about_FAQ: `IR LotBack Program FAQ`,
      howdo_1: `Bagaimana saya menerima status loyalitas?`,
      howdo_2: `Apakah mungkin untuk kehilangan status?`,
      howdo_3: `Apa LotBack layak?`,
      howdo_4: `Bagaimana status loyalitas saya upgrade?`,
      howdo_5: `Apa LotBack?`,
      howdo_6: `Bagaimana cara menebus LotBack sebagai bonus?`,
      howdo_7: `Apa perbedaan antara status loyalitas?`,
      howdo_8: `Bagaimana cara mendapatkan LotBack?`,
      howdo_9: `Apakah ada batasan?`,
      irpoint_changeicon: `perubahan LotBack`,
      irpoint_change: `LotBack to Bonus`,
      irpoint_change_cash: `LotBack to Balance`,
      irpoint_change_discription_credit: `Silahkan masukkan jumlah LotBack Anda ingin tukar ke. Dan klik "confirm"`,
      irpoint_change_discription_cash: `Silahkan masukkan jumlah LotBack Anda ingin tukar ke. Dan klik "confirm"`,
      irpoint_current: `LotBack Anda (IUX Point)`,
      irpoint_bonus_ofchange: `Bonus Anda`,
      irpoint_cash_ofchange: `Keseimbanganmu`,
      irpoint_reject: `LotBack Anda tidak cukup.`,
      irpoint_more: "LotBack harus lebih dari 0,02",
      irpoint_account_to: `akun Untuk`,
    },
  },
  zh: {
    dashbordCommission: {
      irpoint_total_credit: "總獎金收到了",
      irpoint_total_balance: "收到的總餘額",
      irpoint_confirm: `确认`,
      irpoint_cancel: `取消`,
      irpoint_status: `当前的忠诚度状态`,
      irpoint_name: `无状态`,
      irpoint_point: `当前IUX积分余额`,
      irpoint_credit: `等效信用红利价值`,
      irpoint_bonus: `奖金`,
      irpoint_cash: `等值现金奖励价值`,
      botton_credit: `获得奖励NOW`,
      botton_cash: `获得现金NOW`,
      worning_title: `闲置警报！`,
      worning_textcontent: `在-1天，您的LotBack将到期，您的状态将被重置为`,
      about_FAQ: `IR忠诚度计划常见问题解答`,
      howdo_1: `我如何收到忠诚的地位？`,
      howdo_2: `是否有可能失去状态？`,
      howdo_3: `什么是值得LotBack？`,
      howdo_4: `我的忠诚度状态如何升级？`,
      howdo_5: `什么是LotBack？`,
      howdo_6: `如何兑换LotBack作为奖金？`,
      howdo_7: `是什么忠诚度状态之间的区别？`,
      howdo_8: `如何获得LotBack？`,
      howdo_9: `有什么限制？`,
      irpoint_changeicon: `更改LotBack`,
      irpoint_change: `LotBack  - >奖金`,
      irpoint_change_cash: `LotBack  - >平衡`,
      irpoint_change_discription_credit: `请输入您想交换到LotBack量。并点击“确定”`,
      irpoint_change_discription_cash: `请输入您想交换到LotBack量。并点击“确定”`,
      irpoint_current: `您的LotBack（IUX点）`,
      irpoint_bonus_ofchange: `您的奖金`,
      irpoint_cash_ofchange: `您的余额`,
      irpoint_reject: `您的LotBack是不够的。`,
      irpoint_more: "LotBack 必須大於 ",
      irpoint_account_to: `帐户要`,
    },
  },
  vi: {
    dashbordCommission: {
      irpoint_total_credit: "Tổng số tiền thưởng nhận được",
      irpoint_total_balance: "Tổng số dư nhận được",
      irpoint_confirm: "Xác nhận",
      irpoint_cancel: "Hủy bỏ",
      irpoint_status: "Trạng thái LotBack hiện tại",
      irpoint_name: "Không có trạng thái",
      irpoint_point: "Số điểm LotBack hiện tại",
      irpoint_credit: "Giá trị tiền thưởng tín dụng tương đương",
      irpoint_bonus: "tiền thưởng",
      irpoint_cash: "Giá trị giải thưởng tiền mặt tương đương",
      botton_credit: "Đổi ngay bây giờ ",
      botton_cash: "Đổi ngay bây giờ",
      worning_title: "Thông báo không hoạt động!",
      worning_textcontent:
        "Trong-1 ngày LotBack của bạn sẽ hết hạn và trạng thái của bạn sẽ được đặt lại thành.",
      about_FAQ: "Câu hỏi thường gặp về chương trình LotBack",
      howdo_1: "Tôi sẽ được nhận trạng thái LotBack thế nào?",
      howdo_2: "Tôi sẽ bị mất trạng thái này hay không?",
      howdo_3: "LotBack có giá trị thế nào?",
      howdo_4: "Trạng thái LotBack Của tôi sẽ được nâng cấp như thế nào?",
      howdo_5: "LotBack là gì?",
      howdo_6: "Tôi sẽ đổi LotBack là tiền thưởng thế nào?",
      howdo_7: "Trạng Thái  LotBack khác nhau thế nào?",
      howdo_8: "Tôi sẽ được nhận LotBack thế nào?",
      howdo_9: "Có bất kỳ hạn chế nào không?",
      irpoint_changeicon: "Đổi điểm LotBack",
      irpoint_change: "LotBack to đổi tiền thưởng",
      irpoint_change_cash: "LotBack to đổi Balance",
      irpoint_change_discription_credit:
        'Vui lòng nhập số LotBack (điểm LotBack) bạn muốn trao đổi tín dụng tiền thưởng và nhấn nút "xác nhận"',
      irpoint_change_discription_cash: `Vui lòng nhập số LotBack (điểm LotBack) ',bạn muốn trao đổi Balance và nhấn nút "xác nhận`,
      irpoint_current: "LotBack có (điểm LotBack):",
      irpoint_bonus_ofchange: "Tiền thưởng tối đa có sẵn:",
      irpoint_cash_ofchange: "Balance tối đa có sẵn::",
      irpoint_reject: "số lượng LotBack của bạn không đủ",
      irpoint_more: "LotBack phải lớn hơn 0,02",
      irpoint_account_to: "Sang đế",
    },
  },

  ko: {
    dashbordCommission: {
      irpoint_total_credit: "받은 총 보너스",
      irpoint_total_balance: "받은 총 잔액",
      irpoint_confirm: `확인`,
      irpoint_cancel: `취소`,
      irpoint_status: `현재 로열티 상태`,
      irpoint_name: `상태 없음`,
      irpoint_point: `현재 IUX는 밸런스 포인트`,
      irpoint_credit: `동등한 신용 보너스 값`,
      irpoint_bonus: `보너스`,
      irpoint_cash: `상응하는 현금 보상 값`,
      botton_credit: `지금 보너스를받을`,
      botton_cash: `현금 NOW를 GET`,
      worning_title: `비활성 경보!`,
      worning_textcontent: `-1 일이 LotBack는 만료와 상태로 재설정됩니다`,
      about_FAQ: `IR의 로열티 프로그램 자주 묻는 질문`,
      howdo_1: `어떻게 충성도 상태를받을 수 있습니까?`,
      howdo_2: `그것은 자격을 상실 할 수 있습니까?`,
      howdo_3: `LotBack의 가치는 무엇인가?`,
      howdo_4: `내 충성 상태는 어떻게 업그레이드?`,
      howdo_5: `LotBack는 무엇입니까?`,
      howdo_6: `어떻게 보너스로 LotBack를 사용합니까?`,
      howdo_7: `충성도 상태의 차이점은 무엇입니까?`,
      howdo_8: `어떻게 LotBack를 얻을 수 있습니까?`,
      howdo_9: `제한 사항이 있습니까?`,
      irpoint_changeicon: `변경 LotBack`,
      irpoint_change: `LotBack to 보너스`,
      irpoint_change_cash: `LotBack to 밸런스`,
      irpoint_change_discription_credit: `당신을 교환하고자하는 LotBack 금액을 입력하십시오. 그리고 "확인"을 클릭합니다`,
      irpoint_change_discription_cash: `당신을 교환하고자하는 LotBack 금액을 입력하십시오. 그리고 "확인"을 클릭합니다`,
      irpoint_current: `귀하의 LotBack (IUX 포인트)`,
      irpoint_bonus_ofchange: `귀하의 보너스`,
      irpoint_cash_ofchange: `귀하의 균형`,
      irpoint_reject: `귀하의 LotBack는 충분하지 않습니다.`,
      irpoint_more: "LotBack은 보다 커야 합니다.",
      irpoint_account_to: `계정에`,
    },
  },
  fil: {
    dashbordCommission: {
      irpoint_total_credit: "Natanggap ang kabuuang bonus",
      irpoint_total_balance: "Kabuuang natanggap na balanse",
      irpoint_confirm: `Kumpirmahin`,
      irpoint_cancel: `kanselahin`,
      irpoint_status: `Kasalukuyang Katayuan Katapatan`,
      irpoint_name: `Walang estado`,
      irpoint_point: `Kasalukuyang IUX Puntos Balanse`,
      irpoint_credit: `Katumbas Credit Bonus Halaga`,
      irpoint_bonus: `Bonus`,
      irpoint_cash: `Katumbas Cash Gantimpala Halaga`,
      botton_credit: `GET BONUS NGAYON`,
      botton_cash: `GET CASH NGAYON`,
      worning_title: `Inactivity Alert!`,
      worning_textcontent: `Sa -1 araw na ang iyong LotBack ay mawawalan ng bisa at ang katayuan ng mare-reset sa`,
      about_FAQ: `IR Programa ng Katapatan FAQ`,
      howdo_1: `Paano ako makatanggap ng katayuan ng katapatan?`,
      howdo_2: `Posible bang mawala ang status?`,
      howdo_3: `Ano ang LotBack nagkakahalaga ng?`,
      howdo_4: `Paano aking katayuan ng katapatan upgrade na ay?`,
      howdo_5: `Ano ang LotBack?`,
      howdo_6: `Paano ko kukunin ang LotBack bilang bonus?`,
      howdo_7: `Ano ang pagkakaiba sa pagitan ng LotBack status?`,
      howdo_8: `Paano ako makakuha ng LotBack?`,
      howdo_9: `Mayroon bang anumang mga limitasyon?`,
      irpoint_changeicon: `Baguhin ang LotBack`,
      irpoint_change: `LotBack sa bonus`,
      irpoint_change_cash: `LotBack to Balanse`,
      irpoint_change_discription_credit: `Mangyaring ipasok ang halaga LotBack nais mong palitan sa. At i-click "kumpirmahin"`,
      irpoint_change_discription_cash: `Mangyaring ipasok ang halaga LotBack nais mong palitan sa. At i-click "kumpirmahin"`,
      irpoint_current: `Ang iyong LotBack (IUX Point)`,
      irpoint_bonus_ofchange: `Ang iyong Bonus`,
      irpoint_cash_ofchange: `Iyong balanse`,
      irpoint_reject: `Ang iyong LotBack ay hindi sapat.`,
      irpoint_more: "Ang LotBack ay dapat na higit sa ",
      irpoint_account_to: `Upang account`,
    },
  },
  ja: {
    dashbordCommission: {
      irpoint_total_credit: "合計ボーナスを受け取ります",
      irpoint_total_balance: "受け取った合計残高",
      irpoint_confirm: "確認する",
      irpoint_cancel: "キャンセル",
      irpoint_status: "現在のロットバックステータス",
      irpoint_name: "ステタスはありません",
      irpoint_point: "現在のロットバック",
      irpoint_credit: "同等のクレジットボーナス価値",
      irpoint_bonus: "ボーナス",
      irpoint_cash: "同等の現金報酬価値",
      botton_credit: "今すぐボーナスを取得してください",
      botton_cash: "今すぐ現金を手に入れましょう",
      worning_title: "非アクティブアラート！",
      worning_textcontent:
        "-1日であなたのロットバックは期限切れになり、あなたのステータスはにリセットされます",
      about_FAQ: "ロットバックプログラムFAQ",
      howdo_1: "ロットバックのステータスを受け取るにはどうすればよいですか？",
      howdo_2: "ステータスを失うことは可能ですか？",
      howdo_3: "ロットバックの価値は何ですか？",
      howdo_4:
        "私のロットバックのステータスはどのようにアップグレードされていますか？",
      howdo_5: "ロットバックとは何ですか？",
      howdo_6:
        "ロットバックをボーナスとして引き換えるにはどうすればよいですか？",
      howdo_7: "ロットバックのステータスの違いは何ですか？",
      howdo_8: "ロットバックを獲得するにはどうすればよいですか？",
      howdo_9: "制限はありますか？",
      irpoint_changeicon: "ロットバックを変更します",
      irpoint_change: "ボーナスへのロットバック",
      irpoint_change_cash: "バランスをとるロットバック",
      irpoint_change_discription_credit:
        "交換したいロットバックの金額を入力してください。",
      irpoint_change_discription_cash:
        "交換したいロットバックの金額を入力してください。",
      irpoint_current: "トータルロットバック：",
      irpoint_bonus_ofchange: "あなたのボーナス：",
      irpoint_cash_ofchange: "あなたのバランス：",
      irpoint_reject: "あなたのロットバックは十分ではありません。",
      irpoint_more: "ロットバックはそうでなければなりません",
      irpoint_account_to: "アカウントへ",
    },
  },
  es: {
    dashbordCommission: {
      irpoint_total_credit: "Total de Bonos Recibidos",
      irpoint_total_balance: "Total de Saldo Recibido",
      irpoint_confirm: "Confirmar",
      irpoint_cancel: "Cancelar",
      irpoint_status: "Estado Actual de LotBack",
      irpoint_name: "Sin Estado",
      irpoint_point: "LotBack Actual",
      irpoint_credit: "Valor de Crédito de Bono Equivalente",
      irpoint_bonus: "Bono",
      irpoint_cash: "Valor de Recompensa en Efectivo Equivalente",
      botton_credit: "OBTENER BONO AHORA",
      botton_cash: "OBTENER EFECTIVO AHORA",
      worning_title: "¡Alerta de Inactividad!",
      worning_textcontent:
        "En -1 días tu LotBack expirará y tu estado será restablecido a",
      about_FAQ: "Preguntas Frecuentes del Programa LotBack",
      howdo_1: "¿Cómo recibo el estado de LotBack?",
      howdo_2: "¿Es posible perder el estado?",
      howdo_3: "¿Cuál es el valor de LotBack?",
      howdo_4: "¿Cómo se mejora mi estado de LotBack?",
      howdo_5: "¿Qué es LotBack?",
      howdo_6: "¿Cómo canjeo LotBack como bono?",
      howdo_7: "¿Cuál es la diferencia entre los estados de LotBack?",
      howdo_8: "¿Cómo obtengo LotBack?",
      howdo_9: "¿Hay alguna limitación?",
      irpoint_changeicon: "Cambiar LotBack",
      irpoint_change: "LotBack a Bono",
      irpoint_change_cash: "LotBack a Saldo",
      irpoint_change_discription_credit:
        "Por favor, ingresa la cantidad de LotBack que deseas cambiar.",
      irpoint_change_discription_cash:
        "Por favor, ingresa la cantidad de LotBack que deseas cambiar.",
      irpoint_current: "Total de LotBack: ",
      irpoint_bonus_ofchange: "Tu Bono:",
      irpoint_cash_ofchange: "Tu Saldo:",
      irpoint_reject: "Tu LotBack no es suficiente.",
      irpoint_more: "LotBack debe ser mayor que ",
      irpoint_account_to: "A la Cuenta",
    },
  },
};
