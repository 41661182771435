import React, { useEffect, useState } from "react";
import { Empty, Layout, message } from "antd";
import { StyledAccountMain, SubStyledAccountWallet } from "../../components";
import { get } from "../../helper/request";
import {
  restoreAccount,
  updateAccount,
  getUser,
  getArchive,
} from "../../actions";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import VerifyUser from "./fund/verifies/VerifyUser";
import ArchiveCard from "./ArchiveCard";
import { renderApiDomain } from "../../helper/renderApiDomain";
const locale = require("react-redux-i18n").I18n;

const ArchiveAccount = (props) => {
  const isPlatform = props.selectPlatform.toUpperCase();
  const isMobile = window.innerWidth < 800;
  const dispatch = useDispatch();
  const [accounts, setAccounts] = useState(props.user.archive);
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    if (accounts !== props.user.archive) setAccounts(props.user.archive);
    return;
  });

  const onRestore = (acc) => {
    get(renderApiDomain() + `user/account/restore/${acc.id}`)
      .then(() => {
        props.restoreAccount(accounts, acc);
        dispatch(getUser());
        dispatch(getArchive());
        message.success(
          `Account ${acc.account_number}  has been archived Successfully`
        );
      })
      .catch(({ data }) => {
        message.error("Too many requests in this time frame.");
      });
  };

  return (
    <Layout className="max-width-layout">
      <SubStyledAccountWallet>
        {props.user.userVerify.status === "accepted" ? (
          accounts !== undefined ? (
            accounts.filter((account) => account.type === "real").length > 0 ? (
              <div
                className="row"
                style={{
                  margin: "12px",
                  display: isMobile ? (mobile ? "none" : "") : "",
                }}
              >
                {accounts
                  .filter(
                    (account) =>
                      account.type === "real" && account.platform === "MT5"
                  )
                  .map((account, index) => (
                    <div key={index} className="col-md minandmax-width">
                      <ArchiveCard
                        account={account}
                        onRestore={onRestore}
                        key={index}
                      />
                    </div>
                  ))}
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center flex-column pt-5 pb-5">
                <img
                  src="/assets/images/icons/none-account.svg"
                  alt="tranfer-icons"
                />
                <p className="mb-4 mt-2">
                  <div className="d-flex p-2">
                    <div className="m-auto">
                      {locale.t("dashboardWallet.no_archive_account")}
                    </div>
                  </div>
                </p>
              </div>
            )
          ) : (
            <div
              className="d-flex align-items-center justify-content-center text-lightgray"
              style={{ height: 350 }}
            >
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>{locale.t("nodata")}</span>}
              />
              {/* {locale.t("nodata")} */}
            </div>
            // <p style={{ color: "red", padding: "12px" }}>
            //   {locale.t("dashboardWallet.notAccount")}
            // </p>
          )
        ) : (
          <VerifyUser />
        )}
      </SubStyledAccountWallet>
    </Layout>
  );
};

const mapStateToProps = ({ user, i18n }) => {
  return { user, locale: i18n.locale };
};

export default connect(mapStateToProps, {
  restoreAccount,
  updateAccount,
})(ArchiveAccount);
