import { Modal, Upload, message } from "antd";
import React, { useRef, useState } from "react";
import { Scanner } from "@yudiel/react-qr-scanner";
import jsQR from "jsqr";
import { ClipLoader } from "react-spinners";

const locale = require("react-redux-i18n").I18n;

function ModalScanQr({
  open,
  handleOk,
  handleCancel,
  onResult,
  checkCodeFeeCrypto,
}) {
  const [openScan, setOpenScan] = useState(false);
  const reader = useRef(null);
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const uploadOption = {
    beforeUpload: async (file) => {
      const isPNG = file.type === "image/png";
      const isJPG = file.type === "image/jpg";
      const isJPEG = file.type === "image/jpeg";

      const isLt2M = file.size / 1024 / 1024 < 8;
      if (!isPNG && !isJPG && !isJPEG) {
        // message.error(`${file.name} is not an acceptable file type.`);
      } else if (!isLt2M) {
        message.error("Your file size exceeds 8MB");
      } else {
        handleImageUpload(file);
      }

      return false;
    },
  };
  function handleImageUpload(event) {
    const file = event;
    // const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const img = new Image();
        img.onload = function () {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.width = img.width;
          canvas.height = img.height;
          context.drawImage(img, 0, 0, img.width, img.height);
          const imageData = context.getImageData(
            0,
            0,
            canvas.width,
            canvas.height
          );
          const qrCode = jsQR(
            imageData.data,
            imageData.width,
            imageData.height
          );
          if (qrCode) {
            message.success("Success");
            onResult(qrCode.data);
            setOpenScan(false);
            // console.log("QR Code data:", qrCode.data);
            // Handle the QR code content (qrCode.data)
          } else {
            message.error("Invalid QR Code ");
            console.log("No QR code found");
          }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  return (
    <Modal
      title={<div className="text-center">Select Option</div>}
      open={open}
      onOk={false}
      footer={null}
      //   onOk={handleOk}
      onCancel={async () => {
        await setOpenScan(false);
        handleCancel();
      }}
    >
      {" "}
      {isLoading ? (
        <div
          className="h-100 d-flex justify-content-center align-items-center flex-column my-5"
          style={{ height: 500 }}
        >
          <ClipLoader
            color="#36d7b7"
            size={72}
            cssOverride={{ borderWidth: 8 }}
          />
        </div>
      ) : (
        <>
          {!openScan && (
            <div className="d-flex justify-content-between my-5 px-5 mx-4">
              <div className="text-center d-flex flex-column align-items-center">
                <div
                  id="start-scan"
                  className="iux-scan-id cursor-pointer"
                  onClick={() => setOpenScan(true)}
                >
                  <img src="/assets/images/verify/camera.svg" alt="camera" />
                </div>
                <div className="mt-4 text-gray">Scan QR</div>
              </div>{" "}
              <div className="d-flex flex-column align-items-center">
                <Upload
                  {...uploadOption}
                  id="image-file"
                  className=" cursor-pointer"
                  showUploadList={false}
                >
                  <div className="iux-scan-id">
                    <img
                      src="/assets/images/verify/uploadfile.svg"
                      alt="uploadfile"
                      width={32}
                    />
                  </div>
                </Upload>
                <div className="mt-4 text-gray"> {locale.t("uploadfile")}</div>
              </div>
            </div>
          )}
          {openScan && (
            <Scanner
              onResult={async (text, result) => {
                setOpenScan(false);
                setIsLoading(true);
                let check = await checkCodeFeeCrypto(null, text);

                // console.log(await checkCodeFeeCrypto(null, text));
                if (check) {
                  message.success("Success");
                  setIsLoading(false);
                  onResult(text);
                } else {
                  setIsLoading(false);
                  setOpenScan(false);
                  message.error(`Invalid QR Code`);
                }
              }}
              onError={(error) => console.log(error?.message)}
              enabled={openScan}
              onDecode={(decode) => console.log(decode)}
            />
          )}
        </>
      )}
      {/* <div className="d-flex flex-column align-items-center">
        <Upload
          {...uploadOption}
          id="image-file"
          className=" cursor-pointer"
          showUploadList={false}
        >
          <div className="iux-scan-id">
            <img
              src="/assets/images/verify/uploadfile.svg"
              alt="uploadfile"
              width={32}
            />
          </div>
        </Upload>
        <div className="mt-4 text-gray"> {locale.t("uploadfile")}</div>
      </div>
      <div
        className="text-center"
        style={{ cursor: "pointer" }}
        // onClick={handleCancel}
      >
        <input type="file" accept="image/*" onChange={handleImageUpload} />
      </div> */}
    </Modal>
  );
}

export default ModalScanQr;
