import { renderApiDomain } from "../helper/renderApiDomain";
import { get, post } from "../helper/request";

export const partnerlist = async () => {
  return get(`${renderApiDomain()}partner/list`);
};

export const partnerMove = async (data) => {
  return post(`${renderApiDomain()}partner/move`, data);
};
