import { Layout } from "antd";
import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Link, Route, Switch } from "react-router-dom";
import Economic from "../Economic";
import Report from "./Report/Report";
import Holiday from "./Holiday/Holiday";
const locale = require("react-redux-i18n").I18n;
const MenuItem = styled.div`
  padding: 0.8rem 1rem;
  color: ${(props) => props.color};
  border-bottom: ${(props) => props.borderBottom};
  white-space: pre;
  font-weight: ${(props) => props.fontWeight};
`;
function Analyze() {
  const pathName = window.location.pathname;

  const menu = [
    {
      id: 0,
      link: `/${localStorage.getItem("locale")}/wallet/analytics/report`,
      text: locale.t("report"),
    },
    {
      id: 1,
      link: `/${localStorage.getItem("locale")}/wallet/analytics/economic`,
      text: locale.t("calendar_title"),
    },
    {
      id: 2,
      link: `/${localStorage.getItem("locale")}/wallet/analytics/holiday`,
      text: locale.t("holiday_calendar"),
    },
  ];
  return (
    <div>
      {" "}
      <Layout
        style={{
          padding: "24px 0px",
          position: "relative",
          maxWidth: "1160px",
          overflow: "hidden",
        }}
      >
        <Helmet>
          <title> {locale.t("analytics")}</title>
        </Helmet>
        <div className="bg-white" style={{ borderRadius: 20 }}>
          <div className="d-flex justify-content-between align-items-end  p-4">
            <p className="text-header-account-wallet text-left w-100">
              {locale.t("analytics")}
            </p>
          </div>
          <div
            className="sub-menu-page-user mt-4"
            style={{ borderBottom: "1px solid #D9D9D9" }}
          >
            <div className="d-flex overflow-auto scroll-menu-hide px-3">
              {menu.map((e, i) => (
                <Link to={e.link} key={i}>
                  <MenuItem
                    color={
                      e.link === pathName
                        ? "#000"
                        : pathName ===
                            `/${localStorage.getItem(
                              "locale"
                            )}/wallet/analytics` && e.id === 0
                        ? "#000"
                        : "#8D8D8D"
                    }
                    borderBottom={
                      e.link === pathName
                        ? "4px solid #1CB894"
                        : pathName ===
                            `/${localStorage.getItem(
                              "locale"
                            )}/wallet/analytics` && e.id === 0
                        ? "4px solid #1CB894"
                        : "2px solid #ff000000"
                    }
                    fontWeight={
                      e.link === pathName
                        ? "600"
                        : pathName ===
                            `/${localStorage.getItem(
                              "locale"
                            )}/wallet/analytics` && e.id === 0
                        ? "600"
                        : "unset"
                    }
                  >
                    {e.text}
                  </MenuItem>
                </Link>
              ))}
            </div>
          </div>
          <div className="px-2 px-sm-4 pb-4">
            <Switch>
              <Route
                path={`/:lang/wallet/analytics/report`}
                render={() => {
                  return <Report />;
                }}
              />
              <Route
                path={`/:lang/wallet/analytics/economic`}
                render={() => {
                  return <Economic />;
                }}
              />
              <Route
                path={`/:lang/wallet/analytics/holiday`}
                render={() => {
                  return <Holiday />;
                }}
              />
            </Switch>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default Analyze;
