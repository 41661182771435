import { Button, Menu } from "antd";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Langs } from "./listlang";
import { useHistory } from "react-router-dom";
import { CheckLanguageImg } from "../../helper/countryLangImg";
const locale = require("react-redux-i18n").I18n;

const MenuDropdownCustom = styled.div`
  position: fixed;
  z-index: 1;
  background: black;
  width: auto;
  // margin-left: -10px;
  text-align: center;
  min-width: 180px;
`;
export const RightMenu = (props) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const flagStyle = {
    width: 20,
    height: 20,
  };

  const onCloseMenuItem = (el) => {
    setOpen(el?.key);
  };

  const handleChangeLanguage = (oldLang, newLang) => {
    const newPath = props.history?.location?.pathname.replace(oldLang, newLang);
    props.history.push(newPath);
    window.location.reload();
  };

  const handleChangLanguageDesk = (newLang) => {
    // console.log(newLang, "newLang");
    const oldLang = localStorage.getItem("locale");
    localStorage.setItem("locale", newLang);
    const newPath = history?.location?.pathname.replace(oldLang, newLang);
    // console.log(newPath, "newPath");
    history?.push(newPath);
    window.location?.reload();
  };

  return (
    <div className="d-flex align-items-center">
      <div
        key="language"
        onClick={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <div>
          <div className="d-flex align-items-center text-white cursor-pointer">
            <div className="mr-1 d-flex">
              <span
                className={`fi fi-${CheckLanguageImg(
                  localStorage.getItem("locale")
                )}`}
              />
            </div>
            <div className="me-1" style={{ textTransform: "uppercase" }}>
              {localStorage.getItem("locale")}
            </div>
          </div>
          <MenuDropdownCustom
            onMouseLeave={() => setOpen(false)}
            style={{
              display: open ? "block" : "none",
              width: 130,
            }}
          >
            {Langs.map((i, x) => (
              <div
                key={x}
                className="w-100 menu-hover-item  cursor-pointer"
                onClick={() => handleChangLanguageDesk(i.lang)}
              >
                <div className="nav-menu-item d-flex justify-content-between align-items-center p-2">
                  <div className="mr-1">
                    <span className={`fi fi-${i?.img}`} />
                  </div>
                  <div className="text-white">&nbsp;{i.name}</div>
                </div>
              </div>
            ))}
          </MenuDropdownCustom>
        </div>
      </div>
      <div onClick={() => onCloseMenuItem()} className="mx-3">
        <Link to={`/${localStorage.getItem("locale")}/register`}>
          <Button className="btn-primary-new-iux">
            {locale.t("register")}
          </Button>{" "}
        </Link>
      </div>
      <div onClick={() => onCloseMenuItem()}>
        <Link to={`/${localStorage.getItem("locale")}/login`}>
          <Button className="btn-outline-iux ">{locale.t("signin")}</Button>
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RightMenu);
