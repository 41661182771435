export default {
  th: {
    policyPage: {
      title: "ข้อบังคับทั่วไป\n",
      subTitle:
        "ทางเราเคารพความเป็นส่วนตัวของผู้ใช้ที่เข้าสู่เว็ปไซต์ของเรา เราได้ทำการปกปิดข้อมูลของผู้ใช้งานอย่างสูงสุด ในเอกสารฉบับนี้จะอธิบายถึงกระบวนการจัดเก็บข้อมูล และการใช้งานข้อมูลที่ทางเราได้รับจากผู้ใช้บริการ\n",
      title2: "พื้นที่ในการเก็บข้อมูล",
      subTitle2:
        "เมื่อคุณเข้าใช้บริการของ IUX Markets เราจะรวบรวมข้อมูลทั่วไปโดยอัตโนมัติรวมถึง\n",
      title3:
        "พื้นที่ในการเก็บข้อมูลที่รวบรวมจากพื้นที่ส่วนบุคคลของคุณในเว็บไซต์ IUX Markets เก็บข้อมูล\n",
      subTitle3:
        "เมื่อคุณลงทะเบียนบัญชีจริงและ/หรือบัญชีเดโมกับ IUX Markets บริษัทจะเก็บข้อมูลต่างๆดังต่อไปนี้\n",
      title4: "ความยินยอมของคุณ\n",
      subTitle4:
        "ผู้ใช้บริการยินยอมที่จะเปิดเผยข้อมูลตาม “นโยบายความเป็นส่วน” ตามข้อ 2 โดยถ้าหากผู้ใช้บริการไม่ยินยอมผู้ใช้บริการจะไม่สามารถเข้าใช้งานเว็ปไซต์ของ IUX Markets ได้\n",
      checkTitle1: "HTTP headers\n",
      checkTitle2: "ที่อยู่ Internet Protocol (IP)\n",
      checkTitle3: "คุกกี้\n",
      checkTitle4: "เว็บบีคอนและพิกเซลแท็ก\n",
      checkTitle5: "ข้อมูลเบราเซอร์ ฟิงเกอร์พรินท์\n",
      checkTitle6: "ข้อมูลการกำหนดค่าซอฟต์แวร์และฮาร์ดแวร์\n",
      checkTitle7: "วันที่และเวลาที่เข้าถึงเว็บไซต์และบริการ\n",
      checkTitle8: "ข้อมูลตำแหน่งทางภูมิศาสตร์\n",
      checkTitle9:
        "ข้อมูลส่วนบุคคลอื่นๆที่จำเป็นสำหรับการดำเนินการตามเงื่อนไขของเว็บไซต์\n",
      checkTitle10:
        "ข้อมูลส่วนบุคคลที่บริษัทได้รับจากพาร์ทเนอร์ของ IUX Markets ตามข้อกำหนดและเงื่อนไขของข้อตกลงที่คุณและพาร์ทเนอร์ของคุณได้ตกลงกันไว้รวมทั้งข้อตกลงที่บริษัทและพาร์ทเนอร์ได้ตกลงกันไว้เช่นกัน\n",
      checkTitle11:
        "ข้อมูลส่วนบุคคลที่คุณให้เพื่อให้เป็นไปตามข้อกำหนดและเงื่อนไขของบริษัทเช่น ชื่อ, หมายเลขโทรศัพท์, อายุ, ที่อยู่ไปรษณีย์ และที่อยู่อีเมลของคุณ\n",
      checkTitle12: "ข้อมูลการชำระเงิน\n",
      checkTitle13:
        "ข้อมูลเอกสารการระบุตัวตน (หนังสือเดินทางหรือบัตรประจำตัวประชาชน)\n",
      checkTitle14: "สร้างและรักษาบัญชีของคุณ\n",
      checkTitle15: "อนุญาตให้คุณเข้าสู่ระบบ IUX Markets\n",
      checkTitle16:
        "ติดต่อคุณเกี่ยวกับบัญชีของคุณและ/หรือบริการที่ IUX Markets เสนอ\n",
      checkTitle17:
        "จัดการความสัมพันธ์ทางการตลาดของเรา ซึ่งรวมถึงแต่ไม่จำกัดเพียง",
      description1:
        "(1) เรียนรู้เกี่ยวกับความสนใจและความต้องการของคุณเพื่อติดต่อคุณด้วยข้อมูลที่เกี่ยวข้องกับคุณและช่วย ให้เรากำหนดเป้าหมายในการสื่อสารการตลาดและโฆษณาเพื่อให้พวกมันมีความเกี่ยวข้องกับคุณมากขึ้น\n",
      description2:
        "(2) ใช้รายละเอียดของคุณที่เป็นนามแฝงเพื่อแสดงโฆษณาแก่คุณบนแพลตฟอร์มโซเชียลมีเดียเช่น Facebook หรือผ่านทางโฆษณาของบริษัทอื่นๆที่อาจปรากฏในเว็บไซต์อื่นๆที่คุณใช้ ข้อมูลที่แชร์กับแพลตฟอร์มเหล่านี้ จะใช้นามแฝงเพื่อปกป้องข้อมูลส่วนบุคคลของคุณ\n",
      description3:
        "(3) วิเคราะห์และปรับปรุงเว็บไซต์และบริการของเราอย่างต่อเนื่องตลอดจนช่วยให้เราสามารถทำการทดสอบ ภายในเว็บไซต์เวอร์ชั่นต่างๆเพื่อพัฒนาฟีเจอร์ใหม่ๆและตรวจสอบให้แน่ใจว่าการให้บริการของ IUX Markets เป็นไปอย่างมีประสิทธิภาพ\n",
      description4:
        "(4) ตรวจสอบและลดความเสี่ยงด้านการฉ้อโกงและความเสี่ยงด้านเครดิต",
    },
  },
  lo: {
    policyPage: {
      title: "ລະບຽບການທົ່ວໄປ\n",
      subTitle:
        "ພວກເຮົາເຄົາລົບຄວາມເປັນສ່ວນຕົວຂອງຜູ້ໃຊ້ທີ່ເຂົ້າເວັບໄຊທ໌້ຂອງພວກເຮົາ. ພວກເຮົາມີຄວາມລັບທີ່ສຸດຂອງຜູ້ຊົມໃຊ້. ເອກະສານນີ້ອະທິບາຍຂັ້ນຕອນການເກັບ ກຳ ຂໍ້ມູນ. ແລະການ ນຳ ໃຊ້ຂໍ້ມູນທີ່ພວກເຮົາໄດ້ຮັບຈາກຜູ້ຊົມໃຊ້\n",
      title2: "ພື້ນທີ່ເກັບຮັກສາ",
      subTitle2:
        "ເມື່ອທ່ານເຂົ້າເຖິງການບໍລິການຂອງ iuxmarket, ພວກເຮົາຈະລວບລວມຂໍ້ມູນທົ່ວໄປໂດຍອັດຕະໂນມັດ\n",
      title3:
        "ພື້ນທີ່ ສຳ ລັບການລຶບຂໍ້ມູນທີ່ເກັບ ກຳ ມາຈາກພື້ນທີ່ສ່ວນຕົວຂອງທ່ານໃນເວັບໄຊທ໌ iuxmarket. ການລວບລວມຂໍ້ມູນ\n",
      subTitle3:
        "ເມື່ອທ່ານລົງທະບຽນບັນຊີທີ່ແທ້ຈິງຂອງທ່ານແລະ / ຫຼືບັນຊີຕົວຢ່າງກັບ iuxmarket, ບໍລິສັດຈະເກັບ ກຳ ຂໍ້ມູນຕໍ່ໄປນີ້\n",
      title4: "ການຍິນຍອມຂອງທ່ານ\n",
      subTitle4:
        'ຜູ້ໃຊ້ເຫັນດີເປີດເຜີຍຂໍ້ມູນໂດຍອີງຕາມ "ນະໂຍບາຍຄວາມເປັນສ່ວນຕົວ" ຕາມມາດຕາ 2, ຖ້າຜູ້ໃຊ້ບໍ່ເຫັນດີ, ຜູ້ໃຊ້ຈະບໍ່ສາມາດໃຊ້ເວບໄຊທ໌ iuxmarket ໄດ້.\n',
      checkTitle1: "HTTP headers\n",
      checkTitle2: "ที่อยู่ Internet Protocol (IP)\n",
      checkTitle3: "cookie\n",
      checkTitle4: "Web beacons and pixel tags\n",
      checkTitle5: "ຂໍ້ມູນຂອງຕົວທ່ອງເວັບ Finger Print\n",
      checkTitle6: "ຂໍ້ມູນການຕັ້ງຄ່າຊອບແວແລະຮາດແວ\n",
      checkTitle7: "ວັນທີແລະເວລາຂອງການເຂົ້າເບິ່ງເວັບໄຊທ໌້ແລະການບໍລິການ\n",
      checkTitle8: "Geolocation data\n",
      checkTitle9:
        "ຂໍ້ມູນສ່ວນຕົວອື່ນໆທີ່ ຈຳ ເປັນ ສຳ ລັບການປະມວນຜົນເງື່ອນໄຂຂອງເວບໄຊທ໌\n",
      checkTitle10:
        "ຂໍ້ມູນສ່ວນຕົວທີ່ບໍລິສັດໄດ້ຮັບຈາກຄູ່ຮ່ວມງານຂອງ iuxmarket ໂດຍສອດຄ່ອງກັບເງື່ອນໄຂຂອງຂໍ້ຕົກລົງທີ່ທ່ານແລະຄູ່ຮ່ວມງານຂອງທ່ານໄດ້ຕົກລົງເຫັນດີ, ລວມທັງຂໍ້ຕົກລົງຕ່າງໆທີ່ບໍລິສັດແລະຄູ່ຮ່ວມງານໄດ້ຕົກລົງເຫັນດີເຊັ່ນກັນ\n",
      checkTitle11:
        "ຂໍ້ມູນສ່ວນຕົວທີ່ທ່ານໃຫ້ເພື່ອໃຫ້ສອດຄ່ອງກັບຂໍ້ ກຳ ນົດແລະເງື່ອນໄຂຂອງບໍລິສັດເຊັ່ນ: ຊື່, ເບີໂທລະສັບ, ອາຍຸ, ທີ່ຢູ່ທາງໄປສະນີແລະທີ່ຢູ່ອີເມວຂອງທ່ານ\n",
      checkTitle12: "ຂໍ້ມູນການຈ່າຍເງິນ\n",
      checkTitle13: "ຂໍ້ມູນເອກະສານປະ ຈຳ ຕົວ (ໜັງ ສືຜ່ານແດນຫລືບັດປະ ຈຳ ຕົວ)\n",
      checkTitle14: "ສ້າງແລະຮັກສາບັນຊີຂອງທ່ານ.\n",
      checkTitle15: "อนุญาตให้คุณเข้าสู่ระบบ IUX Markets\n",
      checkTitle16:
        "ຕິດຕໍ່ທ່ານກ່ຽວກັບບັນຊີຂອງທ່ານແລະ / ຫຼືການບໍລິການທີ່ສະ ໜອງ ໂດຍ iuxmarket.\n",
      checkTitle17:
        "ຈັດການຄວາມ ສຳ ພັນທາງກາລະຕະຫຼາດຂອງພວກເຮົານີ້ລວມມີແຕ່ບໍ່ ຈຳ ກັດ:",
      description1:
        "(1) ຮຽນຮູ້ກ່ຽວກັບຄວາມສົນໃຈແລະຄວາມຕ້ອງການຂອງທ່ານເພື່ອຕິດຕໍ່ທ່ານກັບຂໍ້ມູນທີ່ກ່ຽວຂ້ອງກັບທ່ານແລະຊ່ວຍໃຫ້ພວກເຮົາຕັ້ງເປົ້າ ໝາຍ ຂອງພວກເຮົາ ສຳ ລັບການສື່ສານ, ການຕະຫຼາດແລະການໂຄສະນາເພື່ອໃຫ້ພວກເຂົາມີຄວາມກ່ຽວຂ້ອງກັບທ່ານຫຼາຍຂຶ້ນ.\n",
      description2:
        "(2) ໃຊ້ລາຍລະອຽດນາມແຝງຂອງທ່ານເພື່ອສະແດງໂຄສະນາໃຫ້ທ່ານໃນເວທີສື່ສັງຄົມເຊັ່ນ Facebook ຫຼືຜ່ານການໂຄສະນາຂອງບໍລິສັດອື່ນທີ່ອາດຈະສະແດງຢູ່ໃນເວັບໄຊທ໌ອື່ນໆທີ່ທ່ານໃຊ້. ຂໍ້ມູນທີ່ແບ່ງປັນກັບເວທີເຫລົ່ານີ້ຈະໃຊ້ນາມແຝງເພື່ອປົກປ້ອງຂໍ້ມູນສ່ວນຕົວຂອງທ່ານ\n",
      description3:
        "(3) ສືບຕໍ່ວິເຄາະແລະປັບປຸງເວບໄຊທ໌ແລະການບໍລິການຂອງພວກເຮົາພ້ອມທັງໃຫ້ພວກເຮົາທົດສອບພາຍໃນສະບັບເວັບໄຊທ໌ຕ່າງໆເພື່ອພັດທະນາຄຸນລັກສະນະ ໃໝ່ ແລະຮັບປະກັນການບໍລິການຂອງ iuxmarket ທີ່ມີປະສິດຕິພາບ.\n",
      description4:
        "(4) ສືບສວນແລະຫຼຸດຜ່ອນການສໍ້ໂກງແລະຄວາມສ່ຽງດ້ານການປ່ອຍສິນເຊື່ອ",
    },
  },
  en: {
    policyPage: {
      title: "General regulations\n",
      subTitle:
        "We respect the privacy of users who enter our website. We have the utmost confidentiality of users. This document describes the data collection process. And the use of information that we receive from users\n",
      title2: "Storage space\n",
      subTitle2:
        "When you access the IUX Markets services, we will automatically collect general information, including",
      title3:
        "The area for deleting data collected from your personal space on the IUX Markets website. Collecting data\n",
      subTitle3:
        "When you register your real account and / or demo account with IUX Markets, the company will collect the following information\n\n",
      title4: "Your consent\n\n",
      subTitle4:
        'Users agree to disclose information in accordance with "Privacy Policy" in accordance with Article 2, if the user does not consent, the user will not be able to use the IUX Markets website.\n\n',
      checkTitle1: "HTTP headers\n",
      checkTitle2: "Internet Protocol (IP) Address\n\n",
      checkTitle3: "cookie\n\n",
      checkTitle4: "Web beacons and pixel tags\n\n",
      checkTitle5: "Browser info Finger Print\n\n",
      checkTitle6: "Software and hardware configuration information\n",
      checkTitle7: "Date and time of accessing the website and service\n\n",
      checkTitle8: "Geolocation data\n\n",
      checkTitle9:
        "Other personal information that is necessary for the processing of the website conditions\n\n",
      checkTitle10:
        "Personal information that the company receives from IUX Markets partners in accordance with the terms and conditions of the agreement that you and your partners have agreed, including agreements that the company and partners have agreed with, such as together\n\n",
      checkTitle11:
        "Personal information that you provide in order to comply with company terms and conditions, such as name, telephone number, age, postal address And your email address",
      checkTitle12: "Payment Information\n\n",
      checkTitle13:
        "Identification document information (Passport or ID card)\n\n",
      checkTitle14: "Create and maintain your account.\n\n",
      checkTitle15:
        "Contact you about your account and / or services offered by IUX Markets.\n",
      checkTitle16: "Allow you to login to IUX Markets.\n",
      checkTitle17:
        "Manage our marketing relationships This includes but is not limited to:\n",
      description1:
        "(1) Learn about your interests and needs in order to contact you with information relevant to you and help Let us set our goals for communication, marketing and advertising in order to make them more relevant to you.\n",
      description2:
        "(2) Use your alias details to display advertisements to you on social media platforms such as Facebook or through advertisements of other companies that may appear on other websites you use. Information shared with these platforms Will use an alias to protect your personal information\n",
      description3:
        "(3) Continuously analyze and improve our websites and services, as well as allow us to test Within different website versions to develop new features and ensure efficient IUX Markets services.\n",
      description4:
        "(4) Investigate and reduce fraud and credit risk\n" + " \n",
    },
  },
  hi: {
    policyPage: {
      title: "General regulations\n",
      subTitle:
        "We respect the privacy of users who enter our website. We have the utmost confidentiality of users. This document describes the data collection process. And the use of information that we receive from users\n",
      title2: "Storage space\n",
      subTitle2:
        "When you access the IUX Markets services, we will automatically collect general information, including",
      title3:
        "The area for deleting data collected from your personal space on the IUX Markets website. Collecting data\n",
      subTitle3:
        "When you register your real account and / or demo account with IUX Markets, the company will collect the following information\n\n",
      title4: "Your consent\n\n",
      subTitle4:
        'Users agree to disclose information in accordance with "Privacy Policy" in accordance with Article 2, if the user does not consent, the user will not be able to use the IUX Markets website.\n\n',
      checkTitle1: "HTTP headers\n",
      checkTitle2: "Internet Protocol (IP) Address\n\n",
      checkTitle3: "cookie\n\n",
      checkTitle4: "Web beacons and pixel tags\n\n",
      checkTitle5: "Browser info Finger Print\n\n",
      checkTitle6: "Software and hardware configuration information\n",
      checkTitle7: "Date and time of accessing the website and service\n\n",
      checkTitle8: "Geolocation data\n\n",
      checkTitle9:
        "Other personal information that is necessary for the processing of the website conditions\n\n",
      checkTitle10:
        "Personal information that the company receives from IUX Markets partners in accordance with the terms and conditions of the agreement that you and your partners have agreed, including agreements that the company and partners have agreed with, such as together\n\n",
      checkTitle11:
        "Personal information that you provide in order to comply with company terms and conditions, such as name, telephone number, age, postal address And your email address",
      checkTitle12: "Payment Information\n\n",
      checkTitle13:
        "Identification document information (Passport or ID card)\n\n",
      checkTitle14: "Create and maintain your account.\n\n",
      checkTitle15:
        "Contact you about your account and / or services offered by IUX Markets.\n",
      checkTitle16: "Allow you to login to IUX Markets.\n",
      checkTitle17:
        "Manage our marketing relationships This includes but is not limited to:\n",
      description1:
        "(1) Learn about your interests and needs in order to contact you with information relevant to you and help Let us set our goals for communication, marketing and advertising in order to make them more relevant to you.\n",
      description2:
        "(2) Use your alias details to display advertisements to you on social media platforms such as Facebook or through advertisements of other companies that may appear on other websites you use. Information shared with these platforms Will use an alias to protect your personal information\n",
      description3:
        "(3) Continuously analyze and improve our websites and services, as well as allow us to test Within different website versions to develop new features and ensure efficient IUX Markets services.\n",
      description4:
        "(4) Investigate and reduce fraud and credit risk\n" + " \n",
    },
  },
  ms: {
    policyPage: {
      title: `Peraturan am`,
      subTitle: `Kami menghormati privasi pengguna yang mengakses laman web kami. Kami telah merahsiakan maklumat pengguna sepenuhnya. Dokumen ini menerangkan proses penyimpanan dan penggunaan maklumat yang kami terima daripada pengguna`,
      title2: `Ruang simpanan`,
      subTitle2: `Apabila anda mengakses perkhidmatan IUX Markets, kami mengumpul maklumat am secara automatik, termasuk:`,
      title3: `Ruangan simpanan maklumat yang dikumpul dari ruangan peribadi anda di web IUX Markets`,
      subTitle3: `Apabila anda mendaftar akaun sebenar anda dan / atau akaun demo dengan IUX Markets, syarikat akan mengumpul maklumat berikut`,
      title4: `Persetujuan anda`,
      subTitle4: `Pengguna bersetuju untuk mendedahkan maklumat mengikut "Dasar Privasi" nombor kedua. Jika pengguna tidak bersetuju, pengguna tidak akan dapat mengakses laman web IUX Markets.`,
      checkTitle1: `HTTP header`,
      checkTitle2: `Alamat Internet Protocol (IP) `,
      checkTitle3: `Cookie`,
      checkTitle4: `Beacon web dan tag piksel`,
      checkTitle5: `Maklumat pelayar Cap Jari`,
      checkTitle6: `Maklumat konfigurasi hard dan soft-ware`,
      checkTitle7: `Tarikh dan masa yang mengakses laman web dan perkhidmatan`,
      checkTitle8: `Data geolokasi`,
      checkTitle9: `Maklumat peribadi lain yang diperlukan untuk memproses selaras dengan syarat laman web`,
      checkTitle10: `Data peribadi yang Syarikat terima daripada rakan kongsi IUX Markets mengikut terma dan syarat perjanjian yang anda dan rakan kongsi anda telah bersetuju serta perjanjian yang telah dipersetujui oleh syarikat dan rakan kongsi`,
      checkTitle11: `Maklumat peribadi yang anda berikan untuk mematuhi terma dan syarat Syarikat, seperti nama, nombor telefon, umur, alamat pos. dan alamat e-mel anda`,
      checkTitle12: `Maklumat Pembayaran`,
      checkTitle13: `Maklumat dokumen pengenalan diri (Pasport atau kad pengenalan)`,
      checkTitle14: `Buat dan Kekalkan akaun anda.`,
      checkTitle15: `Anda boleh log masuk ke IUX Markets.`,
      checkTitle16: `Hubungi anda berkenaan akaun anda dan/atau perkhidmatan yang ditawarkan oleh IUX Markets.`,
      checkTitle17: `Uruskan hubungan pemasaran kami termasuk tetapi tidak terhad kepada:`,
      description1: `(1) Belajar tentang minat dan keperluan anda untuk menghubungi anda dengan maklumat yang berkaitan dengan anda dan membantu kami menyasarkan komunikasi pemasaran dan iklan anda untuk menjadikannya lebih relevan kepada anda.`,
      description2: `(2) Gunakan butiran alias anda untuk memaparkan iklan kepada anda pada platform media sosial seperti Facebook atau melalui iklan syarikat-syarikat lain yang mungkin muncul di laman web lain yang anda gunakan. Maklumat yang dikongsi dengan platform ini akan menggunakan alias untuk melindungi maklumat peribadi anda`,
      description3: `(3) Terus menganalisis dan memperbaiki laman web dan perkhidmatan kami, serta membolehkan kami menjalankan ujian dalam versi laman web yang berbeza untuk membangunkan Feature baru dan memastikan perkhidmatan IUX Markets yang cekap.`,
      description4: `(4) Menyiasat dan mengurangkan risiko penipuan dan kredit`,
    },
  },
  id: {
    policyPage: {
      title: `peraturan umum`,
      subTitle: `Kami menghormati privasi pengguna yang masuk website kami. Kami memiliki kerahasiaan maksimal dari pengguna. Dokumen ini menjelaskan proses pengumpulan data. Dan penggunaan informasi yang kami terima dari pengguna`,
      title2: `Ruang penyimpanan`,
      subTitle2: `Ketika Anda mengakses layanan IUX Markets, kami secara otomatis akan mengumpulkan informasi umum, termasuk`,
      title3: `Daerah untuk menghapus data yang dikumpulkan dari ruang pribadi Anda di situs IUX Markets. Mengumpulkan data`,
      subTitle3: `Bila Anda mendaftar real account Anda dan / atau akun demo dengan IUX Markets, perusahaan akan mengumpulkan informasi berikut`,
      title4: `persetujuan Anda`,
      subTitle4: `Pengguna setuju untuk mengungkapkan informasi sesuai dengan "Kebijakan Privasi" sesuai dengan Pasal 2, jika pengguna tidak menyetujui, pengguna tidak akan dapat menggunakan website IUX Markets.`,
      checkTitle1: `HTTP header`,
      checkTitle2: `Internet Protocol (IP) Alamat`,
      checkTitle3: `Kue kering`,
      checkTitle4: `beacon web dan tag pixel`,
      checkTitle5: `Browser Info Finger Print`,
      checkTitle6: `Software dan konfigurasi hardware informasi`,
      checkTitle7: `Tanggal dan waktu mengakses situs dan layanan`,
      checkTitle8: `Data geolocation`,
      checkTitle9: `informasi pribadi lainnya yang diperlukan untuk pengolahan kondisi situs`,
      checkTitle10: `Informasi pribadi bahwa perusahaan menerima dari mitra IUX Markets sesuai dengan syarat dan kondisi dari perjanjian bahwa Anda dan pasangan Anda telah disepakati, termasuk perjanjian bahwa perusahaan dan mitra telah setuju dengan, seperti bersama-sama`,
      checkTitle11: `Informasi pribadi yang Anda berikan untuk mematuhi persyaratan perusahaan dan kondisi, seperti nama, nomor telepon, usia, alamat pos dan alamat email Anda`,
      checkTitle12: `Informasi Pembayaran`,
      checkTitle13: `Informasi dokumen identifikasi (Paspor atau kartu ID)`,
      checkTitle14: `Membuat dan memelihara akun Anda.`,
      checkTitle15: `Menghubungi Anda tentang akun Anda dan / atau jasa yang ditawarkan oleh IUX Markets.`,
      checkTitle16: `Memungkinkan Anda untuk login ke IUX Markets.`,
      checkTitle17: `Mengelola hubungan pemasaran kami ini termasuk namun tidak terbatas pada:`,
      description1: `(1) Pelajari tentang kepentingan dan kebutuhan Anda untuk menghubungi Anda dengan informasi yang relevan untuk Anda dan bantuan Mari kita menetapkan tujuan kami untuk komunikasi, pemasaran dan periklanan untuk membuat mereka lebih relevan bagi Anda.`,
      description2: `(2) Gunakan rincian alias Anda untuk menampilkan iklan kepada Anda pada platform media sosial seperti Facebook atau melalui iklan dari perusahaan lain yang mungkin muncul di situs-situs lain yang Anda gunakan. Informasi bersama dengan platform ini akan menggunakan alias untuk melindungi informasi pribadi Anda`,
      description3: `(3) Terus menganalisis dan meningkatkan website dan layanan kami, serta memungkinkan kita untuk menguji Dalam versi situs web yang berbeda untuk mengembangkan fitur baru dan memastikan layanan IUX Markets efisien.`,
      description4: `(4) Selidiki dan mengurangi penipuan dan risiko kredit`,
    },
  },
  zh: {
    policyPage: {
      title: `简章`,
      subTitle: `我们尊重谁进入我们的网站用户的隐私。我们所拥有的用户绝对保密。该文档描述了数据收集过程。而使用的信息，我们从用户收到`,
      title2: `储存空间`,
      subTitle2: `当您访问IUX Markets服务，我们会自动收集的一般信息，包括`,
      title3: `删除从IUX Markets网站上的个人空间收集的数据的区域。收集数据`,
      subTitle3: `当你与IUX Markets注册您的真实账户和/或模拟账户，公司将收集以下信息`,
      title4: `您的同意`,
      subTitle4: `用户同意在按照第二条按照“隐私政策”透露的信息，如果用户不同意，则用户将无法使用IUX Markets网站。`,
      checkTitle1: `HTTP头`,
      checkTitle2: `互联网协议（IP）地址`,
      checkTitle3: `曲奇饼`,
      checkTitle4: `网站信标和像素标签`,
      checkTitle5: `浏览器信息指纹`,
      checkTitle6: `软件和硬件配置信息`,
      checkTitle7: `日期和访问的网站和服务的时间`,
      checkTitle8: `地理位置数据`,
      checkTitle9: `其他个人信息是必要的条件，网站的处理`,
      checkTitle10: `该公司从IUX Markets伙伴接收按照协议的条款和条件，您和您的伙伴已同意，包括协议，该公司与合作伙伴商定的，如同时个人资料`,
      checkTitle11: `你为了遵守公司的条款和条件，如姓名，电话号码，年龄，通讯地址和电子邮件地址提供个人信息`,
      checkTitle12: `支付信息`,
      checkTitle13: `识别文档的信息（护照或ID卡）`,
      checkTitle14: `创建和维护您的帐户。`,
      checkTitle15: `联系您有关IUX Markets提供您的帐户和/或服务。`,
      checkTitle16: `允许您登录到IUX Markets。`,
      checkTitle17: `管理我们的营销关系，这包括但不限于：`,
      description1: `（1）为了您与您相关的信息和帮助，请联系我们设置我们的沟通，市场营销和广告目标，以使它们更适合您了解自己的兴趣和需求。`,
      description2: `（2）使用您的别名细节上的社交媒体平台，如Facebook或通过您使用其他网站可能出现的其他公司的广告，显示广告给你。这些平台共享信息将使用别名来保护您的个人信息`,
      description3: `（3）不断分析和改进我们的网站和服务，以及让我们的测试在不同的网站版本，以开发新的功能，并确保有效IUX Markets服务。`,
      description4: `（4）调查和减少欺诈风险和信用风险`,
    },
  },
  vi: {
    policyPage: {
      title: "Quy định chung\n",
      subTitle:
        "Chúng tôi tôn trọng quyền riêng tư của người dùng trang web của chúng tôi. Chúng tôi đã bảo mật thông tin của người dùng một cách tối đa. Tài liệu này mô tả quá trình lưu trữ thông  và việc sử dụng thông tin mà chúng tôi nhận được từ người sủ dùng phục vụ.\n",
      title2: "Không gian lưu trữ thông tin",
      subTitle2:
        "Khi bạn vào sử dụng dịch vụ của IUX Markets, chúng tôi sẽ tự động thu thập thông tin chung bao gồm:\n",
      title3:
        "Không gian lưu trữ thông tin được thu thập từ khu vực cá nhân của bạn trên trang web Thị trường IUX đã yhu thập dữ liệu\n",
      subTitle3:
        "Khi bạn đăng ký tài khoản thực và tài khoản demo với IUX Markets, công ty sẽ thu thập các thông tin sau:\n",
      title4: "Sự đồng ý của bạn",
      subTitle4:
        "Người sử dùng dịch vụ đồng ý với tiết lộ thông tin theo “Chính sách bảo mật” theo điều thứ 2 do điều kiện là nếu gười dùng dịch vụ không đồng ý, người dùng  dịch vụ sẽ không thể truy cập được trang web của IUX Markets.\n",
      checkTitle1: "HTTP headers\n",
      checkTitle2: "Địa chỉInternet Protocol (IP)\n",
      checkTitle3: "cookie\n",
      checkTitle4: "web beacon và pixel\n",
      checkTitle5: "thông tin trình duyệt dấu vân tay\n",
      checkTitle6: "Dữ liệu cấu hình phần cứng và phần mềm\n",
      checkTitle7: "Ngày và giờ Truy cập Trang web và dịch vụ\n",
      checkTitle8: "Dữ liệu vị trí\n",
      checkTitle9:
        "Dữ liệu cá nhân khác cần thiết để thực hiện điều kiện của trang web.\n",
      checkTitle10:
        'Dữ liệu cá nhân  mà công ty thu được từ các ối tác của Thị trường IUX theo các điều khoản và điều kiện của thỏa thuận mà bạn và đối tác  đã thỏa thuận cùng nhau, bao gồm hỏa thuận mà Công ty và đối tác đã thỏa thuận cũng như".\n',
      checkTitle11:
        "Thông tin cá nhân mà bạn cung cấp để tuân thủ các điều khoản và điều kiện của công ty, chẳng hạn như tên, số điện thoại tuổi địa chỉ bưu điện và địa chỉ e-mail của bạn.\n",
      checkTitle12: "Thông tin thanh toán\n",
      checkTitle13:
        "Thông tin tài liệu xác minh danh tính (Hộ chiếu hoặc CMND.)\n",
      checkTitle14: "Tạo và duy trì tài khoản của bạn\n",
      checkTitle15: "Cho phép bạn đăng nhập vào hệ thống IUX Markets\n",
      checkTitle16: "Liên hệ bạn về tài khoản và dịch vụ tại IUX Markets.\n",
      checkTitle17:
        "Quản lý các mối quan hệ tiếp thị của chúng tôi. bao gồm nhưng không giới hạn.",
      description1:
        "(1) Tìm hiểu về sở thích và nhu cầu của bạn để liên hệ bạn với thông tin liên quan đến bạn  giúp chúng tôi nhắm mục tiêu các thông tin tiếp thị và quảng cáo để làm cho chúng phù hợp với bạn nhiều hơn.\n",
      description2:
        "(2) sử dụng chi tiết biệt danh của bạn để hiển thị cho bạn quảng cáo trên các nền tảng truyền thông xã hội như: Facebook hoặc thông qua quảng cáo của các công ty khác mà có thể xuất hiện trên  trang web khác mà bạn đã sử dụng hông tin được chia sẻ với nền tảng này, sẽ sử dụng bút danh để bảo vệ dữ liệu cá nhân của bạn.\n",
      description3:
        "(3) Liên tục phân tích và cải thiện trang web và dịch vụ của chúng tôi ,  cho phép chúng tôi chạy thử nghiệm. trong phiên bản trang web để phát triển các tính năng mới và kiểm tra để chắn chắn dịch vụ của IUX Markets có hiệu quả.\n",
      description4: "(4) Kiểm tra và giảm thiểu gian lận về rủi ro tín dụng.",
    },
  },
  ko: {
    policyPage: {
      title: `일반 규정`,
      subTitle: `우리는 우리의 웹 사이트를 입력 사용자의 개인 정보를 존중합니다. 우리는 사용자의 최고의 비밀이있다. 이 문서는 데이터 수집 프로세스를 설명합니다. 그리고 우리는 사용자 정보의 사용을받을 수`,
      title2: `저장 공간`,
      subTitle2: `당신이 IUX Markets 서비스에 액세스 할 때, 우리는 다음과 같은 일반 정보를 자동으로 수집합니다`,
      title3: `IUX Markets 웹 사이트의 개인 공간에서 수집 된 데이터를 삭제하는 영역입니다. 데이터 수집`,
      subTitle3: `당신이 IUX Markets와 실제 계정 및 / 또는 데모 계정을 등록 할 때, 회사는 다음과 같은 정보를 수집합니다`,
      title4: `귀하의 동의`,
      subTitle4: `사용자는 사용자가 동의하지 않는 경우, 사용자가 IUX Markets 웹 사이트를 사용할 수 없습니다, 제 2 항에 "개인 정보 보호 정책"에 따라 정보를 공개하는 데 동의합니다.`,
      checkTitle1: `HTTP 헤더`,
      checkTitle2: `인터넷 프로토콜 (IP) 주소`,
      checkTitle3: `쿠키`,
      checkTitle4: `웹 비콘 및 픽셀 태그`,
      checkTitle5: `브라우저 정보 지문`,
      checkTitle6: `소프트웨어 및 하드웨어 구성 정보`,
      checkTitle7: `날짜 및 웹 사이트 및 서비스에 액세스 시간`,
      checkTitle8: `위치 정보 데이터`,
      checkTitle9: `웹 사이트 조건의 처리에 필요한 기타 개인 정보`,
      checkTitle10: `회사와 파트너에 동의 한 것으로 회사는 함께 같은 계약을 포함하여 당신과 당신의 파트너가 동의 한 것으로 계약의 조항 및 조건에 따라 IUX Markets 파트너로부터받는 개인 정보`,
      checkTitle11: `당신은 이름, 전화 번호, 나이, 우편 주소 및 이메일 주소와 같은 회사의 이용 약관을 준수하기 위해 제공하는 개인 정보`,
      checkTitle12: `결제 정보`,
      checkTitle13: `식별 문서 정보 (여권 또는 ID 카드)`,
      checkTitle14: `작성 및 계정을 유지한다.`,
      checkTitle15: `IUX Markets가 제공하는 계정 및 / 또는 서비스에 대해 문의하십시오.`,
      checkTitle16: `당신이 IUX Markets에 로그인 할 수 있습니다.`,
      checkTitle17: `여기에는 이에 제한되지 않음 마케팅 관계를 관리 :`,
      description1: `(1) 당신에게 더 관련성 만들기 위해 우리가 위해 커뮤니케이션, 마케팅 및 광고에 대한 우리의 목표를 설정하자 귀하와 관련 정보와 도움으로 연락하기 위해 관심과 요구에 대해 알아보세요.`,
      description2: `(2) 페이스 북과 같은 또는 당신이 사용하는 다른 웹 사이트에 게재 할 수있는 다른 회사의 광고를 통해 소셜 미디어 플랫폼에 당신에게 광고를 표시하기 위해 별명 세부 사항을 사용합니다. 이러한 플랫폼 공유 정보는 귀하의 개인 정보를 보호하기 위해 별칭을 사용합니다`,
      description3: `(3) 연속 분석뿐만 아니라 새로운 기능을 개발하고 효율적인 IUX Markets 서비스를 보장하기 위해 다른 웹 사이트 버전 내에서 테스트 우리를 허용하는, 우리의 웹 사이트 및 서비스를 향상시킬 수 있습니다.`,
      description4: `(4) 조사 및 사기 및 신용 위험을 감소`,
    },
  },
  fil: {
    policyPage: {
      title: `Pangkalahatang regulasyon`,
      subTitle: `Iginagalang namin ang privacy ng mga user na ipasok ang aming website. Mayroon kaming sukdulan pagiging kompidensiyal ng mga gumagamit. Ang dokumentong ito ay naglalarawan sa proseso ng pangongolekta ng data. At ang paggamit ng mga impormasyon na natanggap namin mula sa mga gumagamit`,
      title2: `storage space`,
      subTitle2: `Kapag in-access mo ang IUX Markets serbisyo, awtomatiko naming mangolekta ng pangkalahatang impormasyon, kabilang ang`,
      title3: `Ang lugar para sa pagtanggal ng data na nakolekta mula sa iyong personal na espasyo sa IUX Markets website. Nangongolekta ng datos`,
      subTitle3: `Kapag mong irehistro ang iyong tunay na account at / o demo account na may IUX Markets, ang kumpanya ay kolektahin ang sumusunod na impormasyon`,
      title4: `iyong pahintulot`,
      subTitle4: `Mga gumagamit sumasang-ayon upang ibunyag ang impormasyon alinsunod sa "Patakaran sa Pagkapribado" alinsunod sa Artikulo 2, kung ang gumagamit ay hindi pumayag, ang gumagamit ay hindi magagawang upang gamitin ang IUX Markets website.`,
      checkTitle1: `HTTP header`,
      checkTitle2: `Internet Protocol (IP) Address`,
      checkTitle3: `cookie`,
      checkTitle4: `Web beacons at pixel tag`,
      checkTitle5: `Browser info Finger Print`,
      checkTitle6: `Software at hardware configuration impormasyon`,
      checkTitle7: `Petsa at oras ng pag-access sa website at serbisyo`,
      checkTitle8: `data geolocation`,
      checkTitle9: `Iba pang mga personal na impormasyon na kinakailangan para sa pagproseso ng mga kondisyon website`,
      checkTitle10: `Ang personal na impormasyon na ang kumpanya na natatanggap mula sa mga kasosyo IUX Markets alinsunod sa mga tuntunin at kundisyon ng kasunduan na ikaw at ang iyong mga kasosyo ay sumang-ayon, kabilang ang mga kasunduan na ang kumpanya at mga kasosyo ay may sumang-ayon sa, tulad ng sama-sama`,
      checkTitle11: `Ang personal na impormasyon na iyong ibinigay upang sumunod sa mga tuntunin ng kumpanya at mga kondisyon, tulad ng pangalan, numero ng telepono, edad, postal address at ang iyong email address`,
      checkTitle12: `Impormasyon sa Pagbabayad`,
      checkTitle13: `impormasyon identification document (Passport o ID card)`,
      checkTitle14: `Lumikha at mapanatili ang iyong account.`,
      checkTitle15: `Makipag-ugnay sa iyo tungkol sa iyong account at / o mga serbisyo na inaalok ng IUX Markets.   `,
      checkTitle16: `Daan sa iyo upang mag-login sa IUX Markets.`,
      checkTitle17: `Pamahalaan ang aming mga relasyon sa marketing na ito ay kasama ngunit hindi limitado sa:`,
      description1: `(1) Alamin ang tungkol sa iyong mga interes at pangangailangan upang makipag-ugnayan sa iyo ng impormasyon na may kaugnayan sa iyo at sa tulong Ituon natin ang ating mga layunin para sa komunikasyon, marketing at advertising upang gawin itong mas may-katuturan sa iyo.`,
      description2: `(2) Gamitin ang iyong mga detalye alias upang magpakita ng mga advertisement sa iyo sa mga social media platform tulad ng Facebook o sa pamamagitan ng mga advertisement ng iba pang mga kumpanya na maaaring lumitaw sa ibang mga website na ginagamit mo. Impormasyon ibabahagi sa mga platform Makakaapekto ba gamitin ang isang alias upang protektahan ang iyong personal na impormasyon`,
      description3: `(3) Patuloy na pag-aralan at mapabuti ang aming mga website at mga serbisyo, pati na rin ang daan sa amin upang test loob ng iba't ibang mga bersyon ng website upang bumuo ng mga bagong tampok at matiyak na mahusay na serbisyo IUX Markets.`,
      description4: `(4) Siyasatin at mabawasan ang pandaraya at credit panganib`,
    },
  },
  ja: {
    policyPage: {
      title: "一般規制 \n",
      subTitle:
        "当社のウェブサイトに入るユーザーのプライバシーを尊重します。 ユーザーの最大の機密性があります。 このドキュメントでは、データ収集プロセスについて説明します。 ユーザーから受け取る情報の使用 \n",
      title2: "ストレージスペース \n",
      subTitle2:
        "IUX Marketsサービスにアクセスすると、一般的な情報を自動的に収集します。",
      title3:
        "IUX Markets Webサイトの個人スペースから収集されたデータを削除するための領域。 データの収集 \n",
      subTitle3:
        "IUX市場に実際のアカウントおよび /またはデモアカウントを登録すると、会社は次の情報を収集します \n  n",
      title4: "あなたの同意 n  \n",
      subTitle4:
        'Users agree to disclose information in accordance with "プライバシーポリシー" in accordance with Article 2, if the user does not consent, the user will not be able to use the IUX Markets website.\n\n',
      checkTitle1: "httpヘッダー n",
      checkTitle2: "インターネットプロトコル（IP）アドレス \n  n",
      checkTitle3: "Cookie  \n  \n",
      checkTitle4: "Webビーコンとピクセルタグ \n  \n",
      checkTitle5: "ブラウザ情報フィンガープリント \n  \n",
      checkTitle6: "ソフトウェアおよびハードウェア構成情報 \n",
      checkTitle7: "ウェブサイトとサービスにアクセスする日時 \n  \n",
      checkTitle8: "ジオロケーションデータ \n  \n",
      checkTitle9: "ウェブサイトの条件の処理に必要なその他の個人情報 \n  \n",
      checkTitle10:
        "会社とあなたのパートナーが合意した合意の条件に従って、会社がIUX市場のパートナーから受け取る個人情報。",
      checkTitle11:
        "名前、電話番号、年齢、住所、メールアドレスなどの会社の契約条件に従うために提供する個人情報",
      checkTitle12: "支払い情報および \n",
      checkTitle13: "識別文書情報（パスポートまたはIDカード） \n  \n",
      checkTitle14: "アカウントを作成して維持します。 \n  \n",
      checkTitle15:
        "IUX市場が提供するアカウントおよび /またはサービスについてお問い合わせください。 \n",
      checkTitle16: "iux市場にログインできるようにします。 \n",
      checkTitle17:
        "マーケティング関係を管理するこれには、以下が含まれますが、これらに限定されません。",
      description1:
        "（1）あなたに関連する情報をあなたに連絡するためにあなたの興味とニーズについて学び、あなたにもっと関連するようにコミュニケーション、マーケティング、広告の目標を設定できるようにするのに役立ちます。 \n",
      description2:
        "（2）エイリアスの詳細を使用して、Facebookなどのソーシャルメディアプラットフォームや、使用する他のWebサイトに表示される他の企業の広告を介して広告を表示します。 これらのプラットフォームと共有される情報は、あなたの個人情報を保護するためにエイリアスを使用します \n",
      description3:
        "（3）ウェブサイトとサービスを継続的に分析および改善するだけでなく、さまざまなWebサイトバージョン内でテストして新しい機能を開発し、効率的なIUX市場サービスを確保できるようにします。",
      description4: "（4）詐欺と信用リスクを調査して減らす" + " \n",
    },
  },
  es: {
    policyPage: {
      title: "Regulaciones Generales\n",
      subTitle:
        "Respetamos la privacidad de los usuarios que ingresan a nuestro sitio web. Mantenemos la máxima confidencialidad de los usuarios. Este documento describe el proceso de recopilación de datos y el uso de la información que recibimos de los usuarios\n",
      title2: "Espacio de almacenamiento\n",
      subTitle2:
        "Cuando accedes a los servicios de IUX Markets, recopilamos automáticamente información general, incluyendo",
      title3:
        "Área para eliminar datos recopilados de tu espacio personal en el sitio web de IUX Markets. Recopilación de datos\n",
      subTitle3:
        "Cuando registras tu cuenta real y/o cuenta demo con IUX Markets, la empresa recopilará la siguiente información\n\n",
      title4: "Tu consentimiento\n\n",
      subTitle4:
        'Los usuarios aceptan divulgar información de acuerdo con la "Política de Privacidad" según el Artículo 2, si el usuario no da su consentimiento, no podrá usar el sitio web de IUX Markets.\n\n',
      checkTitle1: "Encabezados HTTP\n",
      checkTitle2: "Dirección de Protocolo de Internet (IP)\n\n",
      checkTitle3: "Cookie\n\n",
      checkTitle4: "Balizas web y etiquetas de píxeles\n\n",
      checkTitle5: "Información del navegador Huella digital\n\n",
      checkTitle6: "Información de configuración de software y hardware\n",
      checkTitle7: "Fecha y hora de acceso al sitio web y al servicio\n\n",
      checkTitle8: "Datos de geolocalización\n\n",
      checkTitle9:
        "Otra información personal que sea necesaria para el procesamiento de las condiciones del sitio web\n\n",
      checkTitle10:
        "Información personal que la empresa recibe de los socios de IUX Markets de acuerdo con los términos y condiciones del acuerdo que usted y sus socios hayan acordado, incluidos los acuerdos que la empresa y los socios hayan acordado, como juntos\n\n",
      checkTitle11:
        "Información personal que proporciona para cumplir con los términos y condiciones de la empresa, como nombre, número de teléfono, edad, dirección postal y su dirección de correo electrónico\n\n",
      checkTitle12: "Información de pago\n\n",
      checkTitle13:
        "Información de documento de identificación (Pasaporte o tarjeta de identificación)\n\n",
      checkTitle14: "Crear y mantener tu cuenta.\n\n",
      checkTitle15:
        "Contactarte sobre tu cuenta y/o servicios ofrecidos por IUX Markets.\n",
      checkTitle16: "Permitir que inicies sesión en IUX Markets.\n",
      checkTitle17:
        "Gestionar nuestras relaciones de marketing. Esto incluye, pero no se limita a:\n",
      description1:
        "(1) Conocer tus intereses y necesidades para contactarte con información relevante para ti y ayudarnos a establecer nuestros objetivos de comunicación, marketing y publicidad para hacerlos más relevantes para ti.\n",
      description2:
        "(2) Utilizar los detalles de tu alias para mostrarte anuncios en plataformas de redes sociales como Facebook o a través de anuncios de otras empresas que puedan aparecer en otros sitios web que uses. La información compartida con estas plataformas utilizará un alias para proteger tu información personal\n",
      description3:
        "(3) Analizar y mejorar continuamente nuestros sitios web y servicios, así como permitirnos probar diferentes versiones del sitio web para desarrollar nuevas funciones y asegurar servicios eficientes de IUX Markets.\n",
      description4:
        "(4) Investigar y reducir el fraude y el riesgo crediticio\n",
    },
  },
};
