import React from "react";
import { Icon, Collapse } from "antd";
import RcQueueAnim from "rc-queue-anim";
import contact from "../../mock/contact.json";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import {
  MailOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { onOpenPolicy } from "../../helper/policy";
import {useSelector} from "react-redux";
const TITLE = "Contact us | IUX Markets";

const { Panel } = Collapse;
const locale = require("react-redux-i18n").I18n;
const Abutton = styled.a`
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #000;
`;
const Border = styled.div`
  width: 1px;
  height: 80px;
  background-color: gray;
  margin: auto;
  @media (max-width: 480px) {
    display: none;
  }
`;
const Contact = (props) => {
  const user = useSelector((state) => state.user);

  const showBonus = user?.accounts?.filter((e) => e.type === "welcome").length > 0 ||
  user?.bonus_config?.bonus_countries?.find(
      (e) => e === props?.user?.profile?.country_id
  )

  const questions = [
    {
      question: props.locale.t("question.question_1"),
      answer: props.locale.t("question.answer_1"),
    },
    {
      question: props.locale.t("question.question_2"),
      answer: props.locale.t("question.answer_2"),
    },
    {
      question: props.locale.t("question.question_3"),
      answer: props.locale.t("question.answer_3"),
    },
    {
      question: props.locale.t("question.question_4"),
      answer: props.locale.t("question.answer_4"),
    },
    {
      question: props.locale.t("question.question_5"),
      answer: props.locale.t("question.answer_5"),
    },
    {
      question: props.locale.t("question.question_6"),
      answer: props.locale.t("question.answer_6"),
    },
    {
      question: props.locale.t("question.question_7"),
      answer: props.locale.t("question.answer_7"),
    },
    {
      question: props.locale.t("question.question_8"),
      answer: props.locale.t("question.answer_8"),
    },
    {
      question: props.locale.t("question.question_9"),
      answer: props.locale.t("question.answer_9"),
    },
    {
      question: props.locale.t("question.question_10"),
      answer: props.locale.t("question.answer_10"),
    },
    {
      question: props.locale.t("question.question_11"),
      answer: props.locale.t("question.answer_11"),
    },
    {
      question: props.locale.t("question.question_12"),
      answer: props.locale.t("question.answer_12"),
    },
    {
      question: props.locale.t("question.question_13"),
      answer: props.locale.t("question.answer_13"),
    },
  ];
  // const user_create_at = props.user.profile.createdAt
  // const checkUserRegister = moment(user_create_at).isSameOrAfter('2021-03-10', 'day')
  return (
    <RcQueueAnim className="bg-white" style={{ borderRadius: 12 }}>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div key={1}>
        <div className="p-4">
          <p className="text-header-account-wallet"> {locale.t("contact")}</p>
          <div className="text-center fs-16 fw-700">
            {props.locale.t("contactus_header")}
          </div>
          <p className="text-center">
            {props.locale.t("contactus_sub_header1")}
          </p>
          <p className="text-center">
            {props.locale.t("contactus_sub_header2")}
          </p>
          <div className="text-start " style={{ marginTop: 84 }}>
            <div className="row">
              <div className="col">
                <div className="d-flex ">
                  <Abutton href="mailto:support@iuxmarkets.com" target="blank">
                    <div className="col d-flex align-items-center pl-2 pl-xs-5">
                      <div className="mr-3 ">
                        <MailOutlined style={{ color: "#1cb894" }} />
                      </div>
                      <p
                        // className="text-white"
                        style={{ whiteSpace: "break-spaces" }}
                      >
                        {locale.t("main_profile.emailAddress")}:
                        support@iuxmarkets.com
                      </p>
                    </div>
                  </Abutton>
                </div>
                <div className="d-flex mt-2">
                  <Abutton href="tel:+35725567778" target="blank">
                    <div className="col d-flex align-items-center pl-2 pl-xs-5">
                      <div className="mr-3 ">
                        <PhoneOutlined style={{ color: "#1cb894" }} />
                      </div>
                      <p
                        // className="text-white"
                        style={{ whiteSpace: "break-spaces" }}
                      >
                        +35725567778
                      </p>
                    </div>
                  </Abutton>
                </div>
              </div>
              <Border />
              <div className="col">
                <div className="d-flex align-items-center justify-content-start pl-2 pl-xs-5">
                  <div className="mr-3 ">
                    <EnvironmentOutlined style={{ color: "#1cb894" }} />
                  </div>

                  <p style={{ whiteSpace: "break-spaces" }}>
                    P.B. 1257 Bonovo Road, Fomboni, Comoros, KM.
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-start pl-2 pl-xs-5">
                  <div className="mr-3 ">
                    <EnvironmentOutlined style={{ color: "#1cb894" }} />
                  </div>

                  <p style={{ whiteSpace: "break-spaces" }}>
                    Beachmont Business Centre, 321, Kingstown, St. Vincent and
                    the Grenadines.
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-start pl-2 pl-xs-5">
                  <div className="mr-3 ">
                    <EnvironmentOutlined style={{ color: "#1cb894" }} />
                  </div>

                  <p style={{ whiteSpace: "break-spaces" }}>
                    Office 2, 26 Pittalou Street, P.C.3117, Limassol, Cyprus
                  </p>
                </div>
                <div className="d-flex  pl-2 pl-xs-5 mt-4 ">
                  <div className="mr-3 ">
                    <EnvironmentOutlined style={{ color: "#1cb894" }} />
                  </div>

                  <p className="text-left">
                    Suite 305, Griffith Corporate Centre, Beachmont P.O.Box
                    1510, Kingstown , St. Vincent and the Grenadines.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-4">
          <p className="text-header-account-wallet">
            {props.locale.t("contact_sidebar.lable_4")}
          </p>
          <div className="d-flex flex-column">
            {
              showBonus &&
            <div
              className="text-primary-new-iux cursor-pointer"
              rel="noopener noreferrer"
              target="_blank"
              style={{ width: "fit-content" }}
              // href={process.env.PUBLIC_URL + locale.t("pdf.bonus30")}
              onClick={() => onOpenPolicy("bonus_terms_conditions_30")}
            >
              {locale.t("termCondition.condition_2")}
            </div>
            }
            <div
              className="text-primary-new-iux cursor-pointer"
              rel="noopener noreferrer"
              target="_blank"
              style={{ width: "fit-content" }}
              onClick={() => onOpenPolicy("bonus_deposit_35")}
            >
              {locale.t("termCondition.condition_3")}
            </div>
            <div
              rel="noopener noreferrer"
              className="text-primary-new-iux cursor-pointer"
              target="_blank"
              style={{ width: "fit-content" }}
              onClick={() => onOpenPolicy("bonus_deposit_25")}
            >
              {locale.t("termCondition.condition_4")}
            </div>
          </div>
        </div>
        <div className="p-4">
          <p className="text-header-account-wallet">
            {props.locale.t("question.title")}
          </p>
          <div className="user">
            <div className="contactus mt-3">
              {questions.map((item, index) => {
                return (
                  <Collapse
                    key={index}
                    bordered={false}
                    style={{
                      background: "#FFF",
                      border: "1px solid #f2f2f2",
                      marginTop: 8,
                    }}
                  >
                    <Panel header={item.question} key={index}>
                      <p style={{ color: "#000", paddingLeft: 24 }}>
                        <strong>{item.answer}</strong>
                      </p>
                    </Panel>
                  </Collapse>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </RcQueueAnim>
  );
};

export default Contact;
