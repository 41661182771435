import { Button, Col, Modal, Row, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { get, post } from "../../../../helper/request";
import { renderApiDomain } from "../../../../helper/renderApiDomain";

const ItemSelect = styled.div`
  box-shadow: 0 0 7px #80808033;
  padding: 12px;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.3s;
  height: 100%;
  &:hover {
    background: #1f73b7;
    color: #fff;
  }
`;
export const poastMonetix = ({ amount_thb, account_trade_id }) => {
  return post(`${renderApiDomain() + "deposit/monetix"}`, {
    amount_thb: amount_thb,
    account_trade_id: account_trade_id,
  });
};

const Monetix = (props) => {
  const selectIp = useSelector(({ user: { ipAddress } }) => ipAddress);
  const history = useHistory();
  const refPerfect = useRef(null);
  const [loading, setLoading] = useState(false);
  const [linkPay, setLinkPay] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    onFinish();
  }, [selectIp]);
  const onFinish = async (el) => {
    try {
      setLoading(true);
      const payload = {
        account_trade_id: props.account.id,
        amount_thb: +props.amount,
      };
      const { data } = await poastMonetix(payload);

      if (data) {
        setLoading(true);

        await Promise.all([setLinkPay(data.data.link)]).then(() => {
          refPerfect.current.click();
          // setIsVisible(true);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onClose = () => {
    history.push(`/${localStorage.getItem("locale")}/wallet`);
    setIsVisible(false);
  };
  return (
    <Spin spinning={loading}>
      <a ref={refPerfect} href={linkPay} target="_blank" hidden>
        Monetix
      </a>

      {/* <Modal
        visible={isVisible}
        onCancel={() => onClose()}
        width={1000}
        footer={[
          <Button key="back" onClick={() => onClose()}>
            Close
          </Button>,
        ]}
      >
        <iframe
          src={linkPay}
          title="Monetix"
          width={"100%"}
          height={"500px"}
          style={{ border: 0 }}
        />
      </Modal> */}
    </Spin>
  );
};

export default Monetix;
