import React, { useState } from "react";
import PropTypes from "prop-types";
import CenterMenu from "./CenterMenu";
import RightMenu from "./RightMenu";
import { Link } from "react-router-dom";
import { Button, Drawer, Space } from "antd";
import { Langs } from "./listlang";
import MobileDrawer from "./MobileDrawer";
import { MessageOutlined } from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import DownloadApp from "./DownloadApp";

function Navbar(props) {
  const [backgroundImage, setBackgroundImage] = useState("");

  const onHover = (isDropdownVisible) => {
    if (isDropdownVisible) {
      setBackgroundImage(
        "radial-gradient(circle farthest-side at center bottom, rgba(0, 156, 222, 0), rgba(0, 48, 135, 0) 125%)"
      );
    } else {
      if (window.scrollY > 0) {
        setBackgroundImage(
          "radial-gradient(circle farthest-side at center bottom, rgb(54 113 147), rgb(57 115 150) 125%)"
        );
      } else {
        setBackgroundImage(
          "radial-gradient(circle farthest-side at center bottom, rgba(0, 156, 222, 0), rgba(0, 48, 135, 0) 125%)"
        );
      }
    }
  };

  return (
    <nav className="" style={{ background: "transparent" }}>
      <DownloadApp />
      <div className="menuBar col-md">
        <div className=" container">
          <div className="d-flex justify-content-between pt-3 align-items-center">
            <div className="d-md-none">
              <MobileDrawer />
            </div>
            <div id="logo " className="d-md-none">
              <Link to={`/${localStorage.getItem("locale")}`}>
                <img
                  alt="IUX"
                  src={
                    process.env.PUBLIC_URL + "/assets/images/icons/iux-logo.png"
                  }
                  width={36}
                  className="border-logo"
                />
              </Link>
            </div>
            <div id="logo" className="d-none d-md-block">
              <Link to={`/${localStorage.getItem("locale")}`}>
                <img
                  alt="IUX"
                  src={
                    process.env.PUBLIC_URL + "/assets/images/icons/iux-logo.png"
                  }
                  width={72}
                  className="border-logo"
                />
              </Link>
            </div>
            <div className="d-md-none text-white">
              {/* <MessageOutlined className="fs-24" /> */}
            </div>
            <div
              id="menu"
              style={{ width: "100%" }}
              className="d-none d-md-block mx-auto"
            >
              <CenterMenu />
            </div>
            <div className="d-none d-md-block">
              <RightMenu />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

Navbar.propTypes = {};

export default Navbar;
