import { renderApiDomain } from "../helper/renderApiDomain";
import { get, post, put, puts } from "../helper/request";

export const getProvinceList = async () => {
  return get(`${renderApiDomain()}master/province`);
};
export const getBankList = async () => {
  return get(`${renderApiDomain()}master/bank`);
};
export const getCareerList = async () => {
  return get(`${renderApiDomain()}master/career`);
};
export const getUserValidation = async () => {
  return get(`${renderApiDomain()}user/validation`);
};
export const getUserAttempt = async () => {
  return get(`${renderApiDomain()}ocr/attempt`);
};
export const putUserAttempt = async (data) => {
  return puts(`${renderApiDomain()}ocr/attempt`, data);
};
export const postJumioCreate = async () => {
  return post(`${renderApiDomain()}jumio/create`);
};
