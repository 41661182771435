import { INITIALIZE_CHANGED, LOGOUT, INITIALIZE_LOADING } from "../types";

const INITIALIZE_STATE = {
  isInitialize: false,
  isInitializeLoading: true,
};

export default (state = INITIALIZE_STATE, action) => {
  switch (action.type) {
    case INITIALIZE_CHANGED:
      return { ...state, isInitialize: action.payload };
    case INITIALIZE_LOADING:
      return { ...state, isInitializeLoading: action.payload };
    case LOGOUT:
      return { ...INITIALIZE_STATE };
    default:
      return state;
  }
};
