import React from "react";
import { BarLoader } from "react-spinners";
const langs = [
  {
    lang: "en",
    img: "/assets/images/icons/united-kingdom.svg",
    name: "English",
  },
  {
    lang: "ms",
    img: "/assets/images/icons/malaysia.svg",
    name: "Malaysia",
  },
  {
    lang: "id",
    img: "/assets/images/icons/indonesian.svg",
    name: "Indonesia",
  },
  {
    lang: "zh",
    img: "/assets/images/icons/china.svg",
    name: "China",
  },
  {
    lang: "vi",
    img: "/assets/images/icons/vietnam.svg",
    name: "Vietnam",
  },
  {
    lang: "ko",
    img: "/assets/images/icons/korea.svg",
    name: "Korea",
  },
  {
    lang: "fil",
    img: "/assets/images/icons/philippines.svg",
    name: "Philippines",
  },
  {
    lang: "lo",
    img: "/assets/images/icons/laos.svg",
    name: "Laos",
  },
  {
    lang: "th",
    img: "/assets/images/icons/thailand.svg",
    name: "ภาษาไทย",
  },
];

const Redirect = ({ onRedirect }) => {
  return (
    <div>
      <div
        className="d-block w-100"
        style={{
          // background:
          //   "url('" +
          //   process.env.PUBLIC_URL +
          //   "../assets/images/covers/img-landing.png" +
          //   "')",
          width: "100%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: "100vh",
        }}
      >
        <div
          style={{
            backgroundImage:
              "radial-gradient(circle, hsl(203deg 44% 20% / 80%), hsl(200deg 41% 17% / 80%), hsl(198deg 34% 15% / 80%), hsl(197deg 28% 13% / 80%), hsl(193deg 17% 10% / 80%))",
            height: "100%",
          }}
        >
          <div className="w-100 h-100 d-flex">
            <div
              className="m-auto text-white d-flex justify-content-center align-items-center flex-column"
              style={{ height: "100vh" }}
            >
              <div className="text-center mb-4">
                <img
                  alt="IUX"
                  className="registerlogo"
                  src={"/assets/images/icons/iux-logo.png"}
                />
              </div>
              <div>
                <BarLoader
                  color="#00AF6C"
                  height={4}
                  width={200}
                  css="border-radius: 4px;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Redirect;
