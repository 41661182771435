import React from "react";

function Inprogress() {
  return (
    <div className="text-center" style={{ minHeight: 750 }}>
      <h1 className="fs-20">In Progress</h1>
      <p className="mtc-12">
        You are in the process of verifying your identity.
      </p>
      <div className="mtc-24 mbc-24">
        <img src="/assets/images/verify/progress.svg" alt="success" />
      </div>
      <div className="mbc-24 d-flex justify-center">
        <div
          className="p-4 text-left"
          style={{ background: "#F9F9F9", borderRadius: 12 }}
        >
          <p className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.8064 4.36488C14.0658 4.62613 14.0643 5.04824 13.8031 5.30768L6.41848 12.641C6.15851 12.8992 5.73893 12.8992 5.47896 12.641L2.19691 9.38175C1.93565 9.12231 1.93418 8.70021 2.19362 8.43895C2.45306 8.1777 2.87517 8.17622 3.13643 8.43567L5.94872 11.2284L12.8636 4.36159C13.1248 4.10215 13.5469 4.10362 13.8064 4.36488Z"
                fill="#1CB894"
              />
            </svg>
            <span className="ml-2">Document Information</span>
          </p>
          <p className="mt-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.8064 4.36488C14.0658 4.62613 14.0643 5.04824 13.8031 5.30768L6.41848 12.641C6.15851 12.8992 5.73893 12.8992 5.47896 12.641L2.19691 9.38175C1.93565 9.12231 1.93418 8.70021 2.19362 8.43895C2.45306 8.1777 2.87517 8.17622 3.13643 8.43567L5.94872 11.2284L12.8636 4.36159C13.1248 4.10215 13.5469 4.10362 13.8064 4.36488Z"
                fill="#1CB894"
              />
            </svg>
            <span className="ml-2">Facial authentication</span>
          </p>
          <p className="mt-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.00004 3.16536C5.05452 3.16536 2.66671 5.55318 2.66671 8.4987C2.66671 11.4442 5.05452 13.832 8.00004 13.832C10.9456 13.832 13.3334 11.4442 13.3334 8.4987C13.3334 5.55318 10.9456 3.16536 8.00004 3.16536ZM1.33337 8.4987C1.33337 4.8168 4.31814 1.83203 8.00004 1.83203C11.6819 1.83203 14.6667 4.8168 14.6667 8.4987C14.6667 12.1806 11.6819 15.1654 8.00004 15.1654C4.31814 15.1654 1.33337 12.1806 1.33337 8.4987ZM8.00004 4.4987C8.36823 4.4987 8.66671 4.79717 8.66671 5.16536V8.4987C8.66671 8.73888 8.53751 8.96049 8.32851 9.07883C8.1195 9.19717 7.863 9.19393 7.65704 9.07036L5.99038 8.07036C5.67466 7.88093 5.57228 7.47142 5.76171 7.1557C5.95114 6.83998 6.36065 6.7376 6.67637 6.92704L7.33337 7.32124V5.16536C7.33337 4.79717 7.63185 4.4987 8.00004 4.4987Z"
                fill="#A3A3A3"
              />
            </svg>
            <span className="ml-2">Base information</span>
          </p>
        </div>
      </div>
      {/* <p className="text-primary-sub-iux">Use mobile phone</p> */}
    </div>
  );
}

export default Inprogress;
