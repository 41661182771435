import { Button, Form, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Tutorial from "../../../../components/tutorial";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const locale = require("react-redux-i18n").I18n;
function SelfieVerfy(props) {
  const { form, onChangeStep, onBackStep } = props;
  const [isFilePDF, setIsFilePDF] = useState(null);
  const [fileIndia, setFileIndia] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result;
        // .replace("data:", "")
        // .replace(/^.+,/, "");
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const uploadIndiaOption = {
    onRemove: async (fileIndia) => {
      setFileIndia(null);

      await form.setFieldsValue({ selfie: null });
      await form.validateFields(["selfie"]);
    },
    beforeUpload: async (file) => {
      const isPNG = file.type === "image/png";
      const isJPG = file.type === "image/jpg";
      const isJPEG = file.type === "image/jpeg";
      const isPDF = file.type === "application/pdf";
      const isLt2M = file.size / 1024 / 1024 < 8;
      if (!isPNG && !isJPG && !isJPEG && !isPDF) {
        message.error(`${file.name} ${locale.t("acceptedfile")}`);
        setFileIndia(null);
        await form.setFieldsValue({ selfie: null });
        // await form.validateFields(["selfie"]);
      } else if (!isLt2M) {
        message.error(locale.t("acceptedfile"));
      } else {
        if (isPDF) {
          setIsFilePDF(file);
          setFileIndia(file);
        } else if (!file.url && !file.preview) {
          file.preview = await fileToBase64(file);
          setIsFilePDF(null);
          setFileIndia(file.preview || file.url);
          // setFileIndia(`data:${file.type};base64,` + file.preview || file.url);
        } else {
          setIsFilePDF(null);
          setFileIndia(`data:${file.type};base64,` + file.preview || file.url);
        }
      }
      return false;
    },
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  useEffect(() => {
    if (form?.getFieldValue("selfie")) {
      if (form.getFieldValue("selfie").fileList[0]?.preview) {
        setFileIndia(
          `data:${form.getFieldValue("selfie").fileList[0]?.type};base64,` +
            form.getFieldValue("selfie").fileList[0]?.preview
        );
      } else {
        setIsFilePDF(form.getFieldValue("selfie").fileList[0]?.originFileObj);
        setFileIndia(form.getFieldValue("selfie").fileList[0]?.originFileObj);
      }
    }
  }, [form]);
  return (
    <div>
      <div>
        {" "}
        <>
          <div className="d-flex justify-content-center my-5 flex-column align-items-center">
            <div className="fs-20 font-weight-300">
              {locale.t("manualselfie")}
            </div>

            <div
              className="fs-16 mt-4 text-lightgray text-center"
              style={{ maxWidth: 800 }}
            >
              {locale.t("manualselfiedetail")}
            </div>
            <div className="mt-5 row">
              <img
                src={`/assets/images/verify/id-selfy.png`}
                alt={`id-selfy`}
              />
            </div>
          </div>
          <div className="text-center text-lightgray mt-4">
            {locale.t("acceptedfile")}
          </div>
          <div className="px-5">
            <hr className="my-5" />
          </div>
          <div className="mb-5">
            <div className="d-flex justify-content-center mt-5">
              <Form.Item
                className="upload-verify"
                name="selfie"
                rules={[
                  {
                    required: true,
                    // message: locale.t("dashboardFund.modalVerify.footerText3"),
                  },
                ]}
              >
                <Upload
                  className="cursor-pointer"
                  // className="iux-scan-id"
                  {...uploadIndiaOption}
                  showUploadList={false}
                  maxCount={1}
                  //   fileList={file}
                >
                  {fileIndia ? (
                    <div
                      style={{
                        maxWidth: "270px",
                        border: "1px dashed #1CB894",
                        borderRadius: 8,
                      }}
                      className="p-4 position-reletive"
                    >
                      <div
                        className="fs-20 cursor-pointer"
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 8,
                          zIndex: 8,
                        }}
                        onClick={() => {
                          form.setFieldsValue({ selfie: null });
                          setFileIndia(null);
                        }}
                      >
                        x
                      </div>
                      {isFilePDF ? (
                        <Document
                          file={fileIndia}
                          onLoadSuccess={onDocumentLoadSuccess}
                        >
                          <Page
                            width={200}
                            pageIndex={0}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                          />
                        </Document>
                      ) : (
                        <img
                          src={fileIndia}
                          alt="file"
                          width={"100%"}
                          style={{ borderRadius: 8 }}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="iux-scan-id ">
                      <img
                        src="/assets/images/verify/uploadfile.svg"
                        alt="uploadfile"
                      />
                    </div>
                  )}
                </Upload>
              </Form.Item>
            </div>
            <div className="text-center mt-3"> {locale.t("uploadfile")} </div>

            <div
              className="text-center mt-3 text-lightdark mx-auto font-weight-300"
              style={{ maxWidth: 800 }}
            >
              {locale.t("anotherdeviceDetail")}
            </div>
            <div className="text-center mt-3 text-primary-sub-iux cursor-pointer">
              <Tutorial isButton />
            </div>
          </div>
          <Form.Item shouldUpdate>
            {() => (
              <div className="text-center">
                <Button
                  disabled={
                    form.getFieldValue("selfie") === undefined ||
                    form.getFieldValue("selfie") === null ||
                    fileIndia === null
                  }
                  // type="submit"
                  // loading={loading}
                  onClick={() => onChangeStep()}
                  className="btn-primary-new-iux"
                >
                  {locale.t("continue")}
                </Button>
              </div>
            )}
          </Form.Item>
          <div
            className="text-center mt-3 text-primary-sub-iux cursor-pointer"
            onClick={() => onBackStep(2)}
          >
            {locale.t("back")}
          </div>
        </>
      </div>
    </div>
  );
}

export default SelfieVerfy;
