export const CheckPhoneCode = (id) => {
  switch (id) {
    case 1:
      return { code: "+66" };
    case 2:
      return { code: "+44" };
    case 3:
      return { code: "+1" };
    case 4:
      return { code: "+86" };
    case 5:
      return { code: "+65" };
    case 6:
      return { code: "+60" };
    case 7:
      return { code: "+91" };
    case 8:
      return { code: "+856" };
    case 9:
      return { code: "+62" };
    case 10:
      return { code: "+84" };
    case 11:
      return { code: "+95" };
    case 12:
      return { code: "+63" };
    case 13:
      return { code: "+81" };
    case 14:
      return { code: "+81" };
    case 15:
      return { code: "+55" };
    case 16:
      return { code: "+52" };
    case 17:
      return { code: "+56" };
    case 18:
      return { code: "+57" };
    case 19:
      return { code: "+51" };
    case 20:
      return { code: "+234" };
    case 21:
      return { code: "+234" };
    case 22:
      return { code: "+254" };
    case 23:
      return { code: "+233" };
    case 24:
      return { code: "+1" };
    case 25:
      return { code: "+593" };
    case 26:
      return { code: "+92" };
    case 27:
      return { code: "+27" };
    default:
      return { code: "" };
  }
};
