import Axios from "axios";
import { getAuth, signInWithEmailAndPassword, getIdToken } from "firebase/auth";
import { getuser } from "../firebase";
export const get = async (url, withAuth = true) => {
  const headers = {
    headers: {
      Verification: "new-flow",
      "Auth-Provider": ["firebase"],
      "Auth-Type": ["client"],
      Authorization: withAuth ? `Bearer ${await getuser()}` : null,
      Language: localStorage.getItem("locale"),
    },
  };
  return Axios.get(url, headers);
};

export const post = async (url, data = {}, withAuth = true) => {
  const headers = {
    headers: {
      "Auth-Provider": ["firebase"],
      "Auth-Type": ["client"],
      Authorization: withAuth ? `Bearer ${await getuser()}` : null,
    },
  };
  return Axios.post(url, data, headers);
};
export const postPayment = async (url, data = {}, withAuth = true) => {
  const headers = {
    headers: {
      "Auth-Provider": ["firebase"],
      "Auth-Type": ["client"],
      Authorization: withAuth ? `Bearer ${await getuser()}` : null,
    },
    responseType: "arraybuffer",
  };
  return Axios.post(url, data, headers);
};
export const patch = async (url, data = {}, withAuth = true) => {
  const headers = {
    headers: {
      "Auth-Provider": ["firebase"],
      "Auth-Type": ["client"],
      Authorization: withAuth ? `Bearer ${await getuser()}` : null,
    },
  };
  return Axios.patch(url, data, headers);
};

export const put = async (url, data = {}, withAuth = true) => {
  const headers = withAuth
    ? {
        "Auth-Provider": ["firebase"],
        "Auth-Type": ["client"],
        Authorization: withAuth ? `Bearer ${await getuser()}` : null,
      }
    : null;
  return Axios.post(url, data, headers);
};
export const puts = async (url, data = {}, withAuth = true) => {
  const headers = withAuth
    ? {
        headers: {
          "Auth-Provider": ["firebase"],
          "Auth-Type": ["client"],
          Authorization: withAuth ? `Bearer ${await getuser()}` : null,
          Language: localStorage.getItem("locale"),
        },
      }
    : null;
  return Axios.put(url, data, headers);
};
export const del = async (url, withAuth = true) => {
  const headers = withAuth
    ? {
        headers: {
          "Auth-Provider": ["firebase"],
          "Auth-Type": ["client"],
          Authorization: withAuth ? `Bearer ${await getuser()}` : null,
        },
      }
    : null;
  return Axios.delete(url, headers);
};
export const deleted = async (url, data = {}, withAuth = true) => {
  const headers = withAuth
    ? {
        headers: {
          "Auth-Provider": ["firebase"],
          "Auth-Type": ["client"],
          Authorization: withAuth ? `Bearer ${await getuser()}` : null,
        },
      }
    : null;
  return Axios.delete(url, headers);
};
