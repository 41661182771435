export default {
  th: {
    promo_contain1: {
      title: "รับโบนัส $30 เมื่อเปิดบัญชี Welcome",
      section1: "ไม่ต้องมีเงินฝาก",
      section2: "เครดิตเข้าบัญชีของคุณโดยอัตโนมัติ",
      section3: "กำไรที่ได้รับทั้งหมดสามารถถอนออกได้",
      section4: "พร้อมให้บริการลูกค้าใหม่",
      section5: "โบนัสไม่สามารถถอนออกได้",
    },
    promo_contain2: "โบนัสเงินฝาก สูงถึง $300",
    promo_contain3: "โบนัสเงินฝาก สูงถึง $3,000",
    promo_contain4: {
      title: "รับโบนัสได้อย่างไร",
      section1: {
        title: "ลงทะเบียนกับ IUX Markets",
        des: "ลงทะเบียนกับ IUX Markets บนเว็บไซต์ของเราหรือ บนแอปพลิเคชันได้อย่างรวดเร็วใน 5 นาที",
      },
      section2: {
        title: "การยืนยันตัวตน",
        des: "ยืนยันตัวตน  (กระบวนการ POI) เพื่อเปิดบัญชีเทรดกับ IUX Markets ได้ง่ายๆ บนเว็บไซต์หรือแอปพลิเคชัน",
      },
      section3: {
        title: "เปิดบัญชีเทรด",
        des: "เลือกเปิดบัญชีต้อนรับเพื่อรับโบนัส $30 โดยไม่ต้อง ฝากเงินหรือเลือกเปิดบัญชี Standard+ เพื่อรับโบนัส เงินฝากสูงถึง 35%",
      },
    },
  },
  lo: {
    promo_contain1: {
      title: "ຮັບໂບນັດ $30 ເມື່ອທ່ານເປີດບັນຊີ Welcome",
      section1: "ບໍ່ຈໍາເປັນຕ້ອງມີເງິນຝາກ",
      section2: "ໂບນັດໂອນເຂົ້າບັນຊີຂອງທ່ານໂດຍອັດຕະໂນມັດ",
      section3: "ກໍາໄລທັງຫມົດທີ່ໄດ້ຮັບສາມາດຖອນໄດ້",
      section4: "ມີໃຫ້ລູກຄ້າໃໝ່",
      section5: "ໂບນັດບໍ່ສາມາດຖອນໄດ້",
    },
    promo_contain2: "ໂບນັດເງິນຝາກ ສູງເຖິງ $300",
    promo_contain3: "ໂບນັດເງິນຝາກ ສູງເຖິງ $3,000",
    promo_contain4: {
      title: "ຈະໄດ້ຮັບໂບນັດແນວໃດ?",
      section1: {
        title: "ລົງທະບຽນກັບ IUX Markets",
        des: "ລົງທະບຽນກັບ IUX Markets ຢູ່ໃນເວັບໄຊທ໌ຂອງພວກເຮົາຫຼືແອັບຢ່າງໄວວາໃນ 5 ນາທີ",
      },
      section2: {
        title: "ຢືນຢັນຕົວຕົນ",
        des: "ຢັ້ງຢືນຕົວຕົນຂອງທ່ານ (ຂະບວນການ POI) ເພື່ອເປີດບັນຊີການຄ້າກັບ IUX Markets ໄດ້ຢ່າງງ່າຍດາຍຢູ່ໃນເວັບໄຊທ໌ຫຼືແອັບຂອງພວກເຮົາ",
      },
      section3: {
        title: "ເປີດບັນຊີຊື້ຂາຍ",
        des: "ເປີດບັນຊີຕ້ອນຮັບເພື່ອຮັບໂບນັດທີ່ບໍ່ມີເງິນຝາກ $30 ຫຼືບັນຊີ Standard+ ເພື່ອຮັບໂບນັດເງິນຝາກສູງສຸດ 35%.",
      },
    },
  },
  en: {
    promo_contain1: {
      title: "BONUS OPEN WELCOME ACCOUNT $30",
      section1: "No deposit required",
      section2: "Automatically credited to your account",
      section3: "All profits earned can be withdrawn",
      section4: "Available to new clients",
      section5: "Non-withdrawable bonus",
    },
    promo_contain2: "DEPOSIT BONUS UP TO $300",
    promo_contain3: "DEPOSIT BONUS UP TO $3,000",
    promo_contain4: {
      title: "HOW TO GET BONUS?",
      section1: {
        title: "Register with IUX Markets",
        des: "Register with IUX Markets on our website or application quickly in 5 minutes. ",
      },
      section2: {
        title: "Verify identity",
        des: "Verify your identity (POI process) to open a trading account with IUX Markets easily on our website or application.",
      },
      section3: {
        title: "Open trading account",
        des: "Open Welcome account to receive a no deposit bonus of $30 or Standard+ account to get maximum 35% deposit bonus.",
      },
    },
  },
  hi: {
    promo_contain1: {
      title: "बोनस ओपन वेलकम अकाउंट $30",
      section1: "किसी जमा की आवश्यकता नहीं",
      section2: "बोनस स्वचालित रूप से आपके खाते में जमा हो जाता है।",
      section3: "अर्जित सभी लाभ वापस लिए जा सकते हैं",
      section4: "नए ग्राहकों के लिए उपलब्ध है",
      section5: "गैर-निकासी योग्य बोनस",
    },
    promo_contain2: "जमा बोनस $300 तक",
    promo_contain3: "$3,000 तक जमा बोनस",
    promo_contain4: {
      title: "बोनस कैसे प्राप्त करें?",
      section1: {
        title: "IUX Markets के साथ पंजीकरण करें",
        des: "हमारी वेबसाइट या एप्लिकेशन पर 5 मिनट में जल्दी से IUX Markets के साथ पंजीकरण करें",
      },
      section2: {
        title: "पहचान सत्यापित करें",
        des: "हमारी वेबसाइट या एप्लिकेशन पर आसानी से IUX Markets के साथ एक ट्रेडिंग खाता खोलने के लिए अपनी पहचान (POI प्रक्रिया) सत्यापित करें।",
      },
      section3: {
        title: "ट्रेडिंग खाता खोलें",
        des: "$30 का नो डिपॉज़िट बोनस प्राप्त करने के लिए वेलकम खाता खोलें या अधिकतम 35% डिपॉज़िट बोनस प्राप्त करने के लिए Standard+ खाता खोलें।",
      },
    },
  },
  ms: {
    promo_contain1: {
      title: "BONUS AKAUN ALU-ALUAN TERBUKA $30",
      section1: "Tiada deposit diperlukan",
      section2: "Dikreditkan secara automatik ke akaun anda",
      section3: "Semua keuntungan yang diperolehi boleh dikeluarkan",
      section4: "Tersedia untuk pelanggan baharu",
      section5: "Bonus tidak boleh dikeluarkan",
    },
    promo_contain2: "BONUS DEPOSIT SEHINGGA $300",
    promo_contain3: "BONUS DEPOSIT SEHINGGA $3,000",
    promo_contain4: {
      title: "MACAM MANA NAK DAPAT BONUS?",
      section1: {
        title: "Daftar dengan IUX Markets",
        des: "Daftar dengan IUX Markets di laman web atau aplikasi kami dengan cepat dalam 5 minit.",
      },
      section2: {
        title: "Sahkan identiti",
        des: "Sahkan identiti anda (proses POI) untuk membuka akaun dagangan dengan IUX Markets dengan mudah di tapak web atau aplikasi kami.",
      },
      section3: {
        title: "Buka akaun dagangan",
        des: "Buka akaun Welcome untuk menerima bonus tanpa deposit sebanyak $30 atau akaun Standard+ untuk mendapatkan bonus deposit maksimum 35%.",
      },
    },
  },
  id: {
    promo_contain1: {
      title: "BONUS BUKA AKUN SELAMAT DATANG $30",
      section1: "Tidak diperlukan setoran",
      section2: "Secara otomatis dikreditkan ke akun Anda",
      section3: "Semua keuntungan yang diperoleh dapat ditarik",
      section4: "Tersedia untuk klien baru",
      section5: "Bonus yang tidak dapat ditarik",
    },
    promo_contain2: "BONUS DEPOSIT HINGGA $300",
    promo_contain3: "BONUS DEPOSIT HINGGA $3,000",
    promo_contain4: {
      title: "BAGAIMANA CARA MENDAPATKAN BONUS?",
      section1: {
        title: "Daftar dengan IUX Markets",
        des: "Daftar ke IUX Markets di website atau aplikasi kami dengan cepat dalam 5 menit.",
      },
      section2: {
        title: "Verifikasi identitas",
        des: "Verifikasi identitas Anda (proses POI) untuk membuka akun perdagangan dengan IUX Markets dengan mudah di situs web atau aplikasi kami.",
      },
      section3: {
        title: "Buka akun perdagangan",
        des: "Buka akun Selamat Datang untuk menerima bonus tanpa deposit sebesar $30 atau akun Standard+ untuk mendapatkan bonus deposit maksimum 35%.",
      },
    },
  },
  zh: {
    promo_contain1: {
      title: "开设 WELCOME 账户即可获得 $30 奖金",
      section1: "无需押金",
      section2: "自动存入您的帐户",
      section3: "所有赚取的利润都可以提取",
      section4: "可供新客户使用",
      section5: "不可提取的奖金",
    },
    promo_contain2: "存款奖金高达 300 美元",
    promo_contain3: "存款奖金高达 3,000 美元",
    promo_contain4: {
      title: "如何获得奖金？",
      section1: {
        title: "注册 IUX Markets",
        des: "只需 5 分钟即可在我们的网站或应用程序上快速注册 IUX Markets。",
      },
      section2: {
        title: "验证身份",
        des: "验证您的身份（POI 流程），以便在我们的网站或应用程序上轻松开设 IUX Markets 交易账户。",
      },
      section3: {
        title: "开设交易账户",
        des: "开设 Welcome 账户可获得 30 美元的无存款奖金，或开设 Standard+ 账户可获得最高 35% 的存款奖金。",
      },
    },
  },
  vi: {
    promo_contain1: {
      title: "Tiền thưởng mở tài khoản chào mừng $30",
      section1: "Không yêu cầu tiền đặt cọc",
      section2: "Tự động ghi có vào tài khoản của bạn",
      section3: "Tất cả lợi nhuận kiếm được có thể được rút",
      section4: "Có sẵn cho khách hàng mới",
      section5: "Tiền thưởng không thể rút",
    },
    promo_contain2: "TIỀN THƯỞNG TIỀN GỬI LÊN ĐẾN $300",
    promo_contain3: "TIỀN THƯỞNG TIỀN GỬI LÊN ĐẾN $3.000",
    promo_contain4: {
      title: "LÀM THẾ NÀO ĐỂ NHẬN TIỀN THƯỞNG?",
      section1: {
        title: "Đăng ký với IUX Markets",
        des: "Đăng ký nhanh chóng với IUX Markets trên trang web hoặc ứng dụng của chúng tôi trong 5 phút.",
      },
      section2: {
        title: "Xác minh danh tính của bạn",
        des: "Xác minh danh tính của bạn (quy trình POI) để mở tài khoản giao dịch với IUX Markets một cách dễ dàng trên trang web hoặc ứng dụng của chúng tôi.",
      },
      section3: {
        title: "Mở tài khoản giao dịch",
        des: "Mở tài khoản Chào mừng để nhận tiền thưởng không cần gửi tiền trị giá $30 hoặc tài khoản Standard+ để nhận tiền thưởng tiền gửi tối đa 35%.",
      },
    },
  },
  ko: {
    promo_contain1: {
      title: "WELCOME 계좌 개설 시 $30 보너스 받기",
      section1: "보증금이 필요하지 않습니다",
      section2: "귀하의 계좌에 자동으로 적립됩니다",
      section3: "벌어들인 이익은 모두 인출 가능",
      section4: "신규 고객에게 제공",
      section5: "인출 불가능한 보너스",
    },
    promo_contain2: "최대 $300까지 입금 보너스",
    promo_contain3: "최대 $3,000까지 입금 보너스",
    promo_contain4: {
      title: "보너스를 받는 방법?",
      section1: {
        title: "IUX Markets 에 등록",
        des: "웹사이트나 애플리케이션을 통해 IUX Markets 에 5분 만에 빠르게 등록하세요.",
      },
      section2: {
        title: "신원 확인",
        des: "당사 웹사이트나 애플리케이션에서 쉽게 IUX Markets 거래 계좌를 개설하려면 신원(POI 프로세스)을 확인하세요.",
      },
      section3: {
        title: "거래계좌 개설",
        des: "Welcome 개설하여 $30의 무입금 보너스를 받거나 Standard+ 계좌를 개설하여 최대 35% 입금 보너스를 받으세요.",
      },
    },
  },
  fil: {
    promo_contain1: {
      title: "BONUS OPEN WELCOME ACCOUNT $30",
      section1: "Walang kinakailangang deposito",
      section2: "Awtomatikong na-kredito sa iyong account",
      section3: "Ang lahat ng kinita ay maaaring bawiin",
      section4: "Magagamit sa mga bagong kliyente",
      section5: "Non-withdrawable bonus",
    },
    promo_contain2: "DEPOSIT BONUS HANGGANG $300",
    promo_contain3: "DEPOSIT BONUS HANGGANG $3,000",
    promo_contain4: {
      title: "PAANO MAKAKUHA NG BONUS?",
      section1: {
        title: "Magrehistro sa IUX Markets",
        des: "Magrehistro sa IUX Markets sa aming website o application nang mabilis sa loob ng 5 minuto.",
      },
      section2: {
        title: "I-verify ang pagkakakilanlan",
        des: "I-verify ang iyong pagkakakilanlan (proseso ng POI) upang madaling magbukas ng trading account sa IUX Markets sa aming website o application.",
      },
      section3: {
        title: "Buksan ang trading account",
        des: "Buksan ang Welcome account para makatanggap ng walang depositong bonus na $30 o Standard+ account para makakuha ng maximum na 35% na deposito na bonus.",
      },
    },
  },
  ja: {
    promo_contain1: {
      title: "ボーナス オープン ウェルカム アカウント $30",
      section1: "デポジットは必要ありません",
      section2: "アカウントに自動的に入金されます",
      section3: "得た利益はすべて出金可能",
      section4: "新規顧客にサービスを提供する",
      section5: "ボーナスは出金できません。",
    },
    promo_contain2: "最大 $300 の入金ボーナス",
    promo_contain3: "最大 $3,000 の入金ボーナス",
    promo_contain4: {
      title: "ボーナスを獲得するには?",
      section1: {
        title: "IUX Markets に登録する",
        des: "当社の Web サイトまたはアプリケーションで IUX Markets に 5 分で簡単に登録できます。",
      },
      section2: {
        title: "本人確認",
        des: "本人確認 (POI プロセス) を行って、当社の Web サイトまたはアプリケーションで IUX Markets の取引口座を簡単に開設してください。",
      },
      section3: {
        title: "取引口座を開く",
        des: "ウェルカム アカウントを開いて 30 ドルの入金不要ボーナスを獲得するか、スタンダード + アカウントを開設して最大 35% の入金ボーナスを獲得してください。",
      },
    },
  },
};
