import { Layout, Tabs } from "antd";
import React from "react";
import { Helmet } from "react-helmet";
import Commission from "../friend/Commission";
import styled from "styled-components";

import { Link, Route, Switch } from "react-router-dom";
import SwapNew from "./swap/SwapNew";
import Bonus from "./bonus/Bonus";
import NegativeBalanceProtection from "./balanceProtection/BalanceProtection";
import ZeroStopLevel from "./zeroStopLevel/ZeroStopLevel";

const { TabPane } = Tabs;
const locale = require("react-redux-i18n").I18n;
const TITLE = "Promotion | IUX Markets";

function Promotion() {
  const [isIdex, setIndex] = React.useState(0);
  const pathName = window.location.pathname;
  const MenuItem = styled.div`
    padding: 0.8rem 1rem;
    color: ${(props) => props.color};
    border-bottom: ${(props) => props.borderBottom};
    white-space: pre;
    font-weight: ${(props) => props.fontWeight};
  `;
  const menu = [
    {
      id: 0,
      link: `/${localStorage.getItem("locale")}/wallet/promotion/lotback`,
      text: "LotBack",
    },
    {
      id: 1,
      link: `/${localStorage.getItem("locale")}/wallet/promotion/swap`,
      text: "Swap",
    },
    {
      id: 2,
      link: `/${localStorage.getItem("locale")}/wallet/promotion/bonus`,
      text: `${locale.t("dashbordCommission.irpoint_bonus")}`,
    },
    {
      id: 3,
      link: `/${localStorage.getItem(
        "locale"
      )}/wallet/promotion/negative-balance-protection`,
      text: locale.t("stopOut.title"),
    },
    {
      id: 4,
      link: `/${localStorage.getItem(
        "locale"
      )}/wallet/promotion/zero-stop-level`,
      text: locale.t("zeroStopLevel.title"),
    },
  ];
  return (
    <div>
      {" "}
      <Layout
        style={{
          padding: "24px 0px",
          position: "relative",
          maxWidth: "1160px",
        }}
      >
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <div className="bg-white" style={{ borderRadius: 20 }}>
          <div className="d-flex justify-content-between align-items-end  p-4">
            <p className="text-header-account-wallet text-left w-100">
              {locale.t("startbonus")}
            </p>
          </div>
          <div
            className="sub-menu-page-user mt-4"
            style={{ borderBottom: "1px solid #D9D9D9" }}
          >
            <div className="d-flex overflow-auto scroll-menu-hide px-3">
              {menu.map((e, i) => (
                <Link to={e.link} key={i}>
                  <MenuItem
                    onClick={() => setIndex(i)}
                    color={
                      e.link === pathName
                        ? "#000"
                        : pathName ===
                            `/${localStorage.getItem(
                              "locale"
                            )}/wallet/promotion` && e.id === 0
                        ? "#000"
                        : "#8D8D8D"
                    }
                    borderBottom={
                      e.link === pathName
                        ? "4px solid #1CB894"
                        : pathName ===
                            `/${localStorage.getItem(
                              "locale"
                            )}/wallet/promotion` && e.id === 0
                        ? "4px solid #1CB894"
                        : "2px solid #ff000000"
                    }
                    fontWeight={
                      e.link === pathName
                        ? "600"
                        : pathName ===
                            `/${localStorage.getItem(
                              "locale"
                            )}/wallet/promotion` && e.id === 0
                        ? "600"
                        : "unset"
                    }
                  >
                    {e.text}
                  </MenuItem>
                </Link>
              ))}
            </div>
          </div>
          <div className="px-3 px-sm-4 pb-4">
            <Switch>
              <Route
                path={`/:lang/wallet/Promotion/lotback`}
                render={() => {
                  return <Commission />;
                }}
              />
              <Route
                path={`/:lang/wallet/Promotion/swap`}
                render={() => {
                  return <SwapNew />;
                }}
              />
              <Route
                path={`/:lang/wallet/Promotion/bonus`}
                render={() => {
                  return <Bonus />;
                }}
              />
              <Route
                path={`/:lang/wallet/Promotion/negative-balance-protection`}
                render={() => {
                  return <NegativeBalanceProtection />;
                }}
              />
              <Route
                path={`/:lang/wallet/Promotion/zero-stop-level`}
                render={() => {
                  return <ZeroStopLevel />;
                }}
              />
            </Switch>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default Promotion;
