export const loLocale = {
  lang: {
    locale: "lo_LA",
    placeholder: "ເລືອກວັນທີ",
    rangePlaceholder: ["ວັນເລີ່ມ", "ວັນສິ້ນສຸດ"],
    today: "ມື້ນີ້",
    now: "ດັ່ງນີ້",
    backToToday: "ກັບໄປມື້ນີ້",
    ok: "ຕົກລົງ",
    clear: "ລ້າງ",
    month: "ເດືອນ",
    year: "ປີ",
    timeSelect: "ເລືອກເວລາ",
    dateSelect: "ເລືອກວັນທີ",
    monthSelect: "ເລືອກເດືອນ",
    yearSelect: "ເລືອກປີ",
    decadeSelect: "ເລືອກສົງຢູນ",
    yearFormat: "YYYY",
    dateFormat: "M/D/YYYY",
    dayFormat: "D",
    dateTimeFormat: "M/D/YYYY HH:mm:ss",
    monthFormat: "MMMM",
    monthBeforeYear: true,
    previousMonth: "ເດືອນກ່ອນ (PageUp)",
    nextMonth: "ເດືອນຖັງ (PageDown)",
    previousYear: "ປີກ່ອນ (Control + ຊົ່ວກ່ອນ)",
    nextYear: "ປີຖັງ (Control + ຊົ່ວຖັງ)",
    previousDecade: "ສົງຢູນກ່ອນ",
    nextDecade: "ສົງຢູນຖັງ",
    previousCentury: "ສົງຢູນກ່ອນ",
    nextCentury: "ສົງຢູນຖັງ",
    shortWeekDays: ["ອາທິດ", "ຈັນ", "ອັງຄານ", "ພຸດ", "ພະຫັດ", "ສຸກ", "ເສົາ"],
    shortMonths: [
      "ມັງກອກ",
      "ກຸມພາ",
      "ມີນາ",
      "ເມສາ",
      "ພຶດສະພາ",
      "ມິຖຸນາ",
      "ກໍລະກາ",
      "ສິຖຸນາ",
      "ກັນຍາ",
      "ຕຸລາ",
      "ພະຈິກ",
      "ທັນວາ",
    ],
  },
  timePickerLocale: {
    placeholder: "ເລືອກເວລາ",
  },
  dateFormat: "YYYY-MM-DD",
  dateTimeFormat: "YYYY-MM-DD HH:mm:ss",
  weekFormat: "YYYY-ອາຈານ",
  monthFormat: "YYYY-MM",
};
export const loasLocale = {
  locale: "lo_LA",
  Pagination: {
    items_per_page: "/ ໜ້າ",
    jump_to: "ໄປຫາ",
    jump_to_confirm: "ຢຶນຢັນ",
    page: "",
    prev_page: "ໜ້າກ່ອນ",
    next_page: "ໜ້າຖັງ",
    prev_5: "ກັບຫິດ 5 ໜ້າ",
    next_5: "ກັບຖັງ 5 ໜ້າ",
    prev_3: "ກັບຫິດ 3 ໜ້າ",
    next_3: "ກັບຖັງ 3 ໜ້າ",
  },
  DatePicker: {
    lang: {
      placeholder: "ເລືອກວັນທີ",
      rangePlaceholder: ["ວັນເລີ່ມ", "ວັນສິ້ນສຸດ"],
      locale: "lo_LA",
      today: "ມື້ນີ້",
      now: "ດັ່ງກ່ອນ",
      backToToday: "ກັບໄປມື້ນີ້",
      ok: "ຕົກລົງ",
      clear: "ລ້າງ",
      month: "ເດືອນ",
      year: "ປີ",
      timeSelect: "ເລືອກເວລາ",
      dateSelect: "ເລືອກວັນທີ",
      monthSelect: "ເລືອກເດືອນ",
      yearSelect: "ເລືອກປີ",
      decadeSelect: "ເລືອກສົງຢູນ",
      yearFormat: "YYYY",
      dateFormat: "D/M/YYYY",
      dayFormat: "D",
      dateTimeFormat: "D/M/YYYY HH:mm:ss",
      monthBeforeYear: true,
      previousMonth: "ເດືອນກ່ອນ (PageUp)",
      nextMonth: "ເດືອນຖັງ (PageDown)",
      previousYear: "ປີກ່ອນ (Control + ຊົ່ວກ່ອນ)",
      nextYear: "ປີຖັງ (Control + ຊົ່ວຖັງ)",
      previousDecade: "ສົງຢູນກ່ອນ",
      nextDecade: "ສົງຢູນຖັງ",
      previousCentury: "ສົງຢູນກ່ອນ",
      nextCentury: "ສົງຢູນຖັງ",
    },
    timePickerLocale: {
      placeholder: "ເລືອກເວລາ",
    },
  },
  TimePicker: {
    placeholder: "ເລືອກເວລາ",
  },
  Calendar: {
    lang: {
      placeholder: "ເລືອກວັນທີ",
      rangePlaceholder: ["ວັນເລີ່ມ", "ວັນສິ້ນສຸດ"],
      locale: "lo_LA",
      today: "ມື້ນີ້",
      now: "ດັ່ງກ່ອນ",
      backToToday: "ກັບໄປມື້ນີ້",
      ok: "ຕົກລົງ",
      clear: "ລ້າງ",
      month: "ເດືອນ",
      year: "ປີ",
      timeSelect: "ເລືອກເວລາ",
      dateSelect: "ເລືອກວັນທີ",
      monthSelect: "ເລືອກເດືອນ",
      yearSelect: "ເລືອກປີ",
      decadeSelect: "ເລືອກສົງຢູນ",
      yearFormat: "YYYY",
      dateFormat: "D/M/YYYY",
      dayFormat: "D",
      dateTimeFormat: "D/M/YYYY HH:mm:ss",
      monthBeforeYear: true,
      previousMonth: "ເດືອນກ່ອນ (PageUp)",
      nextMonth: "ເດືອນຖັງ (PageDown)",
      previousYear: "ປີກ່ອນ (Control + ຊົ່ວກ່ອນ)",
      nextYear: "ປີຖັງ (Control + ຊົ່ວຖັງ)",
      previousDecade: "ສົງຢູນກ່ອນ",
      nextDecade: "ສົງຢູນຖັງ",
      previousCentury: "ສົງຢູນກ່ອນ",
      nextCentury: "ສົງຢູນຖັງ",
    },
    timePickerLocale: {
      placeholder: "ເລືອກເວລາ",
    },
  },
  global: {
    placeholder: "ກະລຸນາເລືອກ",
  },
  Table: {
    filterTitle: "ການກຳນົດ",
    filterConfirm: "ຢືນຢັນ",
    filterReset: "ລ້າງໃໝ່",
    selectAll: "ເລືອກທັງມັນຂອງຫນ້ານີ້",
    selectInvert: "ສັ້ນລັກສຳກັນ",
    sortTitle: "ຮຽກຮ້ອງ",
    expand: "ເບີມຄັ້ງຂ້າງ",
    collapse: "ບິດບາດຄັ້ງຂ້າງ",
  },
  Modal: {
    okText: "ຕົກລົງ",
    cancelText: "ຍົກເລີກ",
    justOkText: "ຕົກລົງ",
  },
  Popconfirm: {
    okText: "ຕົກລົງ",
    cancelText: "ຍົກເລີກ",
  },
  Transfer: {
    titles: ["", ""],
    searchPlaceholder: "ຄົ້ນຫາທີ່ນີ້",
    itemUnit: "ລາຍການ",
    itemsUnit: "ລາຍການ",
  },
  Upload: {
    uploading: "ກຳລັງອັບໂຫຼດ...",
    removeFile: "ລຶບໄຟ",
    uploadError: "ມີຂໍ້ຜິດຜາດໃນການອັບໂຫຼດ",
    previewFile: "ເບີປຽຣດໄຟ",
    downloadFile: "ດາວໂຫຼດໄຟ",
  },
  Empty: {
    description: "ບໍ່ມີຂໍ້ມູນ",
  },
  Icon: {
    icon: "ຮູບມີຂໍ້ມູນ",
  },
  Text: {
    edit: "ແກ້ໄຂ",
    copy: "ຄັດສ້ອມ",
    copied: "ຄັດສ້ອມຮັບເປັນຂໍ້ມູນ",
    expand: "ຂຽດ",
  },
  PageHeader: {
    back: "ກັບຫິດ",
  },
};
