import React, { useEffect, useState } from "react";
import {
  getCheckRegisterWebinar,
  getIframeWebinar,
} from "../../../api/webinar";
import ModalRegister from "./ModalRegister";
import LoadingIux from "../../../components/loading";
import { useHistory } from "react-router-dom";
import { Skeleton } from "antd";
import ModalComingSoon from "./ModalComingSoon";

function Webinar() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [showWebinar, setShowWebinar] = useState(false);
  const [registerOpen, setRegisterOpen] = useState(false);
  const [linkIframe, setLinkIframe] = useState(null);
  const [showComingSoon, setShowComingSoon] = useState(false);
  useEffect(() => {
    fetchIframeLink();
  }, []);
  const fetchCheckRegister = async () => {
    try {
      const { data } = await getCheckRegisterWebinar();

      if (data.data.is_registered === 0) {
        setIsLoading(false);
        setShowWebinar(false);
        setRegisterOpen(true);
      } else {
        setShowWebinar(true);
        setIsLoading(false);
      }
    } catch (error) {
      setShowWebinar(false);
      setRegisterOpen(true);
      console.log(error);
    }
  };
  const onClose = () => {
    history.push(`/${localStorage.getItem("locale")}/wallet/`);
  };
  const fetchIframeLink = async () => {
    try {
      const { data } = await getIframeWebinar();
      if (data?.data?.iframe) {
        setLinkIframe(data?.data?.iframe);
        fetchCheckRegister();
        // setIsLoading(false);
      } else {
        setShowComingSoon(true);
        setShowWebinar(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div style={{ height: "80vh" }}>
      {isLoading ? (
        <LoadingIux />
      ) : (
        <>
          {!showComingSoon ? (
            <div
              className="w-100"

              //   style="width:100%;height:0px;position:relative;padding-bottom:56.25%;"
            >
              {!registerOpen ? (
                <>
                  <div dangerouslySetInnerHTML={{ __html: linkIframe }} />
                  {/* <div>{linkIframe}</div> */}
                </>
              ) : (
                // <iframe
                //   src={linkIframe}
                //   //   src="https://streamyard.com/watch/3h6i7Ajhdnb5?embed=true"
                //   width="100%"
                //   height="100%"
                //   frameborder="0"
                //   allow="autoplay; fullscreen"
                //   //   style="width:100%;height:100%;position:absolute;left:0px;top:0px;overflow:hidden;"
                // ></iframe>
                <div className="overflow-hidden">
                  {/* <Skeleton.Input
                    active={true}
                    size={"auto"}
                    className="w-100"
                  /> */}
                  <ModalRegister isOpen={registerOpen} onClose={onClose} />
                </div>
              )}
            </div>
          ) : (
            <div className="overflow-hidden">
              {/* <Skeleton.Input active={true} size={"auto"} className="w-100" />{" "} */}
              <ModalComingSoon isOpen={showComingSoon} onClose={onClose} />
              {/* <ModalRegister isOpen={showComingSoon} onClose={onClose} /> */}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Webinar;
