import { Button, Statistic } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Tutorial from "../../../../components/tutorial";
const { Countdown } = Statistic;
const locale = require("react-redux-i18n").I18n;
function BlockVerify(props) {
  const { blockType, isTimeCountdown, dataReason, onChangeManualVerify } =
    props;
  const history = useHistory();
  const [isDisable, setisDisable] = useState(true);
  const [timeCoundown, setTimeCoundown] = useState();
  const onFinnish = () => {
    setisDisable(false);
  };
  useEffect(() => {
    setTimeCoundown(moment().diff(moment(isTimeCountdown), "seconds"));
  }, [isTimeCountdown]);
  return (
    <div className="d-flex justify-content-center my-5 flex-column align-items-center">
      <div className="fs-16">
        {blockType === "temp-block"
          ? locale.t("tempblock")
          : locale.t("permanently")}
      </div>
      <div className="iux-scan  d-flex justify-content-center mt-4">
        {blockType === "blocked" ? (
          <img src="/assets/images/verify/blockedall.svg" alt="blockedall" />
        ) : (
          <img src="/assets/images/verify/blocked.svg" alt="blocked" />
        )}
      </div>
      <div className="text-center mt-3 text-primary-sub-iux cursor-pointer">
        <Tutorial isButton />
      </div>
      {blockType === "temp-block" && (
        <div className="mt-3">
          {isDisable && (
            <Countdown
              title={locale.t("countdown")}
              value={moment()
                .add(300 - timeCoundown, "s")
                .toDate()}
              onFinish={onFinnish}
            />
          )}
        </div>
      )}
      <div
        className="fs-12 mt-4 text-lightgray text-center fw-300"
        style={{ maxWidth: 534 }}
      >
        {blockType === "temp-block"
          ? locale.t("tempblockdetail")
          : locale.t("permanentlydetail")}
      </div>
      {blockType !== "temp-block" && (
        <Button
          className="mt-4 btn-primary-new-iux"
          type="primary"
          onClick={() => onChangeManualVerify()}
        >
          <p>{locale.t("manualverify")}</p>
        </Button>
      )}
      {blockType === "temp-block" && (
        <Button
          className="mt-4 btn-primary-new-iux"
          type="primary"
          disabled={isDisable}
          onClick={() =>
            history.push(`/${localStorage.getItem("locale")}/wallet/account`)
          }
        >
          <p>{locale.t("uploadAgian")}</p>
        </Button>
      )}
      <div
        className="mt-3 text-primary-new-iux cursor-pointer"
        onClick={() =>
          history.push(`/${localStorage.getItem("locale")}/wallet/account/demo`)
        }
      >
        {locale.t("trydemo")}
      </div>
    </div>
  );
}

export default BlockVerify;
