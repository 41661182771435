import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";
import { fncToFixed } from "../../../../helper/toFixedCustom";
const locale = require("react-redux-i18n").I18n;
function CryptoRecive({
  cryptoRate,
  account,
  amount,
  currencySelected,
  cryptoFee,
  accountDetail,
}) {
  const renderRateWithdraw = () => {
    if (account?.account_type === "Cent") {
      let total = (amount / 100) * parseFloat(cryptoRate);
      return `${fncToFixed(total, currencySelected?.fixed)}`;
    } else {
      let total = amount * parseFloat(cryptoRate);
      return `${fncToFixed(total, currencySelected?.fixed)}`;
    }
  };
  const renderFeeRate = () => {
    return `${fncToFixed(
      parseFloat(cryptoFee) / parseFloat(cryptoRate),
      currencySelected?.fixed
    )} `;
  };
  const renderRateTotal = () => {
    if (account?.account_type === "Cent") {
      return ` ${(amount / 100 - parseFloat(cryptoFee) / parseFloat(cryptoRate))
        .toFixed(currencySelected?.fixed)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    } else {
      return ` ${(
        amount * parseFloat(cryptoRate) -
        parseFloat(cryptoFee) / parseFloat(cryptoRate)
      )
        .toFixed(currencySelected?.fixed)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    }
  };
  const renderRateWithdrawTotal = () => {
    if (account?.account_type === "Cent") {
      let total =
        (amount / 100) * parseFloat(cryptoRate) - parseFloat(cryptoFee);

      return ` ${fncToFixed(total, currencySelected?.fixed)} `;
    } else {
      let total = amount * parseFloat(cryptoRate) - parseFloat(cryptoFee);

      return ` ${fncToFixed(total, currencySelected?.fixed)} `;
    }
  };
  const checkAmountBroker = () => {
    if (
      amount - parseFloat(cryptoFee) / parseFloat(cryptoRate) >=
      accountDetail?.minimum_withdraw
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div>
      <div className="box-payment-receive d-flex flex-column justify-content-between">
        <div className="fw-300">
          <div className="mb-3 text-lightgray-iux d-flex justify-content-between">
            {locale.t("deposit_step.second_step.description")}
            <span>
              {" "}
              {renderRateWithdraw()}&nbsp;
              {currencySelected?.currency}
            </span>
          </div>
          <div className="mb-3 text-lightgray-iux d-flex justify-content-between">
            <div>
              <div>{locale.t("withdraw_info.bockchain_fee")}</div>
              <div>({locale.t("withdraw_info.approximate")})</div>
            </div>
            <div className="text-end">
              <div className="text-right">
                {cryptoFee} {currencySelected?.currency}
              </div>
              <div>≈ {renderFeeRate()} USD</div>
            </div>
          </div>
          <div className="mb-3 text-lightgray-iux d-flex justify-content-between">
            {locale.t("deposit_step.second_step.fee")}

            <span>0.00 {currencySelected?.currency}</span>
          </div>
          <div className="mb-3 text-lightgray-iux d-flex align-items-center justify-content-between">
            <div>
              {locale.t("withdraw_info.rate")}
              &nbsp;{" "}
              <Tooltip
                placement="topLeft"
                title={<div className="fs-10">{locale.t("faq.notice")}</div>}
              >
                <InfoCircleOutlined className="text-lightgray fs-16" />
              </Tooltip>
            </div>

            <span>
              {/* {fCurrencySymbol(rate.toFixed(2))}&nbsp;USD/
              {profileCountry?.symbol} */}
              {cryptoRate} {currencySelected?.currency}USD
            </span>
          </div>
        </div>
      </div>{" "}
      <div className="box-payment-resultamount d-flex justify-content-between text-right">
        <div>{locale.t("withdraw_info.total_amount")}</div>
        <div className={checkAmountBroker() ? "" : "text-danger"}>
          <b>
            {renderRateWithdrawTotal()} {currencySelected?.currency}
          </b>
          <div>
            ≈{" "}
            {(renderRateWithdrawTotal() / cryptoRate).toFixed(
              currencySelected?.fixed
            )}{" "}
            USD
          </div>
          {/* {renderRateWithdraw()} {profileCountry?.symbol} */}
        </div>
      </div>
    </div>
  );
}

export default CryptoRecive;
