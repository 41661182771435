import { renderApiDomain } from "../helper/renderApiDomain";
import { get, post } from "../helper/request";

export const getAccountTypeList = async (platform) => {
  return get(`${renderApiDomain()}account/types/${platform}`);
};
export const postChangePassword = async (data) => {
  return post(`${renderApiDomain()}user/password/change`, data);
};
export const postCheckPassword = async (data) => {
  return post(`${renderApiDomain()}user/password/check`, data);
};
export const postChangePhoneOtp = async (data) => {
  return post(`${renderApiDomain()}phone/change`, data);
};
export const getSymbolsSwap = async (account_type) => {
  return get(`${renderApiDomain()}symbols/${account_type}`);
};
export const getFirebaseCustom = async () => {
  return get(`${renderApiDomain()}firebase/token/custom`);
};
export const getUserCredit = async () => {
  return get(`${renderApiDomain()}user/credit`);
};
