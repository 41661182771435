import React from "react";
import { Row, Button, Card } from "antd";
const locale = require("react-redux-i18n").I18n;

const ArchiveCard = (props) => {
  const account = props.account.account_type;
  const replace =
    account === "StandardBonus"
      ? account.replace("StandardBonus", "Standard+")
      : account === "Standard"
      ? account.replace("Standard", "Standard")
      : account;

  return (
    <Card
      className="account-card"
      headStyle={{ background: "#fff", borderBottom: "none" }}
      title={
        <>
          <div className="align-content-center d-flex justify-content-between w-100">
            <div className="d-flex align-items-center flex-column">
              <div className="">
                <div className="fs-10 fw-700">
                  {props.account.account_number}
                </div>

                <div className="d-flex fs-10">
                  {replace}
                  {" • "}
                  <div className="m-auto">{props.account.platform}</div>
                </div>
              </div>
            </div>
            <div
              className="ml-2"
              style={{
                cursor: "pointer",
                outline: "none",
                boxShadow: "none",
                border: "none",
                fontSize: "14px",
                paddingRight: "8px",
                textAlign: "right",
                paddingLeft: "0",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => props.onSync(props.account)}
              {...props}
            >
              {/* <Tooltip placement="bottom" title={text}>
                <SyncOutlined />
              </Tooltip> */}
            </div>
          </div>
        </>
      }
    >
      <Row>
        <div
          style={{
            borderTop: "1px solid rgba(128, 128, 128, 0.2)",
            height: 20,
            width: "100%",
          }}
        />
        <Button
          fullWidth
          className="btn-primary-new-outline"
          style={{ textTransform: "uppercase", width: "100%" }}
          onClick={() => {
            props.onRestore(props.account);
          }}
        >
          {locale.t("dashboardWallet.reactive")}
        </Button>
      </Row>
    </Card>
  );
};

export default ArchiveCard;
