import { Carousel, message } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { setModalBonus } from "../../actions";
import { Base64Encoder } from "../../helper/Base64Encoder";
import { getCustomToken } from "../../helper/goWebtrade";
import moment from "moment";
const locale = require("react-redux-i18n").I18n;

const BoxBanner = styled.div`
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  height: ${(props) => props.mobile && "90px"};

  background: ${(props) => props.bg};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  background-color: ${(props) => props.bgcolor};
`;
const BoxBannerDefault = styled.div`
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  height: ${(props) => props.mobile && "90px"};

  background: ${(props) => props.bg};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  background-color: ${(props) => props.bgcolor};
`;
const BoxBannerDefaultMalay = styled.div`
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  height: ${(props) => props.mobile && "90px"};

  background: ${(props) => props.bg};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  background-color: ${(props) => props.bgcolor};
`;
const TextGradients = styled.div`
  background: linear-gradient(180deg, #1cb894 0%, rgba(0, 71, 255, 0.9) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
const TextGradientsYellow = styled.div`
  background: linear-gradient(313deg, #d2b554 0%, rgba(227, 103, 63, 0.5) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
const TextGradientsAppTrade = styled.div`
  background: linear-gradient(180deg, #1cb894 0%, #1cb894 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
const TextGradientsWebTrade = styled.div`
  background: linear-gradient(180deg, #fff 0%, #fff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Bagde = styled.div`
  border-radius: 40px;
  background: rgba(0, 71, 255, 0.2);
  color: #0047ff;
  display: flex;
  align-items: center;
  padding: 8px 16px;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`;

const Banner = () => {
  const profileCountry = useSelector((state) => state.user.profileCountry);
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const onClickBonus = () => {
    if (
      user?.bonus_config?.bonus_countries?.find(
        (e) => e === user?.profile?.country_id
      )
    ) {
      if (
        user.bookBankVerify.status === "accepted" &&
        user.userVerify.status === "accepted"
      ) {
        dispatch(setModalBonus(true));
      } else {
        if (user.userVerify.status !== "accepted") {
          message.error(locale.t("open_account.error_er"));
          history.push(
            `/${localStorage.getItem("locale")}/wallet/account/Standard`
          );
        } else {
          message.error(locale.t("open_account.error_bank"));
          history.push(
            `/${localStorage.getItem("locale")}/wallet/fund/withdraw`
          );
        }
      }
    } else {
      history.push(`/${localStorage.getItem("locale")}/wallet/receivebonus`);
    }
  };

  return (
    <div className="mb-3 banner-default">
      <Carousel id="banner-default" dots className="banner-default-app">
        {/* <BoxBannerDefaultMalay
          bg="url('/assets/images/banner/bannerwebinar.png')"
          bgcolor={"#000"}
          style={{ backgroundSize: "contain" }}
        >
          <div
            className="p-4 h-100 d-flex justify-content-around justify-content-around justify-content-md-between flex-column flex-sm-row  align-items-center banner-100"
            style={{ gap: "14px" }}
          >
            <div className="text-center" style={{ marginRight: 4 }}>
              <TextGradientsWebTrade className="d-flex fs-20 fw-700 justify-content-center justify-content-sm-start">
                {locale.t("seminar_malaysia.title")}
              </TextGradientsWebTrade>
              <div className="text-center text-sm-left text-white">
                {locale.t("seminar_malaysia.description")}
              </div>
            </div>{" "}
            <button
              onClick={() =>
                window.open(
                  "https://www.iux.com/" +
                    (localStorage.getItem("locale") === "ms" ? "ms" : "en") +
                    "/webinar",
                  "_blank"
                )
              }
              className="btn-primary-new-outline-trade-web px-5 fw-700  mt-3 mt-md-0 white-space-break"
              style={{
                borderRadius: "10px",
                whiteSpace: "nowrap",
                backgroundColor: "#24332E",
                height: "auto",
                padding: "8px",
              }}
            >
              {locale.t("seminar_malaysia.button")}
            </button>
          
          </div>
        </BoxBannerDefaultMalay> */}

        <BoxBannerDefault
          bg="url('/assets/images/announcement/trade-bg.png')"
          bgcolor={"#FFF"}
        >
          <div
            className="p-4 h-100 d-flex justify-content-around justify-content-md-between flex-column flex-sm-row  align-items-center banner-100"
            style={{ gap: "14px" }}
          >
            <div className="text-center">
              <TextGradientsWebTrade className="d-flex fs-20 fw-700 justify-content-center justify-content-sm-start">
                IUX Web Trade
              </TextGradientsWebTrade>
              <div className="text-center text-sm-left text-white">
                {/* Open a live trading account with IUX and get up to
                    $30 bonus free */}
                {locale.t("banner_trade_detail")}
              </div>
            </div>{" "}
            <button
              onClick={() => getCustomToken()}
              className="btn-primary-new-outline-trade-web px-5 fw-700  mt-3 mt-md-0 white-space-break"
              style={{
                borderRadius: "10px",
                whiteSpace: "nowrap",
                backgroundColor: "#24332E",
                height: "auto",
                padding: "8px",
              }}
            >
              {locale.t("banner_trade_button")}
            </button>
          </div>
        </BoxBannerDefault>
        <BoxBannerDefault
          bg="url('/assets/images/announcement/bg-default.png')"
          bgcolor={"#FFF"}
        >
          <div
            className="p-4 h-100 d-flex justify-content-around justify-content-md-between flex-column flex-sm-row  align-items-center"
            style={{ height: "100%" }}
          >
            <div className="text-center mb-3 mb-md-0">
              <TextGradientsAppTrade className="d-flex fs-20 fw-700 justify-content-center justify-content-sm-start">
                IUX App Trade
              </TextGradientsAppTrade>
              <div className="text-center text-sm-left">
                {/* Open a live trading account with IUX and get up to
                    $30 bonus free */}
                {locale.t("banner_default_detail")}
              </div>
            </div>{" "}
            <div
              className="d-flex flex-wrap justify-center"
              style={{ gap: "14px" }}
            >
              <div
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
                }}
              >
                <img
                  src="/assets/images/announcement/ios.png"
                  height={50}
                  className="cursor-pointer overflow-hidden"
                  style={{
                    borderRadius: "4px",
                  }}
                  onClick={() =>
                    window.open(
                      "https://apps.apple.com/th/app/iuxmarkets-app/id6444305135",
                      "_blank"
                    )
                  }
                />
              </div>
              <div
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
                }}
              >
                <img
                  className="cursor-pointer overflow-hidden"
                  style={{
                    borderRadius: "4px",
                  }}
                  src="/assets/images/announcement/android.png"
                  height={50}
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.iuxmarket.app&pli=1",
                      "_blank"
                    )
                  }
                />
              </div>
            </div>
          </div>
        </BoxBannerDefault>
        {user?.bonus_config?.bonus_countries?.find(
          (e) => e === user?.profile?.country_id
        ) &&
          user.accountTradeBonusId.isRecived === null &&
          user?.accounts?.filter((e) => e.type === "welcome").length < 1 && (
            <BoxBanner
              bg="url('/assets/images/announcement/bg.png')"
              bgcolor={"#FFF"}
            >
              <div className="p-4 h-100 d-flex justify-content-around justify-content-md-between flex-column flex-sm-row  align-items-center">
                <div className="text-center">
                  <TextGradients className="d-flex fs-20 fw-700 justify-content-center justify-content-sm-start">
                    Get&nbsp;<Bagde>Welcome</Bagde>&nbsp;Bonus $30
                  </TextGradients>
                  <div className="text-center text-sm-left">
                    {/* Open a live trading account with IUX and get up to
                    $30 bonus free */}
                    {locale.t("banner_bonus_detail")}
                  </div>
                </div>{" "}
                <button
                  className="btn-primary-new-outline px-5 fw-700  mt-3 mt-md-0 white-space-break"
                  style={{ borderRadius: "10px", whiteSpace: "nowrap" }}
                  onClick={() => onClickBonus()}
                >
                  {" "}
                  {locale.t("bonus_button")}
                </button>
              </div>
            </BoxBanner>
          )}
        {!user?.affiliate_id && (
          <BoxBanner
            bgcolor={"#FFF"}
            bg="linear-gradient(333deg, rgba(210, 181, 84, 0.49) 0%, rgba(210, 181, 84, 0.00) 100%);"
          >
            <div
              className="p-4 h-100 d-flex justify-content-around justify-content-md-between flex-column flex-sm-row  align-items-center"
              style={{ gap: "14px" }}
            >
              <div>
                <TextGradientsYellow
                  className="d-flex fs-20 fw-700 justify-content-center justify-content-sm-start"
                  style={{ textAlign: "center" }}
                >
                  {locale.t("banner_partner_title")}
                </TextGradientsYellow>
                <div className="text-center text-sm-left">
                  {locale.t("banner_partner_detail")}
                </div>
              </div>{" "}
              <button
                className="btn-yellow-new-iux px-5 fw-700 mt-3 mt-md-0 white-space-break"
                style={{
                  borderRadius: "10px",
                  whiteSpace: "nowrap",
                  height: "auto",
                  padding: "8px",
                }}
                onClick={() => window.open("https://partner.iuxmarkets.com")}
              >
                {locale.t("banner_join_partner")}
              </button>
            </div>
          </BoxBanner>
        )}
      </Carousel>
    </div>
  );
};

export default Banner;
